import { Grid } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { DropdownComponent } from "shared/shared";
import { filterSelectedArticleTypes, filterSelectedQualities, filterUnselectedArticleTypes, filterUnselectedQualities, selectArticleTypes, selectBodyArea, selectQualities, unselectArticleTypes, unselectQualities } from "../redux/policy_scar_treatment.slice";
import { SelectionListComponent } from "shared/components/selectionComponent/selectionList.component";

interface PolicyScarTreatmentBodyArea {
}

export const PolicyScarTreatmentBodyAreaComponent: React.FC<PolicyScarTreatmentBodyArea> = ({  
}) => {

    const state = useAppSelector((state) => state.policy_scar_treatment);
    const dispatch: AppDispatch = useAppDispatch();
    
    const item = state.data.selectedMainProductLine.editableItem; 

    var bodyAreas = item.bodyAreas.map(mpl => { return {
        id: mpl.id,
        name: mpl.name,
        erpId: mpl.erpId
    }});

    var selectedBodyArea = item.selectedBodyArea ? {
        id: item.selectedBodyArea.id,
        name: item.selectedBodyArea.name,
        erpId: item.selectedBodyArea.erpId,
    } : null;

    return (
        <Grid item container md={12}
            direction="column"
            spacing={3}>
            <Grid item>
                <DropdownComponent name="Körperregion"
                    data={bodyAreas}
                    minWidth="350px"
                    isRequired={true}
                    onSelect={(e) => dispatch(selectBodyArea(e ? e.id : null))}
                    displayedProperties={["erpId", "name"]}
                    selectedValue={selectedBodyArea}                                                   
                />
            </Grid>
                {selectedBodyArea && <Grid item>
                <SelectionListComponent
                    title={"Artikelart"}
                    entityName={"Artikelart"}
                    selectionList={item.selectedBodyArea.articleTypes}
                    selectEntities={(ids) => dispatch(selectArticleTypes(ids))}
                    unselectEntities={(ids) => dispatch(unselectArticleTypes(ids))}
                    onFilterSelected={(searchText) => dispatch(filterSelectedArticleTypes(searchText))}
                    onFilterUnselected={(searchText) => dispatch(filterUnselectedArticleTypes(searchText))}
                 />       
            </Grid>}
            <Grid item paddingBottom={3}>
                {selectedBodyArea &&<SelectionListComponent
                    title={"Qualität"}
                    entityName={"Qualität"}
                    selectionList={item.selectedBodyArea.qualities}
                    selectEntities={(ids) => dispatch(selectQualities(ids))}
                    unselectEntities={(ids) => dispatch(unselectQualities(ids))}
                    onFilterSelected={(searchText) => dispatch(filterSelectedQualities(searchText))}
                    onFilterUnselected={(searchText) => dispatch(filterUnselectedQualities(searchText))}
                 />}
            </Grid>
        </Grid>
    );
};