import { Accordion, AccordionDetails, AccordionDetailsProps, AccordionProps, AccordionSummary, AccordionSummaryProps } from '@mui/material';
import colors from 'shared/style/colors';
import styled from "@emotion/styled";;

export const AccordionItem = (props: AccordionProps) => {
    const { sx, ...other } = props;
    return (
        <Accordion
            sx={{
                border: '0px solid rgba(0, 0, 0, .125)',
                boxShadow: 'none',
                backgroundColor: 'whitesmoke',
                flexGrow: 1,
                '&:not(:last-child)': {
                    borderBottom: 0,
                },
                '&:before': {
                    display: 'none',
                },
                '&$expanded': {
                    margin: 'auto',
                },
                ...sx,
            }}
            {...other}
        />
    );
}

export const AccordionSummaryItem = (props: AccordionSummaryProps) => {
    const { sx, ...other } = props;
    return (
        <AccordionSummary
            sx={{
                backgroundColor: 'whitesmoke',
                borderBottom: '1px solid rgba(0, 0, 0, .125)',
                borderBottomColor: colors.mediMagenta,
                marginBottom: -1,
                minHeight: 56,
                padding: 0,
                color: colors.mediMagenta,
                '&$expanded': {
                    minHeight: 56,
                },
                userSelect: "text",
                ...sx,
            }}
            {...other}
        />
    );
}

export const AccordionErrorSummaryItem = (props: AccordionSummaryProps) => {
    const { sx, ...other } = props;
    return (
        <AccordionSummary
            sx={{
                backgroundColor: 'whitesmoke',
                borderBottom: '1px solid rgba(0, 0, 0, .125)',
                borderBottomColor: colors.mediMagenta,
                marginBottom: -1,
                minHeight: 56,
                padding: 0,
                color: colors.mediRed,
                '&$expanded': {
                    minHeight: 56,
                },
                userSelect: "text",
                ...sx,
            }}
            {...other}
        />
    );
}

export const AccordionDetailsItem = (props: AccordionDetailsProps) => {
    const { sx, ...other } = props;
    return (
        <AccordionDetails
            sx={{
                display: 'contents',
                padding: '0px',
                marginTop: '20px',
                backgroundColor: 'whitesmoke',
                ...sx,
            }}
            {...other}
        />
    );
}

export const HeaderColumn = styled.div`
    width: 90%;
`

export const HeaderIconColumn = styled.div`
    flex-basis: 10%;
`

export const Headline = styled.div`
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0px 16px;
    font-weight: bold;
`;