import { DropdownComponent, TextBoxComponent } from "shared/shared";
import { FormControl, Grid, Input, InputLabel } from "@mui/material";
import { IAdditionPolicyInformation } from "models/additions/addition_policy_information";
import { IMassPointCriteriaItem } from "check/policy/components/policy_editor.model";
import { ChangeEvent } from "react";
import theme from "shared/style/theme";
import { DecimalInputComponent } from "shared/components/input/numberInput/decimal_input.component";

interface PolicyUpperThresholdEditor {
    selectedAddition: IAdditionPolicyInformation,
    selectedMasspoint: IMassPointCriteriaItem,
    thresholdValue: number,
    allAdditions: IAdditionPolicyInformation[],
    allMasspoints: IMassPointCriteriaItem[],
    selectAddition: (addition: IAdditionPolicyInformation) => void;
    selectMasspoint: (masspoint: IMassPointCriteriaItem) => void;
    changeThresholdValue: (thresholdValue: number) => void;
}

export const PolicyUpperThresholdEditorComponent: React.FC<PolicyUpperThresholdEditor> = ({
    selectedAddition,
    selectedMasspoint,
    thresholdValue,
    allAdditions,
    allMasspoints,
    selectAddition,
    selectMasspoint,
    changeThresholdValue,
}) => {
    return (
        <Grid item container md={12}
            direction="column"
            spacing={3}>
            <Grid container item md={12} spacing={3}
                direction="column">
                <Grid item>
                    <DropdownComponent name="Zusatz"
                        data={allAdditions}
                        minWidth="350px"
                        isRequired={true}
                        onSelect={(e) => selectAddition(e)}
                        displayedProperties={["option", "description"]}
                        selectedValue={selectedAddition}
                    />
                </Grid>
            </Grid>
            <Grid container item md={12} spacing={3}
                direction="row">
                <Grid item>
                    <DropdownComponent
                        name="Maßpunkt"
                        data={allMasspoints}
                        minWidth="350px"
                        isLoading={false}
                        isRequired={true}
                        onSelect={selectMasspoint}
                        displayedProperties={["displayName"]}
                        selectedValue={selectedMasspoint}
                    />
                </Grid>
            </Grid>
            <Grid container item md={12}>
                <Grid item>
                    <DecimalInputComponent
                        name="Schwellwert"
                        width={350}
                        fixedPositions={0}
                        placeholder="1 bis 999"
                        minValue={1}
                        maxValue={999}
                        isRequired={true}
                        onChange={(value) => changeThresholdValue(value)}
                        value={thresholdValue}
                    />  
                </Grid>
            </Grid>
        </Grid>
    );
};