import { TableCell, TableCellProps, TableRow, TableRowProps } from "@mui/material";
import { forwardRef } from "react";

export const OverviewHeaderTableCell = (props: TableCellProps) => {
    const { sx, ...other } = props;
    return (
        <TableCell
        sx={{
            backgroundColor: '#e9edf0',
            fontWeight: 'bold',
            padding: 2,
            fontSize: 14,
            ...sx,
        }}
        {...other}
        />
    );
}

export const OverviewMenuHeaderTableCell = (props: TableCellProps) => {
    const { sx, ...other } = props;
    return (
        <TableCell
        sx={{
            backgroundColor: '#e9edf0',
            fontWeight: 'bold',
            padding: 2,
            fontSize: 14,
            width: 50,
            ...sx,
        }}
        {...other}
        />
    );
}

export const OverviewTableCell = (props: TableCellProps) => {
    const { sx, ...other } = props;
    return (
        <TableCell
        sx={{
            padding: 2,
            fontSize: 14,
            ...sx,
        }}
        {...other}
        />
    );
}

export const OverviewMenuTableCell = (props: TableCellProps) => {
    const { sx, ...other } = props;
    return (
        <TableCell
        sx={{
            padding: 2,
            fontSize: 14,
            width: 50,
            ...sx,
        }}
        {...other}
        />
    );
}

export const OverviewSelectedTableCell = (props: TableCellProps) => {
    const { sx, ...other } = props;
    return (
        <TableCell
        sx={{
            padding: 2,
            color: '#ffffff',
            fontSize: 14,
            width: 50,
            ...sx,
        }}
        {...other}
        />
    );
}

export const OverviewTableRow = (props: TableRowProps) => {
    const { sx, ...other } = props;
    return (
        <TableRow
        sx={{
            backgroundColor: 'white',
            padding: 2,
            ...sx,
        }}
        {...other}
        />
    );
}

export const OverViewTable = {
    width: 700
}