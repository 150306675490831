import { Grid } from '@mui/material';
import React from 'react';
import { AdditionBaseComponent } from './base/addition_base.component';
import { AdditionTabComponent } from './tab/addition_tab.component';
import { EditorButtonComponent } from 'shared/components/editors/editor_button/editor_buttons.component';
import { IAdditionInitial, IAdditionEditor, IAdditionEditorAction } from 'models/additions/addition_editor.model';

interface AdditionBaseComponentProps {
    message?: string,
    additionInitial: IAdditionInitial,
    additionEditor: IAdditionEditor,
    additionEditorActions: IAdditionEditorAction,
}

export const AdditionEditorComponent: React.FC<AdditionBaseComponentProps> = (
    {
        message,
        additionInitial,
        additionEditor,
        additionEditorActions,
    }) => {
    return (
        <Grid container
            spacing={3}
        >
            <Grid item md={12}>
                <AdditionBaseComponent additionInitial={additionInitial}
                    additionEditor={additionEditor}
                    updateCategory={additionEditorActions.updateCategory}
                    updateQuantityCalculationMode={additionEditorActions.updateQuantityCalculationMode}
                    updateSide={additionEditorActions.updateSide}
                    updateIsGusset={additionEditorActions.updateIsGusset}
                    updateIsMultiply={additionEditorActions.updateIsMultiply} />
                <AdditionTabComponent additionInitial={additionInitial}
                    additionEditor={additionEditor}
                    additionEditorActions={additionEditorActions} />
                <EditorButtonComponent
                    message={message ?? 'Zusatz wird unveröffentlicht gespeichert und muss manuell in der Übersicht veröffentlicht werden.'}
                    canExecute={additionEditorActions.canExecuteSave}
                    save={additionEditorActions.executeSave}
                    cancelSave={additionEditorActions.cancelSave} />
            </Grid>
        </Grid>
    );
}
