import { createAsyncThunk } from '@reduxjs/toolkit';
import CheckTypeService from 'services/checktype.service';
import { RootState } from '../../../app/redux_store';
import { errorMessages, handleResponseErrors } from '../../../shared/networking/error_handling';

export const fetchPlausibilityCheckTypes = createAsyncThunk(
    'plausibilitychecktype/type_overview/fetchPlausibilityCheckType',
    async (thunkApi) => {
        const response = await CheckTypeService.getCheckTypes();
        handleResponseErrors(response, "Prüfungstyp");
        return response;
    }
)

export const confirmDeletePlausibilityCheckType = createAsyncThunk(
    'plausibilitychecktype/type_overview/confirmDeletePlausibilityCheckType',
    async (_, { getState }) => {
        const state = getState() as RootState
        const plausibilityCheckTypeId = state.plausibilitychecktype_get.actualData.selectedPlausibilityCheckType.id;
        const responseDelete = await CheckTypeService.deleteCheckType(plausibilityCheckTypeId);
        handleResponseErrors(responseDelete, "Prüfungstyp", errorMessages.dataAllreadyUsedExists("Prüfungstyp"));

        const response = await CheckTypeService.getCheckTypes();
        handleResponseErrors(response, "Prüfungstyp");
        return response;
    }
)