import { Grid } from "@mui/material";
import { IAdditionPolicyInformation } from "models/additions/addition_policy_information";
import { ListButtonComponent } from "shared/components/buttons/list_button.component";
import { DropdownComponent } from "shared/shared";
import AddIcon from '@mui/icons-material/Add';
import { IAdjustMaximumRangeConfiguration, IIndexedMasspoint, IIndexedNumber, IIndexedSearchText } from "../policy_adjust_maximum_range_create/redux/policy_adjust_maximum_range_create.model";
import { PolicyAdjustMaximumRangeConfigurationComponent } from "./policy_adjust_maximum_range_configuration_editor.component";
import { IIndexedIds } from "check/policy/policy_adjust_ranges_by_masspoint/components/policy_adjust_ranges_by_masspoint_configuration.model";

interface PolicyAdjustMaximumRangeEditor {
    selectedAddition: IAdditionPolicyInformation,
    allAdditions: IAdditionPolicyInformation[],
    configurations: IAdjustMaximumRangeConfiguration[],
    addConfiguration: () => void;
    selectAddition: (addition: IAdditionPolicyInformation) => void;
    deleteConfiguration: (index: number) => {};
    filterSelectedArticleTypes: (value: IIndexedSearchText) => {};
    filterUnselectedArticleTypes: (value: IIndexedSearchText) => {};
    selectArticleTypes: (value: IIndexedIds) => {};
    unSelectArticleTypes: (value: IIndexedIds) => {};
    selectMasspoint1: (value: IIndexedMasspoint) => void;
    selectMasspoint2: (value: IIndexedMasspoint) => void;
    changeMultiplyValue: (value: IIndexedNumber) => void;
}

export const PolicyAdjustMaximumRangeEditorComponent: React.FC<PolicyAdjustMaximumRangeEditor> = ({
    selectedAddition,
    allAdditions,
    configurations,
    addConfiguration,
    selectAddition,
    selectMasspoint1,
    selectMasspoint2,
    deleteConfiguration,
    filterSelectedArticleTypes,
    filterUnselectedArticleTypes,
    selectArticleTypes,
    unSelectArticleTypes,
    changeMultiplyValue
}) => {

    const isRangePlaceholder = Boolean(selectedAddition?.placeholder?.rangePlaceholder);
    const errorText = isRangePlaceholder || !selectedAddition ? "" : "Der Zusatz ist nicht als “Rangewert” konfiguriert."

    return (
        <Grid item container md={12}
            direction="column"
            spacing={3}>
            <Grid container item md={12} spacing={3}
                direction="row">
                <Grid item>
                    <DropdownComponent name="Zusatz"
                        data={allAdditions}
                        minWidth="350px"
                        isRequired={true}
                        onSelect={(e) => selectAddition(e)}
                        displayedProperties={["option", "description"]}
                        selectedValue={selectedAddition}
                        errorText={errorText}
                    />
                </Grid>
                <Grid item>
                    {isRangePlaceholder && <div>
                        <div style={{ fontWeight: 600 }}>Konfigurierte Rangewerte:</div>
                        <div>{`Range : ${selectedAddition.placeholder.rangePlaceholder.valueStart} cm - ${selectedAddition.placeholder.rangePlaceholder.valueEnd} cm`}</div>
                    </div>}
                </Grid>
            </Grid>
            {
                configurations &&
                configurations.map((con, index) => {
                    return (
                        <Grid item key={index}>
                            <PolicyAdjustMaximumRangeConfigurationComponent
                                configuration={con}
                                configurationIndex={index}
                                selectMasspoint1={selectMasspoint1}
                                selectMasspoint2={selectMasspoint2}
                                deleteConfiguration={deleteConfiguration}
                                filterSelectedArticleTypes={filterSelectedArticleTypes}
                                filterUnselectedArticleTypes={filterUnselectedArticleTypes}
                                selectArticleTypes={selectArticleTypes}
                                unSelectArticleTypes={unSelectArticleTypes}
                                changeMultiplyValue={changeMultiplyValue}
                            />
                        </Grid>
                    )
                })
            }
            <Grid item>
                <ListButtonComponent
                    onClick={addConfiguration} disabled={false}>
                    <AddIcon /> Konfiguration hinzufügen
                </ListButtonComponent>
            </Grid>
        </Grid>
    );
};