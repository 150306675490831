import { CommonApiResultError } from "./common_api_result";

class CommonHttpError extends Error {
    // we have to do the following because of: https://github.com/Microsoft/TypeScript/issues/13965
    // otherwise we cannot use instanceof later to catch a given type
    public __proto__: Error;

    public code: string;
    public subcode: string;  
    public error: CommonApiResultError;

    constructor(message?: string, code?: string, error?: CommonApiResultError ) {
      const trueProto = new.target.prototype;
 
      super(message);
  
      this.__proto__ = trueProto;
      this.code = code; 
      this.error = error;
    }
  }

  export default CommonHttpError;