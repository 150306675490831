import { ICountry } from "models/country/country";
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from "./config.service";
import HttpServiceCommonResult from "shared/networking/http_service_common_result";

class CountryServiceAPI extends HttpServiceCommonResult {
    public async getCountries(): Promise<ApiResult<ICountry[]>> {
        return this.get(`/api/country`);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const CountryService = new CountryServiceAPI(TimeoutMilliseconds);

export default CountryService;