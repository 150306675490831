import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseErrors } from "shared/networking/error_handling";
import AdditionService from 'services/addition.service';
import MassPointService from 'services/masspoint.service';
import BaseDataService from 'services/base_data.service';
import { PolicyTypeEnum } from "models/policies/policy_type.enum";
import { RootState } from "app/redux_store";
import PolicyServiceAPI from 'services/policy.service';

export const getAdditions = createAsyncThunk(
    'policy/create_policy_addition_rank/getAdditions',
    async (_) => {
        const response = await AdditionService.getAvailableAdditionsByPolicyTypeOverview(PolicyTypeEnum.AdditionRank, undefined);
        handleResponseErrors(response, "Zusätze");
        return response;
    }
)

export const getMassPoints = createAsyncThunk(
    'policy/create_policy_addition_rank/getMassPoints',
    async (_) => {
        const response = await MassPointService.getMassPoints()
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)

export const getBaseData = createAsyncThunk(
    'policy/create_policy_addition_rank/getBaseData',
    async (_) => {
        const response = await BaseDataService.getBaseData()
        handleResponseErrors(response, "Basisdaten");
        return response;
    }
)

export const savePolicy = createAsyncThunk(
    'policy/create_policy_addition_rank/savepolicy',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.policy_addition_rank_create.data;
        const response = await PolicyServiceAPI.createAdditionRank({
            name: data.policy.name,
            ignoreOnQuotation: data.policy.ignoreOnQuotation,
            additionId: data.selectedAddition.id,
            mainProductLineId: data.selectedMainProductLine.id,
            bodyAreaId: data.selectedBodyArea.id,
            masspoint1: 
            {
                name: data.selectedMasspoint1.name,
                type: data.selectedMasspoint1.massPointType,
                bodyAreaId: data.selectedMasspoint1.bodyAreaId
            },
            masspoint2: {
                name: data.selectedMasspoint2.name,
                type: data.selectedMasspoint2.massPointType,
                bodyAreaId: data.selectedMasspoint2.bodyAreaId
            },
            articleTypes: data.articleTypes.selectedList.allItems.map(x => {
                return x.id
            })
        });
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'policy/create_policy_addition_rank/cancelSave',
    async (_) => { }
)

export const initData = createAsyncThunk(
    'policy/create_policy_addition_rank/initData',
    async (_, { dispatch }) => {
        await Promise.all([
            dispatch(getAdditions()),
            dispatch(getMassPoints()),
            dispatch(getBaseData()),
        ]);
    }
)



