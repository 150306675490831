import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'app/redux_store';
import { OneLegService } from 'services/oneleg.service';
import MassPointService from '../../../services/masspoint.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';

export const fetchAllData = createAsyncThunk(
    'massMasks/oneLeg/edit/fetchAllData',
    async (id: string, thunkApi) => {
        await Promise.all([
            thunkApi.dispatch(getMassPoints()),
            thunkApi.dispatch(getOneLeg(id))         
        ]);
    }
)

export const getMassPoints = createAsyncThunk(
    'massMasks/oneLeg/edit/getMassPoints',
    async (_, { getState }) => {
        const response = await MassPointService.getMassPoints()
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)

export const getOneLeg = createAsyncThunk(
    'massMasks/oneLeg/edit/getOneLeg',
    async (id: string, { getState }) => {
        const response = await OneLegService.getOneLeg(id)
        handleResponseErrors(response, "Einbeinhose");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'massMasks/oneLeg/edit/cancelSave',
    async (_) => { }
)

export const updateOneLeg = createAsyncThunk(
    'massMasks/oneLeg/edit/updateOneLeg',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const selectedData = state.one_leg_edit.actualData;

        const response = await OneLegService.updateOneLeg({
            id: selectedData.oneLegId,
            massPoints: selectedData.massPointCriteria.selected.allItems.map(item => {
                return {
                    name: item.massPointName,
                    type: item.massPointType,
                    bodyAreaId: item.bodyArea.id,
                };
            })
        });

        handleResponseErrors(response, "Einbeinhose");
        return response;
    }
)