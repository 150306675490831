import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/redux_store';
import MassPointService from '../../../services/masspoint.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';

export const editMasspoint = createAsyncThunk(
    'masspoints/masspoint/edit_masspoint',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const data = state.masspoint_edit;
        const response = await MassPointService.updateMassPoint({
            massPointId: data.loadedData.masspoint.id,
            isPantyTopWithStandardDimensions: data.actualData.isPantyTopWithStandardDimensions,
            isEllipticalShapeKneeFunctionZone: data.actualData.isEllipticalShapeKneeFunctionZone
        });
        handleResponseErrors(response, "Maßpunkt");
        return response;
    }
)

export const getMasspoint= createAsyncThunk(
    'massMask/massDefinition/getMasspoint',
    async (masspointId: Number, _) => {
        const response = await MassPointService.getMassPoint(masspointId);
        handleResponseErrors(response, "Maßpunkt");
        return response;
    }
)