import { doesExist, isNullOrWhitespace } from "services/validation.service";
import { PolicyMandatoryCategoryByMasspointUpdateState } from "./policy_mandatory_category_by_masspoint_update.model";
import IMasspointOverview from "models/masspoints/masspoint_overview";
import { MasspointTypeEnumValuesLookup } from "models/masspoints/enums/masspoint_type.enum";
import { distinctMassPointCriteria } from "shared/helpers/distinctMassPointCriteria";

export const updateCanSave = (state: PolicyMandatoryCategoryByMasspointUpdateState) => {
    state.command.updatePolicy.canExecute =
        !isNullOrWhitespace(state.data.policy.name)
        && doesExist(state.data.selectedCategory)
        && doesExist(state.data.selectedMasspointCriteria)
        && isNotEqual(state);
}

const isNotEqual = (state: PolicyMandatoryCategoryByMasspointUpdateState) => {
    return state.data.policy.name !== state.loadedData.policy.name 
        || state.data.policy.ignoreOnQuotation !== state.loadedData.policy.ignoreOnQuotation 
        || state.data.selectedCategory.id !== state.loadedData.policy.additionCategoryId 
        || state.data.selectedMasspointCriteria.bodyAreaId !== state.loadedData.policy.bodyAreaId 
        || state.data.selectedMasspointCriteria.massPointName !== state.loadedData.policy.massPointName 
        || state.data.selectedMasspointCriteria.massPointType !== state.loadedData.policy.massPointType 
}

export const getMassPointCriteriaFromMasspoints = (masspoints: IMasspointOverview[]) => {
    return masspoints.filter(distinctMassPointCriteria).map(mp => {
        return {
            massPointId: mp.id,
            massPointName: mp.name,
            displayName: `${mp.name} ${MasspointTypeEnumValuesLookup(mp.massPointType)} ${mp.bodyArea.name}`,
            massPointType: mp.massPointType,
            bodyAreaId: mp.bodyArea.id,
        }
    });
}