import { MassMaskCommonInfoCreateState } from "./massmask_common_info_create.model";

export const updateCanExecuteCreate = (state: MassMaskCommonInfoCreateState): boolean => {
    const bodyInformationSelectionIsValid = validateBodyInformationSelection(state);

    return (bodyInformationSelectionIsValid && (state.actualData.selectedFootType != null || state.actualData.selectedArticleType != null));
}

const validateBodyInformationSelection = (state: MassMaskCommonInfoCreateState): boolean => {
    return !hasDuplicates(state.actualData.bodyInformationSelection.flatMap(x => x.id));
}

const hasDuplicates = (array: Array<any>) => {
    return new Set(array).size !== array.length
}