import { createSlice } from '@reduxjs/toolkit';
import { MasspointHistoryState } from './masspoint_history_datails.model';
import { fetchMasspointHistoryDetail } from './masspoint_history_datails.thunks';

const initialState: MasspointHistoryState = {
    actualData: { masspointHistoryDetails: null },
    query: {
        fetchMasspointHistoryDetail: { status: "idle", canExecute: true }
    },
}

export const masspointHistoryDetailsSlice = createSlice({
    name: 'history/masspoint/details',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // fetch masspoint history details
        builder.addCase(fetchMasspointHistoryDetail.pending, (state, action) => {
            state.query.fetchMasspointHistoryDetail.status = "pending"
            state.query.fetchMasspointHistoryDetail.canExecute = false;
        }).addCase(fetchMasspointHistoryDetail.rejected, (state, action) => {
            state.query.fetchMasspointHistoryDetail.status = "error"
            state.query.fetchMasspointHistoryDetail.message = action.error.message;
            state.query.fetchMasspointHistoryDetail.canExecute = true;
        }).addCase(fetchMasspointHistoryDetail.fulfilled, (state, action) => {
            state.query.fetchMasspointHistoryDetail.status = "success"
            state.query.fetchMasspointHistoryDetail.message = undefined;
            state.query.fetchMasspointHistoryDetail.canExecute = true;
            state.actualData.masspointHistoryDetails = action.payload.getData();
        })
    }
})

export default masspointHistoryDetailsSlice.reducer