import { AppDispatch } from "app/redux_store";
import { useAppDispatch, useAppSelector } from '../../../app/hook';
import AddIcon from '@mui/icons-material/Add';
import * as style from "./characteristic_type_overview.style";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { FlexBox, Left, LoadingIndicatorComponent, PrimaryButtonComponent, Right } from "shared/shared";
import { createNewCharacteristicType, fetchCharacteristicTypes, updateCharacteristicType } from "./redux/characteristic_type_overview.thunks";
import { CharacteristicTypeDeleteDialogComponent } from "./components/characteristic_type_delete_dialog.component";
import SearchFieldComponent from "shared/components/search/searchfield.component";
import {
    deleteCharacteristicType,
    deleteCompleted,
    updateCompleted,
    setGridFilters,
    setSearchFilter,
    resetState
} from "./redux/characteristic_type_overview.slice";
import { getGridColumns, sortByNameModel } from "./characteristic_type_overview.helper";
import { OverviewDatagrid } from "shared/components/datagrid/overview_datagrid.component";
import { DialogComponent } from "shared/components/dialogs/dialog.component";

export const CharacteristicTypeOverviewComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.characteristic_types_overview);

    useEffect(() => {
        dispatch(fetchCharacteristicTypes());
        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    if (state.query.fetchCharacteristicTypes.status === "error") {
        return <div>Fehler beim Laden der Daten</div>;
    }

    if (state.query.fetchCharacteristicTypes.status !== "success") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.createNewCharacteristicType.status === "pending") {
        navigate(`/additions/characteristictype/create`);
    }

    if (state.command.updateCharacteristicType.status === "success") {
        navigate(`/additions/characteristictype/edit/${state.actualData.characteristicType.id}`);        
        dispatch(updateCompleted());
    }

    if (state.command.confirmDeleteCharacteristicType.status === "error" && !state.errorData.showDeleteErrorDialog) {
        enqueueSnackbar(state.command.confirmDeleteCharacteristicType.message, { variant: "error" });
        dispatch(deleteCompleted())
    }

    if (state.command.confirmDeleteCharacteristicType.status === "success") {
        enqueueSnackbar("Ausprägungstyp erfolgreich gelöscht", { variant: "success" });
        dispatch(deleteCompleted())
    }

    const isLoading = state.command.confirmDeleteCharacteristicType.status === "pending";

    const characteristicTypesAvailable = state.loadedData.characteristicTypes.length > 0;

    return (
        <div>
            {
                state.actualData.showDeleteCharacteristicTypeDialog &&
                <CharacteristicTypeDeleteDialogComponent />
            }
            {
                state.errorData.showDeleteErrorDialog &&
                <DialogComponent
                    title={"Ausprägungstyp löschen nicht möglich"}
                    isOpen={true}
                    options={[
                        { title: "Okay", handleOption: () => { dispatch(deleteCompleted()) } }
                    ]}
                    onClose={() => { }}>
                    <div>{state.errorData.error.message}</div>
                </DialogComponent>
            }
            <FlexBox>

                {
                    characteristicTypesAvailable &&
                    <Left>
                        <SearchFieldComponent onSearch={(filterText) => dispatch(setSearchFilter(filterText))} placeholder="In Ausprägungstypen suchen ..." />
                    </Left>
                }
                <Right>
                    <PrimaryButtonComponent onClick={() => dispatch(createNewCharacteristicType())}>
                        <AddIcon /> Neuen Ausprägungstyp erstellen
                    </PrimaryButtonComponent>
                </Right>
            </FlexBox>
            <style.OverviewArea>
                {
                    !characteristicTypesAvailable &&
                    <div>Keine Ausprägungstypen verfügbar</div>
                }
                {
                    characteristicTypesAvailable &&
                    <OverviewDatagrid
                        isLoading={isLoading}
                        columns={getGridColumns(
                            (characteristic_type) => dispatch(updateCharacteristicType(characteristic_type)),
                            (characteristic_type) => dispatch(deleteCharacteristicType(characteristic_type)),
                        )}
                        rows={state.actualData.characteristicTypes}
                        loadedRowsCount={state.loadedData.characteristicTypes.length}
                        sortModel={sortByNameModel}
                        onFilterModelChange={(newFilterModel) => dispatch(setGridFilters(newFilterModel))}
                        onDoubleClickRow={characteristic_type => dispatch(updateCharacteristicType(characteristic_type))}
                    />
                }
            </style.OverviewArea>
        </div>
    );
}