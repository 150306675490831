export enum ImportStatusEnum {
    running,
    successful,
    error,
}

export const ImportStatusEnumLookup: { [id: number]: string; } = {};
ImportStatusEnumLookup[ImportStatusEnum.running] = "läuft";
ImportStatusEnumLookup[ImportStatusEnum.successful] = "erfolgreich";
ImportStatusEnumLookup[ImportStatusEnum.error] = "fehlgeschlagen";
