import { GridColDef, GridColumnGroupingModel, GridSortModel, getGridBooleanOperators } from "@mui/x-data-grid-pro";
import { CustomBooleanCell } from "shared/components/datagrid/custom_boolean_cell.component";
import { CustomFilterOperators } from "shared/components/datagrid/custom_filter_operators";

export const getGroupingModel = (): GridColumnGroupingModel =>
    [
        {
            groupId: 'horizontal_start',
            headerName: "Horizontal Start",
            children: [{ field: 'pre_position_horizontal_start' }, { field: "position_horizontal_start" }, { field: "horizontalStartValue" }],
        },
        {
            groupId: 'horizontal_end',
            headerName: "Horizontal Ende",
            children: [{ field: 'pre_position_horizontal_end' }, { field: "position_horizontal_end" }, { field: "horizontalEndValue" }],
        },
        {
            groupId: 'vertical_start',
            headerName: "Vertikal Start",
            children: [{ field: 'pre_position_vertical_start' }, { field: "position_vertical_start" }, { field: "verticalStartValue" }],
        },
        {
            groupId: 'vertical_end',
            headerName: "Vertikal Ende",
            children: [{ field: 'pre_position_vertical_end' }, { field: "position_vertical_end" }, { field: "verticalEndValue" }],
        },
    ]

export const getGridColumns = (): GridColDef[] => [
    {
        field: "bodyAreaTranslation",
        headerName: "Körperregion",
        disableColumnMenu: false,
        flex: 0.5,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
        valueGetter: (value, row) => row.bodyAreaTranslation
    },
    {
        field: "side",
        headerName: "Seite",
        disableColumnMenu: false,
        flex: 0.5,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
        valueGetter: (value, row) => row.bodySideTranslation
    },
    {
        field: "pre_position_horizontal_start",
        headerName: "Präposition",
        disableColumnMenu: false,
        flex: 0.5,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
        valueGetter: (value, row) => row.prepositionHorizontalStartTranslation
    },
    {
        field: "position_horizontal_start",
        headerName: "Körperposition",
        disableColumnMenu: false,
        flex: 0.5,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
        valueGetter: (value, row) => row.bodyPositionHorizontalStartTranslation
    },
    {
        field: 'horizontalStartValue',
        headerName: "Abweichung",
        editable: true,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterable: false,
        type: 'singleSelect',
        valueOptions: ({ id, row, field }) => {
            return row?.availableHorizontalStartValues
        },
    },
    {
        field: "pre_position_horizontal_end",
        headerName: "Präposition",
        flex: 0.5,
        disableColumnMenu: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
        valueGetter: (value, row) => row.prepositionHorizontalEndTranslation
    },
    {
        field: "position_horizontal_end",
        headerName: "Körperposition",
        disableColumnMenu: false,
        flex: 0.5,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
        valueGetter: (value, row) => row.bodyPositionHorizontalEndTranslation
    },
    {
        field: 'horizontalEndValue',
        headerName: "Abweichung",
        editable: true,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterable: false,
        type: 'singleSelect',
        valueOptions: ({ id, row, field }) => {
            return row?.availableHorizontalEndValues
        }
    },
    {
        field: "pre_position_vertical_start",
        headerName: "Präposition",
        disableColumnMenu: false,
        flex: 0.5,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
        valueGetter: (value, row) => row.prepositionVerticalStartTranslation
    },
    {
        field: "position_vertical_start",
        headerName: "Körperposition",
        disableColumnMenu: false,
        flex: 0.5,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
        valueGetter: (value, row) => row.bodyPositionVerticalStartTranslation
    },
    {
        field: 'verticalStartValue',
        headerName: "Abweichung",
        editable: true,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterable: false,
        type: 'singleSelect',
        valueOptions: ({ id, row, field }) => {
            return row?.availableVerticalStartValues
        }
    },
    {
        field: "pre_position_vertical_end",
        headerName: "Präposition",
        disableColumnMenu: false,
        flex: 0.5,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
        valueGetter: (value, row) => row.prepositionVerticalEndTranslation
    },
    {
        field: "position_vertical_end",
        headerName: "Körperposition",
        flex: 0.5,
        disableColumnMenu: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
        valueGetter: (value, row) => row.bodyPositionVerticalEndTranslation
    },
    {
        field: 'verticalEndValue',
        headerName: "Abweichung",
        editable: true,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterable: false,
        type: 'singleSelect',
        valueOptions: ({ id, row, field }) => {
            return row?.availableVerticalEndValues
        }
    },
    {
        field: "isDocumentAllowed",
        headerName: "Bild",
        width: 25,
        disableColumnMenu: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: getGridBooleanOperators(),
        renderCell: (params) => CustomBooleanCell({ value: params.row.isDocumentAllowed })
    },
    {
        field: "isMeasurementAllowed",
        headerName: "Maß",
        width: 25,
        disableColumnMenu: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: getGridBooleanOperators(),
        renderCell: (params) => CustomBooleanCell({ value: params.row.isLengthAllowed || params.row.isWidthAllowed || params.row.isDiameterAllowed })
    }
]

export const sortByNameModel: GridSortModel = [
    {
        field: "name",
        sort: "asc",
    },
];
