interface IMenuRoute {
  name: string;
  target: string;
}

const MenuRoutes: Array<IMenuRoute> = [
  { name: "Übersicht", target: "/home" },
  { name: "Maßmasken", target: "/massmasks" },
  { name: "Maßpunkte", target: "/masspoints" },
  { name: "Prüfungen", target: "/plausibilitycheck" },
  { name: "Simulator", target: "/simulator" },
  { name: "Historie", target: "/history" },
  { name: "Zusätze", target: "/additions" },
  { name: "Administration", target: "/admin" }
];

export default MenuRoutes;
