import { FormControlLabel, Grid, Radio } from "@mui/material"
import { SideSeamModeEnum } from "models/position_category/side_seam_mode.enum";
import { EditorDescriptionComponent } from "shared/components/editors/editor_description/editor_description.style"
import { EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";

export interface PositionCategorySideSeamComponentProps {
    sideSeamModeAllowed: SideSeamModeEnum;
    setSideSeamMode: (value: SideSeamModeEnum) => void
}

export const PositionCategoryItemSideSeamComponent = (props: PositionCategorySideSeamComponentProps) => {

    return (
    <>
        <EditorSectionHeaderComponent>Seitliche Naht</EditorSectionHeaderComponent>
        <EditorDescriptionComponent>Sollen die Positionen auch/nur für seitliche Naht verwendet werden?</EditorDescriptionComponent>
        <Grid item>
            <FormControlLabel
                control={
                    <Radio
                        checked={props.sideSeamModeAllowed === SideSeamModeEnum.SideSeam}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={() => props.setSideSeamMode(SideSeamModeEnum.SideSeam)}
                    />
                }
                label={"Ja"}
            />
            <FormControlLabel
                control={
                    <Radio
                        checked={props.sideSeamModeAllowed === SideSeamModeEnum.Normal}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={() => props.setSideSeamMode(SideSeamModeEnum.Normal)}
                    />
                }
                label={"Nein"}
            />
            <FormControlLabel
                control={
                    <Radio
                        checked={props.sideSeamModeAllowed === SideSeamModeEnum.Both}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={() => props.setSideSeamMode(SideSeamModeEnum.Both)}
                    />
                }
                label={"Beides"}
            />
        </Grid>
    </>
    )
}