import { RuleTypeEnum } from '../../models/plausibility_check/enums/rule_type.enum';
import { MassPointCriteria } from '../../models/plausibility_check/masspoint_criteria';
import { MinMaxRule } from '../../models/plausibility_check/min_max_rule';
import { doesExist } from '../../services/validation.service';
import { RulesMinMaxState } from './rules_min_max.model';

export const addNewRuleIfLastRuleFilledOut = (state: RulesMinMaxState) => {
    const lastRule = state.actualData.rules[state.actualData.rules.length - 1];
    if (doesExist(lastRule.massPointName)
        && doesExist(lastRule.massPointType)
        && doesExist(lastRule.ruleType)
        && lastRule.ruleType !== RuleTypeEnum.Undefined) {
        const newEmptyRule = {};
        state.actualData.rules.push(newEmptyRule);
    }
}

export const updateCanSaveRules = (state: RulesMinMaxState) => {
    state.command.saveRules.canExecute = state.actualData.rules.length > 1
        && state.actualData.rules.slice(0, -1).every(rule => isRuleValid(rule))
        && !doesExist(state.actualData.rules[state.actualData.rules.length - 1].massPointCriteria)
        && !doesExist(state.actualData.rules[state.actualData.rules.length - 1].ruleType)
}

const isRuleTypeBothValid = (rule: MinMaxRule) => {
    return rule.ruleType === RuleTypeEnum.Both &&
        doesExist(rule.errorMinValue) &&
        doesExist(rule.errorMaxValue) &&
        doesExist(rule.warningMinValue) &&
        doesExist(rule.warningMaxValue) &&
        rule.errorMinValue < rule.errorMaxValue &&
        rule.warningMinValue < rule.warningMaxValue;
}

const isRuleTypeErrorValid = (rule: MinMaxRule) => {
    return rule.ruleType === RuleTypeEnum.Error &&
        doesExist(rule.errorMinValue) &&
        doesExist(rule.errorMaxValue) &&
        rule.errorMinValue < rule.errorMaxValue;
}

const isRuleTypeWarningValid = (rule: MinMaxRule) => {
    return rule.ruleType === RuleTypeEnum.Warning &&
        doesExist(rule.warningMinValue) &&
        doesExist(rule.warningMaxValue) &&
        rule.warningMinValue < rule.warningMaxValue;
}

const isRuleValid = (rule: MinMaxRule) => {
    return doesExist(rule.massPointCriteria) &&
        doesExist(rule.massPointName) &&
        doesExist(rule.massPointType) &&

        (isRuleTypeBothValid(rule) ||
            isRuleTypeWarningValid(rule) ||
            isRuleTypeErrorValid(rule))
}

export const distinctMassPointCriteria = (value: MassPointCriteria, currentIndex: number, arr: MassPointCriteria[]) => {
    const indexOfFirstOccurrence = arr.findIndex(mp => mp.massPointName === value.massPointName 
        && mp.massPointType === value.massPointType
    )
    return currentIndex === indexOfFirstOccurrence;
}


export const distinctMassPointCriteriaByIsSideDependant = (value: MassPointCriteria, currentIndex: number, arr: MassPointCriteria[]) => {
    const indexOfFirstOccurrence = arr.findIndex(mp => mp.massPointName === value.massPointName 
        && mp.massPointType === value.massPointType 
        && mp.massPointBodySide === value.massPointBodySide
    )
    return currentIndex === indexOfFirstOccurrence;
}