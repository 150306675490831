import { IAdditionEditor, IAdditionEditorAction } from "models/additions/addition_editor.model";
import { FormControlLabel, FormLabel, Grid, Radio } from '@mui/material';
import { SelectionListComponent } from "shared/components/selectionComponent/selectionList.component";
import { EditorDescriptionComponent } from "shared/components/editors/editor_description/editor_description.style";
import { EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";

interface AdditionFootTypeComponentProps {
    additionEditor: IAdditionEditor,
    additionEditorActions: IAdditionEditorAction,
}

export const AdditionFootTypeComponent: React.FC<AdditionFootTypeComponentProps> = ({
    additionEditor,
    additionEditorActions,
}) => {
    return (
        <>
            <EditorSectionHeaderComponent>Fußarten</EditorSectionHeaderComponent>
            <EditorDescriptionComponent>
                Hat der Zusatz Abhängigkeiten zu Fußarten?
            </EditorDescriptionComponent>
            <FormLabel></FormLabel>
            <Grid item marginBottom={"20px"}>
                <FormControlLabel label={"Ja"}
                    control={
                        <Radio
                            checked={additionEditor.addition.lockFootType}
                            inputProps={{ 'aria-label': 'controlled' }}
                            onChange={() => additionEditorActions.updateLockFootType(true)}
                        />} />
                <FormControlLabel label={"Nein"}
                    control={
                        <Radio
                            checked={!additionEditor.addition.lockFootType}
                            inputProps={{ 'aria-label': 'controlled' }}
                            onChange={() => additionEditorActions.updateLockFootType(false)}
                        />} />
            </Grid>

            {
                additionEditor.addition.lockFootType &&
                <>
                    <Grid item md={12}>
                        <SelectionListComponent
                            title="Fußartensperre"
                            entityName={"Fußarten"}
                            allow={() => additionEditorActions.allowFootType()}
                            deny={() => additionEditorActions.denyFootType()}
                            onFilterSelected={(searchText) => additionEditorActions.filterSelectedFootType(searchText)}
                            onFilterUnselected={(searchText) => additionEditorActions.filterUnselectedFootType(searchText)}
                            selectionList={additionEditor.footTypeSelection}
                            selectEntities={(ids) => additionEditorActions.selectFootTypes(ids)}
                            unselectEntities={(ids) => additionEditorActions.unselectFootTypes(ids)}
                        />
                    </Grid>
                </>
            }
        </>
    )
};