import { DateTimeDisplayComponent } from "../form_controls/date_time_display.component";

interface CustomDateCellComponentProps{
    date: Date;
}

export const CustomDateCell: React.FC<CustomDateCellComponentProps> = ({date}) => {
    return <>
            <DateTimeDisplayComponent date={date} />
    </>
}