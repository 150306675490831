import { Grid, Typography } from "@mui/material";
import { LinkComponent } from "shared/components/typography/link.component";
import { getConfig } from "services/config.service";

export interface POEditorLinkComponentProps {
    projectName: string
}

export const POEditorLinkComponent: React.FC<POEditorLinkComponentProps> = ( { projectName }) => {
    const generatePOEditorLink = () => {
        const c = getConfig().poeditor;
        return `${c.baseUrl}/projects/`
    }

    return (
        <Grid item md={9}>
            <Typography>
                Neue Texte können im <LinkComponent href={generatePOEditorLink()} rel="noreferrer" target="_blank">POEditor</LinkComponent> angelegt werden.
            </Typography>
        </Grid>
    );
};