import React from "react";
import { BreadcrumbsComponent } from '../shared/breadcrumbs/components/breadcrumbs';
import { BreadcrumbRoute } from "../shared/breadcrumbs/models/breadcrumb_route";

export const AdditionsBreadcrumbs = () => {
    return <BreadcrumbsComponent breadcrumbs={routes} />
}

const routes: BreadcrumbRoute[] = [
    {
        route: "/additions",
        parts: [{ title: "Zusätze" }]
    },
    {
        route: "/additions/addition/overview",
        parts: [
            { title: "Zusätze", url: "/additions" },
            { title: "Übersicht" }]
    },
    {
        route: "/additions/addition/create",
        parts: [
            { title: "Zusätze", url: "/additions" },
            { title: "Übersicht", url: "/additions/addition/overview" },
            { title: "Zusatz Erstellen" }
        ]
    },
    {
        route: "/additions/addition/edit/:additionid",
        parts: [
            { title: "Zusätze", url: "/additions" },
            { title: "Übersicht", url: "/additions/addition/overview" },
            { title: "Zusatz Editieren" }
        ]
    },
    {
        route: "/additions/additioncategory/overview",
        parts: [
            { title: "Zusatzkategorien", url: "/additions" },
            { title: "Übersicht" }]
    },
    {
        route: "/additions/additioncategory/create",
        parts: [
            { title: "Zusätze", url: "/additions" },
            { title: "Übersicht", url: "/additions/additioncategory/overview" },
            { title: "Zusatzkategorie Erstellen" }
        ]
    },
    {
        route: "/additions/production-instruction/overview",
        parts: [
            { title: "Produktionsanweisungen", url: "/additions" },
            { title: "Übersicht" }
        ]
    },
    {
        route: "/additions/production-instruction/create",
        parts: [
            { title: "Produktionsanweisungen", url: "/additions" },
            { title: "Übersicht", url: "/additions/production-instruction/overview" },
            { title: "Produktionsanweisung Erstellen" }
        ]
    },
    {
        route: "/additions/production-instruction/edit/:id",
        parts: [
            { title: "Produktionsanweisungen", url: "/additions" },
            { title: "Übersicht", url: "/additions/production-instruction/overview" },
            { title: "Produktionsanweisung Editieren" }
        ]
    },
    {
        route: "/additions/additionposition",
        parts: [
            { title: "Zusatzpositionen", url: "/additions" },
            { title: "Übersicht" }
        ]
    },
    {
        route: "/additions/additionposition/create",
        parts: [
            { title: "Zusätze", url: "/additions" },
            { title: "Zusatzpositionen", url: "/additions/additionposition" },
            { title: "Zusatzposition erstellen" }
        ]
    },
    {
        route: "/additions/additionposition/edit/:additionid",
        parts: [
            { title: "Zusätze", url: "/additions" },
            { title: "Zusatzpositionen", url: "/additions/additionposition" },
            { title: "Zusatzposition Editieren" }
        ]
    },
    {
        route: "/additions/positioncategory/overview",
        parts: [
            { title: "Positionskategorie", url: "/additions" },
            { title: "Übersicht" }
        ]
    },
    {
        route: "/additions/positioncategory/create",
        parts: [
            { title: "Zusätze", url: "/additions" },
            { title: "Positionskategorien", url: "/additions/positioncategory/overview" },
            { title: "Positionskategorie erstellen" }
        ]
    },
    {
        route: "/additions/positioncategory/edit/:positionCategoryId",
        parts: [
            { title: "Zusätze", url: "/additions" },
            { title: "Positionskategorien", url: "/additions/positioncategory/overview" },
            { title: "Positionskategorie editieren" }
        ]
    },
    {
        route: "/additions/positioncategory/positioncategorydetails/:positionCategoryId",
        parts: [
            { title: "Zusätze", url: "/additions" },
            { title: "Positionskategorien", url: "/additions/positioncategory/overview" },
            { title: "Positionskategorie Details" }
        ]
    },
    {
        route: "/additions/characteristictype/overview",
        parts: [
            { title: "Zusätze", url: "/additions" },
            { title: "Übersicht" }]
    },
    {
        route: "/additions/characteristictype/create",
        parts: [
            { title: "Zusätze", url: "/additions" },
            { title: "Ausprägungstypen", url: "/additions/characteristictype/overview" },
            { title: "Ausprägungstyp erstellen" }
        ]
    },
    {
        route: "/additions/characteristictype/edit/:characteristictypeId",
        parts: [
            { title: "Zusätze", url: "/additions" },
            { title: "Ausprägungstypen", url: "/additions/characteristictype/overview" },
            { title: "Ausprägungstyp editieren" }
        ]
    },
    {
        route: "/additions/characteristiccategory/overview",
        parts: [
            { title: "Zusätze", url: "/additions" },
            { title: "Übersicht" }
        ]
    },
    {
        route: "/additions/characteristiccategory/create",
        parts: [
            { title: "Zusätze", url: "/additions" },
            { title: "Ausprägungskategorien", url: "/additions/characteristiccategory/overview" },
            { title: "Ausprägungskategorie erstellen" }
        ]
    },
    {
        route: "/additions/characteristiccategory/edit/:characteristiccategoryId",
        parts: [
            { title: "Zusätze", url: "/additions" },
            { title: "Ausprägungskategorien", url: "/additions/characteristiccategory/overview" },
            { title: "Ausprägungskategorie editieren" }
        ]
    }
]