
import { createAsyncThunk } from '@reduxjs/toolkit';
import AdditionPositionCategoryService from 'services/position_category.service';
import { handleResponseErrors } from '../../../../shared/networking/error_handling';

export const getPositionCategory = createAsyncThunk(
    'positionCategory/itemDetails/getPositionCategory',
    async (positionCategoryId: string, _) => {
        const response = await AdditionPositionCategoryService.getPositionCategory(positionCategoryId)
        handleResponseErrors(response, "Positionskategorie");
        return response;
    }
)

export const goBackToOverview = createAsyncThunk(
    'positionCategory/itemDetails/goBackToOverview',
    async (_) => { }
)