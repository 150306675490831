import { EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";
import { IAdjustMaximumRangeConfiguration, IIndexedMasspoint, IIndexedNumber, IIndexedSearchText } from "../policy_adjust_maximum_range_create/redux/policy_adjust_maximum_range_create.model";
import { BoxItem } from 'check/policy/components/policy_box_item.style';
import { Grid, IconButton } from "@mui/material";
import { DropdownComponent } from "shared/shared";
import { SelectionListComponent } from "shared/components/selectionComponent/selectionList.component";
import { DecimalInputComponent } from "shared/components/input/numberInput/decimal_input.component";
import DeleteIcon from '@mui/icons-material/Delete';
import { IIndexedIds } from "check/policy/policy_adjust_ranges_by_masspoint/components/policy_adjust_ranges_by_masspoint_configuration.model";

interface PolicyAdjustMaximumRangeConfigurationComponentProps {
    configuration: IAdjustMaximumRangeConfiguration;
    configurationIndex: number
    deleteConfiguration: (index: number) => {};
    filterSelectedArticleTypes: (value: IIndexedSearchText) => {};
    filterUnselectedArticleTypes: (value: IIndexedSearchText) => {};
    selectArticleTypes: (value: IIndexedIds) => {};
    unSelectArticleTypes: (value: IIndexedIds) => {};
    selectMasspoint1: (value: IIndexedMasspoint) => void;
    selectMasspoint2: (value: IIndexedMasspoint) => void;
    changeMultiplyValue: (value: IIndexedNumber) => void;
}

export const PolicyAdjustMaximumRangeConfigurationComponent: React.FC<PolicyAdjustMaximumRangeConfigurationComponentProps> = ({
    configuration,
    configurationIndex,
    deleteConfiguration,
    filterSelectedArticleTypes,
    filterUnselectedArticleTypes,
    selectArticleTypes,
    unSelectArticleTypes,
    selectMasspoint1,
    selectMasspoint2,
    changeMultiplyValue,
}) => {
    return (
        <BoxItem>
            <Grid container item md={12}
                direction="column"
                spacing={3}>
                <Grid item
                    container
                    direction="row"
                    alignItems="stretch"
                    justifyContent="space-between"
                    spacing={3}>
                    <Grid item
                        md={9}>
                        <EditorSectionHeaderComponent style={{ marginTop: "0px" }}>{`Konfiguration ${configurationIndex + 1}`}</EditorSectionHeaderComponent>
                    </Grid>
                    <Grid item
                        container
                        md={3}
                        justifyContent='flex-end'>
                        <IconButton
                            style={{ outline: "none" }}
                            onClick={() => deleteConfiguration(configurationIndex)}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                    <Grid container item md={12} spacing={3}
                        direction="row">
                        <Grid item>
                            <DropdownComponent
                                name="Maßpunkt"
                                data={configuration.allMasspoints}
                                minWidth="350px"
                                isLoading={false}
                                isRequired={true}
                                onSelect={(selectedItem) => selectMasspoint1({ index: configurationIndex, masspoint: selectedItem },)}
                                displayedProperties={["displayName"]}
                                selectedValue={configuration.selectedMasspoint1}
                            />
                        </Grid>
                        <Grid item>
                            <DropdownComponent
                                name="Maßpunkt"
                                data={configuration.allMasspoints}
                                minWidth="350px"
                                isLoading={false}
                                isRequired={true}
                                onSelect={(selectedItem) => selectMasspoint2({ index: configurationIndex, masspoint: selectedItem },)}
                                displayedProperties={["displayName"]}
                                selectedValue={configuration.selectedMasspoint2}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item md={12} direction="row">
                        <Grid item>
                            <DecimalInputComponent
                                name="Faktor der Differenz"
                                width={350}
                                fixedPositions={1}
                                placeholder="0 bis 999"
                                minValue={0}
                                maxValue={999}
                                isRequired={true}
                                onChange={(value) => changeMultiplyValue({ index: configurationIndex, value: value })}
                                value={configuration.multiplyValue}
                            />
                        </Grid>
                        <Grid item>
                            <div>{`(${configuration.selectedMasspoint1 ? configuration.selectedMasspoint1.name : "<MP1>"} - ${configuration.selectedMasspoint2 ? configuration.selectedMasspoint2.name : "<MP2>"}) * ${configuration.multiplyValue}`}</div>
                        </Grid>
                    </Grid>
                    <Grid item md={12}>
                        <SelectionListComponent
                            title={"Zu beachtende Artikelarten"}
                            entityName={"Artikelarten"}
                            onFilterSelected={(searchText) => { filterSelectedArticleTypes({ index: configurationIndex, searchText: searchText }) }}
                            onFilterUnselected={(searchText) => { filterUnselectedArticleTypes({ index: configurationIndex, searchText: searchText }) }}
                            selectionList={configuration.articleTypes}
                            selectEntities={(ids) => { selectArticleTypes({ index: configurationIndex, ids: ids }) }}
                            unselectEntities={(ids) => { unSelectArticleTypes({ index: configurationIndex, ids: ids }) }}
                        />
                    </Grid>

                </Grid>
            </Grid>
        </BoxItem >
    )
}