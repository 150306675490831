import styled from "@emotion/styled";
import theme from "../../style/theme";

export const ListButtonComponent = styled.button`
  background:none;
  border-radius: 0;
  width: 100%;
  cursor: ${(props) => props.disabled ? "auto" : "pointer"};
  border: 1px dashed ${(props) => props.disabled ? theme.colors.inactive : theme.colors.text};
  color: ${(props) => props.disabled ? theme.colors.inactive : theme.colors.text};
  font-size: 0.9rem;
  text-align: center;
  vertical-align: center;
  padding: 10px 20px 10px 20px;

  &:focus,
  &:hover,
  &:active {
    border-radius: 0;
    border: 1px solid ${(props) => props.disabled ? theme.colors.inactive : theme.colors.active};
    outline-color: rgb(72, 88, 106);
    outline-offset: 0px;
    outline-style: none;
    outline-width: 0px;
  }
`;
