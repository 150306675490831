import { createAsyncThunk } from '@reduxjs/toolkit';
import HttpError from 'shared/networking/http_error';
import { RootState } from '../../app/redux_store';
import PlausibilityCheckService from '../../services/check.service';
import FormulaRuleService from '../../services/formula_rules.service';
import MassPointService from '../../services/masspoint.service';
import { handleResponseErrors } from '../../shared/networking/error_handling';
import { toFormulaRuleContainerCreate, validatePlaceholder, validateConstantPlaceholder } from './rules_formula.reducer';

export const fetchAllData = createAsyncThunk(
    'rules_formula/fetchAllData',
    async (checkId: number, thunkApi) => {
        await Promise.all([
            thunkApi.dispatch(fetchRules(checkId)),
            thunkApi.dispatch(fetchCheck(checkId)),
            thunkApi.dispatch(fetchAllMassPointCriterias())
        ]);

        await thunkApi.dispatch(fetchMassPointCriterias());
    }
)

export const fetchAllMassPointCriterias = createAsyncThunk(
    'rules_formula/fetchAllMassPointCriterias',
    async (_, { getState }) => {
        const response = await MassPointService.getMassPoints()
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)

export const fetchMassPointCriterias = createAsyncThunk(
    'rules_formula/fetchMassPointCriterias',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const bodyAreaId = state.rules_formula.loadedData.check.bodyArea?.id;
        const response = await MassPointService.getMassPointsForBodyArea(bodyAreaId)
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)

export const fetchCheck = createAsyncThunk(
    'rules_formula/fetchCheck',
    async (checkId: number, thunkApi) => {
        const response = await PlausibilityCheckService.getCheck(checkId);
        handleResponseErrors(response, "Prüfung");
        return response;
    }
)

export const fetchRules = createAsyncThunk(
    'rules_formula/fetchRules',
    async (checkId: number, thunkApi) => {
        const response = await FormulaRuleService.getFormulaRules(checkId)
        handleResponseErrors(response, "Regeln");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'rules_formula/cancelSave',
    async (_) => { }
)

export const saveRules = createAsyncThunk(
    'rules_formula/saveRules',
    async (_, { getState, dispatch }) => {
        const state = getState() as RootState;
        const checkId = state.rules_formula.loadedData.check.id;
        const validPlaceholder = validatePlaceholder(state.rules_formula);
        if (!validPlaceholder) {
            throw new HttpError("Nicht allen Platzhaltern ist ein Masspunkt zugewiesen", "409");
        }
        const validConstantPlaceholder = validateConstantPlaceholder(state.rules_formula);
        if (!validConstantPlaceholder) {
            throw new HttpError("Nicht allen Konstanten ist ein Wert zugewiesen", "409");
        }
        const rulesWithoutLastOne = state.rules_formula.actualData.rules.slice(0, -1);
        const response = await FormulaRuleService.saveFormulaRules(toFormulaRuleContainerCreate(checkId, rulesWithoutLastOne));

        if (response.isConflict()) {
            throw new HttpError("Kombination Typ & Maßpunktkriterium kommt mehrfach vor", response.getErrorCode());
        }

        handleResponseErrors(response, "Prüfungsregeln");
        return response;
    }
)