import { Grid } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { OverviewDatagrid } from "shared/components/datagrid/overview_datagrid.component";
import { DialogComponent } from "shared/components/dialogs/dialog.component";
import SearchFieldComponent from "shared/components/search/searchfield.component";
import { LoadingIndicatorComponent, FlexBox, Left, Right, PrimaryButtonComponent } from "shared/shared";
import { getGridColumns, sortByQualityModel } from "./serial_sizes_overview.helper";
import { resetState, setSearchFilter, setGridFilters, confirmDeleteSerialSizeCompleted, cancelDeleteSerialSize, deleteSerialSize } from "./redux/serial_sizes_overview.slice";
import { fetchSerialSizesOverviews, confirmDeleteSerialSize } from "./redux/serial_sizes_overview.thunks";
import AddIcon from "@mui/icons-material/Add";
import * as style from "./serial_sizes_overview.style";
import PreviewIcon from '@mui/icons-material/Preview';

export const SerialSizesOverviewComponent = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const state = useAppSelector((state) => state.serial_size_overview);

  useEffect(() => {
    dispatch(fetchSerialSizesOverviews());
    return () => {
      dispatch(resetState());
    }
  }, [dispatch]);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  if (state.command.confirmDeleteSerialSize.status === "error") {
    enqueueSnackbar(state.command.confirmDeleteSerialSize.message, { variant: "warning" });
    dispatch(confirmDeleteSerialSizeCompleted());
  }

  if (state.command.confirmDeleteSerialSize.status === "success") {
    enqueueSnackbar("Seriengröße entfernt", { variant: "success" });
    dispatch(confirmDeleteSerialSizeCompleted())
  }

  if (state.query.fetchSerialSizesOverviews.status === "error") {
    return <div>Fehler beim Laden der Daten</div>;
  }

  if (state.query.fetchSerialSizesOverviews.status !== "success") {
    return <LoadingIndicatorComponent />;
  }

  const isLoading = state.command.confirmDeleteSerialSize.status === "pending";
  const serialSizesAvailable = state.loadedData.serialSizes.length > 0;

  return (
    <div>
      {
        state.actualData.showDeleteSerialSizeDialog &&
        <DialogComponent
          title={"Seriengröße löschen?"}
          isOpen={true}
          options={[
            { title: "Abbrechen", handleOption: () => { dispatch(cancelDeleteSerialSize()) } },
            { title: "Löschen", handleOption: () => { dispatch(confirmDeleteSerialSize()) } }
          ]}
          onClose={() => { }}>
          <Grid container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}>
            <Grid item>
              Möchten Sie die Seriengröße löschen?
            </Grid>
            <Grid item>
              Alle mit der Seriengröße verknüpften Elemente werden ebenfalls gelöscht!
            </Grid>
          </Grid>
        </DialogComponent>
      }

      <FlexBox>
        {
          serialSizesAvailable &&
          <Left>
            <SearchFieldComponent onSearch={(filterText) => dispatch(setSearchFilter(filterText))} placeholder="In Seriengrößen suchen ..." />
          </Left>
        }
        <Right>
          <PrimaryButtonComponent style={{ "marginRight": "10px" }} onClick={() => navigate("/masspoints/serialsize/preview")}>
            <PreviewIcon /> Seriengrößen Vorschau
          </PrimaryButtonComponent>
          <PrimaryButtonComponent onClick={() => navigate("/masspoints/serialsize/create")}>
            <AddIcon /> Neue Seriengröße erstellen
          </PrimaryButtonComponent>
        </Right>
      </FlexBox>
      <style.TableContainer>
        {
          !serialSizesAvailable &&
          <div>Keine Seriengrößen verfügbar</div>
        }
        {
          serialSizesAvailable &&
          <OverviewDatagrid
            isLoading={isLoading}
            columns={getGridColumns(
              (serialSize) => navigate(`/masspoints/serialsize/edit/${serialSize.id}`),
              (serialSize) => dispatch(deleteSerialSize(serialSize))
            )}
            rows={state.actualData.serialSizes}
            loadedRowsCount={state.loadedData.serialSizes.length}
            sortModel={sortByQualityModel}
            onFilterModelChange={(newFilterModel) => dispatch(setGridFilters(newFilterModel))}
            onDoubleClickRow={(serialSize) => navigate(`/masspoints/serialsize/edit/${serialSize.id}`)}
          />
        }
      </style.TableContainer>
    </div>
  );
};
