import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MassmaskHistoryOverviewsState } from './massmask_history.model';
import { fetchMassmaskHistoryOverviews } from './massmask_history.thunks';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import { filterMassmaskHistoryOverviews } from './massmask_history.reducer';

const initialState: MassmaskHistoryOverviewsState = {
    loadedData: {
        massmaskHistoryOverviews: [],
    },  
    actualData: {
        massmaskHistoryOverviews: [],
        searchFilter: "",
        gridFilters: {items: []}
    },
    query: {
        fetchMassmaskHistoryOverviews: { status: "idle", canExecute: true },
    },
}

export const getMassmaskHistoryOverviewSlice = createSlice({
    name: 'massmask_history/massmask_history_overview',
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.loadedData = initialState.loadedData;
            state.query = initialState.query;
        },
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.actualData.searchFilter = action.payload;
            filterMassmaskHistoryOverviews(state);
        },  
        setGridFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.gridFilters = action.payload;
            filterMassmaskHistoryOverviews(state);
        },
    }, extraReducers: (builder) => {

        // fetch MassmaskHistoryOverviews
        builder.addCase(fetchMassmaskHistoryOverviews.pending, (state, action) => {
            state.query.fetchMassmaskHistoryOverviews.status = "pending"
            state.query.fetchMassmaskHistoryOverviews.canExecute = false;
        }).addCase(fetchMassmaskHistoryOverviews.rejected, (state, action) => {
            state.query.fetchMassmaskHistoryOverviews.status = "error"
            state.query.fetchMassmaskHistoryOverviews.message = action.error.message;
            state.query.fetchMassmaskHistoryOverviews.canExecute = true;
        }).addCase(fetchMassmaskHistoryOverviews.fulfilled, (state, action) => {
            state.query.fetchMassmaskHistoryOverviews.status = "success"
            state.query.fetchMassmaskHistoryOverviews.message = undefined;
            state.query.fetchMassmaskHistoryOverviews.canExecute = true;
            state.loadedData.massmaskHistoryOverviews = action.payload.getData();
            state.loadedData.massmaskHistoryOverviews = action.payload.getData();
            filterMassmaskHistoryOverviews(state);
        })
    }
})

export const {
    resetState,
    setSearchFilter,
    setGridFilters,
} = getMassmaskHistoryOverviewSlice.actions

export default getMassmaskHistoryOverviewSlice.reducer