import React from "react";
import { SerialSizeTableEntryContainer, SerialSizeTableEntryItem } from "./serial_sizes.style";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from "@mui/material";
import { IAttributeViewModel } from "./serial_size_editor.model";

export interface AttributeSummaryDetailComponentProps {
    attributeSummary: IAttributeViewModel;
    disabledAttribute: boolean;
    editAttribute: (size: IAttributeViewModel) => void;
    deleteAttribute: (attribute: IAttributeViewModel) => void;
}

export const AttributeSummaryDetailComponent: React.FC<AttributeSummaryDetailComponentProps> = ({ 
    attributeSummary,
    disabledAttribute,
    editAttribute,
    deleteAttribute,
}) => {

    const masspointNames = attributeSummary.masspoints.map(mp => mp.masspoint.displayName).join(", ");
    return (
        <>
            <SerialSizeTableEntryContainer>
                <SerialSizeTableEntryItem width="42px" backgroundColor="white"/>
                <SerialSizeTableEntryItem width="150px" backgroundColor="white">
                    {attributeSummary.attribute.name}
                </SerialSizeTableEntryItem>
                <SerialSizeTableEntryItem flex={1} backgroundColor="white">
                    {masspointNames}
                </SerialSizeTableEntryItem>
                <SerialSizeTableEntryItem width="80px" backgroundColor="white">
                    <IconButton
                        disabled={disabledAttribute}
                        style={{ outline: "none" }}
                        onClick={() => editAttribute(attributeSummary)}>
                        <EditIcon />
                    </IconButton>
                </SerialSizeTableEntryItem>
                <SerialSizeTableEntryItem width="80px">
                    <IconButton
                        disabled={disabledAttribute}
                        style={{ outline: "none" }}
                        onClick={() => deleteAttribute(attributeSummary)}>
                        <DeleteIcon />
                    </IconButton>            
                </SerialSizeTableEntryItem>   
            </SerialSizeTableEntryContainer>    
        </>
    )
}