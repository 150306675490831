import { IAdditionCategoryOverview } from "models/addition_categories/addition_category_overview";
import { doesExist, isNullOrWhitespace } from "services/validation.service";
import { PolicyMandatoryCategoryUpdateState } from "./policy_mandatory_category_update.model";

export const updateCanSave = (state: PolicyMandatoryCategoryUpdateState) => {
    state.command.updatePolicy.canExecute =
        !isNullOrWhitespace(state.data.policy.name)
        && doesExist(state.data.selectedCategory)
        && isNotEqual(state);
}

const isNotEqual = (state: PolicyMandatoryCategoryUpdateState) => {
    return state.data.policy.name !== state.loadedData.policy.name 
        || state.data.policy.ignoreOnQuotation !== state.loadedData.policy.ignoreOnQuotation 
        || state.data.selectedCategory.id !== state.loadedData.policy.additionCategoryId
}