import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { TextBoxComponent } from "shared/shared";
import { IPositionCategoryActions } from "models/position_category/position_category_actions";
import { IPositionCategoryEditor } from "models/position_category/position_category_editor";
import { PrimaryButtonComponent } from "shared/shared";
import { FlexBox, Right } from "shared/style/alignment";
import AddIcon from "@mui/icons-material/Add";
import { getColumnVisibilityModel, getGridColumns, getGroupingModel, sortByBodyArea } from './position_category_editor.reducer';
import { OverviewDatagrid } from 'shared/components/datagrid/overview_datagrid.component';
import { EditorButtonComponent } from 'shared/components/editors/editor_button/editor_buttons.component';
import { EditorSectionHeaderComponent } from 'shared/components/editors/editor_header/editor_header.style';
import { EditorDescriptionComponent } from 'shared/components/editors/editor_description/editor_description.style';

interface PositionCategoryEditorComponentProps {
    editor: IPositionCategoryEditor,
    editorActions: IPositionCategoryActions,
    canSave: boolean,
}

export const PositionCategoryEditorComponent: React.FC<PositionCategoryEditorComponentProps> = ({ editor, editorActions, canSave }) => {

    const renderTable = () => {
        if (editor.positionCategoryItemSet.positionCategoryItems.length === 0) {
            return (
                <p>Es ist noch kein Positionskategorie-Item vorhanden</p>
            );
        }

        return (
            <OverviewDatagrid
                isLoading={false}
                columns={
                    getGridColumns(editorActions.editPositionCategoryItemByIndex, editorActions.deletePositionCategoryItemByIndex)
                }
                columnVisibilityModel={getColumnVisibilityModel(editor.sideSeamAllowed)}
                groupingModel={getGroupingModel()}
                rows={editor.positionCategoryItemSet.filteredPositionCategoryItems}
                loadedRowsCount={editor.positionCategoryItemSet.positionCategoryItems.length}
                sortModel={sortByBodyArea}
                onFilterModelChange={editorActions.setGridFilters}
                getRowId={(row) => row.sequenceId}
                onDoubleClickRow={params => editorActions.editPositionCategoryItemByIndex(params.row.sequenceId)}
            />
        );
    }

    return (
        <Grid container
            direction="column" spacing={3}>
            <Grid item xs={6}>
                <TextBoxComponent name="Name der Positionskategorie"
                    value={editor.name}
                    onChange={(value) => editorActions.updateName(value)}
                    isRequired={true}
                    isAutoFocus={true}
                    width={"50%"}
                />
            </Grid>
            <Grid item xs={6}>
                <EditorSectionHeaderComponent>Seitliche Naht</EditorSectionHeaderComponent>
                <EditorDescriptionComponent>Wenn seitliche Naht erlaubt ist, muss diese an den Items angepaßt werden.</EditorDescriptionComponent>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="sideseam-checkbox"
                            checked={editor.sideSeamAllowed}
                            inputProps={{ 'aria-label': 'controlled' }}
                            onChange={() => editorActions.toggleSideSeamAllowed()}
                        />
                    }
                    label="Seitliche Naht erlaubt"
                />
            </Grid>
            <Grid item xs={12}>
                <h3>Positionskategorie-Item</h3>
                <hr></hr>
                <FlexBox>
                    <Right>
                        <PrimaryButtonComponent onClick={() => editorActions.createNewPositionCategoryItem()}>
                            <AddIcon /> Neues Positionskategorie-Item erstellen
                        </PrimaryButtonComponent>
                    </Right>
                </FlexBox>
            </Grid>
            <Grid item xs={12}>
                {renderTable()}
            </Grid>
            <EditorButtonComponent
                    canExecute={canSave}
                    save={() => editorActions.savePositionCategory()}
                    cancelSave={() => editorActions.cancelSavePositionCategory()}/>
        </Grid>
    );
}