import React from "react";
import { IParsedMassPoint } from "models/masspoints/parsed_masspoint";
import { List, ListItem } from "@mui/material";
import { LoadingIndicatorComponent } from "shared/shared";
import { MasspointBodySideEnumValues } from "models/masspoints/enums/body_side.enum";
import { MasspointTypeEnumValues } from "models/masspoints/enums/masspoint_type.enum";
import * as style from "./missing_masspoints.style";
import { fetchBodyAreas } from './redux/missing_masspoints.thunks';
import { useAppDispatch, useAppSelector } from 'app/hook';
import { AppDispatch } from 'app/redux_store';
import { useEffect } from "react";

interface MissingMasspointsComponentProps {
    masspoints: Array<IParsedMassPoint>;
    masspointsNotInNewImage: Array<IParsedMassPoint>;
}

export const MissingMasspointsComponent: React.FC<MissingMasspointsComponentProps> = ({ masspoints, masspointsNotInNewImage }) => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.missing_masspoints_dialog);
    useEffect(() => {
        dispatch(fetchBodyAreas());
    }, [dispatch]);

    const isLoading = state.query.fetchAvailableBodyAreas.status === "pending";

    if (isLoading) {
        return <LoadingIndicatorComponent />
    }

    return (
        <>
            {masspointsNotInNewImage != null && masspointsNotInNewImage.length > 0 &&
                <div>
                    Im alten Maßbild vorhandene Maßpunkte müssen auch im neuen Maßbild enthalten sein, folgende fehlen:
                    <List>
                        {masspointsNotInNewImage.map((masspoint, index) =>
                            <ListItem key={index}>
                                <style.Column1>
                                    {masspoint.name}
                                </style.Column1>
                                <style.Column2>
                                    {state.data.bodyareas.find(entry => entry.id === masspoint.bodyAreaId)?.name}
                                </style.Column2>
                                <style.Column3>
                                    {MasspointTypeEnumValues.find(entry => entry.value === masspoint.massPointType)?.text}
                                </style.Column3>
                                <style.Column4>
                                    {MasspointBodySideEnumValues.find(entry => entry.value === masspoint.bodySide)?.text}
                                </style.Column4>
                                <style.Column5>
                                    <style.Code>{`mp_${masspoint.name}_${masspoint.bodyAreaId}_${masspoint.massPointType}_${masspoint.bodySide}`}</style.Code>
                                </style.Column5>
                            </ListItem>
                        )}
                    </List>
                </div>
            }

            {
                masspoints != null && masspoints.length > 0 &&
                <div>
                    Folgende Maßpunkte wurden im System nicht gefunden, sind jedoch in der Bilddatei vorhanden:
                    <List>
                        {masspoints.map((masspoint, index) =>
                            <ListItem key={index}>
                                <style.Column1>
                                    {masspoint.name}
                                </style.Column1>
                                <style.Column2>
                                    {state.data.bodyareas.find(entry => entry.id === masspoint.bodyAreaId)?.name}
                                </style.Column2>
                                <style.Column3>
                                    {MasspointTypeEnumValues.find(entry => entry.value === masspoint.massPointType)?.text}
                                </style.Column3>
                                <style.Column4>
                                    {MasspointBodySideEnumValues.find(entry => entry.value === masspoint.bodySide)?.text}
                                </style.Column4>
                                <style.Column5>
                                    <style.Code>{`mp_${masspoint.name}_${masspoint.bodyAreaId}_${masspoint.massPointType}_${masspoint.bodySide}`}</style.Code>
                                </style.Column5>
                            </ListItem>
                        )}
                    </List>
                </div >
            }
        </>
    );
};