import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseErrors } from "shared/networking/error_handling";
import AdditionService from 'services/addition.service';
import PolicyServiceAPI from 'services/policy.service';
import { PolicyTypeEnum } from "models/policies/policy_type.enum";
import BaseDataService from 'services/base_data.service';
import { RootState } from "app/redux_store";

export const getAdditions = createAsyncThunk(
    'policy/create_policy_default_addition_by_articletype/getAdditions',
    async (_) => {
        const response = await AdditionService.getAvailableAdditionsByPolicyTypeOverview(PolicyTypeEnum.DefaultAdditionByArticleType, undefined);
        handleResponseErrors(response, "Zusätze");
        return response;
    }
)

export const getBaseData = createAsyncThunk(
    'policy/create_policy_default_addition_by_articletype/getBaseData',
    async (_) => {
        const response = await BaseDataService.getBaseData()
        handleResponseErrors(response, "Basisdaten");
        return response;
    }
)

export const savePolicy = createAsyncThunk(
    'policy/create_policy_default_addition_by_articletype/savepolicy',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.policy_default_addition_by_articletype_create.data;
        const response = await PolicyServiceAPI.createDefaultAddition({
            name: data.policy.name,
            ignoreOnQuotation: data.policy.ignoreOnQuotation,
            additionId: data.selectedAddition.id,
            articleTypes: data.articleTypes.selectedList.allItems.map(x => {
                return x.id
            })
        });
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'policy/create_policy_default_addition_by_articletype/cancelSave',
    async (_) => { }
)

export const initData = createAsyncThunk(
    'policy/create_policy_default_addition_by_articletype/initData',
    async (_, { dispatch }) => {
        await Promise.all([
            dispatch(getAdditions()),
            dispatch(getBaseData()),
        ]);
    }
)