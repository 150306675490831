
import styled from "@emotion/styled";
import colors from 'shared/style/colors';

export const Input = styled.input`
    border: 1px solid ${colors.mediBlue};
    width:80px;
    height: 30px;
    z-index: 99;
    text-align: left;
    
    :focus {
        border: 1px solid ${colors.mediBlue};
        border-radius: 0px;
        outline: none;
    }
`