import React from "react";
import { BreadcrumbsComponent } from '../shared/breadcrumbs/components/breadcrumbs';
import { BreadcrumbRoute } from "../shared/breadcrumbs/models/breadcrumb_route";

export const MassmasksBreadcrumbs = () => {
    return <BreadcrumbsComponent breadcrumbs={routes} />
}

const routes: BreadcrumbRoute[] = [
    {
        route: "/massmasks",
        parts: [{ title: "Maßmasken" }]
    },
    {
        route: "/massmasks/massmask/overview",
        parts: [
            { title: "Maßmasken", url: "/massmasks" },
            { title: "Übersicht" }]
    },
    {
        route: "/massmasks/massmask/create",
        parts: [
            { title: "Maßmasken", url: "/massmasks" },
            { title: "Übersicht", url: "/massmasks/massmask/overview" },
            { title: "Maßmaske Erstellen" }
        ]
    },
    {
        route: "/massmasks/massmask/edit/:maskmaskId",
        parts: [
            { title: "Maßmasken", url: "/massmasks" },
            { title: "Übersicht", url: "/massmasks/massmask/overview" },
            { title: "Maßmaske bearbeiten" }
        ]
    },
    {
        route: "/massmasks/massmask/:massmaskid/massdefinition",
        parts: [
            { title: "Maßmasken", url: "/massmasks" },
            { title: "Übersicht", url: "/massmasks/massmask/overview" },
            { title: "Maßdefinitionen" }
        ]
    },
    {
        route: "/massmasks/massmask/:massmaskid/massdefinition/massimages",
        parts: [
            { title: "Maßmasken", url: "/massmasks" },
            { title: "Übersicht", url: "/massmasks/massmask/overview" },
            { title: "Maßdefinitionen", url: "/massmasks/massmask/:massmaskid/massdefinition" },
            { title: "Maßbilder" }
        ]
    },
    {
        route: "/massmasks/massmask/:massmaskid/massdefinition/:massdefinitionId/masspoints",
        parts: [
            { title: "Maßmasken", url: "/massmasks" },
            { title: "Übersicht", url: "/massmasks/massmask/overview" },
            { title: "Maßdefinitionen", url: "/massmasks/massmask/:massmaskid/massdefinition" },
            { title: "Maßpunkte" }
        ]
    },
    {
        route: "/massmasks/footoptions/overview",
        parts: [
            { title: "Maßmasken", url: "/massmasks" },
            { title: "Fußoptionen" }
        ]
    },
    {
        route: "/massmasks/footoptions/create",
        parts: [
            { title: "Maßmasken", url: "/massmasks" },
            { title: "Fußoptionen", url: "/massmasks/footoptions/overview" },
            { title: "Fußoption erstellen" }
        ]
    },
    {
        route: "/massmasks/footoptions/edit/:footoptionid",
        parts: [
            { title: "Maßmasken", url: "/massmasks" },
            { title: "Fußoptionen", url: "/massmasks/footoptions/overview" },
            { title: "Fußoption editieren" }
        ]
    },
    {
        route: "/massmasks/onelegs/overview",
        parts: [
            { title: "Maßmasken", url: "/massmasks" },
            { title: "Einbeinhosen" }
        ]
    },
    {
        route: "/massmasks/onelegs/create",
        parts: [
            { title: "Maßmasken", url: "/massmasks" },
            { title: "Einbeinhosen", url: "/massmasks/onelegs/overview" },
            { title: "Einbeinhose erstellen" }
        ]
    },
    {
        route: "/massmasks/onelegs/edit/:onelegid",
        parts: [
            { title: "Maßmasken", url: "/massmasks" },
            { title: "Einbeinhosen", url: "/massmasks/onelegs/overview" },
            { title: "Einbeinhose editieren" }
        ]
    },
    {
        route: "/massmasks/bodyinformations/overview",
        parts: [
            { title: "Maßmasken", url: "/massmasks" },
            { title: "Zusätzliche Angaben" }
        ]
    },
    {
        route: "/massmasks/bodyinformations/create",
        parts: [
            { title: "Maßmasken", url: "/massmasks" },
            { title: "Zusätzliche Angaben", url: "/massmasks/bodyinformations/overview" },
            { title: "Zusätzliche Angaben erstellen" }
        ]
    },
    {
        route: "/massmasks/bodyinformations/edit/:bodyinformationid",
        parts: [
            { title: "Maßmasken", url: "/massmasks" },
            { title: "Zusätzliche Angaben", url: "/massmasks/bodyinformations/overview" },
            { title: "Zusätzliche Angaben editieren" }
        ]
    },
]