import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/redux_store';
import BaseDataService from '../../../services/base_data.service';
import { FootOptionService } from '../../../services/footoption.service';
import MassPointService from '../../../services/masspoint.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';

export const fetchAllData = createAsyncThunk(
    'massMasks/footOption/edit/fetchAllData',
    async (id: string, thunkApi) => {
        await Promise.all([
            thunkApi.dispatch(getFootTypes()),
            thunkApi.dispatch(getMainProductLines()),
            thunkApi.dispatch(getMassPoints()),           
        ]);
        await thunkApi.dispatch(getFootoption(id));
    }
)

export const getFootTypes = createAsyncThunk(
    'massMasks/footOption/edit/getFootTypes',
    async (_) => {
        const response = await BaseDataService.getFootTypes();
        handleResponseErrors(response, "Fußarten");
        return response;
    }
)

export const getMainProductLines = createAsyncThunk(
    'massMasks/footOption/edit/getMainProductLines',
    async (_) => {
        const response = await BaseDataService.getMainProductLines();
        handleResponseErrors(response, "Hauptproduktlinien");
        return response;
    }
)

export const getMassPoints = createAsyncThunk(
    'massMasks/footOption/edit/getMassPoints',
    async (_, { getState }) => {
        const response = await MassPointService.getMassPoints()
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)

export const getFootoption = createAsyncThunk(
    'massMasks/footOption/edit/getFootOption',
    async (id: string, { getState }) => {
        const response = await FootOptionService.getFootOption(id)
        handleResponseErrors(response, "Fußoption");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'massMasks/footOption/edit/cancelSave',
    async (_) => { }
)

export const updateFootOption = createAsyncThunk(
    'massMasks/footOption/edit/updateFootOption',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const selectedData = state.foot_options_edit.actualData;

        const response = await FootOptionService.updateFootOption({

            id: selectedData.footOptionId,
            footOptionMassPoints: selectedData.massPointCriteria.selected.allItems.map(item => {
                return {
                    name: item.massPointName,
                    type: item.massPointType,
                    bodyAreaId: item.bodyArea.id,
                };
            })
        });

        handleResponseErrors(response, "Fußoption");
        return response;
    }
)