import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useAppDispatch, useAppSelector } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { MassmaskStepperComponent, MassmaskWizardStepEnum } from "massmasks/components/massmask.stepper.component";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { PrimaryButtonComponent } from "shared/components/buttons/primary_button.component";
import { LoadingIndicatorComponent } from "shared/components/loading_indicator/loading_indicator.component";
import { LoadingOverlayComponent, LoadingOverlayContainer } from "shared/components/loading_overlay/loading_overlay.component";
import { FlexBox, Right } from "shared/shared";
import { InteractiveMassimageComponent } from "./components/interactive_massimage.component";
import { activateMassPointsCompleted, resetState, toggleMassPointActive, toggleMassPointHighlight, updateMeasurementSheet } from "./redux/massmask_masspoints.slice";
import { activateMassPoints, cancelActivateMassPoints, getMassMask } from './redux/massmask_masspoints.thunks';
import * as style from "./massmask_masspoints.style";
import { MassmaskInformationComponent } from "massmasks/components/massmask.information.component";
import { useNavigate, useParams } from "react-router-dom";
import { MassDefinitionEditorComponent } from "./components/messdefinition_editor";
import { EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";

export const MassmaskMasspointsComponent = () => {
        const dispatch: AppDispatch = useAppDispatch();
        const state = useAppSelector((state) => state.massmask_masspoints);

        const navigate = useNavigate();
        const { enqueueSnackbar } = useSnackbar();
        const { massmaskId, massdefinitionId } = useParams();

        useEffect(() => {
            dispatch(resetState());
            dispatch(
                getMassMask({
                    massMaskId: Number(massmaskId),
                    massDefinitionId: Number(massdefinitionId)
                }));
        }, [dispatch, massmaskId, massdefinitionId]);

        const isLoading = state.query.getMassMask.status === "pending"
            || state.query.getMassMask.status === "idle";

        const isSaving = state.command.activateMassPoints.status === "pending";

        if (state.command.activateMassPoints.status === "error") {
            enqueueSnackbar(state.command.activateMassPoints.message, { variant: "error" });
            dispatch(resetState());
        }

        if (state.command.activateMassPoints.status === "success") {
            enqueueSnackbar("Maßpunkte gespeichert", { variant: "success" });
            navigate(`/massmasks/massmask/${massmaskId}/massdefinition`);
            dispatch(activateMassPointsCompleted())
        }

        if (state.command.cancelActivateMassPoints.status === "pending") {
            navigate(`/massmasks/massmask/${massmaskId}/massdefinition`);
        }

        return (<>
            <MassmaskStepperComponent currentStep={MassmaskWizardStepEnum.MassPoints} />
            
            {
                (state.loadedData.massmask != null)
                ? <MassmaskInformationComponent massMask={state.loadedData.massmask}/> :<></>
            }

            <style.OverviewArea>
                

                {isLoading
                    ? <LoadingIndicatorComponent />
                    :
                    <LoadingOverlayContainer>
                        {isSaving ? <LoadingOverlayComponent /> : <></>}
                        <EditorSectionHeaderComponent>Maßdefinition editieren</EditorSectionHeaderComponent>
                        <MassDefinitionEditorComponent
                            measurementSheet={state.actualData.measurementSheet}
                            updateMeasurementSheet={(value) => dispatch(updateMeasurementSheet(value))}
                            />

                        <EditorSectionHeaderComponent>Maßpunkte zuweisen</EditorSectionHeaderComponent>
                        <InteractiveMassimageComponent
                            svgXML={state.actualData.massImageContent}
                            rightSideMasspoints={state.actualData.rightSideMassPoints}
                            leftSideMasspoints={state.actualData.leftSideMassPoints}
                            noSideMasspoints={state.actualData.noSideMassPoints}
                            toggleMasspointActiveState={(massPoint) => dispatch(toggleMassPointActive(massPoint))}
                            setMasspointHighlightState={(massPoint) => dispatch(toggleMassPointHighlight(massPoint))}
                        />
                    </LoadingOverlayContainer>
                }

                <FlexBox>
                    <Right>
                        <style.ButtonMargin>
                            <PrimaryButtonComponent onClick={() => dispatch(cancelActivateMassPoints())}><ClearIcon /> Abbrechen</PrimaryButtonComponent>
                        </style.ButtonMargin>
                        <PrimaryButtonComponent
                            onClick={() => dispatch(activateMassPoints())}
                            disabled={!state.command.activateMassPoints.canExecute}
                        ><CheckIcon /> Speichern
                        </PrimaryButtonComponent>
                    </Right>
                </FlexBox>
            </style.OverviewArea>
        </>
        );
    }