import styled from "@emotion/styled";

export const Column1 = styled.div`
    width:40px;
`
export const Column2 = styled.div`
    width:80px;
`
export const Column3 = styled.div`
    margin-left:5px;
    width:80px;
`
export const Column4 = styled.div`
    margin-left:5px;
    width:100px;
`
export const Column5 = styled.div`
    margin-left:5px;
    width:100px;
`

export const Code = styled.span`
    font-family:'Courier New', Courier, monospace;
    font-weight: bold;
`;