import DoneAllIcon from '@mui/icons-material/DoneAll';
import colors from "shared/style/colors";
import styled from "@emotion/styled";

const ColorGreen = styled.span`
  color: ${colors.mediGreen};
`

export const AllIconComponent = () => {
  return <ColorGreen><DoneAllIcon /></ColorGreen>
}