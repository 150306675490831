import { ICharacteristicTypeOverview } from "models/characteristic_types/characteristic_type_overview";
import { CharcteristicTypeOverviewState } from "./characteristic_type_overview.model";
import { filterCharacterTypeValue } from "../characteristic_type_overview.helper";

export const filterCharacteristicTypes = (state: CharcteristicTypeOverviewState) => {
    const searchTextLower = state.actualData.searchFilter.toLocaleLowerCase().trim();
    let filteredItems = state.loadedData.characteristicTypes.filter(
        (characteristicType) =>{
            return (
                characteristicType.name.toLowerCase().includes(searchTextLower) ||
                characteristicType.shortKey.toLowerCase().includes(searchTextLower));
        }
    );

    state.actualData.gridFilters.items.forEach(fil => {
        filteredItems = filterCharacterTypeValue(fil, filteredItems);
    });
    
    state.actualData.characteristicTypes = filteredItems.sort(sortCharacteristicTypes);

}

export const sortCharacteristicTypes = (mp1: ICharacteristicTypeOverview, mp2: ICharacteristicTypeOverview) => {
    return mp1.name.localeCompare(mp2.name);
}