import { ThumbnailComponent } from "../../shared/components/buttons/thumbnail.component";
import { ThumbnailModel } from 'models/thumbnail.model';
import typesImage from './images/types.jpg';
import checksImage from './images/minmax.jpg';
import policiesImage from './images/policies.jpg';
import * as style from "./overview.style";

export const PlausibilityCheckOverviewComponent = () => {
    const thumbnails: ThumbnailModel[] = [
        new ThumbnailModel("Übersicht Prüfungen", checksImage, "/plausibilitycheck/check"),
        new ThumbnailModel("Prüfungstypen", typesImage, "/plausibilitycheck/type"),
        new ThumbnailModel("Regelwerke", policiesImage, "/plausibilitycheck/policy"),
    ];

    return (
        <style.ThumbnailWrapper>
            {thumbnails.map((thumb, i) => {
                return (<ThumbnailComponent key={i} thumbnail={thumb} />)
            })}
        </style.ThumbnailWrapper>
    );
};
