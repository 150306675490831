import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICheckTypeMessageEditModel, PlausibilityCheckTypeState } from './type_edit.model';
import { updateCanEditPlausibilityCheckType, toCheckTypeMessageEditModel } from './type_edit.reducer';
import { editPlausibilityCheckType, fetchPlausibilityCheckType, getCheckTypeMessages, initData } from './type_edit.thunks';

const initialState: PlausibilityCheckTypeState = {
    actualData: {
        plausibilityCheckType: null,
        checkTypeMessages: null
    },
    editedData:
    {
        plausibilityCheckType: null,
        selectedWarningMessage: null,
        selectedErrorMessage: null,
        warningMessages: [],
        errorMessages: [],
    },
    query: {
        fetchPlausibilityCheckType: { status: "idle", canExecute: true },
        getCheckTypeMessages: { status: "idle", canExecute: true },
        initData: { status: "idle", canExecute: true },
    },
    command: {
        editPlausibilityCheckType: { status: "idle", canExecute: false },
        cancel: { status: "idle", canExecute: false },
    },
}

export const editPlausibilityCheckTypeSlice = createSlice({
    name: 'plausibilitychecktype/type_edit',
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.editedData = initialState.editedData;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        updateName: (state, action: PayloadAction<string>) => {
            state.editedData.plausibilityCheckType.name = action.payload;
            updateCanEditPlausibilityCheckType(state);
        },
        editPlausibilityCheckTypeCompleted: (state) => {
            state.command.editPlausibilityCheckType.status = "idle"
        },
        updateWarningMessageKey: (state, action: PayloadAction<ICheckTypeMessageEditModel>) => {
            const selectedItem = action.payload;
            state.editedData.selectedWarningMessage = selectedItem;
            state.editedData.plausibilityCheckType.warningMessageKey = selectedItem.message?.key;
            updateCanEditPlausibilityCheckType(state);
        },
        updateErrorMessageKey: (state, action: PayloadAction<ICheckTypeMessageEditModel>) => {
            const selectedItem = action.payload;
            state.editedData.selectedErrorMessage = selectedItem;
            state.editedData.plausibilityCheckType.errorMessageKey = selectedItem.message?.key;
            updateCanEditPlausibilityCheckType(state);
        },
        toggleIsBestMatch: (state, action: PayloadAction<boolean>) => {
            state.editedData.plausibilityCheckType.isBestMatchEnabled = action.payload;
            updateCanEditPlausibilityCheckType(state);
        },
        cancelCheck: (state) => {
            state.command.cancel.status = "success";
        }
    }, extraReducers: (builder) => {

        // fetch PlausibiltyCheckType
        builder.addCase(fetchPlausibilityCheckType.pending, (state, action) => {
            state.query.fetchPlausibilityCheckType.status = "pending"
            state.query.fetchPlausibilityCheckType.canExecute = false;
        }).addCase(fetchPlausibilityCheckType.rejected, (state, action) => {
            state.query.fetchPlausibilityCheckType.status = "error"
            state.query.fetchPlausibilityCheckType.message = action.error.message;
            state.query.fetchPlausibilityCheckType.canExecute = true;
        }).addCase(fetchPlausibilityCheckType.fulfilled, (state, action) => {
            state.query.fetchPlausibilityCheckType.status = "success"
            state.query.fetchPlausibilityCheckType.message = undefined;
            state.query.fetchPlausibilityCheckType.canExecute = true;
            state.actualData.plausibilityCheckType = action.payload.getData();
            state.editedData.plausibilityCheckType = action.payload.getData();
            updateCanEditPlausibilityCheckType(state);

            // initData
        }).addCase(initData.pending, (state, action) => {
            state.query.initData.status = "pending"
            state.query.initData.canExecute = false;
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.canExecute = true;
            state.query.initData.message = action.error.message;
        }).addCase(initData.fulfilled, (state, action) => {
            state.query.initData.status = "success"
            state.query.initData.message = undefined;
            state.query.initData.canExecute = true;

            const messages = state.actualData.checkTypeMessages;
            const warningMessages = [
                {
                    displayValue: "Keine Auswahl",
                    message: null
                },
                ...messages.warningMessages.map(toCheckTypeMessageEditModel)];

            const errorMessages = [
                {
                    displayValue: "Keine Auswahl",
                    message: null
                },
                ...messages.errorMessages.map(toCheckTypeMessageEditModel)];

            state.editedData.warningMessages = warningMessages;
            state.editedData.errorMessages = errorMessages;

            const selectedErrorMessage = state.editedData.errorMessages.find(item =>
                (state.editedData.plausibilityCheckType.errorMessageKey == null && item.message == null) ||
                (state.editedData.plausibilityCheckType.errorMessageKey != null && item.message != null && item.message.key === state.editedData.plausibilityCheckType.errorMessageKey))
            state.editedData.selectedErrorMessage = selectedErrorMessage;
            const selectedWarningMessage = state.editedData.warningMessages.find(item =>
                (state.editedData.plausibilityCheckType.warningMessageKey == null && item.message == null) ||
                (state.editedData.plausibilityCheckType.warningMessageKey != null && item.message != null && item.message.key === state.editedData.plausibilityCheckType.warningMessageKey))
            state.editedData.selectedWarningMessage = selectedWarningMessage;
            updateCanEditPlausibilityCheckType(state);

            // edit plausibilitychecktype
        }).addCase(editPlausibilityCheckType.pending, (state, action) => {
            state.command.editPlausibilityCheckType.status = "pending"
            state.command.editPlausibilityCheckType.canExecute = false;
        }).addCase(editPlausibilityCheckType.rejected, (state, action) => {
            state.command.editPlausibilityCheckType.status = "error"
            state.command.editPlausibilityCheckType.canExecute = true;
            state.command.editPlausibilityCheckType.message = action.error.message;
        }).addCase(editPlausibilityCheckType.fulfilled, (state, action) => {
            state.command.editPlausibilityCheckType.status = "success"
            state.command.editPlausibilityCheckType.message = undefined;
            state.command.editPlausibilityCheckType.canExecute = true;

            // getCheckTypeMessages
        }).addCase(getCheckTypeMessages.pending, (state, action) => {
            state.query.getCheckTypeMessages.status = "pending"
            state.query.getCheckTypeMessages.canExecute = false;
            state.command.editPlausibilityCheckType.canExecute = false;
        }).addCase(getCheckTypeMessages.rejected, (state, action) => {
            state.query.getCheckTypeMessages.status = "error"
            state.query.getCheckTypeMessages.canExecute = true;
            state.command.editPlausibilityCheckType.canExecute = false;
            state.query.getCheckTypeMessages.message = action.error.message;
        }).addCase(getCheckTypeMessages.fulfilled, (state, action) => {
            state.query.getCheckTypeMessages.status = "success"
            state.query.getCheckTypeMessages.message = undefined;
            state.query.getCheckTypeMessages.canExecute = true;
            state.actualData.checkTypeMessages = action.payload.getData();
        })
    }
})

export const {
    updateName,
    toggleIsBestMatch,
    cancelCheck,
    resetState,
    editPlausibilityCheckTypeCompleted,
    updateWarningMessageKey,
    updateErrorMessageKey
} = editPlausibilityCheckTypeSlice.actions

export default editPlausibilityCheckTypeSlice.reducer