import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CheckDuplicateState, SelectArticleTypePayload, SelectMainProductLinePayload, SelectQualityPayload } from './check_duplicate.model';
import { duplicateCheck, fetchBaseData, fetchCheck, fetchCheckTypes, initData } from './check_duplicate.thunks';

const initialState: CheckDuplicateState = {
    actualData: {},
    loadedData: {},
    query: {
        initData: { status: "idle", canExecute: true },
        fetchCheck: { status: "idle", canExecute: true },
        fetchBaseData: { status: "idle", canExecute: true },
        fetchCheckTypes: { status: "idle", canExecute: true },
    },
    command: { duplicateCheck: { status: "idle", canExecute: false } },
}

export const checkDuplicateSlice = createSlice({
    name: 'check_duplicate',
    initialState,
    reducers: {
        selectMainProductLine: (state, action: PayloadAction<SelectMainProductLinePayload>) => {
            state.actualData.selectedMainProductLine = action.payload.selectedMainProductLine;
        },
        selectArticleType: (state, action: PayloadAction<SelectArticleTypePayload>) => {
            state.actualData.selectedArticleType = action.payload.selectedArticleType;
        },
        selectQuality: (state, action: PayloadAction<SelectQualityPayload>) => {
            state.actualData.selectedQuality = action.payload.selectedQuality;
        },
        handleError: (state) => {
            state.command = {
                duplicateCheck: { status: "idle", canExecute: true }
            };
        },
        duplicateCheckCompleted: (state) => {
            state.command.duplicateCheck.status = "idle";
            state.command.duplicateCheck.canExecute = true;
            state.query = initialState.query;
        },
    }, extraReducers: (builder) => {

        // fetchCheck
        builder.addCase(fetchCheck.pending, (state, action) => {
            state.query.fetchCheck.status = "pending"
            state.query.fetchCheck.canExecute = false;
        }).addCase(fetchCheck.rejected, (state, action) => {
            state.query.fetchCheck.status = "error"
            state.query.fetchCheck.message = action.error.message;
            state.query.fetchCheck.canExecute = true;
        }).addCase(fetchCheck.fulfilled, (state, action) => {
            state.query.fetchCheck.status = "success"
            state.query.fetchCheck.message = undefined;
            state.query.fetchCheck.canExecute = true;
            state.loadedData.check = action.payload.getData();

            // fetchBaseData
        }).addCase(fetchBaseData.pending, (state, action) => {
            state.query.fetchBaseData.status = "pending"
            state.query.fetchBaseData.canExecute = false;
        }).addCase(fetchBaseData.rejected, (state, action) => {
            state.query.fetchBaseData.message = action.error.message;
            state.query.fetchBaseData.status = "error"
            state.query.fetchBaseData.canExecute = true;
        }).addCase(fetchBaseData.fulfilled, (state, action) => {
            state.query.fetchBaseData.status = "success"
            state.query.fetchBaseData.message = undefined;
            state.query.fetchBaseData.canExecute = true;
            state.loadedData.baseData = action.payload.getData();

            // fetchCheckTypes
        }).addCase(fetchCheckTypes.pending, (state, action) => {
            state.query.fetchCheckTypes.status = "pending"
            state.query.fetchCheckTypes.canExecute = false;
        }).addCase(fetchCheckTypes.rejected, (state, action) => {
            state.query.fetchCheckTypes.status = "error"
            state.query.fetchCheckTypes.canExecute = true;
            state.query.fetchCheckTypes.message = action.error.message;
        }).addCase(fetchCheckTypes.fulfilled, (state, action) => {
            state.query.fetchCheckTypes.status = "success"
            state.query.fetchCheckTypes.message = undefined;
            state.query.fetchCheckTypes.canExecute = true;
            state.loadedData.checkTypes = action.payload.getData();

            // duplicateCheck
        }).addCase(duplicateCheck.pending, (state, action) => {
            state.command.duplicateCheck.status = "pending"
            state.command.duplicateCheck.canExecute = false;
        }).addCase(duplicateCheck.rejected, (state, action) => {
            state.command.duplicateCheck.status = "error"
            state.command.duplicateCheck.canExecute = true;
            state.command.duplicateCheck.message = action.error.message;
            if (action.error.code === "409") {
                state.command.duplicateCheck.status = "warning";
            }
        }).addCase(duplicateCheck.fulfilled, (state, action) => {
            state.command.duplicateCheck.status = "success"
            state.command.duplicateCheck.message = undefined;
            state.command.duplicateCheck.canExecute = true;
            state.actualData.createdCheckId = action.payload.getData();

            // initData
        }).addCase(initData.pending, (state, action) => {
            state.actualData = {}
            state.loadedData = {}

            state.actualData.createdCheckId = null;
            state.query.initData.status = "pending"
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.message = action.error.message;
        }).addCase(initData.fulfilled, (state, action) => {
            state.query.initData.status = "success"
            state.query.initData.message = undefined;

            const baseData = state.loadedData.baseData;
            state.actualData.baseData = {
                availableMainProductLines: baseData.availableMainProductLines,
                availableBodyAreas: baseData.availableBodyAreas,
                availableArticleTypes: [{ name: "Alle", id: null, erpId: null }, ...baseData.availableArticleTypes],
                availableQualities: [{ name: "Alle", id: null, erpId: null }, ...baseData.availableQualities],
            }
            state.actualData.check = state.loadedData.check
            state.actualData.check.plausibilityCheckTypeVersion = state.loadedData.check.plausibilityCheckTypeVersion
            state.actualData.checkTypes = state.loadedData.checkTypes

            state.actualData.selectedMainProductLine = state.actualData.baseData.availableMainProductLines.find(item =>
                item.id === state.loadedData.check.mainProductLine.id)

            state.actualData.selectedBodyArea = state.actualData.baseData.availableBodyAreas.find(item =>
                (state.loadedData.check.bodyArea == null && item.id == null) ||
                (state.loadedData.check.bodyArea != null && item.id === state.loadedData.check.bodyArea.id))

            state.actualData.selectedArticleType = state.actualData.baseData.availableArticleTypes.find(item =>
                (state.loadedData.check.articleType == null && item.id == null) ||
                (state.loadedData.check.articleType != null && item.id === state.loadedData.check.articleType.id))

            state.actualData.selectedQuality = state.actualData.baseData.availableQualities.find(item =>
                (state.loadedData.check.quality == null && item.id == null) ||
                (state.loadedData.check.quality != null && item.id === state.loadedData.check.quality.id))

            state.command.duplicateCheck.canExecute = true;
        })
    }
})

export const { selectMainProductLine, selectArticleType, selectQuality, duplicateCheckCompleted, handleError } = checkDuplicateSlice.actions

export default checkDuplicateSlice.reducer