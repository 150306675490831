import React, { useEffect } from "react";
import FileCopy from '@mui/icons-material/FileCopy';
import { FlexBox, Right, PrimaryButtonComponent, CancelButtonComponent, DropdownComponent } from 'shared/shared';
import { LoadingIndicatorComponent } from '../../shared/components/loading_indicator/loading_indicator.component';
import { FormControlLabel, Switch, Checkbox } from "@mui/material";
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { AppDispatch } from "app/redux_store";
import { initData, duplicateCheck } from './redux/check_duplicate.thunks';
import { duplicateCheckCompleted, selectArticleType, selectMainProductLine, selectQuality, handleError } from "./redux/check_duplicate.slice";
import { useSnackbar } from 'notistack';
import * as style from "./check_duplicate.style";
import { LoadingOverlayContainer, LoadingOverlayComponent } from '../../shared/components/loading_overlay/loading_overlay.component';
import { useNavigate, useParams } from "react-router-dom";

export const CheckDuplicateComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.check_duplicate);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { checkId } = useParams();

    useEffect(() => {
        dispatch(initData(Number(checkId)));
    }, [dispatch, checkId]);

    if (state.query.fetchCheck.status === "error") {
        return <div>Fehler beim Laden der existierenden Prüfung</div>;
    }

    if (state.query.fetchBaseData.status === "error") {
        return <div>Fehler beim Laden der Basisdaten</div>;
    }

    if (state.query.initData.status === "pending"
        || state.query.fetchBaseData.status === "pending"
        || state.query.fetchCheckTypes.status === "pending"
        || state.query.fetchCheck.status === "pending") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.duplicateCheck.status === "success") {
        enqueueSnackbar("Prüfung dupliziert", { variant: "success" });
        dispatch(duplicateCheckCompleted());
        navigate(`/plausibilitycheck/check/${state.actualData.createdCheckId}/rule/false`);
    }

    if (state.command.duplicateCheck.status === "error") {
        enqueueSnackbar(state.command.duplicateCheck.message, { variant: "error" });
        dispatch(handleError());
    }

    if (state.command.duplicateCheck.status === "warning") {
        enqueueSnackbar(state.command.duplicateCheck.message, { variant: "warning" });
        dispatch(handleError());
    }


    if (state.actualData?.baseData == undefined) return <div></div>;

    const isSaving = state.command.duplicateCheck.status === "pending";

    return (<>
        <LoadingOverlayContainer>
            {isSaving ? <LoadingOverlayComponent /> : <></>}

            <style.Box>
                <DropdownComponent
                    name="Hauptproduktlinie"
                    data={state.actualData.baseData.availableMainProductLines}
                    isLoading={false}
                    isRequired={true}
                    onSelect={(selectedMainProductLine) => { dispatch(selectMainProductLine({ selectedMainProductLine })) }}
                    displayedProperties={["name"]}
                    selectedValue={state.actualData.selectedMainProductLine}
                />
                <DropdownComponent
                    name="Körperregion"
                    data={state.actualData.baseData.availableBodyAreas}
                    isReadonly={true}
                    isLoading={false}
                    isRequired={true}
                    onSelect={() => { }}
                    displayedProperties={["name"]}
                    selectedValue={state.actualData.selectedBodyArea}
                />
                <DropdownComponent
                    name="Artikelart"
                    data={state.actualData.baseData.availableArticleTypes}
                    isLoading={false}
                    isRequired={true}
                    onSelect={(selectedArticleType) => { dispatch(selectArticleType({ selectedArticleType })) }}
                    displayedProperties={["erpId", "name"]}
                    selectedValue={state.actualData.selectedArticleType}
                />
                <DropdownComponent
                    name="Qualität"
                    data={state.actualData.baseData.availableQualities}
                    isLoading={false}
                    isRequired={true}
                    onSelect={(selectedQuality) => { dispatch(selectQuality({ selectedQuality })) }}
                    displayedProperties={["erpId", "name"]}
                    selectedValue={state.actualData.selectedQuality}
                />

            </style.Box>
            <style.Box>
                <DropdownComponent
                    name="Prüfungstyp"
                    isReadonly={true}
                    data={state.actualData.checkTypes}
                    isLoading={false}
                    isRequired={true}
                    onSelect={() => { }}
                    displayedProperties={["name"]}
                    selectedValue={state.actualData.check.plausibilityCheckTypeVersion}
                />
                <style.IsSideSpecificWrapper>
                    <FormControlLabel
                        control={<Switch name="IsSideSpecificSwitch" />}
                        label="Seitenabhängig"
                        disabled={true}
                        checked={state.actualData.check.isSideSpecific}
                        labelPlacement="end"
                    />
                </style.IsSideSpecificWrapper>
            </style.Box>

            <style.Box>
                <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="isuniquerules-checktype-checkbox"
                                checked={state.actualData.check.plausibilityCheckTypeVersion.isUniqueRules}
                                disabled={true}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="eindeutiges Regelwerk"
                    />
                </div>
            </style.Box>

            <FlexBox>
                <Right>
                    <style.ButtonMargin>
                        <CancelButtonComponent />
                    </style.ButtonMargin>
                    <PrimaryButtonComponent
                        onClick={() => { dispatch(duplicateCheck()); }}
                        disabled={!state.command.duplicateCheck.canExecute}>
                        <FileCopy /> Duplizieren
                    </PrimaryButtonComponent>
                </Right>
            </FlexBox>
        </LoadingOverlayContainer>
    </>
    );
};
