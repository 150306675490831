import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseErrors } from "shared/networking/error_handling";
import AdditionCategoryService from 'services/addition_category.service';
import PolicyServiceAPI from '../../../../../services/policy.service';
import { RootState } from "app/redux_store";
import { PolicyTypeEnum } from "models/policies/policy_type.enum";

export const getAdditionCategories = createAsyncThunk(
    'policy/create_policy_mandatory_category/getAdditionCategories',
    async (_) => {
        const response = await AdditionCategoryService.getAvailableAdditionCategoryByPolicyTypeOverview(PolicyTypeEnum.MandatoryCategory, undefined);
        handleResponseErrors(response, "Zusatzekategorien");
        return response;
    }
)

export const savePolicy = createAsyncThunk(
    'policy/create_policy_mandatory_category/savepolicy',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.policy_mandatory_category_create.data;
        const response = await PolicyServiceAPI.createMandatoryCategory({
            name: data.policy.name,
            ignoreOnQuotation: data.policy.ignoreOnQuotation,
            additionCategoryId: data.selectedCategory.id
        });
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'policy/create_policy_mandatory_category/cancelSave',
    async (_) => { }
)


