import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GridFilterModel } from "@mui/x-data-grid-pro";
import { CharcteristicTypeOverviewState } from './characteristic_type_overview.model';
import { ICharacteristicTypeOverview } from 'models/characteristic_types/characteristic_type_overview';
import { filterCharacteristicTypes } from './characteristic_type_overview.reducer';
import {
    createNewCharacteristicType,
    fetchCharacteristicTypes,
    updateCharacteristicType,
    confirmDelete
} from './characteristic_type_overview.thunks';
import { Errordefinitions } from 'models/error/error_definitions';
import { CommonApiResultError } from 'shared/networking/common_api_result';

const initialState: CharcteristicTypeOverviewState = {
    loadedData: {
        characteristicTypes: []
    },
    actualData: {
        characteristicTypes: [],
        searchFilter: "",
        gridFilters: { items: [] },
        characteristicType: null,
        showDeleteCharacteristicTypeDialog: false
    },
    errorData: {
        error: null,
        showDeleteErrorDialog: false
    },
    command: {
        confirmDeleteCharacteristicType: { status: "idle", canExecute: false },
        createNewCharacteristicType: { status: "idle", canExecute: false },
        updateCharacteristicType: { status: "idle", canExecute: false },
    },
    query: {
        fetchCharacteristicTypes: { status: "idle", canExecute: true }
    }
}

export const getCharacteristicTypesSlice = createSlice({
    name: 'get_characteristic_types_overview',
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.loadedData = initialState.loadedData;
            state.errorData = initialState.errorData;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.actualData.searchFilter = action.payload;
            filterCharacteristicTypes(state);
        },
        setGridFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.gridFilters = action.payload;
            filterCharacteristicTypes(state);
        },
        cancelDelete: (state) => {
            state.actualData.characteristicType = null;
            state.actualData.showDeleteCharacteristicTypeDialog = false;
        },
        deleteCompleted: (state) => {
            state.command.confirmDeleteCharacteristicType = initialState.command.confirmDeleteCharacteristicType;
            state.errorData = initialState.errorData;
        },
        updateCompleted: (state) => {
            state.actualData.characteristicType = null;;
        },
        deleteCharacteristicType: (state, action: PayloadAction<ICharacteristicTypeOverview>) => {
            state.actualData.showDeleteCharacteristicTypeDialog = true;
            state.actualData.characteristicType = action.payload;
        },
    }, extraReducers: (builder) => {

        // fetchCharacteristicTypes
        builder.addCase(fetchCharacteristicTypes.pending, (state) => {
            state.query.fetchCharacteristicTypes.status = "pending"
            state.query.fetchCharacteristicTypes.canExecute = false;
        }).addCase(fetchCharacteristicTypes.rejected, (state, action) => {
            state.query.fetchCharacteristicTypes.status = "error"
            state.query.fetchCharacteristicTypes.message = action.error.message;
            state.query.fetchCharacteristicTypes.canExecute = true;
        }).addCase(fetchCharacteristicTypes.fulfilled, (state, action) => {
            state.query.fetchCharacteristicTypes.status = "success"
            state.query.fetchCharacteristicTypes.canExecute = true;
            state.loadedData.characteristicTypes = action.payload.getData();
            filterCharacteristicTypes(state);

            // createNewCharacteristicType
        }).addCase(createNewCharacteristicType.pending, (state, action) => {
            state.command.createNewCharacteristicType.status = "pending"
        }).addCase(createNewCharacteristicType.fulfilled, (state, action) => {
            state.command.createNewCharacteristicType.status = "success"

            // updateCharacteristicType
        }).addCase(updateCharacteristicType.pending, (state) => {
            state.command.updateCharacteristicType.status = "pending"
        }).addCase(updateCharacteristicType.fulfilled, (state, action) => {
            state.command.updateCharacteristicType.status = "success"
            state.actualData.characteristicType = action.payload;

            // confirmDelete 
        }).addCase(confirmDelete.pending, (state) => {
            state.command.confirmDeleteCharacteristicType.status = 'pending'
        }).addCase(confirmDelete.rejected, (state, action) => {
            state.command.confirmDeleteCharacteristicType.status = "error"
            const commonError = action.payload as CommonApiResultError;
            if (commonError) {
                if (commonError.errorSubCode === Errordefinitions.CHARACTERISTICTYPE_USED) {
                    state.errorData.showDeleteErrorDialog = true;
                    state.errorData.error = commonError;
                }
                state.command.confirmDeleteCharacteristicType.message = commonError.message;
            } else {
                state.command.confirmDeleteCharacteristicType.message = action.error.message;
            }
            state.actualData.showDeleteCharacteristicTypeDialog = false;
        }).addCase(confirmDelete.fulfilled, (state, action) => {
            state.command.confirmDeleteCharacteristicType.status = "success"
            state.command.confirmDeleteCharacteristicType.canExecute = true;
            state.actualData.showDeleteCharacteristicTypeDialog = false;
            state.loadedData.characteristicTypes = action.payload.getData();
            filterCharacteristicTypes(state);
        })
    }
})

export const {
    resetState,
    setSearchFilter,
    setGridFilters,
    deleteCompleted,
    updateCompleted,
    cancelDelete,
    deleteCharacteristicType
} = getCharacteristicTypesSlice.actions

export default getCharacteristicTypesSlice.reducer