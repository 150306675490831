import styled from "@emotion/styled";

export const Thumbnail = styled.img`
    max-width:40px;
    max-height:40px;
`

export const Column1 = styled.div`
    margin-left:20px;
`

export const Column2 = styled.div`
    margin-left:40px;
`