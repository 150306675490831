import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createOneLeg, getOneLegs, confirmDeleteOneLeg, editOneLeg } from './onelegs_overview.thunks';
import { OneLegOverviewState } from './onelegs_overview.model';
import { IOneLegOverview } from '../../../models/onelegs/oneLeg_overview';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import { filterOneLegs } from './onelegs_overview.reducer';

const initialState: OneLegOverviewState = {
    loadedData: {
        oneLegs: [],
    },
    actualData: {
        oneLegs: [],
        searchFilter: "",
        gridFilters: {items: []},
        showDeleteDialog: false,
        selectedOneLeg: null,
    },
    query: {
        getOneLegs: { status: "idle", canExecute: false },
    },
    command: {
        createOneLeg: { status: "idle", canExecute: false },
        confirmDeleteOneLeg: { status: "idle", canExecute: false },
        editOneLeg: { status: "idle", canExecute: false },
    }
}

export const oneLegOverviewSlice = createSlice({
    name: 'massMask/onelegs/overview',
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        setSearchFilter:(state, action: PayloadAction<string>) => {
            state.actualData.searchFilter = action.payload;
            filterOneLegs(state);
        }, 
        setGridFilters:(state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.gridFilters = action.payload;
            filterOneLegs(state);
        },
        deleteOneLeg: (state, action: PayloadAction<IOneLegOverview>) => {
            state.actualData.selectedOneLeg = action.payload;
            state.actualData.showDeleteDialog = true;
        },
        resetDeleteOneLeg: (state) => {
            state.command.confirmDeleteOneLeg = initialState.command.confirmDeleteOneLeg;
        },
        cancelDeleteOneLeg: (state) => {
            state.actualData.selectedOneLeg = null;
            state.actualData.showDeleteDialog = false;
        },
    },
    extraReducers: (builder) => {
        //getOneLegs
        builder.addCase(getOneLegs.pending, (state, action) => {
            state.query.getOneLegs.status = 'pending'
        }).addCase(getOneLegs.rejected, (state, action) => {
            state.query.getOneLegs.status = "error"
            state.query.getOneLegs.message = action.error.message;
        }).addCase(getOneLegs.fulfilled, (state, action) => {
            state.query.getOneLegs.status = "success"
            state.loadedData.oneLegs = action.payload.getData();
            filterOneLegs(state);

            // createOneLeg
        }).addCase(createOneLeg.pending, (state, action) => {
            state.command.createOneLeg.status = 'pending'
        }).addCase(createOneLeg.fulfilled, (state, action) => {
            state.command.createOneLeg.status = "success"

            // confirmDeleteOneLeg
        }).addCase(confirmDeleteOneLeg.pending, (state, action) => {
            state.command.confirmDeleteOneLeg.status = 'pending'
        }).addCase(confirmDeleteOneLeg.rejected, (state, action) => {
            state.command.confirmDeleteOneLeg.status = "error"
            state.command.confirmDeleteOneLeg.message = action.error.message;
            state.actualData.selectedOneLeg = null;
            state.actualData.showDeleteDialog = false;
        }).addCase(confirmDeleteOneLeg.fulfilled, (state, action) => {
            state.command.confirmDeleteOneLeg.status = "success"
            state.loadedData.oneLegs = action.payload.getData();
            filterOneLegs(state);
            state.actualData.selectedOneLeg = null;
            state.actualData.showDeleteDialog = false;

            // editOneLeg
        }).addCase(editOneLeg.pending, (state, action) => {
            state.command.editOneLeg.status = 'pending'
        }).addCase(editOneLeg.fulfilled, (state, action) => {
            state.command.editOneLeg.status = "success";
            state.actualData.selectedOneLeg = action.payload;
        })
    }
});

export const {
    setSearchFilter,
    setGridFilters,
    resetState,
    deleteOneLeg,
    cancelDeleteOneLeg,
    resetDeleteOneLeg
} = oneLegOverviewSlice.actions

export default oneLegOverviewSlice.reducer