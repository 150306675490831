import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseErrors } from "shared/networking/error_handling";
import AdditionCategoryService from 'services/addition_category.service';
import PolicyServiceAPI from '../../../../../services/policy.service';
import { RootState } from "app/redux_store";
import { PolicyTypeEnum } from "models/policies/policy_type.enum";
import MassPointService from "services/masspoint.service";

export const getAdditionCategories = createAsyncThunk(
    'policy/create_policy_mandatory_category_by_masspoint/getAdditionCategories',
    async (_) => {
        const response = await AdditionCategoryService.getAvailableAdditionCategoryByPolicyTypeOverview(PolicyTypeEnum.MandatoryCatagoryByMasspoint, undefined);
        handleResponseErrors(response, "Zusatzekategorien");
        return response;
    }
)

export const getMasspoints = createAsyncThunk(
    'policy/create_policy_mandatory_category_by_masspoint/getMasspoints',
    async (_) => {
        const response = await MassPointService.getMassPoints();
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)

export const savePolicy = createAsyncThunk(
    'policy/create_policy_mandatory_category_by_masspoint/savepolicy',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.policy_mandatory_category_by_masspoint_create.data;
        const response = await PolicyServiceAPI.createMandatoryCategoryByMasspoint({
            name: data.policy.name,
            ignoreOnQuotation: data.policy.ignoreOnQuotation,
            additionCategoryId: data.selectedCategory.id,
            massPointName: data.selectedMasspointCriteria.massPointName,
            massPointType: data.selectedMasspointCriteria.massPointType,
            bodyAreaId: data.selectedMasspointCriteria.bodyAreaId,
        });
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'policy/create_policy_mandatory_category_by_masspoint/cancelSave',
    async (_) => { }
)


