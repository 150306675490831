import { Step, StepLabel, Stepper } from "@mui/material";
import React from "react";

export enum MassmaskWizardStepEnum {
    CommonInformation = 0,
    MassDefinitions = 1,
    MassImage = 2,
    MassPoints = 3
}

interface MassmaskStepperComponentProps {
    currentStep: MassmaskWizardStepEnum
}

export const MassmaskStepperComponent: React.FC<MassmaskStepperComponentProps> = ({ currentStep }) => {
    return <Stepper activeStep={currentStep} nonLinear={true}>
        <Step key="0">
            <StepLabel>Allgemeine Informationen definieren</StepLabel>
        </Step>
        <Step key="1">
            <StepLabel>Maßdefinitionen</StepLabel>
        </Step>
        <Step key="2">
            <StepLabel>Grafik auswählen</StepLabel>
        </Step>
        <Step key="3">
            <StepLabel>Maßdefinition editieren und Maßpunkte zuweisen</StepLabel>
        </Step>
    </Stepper>
}