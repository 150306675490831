import { doesExist, isNullOrWhitespace } from "services/validation.service";
import { PolicyDefaultAdditionByArticleTypeCreateState } from "./policy_default_addition_by_articletype_create.model";
import IAvailableArticleType from "models/available_basedata/available_article_type";
import { IAdditionPolicyInformation } from "models/additions/addition_policy_information";
import { IFilterList } from "shared/components/selectionComponent/models/filterList";

export const updateCanSave = (state: PolicyDefaultAdditionByArticleTypeCreateState) => {
    state.command.savePolicy.canExecute =
        !isNullOrWhitespace(state.data.policy.name)
        && doesExist(state.data.selectedAddition)
        && containsArticleType(state);
}

const containsArticleType = (state: PolicyDefaultAdditionByArticleTypeCreateState) => {
    return state.data.articleTypes.selectedList.allItems.length > 0;
}

export const sortAdditions = (mp1: IAdditionPolicyInformation, mp2: IAdditionPolicyInformation) => {
    return mp1.option.localeCompare(mp2.option);
}