import { GridFilterItem, GridColDef, getGridBooleanOperators, GridSortModel } from "@mui/x-data-grid-pro"
import IAvailableArticleType from "models/available_basedata/available_article_type"
import IAvailableFootType from "models/available_basedata/available_foot_type"
import IMassmaskOverview from "models/massmasks/massmask_overview"
import { CustomBooleanCell } from "shared/components/datagrid/custom_boolean_cell.component"
import { CustomFilterOperators } from "shared/components/datagrid/custom_filter_operators"
import { CustomTooltipHeader } from "shared/components/datagrid/custom_tooltip_header.component"
import { DatagridMenu } from "shared/components/datagrid/datagrid_menu.component"

const buildArticleTypeDisplayString = (articleType: IAvailableArticleType): string => {
    if (articleType != null) {
        return `${articleType.erpId} ${articleType.name}`;
    }
    return "-";
}

const buildFootTypeDisplayString = (footType: IAvailableFootType): string => {
    if (footType != null) {
        return `${footType.externalIdentifier} ${footType.name}`;
    }
    return "-";
}

export const filterMassmaskValue = (filterItem: GridFilterItem, filteredItems: IMassmaskOverview[]) => {
    if (filterItem.value) {
        switch (filterItem.field) {
            case 'mainProductLine':
                filteredItems = filteredItems.filter(x => x.mainProductLine.name.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'bodyArea':
                filteredItems = filteredItems.filter(x => x.bodyArea.name.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'articleType':
                filteredItems = filteredItems.filter(x => buildArticleTypeDisplayString(x.articleType).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'footType':
                filteredItems = filteredItems.filter(x => buildFootTypeDisplayString(x.footType).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'isPublished':
                filteredItems = filteredItems.filter(x => filterItem.value === 'any' ? true : x.isPublished.toString() === filterItem.value);
                break;
            case 'isPantyTopWithStandardDimensions':
                filteredItems = filteredItems.filter(x => filterItem.value === 'any' ? true : x.isPantyTopWithStandardDimensions.toString() === filterItem.value);
                break;
            case 'isEllipticalShapeKneeFunctionZone':
                filteredItems = filteredItems.filter(x => filterItem.value === 'any' ? true : x.isEllipticalShapeKneeFunctionZone.toString() === filterItem.value);
                break;     
           case 'hideFingersAndToes':
                filteredItems = filteredItems.filter(x => filterItem.value === 'any' ? true : x.hideFingersAndToes.toString() === filterItem.value);
                break;
        }
    }
    return filteredItems;
}

export const getGridColumns = (
    editMassmask: (massmask: IMassmaskOverview) => void,
    deleteMaskmask: (massmask: IMassmaskOverview) => void,
    togglePublishMassmask: (massmask: IMassmaskOverview) => void,
): GridColDef[] => [
        {
            field: "menu",
            headerName: " ",
            width: 15,
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            hideable: false,
            pinnable: false,
            renderCell: params => DatagridMenu({
                params: params, menuItemsData:
                    [
                        { label: "Maßmaske editieren", action: editMassmask },
                        { label: params.row.isPublished ? "Veröffentlichung aufheben" : "Maßmaske veröffentlichen", action: togglePublishMassmask },
                        { label: "Maßmaske löschen", action: deleteMaskmask }
                    ]
            })
        },
        {
            field: "mainProductLine",
            headerName: "Hauptproduktlinie",
            disableColumnMenu: false,
            width: 150,
            sortable: false,
            hideable: false,
            pinnable: false,
            valueGetter: (value, row) => row.mainProductLine.name,
            filterOperators: CustomFilterOperators(),
        },
        {
            field: "bodyArea",
            headerName: "Körperregion",
            disableColumnMenu: false,
            width: 150,
            sortable: false,
            hideable: false,
            pinnable: false,
            valueGetter: (value, row) => row.bodyArea.name,
            filterOperators: CustomFilterOperators(),
        },
        {
            field: "articleType",
            headerName: "Artikelart",
            disableColumnMenu: false,
            flex: 0.5,
            sortable: false,
            hideable: false,
            pinnable: false,
            valueGetter: (value, row) => buildArticleTypeDisplayString(row.articleType),
            filterOperators: CustomFilterOperators(),
        },
        {
            field: "footType",
            headerName: "Fußart",
            disableColumnMenu: false,
            flex: 0.5,
            sortable: false,
            hideable: false,
            pinnable: false,
            valueGetter: (value, row) => buildFootTypeDisplayString(row.footType),
            filterOperators: CustomFilterOperators(),
        },
        {
            field: "isPublished",
            disableColumnMenu: false,
            width: 80,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterOperators: getGridBooleanOperators(),
            renderHeader: () => CustomTooltipHeader({ shortText: "v", tooltip: "Veröffentlicht" }),
            renderCell: (params) => CustomBooleanCell({ value: params.row.isPublished })
        },
        {
            field: "isPantyTopWithStandardDimensions",
            disableColumnMenu: false,
            width: 90,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterOperators: getGridBooleanOperators(),
            renderHeader: () => CustomTooltipHeader({ shortText: "LmS", tooltip: "Leibteil mit Standardmaßen" }),
            renderCell: (params) => CustomBooleanCell({ value: params.row.isPantyTopWithStandardDimensions })
        },
        {
            field: "isEllipticalShapeKneeFunctionZone",
            disableColumnMenu: false,
            width: 90,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterOperators: getGridBooleanOperators(),
            renderHeader: () => CustomTooltipHeader({ shortText: "E/K", tooltip: "Ellipsenform/Kniefunktionszone" }),
            renderCell: (params) => CustomBooleanCell({ value: params.row.isEllipticalShapeKneeFunctionZone })
        },
        {
            field: "hideFingersAndToes",
            disableColumnMenu: false,
            width: 90,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterOperators: getGridBooleanOperators(),
            renderHeader: () => CustomTooltipHeader({ shortText: "F&Z", tooltip: "Finger & Zehen" }),
            renderCell: (params) => CustomBooleanCell({ value: params.row.hideFingersAndToes })
        }
    ]

export const sortByMainProductLineModel: GridSortModel = [
    {
        field: "mainProductLine",
        sort: "asc",
    },
];