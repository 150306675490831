import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/redux_store';
import CheckTypeService from '../../../services/checktype.service';
import CheckTypeMessageService from '../../../services/checktype_message.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';

export const createPlausibilityCheckType = createAsyncThunk(
    'plausibilitychecktype/createPlausibilityCheckType',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const checkTypeToCreate = state.plausibilitychecktype_create.actualData.checkTypeToCreate;
        const response = await CheckTypeService.saveCheckType(checkTypeToCreate);
        handleResponseErrors(response, "Prüfungstyp");
        return response;
    }
)

export const getCheckTypeMessages = createAsyncThunk(
    'plausibilitychecktype/getCheckTypeMessages',
    async (_) => {
        const response = await CheckTypeMessageService.getMessages();
        handleResponseErrors(response, "Fehler/Warntexte");
        return response;
    }
)