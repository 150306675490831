import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdditionCategoryOverviewState } from './addition_category_overview.model';
import { IAdditionCategoryOverview } from 'models/addition_categories/addition_category_overview';
import { createNewAdditionCategory, getAdditionCategories, confirmDeleteAdditionCategory } from './addition_category_overview.thunks';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import { filterAdditionCategories } from './addition_category_overview.reducer';

const initialState: AdditionCategoryOverviewState = {
    loadedData: {
        additionCategories: [],
    },
    actualData: {
        additionCategories: [],
        searchFilter: "",
        additionCategoriesGridFilter: { items: []},
        showDeleteDialog: false,
        activeAdditionCategory: null,
    },
    query: {
        getAdditionCategories: { status: "idle", canExecute: true },
    },
    command: {
        createNewAdditionCategory: { status: "idle", canExecute: false },
        confirmDeleteAdditionCategory: { status: "idle", canExecute: false },
    }, 
}

export const additionCategoryOverviewSlice = createSlice({
    name: 'additioncategory/overview',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loadedData = initialState.loadedData;
            state.actualData = initialState.actualData;
            state.query = initialState.query;
            state.command = initialState.command;
        },
        resetDeleteAdditionCategory: (state) => {
            state.command.confirmDeleteAdditionCategory = initialState.command.confirmDeleteAdditionCategory;
        },     
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.actualData.searchFilter = action.payload;
            filterAdditionCategories(state)
        },
        setGridFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.additionCategoriesGridFilter = action.payload;
            filterAdditionCategories(state);
        },
        deleteAdditionCategory: (state, action: PayloadAction<IAdditionCategoryOverview>) => {
            state.actualData.activeAdditionCategory = action.payload;
            state.actualData.showDeleteDialog = true;
        },
        cancelDeleteAdditionCategory: (state) => {
            state.actualData.activeAdditionCategory = null;
            state.actualData.showDeleteDialog = false;
        },
    }, extraReducers: (builder) => {
        // getAdditions
        builder.addCase(getAdditionCategories.pending, (state, action) => {
            state.query.getAdditionCategories.status = "pending"
            state.query.getAdditionCategories.canExecute = false;
        }).addCase(getAdditionCategories.rejected, (state, action) => {
            state.query.getAdditionCategories.status = "error"
            state.query.getAdditionCategories.message = action.error.message;
        }).addCase(getAdditionCategories.fulfilled, (state, action) => {
            state.query.getAdditionCategories.status = "success"

            const additionCategories = action.payload.getData();
            state.loadedData.additionCategories = additionCategories;
            filterAdditionCategories(state);

        // confirmDeleteAdditionCategory
        }).addCase(confirmDeleteAdditionCategory.pending, (state, action) => {
            state.command.confirmDeleteAdditionCategory.status = 'pending'
        }).addCase(confirmDeleteAdditionCategory.rejected, (state, action) => {
            state.command.confirmDeleteAdditionCategory.status = "error"
            state.command.confirmDeleteAdditionCategory.message = action.error.message;
            state.actualData.activeAdditionCategory = null;
            state.actualData.showDeleteDialog = false;
        }).addCase(confirmDeleteAdditionCategory.fulfilled, (state, action) => {
            state.command.confirmDeleteAdditionCategory.status = "success"
            state.actualData.additionCategories = action.payload.getData();
            state.actualData.activeAdditionCategory = null;
            state.actualData.showDeleteDialog = false;

        // createNewAdditionCategory
        }).addCase(createNewAdditionCategory.pending, (state, action) => {
            state.command.createNewAdditionCategory.status = "pending"
        }).addCase(createNewAdditionCategory.fulfilled, (state, action) => {
            state.command.createNewAdditionCategory.status = "success"
        })
    }
})

export const {
    setSearchFilter,
    setGridFilters,
    deleteAdditionCategory,
    cancelDeleteAdditionCategory,
    resetState,
    resetDeleteAdditionCategory,
} = additionCategoryOverviewSlice.actions

export default additionCategoryOverviewSlice.reducer