import styled from "@emotion/styled";
import colors from './../../style/colors';
import InfoIcon from '@mui/icons-material/Info';
import { Box } from "@mui/material";

export const DescriptionComponent = styled.div`
    .MuiSvgIcon-root {
        cursor: pointer;
    }
`

export const DescriptionTooltip = styled.div`
    .MuiSvgIcon-root {
        color: white;
        cursor: pointer;
    }
`

export const InfoIconStyled = styled(InfoIcon)`
    color: ${colors.mediMagenta};
    cursor: pointer;
`

export const BoxStyled = styled(Box)`
    border-radius: 4px;
    padding: 8px;
    background-color: #333F48;
    color: #fff;
    &:before {
        background-color: #333F48;
        content: "";
        display: block;
        position: absolute;
        width: 12px;
        height: 12px;
        bottom: -5px;
        transform: rotate(45deg);
        left: calc(50% - 6px);
    }
`