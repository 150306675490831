import { IconButton, Menu, MenuItem } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { IMassDefinition } from "models/massdefinitions/massdefinition";
import React from "react";
import { LoadingOverlayComponent } from "shared/components/loading_overlay/loading_overlay.component";
import { MimeTypeConstantsEnum } from "../../../models/mime_type_constants.enum";
import { LoadingOverlayContainer } from '../../../shared/components/loading_overlay/loading_overlay.component';
import * as style from "./massdefinitions_table.style";
import { MenuOverviewComponent } from 'shared/components/overview/menu_overview.component';
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { isNullOrWhitespace } from "services/validation.service";

interface MassDefinitionsTableComponentProps {
    massdefinitions: Array<IMassDefinition>;
    onDelete: (massDefinition: IMassDefinition) => void;
    onEdit: (massDefinition: IMassDefinition) => void;
    onSortUp: (sortIndex: number) => void;
    onSortDown: (sortIndex: number) => void;
    isDeleting: boolean;
}

export const MassDefinitionsTableComponent: React.FC<MassDefinitionsTableComponentProps> =
    ({ massdefinitions,
        onDelete,
        onEdit,
        onSortUp,
        onSortDown,
        isDeleting }) => {

        const [menuAnchor, setAnchorEl] = React.useState(null);
        const [selectedMassdefinition, setSelectedMassdefinition] = React.useState<IMassDefinition>(null);

        const onMenuOpen = (event, massDefinition: IMassDefinition) => {
            setSelectedMassdefinition(massDefinition);
            setAnchorEl(event.currentTarget);
        };

        const onMenuClose = () => {
            setAnchorEl(null);
        };

        const onEditMenu = () => {
            onMenuClose();
            onEdit(selectedMassdefinition);
        };

        const onDeleteMenu = () => {
            onMenuClose();
            onDelete(selectedMassdefinition);
        };

        if (!massdefinitions || massdefinitions.length === 0) {
            return <div>Keine Maßdefinitionen vorhanden</div>
        }

        
        return (
            <>
                <h3>Maßdefinitionen</h3>
                <LoadingOverlayContainer>
                    {isDeleting ? <LoadingOverlayComponent /> : <></>}

                    <Menu
                        anchorEl={menuAnchor}
                        open={Boolean(menuAnchor)}
                        onClose={onMenuClose}
                    >
                        <MenuItem onClick={onEditMenu}>Maßdefinition editieren</MenuItem>
                        <MenuItem onClick={onDeleteMenu}>Maßdefinition löschen</MenuItem>
                    </Menu>
                    <List>
                        {massdefinitions.slice().sort((x,y) => x.sortIndex - y.sortIndex).map(massdefinition => (
                            <div style={{display: "flex", alignItems: "center"}}>
                                <ListItem key={massdefinition.id}>
                                    <style.Column1>
                                        <MenuOverviewComponent onMenuOpen={onMenuOpen}
                                            menuItem={massdefinition} />
                                    </style.Column1>
                                    <style.Column2>
                                        <ListItemAvatar>
                                            <style.Thumbnail src={`data:${MimeTypeConstantsEnum.Svg};base64, ${massdefinition.massImage.content}`} alt={massdefinition.massImage.name} />
                                        </ListItemAvatar>
                                    </style.Column2>
                                    <style.Column2>
                                        <ListItemText primary={massdefinition.massImage.name} />
                                    </style.Column2>
                                    <style.Column2>
                                        <ListItemText primary={!isNullOrWhitespace(massdefinition.measurementSheet) ? `Maßdatenblatt Template:   ${massdefinition.measurementSheet}` : ""} />
                                    </style.Column2>
                                </ListItem>
                                <IconButton
                                    style={{ outline: "none", marginLeft: "15px", height: "40px" }}
                                    onClick={() => {onSortUp(massdefinition.sortIndex)}}>
                                    <ArrowUpward />
                                </IconButton>
                                <IconButton
                                    style={{ outline: "none", marginLeft: "15px", height: "40px" }}
                                    onClick={() => {onSortDown(massdefinition.sortIndex)}}>
                                    <ArrowDownward />
                                </IconButton>
                            </div>
                        )
                        )}
                    </List>
                </LoadingOverlayContainer>
            </>
        );
    };