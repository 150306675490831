import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getMassMask } from 'massmasks/massmask_massdefinition/redux/massmask_massdefinition.thunks';
import IMassimageOverview from '../../../models/massimages/massimage_overview';
import { MassMaskMassImageState } from './massmask_massimage.model';
import { cancelMassImages, confirmMasspointsMissing, getMassImages, saveMassDefinition, initData } from './massmask_massimage.thunks';
import { IMassDefinitionCreate } from 'models/massdefinitions/massdefinition_create';

const initialState: MassMaskMassImageState = {
    actualData: {
        massMaskId: null,
        massImages: [],
        selectedMassImage: null,
        createdMassDefinitionId: null,
        notExistingMassPoints: [],
        areMassPointsMissing: false,
    },
    loadedData: {
        massMask: null,
    },
    query: {
        getMassImages: { status: "idle", canExecute: true },
        getMassMask: { status: "idle", canExecute: true },
        initData: { status: "idle", canExecute: true },
    },
    command: {
        saveMassDefinition: { status: "idle", canExecute: false },
        cancelMassImages: { status: "idle", canExecute: true },
        confirmMasspointsMissing: { status: "idle", canExecute: true },
    }
}

export const massMaskMassImageSlice = createSlice({
    name: 'massMask/massImage',
    initialState,
    reducers: {
        resetState: (state, action: PayloadAction<Number>) => {
            state.actualData = {
                ...initialState.actualData,
                massMaskId: action.payload
            };
            state.command = initialState.command;
            state.query = initialState.query;
        },
        saveMassDefinitionCompleted: (state) => {
            state.command.saveMassDefinition.status = "idle";
        },
        selectMassImage: (state, action: PayloadAction<IMassimageOverview>) => {
            state.actualData.selectedMassImage = action.payload;
            state.command.saveMassDefinition.canExecute = action.payload != null;
        },
    }, extraReducers: (builder) => {

        // saveMassDefinition 
        builder.addCase(saveMassDefinition.pending, (state, action) => {
            state.command.saveMassDefinition.status = 'pending'
        }).addCase(saveMassDefinition.rejected, (state, action) => {
            state.command.saveMassDefinition.status = "error"
            state.command.saveMassDefinition.message = action.error.message;
        }).addCase(saveMassDefinition.fulfilled, (state, action) => {
            state.command.saveMassDefinition.status = "success";
            const saveMassDefinitionData = action.payload.getData();
            state.actualData.createdMassDefinitionId = saveMassDefinitionData.id;
            state.actualData.areMassPointsMissing = saveMassDefinitionData.notExistingMassPoints.length > 0;
            state.actualData.notExistingMassPoints = saveMassDefinitionData.notExistingMassPoints;

            // getMassImages
        }).addCase(getMassImages.pending, (state, action) => {
            state.query.getMassImages.status = 'pending'
        }).addCase(getMassImages.rejected, (state, action) => {
            state.query.getMassImages.status = "error"
            state.query.getMassImages.message = action.error.message;
        }).addCase(getMassImages.fulfilled, (state, action) => {
            state.query.getMassImages.status = "success"
            state.query.getMassImages.canExecute = true;
            state.actualData.massImages = action.payload.getData();

            // getMassMask
        }).addCase(getMassMask.pending, (state, action) => {
            state.query.getMassMask.status = 'pending'
        }).addCase(getMassMask.rejected, (state, action) => {
            state.query.getMassMask.status = "error"
            state.query.getMassMask.message = action.error.message;
        }).addCase(getMassMask.fulfilled, (state, action) => {
            state.query.getMassMask.status = "success"
            state.query.getMassMask.canExecute = true;
            state.loadedData.massMask = action.payload.getData();

            // initData
        }).addCase(initData.pending, (state, action) => {
            state.query.initData.status = "pending"
            state.query.initData.canExecute = false;
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.canExecute = true;
            state.query.initData.message = action.error.message;
        }).addCase(initData.fulfilled, (state, action) => {
            state.query.initData.status = "success"
            state.query.initData.message = undefined;
            state.query.initData.canExecute = true;

            // addMassDefinition 
        }).addCase(cancelMassImages.pending, (state, action) => {
            state.command.cancelMassImages.status = 'pending'
        }).addCase(cancelMassImages.fulfilled, (state, action) => {
            state.command.cancelMassImages.status = "success"

            // confirmMasspointsMissing 
        }).addCase(confirmMasspointsMissing.pending, (state, action) => {
            state.command.confirmMasspointsMissing.status = 'pending'
        }).addCase(confirmMasspointsMissing.fulfilled, (state, action) => {
            state.command.confirmMasspointsMissing.status = "success"
        })
    }
})

export const {
    resetState,
    selectMassImage,
    saveMassDefinitionCompleted,
} = massMaskMassImageSlice.actions

export default massMaskMassImageSlice.reducer