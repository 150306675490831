import { SelectionListComponent } from "shared/components/selectionComponent/selectionList.component";
import { DropdownComponent } from "shared/shared";
import { Grid, styled } from "@mui/material";
import theme from "shared/style/theme";
import { IMassPointCriteriaItem } from '../../components/policy_editor.model';
import IAvailableProductLine from "models/available_basedata/available_product_line";
import IAvailableBodyArea from "models/available_basedata/available_body_area";
import { IAdditionPolicyInformation } from "models/additions/addition_policy_information";
import { AdditionPlaceHolderTypeEnum } from "models/additions/addition_placeholder.enum";
import { EditorDescriptionComponent } from "shared/components/editors/editor_description/editor_description.style";
import { ISelectionList } from "shared/components/selectionComponent/models/selectionList";

interface PolicyAdditionRankEditor {
    selectedAddition: IAdditionPolicyInformation,
    allAdditions: IAdditionPolicyInformation[],
    selectedMainProductLine: IAvailableProductLine,
    allMainProductLines: IAvailableProductLine[],
    selectedBodyArea: IAvailableBodyArea,
    allBodyAreas: IAvailableBodyArea[],
    articleTypeSelection: ISelectionList,
    allMasspoints1: IMassPointCriteriaItem[],
    allMasspoints2: IMassPointCriteriaItem[],
    selectedMasspoint1: IMassPointCriteriaItem,
    selectedMasspoint2: IMassPointCriteriaItem,
    selectAddition: (addition: IAdditionPolicyInformation) => void;
    selectMainProductLine: (mainProductLine: IAvailableProductLine) => void;
    selectBodyArea: (bodyArea: IAvailableBodyArea) => void;
    selectMasspoint1: (masspoint: IMassPointCriteriaItem) => void;
    selectMasspoint2: (masspoint: IMassPointCriteriaItem) => void;
    filterSelectedArticleTypes: (text: string) => {};
    filterUnselectedArticleTypes: (text: string) => {};
    selectArticleTypes: (ids: number[]) => {};
    unSelectArticleTypes: (ids: number[]) => {};
}

export const PolicyAdditionRankEditorComponent: React.FC<PolicyAdditionRankEditor> = ({
    selectedAddition,
    allAdditions,
    selectedMainProductLine,
    allMainProductLines,
    selectedBodyArea,
    allBodyAreas,
    allMasspoints1,
    allMasspoints2,
    selectedMasspoint1,
    selectedMasspoint2,
    articleTypeSelection,
    selectAddition,
    selectMainProductLine,
    selectBodyArea,
    selectMasspoint1,
    selectMasspoint2,
    filterSelectedArticleTypes,
    filterUnselectedArticleTypes,
    selectArticleTypes,
    unSelectArticleTypes,
}) => {

    const renderArticleTypes = <SelectionListComponent
        title={"Zu beachtende Artikelarten"}
        entityName={"Artikelarten"}
        onFilterSelected={(searchText) => { filterSelectedArticleTypes(searchText) }}
        onFilterUnselected={(searchText) => { filterUnselectedArticleTypes(searchText) }}
        selectionList={articleTypeSelection}
        selectEntities={(ids) => { selectArticleTypes(ids) }}
        unselectEntities={(ids) => { unSelectArticleTypes(ids) }}
    />

    let errorText = ""
    if (selectedAddition) {
        errorText = selectedAddition.placeholderType !== AdditionPlaceHolderTypeEnum.RangeInput ? " Der Zusatz ist nicht als “Rangewert” konfiguriert." : "";
    }

    return (
        <Grid item container md={12}
            direction="column"
            spacing={3}>
            <Grid container item md={12} spacing={3}
                direction="column">
                <Grid item>
                    <DropdownComponent
                        name="Zusatz"
                        data={allAdditions}
                        minWidth="350px"
                        isRequired={true}
                        onSelect={(e) => selectAddition(e)}
                        displayedProperties={["option", "description"]}
                        selectedValue={selectedAddition}
                        errorText={errorText}
                    />
                </Grid>
                {selectedAddition && selectedAddition.placeholderType === AdditionPlaceHolderTypeEnum.RangeInput ?
                    <Grid item>
                        <EditorDescriptionComponent>{`Die Range geht von ${selectedAddition.placeholder?.rangePlaceholder?.valueStart} bis ${selectedAddition.placeholder?.rangePlaceholder?.valueEnd}`}</EditorDescriptionComponent>
                    </Grid> : <></>
                }
            </Grid>
            <Grid container item md={12} spacing={3}
                direction="row">
                <Grid item>
                    <DropdownComponent
                        name="Hauptproduktlinie"
                        minWidth="350px"
                        data={allMainProductLines}
                        isLoading={false}
                        isRequired={true}
                        onSelect={selectMainProductLine}
                        displayedProperties={["name"]}
                        selectedValue={selectedMainProductLine}
                    />
                </Grid>
                <Grid item>
                    <DropdownComponent
                        name="Körperregion"
                        data={allBodyAreas}
                        minWidth="350px"
                        isLoading={false}
                        isRequired={true}
                        onSelect={selectBodyArea}
                        displayedProperties={["name"]}
                        selectedValue={selectedBodyArea}
                    />
                </Grid>
            </Grid>
            <Grid container item md={12} spacing={3}
                direction="row">
                <Grid item>
                    <DropdownComponent
                        name="1. Maßpunkt"
                        data={allMasspoints1}
                        minWidth="350px"
                        isLoading={false}
                        isRequired={true}
                        onSelect={selectMasspoint1}
                        displayedProperties={["displayName"]}
                        selectedValue={selectedMasspoint1}
                    />
                </Grid>
                <Grid item>
                    <DropdownComponent
                        name="2. Maßpunkt"
                        data={allMasspoints2}
                        minWidth="350px"
                        isLoading={false}
                        isRequired={true}
                        onSelect={selectMasspoint2}
                        displayedProperties={["displayName"]}
                        selectedValue={selectedMasspoint2}
                    />
                </Grid>
            </Grid>
            <Grid item
                direction="column">
                {renderArticleTypes}
            </Grid>
        </Grid>
    );
};

export const ErrorTextComponent = styled('div')({
    color: theme.colors.text,
    display: 'flex',
    alignItems: 'flex-end',
});