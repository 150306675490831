import { createAsyncThunk } from '@reduxjs/toolkit';
import CheckTypeService from 'services/checktype.service';
import { RootState } from '../../../app/redux_store';
import BaseDataService from '../../../services/base_data.service';
import PlausibilityCheckService from '../../../services/check.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';

export const initData = createAsyncThunk(
    'check_duplicate/initData',
    async (checkId: number, thunkApi) => {
        await Promise.all([
            thunkApi.dispatch(fetchBaseData()),
            thunkApi.dispatch(fetchCheckTypes()),
            thunkApi.dispatch(fetchCheck(checkId))
        ]);
    }
)

export const fetchCheck = createAsyncThunk(
    'check_duplicate/fetchCheck',
    async (checkId: number, thunkApi) => {
        const response = await PlausibilityCheckService.getCheck(checkId);
        handleResponseErrors(response, "Prüfung");
        return response;
    }
)

export const fetchBaseData = createAsyncThunk(
    'check_duplicate/fetchBaseData',
    async (_, thunkApi) => {
        const response = await BaseDataService.getBaseData();
        handleResponseErrors(response, "Basisdaten");
        return response;
    }
)

export const fetchCheckTypes = createAsyncThunk(
    'check_duplicate/fetchCheckTypes',
    async (_, thunkApi) => {
        const response = await CheckTypeService.getCheckTypes();
        handleResponseErrors(response, "Prüfungstypen");
        return response;
    }
)

export const duplicateCheck = createAsyncThunk(
    'check_duplicate/duplicateCheck',
    async (_, { getState }) => {
        const actualState = (getState() as RootState).check_duplicate.actualData;

        const response = await PlausibilityCheckService.duplicateCheck({
            checkId: actualState.check.id,
            mainProductLineId: actualState.selectedMainProductLine.id,
            articleTypeId: actualState.selectedArticleType.id,
            qualityId: actualState.selectedQuality.id
        });

        handleResponseErrors(response, "Prüfung");
        return response;
    }
)