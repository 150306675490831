import { EnumDescriptor } from "models/enum_descriptor";

export enum SideSeamModeEnum
{
    Undefinied = 0,
    Normal = 1,
    SideSeam = 2,
    Both = 3
 }

 export const SideSeamModeEnumValues: Array<
  EnumDescriptor<SideSeamModeEnum>
> = [
    { value: SideSeamModeEnum.Undefinied, text: "Nicht definiert" },
    { value: SideSeamModeEnum.Normal, text: "Nein" },
    { value: SideSeamModeEnum.SideSeam, text: "Ja" },
    { value: SideSeamModeEnum.Both, text: "Beides" }, 
  ];

export const SideSeamModeEnumValuesLookup = (value: SideSeamModeEnum) => SideSeamModeEnumValues.find(x => x.value === value).text;

export const SideSeamModeEnumLookupForEnum = (value: string) => SideSeamModeEnumValues.find(x => x.text === value).value;
