import React from "react";
import { ServiceDataComponent } from '../servicedata/addition_servicedata.component';
import { ValidityComponent } from '../validities/addition_validity.component';
import { PlaceHolderComponent } from '../placeholders/addition_placeholder.component';
import { AdditionAttributeCategoriesComponent } from "../attributes/addition_attribute_categories.component";
import { AdditionFootTypeComponent } from "../foottype/addition_foottype.component";
import { BoxItem, TabItem, TabListItem, TabItemSpacer } from "./addition_tab.style";
import { Grid } from "@mui/material";
import { IAdditionInitial, IAdditionEditor, IAdditionEditorAction } from "models/additions/addition_editor.model";
import { AdditionExcludesComponent } from "../excludes/addition_excludes.component";
import { GussetComponent } from "../gussets/addition_gusset.component";

interface AdditionTabComponentComponentProps {
    additionInitial: IAdditionInitial,
    additionEditor: IAdditionEditor,
    additionEditorActions: IAdditionEditorAction,
}

export const AdditionTabComponent: React.FC<AdditionTabComponentComponentProps> = ({
    additionInitial,
    additionEditor,
    additionEditorActions,
}) => {
    const [tabValue, setTabValue] = React.useState('1');

    const handleTabChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    const getTabStyleForIndex = (indexOfTab: string) => {
        let isVisible = false;
        if (indexOfTab === tabValue) {
            isVisible = true;
        }
        return isVisible;
    }

    return <>
        <BoxItem>
            <TabListItem onChange={handleTabChange} value={tabValue}>
                <TabItem label="Servicedaten"
                         key="servicedata"
                         value='1'/>
                <TabItem label="Gültigkeitsbereiche"
                         key="validity"
                         value='2'/>
                <TabItem label="Werte"
                         key="placeholder"
                         value='3'/>
                <TabItem label="Ausschlüsse"
                         key="excludes"
                         value='4'/>
                <TabItem label="Zwickel"
                         key="gusset"
                         value='5'/>
                <TabItemSpacer/>
            </TabListItem>
            <Grid item
                  sx={getTabStyleForIndex("1") ? {
                      display: 'block',
                      paddingTop: '20px',
                  } : { display: 'none' }}>
                <ServiceDataComponent additionInital={additionInitial}
                                      additionEditor={additionEditor}
                                      additionEditorActions={additionEditorActions}/>
            </Grid>
            <Grid item
                  sx={getTabStyleForIndex("2") ? {
                      display: 'block',
                      paddingTop: '20px',
                  } : { display: 'none' }}>
                <ValidityComponent additionInitial={additionInitial}
                                   additionEditor={additionEditor}
                                   additionEditorActions={additionEditorActions}/>
            </Grid>
            <Grid item
                  sx={getTabStyleForIndex("3") ? {
                      display: 'block',
                      paddingTop: '20px',
                  } : { display: 'none' }}>
                <PlaceHolderComponent additionInitial={additionInitial}
                                      additionEditor={additionEditor}
                                      additionEditorActions={additionEditorActions}/>
            </Grid>
            <Grid item
                  sx={getTabStyleForIndex("4") ? {
                      display: 'block',
                      paddingTop: '20px',
                  } : { display: 'none' }}>
                <div>
                    <AdditionExcludesComponent
                        additionEditor={additionEditor}
                        additionEditorActions={additionEditorActions}/>
                    <AdditionAttributeCategoriesComponent
                        additionInitial={additionInitial}
                        additionEditor={additionEditor}
                        additionEditorActions={additionEditorActions}/>
                    <AdditionFootTypeComponent
                        additionEditor={additionEditor}
                        additionEditorActions={additionEditorActions}/>
                </div>
            </Grid>
            <Grid item
                  sx={getTabStyleForIndex("5") ? {
                      display: 'block',
                      paddingTop: '20px',
                  } : { display: 'none' }}>
                <GussetComponent additionEditor={additionEditor}
                                 additionEditorActions={additionEditorActions.gussets}/>
            </Grid>
        </BoxItem>
    </>
}