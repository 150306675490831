import { GridColumnMenu, GridColumnMenuProps } from "@mui/x-data-grid-pro";

export const CustomColumnMenu =(props: GridColumnMenuProps) => {
    return (
        <GridColumnMenu
            {...props}
            slots={{
                columnMenuColumnsItem: null,
            }}
        />
    );
}