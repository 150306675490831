import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CharacteristicCategoryEditState } from "./characteristic_category_edit.model";
import { filterValues, initializeData, moveValueFromSelectedToUnselected, moveValueFromUnselectedToSelected, updateCanSave } from "./characteristic_category_edit.reducer";
import { cancelSave, getCharacteristicCategory, getCharacteristicTypes, initData, updateCharacteristicCategory } from "./characteristic_category_edit.thunks";
import { ICharacteristicTypeOverview } from "models/characteristic_types/characteristic_type_overview";

const initialState: CharacteristicCategoryEditState = {
    data: { 
        characteristicCategory: {
            name: "", 
            selectionValues: {
                selectedList: 
                {
                    searchText: "",
                    allItems: [],
                    filteredItems: []
                },
                unSelectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: []
                }
            }
        }     
    },
    command:{
        updateCharacteristicCategory: { status: "idle", canExecute: false },
        cancelSave: { status: "idle", canExecute: true }
    },
    query: {              
        initData: { status: "idle", canExecute: true },
        fetchCharacteristicCategory: { status: "idle", canExecute: true },
        fetchCharacteristicTypes: { status: "idle", canExecute: true },
    },
    loadedData: {
        characteristicCategory: undefined,
        characteristicTypes: [],
    }
}

export const updateCharacteristicCategorySlice = createSlice({
    name: 'update_characteristic_category',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = initialState.data;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        changeName: (state, action: PayloadAction<string>) => {
            state.data.characteristicCategory.name = action.payload;
            updateCanSave(state);
        }, 
        completedSave: (state) => {
            state.command.updateCharacteristicCategory = initialState.command.updateCharacteristicCategory;
        },
        selectType: (state, action: PayloadAction<ICharacteristicTypeOverview>) => {
            state.data.selectedCharacteristicType = action.payload;
            state.data.characteristicCategory.selectionValues = {
                selectedList: 
                {
                    searchText: "",
                    allItems: [],
                    filteredItems: []
                },
                unSelectedList: {
                    searchText: "",
                    allItems: action.payload.values.map(x =>{  return {
                        displayName: x.name,
                        id: x.id,
                    }}),
                    filteredItems: []
                }
            };
            filterValues(state.data.characteristicCategory.selectionValues.unSelectedList);
        },
        filterSelectedValues: (state, action: PayloadAction<string>) => {
            state.data.characteristicCategory.selectionValues.selectedList.searchText = action.payload;
            filterValues(state.data.characteristicCategory.selectionValues.selectedList);
        },
        filterUnselectedValues: (state, action: PayloadAction<string>) => {
            state.data.characteristicCategory.selectionValues.unSelectedList.searchText = action.payload;
            filterValues(state.data.characteristicCategory.selectionValues.unSelectedList);
        },
        selectValues: (state, action: PayloadAction<number[]>) => {
            moveValueFromUnselectedToSelected(state, action.payload);
            updateCanSave(state);
        },      
        unSelectValues: (state, action: PayloadAction<number[]>) => {
            moveValueFromSelectedToUnselected(state, action.payload);
            updateCanSave(state);
        },   
    }, extraReducers: (builder) => {
        // updateCharacteristicCategory
        builder.addCase(updateCharacteristicCategory.pending, (state) => {
            state.command.updateCharacteristicCategory.status = 'pending'
            state.command.updateCharacteristicCategory.canExecute = false;
        }).addCase(updateCharacteristicCategory.rejected, (state, action) => {
            state.command.updateCharacteristicCategory.status = "error"
            state.command.updateCharacteristicCategory.canExecute = true;
            state.command.updateCharacteristicCategory.message = action.error.message;
        }).addCase(updateCharacteristicCategory.fulfilled, (state) => {
            state.command.updateCharacteristicCategory.status = "success"
            state.command.updateCharacteristicCategory.canExecute = false;

        // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancelSave.status = 'pending'
            state.command.cancelSave.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancelSave.status = "success"
            state.command.cancelSave.canExecute = false;

        // getCharacteristicCategory
        }).addCase(getCharacteristicCategory.pending, (state) => {
            state.query.fetchCharacteristicCategory.status = "pending"
            state.query.fetchCharacteristicCategory.canExecute = false;
        }).addCase(getCharacteristicCategory.rejected, (state, action) => {
            state.query.fetchCharacteristicCategory.status = "error"
            state.query.fetchCharacteristicCategory.message = action.error.message;
            state.query.fetchCharacteristicCategory.canExecute = true;
        }).addCase(getCharacteristicCategory.fulfilled, (state, action) => {
            state.query.fetchCharacteristicCategory.status = "success"
            state.query.fetchCharacteristicCategory.canExecute = true;
            state.loadedData.characteristicCategory = action.payload.getData();

        // getCharacteristicTypes
        }).addCase(getCharacteristicTypes.pending, (state) => {
            state.query.fetchCharacteristicTypes.status = "pending"
            state.query.fetchCharacteristicTypes.canExecute = false;
        }).addCase(getCharacteristicTypes.fulfilled, (state, action) => {
            state.query.fetchCharacteristicTypes.status = "success"
            state.query.fetchCharacteristicTypes.canExecute = true;
            state.loadedData.characteristicTypes = action.payload.getData();

            // initData
        }).addCase(initData.pending, (state) => {
            state.query.initData.status = "pending"
            state.query.initData.canExecute = false;
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.canExecute = true;
            state.query.initData.message = action.error.message;
        }).addCase(initData.fulfilled, (state) => {
            state.query.initData.status = "success"
            state.query.initData.canExecute = true;
            initializeData(state);
            updateCanSave(state);
        })
    }
})

export const {
    resetState,
    changeName,
    completedSave,
    selectType,
    filterSelectedValues,
    filterUnselectedValues,
    selectValues,
    unSelectValues,
} = updateCharacteristicCategorySlice.actions

export default updateCharacteristicCategorySlice.reducer