import { createSlice } from '@reduxjs/toolkit';
import { MissingMasspointsAvailableBodyAreaState } from './missing_masspoints.model';
import { fetchBodyAreas } from './missing_masspoints.thunks';

const initialState: MissingMasspointsAvailableBodyAreaState = {
    data: { 
        bodyareas: [] ,
    },
    query: {
        fetchAvailableBodyAreas: { status: "idle", canExecute: true },
    },
}

export const missingMasspointSlice = createSlice({
    name: 'dialogs/missing_masspoints/',
    initialState,
    reducers: {
        resetState: (state) => {
            state.query = initialState.query;
            state.data = initialState.data;
        },
    }, extraReducers: (builder) => {

        // fetch BodyAreas
        builder.addCase(fetchBodyAreas.pending, (state, action) => {
            state.query.fetchAvailableBodyAreas.status = "pending"
            state.query.fetchAvailableBodyAreas.canExecute = false;
        }).addCase(fetchBodyAreas.rejected, (state, action) => {
            state.query.fetchAvailableBodyAreas.status = "error"
            state.query.fetchAvailableBodyAreas.message = action.error.message;
            state.query.fetchAvailableBodyAreas.canExecute = true;
        }).addCase(fetchBodyAreas.fulfilled, (state, action) => {
            state.query.fetchAvailableBodyAreas.status = "success"
            state.query.fetchAvailableBodyAreas.message = undefined;
            state.query.fetchAvailableBodyAreas.canExecute = true;
            state.data.bodyareas = action.payload.getData();
        })
    }
})

export const {  
    resetState,
} = missingMasspointSlice.actions

export default missingMasspointSlice.reducer