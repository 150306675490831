import React from "react";
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { AppDispatch } from '../../app/redux_store';
import { LoadingOverlayContainer } from 'shared/components/loading_overlay/loading_overlay.component';
import { LoadingOverlayComponent } from '../../shared/components/loading_overlay/loading_overlay.component';
import { Grid } from "@mui/material";
import { MassPointCriteriaListComponent } from '../../shared/components/criteriaListComponent/masspoint_criteria_list.component';
import { DisabledTextBoxComponent } from "shared/shared";
import { cancelSave, fetchAllData, updateOneLeg } from "./redux/oneleg_edit.thunks";
import { deselectMassPointCriteria, filterAvailableMassPointCriteria, filterSelectedMassPointCriteria, resetEditOneLeg, resetState, selectMassPointCriteria } from "./redux/oneleg_edit.slice";
import * as style from "./oneleg_edit.style";
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from "react-router-dom";
import { PaperContentFitItem } from "shared/style/paper";
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";

export const OneLegsEditComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.one_leg_edit);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { onelegid } = useParams();

    useEffect(() => {
        dispatch(fetchAllData(onelegid));

        return () => {
            dispatch(resetState());
        }
    }, [dispatch, onelegid]);

    if (state.query.fetchAllData.status === "error") {
        enqueueSnackbar("Fehler beim Laden der Basisdaten", { variant: "error" });
        dispatch(resetState());
    }

    if (state.query.getOneLeg.status === "error") {
        enqueueSnackbar(state.query.getOneLeg.message, { variant: "error" });
        navigate("/massmasks/onelegs/overview");
        dispatch(resetState());
    }

    if (state.command.editOneLeg.status === "success") {
        enqueueSnackbar("Einbeinhose erfolgreich editiert", { variant: "success" });
        navigate("/massmasks/onelegs/overview");
        dispatch(resetEditOneLeg());
    }

    if (state.command.editOneLeg.status === "error") {
        enqueueSnackbar(state.command.editOneLeg.message, { variant: "error" });
        dispatch(resetEditOneLeg());
    }

    if (state.command.cancel.status === "success") {
        navigate("/massmasks/onelegs/overview");
        dispatch(resetState());
    }

    const isEditingOneLeg = state.command.editOneLeg.status === "pending";

    return (
        <LoadingOverlayContainer>
            {isEditingOneLeg
                ? <LoadingOverlayComponent />
                : <></>
            }
            <Grid
                container
                spacing={3}>
                <Grid
                    container
                    item
                    direction="row">
                    <Grid
                        item>
                        <DisabledTextBoxComponent
                            name="Hauptproduktlinie"
                            value={state.actualData.selectedProductLine?.name}
                            isRequired={false}
                        />
                    </Grid>
                    <Grid
                        item>
                        <DisabledTextBoxComponent
                            name="Artikelart"
                            value={`${state.actualData.selectedArticleType?.erpId} ${state.actualData.selectedArticleType?.name}`}
                            isRequired={false}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item>
                    <h3>Maßpunktfilter</h3>
                    <p>Diese Maßpunkte werden in der Maßmaske ausgeblendet.</p>

                    <PaperContentFitItem>
                        <Grid item container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            wrap="nowrap">
                            <Grid item>
                                <MassPointCriteriaListComponent
                                    type="remove"
                                    title="Folgende Maßpunkte werden ausgeblendet"
                                    items={state.actualData.massPointCriteria.selected.filteredItems}
                                    onClick={(index) => dispatch(deselectMassPointCriteria(index))}
                                    onFilter={(searchText) => dispatch(filterSelectedMassPointCriteria(searchText))}
                                />
                            </Grid>
                            <Grid item>
                                <style.Spacer />
                            </Grid>
                            <Grid item>
                                <MassPointCriteriaListComponent
                                    type="add"
                                    title="Verfügbare Maßpunkte"
                                    items={state.actualData.massPointCriteria.available.filteredItems}
                                    onClick={(index) => dispatch(selectMassPointCriteria(index))}
                                    onFilter={(searchText) => dispatch(filterAvailableMassPointCriteria(searchText))}
                                />
                            </Grid>
                        </Grid>
                    </PaperContentFitItem>
                </Grid>
                <EditorButtonComponent
                    canExecute={state.command.editOneLeg.canExecute}
                    save={() => dispatch(updateOneLeg())}
                    cancelSave={() => dispatch(cancelSave())}
                />
            </Grid>
        </LoadingOverlayContainer>
    );
}