import { PositionCategoryItemDetailsState } from "./position_category_item_details.model";
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getPositionCategory, goBackToOverview} from ".//position_category_item_details.thunks";
import { extractPositionCategoryItemDetails, filterPositionCategoryItemDetails } from "./position_category_item_details.reducer";
import { GridFilterModel } from "@mui/x-data-grid-pro";

const initialState: PositionCategoryItemDetailsState = {
    loadedData: {
        positionCategory: null,
    },
    actualData: {
        positionCategoryItemDetails: [],
        filteredPositionCategoryItemDetails: [],
        searchFilter: "",
        gridFilters: {items: []}
    },
    query: {
        getPositionCategory: { status: "idle", canExecute: true },
    },
    command: {
        goBackToOverview: { status: "idle", canExecute: false },
    },
}

export const positionCategoryItemDetailSlice = createSlice({
    name: 'positionCategory/positioncategoryitemdetail',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loadedData = initialState.loadedData;
            state.actualData = initialState.actualData;
            state.query = initialState.query;
            state.command = initialState.command;
        },
        setGridFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.gridFilters = action.payload;
            filterPositionCategoryItemDetails(state);
        }, 
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.actualData.searchFilter = action.payload;
            filterPositionCategoryItemDetails(state);
        }
    }, extraReducers: (builder) => {
        // getPositionCaregory bodyAreas
        builder.addCase(getPositionCategory.pending, (state, action) => {
            state.query.getPositionCategory.status = "pending"
            state.query.getPositionCategory.canExecute = false;
        }).addCase(getPositionCategory.rejected, (state, action) => {
            state.query.getPositionCategory.status = "error"
            state.query.getPositionCategory.message = action.error.message;
            state.query.getPositionCategory.canExecute = true;
        }).addCase(getPositionCategory.fulfilled, (state, action) => {
            state.query.getPositionCategory.status = "success"
            state.query.getPositionCategory.message = undefined;
            state.query.getPositionCategory.canExecute = true;
            const positionCategory = action.payload.getData();
            state.loadedData.positionCategory = positionCategory;
            state.actualData.positionCategoryItemDetails = extractPositionCategoryItemDetails(positionCategory); 
            state.actualData.filteredPositionCategoryItemDetails = extractPositionCategoryItemDetails(positionCategory); 
            
          // goBackToOverview
        }).addCase(goBackToOverview.pending, (state, action) => {
            state.command.goBackToOverview.status = "pending"
        }).addCase(goBackToOverview.fulfilled, (state, action) => {
            state.command.goBackToOverview.status = "success"          
        })
    }
})

export const {
    resetState,
    setSearchFilter,
    setGridFilters
} = positionCategoryItemDetailSlice.actions

export default positionCategoryItemDetailSlice.reducer