import { createSlice } from '@reduxjs/toolkit';
import { MassmaskHistoryDetailsState } from './massmask_history_details.model';
import { fetchMassmaskHistoryDetails } from './massmask_history_details.thunks';

const initialState: MassmaskHistoryDetailsState = {
    data: {
        massmaskHistoryDetails: null,
    },
    query: {
        fetchMassmaskHistoryDetails: { status: "idle", canExecute: true },
    },
}

export const getMassmaskHistoryDetailsSlice = createSlice({
    name: 'massmask_history/massmask_history_details',
    initialState,
    reducers: {
    }, extraReducers: (builder) => {

        // fetch MassmaskHistoryOverviews
        builder.addCase(fetchMassmaskHistoryDetails.pending, (state, action) => {
            state.query.fetchMassmaskHistoryDetails.status = "pending"
            state.query.fetchMassmaskHistoryDetails.canExecute = false;
        }).addCase(fetchMassmaskHistoryDetails.rejected, (state, action) => {
            state.query.fetchMassmaskHistoryDetails.status = "error"
            state.query.fetchMassmaskHistoryDetails.message = action.error.message;
            state.query.fetchMassmaskHistoryDetails.canExecute = true;
        }).addCase(fetchMassmaskHistoryDetails.fulfilled, (state, action) => {
            state.query.fetchMassmaskHistoryDetails.status = "success"
            state.query.fetchMassmaskHistoryDetails.message = undefined;
            state.query.fetchMassmaskHistoryDetails.canExecute = true;
            state.data.massmaskHistoryDetails = action.payload.getData();
        })
    }
})

export default getMassmaskHistoryDetailsSlice.reducer