import { HistoryValues } from "models/history_type.enum";
import { CheckHistoryOverviewsState } from "./check_history.model";
import { filterCheckHistoryOverviewValue, stringify } from "../check_history.helper";

export const filterCheckHistoryOverviews = (state: CheckHistoryOverviewsState) => {
    const searchTextLower = state.actualData.searchFilter.toLocaleLowerCase().trim();
    let filteredItems = state.loadedData.checkHistoryOverviews.filter(
        (checkHistoryOverview) =>{
            const type = HistoryValues.find(x => x.value === checkHistoryOverview.type)?.text;

            return (
                type?.toLowerCase().includes(searchTextLower) ||
                checkHistoryOverview.userName?.toLowerCase().includes(searchTextLower) ||
                checkHistoryOverview.mainProductLineName?.toLowerCase().includes(searchTextLower) ||
                stringify(checkHistoryOverview.bodyAreaName)?.toLowerCase().includes(searchTextLower) ||
                stringify(checkHistoryOverview.articleTypeName)?.toLowerCase().includes(searchTextLower) ||
                stringify(checkHistoryOverview.qualityName)?.toLowerCase().includes(searchTextLower) ||
                checkHistoryOverview.checkTypeName?.toLowerCase().includes(searchTextLower));
        }
    );

    state.actualData.gridFilters.items.forEach(fil => {
        filteredItems = filterCheckHistoryOverviewValue(fil, filteredItems);
    });
    
    state.actualData.checkHistoryOverviews = filteredItems;

}