import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormulaTemplateEnum } from '../../../models/plausibility_check/enums/formula_template.enum';
import { ICheckTypeMessageCreateModel, PlausibilityCheckTypeState } from './type_create.model';
import { toCheckTypeMessageCreateModel, updateCanCreatePlausibilityCheckType } from './type_create.reducer';
import { createPlausibilityCheckType, getCheckTypeMessages } from './type_create.thunks';

const initialState: PlausibilityCheckTypeState = {
    loadedData: {
        checkTypeMessages: null,
    },
    actualData: {
        checkTypeToCreate: {
            name: "",
            formulaTemplate: FormulaTemplateEnum.Undefined,
            warningMessageKey: null,
            errorMessageKey: null,
            isUniqueRules: true,
            isBestMatchEnabled: true,
        },
        selectedWarningMessage: null,
        selectedErrorMessage: null,
        warningMessages: [],
        errorMessages: [],
    },
    query: {
        getCheckTypeMessages: { status: "idle", canExecute: false },
    },
    command: {
        createPlausibilityCheckType: { status: "idle", canExecute: false },
        cancelPlausibilityCheckType: { status: "idle", canExecute: false },
    },
}

export const createPlausibilityCheckTypeSlice = createSlice({
    name: 'create_plausibility_check_type',
    initialState,
    reducers: {
        updateName: (state, action: PayloadAction<string>) => {
            state.actualData.checkTypeToCreate.name = action.payload;
            updateCanCreatePlausibilityCheckType(state);
        },
        updateFormula: (state, action: PayloadAction<FormulaTemplateEnum>) => {
            state.actualData.checkTypeToCreate.formulaTemplate = action.payload;
            updateCanCreatePlausibilityCheckType(state);
        },
        updateWarningMessageKey: (state, action: PayloadAction<ICheckTypeMessageCreateModel>) => {
            const selectedItem = action.payload;
            state.actualData.selectedWarningMessage = selectedItem;
            state.actualData.checkTypeToCreate.warningMessageKey = selectedItem.message?.key;
        },
        updateErrorMessageKey: (state, action: PayloadAction<ICheckTypeMessageCreateModel>) => {
            const selectedItem = action.payload;
            state.actualData.selectedErrorMessage = selectedItem;
            state.actualData.checkTypeToCreate.errorMessageKey = selectedItem.message?.key;
        },
        toggleIsUniqueRules: (state, action: PayloadAction<boolean>) => {
            state.actualData.checkTypeToCreate.isUniqueRules = action.payload;
        },
        toggleIsBestMatch: (state, action: PayloadAction<boolean>) => {
            state.actualData.checkTypeToCreate.isBestMatchEnabled = action.payload;
        },
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        createPlausibilityCheckTypeFailed: (state) => {
            state.command.createPlausibilityCheckType.status = "idle";
            updateCanCreatePlausibilityCheckType(state);
        },
        cancelCheck: (state) => {
            state.command.cancelPlausibilityCheckType.status = "success";
        }
    }, extraReducers: (builder) => {
        // create PlausibiltyCheckType
        builder.addCase(createPlausibilityCheckType.pending, (state, action) => {
            state.command.createPlausibilityCheckType.status = "pending"
            state.command.createPlausibilityCheckType.canExecute = false;
        }).addCase(createPlausibilityCheckType.rejected, (state, action) => {
            state.command.createPlausibilityCheckType.status = "error"
            state.command.createPlausibilityCheckType.canExecute = true;
            state.command.createPlausibilityCheckType.message = action.error.message;
        }).addCase(createPlausibilityCheckType.fulfilled, (state, action) => {
            state.command.createPlausibilityCheckType.status = "success"
            state.command.createPlausibilityCheckType.message = undefined;
            state.command.createPlausibilityCheckType.canExecute = true;
            state.actualData.checkTypeToCreate =
            {
                name: "",
                formulaTemplate: FormulaTemplateEnum.Undefined,
                warningMessageKey: null,
                errorMessageKey: null,
                isUniqueRules: true,
                isBestMatchEnabled: true,
            }
            state.actualData.selectedWarningMessage = null;
            state.actualData.selectedErrorMessage = null;

            // getCheckTypeMessages
        }).addCase(getCheckTypeMessages.pending, (state, action) => {
            state.query.getCheckTypeMessages.status = "pending"
            state.query.getCheckTypeMessages.canExecute = false;
            state.command.createPlausibilityCheckType.canExecute = false;
        }).addCase(getCheckTypeMessages.rejected, (state, action) => {
            state.query.getCheckTypeMessages.status = "error"
            state.query.getCheckTypeMessages.canExecute = true;
            state.command.createPlausibilityCheckType.canExecute = false;
            state.query.getCheckTypeMessages.message = action.error.message;
        }).addCase(getCheckTypeMessages.fulfilled, (state, action) => {
            state.query.getCheckTypeMessages.status = "success"
            state.query.getCheckTypeMessages.message = undefined;
            state.query.getCheckTypeMessages.canExecute = true;

            const messages = action.payload.getData();
            state.loadedData.checkTypeMessages = messages;

            const warningMessages = [
                {
                    displayValue: "Keine Auswahl",
                    message: null
                },
                ...messages.warningMessages.map(toCheckTypeMessageCreateModel)];

            const errorMessages = [
                {
                    displayValue: "Keine Auswahl",
                    message: null
                },
                ...messages.errorMessages.map(toCheckTypeMessageCreateModel)];

            state.actualData.warningMessages = warningMessages;
            state.actualData.errorMessages = errorMessages;
            state.actualData.selectedWarningMessage = warningMessages[0];
            state.actualData.selectedErrorMessage = errorMessages[0];
            updateCanCreatePlausibilityCheckType(state);
        })
    }
})

export const {
    updateName,
    updateFormula,
    resetState,
    cancelCheck,
    updateWarningMessageKey,
    updateErrorMessageKey,
    toggleIsUniqueRules,
    toggleIsBestMatch,
    createPlausibilityCheckTypeFailed } = createPlausibilityCheckTypeSlice.actions

export default createPlausibilityCheckTypeSlice.reducer