import * as style from "./massmask_information.style";
import IMassmask from '../../models/massmasks/massmask';

interface MassmaskInformationComponentProps {
    massMask: IMassmask
}

export const MassmaskInformationComponent: React.FC<MassmaskInformationComponentProps> = ({ massMask }) => {

    const getMassmaskInformation = (massMask: IMassmask) => {
        if (massMask.articleType != null) {
            return `${massMask.mainProductLine.name} / ${massMask.bodyArea.name}  / ${massMask.articleType.erpId} ${massMask.articleType.name} `
        } else {
            return `${massMask.mainProductLine.name} / ${massMask.bodyArea.name}  / ${massMask.footType.externalIdentifier} ${massMask.footType.name} `
        }
    }

    return <style.HierarchyInfoWrapper>
        <style.HierarchyList>
            {getMassmaskInformation(massMask)}
        </style.HierarchyList>
    </style.HierarchyInfoWrapper>
}