import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "app/redux_store";
import MappingPositionService from "services/mapping_position.service";
import { handleResponseErrors } from "shared/networking/error_handling";
import { getPositionMapping } from "./position_mapping.reducer";

export const uploadFile = createAsyncThunk(
    'positionmapping/uploadFile',
    async (_, { getState, dispatch }) => {
        const state = getState() as RootState;
        const selectedFile = state.position_mapping.actualData.upload.file;

        const response = await MappingPositionService.uploadMappingFileFile(selectedFile);

        handleResponseErrors(response, "Mapping gespeichert");
        await dispatch(initData());
        return response;
    }
)

export const initData = createAsyncThunk(
    'positionmapping/initData',
    async (_, { dispatch }) => {
        await Promise.all([
            dispatch(fetchLegacyPositions()),
            dispatch(fetchConfiguredPositions())
        ]);
    }
)

export const fetchLegacyPositions = createAsyncThunk(
    'positionmapping/fetchLegacyPositions',
    async (thunkApi) => {
        const response = await MappingPositionService.getLegacyPositions();
        handleResponseErrors(response, "Alte Positionen");
        return response;
    }
)

export const fetchConfiguredPositions = createAsyncThunk(
    'positionmapping/fetchConfiguredPositions',
    async (thunkApi) => {
        const response = await MappingPositionService.getConfiguredPositions();
        handleResponseErrors(response, "Konfigurierte Positionen");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'positionmapping/cancelSave',
    async (_) => { }
)

export const updatePositionsMapping = createAsyncThunk(
    'positionmapping/uploadMapping',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const mappedPositions = getPositionMapping(state.position_mapping);
        const response = await MappingPositionService.updatePositionsMapping(mappedPositions);

        handleResponseErrors(response, "Zusätzliche Angaben");
        return response;
    }
)

export const confirmDelete = createAsyncThunk(
    'positionmapping/confirmDelete',
    async (_, { getState }) => {
        const state = getState() as RootState;

        const response = await MappingPositionService.deletePositionsMapping();
        handleResponseErrors(response, "Konfigurierte Positionen");
        return response;
    }
)