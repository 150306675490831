import { PositionDirectionEnum } from "models/position_category/position_direction.enum";
import { PositionCategoryItemConfigListComponent } from "./position_category_item_config_list.component";
import { PositionCategoryItemConfigItemComponent } from "./position_category_item_config_item.component";
import { useAppDispatch, useAppSelector } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { applyArticleTypeConfig, cancelArticleTypeConfig, addArticleTypeConfig, editArticleTypeConfig, deleteArticleTypeConfig, filterSelectedArticleTypeConfigArticleTypes, filterUnselectedArticleTypeConfigArticleTypes, selectArticleTypeConfigArticleTypes, unselectArticleTypeConfigArticleTypes, filterSelectedArticleTypeConfigPositions, filterUnselectedArticleTypeConfigPositions, selectArticleTypeConfigPositions, unselectArticleTypeConfigPositions } from "../position_category/redux/position_category.slice";

export interface PositionCategoryItemConfigListContainerComponentProps {
    direction: PositionDirectionEnum;
}

export const PositionCategoryItemConfigListContainerComponent = (props: PositionCategoryItemConfigListContainerComponentProps) => {

    const state = useAppSelector((state) => state.position_category);
    const dispatch: AppDispatch = useAppDispatch();

    const getItem = () => {
        switch (props.direction) {
            case PositionDirectionEnum.HorizontalStart:
                return state.currentEditedPositionCategoryItem.horizontalStartItem;
            case PositionDirectionEnum.HorizontalEnd:
                return state.currentEditedPositionCategoryItem.horizontalEndItem;
            case PositionDirectionEnum.VerticalStart:
                return state.currentEditedPositionCategoryItem.verticalStartItem;
            case PositionDirectionEnum.VerticalEnd:
                return state.currentEditedPositionCategoryItem.verticalEndItem;
        }
    }

    const item = getItem();

    return (
        <PositionCategoryItemConfigListComponent
            configs={item.articleTypeConfigs}
            editableConfig={item.editableArticleTypeConfig}
            editableConfigIndex={item.editableArticleTypeConfigIndex}
            applyEditableConfig={() => dispatch(applyArticleTypeConfig(props.direction))}
            cancelEditableConfig={() => dispatch(cancelArticleTypeConfig(props.direction))}
            addConfig={() => dispatch(addArticleTypeConfig(props.direction))}
            editConfig={(value) => dispatch(editArticleTypeConfig({ index: value, direction: props.direction }))}
            deleteConfig={(value) => dispatch(deleteArticleTypeConfig({ index: value, direction: props.direction }))}
        >
            <PositionCategoryItemConfigItemComponent
                articleTypeSelection={item.editableArticleTypeConfig?.articleTypes}
                filterSelectedArticleTypes={(value) => dispatch(filterSelectedArticleTypeConfigArticleTypes({ searchText: value, direction: props.direction }))}
                filterUnselectedArticleTypes={(value) => dispatch(filterUnselectedArticleTypeConfigArticleTypes({ searchText: value, direction: props.direction }))}
                selectArticleTypes={(value) => dispatch(selectArticleTypeConfigArticleTypes({ ids: value, direction: props.direction }))}
                unselectArticleTypes={(value) => dispatch(unselectArticleTypeConfigArticleTypes({ ids: value, direction: props.direction }))}
                positionSelection={item.editableArticleTypeConfig?.positions}
                filterSelectedPositions={(value) => dispatch(filterSelectedArticleTypeConfigPositions({ searchText: value, direction: props.direction }))}
                filterUnselectedPositions={(value) => dispatch(filterUnselectedArticleTypeConfigPositions({ searchText: value, direction: props.direction }))}
                selectPositions={(value) => dispatch(selectArticleTypeConfigPositions({ ids: value, direction: props.direction }))}
                unselectPositions={(value) => dispatch(unselectArticleTypeConfigPositions({ ids: value, direction: props.direction }))}
            />
        </PositionCategoryItemConfigListComponent>
    )
}