import IMasspointOverview from "models/masspoints/masspoint_overview";
import { MasspointTypeEnumValuesLookup } from "models/masspoints/enums/masspoint_type.enum";
import { SerialSizeCreateState } from "./serial_sizes_create.model";
import { IMassPointCriteria } from "models/masspoints/masspoint_criteria";
import IAvailableArticleType from "models/available_basedata/available_article_type";
import IAvailableSize from "models/available_basedata/available_size";
import { isNotUndefinied } from "services/validation.service";
import { ISerialSizeViewModel } from "masspoints/serial_sizes/components/serial_size_editor.model";
import { ISerialSizeCreate } from "models/serial_sizes/serial_size_create";
import { IAvailableAdditionAttribute } from "models/addition_attribute_categories/available_addition_attribute";
import { distinctMassPointCriteria } from "shared/helpers/distinctMassPointCriteria";


export const createMasspointItemList = (masspoints: IMasspointOverview[]): IMassPointCriteria[] => {
    return masspoints.filter(distinctMassPointCriteria).map(mp => {
        return {
            massPointId: mp.id,
            massPointName: mp.name,
            displayName: `${mp.name} ${MasspointTypeEnumValuesLookup(mp.massPointType)} ${mp.bodyArea.name}`,
            massPointType: mp.massPointType,
            bodyAreaId: mp.bodyArea.id,
        }
    });
}

export const updateCanSave = (state: SerialSizeCreateState) => {
    state.command.createSerialSize.canExecute = calculateCanUpdate(state);
}

const calculateCanUpdate = (state: SerialSizeCreateState): boolean => {
    return isNotUndefinied(state.actualData.serialSize.quality)
        && state.actualData.serialSize.articleTypeViewModels.length > 0
        && state.actualData.serialSize.articleTypeViewModels.find(art => art.articleType.id === -1) != undefined;
}

export const calculateAvailableArticleTypes = (state: SerialSizeCreateState): IAvailableArticleType[] => {
    const usedArticleTypes = state.actualData.serialSize.articleTypeViewModels.map(x => { return x.articleType.id });
    return state.loadedData.articleTypes.filter(x => !usedArticleTypes.find(y => y === x.id));
}

export const calculateAvailableSizes = (state: SerialSizeCreateState): IAvailableSize[] => {
    const usedSizes = state.actualData.configureArticleType.sizes.map(x => { return x.size.id });
    return state.loadedData.sizes.filter(x => !usedSizes.find(y => y === x.id));
}

const sortMasspointCriteria = (mp1: IMassPointCriteria, mp2: IMassPointCriteria) => {
    return mp1.displayName.localeCompare(mp2.displayName);
}

export const recalculateAvailableMasspoints = (state: SerialSizeCreateState, additionalMasspoint: IMassPointCriteria): IMassPointCriteria[] => {
    const usedMasspoints = state.actualData.configureArticleType.configureSize.masspoints.map(x => { return x.masspoint });
    const availableMasspoints = state.loadedData.masspointCriterias.filter(x => !usedMasspoints.find(y => y.displayName === x.displayName));
    if (additionalMasspoint) {
        availableMasspoints.push(additionalMasspoint);
    }
    return availableMasspoints.sort(sortMasspointCriteria);
}

export const recalculateAvailableAttributeMasspoints = (state: SerialSizeCreateState, additionalMasspoint: IMassPointCriteria): IMassPointCriteria[] => {
    const usedMasspoints = state.actualData.configureArticleType.configureAttribute.masspoints.map(x => { return x.masspoint });
    const availableMasspoints = state.loadedData.masspointCriterias.filter(x => !usedMasspoints.find(y => y.displayName === x.displayName));
    if (additionalMasspoint) {
        availableMasspoints.push(additionalMasspoint);
    }
    return availableMasspoints.sort(sortMasspointCriteria);
}

export const recalculateAvailableAttributes = (state: SerialSizeCreateState, availableAttributes: IAvailableAdditionAttribute[]): IAvailableAdditionAttribute[] => {
    const usedAttributes = state.actualData.configureArticleType.attributes.map(x => { return x.attribute });
    return availableAttributes.filter(x => !usedAttributes.find(y => y.code === x.code))
        .sort(sortAvailableAttributes);
}

export const sortAvailableAttributes = (att1: IAvailableAdditionAttribute, att2: IAvailableAdditionAttribute) => {
    return att1.code.localeCompare(att2.code);
}

export const toSerialSize = (serialSize: ISerialSizeViewModel): ISerialSizeCreate => {
    return {
        qualityId: serialSize.quality.id,
        articleTypes: serialSize.articleTypeViewModels.map(art => {
            return {
                articleTypeId: art.articleType.id !== -1 ? art.articleType.id : null,
                configurations: art.sizeViewModels.map((size) => {
                    return {
                        sizeId: size.size.id,
                        masspoints: size.masspoints.map((mp) => {
                            return {
                                valueFrom: mp.valueFrom,
                                valueTo: mp.valueTo,
                                name: mp.masspoint.massPointName,
                                type: mp.masspoint.massPointType,
                                bodyAreaId: mp.masspoint.bodyAreaId
                            }
                        })
                    }
                }),
                attributeConfigurations: art.attributeViewModels.map((attribute) => {
                    return {
                        attributeId: attribute.attribute.id,
                        masspoints: attribute.masspoints.map((mp) => {
                            return {
                                valueFrom: mp.valueFrom,
                                valueTo: mp.valueTo,
                                name: mp.masspoint.massPointName,
                                type: mp.masspoint.massPointType,
                                bodyAreaId: mp.masspoint.bodyAreaId
                            }
                        })
                    }
                })
            }
        })
    };
}