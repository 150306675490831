import { useAppDispatch, useAppSelector } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { BodyInformationEditorComponent } from "../components/bodyinformation_editor.component";
import { IBodyInformationEditorAction } from "../components/bodyinformation_editor.model";
import { LoadingOverlayComponent, LoadingOverlayContainer } from "shared/components/loading_overlay/loading_overlay.component";
import { addTranslationKey, removeTranslationKey, resetState, resetUpdateBodyInformation, updateTranslationKey, updateCategoryName, updateCategoryType, updateFreeTextLength, updateShortKey, updateIsMandatory } from "./redux/bodyinformation_edit.slice";
import { cancelSave, fetchBodyInformation, updateBodyInformation } from "./redux/bodyinformation_edit.thunks";
import { useEffect } from "react";
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";

export const BodyInformationEditComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const state = useAppSelector((state) => state.body_information_edit);

    const { bodyinformationid } = useParams();

    useEffect(() => {
        dispatch(fetchBodyInformation(bodyinformationid));

        return () => {
            dispatch(resetState());
        }
    }, [dispatch, bodyinformationid]);

    const editorActions: IBodyInformationEditorAction = {
        updateCategoryName: (categoryName) => dispatch(updateCategoryName(categoryName)),
        updateCategoryType: (categoryType) => dispatch(updateCategoryType(categoryType)),
        updateIsMandatory: (isMandatory) => dispatch(updateIsMandatory(isMandatory)),
        updateFreeTextLength: (freeTextLength) => dispatch(updateFreeTextLength(freeTextLength)),
        addTranslationKey: () => dispatch(addTranslationKey()),
        removeTranslationKey: (index) => dispatch(removeTranslationKey(index)),
        updateTranslationKey: (index, translationKey) => dispatch(updateTranslationKey({ index, translationKey })),
        updateShortKey: (index, shortKey) => dispatch(updateShortKey({ index, shortKey }))
    };

    if (state.command.cancel.status === "success") {
        navigate("/massmasks/bodyinformations/overview");
        dispatch(resetState());
    }

    if (state.command.updateBodyInformation.status === "success") {
        enqueueSnackbar("Zusätzliche Angabe erfolgreich erstellt", { variant: "success" });
        navigate("/massmasks/bodyinformations/overview");
        dispatch(resetState());
    }

    if (state.command.updateBodyInformation.status === "error") {
        enqueueSnackbar(state.command.updateBodyInformation.message, { variant: "error" });
        dispatch(resetUpdateBodyInformation())
    }
    const isLoading = state.command.updateBodyInformation.status === "pending" || state.command.getBodyInformation.status === "pending";

    return (
        <>
            <LoadingOverlayContainer>
                {
                    isLoading &&
                    <LoadingOverlayComponent />
                }
                <BodyInformationEditorComponent
                    editor={state.actualData.editor}
                    editorActions={editorActions}
                />
                <EditorButtonComponent
                    canExecute={state.command.updateBodyInformation.canExecute}
                    save={() => dispatch(updateBodyInformation())}
                    cancelSave={() => dispatch(cancelSave())}
                />                        
            </LoadingOverlayContainer>
        </>
    );
}