import { doesExist } from "services/validation.service";
import { SerialSizePreviewState } from "./serial_size_preview.model";
import { IAttributePreview, IMasspointPreview, ISerialSizePreview, ISizePreview } from "models/serial_sizes/serial_size_preview";
import { ISerialSizePreviewViewModel, IAttributePreviewViewModel, IMasspointPreviewViewModel, ISizePreviewViewModel } from "../component/serial_size_preview.models";
import { MasspointTypeEnumValuesLookup } from "models/masspoints/enums/masspoint_type.enum";
import { MasspointBodySideEnumValuesLookup } from "models/masspoints/enums/body_side.enum";

export const updateCanPreview = (state: SerialSizePreviewState) => {
    state.query.fetchSerialSizePreview.canExecute =
        doesExist(state.actualData.quality) 
        && doesExist(state.actualData.articleType);
}

export const createViewModel = (serialSize: ISerialSizePreview): ISerialSizePreviewViewModel => {
    return {
        sizes: createSizesViewModel(serialSize.sizes),
        attributes: createAttributeViewModel(serialSize.attributes)
    };
}

const createSizesViewModel = (sizes: ISizePreview[]): ISizePreviewViewModel[] => {
    return sizes.map(size => { return {
        displayName: `${size.code} - ${size.name}`,
        masspoints: createMasspointViewModel(size.masspoints)
    }});
}

const createAttributeViewModel = (attributes: IAttributePreview[]): IAttributePreviewViewModel[] => {
    return attributes.map(atr => { return {
        displayName: `${atr.code} - ${atr.name}`,
        displayCategory: `${atr.category} - ${atr.categoryName}`,
        masspoints: createMasspointViewModel(atr.masspoints)
    }});
}

const createMasspointViewModel = (masspoints: IMasspointPreview[]): IMasspointPreviewViewModel[] => {
    return masspoints.sort(sortMasspoints).map((mp, index) => { return {
        id: index,
        displayName: `${mp.name} / ${MasspointTypeEnumValuesLookup(mp.type)} / ${mp.bodyArea} / ${MasspointBodySideEnumValuesLookup(mp.bodySide)}`,
        side: mp.bodySide,
        isDefault: mp.isDefault,
        minimalValue: mp.minimalValue,
        maximalValue: mp.maximalValue,
    }});
} 

export const sortMasspoints = (mp1: IMasspointPreview, mp2: IMasspointPreview) => {
    return mp1.bodySide.toString().localeCompare(mp2.bodySide.toString());
}