import { AppDispatch } from "app/redux_store";
import { useAppDispatch, useAppSelector } from '../../../app/hook';
import AddIcon from '@mui/icons-material/Add';
import * as style from "./characteristic_category_overview.style";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { FlexBox, Left, LoadingIndicatorComponent, PrimaryButtonComponent, Right } from "shared/shared";
import SearchFieldComponent from "shared/components/search/searchfield.component";
import { getGridColumns, sortByNameModel } from "./characteristic_category_overview.helper";
import { OverviewDatagrid } from "shared/components/datagrid/overview_datagrid.component";
import {
    deleteCharacteristicCategory,
    deleteCompleted,
    resetState,
    setSearchFilter,
    updateCompleted,
    setGridFilters
} from "./redux/characteristic_category_overview.slice";
import { CharacteristicCategoryDeleteDialogComponent } from "./components/characteristic_category_delete_dialog.component";
import { createNewCharacteristicCategory, fetchCharacteristicCategories, updateCharacteristicCategory } from "./redux/characteristic_category_overview.thunks";

export const CharacteristicCategoryOverviewComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.characteristic_categories_overview);

    useEffect(() => {
        dispatch(fetchCharacteristicCategories());
        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    if (state.query.fetchCharacteristicCategories.status === "error") {
        return <div>Fehler beim Laden der Daten</div>;
    }

    if (state.query.fetchCharacteristicCategories.status !== "success") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.createNewCharacteristicCategory.status === "pending") {
        navigate(`/additions/characteristiccategory/create`);
    }

    if (state.command.updateCharacteristicCategory.status === "success") {
        navigate(`/additions/characteristiccategory/edit/${state.actualData.characteristicCategory.id}`);        
        dispatch(updateCompleted());
    }

    if (state.command.confirmDeleteCharacteristicCategory.status === "error") {
        enqueueSnackbar(state.command.confirmDeleteCharacteristicCategory.message, { variant: "error" });
        dispatch(deleteCompleted())
    }

    if (state.command.confirmDeleteCharacteristicCategory.status === "success") {
        enqueueSnackbar("Ausprägungskategorie erfolgreich gelöscht", { variant: "success" });
        dispatch(deleteCompleted())
    }

    const isLoading = state.command.confirmDeleteCharacteristicCategory.status === "pending";

    const characteristicCategoresAvailable = state.loadedData.characteristicCategories.length > 0;

    return (
        <div>
            {
                state.actualData.showDeleteCharacteristicCategoryDialog &&
                <CharacteristicCategoryDeleteDialogComponent />
            }
            <FlexBox>

                {
                    characteristicCategoresAvailable &&
                    <Left>
                        <SearchFieldComponent onSearch={(filterText) => dispatch(setSearchFilter(filterText))} placeholder="In Ausprägungskategorien suchen ..." />
                    </Left>
                }
                <Right>
                    <PrimaryButtonComponent onClick={() => dispatch(createNewCharacteristicCategory())}>
                        <AddIcon /> Neue Ausprägungskategorie erstellen
                    </PrimaryButtonComponent>
                </Right>
            </FlexBox>
            <style.OverviewArea>
                {
                    !characteristicCategoresAvailable &&
                    <div>Keine Ausprägungskategorien verfügbar</div>
                }
                {
                    characteristicCategoresAvailable &&
                    <OverviewDatagrid
                        isLoading={isLoading}
                        columns={getGridColumns(
                            (characteristic_category) => dispatch(updateCharacteristicCategory(characteristic_category)),
                            (characteristic_category) => dispatch(deleteCharacteristicCategory(characteristic_category)),
                        )}
                        rows={state.actualData.characteristicCategories}
                        loadedRowsCount={state.loadedData.characteristicCategories.length}
                        sortModel={sortByNameModel}
                        onFilterModelChange={(newFilterModel) => dispatch(setGridFilters(newFilterModel))}
                        onDoubleClickRow={characteristic_category => dispatch(updateCharacteristicCategory(characteristic_category))}
                    />
                }
            </style.OverviewArea>
        </div>
    );
}