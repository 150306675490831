import { AppDispatch } from "app/redux_store";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { fetchAllData, createPositionCategory, updatePositionCategory } from "./redux/position_category.thunks";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from 'app/hook';
import { LoadingOverlayComponent, LoadingOverlayContainer } from "shared/components/loading_overlay/loading_overlay.component";
import { PositionCategoryEditorState } from "models/position_category/position_category_editor";
import { PositionCategoryEditorComponent } from "../component/position_category_editor.component";
import { PositionCategoryItemEditorComponent } from "../component/position_category_item_editor.component";
import { IPositionCategoryActions } from "models/position_category/position_category_actions";
import { LoadingIndicatorComponent } from "shared/shared";
import { useDialog } from "shared/contexts/dialog_context";
import { updateName, toggleSideSeamAllowed, createNewPositionCategoryItem, cancelSavePositionCategory, deletePositionCategoryItemByIndex, editPositionCategoryItemByIndex, setGridFilters, handleSaveError, resetState } from "./redux/position_category.slice";
import { EditorModeEnum } from "models/editors/editor_mode.enum";

export const PositionCategoryComponent = () => {
    const state = useAppSelector((state) => state.position_category);
    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { positionCategoryId } = useParams();
    const dialog = useDialog();

    useEffect(() => {
        dispatch(fetchAllData(positionCategoryId));
        return () => {
            dispatch(resetState());
        }
    }, [dispatch, positionCategoryId]);

    const savePositionCategory = () => {
        switch(state.actualData.editorMode){
            case EditorModeEnum.Edit:
                dispatch(updatePositionCategory());
                break;          
            case EditorModeEnum.Create:
                dispatch(createPositionCategory());
                break;
        }
    }

    const editorActions: IPositionCategoryActions = {
        updateName: (value) => dispatch(updateName(value)),
        toggleSideSeamAllowed: () => dispatch(toggleSideSeamAllowed()),
        createNewPositionCategoryItem: () => dispatch(createNewPositionCategoryItem()),
        cancelSavePositionCategory: () => dispatch(cancelSavePositionCategory()),
        savePositionCategory: savePositionCategory,
        deletePositionCategoryItemByIndex: (number) => dispatch(deletePositionCategoryItemByIndex(number)),
        editPositionCategoryItemByIndex: (number) => dispatch(editPositionCategoryItemByIndex(number)),
        setGridFilters: (filter) => dispatch(setGridFilters(filter)),
    };

    if (state.query.fetchAllData.status === "pending") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.cancelSavePositionCategory.status === "success") {
        navigate("/additions/positioncategory/overview");
        dispatch(resetState());
    }

    if (state.command.updatePositionCategory.status === "success") {
        enqueueSnackbar("Zusatzposition gespeichert", { variant: "success" });
        navigate("/additions/positioncategory/overview");
        dispatch(resetState());
    }

    if (state.command.updatePositionCategory.status === "error") {
        if (state.error.showErrorDialog) {
            if (state.error.errorCode === "422") {
                dialog.show(
                    "Die Positionskategorie kann nicht gespeichert werden, da eine zugehörige gemappte Postion vorhanden ist.",
                    <div>{state.command.updatePositionCategory.message}</div>,
                    [{
                        title: "Ok",
                        handleOption: () => { }
                    }]);
            }
        }
        enqueueSnackbar(state.command.updatePositionCategory.message, { variant: "error" });
        dispatch(handleSaveError());
    }

    if (state.command.updatePositionCategory.status === "warning") {
        enqueueSnackbar(state.command.updatePositionCategory.message, { variant: "warning" });
        dispatch(handleSaveError());
    }

    if (state.command.savePositionCategory.status === "warning") {
        enqueueSnackbar(state.command.savePositionCategory.message, { variant: "warning" });
        dispatch(handleSaveError());

    }

    if (state.command.savePositionCategory.status === "success") {
        enqueueSnackbar("Zusatzposition gespeichert", { variant: "success" });
        navigate("/additions/positioncategory/overview");
        dispatch(resetState());
    }

    if (state.command.savePositionCategory.status === "error") {
        enqueueSnackbar(state.command.savePositionCategory.message, { variant: "error" });
    }

    const isSaving = state.command.updatePositionCategory.status === "pending" || state.command.savePositionCategory.status === "pending";

    return (
        <LoadingOverlayContainer>
            {
            isSaving && 
                <LoadingOverlayComponent />
            }
            {
                state.actualData.editorState === PositionCategoryEditorState.Category &&
                    <PositionCategoryEditorComponent
                        editor={state.actualData}
                        editorActions={editorActions}
                        canSave={state.actualData.canSaveCategory}
                    />
            }
            {
                state.actualData.editorState !== PositionCategoryEditorState.Category &&
                    <PositionCategoryItemEditorComponent />
            }
        </LoadingOverlayContainer>
    );
}