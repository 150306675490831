import styled from "@emotion/styled";;

export const OuterWrapper = styled.div`
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(1px);
`;

export const InnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;