import { PolicyOverviewState } from "./policy_overview.model";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createNewMandatoryCategoryByMasspointPolicy, confirmDelete, createNewMandatoryCategoryPolicy, fetchPolicies, publish, unpublish, updatePolicy, createNewWhippedSeamPolicy, createNewAdditionRankPolicy, createNewDefaultAdditionByArticleTypePolicy, createNewExcludeAdditionByMasspointPolicy, createNewUpperThresholdPolicy, createNewDefaultAdditionByQualityAndAttributePolicy, createNewExcludeAdditionByMasspointValuePolicy, createNewAdjustRangesByMasspointPolicy, createNewAdjustMaximumRangePolicy, createNewDefaultByAttributePolicy, createNewSideSeamPolicy, createNewScarTreatmentPolicy, createNewFingersAndToesPolicy } from "./policy_overview.thunks";
import { IPolicyOverview } from "models/policies/policy_overview";
import { filterPolicies } from "./policy_overview.reducer";
import { GridFilterModel } from "@mui/x-data-grid-pro";

const initialState: PolicyOverviewState = {
    loadedData: {
        policies: []
    },
    actualData: {
        policies: [],
        searchFilter: "",
        gridFilters: { items: [] },
        policy: null,
        showDeletePolicyDialog: false
    },
    command: {
        publishPolicy: { status: "idle", canExecute: false },
        unpublishPolicy: { status: "idle", canExecute: false },
        confirmDeletePolicy: { status: "idle", canExecute: false },
        createNewMandatoryCategoryPolicy: { status: "idle", canExecute: false },
        createNewMandatoryCategoryByMasspointPolicy: { status: "idle", canExecute: false },
        createNewWhippedSeamPolicy: { status: "idle", canExecute: false },
        createNewSideSeamPolicy: {status: "idle", canExecute: false},
        createNewScarTreatmentPolicy: {status: "idle", canExecute: false},
        createNewAdditionRankPolicy: { status: "idle", canExecute: false },
        createNewDefaultAdditionByArticleTypePolicy: { status: "idle", canExecute: false },
        createNewDefaultAdditionByQualityAndAttributePolicy: { status: "idle", canExecute: false },
        createNewDefaultByAttributePolicy: { status: "idle", canExecute: false },
        createNewExcludeAdditionByMasspointPolicy: { status: "idle", canExecute: false },
        createNewUpperThresholdPolicy: { status: "idle", canExecute: false },
        createNewExcludeAdditionByMasspointValuePolicy: { status: "idle", canExecute: false },
        createNewAdjustRangesByMasspointPolicy: { status: "idle", canExecute: false },
        createNewAdjustMaximumRangePolicy: { status: "idle", canExecute: false },
        createNewFingersAndToesPolicy: { status: "idle", canExecute: false },
        updatePolicy: { status: "idle", canExecute: false },
    },
    query: {
        fetchPolicies: { status: "idle", canExecute: true }
    }
}

export const getPoliciesSlice = createSlice({
    name: 'get_policies_overview',
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.actualData.searchFilter = action.payload;
            filterPolicies(state);
        },
        setGridFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.gridFilters = action.payload;
            filterPolicies(state);
        },
        publishCompleted: (state) => {
            state.command.publishPolicy.status = "idle";
        },
        unpublishCompleted: (state) => {
            state.command.unpublishPolicy.status = "idle";
        },
        cancelDelete: (state) => {
            state.actualData.policy = null;
            state.actualData.showDeletePolicyDialog = false;
        },
        deleteCompleted: (state) => {
            state.command.confirmDeletePolicy = initialState.command.confirmDeletePolicy;
        },
        updateCompleted: (state) => {
            state.actualData.policy = null;;
        },
        deletePolicy: (state, action: PayloadAction<IPolicyOverview>) => {
            state.actualData.showDeletePolicyDialog = true;
            state.actualData.policy = action.payload;
        },
    }, extraReducers: (builder) => {

        // fetchPolicies
        builder.addCase(fetchPolicies.pending, (state) => {
            state.query.fetchPolicies.status = "pending"
            state.query.fetchPolicies.canExecute = false;
        }).addCase(fetchPolicies.rejected, (state, action) => {
            state.query.fetchPolicies.status = "error"
            state.query.fetchPolicies.message = action.error.message;
            state.query.fetchPolicies.canExecute = true;
        }).addCase(fetchPolicies.fulfilled, (state, action) => {
            state.query.fetchPolicies.status = "success"
            state.query.fetchPolicies.canExecute = true;
            state.loadedData.policies = action.payload.getData();
            filterPolicies(state);

            // publish
        }).addCase(publish.pending, (state) => {
            state.command.publishPolicy.status = 'pending'
        }).addCase(publish.rejected, (state, action) => {
            state.command.publishPolicy.status = "error"
            state.command.publishPolicy.message = action.error.message;
        }).addCase(publish.fulfilled, (state, action) => {
            state.command.publishPolicy.status = "success"
            state.command.publishPolicy.canExecute = true;
            state.loadedData.policies = action.payload.getData();
            filterPolicies(state);

            // unpublish
        }).addCase(unpublish.pending, (state) => {
            state.command.unpublishPolicy.status = 'pending'
        }).addCase(unpublish.rejected, (state, action) => {
            state.command.unpublishPolicy.status = "error"
            state.command.unpublishPolicy.message = action.error.message;
        }).addCase(unpublish.fulfilled, (state, action) => {
            state.command.unpublishPolicy.status = "success"
            state.command.unpublishPolicy.canExecute = true;
            state.loadedData.policies = action.payload.getData();
            filterPolicies(state);

            // createNewMandatoryCategoryPolicy
        }).addCase(createNewMandatoryCategoryPolicy.pending, (state) => {
            state.command.createNewMandatoryCategoryPolicy.status = "pending"
        }).addCase(createNewMandatoryCategoryPolicy.fulfilled, (state) => {
            state.command.createNewMandatoryCategoryPolicy.status = "success"

            // createNewMandatoryCategoryByMasspointPolicy
        }).addCase(createNewMandatoryCategoryByMasspointPolicy.pending, (state) => {
            state.command.createNewMandatoryCategoryByMasspointPolicy.status = "pending"
        }).addCase(createNewMandatoryCategoryByMasspointPolicy.fulfilled, (state) => {
            state.command.createNewMandatoryCategoryByMasspointPolicy.status = "success"

            // createNewWhippedSeamPolicy
        }).addCase(createNewWhippedSeamPolicy.pending, (state) => {
            state.command.createNewWhippedSeamPolicy.status = "pending"
        }).addCase(createNewWhippedSeamPolicy.fulfilled, (state) => {
            state.command.createNewWhippedSeamPolicy.status = "success"

            // createNewSideSeamPolicy
        }).addCase(createNewSideSeamPolicy.pending, (state) => {
            state.command.createNewSideSeamPolicy.status = "pending"
        }).addCase(createNewSideSeamPolicy.fulfilled, (state) => {
            state.command.createNewSideSeamPolicy.status = "success"   
            
            // createNewScarTreatmentPolicy
        }).addCase(createNewScarTreatmentPolicy.pending, (state) => {
            state.command.createNewScarTreatmentPolicy.status = "pending"
        }).addCase(createNewScarTreatmentPolicy.fulfilled, (state) => {
            state.command.createNewScarTreatmentPolicy.status = "success"                

            // createNewAdditionRankPolicy
        }).addCase(createNewAdditionRankPolicy.pending, (state) => {
            state.command.createNewAdditionRankPolicy.status = "pending"
        }).addCase(createNewAdditionRankPolicy.fulfilled, (state) => {
            state.command.createNewAdditionRankPolicy.status = "success"
           
            // createNewFingersAndToesPolicy
        }).addCase(createNewFingersAndToesPolicy.pending, (state) => {
            state.command.createNewFingersAndToesPolicy.status = "pending"
        }).addCase(createNewFingersAndToesPolicy.fulfilled, (state) => {
            state.command.createNewFingersAndToesPolicy.status = "success"

            // createNewDefaultAdditionByArticleTypePolicy
        }).addCase(createNewDefaultAdditionByArticleTypePolicy.pending, (state) => {
            state.command.createNewDefaultAdditionByArticleTypePolicy.status = "pending"
        }).addCase(createNewDefaultAdditionByArticleTypePolicy.fulfilled, (state) => {
            state.command.createNewDefaultAdditionByArticleTypePolicy.status = "success"

            // createNewDefaultAdditionByQualityAndAttributePolicy
        }).addCase(createNewDefaultAdditionByQualityAndAttributePolicy.pending, (state) => {
            state.command.createNewDefaultAdditionByQualityAndAttributePolicy.status = "pending"
        }).addCase(createNewDefaultAdditionByQualityAndAttributePolicy.fulfilled, (state) => {
            state.command.createNewDefaultAdditionByQualityAndAttributePolicy.status = "success"

            // createNewDefaultByAttributePolicy
        }).addCase(createNewDefaultByAttributePolicy.pending, (state) => {
            state.command.createNewDefaultByAttributePolicy.status = "pending"
        }).addCase(createNewDefaultByAttributePolicy.fulfilled, (state) => {
            state.command.createNewDefaultByAttributePolicy.status = "success"            

            // createNewExcludeAdditionByMasspointPolicy
        }).addCase(createNewExcludeAdditionByMasspointPolicy.pending, (state) => {
            state.command.createNewExcludeAdditionByMasspointPolicy.status = "pending"
        }).addCase(createNewExcludeAdditionByMasspointPolicy.fulfilled, (state) => {
            state.command.createNewExcludeAdditionByMasspointPolicy.status = "success"

            // createNewUpperThresholdPolicy
        }).addCase(createNewUpperThresholdPolicy.pending, (state) => {
            state.command.createNewUpperThresholdPolicy.status = "pending"
        }).addCase(createNewUpperThresholdPolicy.fulfilled, (state) => {
            state.command.createNewUpperThresholdPolicy.status = "success"

            // createNewExcludeAdditionByMasspointValuePolicy
        }).addCase(createNewExcludeAdditionByMasspointValuePolicy.pending, (state) => {
            state.command.createNewExcludeAdditionByMasspointValuePolicy.status = "pending"
        }).addCase(createNewExcludeAdditionByMasspointValuePolicy.fulfilled, (state) => {
            state.command.createNewExcludeAdditionByMasspointValuePolicy.status = "success"

            // createNewAdjustRangesByMasspointPolicy
        }).addCase(createNewAdjustRangesByMasspointPolicy.pending, (state) => {
            state.command.createNewAdjustRangesByMasspointPolicy.status = "pending"
        }).addCase(createNewAdjustRangesByMasspointPolicy.fulfilled, (state) => {
            state.command.createNewAdjustRangesByMasspointPolicy.status = "success"

            //createNewAdjustMaximumRangePolicy
        }).addCase(createNewAdjustMaximumRangePolicy.pending, (state) => {
            state.command.createNewAdjustMaximumRangePolicy.status = "pending"
        }).addCase(createNewAdjustMaximumRangePolicy.fulfilled, (state) => {
            state.command.createNewAdjustMaximumRangePolicy.status = "success"


            // updatePolicy
        }).addCase(updatePolicy.pending, (state) => {
            state.command.updatePolicy.status = "pending"
        }).addCase(updatePolicy.fulfilled, (state, action) => {
            state.command.updatePolicy.status = "success"
            state.actualData.policy = action.payload;

            // confirmDelete 
        }).addCase(confirmDelete.pending, (state) => {
            state.command.confirmDeletePolicy.status = 'pending'
        }).addCase(confirmDelete.rejected, (state, action) => {
            state.command.confirmDeletePolicy.status = "error"
            state.command.confirmDeletePolicy.message = action.error.message;
            state.actualData.showDeletePolicyDialog = false;
        }).addCase(confirmDelete.fulfilled, (state, action) => {
            state.command.confirmDeletePolicy.status = "success"
            state.command.confirmDeletePolicy.canExecute = true;
            state.actualData.showDeletePolicyDialog = false;
            state.loadedData.policies = action.payload.getData();
            filterPolicies(state);
        })
    }
})

export const {
    resetState,
    setSearchFilter,
    setGridFilters,
    publishCompleted,
    unpublishCompleted,
    deleteCompleted,
    updateCompleted,
    cancelDelete,
    deletePolicy,
} = getPoliciesSlice.actions

export default getPoliciesSlice.reducer