import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MasspointGroupOverviewState } from './masspoint_group_overview.model';
import { filterMasspointGroupOverviews } from './masspoint_group_overview.reducer';
import { confirmDeleteMasspointGroup, fetchMasspointGroupOverviews } from './masspoint_group_overview.thunks';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import IMasspointGroupOverview from 'models/masspoint_groups/masspoint_group_overview';

const initialState: MasspointGroupOverviewState = {
    loadedData: {
        masspointGroups: [],
    },
    actualData: {
        masspointGroups: [],
        selectedMasspointGroupOverview: null,
        showDeleteMasspointGroupDialog: false,
        searchFilter: "",
        gridFilters: {items: []},
    },
    command: {
        confirmDeleteMasspointGroup: { status: "idle", canExecute: false },
    },
    query: {
        fetchMasspointGroupOverviews: { status: "idle", canExecute: true },
    },
}

export const getMasspointGroupOverviewSlice = createSlice({
    name: 'masspoints/masspoint_group_overview',
    initialState,
    reducers: {
        resetState: (state) => {
            state.command = initialState.command;
            state.loadedData = initialState.loadedData;
            state.actualData = initialState.actualData;
            state.query = initialState.query;
        },
        deleteMasspointGroup: (state, action: PayloadAction<IMasspointGroupOverview>) => {
            state.actualData.showDeleteMasspointGroupDialog = true;
            state.actualData.selectedMasspointGroupOverview = action.payload;
        },
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.actualData.searchFilter = action.payload;
            filterMasspointGroupOverviews(state);
        },
        setGridFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.gridFilters = action.payload;
            filterMasspointGroupOverviews(state);
        },
        confirmDeleteMasspointGroupCompleted: (state) => {
            state.command.confirmDeleteMasspointGroup.status = "idle";
        },
        cancelDeleteMasspointGroup: (state) => {
            state.actualData.showDeleteMasspointGroupDialog = false;
        },
    }, extraReducers: (builder) => {

        // fetch MasspointGroupOverviews
        builder.addCase(fetchMasspointGroupOverviews.pending, (state, action) => {
            state.query.fetchMasspointGroupOverviews.status = "pending"
            state.query.fetchMasspointGroupOverviews.canExecute = false;
        }).addCase(fetchMasspointGroupOverviews.rejected, (state, action) => {
            state.query.fetchMasspointGroupOverviews.status = "error"
            state.query.fetchMasspointGroupOverviews.message = action.error.message;
            state.query.fetchMasspointGroupOverviews.canExecute = true;
        }).addCase(fetchMasspointGroupOverviews.fulfilled, (state, action) => {
            state.query.fetchMasspointGroupOverviews.status = "success"
            state.query.fetchMasspointGroupOverviews.message = undefined;
            state.query.fetchMasspointGroupOverviews.canExecute = true;
            state.loadedData.masspointGroups = action.payload.getData();
            filterMasspointGroupOverviews(state);

            // delete MasspointGroup
        }).addCase(confirmDeleteMasspointGroup.pending, (state, action) => {
            state.command.confirmDeleteMasspointGroup.status = 'pending'
        }).addCase(confirmDeleteMasspointGroup.rejected, (state, action) => {
            state.command.confirmDeleteMasspointGroup.status = "error"
            state.command.confirmDeleteMasspointGroup.message = action.error.message;
            state.actualData.showDeleteMasspointGroupDialog = false;
        }).addCase(confirmDeleteMasspointGroup.fulfilled, (state, action) => {
            state.command.confirmDeleteMasspointGroup.status = "success"
            state.command.confirmDeleteMasspointGroup.canExecute = true;
            state.actualData.showDeleteMasspointGroupDialog = false;
            state.loadedData.masspointGroups = action.payload.getData();
            filterMasspointGroupOverviews(state);
        })
    }
})

export const {
    setSearchFilter,
    setGridFilters,
    deleteMasspointGroup,
    confirmDeleteMasspointGroupCompleted,
    cancelDeleteMasspointGroup,
    resetState,
} = getMasspointGroupOverviewSlice.actions

export default getMasspointGroupOverviewSlice.reducer