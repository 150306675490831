import { DialogComponent } from '../../../../shared/components/dialogs/dialog.component';
import { cancelDelete } from '../redux/policy_overview.slice';
import { AppDispatch } from '../../../../app/redux_store';
import { useAppDispatch, useAppSelector } from '../../../../app/hook';
import { confirmDelete } from '../redux/policy_overview.thunks';

export const PolicyDeleteDialogComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.policy_overview);
    const policy = state.actualData.policy;
    return (<>
            <DialogComponent
                title={"Regelwerk löschen?"}
                isOpen={true}
                options={[
                    { title: "Abbrechen", handleOption: () => { dispatch(cancelDelete())}},
                    { title: "Löschen", handleOption: () => { dispatch(confirmDelete())}}
                ]}
                onClose={() => { }}>
                <div>Möchten Sie das Regelwerk {policy.name} wirklich löschen?</div>
            </DialogComponent>
    </>);
}