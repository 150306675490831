import { Grid } from "@mui/material";
import { PrimaryButtonComponent } from "shared/shared";
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import InfoIcon from '@mui/icons-material/Info';
import colors from "shared/style/colors";
import { InfoTextComponent } from "./editor_buttons.style";

interface EditorButtonProps {
    canExecute: boolean;
    save: (useForce?: boolean) => void;
    cancelSave: () => void;
    message?: string;
}

export const EditorButtonComponent: React.FC<EditorButtonProps> = (
    {
        canExecute, 
        message,
        save, 
        cancelSave  
    }
) => {
    return (
        <Grid container item spacing={1}>
            {message && <Grid item                 
                container
                justifyContent="flex-end"
                columnSpacing={3}>
                <InfoIcon
                    sx={{
                    "fontSize": "medium",
                    "alignContent": "center",
                    "&.MuiSvgIcon-root": {
                        color: `${colors.mediGrey}`,
                        cursor: `${"pointer"}`
                    }
                    }}/>
                <InfoTextComponent>{message}</InfoTextComponent>
            </Grid>}
            <Grid item
                container
                justifyContent="flex-end"
                columnSpacing={2}>
                <Grid item>
                    <PrimaryButtonComponent
                        onClick={cancelSave}>
                        <ClearIcon /> Abbrechen
                    </PrimaryButtonComponent>
                </Grid>
                <Grid item>
                    <PrimaryButtonComponent
                        disabled={!canExecute}
                        onHover
                        onClick={save}>
                        <AddIcon /> Speichern
                    </PrimaryButtonComponent>                
                </Grid>
            </Grid>
        </Grid>     
    );
};