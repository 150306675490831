import { Grid } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingOverlayComponent, LoadingOverlayContainer } from "shared/components/loading_overlay/loading_overlay.component";
import { cancelSave, updateSerialSize, initData } from "./redux/serial_sizes_edit.thunks";
import { SerialSizeEditorComponent } from "../components/serial_size_editor.component";
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";
import { 
  completedSave, 
  resetState, 
  selectQuality, 
  addArticleType,
  selectArticleType, 
  takeArticleType,
  cancelArticleType,
  editArticleType,
  deleteArticleType,
  addSize,
  selectSize,
  cancelSize,
  takeSize,
  deleteSize,
  editSize,
  addMasspoint,
  deleteMasspoint,
  selectMasspoint,
  updateValueFrom,
  updateValueTo,
  addAttribute,
  takeAttribute,
  editAttribute,
  deleteAttribute,
  selectCategory,
  selectAttribute,
  cancelAttribute,
  addAttributeMasspoint,
  selectAttributeMasspoint,
  updateAttributeValueFrom,
  updateAttributeValueTo,
  deleteAttributeMasspoint
} from "./redux/serial_sizes_edit.slice";
import { LoadingIndicatorComponent } from "shared/shared";

export const SerialSizesUpdateComponent = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const state = useAppSelector((state) => state.serial_size_edit);

  const { iserialsizeId } = useParams();

  useEffect(() => {
    dispatch(initData(iserialsizeId));
    return () => {
      dispatch(resetState());
    }
  }, [dispatch]);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const isSaving = false;

  if (state.query.initData.status === "pending") {
    return <LoadingIndicatorComponent />;
  }

  if (state.command.cancelSerialSize.status === "success") {
      navigate("/masspoints/serialsize/overview");
      dispatch(resetState());
  }

  if (state.command.updateSerialSize.status === "success") {
      enqueueSnackbar("Seriengröße gespeichert", { variant: "success" });
      navigate("/masspoints/serialsize/overview");
      dispatch(resetState());
  }

  if (state.command.updateSerialSize.status === "error") {
      enqueueSnackbar(state.command.updateSerialSize.message, { variant: "error" });
      dispatch(completedSave());
  }

  const qualityIsSelected = !!state.actualData.serialSize?.quality;

  return (
    <LoadingOverlayContainer>
      {isSaving && <LoadingOverlayComponent />}
        <Grid
          container
          spacing={3}
          md={12}
          direction="column"
          item>
          <Grid item>
            <SerialSizeEditorComponent
                header='Seriengröße editieren'
                qualities={state.loadedData.qualities}
                articleTypes={state.loadedData.articleTypes}
                serialSize={state.actualData.serialSize}
                configurationArticleType={state.actualData.configureArticleType}
                selectQuality={(quality) => dispatch(selectQuality(quality))}
                addArticleType={() => dispatch(addArticleType())}
                editArticleType={(articleType => dispatch(editArticleType(articleType)))}
                deleteArticleType={(articleType => dispatch(deleteArticleType(articleType)))}
                takeArticleType={() => dispatch(takeArticleType())}
                cancelArticleType={() => dispatch(cancelArticleType())}
                selectArticleType={(articleType) => dispatch(selectArticleType(articleType))}
                addSize={() => dispatch(addSize())}
                editSize={(size) => dispatch(editSize(size))}
                deleteSize={(size) => dispatch(deleteSize(size))}  
                selectSize={(size) => dispatch(selectSize(size))}
                takeSize={() => dispatch(takeSize())}
                cancelSize={() => dispatch(cancelSize())}
                addMasspoint={() => dispatch(addMasspoint())}
                selectMasspoint={(masspoint) => dispatch(selectMasspoint(masspoint))}    
                updateValueFrom={(masspoint) => dispatch(updateValueFrom(masspoint))}         
                updateValueTo={(masspoint) => dispatch(updateValueTo(masspoint))}          
                deleteMasspoint={(masspoint) => dispatch(deleteMasspoint(masspoint))}
                addAttribute={() => dispatch(addAttribute())}
                takeAttribute={() => dispatch(takeAttribute())}    
                editAttribute={(attribute) => dispatch(editAttribute(attribute))}
                deleteAttribute={(attribute) => dispatch(deleteAttribute(attribute))}            
                selectCategory={(category) => dispatch(selectCategory(category))}
                selectAttribute={(attribute) => dispatch(selectAttribute(attribute))}
                cancelAttribute={() => dispatch(cancelAttribute())}
                addAttributeMasspoint={() => dispatch(addAttributeMasspoint())}
                selectAttributeMasspoint={(masspoint) => dispatch(selectAttributeMasspoint(masspoint))}  
                updateAttributeValueFrom={(masspoint) => dispatch(updateAttributeValueFrom(masspoint))}         
                updateAttributeValueTo={(masspoint) => dispatch(updateAttributeValueTo(masspoint))}   
                deleteAttributeMasspoint={(masspoint) => dispatch(deleteAttributeMasspoint(masspoint))}
              />
          </Grid>
          <Grid item>
              <EditorButtonComponent
                  canExecute={state.command.updateSerialSize.canExecute}
                  save={() => dispatch(updateSerialSize())}
                  cancelSave={() => dispatch(cancelSave())}
              />
          </Grid>
        </Grid>
    </LoadingOverlayContainer>
  );
};