import { RootState } from 'app/redux_store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import PositionCategoryService from 'services/position_category.service';
import { handleResponseErrors } from 'shared/networking/error_handling';
import { IPositionCategoryOverview } from 'models/position_category/position_category_overview';

export const getPositionCategories = createAsyncThunk(
    'positionCategory/overview/getPositionCategories',
    async (_) => {
        const response = await PositionCategoryService.getPositionCategories();
        handleResponseErrors(response, "Positionskategorien");
        return response;
    }
)

export const confirmDeletePositionCategory = createAsyncThunk(
    'positionCategory/overview/confirmDeletePositionCategory',
    async (_, { getState, dispatch }) => {
        const state = getState() as RootState
        const positionCategoryId = state.position_categories_overview.actualData.activePositionCategory.id;
        const responseDelete = await PositionCategoryService.deletePositionCategory(positionCategoryId);
        handleResponseErrors(responseDelete, "Positionskategorie");
        const additionPositions = await PositionCategoryService.getPositionCategories();
        handleResponseErrors(additionPositions, "Positionskategorien");
        return additionPositions;
    }
)

export const createNewPositionCategory = createAsyncThunk(
    'positionCategory/overview/createNewPositionCategory',
    async (_) => { }
)

export const showPositionCategoryItemDetails = createAsyncThunk(
    'positionCategory/overview/showPositionCategoryItemDetails',
    async (_) => { }
)

export const updatePositionCategory = createAsyncThunk(
    'positionCategory/overview/updatePositionCategory',
    async (positionCategory: IPositionCategoryOverview) => {
        return positionCategory;
    }
)