import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CharacteristicTypeCreateState } from './characteristic_type_create.model';
import { updateCanSave } from './characteristic_type_create.reducer';
import { cancelSave, getCharacteristicValueTranslation, initData, saveCharacteristicType } from './characteristic_type_create.thunks';
import { IIndexedValueChanged } from 'additions/characteristic_type/components/characteristic_type_editor.model';

const initialState: CharacteristicTypeCreateState = {
    data: { 
        characteristicType: {
            name: "", 
            shortKey: "", 
            values: []
        }
    },
    loadedData: {
        characteristicValueTranslations: [],
    },
    command:{
        saveCharacteristicType: { status: "idle", canExecute: false },
        cancelSave: { status: "idle", canExecute: true }
    },
    query: {              
        initData: { status: "idle", canExecute: true },
        fetchCharacteristicValueTranslations: { status: "idle", canExecute: true },
    }
}

export const createCharacteristicTypeSlice = createSlice({
    name: 'create_characteristic_type',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = initialState.data;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        addValue: (state) => {
            state.data.characteristicType.values.push({
                name: "",
                translationKey: ""
            });
            updateCanSave(state);
        },
        changeName: (state, action: PayloadAction<string>) => {
            state.data.characteristicType.name = action.payload;
            updateCanSave(state);
        },
        changeShortKey: (state, action: PayloadAction<string>) => {
            state.data.characteristicType.shortKey = action.payload;
            updateCanSave(state);
        },      
        changeValueName: (state, action: PayloadAction<IIndexedValueChanged>) => {
            const payLoad = action.payload;
            state.data.characteristicType.values[payLoad.index].name = payLoad.value;  
            updateCanSave(state);
        },
        changeValueKey: (state, action: PayloadAction<IIndexedValueChanged>) => {
            const payLoad = action.payload;
            state.data.characteristicType.values[payLoad.index].translationKey = payLoad.value;  
            updateCanSave(state);
        }, 
        deleteValue: (state, action: PayloadAction<number>) => {
            state.data.characteristicType.values.splice(action.payload,1);
            updateCanSave(state); 
        },                 
        completedSave: (state) => {
            state.command.saveCharacteristicType = initialState.command.saveCharacteristicType;
        }
    }, extraReducers: (builder) => {
        // saveCharacteristicType
        builder.addCase(saveCharacteristicType.pending, (state) => {
            state.command.saveCharacteristicType.status = 'pending'
            state.command.saveCharacteristicType.canExecute = false;
        }).addCase(saveCharacteristicType.rejected, (state, action) => {
            state.command.saveCharacteristicType.status = "error"
            state.command.saveCharacteristicType.canExecute = true;
            state.command.saveCharacteristicType.message = action.error.message;
        }).addCase(saveCharacteristicType.fulfilled, (state) => {
            state.command.saveCharacteristicType.status = "success"
            state.command.saveCharacteristicType.canExecute = false;

        // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancelSave.status = 'pending'
            state.command.cancelSave.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancelSave.status = "success"
            state.command.cancelSave.canExecute = false;

        // getCharacteristicValueTranslations
        }).addCase(getCharacteristicValueTranslation.pending, (state) => {
            state.query.fetchCharacteristicValueTranslations.status = "pending"
            state.query.fetchCharacteristicValueTranslations.canExecute = false;
        }).addCase(getCharacteristicValueTranslation.fulfilled, (state, action) => {
            state.query.fetchCharacteristicValueTranslations.status = "success"
            state.query.fetchCharacteristicValueTranslations.canExecute = true;
            state.loadedData.characteristicValueTranslations = action.payload.getData();

        // initData
        }).addCase(initData.pending, (state) => {
            state.query.initData.status = "pending"
            state.query.initData.canExecute = false;
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.canExecute = true;
            state.query.initData.message = action.error.message;
        }).addCase(initData.fulfilled, (state) => {
            state.query.initData.status = "success"
            state.query.initData.canExecute = true;
            updateCanSave(state);
        })
    }
})

export const {
    resetState,
    addValue,
    changeName,
    changeShortKey,
    changeValueName,
    changeValueKey,
    completedSave,
    deleteValue
} = createCharacteristicTypeSlice.actions

export default createCharacteristicTypeSlice.reducer