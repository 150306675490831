import { IAvailableBaseData } from 'models/available_basedata/available_base_data';
import IAvailableFootType from 'models/available_basedata/available_foot_type';
import IAvailableQuality from 'models/available_basedata/available_quality';
import { ICountry } from 'models/country/country';
import { LoadingState } from 'models/loading_state';
import { IDisabledMassPoint } from 'models/massmasks/massmask';
import { EvaluationResultEnum } from 'models/simulator/product_configuration_evaluation';
import { SideSelectionEnum } from 'models/simulator/side_selection.enum';
import { BodySideEnum } from '../../../models/masspoints/enums/body_side.enum';
import { MasspointEvaluation } from '../../../models/simulator/product_configuration_evaluation';
import { ProcessTypeEnum } from 'models/simulator/process_type.enum';
import { OfferTypeEnum } from 'models/simulator/offer_type.enum';
import { SimulationTypeEnum } from 'models/simulator/simulation_type.enum';
import { ProductConfigurationRunDetails } from 'models/simulator/product_configuration_run_details';

export interface SimulationState {
    query: {
        getMassMask: LoadingState;
        getQualities: LoadingState;
        fetchBaseData: LoadingState;
        getFootoptions: LoadingState;
        getAdditions: LoadingState;
        checkOneleg: LoadingState;
        initData: LoadingState;
        getCountries: LoadingState;
        getArticleAttributeCategories: LoadingState;
        validateMassMask: LoadingState;
        validateAdditions: LoadingState;
        validateProductionInstructions: LoadingState;
    };
    command: {
        loadMassMask: LoadingState;
        evaluateMassMask: LoadingState;
        evaluateAddition: LoadingState;
        evaluateProductionInstruction: LoadingState;
        createOfferWithoutMasspoint: LoadingState;
        createCustomDesignOffer: LoadingState;
        createCustomDesignOrder: LoadingState;
        updateCustomDesign: LoadingState;
    };
    simulation: Simulation;
    qualities: IAvailableQuality[];
    footTypes: IAvailableFootType[];
    disabledMasspoints: IDisabledMassPoint[];
    availablefootoptions: SimulationFootOption[];
    baseData: IAvailableBaseData;
    storedConfigurationData?: ProductConfigurationRunDetails;
    sides: { value: SideSelectionEnum, text: string }[];
    isOneleg: boolean;
    countries: ICountry[];
}

export enum EvaluationState {
    presetSimulator,
    loadMassMask,
    loadingMassMask,
    loadedMassMask,
    reloadMassMask,
    evaluatingMassMask,
    evaluatedMassMask,
    validatingMassMask,
    validatedMassMask,
    evaluateCustomDesign,
    evaluatingCustomDesign,
    evaluatedCustomDesign,
    loadAdditions,
    loadingAdditions,
    loadedAdditions,
    reloadAdditions,
    evaluatingAdditions,
    evaluatedAdditions,
    validatingAdditions,
    validatedAdditions,
    loadProductionInstructions,
    loadingProductionInstructions,
    loadedProductionInstructions,
    reloadProductionInstructions,
    evaluatingProductionInstructions,
    evaluatedProductionInstructions,
    validatingProductionInstruction,
    validatedProductionInstruction,
    restoring,
    restoreMassMask,
    restoringMassMask,
    restoredMassMask,
    restoreAdditions,
    restoringAdditions,
    restoredAdditions,
    restoreProductionInstructions,
    restoringProductionInstructions,
    restoredProductionInstructions
}

export type Simulation = {
    massMaskLoadingFailedErrorMessage: string;
    productConfigurationId?: string;
    productConfigurationIsLocked: boolean;
    configurationRunId?: number;
    info?: SimulationMassMaskInfo;
    processType: ProcessTypeEnum;
    offerType: OfferTypeEnum;
    simulationType: SimulationTypeEnum;
    diagnoseModeEnabled: boolean;
    massMaskDefinition?: MassMaskDefinition;
    evaluations: MasspointEvaluation[];
    additions: ISimulationAdditionViewModel[];
    additionCategoryAttributes: ISimulationAdditionCategoryAttributeViewModel[];
    selectedQuality?: IAvailableQuality;
    selectedFootoption?: SimulationFootOption;
    selectedSide?: SideSelectionEnum;
    selectedCountry: ICountry,
    includeUnpublished: boolean;
    showEvaluationResultInMassmask: boolean;
    productConfigurationStarted: boolean;
    evaluationState: EvaluationState;
}

export interface ISimulationRestoreViewModel {
    additionList: ISimulationAdditionViewModel[],
    success: boolean,
    qualityErpId: string,
    countryCode: string,
    isRestoring: boolean,
    restoreSuccess: boolean,
    categoryAttributes: ISimulationCategoryViewModel[]
}

export interface ISimulationCategoryViewModel {
    categoryErpId: string;
    attributeId: string;
}

export interface ISimulationAdditionViewModel {
    id?: number;
    option: string;
    code: string;
    description: string;
    isSelected: boolean;
    isHidden: boolean;
    placeholder: ISimulationPlaceholderViewModel;
    additionExcludes: ISimulationAdditionExcludeViewModel[];
}

export interface ISimulationAdditionExcludeViewModel {
    excludedId: number;
}

export interface UpdateSimulationAdditionViewModelPayload {
    addition: ISimulationAdditionViewModel,
    value: string,
}

export interface ISimulationAdditionCategoryAttributeViewModel {
    erpId: string;
    name: string;
    displayName: string;
    allAttributes: ISimulationAdditionCategoryAttributeValueViewModel[];
    selectedAttribute: ISimulationAdditionCategoryAttributeValueViewModel;
}

export interface ISimulationAdditionCategoryAttributeValueViewModel {
    id?: number;
    code: string;
    name: string;
    displayName: string;
}

export interface ISimulationPlaceholderViewModel {
    placeholderTemplate: number;
    textInputPlaceholderVersion: ISimulationTextInputPlaceholderViewModel;
    rangePlaceholderVersion: ISimulationRangePlaceholderViewModel;
    twoRangePlaceholderVersion: ISimulationTwoRangePlaceholderViewModel;
}

export interface ISimulationTextInputPlaceholderViewModel {
    textInputMaxLength: number;
    value?: string;
}

export interface ISimulationRangePlaceholderViewModel {
    id?: number;
    minimalValue: number;
    maximalValue: number;
    value?: string;
}

export interface ISimulationTwoRangePlaceholderViewModel {
    id?: number;
    firstMinimalValue: number;
    firstMaximalValue: number;
    secondMinimalValue: number;
    secondMaximalValue: number;

    firstValue?: string;
    secondValue?: string;
}

export type SimulationMassMaskInfo = {
    mainProductLine: string;
    bodyArea: string;
    articleType: string;
    articleTypeId: number;
}

export type MassMaskDefinition = {
    mainProductLineErpId: string;
    bodyAreaErpId: string;
    articleTypeErpId: string;
}

export type SimulationMassDefinition = {
    massDefinitionVersionId: number;
    massImage: string;
    massPoints: SimulationMassPoints;
}

export type SimulationMassPoints = {
    inputMasspoints: SimulationMassPointInputs;
    activeMasspoints: SimulationMassPointInputs;
    paths: SimulationMassPointPaths;
}

export type SimulationMassPointInputs = {
    noSideMasspoints: SimulationMassPoint[];
    leftSideMasspoints: SimulationMassPoint[];
    rightSideMasspoints: SimulationMassPoint[];
}

export type SimulationMassPointPaths = {
    inactiveMasspoints: SimulationMassPoint[];
    activeMasspointsSortedByHighlighted: SimulationMassPoint[];
}

export type SimulationFootOption = {
    id?: number;
    name: string;
    externalIdentifier: number;
}

export type SimulationMassPoint = {
    massPointId: number;
    massDefinitionVersionId: number;
    massMaskId: number;
    name: string;
    textValue?: string;
    evaluationResult: EvaluationResultEnum;
    isHighlighted: boolean;
    svgPathSelector: string;
    bodySide: BodySideEnum;
    hasInitialFocus: boolean;
}

export interface UpdateMassPointValuePayload {
    massPointId: number;
    massMaskId: number;
    massDefinitionVersionId: number;
    textValue: string;
    bodySide: BodySideEnum;
}

export interface SelectMassPointPayload {
    massPointId: number;
    massMaskId: number;
    massDefinitionVersionId: number;
    shouldSelect: boolean;
    bodySide: BodySideEnum;
}

export interface SelectQualityPayload {
    quality: IAvailableQuality;
}

export interface SelectFootOptionPayload {
    footOption: SimulationFootOption;
}

export interface SelectIncludePayload {
    includeUnpublished: boolean;
}

export interface SelectCategoryAttributePayload {
    categoryAttribute: ISimulationAdditionCategoryAttributeViewModel,
    selectedItem: ISimulationAdditionCategoryAttributeValueViewModel
}

export interface ProductConfigurationInformationPayload {
    productConfigurationId: string,
    configurationRunId: number
}

export interface MassMaskAttributesThunk {
    articleTypeErpId: string,
    bodyAreaErpId: string,
    mainProductLineErpId: string
}