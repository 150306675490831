import theme from "../../../style/theme";
import { LoadingIndicatorComponent } from "../../loading_indicator/loading_indicator.component";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { useEffect, useState } from "react";
import * as style from './dropdown.style';
import { isNotUndefinied } from 'services/validation.service';
import _ from "lodash";

interface DropdownProps {
  name: string;
  data: Array<any>;
  displayedProperties?: Array<string>;
  isRequired?: boolean;
  isLoading?: boolean;
  isReadonly?: boolean;
  onSelect: (selectedItem: any) => void;
  selectedValue?: any;
  renderEntry?: (entry: any) => string;
  errorText?: string;
  minWidth?: string;
}

export const DropdownComponent: React.FC<DropdownProps> = ({
  data,
  displayedProperties,
  name,
  onSelect,
  isLoading,
  isReadonly,
  isRequired,
  selectedValue,
  renderEntry,
  errorText,
  minWidth
}) => {

  const [inputValue, setInputValue] = useState('');
  const isErrorVisible = errorText !== "" && errorText != null;

  useEffect(() => {
    if (!selectedValue) {
      setInputValue('');
    }
  }, []);

  if (!isNotUndefinied(selectedValue)) {
    selectedValue = null;
  }

  if (isLoading) {
    return (
      <TextField
        name={name}
        label={name}
        required={isRequired}
        disabled={true}
        InputProps={{
          endAdornment: (
            <LoadingIndicatorComponent size="1rem" />
          )
        }}
      />)
  }

  const getDisplayValue = (option: any) => {
    if (renderEntry && option) {
      return renderEntry(option);
    } else if (displayedProperties && option) {
      return displayedProperties
        .map((property) => option[property])
        .filter(entry => entry != null && entry != undefined)
        .join(" - ");
    } else {
      return "";
    }
  }

  return (
    <FormControl
      sx={{ border: 'none' }}
      style={{ minWidth: minWidth ?? theme.form.minWidth }}
      required={isRequired}
    >
      <Autocomplete
        noOptionsText={'Keine Auswahl verfügbar'}
        id={name}
        title={getDisplayValue(selectedValue)}
        disabled={isReadonly}
        disableClearable
        value={selectedValue}
        onChange={(event: any, selectedItem: any) => {
          onSelect(selectedItem)
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={data}
        getOptionLabel={(option) => {
          if (renderEntry != undefined) {
            return renderEntry(option)
          } else {
            return getDisplayValue(option)
          }
        }
        }
        renderInput={(params) => {
          return (<TextField {...params}
            variant="standard"
            label={name}
            margin="none"
            required={isRequired}
            sx={isErrorVisible ? { input: { color: 'red' } } : {}}
          />);
        }}
        isOptionEqualToValue={() => true}
        // Todo remove m e after test 
        // isOptionEqualToValue={(option, value) =>  _.isEqual(option, value)}
      />
      {isErrorVisible ? <style.ErrorWrapper>{errorText}</style.ErrorWrapper> : <></>}
    </FormControl>
  );
};
