import { HistoryValues } from "models/history_type.enum";
import { filterMasspointHistoryOverviewValue } from "../masspoint_history.helper";
import { MasspointHistoryOverviewsState } from "./masspoint_history.model";

export const filterMasspointHistoryOverviews = (state: MasspointHistoryOverviewsState) => {
    const searchTextLower = state.actualData.searchFilter.toLocaleLowerCase().trim();
    let filteredItems = state.loadedData.masspointHistoryOverviews.filter(
        (masspointHistoryOverview) =>{
            const type = HistoryValues.find(x => x.value === masspointHistoryOverview.type)?.text;

            return (
                type.toLowerCase().includes(searchTextLower) ||
                masspointHistoryOverview.userName?.toLowerCase().includes(searchTextLower) ||
                masspointHistoryOverview.massPointName?.toLowerCase().includes(searchTextLower) ||
                masspointHistoryOverview.massTypeName?.toLowerCase().includes(searchTextLower) ||
                masspointHistoryOverview.bodyAreaName?.toLowerCase().includes(searchTextLower) ||
                masspointHistoryOverview.bodySideName?.toLowerCase().includes(searchTextLower));  
        }
    );

    state.actualData.gridFilters.items.forEach(fil => {
        filteredItems = filterMasspointHistoryOverviewValue(fil, filteredItems);
    });
    
    state.actualData.masspointHistoryOverviews = filteredItems;

}