import { doesExist, isNullOrWhitespace } from "services/validation.service";
import { PolicySideSeamState } from "./policy_side_seam.model";


export const updateCanSave = (state: PolicySideSeamState) => {
    const saveAllowed =
        !isNullOrWhitespace(state.data.policy.name)
        && doesExist(state.data.selectedAddition)
        && 
        ( 
            state.loadedData.isEdit === false
            || isNotEqual(state)
        );
    state.command.savePolicy.canExecute = saveAllowed;
}

const isNotEqual = (state: PolicySideSeamState) => {
    return state.data.policy.name !== state.loadedData.policy.name 
        || state.data.policy.ignoreOnQuotation !== state.loadedData.policy.ignoreOnQuotation 
        || state.data.selectedAddition.id !== state.loadedData.policy.additionId;
}

