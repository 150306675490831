import AddIcon from "@mui/icons-material/Add";
import { PrimaryButtonComponent } from "../../shared/shared";
import { FlexBox, Left, Right } from "../../shared/style/alignment";
import SearchFieldComponent from "../../shared/components/search/searchfield.component";
import { LoadingIndicatorComponent } from '../../shared/components/loading_indicator/loading_indicator.component';
import { AppDispatch } from "app/redux_store";
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { editMassMask, deleteMassMask, confirmDeleteMassMaskCompleted, editMassMaskCompleted, cancelDeleteMassMask, publishMassMaskCompleted, resetState, unpublishMassMaskCompleted, setGridFilters, setSearchFilter } from "./redux/massmasks_overview.slice";
import { createNewMassMask, getMassMasks, togglePublishMassMask, confirmDeleteMassMask } from './redux/massmasks_overview.thunks';
import { ErrorPanelComponent } from '../../shared/components/error_handling/error_panel.component';
import { DialogComponent } from '../../shared/components/dialogs/dialog.component';
import * as style from "./massmask_overview.style";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { OverviewDatagrid } from "shared/components/datagrid/overview_datagrid.component";
import { getGridColumns, sortByMainProductLineModel } from "./massmask_overview.helper";

export const MassmaskOverviewComponent = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const state = useAppSelector((state) => state.massmasks_overview);

  useEffect(() => {
    dispatch(getMassMasks());
    return () => {
      dispatch(resetState());
    }
  }, [dispatch]);

  if (state.query.getMassMasks.status === "error") {
    enqueueSnackbar(state.query.getMassMasks.message, { variant: "error" });
    return <ErrorPanelComponent message={state.query.getMassMasks.message} />;
  }

  if (state.query.getMassMasks.status === "pending" || state.query.getMassMasks.status === "idle") {
    return <LoadingIndicatorComponent />;
  }

  if (state.command.publishMassMask.status === "success") {
    enqueueSnackbar("Maßmaske erfolgreich veröffentlicht", { variant: "success" });
    dispatch(publishMassMaskCompleted())
  }

  if (state.command.unpublishMassMask.status === "success") {
    enqueueSnackbar("Veröffentlichung erfolgreich aufgehoben", { variant: "success" });
    dispatch(unpublishMassMaskCompleted())
  }

  if (state.actualData.massMaskToEdit != null) {
    navigate(`/massmasks/massmask/edit/${state.actualData.massMaskToEdit.id}`);
    dispatch(editMassMaskCompleted());
  }

  if (state.command.createNewMassMask.status === "pending") {
    navigate(`/massmasks/massmask/create`);
  }

  if (state.command.confirmDeleteMassMask.status === "error") {
    enqueueSnackbar(state.command.confirmDeleteMassMask.message, { variant: "warning" });
    dispatch(confirmDeleteMassMaskCompleted());
  }

  if (state.command.confirmDeleteMassMask.status === "success") {
    enqueueSnackbar("Maßmaske entfernt", { variant: "success" });
    dispatch(confirmDeleteMassMaskCompleted())
  }

  const isLoading = state.command.publishMassMask.status === "pending"
    || state.command.unpublishMassMask.status === "pending"
    || state.command.confirmDeleteMassMask.status === "pending";

  const massmasksAvailable = state.loadedData.massMasks?.length > 0;

  return (
    <div>
      {
        state.actualData.showDeleteMassMaskDialog &&
        <DialogComponent
          title={"Maßmaske löschen?"}
          isOpen={true}
          options={[
            { title: "Abbrechen", handleOption: () => { dispatch(cancelDeleteMassMask()) } },
            { title: "Löschen", handleOption: () => { dispatch(confirmDeleteMassMask()) } }
          ]}
          onClose={() => { }}>
          <Grid container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}>
            <Grid item>
              Möchten Sie die Maßmaske löschen?
            </Grid>
            <Grid item>
              Alle Maßdefinitionen werden ebenfalls gelöscht.
            </Grid>
          </Grid>
        </DialogComponent>
      }
      <FlexBox>
        {
          state.actualData.isSearchEnabled &&
          <Left>
            <SearchFieldComponent onSearch={(filterText) => dispatch(setSearchFilter(filterText))} placeholder="In Maßmasken suchen ..." />
          </Left>
        }
        <Right>
          <PrimaryButtonComponent onClick={() => dispatch(createNewMassMask())}>
            <AddIcon /> Neue Maßmaske erstellen
          </PrimaryButtonComponent>
        </Right>
      </FlexBox>
      <style.TableContainer>
        {
          !massmasksAvailable &&
          <div>Keine Maßmasken verfügbar</div>
        }
        {
          massmasksAvailable &&
          <OverviewDatagrid
            isLoading={isLoading}
            columns={getGridColumns(
              (massmask) => dispatch(editMassMask(massmask)),
              (massmask) => dispatch(deleteMassMask(massmask)),
              (massmask) => dispatch(togglePublishMassMask(massmask))
            )}
            rows={state.actualData.massMasks}
            loadedRowsCount={state.loadedData.massMasks.length}
            sortModel={sortByMainProductLineModel}
            onFilterModelChange={(newFilterModel) => dispatch(setGridFilters(newFilterModel))}
            onDoubleClickRow={(massmask) => dispatch(editMassMask(massmask))}
          />
        }
      </style.TableContainer>
    </div>
  );
};
