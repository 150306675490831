import { Grid } from "@mui/material";
import { IAdditionPolicyInformation } from "models/additions/addition_policy_information";
import { DropdownComponent } from "shared/shared";

interface PolicySideSeamEditor {
    selectedAddition: IAdditionPolicyInformation,
    allAdditions: IAdditionPolicyInformation[],
    selectAddition:(addition: IAdditionPolicyInformation) => void;
}

export const PolicySideSeamEditorComponent: React.FC<PolicySideSeamEditor> = ({ 
    selectedAddition, 
    allAdditions, 
    selectAddition, 
}) => {
    return (
        <Grid item container md={12}
            direction="column"
            spacing={3}>
            <Grid container item md={12} spacing={3}
                direction="column">
                <Grid item>
                    <DropdownComponent name="Zusatz"
                        data={allAdditions}
                        minWidth="350px"
                        isRequired={true}
                        onSelect={(e) => selectAddition(e)}
                        displayedProperties={["option", "description"]}
                        selectedValue={selectedAddition}                                                   
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};