import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAdditionPolicyInformation } from "models/additions/addition_policy_information";
import { PolicyDefaultAdditionByArticleTypeCreateState } from '../../policy_default_addition_by_articletype_create/redux/policy_default_addition_by_articletype_create.model';
import { 
    updateCanSave,
    sortAdditions
 } from './policy_default_addition_by_articletype_create.reducer';
import { cancelSave, getAdditions, getBaseData, initData, savePolicy } from './policy_default_addition_by_articletype_create.thunks';
import { createSelectionList, filterSelectionList, getEmptySelectionList, moveFromSelectedToUnselected, moveFromUnselectedToSelected } from 'shared/components/selectionComponent/selectionList.helper';
import { getIAvailableArticleTypeDisplayName } from 'shared/helpers/displayNames';

const initialState: PolicyDefaultAdditionByArticleTypeCreateState = {
    data: { 
        policy: { name: "", ignoreOnQuotation: false},
        selectedAddition: null,
        articleTypes: getEmptySelectionList()
    },
    loadedData: {
        additions: [],
        articleTypes: [],
    },
    command:{
        savePolicy: { status: "idle", canExecute: false },
        cancelSave: { status: "idle", canExecute: true }
    },
    query: {
        fetchAdditions: { status: "idle", canExecute: true },
        fetchBaseData: { status: "idle", canExecute: true },              
        initData: { status: "idle", canExecute: true }
    }
}

export const createPolicyDefaultAdditionByArticleType = createSlice({
    name: 'create_policy_default_addition_by_articletype',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = initialState.data;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        changeName: (state, action: PayloadAction<string>) => {
            state.data.policy.name = action.payload;
            updateCanSave(state);
        },
        selectAddition: (state, action: PayloadAction<IAdditionPolicyInformation>) => {
            state.data.selectedAddition = action.payload;
            updateCanSave(state);
        },
        selectArticleTypes: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.data.articleTypes, action.payload);
            updateCanSave(state);
        },      
        unselectArticleTypes: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.data.articleTypes, action.payload);
            updateCanSave(state);
        },      
        filterSelectedArticleTypes: (state, action: PayloadAction<string>) => {
            state.data.articleTypes.selectedList.searchText = action.payload;
            filterSelectionList(state.data.articleTypes.selectedList);
        },
        filterUnselectedArticleTypes: (state, action: PayloadAction<string>) => {
            state.data.articleTypes.unSelectedList.searchText = action.payload;
            filterSelectionList(state.data.articleTypes.unSelectedList);
        },            
        toggleIgnoreOnQuotation: (state, action: PayloadAction<boolean>) => {
            state.data.policy.ignoreOnQuotation = action.payload;
            updateCanSave(state);
        },                        
        completedSave: (state) => {
            state.command.savePolicy = initialState.command.savePolicy;
        }
    }, extraReducers: (builder) => {

        // fetchAdditions
        builder.addCase(getAdditions.pending, (state) => {
            state.query.fetchAdditions.status = "pending"
            state.query.fetchAdditions.canExecute = false;
        }).addCase(getAdditions.rejected, (state, action) => {
            state.query.fetchAdditions.status = "error"
            state.query.fetchAdditions.message = action.error.message;
            state.query.fetchAdditions.canExecute = true;
        }).addCase(getAdditions.fulfilled, (state, action) => {
            state.query.fetchAdditions.status = "success"
            state.query.fetchAdditions.canExecute = true;
            state.loadedData.additions = action.payload.getData().sort(sortAdditions);
            updateCanSave(state);

        // fetchBaseData
        }).addCase(getBaseData.pending, (state) => {
            state.query.fetchBaseData.status = "pending"
            state.query.fetchBaseData.canExecute = false;
        }).addCase(getBaseData.rejected, (state, action) => {
            state.query.fetchBaseData.status = "error"
            state.query.fetchBaseData.message = action.error.message;
            state.query.fetchBaseData.canExecute = true;
        }).addCase(getBaseData.fulfilled, (state, action) => {
            state.query.fetchBaseData.status = "success"
            state.query.fetchBaseData.canExecute = true;
            state.loadedData.articleTypes = action.payload.getData().availableArticleTypes;
            state.data.articleTypes = createSelectionList(action.payload.getData().availableArticleTypes, [], getIAvailableArticleTypeDisplayName);

        // savePolicy
        }).addCase(savePolicy.pending, (state) => {
            state.command.savePolicy.status = 'pending'
            state.command.savePolicy.canExecute = false;
        }).addCase(savePolicy.rejected, (state, action) => {
            state.command.savePolicy.status = "error"
            state.command.savePolicy.canExecute = true;
            state.command.savePolicy.message = action.error.message;
        }).addCase(savePolicy.fulfilled, (state) => {
            state.command.savePolicy.status = "success"
            state.command.savePolicy.canExecute = false;

        // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancelSave.status = 'pending'
            state.command.cancelSave.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancelSave.status = "success"
            state.command.cancelSave.canExecute = false;

            // initData
        }).addCase(initData.pending, (state) => {
            state.query.initData.status = "pending"
            state.query.initData.canExecute = false;
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.canExecute = true;
            state.query.initData.message = action.error.message;
        }).addCase(initData.fulfilled, (state) => {
            state.query.initData.status = "success"
            state.query.initData.canExecute = true;
            updateCanSave(state);
        })
    }
})

export const {
    resetState,
    changeName,
    selectAddition,
    selectArticleTypes,
    unselectArticleTypes,
    filterSelectedArticleTypes,
    filterUnselectedArticleTypes,
    toggleIgnoreOnQuotation,
    completedSave
} = createPolicyDefaultAdditionByArticleType.actions

export default createPolicyDefaultAdditionByArticleType.reducer