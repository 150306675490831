import { PolicyMandatoryCategoryCreateState } from "./policy_mandatory_category_create.model";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAdditionCategoryOverview } from "models/addition_categories/addition_category_overview";
import { cancelSave, getAdditionCategories, savePolicy } from "./policy_mandatory_category_create.thunks";
import { updateCanSave } from "./policy_mandatory_category_create.reducer";
import { sortAdditionCategories } from "check/policy/helper/policy.helper";

const initialState: PolicyMandatoryCategoryCreateState = {
    data: { 
        policy: { name: "", ignoreOnQuotation: false},
        selectedCategory: null,
    },
    loadedData: {
        additionCategories: [],
    },
    command:{
        savePolicy: { status: "idle", canExecute: false },
        cancelSave: { status: "idle", canExecute: true }
    },
    query: {
        fetchAdditionCategories: { status: "idle", canExecute: true }
    }
}

export const createPolicyMandatoryCategory = createSlice({
    name: 'create_policy_mandatory_category',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = initialState.data;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        changeName: (state, action: PayloadAction<string>) => {
            state.data.policy.name = action.payload;
            updateCanSave(state);
        },
        selectCategory: (state, action: PayloadAction<IAdditionCategoryOverview>) => {
            state.data.selectedCategory = action.payload;
            updateCanSave(state);
        },
        toggleIgnoreOnQuotation: (state, action: PayloadAction<boolean>) => {
            state.data.policy.ignoreOnQuotation = action.payload;
            updateCanSave(state);
        },               
        completedSave: (state) => {
            state.command.savePolicy = initialState.command.savePolicy;
        }
    }, extraReducers: (builder) => {

        // fetchPolicies
        builder.addCase(getAdditionCategories.pending, (state) => {
            state.query.fetchAdditionCategories.status = "pending"
            state.query.fetchAdditionCategories.canExecute = false;
        }).addCase(getAdditionCategories.rejected, (state, action) => {
            state.query.fetchAdditionCategories.status = "error"
            state.query.fetchAdditionCategories.message = action.error.message;
            state.query.fetchAdditionCategories.canExecute = true;
        }).addCase(getAdditionCategories.fulfilled, (state, action) => {
            state.query.fetchAdditionCategories.status = "success"
            state.query.fetchAdditionCategories.canExecute = true;
            state.loadedData.additionCategories = action.payload.getData().sort(sortAdditionCategories);
            updateCanSave(state);
   
        // savePolicy
        }).addCase(savePolicy.pending, (state) => {
            state.command.savePolicy.status = 'pending'
            state.command.savePolicy.canExecute = false;
        }).addCase(savePolicy.rejected, (state, action) => {
            state.command.savePolicy.status = "error"
            state.command.savePolicy.canExecute = true;
            state.command.savePolicy.message = action.error.message;
        }).addCase(savePolicy.fulfilled, (state) => {
            state.command.savePolicy.status = "success"
            state.command.savePolicy.canExecute = false;

        // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancelSave.status = 'pending'
            state.command.cancelSave.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancelSave.status = "success"
            state.command.cancelSave.canExecute = false;

        })
    }
})

export const {
    resetState,
    changeName,
    selectCategory,
    toggleIgnoreOnQuotation,
    completedSave,
} = createPolicyMandatoryCategory.actions

export default createPolicyMandatoryCategory.reducer