import { CircularProgress } from "@mui/material";

interface ILoadingIndicatorProps {
  size?: string;
}

export const LoadingIndicatorComponent: React.FC<ILoadingIndicatorProps> = ({
  size
}) => {
  if (size == null) {
    return <CircularProgress color="secondary" />
  }

  return <CircularProgress color="secondary" size={size} />

}
