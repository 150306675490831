import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPlausibilityCheckType } from 'models/plausibility_check/plausibility_check_type';
import { TypeOverviewState } from './type_overview.model';
import { fetchPlausibilityCheckTypes, confirmDeletePlausibilityCheckType} from './type_overview.thunks';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import { filterPlausibilityCheckTypes } from './type_overview.reducer';

const initialState: TypeOverviewState = {
    loadedData: {
        plausibilityCheckTypes: []
    },
    actualData: { 
        plausibilityCheckTypes: [],
        searchFilter: "",
        gridFilters: {items: []},
        selectedPlausibilityCheckType: null,
        showDeletePlausibilityCheckDialogDialog: false },
    command:{
        confirmDeletePlausibilityCheckType: { status: "idle", canExecute: false },
    },
    query: {
        fetchPlausibilityCheckType: { status: "idle", canExecute: true }
    }
}

export const getPlausibilityCheckTypeSlice = createSlice({
    name: 'get_plausibility_check_type',
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.actualData.searchFilter = action.payload;
            filterPlausibilityCheckTypes(state);
        },
        setGridFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.gridFilters = action.payload;
            filterPlausibilityCheckTypes(state);
        },
        confirmDeletePlausibilityCheckCompleted: (state) => {
            state.command.confirmDeletePlausibilityCheckType.status = "idle";
        },
        deletePlausibilityCheckType: (state, action: PayloadAction<IPlausibilityCheckType>) => {
            state.actualData.showDeletePlausibilityCheckDialogDialog = true;
            state.actualData.selectedPlausibilityCheckType = action.payload;
        },
        cancelDeletePlausibilityCheckType: (state) => {
            state.actualData.showDeletePlausibilityCheckDialogDialog = false;
        },
    }, extraReducers: (builder) => {

        // fetch PlausibiltyCheckType
        builder.addCase(fetchPlausibilityCheckTypes.pending, (state, action) => {
            state.query.fetchPlausibilityCheckType.status = "pending"
            state.query.fetchPlausibilityCheckType.canExecute = false;
        }).addCase(fetchPlausibilityCheckTypes.rejected, (state, action) => {
            state.query.fetchPlausibilityCheckType.status = "error"
            state.query.fetchPlausibilityCheckType.message = action.error.message;
            state.query.fetchPlausibilityCheckType.canExecute = true;
        }).addCase(fetchPlausibilityCheckTypes.fulfilled, (state, action) => {
            state.query.fetchPlausibilityCheckType.status = "success"
            state.query.fetchPlausibilityCheckType.message = undefined;
            state.query.fetchPlausibilityCheckType.canExecute = true;
            state.loadedData.plausibilityCheckTypes = action.payload.getData();
            filterPlausibilityCheckTypes(state);
        
        // confirmDeletePlausibilityCheckType 
        }).addCase(confirmDeletePlausibilityCheckType.pending, (state, action) => {
            state.command.confirmDeletePlausibilityCheckType.status = 'pending'
        }).addCase(confirmDeletePlausibilityCheckType.rejected, (state, action) => {
            state.command.confirmDeletePlausibilityCheckType.status = "error"
            state.command.confirmDeletePlausibilityCheckType.message = action.error.message;
            state.actualData.showDeletePlausibilityCheckDialogDialog = false;
        }).addCase(confirmDeletePlausibilityCheckType.fulfilled, (state, action) => {
            state.command.confirmDeletePlausibilityCheckType.status = "success"
            state.command.confirmDeletePlausibilityCheckType.canExecute = true;
            state.actualData.showDeletePlausibilityCheckDialogDialog = false;
            state.loadedData.plausibilityCheckTypes = action.payload.getData();
            filterPlausibilityCheckTypes(state);        })
    }
})

export const {
    resetState,
    setSearchFilter,
    setGridFilters,
    confirmDeletePlausibilityCheckCompleted,
    cancelDeletePlausibilityCheckType,
    deletePlausibilityCheckType 
} = getPlausibilityCheckTypeSlice.actions

export default getPlausibilityCheckTypeSlice.reducer