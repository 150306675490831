import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseErrors } from "shared/networking/error_handling";
import AdditionService from 'services/addition.service';
import PolicyServiceAPI from 'services/policy.service';
import MassPointService from 'services/masspoint.service';
import { PolicyTypeEnum } from "models/policies/policy_type.enum";
import { RootState } from "app/redux_store";
import BaseDataService from "services/base_data.service";

export const getAdditions = createAsyncThunk(
    'policy/update_policy_adjust_ranges_by_masspoint/getAdditions',
    async (id: string) => {
        const response = await AdditionService.getAvailableAdditionsByPolicyTypeOverview(PolicyTypeEnum.AdjustRangesByMasspoint, id);
        handleResponseErrors(response, "Zusätze");
        return response;
    }
)

export const getMassPoints = createAsyncThunk(
    'policy/update_policy_adjust_ranges_by_masspoint/getMassPoints',
    async (_) => {
        const response = await MassPointService.getMassPoints()
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)

export const getBaseData = createAsyncThunk(
    'policy/update_policy_adjust_ranges_by_masspoint/getBaseData',
    async (_) => {
        const response = await BaseDataService.getBaseData()
        handleResponseErrors(response, "Basisdaten");
        return response;
    }
)

export const getPolicy = createAsyncThunk(
    'policy/update_policy_adjust_ranges_by_masspoint/getPolicy',
    async (id: string) => {
        const response = await PolicyServiceAPI.getAdjustRangesByMasspoint(id);
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const updatePolicy = createAsyncThunk(
    'policy/update_policy_adjust_ranges_by_masspoint/savepolicy',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.policy_adjust_ranges_by_masspoint_update.data;
        const response = await PolicyServiceAPI.updateAdjustRangesByMasspoint({
            policyId: data.policy.id,
            name: data.policy.name,
            ignoreOnQuotation: data.policy.ignoreOnQuotation,
            additionId: data.selectedAddition.id,
            configs: data.configurations.map(config =>
                ({
                    defaultPercentage: config.defaultPercentage,
                    thresholdPercentage: config.thresholdPercentage,
                    thresholdValue: config.thresholdValue,
                    massPoint: { 
                        name: config.selectedMasspoint.name, 
                        type: config.selectedMasspoint.type, 
                        bodyAreaId: config.selectedMasspoint.bodyAreaId
                    },
                    articleTypes: config.articleTypes.selectedList.allItems.map(articleType => articleType.id),
                    qualities: config.qualities.selectedList.allItems.map(quality => quality.id),
                }))
        });
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'policy/update_policy_adjust_ranges_by_masspoint/cancelSave',
    async (_) => { }
)

export const initData = createAsyncThunk(
    'policy/update_policy_adjust_ranges_by_masspoint/initData',
    async (id: string, { dispatch }) => {
        await Promise.all([
            dispatch(getAdditions(id)),
            dispatch(getMassPoints()),
            dispatch(getBaseData()),
        ]);
        await dispatch(getPolicy(id));
    }
)

