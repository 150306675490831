import * as style from "./addition_attribute_category_item.style";
import { Grid, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DropdownComponent } from 'shared/components/form_controls/dropdown/dropdown.component';
import SearchFieldComponent from 'shared/components/search/searchfield.component';
import { DisabledTextBoxComponent } from 'shared/shared';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { ToggleButtonComponent } from 'shared/components/buttons/toggle_button.component';
import { PaperItem } from 'shared/style/paper';
import { BoxItem } from './addition_attribute_category_item.style';
import colors from 'shared/style/colors';
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { MoveAllContainerStyled } from 'shared/components/selectionComponent/selectionList.style';
import { AttributeForm, IAdditionEditor, IAdditionEditorAction } from "models/additions/addition_editor.model";

interface AdditionAttributeCategoryItemComponentProps {
    attributeForm: AttributeForm,
    additionEditor: IAdditionEditor,
    additionEditorActions: IAdditionEditorAction,
    formIndex: number,
}

export const AdditionAttributeCategoryItemComponent: React.FC<AdditionAttributeCategoryItemComponentProps> = (
    {
        attributeForm,
        additionEditor,
        additionEditorActions,
        formIndex
    }) => {

    const displayDummy = [{ name: "Alle" }];

    const renderHeader = () => {
        return (
            <style.AttributeCategoryBoxHeader>
                {
                    attributeForm.selectedCategory == undefined ?
                        <DropdownComponent
                            name="Attributart"
                            data={additionEditor.categories.availableCategories}
                            isLoading={false}
                            isRequired={true}
                            onSelect={(category) => additionEditorActions.selectCategory(category)}
                            displayedProperties={["erpId", "name"]}
                            selectedValue={attributeForm.selectedCategory}
                        />
                        :
                        <DisabledTextBoxComponent
                            name="Attributart"
                            value={attributeForm.selectedCategory.erpId + " " + attributeForm.selectedCategory.name}
                            isRequired={false}
                        />
                }

                <style.ButtonSection>
                    <style.ToggleWrapper>
                        <style.ToggleInfo>Zusatz bei Auswahl des Attributs</style.ToggleInfo>
                        <ToggleButtonComponent
                            enabledText="erlauben"
                            disabledText="verbieten"
                            isEnabled={!attributeForm.isLocked}
                            onDisable={() => { additionEditorActions.denyCategory(formIndex) }}
                            onEnable={() => { additionEditorActions.allowCategory(formIndex) }}
                        />
                    </style.ToggleWrapper>
                    <IconButton
                        style={{ outline: "none", marginLeft: "15px" }}
                        onClick={() => additionEditorActions.deleteAttributeForm(attributeForm)}>
                        <DeleteIcon />
                    </IconButton>
                </style.ButtonSection>
            </style.AttributeCategoryBoxHeader>
        )
    }

    const renderSelectionTable = () => {

        const selectAll = () => {
            additionEditorActions.selectAttributes(({ items: [...attributeForm.unSelectedList.filteredItems], formIndex }));
        }

        const unSelectAll = () => {
            additionEditorActions.unselectAttributes(({items: [...attributeForm.selectedList.filteredItems], formIndex }));
        }

        return (
            <PaperItem>
                <Grid item container
                    spacing={3}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    wrap="nowrap">
                    <Grid item md={6}>
                        <Grid
                            container
                            direction="column"
                            spacing={2}>
                            <Grid item>
                                <strong>Ausgewählte Attribute</strong>
                            </Grid>
                            <Grid item>
                                <SearchFieldComponent
                                    onSearch={(searchText) => { additionEditorActions.filterSelectedAttributes({ searchText, formIndex }) }}
                                    placeholder="In Ausgewählten suchen" />
                            </Grid>
                            <Grid item>
                                <style.ListWrapper>
                                    {selectedAttributesList}
                                </style.ListWrapper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <MoveAllContainerStyled>
                            <IconButton style={{ outline: "none" }} onClick={() => selectAll()}>
                                <KeyboardDoubleArrowLeft />
                            </IconButton>
                            <IconButton style={{ outline: "none" }} onClick={() => unSelectAll()}>
                                <KeyboardDoubleArrowRight />
                            </IconButton>
                        </MoveAllContainerStyled>
                    </Grid>
                    <Grid item md={6}>
                        <Grid
                            container
                            direction="column"
                            spacing={2}>
                            <Grid item>
                                <strong>Verfügbare Attribute</strong>
                            </Grid>
                            <Grid item>
                                <SearchFieldComponent
                                    onSearch={(searchText) => { additionEditorActions.filterUnselectedAttributes({ searchText, formIndex }) }}
                                    placeholder="In Verfügbaren suchen" />
                            </Grid>
                            <Grid item>
                                <style.ListWrapper>
                                    {unselectedAttributesList}
                                </style.ListWrapper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </PaperItem>
        )
    }



    const unselectedAttributesList = attributeForm.unSelectedList.filteredItems.map((item, index) => {
        const isEvenIndex = index % 2 === 0;
        return (
            <div key={index} style={isEvenIndex ? { backgroundColor: colors.mediLightGray } : {}}>
                <IconButton style={{ outline: "none" }} onClick={() => additionEditorActions.selectAttributes({ items: [item], formIndex })}>
                    <KeyboardArrowLeftIcon />
                </IconButton>
                {" "}
                {item.code} {item.name}
            </div >
        )
    });

    const selectedAttributesList = attributeForm.selectedList.allItems.length > 0 ? attributeForm.selectedList.filteredItems.map((item, index) => {
        const isEvenIndex = index % 2 === 0;
        return (
            <div key={index} style={isEvenIndex ? { backgroundColor: colors.mediLightGray } : {}}>
                <IconButton style={{ outline: "none" }} onClick={() => additionEditorActions.unselectAttributes({ items: [item], formIndex })}>
                    <KeyboardArrowRightIcon />
                </IconButton>
                {" "}
                {item.code} {item.name}
            </div>);
    }) :
        displayDummy.map((item, index) => {
            return (
                <div key={index}>
                    <IconButton style={{ outline: "none" }}>
                        <AllInclusiveIcon />
                    </IconButton>
                    {" "}
                    {item.name}
                </div >
            );
        });

    return (
        <>
            <BoxItem key={formIndex}>
                {renderHeader()}
                {renderSelectionTable()}
            </BoxItem>
        </>
    );
};