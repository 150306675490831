import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseErrors } from "shared/networking/error_handling";
import PolicyServiceAPI from 'services/policy.service';
import AdditionAttributeCategoryServiceAPI from 'services/addition_attribute_category.service';
import { RootState } from "app/redux_store";
import { PolicyTypeEnum } from "models/policies/policy_type.enum";
import AdditionService from 'services/addition.service';
import BaseDataService from "services/base_data.service";
import { IAvailableMappedBaseDataByAddition } from "models/available_basedata/available_mapped_base_data";
import MassPointService from "services/masspoint.service";
import { setMasspoint } from "../common/helpers";
import { EditorModeEnum } from "models/editors/editor_mode.enum";
import { setEditorMode } from "./policy_mandatory_by_attribute.slice";

export const getAvailableAttributeCategoriesAndAttributes = createAsyncThunk(
    'policy/mandatory_by_attribute/getAvailableAttributeCategoriesAndAttributes',
    async (id: string) => {
        const response = await AdditionAttributeCategoryServiceAPI.getAvailableAttributeCategoriesAndAttributesByPolicy(PolicyTypeEnum.DefaultByAttribute, id)
        handleResponseErrors(response, "Basisdaten");
        return response;
    }
)

export const getAdditions = createAsyncThunk(
    'policy/mandatory_by_attribute/getAdditions',
    async (_) => {
        const response = await AdditionService.getAvailableAdditionsByPolicyTypeOverview(PolicyTypeEnum.DefaultByAttribute, undefined);
        handleResponseErrors(response, "Zusätze");
        return response;
    }
)

export const getMappedBaseDataByAddition = createAsyncThunk(
    'policy/mandatory_by_attribute/getMappedBaseDataByAddition',
    async (additionIds: number[]) => {
        const mappedBaseData: IAvailableMappedBaseDataByAddition[] = [];
        await Promise.all(
            additionIds.map(async (additionId) => {
                const response = await BaseDataService.getMappedBaseDataByAddition(additionId)
                handleResponseErrors(response, "Basisdaten");
                mappedBaseData.push(response.getData());
            }));

        return mappedBaseData;
    }
)

export const getMassPoints = createAsyncThunk(
    'policy/mandatory_by_attribute/getMassPoints',
    async (_) => {
        const response = await MassPointService.getMassPoints()
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)

export const getPolicy = createAsyncThunk(
    'policy/mandatory_by_attribute/getPolicy',
    async (id: string) => {
        const response = await PolicyServiceAPI.getMandatoryByAttribute(id);
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const updatePolicy = createAsyncThunk(
    'policy/mandatory_by_attribute/updatePolicy',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.policy_mandatory_by_attribute.data;
        const response = await PolicyServiceAPI.updateMandatoryByAttribute({
            policyId: data.editor.policy.id,
            ignoreOnQuotation: data.editor.policy.ignoreOnQuotation,
            name: data.editor.policy.name,
            attributeId: data.editor.selectedAttribute.id,
            configs: data.editor.configs.map(conf => ({
                additionId: conf.addition.id,
                subConfigs: conf.subConfigs.map(subConfig => ({
                    mainProductLineId: subConfig.mainProductLine.id,
                    bodyAreaId: subConfig.bodyArea.id,
                    articleTypeIds: subConfig.articleTypes.selectedList.allItems.map(x => x.id),
                    qualityIds: subConfig.qualities.selectedList.allItems.map(x => x.id),
                    defaultMasspointOne: setMasspoint(subConfig.defaultMasspointOne),
                    defaultMasspointTwo: setMasspoint(subConfig.defaultMasspointTwo),
                    isSideDependant: subConfig.isSideDependant,
                    attributeMasspointConfigurations: subConfig.attributeMasspointConfigurations.map(x => ({
                        articleTypeId: x.articleType.id,
                        masspointOne: setMasspoint(x.masspointOne),
                        masspointTwo: setMasspoint(x.masspointTwo),
                    }))
                }))
            }))
        });
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const createPolicy = createAsyncThunk(
    'policy/mandatory_by_attribute/createPolicy',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.policy_mandatory_by_attribute.data;
        const response = await PolicyServiceAPI.createMandatoryByAttribute({
            name: data.editor.policy.name,
            ignoreOnQuotation: data.editor.policy.ignoreOnQuotation,
            attributeId: data.editor.selectedAttribute.id,
            configs: data.editor.configs.map(conf => ({
                additionId: conf.addition.id,
                subConfigs: conf.subConfigs.map(subConfig => ({
                    mainProductLineId: subConfig.mainProductLine.id,
                    bodyAreaId: subConfig.bodyArea.id,
                    articleTypeIds: subConfig.articleTypes.selectedList.allItems.map(x => x.id),
                    qualityIds: subConfig.qualities.selectedList.allItems.map(x => x.id),
                    defaultMasspointOne: setMasspoint(subConfig.defaultMasspointOne),
                    defaultMasspointTwo: setMasspoint(subConfig.defaultMasspointTwo),
                    isSideDependant: subConfig.isSideDependant,
                    attributeMasspointConfigurations: subConfig.attributeMasspointConfigurations.map(x => ({
                        articleTypeId: x.articleType.id,
                        masspointOne: setMasspoint(x.masspointOne),
                        masspointTwo: setMasspoint(x.masspointTwo),
                    }))
                }))
            }))
        });
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'policy/mandatory_by_attribute/cancelSave',
    async (_) => { }
)

export const initData = createAsyncThunk(
    'policy/mandatory_by_attribute/initData',
    async (id: string, { dispatch, getState }) => {
        await Promise.all([
            dispatch(getAvailableAttributeCategoriesAndAttributes(id)),
            dispatch(getAdditions()),
            dispatch(getMassPoints())
        ]);
        if(id){
            await dispatch(getPolicy(id));
            dispatch(setEditorMode(EditorModeEnum.Edit))
            const state = getState() as RootState;
            var data = state.policy_mandatory_by_attribute;
            var additionIds = data.loadedData.policy.configs.flatMap(con => con.additionId);
            await dispatch(getMappedBaseDataByAddition(additionIds))
        }
    }
)

