import { Chip } from "@mui/material";
import { GridCellParams, GridColDef, GridSortModel } from "@mui/x-data-grid-pro";
import IBodyInformationOverview from "models/bodyinformation/bodyinformation_overview";
import { CustomFilterOperators } from "shared/components/datagrid/custom_filter_operators";
import { DatagridMenu } from "shared/components/datagrid/datagrid_menu.component";
import { BodyInformationType, BodyInformationTypeEnumValues } from "../components/bodyinformation_editor.model";

export const getGridColumns =
    (
        editBodyInformation: (bodyInformation: IBodyInformationOverview) => void,
        deleteBodyInformation: (bodyInformation: IBodyInformationOverview) => void
    ): GridColDef[] => {
        return [
            {
                field: "menu",
                headerName: " ",
                width: 15,
                disableColumnMenu: true,
                filterable: false,
                sortable: false,
                hideable: false,
                pinnable: false,
                renderCell: params => DatagridMenu({
                    params: params, menuItemsData:
                        [
                            { label: "Zusätzliche Angabe editieren", action: editBodyInformation },
                            { label: "Zusätzliche Angabe löschen", action: deleteBodyInformation }
                        ]
                })
            },
            {
                field: "translationKeyForName",
                headerName: "Übersetzungskey",
                disableColumnMenu: false,
                width: 140,
                sortable: false,
                hideable: false,
                pinnable: false,
                filterOperators: CustomFilterOperators(),
            },
            {
                field: "type",
                headerName: "Positionstyp",
                disableColumnMenu: false,
                width: 140,
                sortable: false,
                hideable: false,
                pinnable: false,
                filterOperators: CustomFilterOperators(),
                valueGetter: (value, row) => buildPositionTypeText(row.type),
            },
            {
                field: "entryCount",
                headerName: "Anzahl Positionen",
                disableColumnMenu: false,
                width: 140,
                sortable: false,
                hideable: false,
                pinnable: false,
                filterOperators: CustomFilterOperators('number'),
                renderCell: renderBodySideEntriesCount
            },
        ];
    }

const renderBodySideEntriesCount = (params: GridCellParams) => {
    return <>
        <Chip color="primary" size="medium" label={params.row?.entryCount} />
    </>
}

const buildPositionTypeText = (type: BodyInformationType) => {
    return BodyInformationTypeEnumValues.find(x => x.value === type)?.text;
};


export const sortModel: GridSortModel = [
    {
        field: "categoryName",
        sort: "asc",
    },
];