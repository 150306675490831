import { FormControlLabel, Grid, Radio } from "@mui/material"
import { EditorDescriptionComponent } from "shared/components/editors/editor_description/editor_description.style"
import { EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";
import { DecimalInputComponent } from "shared/components/input/numberInput/decimal_input.component"

export interface PositionCategoryOptionComponentProps {
    header: string;
    description: string;
    value: boolean;
    setNewValue: (value: boolean) => void
}

export const PositionCategoryItemOptionComponent = (props: PositionCategoryOptionComponentProps) => {

    return (
    <>
        <EditorSectionHeaderComponent>{props.header}</EditorSectionHeaderComponent>
        <EditorDescriptionComponent>{props.description}</EditorDescriptionComponent>
        <Grid item>
            <FormControlLabel
                control={
                    <Radio
                        checked={props.value}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={() => props.setNewValue(true)}
                    />
                }
                label={"Ja"}
            />
            <FormControlLabel
                control={
                    <Radio
                        checked={!props.value}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={() => props.setNewValue(false)}
                    />
                }
                label={"Nein"}
            />
        </Grid>
    </>
    )
}