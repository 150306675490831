import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useState } from "react";
import theme from "../../../style/theme";
import * as style from "./radio_box_advanced.style";

export interface RadioGroupAdvancedProps {
  name: string;
  nameHidden?: boolean;
  description?: string;
  data: Array<any>;
  displayedProperties: Array<string>;
  isRequired?: boolean;
  selectedItem?: any;
  onSelect: (selectedItem: any) => void;
  disabled?: boolean;
}

export const RadioBoxAdvancedComponent: React.FC<RadioGroupAdvancedProps> = ({
  data,
  displayedProperties,
  name,
  nameHidden,
  description,
  onSelect,
  isRequired,
  selectedItem,
  disabled
}) => {
  const [selectedIndex, setSelectedIndex] = useState(
    data.indexOf(selectedItem)
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const index = Number((e.target as HTMLInputElement).value);
    setSelectedIndex(index);
    onSelect(data[index]);
  };

  const isHidden = description == null || description === "" || description == undefined;

  return (<div>
    <FormControl
      component="fieldset"
      style={{ minWidth: theme.form.minWidth }}
      required={isRequired}
    >
      <style.RadioBoxHeader hidden={nameHidden}>{name}</style.RadioBoxHeader>
      <style.Description hidden={isHidden}>{description}</style.Description>
      <RadioGroup
        aria-describedby={`${name}_description`}
        row={true}
        aria-label={name}
        name={name}
        value={`${selectedIndex}`}
        onChange={handleChange}
      >
        {data.map((entry, index) => (
          <FormControlLabel
            key={index}
            value={`${index}`}
            control={<Radio />}
            label={displayedProperties.map((property) => entry[property])}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  </div>
  );
};
