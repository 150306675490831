import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAdditionPolicyInformation } from "models/additions/addition_policy_information";
import { cancelSave, getAdditions, getBaseData, getMassPoints, initData, savePolicy } from './policy_adjust_ranges_by_masspoint_create.thunks';
import { sortAdditions } from 'check/policy/helper/policy.helper';
import { calculateAvailableMasspoints, createMasspointItemList, createNewConfiguration, updateCanSave } from './policy_adjust_ranges_by_masspoint_create.reducer';
import { PolicyAdjustRangesByMasspointCreateState } from './policy_adjust_ranges_by_masspoint_create.model';
import { IIndexedIds, IIndexedMasspoint, IIndexedNumber, IIndexedSearchText } from '../../components/policy_adjust_ranges_by_masspoint_configuration.model';
import { filterSelectionList, moveFromSelectedToUnselected, moveFromUnselectedToSelected } from 'shared/components/selectionComponent/selectionList.helper';

const initialState: PolicyAdjustRangesByMasspointCreateState = {
    data: { 
        policy: { name: "", ignoreOnQuotation: false},
        selectedAddition: null,
        allMasspoints: [],
        configurations: []
    },
    loadedData: {
        additions: [],
        masspoints: [],
        articleTypes: [],
        qualities: []
    },
    command:{
        savePolicy: { status: "idle", canExecute: false },
        cancelSave: { status: "idle", canExecute: true }
    },
    query: {
        fetchAdditions: { status: "idle", canExecute: true },
        fetchMasspoints: { status: "idle", canExecute: true },    
        fetchBaseData: { status: "idle", canExecute: true },                        
        initData: { status: "idle", canExecute: true }
    }
}

export const createPolicyAdjustRangesByMasspoint = createSlice({
    name: 'create_policy_adjust_ranges_by_masspoint',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = initialState.data;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        changeName: (state, action: PayloadAction<string>) => {
            state.data.policy.name = action.payload;
            updateCanSave(state);
        },  
        selectAddition: (state, action: PayloadAction<IAdditionPolicyInformation>) => {
            state.data.selectedAddition = action.payload;
            updateCanSave(state);
        }, 
        addConfiguration: (state) => {
            const configuration = createNewConfiguration(state);
            filterSelectionList(configuration.articleTypes.selectedList);
            filterSelectionList(configuration.articleTypes.unSelectedList);        
            filterSelectionList(configuration.qualities.selectedList);
            filterSelectionList(configuration.qualities.unSelectedList);
            state.data.configurations = [...state.data.configurations, configuration];
            calculateAvailableMasspoints(state.data.configurations, state.loadedData.masspoints);
            updateCanSave(state);
        },  
        deleteConfiguration: (state, action: PayloadAction<number>) => {
            state.data.configurations = state.data.configurations.filter((_,index) => index !== action.payload);
            calculateAvailableMasspoints(state.data.configurations, state.loadedData.masspoints);
        },
        selectMasspoint: (state, action: PayloadAction<IIndexedMasspoint>) => {
            state.data.configurations[action.payload.index].selectedMasspoint = action.payload.masspoint;
            calculateAvailableMasspoints(state.data.configurations, state.loadedData.masspoints);
            updateCanSave(state);
        },      
        changeThresholdValue: (state, action: PayloadAction<IIndexedNumber>) => {
            const configuration = state.data.configurations[action.payload.index];
            configuration.thresholdValue = action.payload.value;
            updateCanSave(state);
        },   
        changeDefaultPercentage: (state, action: PayloadAction<IIndexedNumber>) => {
            const configuration = state.data.configurations[action.payload.index];
            configuration.defaultPercentage = action.payload.value;
            updateCanSave(state);
        },    
        changeThresholdPercentage: (state, action: PayloadAction<IIndexedNumber>) => {
            const configuration = state.data.configurations[action.payload.index];
            configuration.thresholdPercentage = action.payload.value;
            updateCanSave(state);
        },  
        toggleIgnoreOnQuotation: (state, action: PayloadAction<boolean>) => {
            state.data.policy.ignoreOnQuotation = action.payload;
            updateCanSave(state);
        },
        selectArticleTypes: (state, action: PayloadAction<IIndexedIds>) => {
            const configuration = state.data.configurations[action.payload.index];
            moveFromUnselectedToSelected(configuration.articleTypes, action.payload.ids);
            updateCanSave(state);
        },      
        unSelectArticleTypes: (state, action: PayloadAction<IIndexedIds>) => {
            const configuration = state.data.configurations[action.payload.index];
            moveFromSelectedToUnselected(configuration.articleTypes, action.payload.ids);
            updateCanSave(state);
        },      
        filterSelectedArticleTypes: (state, action: PayloadAction<IIndexedSearchText>) => {
            const configuration = state.data.configurations[action.payload.index];
            configuration.articleTypes.selectedList.searchText = action.payload.searchText;
            filterSelectionList(configuration.articleTypes.selectedList);
        },
        filterUnselectedArticleTypes: (state, action: PayloadAction<IIndexedSearchText>) => {
            const configuration = state.data.configurations[action.payload.index];
            configuration.articleTypes.unSelectedList.searchText = action.payload.searchText;
            filterSelectionList(configuration.articleTypes.unSelectedList);
        },
        selectQualities: (state, action: PayloadAction<IIndexedIds>) => {
            const configuration = state.data.configurations[action.payload.index];
            moveFromUnselectedToSelected(configuration.qualities, action.payload.ids);
            updateCanSave(state);
        },      
        unSelectQualities: (state, action: PayloadAction<IIndexedIds>) => {
            const configuration = state.data.configurations[action.payload.index];
            moveFromSelectedToUnselected(configuration.qualities, action.payload.ids);
            updateCanSave(state);
        },      
        filterSelectedQualities: (state, action: PayloadAction<IIndexedSearchText>) => {
            const configuration = state.data.configurations[action.payload.index];
            configuration.qualities.selectedList.searchText = action.payload.searchText;
            filterSelectionList(configuration.qualities.selectedList);
        },
        filterUnselectedQualities: (state, action: PayloadAction<IIndexedSearchText>) => {
            const configuration = state.data.configurations[action.payload.index];
            configuration.qualities.unSelectedList.searchText = action.payload.searchText;
            filterSelectionList(configuration.qualities.unSelectedList);
        },                                     
        completedSave: (state) => {
            state.command.savePolicy = initialState.command.savePolicy;
        }
    }, extraReducers: (builder) => {

        // fetchAdditions
        builder.addCase(getAdditions.pending, (state) => {
            state.query.fetchAdditions.status = "pending"
            state.query.fetchAdditions.canExecute = false;
        }).addCase(getAdditions.rejected, (state, action) => {
            state.query.fetchAdditions.status = "error"
            state.query.fetchAdditions.message = action.error.message;
            state.query.fetchAdditions.canExecute = true;
        }).addCase(getAdditions.fulfilled, (state, action) => {
            state.query.fetchAdditions.status = "success"
            state.query.fetchAdditions.canExecute = true;
            state.loadedData.additions = action.payload.getData().sort(sortAdditions);
            updateCanSave(state);

            // fetchMasspoints
        }).addCase(getMassPoints.pending, (state) => {
            state.query.fetchMasspoints.status = "pending"
            state.query.fetchMasspoints.canExecute = false;
        }).addCase(getMassPoints.rejected, (state, action) => {
            state.query.fetchMasspoints.status = "error"
            state.query.fetchMasspoints.message = action.error.message;
            state.query.fetchMasspoints.canExecute = true;
        }).addCase(getMassPoints.fulfilled, (state, action) => {
            state.query.fetchMasspoints.status = "success"
            state.query.fetchMasspoints.canExecute = true;
            const masspoints = createMasspointItemList(action.payload.getData());   
            state.loadedData.masspoints = masspoints;
            state.data.allMasspoints = masspoints;

        // fetchBaseData
        }).addCase(getBaseData.pending, (state) => {
            state.query.fetchBaseData.status = "pending"
            state.query.fetchBaseData.canExecute = false;
        }).addCase(getBaseData.rejected, (state, action) => {
            state.query.fetchBaseData.status = "error"
            state.query.fetchBaseData.message = action.error.message;
            state.query.fetchBaseData.canExecute = true;
        }).addCase(getBaseData.fulfilled, (state, action) => {
            state.query.fetchBaseData.status = "success"
            state.query.fetchBaseData.canExecute = true;
            state.loadedData.articleTypes = action.payload.getData().availableArticleTypes; 
            state.loadedData.qualities = action.payload.getData().availableQualities;
            
        // savePolicy
        }).addCase(savePolicy.pending, (state) => {
            state.command.savePolicy.status = 'pending'
            state.command.savePolicy.canExecute = false;
        }).addCase(savePolicy.rejected, (state, action) => {
            state.command.savePolicy.status = "error"
            state.command.savePolicy.canExecute = true;
            state.command.savePolicy.message = action.error.message;
        }).addCase(savePolicy.fulfilled, (state) => {
            state.command.savePolicy.status = "success"
            state.command.savePolicy.canExecute = false;

        // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancelSave.status = 'pending'
            state.command.cancelSave.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancelSave.status = "success"
            state.command.cancelSave.canExecute = false;

            // initData
        }).addCase(initData.pending, (state) => {
            state.query.initData.status = "pending"
            state.query.initData.canExecute = false;
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.canExecute = true;
            state.query.initData.message = action.error.message;
        }).addCase(initData.fulfilled, (state) => {
            state.query.initData.status = "success"
            state.query.initData.canExecute = true;
            updateCanSave(state);
        })
    }
})

export const {
    resetState,
    changeName,
    selectAddition,
    addConfiguration,
    selectArticleTypes,
    unSelectArticleTypes,   
    selectQualities,
    unSelectQualities,
    filterSelectedArticleTypes,
    filterUnselectedArticleTypes,  
    filterSelectedQualities,
    filterUnselectedQualities,
    deleteConfiguration,
    selectMasspoint,
    changeThresholdPercentage,
    changeDefaultPercentage,
    changeThresholdValue,
    toggleIgnoreOnQuotation,
    completedSave
} = createPolicyAdjustRangesByMasspoint.actions

export default createPolicyAdjustRangesByMasspoint.reducer