import { LoadingStatus } from '../../models/loading_state';
import { CommonApiResult, CommonApiResultError } from './common_api_result';
export class ApiResult<T> {
    private statusCode: number;
    private isRequestTimeout: boolean;
    private data: CommonApiResult<T>;

    public withTimeout(): ApiResult<T> {
        this.isRequestTimeout = true;
        return this;
    }

    public withStatusCode(statusCode: number): ApiResult<T> {
        this.statusCode = statusCode;
        return this;
    }

    public withData(data: CommonApiResult<T>): ApiResult<T> {
        this.data = data;
        return this;
    }

    public isSuccessfull() {
        const httpSucessful = !this.isRequestTimeout && this.statusCode >= 200 && this.statusCode < 300;
        if (!httpSucessful) {
            return false;
        }

        const commonApiResult = this.data;
        if (commonApiResult) {
            if (commonApiResult.success)
                this.statusCode = 200;
            else
                this.statusCode = commonApiResult.error.errorCode;
        }

        return (this.statusCode >= 200 && this.statusCode < 300);
    }

    public isCustomError() {
        const commonApiResult = this.data;
        return (commonApiResult && !commonApiResult.success);
    }

    public isBadRequest() {
        return this.statusCode === 400
    }

    public isUnauthorized() {
        return this.statusCode === 401;
    }

    public isForbidden() {
        return this.statusCode === 403;
    }

    public isNotFound() {
        return this.statusCode === 404
    }

    public isConflict() {
        return this.statusCode === 409
    }

    public isUnprocessableEntity() {
        return this.statusCode === 422;
    }

    public isServerError() {
        return this.statusCode === 500
    }

    public isTimeout() {
        return this.isRequestTimeout;
    }

    public getData(): T {
        return this.data?.content as T;
    }

    public getErrorCode(): string {
        if (this.isCustomError()){
            return this.data.error.errorCode.toString();
        }

        return this.statusCode.toString();
    }

    public getCustomError(): CommonApiResultError {
        if (this.isCustomError())
            return this.data.error;

        return null;
    }

    public state(): LoadingStatus {
        if (this.isTimeout()
            || this.isCustomError()
            || this.isServerError()
            || this.isBadRequest()
            || this.isNotFound()
            || this.isUnprocessableEntity()
            || this.isConflict()
            || this.isUnauthorized()
            || this.isForbidden()
        ) {

            return "error";
        }
        return "success";
    }
}