import { IPositionCategoryOverview } from 'models/position_category/position_category_overview';
import { PositionCategoryOverviewState } from './position_category_overview.model';
import { filterPositionCategoriesValue } from '../position_category_overview.helper';

export const filterPositionCategories = (state: PositionCategoryOverviewState) => {
    const searchTextLower = state.actualData.searchFilter.toLowerCase().trim();
    let filteredItems = state.loadedData.positionCategories.filter(
        (positionCategory) =>
            positionCategory.name.toLowerCase().includes(searchTextLower)
    ).sort(sortPositionCategory);

    state.actualData.gridFilters.items.forEach(fil => {
        filteredItems = filterPositionCategoriesValue(fil, filteredItems);
    });
    state.actualData.positionCategories = filteredItems;
}

export const sortPositionCategory = (positionCategory1: IPositionCategoryOverview, positionCategory2: IPositionCategoryOverview) => {
    return positionCategory1.name.localeCompare(positionCategory2.name);
}

