import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMassDefinition } from '../../../models/massdefinitions/massdefinition';
import { MassMaskMassDefinitionState } from './massmask_massdefinition.model';
import { resetMassDefinitionState } from './massmask_massdefinition.reducer';
import { addMassDefinition, assignMassPoints, confirmDeleteMassDefinition, finishMassDefinitions, getMassMask, updateMassDefinitionSortIndex } from './massmask_massdefinition.thunks';

const initialState: MassMaskMassDefinitionState = {
    actualData: {
        massMask: null,
        selectedMassDefinition: null,
        showDeleteMassDefinitionDialog: false,
    },
    query: {
        getMassMask: { status: "idle", canExecute: true },
    },
    command: {
        confirmDeleteMassDefinition: { status: "idle", canExecute: false },
        addMassDefinition: { status: "idle", canExecute: false },
        assignMassPoints: { status: "idle", canExecute: false },
        finishMassDefinitions: { status: "idle", canExecute: false },
        updateMassDefinitionSortIndex: { status: "idle", canExecute: false },
    }
}

export const massMaskMassDefinitionSlice = createSlice({
    name: 'massMask/massDefinition',
    initialState,
    reducers: {
        resetState: (state) => {
            resetMassDefinitionState(state, initialState);
        },
        deleteMassDefinition: (state, action: PayloadAction<IMassDefinition>) => {
            state.actualData.showDeleteMassDefinitionDialog = true;
            state.actualData.selectedMassDefinition = action.payload;
        },
        cancelDeleteMassDefinition: (state) => {
            state.actualData.showDeleteMassDefinitionDialog = false;
        },
        confirmDeleteMassDefinitionCompleted: (state) => {
            state.command.confirmDeleteMassDefinition.status = "idle";
        },
        updateMassDefinitionSortIndexCompleted: (state) => {
            state.command.updateMassDefinitionSortIndex.status = "idle";
        }
    }, extraReducers: (builder) => {

        // confirmDeleteMassDefinition 
        builder.addCase(confirmDeleteMassDefinition.pending, (state, action) => {
            state.command.confirmDeleteMassDefinition.status = 'pending'
        }).addCase(confirmDeleteMassDefinition.rejected, (state, action) => {
            state.command.confirmDeleteMassDefinition.status = "error"
            state.command.confirmDeleteMassDefinition.message = action.error.message;
            state.actualData.showDeleteMassDefinitionDialog = false;
        }).addCase(confirmDeleteMassDefinition.fulfilled, (state, action) => {
            state.command.confirmDeleteMassDefinition.status = "success"
            state.command.confirmDeleteMassDefinition.canExecute = true;
            state.actualData.showDeleteMassDefinitionDialog = false;
            state.actualData.massMask = action.payload.getData();

            // getMassMask
        }).addCase(getMassMask.pending, (state, action) => {
            state.query.getMassMask.status = 'pending'
        }).addCase(getMassMask.rejected, (state, action) => {
            state.query.getMassMask.status = "error"
            state.query.getMassMask.message = action.error.message;
        }).addCase(getMassMask.fulfilled, (state, action) => {
            state.query.getMassMask.status = "success"
            state.query.getMassMask.canExecute = true;
            state.actualData.massMask = action.payload.getData();

            // addMassDefinition 
        }).addCase(addMassDefinition.pending, (state, action) => {
            state.command.addMassDefinition.status = 'pending'
        }).addCase(addMassDefinition.fulfilled, (state, action) => {
            resetMassDefinitionState(state, initialState);

            // assignMassPoints 
        }).addCase(assignMassPoints.pending, (state, action) => {
            state.command.assignMassPoints.status = 'pending'
            state.actualData.selectedMassDefinition = action.meta.arg;
        }).addCase(assignMassPoints.fulfilled, (state, action) => {
            resetMassDefinitionState(state, initialState);

            // finishMassDefinitions 
        }).addCase(finishMassDefinitions.pending, (state, action) => {
            state.command.finishMassDefinitions.status = 'pending'
        }).addCase(finishMassDefinitions.fulfilled, (state, action) => {
            resetMassDefinitionState(state, initialState);

            // updateSortIndexes 
        }).addCase(updateMassDefinitionSortIndex.pending, (state, action) => {
            state.command.updateMassDefinitionSortIndex.status = 'pending'
        }).addCase(updateMassDefinitionSortIndex.rejected, (state, action) => {
            state.command.updateMassDefinitionSortIndex.status = "error"
            state.command.updateMassDefinitionSortIndex.message = action.error.message;
        }).addCase(updateMassDefinitionSortIndex.fulfilled, (state, action) => {
            state.command.updateMassDefinitionSortIndex.status = "success"
            state.command.updateMassDefinitionSortIndex.canExecute = true;
            state.actualData.massMask = action.payload.getData();
        })
    }
})

export const {
    resetState,
    deleteMassDefinition,
    cancelDeleteMassDefinition,
    confirmDeleteMassDefinitionCompleted,
    updateMassDefinitionSortIndexCompleted
} = massMaskMassDefinitionSlice.actions

export default massMaskMassDefinitionSlice.reducer