import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import theme from "../../../style/theme";
import  * as style from './disabled_text_box.style'

export interface DisabledTextBoxProps {
  name: string;
  placeholder?: string;
  isRequired?: boolean;
  maxLength?: number;
  width?: string,
  value?: string;
  errorText?: string;
}

export const DisabledTextBoxComponent: React.FC<DisabledTextBoxProps> = ({
  name,
  placeholder,
  maxLength,
  width,
  value, 
  errorText
}) => {
  const isErrorVisible = errorText !== "" && errorText != null;
  return (
    <FormControl
      component="fieldset"
      style={{ minWidth: theme.form.minWidth, width: width }}
    >
      <InputLabel shrink htmlFor={name}>{name} </InputLabel>
      <Input
        aria-describedby={`${name}_description`}
        id={name}
        placeholder={placeholder}
        disabled={true}
        inputProps={{ maxLength: maxLength ?? 512 }}
        value={value}
        error={isErrorVisible}
      />
      { isErrorVisible ? <style.ErrorWrapper>{errorText}</style.ErrorWrapper>:<></>}
    </FormControl>
  );
};
