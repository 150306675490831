import { FlexBox, Left, LoadingIndicatorComponent, PrimaryButtonComponent, Right } from "shared/shared";
import * as style from "./policy_overview.style";
import { useAppDispatch, useAppSelector } from '../../../app/hook';
import { AppDispatch } from '../../../app/redux_store';
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import {
    createNewMandatoryCategoryByMasspointPolicy,
    createNewMandatoryCategoryPolicy,
    createNewWhippedSeamPolicy,
    createNewAdditionRankPolicy,
    fetchPolicies,
    togglePublish,
    updatePolicy,
    createNewDefaultAdditionByArticleTypePolicy,
    createNewDefaultAdditionByQualityAndAttributePolicy,
    createNewExcludeAdditionByMasspointPolicy,
    createNewUpperThresholdPolicy,
    createNewExcludeAdditionByMasspointValuePolicy,
    createNewAdjustRangesByMasspointPolicy,
    createNewAdjustMaximumRangePolicy,
    createNewDefaultByAttributePolicy,
    createNewSideSeamPolicy,
    createNewScarTreatmentPolicy,
    createNewFingersAndToesPolicy
} from "./redux/policy_overview.thunks";
import { deleteCompleted, deletePolicy, publishCompleted, resetState, setGridFilters, setSearchFilter, unpublishCompleted, updateCompleted } from "./redux/policy_overview.slice";
import { PolicyDeleteDialogComponent } from "./components/policy_delete_dialog.component";
import { Menu, MenuItem, ClickAwayListener, Popper, Box, Paper, Tooltip, IconButton } from "@mui/material";
import { PolicyTypeEnum } from "models/policies/policy_type.enum";
import SearchFieldComponent from "shared/components/search/searchfield.component";
import { getGridColumns, sortByNameModel } from "./policy_overview.helper";
import { OverviewDatagrid } from "shared/components/datagrid/overview_datagrid.component";
import { NestedMenuItem } from "mui-nested-menu";
import InfoIcon from '@mui/icons-material/Info';
import i18next from "services/translation.service";


export const PolicyOverviewComponent = () => {
    const [menuAnchor, setAnchorEl] = useState(null);

    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.policy_overview);

    useEffect(() => {
        dispatch(fetchPolicies());
        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    if (state.query.fetchPolicies.status === "error") {
        return <div>Fehler beim Laden der Daten</div>;
    }

    if (state.query.fetchPolicies.status !== "success") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.createNewMandatoryCategoryPolicy.status === "pending") {
        navigate(`/plausibilitycheck/policy/mandatorycategory/create`);
    }

    if (state.command.createNewMandatoryCategoryByMasspointPolicy.status === "pending") {
        navigate(`/plausibilitycheck/policy/mandatorycategorybymasspoint/create`);
    }

    if (state.command.createNewWhippedSeamPolicy.status === "pending") {
        navigate(`/plausibilitycheck/policy/softseam/create`);
    }

    if (state.command.createNewSideSeamPolicy.status === "pending") {
        navigate(`/plausibilitycheck/policy/sideseam/create`);
    }

    if (state.command.createNewScarTreatmentPolicy.status === "pending") {
        navigate(`/plausibilitycheck/policy/scartreatment/create`);
    }

    if (state.command.createNewAdditionRankPolicy.status === "pending") {
        navigate(`/plausibilitycheck/policy/additionrank/create`);
    }

    if (state.command.createNewDefaultAdditionByArticleTypePolicy.status === "pending") {
        navigate(`/plausibilitycheck/policy/defaultadditionbyarticletype/create`);
    }

    if (state.command.createNewDefaultAdditionByQualityAndAttributePolicy.status === "pending") {
        navigate(`/plausibilitycheck/policy/defaultadditionbyqualityandattribute/create`);
    }

    if (state.command.createNewDefaultByAttributePolicy.status === "pending") {
        navigate(`/plausibilitycheck/policy/defaultbyattribute/create`);
    }

    if (state.command.createNewExcludeAdditionByMasspointPolicy.status === "pending") {
        navigate(`/plausibilitycheck/policy/excludeadditionbymasspoint/create`);
    }

    if (state.command.createNewUpperThresholdPolicy.status === "pending") {
        navigate(`/plausibilitycheck/policy/upperthreshold/create`);
    }

    if (state.command.createNewExcludeAdditionByMasspointValuePolicy.status === "pending") {
        navigate(`/plausibilitycheck/policy/excludeadditionbymasspointvalue/create`);
    }

    if (state.command.createNewAdjustRangesByMasspointPolicy.status === "pending") {
        navigate(`/plausibilitycheck/policy/adjustrangesbymasspoint/create`);
    }

    if (state.command.createNewAdjustMaximumRangePolicy.status === "pending") {
        navigate(`/plausibilitycheck/policy/adjustsecondrange/create`);
    }

    if (state.command.createNewFingersAndToesPolicy.status === "pending") {
        navigate(`/plausibilitycheck/policy/fingersandtoes/create`);
    }


    if (state.command.updatePolicy.status === "success") {
        switch (state.actualData.policy.policyType) {
            case PolicyTypeEnum.MandatoryCategory:
                navigate(`/plausibilitycheck/policy/mandatorycategory/${state.actualData.policy.id}/edit`);
                break;
            case PolicyTypeEnum.MandatoryCatagoryByMasspoint:
                navigate(`/plausibilitycheck/policy/mandatorycategorybymasspoint/${state.actualData.policy.id}/edit`);
                break;
            case PolicyTypeEnum.SoftSeam:
                navigate(`/plausibilitycheck/policy/softseam/${state.actualData.policy.id}/edit`);
                break;
            case PolicyTypeEnum.SideSeam:
                navigate(`/plausibilitycheck/policy/sideseam/${state.actualData.policy.id}/edit`);
                break;    
            case PolicyTypeEnum.ScarTreatment:
                navigate(`/plausibilitycheck/policy/scartreatment/${state.actualData.policy.id}/edit`);
                break;                           
            case PolicyTypeEnum.AdditionRank:
                navigate(`/plausibilitycheck/policy/additionrank/${state.actualData.policy.id}/edit`);
                break;
            case PolicyTypeEnum.DefaultAdditionByArticleType:
                navigate(`/plausibilitycheck/policy/defaultadditionbyarticletype/${state.actualData.policy.id}/edit`);
                break;
            case PolicyTypeEnum.DefaultByAttribute:
                navigate(`/plausibilitycheck/policy/defaultbyattribute/${state.actualData.policy.id}/edit`);
                break;
            case PolicyTypeEnum.DefaultAdditionByQualityAndAttribute:
                navigate(`/plausibilitycheck/policy/defaultadditionbyqualityandattribute/${state.actualData.policy.id}/edit`);
                break;
            case PolicyTypeEnum.ExcludeAdditionByMasspoint:
                navigate(`/plausibilitycheck/policy/excludeadditionbymasspoint/${state.actualData.policy.id}/edit`);
                break;
            case PolicyTypeEnum.UpperThreshold:
                navigate(`/plausibilitycheck/policy/upperthreshold/${state.actualData.policy.id}/edit`);
                break;
            case PolicyTypeEnum.ExcludeAdditionByMasspointValue:
                navigate(`/plausibilitycheck/policy/excludeadditionbymasspointvalue/${state.actualData.policy.id}/edit`);
                break;
            case PolicyTypeEnum.AdjustRangesByMasspoint:
                navigate(`/plausibilitycheck/policy/adjustrangesbymasspoint/${state.actualData.policy.id}/edit`);
                break;
            case PolicyTypeEnum.AdjustMaximumRange:
                navigate(`/plausibilitycheck/policy/adjustsecondrange/${state.actualData.policy.id}/edit`);
                break;          
            case PolicyTypeEnum.FingersAndToes:
                navigate(`/plausibilitycheck/policy/fingersandtoes/${state.actualData.policy.id}/edit`);
                break;
        }
        dispatch(updateCompleted());
    }

    if (state.command.publishPolicy.status === "success") {
        enqueueSnackbar("Regelwerk erfolgreich veröffentlicht", { variant: "success" });
        dispatch(publishCompleted())
    }

    if (state.command.unpublishPolicy.status === "success") {
        enqueueSnackbar("Veröffentlichung erfolgreich aufgehoben", { variant: "success" });
        dispatch(unpublishCompleted())
    }

    if (state.command.confirmDeletePolicy.status === "error") {
        enqueueSnackbar(state.command.confirmDeletePolicy.message, { variant: "error" });
        dispatch(deleteCompleted())
    }

    if (state.command.confirmDeletePolicy.status === "success") {
        enqueueSnackbar("Regelwerk erfolgreich gelöscht", { variant: "success" });
        dispatch(deleteCompleted())
    }

    if (state.command.publishPolicy.status === "error") {
        enqueueSnackbar(state.command.publishPolicy.message, { variant: "error" });
        dispatch(publishCompleted());
    }

    if (state.command.unpublishPolicy.status === "error") {
        enqueueSnackbar(state.command.unpublishPolicy.message, { variant: "error" });
        dispatch(unpublishCompleted());
    }

    const isLoading = state.command.unpublishPolicy.status === "pending"
        || state.command.publishPolicy.status === "pending"
        || state.command.confirmDeletePolicy.status === "pending";

    const policiesAvailable = state.loadedData.policies.length > 0;

    const onMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onMenuClose = (event) => {
        setAnchorEl(null);
    };

    const onAddMandatoryAdditionCategory = () => {
        dispatch(createNewMandatoryCategoryPolicy());
    }

    const onAddMandatoryAdditionCategoryByMasspoint = () => {
        dispatch(createNewMandatoryCategoryByMasspointPolicy());
    }

    const onAddWhippedSeam = () => {
        dispatch(createNewWhippedSeamPolicy());
    }

    const onAddSideSeam = () => {
        dispatch(createNewSideSeamPolicy());
    }

    const onAddScarTreatment = () => {
        dispatch(createNewScarTreatmentPolicy());
    }

    const onAddAdditionRank = () => {
        dispatch(createNewAdditionRankPolicy());
    }

    const onAddFingersAndToes = () => {
        dispatch(createNewFingersAndToesPolicy());
    }

    const onAddDefaultAddition = () => {
        dispatch(createNewDefaultAdditionByArticleTypePolicy());
    }

    const onAddDefaultByAttributePolicy = () => {
        dispatch(createNewDefaultByAttributePolicy());
    }

    const onAddDefaultAdditionByQualityAndAttributePolicy = () => {
        dispatch(createNewDefaultAdditionByQualityAndAttributePolicy());
    }

    const onAddExcludeAddition = () => {
        dispatch(createNewExcludeAdditionByMasspointPolicy());
    }

    const onAddExcludeAdditionByMasspointValue = () => {
        dispatch(createNewExcludeAdditionByMasspointValuePolicy());
    }

    const onAddUpperThreshold = () => {
        dispatch(createNewUpperThresholdPolicy());
    }

    const onAddAdjustRangesByMasspoint = () => {
        dispatch(createNewAdjustRangesByMasspointPolicy());
    }

    const onAddAdjustMaximumRange = () => {
        dispatch(createNewAdjustMaximumRangePolicy());
    }

    const renderToolTip = (text: string) =>
        <Tooltip title={text}>
            <IconButton sx={{ padding: 0 }}>
                <InfoIcon />
            </IconButton>
        </Tooltip>

    return (
        <div>
            {
                state.actualData.showDeletePolicyDialog &&
                <PolicyDeleteDialogComponent />
            }
            <FlexBox>

                {
                    policiesAvailable &&
                    <Left>
                        <SearchFieldComponent onSearch={(filterText) => dispatch(setSearchFilter(filterText))} placeholder="In Regelwerken suchen ..." />
                    </Left>
                }
                <Right>
                    <PrimaryButtonComponent onClick={onMenuOpen}>
                        <AddIcon /> Neues Regelwerk erstellen
                    </PrimaryButtonComponent>
                    <Popper anchorEl={menuAnchor}
                        open={Boolean(menuAnchor)}>
                        <ClickAwayListener onClickAway={(event) => onMenuClose(event)}>
                            <Paper>
                                <NestedMenuItem key={0} sx={{ paddingRight: "8px", paddingLeft: "8px" }} label="Pflichtzusatz" parentMenuOpen={Boolean(menuAnchor)}>
                                    <MenuItem key={1} onClick={onAddMandatoryAdditionCategory}>
                                        nach Kategorie
                                        {renderToolTip(i18next.t("policy.mandatory_addition_category.short_description"))}
                                    </MenuItem>
                                    <MenuItem key={2} onClick={onAddMandatoryAdditionCategoryByMasspoint}>
                                        nach Maßpunkt
                                        {renderToolTip(i18next.t("policy.mandatory_addition_by_masspoint.short_description"))}
                                    </MenuItem>
                                    <MenuItem key={3} onClick={onAddWhippedSeam}>
                                        Kettelnaht
                                        {renderToolTip(i18next.t("policy.mandatory_whipped_seam.short_description"))}
                                    </MenuItem>
                                    <MenuItem key={4} onClick={onAddSideSeam}>
                                        seitl. Naht
                                        {renderToolTip(i18next.t("policy.mandatory_side_seam.short_description"))}
                                    </MenuItem>                                    
                                    <MenuItem key={5} onClick={onAddAdditionRank}>
                                        nach Maßpunkt Differenz
                                        {renderToolTip(i18next.t("policy.mandatory_addition_by_masspoint_difference.short_description"))}
                                    </MenuItem>       
                                    <MenuItem key={6} onClick={onAddFingersAndToes}>
                                        Finger & Zehen
                                        {renderToolTip(i18next.t("policy.mandatory_fingers_and_toes.short_description"))}
                                    </MenuItem>
                                </NestedMenuItem>
                                <NestedMenuItem key={100} sx={{ paddingRight: "8px", paddingLeft: "8px" }} label="Auto-Zusätze" parentMenuOpen={Boolean(menuAnchor)}>
                                    <MenuItem key={101} onClick={onAddDefaultAddition}>
                                        nach Artikelart
                                        {renderToolTip(i18next.t("policy.autocheck_articletype.short_description"))}
                                    </MenuItem>
                                    <MenuItem key={102} onClick={onAddDefaultAdditionByQualityAndAttributePolicy}>
                                        nach Attribute DZK 
                                        {renderToolTip(i18next.t("policy.autocheck_quality_attribute.short_description"))}
                                    </MenuItem>
                                    <MenuItem key={102} onClick={onAddDefaultByAttributePolicy}>
                                        nach Attribute
                                        {renderToolTip(i18next.t("policy.autocheck_attribute.short_description"))}
                                    </MenuItem>
                                </NestedMenuItem>
                                <NestedMenuItem key={200} sx={{ paddingRight: "8px", paddingLeft: "8px" }} label="Zusatzausschluß" parentMenuOpen={Boolean(menuAnchor)}>
                                    <MenuItem key={201} onClick={onAddExcludeAddition}>
                                        anhand Maßpunkten
                                        {renderToolTip(i18next.t("policy.addition_exclude_masspoint.short_description"))}
                                    </MenuItem>
                                    <MenuItem key={202} onClick={onAddExcludeAdditionByMasspointValue}>
                                        anhand Maßpunktwert
                                        {renderToolTip(i18next.t("policy.addition_exclude_masspoint_value.short_description"))}
                                    </MenuItem>
                                </NestedMenuItem>
                                <NestedMenuItem key={300} sx={{ paddingRight: "8px", paddingLeft: "8px" }} label="Dyn Anp. Rangewert" parentMenuOpen={Boolean(menuAnchor)}>
                                    <MenuItem key={301} onClick={onAddUpperThreshold}>
                                        Trikotspitze
                                        {renderToolTip(i18next.t("policy.addition_range_treshold.short_description"))}
                                    </MenuItem>
                                    <MenuItem key={302} onClick={onAddAdjustRangesByMasspoint}>
                                        prozentual anhand Maßpunkt
                                        {renderToolTip(i18next.t("policy.adjust_ranges_by_masspoint.short_description"))}
                                    </MenuItem>
                                    <MenuItem key={303} onClick={onAddAdjustMaximumRange}>
                                        Maximalwert anhand MP-Berechnung
                                        {renderToolTip(i18next.t("policy.adjust_second_range.short_description"))}
                                    </MenuItem>
                                </NestedMenuItem>
                                <NestedMenuItem key={400} sx={{ paddingRight: "8px", paddingLeft: "8px" }} label="Maßmaske" parentMenuOpen={Boolean(menuAnchor)}>
                                    <MenuItem key={401} onClick={onAddScarTreatment}>
                                        Narbenversorgung
                                        {renderToolTip(i18next.t("policy.scar_treatment.short_description"))}
                                    </MenuItem>
                                </NestedMenuItem>
                            </Paper>
                        </ClickAwayListener>
                    </Popper>
                </Right>
            </FlexBox>
            <style.PolicyOverviewArea>
                {
                    !policiesAvailable &&
                    <div>Keine Regelwerke verfügbar</div>
                }
                {
                    policiesAvailable &&
                    <OverviewDatagrid
                        isLoading={isLoading}
                        columns={getGridColumns(
                            (policy) => dispatch(updatePolicy(policy)),
                            (policy) => dispatch(togglePublish(policy)),
                            (policy) => dispatch(deletePolicy(policy)),
                        )}
                        rows={state.actualData.policies}
                        loadedRowsCount={state.loadedData.policies.length}
                        sortModel={sortByNameModel}
                        onFilterModelChange={(newFilterModel) => dispatch(setGridFilters(newFilterModel))}
                        onDoubleClickRow={(policy) => dispatch(updatePolicy(policy.row))}
                    />
                }
            </style.PolicyOverviewArea>
        </div>
    );
}