import { doesExist, isNullOrWhitespace } from "services/validation.service";
import { BodyInformationEditState } from "./bodyinformation_edit.model";

export const updateCanExecuteEdit = (state: BodyInformationEditState) => {
    state.command.updateBodyInformation.canExecute = canExecuteUpdateBodyInformation(state);
}

const canExecuteUpdateBodyInformation = (state: BodyInformationEditState): boolean => {
    return !isNullOrWhitespace(state.actualData.editor.translationKey) 
        && doesExist(state.actualData.editor.translationKey)
        && state.actualData.editor.entriesKeys.every(x => !isNullOrWhitespace(x.translationKey) && !isNullOrWhitespace(x.shortKey) && x.shortKey.length === 3)
        && state.actualData.editor.entriesKeys.length > 0;
}