import { DialogComponent } from '../../../../shared/components/dialogs/dialog.component';
import { cancelDeleteProductionInstruction } from '../redux/production_instruction_overview.slice';
import { AppDispatch } from '../../../../app/redux_store';
import { useAppDispatch, useAppSelector } from '../../../../app/hook';
import { confirmDeleteProductionInstruction } from '../redux/production_instruction_overview.thunks';

export const ProductionInstructionDeleteDialogComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.production_instruction_overview);
    const instruction = state.actualData.activeProductionInstruction;
    const instructionText = `${instruction.symbol}`;

    return (<>
        <DialogComponent
            title={"Anweisung löschen?"}
            isOpen={true}
            options={[{
                title: "Abbrechen", handleOption: () => {
                    dispatch(cancelDeleteProductionInstruction())
                }
            }, {
                title: "Löschen", handleOption: () => {
                    dispatch(confirmDeleteProductionInstruction())
                }
            }]}
            onClose={() => { }}>
            <div>
                Möchten Sie die Anweisung {instructionText} löschen?
            </div>
        </DialogComponent>
    </>);
}