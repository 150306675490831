import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IMasspointOverview from 'models/masspoints/masspoint_overview';
import { MasspointOverviewState } from './masspoint_overview.model';
import { filterMasspoints } from './masspoint_overview.reducer';
import { confirmDeleteMasspoint, fetchMasspointOverviews } from './masspoint_overview.thunks';
import { GridFilterModel } from '@mui/x-data-grid-pro';

const initialState: MasspointOverviewState = {
    loadedData: {
        masspoints: [],
    },
    actualData: {
        masspoints: [],
        selectedMasspointOverview: null,
        showDeleteMasspointDialog: false,
        searchFilter: "",
        gridFilters: {items: []},
    },
    command: {
        confirmDeleteMasspoint: { status: "idle", canExecute: false },
    },
    query: {
        fetchMasspointOverviews: { status: "idle", canExecute: true },
    },
}

export const getMasspointOverviewSlice = createSlice({
    name: 'masspoints/masspoint_overview',
    initialState,
    reducers: {
        resetState: (state) => {
            state.command = initialState.command;
            state.loadedData = initialState.loadedData;
            state.actualData = initialState.actualData;
            state.query = initialState.query;
        },
        deleteMasspoint: (state, action: PayloadAction<IMasspointOverview>) => {
            state.actualData.showDeleteMasspointDialog = true;
            state.actualData.selectedMasspointOverview = action.payload;
        },
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.actualData.searchFilter = action.payload;
            filterMasspoints(state);
        },
        setGridFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.gridFilters = action.payload;
            filterMasspoints(state);
        },
        confirmDeleteMasspointCompleted: (state) => {
            state.command.confirmDeleteMasspoint.status = "idle";
        },
        cancelDeleteMasspoint: (state) => {
            state.actualData.showDeleteMasspointDialog = false;
        },
    }, extraReducers: (builder) => {

        // fetch MasspointOverviews
        builder.addCase(fetchMasspointOverviews.pending, (state, action) => {
            state.query.fetchMasspointOverviews.status = "pending"
            state.query.fetchMasspointOverviews.canExecute = false;
        }).addCase(fetchMasspointOverviews.rejected, (state, action) => {
            state.query.fetchMasspointOverviews.status = "error"
            state.query.fetchMasspointOverviews.message = action.error.message;
            state.query.fetchMasspointOverviews.canExecute = true;
        }).addCase(fetchMasspointOverviews.fulfilled, (state, action) => {
            state.query.fetchMasspointOverviews.status = "success"
            state.query.fetchMasspointOverviews.message = undefined;
            state.query.fetchMasspointOverviews.canExecute = true;
            state.loadedData.masspoints = action.payload.getData();
            filterMasspoints(state);

            // delete Masspoint
        }).addCase(confirmDeleteMasspoint.pending, (state, action) => {
            state.command.confirmDeleteMasspoint.status = 'pending'
        }).addCase(confirmDeleteMasspoint.rejected, (state, action) => {
            state.command.confirmDeleteMasspoint.status = "error"
            state.command.confirmDeleteMasspoint.message = action.error.message;
            state.actualData.showDeleteMasspointDialog = false;
        }).addCase(confirmDeleteMasspoint.fulfilled, (state, action) => {
            state.command.confirmDeleteMasspoint.status = "success"
            state.command.confirmDeleteMasspoint.canExecute = true;
            state.actualData.showDeleteMasspointDialog = false;
            state.loadedData.masspoints = action.payload.getData();
            filterMasspoints(state);
        })
    }
})

export const {
    setSearchFilter,
    setGridFilters,
    deleteMasspoint,
    confirmDeleteMasspointCompleted,
    cancelDeleteMasspoint,
    resetState,
} = getMasspointOverviewSlice.actions

export default getMasspointOverviewSlice.reducer