export enum ProcessTypeEnum {
    Undefined = 0,
    Order = 1,
    Offer = 2
}

export const ProcessTypeEnumValues = [
    { value: ProcessTypeEnum.Undefined, text: "Kein Auswahl" },
    { value: ProcessTypeEnum.Order, text: "Bestellung" },
    { value: ProcessTypeEnum.Offer, text: "Angebot" }
];