import { Grid, IconButton } from "@mui/material";
import { EditorMediumSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";
import { DropdownComponent, FlexBox, Left, PrimaryButtonComponent } from "shared/shared";
import { IMassPointCriteriaSelection, IMassPointValueSelection, ISizeConfigurationViewModel } from "./serial_size_editor.model";
import IAvailableSize from "models/available_basedata/available_size";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { ListButtonComponent } from "shared/components/buttons/list_button.component";
import { EditorDescriptionComponent } from "shared/components/editors/editor_description/editor_description.style";
import { DecimalInputComponent } from "shared/components/input/numberInput/decimal_input.component";

interface SizeEditorProps {
    configurationSize: ISizeConfigurationViewModel;
    takeSize: () => void;
    cancelSize: () => void;
    selectSize: (size: IAvailableSize) => void;
    addMasspoint: () => void;
    selectMasspoint: (masspoint: IMassPointCriteriaSelection) => void;
    updateValueFrom: (masspoint: IMassPointValueSelection) => void;
    updateValueTo: (masspoint: IMassPointValueSelection) => void;
    deleteMasspoint: (index: number) => void;
}

export const SizeEditorComponent: React.FC<SizeEditorProps> = (
    {
        configurationSize,
        takeSize,
        cancelSize,
        selectSize,
        addMasspoint,
        selectMasspoint,
        updateValueFrom,
        updateValueTo,
        deleteMasspoint
    }) => {

    const renderMasspoints = () => {
        return (
            <Grid container
                direction='row'
                item
                md={12}>
                {configurationSize.masspoints.map((masspoint, index) => (
                    <Grid item
                        container
                        direction="row"
                        alignItems="center"
                        alignContent='center'
                        style={{ marginTop: "5px" }}
                        md={12}
                        spacing={3}
                        key={index}>
                        <Grid item>
                            <DropdownComponent name="Maßpunkt - Kriterium"
                                data={masspoint.availableMasspoints}
                                isLoading={false}
                                isRequired={true}
                                displayedProperties={["displayName"]}
                                onSelect={(selectedMasspoint) =>
                                    selectMasspoint({
                                        masspoint: selectedMasspoint,
                                        index: index
                                    })
                                }
                                selectedValue={masspoint.masspoint}
                            />
                        </Grid>
                        <Grid item>
                            <EditorDescriptionComponent>von</EditorDescriptionComponent>
                        </Grid>
                        <Grid item>
                            <DecimalInputComponent
                                name="von"
                                fixedPositions={1}
                                placeholder="1,0"
                                minValue={0.1}
                                maxValue={999.9}
                                isRequired={true}
                                onChange={(value) => updateValueFrom({ index: index, value: value })}
                                value={masspoint.valueFrom}
                            />
                        </Grid>
                        <Grid item>
                            <EditorDescriptionComponent>bis</EditorDescriptionComponent>
                        </Grid>
                        <Grid item>
                            <DecimalInputComponent
                                name="bis"
                                fixedPositions={1}
                                placeholder="999,9"
                                minValue={0.1}
                                maxValue={999.9}
                                isRequired={true}
                                onChange={(value) => updateValueTo({ index: index, value: value })}
                                value={masspoint.valueTo}
                            />
                        </Grid>
                        <Grid item>
                            <IconButton
                                style={{ outline: "none", marginLeft: "15px" }}
                                onClick={() => deleteMasspoint(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}

                <Grid item
                    style={{ marginTop: "15px" }}
                    md={6}>
                    <ListButtonComponent
                        disabled={configurationSize.size == undefined || !configurationSize.masspoints.every(x => x.masspoint != undefined && !isNaN(x.valueFrom) && !isNaN(x.valueTo) && x.valueFrom < x.valueTo)}
                        onClick={() => addMasspoint()}>
                        <AddIcon /> Maßpunkt hinzufügen
                    </ListButtonComponent>
                </Grid>
            </Grid>
        );
    }

    const disableTake = configurationSize.masspoints.length <= 0 || configurationSize.masspoints.findIndex(ds => ds.masspoint == undefined || isNaN(ds.valueFrom) || isNaN(ds.valueTo) || ds.valueFrom >= ds.valueTo) >= 0;

    return (
        <>
            <Grid spacing={3}
                direction="column"
                container
                justifyContent="space-between">
                <Grid item>
                    <EditorMediumSectionHeaderComponent style={{ marginTop: "0px" }}>Grösse konfigurieren</EditorMediumSectionHeaderComponent>
                </Grid>
                <Grid item>
                    <FlexBox>
                        <Left>
                            <DropdownComponent name="Grösse"
                                data={configurationSize.availableSizes}
                                isRequired={true}
                                onSelect={(e) => selectSize(e)}
                                displayedProperties={["code", "name"]}
                                selectedValue={configurationSize.size}
                            />
                        </Left>
                    </FlexBox>
                </Grid>
                <Grid item>
                    {renderMasspoints()}
                </Grid>
                <Grid item
                    container
                    justifyContent="flex-end"
                    spacing={2}>
                    <Grid item>
                        <PrimaryButtonComponent
                            onClick={cancelSize}>
                            Verwerfen
                        </PrimaryButtonComponent>
                    </Grid>
                    <Grid item>
                        <PrimaryButtonComponent
                            disabled={disableTake}
                            onClick={takeSize}>
                            Übernehmen
                        </PrimaryButtonComponent>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};