import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPositionCategoryOverview } from 'models/position_category/position_category_overview';
import { PositionCategoryOverviewState } from './position_category_overview.model';
import { filterPositionCategories } from './position_category_overview.reducer';
import {
    createNewPositionCategory,
    confirmDeletePositionCategory,
    showPositionCategoryItemDetails,
    getPositionCategories,
    updatePositionCategory
} from './position_category_overview.thunks';
import { GridFilterModel } from '@mui/x-data-grid-pro';

const initialState: PositionCategoryOverviewState = {
    loadedData: {
        positionCategories: [],
    },
    actualData: {
        positionCategories: [],
        isSearchEnabled: false,
        searchFilter: "",
        gridFilters: { items: [] },
        activePositionCategory: null,
        showDeleteDialog: false,
        showDeleteErrorDialog: false,
        activeAssignedAdditions: [],
    },
    query: {
        getPositionCategories: { status: "idle", canExecute: true },
    },
    command: {
        createNewPositionCategory: { status: "idle", canExecute: false },
        showPositionCategoryItemDetails: { status: "idle", canExecute: false },
        confirmDelete: { status: "idle", canExecute: false },
        updatePositionCategory: { status: "idle", canExecute: false },
    },
}

export const positionCategoryOverviewSlice = createSlice({
    name: 'positionCategory/overview',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loadedData = initialState.loadedData;
            state.actualData = initialState.actualData;
            state.query = initialState.query;
            state.command = initialState.command;
        },
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.actualData.searchFilter = action.payload;
            filterPositionCategories(state);
        },
        setGridFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.gridFilters = action.payload;
            filterPositionCategories(state);
        },
        cancelDeletePositionCategory: (state) => {
            state.actualData.showDeleteDialog = false;
        },
        deletePositionCategory: (state, action: PayloadAction<IPositionCategoryOverview>) => {
            state.actualData.showDeleteDialog = true;
            state.actualData.activePositionCategory = action.payload;
        },
        setActivePositionCategory: (state, action: PayloadAction<IPositionCategoryOverview>) => {
            state.actualData.activePositionCategory = action.payload;
        },
        setAssignedAdditions: (state, action: PayloadAction<string[]>) => {
            state.actualData.activeAssignedAdditions = action.payload;
        },
        confirmDeleteError: (state) => {
            state.actualData.showDeleteErrorDialog = false;
            state.actualData.activeAssignedAdditions = [];
        },
        confirmDeleteCompleted: (state) => {
            state.command.confirmDelete.status = "idle";
        },
    }, extraReducers: (builder) => {
        // getPositionCategories
        builder.addCase(getPositionCategories.pending, (state, action) => {
            state.query.getPositionCategories.status = "pending"
            state.query.getPositionCategories.canExecute = false;
        }).addCase(getPositionCategories.rejected, (state, action) => {
            state.query.getPositionCategories.status = "error"
            state.query.getPositionCategories.message = action.error.message;
        }).addCase(getPositionCategories.fulfilled, (state, action) => {
            state.query.getPositionCategories.status = "success"

            const positionCategories = action.payload.getData();
            state.loadedData.positionCategories = positionCategories;
            filterPositionCategories(state);
            state.actualData.isSearchEnabled = (positionCategories != null && positionCategories.length > 0);

            // createNewPositionCategory
        }).addCase(createNewPositionCategory.pending, (state, action) => {
            state.command.createNewPositionCategory.status = "pending"
        }).addCase(createNewPositionCategory.fulfilled, (state, action) => {
            state.command.createNewPositionCategory.status = "success"

            // showPositionCategoryItemDetails
        }).addCase(showPositionCategoryItemDetails.pending, (state, action) => {
            state.command.showPositionCategoryItemDetails.status = "pending"
        }).addCase(showPositionCategoryItemDetails.fulfilled, (state, action) => {
            state.command.showPositionCategoryItemDetails.status = "success"

            // confirmDeletePositionCategory
        }).addCase(confirmDeletePositionCategory.pending, (state, action) => {
            state.command.confirmDelete.status = 'pending'
        }).addCase(confirmDeletePositionCategory.rejected, (state, action) => {
            if (action.error.code === "409") {
                state.actualData.showDeleteErrorDialog = true;
            }
            state.command.confirmDelete.status = "error"
            state.command.confirmDelete.message = action.error.message;
            state.actualData.showDeleteDialog = false;
        }).addCase(confirmDeletePositionCategory.fulfilled, (state, action) => {
            state.command.confirmDelete.status = "success"
            state.command.confirmDelete.canExecute = true;
            state.actualData.showDeleteDialog = false;

            const positionCategories = action.payload.getData();
            state.loadedData.positionCategories = positionCategories;
            state.actualData.positionCategories = positionCategories;

            // updatePositionCategory
        }).addCase(updatePositionCategory.pending, (state, action) => {
            state.command.updatePositionCategory.status = "pending"
        }).addCase(updatePositionCategory.fulfilled, (state, action) => {
            state.command.updatePositionCategory.status = "success"
            state.actualData.activePositionCategory = action.payload;
        })
    }
})

export const {
    setSearchFilter,
    setGridFilters,
    deletePositionCategory,
    resetState,
    confirmDeleteCompleted,
    confirmDeleteError,
    setActivePositionCategory,
    setAssignedAdditions,
    cancelDeletePositionCategory,
} = positionCategoryOverviewSlice.actions

export default positionCategoryOverviewSlice.reducer