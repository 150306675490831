import { PolicyEditorComponent } from "check/policy/components/policy_editor.component";
import { LoadingOverlayComponent, LoadingOverlayContainer } from "shared/components/loading_overlay/loading_overlay.component";
import { useAppDispatch, useAppSelector } from '../../../../app/hook';
import { AppDispatch } from '../../../../app/redux_store';
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { LoadingIndicatorComponent } from "shared/shared";
import { Grid } from "@mui/material";
import { EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";
import { useNavigate } from "react-router-dom";
import { PolicyExcludeAdditionEditorComponent } from "../components/policy_exclude_addition_editor.component";
import { changeName, completedSave, resetState, selectAddition, selectMasspoint1, selectMasspoint2, toggleIgnoreOnQuotation } from "./redux/policy_exclude_addition_by_masspoint_create.slice";
import { cancelSave, initData, savePolicy } from "./redux/policy_exclude_addition_by_masspoint_create.thunks";
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";
import i18next from "i18next";

export const PolicyExcludeAdditionByMasspointCreateComponent = () => {
    const state = useAppSelector((state) => state.policy_exclude_addition_by_masspoint_create);
    const dispatch: AppDispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(initData());

        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    if (state.query.initData.status === "pending") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.cancelSave.status === "success") {
        navigate("/plausibilitycheck/policy");
        dispatch(resetState());
    }

    if (state.command.savePolicy.status === "success") {
        enqueueSnackbar("Regelwerk gespeichert", { variant: "success" });
        navigate("/plausibilitycheck/policy");
        dispatch(resetState());
    }

    if (state.command.savePolicy.status === "error") {
        enqueueSnackbar(state.command.savePolicy.message, { variant: "error" });
        dispatch(completedSave());
    }

    if (state.command.savePolicy.status === "warning") {
        enqueueSnackbar(state.command.savePolicy.message, { variant: "warning" });
        dispatch(completedSave());
    }

    const isSaving = state.command.savePolicy.status === "pending";

    return (
        <>
            <LoadingOverlayContainer>
                {isSaving ? <LoadingOverlayComponent /> : <></>}
                <Grid
                    container
                    spacing={3}
                    direction="row">
                    <Grid item md={6}>
                        <EditorSectionHeaderComponent>Neuen Zusatzausschluß anhand Maßpunkten erstellen</EditorSectionHeaderComponent>
                    </Grid>
                    <Grid item md={6}>
                        <p>{i18next.t("policy.addition_exclude_masspoint.description")}</p>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={3}
                    direction="column">
                    <PolicyEditorComponent
                        name={state.data.policy.name}
                        ignoreOnQuotation={state.data.policy.ignoreOnQuotation}
                        changeName={(value) => dispatch(changeName(value))}
                        toggleIgnoreOnQuotation={(value) => dispatch(toggleIgnoreOnQuotation(value))}
                    />
                    <PolicyExcludeAdditionEditorComponent
                        selectedAddition={state.data.selectedAddition}
                        selectedMasspoint1={state.data.selectedMasspoint1}
                        selectedMasspoint2={state.data.selectedMasspoint2}
                        allAdditions={state.loadedData.additions}
                        allMasspoints1={state.data.allMasspoints1}
                        allMasspoints2={state.data.allMasspoints2}
                        selectAddition={(addition) => dispatch(selectAddition(addition))}
                        selectMasspoint1={(masspoint) => dispatch(selectMasspoint1(masspoint))}
                        selectMasspoint2={(masspoint) => dispatch(selectMasspoint2(masspoint))}
                    />
                    <EditorButtonComponent
                        canExecute={state.command.savePolicy.canExecute}
                        save={() => dispatch(savePolicy())}
                        cancelSave={() => dispatch(cancelSave())}
                    />
                </Grid>
            </LoadingOverlayContainer>
        </>
    );
};