import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BodyInformationCreateState } from "./bodyinformation_create.model";
import { BodyInformationType, BodyInformationTypeEnumValues } from "massmasks/bodyinformation/components/bodyinformation_editor.model";
import { EnumDescriptor } from "models/enum_descriptor";
import { updateCanExecuteCreate } from "./bodyinformation_create.reducer";
import { cancelSave, createBodyInformation } from "./bodyinformation_create.thunks";

const initialState: BodyInformationCreateState = {
    actualData: {
        editor: {
            translationKey: "",
            type: BodyInformationTypeEnumValues.find(x => x.value === 1),
            isMandatory: true,
            freeTextLength: 0,
            entriesKeys: []
        }
    },
    query: {

    },
    command: {
        createBodyInformation: { status: "idle", canExecute: false },
        cancel: { status: "idle", canExecute: false },
    }
}

export const bodyInformationCreateSlice = createSlice({
    name: 'massMask/bodyInformations/create',
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        resetCreateBodyInformation: (state) => {
            state.command.createBodyInformation = {
                ...initialState.command.createBodyInformation,
                canExecute: false
            };
            updateCanExecuteCreate(state)
        },
        updateCategoryName: (state, action: PayloadAction<string>) => {
            state.actualData.editor.translationKey = action.payload;
            updateCanExecuteCreate(state);
        },
        updateCategoryType: (state, action: PayloadAction<EnumDescriptor<BodyInformationType>>) => {
            state.actualData.editor.type = action.payload;
        }, 
        updateIsMandatory: (state, action: PayloadAction<boolean>) => {
            state.actualData.editor.isMandatory = action.payload;
        },
        updateFreeTextLength: (state, action: PayloadAction<number>) => {
            state.actualData.editor.freeTextLength = action.payload;
        },
        addTranslationKey: (state) => {
            state.actualData.editor.entriesKeys.push({translationKey: "", shortKey: ""});
            updateCanExecuteCreate(state);
        },
        removeTranslationKey: (state, action: PayloadAction<number>) => {
            const index = action.payload;
            state.actualData.editor.entriesKeys.splice(index, 1);
            updateCanExecuteCreate(state);
        },
        updateTranslationKey: (state, action: PayloadAction<{ index: number, translationKey: string }>) => {
            const index = action.payload.index;
            const translationKey = action.payload.translationKey;
            state.actualData.editor.entriesKeys[index].translationKey = translationKey;
            updateCanExecuteCreate(state);
        },
        updateShortKey: (state, action: PayloadAction<{ index: number, shortKey: string }>) => {
            const index = action.payload.index;
            const shortKey = action.payload.shortKey;
            state.actualData.editor.entriesKeys[index].shortKey = shortKey;
            updateCanExecuteCreate(state);
        },
    }, extraReducers: (builder) => {
        // create Body Information
        builder.addCase(createBodyInformation.pending, (state, action) => {
            state.command.createBodyInformation.status = 'pending'
            state.command.createBodyInformation.canExecute = false;
        }).addCase(createBodyInformation.rejected, (state, action) => {
            state.command.createBodyInformation.status = "error"
            state.command.createBodyInformation.canExecute = true;
            state.command.createBodyInformation.message = action.error.message;
        }).addCase(createBodyInformation.fulfilled, (state, action) => {
            state.command.createBodyInformation.status = "success"
            state.command.createBodyInformation.canExecute = true;
        
            // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancel.status = 'pending'
            state.command.cancel.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancel.status = "success"
            state.command.cancel.canExecute = false;
        })
    }
})

export const {
    resetState,
    resetCreateBodyInformation,
    updateCategoryName,
    updateCategoryType,
    updateIsMandatory,
    updateFreeTextLength,
    addTranslationKey,
    removeTranslationKey,
    updateShortKey,
    updateTranslationKey
} = bodyInformationCreateSlice.actions

export default bodyInformationCreateSlice.reducer