import { ThumbnailModel } from "../../models/thumbnail.model";
import { ThumbnailComponent } from "../../shared/components/buttons/thumbnail.component";
import massmasksImage from './images/massmasks.jpg';
import massimageImage from './images/massimage.jpg';
import * as style from "./overview.style";

export const AdminOverviewComponent = () => {
    const thumbnails: ThumbnailModel[] = [
        new ThumbnailModel("Maßbilder", massimageImage, "/admin/massimages"),
        new ThumbnailModel("eHub Import", massmasksImage, "/admin/basedataimports"),
        new ThumbnailModel("AS400-Positionen", massmasksImage, "/admin/positionmapping")
    ];

    return (
        <style.ThumbnailWrapper>
            {thumbnails.map((thumb, i) => {
                return (<ThumbnailComponent key={i} thumbnail={thumb} />)
            })}
        </style.ThumbnailWrapper>
    );
}