import { Grid, IconButton, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { useEffect, useRef } from "react";
import { LoadingOverlayComponent, LoadingOverlayContainer } from "shared/components/loading_overlay/loading_overlay.component";
import { DropdownComponent, PrimaryButtonComponent } from "shared/shared";
import { resetState, selectFile, setLegacyPositionById, setGridFilters, setSelectedPositions, updatePositionsMappingCompleted, uploadFileCompleted, updatePosition, cancelDelete, showDeleteDialog } from "./redux/position_mapping.slice";
import { cancelSave, confirmDelete, initData, updatePositionsMapping, uploadFile } from "./redux/position_mapping.thunks";
import { OverviewDatagrid } from "shared/components/datagrid/overview_datagrid.component";
import { getGridColumns, getGroupingModel, sortByNameModel } from "./positionMapping.helper";
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";
import { useNavigate } from "react-router-dom";
import { GridRowId, GridValidRowModel, useGridApiRef } from "@mui/x-data-grid-pro";
import { OverviewPosition } from "models/mapping_position/overview_position";
import { DialogComponent } from "shared/components/dialogs/dialog.component";
import DeleteIcon from '@mui/icons-material/Delete';

export const PositionMappingComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        dispatch(initData());
        return () => {
            dispatch(resetState());
        }
    }, []);

    const navigate = useNavigate();


    const state = useAppSelector((state) => state.position_mapping);

    if (state.command.cancelSave.status === "success") {
        navigate("/admin");
        dispatch(resetState());
    }

    const isLoading = state.command.uploadFile.status === 'pending'
        || state.command.updatePositionsMapping.status === 'pending'
        || state.query.fetchConfiguredPositions.status === 'pending'
        || state.query.fetchLegacyPositions.status === 'pending'
        || state.command.confirmDelete.status === 'pending';

    if (state.command.uploadFile.status === "error") {
        enqueueSnackbar("Fehler beim Hochladen der Excel Datei", { variant: "error" });
        dispatch(uploadFileCompleted());
    }

    if (state.command.uploadFile.status === "success") {
        enqueueSnackbar("AS Positionen gespeichert", { variant: "success" });
        dispatch(uploadFileCompleted());
    }

    if (state.command.updatePositionsMapping.status === "error") {
        enqueueSnackbar("Mapping konnte nicht gespeichert werden", { variant: "error" });
        dispatch(updatePositionsMappingCompleted());
    }

    if (state.command.updatePositionsMapping.status === "success") {
        enqueueSnackbar("Positionsmapping gespeichert", { variant: "success" });
        dispatch(updatePositionsMappingCompleted());
    }

    if (state.command.confirmDelete.status === "success") {
        enqueueSnackbar("Mappingdaten erfolgreich gelöscht", { variant: "success" });
        dispatch(resetState());
    }

    if (state.command.confirmDelete.status === "error") {
        enqueueSnackbar("Mappingdaten konnten nicht gelöscht werden", { variant: "error" });
    }

    const imageref = useRef(null);

    const handleSelectFile = (file: File) => {
        dispatch(selectFile(file));
    }

    const renderDisplayLegacyPosition = () => {
        const selectedLegacyPosition = state.actualData.selectedLegacyPosition;
        if (state.actualData.selectedLegacyPosition != undefined) {
            const displayString = `${selectedLegacyPosition.pkatpk} - ${selectedLegacyPosition.textpk} - ${selectedLegacyPosition.arartposnr}`
            return <Typography>
                {displayString}
            </Typography>
        }
    }

    const apiRef = useGridApiRef();

    const selectedPositionsChanged = (model: GridRowId[]) => {
        const selection = new Array<OverviewPosition>();
        model.forEach(rowId => {
            const row = apiRef.current.getRow(rowId) as OverviewPosition;
            selection.push(row);
        })

        dispatch(setSelectedPositions(selection));
    }

    const updateRow = (newRow: GridValidRowModel) => {
        let overviewPosition = newRow as OverviewPosition;
        dispatch(updatePosition(overviewPosition));
    }

    const renderUploadFile = () => <Grid alignItems="flex-end" container spacing={3}>
        <Grid item xs={12} sm={6} md={2} lg={2}>
            Positionsfile hochladen
        </Grid>
        <Grid item xs={12} sm={6} md={7} lg={5}>
            <input ref={imageref} type="file" accept=".xlsx" onChange={(e) => handleSelectFile(e.target.files[0])} />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={2}>
            <PrimaryButtonComponent
                disabled={state.actualData.upload.file == undefined}
                onClick={() => dispatch(uploadFile())}>
                Hochladen
            </PrimaryButtonComponent>
        </Grid>
    </Grid>

    const renderMappingEditor = () =>
        <Grid container spacing={4}>
            <Grid item md={12}>
                <Grid item container justifyContent="space-between">

                    <DropdownComponent
                        name="AS Position"
                        data={state.loadedData.legacyPositions}
                        isRequired={true}
                        onSelect={(legacyPosition) => dispatch(setLegacyPositionById(legacyPosition.id))}
                        displayedProperties={["textpk"]}
                        selectedValue={state.actualData.selectedLegacyPosition}
                    />
                    <IconButton
                        style={{ outline: "none" }}
                        onClick={() => dispatch(showDeleteDialog())}>
                        <DeleteIcon />
                    </IconButton>
                </Grid>
                <Grid item md={9}>
                    {
                        renderDisplayLegacyPosition()
                    }
                </Grid>
            </Grid>
            <Grid item md={12}>
                <OverviewDatagrid
                    isLoading={false}
                    apiRef={apiRef}
                    groupingModel={getGroupingModel()}
                    columns={getGridColumns()}
                    checkboxSelection={true}
                    rows={state.loadedData.configuredPositions.filter(x => x.isVisibleByFilter)}
                    loadedRowsCount={state.loadedData.configuredPositions.filter(x => x.isVisibleByFilter).length}
                    sortModel={sortByNameModel}
                    getRowId={(row) => row.sequenceId}
                    onRowSelectionModelChange={(model) => { selectedPositionsChanged(model) }}
                    onFilterModelChange={(newFilterModel) => dispatch(setGridFilters(newFilterModel))}
                    selectedRowIds={state.actualData.selectedPositions.map(x => x.sequenceId)}
                    processRowUpdate={(newRow: GridValidRowModel) => { updateRow(newRow); return newRow; }}
                    pagination={true}
                />
            </Grid>
            <EditorButtonComponent
                canExecute={state.command.updatePositionsMapping.canExecute}
                save={() => { dispatch(updatePositionsMapping()) }}
                cancelSave={() => dispatch(cancelSave())}
            />
        </Grid>

    return (
        <>
            <LoadingOverlayContainer>
                {state.actualData.showDeleteDialog &&
                    <DialogComponent
                        title={"Mapping löschen?"}
                        isOpen={true}
                        options={[
                            { title: "Abbrechen", handleOption: () => { dispatch(cancelDelete()) } },
                            { title: "Löschen", handleOption: () => { dispatch(confirmDelete()) } }
                        ]}
                        onClose={() => { }}>
                        <div>
                            Wollen Sie wirklich das Mapping löschen? Hierbei müssen alle erfassten Daten erneut gemappt werden.
                        </div>
                    </DialogComponent>
                }
                {isLoading && <LoadingOverlayComponent />}
                {
                    (!isLoading && state.loadedData.legacyPositions.length === 0) &&
                    renderUploadFile()
                }
                {
                    (!isLoading && state.loadedData.legacyPositions.length > 0) &&
                    renderMappingEditor()
                }
            </LoadingOverlayContainer>
        </>
    );
};