import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import BugReport from '@mui/icons-material/BugReport';
import SkipNext from '@mui/icons-material/SkipNext';
import styled from "@emotion/styled";;
import colors from '../../../shared/style/colors';

export const SuccessResultIcon = styled(CheckIcon)`
    color: ${colors.success};
`;
export const ErrorResultIcon = styled(ReportProblemIcon)`
    color: ${colors.error};
`;
export const WarningResultIcon = styled(ErrorIcon)`
    color: ${colors.warning};
`;
export const InvalidIcon = styled(BugReport)`
    color: red;
`;
export const SkippedIcon = styled(SkipNext)`
    color: ${colors.warning};
`;