export enum SideSelectionEnum {
  None = 0,
  Left = 2,
  Right = 3,
  LeftRight = 4,
}

export const SideSelectionEnumValues = [
  { value: SideSelectionEnum.LeftRight, text: "Alle" },
  { value: SideSelectionEnum.Left, text: "Links" },
  { value: SideSelectionEnum.Right, text: "Rechts" }
];

export const SideSelectionOnelegEnumValues = [
  { value: SideSelectionEnum.Left, text: "Links" },
  { value: SideSelectionEnum.Right, text: "Rechts" }
];