import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initData, fetchPlausibilityCheckType, fetchSimilarPlausibilityCheckTypes, updateCheckType } from './check_edit.thunks';
import { CheckEditState } from './check_edit.model';
import { toSimilarCheckTypeEditModel, updateCanExecuteEdit } from './check_edit.reducer';
import { ISimilarPlausibilityCheckType } from 'models/plausibility_check/plausability_check_type_similar';

const initialState: CheckEditState = {
    query: {
        initData: { status: "idle", canExecute: true },
        fetchPlausibilityCheckType: { status: "idle", canExecute: true },
        fetchSimilarPlausibilityCheckTypes: { status: "idle", canExecute: true },
    },
    command: {
        updateCheckType: { status: "idle", canExecute: false },
        cancelEdit: { status: "idle", canExecute: true }
    },
    loadedData: {
        check: null,
        similiarCheckTypes: []
    },
    actualData: {
        selectedCheckType: null,
    }
};

export const checkEditSlice = createSlice({
    name: 'check_edit',
    initialState,
    reducers: {
        resetState: (state) => {
            state.command = initialState.command;
            state.query = initialState.query;
            state.actualData = initialState.actualData;
        },
        updateSelectedPlausibilityCheckType: (state, action: PayloadAction<ISimilarPlausibilityCheckType>) => {
            state.actualData.selectedCheckType = action.payload;
            updateCanExecuteEdit(state);
        },
        updateCheckTypeCompleted: (state) => {
            state.command.updateCheckType = { status: "idle", canExecute: true };
        },
        cancelEdit: (state) => {
            state.command.cancelEdit.status = "success";
        },
        handleError: (state) => {
            state.command = initialState.command;
        }
    },
    extraReducers: (builder) => {
        //init Data
        builder.addCase(initData.pending, (state, action) => {
            state.query.initData.status = "pending"
            state.query.initData.canExecute = false;
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.message = action.error.message;
            state.query.initData.canExecute = true;
        }).addCase(initData.fulfilled, (state, action) => {
            state.query.initData.status = "success"
            state.query.initData.message = undefined;
            state.query.initData.canExecute = true;

            //fetchPlausibilityCheckType
        }).addCase(fetchPlausibilityCheckType.pending, (state, action) => {
            state.query.fetchPlausibilityCheckType.status = "pending"
            state.query.fetchPlausibilityCheckType.canExecute = false;
        }).addCase(fetchPlausibilityCheckType.rejected, (state, action) => {
            state.query.fetchPlausibilityCheckType.status = "error"
            state.query.fetchPlausibilityCheckType.message = action.error.message;
            state.query.fetchPlausibilityCheckType.canExecute = true;
        }).addCase(fetchPlausibilityCheckType.fulfilled, (state, action) => {
            state.query.fetchPlausibilityCheckType.status = "success"
            state.query.fetchPlausibilityCheckType.message = undefined;
            state.query.fetchPlausibilityCheckType.canExecute = true;
            const checkOverview = action.payload.getData();

            if (checkOverview.articleType == null) {
                checkOverview.articleType = { name: "Alle", id: null, erpId: "" };
            }
            if (checkOverview.quality == null) {
                checkOverview.quality = { name: "Alle", id: null, erpId: "" };
            }
            state.loadedData.check = checkOverview;
            state.actualData.selectedCheckType = toSimilarCheckTypeEditModel(checkOverview);

            //fetchSimilarPlausibilityCheckTypes
        }).addCase(fetchSimilarPlausibilityCheckTypes.pending, (state, action) => {
            state.query.fetchSimilarPlausibilityCheckTypes.status = "pending"
            state.query.fetchSimilarPlausibilityCheckTypes.canExecute = false;
        }).addCase(fetchSimilarPlausibilityCheckTypes.rejected, (state, action) => {
            state.query.fetchSimilarPlausibilityCheckTypes.status = "error"
            state.query.fetchSimilarPlausibilityCheckTypes.message = action.error.message;
            state.query.fetchSimilarPlausibilityCheckTypes.canExecute = true;
        }).addCase(fetchSimilarPlausibilityCheckTypes.fulfilled, (state, action) => {
            state.query.fetchSimilarPlausibilityCheckTypes.status = "success"
            state.query.fetchSimilarPlausibilityCheckTypes.message = undefined;
            state.query.fetchSimilarPlausibilityCheckTypes.canExecute = true;
            state.loadedData.similiarCheckTypes = action.payload.getData();

            //updateCheckType
        }).addCase(updateCheckType.pending, (state, action) => {
            state.command.updateCheckType.status = "pending"
            state.command.updateCheckType.canExecute = false;
        }).addCase(updateCheckType.rejected, (state, action) => {
            state.command.updateCheckType.status = "error"
            state.command.updateCheckType.message = action.error.message;
            state.command.updateCheckType.canExecute = true;
        }).addCase(updateCheckType.fulfilled, (state, action) => {
            state.command.updateCheckType.status = "success"
            state.command.updateCheckType.message = undefined;
            state.command.updateCheckType.canExecute = true;
        })
    }
});

export const {
    resetState,
    updateSelectedPlausibilityCheckType,
    updateCheckTypeCompleted,
    cancelEdit,
    handleError
} = checkEditSlice.actions

export default checkEditSlice.reducer