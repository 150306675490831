import { useAppDispatch, useAppSelector } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { LoadingIndicatorComponent } from "shared/shared";
import { completeLockProductConfiguration, completeUnlockProductConfiguration, resetState } from "./redux/product_configurations_overview.slice";
import { getProductConfigurations } from './redux/product_configurations_overview.thunks';
import { ProductConfigurationsAccordionComponent } from './components/product_configurations_accordion.component';
import { LoadingOverlayComponent, LoadingOverlayContainer } from "shared/components/loading_overlay/loading_overlay.component";
import { ProductConfigurationRunDetailsComponent } from './components/product_configuration_run_details.component';
import { useNavigate } from "react-router-dom";

export const ProductConfigurationsOverviewComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.simulation_productconfigurations_overview);
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        dispatch(getProductConfigurations());
        return () => {
            dispatch(resetState())
        };
    }, []);

    const navigate = useNavigate();

    const isLoadingInitialData = state.query.getProductConfigurations.status === "idle" ||
        state.query.getProductConfigurations.status === "pending";

    if (state.query.getProductConfigurations.status === "error") {
        enqueueSnackbar(state.query.getProductConfigurations.message, { variant: "error" });
        dispatch(resetState());
    }

    if (state.query.getProductConfigurationRunDetails.status === "error") {
        enqueueSnackbar(state.query.getProductConfigurationRunDetails.message, { variant: "error" });
        dispatch(resetState());
    }

    if (state.command.lockProductConfiguration.status === "error") {
        enqueueSnackbar(state.command.lockProductConfiguration.message, { variant: "error" });
        dispatch(resetState());
    }    
    
    if (state.command.unlockProductConfiguration.status === "error") {
        enqueueSnackbar(state.command.unlockProductConfiguration.message, { variant: "error" });
        dispatch(resetState());
    }   
    
    if (state.command.lockProductConfiguration.status === "success") {
        enqueueSnackbar("Der Schreibschutz wurde erfolgreich gesetzt", { variant: "success" });
        dispatch(completeLockProductConfiguration());
    }  
    
    if (state.command.unlockProductConfiguration.status === "success") {
        enqueueSnackbar("Der Schreibschutz wurde erfolgreich aufgehoben", { variant: "success" });
        dispatch(completeUnlockProductConfiguration());
    }

    useEffect( () =>
    {
        if(state.command.cloneProductConfiguration.status === "success") {
            enqueueSnackbar(state.command.cloneProductConfiguration.message, {variant: "success"});
            dispatch(getProductConfigurations());
        } else if(state.command.cloneProductConfiguration.status === "error") {
            enqueueSnackbar(state.command.cloneProductConfiguration.message, {variant: "error"});
        }
    },[state.command.cloneProductConfiguration.status])


    const buildNavigationString = () => {
        let navigationUrl = `/simulator/massmask/simulate/${state.actualData.selectedProductConfigurationRun.mainProductLineErpId}/${state.actualData.selectedProductConfigurationRun.bodyAreaErpId}/${state.actualData.selectedProductConfigurationRun.articleTypeErpId}/${state.actualData.selectedProductConfigurationRun.productConfigurationId}`
        return navigationUrl;
    }

    if (state.command.loadProductConfiguration.status === "pending") {
        navigate(buildNavigationString());
    }

    if (state.command.loadProductConfigurationRun.status === "pending") {
        navigate(buildNavigationString() + `/${state.actualData.selectedProductConfigurationRun.productConfigurationRunId}`);
    }

    const isLoadingDetails = state.query.getProductConfigurationRunDetails.status === "pending";

    return (
        <div>
            {isLoadingInitialData
                ? <LoadingIndicatorComponent />
                : (state.actualData.productConfigurations.length === 0)
                    ? <div>Keine Konfigurationen verfügbar</div>
                    : <>
                        {state.actualData.isShowDetails
                            ? <ProductConfigurationRunDetailsComponent
                                productConfigurationRunDetails={state.actualData.productConfigurationRunDetails} />
                            : <LoadingOverlayContainer>
                                {isLoadingDetails
                                    ? <LoadingOverlayComponent />
                                    : <></>
                                }
                                <ProductConfigurationsAccordionComponent
                                    productConfigurations={state.actualData.productConfigurationPages[state.actualData.currentPageIndex]?.productConfigurations} />
                            </LoadingOverlayContainer>
                        }

                    </>
            }

        </div>
    );
}