import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { confirmDeleteSerialSize, fetchSerialSizesOverviews } from './serial_sizes_overview.thunks';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import { SerialSizeOverviewState } from './serial_sizes_overview.model';
import ISerialSizeOverview from 'models/serial_sizes/serial_sizes_overview';
import { filterSerialSizes } from './serial_sizes_overview.reducer';

const initialState: SerialSizeOverviewState = {
    loadedData: {
        serialSizes: [],
    },
    actualData: {
        serialSizes: [],
        selectedSerialSizeOverview: null,
        showDeleteSerialSizeDialog: false,
        searchFilter: "",
        gridFilters: {items: []},
    },
    command: {
        confirmDeleteSerialSize: { status: "idle", canExecute: false },
    },
    query: {
        fetchSerialSizesOverviews: { status: "idle", canExecute: true },
    },
}

export const getSerialSizesOverviewSlice = createSlice({
    name: 'serial_sizes/serial_sizes_overview',
    initialState,
    reducers: {
        resetState: (state) => {
            state.command = initialState.command;
            state.loadedData = initialState.loadedData;
            state.actualData = initialState.actualData;
            state.query = initialState.query;
        },
        deleteSerialSize: (state, action: PayloadAction<ISerialSizeOverview>) => {
            state.actualData.showDeleteSerialSizeDialog = true;
            state.actualData.selectedSerialSizeOverview = action.payload;
        },
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.actualData.searchFilter = action.payload;
            filterSerialSizes(state);
        },
        setGridFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.gridFilters = action.payload;
            filterSerialSizes(state);
        },
        confirmDeleteSerialSizeCompleted: (state) => {
            state.command.confirmDeleteSerialSize.status = "idle";
        },
        cancelDeleteSerialSize: (state) => {
            state.actualData.showDeleteSerialSizeDialog = false;
        },
    }, extraReducers: (builder) => {

        // fetch SerialSizesOverviews
        builder.addCase(fetchSerialSizesOverviews.pending, (state, action) => {
            state.query.fetchSerialSizesOverviews.status = "pending"
            state.query.fetchSerialSizesOverviews.canExecute = false;
        }).addCase(fetchSerialSizesOverviews.rejected, (state, action) => {
            state.query.fetchSerialSizesOverviews.status = "error"
            state.query.fetchSerialSizesOverviews.message = action.error.message;
            state.query.fetchSerialSizesOverviews.canExecute = true;
        }).addCase(fetchSerialSizesOverviews.fulfilled, (state, action) => {
            state.query.fetchSerialSizesOverviews.status = "success"
            state.query.fetchSerialSizesOverviews.message = undefined;
            state.query.fetchSerialSizesOverviews.canExecute = true;
            state.loadedData.serialSizes = action.payload.getData();
            filterSerialSizes(state);

            // delete SerialSize
        }).addCase(confirmDeleteSerialSize.pending, (state, action) => {
            state.command.confirmDeleteSerialSize.status = 'pending'
        }).addCase(confirmDeleteSerialSize.rejected, (state, action) => {
            state.command.confirmDeleteSerialSize.status = "error"
            state.command.confirmDeleteSerialSize.message = action.error.message;
            state.actualData.showDeleteSerialSizeDialog = false;
        }).addCase(confirmDeleteSerialSize.fulfilled, (state, action) => {
            state.command.confirmDeleteSerialSize.status = "success"
            state.command.confirmDeleteSerialSize.canExecute = true;
            state.actualData.showDeleteSerialSizeDialog = false;
            state.loadedData.serialSizes = action.payload.getData();
            filterSerialSizes(state);
        })
    }
})

export const {
    setSearchFilter,
    setGridFilters,
    deleteSerialSize,
    confirmDeleteSerialSizeCompleted,
    cancelDeleteSerialSize,
    resetState,
} = getSerialSizesOverviewSlice.actions

export default getSerialSizesOverviewSlice.reducer