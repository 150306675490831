import { createAsyncThunk } from '@reduxjs/toolkit';
import MassMaskHistoryServiceAPI from '../../../services/massmask_history.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';

export const fetchMassmaskHistoryDetails = createAsyncThunk(
    'massmask_history/massmask_history_details/fetchMassmaskHistoryDetails',
    async (id: number) => {
        const response = await MassMaskHistoryServiceAPI.getMassmasksHistoryDetails(id);
        handleResponseErrors(response, "Maßmasken Historie");
        return response;
    }
)