import { GridFilterItem, GridColDef } from "@mui/x-data-grid-pro";
import IChecktypeHistoryOverview from "models/checktype_history/checktype_history_overview";
import { HistoryTypeEnum, HistoryValues } from "models/history_type.enum";
import { FormulaTemplateEnum, FormulaTemplateEnumValues } from "models/plausibility_check/enums/formula_template.enum";
import { CustomDateCell } from "shared/components/datagrid/custom_date_cell.component";
import { CustomFilterOperators } from "shared/components/datagrid/custom_filter_operators";

export const buildTypeDisplayText = ( type: HistoryTypeEnum ) => {
    return HistoryValues.find(x => x.value === type)?.text;
}

export const buildFormulaTemplateDisplayText = ( formulaTemplate: FormulaTemplateEnum ) => {
    return FormulaTemplateEnumValues.find(x => x.value === formulaTemplate )?.text
}

export const filterCheckTypeHistoryOverviewValue = (filterItem: GridFilterItem, filteredItems: IChecktypeHistoryOverview[]) => {
    if(filterItem.value)
    {
        switch(filterItem.field)
        {
            case 'type':
                filteredItems = filteredItems.filter(x => buildTypeDisplayText(x.type).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;           
            case 'userName':
                filteredItems = filteredItems.filter(x => x.userName?.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;          
            case 'name':
                filteredItems = filteredItems.filter(x => x.name?.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;  
            case 'formulaTemplate':
                filteredItems = filteredItems.filter(x => buildFormulaTemplateDisplayText(x.formulaTemplate)?.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;                                                                                                 
        }
    }
    return filteredItems;
}

export const getGridColumns = (): GridColDef[] => [
    {
        field: "date",
        headerName: "Datum",
        disableColumnMenu: true,
        flex: 0.15,
        sortable: false,
        hideable: false,
        pinnable: false,
        valueGetter: (value, row) => row.mainProductLine?.name,
        renderCell: params => CustomDateCell({date: params.row.date})
    },  
    {
        field: "type",
        headerName: "Typ",
        disableColumnMenu: false,
        flex: 0.1,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
        valueGetter: (value, row) => buildTypeDisplayText(row.type) 
    },  
    {
        field: "userName",
        headerName: "Benutzer",
        disableColumnMenu: false,
        flex: 0.2,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    }, 
    {
        field: "name",
        headerName: "Name",
        disableColumnMenu: false,
        flex: 0.3,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    },   
    {
        field: "formulaTemplate",
        headerName: "Regeltemplate",
        disableColumnMenu: false,
        flex: 0.1,
        sortable: false,
        hideable: false,
        pinnable: false,
        valueGetter: (value, row) => buildFormulaTemplateDisplayText(row.formulaTemplate),
        filterOperators: CustomFilterOperators()
    }
]