import { createAsyncThunk } from '@reduxjs/toolkit';
import CheckTypeService from 'services/checktype.service';
import { RootState } from '../../../app/redux_store';
import BaseDataServiceAPI from '../../../services/base_data.service';
import PlausibilityCheckServiceAPI from '../../../services/check.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';

export const initData = createAsyncThunk(
    'plausibilitychecktype/check_create/initData',
    async (_, { dispatch }) => {
        await Promise.all([
            dispatch(fetchPlausibilityCheckType()),
            dispatch(fetchBaseData()),
        ]);
    }
)

export const fetchBaseData = createAsyncThunk(
    'plausibilitychecktype/check_create/fetchBaseData',
    async (thunkApi) => {
        const response = await BaseDataServiceAPI.getBaseData();
        handleResponseErrors(response, "Basisdaten");
        return response;
    }
)

export const fetchPlausibilityCheckType = createAsyncThunk(
    'plausibilitychecktype/check_create/fetchPlausibilityCheckType',
    async (thunkApi) => {
        const response = await CheckTypeService.getCheckTypes();
        handleResponseErrors(response, "Prüfungstyp");
        return response;
    }
)

export const createCheck = createAsyncThunk(
    'plausibilitychecktype/check_create/createCheck',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const data = state.check_create.data.checkCreate;
        const response = await PlausibilityCheckServiceAPI.saveCheck({
            plausibilityCheckTypeId: data.plausibilityCheckTypeId,
            mainProductLineId: data.mainProductLineId,
            isSideSpecific: data.isSideSpecific,
            bodyAreaId: data.bodyAreaId,
            articleTypeId: data.articleTypeId,
            qualityId: data.qualityId
        });
        handleResponseErrors(response, "Prüfung");
        return response;
    }
)