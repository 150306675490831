import React, { useEffect } from "react";
import { LoadingIndicatorComponent } from '../shared/components/loading_indicator/loading_indicator.component';
import { cancelSave, fetchAllData, saveRules } from './redux/rules_formula.thunks';
import { RuleElementComponent } from './components/rule_element.component';
import { useAppDispatch, useAppSelector } from '../app/hook';
import { AppDispatch } from '../app/redux_store';
import { Grid } from "@mui/material";
import { LoadingOverlayComponent, LoadingOverlayContainer } from '../shared/components/loading_overlay/loading_overlay.component';
import { resetState, saveRulesCompleted, saveRulesErrorCompleted } from './redux/rules_formula.slice';
import { Typography } from "@mui/material";
import { doesExist } from 'services/validation.service';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";

interface RulesFormulaComponentProps {
    checkId: number;
}

export const RulesFormulaComponent: React.FC<RulesFormulaComponentProps> = ({ checkId }) => {

    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.rules_formula);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        dispatch(fetchAllData(checkId));
        return () => { dispatch(resetState())};
    }, [dispatch, checkId]);

    if (state.query.fetchMassPointCriterias.status === "error") {
        enqueueSnackbar(state.query.fetchMassPointCriterias.message, { variant: "error" });
        return <div>Fehler beim Laden der Basisdaten</div>;
    }

    if(state.query.fetchCheck.status === "error"){
        enqueueSnackbar(state.query.fetchCheck.message, { variant: "error" });
        return <div>Fehler beim Laden der Basisdaten</div>;
    }

    if (state.query.fetchRules.status === "error") {
        enqueueSnackbar(state.query.fetchRules.message, { variant: "error" });
        return <div>Fehler beim Laden der Regeln</div>;
    }   

    if (state.query.fetchAllData.status !== "success") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.cancel.status === "success") {
        navigate("/plausibilitycheck/check");
    }

    if (state.command.saveRules.status === "error") {
        enqueueSnackbar(state.command.saveRules.message, { variant: "error" });
        dispatch(saveRulesErrorCompleted());
    }

    if (state.command.saveRules.status === "warning") {
        enqueueSnackbar(state.command.saveRules.message, { variant: "warning" });
        dispatch(saveRulesErrorCompleted());
    }

    if (state.query.fetchAllMassPointCriterias.status === "error") {
        enqueueSnackbar(state.query.fetchAllMassPointCriterias.message, { variant: "error" });
        navigate("/plausibilitycheck/check");
        dispatch(resetState());
    }

    if (state.command.saveRules.status === "success") {
        enqueueSnackbar("Prüfungsregeln gespeichert", { variant: "success" });
        dispatch(saveRulesCompleted());
        navigate("/plausibilitycheck/check?focusId=" + state.loadedData.check.id);
    }

    const isSaving = state.command.saveRules.status === "pending";
    const errorText = doesExist(state.loadedData.errorText) ? state.loadedData.errorText : "Keine";
    const warningText = doesExist(state.loadedData.warningText) ? state.loadedData.warningText : "Keine";     

    return (<>
        <LoadingOverlayContainer>
            {isSaving ? <LoadingOverlayComponent /> : <></>}
            <Grid container spacing={3} xs={12}>              
                <Grid style={{ paddingBottom: '40px' }}
                    item
                    container
                    direction="row"
                    spacing={3}>
                    <Grid item
                        md={6}
                        spacing={3}>
                            <Typography>
                                <strong>Warnung: </strong>
                                {warningText}
                            </Typography>
                    </Grid>

                    <Grid item
                        md={6}
                        spacing={3}>
                            <Typography>
                                <strong>Fehler: </strong>
                                {errorText}
                            </Typography>
                    </Grid>
                </Grid>                  
                {state.actualData.rules.map((_, index) => {
                    return (<Grid item xs={12}>
                        <RuleElementComponent key={index} ruleIndex={index} />
                    </Grid>);
                })}
                <EditorButtonComponent
                    canExecute={state.command.saveRules.canExecute}
                    save={() => dispatch(saveRules())}
                    cancelSave={() => dispatch(cancelSave())}
                />
            </Grid>
        </LoadingOverlayContainer>
    </>)
};
