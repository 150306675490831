import { Grid } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingOverlayComponent, LoadingOverlayContainer } from "shared/components/loading_overlay/loading_overlay.component";
import { cancelSave, createSerialSize, initData } from "./redux/serial_sizes_create.thunks";
import { SerialSizeEditorComponent } from "../components/serial_size_editor.component";
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";
import {
  completedSave,
  resetState,
  selectQuality,
  addArticleType,
  selectArticleType,
  takeArticleType,
  cancelArticleType,
  editArticleType,
  deleteArticleType,
  addSize,
  selectSize,
  cancelSize,
  takeSize,
  editSize,
  deleteSize,
  addMasspoint,
  deleteMasspoint,
  selectMasspoint,
  updateValueTo,
  updateValueFrom,
  addAttribute,
  takeAttribute,
  editAttribute,
  deleteAttribute,
  selectCategory,
  selectAttribute,
  cancelAttribute,
  addAttributeMasspoint,
  selectAttributeMasspoint,
  updateAttributeValueFrom,
  updateAttributeValueTo,
  deleteAttributeMasspoint
} from "./redux/serial_sizes_create.slice";
import { LoadingIndicatorComponent } from "shared/shared";

export const SerialSizesCreateComponent = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const state = useAppSelector((state) => state.serial_size_create);

  useEffect(() => {
    dispatch(initData());
    return () => {
      dispatch(resetState());
    }
  }, [dispatch]);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const isSaving = false;

  if (state.query.initData.status === "pending") {
    return <LoadingIndicatorComponent />;
  }

  if (state.command.cancelSerialSize.status === "success") {
    navigate("/masspoints/serialsize/overview");
    dispatch(resetState());
  }

  if (state.command.createSerialSize.status === "success") {
    enqueueSnackbar("Seriengröße gespeichert", { variant: "success" });
    navigate("/masspoints/serialsize/overview");
    dispatch(resetState());
  }

  if (state.command.createSerialSize.status === "error") {
    enqueueSnackbar(state.command.createSerialSize.message, { variant: "error" });
    dispatch(completedSave());
  }

  return (
    <LoadingOverlayContainer>
      {isSaving && <LoadingOverlayComponent />}
      <Grid
        container
        spacing={3}
        md={12}
        item
        direction="column">
        <Grid item>
          <SerialSizeEditorComponent
            header='Neue Seriengröße erstellen'
            qualities={state.loadedData.qualities}
            articleTypes={state.loadedData.articleTypes}
            serialSize={state.actualData.serialSize}
            configurationArticleType={state.actualData.configureArticleType}
            selectQuality={(quality) => dispatch(selectQuality(quality))}
            addArticleType={() => dispatch(addArticleType())}
            editArticleType={(articleType => dispatch(editArticleType(articleType)))}
            deleteArticleType={(articleType => dispatch(deleteArticleType(articleType)))}
            takeArticleType={() => dispatch(takeArticleType())}
            cancelArticleType={() => dispatch(cancelArticleType())}
            selectArticleType={(articleType) => dispatch(selectArticleType(articleType))}
            addSize={() => dispatch(addSize())}
            editSize={(size) => dispatch(editSize(size))}
            deleteSize={(size) => dispatch(deleteSize(size))}
            selectSize={(size) => dispatch(selectSize(size))}
            takeSize={() => dispatch(takeSize())}
            cancelSize={() => dispatch(cancelSize())}
            addMasspoint={() => dispatch(addMasspoint())}
            selectMasspoint={(masspoint) => dispatch(selectMasspoint(masspoint))}
            updateValueFrom={(masspoint) => dispatch(updateValueFrom(masspoint))}
            updateValueTo={(masspoint) => dispatch(updateValueTo(masspoint))}
            deleteMasspoint={(index) => dispatch(deleteMasspoint(index))}
            addAttribute={() => dispatch(addAttribute())}
            takeAttribute={() => dispatch(takeAttribute())}
            editAttribute={(attribute) => dispatch(editAttribute(attribute))}
            deleteAttribute={(attribute) => dispatch(deleteAttribute(attribute))}
            selectCategory={(category) => dispatch(selectCategory(category))}
            selectAttribute={(attribute) => dispatch(selectAttribute(attribute))}
            cancelAttribute={() => dispatch(cancelAttribute())}
            addAttributeMasspoint={() => dispatch(addAttributeMasspoint())}
            selectAttributeMasspoint={(masspoint) => dispatch(selectAttributeMasspoint(masspoint))}
            updateAttributeValueFrom={(masspoint) => dispatch(updateAttributeValueFrom(masspoint))}
            updateAttributeValueTo={(masspoint) => dispatch(updateAttributeValueTo(masspoint))}
            deleteAttributeMasspoint={(index) => dispatch(deleteAttributeMasspoint(index))}
          />
        </Grid>
        <Grid item>
          <EditorButtonComponent
            canExecute={state.command.createSerialSize.canExecute}
            save={() => dispatch(createSerialSize())}
            cancelSave={() => dispatch(cancelSave())}
          />
        </Grid>
      </Grid>
    </LoadingOverlayContainer>
  );
};