import { createAsyncThunk } from '@reduxjs/toolkit';
import PlausibilityCheckService from 'services/check.service';
import CheckTypeService from 'services/checktype.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';
import { RootState } from '../../../app/redux_store';

export const initData = createAsyncThunk(
    'plausibilitychecktype/check_edit/initData',
    async (checkId: number, { dispatch }) => {

        await Promise.all([
            dispatch(fetchPlausibilityCheckType(checkId)),
            dispatch(fetchSimilarPlausibilityCheckTypes(checkId)),
        ]);
    }
);

export const fetchPlausibilityCheckType = createAsyncThunk(
    'plausibilitychecktype/check_edit/fetchPlausabilityCheckType',
    async(checkId: number, _) => {
        const response = await PlausibilityCheckService.getCheck(checkId);
        handleResponseErrors(response, "Prüfungstyp");
        return response;
    }
);

export const fetchSimilarPlausibilityCheckTypes = createAsyncThunk(
    'plausibilitychecktype/check_edit/fetchSimilarPlausibilityCheckTypes',
    async(checkId: number, _) => {
        const response = await CheckTypeService.getSimilarPlausibilityCheckTypes(checkId);
        handleResponseErrors(response, "Gleiche Prüfungstypen");
        return response;
    }
);

export const updateCheckType = createAsyncThunk(
    'plausibilitychecktype/check_edit/updateCheckType',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const data = state.check_edit;
        const response = await PlausibilityCheckService.updateCheck({
            checkId: data.loadedData.check.id,
            checkTypeId: data.actualData.selectedCheckType.id,
        });
        handleResponseErrors(response, "Prüfung");
        return response;
    }
);