import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMassImageUploaded } from 'models/massimages/massimage_uploaded';
import { UpdateMassImageState } from './update_massimage.model';
import { updateCanUpload } from './update_massimage.reducer';
import { updateMassimage } from './update_massimage.thunks';
import MassImageUploadErrorData from 'models/massimages/massimage_error_data';

const initialState: UpdateMassImageState = {
    data: {
        isUpdating: false,
        isUploadAllowed: false,
        currentMassImageId: -1,
        file: null,
        name: "",
    },
    uploadError: {
        uploadErrorData: null,
        isUnprocessableEntityException: false,
    },
    query: {
        getMassImage: { status: "idle", canExecute: true },
    },
    command: {
        uploadImage: { status: "idle", canExecute: false },
    },
}

export const updateMassImageSlice = createSlice({
    name: 'update_massimage',
    initialState,
    reducers: {
        initCurrentMassImage: (state, action: PayloadAction<number>) => {
            state.data.currentMassImageId = action.payload;
        },
        resetState: (state) => {
            state.uploadError = initialState.uploadError;
            state.data = initialState.data;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        updateUploadErrorData: (state, action: PayloadAction<MassImageUploadErrorData>) => {
            state.uploadError.uploadErrorData = action.payload;
        },   
        updateIsUnprocessableEntityException: (state, action: PayloadAction<boolean>) => {
            state.uploadError.isUnprocessableEntityException = action.payload;
        },
        updateFile: (state, action: PayloadAction<File>) => {
            state.data.file = action.payload;
            updateCanUpload(state);
        },
        updateName: (state, action: PayloadAction<string>) => {
            state.data.name = action.payload;
            updateCanUpload(state);
        },
    }, extraReducers: (builder) => {
        builder.addCase(updateMassimage.pending, (state, action) => {
            state.command.uploadImage.status = "pending"
            state.command.uploadImage.canExecute = false;
        }).addCase(updateMassimage.rejected, (state, action) => {
            state.command.uploadImage.status = "error"
            state.command.uploadImage.canExecute = true;
            state.command.uploadImage.message = action.error.message;
        }).addCase(updateMassimage.fulfilled, (state, action) => {
            state.command.uploadImage.status = "success"
            state.command.uploadImage.canExecute = true;
        })
    }
})

export const {
    updateFile,
    updateName,
    initCurrentMassImage,
    resetState,
    updateUploadErrorData,
    updateIsUnprocessableEntityException
} = updateMassImageSlice.actions

export default updateMassImageSlice.reducer