import { EnumOverviewDescriptor } from "../enum_descriptor";

export enum PolicyTypeEnum {
    Undefined,
    MandatoryCategory,
    MandatoryCatagoryByMasspoint,
    SoftSeam,
    AdditionRank,
    DefaultAdditionByArticleType,
    ExcludeAdditionByMasspoint,
    UpperThreshold,
    DefaultAdditionByQualityAndAttribute,
    ExcludeAdditionByMasspointValue,
    AdjustRangesByMasspoint,
    AdjustMaximumRange,
    DefaultByAttribute,
    SideSeam,
    ScarTreatment,
    FingersAndToes
}

export const PolicyTypeValues: Array<
    EnumOverviewDescriptor<PolicyTypeEnum>
> = [
        { value: PolicyTypeEnum.Undefined, text: "Nicht definiert", overviewText: "Nicht definiert" },
        { value: PolicyTypeEnum.MandatoryCategory, text: "nach Kategorie", overviewText: "Pflichtzusatz nach Kategorie" },
        { value: PolicyTypeEnum.MandatoryCatagoryByMasspoint, text: "nach Maßpunkt", overviewText: "Pflichtzusatz nach Maßpunkt" },
        { value: PolicyTypeEnum.SoftSeam, text: "Kettelnaht", overviewText: "Kettelnaht" },
        { value: PolicyTypeEnum.AdditionRank, text: "nach Maßpunkt Differenz", overviewText: "Pflichtzusatz nach Maßpunkt Differenz" },
        { value: PolicyTypeEnum.DefaultAdditionByArticleType, text: "nach Artikelart", overviewText: "Auto. Zusatz für Artikelart" },
        { value: PolicyTypeEnum.ExcludeAdditionByMasspoint, text: "anhand Maßpunkten", overviewText: "Zusatzausschluß anhand Maßpunkten" },
        { value: PolicyTypeEnum.UpperThreshold, text: "Trikotspitze", overviewText: "Trikotspitze" },
        { value: PolicyTypeEnum.DefaultAdditionByQualityAndAttribute, text: "für DZK", overviewText: "Auto. Zusatz für DZK (durchgestrickte Zehenkappe)" },
        { value: PolicyTypeEnum.ExcludeAdditionByMasspointValue, text: "anhand Maßpunktwert", overviewText: "Zusatzausschluß anhand Maßpunktwert" },
        { value: PolicyTypeEnum.AdjustRangesByMasspoint, text: "prozentual anhand Maßpunkt", overviewText: "Dynamische Anpassung Rangewert prozentual anhand Maßpunkt" },
        { value: PolicyTypeEnum.AdjustMaximumRange, text: "Maximalwert anhand Maßpunktdifferenz", overviewText: "Dynamische Anpassung Rangewert Maximalwert anhand Maßpunktdifferenz" },
        { value: PolicyTypeEnum.DefaultByAttribute, text: "nach Attribute", overviewText: "Auto. Zusatz für Attribute" },
        { value: PolicyTypeEnum.SideSeam, text: "seitliche Naht", overviewText: "seitliche Naht" },
        { value: PolicyTypeEnum.ScarTreatment, text: "Narbenversorgung", overviewText: "Narbenversorgung" },
        { value: PolicyTypeEnum.FingersAndToes, text: "Finger & Zehen", overviewText: "Finger & Zehen" },
]