import { GridFilterItem, GridColDef, getGridDateOperators } from "@mui/x-data-grid-pro";
import IAdditionHistoryOverview from "models/additions_history/addition_history_overview";
import { HistoryTypeEnum, HistoryValues } from "models/history_type.enum";
import { CustomDateCell } from "shared/components/datagrid/custom_date_cell.component";
import { CustomFilterOperators } from "shared/components/datagrid/custom_filter_operators";
import { DatagridMenu } from "shared/components/datagrid/datagrid_menu.component";

export const buildTypeDisplayText = (type: HistoryTypeEnum) => {
    return HistoryValues.find(x => x.value === type)?.text;
}

export const filterAdditionHistoryOverviewValue = (filterItem: GridFilterItem, filteredItems: IAdditionHistoryOverview[]) => {
    if (filterItem.value) {
        switch (filterItem.field) {      
            case 'type':
                filteredItems = filteredItems.filter(x => buildTypeDisplayText(x.type).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'userName':
                filteredItems = filteredItems.filter(x => x.userName?.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'option':
                filteredItems = filteredItems.filter(x => x.option?.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'code':
                filteredItems = filteredItems.filter(x => x.code?.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
        }
    }
    return filteredItems;
}

export const getGridColumns = (
    showDetails: (history: IAdditionHistoryOverview) => void,
): GridColDef[] => [
        {
            field: "menu",
            headerName: " ",
            width: 15,
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            hideable: false,
            pinnable: false,
            renderCell: params => DatagridMenu({
                params: params, menuItemsData:
                    [
                        { label: "Details ansehen", action: showDetails }
                    ]
            })
        },
        {
            field: "date",
            headerName: "Datum",
            disableColumnMenu: true,
            flex: 0.15,
            sortable: false,
            hideable: false,
            pinnable: false,
            valueGetter: (value, row) => row.mainProductLine?.name,
            renderCell: params => CustomDateCell({ date: params.row.date })
        },
        {
            field: "type",
            headerName: "Typ",
            disableColumnMenu: false,
            flex: 0.1,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterOperators: CustomFilterOperators(),
            valueGetter: (value, row) => buildTypeDisplayText(row.type)
        },
        {
            field: "userName",
            headerName: "Benutzer",
            disableColumnMenu: false,
            flex: 0.2,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterOperators: CustomFilterOperators(),
        },
        {
            field: "option",
            headerName: "Artikelnummer",
            disableColumnMenu: false,
            flex: 0.15,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterOperators: CustomFilterOperators(),
        },
        {
            field: "code",
            headerName: "Zusatzcode",
            disableColumnMenu: false,
            flex: 0.15,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterOperators: CustomFilterOperators()
        }
    ]