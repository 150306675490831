import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseErrors } from "shared/networking/error_handling";
import BaseDataService from "services/base_data.service";
import PolicyServiceAPI from '../../../../services/policy.service';
import { RootState } from "app/redux_store";
import { PolicyTypeEnum } from "models/policies/policy_type.enum";
import { InitBaseModel } from "app/helper/initialize_editors";

export const getMappedBaseData = createAsyncThunk(
    'policy/policy_scar_treatment/getMappedBaseData',
    async (id?: string) => {
        const response = await BaseDataService.getMappedBaseDataByPolicy(PolicyTypeEnum.ScarTreatment, id)
        handleResponseErrors(response, "Basisdaten");
        return response;
    }
)
export const savePolicy = createAsyncThunk(
    'policy/policy_scar_treatment/savepolicy',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.policy_scar_treatment.data;
        const response = await PolicyServiceAPI.createScarTreatment({
            name: data.policy.name,
            ignoreOnQuotation: data.policy.ignoreOnQuotation,
            mainProductLineId: data.selectedMainProductLine.id,
            bodyAreas: data.selectedMainProductLine.selectedBodyAreas.map(bdy => { return {
                bodyAreaId: bdy.id,
                articleTypeIds: bdy.articleTypes.selectedList.allItems.map(art => {return art.id}),
                qualityIds: bdy.qualities.selectedList.allItems.map(qua => {return qua.id}),
            }})
        });
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const updatePolicy = createAsyncThunk(
    'policy/policy_scar_treatment/updatepolicy',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.policy_scar_treatment.data;
        const response = await PolicyServiceAPI.updateScarTreatment({
            policyId: data.policy.id,
            name: data.policy.name,
            ignoreOnQuotation: data.policy.ignoreOnQuotation,
            mainProductLineId: data.selectedMainProductLine.id,
            bodyAreas: data.selectedMainProductLine.selectedBodyAreas.map(bdy => { return {
                bodyAreaId: bdy.id,
                articleTypeIds: bdy.articleTypes.selectedList.allItems.map(art => {return art.id}),
                qualityIds: bdy.qualities.selectedList.allItems.map(qua => {return qua.id}),
            }})
        });
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const storePolicy = createAsyncThunk(
    'policy/policy_scar_treatment/storePolicy',
    async (_, { getState, dispatch }) => {
        const state = getState() as RootState;
        if(state.policy_scar_treatment.loadedData.isEdit) {
            await dispatch(updatePolicy());
        } else {
            await dispatch(savePolicy());
        }
    }
)


export const cancelSave = createAsyncThunk(
    'policy/policy_scar_treatment/cancelSave',
    async (_) => { }
)

export const getPolicy = createAsyncThunk(
    'policy/policy_scar_treatment/getPolicy',
    async (id: string) => {
        const response = await PolicyServiceAPI.getScarTreatment(id);
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const initData = createAsyncThunk(
    'policy/policy_scar_treatment/initData',
    async (initBase: InitBaseModel, { dispatch }) => {
        await Promise.all([
            dispatch(getMappedBaseData(initBase.id)),
        ]);
        if(initBase.id){
            await dispatch(getPolicy(initBase.id));
        }
    }
)
