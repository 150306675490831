import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from "./config.service";
import HttpServiceCommonResult from "shared/networking/http_service_common_result";
import { IMasspointGroup } from "models/masspoint_groups/masspoint_group";
import IMasspointGroupOverview from "models/masspoint_groups/masspoint_group_overview";

class MassPointGroupServiceAPI extends HttpServiceCommonResult {
    public async getMassPointGroupOverviews(): Promise<ApiResult<Array<IMasspointGroupOverview>>> {
        return this.get<Array<IMasspointGroupOverview>>(`/api/masspointgroup/overview`);
    }    
    
    public async getMassPointGroups(): Promise<ApiResult<Array<IMasspointGroup>>> {
        return this.get<Array<IMasspointGroup>>(`/api/masspointgroup`);
    }

    public async getMassPointGroup(masspointGroupId: Number): Promise<ApiResult<IMasspointGroup>> {
        return this.get<IMasspointGroup>(`/api/masspointgroup/${masspointGroupId}`);
    }
    
    public async saveMasspointGroup(masspointGroup: IMasspointGroup): Promise<ApiResult<void>> {
        return this.post<IMasspointGroup,void>("/api/masspointgroup", masspointGroup);
    }

    public async updateMassPointGroup(updatedMasspointGroup: IMasspointGroup): Promise<ApiResult<void>> {
        return this.put<IMasspointGroup,void>(`/api/masspointgroup/${updatedMasspointGroup.id}`, updatedMasspointGroup);
    }

    public async deleteMassPointGroup(masspointGroupId: Number): Promise<ApiResult<void>> {
        return this.delete(`/api/masspointgroup/${masspointGroupId}`);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const MassPointGroupService = new MassPointGroupServiceAPI(TimeoutMilliseconds);

export default MassPointGroupService;
