import { IProductionInstructionOverview } from "models/production_instructions/production_instruction_overview";
import { ProductionInstructionOverviewState } from "./production_instruction_overview_state";
import { filterProductionInstructionValue } from "../production_instruction_overview.helper";

export const filterProductionInstructions = (state: ProductionInstructionOverviewState) => {
    const searchTextLower = state.actualData.searchFilter.toLowerCase().trim();
    let filteredItems = state.loadedData.productionInstructions.filter(
        (instruction) =>
            instruction.symbol.toLowerCase().includes(searchTextLower) ||
            instruction.instructionPreviewText.toLowerCase().includes(searchTextLower)
    ).sort(sortInstructions);

    state.actualData.gridFilters.items.forEach(fil => {
        filteredItems = filterProductionInstructionValue(fil, filteredItems);
    });

    state.actualData.productionInstructions = filteredItems;
}

export const sortInstructions = (current: IProductionInstructionOverview, other: IProductionInstructionOverview) => {
    return current.symbol.localeCompare(other.symbol);
}
