import { EnumDescriptor } from "models/enum_descriptor";

export enum PositionDirectionEnum{
  HorizontalStart = 0,
  HorizontalEnd = 1,
  VerticalStart = 2,
  VerticalEnd = 3
}

 export const PositionDirectionValues: Array<
  EnumDescriptor<PositionDirectionEnum>
> = [
    { value: PositionDirectionEnum.HorizontalStart, text: "Horizontal start" },
    { value: PositionDirectionEnum.HorizontalEnd, text: "Horizontal ende" },
    { value: PositionDirectionEnum.VerticalStart, text: "Vertikal start" },
    { value: PositionDirectionEnum.VerticalEnd, text: "Vertikal ende" }, 
  ];

export const PositionDirectionValuesLookup = (value: PositionDirectionEnum) => PositionDirectionValues.find(x => x.value === value).text;

export const PositionDirectionEnumLookupForEnum = (value: string) => PositionDirectionValues.find(x => x.text === value).value;
