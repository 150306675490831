import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseErrors } from "shared/networking/error_handling";
import AdditionService from 'services/addition.service';
import MassPointService from 'services/masspoint.service';
import { PolicyTypeEnum } from "models/policies/policy_type.enum";
import { RootState } from "app/redux_store";
import PolicyServiceAPI from 'services/policy.service';

export const getAdditions = createAsyncThunk(
    'policy/create_policy_upper_threshold/getAdditions',
    async (_) => {
        const response = await AdditionService.getAvailableAdditionsByPolicyTypeOverview(PolicyTypeEnum.UpperThreshold, undefined);
        handleResponseErrors(response, "Zusätze");
        return response;
    }
)

export const getMassPoints = createAsyncThunk(
    'policy/create_policy_upper_threshold/getMassPoints',
    async (_) => {
        const response = await MassPointService.getMassPoints()
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)

export const savePolicy = createAsyncThunk(
    'policy/create_policy_upper_threshold/savepolicy',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.policy_upper_threshold_create.data;
        const response = await PolicyServiceAPI.createUpperThreshold({
            name: data.policy.name,
            ignoreOnQuotation: data.policy.ignoreOnQuotation,
            additionId: data.selectedAddition.id,
            masspoint: 
            {
                name: data.selectedMasspoint.name,
                type: data.selectedMasspoint.massPointType,
                bodyAreaId: data.selectedMasspoint.bodyAreaId
            },
            thresholdValue: data.thresholdValue
        });
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'policy/create_policy_upper_threshold/cancelSave',
    async (_) => { }
)

export const initData = createAsyncThunk(
    'policy/create_policy_upper_threshold/initData',
    async (_, { dispatch }) => {
        await Promise.all([
            dispatch(getAdditions()),
            dispatch(getMassPoints()),
        ]);
    }
)



