import { GridFilterItem, GridColDef, GridSortModel } from "@mui/x-data-grid-pro";
import IAvailableArticleType from "models/available_basedata/available_article_type";
import IAvailableQuality from "models/available_basedata/available_quality";
import ISerialSizeOverview from "models/serial_sizes/serial_sizes_overview";
import { CustomFilterOperators } from "shared/components/datagrid/custom_filter_operators";
import { CustomPopperTextCellComponent } from "shared/components/datagrid/custom_popper_text_cell.component";
import { DatagridMenu } from "shared/components/datagrid/datagrid_menu.component";

const buildQualityDisplayText = (quality: IAvailableQuality) => {
    return `${quality.erpId} - ${quality.name}`;
}

const buildArticleTypesDisplayText = (articleTypes: IAvailableArticleType[]) => {
    return articleTypes.map(articleType => articleType.erpId !== "" ? `${articleType.erpId} - ${articleType.name}` : `${articleType.name}`).join(", ");
}

export const filterSerialSizeValue = (filterItem: GridFilterItem, filteredItems: ISerialSizeOverview[]) => {
    if (filterItem.value) {
        switch (filterItem.field) {
            case 'quality':
                filteredItems = filteredItems.filter(x => buildQualityDisplayText(x.quality).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'articleTypes':
                filteredItems = filteredItems.filter(x => buildArticleTypesDisplayText(x.articleTypes).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
        }
    }
    return filteredItems;
}

export const getGridColumns = (
    editSerialSize: (serialSize: ISerialSizeOverview) => void,
    deleteSerialSize: (serialSize: ISerialSizeOverview) => void,
): GridColDef[] => [
        {
            field: "menu",
            headerName: " ",
            width: 15,
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            hideable: false,
            pinnable: false,
            renderCell: params => DatagridMenu({
                params: params, menuItemsData:
                    [
                        { label: "Seriengröße editieren", action: editSerialSize },
                        { label: "Seriengröße löschen", action: deleteSerialSize }
                    ]
            })
        },
        {
            field: "quality",
            headerName: "Qualität",
            disableColumnMenu: false,
            flex: 0.3,
            sortable: false,
            hideable: false,
            pinnable: false,
            valueGetter: (value, row) => buildQualityDisplayText(row.quality),
            filterOperators: CustomFilterOperators(),
        },
        {
            field: "articleTypes",
            headerName: "Artikeltypen",
            disableColumnMenu: false,
            flex: 0.7,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterOperators: CustomFilterOperators(),
            renderCell: (params) => CustomPopperTextCellComponent({ content: (createContent(params.row.articleTypes)), info: (createInfo(params.row.articleTypes)) })
        }
    ]

export const sortByQualityModel: GridSortModel = [
    {
        field: "quality",
        sort: "asc",
    },
];

const createContent = (values: IAvailableArticleType[]): string => {
    let info: string = '';
    const lengthAllowed = 100;
    if (values && values.length > 0) {
        info = '';
        values.forEach(x => {
            if (info === '') {
                info += x.id != null ? `${x.erpId} - ${x.name}` : `${x.name}`
            } else {
                info += x.id != null ? `;${x.erpId} - ${x.name}` : `${x.name}`;
            }
        });
    }
    return info.length <= lengthAllowed ? info : `${info.substring(0, lengthAllowed - 3)}...`;
}

const createInfo = (values: IAvailableArticleType[]): string => {
    let info: string = '';
    if (values && values.length > 0) {
        info = '';
        values.forEach(x => {
            if (info === '') {
                info += x.id != null ? `${x.erpId} - ${x.name}` : `${x.name}`
            } else {
                info += x.id != null ? `; ${x.erpId} - ${x.name}` : `; ${x.name}`;
            }
        });
    }
    return info;
}