import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProductionInstructionOverview } from 'models/production_instructions/production_instruction_overview';
import { filterProductionInstructions } from './production_instruction_overview.reducer';
import { confirmDeleteProductionInstruction, createNewProductionInstruction, getProductionInstructions, publishProductionInstruction, unpublishProductionInstruction } from './production_instruction_overview.thunks';
import { ProductionInstructionOverviewState } from './production_instruction_overview_state';
import { GridFilterModel } from '@mui/x-data-grid-pro';

const initialState: ProductionInstructionOverviewState = {
    loadedData: {
        productionInstructions: [],
    },
    actualData: {
        productionInstructions: [],
        isSearchEnabled: false,
        searchFilter: "",
        gridFilters: { items: [] },
        activeProductionInstruction: null,
        showDeleteDialog: false,
    },
    query: {
        getProductionInstructions: { status: "idle", canExecute: true },
    },
    command: {
        publishProductionInstruction: { status: "idle", canExecute: false },
        unpublishProductionInstruction: { status: "idle", canExecute: false },
        createNewProductionInstruction: { status: "idle", canExecute: false },
        confirmDeleteProductionInstruction: { status: "idle", canExecute: false },
    },
}

export const productionInstructionOverviewSlice = createSlice({
    name: 'production-instruction/overview',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loadedData = initialState.loadedData;
            state.actualData = initialState.actualData;
            state.query = initialState.query;
            state.command = initialState.command;
        },
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.actualData.searchFilter = action.payload;
            filterProductionInstructions(state);
        },
        setGridFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.gridFilters = action.payload;
            filterProductionInstructions(state);
        },
        publishProductionInstructionCompleted: (state) => {
            state.command.publishProductionInstruction.status = "idle";
        },
        unpublishProductionInstructionCompleted: (state) => {
            state.command.unpublishProductionInstruction.status = "idle";
        },
        resetDeleteProductionInstruction: (state) => {
            state.command.confirmDeleteProductionInstruction = initialState.command.confirmDeleteProductionInstruction;
        },
        deleteProductionInstruction: (state, action: PayloadAction<IProductionInstructionOverview>) => {
            state.actualData.activeProductionInstruction = action.payload;
            state.actualData.showDeleteDialog = true;
        },
        cancelDeleteProductionInstruction: (state) => {
            state.actualData.activeProductionInstruction = null;
            state.actualData.showDeleteDialog = false;
        },
    }, extraReducers: (builder) => {
        // get all overviews Product Instruction
        builder.addCase(getProductionInstructions.pending, (state, action) => {
            state.query.getProductionInstructions.status = "pending"
            state.query.getProductionInstructions.canExecute = false;
        }).addCase(getProductionInstructions.rejected, (state, action) => {
            state.query.getProductionInstructions.status = "error"
            state.query.getProductionInstructions.message = action.error.message;
        }).addCase(getProductionInstructions.fulfilled, (state, action) => {
            state.query.getProductionInstructions.status = "success"

            const instructions = action.payload.getData();
            state.loadedData.productionInstructions = instructions;
            filterProductionInstructions(state);
            state.actualData.isSearchEnabled = (instructions != null && instructions.length > 0);

            // publish Product Instruction
        }).addCase(publishProductionInstruction.pending, (state, action) => {
            state.command.publishProductionInstruction.status = 'pending'
        }).addCase(publishProductionInstruction.rejected, (state, action) => {
            state.command.publishProductionInstruction.status = "error"
            state.command.publishProductionInstruction.message = action.error.message;
        }).addCase(publishProductionInstruction.fulfilled, (state, action) => {
            state.command.publishProductionInstruction.status = "success"
            state.command.publishProductionInstruction.canExecute = true;

            const instructions = action.payload.getData();
            state.loadedData.productionInstructions = instructions;
            filterProductionInstructions(state);
            state.actualData.isSearchEnabled = (instructions != null && instructions.length > 0);

            // unpublish Product Instruction
        }).addCase(unpublishProductionInstruction.pending, (state, action) => {
            state.command.unpublishProductionInstruction.status = 'pending';
        }).addCase(unpublishProductionInstruction.rejected, (state, action) => {
            state.command.unpublishProductionInstruction.status = "error";
            state.command.unpublishProductionInstruction.message = action.error.message;
        }).addCase(unpublishProductionInstruction.fulfilled, (state, action) => {
            state.command.unpublishProductionInstruction.status = "success";
            state.command.unpublishProductionInstruction.canExecute = true;

            const instructions = action.payload.getData();
            state.loadedData.productionInstructions = instructions;
            filterProductionInstructions(state);
            state.actualData.isSearchEnabled = (instructions != null && instructions.length > 0);

            // confirmDeleteAddition
        }).addCase(confirmDeleteProductionInstruction.pending, (state, action) => {
            state.command.confirmDeleteProductionInstruction.status = 'pending'
        }).addCase(confirmDeleteProductionInstruction.rejected, (state, action) => {
            state.command.confirmDeleteProductionInstruction.status = "error"
            state.command.confirmDeleteProductionInstruction.message = action.error.message;
            state.actualData.activeProductionInstruction = null;
            state.actualData.showDeleteDialog = false;
        }).addCase(confirmDeleteProductionInstruction.fulfilled, (state, action) => {
            state.command.confirmDeleteProductionInstruction.status = "success"
            state.actualData.productionInstructions = action.payload.getData();
            state.actualData.activeProductionInstruction = null;
            state.actualData.showDeleteDialog = false;

            // create new Product Instruction 
        }).addCase(createNewProductionInstruction.pending, (state, action) => {
            state.command.createNewProductionInstruction.status = "pending"
        }).addCase(createNewProductionInstruction.fulfilled, (state, action) => {
            state.command.createNewProductionInstruction.status = "success"

        })
    }
})

export const {
    publishProductionInstructionCompleted,
    unpublishProductionInstructionCompleted,
    setSearchFilter,
    setGridFilters,
    cancelDeleteProductionInstruction,
    deleteProductionInstruction,
    resetState,
    resetDeleteProductionInstruction,
} = productionInstructionOverviewSlice.actions

export default productionInstructionOverviewSlice.reducer