import { useAppDispatch, useAppSelector } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { isNotEmpty } from "services/validation.service";
import { DialogComponent } from "shared/components/dialogs/dialog.component";
import { Grid } from "@mui/material";
import { cancelDeleteValidityScope, cancelDismissChanges, confirmDeleteValidityScope, confirmDismissChanges, confirmError } from "../production_instruction/redux/production_instruction.slice";

export const ProductionInstructionDialogsComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.production_instruction);

    const showDeletingValidityAreaDialog = state.actualData.productionInstructionEditor.showDeleteValidityScopeDialog;
    const showErrorMessageDialog = state.actualData.productionInstructionEditor.showErrorMessageDialog;
    const errorMessageTitle = state.actualData.productionInstructionEditor.errorMessageTitle;
    const errorMessageDescription = state.actualData.productionInstructionEditor.errorMessageDescription;
    const showDismissValidityAreaDialog = state.actualData.productionInstructionEditor.showDismissValidityAreaDialog;

    const validityScopeDeletingIndex = state.actualData.productionInstructionEditor.validityScopeDeletingIndex;
    const validityScopes = state.actualData.productionInstructionEditor.validityScopes;

    const mainProductline = validityScopeDeletingIndex >= 0 ? validityScopes[validityScopeDeletingIndex]?.mainProductLine : null;
    const bodyArea = validityScopeDeletingIndex >= 0 ? validityScopes[validityScopeDeletingIndex]?.bodyArea : null;
    const deleteMessage = mainProductline != null && isNotEmpty(mainProductline.displayName) && bodyArea != null && isNotEmpty(bodyArea.displayName) ? "Möchten Sie den Gültigkeitsbereich " + mainProductline.displayName + ", " + bodyArea.displayName + " löschen?" :
        "Möchten Sie den Gültigkeitsbereich löschen?";

    return (<>
            {
                showDeletingValidityAreaDialog &&
                <DialogComponent
                    title={"Gültigkeitsbereich löschen?"}
                    isOpen={true}
                    options={[
                        {
                            title: "Abbrechen", handleOption: () => {
                                dispatch(cancelDeleteValidityScope())
                            }
                        },
                        {
                            title: "Löschen", handleOption: () => {
                                dispatch(confirmDeleteValidityScope())
                            }
                        }
                    ]}
                    onClose={() => {
                        dispatch(cancelDeleteValidityScope())}}>
                    <Grid container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}>
                        <Grid item>{deleteMessage}</Grid>
                    </Grid>
                </DialogComponent>
            }

            {
                showErrorMessageDialog &&
                <DialogComponent
                    title={errorMessageTitle}
                    isOpen={true}
                    options={[
                        { title: "Okay", handleOption: () => dispatch(confirmError()) }
                    ]}
                    onClose={() => dispatch(confirmError())}>
                    <div>{errorMessageDescription}</div>
                </DialogComponent>
            }

            {
                showDismissValidityAreaDialog &&
                <DialogComponent
                    title={"Änderungen verwerfen?"}
                    isOpen={true}
                    options={[
                        {
                            title: "Ja", handleOption: () => dispatch(confirmDismissChanges())
                        },
                        {
                            title: "Nein", handleOption: () => dispatch(cancelDismissChanges())
                        }
                    ]}
                    onClose={() => dispatch(cancelDismissChanges())}>
                </DialogComponent>
            }
    </>)
};