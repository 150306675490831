import { PolicyAdditionRankUpdateState } from "./policy_addition_rank_update.model";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { 
    cancelSave, 
    getAdditions,
    getBaseData,
    getMassPoints,
    getPolicy,
    initData,
    updatePolicy, 
} from "./policy_addition_rank_update.thunks";
import { IMassPointCriteriaItem } from '../../../components/policy_editor.model';
import { 
    updateCanSave, 
    createMasspointItemList,
} from "./policy_addition_rank_update.reducer";
import IAvailableProductLine from "models/available_basedata/available_product_line";
import IAvailableBodyArea from "models/available_basedata/available_body_area";
import { IAdditionPolicyInformation } from "models/additions/addition_policy_information";
import { filterMasspoint, sortAdditions } from "check/policy/helper/policy.helper";
import { createSelectionList, filterSelectionList, getEmptySelectionList, moveFromSelectedToUnselected, moveFromUnselectedToSelected } from "shared/components/selectionComponent/selectionList.helper";
import IAvailableArticleType from "models/available_basedata/available_article_type";
import { getIAvailableArticleTypeDisplayName } from "shared/helpers/displayNames";

const initialState: PolicyAdditionRankUpdateState = {
    data: { 
        policy: { name: "", ignoreOnQuotation: false},
        selectedAddition: null,
        selectedBodyArea: null,
        selectedMainProductLine: null,
        selectedMasspoint1: null,
        selectedMasspoint2: null,
        allMasspoints1: [],
        allMasspoints2:[],
        articleTypes: getEmptySelectionList()
    },
    loadedData: {
        additions: [],
        masspoints: [],
        articleTypes: [],
        bodyAreas: [],
        mainProductLines:[],
        policy: null,
    },
    command:{
        updatePolicy: { status: "idle", canExecute: false },
        cancelSave: { status: "idle", canExecute: true }
    },
    query: {
        fetchAdditions: { status: "idle", canExecute: true },
        fetchMasspoints: { status: "idle", canExecute: true },
        fetchBaseData: { status: "idle", canExecute: true },               
        fetchPolicy: { status: "idle", canExecute: true },
        initData: { status: "idle", canExecute: true },
    }
}

export const updatePolicyAdditionRank = createSlice({
    name: 'update_policy_addition_rank',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = initialState.data;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        changeName: (state, action: PayloadAction<string>) => {
            state.data.policy.name = action.payload;
            updateCanSave(state);
        },
        selectAddition: (state, action: PayloadAction<IAdditionPolicyInformation>) => {
            state.data.selectedAddition = action.payload;
            updateCanSave(state);
        },
        selectMainProductLine: (state, action: PayloadAction<IAvailableProductLine>) => {
            state.data.selectedMainProductLine = action.payload;
            updateCanSave(state);
        },     
        selectBodyArea: (state, action: PayloadAction<IAvailableBodyArea>) => {
            state.data.selectedBodyArea = action.payload;
            updateCanSave(state);
        },           
        selectMasspoint1: (state, action: PayloadAction<IMassPointCriteriaItem>) => {
            state.data.selectedMasspoint1 = action.payload;
            if(state.data.selectedMasspoint1)
            {
                state.data.allMasspoints2 = filterMasspoint(state.loadedData.masspoints, state.data.selectedMasspoint1); 
            }
            updateCanSave(state);
        },      
        selectMasspoint2: (state, action: PayloadAction<IMassPointCriteriaItem>) => {
            state.data.selectedMasspoint2 = action.payload;
            if(state.data.selectedMasspoint2)
            {
                state.data.allMasspoints1 =  filterMasspoint(state.loadedData.masspoints, state.data.selectedMasspoint2); 
            }
            updateCanSave(state);
        }, 
        selectArticleTypes: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.data.articleTypes, action.payload);
            updateCanSave(state);
        },      
        unselectArticleTypes: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.data.articleTypes, action.payload);
            updateCanSave(state);
        },   
        filterSelectedArticleTypes: (state, action: PayloadAction<string>) => {
            state.data.articleTypes.selectedList.searchText = action.payload;
            filterSelectionList(state.data.articleTypes.selectedList);
        },
        filterUnselectedArticleTypes: (state, action: PayloadAction<string>) => {
            state.data.articleTypes.unSelectedList.searchText = action.payload;
            filterSelectionList(state.data.articleTypes.unSelectedList);
        },    
        toggleIgnoreOnQuotation: (state, action: PayloadAction<boolean>) => {
            state.data.policy.ignoreOnQuotation = action.payload;
            updateCanSave(state);
        },                                                           
        completedSave: (state) => {
            state.command.updatePolicy = initialState.command.updatePolicy;
        }
    }, extraReducers: (builder) => {
        //fetchAdditions
        builder.addCase(getAdditions.pending, (state) => {
            state.query.fetchAdditions.status = "pending"
            state.query.fetchAdditions.canExecute = false;
        }).addCase(getAdditions.rejected, (state, action) => {
            state.query.fetchAdditions.status = "error"
            state.query.fetchAdditions.message = action.error.message;
            state.query.fetchAdditions.canExecute = true;
        }).addCase(getAdditions.fulfilled, (state, action) => {
            state.query.fetchAdditions.status = "success"
            state.query.fetchAdditions.canExecute = true;
            state.loadedData.additions = action.payload.getData()
                 .sort(sortAdditions);

        // fetchBaseData
        }).addCase(getBaseData.pending, (state) => {
            state.query.fetchBaseData.status = "pending"
            state.query.fetchBaseData.canExecute = false;
        }).addCase(getBaseData.rejected, (state, action) => {
            state.query.fetchBaseData.status = "error"
            state.query.fetchBaseData.message = action.error.message;
            state.query.fetchBaseData.canExecute = true;
        }).addCase(getBaseData.fulfilled, (state, action) => {
            state.query.fetchBaseData.status = "success"
            state.query.fetchBaseData.canExecute = true;
            state.loadedData.articleTypes = action.payload.getData().availableArticleTypes; 
            state.loadedData.bodyAreas = action.payload.getData().availableBodyAreas; 
            state.loadedData.mainProductLines = action.payload.getData().availableMainProductLines;  
            state.loadedData.articleTypes = action.payload.getData().availableArticleTypes
            state.data.articleTypes = createSelectionList(action.payload.getData().availableArticleTypes, [], getIAvailableArticleTypeDisplayName)    

            // fetchMasspoints
        }).addCase(getMassPoints.pending, (state) => {
            state.query.fetchMasspoints.status = "pending"
            state.query.fetchMasspoints.canExecute = false;
        }).addCase(getMassPoints.rejected, (state, action) => {
            state.query.fetchMasspoints.status = "error"
            state.query.fetchMasspoints.message = action.error.message;
            state.query.fetchMasspoints.canExecute = true;
        }).addCase(getMassPoints.fulfilled, (state, action) => {
            state.query.fetchMasspoints.status = "success"
            state.query.fetchMasspoints.canExecute = true;
            const masspoints = createMasspointItemList(action.payload.getData());   
            state.loadedData.masspoints = masspoints;
            state.data.allMasspoints1 = masspoints;
            state.data.allMasspoints2 = masspoints;

        // fetchPolicy
        }).addCase(getPolicy.pending, (state) => {
            state.query.fetchPolicy.status = "pending"
            state.query.fetchPolicy.canExecute = false;
        }).addCase(getPolicy.rejected, (state, action) => {
            state.query.fetchPolicy.status = "error"
            state.query.fetchPolicy.message = action.error.message;
            state.query.fetchPolicy.canExecute = true;
        }).addCase(getPolicy.fulfilled, (state, action) => {
            state.query.fetchPolicy.status = "success"
            state.query.fetchPolicy.canExecute = true;
            state.loadedData.policy = action.payload.getData();

        // updatePolicy
        }).addCase(updatePolicy.pending, (state) => {
            state.command.updatePolicy.status = 'pending'
            state.command.updatePolicy.canExecute = false;
        }).addCase(updatePolicy.rejected, (state, action) => {
            state.command.updatePolicy.status = "error"
            state.command.updatePolicy.canExecute = true;
            state.command.updatePolicy.message = action.error.message;
        }).addCase(updatePolicy.fulfilled, (state) => {
            state.command.updatePolicy.status = "success"
            state.command.updatePolicy.canExecute = false;

        // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancelSave.status = 'pending'
            state.command.cancelSave.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancelSave.status = "success"
            state.command.cancelSave.canExecute = false;

            // initData
        }).addCase(initData.pending, (state) => {
            state.query.initData.status = "pending"
            state.query.initData.canExecute = false;
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.canExecute = true;
            state.query.initData.message = action.error.message;
        }).addCase(initData.fulfilled, (state) => {
            state.query.initData.status = "success"
            state.query.initData.canExecute = true;
            state.data.policy = { 
                id: state.loadedData.policy.id,
                name: state.loadedData.policy.name,
                ignoreOnQuotation: state.loadedData.policy.ignoreOnQuotation
            };
            state.data.selectedAddition = state.loadedData.additions.find(x => x.id === state.loadedData.policy.additionId);
            state.data.selectedMainProductLine = state.loadedData.mainProductLines.find(x => x.id === state.loadedData.policy.mainProductLineId);
            state.data.selectedBodyArea = state.loadedData.bodyAreas.find(x => x.id === state.loadedData.policy.bodyAreaId);
            state.data.selectedMasspoint1 = state.loadedData.masspoints.find(x => x.bodyAreaId === state.loadedData.policy.massPoint1.bodyAreaId
                &&  x.name === state.loadedData.policy.massPoint1.massPointName
                && x.massPointType === state.loadedData.policy.massPoint1.massPointType);
            state.data.selectedMasspoint2 = state.loadedData.masspoints.find(x => x.bodyAreaId === state.loadedData.policy.massPoint2.bodyAreaId
                &&  x.name === state.loadedData.policy.massPoint2.massPointName
                && x.massPointType === state.loadedData.policy.massPoint2.massPointType);

            if(state.data.selectedMasspoint1)
            {
                state.data.allMasspoints2 = filterMasspoint(state.loadedData.masspoints, state.data.selectedMasspoint1); 
            }
            if(state.data.selectedMasspoint2)
            {
                state.data.allMasspoints1 = filterMasspoint(state.loadedData.masspoints, state.data.selectedMasspoint2); 
            }            
            state.data.articleTypes = createSelectionList(state.loadedData.articleTypes, state.loadedData.policy.articleTypes, getIAvailableArticleTypeDisplayName);

            updateCanSave(state);

        })
    }
})

export const {
    resetState,
    changeName,
    selectAddition,
    selectMainProductLine,
    selectBodyArea,
    selectMasspoint1,
    selectMasspoint2,
    selectArticleTypes,
    unselectArticleTypes,
    filterSelectedArticleTypes,
    filterUnselectedArticleTypes,
    toggleIgnoreOnQuotation,
    completedSave,
} = updatePolicyAdditionRank.actions

export default updatePolicyAdditionRank.reducer