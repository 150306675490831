import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/redux_store';
import BaseDataService from "../../../services/base_data.service";
import MassPointService from '../../../services/masspoint.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';

export const createMasspoint = createAsyncThunk(
    'masspoints/masspoint/create_masspoint',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const data = state.masspoint_create.data.masspoint;
        const response = await MassPointService.saveMasspoint({
            name: data.name,
            massPointType: data.massPointType,
            bodySide: data.bodySide,
            bodyAreaId: data.bodyArea.id,
            isPantyTopWithStandardDimensions: data.isPantyTopWithStandardDimensions,
            isEllipticalShapeKneeFunctionZone: data.isEllipticalShapeKneeFunctionZone
        });
        handleResponseErrors(response, "Maßpunkt");
        return response;
    }
)

export const initData = createAsyncThunk(
    'masspoints/masspoint/create_masspoint/initData',
    async (_, { dispatch }) => {
        await Promise.all([
            dispatch(fetchBodyAreas()),
        ]);
    }
)

export const fetchBodyAreas = createAsyncThunk(
    'masspoints/masspoint/create_masspoint//fetchBodyAreas',
    async (thunkApi) => {
        const response = await BaseDataService.getBodyAreas();
        handleResponseErrors(response, "Basisdaten");
        return response;
    }
)