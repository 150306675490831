import { doesExist, isNullOrWhitespace } from "services/validation.service";
import IMasspointOverview from "models/masspoints/masspoint_overview";
import { MasspointTypeEnumValuesLookup } from "models/masspoints/enums/masspoint_type.enum";
import { IMassPointCriteriaItem } from "check/policy/components/policy_editor.model";
import { PolicyUpperThresholdCreateState } from "./policy_upper_threshold_create.model";
import { distinctMassPointCriteria } from "shared/helpers/distinctMassPointCriteria";

export const updateCanSave = (state: PolicyUpperThresholdCreateState) => {
    state.command.savePolicy.canExecute =
        !isNullOrWhitespace(state.data.policy.name)
        && doesExist(state.data.selectedAddition)
        && doesExist(state.data.selectedMasspoint)
        && doesExist(state.data.thresholdValue);
}

export const createMasspointItemList = (massPoints: IMasspointOverview[]) : IMassPointCriteriaItem[] => {
    return  massPoints
                .filter(distinctMassPointCriteria)
                .map(mp =>  { return {
                    id: mp.id,
                    displayName: `${mp.name} ${MasspointTypeEnumValuesLookup(mp.massPointType)} ${mp.bodyArea.name}`,
                    name: mp.name,
                    massPointType: mp.massPointType,
                    bodySide: mp.bodySide,
                    bodyAreaId: mp.bodyArea.id, 
    }})
};