import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/redux_store';
import { IMassDefinition } from '../../../models/massdefinitions/massdefinition';
import MassMaskService from '../../../services/massmask.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';
import { IMassDefinitionSortIndex } from 'models/massdefinitions/massDefinitionSortIndex';

export const getMassMask = createAsyncThunk(
    'massMask/massDefinition/getMassMask',
    async (massMaskId: Number, _) => {
        const response = await MassMaskService.getMassmask(massMaskId);
        handleResponseErrors(response, "Maßmaske");
        return response;
    }
)

export const updateMassDefinitionSortIndex = createAsyncThunk(
    'massMask/massDefinition/updateMassDefinitionSortIndex',
    async (massdefinitionSortIndexes: IMassDefinitionSortIndex[], { getState }) => {
        const state = getState() as RootState
        const massMaskId = state.massmask_massdefinition.actualData.massMask.id;
        const response = await MassMaskService.updateMassDefinitionSortIndex(massMaskId, massdefinitionSortIndexes);
        handleResponseErrors(response, "Maßdefinition");

        const responseMassMask = await MassMaskService.getMassmask(massMaskId);
        handleResponseErrors(responseMassMask, "Maßmaske");
        return responseMassMask;
    }
)

export const confirmDeleteMassDefinition = createAsyncThunk(
    'massMask/massDefinition/confirmDeleteMassDefinition',
    async (_, { getState }) => {
        const state = getState() as RootState
        const massDefinitionId = state.massmask_massdefinition.actualData.selectedMassDefinition.id
        const massmaskId = state.massmask_massdefinition.actualData.massMask.id;
        const responseDelete = await MassMaskService.deleteMassDefinition(massmaskId, massDefinitionId)
        handleResponseErrors(responseDelete, "Maßdefinition");

        const responseMassMask = await MassMaskService.getMassmask(massmaskId);
        handleResponseErrors(responseMassMask, "Maßmaske");
        return responseMassMask;
    }
)

export const addMassDefinition = createAsyncThunk(
    'massMask/massDefinition/addMassDefinition',
    async (_) => { }
)

export const assignMassPoints = createAsyncThunk(
    'massMask/massDefinition/assignMassPoints',
    async (massDefinition: IMassDefinition, _) => { }
)

export const finishMassDefinitions = createAsyncThunk(
    'massMask/massDefinition/finishMassDefinitions',
    async (_) => { }
)
