import { EnumDescriptor } from 'models/enum_descriptor';
import { MasspointBodySideEnumValuesLookup } from 'models/masspoints/enums/body_side.enum';
import { MasspointTypeEnumValuesLookup } from 'models/masspoints/enums/masspoint_type.enum';
import { UpdateMassPointReferences } from 'models/masspoints/masspoint_references';
import { MasspointSubMassCodeEnum, MasspointSubMassCodeEnumLookup } from '../../../models/masspoints/enums/masspoint_sub_code.enum';
import { doesExist, isNotEmpty } from "../../../services/validation.service";
import { EditReferences, MassPointEditReferencesState } from "./edit_references.model";

export const toUpdateReferences = (state: MassPointEditReferencesState): UpdateMassPointReferences => {
    return {
        massPointId: state.loadedData.existingMassPoint.id,
        massCode: getMassCodeForUpdate(state.actualData.massCode),
        massSubcode: getMassSubCodeForUpdate(state.actualData.massSubCode),
        codeReferences: state.actualData.references.map(reference => {
            return {
                articleTypeId: reference.articleType.id,
                mainProductLineId: reference.mainProductLine.id,
                massCode: getMassCodeForUpdate(reference.massCode),
                massSubCode: getMassSubCodeForUpdate(reference.massSubCode)
            }
        })
    };
}

export const updateIsAddReferenceEnabled = (state: MassPointEditReferencesState) => {
    state.actualData.isAddReferenceEnabled = doesExist(state.actualData.massCode)
        && isNotEmpty(state.actualData.massCode)
        && doesExist(state.actualData.massSubCode)
        && state.actualData.massSubCode.value !== MasspointSubMassCodeEnum.Undefined;
}

export const updateCanRemoveMainReference = (state: MassPointEditReferencesState) => {
    state.actualData.isRemoveMainReferenceEnabled = state.actualData.references.length === 0;
}

export const updateCanUpdateReferences = (state: MassPointEditReferencesState) => {
    state.command.updateReferences.canExecute = isMainReferenceValid(state) &&
        state.actualData.references.every(value => isReferenceValid(value))
}

export const updateActualData = (state: MassPointEditReferencesState) => {
    state.actualData.massPointInfo = {
        name: state.loadedData.existingMassPoint.name,
        side: MasspointBodySideEnumValuesLookup(state.loadedData.existingMassPoint.bodySide),
        type: MasspointTypeEnumValuesLookup(state.loadedData.existingMassPoint.massPointType),
        bodyArea: state.loadedData.existingMassPoint.bodyArea.name,
    };
    state.actualData.massCode = doesExist(state.loadedData.existingReferences.massCode) ? state.loadedData.existingReferences.massCode : "";
    state.actualData.massSubCode = MasspointSubMassCodeEnumLookup(state.loadedData.existingReferences.massSubCode)

    state.loadedData.existingReferences.massPointCodeReferenceVersions.forEach((value, index) => {
        state.actualData.references[index] = {
            massCode: doesExist(value.masscode) ? value.masscode : "",
            massSubCode: MasspointSubMassCodeEnumLookup(value.massSubCode),
            articleType: value.articleType,
            mainProductLine: value.mainProductLine != null ? value.mainProductLine : state.loadedData.existingMainProductLines[0],
        };
    });
}

const isMainReferenceValid = (state: MassPointEditReferencesState) => {
    const data = state.actualData;
    return (isNotEmpty(data.massCode) === false
        && data.massSubCode.value === MasspointSubMassCodeEnum.Undefined
        && data.references.length === 0)
        || (data.massSubCode.value !== MasspointSubMassCodeEnum.Undefined
            && isNotEmpty(data.massCode) &&
            data.massCode.length <= 3);
}

const isReferenceValid = (reference: EditReferences) => {
    return isNotEmpty(reference.massCode) &&
        reference.massCode.length <= 3 &&
        reference.massSubCode.value !== MasspointSubMassCodeEnum.Undefined &&
        doesExist(reference.articleType)
}

const getMassCodeForUpdate = (massCode: string): string => {
    return isNotEmpty(massCode)
        ? massCode
        : null;
}

const getMassSubCodeForUpdate = (massSubCode: EnumDescriptor<MasspointSubMassCodeEnum>): string => {
    return massSubCode.value === MasspointSubMassCodeEnum.Undefined
        ? null
        : massSubCode.text;
}