import { doesExist, isNullOrWhitespace } from "services/validation.service";
import { PolicyExcludeAdditionByMasspointUpdateState } from "./policy_exclude_addition_by_masspoint_update.model";
import { IMassPointCriteriaItem } from "check/policy/components/policy_editor.model";
import { MassPointCriteria } from "models/masspoints/masspoint_criteria";
import IMasspointOverview from "models/masspoints/masspoint_overview";
import { MasspointTypeEnumValuesLookup } from "models/masspoints/enums/masspoint_type.enum";
import { distinctMassPointCriteria } from "shared/helpers/distinctMassPointCriteria";

export const updateCanSave = (state: PolicyExcludeAdditionByMasspointUpdateState) => {
    state.command.updatePolicy.canExecute =
        !isNullOrWhitespace(state.data.policy.name)
        && doesExist(state.data.selectedAddition)
        && doesExist(state.data.selectedMasspoint1)
        && doesExist(state.data.selectedMasspoint2)
        && isNotEqual(state);
}

const isNotEqual = (state: PolicyExcludeAdditionByMasspointUpdateState) => {
    return state.data.policy.name !== state.loadedData.policy.name 
        || state.data.policy.ignoreOnQuotation !== state.loadedData.policy.ignoreOnQuotation 
        || state.data.selectedAddition.id !== state.loadedData.policy.additionId
        || masspointNotEquals(state.data.selectedMasspoint1, state.loadedData.policy.massPoint1)
        || masspointNotEquals(state.data.selectedMasspoint2, state.loadedData.policy.massPoint2);
}

const masspointNotEquals = (selectedMasspoint: IMassPointCriteriaItem, loadedMasspointCriteria: MassPointCriteria) => {
    return !(selectedMasspoint.bodyAreaId === loadedMasspointCriteria.bodyAreaId 
        && selectedMasspoint.name === loadedMasspointCriteria.massPointName
        && selectedMasspoint.massPointType === loadedMasspointCriteria.massPointType);
}

export const createMasspointItemList = (massPoints: IMasspointOverview[]) : IMassPointCriteriaItem[] => {
    return  massPoints
                .filter(distinctMassPointCriteria)
                .map(mp =>  { return {
                    id: mp.id,
                    displayName: `${mp.name} ${MasspointTypeEnumValuesLookup(mp.massPointType)} ${mp.bodyArea.name}`,
                    name: mp.name,
                    massPointType: mp.massPointType,
                    bodySide: mp.bodySide,
                    bodyAreaId: mp.bodyArea.id, 
    }})
};