import { GridColDef, GridSortModel } from "@mui/x-data-grid-pro";

export const getGridColumns = (): GridColDef[] => [
    {
        field: "displayName",
        headerName: "Maßpunkt",
        disableColumnMenu: false,
        flex: 0.4,
        sortable: false,
        filterable:false,
        hideable: false,
        pinnable: false,
        valueGetter: (value, row) => row.displayName,
    },  
    {
        field: "minimalValue",
        headerName: "Unterer Wert",
        disableColumnMenu: false,
        flex: 0.2,
        sortable: false,
        filterable:false,
        hideable: false,
        pinnable: false,
        valueGetter: (value, row) => row.minimalValue
    },  
    {
        field: "maximalValue",
        headerName: "Oberer Wert",
        disableColumnMenu: false,
        flex: 0.2,
        sortable: false,
        filterable:false,
        hideable: false,
        pinnable: false,
        valueGetter: (value, row) => row.maximalValue
    },  
    {
        field: "isDefault",
        headerName: "Default",
        disableColumnMenu: false,
        flex: 0.2,
        sortable: false,
        filterable:false,
        hideable: false,
        pinnable: false,
        valueGetter: (value, row) => row.isDefault
    }
]    

export const sortBySide: GridSortModel = [
    {
        field: "side",
        sort: "asc",
    },
];