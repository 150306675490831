import { isNullOrWhitespace } from "services/validation.service";
import { PolicyAdjustMaximumRangeEditState } from "./policy_adjust_maximum_range_update.model";
import { additionValid, allConfigsValid } from "../../policy_adjust_maximum_range_create/redux/policy_adjust_maximum_range_create.reducer";
import { IAdjustSecondRangConfig } from "models/policies/policy_adjust_maximum_range";
import { IAdjustMaximumRangeConfiguration } from "../../policy_adjust_maximum_range_create/redux/policy_adjust_maximum_range_create.model";
import IAvailableArticleType from "models/available_basedata/available_article_type";
import { ISelectable } from "shared/components/selectionComponent/models/selectable";
import { createSelectionList, filterSelectionList } from "shared/components/selectionComponent/selectionList.helper";
import { getIAvailableArticleTypeDisplayName } from "shared/helpers/displayNames";

export const updateCanSave = (state: PolicyAdjustMaximumRangeEditState) => {
    state.command.updatePolicy.canExecute =
        !isNullOrWhitespace(state.data.policy.name)
        && additionValid(state.data.selectedAddition)
        && state.data.configurations.length > 0
        && allConfigsValid(state.data.configurations);
}

export const createNewConfiguration = (state: PolicyAdjustMaximumRangeEditState): IAdjustMaximumRangeConfiguration => {

    return {
        selectedMasspoint1: null,
        selectedMasspoint2: null,
        allMasspoints: state.loadedData.masspoints,
        multiplyValue: 1,
        articleTypes: createSelectionList(getUnusedArticleTypes(state),[],getIAvailableArticleTypeDisplayName)
    };
}

const getUnusedArticleTypes = (state: PolicyAdjustMaximumRangeEditState): IAvailableArticleType[] => {
    let availableArticleTypes = state.loadedData.articleTypes;
    const usedArticleTypes = state.data.configurations.flatMap(x => x.articleTypes.selectedList.allItems);
    return availableArticleTypes.filter(x => usedArticleTypes.find(articleType => articleType.id === x.id) == undefined);
}

export const createConfigurations = (state: PolicyAdjustMaximumRangeEditState, configs: IAdjustSecondRangConfig[]): IAdjustMaximumRangeConfiguration[] => {
    return configs?.map(config => {
        return ({
            selectedMasspoint1: state.loadedData.masspoints.find(x =>
                x.bodyAreaId === config.massPointOne.bodyAreaId
                && x.name === config.massPointOne.massPointName
                && x.type === config.massPointOne.massPointType),
            selectedMasspoint2: state.loadedData.masspoints.find(x =>
                x.bodyAreaId === config.massPointTwo.bodyAreaId
                && x.name === config.massPointTwo.massPointName
                && x.type === config.massPointTwo.massPointType),
            multiplyValue: config.multiplyValue,
            allMasspoints: state.loadedData.masspoints,
            articleTypes: createSelectionList(state.loadedData.articleTypes, config.articleTypes, getIAvailableArticleTypeDisplayName),
        })
    })
}

export const removeArticleTypeFromAllConfigurations = (state: PolicyAdjustMaximumRangeEditState, articleIds: number[]) => {
    state.data.configurations.forEach(config => {
        let unselectedList = config.articleTypes.unSelectedList;
        articleIds.forEach(articleId => {
            if (unselectedList.allItems.find(x => x.id === articleId)) {
                const articleTypeToRemove = unselectedList.allItems.findIndex(x => x.id == articleId);
                unselectedList.allItems.splice(articleTypeToRemove, 1);
            }
        });
        filterSelectionList(unselectedList);
    });
}

export const addArticleTypeToAllConfigurations = (state: PolicyAdjustMaximumRangeEditState, articleIds: number[]) => {
    state.data.configurations.forEach(config => {
        let unselectedList = config.articleTypes.unSelectedList;
        articleIds.forEach(articleId => {
            if (unselectedList.allItems.find(x => x.id === articleId) == undefined) {
                const articleType = state.loadedData.articleTypes.find(x => x.id == articleId);
                unselectedList.allItems.push(createArticleType(articleType));

            }
        });
        filterSelectionList(unselectedList);
    });
}

const createArticleType = (articleType: IAvailableArticleType): ISelectable => {
    return {
        id: articleType.id,
        displayName: `${articleType.erpId} ${articleType.name}`,
    }
}