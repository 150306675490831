import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { DateDisplayComponent } from "shared/components/form_controls/date_display.component";
import { TimeDisplayComponent } from '../../../shared/components/form_controls/time_display.component';
import { IBasedataImport } from '../../../models/basedata_import/basedata_import';
import * as style from "./imports_overview_table.style";
import { ImportStatusEnumLookup } from "models/basedata_import/import_status.enum";
import { OverViewTable, OverviewHeaderTableCell, OverviewTableCell, OverviewTableRow } from "shared/style/overview";
import { DateTimeDisplayComponent } from "shared/components/form_controls/date_time_display.component";

interface ImportsOverviewTableComponentProps {
    imports: IBasedataImport[];
}

export const ImportsOverviewTableComponent: React.FC<ImportsOverviewTableComponentProps> = ({ imports }) => {
    return (
        <>
            <TableContainer>
                <Table sx={{minWidth: OverViewTable.width}} aria-label="massmask table">
                    <TableHead>
                        <TableRow>
                            <OverviewHeaderTableCell align="left">Status</OverviewHeaderTableCell>
                            <OverviewHeaderTableCell align="left">Startzeit</OverviewHeaderTableCell>
                            <OverviewHeaderTableCell align="left">Dauer</OverviewHeaderTableCell>
                            <OverviewHeaderTableCell align="left">Nutzer</OverviewHeaderTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {imports.map(baseDataImport => (
                            <OverviewTableRow key={baseDataImport.id}>
                                <OverviewTableCell align="left">
                                    {ImportStatusEnumLookup[baseDataImport.status]}
                                </OverviewTableCell>
                                <OverviewTableCell align="left">
                                    <DateTimeDisplayComponent date={baseDataImport.startedAt} />
                                </OverviewTableCell>
                                <OverviewTableCell align="left">
                                    {baseDataImport.duration}
                                </OverviewTableCell>
                                <OverviewTableCell align="left">
                                    {baseDataImport.startedBy}
                                </OverviewTableCell>
                            </OverviewTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
