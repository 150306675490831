import { ThumbnailModel } from "../../models/thumbnail.model";
import { ThumbnailComponent } from "../../shared/components/buttons/thumbnail.component";
import massmasksImage from './images/massmasks.jpg';
import checks from './images/checks.jpg';

import * as style from "./simulator_overview.style";

export const SimulatorOverviewComponent = () => {
    const thumbnails: ThumbnailModel[] = [
        new ThumbnailModel("Maßmasken", massmasksImage, "/simulator/massmask"),
        new ThumbnailModel("Konfigurationen", checks, "/simulator/configuration"),
    ];

    return (<style.ThumbnailWrapper>
        {thumbnails.map((thumb, i) => {
            return (<ThumbnailComponent key={i} thumbnail={thumb} />)
        })}
    </style.ThumbnailWrapper>);
}