import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "app/redux_store";
import IBodyInformationOverview from "models/bodyinformation/bodyinformation_overview";
import { BodyInformationService } from "services/bodyInformation.service";
import { handleResponseErrors } from "shared/networking/error_handling";

export const createBodyInformation = createAsyncThunk(
    'massMask/bodyInformations/overview/createBodyInformation',
    async (_) => { }
)

export const getBodyInformations = createAsyncThunk(
    'massMask/bodyInformations/overview/getBodyInformations',
    async (_) => {
        const response = await BodyInformationService.getBodyInformations();
        handleResponseErrors(response, "Zusätzliche Angaben");
        return response;
    }
)

export const confirmDeleteBodyInformation = createAsyncThunk(
    'massMask/bodyInformations/overview/deleteBodyInformation',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const bodyInformationId = state.body_information_overview.actualData.activeBodyInformation.id

        const deleteResponse = await BodyInformationService.deleteBodyInformation(bodyInformationId);
        handleResponseErrors(deleteResponse, "Zusätzliche Angaben");

        const response = await BodyInformationService.getBodyInformations();
        handleResponseErrors(response, "Zusätzliche Angaben");
        return response;
    }
)

export const updateBodyInformation = createAsyncThunk(
    'massMask/bodyInformations/overview/updateBodyInformation',
    async (bodyInformation: IBodyInformationOverview) => {
        return bodyInformation;
    }
)