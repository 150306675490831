import { MassMaskCommonInfoEditState } from "./massmask_common_info_edit.model";

export const updateCanExecuteEdit = (state: MassMaskCommonInfoEditState): boolean => {
    const bodyInformationSelectionIsValid = validateBodyInformationSelection(state);

    return (bodyInformationSelectionIsValid);
}

const validateBodyInformationSelection = (state: MassMaskCommonInfoEditState): boolean => {
    return !hasDuplicates(state.actualData.bodyInformationSelection.flatMap(x => x.id));
}

const hasDuplicates = (array: Array<any>) => {
    return new Set(array).size !== array.length
}