import { Grid } from "@mui/material";
import { EditorMediumSectionHeaderComponent, EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";
import { DropdownComponent, FlexBox, Left, PrimaryButtonComponent, Right } from "shared/shared";
import { IAttributeViewModel, IMassPointCriteriaSelection, IMassPointValueSelection, ISerialSizeArticleConfigurationViewModel, ISizeViewModel } from "./serial_size_editor.model";
import IAvailableArticleType from "models/available_basedata/available_article_type";
import AddIcon from '@mui/icons-material/Add';
import { SizeEditorComponent } from "./serial_size_editor_size_editor.component";
import { BoxItemWithShadow } from "./serial_sizes.style";
import IAvailableSize from "models/available_basedata/available_size";
import { SizeSummaryComponent } from "./serial_size_editor_size_summary.component";
import { AttributeSummaryComponent } from "./serial_size_editor_attribute_summary.component";
import { AttributeEditorComponent } from "./serial_size_editor_attribute_editor.component";
import { IAvailableAdditionAttributeCategory } from "models/addition_attribute_categories/available_addition_attribute_category";
import { IAvailableAdditionAttribute } from "models/addition_attribute_categories/available_addition_attribute";

interface SerialSizeArticleTypeConfigurationProps {
    configurationArticleType: ISerialSizeArticleConfigurationViewModel;
    selectArticleType: (articleType: IAvailableArticleType) => void;
    takeArticleType: () => void;
    cancelArticleType: () => void;
    addSize: () => void;
    selectSize: (size: IAvailableSize) => void;
    editSize: (size: ISizeViewModel) => void;
    deleteSize: (size: ISizeViewModel) => void;
    takeSize: () => void;
    cancelSize: () => void;
    addMasspoint: () => void;
    selectMasspoint: (masspoint: IMassPointCriteriaSelection) => void;
    updateValueFrom: (masspoint: IMassPointValueSelection) => void;
    updateValueTo: (masspoint: IMassPointValueSelection) => void;
    deleteMasspoint: (index: number) => void;
    addAttribute: () => void;
    takeAttribute: () => void;
    editAttribute: (attribute: IAttributeViewModel) => void;
    deleteAttribute: (attribute: IAttributeViewModel) => void;
    selectCategory: (category: IAvailableAdditionAttributeCategory) => void;
    selectAttribute: (attribute: IAvailableAdditionAttribute) => void;
    cancelAttribute: () => void;
    addAttributeMasspoint: () => void;
    selectAttributeMasspoint: (masspoint: IMassPointCriteriaSelection) => void;
    updateAttributeValueFrom: (masspoint: IMassPointValueSelection) => void;
    updateAttributeValueTo: (masspoint: IMassPointValueSelection) => void;
    deleteAttributeMasspoint: (index: number) => void;
}

export const SerialSizeArticleTypeConfigurationComponent: React.FC<SerialSizeArticleTypeConfigurationProps> = (
    {
        configurationArticleType,
        selectArticleType,
        takeArticleType,
        cancelArticleType,
        addSize,
        selectSize,
        editSize,
        deleteSize,
        takeSize,
        cancelSize,
        addMasspoint,
        selectMasspoint,
        updateValueFrom,
        updateValueTo,
        deleteMasspoint,
        addAttribute,
        takeAttribute,
        editAttribute,
        deleteAttribute,
        selectCategory,
        selectAttribute,
        cancelAttribute,
        addAttributeMasspoint,
        selectAttributeMasspoint,
        updateAttributeValueFrom,
        updateAttributeValueTo,
        deleteAttributeMasspoint
    }) => {

    const isNewSize = configurationArticleType.configureSize != undefined && configurationArticleType.configureSize.isNewSize;
    const isNewAttributeSize = configurationArticleType.configureAttribute != undefined && configurationArticleType.configureAttribute.isNewAttribute;
    const disabledSize = configurationArticleType.configureSize != undefined || configurationArticleType.configureAttribute != undefined;

    const disableCancelArticleType = configurationArticleType.articleType.id === -1
        && (configurationArticleType.configureSize != undefined
            || configurationArticleType.configureAttribute != undefined);

    const disableTakeArticleType = configurationArticleType.configureSize != undefined
        || configurationArticleType.configureAttribute != undefined
        || (configurationArticleType.sizes.length <= 0 && configurationArticleType.articleType.id === -1)
        || (configurationArticleType.sizes.length < 0 && configurationArticleType.articleType.id !== -1)
        || configurationArticleType.attributes.length < 0;

    return (
        <>
            <Grid spacing={3}
                direction="column"
                container
                justifyContent="space-between">
                <Grid item>
                    <EditorSectionHeaderComponent style={{ marginTop: "0px" }}>Grössenkonfiguration</EditorSectionHeaderComponent>
                </Grid>
                <Grid item>
                    <FlexBox>
                        <Left>
                            <DropdownComponent name="Artikelart"
                                data={configurationArticleType.availableArticleTypes}
                                isRequired={true}
                                isReadonly={configurationArticleType.articleType.id === -1}
                                onSelect={(e) => selectArticleType(e)}
                                displayedProperties={["erpId", "name"]}
                                selectedValue={configurationArticleType.articleType}
                            />
                        </Left>
                        <Right>
                            <PrimaryButtonComponent disabled={disabledSize} onClick={() => addSize()}>
                                <AddIcon />Neue Grösse konfigurieren
                            </PrimaryButtonComponent>
                        </Right>
                    </FlexBox>
                </Grid>
                <Grid item>
                    {isNewSize && <Grid item>
                        <BoxItemWithShadow>
                            <SizeEditorComponent
                                configurationSize={configurationArticleType.configureSize}
                                selectSize={selectSize}
                                takeSize={takeSize}
                                cancelSize={cancelSize}
                                addMasspoint={addMasspoint}
                                selectMasspoint={selectMasspoint}
                                updateValueFrom={updateValueFrom}
                                updateValueTo={updateValueTo}
                                deleteMasspoint={deleteMasspoint}
                            />
                        </BoxItemWithShadow>
                    </Grid>}
                    <Grid item>
                        {configurationArticleType.sizes.length > 0 &&
                            <SizeSummaryComponent
                                sizeSummaries={configurationArticleType.sizes}
                                configurationSize={configurationArticleType.configureSize}
                                configurationAttribute={configurationArticleType.configureAttribute}
                                editSize={editSize}
                                deleteSize={deleteSize}
                                selectSize={selectSize}
                                takeSize={takeSize}
                                cancelSize={cancelSize}
                                addMasspoint={addMasspoint}
                                selectMasspoint={selectMasspoint}
                                updateValueFrom={updateValueFrom}
                                updateValueTo={updateValueTo}
                                deleteMasspoint={deleteMasspoint}
                            />
                        }
                    </Grid>
                </Grid>
                <Grid item>
                    <EditorMediumSectionHeaderComponent>Sondergrößen</EditorMediumSectionHeaderComponent>
                </Grid>
                <Grid item>
                    <FlexBox>
                        <Left>
                            <div>Es ist möglich die Maßpunktranges zu definieren, damit ein Artikelattribut bevorzugt vorgeschlagen wird.</div>
                            <div>Die Werte der konfigurierten Maßpunkten müssen in der Maßmaske innerhalb des definierten Ranges sein.</div>
                        </Left>
                        <Right>
                            <PrimaryButtonComponent disabled={disabledSize} onClick={() => addAttribute()}>
                                <AddIcon />Neue Sondergröße konfigurieren
                            </PrimaryButtonComponent>
                        </Right>
                    </FlexBox>
                </Grid>
                <Grid item>
                    {isNewAttributeSize && <Grid item>
                        <BoxItemWithShadow>
                            <AttributeEditorComponent
                                configurationAttribute={configurationArticleType.configureAttribute}
                                selectCategory={selectCategory}
                                selectAttribute={selectAttribute}
                                takeAttribute={takeAttribute}
                                cancelAttribute={cancelAttribute}
                                addAttributeMasspoint={addAttributeMasspoint}
                                selectAttributeMasspoint={selectAttributeMasspoint}
                                updateAttributeValueFrom={updateAttributeValueFrom}
                                updateAttributeValueTo={updateAttributeValueTo}
                                deleteAttributeMasspoint={deleteAttributeMasspoint}
                            />
                        </BoxItemWithShadow>
                    </Grid>}
                    <Grid item>
                        {configurationArticleType.attributes.length > 0 &&
                            <AttributeSummaryComponent
                                attributeSummaries={configurationArticleType.attributes}
                                configurationAttribute={configurationArticleType.configureAttribute}
                                configurationSize={configurationArticleType.configureSize}
                                editAttribute={editAttribute}
                                deleteAttribute={deleteAttribute}
                                selectCategory={selectCategory}
                                selectAttribute={selectAttribute}
                                takeAttribute={takeAttribute}
                                cancelAttribute={cancelAttribute}
                                addAttributeMasspoint={addAttributeMasspoint}
                                selectAttributeMasspoint={selectAttributeMasspoint}
                                updateAttributeValueFrom={updateAttributeValueFrom}
                                updateAttributeValueTo={updateAttributeValueTo}
                                deleteAttributeMasspoint={deleteAttributeMasspoint}
                            />
                        }
                    </Grid>
                </Grid>
                <Grid item
                    container
                    justifyContent="flex-end"
                    spacing={2}>
                    <Grid item>
                        <PrimaryButtonComponent
                            disabled={disableCancelArticleType}
                            onClick={cancelArticleType}>
                            Verwerfen
                        </PrimaryButtonComponent>
                    </Grid>
                    <Grid item>
                        <PrimaryButtonComponent
                            disabled={disableTakeArticleType}
                            onClick={takeArticleType}>
                            Übernehmen
                        </PrimaryButtonComponent>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};