import { Grid } from "@mui/material"
import { GridFilterItem, GridColDef, GridSortModel } from "@mui/x-data-grid-pro"
import IAvailableFootType from "models/available_basedata/available_foot_type"
import { IFootOptionMassPoint, IFootOptionOverview } from "models/footoptions/footoption_overview"
import { MasspointTypeEnumValuesLookup } from "models/masspoints/enums/masspoint_type.enum"
import { CustomFilterOperators } from "shared/components/datagrid/custom_filter_operators"
import { DatagridMenu } from "shared/components/datagrid/datagrid_menu.component"
import { OverviewTableCell } from "shared/style/overview"

const buildFootTypeDisplayString = (footType: IAvailableFootType): string => {
    if (footType != null) {
        return `${footType.externalIdentifier} ${footType.name}`;
    }
    return "-";
}

const renderHiddenMasspointCriterias = (masspoints: IFootOptionMassPoint[]) => {
    return <div style={{ minHeight: "60px", padding: "10px 0px" }}>
        <Grid container spacing={2} xs={12}>
            {
                masspoints.map((masspoint, index) => (
                    <Grid item xs={6} key={index}>
                        {masspoint.name} {MasspointTypeEnumValuesLookup(masspoint.type)} {masspoint.bodyArea.name}
                    </Grid>
                ))
            }
        </Grid>
    </div>
}

export const filterFootOptionValue = (filterItem: GridFilterItem, filteredItems: IFootOptionOverview[]) => {
    if (filterItem.value) {
        switch (filterItem.field) {
            case 'mainProductLine':
                filteredItems = filteredItems.filter(x => x.mainProductLine.name.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'footType':
                filteredItems = filteredItems.filter(x => buildFootTypeDisplayString(x.footType).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
        }
    }
    return filteredItems;
}

export const getGridColumns = (
    editFootOption: (footOption: IFootOptionOverview) => void,
    deleteFootOption: (footOption: IFootOptionOverview) => void,
): GridColDef[] => [
        {
            field: "menu",
            headerName: " ",
            width: 15,
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            hideable: false,
            pinnable: false,
            renderCell: params => DatagridMenu({
                params: params, menuItemsData:
                    [
                        { label: "Fußoption editieren", action: editFootOption },
                        { label: "Fußoption löschen", action: deleteFootOption }
                    ]
            })
        },
        {
            field: "mainProductLine",
            headerName: "Hauptproduktlinie",
            disableColumnMenu: false,
            flex: 0.25,
            sortable: false,
            hideable: false,
            pinnable: false,
            valueGetter: (value, row) => row.mainProductLine.name,
            filterOperators: CustomFilterOperators(),
        },
        {
            field: "footType",
            headerName: "Fußart",
            disableColumnMenu: false,
            flex: 0.5,
            sortable: false,
            hideable: false,
            pinnable: false,
            valueGetter: (value, row) => buildFootTypeDisplayString(row.footType),
            filterOperators: CustomFilterOperators(),
        },
        {
            field: "hiddenMasspointCriterias",
            headerName: "Ausgeblendete Maßpunktkriterien",
            disableColumnMenu: true,
            flex: 0.25,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterable: false,
            renderCell: params => renderHiddenMasspointCriterias(params.row.footOptionMassPointVersions),
            valueGetter: (value, row) => buildFootTypeDisplayString(row.footType),
        }
    ]

export const sortByMainProductLineModel: GridSortModel = [
    {
        field: "mainProductLine",
        sort: "asc",
    },
];