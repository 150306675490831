import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MasspointEditState } from './masspoint_edit.model';
import { editMasspoint, getMasspoint } from './masspoint_edit.thunks';

const initialState: MasspointEditState = {
    actualData: { 
        isPantyTopWithStandardDimensions: false,
        isEllipticalShapeKneeFunctionZone: false   
    },
    loadedData: {
        masspoint: null
    },
    query: {
        getMasspoint: { status: "idle", canExecute: true },
    },
    command: {
        editMasspoint: { status: "idle", canExecute: false },
        cancelMasspoint: { status: "idle", canExecute: false } 
    },
}

export const masspointEditSlice = createSlice({
    name: 'masspoints/masspoint/edit',
    initialState,
    reducers: {
        resetState: (state) => {
            state.command = initialState.command;
            state.query = initialState.query;
            state.actualData = initialState.actualData;
        },
        updateIsPantyTopWithStandardDimensions: (state, action: PayloadAction<boolean>) => {
            state.actualData.isPantyTopWithStandardDimensions = action.payload;
        },      
        updateIsEllipticalShapeKneeFunctionZone: (state, action: PayloadAction<boolean>) => {
            state.actualData.isEllipticalShapeKneeFunctionZone = action.payload;
        },
        cancelMasspoint: (state) => {
            state.command.cancelMasspoint.status = "success";
        },
        editMasspointCompleted: (state) => {
            state.command.editMasspoint.status = "idle"
        },
    }, extraReducers: (builder) => {

        // getMasspoint
        builder.addCase(getMasspoint.pending, (state, action) => {
            state.query.getMasspoint.status = "pending"
            state.query.getMasspoint.canExecute = false;
        }).addCase(getMasspoint.rejected, (state, action) => {
            state.query.getMasspoint.status = "error"
            state.query.getMasspoint.message = action.error.message;
            state.query.getMasspoint.canExecute = true;
        }).addCase(getMasspoint.fulfilled, (state, action) => {
            state.query.getMasspoint.status = "success"
            state.query.getMasspoint.message = undefined;
            const loadedMassPoint = action.payload.getData();
            state.loadedData.masspoint = loadedMassPoint;
            state.actualData.isPantyTopWithStandardDimensions = loadedMassPoint.isPantyTopWithStandardDimensions;
            state.actualData.isEllipticalShapeKneeFunctionZone = loadedMassPoint.isEllipticalShapeKneeFunctionZone;
            state.query.getMasspoint.canExecute = true;
            state.command.editMasspoint.canExecute = true;
            
        // edit masspoint
        }).addCase(editMasspoint.pending, (state, action) => {
            state.command.editMasspoint.status = "pending"
            state.command.editMasspoint.canExecute = false;
        }).addCase(editMasspoint.rejected, (state, action) => {
            state.command.editMasspoint.status = "error"
            state.command.editMasspoint.canExecute = true;
            state.command.editMasspoint.message = action.error.message;
        }).addCase(editMasspoint.fulfilled, (state, action) => {
            state.command.editMasspoint.status = "success"
            state.command.editMasspoint.canExecute = true;
        })
    }
})

export const {  
    resetState,
    updateIsPantyTopWithStandardDimensions,
    updateIsEllipticalShapeKneeFunctionZone,
    cancelMasspoint,
    editMasspointCompleted
} = masspointEditSlice.actions

export default masspointEditSlice.reducer