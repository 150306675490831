import styled from "@emotion/styled";
import colors from "shared/style/colors";

export const Spacer = styled.div`
    border-left: 1px solid ${colors.mediLightGray};
    border-right: 1px solid ${colors.mediLightGray};
    width: 5px;
    min-height: 10px;
    height: 300px;
    margin-left: 20px;
    margin-right: 20px;
`;

export const ListWrapper = styled.div`
    overflow-y: scroll;
    height: 200px;
`

export const LockedHeader = styled.div`
  margin-top: 10px;
  background-color: ${colors.mediLightGray};
  display: flex;
  align-items: flex-end;  
  justify-content: space-between;
  padding: 10px 15px;
`;

export const ToggleWrapper = styled.div`
  
`;

export const ToggleInfo = styled.div`
  color: ${colors.mediGrey};
`;

export const LockedHeadline = styled.h3`

`;