import React from 'react';
import { Grid} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { AppDispatch } from '../../app/redux_store';
import * as style from "./rule_placeholder_list.style";
import { toggleExpandedRulePlaceholder, toggleExpandedRuleConstantPlaceholder } from '../redux/rules_formula.slice';
import { RulePlaceholderComponent } from './rule_placeholder.component';
import { RuleConstantPlaceholderComponent } from './rule_constant_placeholder.component';
import { RuleLinkMasspointComponent } from './rule_link_masspoint.component';
import { AccordionDetailsItem, AccordionErrorSummaryItem, AccordionItem, AccordionSummaryItem } from './rule_placeholder_list.style';

interface RulePlaceholderListComponentProps {
    ruleIndex: number;
}

export const RulePlaceholderListComponent: React.FC<RulePlaceholderListComponentProps> = ({ ruleIndex }) => {
    const dispatch: AppDispatch = useAppDispatch();

    const state = useAppSelector(store => store.rules_formula);
    const rule = state.actualData.rules[ruleIndex];

    const hasNoError = !state.actualData.placerholderValidated || !(rule.formularRuleMassPointPlaceholderVersions.findIndex(x => !x.isValid) > -1);
    const hasNoErrorConstant = !state.actualData.placerholderValidated || !(rule.formulaRuleConstantPlaceholderVersions.findIndex(x => !x.isValid) > -1);

    const isExpanded = rule.isExpanded;
    const isConstantExpanded = rule.isConstantExpanded;

    return (
        <Grid container
            direction='column'
            spacing={1}>               
            <Grid item>
                <AccordionItem id={`${ruleIndex}-accordion`} square expanded={isExpanded} onChange={() => dispatch(toggleExpandedRulePlaceholder(ruleIndex))}>
                    {hasNoError ?
                        <AccordionSummaryItem aria-controls={`${ruleIndex}-content`} id={`${ruleIndex}-header`}>
                            <style.HeaderColumn>
                                Text-Maßpunkte
                            </style.HeaderColumn>
                            <style.HeaderIconColumn>
                                {isExpanded
                                    ? <KeyboardArrowUpIcon />
                                    : <KeyboardArrowDownIcon />}
                            </style.HeaderIconColumn>
                        </AccordionSummaryItem>
                        :
                        <AccordionErrorSummaryItem aria-controls={`${ruleIndex}-content`} id={`${ruleIndex}-header`}>
                            <style.HeaderColumn>
                                Text-Maßpunkte - Alle Tags müssen zugewiesen sein
                            </style.HeaderColumn>
                            <style.HeaderIconColumn>
                                {isExpanded
                                    ? <KeyboardArrowUpIcon />
                                    : <KeyboardArrowDownIcon />}
                            </style.HeaderIconColumn>
                        </AccordionErrorSummaryItem>
                    }
                    <AccordionDetailsItem>
                        <RulePlaceholderComponent ruleIndex={ruleIndex} />
                        <RuleLinkMasspointComponent ruleIndex={ruleIndex} />
                    </AccordionDetailsItem>
                </AccordionItem>
            </Grid>
            <Grid item>
                <AccordionItem id={`${ruleIndex}-constant-accordion`} square expanded={isConstantExpanded} onChange={() => dispatch(toggleExpandedRuleConstantPlaceholder(ruleIndex))}>
                    {hasNoErrorConstant ?
                        <AccordionSummaryItem aria-controls={`${ruleIndex}-constant-content`} id={`${ruleIndex}-constant-header`}>
                            <style.HeaderColumn>
                                Text-Konstanten
                            </style.HeaderColumn>
                            <style.HeaderIconColumn>
                                {isConstantExpanded
                                    ? <KeyboardArrowUpIcon />
                                    : <KeyboardArrowDownIcon />}
                            </style.HeaderIconColumn>
                        </AccordionSummaryItem>
                    :
                        <AccordionErrorSummaryItem aria-controls={`${ruleIndex}-content`} id={`${ruleIndex}-header`}>
                            <style.HeaderColumn>
                                Text-Konstant - Es muss jeweils Wert zugewiesen sein
                            </style.HeaderColumn>
                            <style.HeaderIconColumn>
                                {isExpanded
                                    ? <KeyboardArrowUpIcon />
                                    : <KeyboardArrowDownIcon />}
                            </style.HeaderIconColumn>
                        </AccordionErrorSummaryItem>
                    }
                    <AccordionDetailsItem>
                        <RuleConstantPlaceholderComponent ruleIndex={ruleIndex} />
                    </AccordionDetailsItem>
                </AccordionItem>  
                </Grid>
        </Grid>)
}