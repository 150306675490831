import { Grid } from "@mui/material";
import { IAttributeConfigurationViewModel, IAttributeViewModel, IMassPointCriteriaSelection, IMassPointValueSelection, ISizeConfigurationViewModel } from "./serial_size_editor.model";
import { AttributeSummaryHeading } from "./serial_size_editor_attribute_summary_heading.component";
import { AttributeAccordionComponent } from "./serial_size_editor_attribute_summary_accordion.component";
import { IAvailableAdditionAttributeCategory } from "models/addition_attribute_categories/available_addition_attribute_category";
import { IAvailableAdditionAttribute } from "models/addition_attribute_categories/available_addition_attribute";

interface AttributeSummaryProps {
    attributeSummaries: IAttributeViewModel[];
    configurationAttribute: IAttributeConfigurationViewModel;
    configurationSize: ISizeConfigurationViewModel;
    editAttribute: (attribute: IAttributeViewModel) => void;
    deleteAttribute: (attribute: IAttributeViewModel) => void;
    selectCategory: (category: IAvailableAdditionAttributeCategory) => void;
    selectAttribute: (attribute: IAvailableAdditionAttribute) => void;
    takeAttribute: () => void;
    cancelAttribute: () => void;
    addAttributeMasspoint: () => void;
    selectAttributeMasspoint: (masspoint: IMassPointCriteriaSelection) => void;
    updateAttributeValueFrom: (masspoint: IMassPointValueSelection) => void;
    updateAttributeValueTo: (masspoint: IMassPointValueSelection) => void;
    deleteAttributeMasspoint: (index: number) => void;
}

export const AttributeSummaryComponent: React.FC<AttributeSummaryProps> = (
    {
        attributeSummaries,
        configurationAttribute,
        configurationSize,
        editAttribute,
        deleteAttribute,
        selectCategory,
        selectAttribute,
        takeAttribute,
        cancelAttribute,
        addAttributeMasspoint,
        selectAttributeMasspoint,
        updateAttributeValueFrom,
        updateAttributeValueTo,
        deleteAttributeMasspoint
    }) => {

    return (
        <>
            <Grid spacing={3}
                direction="column"
                container
                justifyContent="space-between">
                <Grid item>
                    <AttributeSummaryHeading />
                    <AttributeAccordionComponent
                        attributeSummaries={attributeSummaries}
                        configurationAttribute={configurationAttribute}
                        configurationSize={configurationSize}
                        editAttribute={editAttribute}
                        deleteAttribute={deleteAttribute}
                        selectCategory={selectCategory}
                        selectAttribute={selectAttribute}
                        takeAttribute={takeAttribute}
                        cancelAttribute={cancelAttribute}
                        addAttributeMasspoint={addAttributeMasspoint}
                        selectAttributeMasspoint={selectAttributeMasspoint}
                        updateAttributeValueFrom={updateAttributeValueFrom}
                        updateAttributeValueTo={updateAttributeValueTo}
                        deleteAttributeMasspoint={deleteAttributeMasspoint}
                    />
                </Grid>
            </Grid>
        </>
    );
};