import styled from "@emotion/styled";

export const InfoText = styled.div`
  font-size: 0.8rem;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ButtonMargin = styled.span`
  margin-right: 10px;
`

export const FormRow = styled.div`
  margin-bottom: 30px;
`;
export const FormCheckboxRow = styled.div`
  margin-bottom: 20px;
`;

export const CustomTitle = styled.div`
  margin-top: 25px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
`;
