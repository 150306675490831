import * as style from "./massmask_info.style";
import { useAppSelector } from "app/hook";

export const MassmaskInfoComponent = () => {
    const info = useAppSelector((state) => state.simulation.baseData)
    const definition = useAppSelector((state) => state.simulation.simulation.massMaskDefinition)

    const mainProductline = info.availableMainProductLines.find(x => x.erpId === definition.mainProductLineErpId);
    const bodyArea = info.availableBodyAreas.find(x => x.erpId === definition.bodyAreaErpId);
    const articleType = info.availableArticleTypes.find(x => x.erpId === definition.articleTypeErpId);
     return <style.HierarchyInfoWrapper>
        <style.HierarchyList>
            {mainProductline.name}
            {" "}/{" "}{bodyArea.name}
            {" "}/{" "}{articleType.erpId}{" "}{articleType.name}{" "}
        </style.HierarchyList>
    </style.HierarchyInfoWrapper>
}