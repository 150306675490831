import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'app/redux_store';
import BaseDataService from 'services/base_data.service';
import CountryService from 'services/country.service';
import { FootOptionService } from 'services/footoption.service';
import { OneLegService } from 'services/oneleg.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';
import ArticleAttributeCategoryService from 'services/addition_attribute_category.service';
import SimulatorService from 'services/simulator.service';
import SimulatorCommonService from 'services/simulator.common.service';
import { OfferWithoutMasspointsRequest } from 'models/simulator/offer_without_masspoints_request';
import { ApiResult } from 'shared/networking/api_result';
import { ProductConfigurationResponse } from 'models/simulator/product_configuration_evaluation_response';
import { MassMaskAttributesThunk } from './simulator_simulation.model';
import { SaveCustomDesignRequest } from 'models/simulator/save_custom_design_request';
import { getCategoryAttributeRequests } from './simulator_simulation.reducers';
import { UpdateCustomDesignRequest } from 'models/simulator/update_custom_design.request';
import { GetProductConfigurationRunDetailsPayload } from 'simulator/product_configurations/overview/redux/product_configurations_overview.model';

export const initializeMassMaskSimulation = createAsyncThunk(
    'simulation/initializeMassMaskSimulation',
    async (_, thunkApi) => {
        await thunkApi.dispatch(fetchBaseData());
        await Promise.all([
            thunkApi.dispatch(getQualities()),
            thunkApi.dispatch(getArticleAttributeCategories()),
            thunkApi.dispatch(getAvailableFootTypes()),
            thunkApi.dispatch(checkIsOneLeg()),
            thunkApi.dispatch(getCountries())
        ]);
    }
)

export const initializeProductConfigurationSimulation = createAsyncThunk(
    'simulation/initializeProductConfigurationSimulation',
    async (productConfigurationDefinition: GetProductConfigurationRunDetailsPayload, thunkApi) => {
        await thunkApi.dispatch(fetchBaseData());
        await Promise.all([
            thunkApi.dispatch(getQualities()),
            thunkApi.dispatch(getArticleAttributeCategories()),
            thunkApi.dispatch(getAvailableFootTypes()),
            thunkApi.dispatch(checkIsOneLeg()),
            thunkApi.dispatch(getCountries()),
            thunkApi.dispatch(getProductConfigurationRunDetails(productConfigurationDefinition))
        ]);
    }
)

export const getProductConfigurationRunDetails = createAsyncThunk(
    'simulator/initializeProductConfigurationDetails',
    async ({ productConfigurationId, productConfigurationRunId }: GetProductConfigurationRunDetailsPayload, _) => {
        const response = await SimulatorService.getProductConfigurationRunDetails(productConfigurationId, productConfigurationRunId);
        handleResponseErrors(response, "Details");
        return response;
    }
)

export const fetchBaseData = createAsyncThunk(
    'simulation/getBaseData',
    async (_) => {
        const response = await BaseDataService.getBaseData();
        handleResponseErrors(response, "Basisdaten");
        return response;
    }
)

export const getQualities = createAsyncThunk(
    'simulation/qualities',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const mainProductLine = state.simulation.baseData.availableMainProductLines.find(x => x.erpId === state.simulation.simulation.massMaskDefinition.mainProductLineErpId);
        const bodyArea = state.simulation.baseData.availableBodyAreas.find(x => x.erpId === state.simulation.simulation.massMaskDefinition.bodyAreaErpId);
        const articleType = state.simulation.baseData.availableArticleTypes.find(x => x.erpId === state.simulation.simulation.massMaskDefinition.articleTypeErpId);
        const response = await BaseDataService.getMatchingQualities(
            mainProductLine.id,
            bodyArea.id,
            articleType.id
        );
        handleResponseErrors(response, "Qualität");
        return response;
    }
)

export const getAvailableFootTypes = createAsyncThunk(
    'simulation/availablefoottypes',
    async (_) => {
        const response = await FootOptionService.getAvailableFootTypes();
        handleResponseErrors(response, "Fußtypen");
        return response;
    }
)

export const checkIsOneLeg = createAsyncThunk(
    'simulation/checkIsOneleg',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const response = await OneLegService.isOneLeg(state.simulation.simulation.massMaskDefinition.mainProductLineErpId,
            state.simulation.simulation.massMaskDefinition.articleTypeErpId);
        handleResponseErrors(response, "Einbeinhosen");
        return response;
    }
);

export const getArticleAttributeCategories = createAsyncThunk(
    "simulation/getArticleAttributeCategories",
    async (_) => {
        const response = await ArticleAttributeCategoryService.getAvailableAttributeCategories();
        handleResponseErrors(response, "Attribute");
        return response;
    }
)

export const getCountries = createAsyncThunk(
    "simulation/getCountries",
    async (_) => {
        const response = await CountryService.getCountries();
        handleResponseErrors(response, "Länder");
        return response;
    }
)

export const createCustomDesignOrder = createAsyncThunk<ApiResult<ProductConfigurationResponse>, MassMaskAttributesThunk>(
    "simulation/customDesignOrder",
    async (params, { getState }) => {
        const { articleTypeErpId, bodyAreaErpId, mainProductLineErpId } = params;
        const state = getState() as RootState;
        const simulationState = state.simulation.simulation;
        const saveCustomDesignRequest: SaveCustomDesignRequest = {
            articleTypeErpId: articleTypeErpId,
            bodyAreaErpId: bodyAreaErpId,
            mainProductLineErpId: mainProductLineErpId,
            useUnpublishedSettings: simulationState.includeUnpublished,
            countryCode: simulationState.selectedCountry.countryCode,
            qualityErpId: simulationState.selectedQuality.erpId,
            evaluationSide: simulationState.selectedSide,
            system: "Simulator",
            footType: simulationState.selectedFootoption?.externalIdentifier,
            categoryAttributeRequests: getCategoryAttributeRequests(simulationState.additionCategoryAttributes)
        };
        const response = await SimulatorService.postCustomDesignOrder(saveCustomDesignRequest);
        handleResponseErrors(response, "Bestellung Sonderanfertigung");
        return response;
    }
)

export const createCustomDesignOffer = createAsyncThunk<ApiResult<ProductConfigurationResponse>, MassMaskAttributesThunk>(
    "simulation/customDesignOffer",
    async (params, { getState }) => {
        const { articleTypeErpId, bodyAreaErpId, mainProductLineErpId } = params;
        const state = getState() as RootState;
        const simulationState = state.simulation.simulation;
        const saveCustomDesignRequest: SaveCustomDesignRequest = {
            articleTypeErpId: articleTypeErpId,
            bodyAreaErpId: bodyAreaErpId,
            mainProductLineErpId: mainProductLineErpId,
            useUnpublishedSettings: simulationState.includeUnpublished,
            countryCode: simulationState.selectedCountry.countryCode,
            qualityErpId: simulationState.selectedQuality.erpId,
            evaluationSide: simulationState.selectedSide,
            system: "Simulator",
            footType: simulationState.selectedFootoption?.externalIdentifier,
            categoryAttributeRequests: getCategoryAttributeRequests(simulationState.additionCategoryAttributes)
        };

        const response = await SimulatorService.postCustomDesignOffer(saveCustomDesignRequest);
        handleResponseErrors(response, "Angebot Sonderanfertigung");
        return response;
    }
)

export const createOfferWithoutMasspoint = createAsyncThunk<ApiResult<ProductConfigurationResponse>, MassMaskAttributesThunk>(
    "simulation/offerWithoutMasspoint",
    async (params, { getState }) => {
        const { articleTypeErpId, bodyAreaErpId, mainProductLineErpId } = params;
        const state = getState() as RootState;
        const simulationState = state.simulation.simulation;
        const offerWithouMasspointRequest: OfferWithoutMasspointsRequest = {
            articleTypeErpId: articleTypeErpId,
            bodyAreaErpId: bodyAreaErpId,
            mainProductLineErpId: mainProductLineErpId,
            includeUnpublished: simulationState.includeUnpublished,
            isEllipticalShapeKneeFunctionZone: false,
            isPantyTopWithStandardDimensions: false,
            qualityErpId: simulationState.selectedQuality.erpId,
            selectedSide: simulationState.selectedSide,
            system: "Simulator",
            footType: simulationState.selectedFootoption?.externalIdentifier
        };

        const response = await SimulatorService.postOfferWithoutMasspoint(offerWithouMasspointRequest);
        handleResponseErrors(response, "Angebot ohne Maßpunkte");
        return response;
    }
)

export const massMaskValidation = createAsyncThunk(
    "simulation/massMaskValidation",
    async (productConfigurationId: string, { }) => {
        const response = await SimulatorCommonService.getMassMaskValidationState(productConfigurationId);
        handleResponseErrors(response, "Prüfen der Maßmaskenaktualität");
        return response;
    }
)

export const additionsValidation = createAsyncThunk(
    "simulation/additionsValidation",
    async (productConfigurationId: string, { }) => {
        const response = await SimulatorCommonService.getAdditionsValidationState(productConfigurationId);
        handleResponseErrors(response, "Prüfen der Zusätzeaktualität");
        return response;
    }
)

export const productionInstructionsValidation = createAsyncThunk(
    "simulation/productionInstructionsValidation",
    async (productConfigurationId: string, { }) => {
        const response = await SimulatorCommonService.getProductionInstructionsValidationState(productConfigurationId);
        handleResponseErrors(response, "Prüfen der Produktionsanweisungsaktualität");
        return response;
    }
)

export const changeSimulationTypeToMass = createAsyncThunk<ApiResult<ProductConfigurationResponse>, MassMaskAttributesThunk>(
    "simulation/changeSimulationTypeToMass",
    async (params, { getState }) => {
        const { articleTypeErpId, bodyAreaErpId, mainProductLineErpId } = params;
        const state = getState() as RootState;
        const simulationState = state.simulation.simulation;
        let queryParameter = "?";
        queryParameter += `productConfigurationId=${simulationState.productConfigurationId}`;
        queryParameter += `&configurationRunId=${simulationState.configurationRunId}`;
        queryParameter += `&mainProductLineErpId=${mainProductLineErpId}`;
        queryParameter += `&bodyAreaErpId=${bodyAreaErpId}`;
        queryParameter += `&articleTypeErpId=${articleTypeErpId}`;
        queryParameter += `&qualityErpIds=${simulationState.selectedQuality.erpId}`;
        queryParameter += `&evaluationSide=${simulationState.selectedSide}`;
        if (simulationState.selectedFootoption?.externalIdentifier != null) {
            queryParameter += `&footType=${simulationState.selectedFootoption?.externalIdentifier}`;
        }
        queryParameter += `&useUnpublished=${simulationState.includeUnpublished}`;

        const response = await SimulatorService.putSimulationTypeToMass(queryParameter);
        handleResponseErrors(response, "Wechsel auf Maßerfassung");
        return response;
    }
)

export const updateCustomDesign = createAsyncThunk<ApiResult<ProductConfigurationResponse>, MassMaskAttributesThunk>(
    "simulation/updateCustomDesign",
    async (params, { getState }) => {
        const { articleTypeErpId, bodyAreaErpId, mainProductLineErpId } = params;
        const state = getState() as RootState;
        const simulationState = state.simulation.simulation;
        const updateCustomDesignRequest: UpdateCustomDesignRequest = {
            articleTypeErpId: articleTypeErpId,
            bodyAreaErpId: bodyAreaErpId,
            mainProductLineErpId: mainProductLineErpId,
            useUnpublishedSettings: simulationState.includeUnpublished,
            countryCode: simulationState.selectedCountry.countryCode,
            qualityErpId: simulationState.selectedQuality.erpId,
            evaluationSide: simulationState.selectedSide,
            system: "Simulator",
            footType: simulationState.selectedFootoption?.externalIdentifier,
            categoryAttributeRequests: getCategoryAttributeRequests(simulationState.additionCategoryAttributes),
            productConfigurationId: simulationState.productConfigurationId,
            configurationRunId: simulationState.configurationRunId
        };
        const processType = simulationState.processType;
        const response = await SimulatorService.putCustomDesign(updateCustomDesignRequest, processType);
        handleResponseErrors(response, "Wechsel auf Maßerfassung");
        return response;
    }
)