import { GridFilterItem, GridColDef, getGridBooleanOperators, GridSortModel } from "@mui/x-data-grid-pro"
import IMassimageOverview from "models/massimages/massimage_overview"
import { MimeTypeConstantsEnum } from "models/mime_type_constants.enum"
import { CustomBooleanCell } from "shared/components/datagrid/custom_boolean_cell.component"
import { CustomFilterOperators } from "shared/components/datagrid/custom_filter_operators"
import { DatagridMenu } from "shared/components/datagrid/datagrid_menu.component"
import * as style from "./massimage_overview.style";

export const filterMassimageValue = (filterItem: GridFilterItem, filteredItems: IMassimageOverview[]) => {
    if(filterItem.value)
    {
        switch(filterItem.field)
        {
            case 'name':
                filteredItems = filteredItems.filter(x => x.name.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'hasConfigurationSetting':
                filteredItems = filteredItems.filter(x => filterItem.value === 'any' ? true: x.hasConfigurationSetting.toString() === filterItem.value);
                break;                                                                        
        }
    }
    return filteredItems;
}

const CustomThumbnailCell = (massimage: IMassimageOverview) => {
    return <style.Thumbnail src={`data:${MimeTypeConstantsEnum.Svg};base64, ${massimage.content}`} alt={massimage.name} />

}

export const getGridColumns = (
    replaceMassimage: (massimage: IMassimageOverview) => void,
    deleteMassimage: (massimage: IMassimageOverview) => void,
    uploadConfigFile: (massimage: IMassimageOverview) => void,
    ): GridColDef[] => [
    {
        field: "menu",
        headerName: " ",
        width: 15,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        renderCell: params => DatagridMenu({params: params, menuItemsData: 
            [
                {label: "Maßbild ersetzen", action: replaceMassimage},
                {label: "Maßbild löschen", action: deleteMassimage},
                {label: "Neues Konfigfile hochladen", action: uploadConfigFile}
            ]})
    },
    {
        field: "thumbnail",
        headerName: "Thumbnail",
        disableColumnMenu: true,
        flex: 0.25,
        sortable: false,
        hideable: false,
        pinnable: false,
        renderCell: (params) => CustomThumbnailCell(params.row)
    },
    {
        field: "name",
        headerName: "Name des Bildes",
        disableColumnMenu: false,
        flex: 0.5,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    },
    {
        field: "hasConfigurationSetting",
        headerName: "Konfigfile hochgeladen",
        disableColumnMenu: false,
        flex: 0.25,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: getGridBooleanOperators(),
        renderCell: (params) => CustomBooleanCell({value: params.row.hasConfigurationSetting})
    }
]    

export const sortByNameModel: GridSortModel = [
    {
        field: "name",
        sort: "asc",
    },
];