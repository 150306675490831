import { TabPanel, TabPanelProps } from "@mui/lab";
import { Box, Tab, TabProps, Tabs, TabsProps } from "@mui/material";
import colors from "shared/style/colors";
import styled from "@emotion/styled";

export const BoxItem = styled(Box)({
    '&.MuiBox-root': {
        borderBottom: '2px solid #e6007e',
        margin: 0,
        marginTop: '20px',
        paddingBottom: '20px',
        boxShadow: 'none'
    },
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    }
})

export const TabListItem = (props: TabsProps) => {
    const { sx, ...other } = props;
    return (
        <Tabs
            sx={{
                '& .MuiTabs-indicator': {
                    height: 0,
                },
                ...sx,
            }}
            {...other}
        />
    );
}

export const TabItem = (props: TabProps) => {
    const { sx, ...other } = props;
    return (
        <Tab
            sx={{
                minWidth: '200px',
                '&.MuiTab-root': {
                    borderWidth: 0,
                    borderBottom: `2px solid ${colors.mediMagenta}`,
                },
                '&.Mui-selected': {
                    border: `2px solid ${colors.mediMagenta}`,
                    borderBottomWidth: 0
                },
                ...sx,
            }}
            {...other}
        />
    );
}

export const TabPanelItem = (props: TabPanelProps) => {
    const { sx, ...other } = props;
    return (
        <TabPanel
            sx={{
                ...sx,
            }}
            {...other}
        />
    );
}

export const TabItemSpacer = styled('div')({
    'width': '100%',
    'borderBottom': `2px solid ${colors.mediMagenta}`
})
