import { useAppSelector } from '../../../app/hook';
import * as style from "./masspoint_info.style";

export const MassPointInfoComponent = () => {
    const info = useAppSelector((state) => state.masspoint_edit_references.actualData.massPointInfo)

    return <style.HierarchyInfoWrapper>
        <style.HierarchyList>
            {info ? info.name : ""}
            {" "}/{" "}{info ? info.type : ""}
            {" "}/{" "}{info ? info.bodyArea : ""}
            {" "}/{" "}{info ? info.side : ""}
        </style.HierarchyList>
    </style.HierarchyInfoWrapper>
}