import { FormulaTemplateEnumValues } from "models/plausibility_check/enums/formula_template.enum";
import { filterPlausibilityCheckTypeValue } from "../type_overview.helper";
import { TypeOverviewState } from "./type_overview.model";

export const filterPlausibilityCheckTypes = (state: TypeOverviewState) => {
    const searchTextLower = state.actualData.searchFilter.toLocaleLowerCase().trim();
    let filteredItems = state.loadedData.plausibilityCheckTypes.filter(
        (plausibilityCheckType) =>{
                const formulaTemplate = FormulaTemplateEnumValues.find(bodySide => bodySide.value === plausibilityCheckType.formulaTemplate);
            return (
                plausibilityCheckType.name.toLowerCase().includes(searchTextLower) ||
                formulaTemplate.text.toLowerCase().includes(searchTextLower) ||
                plausibilityCheckType.warningMessageKey?.toLowerCase().includes(searchTextLower) ||
                plausibilityCheckType.errorMessageKey?.toLowerCase().includes(searchTextLower) ||
                (plausibilityCheckType.isUniqueRules ? "eindeutig" : "uneindeutig").includes(searchTextLower));
        }
    );

    state.actualData.gridFilters.items.forEach(fil => {
        filteredItems = filterPlausibilityCheckTypeValue(fil, filteredItems);
    });
    
    state.actualData.plausibilityCheckTypes = filteredItems;

}