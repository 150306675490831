import { Grid, IconButton } from "@mui/material";
import { EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";
import { ISelectCompressionClass, IUpdateThreshold, ISoftSeamConfigurationViewModel, ISearchQuality, ISelectQualities } from "./policy_soft_seam.model";
import DeleteIcon from '@mui/icons-material/Delete';
import { DropdownComponent } from "shared/shared";
import { SelectionListComponent } from "shared/components/selectionComponent/selectionList.component";
import { DecimalInputComponent } from "shared/components/input/numberInput/decimal_input.component";
import { BoxItem } from "check/policy/components/policy_box_item.style";

interface PolicySoftSeamConfiguration {
    configuration: ISoftSeamConfigurationViewModel;
    deleteConfiguration: (index: number) => {};
    selectCompressionClass: (selectClass: ISelectCompressionClass) => {};
    filterSelectedQualities: (qualities : ISearchQuality ) => {};
    filterUnselectedQualities: (qualities : ISearchQuality ) => {};
    selectQualities: (selectedQualities: ISelectQualities) => {};
    unSelectQualities: (selectedQualities: ISelectQualities) => {};
    updateThreshold: (textInput: IUpdateThreshold) => {};
}

export const PolicySoftSeamConfigurationComponent: React.FC<PolicySoftSeamConfiguration> = ({ 
    configuration,
    deleteConfiguration,
    selectCompressionClass,
    selectQualities,
    unSelectQualities,
    filterSelectedQualities,
    filterUnselectedQualities,
    updateThreshold
}) => {
    const header = "Konfiguration " + configuration.index;
    return (
    <BoxItem>
        <Grid item container md={12}
            direction="column"
            spacing={3}>
            <Grid item
                container
                direction="row"            
                alignItems="stretch"
                justifyContent="space-between"
                spacing={3}>
                <Grid item
                    md={9}>
                    <EditorSectionHeaderComponent style={{ marginTop: "0px" }}>{header}</EditorSectionHeaderComponent>  
                </Grid>
                <Grid item
                    container
                    md={3}
                    justifyContent='flex-end'>
                    <IconButton
                        style={{ outline: "none" }}
                        onClick={() =>deleteConfiguration(configuration.index)}>
                        <DeleteIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <DropdownComponent name="Kompressionsklasse"
                        data={configuration.compressionClasses}
                        isRequired={true}
                        onSelect={(e) => selectCompressionClass({ index: configuration.index, item: e})}
                        displayedProperties={["displayName"]}
                        selectedValue={configuration.selectedCompressionClass}                           
                    />    
                    <DecimalInputComponent
                        name={"Schwellenwert"}
                        fixedPositions={1}
                        placeholder='0'
                        minValue={0}
                        maxValue={999.0}
                        isRequired={true}
                        onChange={(value) => updateThreshold({ index: configuration.index, threshold: value})}
                        value={configuration.threshold}
                    />    
                </Grid>
                <Grid item md={12}>
                    <SelectionListComponent
                        title={"Qualitäten"}
                        entityName={"Qualitäten"}
                        onFilterSelected={(searchText) => { filterSelectedQualities({index: configuration.index, searchText: searchText}) }}
                        onFilterUnselected={(searchText) => { filterUnselectedQualities({index: configuration.index, searchText: searchText}) }}
                        selectionList={configuration.qualities}
                        selectEntities={(ids) => { selectQualities({index: configuration.index, qualityIds: ids}) }}
                        unselectEntities={(ids) => { unSelectQualities({index: configuration.index, qualityIds: ids}) }}
                    />                       
                </Grid>
            </Grid>
        </Grid>
    </BoxItem>
)}