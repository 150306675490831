import { createAsyncThunk } from "@reduxjs/toolkit";
import { PolicyTypeEnum } from "models/policies/policy_type.enum";
import AdditionService from "services/addition.service";
import BaseDataService from "services/base_data.service";
import MassPointService from "services/masspoint.service";
import { handleResponseErrors } from "shared/networking/error_handling";
import PolicyServiceAPI from 'services/policy.service';
import { RootState } from "app/redux_store";

export const initData = createAsyncThunk(
    'policy/edit_policy_adjust_maximum_range/initData',
    async (id: string, { dispatch }) => {
        await Promise.all([
            dispatch(getAdditions(id)),
            dispatch(getMassPoints()),
            dispatch(getBaseData()),
        ]);
        await dispatch(getPolicy(id));
    }
)

export const getPolicy = createAsyncThunk(
    'policy/edit_policy_adjust_maximum_range/getPolicy',
    async (id: string) => {
        const response = await PolicyServiceAPI.getAdjustMaximumRange(id);
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const getAdditions = createAsyncThunk(
    'policy/edit_policy_adjust_maximum_range/getAdditions',
    async (id: string) => {
        const response = await AdditionService.getAvailableAdditionsByPolicyTypeOverview(PolicyTypeEnum.AdjustMaximumRange, id);
        handleResponseErrors(response, "Zusätze");
        return response;
    }
)

export const getMassPoints = createAsyncThunk(
    'policy/edit_policy_adjust_maximum_range/getMassPoints',
    async (_) => {
        const response = await MassPointService.getMassPoints()
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)

export const getBaseData = createAsyncThunk(
    'policy/edit_policy_adjust_maximum_range/getBaseData',
    async (_) => {
        const response = await BaseDataService.getBaseData()
        handleResponseErrors(response, "Basisdaten");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'policy/edit_policy_adjust_maximum_range/cancelSave',
    async (_) => { }
)

export const updatePolicy = createAsyncThunk(
    'policy/edit_policy_adjust_maximum_range/savepolicy',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.policy_adjust_maximum_range_edit.data;
        const response = await PolicyServiceAPI.updateAdjustMaximumRange({
            policyId: data.policy.id,
            name: data.policy.name,
            ignoreOnQuotation: data.policy.ignoreOnQuotation,
            additionId: data.selectedAddition.id,
            configs: data.configurations.map(config =>
            ({
                multiplyValue: config.multiplyValue,
                massPointOne: {
                    name: config.selectedMasspoint1.name,
                    type: config.selectedMasspoint1.type,
                    bodyAreaId: config.selectedMasspoint1.bodyAreaId
                },
                massPointTwo: {
                    name: config.selectedMasspoint2.name,
                    type: config.selectedMasspoint2.type,
                    bodyAreaId: config.selectedMasspoint2.bodyAreaId
                },
                articleTypes: config.articleTypes.selectedList.allItems.map(articleType => articleType.id),
            }))
        });
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)
