import { FormControlLabel, Grid, IconButton, Switch } from '@mui/material';
import { DropdownComponent } from 'shared/components/form_controls/dropdown/dropdown.component';
import { EditorSectionHeaderComponent } from 'shared/components/editors/editor_header/editor_header.style';
import { EditorDescriptionComponent } from 'shared/components/editors/editor_description/editor_description.style';
import { PlaceHolderTextInputComponent } from './addition_placeholder_textinput.component';
import { PlaceHolderRangeComponent } from './addition_placeholder_rangeinput.component';
import { PlaceHolderTwoRangeComponent } from './addition_placeholder_tworangeinput.component';
import { AdditionPlaceHolderTypeEnum } from 'models/additions/addition_placeholder.enum';
import { ListButtonComponent } from 'shared/components/buttons/list_button.component';
import AddIcon from "@mui/icons-material/Add";
import { Delete } from '@mui/icons-material';
import { IAdditionEditor, IAdditionEditorAction, IAdditionInitial } from 'models/additions/addition_editor.model';

interface PlaceHolderComponentProps {
    additionInitial: IAdditionInitial,
    additionEditor: IAdditionEditor,
    additionEditorActions: IAdditionEditorAction
}

export const PlaceHolderComponent: React.FC<PlaceHolderComponentProps> = ({
    additionInitial,
    additionEditor,
    additionEditorActions,
}) => {

    const placeHolderTypes = additionInitial.placeholderTypes;
    const selectedPlaceHolder = additionEditor.addition.placeholder.placeholderTemplate;
    const twoRangePlaceholderIsSideSpecific = additionEditor.addition.placeholder.twoRangePlaceholderIsSideSpecific;
    const isInteger = additionEditor.addition.placeholder.isInteger;
    const selectedPlaceHolderAction = additionEditorActions.updatePlaceHolderType;
    const addPlaceholderQualityConfig = additionEditorActions.addPlaceholderQualityConfig;
    const deletePlaceholderQualityConfig = additionEditorActions.deletePlaceholderQualityConfig;
    const selectPlaceholderConfigQuality = additionEditorActions.selectPlaceholderConfigQuality;
    const toggleTwoRangePlaceholderIsSideSpecific = additionEditorActions.toggleTwoRangePlaceholderIsSideSpecific;
    const toggleIsInteger = additionEditorActions.toggleIsInteger;

    const inputComponent = (placeholderQualityConfigIndex?: number) => {
        switch (selectedPlaceHolder.value) {
            case AdditionPlaceHolderTypeEnum.TextInput:
                return <PlaceHolderTextInputComponent placeholderQualityConfigIndex={placeholderQualityConfigIndex} additionEditor={additionEditor}
                    additionEditorActions={additionEditorActions} />
            case AdditionPlaceHolderTypeEnum.RangeInput:
                return <PlaceHolderRangeComponent placeholderQualityConfigIndex={placeholderQualityConfigIndex} additionEditor={additionEditor}
                    additionEditorActions={additionEditorActions} />
            case AdditionPlaceHolderTypeEnum.TwoRangeInput:
                return <PlaceHolderTwoRangeComponent placeholderQualityConfigIndex={placeholderQualityConfigIndex} additionEditor={additionEditor}
                    additionEditorActions={additionEditorActions} />
            case AdditionPlaceHolderTypeEnum.None:
                return <></>
        }
    };

    const showConfigs = selectedPlaceHolder.value !== AdditionPlaceHolderTypeEnum.None;

    return <Grid spacing={3}
        direction="column"
        container
        justifyContent="space-between">
        <Grid item md={6}>
            <EditorSectionHeaderComponent>Werte</EditorSectionHeaderComponent>
            <EditorDescriptionComponent>Der Platzhalter für die Werte erscheint im Simulator und im e-shop hinter
                dem Bezeichnungstext.</EditorDescriptionComponent>
        </Grid>

        <Grid item
            container
            direction="row"
            alignItems="center"
            md={12}>
            <Grid item marginRight={"24px"}>
                <DropdownComponent
                    name="Platzhalterart"
                    data={placeHolderTypes}
                    isLoading={false}
                    isRequired={true}
                    onSelect={selectedPlaceHolderAction}
                    displayedProperties={["text"]}
                    selectedValue={selectedPlaceHolder}
                />
            </Grid>
            {
                AdditionPlaceHolderTypeEnum.TwoRangeInput === selectedPlaceHolder.value &&
                <Grid item marginRight={"24px"}>
                    <FormControlLabel
                        control={<Switch name="IsSideSpecificSwitch" />}
                        label="Seitenabhängig"
                        checked={twoRangePlaceholderIsSideSpecific}
                        onChange={(event, value) => { toggleTwoRangePlaceholderIsSideSpecific() }}
                        labelPlacement="end"
                    />
                </Grid>
            }
            {
                (AdditionPlaceHolderTypeEnum.TwoRangeInput === selectedPlaceHolder.value || AdditionPlaceHolderTypeEnum.RangeInput === selectedPlaceHolder.value) &&
                <Grid item>
                    <FormControlLabel
                        control={<Switch name="IsInteger" />}
                        label="Ganzzahl"
                        checked={isInteger}
                        onChange={(event, value) => { toggleIsInteger() }}
                        labelPlacement="end"
                    />
                </Grid>
            }
        </Grid>
        {
            showConfigs && <>
                <Grid item
                    container
                    direction="row"
                    alignItems="stretch"
                    justifyContent="space-between">
                    <Grid item
                        md={12}>
                        <EditorSectionHeaderComponent style={{ marginTop: "0px" }}>Qualitätsunabhängig</EditorSectionHeaderComponent>
                    </Grid>
                </Grid>
                <Grid item
                    container
                    direction="row">
                    <Grid item md={9}>
                        {inputComponent()}
                    </Grid>
                </Grid>
                <Grid item
                    container
                    direction="row">
                    <Grid spacing={3}
                        direction="column"
                        container
                        justifyContent="space-between">
                        <Grid item
                            container
                            direction="row"
                            alignItems="stretch"
                            justifyContent="space-between">
                            <Grid item
                                md={12}>
                                <EditorSectionHeaderComponent style={{ marginTop: "0px" }}>Qualitätsabhängig</EditorSectionHeaderComponent>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            container
                            justifyContent="space-between"
                            direction="column"
                            spacing={1}
                        >
                            {
                                additionEditor.addition.placeholder.qualityConfigs.map((placeholderQualityConfig, index) =>
                                    <>
                                        <Grid item
                                            container
                                            direction="row">
                                            <Grid
                                                item
                                                direction="row"
                                                container style={{ backgroundColor: "white", padding: "14px" }}>
                                                <Grid item md={3}>
                                                    <DropdownComponent
                                                        name="Qualität"
                                                        data={placeholderQualityConfig.selectableQualites ?? []}
                                                        isLoading={false}
                                                        isRequired={true}
                                                        onSelect={(selectedQuality) => selectPlaceholderConfigQuality({ index: index, quality: selectedQuality })}
                                                        displayedProperties={["erpId", "name"]}
                                                        selectedValue={placeholderQualityConfig.quality}
                                                    />
                                                </Grid>
                                                <Grid item md={8}>
                                                    {inputComponent(index)}
                                                </Grid>
                                                <Grid item
                                                    container
                                                    md={1}
                                                    justifyContent='flex-end'>
                                                    <IconButton
                                                        style={{ outline: "none" }}
                                                        onClick={() => deletePlaceholderQualityConfig(index)}>
                                                        <Delete />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )
                            }
                        </Grid>
                        <Grid item
                            container
                            direction="row">
                            <ListButtonComponent
                                onClick={addPlaceholderQualityConfig}>
                                <AddIcon /> Qualitätsabhängige Platzhalterdefinition hinzufügen
                            </ListButtonComponent>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        }
    </Grid >;
};