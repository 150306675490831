import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BodyInformationEditState } from "./bodyinformation_edit.model";
import { BodyInformationType, BodyInformationTypeEnumValues } from "massmasks/bodyinformation/components/bodyinformation_editor.model";
import { EnumDescriptor } from "models/enum_descriptor";
import { cancelSave, fetchBodyInformation, updateBodyInformation } from "./bodyinformation_edit.thunks";
import { updateCanExecuteEdit } from "./bodyinformation_edit.reducer";
import { Satellite } from "@mui/icons-material";

const initialState: BodyInformationEditState = {
    actualData: {
        id: 0,
        editor: {
            translationKey: "",
            type: BodyInformationTypeEnumValues.find(x => x.value === 1),
            isMandatory: true,
            freeTextLength: 0,
            entriesKeys: []
        }
    },
    query: {

    },
    command: {
        getBodyInformation: { status: "idle", canExecute: true },
        updateBodyInformation: { status: "idle", canExecute: false },
        cancel: { status: "idle", canExecute: false },
    }
}

export const bodyInformationEditSlice = createSlice({
    name: 'massMask/bodyInformations/edit',
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        resetUpdateBodyInformation: (state) => {
            state.command.updateBodyInformation = {
                ...initialState.command.updateBodyInformation,
                canExecute: false
            };
            updateCanExecuteEdit(state)
        },
        updateCategoryName: (state, action: PayloadAction<string>) => {
            state.actualData.editor.translationKey = action.payload;
            updateCanExecuteEdit(state);
        },
        updateCategoryType: (state, action: PayloadAction<EnumDescriptor<BodyInformationType>>) => {
            state.actualData.editor.type = action.payload;
            updateCanExecuteEdit(state);
        },
        updateIsMandatory: (state, action: PayloadAction<boolean>) => {
            state.actualData.editor.isMandatory = action.payload;
            updateCanExecuteEdit(state);
        },
        updateFreeTextLength: (state, action: PayloadAction<number>) => {
            state.actualData.editor.freeTextLength = action.payload;
            updateCanExecuteEdit(state);
        },
        addTranslationKey: (state) => {
            state.actualData.editor.entriesKeys.push({ translationKey: "", shortKey: "" });
            updateCanExecuteEdit(state);
        },
        removeTranslationKey: (state, action: PayloadAction<number>) => {
            const index = action.payload;
            state.actualData.editor.entriesKeys.splice(index, 1);
            updateCanExecuteEdit(state);
        },
        updateTranslationKey: (state, action: PayloadAction<{ index: number, translationKey: string }>) => {
            const index = action.payload.index;
            const translationKey = action.payload.translationKey;
            state.actualData.editor.entriesKeys[index].translationKey = translationKey;
            updateCanExecuteEdit(state);
        },
        updateShortKey: (state, action: PayloadAction<{ index: number, shortKey: string }>) => {
            const index = action.payload.index;
            const shortKey = action.payload.shortKey;
            state.actualData.editor.entriesKeys[index].shortKey = shortKey;
            updateCanExecuteEdit(state);
        },
    }, extraReducers: (builder) => {
        // update Body Information
        builder.addCase(updateBodyInformation.pending, (state, action) => {
            state.command.updateBodyInformation.status = 'pending'
            state.command.updateBodyInformation.canExecute = false;
        }).addCase(updateBodyInformation.rejected, (state, action) => {
            state.command.updateBodyInformation.status = "error"
            state.command.updateBodyInformation.canExecute = true;
            state.command.updateBodyInformation.message = action.error.message;
        }).addCase(updateBodyInformation.fulfilled, (state, action) => {
            state.command.updateBodyInformation.status = "success"
            state.command.updateBodyInformation.canExecute = true;

            // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancel.status = 'pending'
            state.command.cancel.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancel.status = "success"
            state.command.cancel.canExecute = false;

            //getBodyInformation
        }).addCase(fetchBodyInformation.pending, (state, action) => {
            state.command.getBodyInformation.status = 'pending'
            state.command.getBodyInformation.canExecute = false;
        }).addCase(fetchBodyInformation.rejected, (state, action) => {
            state.command.getBodyInformation.status = "error"
            state.command.getBodyInformation.canExecute = true;
            state.command.getBodyInformation.message = action.error.message;
        }).addCase(fetchBodyInformation.fulfilled, (state, action) => {
            state.command.getBodyInformation.status = "success"
            state.command.getBodyInformation.canExecute = true;

            const bodyInformation = action.payload.getData();
            state.actualData.id = bodyInformation.id;
            state.actualData.editor.entriesKeys = bodyInformation.entries.map(x => {
                return {
                    shortKey: x.shortKey,
                    translationKey: x.translationKeyForName
                }
            });
            state.actualData.editor.translationKey = bodyInformation.translationKeyForName;
            state.actualData.editor.freeTextLength = bodyInformation.freeTextLength;
            state.actualData.editor.type = BodyInformationTypeEnumValues.find(x => x.value === bodyInformation.type);
            state.actualData.editor.isMandatory = bodyInformation.isMandatory;
        })
    }
})

export const {
    resetState,
    resetUpdateBodyInformation,
    updateCategoryName,
    updateCategoryType,
    updateIsMandatory,
    updateFreeTextLength,
    addTranslationKey,
    removeTranslationKey,
    updateShortKey,
    updateTranslationKey
} = bodyInformationEditSlice.actions

export default bodyInformationEditSlice.reducer