import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "app/redux_store";
import { handleResponseErrors } from "shared/networking/error_handling";
import CharacteristicTypeServiceAPI from '../../../../services/characteristic_type.service';
import CharacteristicValueServiceAPI from '../../../../services/characteristic_value.service';

export const saveCharacteristicType = createAsyncThunk(
    'characteristic_type/characteristic_type_create/saveCharacteristicType',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.characteristic_types_create.data;
        const response = await CharacteristicTypeServiceAPI.createCharacteristicTypes({
            name: data.characteristicType.name,
            shortKey: data.characteristicType.shortKey,
            values: data.characteristicType.values.map(x => {
                return {
                    name: x.name,
                    translationKey: x.translationKey
                }
            })
        });
        handleResponseErrors(response, "Ausprägungstyp");
        return response;
    }
)

export const getCharacteristicValueTranslation = createAsyncThunk(
    'characteristic_type/characteristic_type_create/getCharacteristicTypeTranslations',
    async (_) => {
        const response = await CharacteristicValueServiceAPI.getCharacteristicTypeTranslations();
        handleResponseErrors(response, "Übersetzungen");
        return response;
     }
)
 

export const cancelSave = createAsyncThunk(
    'characteristic_type/characteristic_type_create/cancelSave',
    async (_) => { }
)


export const initData = createAsyncThunk(
    'characteristic_type/characteristic_type_create/initData',
    async (_, thunkApi) => {
        await Promise.all([
            thunkApi.dispatch(getCharacteristicValueTranslation())
        ]);
     }
)