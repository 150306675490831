import { createAsyncThunk } from '@reduxjs/toolkit';
import { ICheckOverview } from 'models/plausibility_check/check_overview';
import { RootState } from '../../../app/redux_store';
import PlausibilityCheckServiceAPI from '../../../services/check.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';

export const fetchPlausibilityChecks = createAsyncThunk(
    'plausibilitychecktype/check_overview/fetchPlausibilityChecks',
    async (thunkApi) => {
        const response = await PlausibilityCheckServiceAPI.getChecks();
        handleResponseErrors(response, "Prüfung");
        return response;
    }
)

export const confirmDeletePlausibilityCheck = createAsyncThunk(
    'plausibilitychecktype/check_overview/confirmDeletePlausibilityCheck',
    async (_, { getState }) => {
        const state = getState() as RootState
        const plausibilityCheckId = state.plausibilitycheck_get.actualData.selectedCheck.id
        const responseDelete = await PlausibilityCheckServiceAPI.deleteCheck(plausibilityCheckId)
        handleResponseErrors(responseDelete, "Prüfung");

        const response = await PlausibilityCheckServiceAPI.getChecks();
        handleResponseErrors(response, "Prüfung");
        return response;
    }
)

export const togglePublishPlausibilityCheck = createAsyncThunk(
    'plausibilitychecktype/check_overview/togglePlausibilityCheck',
    async (plausibilityCheck: ICheckOverview, { dispatch }) => {
        if (plausibilityCheck.isPublished) {
            await dispatch(unpublishPlausibilityCheck(plausibilityCheck));
        } else {
            await dispatch(publishPlausibilityCheck(plausibilityCheck));
        }
    }
)

export const publishPlausibilityCheck = createAsyncThunk(
    'plausibilitychecktype/check_overview/publishPlausibilityCheck',
    async (plausibilityCheck: ICheckOverview, thunkApi) => {
        const responsePublish = await PlausibilityCheckServiceAPI.publishCheck(plausibilityCheck);
        handleResponseErrors(responsePublish, "Prüfung");
        const responseMassMasks = await PlausibilityCheckServiceAPI.getChecks();
        handleResponseErrors(responseMassMasks, "Prüfung");
        return responseMassMasks;
    }
)

export const unpublishPlausibilityCheck = createAsyncThunk(
    'plausibilitychecktype/check_overview/unpublishPlausibilityCheck',
    async (plausibilityCheck: ICheckOverview, thunkApi) => {
        const responseUnpublish = await PlausibilityCheckServiceAPI.unpublishCheck(plausibilityCheck);
        handleResponseErrors(responseUnpublish, "Prüfung");
        const responseMassMasks = await PlausibilityCheckServiceAPI.getChecks();
        handleResponseErrors(responseMassMasks, "Prüfung");
        return responseMassMasks;
    }
)
