import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAdditionPositionOverview } from "models/addition_position/addition_position_overview";
import { AdditionPositionOverviewState } from "./addition_position_overview.model";
import { filterAdditionPositions } from "./addition_position_overview.reducer";
import { confirmDeleteAdditionPosition, fetchAdditionPositions, navigateCreateAdditionPosition } from "./addition_position_overview.thunks";
import { GridFilterModel } from "@mui/x-data-grid-pro";

const initialState: AdditionPositionOverviewState = {
    loadedData: {
        additionPositions: []
    },
    actualData: {
        showDeleteDialog: false,
        showDeleteErrorDialog: false,
        selectedAdditionPosition: null,
        searchFilter: "",
        gridFilters: { items: [] },
        additionPositions: []
    },
    query: {
        getAdditionPositions: { status: "idle", canExecute: false },
    },
    command: {
        navigateCreateAdditionPosition: { status: "idle", canExecute: true },
        confirmDelete: { status: "idle", canExecute: false },
    }
}

export const additionPositionOverviewSlice = createSlice({
    name: 'additionPosition/overview',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
            state.actualData = initialState.actualData;
        },
        deleteAdditionPosition: (state, action: PayloadAction<IAdditionPositionOverview>) => {
            state.actualData.showDeleteDialog = true;
            state.actualData.selectedAdditionPosition = action.payload;
        },
        cancelDeleteAdditionPosition: (state) => {
            state.actualData.showDeleteDialog = false;
        },
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.actualData.searchFilter = action.payload;
            filterAdditionPositions(state);
        }, 
        setGridFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.gridFilters = action.payload;
            filterAdditionPositions(state);
        },
        confirmDeleteCompleted: (state) => {
            state.command.confirmDelete.status = "idle";
        },
        confirmDeleteError: (state) => {
            state.actualData.showDeleteErrorDialog = false;
            state.actualData.showDeleteDialog = false;
        },
    }, extraReducers: (builder) => {
        //fetchAdditionPositions
        builder.addCase(fetchAdditionPositions.pending, (state, action) => {
            state.query.getAdditionPositions.status = "pending"
            state.query.getAdditionPositions.canExecute = false;
        }).addCase(fetchAdditionPositions.rejected, (state, action) => {
            state.query.getAdditionPositions.status = "error"
            state.query.getAdditionPositions.message = action.error.message;
            state.query.getAdditionPositions.canExecute = true;
        }).addCase(fetchAdditionPositions.fulfilled, (state, action) => {
            state.query.getAdditionPositions.status = "success"
            state.query.getAdditionPositions.canExecute = true;
            const additionPositions = action.payload.getData();
            state.loadedData.additionPositions = additionPositions;
            state.actualData.additionPositions = additionPositions;

            //navigateCreateAdditionPosition
        }).addCase(navigateCreateAdditionPosition.pending, (state, action) => {
            state.command.navigateCreateAdditionPosition.status = "pending"
        }).addCase(navigateCreateAdditionPosition.fulfilled, (state, action) => {
            state.command.navigateCreateAdditionPosition.status = "success"
        
            // confirmDeleteAdditionPosition
        }).addCase(confirmDeleteAdditionPosition.pending, (state, action) => {
            state.command.confirmDelete.status = 'pending'
        }).addCase(confirmDeleteAdditionPosition.rejected, (state, action) => {
            state.command.confirmDelete.status = "error"
            state.command.confirmDelete.message = action.error.message;
            if (action.error.code === "409") {
                state.actualData.showDeleteErrorDialog = true;
            }
            state.actualData.showDeleteDialog = false;
        }).addCase(confirmDeleteAdditionPosition.fulfilled, (state, action) => {
            state.command.confirmDelete.status = "success"
            state.command.confirmDelete.canExecute = true;
            state.actualData.showDeleteDialog = false;

            const additionPositions = action.payload.getData();
            state.loadedData.additionPositions = additionPositions;
            state.actualData.additionPositions = additionPositions;
        })
    }
});


export const {
    resetState,
    deleteAdditionPosition,
    cancelDeleteAdditionPosition,
    setSearchFilter,
    setGridFilters,
    confirmDeleteCompleted,
    confirmDeleteError,
} = additionPositionOverviewSlice.actions

export default additionPositionOverviewSlice.reducer