import React from "react";
import { MassPointEditReferencesComponent } from './edit_code_references/masspoint_edit_references.component';
import { MassPointCreateComponent } from './masspoint_create/masspoint_create.component';
import { MassPointOverviewComponent } from './masspoint_overview/masspoint_overview.component';
import { Route, Routes } from "react-router-dom";
import { MassPointEditComponent } from "./masspoint_edit/masspoint_edit.component";
import { MassPointsOverviewComponent } from "./overview/overview.component";
import { SerialSizesOverviewComponent } from "./serial_sizes/serial_sizes_overview/serial_sizes_overview.component";
import { SerialSizesCreateComponent } from "./serial_sizes/serial_sizes_create/serial_sizes_create.component";
import { SerialSizesUpdateComponent } from "./serial_sizes/serial_sizes_edit/serial_sizes_edit.component";
import { SerialSizesPreviewComponent } from "./serial_sizes/serial_sizes_preview/serial_size_preview.component";
import { MassPointGroupOverviewComponent } from "./masspoint_groups/masspoint_group_overview/masspoint_group_overview.component";
import { MassPointGroupComponent } from "./masspoint_groups/masspoint_group/masspoint_group.component";

export const MasspointsComponent = () => {
    return (
        <Routes>
            <Route element={<MassPointsOverviewComponent />} path="/" />
            <Route element={<MassPointCreateComponent />} path="masspoint/create" />
            <Route element={<MassPointEditComponent />} path="masspoint/edit/:masspointId" />
            <Route element={<MassPointEditReferencesComponent />} path="masspoint/:masspointId/references" />
            <Route element={<MassPointOverviewComponent />} path="masspoint/overview" />
            <Route element={<SerialSizesOverviewComponent />} path="serialsize/overview" />
            <Route element={<SerialSizesCreateComponent />} path="serialsize/create" />
            <Route element={<SerialSizesUpdateComponent />} path="serialsize/edit/:iserialsizeId" />
            <Route element={<SerialSizesPreviewComponent />} path="serialsize/preview" />
            <Route element={<MassPointGroupOverviewComponent />} path="masspointgroup/overview" />
            <Route element={<MassPointGroupComponent />} path="masspointgroup/create" />
            <Route element={<MassPointGroupComponent />} path="masspointgroup/edit/:masspointGroupId" />
        </Routes>)
}