import { DialogComponent } from '../../../../shared/components/dialogs/dialog.component';
import { cancelDelete } from '../redux/characteristic_category_overview.slice';
import { AppDispatch } from '../../../../app/redux_store';
import { useAppDispatch, useAppSelector } from '../../../../app/hook';
import { confirmDelete } from '../redux/characteristic_category_overview.thunks';

export const CharacteristicCategoryDeleteDialogComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.characteristic_categories_overview);
    const characteristicCategory = state.actualData.characteristicCategory;
    return (<>
            <DialogComponent
                title={"Ausprägungskategorie löschen?"}
                isOpen={true}
                options={[
                    { title: "Abbrechen", handleOption: () => { dispatch(cancelDelete())}},
                    { title: "Löschen", handleOption: () => { dispatch(confirmDelete())}}
                ]}
                onClose={() => { }}>
                <div>Möchten Sie die Ausprägungskategorie {characteristicCategory.name} wirklich löschen?</div>
            </DialogComponent>
    </>);
}