import AddIcon from "@mui/icons-material/Add";
import CheckIcon from '@mui/icons-material/Check';
import { MassmaskStepperComponent, MassmaskWizardStepEnum } from "massmasks/components/massmask.stepper.component";
import { MassmaskInformationComponent } from "massmasks/components/massmask.information.component";
import React, { useEffect } from "react";
import { FlexBox, LoadingIndicatorComponent, PrimaryButtonComponent, Right } from "shared/shared";
import { MassDefinitionsTableComponent } from "./components/massdefinitions_table.component";
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { useSnackbar } from "notistack";
import { AppDispatch } from "app/redux_store";
import { confirmDeleteMassDefinition, getMassMask, finishMassDefinitions, assignMassPoints, addMassDefinition, updateMassDefinitionSortIndex } from './redux/massmask_massdefinition.thunks';
import { cancelDeleteMassDefinition, confirmDeleteMassDefinitionCompleted, deleteMassDefinition, resetState, updateMassDefinitionSortIndexCompleted } from "./redux/massmask_massdefinition.slice";
import { DialogComponent } from '../../shared/components/dialogs/dialog.component';
import * as style from "./massmask_massdefinition.style";
import { useNavigate, useParams } from "react-router-dom";
import { doesExist } from "services/validation.service";
import { IMassDefinitionSortIndex } from "models/massdefinitions/massDefinitionSortIndex";

export const MassmaskMassdefinitionComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const state = useAppSelector((state) => state.massmask_massdefinition);
    const { massmaskId } = useParams();

    const isLoading = 
        state.command.updateMassDefinitionSortIndex.status === "pending" || 
        state.query.getMassMask.status === "pending" || 
        state.query.getMassMask.status === "idle";
        
    const isDeleting = state.command.confirmDeleteMassDefinition.status === "pending";

    useEffect(() => {
        dispatch(getMassMask(Number(massmaskId)));
        return () => {
            dispatch(resetState());
        }
    }, [dispatch, massmaskId]);

    if (state.command.confirmDeleteMassDefinition.status === "error") {
        enqueueSnackbar(state.command.confirmDeleteMassDefinition.message, { variant: "error" });
        dispatch(resetState());
    }

    if (state.command.addMassDefinition.status === "pending") {
        navigate(`/massmasks/massmask/${massmaskId}/massdefinition/massimages`);
    }

    if (state.command.confirmDeleteMassDefinition.status === "success") {
        enqueueSnackbar("Maßdefinition entfernt", { variant: "success" });
        dispatch(confirmDeleteMassDefinitionCompleted())
    }  
    
    if (state.command.updateMassDefinitionSortIndex.status === "success") {
        enqueueSnackbar("Reihenfolge gespeichert", { variant: "success" });
        dispatch(updateMassDefinitionSortIndexCompleted())
    }

    if (state.command.assignMassPoints.status === "pending") {
        navigate(`/massmasks/massmask/${massmaskId}/massdefinition/${state.actualData.selectedMassDefinition.id}/masspoints`);
    }

    if (state.command.finishMassDefinitions.status === "pending") {
        navigate(`/massmasks/massmask/overview`);
    }

    const sortUp = (sortIndex: number) => {
        let selectedMassdefinition = state.actualData.massMask.massDefinitionVersions.find(md => md.sortIndex === sortIndex);
        let higherSortetMassdefinition = state.actualData.massMask.massDefinitionVersions.find(md => md.sortIndex === (sortIndex - 1));
        if (doesExist(higherSortetMassdefinition) && state.command.updateMassDefinitionSortIndex.status === "idle") {


            const massDefinitionSortIndexes = [
                {
                    massDefinitionId: selectedMassdefinition.id,
                    sortIndex: higherSortetMassdefinition.sortIndex
                },
                {
                    massDefinitionId: higherSortetMassdefinition.id,
                    sortIndex: selectedMassdefinition.sortIndex
                },

            ] as IMassDefinitionSortIndex[]

            dispatch(updateMassDefinitionSortIndex(massDefinitionSortIndexes));
        }
    }

    const sortDown = (sortIndex: number) => {
        let selectedMassdefinition = state.actualData.massMask.massDefinitionVersions.find(md => md.sortIndex === sortIndex);
        let LowerSortetMassdefinition = state.actualData.massMask.massDefinitionVersions.find(md => md.sortIndex === (sortIndex + 1));
        if (doesExist(LowerSortetMassdefinition) && state.command.updateMassDefinitionSortIndex.status === "idle") {
            const massDefinitionSortIndexes = [
                {
                    massDefinitionId: selectedMassdefinition.id,
                    sortIndex: LowerSortetMassdefinition.sortIndex
                },
                {
                    massDefinitionId: LowerSortetMassdefinition.id,
                    sortIndex: selectedMassdefinition.sortIndex
                },

            ] as IMassDefinitionSortIndex[]

            dispatch(updateMassDefinitionSortIndex(massDefinitionSortIndexes));
        }
    }

    return (<>
        {state.actualData.showDeleteMassDefinitionDialog ?
            <DialogComponent
                title={"Maßdefinition löschen?"}
                isOpen={true}
                options={[
                    { title: "Abbrechen", handleOption: () => { dispatch(cancelDeleteMassDefinition()) } },
                    { title: "Löschen", handleOption: () => { dispatch(confirmDeleteMassDefinition()) } }
                ]}
                onClose={() => { }}>
                <div>Möchten Sie die Maßdefinition wirklich löschen?</div>
            </DialogComponent>
            : <></>}

        <MassmaskStepperComponent currentStep={MassmaskWizardStepEnum.MassDefinitions} />

        {
            (state.actualData.massMask != null)
                ? <MassmaskInformationComponent massMask={state.actualData.massMask} /> : <></>
        }

        <style.OverviewArea>
            <FlexBox>
                <Right>
                    <PrimaryButtonComponent onClick={() => dispatch(addMassDefinition())}><AddIcon /> Maßdefinition hinzufügen</PrimaryButtonComponent>
                </Right>
            </FlexBox>

            {(isLoading)
                ? <LoadingIndicatorComponent />
                : <MassDefinitionsTableComponent massdefinitions={state.actualData.massMask?.massDefinitionVersions}
                    onDelete={(massDefinition) => dispatch(deleteMassDefinition(massDefinition))}
                    onEdit={(massDefinition) => dispatch(assignMassPoints(massDefinition))}
                    onSortUp={(sortIndex) => sortUp(sortIndex)}
                    onSortDown={(sortIndex) => sortDown(sortIndex)}
                    isDeleting={isDeleting}
                />
            }

            <FlexBox>
                <Right>
                    <PrimaryButtonComponent onClick={() => dispatch(finishMassDefinitions())}><CheckIcon /> Fertig</PrimaryButtonComponent>
                </Right>
            </FlexBox>
        </style.OverviewArea>
    </>
    );
}