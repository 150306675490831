import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'app/redux_store';
import BaseDataService from 'services/base_data.service';
import MassPointService from 'services/masspoint.service';
import SerialSizeService from 'services/serial_size.service';
import AdditionAttributeCategoryService from 'services/addition_attribute_category.service';
import { handleResponseErrors } from 'shared/networking/error_handling';
import { toSerialSize } from './serial_sizes_create.reducer';

export const createSerialSize = createAsyncThunk(
    'serial_sizes/serial_sizes_create/createSerialSize',
    async (_, { getState }) => {
        const serialSize = (getState() as RootState).serial_size_create.actualData.serialSize;
        const response = await SerialSizeService.saveSerialSize(toSerialSize(serialSize));
        return handleResponseErrors(response, "Seriengröße");
    }
)

export const getQualitiesAndArticleTypes = createAsyncThunk(
    'serial_sizes/serial_sizes_create/getQualitiesAndArticleTypes',
    async (_) => {
        const response = await BaseDataService.getBaseData()
        handleResponseErrors(response, "QualitiesArticleTypes");
        return response;
    }
)

export const getSizes = createAsyncThunk(
    'serial_sizes/serial_sizes_create/getSizes',
    async (_) => {
        const response = await BaseDataService.getSizes()
        handleResponseErrors(response, "Sizes");
        return response;
    }
)

export const getAvailableAttributeCategories = createAsyncThunk(
    'serial_sizes/serial_sizes_create/getAttributeCategories',
    async (_) => {
        const response = await AdditionAttributeCategoryService.getAvailableAttributeCategories()
        handleResponseErrors(response, "Sizes");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'serial_sizes/serial_sizes_create/cancelSave',
    async (_) => { }
)

export const getMasspoints = createAsyncThunk(
    'serial_sizes/serial_sizes_create/getMasspoints',
    async (_) => {
        const response = await MassPointService.getMassPoints()
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)

export const initData = createAsyncThunk(
    'serial_sizes/serial_sizes_create/initData',
    async (_, thunkApi) => {
        await Promise.all([
            thunkApi.dispatch(getMasspoints()),
            thunkApi.dispatch(getSizes()),
            thunkApi.dispatch(getAvailableAttributeCategories()),
            thunkApi.dispatch(getQualitiesAndArticleTypes())
        ]);
     }
)