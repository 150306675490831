import { doesExist } from "services/validation.service"
import { ProductionInstructionState } from "./production_instruction.model"
import { IProductionInstructionUpdate } from "models/production_instructions/production_instruction_update";
import { YesNoOptions } from "models/boolean_radio_box.options";
import { toValidityScopeState } from "additions/common/helpers/validityScope";

export const updateCanSave = (state: ProductionInstructionState) => {
    state.command.updateProductionInstruction.canExecute =
        doesExist(state.actualData.productionInstructionEditor.isFreeTextEnabled)
        && doesExist(state.actualData.productionInstructionEditor.isDocumentsEnabled)
        && doesExist(state.actualData.productionInstructionEditor.isAutomaticProductionEnabled)
        && doesExist(state.actualData.productionInstructionEditor.calingaKey)
        && doesExist(state.actualData.productionInstructionEditor.symbol);
}

export const loadedProductionInstructionToState = (state: ProductionInstructionState, loadedProductionInstruction: IProductionInstructionUpdate) => {
    state.actualData.productionInstructionEditor.symbol = loadedProductionInstruction.symbol;
    state.actualData.productionInstructionEditor.calingaKey = state.loadedData.calingaKeys.find(x => x.key === loadedProductionInstruction.calingaKey);
    state.actualData.productionInstructionEditor.isAutomaticProductionEnabled = YesNoOptions.find(x => x.value === loadedProductionInstruction.isAutomaticProductionEnabled);
    state.actualData.productionInstructionEditor.isFreeTextEnabled = YesNoOptions.find(x => x.value === loadedProductionInstruction.isFreeTextEnabled);
    state.actualData.productionInstructionEditor.isDocumentsEnabled = YesNoOptions.find(x => x.value === loadedProductionInstruction.isDocumentsEnabled);
    state.actualData.id = loadedProductionInstruction.productionInstructionId;
    state.actualData.productionInstructionEditor.validityScopes = toValidityScopeState(state.loadedData.mappedBaseData,loadedProductionInstruction.validityScopes);
}

