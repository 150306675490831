import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from "./config.service";
import ISerialSizeOverview from "models/serial_sizes/serial_sizes_overview";
import { ISerialSize } from "models/serial_sizes/serial_size";
import { ISerialSizeCreate, ISerialSizeUpdate } from "models/serial_sizes/serial_size_create";
import { ISerialSizePreview } from "models/serial_sizes/serial_size_preview";
import HttpServiceCommonResult from "shared/networking/http_service_common_result";

class SerialSizeServiceAPI extends HttpServiceCommonResult {
    public async saveSerialSize(serialSize: ISerialSizeCreate): Promise<ApiResult<void>> {
        return this.post<ISerialSizeCreate,void>("/api/serialsize", serialSize);
    }

    public async getSerialSizes(): Promise<ApiResult<Array<ISerialSizeOverview>>> {
        return this.get<Array<ISerialSizeOverview>>(`/api/serialsize`);
    }

    public async getSerialSizeByArticleTypeAndQuality(quality: string, articletype: string): Promise<ApiResult<ISerialSizePreview>> {
        return this.get<ISerialSizePreview>(`/api/serialsize/${quality}/${articletype}`);
    }

    public async deleteSerialSize(serialSizeId: Number): Promise<ApiResult<void>> {
        return this.delete(`/api/serialsize/${serialSizeId}`);
    }

    public async getSerialSize(sizeId: string): Promise<ApiResult<ISerialSize>> {
        return this.get<ISerialSize>(`/api/serialsize/${sizeId}`);
    }
    
    public async updateSerialSize(serialSize: ISerialSizeUpdate): Promise<ApiResult<void>> {
        return this.put<ISerialSizeUpdate,void>(`/api/serialsize`, serialSize);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const SerialSizeService = new SerialSizeServiceAPI(TimeoutMilliseconds);

export default SerialSizeService;
