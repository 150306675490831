import IMasspointOverview from "models/masspoints/masspoint_overview";
import { MasspointTypeEnumValuesLookup } from "models/masspoints/enums/masspoint_type.enum";
import { SerialSizeUpdateState } from "./serial_sizes_edit.model";
import { IMassPointCriteria } from "models/masspoints/masspoint_criteria";
import { IAttributeViewModel, IMasspointViewModel, ISerialSizeArticleViewModel, ISerialSizeViewModel, ISizeViewModel } from "masspoints/serial_sizes/components/serial_size_editor.model";
import IAvailableArticleType from "models/available_basedata/available_article_type";
import IAvailableSize from "models/available_basedata/available_size";
import { isNotUndefinied } from "services/validation.service";
import { ISerialSize, ISerialSizeArticleType, ISerialSizeAttributeConfiguration, ISerialSizeSizeConfiguration } from "models/serial_sizes/serial_size";
import { ISerialSizeUpdate } from "models/serial_sizes/serial_size_create";
import { IAvailableAdditionAttribute } from "models/additions/addition";
import { IAvailableAdditionAttributeCategory } from "models/addition_attribute_categories/available_addition_attribute_category";
import { distinctMassPointCriteria } from "shared/helpers/distinctMassPointCriteria";

export const createMasspointItemList = (masspoints: IMasspointOverview[]): IMassPointCriteria[] => {
    return masspoints.filter(distinctMassPointCriteria).map(mp => {
        return {
            massPointId: mp.id,
            massPointName: mp.name,
            displayName: `${mp.name} ${MasspointTypeEnumValuesLookup(mp.massPointType)} ${mp.bodyArea.name}`,
            massPointType: mp.massPointType,
            bodyAreaId: mp.bodyArea.id,
        }
    });
}

export const updateCanSave = (state: SerialSizeUpdateState) => {
    state.command.updateSerialSize.canExecute = calculateCanUpdate(state);
}

const calculateCanUpdate = (state: SerialSizeUpdateState): boolean => {
    return isNotUndefinied(state.actualData.serialSize.quality)
        && state.actualData.serialSize.articleTypeViewModels.length > 0
        && state.actualData.serialSize.articleTypeViewModels.find(art => art.articleType.id === -1) != undefined
        && !isEqual(state.loadedData.serialSize, state.actualData.serialSize);
}

const isEqual = (loadedSize: ISerialSize, actualSize: ISerialSizeViewModel): boolean => {
    let equal = loadedSize.qualityId === actualSize.quality.id
        && areArticleTypesEqual(loadedSize, actualSize.articleTypeViewModels);
    return equal;
}

const areArticleTypesEqual = (loadedSize: ISerialSize, articleTypes: ISerialSizeArticleViewModel[]): boolean => {
    let equal = loadedSize.serialSizeArticleTypes.length === articleTypes.length;
    articleTypes.forEach(art => {
        if (equal === true) {
            const loadedArticleType = loadedSize.serialSizeArticleTypes.find(lart => lart.articleTypeId === art.articleType.id
                || (lart.articleTypeId == null && art.articleType.id === -1));
            if (loadedArticleType == undefined) {
                equal = false;
            } else {
                equal = areSizesEqual(loadedArticleType, art.sizeViewModels);
                if (equal === true) {
                    equal = areAttributesEqual(loadedArticleType, art.attributeViewModels)
                }
            }
        }
    });
    return equal;
}

const areSizesEqual = (loadedArticleType: ISerialSizeArticleType, sizes: ISizeViewModel[]): boolean => {
    let equal = loadedArticleType.serialSizeSizeConfigurations.length === sizes.length;
    sizes.forEach(size => {
        if (equal === true) {
            const loadedSize = loadedArticleType.serialSizeSizeConfigurations.find(lsize => lsize.sizeId === size.size.id);
            if (loadedSize == undefined) {
                equal = false;
            } else {
                equal = areMasspointsEqual(loadedSize, size.masspoints);
            }
        }
    });
    return equal;
}

const areAttributesEqual = (loadedArticleType: ISerialSizeArticleType, attributes: IAttributeViewModel[]): boolean => {
    let equal = loadedArticleType.serialSizeAttributeConfigurations.length === attributes.length;
    attributes.forEach(attribute => {
        if (equal === true) {
            const loadedAttribute = loadedArticleType.serialSizeAttributeConfigurations.find(lattri => lattri.attributeId === attribute.attribute.id);
            if (loadedAttribute == undefined) {
                equal = false;
            } else {
                equal = areAttributeMasspointsEqual(loadedAttribute, attribute.masspoints);
            }
        }
    });
    return equal;
}

const areMasspointsEqual = (loadedSize: ISerialSizeSizeConfiguration, masspoints: IMasspointViewModel[]): boolean => {
    let equal = loadedSize.serialSizeMassPointCriterias.length === masspoints.length;
    masspoints.forEach(mp => {
        if (equal === true) {
            const loadedMp = loadedSize.serialSizeMassPointCriterias.find(lmp => lmp.massPointName === mp.masspoint.massPointName
                && lmp.massPointType === mp.masspoint.massPointType
                && lmp.bodyAreaId === mp.masspoint.bodyAreaId);
            if (loadedMp == undefined) {
                equal = false;
            } else {
                equal = loadedMp.fromValue === mp.valueFrom && loadedMp.toValue === mp.valueTo;
            }
        }
    });
    return equal;
}

const areAttributeMasspointsEqual = (loadedAttribute: ISerialSizeAttributeConfiguration, masspoints: IMasspointViewModel[]): boolean => {
    let equal = loadedAttribute.serialSizeAttributeMassPointCriterias.length === masspoints.length;
    masspoints.forEach(mp => {
        if (equal === true) {
            const loadedMp = loadedAttribute.serialSizeAttributeMassPointCriterias.find(lmp => lmp.massPointName === mp.masspoint.massPointName
                && lmp.massPointType === mp.masspoint.massPointType
                && lmp.bodyAreaId === mp.masspoint.bodyAreaId);
            if (loadedMp == undefined) {
                equal = false;
            } else {
                equal = loadedMp.fromValue === mp.valueFrom && loadedMp.toValue === mp.valueTo;
            }
        }
    });
    return equal;
}

export const calculateViewModel = (state: SerialSizeUpdateState): ISerialSizeViewModel => {
    return {
        id: state.loadedData.serialSize.id,
        quality: state.loadedData.qualities.find(x => x.id == state.loadedData.serialSize.qualityId),
        articleTypeViewModels: state.loadedData.serialSize.serialSizeArticleTypes.map(art => {
            return {
                articleType: art.articleTypeId ? state.loadedData.articleTypes.find(x => x.id === art.articleTypeId) : state.loadedData.articleTypes.find(x => x.id === -1),
                sizeViewModels: art.serialSizeSizeConfigurations.map(size => {
                    return {
                        size: state.loadedData.sizes.find(y => y.id === size.sizeId),
                        masspoints: size.serialSizeMassPointCriterias.map((mp) => {
                            return {
                                masspoint: state.loadedData.masspointCriterias.find(z => z.bodyAreaId === mp.bodyAreaId
                                    && z.massPointType === mp.massPointType
                                    && z.massPointName === mp.massPointName),
                                valueFrom: mp.fromValue,
                                valueTo: mp.toValue
                            }
                        })
                    }
                }),
                attributeViewModels: art.serialSizeAttributeConfigurations.map(attribute => {
                    return {
                        attribute: flatAttributes(state.loadedData.attributeCategories).find(y => y.id === attribute.attributeId),
                        masspoints: attribute.serialSizeAttributeMassPointCriterias.map((mp) => {
                            return {
                                masspoint: state.loadedData.masspointCriterias.find(z => z.bodyAreaId === mp.bodyAreaId
                                    && z.massPointType === mp.massPointType
                                    && z.massPointName === mp.massPointName),
                                valueFrom: mp.fromValue,
                                valueTo: mp.toValue
                            }
                        })
                    }
                })
            }
        })
    }
}

const flatAttributes = (attributeCategories: IAvailableAdditionAttributeCategory[]): IAvailableAdditionAttribute[] => {
    return attributeCategories.flatMap(x => x.availableAdditionAttributes);
}

export const calculateAvailableArticleTypes = (state: SerialSizeUpdateState): IAvailableArticleType[] => {
    const usedArticleTypes = state.actualData.serialSize.articleTypeViewModels.map(x => { return x.articleType.id });
    return state.loadedData.articleTypes.filter(x => !usedArticleTypes.find(y => y === x.id));
}

export const calculateAvailableSizes = (state: SerialSizeUpdateState): IAvailableSize[] => {
    const usedSizes = state.actualData.configureArticleType.sizes.map(x => { return x.size.id });
    return state.loadedData.sizes.filter(x => !usedSizes.find(y => y === x.id));
}

const sortMasspointCriteria = (mp1: IMassPointCriteria, mp2: IMassPointCriteria) => {
    return mp1.displayName.localeCompare(mp2.displayName);
}

export const recalculateAvailableMasspoints = (state: SerialSizeUpdateState, additionalMasspoint: IMassPointCriteria): IMassPointCriteria[] => {
    const usedMasspoints = state.actualData.configureArticleType.configureSize.masspoints.map(x => { return x.masspoint });
    const availableMasspoints = state.loadedData.masspointCriterias.filter(x => !usedMasspoints.find(y => y.displayName === x.displayName));
    if (additionalMasspoint) {
        availableMasspoints.push(additionalMasspoint);
    }
    return availableMasspoints.sort(sortMasspointCriteria);
}

export const recalculateAvailableAttributeMasspoints = (state: SerialSizeUpdateState, additionalMasspoint: IMassPointCriteria): IMassPointCriteria[] => {
    const usedMasspoints = state.actualData.configureArticleType.configureAttribute.masspoints.map(x => { return x.masspoint });
    const availableMasspoints = state.loadedData.masspointCriterias.filter(x => !usedMasspoints.find(y => y.displayName === x.displayName));
    if (additionalMasspoint) {
        availableMasspoints.push(additionalMasspoint);
    }
    return availableMasspoints.sort(sortMasspointCriteria);
}

export const recalculateAvailableAttributes = (state: SerialSizeUpdateState, availableAttributes: IAvailableAdditionAttribute[]): IAvailableAdditionAttribute[] => {
    const usedAttributes = state.actualData.configureArticleType.attributes.map(x => { return x.attribute });
    return availableAttributes.filter(x => !usedAttributes.find(y => y.code === x.code))
        .sort(sortAvailableAttributes);
}

export const sortAvailableAttributes = (att1: IAvailableAdditionAttribute, att2: IAvailableAdditionAttribute) => {
    return att1.code.localeCompare(att2.code);
}

export const toSerialSize = (serialSize: ISerialSizeViewModel): ISerialSizeUpdate => {
    return {
        Id: serialSize.id,
        qualityId: serialSize.quality.id,
        articleTypes: serialSize.articleTypeViewModels.map(art => {
            return {
                articleTypeId: art.articleType.id !== -1 ? art.articleType.id : null,
                configurations: art.sizeViewModels.map((size) => {
                    return {
                        sizeId: size.size.id,
                        masspoints: size.masspoints.map((mp) => {
                            return {
                                valueFrom: mp.valueFrom,
                                valueTo: mp.valueTo,
                                name: mp.masspoint.massPointName,
                                type: mp.masspoint.massPointType,
                                bodyAreaId: mp.masspoint.bodyAreaId
                            }
                        })
                    }
                }),
                attributeConfigurations: art.attributeViewModels.map((attribute) => {
                    return {
                        attributeId: attribute.attribute.id,
                        masspoints: attribute.masspoints.map((mp) => {
                            return {
                                valueFrom: mp.valueFrom,
                                valueTo: mp.valueTo,
                                name: mp.masspoint.massPointName,
                                type: mp.masspoint.massPointType,
                                bodyAreaId: mp.masspoint.bodyAreaId
                            }
                        })
                    }
                })
            }
        })
    };
}