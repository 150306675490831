import { ImportsOverviewTableComponent } from './imports_overview_table.component';
import * as style from "./imports_overview.style";
import { IBasedataImport } from 'models/basedata_import/basedata_import';
import { LoadingIndicatorComponent } from 'shared/shared';
import { LoadingOverlayComponent, LoadingOverlayContainer } from 'shared/components/loading_overlay/loading_overlay.component';

interface ImportsOverviewComponentProps {
    imports: IBasedataImport[];
    isReloading: boolean;
    isLoading: boolean;
}

export const ImportsOverviewComponent: React.FC<ImportsOverviewComponentProps> = ({ imports, isLoading, isReloading }) => {
    return <style.ImportsOverviewWrapper>
        <style.Headline>Letzte Imports</style.Headline>
        {
            isLoading
                ? <LoadingIndicatorComponent />
                : <LoadingOverlayContainer>
                    {
                        isReloading
                            ? <LoadingOverlayComponent />
                            : <></>
                    }
                    {
                        imports.length === 0
                            ? <div>Keine Imports vorhanden</div>
                            : <ImportsOverviewTableComponent imports={imports} />
                    }
                </LoadingOverlayContainer>
        }
    </style.ImportsOverviewWrapper>
}