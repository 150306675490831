import { FlexBox, LoadingIndicatorComponent, PrimaryButtonComponent, Right, Left } from "shared/shared";
import AddIcon from '@mui/icons-material/Add';
import SearchFieldComponent from "shared/components/search/searchfield.component";
import { useSnackbar } from "notistack";
import { Box } from "@mui/material";
import * as style from "./addition_position_overview.style";
import { useAppDispatch, useAppSelector } from 'app/hook';
import { AppDispatch } from 'app/redux_store';
import {
    cancelDeleteAdditionPosition,
    confirmDeleteCompleted,
    confirmDeleteError,
    deleteAdditionPosition,
    resetState,
    setGridFilters,
    setSearchFilter
} from "./redux/addition_position_overview.slice";
import { confirmDeleteAdditionPosition, fetchAdditionPositions, navigateCreateAdditionPosition } from "./redux/addition_position_overview.thunks";
import { useEffect } from "react";
import { DialogComponent } from "shared/components/dialogs/dialog.component";
import { useNavigate } from "react-router-dom";
import { OverviewDatagrid } from "shared/components/datagrid/overview_datagrid.component";
import { GridFilterModel } from "@mui/x-data-grid-pro";
import { getGridColumns, sortByNameModel } from "./addition_position_overview.helper";

export const AdditionPositionOverviewComponent = () => {
    const state = useAppSelector((state) => state.addition_position_overview);
    const dispatch: AppDispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchAdditionPositions());
        return () => {
            dispatch(resetState())
        };
    }, [dispatch]);

    if (state.query.getAdditionPositions.status === "error") {
        return <div>Fehler beim Laden der Daten</div>;
    }

    if (state.query.getAdditionPositions.status !== "success") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.navigateCreateAdditionPosition.status === "pending") {
        navigate("/additions/additionposition/create");
    }   

    if (state.command.confirmDelete.status === "success") {
        enqueueSnackbar("Zusatzposition entfernt", { variant: "success" });
        dispatch(confirmDeleteCompleted())
    }

    if (state.command.confirmDelete.status === "error") {
        enqueueSnackbar(state.command.confirmDelete.message, { variant: "error" });
        dispatch(confirmDeleteCompleted());
    }


    const editAdditionPosition = (additionPosition) => {
        navigate(`/additions/additionposition/edit/${additionPosition.id}`);
    }

    const isLoading = state.command.confirmDelete.status === "pending";
    const additionPositionsAvailable = state.loadedData.additionPositions?.length > 0;
    return (
        <>
            {
                state.actualData.showDeleteErrorDialog ? 
                <DialogComponent
                    title={"Zusatzposition löschen nicht möglich"}
                    isOpen={true}
                    options={[
                        { title: "Okay", handleOption: () => { dispatch(confirmDeleteError()) } }
                    ]}
                    onClose={() => { }}>
                    <div>{state.command.confirmDelete.message}</div>
                </DialogComponent> : <></>
            }
            {
                state.actualData.showDeleteDialog ?
                <DialogComponent
                    title={"Zusatzposition löschen?"}
                    isOpen={true}
                    options={[
                        { title: "Abbrechen", handleOption: () => { dispatch(cancelDeleteAdditionPosition()) } },
                        { title: "Löschen", handleOption: () => { dispatch(confirmDeleteAdditionPosition()) } }
                    ]}
                    onClose={() => { }}>
                    <div>Möchten Sie die Zusatzposition wirklich löschen?</div>
                </DialogComponent> : <></>
            }
            <FlexBox>
                <Left>
                    <SearchFieldComponent onSearch={(filterText) => dispatch(setSearchFilter(filterText))} placeholder="In Zusatzpositionen suchen ..." />
                </Left>
                <Right>
                    <PrimaryButtonComponent onClick={() => dispatch(navigateCreateAdditionPosition())}>
                        <AddIcon /> Neue Zusatzposition erstellen
                    </PrimaryButtonComponent>
                </Right>
            </FlexBox>

            <style.OverviewWrapper>
                {
                    !additionPositionsAvailable && <Box mt={4}>Keine Zusatzposition verfügbar</Box>
                }
                {
                    additionPositionsAvailable &&
                    <OverviewDatagrid 
                        isLoading={isLoading} 
                        sortModel={sortByNameModel} 
                        columns={getGridColumns(
                            (additionPosition) => editAdditionPosition(additionPosition),
                            (additionPosition) => dispatch(deleteAdditionPosition(additionPosition))
                        )} 
                        rows={state.actualData.additionPositions} 
                        loadedRowsCount={state.loadedData.additionPositions.length} 
                        onFilterModelChange={(newFilterModel: GridFilterModel) => dispatch(setGridFilters(newFilterModel))}
                        onDoubleClickRow={additionPosition => editAdditionPosition(additionPosition)}
                        />
                }
            </style.OverviewWrapper>
        </>
    );
};