import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IMassmaskOverview from '../../../models/massmasks/massmask_overview';
import { doesExist } from '../../../services/validation.service';
import { MassmaskSelectionState } from './massmask_selection.model';
import { getMassMasks, simulateMassMask } from './massmask_selection.thunks';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import { filterMassMasks } from './massmask_selection.reducer';

const initialState: MassmaskSelectionState = {
    loadedData: {
        massMasks: [],
    },
    actualData: {
        massMasks: [],
        searchFilter: "",
        gridFilters: { items: [] },
        selectedMassMask: null,
        isMassMaskSelected: false,
    },
    query: {
        getMassMasks: { status: "idle", canExecute: true },
    },
    command: {
        simulateMassMask: { status: "idle", canExecute: true },
    },
}

export const massMaskSelectionSlice = createSlice({
    name: 'simulator/massmask_selection',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loadedData = initialState.loadedData;
            state.actualData = initialState.actualData;
            state.query = initialState.query;
            state.command = initialState.command;
        },
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.actualData.searchFilter = action.payload;
            filterMassMasks(state);
        },
        setGridFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.gridFilters = action.payload;
            filterMassMasks(state);
        },
        selectMassMask: (state, action: PayloadAction<IMassmaskOverview>) => {
            state.actualData.selectedMassMask = action.payload;
            state.actualData.isMassMaskSelected = doesExist(action.payload);
        },
    }, extraReducers: (builder) => {
        // getMassMasks
        builder.addCase(getMassMasks.pending, (state, action) => {
            state.query.getMassMasks.status = "pending"
            state.query.getMassMasks.canExecute = false;
        }).addCase(getMassMasks.rejected, (state, action) => {
            state.query.getMassMasks.status = "error"
            state.query.getMassMasks.message = action.error.message;
        }).addCase(getMassMasks.fulfilled, (state, action) => {
            state.query.getMassMasks.status = "success"
            const massMasks = action.payload.getData().filter(x => x.articleType != null && x.footType == null);
            state.loadedData.massMasks = massMasks;
            state.actualData.massMasks = massMasks;

            // simulateMassMask 
        }).addCase(simulateMassMask.pending, (state, action) => {
            state.command.simulateMassMask.status = "pending"
        }).addCase(simulateMassMask.fulfilled, (state, action) => {
            state.command.simulateMassMask.status = "success"
        })
    }
})

export const {
    resetState,
    setSearchFilter,
    setGridFilters,
    selectMassMask,
} = massMaskSelectionSlice.actions

export default massMaskSelectionSlice.reducer