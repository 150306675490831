import { Box, ClickAwayListener, Grid, Popper, styled } from "@mui/material";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';

interface CustomPopperTextCellComponentProps{
    content: string;
    maxWidth?: number;
    info?: string;   
}

export const CustomPopperTextCellComponent: React.FC<CustomPopperTextCellComponentProps> = (
    {
        content, 
        info,
        maxWidth
    }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLDivElement>(null);
    const showPopper = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const usePopper = info && info !== '';

    const allowedMaxWidth = maxWidth ? maxWidth : 350;

    return <>        
        { usePopper ? 
        <>
            <div onClick={e => handleClick(e)}>{content}</div>
            <Popper
                style={{ zIndex: 9999 , maxWidth: allowedMaxWidth }}
                open={showPopper}
                anchorEl={anchorEl}
                placement="left"
                modifiers={[
                    {
                        name: "offset",
                        options: {
                            offset: [0, 5],
                        },
                    }
                ]}
            >
                <ClickAwayListener onClickAway={handleClickAway}>
                    <BoxStyled>
                        <Grid container>
                            <Grid container item justifyContent="flex-end">
                                <CloseIcon onClick={handleClickAway}></CloseIcon>
                            </Grid>
                            <Grid item>
                                {info}
                            </Grid>
                        </Grid>
                    </BoxStyled>
                </ClickAwayListener>
            </Popper>
        </>
        : 
        <div>{content}</div>} 
    </>
}

const BoxStyled = styled(Box)`
    border-radius: 4px;
    padding: 8px;
    background-color: #333F48;
    color: #fff;
    &:before {
        background-color: #333F48;
        content: "";
        display: block;
        position: absolute;
        width: 12px;
        height: 12px;
        bottom: -5px;
        transform: rotate(45deg);
        top: calc(50% - 3px);
        left: calc(100% - 6px);
    }
`