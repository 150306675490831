import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import theme from "../../../style/theme";
import * as style from './text_box.style'

export interface TextBoxProps {
  name: string;
  placeholder?: string;
  isRequired?: boolean;
  isReadonly?: boolean;
  isMultiLine?: boolean;
  minLength?: number;
  maxLength?: number;
  isAutoFocus?: boolean,
  width?: string,
  onChange: (text: string) => void;
  value?: string;
  errorText?: string;
}

export const TextBoxComponent: React.FC<TextBoxProps> = ({
  name,
  placeholder,
  isRequired,
  isReadonly,
  isMultiLine = false,
  minLength,
  maxLength,
  isAutoFocus,
  width,
  onChange,
  value,
  errorText
}) => {
  const isErrorVisible = errorText !== "" && errorText != null;
  return (
    <FormControl
      component="fieldset"
      style={{ minWidth: theme.form.minWidth, width: width }}
      required={isRequired}
    >
      <InputLabel htmlFor={name} variant="standard" >{name} </InputLabel>
      <Input
        aria-describedby={`${name}_description`}
        multiline={isMultiLine}
        id={name}
        autoFocus={isAutoFocus}
        placeholder={placeholder}
        required={isRequired}
        disabled={isReadonly}
        inputProps={{ maxLength: maxLength ?? 512, minLength: minLength ?? 0 }}
        onChange={(e) => onChange(e.currentTarget.value)}
        value={value}
        error={isErrorVisible}
      />
      {isErrorVisible ? <style.ErrorWrapper>{errorText}</style.ErrorWrapper> : <></>}
    </FormControl>
  );
};
