import React from "react";
import { Container } from "reactstrap";
import styled from "@emotion/styled";
import theme from "../../style/theme";

const ContentArea = styled.div`
  color: ${theme.colors.text};
  flex-grow: 1;
  flex-shrink: 0;
  background:whitesmoke;
  padding-top:10px;
`;

const ContentHolder = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export type ContentComponentProps = {
  children: React.ReactNode;
}

export const ContentComponent: React.FC<ContentComponentProps> = ({ children }) => {
  return (
    <ContentArea>
      <Container>
        <ContentHolder>{children}</ContentHolder>
      </Container>
    </ContentArea>
  );
};
