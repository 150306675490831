import { doesExist, isNullOrWhitespace } from "services/validation.service";
import { PolicyMandatoryCategoryByMasspointCreateState } from "./policy_mandatory_category_by_masspoint_create.model";
import IMasspointOverview from "models/masspoints/masspoint_overview";
import { MasspointTypeEnumValuesLookup } from "models/masspoints/enums/masspoint_type.enum";
import { distinctMassPointCriteria } from "shared/helpers/distinctMassPointCriteria";

export const updateCanSave = (state: PolicyMandatoryCategoryByMasspointCreateState) => {
    state.command.savePolicy.canExecute =
        !isNullOrWhitespace(state.data.policy.name)
        && doesExist(state.data.selectedCategory)
        && doesExist(state.data.selectedMasspointCriteria);
}

export const getMassPointCriteriaFromMasspoints = (masspoints: IMasspointOverview[]) => {
    return masspoints.filter(distinctMassPointCriteria).map(mp => {
        return {
            massPointId: mp.id,
            massPointName: mp.name,
            displayName: `${mp.name} ${MasspointTypeEnumValuesLookup(mp.massPointType)} ${mp.bodyArea.name}`,
            massPointType: mp.massPointType,
            bodyAreaId: mp.bodyArea.id,
        }
    });
}