import AddIcon from "@mui/icons-material/Add";
import { LoadingIndicatorComponent, PrimaryButtonComponent } from "shared/shared";
import { FlexBox, Left, Right } from "shared/style/alignment";
import SearchFieldComponent from "shared/components/search/searchfield.component";
import { AppDispatch } from "app/redux_store";
import { useAppDispatch, useAppSelector } from 'app/hook';
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import {
  resetState,
  deletePositionCategory,
  confirmDeleteCompleted,
  confirmDeleteError,
  setActivePositionCategory,
  cancelDeletePositionCategory,
  setSearchFilter,
  setGridFilters,
} from "./redux/position_category_overview.slice";
import {
  createNewPositionCategory,
  confirmDeletePositionCategory,
  getPositionCategories,
  showPositionCategoryItemDetails,
  updatePositionCategory
} from './redux/position_category_overview.thunks';
import * as style from "./position_category_overview.style";
import { DialogComponent } from "shared/components/dialogs/dialog.component";
import { useNavigate } from "react-router-dom";
import { OverviewDatagrid } from "shared/components/datagrid/overview_datagrid.component";
import { getGridColumns, sortByNameModel } from "./position_category_overview.helper";

export const PositionCategoryOverviewComponent = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const state = useAppSelector((state) => state.position_categories_overview);

  useEffect(() => {
    dispatch(getPositionCategories());
    return () => {
      dispatch(resetState());
    }
  }, [dispatch]);

  if (state.query.getPositionCategories.status === "error") {
    enqueueSnackbar(state.query.getPositionCategories.message, { variant: "error" });
  }

  if (state.query.getPositionCategories.status !== "success") {
    return <LoadingIndicatorComponent />;
  }

  if (state.command.createNewPositionCategory.status === "pending") {
    navigate(`/additions/positioncategory/create`);
  }

  if (state.command.showPositionCategoryItemDetails.status === "pending") {
    navigate(`/additions/positioncategory/positioncategorydetails/${state.actualData.activePositionCategory.id}`);
  }

  if (state.command.confirmDelete.status === "success") {
    enqueueSnackbar("Positionskategorie entfernt", { variant: "success" });
    dispatch(confirmDeleteCompleted())
  }

  if (state.command.confirmDelete.status === "error") {
    enqueueSnackbar(state.command.confirmDelete.message, { variant: "error" });
    dispatch(confirmDeleteCompleted());
  }

  if (state.command.updatePositionCategory.status === "success") {
    navigate(`/additions/positioncategory/edit/${state.actualData.activePositionCategory.id}`);
  }

  const gridColumns = getGridColumns(
    positionCategory => {
      dispatch(setActivePositionCategory(positionCategory));
      dispatch(showPositionCategoryItemDetails());
    },
    (positionCategory) => dispatch(updatePositionCategory(positionCategory)),
    (positionCategory) => dispatch(deletePositionCategory(positionCategory))
  )

  const positionCategoriesAvailable = state.loadedData.positionCategories?.length > 0;

  return (
    <>
      {
        state.actualData.showDeleteDialog &&
        <DialogComponent
          title={"Positionskategorie löschen?"}
          isOpen={true}
          options={[
            { title: "Abbrechen", handleOption: () => { dispatch(cancelDeletePositionCategory()) } },
            { title: "Löschen", handleOption: () => { dispatch(confirmDeletePositionCategory()) } }
          ]}
          onClose={() => { }}>
          <div>Möchten Sie die Positionskategorie wirklich löschen?</div>
        </DialogComponent>
      }
      {
        state.actualData.showDeleteErrorDialog &&
        <DialogComponent
          title={"Positionskategorie löschen nicht möglich"}
          isOpen={true}
          options={[
            { title: "Okay", handleOption: () => { dispatch(confirmDeleteError()) } }
          ]}
          onClose={() => { }}>
          <div>Die Positionskategorie kann nicht gelöscht werden, da es in folgenden Zusätzen verwendet wird:
            <style.ErrorDataWrapper>{state.actualData.activeAssignedAdditions.join(", ")}</style.ErrorDataWrapper>
          </div>
        </DialogComponent>
      }
      <FlexBox>
        {
          state.actualData.isSearchEnabled &&
          <Left>
            <SearchFieldComponent onSearch={(filterText) => dispatch(setSearchFilter(filterText))}
              placeholder="Suchen ..." />
          </Left>
        }
        <Right>
          <PrimaryButtonComponent onClick={() => dispatch(createNewPositionCategory())}>
            <AddIcon /> Neue Positionskategorie erstellen
          </PrimaryButtonComponent>
        </Right>
      </FlexBox>
      <style.TableContainer>
        {
          !positionCategoriesAvailable && <div>Keine Positionskategorie verfügbar</div>
        }
        {
          positionCategoriesAvailable &&
          <OverviewDatagrid
            isLoading={false}
            columns={gridColumns}
            rows={state.actualData.positionCategories}
            loadedRowsCount={state.loadedData.positionCategories.length}
            sortModel={sortByNameModel}
            onFilterModelChange={(newFilterModel) => dispatch(setGridFilters(newFilterModel))}
            onDoubleClickRow={(positionCategory) => dispatch(updatePositionCategory(positionCategory))}
          />
        }
      </style.TableContainer>
    </>
  );
};