import { EnumDescriptor } from "../enum_descriptor";

export enum ProductionTypeEnum {
    None = 0,
    Measurement = 1,
    CustomDesign = 2,
    MeasurementAndCustomDesign = 3,
}

export const ProductionTypeValues: Array<
    EnumDescriptor<ProductionTypeEnum>
> = [
        { value: ProductionTypeEnum.CustomDesign, text: "Sonderanfertigung" },
        { value: ProductionTypeEnum.Measurement, text: "Maßanfertigung" },
        { value: ProductionTypeEnum.MeasurementAndCustomDesign, text: "Sonder- und Maßanfertigung" },
    ];