import { RootState } from 'app/redux_store';
import { createAsyncThunk } from "@reduxjs/toolkit";
import AdditionPositionService from 'services/addition_position.service';
import { handleResponseErrors } from 'shared/networking/error_handling';

export const navigateCreateAdditionPosition = createAsyncThunk(
    'additionPosition/overview/navigateCreateAdditionPosition',
    async (_) => { }
)

export const fetchAdditionPositions = createAsyncThunk(
    'additionPosition/overview/fetchAdditionPositions',
    async (thunkApi) => {
        const response = await AdditionPositionService.getAdditionPositionsOverview();
        handleResponseErrors(response, "Zusatzpositionen");
        return response;
    }
)

export const confirmDeleteAdditionPosition = createAsyncThunk(
    'additionPosition/overview/confirmDeleteAdditionPosition',
    async (_, { getState }) => {
        const state = getState() as RootState
        const additionPositionId = state.addition_position_overview.actualData.selectedAdditionPosition.id;
        const responseDelete = await AdditionPositionService.deleteAdditionPosition(additionPositionId);
        handleResponseErrors(responseDelete, "Zusatzposition");
        const additionPositions = await AdditionPositionService.getAdditionPositionsOverview();
        handleResponseErrors(additionPositions, "Zusatzpositionen");
        return additionPositions;
    }
)