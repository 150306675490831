import React, { FormEvent, useState, useEffect } from "react";
import * as style from "./range_input.style";
import { isNumeric } from '../../services/validation.service';

const normalizeInput = (event: FormEvent<HTMLInputElement>) => {
    const input = event.currentTarget.value
    const value = parseFloat(input)
    return normalizeValue(value);
}

const normalizeValue = (value: number) => {
    const min = 0.0;
    const max = 999.0;
    const commaPlaces = 1;
    const result = Math.max(Math.min(value, max), min);
    return Number.isNaN(result) ? "" : result.toFixed(commaPlaces);
}

interface RangeInputComponentProps {
    initialValue?: number;
    onMouseEnter?: () => void;
    onMouseLeave?: (value: number) => void;
}

export const RangeInputComponent: React.FC<RangeInputComponentProps> = ({ initialValue, onMouseEnter, onMouseLeave }) => {
    useEffect(() => {
        setInputValue(normalizeValue(initialValue));
    }, [initialValue]);

    const [inputValue, setInputValue] = useState(normalizeValue(initialValue));

    return <><div style={{display:"flex", flexDirection:"row", position:"relative"}}>
        <style.Input
            onFocus={onMouseEnter}
            onBlur={(event) => {
                const input = event?.currentTarget?.value;
                const value = isNumeric(input) ? Number(input) : -1;
                onMouseLeave(value);
            }}
            type="text"
            value={inputValue}
            placeholder=''
            onInput={(e) => {
                const selectionStart = e.currentTarget.selectionStart;
                const selectionEnd = e.currentTarget.selectionEnd;
                const value = normalizeInput(e)
                e.currentTarget.value = value;
                setInputValue(value);
                e.currentTarget.setSelectionRange(selectionStart, selectionEnd);
            }}
        />
        <div style={{ position: "absolute", right: 5, bottom: 1, zIndex: 1000000 }}>cm</div>
    </div>

    </>
}
