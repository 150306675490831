import styled from "@emotion/styled";
import colors from "./colors";
import { DataGridPro } from "@mui/x-data-grid-pro";

interface StyledDataGridProps {
    enableHover?: boolean
}

export const StyledDataGrid = styled(DataGridPro)<StyledDataGridProps>(({ enableHover }) => ({
    "& .MuiDataGrid-columnHeaders, .MuiDataGrid-columnHeader, .MuiDataGrid-footerContainer, .MuiDataGrid-columnHeader--moving": {
        backgroundColor: colors.mediSecondaryGray,
    },
    "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 'bold',
        padding: 2,
        fontSize: 14,
    },
    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
        marginTop: "14px"
    },
    "& .MuiDataGrid-cell, .MuiDataGrid-columnHeader": {
        outline: "none !important",
    },
    "& .Mui-selected": {
        borderTop: "2px solid " + colors.mediMagenta,
        borderBottom: "2px solid " + colors.mediMagenta,
        backgroundColor: "white !important"
    },
    "& .grayBackground": {
        backgroundColor: colors.reallyLightGray + " !important",
        cursor: "pointer"
    },
    "& .grayBackground:hover": {
        backgroundColor: (enableHover ? colors.mediSecondaryGray : colors.reallyLightGray) + " !important",
        cursor: "pointer"
    },
    "& .whiteBackground": {
        backgroundColor: "white !important",
        cursor: "pointer"
    },
    "& .whiteBackground:hover": {
        backgroundColor: (enableHover ? colors.mediSecondaryGray : "white") + " !important",
        cursor: "pointer"
    }
}));