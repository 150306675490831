import { GridFilterItem, GridColDef, getGridBooleanOperators, GridSortModel } from "@mui/x-data-grid-pro";
import { IPolicyOverview } from "models/policies/policy_overview";
import { PolicyTypeEnum, PolicyTypeValues } from "models/policies/policy_type.enum";
import { CustomBooleanCell } from "shared/components/datagrid/custom_boolean_cell.component";
import { CustomFilterOperators } from "shared/components/datagrid/custom_filter_operators";
import { DatagridMenu } from "shared/components/datagrid/datagrid_menu.component";


export const buildPolicyTypeDisplayText = ( policyType: PolicyTypeEnum ) => {
    return PolicyTypeValues.find(x => x.value === policyType)?.overviewText
}

export const filterPolicyValue = (filterItem: GridFilterItem, filteredItems: IPolicyOverview[]) => {
    if(filterItem.value)
    {
        switch(filterItem.field)
        {
            case 'name':
                filteredItems = filteredItems.filter(x => x.name.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'policyType':
                filteredItems = filteredItems.filter(x => buildPolicyTypeDisplayText(x.policyType).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;         
            case 'isPublished':
                filteredItems = filteredItems.filter(x => filterItem.value === 'any' ? true: x.isPublished.toString() === filterItem.value);
                break;                                                                                                      
        }
    }
    return filteredItems;
}

export const getGridColumns = (
    editPolicy: (policy: IPolicyOverview) => void,
    togglePublishPolicy: (policy: IPolicyOverview) => void,
    deletePolicy: (policy: IPolicyOverview) => void,
    ): GridColDef[] => [
    {
        field: "menu",
        headerName: " ",
        width: 15,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        renderCell: params => DatagridMenu({params: params, menuItemsData: 
            [
                {label: "Regelwerk editieren", action: editPolicy},
                {label: params.row.isPublished ? "Veröffentlichung aufheben" : "Regelwerk veröffentlichen", action: togglePublishPolicy},
                {label: "Regelwerk löschen", action: deletePolicy},
            ]})
    },
    {
        field: "name",
        headerName: "Name",
        disableColumnMenu: false,
        flex: 0.4,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    }, 
    {
        field: "policyType",
        headerName: "Regeltyp",
        disableColumnMenu: false,
        flex: 0.4,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
        valueGetter: (value, row) => buildPolicyTypeDisplayText(row.policyType)
    },   
    {
        field: "isPublished",
        headerName: "Veröffentlicht",
        disableColumnMenu: false,
        flex: 0.2,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: getGridBooleanOperators(),
        renderCell: (params) => CustomBooleanCell({value: params.row.isPublished})
    }
]    

export const sortByNameModel: GridSortModel = [
    {
        field: "name",
        sort: "asc",
    },
];