import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/redux_store';
import CharacteristicCategoryServiceAPI from '../../../../services/characteristic_category.service';
import { handleResponseErrors } from '../../../../shared/networking/error_handling';
import { ICharacteristicCategoryOverview } from 'models/characteristic_categories/characteristic_category_overview';

export const fetchCharacteristicCategories = createAsyncThunk(
    'characteristic_categories/characteristic_categories_overview/fetch',
    async (_) => {
        const response = await CharacteristicCategoryServiceAPI.getCharacteristicCategories();
        handleResponseErrors(response, "Ausprägungskategorien");
        return response;
    }
)

export const confirmDelete = createAsyncThunk(
    'characteristic_categories/characteristic_categories_overview/confirmDelete',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const activeId = state.characteristic_categories_overview.actualData.characteristicCategory.id

        const deleteResponse = await CharacteristicCategoryServiceAPI.deleteCharacteristicCategory(activeId);
        handleResponseErrors(deleteResponse, "Ausprägungskategorie");
        const responsePolicies = await CharacteristicCategoryServiceAPI.getCharacteristicCategories();
        handleResponseErrors(responsePolicies, "Ausprägungskategorien");
        return responsePolicies;
    }
)

export const createNewCharacteristicCategory = createAsyncThunk(
    'characteristic_categories/characteristic_categories_overview/createNew',
    async (_) => { }
)

export const updateCharacteristicCategory = createAsyncThunk(
    'characteristic_categories/characteristic_categories_overview/update',
    async (characteristic_category: ICharacteristicCategoryOverview) => { return characteristic_category }
)
