import { useAppSelector, useAppDispatch } from "app/hook";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { AppDispatch } from "app/redux_store";
import { LoadingIndicatorComponent } from "shared/shared";
import { LoadingOverlayComponent, LoadingOverlayContainer } from "shared/components/loading_overlay/loading_overlay.component";
import { Grid } from "@mui/material";
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";
import { cancelSave, initData, updateCharacteristicCategory } from "./redux/characteristic_category_edit.thunks";
import { changeName, completedSave, filterSelectedValues, filterUnselectedValues, resetState, selectType, selectValues, unSelectValues } from "./redux/characteristic_category_edit.slice";
import { CharacteristicCategoryEditorComponent } from "../components/characteristic_category_editor.component";

export const CharacteristicCategoryEditComponent = () => {
    const state = useAppSelector((state) => state.characteristic_categories_edit);
    const dispatch: AppDispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        dispatch(initData(id));

        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    if (state.query.initData.status === "pending") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.cancelSave.status === "success") {
        navigate("/additions/characteristiccategory/overview");
        dispatch(resetState());
    }

    if (state.command.updateCharacteristicCategory.status === "success") {
        enqueueSnackbar("Ausprägungskategorie gespeichert", { variant: "success" });
        navigate("/additions/characteristiccategory/overview");
        dispatch(resetState());
    }

    if (state.command.updateCharacteristicCategory.status === "error") {
        enqueueSnackbar(state.command.updateCharacteristicCategory.message, { variant: "error" });
        dispatch(completedSave());
    }

    if (state.command.updateCharacteristicCategory.status === "warning") {
        enqueueSnackbar(state.command.updateCharacteristicCategory.message, { variant: "warning" });
        dispatch(completedSave());
    }

    const isSaving = state.command.updateCharacteristicCategory.status === "pending";

    return (<>
            <LoadingOverlayContainer>
                {isSaving ? <LoadingOverlayComponent /> : <></>}
                <Grid
                    container
                    spacing={3}
                    direction="column">
                    <Grid item>
                        <CharacteristicCategoryEditorComponent
                            header='Ausprägungskategorie editieren'
                            name={state.data.characteristicCategory.name}
                            types={state.loadedData.characteristicTypes}
                            selectedType={state.data.selectedCharacteristicType}
                            selectionValues={state.data.characteristicCategory.selectionValues}
                            changeName={(value) => dispatch(changeName(value))} 
                            selectType={(value) => dispatch(selectType(value))}
                            filterSelectedValues={(text) => dispatch(filterSelectedValues(text))}
                            filterUnselectedValues={(text) => dispatch(filterUnselectedValues(text))}
                            selectValues={(value) => dispatch(selectValues(value))}
                            unSelectValues={(value) => dispatch(unSelectValues(value))}
                        />
                    </Grid>  
                    <EditorButtonComponent
                        canExecute={state.command.updateCharacteristicCategory.canExecute}
                        save={() => dispatch(updateCharacteristicCategory())}
                        cancelSave={() => dispatch(cancelSave())}
                    />
                </Grid>
            </LoadingOverlayContainer>
        </>
    );
};