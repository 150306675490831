import { Grid, IconButton } from '@mui/material';
import { EditorSectionHeaderComponent } from 'shared/components/editors/editor_header/editor_header.style'
import { EditorDescriptionComponent } from 'shared/components/editors/editor_description/editor_description.style'
import { ListButtonComponent } from 'shared/components/buttons/list_button.component';
import AddIcon from "@mui/icons-material/Add";
import { SelectGussetMassPointsComponent } from './addition_gusset_masspoint.component';
import DeleteIcon from '@mui/icons-material/Delete';
import { BoxItem } from './addition_gusset.style';
import { DecimalInputComponent } from 'shared/components/input/numberInput/decimal_input.component';
import { IAdditionEditor, IAdditionGussetEditorAction } from 'models/additions/addition_editor.model';
import { SelectionQualityComponent } from '../validities/addition_validity_item_quality_selection.component';
import { SelectionArticleTypeComponent } from '../validities/addition_validity_item_articleType_selection.component';

interface GussetComponentProps {
    additionEditor: IAdditionEditor,
    additionEditorActions: IAdditionGussetEditorAction,
}

export const GussetComponent: React.FC<GussetComponentProps> = (
{
    additionEditor,
    additionEditorActions,
}) => 
{
    const name: string = "Gewichtung";

    return (
        <Grid container
            spacing={3}
            direction="column"
            justifyContent="space-between">
            <>
                <Grid item md={6}>
                    <EditorSectionHeaderComponent>Zwickel</EditorSectionHeaderComponent>
                    <EditorDescriptionComponent>Ein Zwickelsbereich definiert unter welchen Bedingungen der aktuelle Zusatz als Zwickel genutzt werden kann.</EditorDescriptionComponent>
                </Grid>
                { additionEditor.addition.additionGussets.length > 0 ?
                    additionEditor.addition.additionGussets.map((additionGusset) => {
                    return (                         
                        <Grid item md={12}>
                            <BoxItem>         
                                <Grid item
                                container
                                    md={12}
                                    justifyContent='flex-end'>
                                    <IconButton
                                        style={{ outline: "none" }}
                                        onClick={() =>additionEditorActions.deleteGussetDefinition(additionGusset.index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>                       
                                <Grid item md={12}>
                                    <SelectionQualityComponent
                                        onFilterSelected={(searchText) => additionEditorActions.filterSelectedGussetQualities( {index: additionGusset.index, searchText: searchText})}
                                        onFilterUnselected={(searchText) => additionEditorActions.filterUnselectedGussetQualities( {index: additionGusset.index, searchText: searchText})}
                                        qualitySelection={additionGusset.additionGussetQualities}
                                        select={(qualities) => additionEditorActions.selectGussetQualities({ index: additionGusset.index, ids: qualities.map(x => x.id)})}
                                        unselect={(qualities) => additionEditorActions.unselectGussetQualities({ index: additionGusset.index, ids: qualities.map(x => x.id)})}
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <SelectionArticleTypeComponent
                                        onFilterSelected={(searchText) => additionEditorActions.filterSelectedGussetArticleTypes( {index: additionGusset.index, searchText: searchText})}
                                        onFilterUnselected={(searchText) => additionEditorActions.filterUnselectedGussetArticleTypes( {index: additionGusset.index, searchText: searchText})}
                                        articleTypeSelection={additionGusset.additionGussetArticleTypes}
                                        select={(articleTypes) => additionEditorActions.selectGussetArticleTypes({ index: additionGusset.index, ids: articleTypes.map(x => x.id)})}
                                        unselect={(articleTypes) => additionEditorActions.unselectGussetArticleTypes({ index: additionGusset.index, ids: articleTypes.map(x => x.id)})}
                                    />
                                </Grid>
                                <Grid container 
                                    direction="column"
                                    item md={6}>
                                    <EditorSectionHeaderComponent>Rangewerte</EditorSectionHeaderComponent>
                                    <EditorDescriptionComponent>
                                        Die Maße an ausgewählten Maßpunkten müssen sich zwischen dem Mindestwert und dem Maximalwert befinden, damit der Zwickel verwendet werden kann.
                                    </EditorDescriptionComponent>

                                </Grid>   
                                <Grid
                                    container
                                    item md={12} direction="row">
                                    <SelectGussetMassPointsComponent additionGusset={additionGusset} additionEditorActions={additionEditorActions}/> 
                                
                                </Grid>                                
                                <Grid container 
                                    direction="row"
                                    item md={6}>
                                    <EditorSectionHeaderComponent>Gewichtung</EditorSectionHeaderComponent>
                                    <EditorDescriptionComponent>
                                        Wenn für eine Konfiguration mehrere Zwickel in Frage kommen, da sie in die Rangewerte passen, wird der Zwickel empfohlen, der die höchste Gewichtung hat.
                                    </EditorDescriptionComponent>

                                </Grid>                               
                                <Grid item md={3}>
                                    <DecimalInputComponent
                                        name={name}
                                        fixedPositions={0}
                                        minValue={1}
                                        maxValue={999}
                                        isRequired={true}
                                        onChange={(value) => additionEditorActions.updateGussetPriority({index: additionGusset.index, value: value})}
                                        value={additionGusset.priority}
                                    />
                                </Grid>
                            </BoxItem>
                        </Grid>
                        )
                    })  
                    : <></>                  
                }
                <Grid item md={12}>
                    <ListButtonComponent
                        onClick={additionEditorActions.addGussetDefinition}>
                        <AddIcon /> Zwickel hinzufügen
                    </ListButtonComponent>                         
                </Grid>
            </>
        </Grid>
    );
};