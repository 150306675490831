import { Grid } from "@mui/material"
import { EditorDescriptionComponent } from "shared/components/editors/editor_description/editor_description.style"
import { DecimalInputComponent } from "shared/components/input/numberInput/decimal_input.component"

export interface PositionCategoryRangeComponentProps{
    inputValueFrom: number;
    inputValueTo: number;
    onChangeInputFrom: (value) => void 
    onChangeInputTo: (value) => void 
    isDisabled?: boolean
    minValue?: number
    maxValue?: number
}

export const PositionCategoryItemRangeComponent = ( props: PositionCategoryRangeComponentProps) => {

return (
    <Grid direction="row"
    container
    justifyContent="flex-start"
    alignContent="flex-end"
    alignItems="flex-end">
    <Grid item md={3}>
        <EditorDescriptionComponent>von Mindestwert</EditorDescriptionComponent>
    </Grid>
    <Grid item md={3}>
        <DecimalInputComponent
            name={"von"}
            minValue={props.minValue ?? 0}
            maxValue={props.maxValue ?? 999}
            isDisabled={props.isDisabled ?? false}
            value={props.inputValueFrom}
            onChange={props.onChangeInputFrom}
        />
    </Grid>
    <Grid item md={3}>
        <EditorDescriptionComponent>bis Maximalwert</EditorDescriptionComponent>
    </Grid>
    <Grid item md={3}>
        <DecimalInputComponent
            name={"bis"}
            minValue={props.minValue ?? 0}
            maxValue={props.maxValue ?? 999}
            isDisabled={props.isDisabled ?? false}
            value={props.inputValueTo}
            onChange={props.onChangeInputTo}
        />
    </Grid>
</Grid>
)
}