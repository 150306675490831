import React from "react";
import { LoadingIndicatorComponent } from "../../shared/components/loading_indicator/loading_indicator.component";
import { JsonViewer } from '@textea/json-viewer';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { AppDispatch } from '../../app/redux_store';
import { useEffect } from "react";
import { fetchMasspointHistoryDetail } from "./redux/masspoint_history_datails.thunks";
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from "notistack";

export const MassPointHistoryDetailsComponent = () => {

  const dispatch: AppDispatch = useAppDispatch();
  const state = useAppSelector((state) => state.masspoint_history_details);
  const { historyId } = useParams();

  useEffect(() => {
    dispatch(fetchMasspointHistoryDetail( Number(historyId)));
  }, [dispatch, historyId]);

  if (state.query.fetchMasspointHistoryDetail.status === "error") {
    enqueueSnackbar(state.query.fetchMasspointHistoryDetail.message, { variant: "error" });
    return <div>Fehler beim Laden der Daten</div>;
  }

  if (state.query.fetchMasspointHistoryDetail.status !== "success") {
    return <LoadingIndicatorComponent />;
  }

  return (
    <div>
      <JsonViewer displayDataTypes={false} value={JSON.parse(state.actualData.masspointHistoryDetails.content)} />
    </div>
  )
}