import { GridColDef, GridFilterItem, GridSortModel, getGridBooleanOperators } from "@mui/x-data-grid-pro";
import { IProductionInstructionOverview } from "models/production_instructions/production_instruction_overview";
import { CustomBooleanCell } from "shared/components/datagrid/custom_boolean_cell.component";
import { CustomFilterOperators } from "shared/components/datagrid/custom_filter_operators";
import { DatagridMenu } from "shared/components/datagrid/datagrid_menu.component";

export const filterProductionInstructionValue = (filterItem: GridFilterItem,
    filteredItems: IProductionInstructionOverview[]) => {
    if (filterItem.value) {
        switch (filterItem.field) {
            case 'symbol':
                filteredItems = filteredItems.filter(x => x.symbol.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'instructionPreviewText':
                filteredItems = filteredItems.filter(x => x.instructionPreviewText.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'isFreeTextEnabled':
                filteredItems = filteredItems.filter(x => filterItem.value === 'any' ? true : x.isFreeTextEnabled.toString() === filterItem.value);
                break;
            case 'isDocumentsEnabled':
                filteredItems = filteredItems.filter(x => filterItem.value === 'any' ? true : x.isDocumentsEnabled.toString() === filterItem.value);
                break;
            case 'isPublished':
                filteredItems = filteredItems.filter(x => filterItem.value === 'any' ? true : x.isPublished.toString() === filterItem.value);
                break;
        }
    }
    return filteredItems;
}

export const getGridColumns = (editProductionInstruction: (productionInstruction: IProductionInstructionOverview) => void,
    deleteProductionInstruction: (productionInstruction: IProductionInstructionOverview) => void,
    togglePublishProductionInstruction: (productionInstruction: IProductionInstructionOverview) => void,): GridColDef[] => [{
    field: "menu",
    headerName: " ",
    width: 15,
    disableColumnMenu: true,
    filterable: false,
    sortable: false,
    hideable: false,
    pinnable: false,
    renderCell: params => DatagridMenu({
        params: params, menuItemsData: [{
            label: "Anweisung editieren", action: editProductionInstruction
        }, {
            label: params.row.isPublished ? "Veröffentlichung aufheben" : "Anweisung veröffentlichen",
            action: togglePublishProductionInstruction
        }, { label: "Anweisung löschen", action: deleteProductionInstruction },]
    })
}, {
    field: "symbol",
    headerName: "Kürzel",
    disableColumnMenu: false,
    width: 100,
    sortable: false,
    hideable: false,
    pinnable: false,
    filterOperators: CustomFilterOperators(),
}, {
    field: "instructionPreviewText",
    headerName: "Anweisung",
    disableColumnMenu: false,
    flex: 1,
    sortable: false,
    hideable: false,
    pinnable: false,
    filterOperators: CustomFilterOperators(),
}, {
    field: "isFreeTextEnabled",
    headerName: "Freitext erlaubt",
    disableColumnMenu: false,
    width: 120,
    sortable: false,
    hideable: false,
    pinnable: false,
    filterOperators: getGridBooleanOperators(),
    renderCell: (params) => CustomBooleanCell({ value: params.row.isFreeTextEnabled })
}, {
    field: "isDocumentsEnabled",
    headerName: "Bilder erlaubt",
    disableColumnMenu: false,
    width: 100,
    sortable: false,
    hideable: false,
    pinnable: false,
    filterOperators: getGridBooleanOperators(),
    renderCell: (params) => CustomBooleanCell({ value: params.row.isDocumentsEnabled })
}, {
    field: "isPublished",
    headerName: "Veröffentlicht",
    disableColumnMenu: false,
    width: 100,
    sortable: false,
    hideable: false,
    pinnable: false,
    filterOperators: getGridBooleanOperators(),
    renderCell: (params) => CustomBooleanCell({ value: params.row.isPublished })
}]

export const sortBySymbolModel: GridSortModel = [{
    field: "symbol", sort: "asc",
},];