import { ThumbnailModel } from "../models/thumbnail.model";
import { ThumbnailComponent } from "../shared/components/buttons/thumbnail.component";
import massmasksImage from './images/massmasks.jpg';
import masspointsImage from './images/masspoints.jpg';

import * as style from "./home.style";

export const HomeComponent = () => {
    const thumbnails: ThumbnailModel[] = [
        new ThumbnailModel("Maßmasken", massmasksImage, "/massmasks/massmask/overview"),
        new ThumbnailModel("Maßmaske erstellen", massmasksImage, "/massmasks/massmask/create"),
        new ThumbnailModel("Maßpunkte", masspointsImage, "/masspoints"),
        new ThumbnailModel("Maßpunkt erstellen", masspointsImage, "/masspoints/masspoint/create")
    ];

    return (<style.ThumbnailWrapper>
        {thumbnails.map((thumb, i) => {
            return (<ThumbnailComponent key={i} thumbnail={thumb} />)
        })}
    </style.ThumbnailWrapper>);
}