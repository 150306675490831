import { Grid } from '@mui/material';
import { DropdownComponent } from 'shared/components/form_controls/dropdown/dropdown.component';
import { isNullOrWhitespace } from 'services/validation.service';
import { SelectionListComponent } from 'shared/components/selectionComponent/selectionList.component';
import { IProductionInstructionEditor, IProductionInstructionEditorAction } from '../production_instruction_editor.model';
import { IAvailableMappedBaseData } from 'models/available_basedata/available_mapped_base_data';
import { calculateBodyAreas, calculateMainProductLines } from 'additions/common/helpers/validityScope';

interface ProductionInstructionValidityItemComponentProps {
    mappedBaseData: IAvailableMappedBaseData,
    productionInstructionEditor: IProductionInstructionEditor,
    productionInstructionEditorActions: IProductionInstructionEditorAction,
}

export const ProductionInstructionValidityItemComponent: React.FC<ProductionInstructionValidityItemComponentProps> = (
    {
        mappedBaseData,
        productionInstructionEditor,
        productionInstructionEditorActions,
    }) => {

    const bodyAreaSelectionReadOnly = isNullOrWhitespace(productionInstructionEditor.editableValidityScope.mainProductLine.displayName);

    return (
        <Grid spacing={3}
            direction="column"
            container
            marginBottom={"20px"}
            justifyContent="space-between">
            <Grid item
                container
                direction="row">
                <Grid item>
                    <DropdownComponent
                        name="Hauptproduktlinie"
                        data={calculateMainProductLines(mappedBaseData)}
                        isLoading={false}
                        isRequired={true}
                        onSelect={productionInstructionEditorActions.updateMainProductLine}
                        displayedProperties={["displayName"]}
                        selectedValue={productionInstructionEditor.editableValidityScope.mainProductLine}
                    />
                </Grid>
                <Grid item>
                    <DropdownComponent
                        name="Körperregion"
                        data={bodyAreaSelectionReadOnly ? [] : calculateBodyAreas(mappedBaseData, productionInstructionEditor.editableValidityScope)}
                        isLoading={false}
                        isRequired={true}
                        isReadonly={bodyAreaSelectionReadOnly}
                        onSelect={productionInstructionEditorActions.updateBodyArea}
                        displayedProperties={["displayName"]}
                        selectedValue={productionInstructionEditor.editableValidityScope.bodyArea}
                    />
                </Grid>
            </Grid>
            <Grid item md={12}>
                <SelectionListComponent
                    title="Artikeltypen"
                    entityName='Artikeltypen'
                    onFilterSelected={productionInstructionEditorActions.filterSelectedArticleType}
                    onFilterUnselected={productionInstructionEditorActions.filterUnselectedArticleType}
                    allow={productionInstructionEditorActions.allowArticleType}
                    deny={productionInstructionEditorActions.denyArticleType}
                    selectionList={productionInstructionEditor.editableValidityScope.articleTypes}
                    selectEntities={productionInstructionEditorActions.selectArticleTypes}
                    unselectEntities={productionInstructionEditorActions.unselectArticleTypes}
                    showSelectedAllIcon={true}
                    disabled={isNullOrWhitespace(productionInstructionEditor.editableValidityScope.bodyArea.displayName)} />
            </Grid>
            <Grid item md={12}>
                <SelectionListComponent
                    title='Qualitäten'
                    entityName='Qualitäten'
                    onFilterSelected={productionInstructionEditorActions.filterSelectedQuality}
                    onFilterUnselected={productionInstructionEditorActions.filterUnselectedQuality}
                    allow={productionInstructionEditorActions.allowQuality}
                    deny={productionInstructionEditorActions.denyQuality}
                    selectionList={productionInstructionEditor.editableValidityScope.qualities}
                    selectEntities={productionInstructionEditorActions.selectQualities}
                    unselectEntities={productionInstructionEditorActions.unselectQualities}
                    showSelectedAllIcon={true}
                    disabled={isNullOrWhitespace(productionInstructionEditor.editableValidityScope.bodyArea.displayName)}
                    />
            </Grid>
        </Grid>
    );
};