import { SerialSizeTableHeadline, SerialSizeTableHeadlineContainer } from "./serial_sizes.style"

export const SerialSizeArticleTypeSummaryHeading = () => {
    return (
        <SerialSizeTableHeadlineContainer>
            <SerialSizeTableHeadline width="42px" />
            <SerialSizeTableHeadline width="150px">
                Artikelart
            </SerialSizeTableHeadline>
            <SerialSizeTableHeadline flex={1}>
                Größen
            </SerialSizeTableHeadline>
            <SerialSizeTableHeadline width="80px">
                ändern
            </SerialSizeTableHeadline>  
            <SerialSizeTableHeadline width="80px">
                löschen
            </SerialSizeTableHeadline> 
        </SerialSizeTableHeadlineContainer>
    )
}