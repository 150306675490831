import { GridFilterItem, GridColDef, getGridBooleanOperators } from "@mui/x-data-grid-pro";
import ICheckHistoryOverview from "models/checks_history/check_history_overview";
import { HistoryTypeEnum, HistoryValues } from "models/history_type.enum";
import { CustomBooleanCell } from "shared/components/datagrid/custom_boolean_cell.component";
import { CustomDateCell } from "shared/components/datagrid/custom_date_cell.component";
import { CustomFilterOperators } from "shared/components/datagrid/custom_filter_operators";
import { CustomTooltipHeader } from "shared/components/datagrid/custom_tooltip_header.component";
import { DatagridMenu } from "shared/components/datagrid/datagrid_menu.component";

export const buildTypeDisplayText = ( type: HistoryTypeEnum ) => {
    return HistoryValues.find(x => x.value === type)?.text;
}

export const stringify = (value: string) => !value || !value.length ? 'Alle' : value;

export const filterCheckHistoryOverviewValue = (filterItem: GridFilterItem, filteredItems: ICheckHistoryOverview[]) => {
    if(filterItem.value)
    {
        switch(filterItem.field)
        {
            case 'type':
                filteredItems = filteredItems.filter(x => buildTypeDisplayText(x.type).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;           
            case 'userName':
                filteredItems = filteredItems.filter(x => x.userName?.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;          
            case 'mainProductLineName':
                filteredItems = filteredItems.filter(x => x.mainProductLineName?.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;  
            case 'bodyAreaName':
                filteredItems = filteredItems.filter(x => stringify(x.bodyAreaName)?.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;    
            case 'articleTypeName':
                filteredItems = filteredItems.filter(x => stringify(x.articleTypeName)?.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;    
            case 'qualityName':
                filteredItems = filteredItems.filter(x => stringify(x.qualityName)?.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;    
            case 'isSideSpecific':
                filteredItems = filteredItems.filter(x => filterItem.value === 'any' ? true: x.isSideSpecific.toString() === filterItem.value);
                break;                                                                                                  
        }
    }
    return filteredItems;
}

export const getGridColumns = (
    showDetails: (history: ICheckHistoryOverview) => void,
): GridColDef[] => [
    {
        field: "menu",
        headerName: " ",
        width: 15,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        renderCell: params => DatagridMenu({params: params, menuItemsData: 
            [
                {label: "Details ansehen", action: showDetails}
            ]})
    },
    {
        field: "date",
        headerName: "Datum",
        disableColumnMenu: true,
        flex: 0.15,
        sortable: false,
        hideable: false,
        pinnable: false,
        valueGetter: (value, row) => row.mainProductLine?.name,
        renderCell: params => CustomDateCell({date: params.row.date})
    },  
    {
        field: "type",
        headerName: "Typ",
        disableColumnMenu: false,
        flex: 0.1,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
        valueGetter: (value, row) => buildTypeDisplayText(row.type) 
    },  
    {
        field: "userName",
        headerName: "Benutzer",
        disableColumnMenu: false,
        flex: 0.2,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    }, 
    {
        field: "mainProductLineName",
        headerName: "Hauptproduktlinie",
        disableColumnMenu: false,
        flex: 0.1,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    },   
    {
        field: "bodyAreaName",
        headerName: "Körperregion",
        disableColumnMenu: false,
        flex: 0.1,
        sortable: false,
        hideable: false,
        pinnable: false,
        valueGetter: (value, row) => stringify(row.bodyAreaName),
        filterOperators: CustomFilterOperators()
    },
    {
        field: "articleTypeName",
        headerName: "Artikelart",
        disableColumnMenu: false,
        flex: 0.1,
        sortable: false,
        hideable: false,
        pinnable: false,
        valueGetter: (value, row) => stringify(row.articleTypeName),
        filterOperators: CustomFilterOperators()
    },
    {
        field: "qualityName",
        headerName: "Qualtität",
        disableColumnMenu: false,
        flex: 0.1,
        sortable: false,
        hideable: false,
        pinnable: false,
        valueGetter: (value, row) => stringify(row.qualityName),
        filterOperators: CustomFilterOperators()
    },
    {
        field: "checkTypeName",
        headerName: "Prüfungstyp",
        disableColumnMenu: false,
        flex: 0.1,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators()
    },
    {
        field: "isSideSpecific",
        headerName: "Seitenabhängigkeit",
        disableColumnMenu: false,
        width: 90,
        sortable: false,
        hideable: false,
        pinnable: false,
        renderCell: params => CustomBooleanCell(params.row.isSideSpecific),
        renderHeader: () => CustomTooltipHeader({ shortText: "S.a.", tooltip: "Seitenabhängigkeit" }),
        filterOperators: getGridBooleanOperators(),
    }
]