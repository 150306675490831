import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MassMaskCommonInfoEditState, SelectBodyInformationPayload } from './massmask_common_info_edit.model';
import { editMassMask, getBodyInformations, getMassMask } from './massmask_common_info_edit.thunks';
import { updateCanExecuteEdit } from './massmask_common_info_edit.reducer';

const initialState: MassMaskCommonInfoEditState = {
    actualData: {
        massMask: null,
        isPantyTopWithStandardDimensions: false,
        isEllipticalShapeKneeFunctionZone: false,
        hideFingersAndToes: false,
        bodyInformationSelection: [],
    },
    loadedData: {
        bodyInformations: []
    },
    query: {
        getMassMask: { status: "idle", canExecute: true },
        getBodyInformations: { status: "idle", canExecute: true },
    },
    command: {
        editMassMask: { status: "idle", canExecute: false }
    }
}

export const massMaskCommonInfoEditSlice = createSlice({
    name: 'massMask/commonInfo/',
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        setMassMaskIsPantyTopWithStandardDimensions: (state, action: PayloadAction<boolean>) => {
            state.actualData.isPantyTopWithStandardDimensions = action.payload;
        },
        setMassMaskIsEllipticalShapeKneeFunctionZone: (state, action: PayloadAction<boolean>) => {
            state.actualData.isEllipticalShapeKneeFunctionZone = action.payload;
        },
        setHideFingersAndToes: (state, action: PayloadAction<boolean>) => {
            state.actualData.hideFingersAndToes = action.payload;
        },
        selectBodyInformation: (state, action: PayloadAction<SelectBodyInformationPayload>) => {
            const selectBodyInformationPayload = action.payload;
            state.actualData.bodyInformationSelection[selectBodyInformationPayload.arrayPosition].id = selectBodyInformationPayload.id;

            state.command.editMassMask.canExecute = updateCanExecuteEdit(state);
        },
        moveUp: (state, action: PayloadAction<number>) => {
            const indexToMove = action.payload;
            if (indexToMove > 0) {
                var itemToMove = state.actualData.bodyInformationSelection[indexToMove];
                var previousItem = state.actualData.bodyInformationSelection[indexToMove - 1];

                state.actualData.bodyInformationSelection[indexToMove] = previousItem;
                state.actualData.bodyInformationSelection[indexToMove - 1] = itemToMove;
            }
        },
        moveDown: (state, action: PayloadAction<number>) => {
            const indexToMove = action.payload;
            if (indexToMove < state.actualData.bodyInformationSelection.length - 1) {
                var itemToMove = state.actualData.bodyInformationSelection[indexToMove];
                var nextItem = state.actualData.bodyInformationSelection[indexToMove + 1];

                state.actualData.bodyInformationSelection[indexToMove] = nextItem;
                state.actualData.bodyInformationSelection[indexToMove + 1] = itemToMove;
            }
        },
        removeBodyInformation: (state, action: PayloadAction<number>) => {
            state.actualData.bodyInformationSelection.splice(action.payload, 1);
            state.command.editMassMask.canExecute = updateCanExecuteEdit(state);
        },
        addBodyInformation: (state) => {
            state.actualData.bodyInformationSelection.push({
                id: state.loadedData.bodyInformations[0].id
            });
            state.command.editMassMask.canExecute = updateCanExecuteEdit(state);
        },
    }, extraReducers: (builder) => {

        // editMassMask 
        builder.addCase(editMassMask.pending, (state, action) => {
            state.command.editMassMask.status = 'pending'
        }).addCase(editMassMask.rejected, (state, action) => {
            state.command.editMassMask.status = "error"
            state.command.editMassMask.message = action.error.message;
        }).addCase(editMassMask.fulfilled, (state, action) => {
            state.command.editMassMask.status = "success"

            // getMassMask
        }).addCase(getMassMask.pending, (state, action) => {
            state.query.getMassMask.status = 'pending'
        }).addCase(getMassMask.rejected, (state, action) => {
            state.query.getMassMask.status = "error"
            state.query.getMassMask.message = action.error.message;
        }).addCase(getMassMask.fulfilled, (state, action) => {
            state.query.getMassMask.status = "success";
            const payload = action.payload.getData();
            state.actualData.massMask = payload;
            state.actualData.isPantyTopWithStandardDimensions = payload.isPantyTopWithStandardDimensions;
            state.actualData.isEllipticalShapeKneeFunctionZone = payload.isEllipticalShapeKneeFunctionZone;
            state.actualData.hideFingersAndToes = payload.hideFingersAndToes;

            payload.selectedBodyInformations.map(sBi =>
                state.actualData.bodyInformationSelection.push({
                    id: sBi.id
                })
            );
            state.command.editMassMask.canExecute = true;

            //getBodyInformations
        }).addCase(getBodyInformations.pending, (state, action) => {
            state.query.getBodyInformations.status = 'pending'
        }).addCase(getBodyInformations.rejected, (state, action) => {
            state.query.getBodyInformations.status = "error"
            state.query.getBodyInformations.message = action.error.message;
        }).addCase(getBodyInformations.fulfilled, (state, action) => {
            state.query.getBodyInformations.status = "success"
            state.query.getBodyInformations.canExecute = true;

            const data = action.payload.getData();
            state.loadedData.bodyInformations = data;
        })
    }
})

export const {
    resetState,
    setMassMaskIsPantyTopWithStandardDimensions,
    setMassMaskIsEllipticalShapeKneeFunctionZone,
    setHideFingersAndToes,
    selectBodyInformation,
    moveUp,
    moveDown,
    removeBodyInformation,
    addBodyInformation,
} = massMaskCommonInfoEditSlice.actions

export default massMaskCommonInfoEditSlice.reducer