import { useAppDispatch, useAppSelector } from '../../app/hook';
import { AppDispatch } from '../../app/redux_store';
import { useEffect } from "react";
import { LoadingIndicatorComponent } from "shared/shared";
import { JsonViewer } from '@textea/json-viewer';
import { useSnackbar } from "notistack";
import { fetchMassmaskHistoryDetails } from './redux/massmask_history_details.thunks';
import { useParams } from 'react-router-dom';

export const MassMaskHistoryDetailsComponent = () => {
    ;
    const dispatch: AppDispatch = useAppDispatch();
    const { historyId } = useParams();
    const state = useAppSelector((state) => state.massmasks_history_details);

    useEffect(() => {
        dispatch(fetchMassmaskHistoryDetails(Number(historyId)));
    }, [dispatch, historyId]);

    const { enqueueSnackbar } = useSnackbar();

    if (state.query.fetchMassmaskHistoryDetails.status === "error") {
        enqueueSnackbar(state.query.fetchMassmaskHistoryDetails.message, { variant: "error" });
        return <div>Fehler beim Laden der Daten</div>;
    }

    if (state.query.fetchMassmaskHistoryDetails.status !== "success") {
        return <LoadingIndicatorComponent />;
    }

    return (
        <div>
            <JsonViewer displayDataTypes={false} value={JSON.parse(state.data.massmaskHistoryDetails.content)} />
        </div>
    )
}