import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'app/redux_store';
import MassPointGroupService from 'services/masspoint_group.service';
import { errorMessages, handleResponseErrors } from 'shared/networking/error_handling';

export const fetchMasspointGroupOverviews = createAsyncThunk(
    'masspoints/masspoint_group_overview/fetchMasspointGroupOverviews',
    async (thunkApi) => {
        const response = await MassPointGroupService.getMassPointGroupOverviews();
        handleResponseErrors(response, "Maßpunktgruppe");
        return response;
    }
)

export const confirmDeleteMasspointGroup = createAsyncThunk(
    'masspoints/masspoint_group_overview/confirmDeleteMasspointGroup',
    async (_, { getState }) => {
        const state = getState() as RootState
        const masspointGroupId = state.masspoint_group_overviews.actualData.selectedMasspointGroupOverview.id
        const responseDelete = await MassPointGroupService.deleteMassPointGroup(masspointGroupId)
        handleResponseErrors(responseDelete, "Maßpunktgruppen", errorMessages.dataAllreadyUsedExists("Maßpunktgruppe"));

        const response = await MassPointGroupService.getMassPointGroupOverviews();
        handleResponseErrors(response, "Maßpunktgruppen");
        return response;
    }
)