import React from "react";
import { DropdownComponent } from 'shared/components/form_controls/dropdown/dropdown.component';
import { EditorDescriptionComponent } from 'shared/components/editors/editor_description/editor_description.style';
import { ListButtonComponent } from "shared/components/buttons/list_button.component";
import AddIcon from "@mui/icons-material/Add";
import { Grid, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DecimalInputComponent } from "shared/components/input/numberInput/decimal_input.component";
import { IAdditionGussetEditorAction, IAdditionGussetViewModel } from "models/additions/addition_editor.model";

interface SelectGussetMassPointsComponentProps {
    additionGusset: IAdditionGussetViewModel,
    additionEditorActions: IAdditionGussetEditorAction,
}

export const SelectGussetMassPointsComponent: React.FC<SelectGussetMassPointsComponentProps> = ({
    additionGusset,
    additionEditorActions
}) => {
    const undefinedMasspoint = additionGusset.additionGussetMassPoints.findIndex(x => x.displayName == null) >= 0;

    return (
        <Grid container spacing={3}>
            {additionGusset.additionGussetMassPoints.map((masspoint) => (
                <Grid container
                    item
                    direction='row'
                    justifyContent="flex-start"
                    alignContent="flex-end"
                    alignItems="flex-end"
                    key={`additiongusset_masspoint_${masspoint.displayName}`}>
                    <Grid item md={3}>
                        <DropdownComponent name="Maßpunkt"
                            data={additionGusset.availableGussetMassPointModels}
                            isRequired={true}
                            onSelect={(e) => additionEditorActions.updateGussetMasspoint({ index: additionGusset.index, masspoint: e, targetMasspoint: masspoint })}
                            displayedProperties={["displayName"]}
                            selectedValue={masspoint}
                        />
                    </Grid>
                    <Grid item md={2}>
                        <EditorDescriptionComponent>von Mindestwert</EditorDescriptionComponent>
                    </Grid>
                    <Grid item md={2}>
                        <DecimalInputComponent
                            name={"von"}
                            fixedPositions={1}
                            placeholder='0.1'
                            minValue={0.1}
                            maxValue={999.9}
                            isRequired={true}
                            onChange={(value) => additionEditorActions.updateGussetRangeMinimalValue({ index: additionGusset.index, masspoint: masspoint, value: value })}
                            value={masspoint.minimalValue}
                        />
                    </Grid>
                    <Grid item md={2}>
                        <EditorDescriptionComponent>bis Maximalwert</EditorDescriptionComponent>
                    </Grid>
                    <Grid item md={2}>
                        <DecimalInputComponent
                            name={"bis"}
                            fixedPositions={1}
                            placeholder='0.1'
                            minValue={0.1}
                            maxValue={999.9}
                            isRequired={true}
                            onChange={(value) => additionEditorActions.updateGussetRangeMaximalValue({ index: additionGusset.index, masspoint: masspoint, value: value })}
                            value={masspoint.maximalValue}
                        />
                    </Grid>
                    <Grid item md={1}>
                        <IconButton
                            style={{ outline: "none" }}
                            onClick={() => additionEditorActions.deleteGussetMasspoint({ index: additionGusset.index, masspoint })}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}
            <Grid container
                item>
                <ListButtonComponent
                    disabled={undefinedMasspoint}
                    onClick={() => additionEditorActions.addGussetMasspoint(additionGusset.index)}>
                    <AddIcon /> Maßpunkt hinzufügen
                </ListButtonComponent>
            </Grid>
        </Grid>

    );
}
