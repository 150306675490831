import DeleteIcon from '@mui/icons-material/Delete';
import { DropdownComponent } from "shared/shared";
import { Grid, IconButton } from "@mui/material";
import { EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style"
import { IAdditionSelection, IAttributeCategorySelection, IAttributeSelection, IDefaultAdditionByQualityAndAttributeConfigurationViewModel, ISearchAddition } from './policy_default_addition_by_qu_and_att.model';
import { BoxItem } from 'check/policy/components/policy_box_item.style';
import { SelectionListComponent } from 'shared/components/selectionComponent/selectionList.component';

interface PolicyDefaultAdditionAttributeConfigurationProps {
    attributeConfiguration: IDefaultAdditionByQualityAndAttributeConfigurationViewModel;
    deleteConfiguration: (index: number) => {};
    selectAttributeCategory: (category: IAttributeCategorySelection) => {};
    selectAttribute: (attribute: IAttributeSelection) => {};
    filterSelectedAdditions: (additions: ISearchAddition ) => {};
    filterUnselectedAdditions: (additions : ISearchAddition ) => {};
    selectAdditions: (selectedAdditions: IAdditionSelection) => {};
    unSelectAdditions: (selectedAdditions: IAdditionSelection) => {};
}

export const PolicyDefaultAdditionAttributeConfigurationComponent: React.FC<PolicyDefaultAdditionAttributeConfigurationProps> = ({ 
    attributeConfiguration,
    deleteConfiguration,
    selectAttributeCategory,
    selectAttribute,
    filterSelectedAdditions,
    filterUnselectedAdditions,
    selectAdditions,
    unSelectAdditions,
}) => {
    return (
        <BoxItem>
            <Grid container item md={12}
                direction="column"
                spacing={3}>
                <Grid item
                    container
                    direction="row"            
                    alignItems="stretch"
                    justifyContent="space-between"
                    spacing={3}>
                    <Grid item
                        md={9}>
                        <EditorSectionHeaderComponent style={{ marginTop: "0px" }}>{`Attribute Konfiguration ${attributeConfiguration.index}`}</EditorSectionHeaderComponent>  
                    </Grid>
                    <Grid item
                        container
                        md={3}
                        justifyContent='flex-end'>
                        <IconButton
                            style={{ outline: "none" }}
                            onClick={() =>deleteConfiguration(attributeConfiguration.index)}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <DropdownComponent name="Attribute Kategorie"
                            data={attributeConfiguration.attributeCategoryValues}
                            isRequired={true}
                            onSelect={(e) => selectAttributeCategory({ index: attributeConfiguration.index, item: e})}
                            displayedProperties={["displayName"]}
                            selectedValue={attributeConfiguration.selectedAttributeCategoryValue}                           
                        />    
                        <DropdownComponent name="Attribute"
                            key={`${attributeConfiguration.index}-${attributeConfiguration.selectedAttributeValue}`}
                            data={attributeConfiguration.attributeValues}
                            isRequired={true}
                            onSelect={(e) => selectAttribute({ index: attributeConfiguration.index, item: e})}
                            displayedProperties={["displayName"]}
                            selectedValue={attributeConfiguration.selectedAttributeValue}                           
                        />  
                    </Grid>
                    <Grid item md={12}>
                        <SelectionListComponent
                            title={"Zusätze"}
                            entityName={"Zusätze"}
                            onFilterSelected={(searchText) => { filterSelectedAdditions({index: attributeConfiguration.index, searchText: searchText}) }}
                            onFilterUnselected={(searchText) => { filterUnselectedAdditions({index: attributeConfiguration.index, searchText: searchText}) }}
                            selectionList={ attributeConfiguration.additions}
                            selectEntities={(ids) => { selectAdditions({index: attributeConfiguration.index, ids: ids}) }}
                            unselectEntities={(ids) => { unSelectAdditions({index: attributeConfiguration.index, ids: ids}) }}
                        />                       
                    </Grid>
                </Grid>
            </Grid> 
        </BoxItem>
)}