import { createAsyncThunk } from '@reduxjs/toolkit';
import MassMasksService from "../../../services/massmask.service";
import { handleResponseErrors } from '../../../shared/networking/error_handling';

export const getMassMasks = createAsyncThunk(
    'simulator/massmask_selection/getMasMasks',
    async (_) => {
        const response = await MassMasksService.getMassmasksOverview();
        handleResponseErrors(response, "Maßmasken");
        return response;
    }
)

export const simulateMassMask = createAsyncThunk(
    'simulator/massmask_selection/simulateMassMask',
    async (_) => { }
)