import styled from "@emotion/styled";
import { TooltipComponent } from "../tooltip/tooltip"

interface CustomTooltipHeaderProps {
    shortText: string
    tooltip: string
}

export const CustomTooltipHeader: React.FC<CustomTooltipHeaderProps> = ({ shortText, tooltip }) => {
    return <CustomTooltipHeaderStyled>
        <span style={{ marginRight: "3px" }}>{shortText}</span><TooltipComponent text={tooltip} />
    </CustomTooltipHeaderStyled>
}

export const CustomTooltipHeaderStyled = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    padding: 2px;
    font-size: 14px;
`;

