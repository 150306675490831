import { doesExist, isNullOrWhitespace } from "services/validation.service";
import { BodyInformationCreateState } from "./bodyinformation_create.model";

export const updateCanExecuteCreate = (state: BodyInformationCreateState) => {
    state.command.createBodyInformation.canExecute = canExecuteCreateBodyInformation(state);
}

const canExecuteCreateBodyInformation = (state: BodyInformationCreateState): boolean => {
    return !isNullOrWhitespace(state.actualData.editor.translationKey)
        && doesExist(state.actualData.editor.translationKey)
        && state.actualData.editor.entriesKeys.every(x => !isNullOrWhitespace(x.translationKey) && !isNullOrWhitespace(x.shortKey) && x.shortKey.length === 3)
        && state.actualData.editor.entriesKeys.length > 0;
}