import React, { Fragment } from "react";
import { IAttributeConfigurationViewModel, IMassPointCriteriaSelection, IMassPointValueSelection, ISizeConfigurationViewModel, ISizeViewModel } from "./serial_size_editor.model";
import { BoxItem } from "./serial_sizes.style";
import { SizeSummaryDetailComponent } from "./serial_size_editor_size_summary_detail.component";
import { SizeEditorComponent } from "./serial_size_editor_size_editor.component";
import IAvailableSize from "models/available_basedata/available_size";

export interface SizeAccordionComponentProps {
    sizeSummaries: ISizeViewModel[];
    configurationSize: ISizeConfigurationViewModel;
    configurationAttribute: IAttributeConfigurationViewModel;
    takeSize: () => void;
    cancelSize: () => void;
    selectSize: (size: IAvailableSize) => void;
    editSize: (size: ISizeViewModel) => void;
    deleteSize: (size: ISizeViewModel) => void;
    addMasspoint: () => void;
    selectMasspoint: (masspoint: IMassPointCriteriaSelection) => void;
    updateValueFrom: (masspoint: IMassPointValueSelection) => void;
    updateValueTo: (masspoint: IMassPointValueSelection) => void;
    deleteMasspoint: (index: number) => void;
}

export const SizeAccordionComponent: React.FC<SizeAccordionComponentProps> = ({
    sizeSummaries,
    configurationSize,
    configurationAttribute,
    editSize,
    deleteSize,
    selectSize,
    takeSize,
    cancelSize,
    addMasspoint,
    selectMasspoint,
    updateValueFrom,
    updateValueTo,
    deleteMasspoint
}) => {

    const disabledSize = configurationSize != undefined || configurationAttribute != undefined;

    return (
        <>
            {
                sizeSummaries?.map((sizeSummary, index) => {

                    const showDetails = configurationSize != undefined
                        && !configurationSize.isNewSize
                        && configurationSize.oldSize.id === sizeSummary.size.id;
                    return <Fragment key={index}>
                        <>
                            <SizeSummaryDetailComponent
                                sizeSummary={sizeSummary}
                                disabledSize={disabledSize}
                                editSize={editSize}
                                deleteSize={deleteSize}
                            />
                            {showDetails && <>
                                <BoxItem marginTop={1} marginBottom={1}>
                                    <SizeEditorComponent
                                        configurationSize={configurationSize}
                                        selectSize={selectSize}
                                        takeSize={takeSize}
                                        cancelSize={cancelSize}
                                        addMasspoint={addMasspoint}
                                        selectMasspoint={selectMasspoint}
                                        updateValueFrom={updateValueFrom}
                                        updateValueTo={updateValueTo}
                                        deleteMasspoint={deleteMasspoint}
                                    />
                                </BoxItem>
                            </>}
                        </>
                    </Fragment>
                })
            }
        </>
    );
}