import React from "react";
import { DropdownComponent } from 'shared/shared';
import { FormControlLabel, Switch, Checkbox } from "@mui/material";
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { AppDispatch } from '../../app/redux_store';
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { LoadingIndicatorComponent } from '../../shared/components/loading_indicator/loading_indicator.component';
import { updateMainProductLine, updateBodyArea, updateArticleType, updateQuality, updatePlausibilityCheck, updateIsSideSpecific, cancelCheck, resetState, handleError } from "./redux/check_create.slice";
import { createCheck, initData } from './redux/check_create.thunks';
import * as style from "./check_create.style";
import { LoadingOverlayComponent, LoadingOverlayContainer } from '../../shared/components/loading_overlay/loading_overlay.component';
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";

export const CheckCreateComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.check_create);

    useEffect(() => {
        dispatch(initData());
    }, [dispatch]);

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();


    if (state.query.fetchPlausibilityCheckType.status === "error") {
        enqueueSnackbar(state.query.fetchPlausibilityCheckType.message, { variant: "error" });
        return <div>Fehler beim Laden der Daten</div>;
    }  
    
    if ( state.query.fetchBaseData.status === "error") {
        enqueueSnackbar(state.query.fetchBaseData.message, { variant: "error" });
        return <div>Fehler beim Laden der Daten</div>;
    }

    if (state.query.initData.status === "pending") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.createCheck.status === "success") {
        enqueueSnackbar("Prüfung gespeichert", { variant: "success" });
        navigate(`/plausibilitycheck/check/${state.data.checkCreate.checkId}/rule/false`);
        dispatch(resetState());
    }

    if (state.command.cancelCheck.status === "success") {
        navigate("/plausibilitycheck/check");
        dispatch(resetState());
    }

    if (state.command.createCheck.status === "error") {
        enqueueSnackbar(state.command.createCheck.message, { variant: "error" });
        dispatch(handleError());
    }

    if (state.command.createCheck.status === "warning") {
        enqueueSnackbar(state.command.createCheck.message, { variant: "warning" });
        dispatch(handleError());
    }

    const isSaving = state.command.createCheck.status === "pending";

    return (<>
        <LoadingOverlayContainer>
            {isSaving ? <LoadingOverlayComponent /> : <></>}

            <style.Box>
                <DropdownComponent
                    name="Hauptproduktlinie"
                    data={state.data.baseData.availableMainProductLines}
                    isRequired={true}
                    isLoading={false}
                    onSelect={(value) => dispatch(updateMainProductLine(value))}
                    displayedProperties={["name"]}
                    selectedValue={state.data.selectedMainProductLine}
                />
                <DropdownComponent
                    name="Körperregion"
                    data={state.data.baseData.availableBodyAreas}
                    isRequired={true}
                    isLoading={false}
                    onSelect={(value) => dispatch(updateBodyArea(value))}
                    displayedProperties={["name"]}
                    selectedValue={state.data.selectedBodyArea}
                />
                <DropdownComponent
                    name="Artikelart"
                    data={state.data.baseData.availableArticleTypes}
                    isRequired={true}
                    isLoading={false}
                    onSelect={(value) => dispatch(updateArticleType(value))}
                    displayedProperties={["erpId", "name"]}
                    selectedValue={state.data.selectedArticleType}
                />
                <DropdownComponent
                    name="Qualität"
                    data={state.data.baseData.availableQualities}
                    isRequired={true}
                    isLoading={false}
                    onSelect={(value) => dispatch(updateQuality(value))}
                    displayedProperties={["erpId", "name"]}
                    selectedValue={state.data.selectedQuality}
                />

            </style.Box>
            <style.Box>
                <DropdownComponent
                    name="Prüfungstyp"
                    data={state.data.plausibilityChecks}
                    isRequired={true}
                    isLoading={false}
                    onSelect={(value) => dispatch(updatePlausibilityCheck(value))}
                    displayedProperties={["name"]}
                    selectedValue={state.data.selectedPlausibilityCheckType}
                />
                <style.IsSideSpecificWrapper>
                    <FormControlLabel
                        control={<Switch name="IsSideSpecificSwitch" />}
                        label="Seitenabhängig"
                        checked={state.data.checkCreate.isSideSpecific}
                        onChange={(event, value) => dispatch(updateIsSideSpecific(value))}
                        labelPlacement="end"
                    />
                </style.IsSideSpecificWrapper>
            </style.Box>

            {state.data.showIsUniqueRules
                ? <style.Box>
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="isuniquerules-checktype-checkbox"
                                    checked={state.data.selectedPlausibilityCheckType.isUniqueRules}
                                    disabled={true}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="eindeutiges Regelwerk"
                        />
                    </div>
                </style.Box>
                : <></>
            }

            <EditorButtonComponent
                    canExecute={state.command.createCheck.canExecute}
                    save={() => dispatch(createCheck())}
                    cancelSave={() => dispatch(cancelCheck())}
            />
        </LoadingOverlayContainer>
    </>
    );
};
