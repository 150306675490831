import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PolicySideSeamState } from "./policy_side_seam.model";
import { IAdditionPolicyInformation } from "models/additions/addition_policy_information";
import { sortAdditions } from "check/policy/helper/policy.helper";
import { updateCanSave } from "./policy_side_seam.reducer";
import { cancelSave, getAdditions, getPolicy, initData, savePolicy, updatePolicy } from "./policy_side_seam.thunks";

const initialState: PolicySideSeamState = {
    data: { 
        policy: { name: "", ignoreOnQuotation: false},
        selectedAddition: null,
    },
    loadedData: {
        additions: [],
        policy: null,
        isEdit: false,
    },
    command:{
        savePolicy: { status: "idle", canExecute: false },
        cancelSave: { status: "idle", canExecute: true }
    },
    query: {
        fetchAdditions: { status: "idle", canExecute: true },
        getPolicy: {status: "idle", canExecute: true },
        initData: {status: "idle", canExecute: true}
    }
}

export const policySideSeam = createSlice({
    name: 'policy_side_seam',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = initialState.data;
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        changeName: (state, action: PayloadAction<string>) => {
            state.data.policy.name = action.payload;
            updateCanSave(state);
        },
        selectAddition: (state, action: PayloadAction<IAdditionPolicyInformation>) => {
            state.data.selectedAddition = action.payload;
            updateCanSave(state);
        },
        toggleIgnoreOnQuotation: (state, action: PayloadAction<boolean>) => {
            state.data.policy.ignoreOnQuotation = action.payload;
            updateCanSave(state);
        },               
        completedSave: (state) => {
            state.command.savePolicy = initialState.command.savePolicy;
        }
    }, extraReducers: (builder) => {

        // fetchAdditions
        builder.addCase(getAdditions.pending, (state) => {
            state.query.fetchAdditions.status = "pending"
            state.query.fetchAdditions.canExecute = false;
        }).addCase(getAdditions.rejected, (state, action) => {
            state.query.fetchAdditions.status = "error"
            state.query.fetchAdditions.message = action.error.message;
            state.query.fetchAdditions.canExecute = true;
        }).addCase(getAdditions.fulfilled, (state, action) => {
            state.query.fetchAdditions.status = "success"
            state.query.fetchAdditions.canExecute = true;
            state.loadedData.additions = action.payload.getData().sort(sortAdditions);
            updateCanSave(state);
   
        // getPolicy
        }).addCase(getPolicy.pending, (state) => {
            state.query.getPolicy.status = 'pending'
            state.query.getPolicy.canExecute = false;
        }).addCase(getPolicy.rejected, (state, action) => {
            state.query.getPolicy.status = "error"
            state.query.getPolicy.canExecute = true;
            state.query.getPolicy.message = action.error.message;
        }).addCase(getPolicy.fulfilled, (state, action) => {
            state.query.getPolicy.status = "success"
            state.query.getPolicy.canExecute = false;
            state.loadedData.policy = action.payload.getData();

        // savePolicy
        }).addCase(savePolicy.pending, (state) => {
            state.command.savePolicy.status = 'pending'
            state.command.savePolicy.canExecute = false;
        }).addCase(savePolicy.rejected, (state, action) => {
            state.command.savePolicy.status = "error"
            state.command.savePolicy.canExecute = true;
            state.command.savePolicy.message = action.error.message;
        }).addCase(savePolicy.fulfilled, (state) => {
            state.command.savePolicy.status = "success"
            state.command.savePolicy.canExecute = false;

        // updatePolicy
        }).addCase(updatePolicy.pending, (state) => {
            state.command.savePolicy.status = 'pending'
            state.command.savePolicy.canExecute = false;
        }).addCase(updatePolicy.rejected, (state, action) => {
            state.command.savePolicy.status = "error"
            state.command.savePolicy.canExecute = true;
            state.command.savePolicy.message = action.error.message;
        }).addCase(updatePolicy.fulfilled, (state) => {
            state.command.savePolicy.status = "success"
            state.command.savePolicy.canExecute = false;

        // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancelSave.status = 'pending'
            state.command.cancelSave.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancelSave.status = "success"
            state.command.cancelSave.canExecute = false;

        // initData
        }).addCase(initData.pending, (state) => {
            state.query.initData.status = "pending"
            state.query.initData.canExecute = false;
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.canExecute = true;
            state.query.initData.message = action.error.message;
        }).addCase(initData.fulfilled, (state) => {
            state.query.initData.status = "success"
            state.query.initData.canExecute = true;
            if(state.loadedData.policy) {
                state.data.policy = { 
                    id: state.loadedData.policy.id,
                    name: state.loadedData.policy.name,
                    ignoreOnQuotation: state.loadedData.policy.ignoreOnQuotation
                };
                state.loadedData.isEdit = true;
                state.data.selectedAddition = state.loadedData.additions.find(x => x.id === state.loadedData.policy.additionId);
            } else {
                state.loadedData.isEdit = false;
            } 
            updateCanSave(state);
        })
    }
})

export const {
    resetState,
    changeName,
    selectAddition,
    toggleIgnoreOnQuotation,
    completedSave,
} = policySideSeam.actions

export default policySideSeam.reducer