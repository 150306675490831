import theme from "shared/style/theme";
import styled from "@emotion/styled";

export const InfoArea = styled.div`
    margin-bottom: 20px;
`;

export const Title = styled.div`
    margin-top: 20px;
    font-weight: bold;
    font-size: medium;
    color: ${theme.colors.text};
`;

export const Description = styled.div`
    padding-top: 10px;
    color: ${theme.colors.text};
`;