import { Grid } from "@mui/material";
import { IMasspoint } from "models/masspoints/masspoint";
import { DropdownComponent } from "shared/shared";
import { ErrorTextComponent } from "../policy_mandatory_category_by_masspoint/components/addition_category_selection.style";
import { IMassPointCriteria } from "../policy_mandatory_category_by_masspoint/components/policy_mandatory_category_by_masspoint.model";

interface MasspointCriteriaSelection {
    selectedMasspointCriteria: IMassPointCriteria;
    allMasspointCriteria: IMassPointCriteria[];
    select:(masspoint: IMassPointCriteria) => void;
}

export const MasspointCriteriaSelectionComponent: React.FC<MasspointCriteriaSelection> = ({ selectedMasspointCriteria, allMasspointCriteria, select }) => {
    return (
        <Grid item container md={12}
            direction="row">
            <DropdownComponent name="Maßpunkt"
                data={allMasspointCriteria}
                isRequired={true}
                minWidth="350px"
                onSelect={(e) => select(e)}
                displayedProperties={["displayName"]}
                selectedValue={selectedMasspointCriteria}                           
            />
            { allMasspointCriteria.length === 0 ?
                <ErrorTextComponent>
                    Es kann kein Maßpunkt zugewiesen werden.
                </ErrorTextComponent> : <></>
            }
        </Grid>
    );
};