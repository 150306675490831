import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AdditionHistoryOverviewsState } from './addition_history.model';
import { fetchAdditionHistoryOverviews } from './addition_history.thunks';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import { filterAdditionHistoryOverviews } from './addition_history.reducer';

const initialState: AdditionHistoryOverviewsState = {
    loadedData: {
        additionHistoryOverviews: [],
    },
    actualData: {
        additionHistoryOverviews: [],
        searchFilter: "",
        gridFilters: { items: [] }
    },
    query: {
        fetchAdditionHistoryOverviews: { status: "idle", canExecute: true },
    },
}

export const additionHistoryOverviewSlice = createSlice({
    name: 'addition_history/addition_history_overview',
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.loadedData = initialState.loadedData;
            state.query = initialState.query;
        },
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.actualData.searchFilter = action.payload;
            filterAdditionHistoryOverviews(state);
        },
        setGridFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.gridFilters = action.payload;
            filterAdditionHistoryOverviews(state);
        },
    }, extraReducers: (builder) => {

        // fetch AdditionHistoryOverviews
        builder.addCase(fetchAdditionHistoryOverviews.pending, (state, action) => {
            state.query.fetchAdditionHistoryOverviews.status = "pending"
            state.query.fetchAdditionHistoryOverviews.canExecute = false;
        }).addCase(fetchAdditionHistoryOverviews.rejected, (state, action) => {
            state.query.fetchAdditionHistoryOverviews.status = "error"
            state.query.fetchAdditionHistoryOverviews.message = action.error.message;
            state.query.fetchAdditionHistoryOverviews.canExecute = true;
        }).addCase(fetchAdditionHistoryOverviews.fulfilled, (state, action) => {
            state.query.fetchAdditionHistoryOverviews.status = "success"
            state.query.fetchAdditionHistoryOverviews.message = undefined;
            state.query.fetchAdditionHistoryOverviews.canExecute = true;
            state.loadedData.additionHistoryOverviews = action.payload.getData();
            filterAdditionHistoryOverviews(state);
        })
    }
})

export const {
    resetState,
    setSearchFilter,
    setGridFilters,
} = additionHistoryOverviewSlice.actions

export default additionHistoryOverviewSlice.reducer