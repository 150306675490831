import { Grid } from '@mui/material';
import { EditorSectionHeaderComponent } from 'shared/components/editors/editor_header/editor_header.style'
import { ProductionInstructionValidityItemComponent } from './production_instruction_validity_item.component';
import { EditorDescriptionComponent } from 'shared/components/editors/editor_description/editor_description.style';
import { ListEditorComponent } from 'shared/components/editors/list_editor/list_editor.component';
import { EditorConfigurationComponent } from 'shared/components/editors/list_editor/editor_configuration.component';
import { isValidValidityScopeByIndex } from 'additions/common/helpers/validityScope';
import { doesExist } from 'services/validation.service';
import { ListEditorMode } from 'shared/components/editors/list_editor/enums/EditorMode';
import { EditorSummaryDefinition } from 'shared/components/editors/list_editor/models/EditorSummaryDefinition';
import { IAvailableMappedBaseData } from 'models/available_basedata/available_mapped_base_data';
import { IValidityScope } from 'models/additions/validity_scope';
import { IProductionInstructionEditor, IProductionInstructionEditorAction } from '../production_instruction_editor.model';

interface ProductionInstructionValidityComponentProps {
    mappedBaseData: IAvailableMappedBaseData,
    productionInstructionEditor: IProductionInstructionEditor,
    productionInstructionEditorActions: IProductionInstructionEditorAction,
}

export const ProductionInstructionValidityComponent: React.FC<ProductionInstructionValidityComponentProps> = (
    {
        mappedBaseData,
        productionInstructionEditor,
        productionInstructionEditorActions,
    }) => {

    const editorSummaryDefinitions: EditorSummaryDefinition[] = [{
        heading: "Hauptproduktlinie",
        flex: 1,
        displayValue: (value: IValidityScope) => (value.mainProductLine.displayName)
    },
    {
        heading: "Körperregion",
        flex: 1,
        displayValue: (value: IValidityScope) => (value.bodyArea.displayName)
    }];

    const renderContent = () => {
        return (
            <Grid item>
                <ProductionInstructionValidityItemComponent
                    mappedBaseData={mappedBaseData}
                    productionInstructionEditor={productionInstructionEditor}
                    productionInstructionEditorActions={productionInstructionEditorActions} />
            </Grid>
        )
    };

    const isEditableScopeValid = () => {
        return productionInstructionEditor.editableValidityScope.bodyArea && productionInstructionEditor.editableValidityScope.mainProductLine
    }

    const renderNewConfiguration = () => {
        return <EditorConfigurationComponent
            renderContent={renderContent}
            disabledTakeConfiguration={!isEditableScopeValid()}
            onTakeConfiguration={productionInstructionEditorActions.applyEditableScope}
            onCancelConfiguration={productionInstructionEditorActions.cancelEditableScope}
        />
    }

    const isValidScope = (index: number) => {
        return isValidValidityScopeByIndex(index, productionInstructionEditor.validityScopes, mappedBaseData);
    }

    const getEditorMode = () => {
        if (doesExist(productionInstructionEditor.editableValidityScope)) {
            return productionInstructionEditor.editableValidityScopeIndex === -1 ? ListEditorMode.New : ListEditorMode.Edit;
        } else {
            return ListEditorMode.None;
        }
    }

    return (<Grid container
        spacing={3}
        direction="column"
        justifyContent="space-between">
        <Grid item md={6}>
            <EditorSectionHeaderComponent>Gültigkeitsbereiche</EditorSectionHeaderComponent>
            <EditorDescriptionComponent>Ein Gültigkeitsbereich definiert unter welchen Bedingungen die aktuelle Produktionsanweisung angezeigt werden kann. Hier ist es möglich für jede Hauptproduktlinie/Körperregion verschiedene Kombinationen aus Artikelarten und Qualitäten zu erlauben bzw verbieten.</EditorDescriptionComponent>
        </Grid>
        <Grid item md={6}>
            <EditorSectionHeaderComponent>Erlauben</EditorSectionHeaderComponent>
            <EditorDescriptionComponent>wird die Artikelart oder Qualität in der Produktkonfiguration ausgewählt, dann sind sie für die ausgewählte Kombination aus Hauptproduktlinie und Körperregion gültig</EditorDescriptionComponent>
        </Grid>
        <Grid item md={6}>
            <EditorSectionHeaderComponent>Verbieten</EditorSectionHeaderComponent>
            <EditorDescriptionComponent>wird die Artikelart oder Qualität in der Produktkonfiguration ausgewählt, dann sind sie für die ausgewählte Kombination aus Hauptproduktlinie und Körperregion gesperrt</EditorDescriptionComponent>
        </Grid>
        <Grid item md={6}>
            <EditorDescriptionComponent>Alle Felder mit * sind Pflichtangaben.</EditorDescriptionComponent>
        </Grid>
        <Grid item md={12}>
            <ListEditorComponent
                addButtonText="Neuen Gültigkeitsbereich hinzufügen"
                editorSummaryDefinitions={editorSummaryDefinitions}
                items={productionInstructionEditor.validityScopes}
                onEditConfiguration={productionInstructionEditorActions.editScopeConfiguration}
                onDeleteConfiguration={productionInstructionEditorActions.deleteValidityScope}
                onNewConfiguration={productionInstructionEditorActions.addValidityScope}
                isValid={isValidScope}
                renderConfiguration={renderNewConfiguration}
                editorMode={getEditorMode()}
                editableConfigurationIndex={productionInstructionEditor.editableValidityScopeIndex} />
        </Grid>
    </Grid>
    );
};