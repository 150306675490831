import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MassImageConfigurationCreateState } from './create_config.model';
import { updateCanUploadConfigFile } from './create_config.reducer';
import { createMassimageConfig } from './create_config.thunks';

const initialState: MassImageConfigurationCreateState = {
    loadedData: {
        imageId: 0,
    },
    actualData: {
        configFile: {
            file: null,
        },
    },
    command: {
        createConfigFile: { status: "idle", canExecute: false },
        uploadConfigFile: { status: "idle", canExecute: false },
    },
}

export const createMassImageConfigSlice = createSlice({
    name: 'create_massimage_config',
    initialState,
    reducers: {
        initCurrentMassImage: (state, action: PayloadAction<number>) => {
            state.loadedData.imageId = action.payload;
        },
        resetState: (state) => {
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
        },
        updateConfigFile: (state, action: PayloadAction<File>) => {
            state.actualData.configFile.file = action.payload;
            updateCanUploadConfigFile(state);
        },
        createConfigFileErrorCompleted: (state) => {
            state.command.createConfigFile.status = "idle";
        },
        resetCreateConfigFile: (state) => {
            state.command.uploadConfigFile.status = "idle";
            state.command.createConfigFile.status = "idle";
        }
    }, extraReducers: (builder) => {
        //createConfigFile
        builder.addCase(createMassimageConfig.pending, (state, action) => {
            state.command.createConfigFile.status = "pending"
        }).addCase(createMassimageConfig.rejected, (state, action) => {
            state.command.createConfigFile.status = "error"
        }).addCase(createMassimageConfig.fulfilled, (state, action) => {
            state.command.createConfigFile.status = "success"
        })
    }
})

export const {
    initCurrentMassImage,
    resetState,
    resetCreateConfigFile,
    updateConfigFile,
    createConfigFileErrorCompleted
} = createMassImageConfigSlice.actions

export default createMassImageConfigSlice.reducer