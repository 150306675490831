import { EnumDescriptor } from "../../enum_descriptor";

export enum RuleTypeEnum {
    Undefined,
    Error,
    Warning,
    Both,
}

export const RuleTypeEnumValues: Array<
    EnumDescriptor<RuleTypeEnum>
> = [
        { value: RuleTypeEnum.Error, text: "Fehler" },
        { value: RuleTypeEnum.Warning, text: "Warnung" },
        { value: RuleTypeEnum.Both, text: "Beides" },];
