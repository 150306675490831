import { ThumbnailModel } from "models/thumbnail.model";
import { ThumbnailComponent } from "../../shared/components/buttons/thumbnail.component";
import massmasksImage from './images/massmasks.jpg';
import masspointsImage from './images/masspoints.jpg';
import checktypeImage from './images/types.jpg';
import checkImage from './images/minmax.jpg';
import additionImage from './images/additions.jpg';
import * as style from "./history_overview.style";

export const HistoryOverviewComponent = () => {
    const thumbnails: ThumbnailModel[] = [
        new ThumbnailModel("Maßmasken", massmasksImage, "/history/massmask"),
        new ThumbnailModel("Maßpunkte", masspointsImage, "/history/masspoint"),
        new ThumbnailModel("Prüfungstypen", checktypeImage, "/history/checktype"),
        new ThumbnailModel("Prüfungen", checkImage, "/history/check"),
        new ThumbnailModel("Zusätze", additionImage, "/history/addition"),
    ];

    return (
        <style.HistoryThumbnailWrapper>
            {thumbnails.map((thumb, i) => {
                return (<ThumbnailComponent key={i} thumbnail={thumb} />)
            })}
        </style.HistoryThumbnailWrapper>
    );
};
