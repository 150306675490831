import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/redux_store';
import { MassImageService } from '../../../services/massimage.service';
import MassMaskService from '../../../services/massmask.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';

export const getMassImages = createAsyncThunk(
    'massMask/massImage/getMassImages',
    async (_) => {
        const response = await MassImageService.getAllMassImages();
        handleResponseErrors(response, "Maßbilder");
        return response;
    }
)

export const getMassMask = createAsyncThunk(
    'massMask/massImage/getMassMask',
    async (massMaskId: Number, _) => {
        const response = await MassMaskService.getMassmask(massMaskId);
        handleResponseErrors(response, "Maßmaske");
        return response;
    }
)

export const saveMassDefinition = createAsyncThunk(
    'massMask/massImage/saveMassDefinition',
    async (_, { getState }) => {
        const state = getState() as RootState
        const massMaskId = state.massmask_massimage.actualData.massMaskId;
        const massImageId = state.massmask_massimage.actualData.selectedMassImage.massImageId;
        const response = await MassMaskService.saveMassDefinition(massMaskId, massImageId);
        handleResponseErrors(response, "Maßbild");
        return response;
    }
)

export const cancelMassImages = createAsyncThunk(
    'massMask/massImage/cancelMassImages',
    async (_) => { }
)

export const confirmMasspointsMissing = createAsyncThunk(
    'massMask/massImage/confirmMassPointsMissing',
    async (_) => { }
)

export const initData = createAsyncThunk(
    'massMask/massImage/initData',
    async (_, { dispatch , getState}) => {
        const state = getState() as RootState
        const massMaskId = state.massmask_massimage.actualData.massMaskId;
        await Promise.all([
            dispatch(getMassImages()),
            dispatch(getMassMask(massMaskId)),
        ]);
    }
)
