import { createAsyncThunk } from '@reduxjs/toolkit';
import HttpError from 'shared/networking/http_error';
import { RootState } from '../../app/redux_store';
import PlausibilityCheckService from '../../services/check.service';
import MassPointService from '../../services/masspoint.service';
import MinMaxRuleService from '../../services/minmax_rules.service';
import { handleResponseErrors } from '../../shared/networking/error_handling';

export const fetchAllData = createAsyncThunk(
    'rules_min_max/fetchAllData',
    async (checkId: number, thunkApi) => {
        await Promise.all([
            thunkApi.dispatch(fetchRules(checkId)),
            thunkApi.dispatch(fetchCheck(checkId)),
        ]);

        await thunkApi.dispatch(fetchMassPointCriterias());
    }
)

export const fetchMassPointCriterias = createAsyncThunk(
    'rules_min_max/fetchMassPointCriterias',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const bodyAreaId = state.rules_min_max.loadedData.check.bodyArea?.id;
        const response = await MassPointService.getMassPointsForBodyArea(bodyAreaId)
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)

export const fetchCheck = createAsyncThunk(
    'rules_min_max/fetchCheck',
    async (checkId: number, thunkApi) => {
        const response = await PlausibilityCheckService.getCheck(checkId);
        handleResponseErrors(response, "Prüfung");
        return response;
    }
)

export const fetchRules = createAsyncThunk(
    'rules_min_max/fetchRules',
    async (checkId: number, thunkApi) => {
        const response = await MinMaxRuleService.getMinMaxRules(checkId)
        handleResponseErrors(response, "Regeln");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'rules_min_max/cancelSave',
    async (_) => { }
)

export const saveRules = createAsyncThunk(
    'rules_min_max/saveRules',
    async (_, { getState, dispatch }) => {
        const state = getState() as RootState;
        const checkId = state.rules_min_max.loadedData.check.id;
        const rulesWithoutLastOne = state.rules_min_max.actualData.rules.slice(0, -1);
        const response = await MinMaxRuleService.saveMinMaxRules({ checkId, rules: rulesWithoutLastOne });

        if (response.isConflict()) {
            throw new HttpError("Kombination Typ & Maßpunktkriterium kommt mehrfach vor", response.getErrorCode());
        }

        handleResponseErrors(response, "Prüfungsregeln");
        return response;
    }
)