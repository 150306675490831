import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseErrors } from "shared/networking/error_handling";
import AdditionService from 'services/addition.service';
import PolicyServiceAPI from 'services/policy.service';
import MassPointService from 'services/masspoint.service';
import { PolicyTypeEnum } from "models/policies/policy_type.enum";
import BaseDataService from 'services/base_data.service';
import { RootState } from "app/redux_store";

export const getAdditions = createAsyncThunk(
    'policy/update_policy_addition_rank/getAdditions',
    async (id: string) => {
        const response = await AdditionService.getAvailableAdditionsByPolicyTypeOverview(PolicyTypeEnum.AdditionRank, id);
        handleResponseErrors(response, "Zusätze");
        return response;
    }
)

export const getMassPoints = createAsyncThunk(
    'policy/update_policy_addition_rank/getMassPoints',
    async (_) => {
        const response = await MassPointService.getMassPoints()
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)

export const getBaseData = createAsyncThunk(
    'policy/update_policy_addition_rank/getBaseData',
    async (_) => {
        const response = await BaseDataService.getBaseData()
        handleResponseErrors(response, "Basisdaten");
        return response;
    }
)

export const getPolicy = createAsyncThunk(
    'policy/update_policy_addition_rank/getPolicy',
    async (id: string) => {
        const response = await PolicyServiceAPI.getAdditionRank(id);
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const updatePolicy = createAsyncThunk(
    'policy/update_policy_addition_rank/savepolicy',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.policy_addition_rank_update.data;
        const response = await PolicyServiceAPI.updateAdditionRank({
            policyId: data.policy.id,
            name: data.policy.name,
            ignoreOnQuotation: data.policy.ignoreOnQuotation,
            additionId: data.selectedAddition.id,
            mainProductLineId: data.selectedMainProductLine.id,
            bodyAreaId: data.selectedBodyArea.id,
            masspoint1: 
            {
                name: data.selectedMasspoint1.name,
                type: data.selectedMasspoint1.massPointType,
                bodyAreaId: data.selectedMasspoint1.bodyAreaId
            },
            masspoint2: {
                name: data.selectedMasspoint2.name,
                type: data.selectedMasspoint2.massPointType,
                bodyAreaId: data.selectedMasspoint2.bodyAreaId
            },
            articleTypes: data.articleTypes.selectedList.allItems.map(x => {
                return x.id
            })
        });
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'policy/update_policy_addition_rank/cancelSave',
    async (_) => { }
)


export const initData = createAsyncThunk(
    'policy/update_policy_addition_rank/initData',
    async (id: string, { dispatch }) => {
        await Promise.all([
            dispatch(getAdditions(id)),
            dispatch(getMassPoints()),
            dispatch(getBaseData()),
        ]);
        await dispatch(getPolicy(id));
    }
)

