import { createAsyncThunk } from '@reduxjs/toolkit';
import CheckHistoryServiceAPI from '../../../services/check_history.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';

export const fetchCheckHistoryDetails = createAsyncThunk(
    'check_history/check_history_details/fetchCheckHistoryDetails',
    async (id: number) => {
        const response = await CheckHistoryServiceAPI.getCheckHistoryDetails(id);
        handleResponseErrors(response, "Prüfungshistorie");
        return response;
    }
)