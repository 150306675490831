import { HistoryValues } from "models/history_type.enum";
import { MassmaskHistoryOverviewsState } from "./massmask_history.model";
import { filterMassmaskHistoryOverviewValue } from "../massmask_history.helper";

export const filterMassmaskHistoryOverviews = (state: MassmaskHistoryOverviewsState) => {
    const searchTextLower = state.actualData.searchFilter.toLocaleLowerCase().trim();
    let filteredItems = state.loadedData.massmaskHistoryOverviews.filter(
        (massmaskHistoryOverview) =>{
            const type = HistoryValues.find(x => x.value === massmaskHistoryOverview.type)?.text;

            return (
                type.toLowerCase().includes(searchTextLower) ||
                massmaskHistoryOverview.userName?.toLowerCase().includes(searchTextLower) ||
                massmaskHistoryOverview.mainProductLineName?.toLowerCase().includes(searchTextLower) ||
                massmaskHistoryOverview.bodyAreaName?.toLowerCase().includes(searchTextLower) ||
                massmaskHistoryOverview.articleTypeName?.toLowerCase().includes(searchTextLower) ||
                massmaskHistoryOverview.footTypeName?.toLowerCase().includes(searchTextLower));  
        }
    );

    state.actualData.gridFilters.items.forEach(fil => {
        filteredItems = filterMassmaskHistoryOverviewValue(fil, filteredItems);
    });
    
    state.actualData.massmaskHistoryOverviews = filteredItems;

}