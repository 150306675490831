import React from 'react';
import { IconButton, Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { AppDispatch } from '../../app/redux_store';
import { DropdownComponent } from 'shared/shared';
import * as style from "./rule_placeholder.style";
import { addRulePlaceholder, deleteRulePlaceholder, selectedRulePlaceholder } from '../redux/rules_formula.slice';
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ListButtonComponent } from "shared/components/buttons/list_button.component";

interface RulePlaceholderComponentProps {
    ruleIndex: number;
}

export const RulePlaceholderComponent: React.FC<RulePlaceholderComponentProps> = ({ ruleIndex }) => {
    const dispatch: AppDispatch = useAppDispatch();

    const state = useAppSelector(store => store.rules_formula);
    const rule = state.actualData.rules[ruleIndex];
    const placerholderValidated = state.actualData.placerholderValidated;
    const massPointCriteriaSuggestion = useAppSelector(store => store.rules_formula.placeholderMassPointCriteriaSuggestions);

    return (
        <>
            <Grid container
                direction='row'
                md={12}>
                {rule.formularRuleMassPointPlaceholderVersions.map((ruleMpPlaceholder) => (
                    <Grid item
                        container
                        direction="row"
                        alignItems="center"
                        alignContent='center'
                        style={{ marginTop: "5px" }}
                        md={12}
                        spacing={3}>
                        <Grid item
                            alignContent="center">
                            <style.PlaceholderText>#{ruleMpPlaceholder.placeholderName}</style.PlaceholderText>
                        </Grid>
                        <Grid item
                            direction="column">
                            {ruleMpPlaceholder.isValid || !placerholderValidated ?
                                <DropdownComponent name="Text - Maßpunkt - Kriterium"
                                    data={massPointCriteriaSuggestion.suggestions}
                                    isLoading={false}
                                    isRequired={true}
                                    displayedProperties={["display"]}
                                    onSelect={(selectedPlaceholderMasspoint) =>
                                        dispatch(selectedRulePlaceholder({
                                            ruleIndex: ruleIndex,
                                            placeholderName: ruleMpPlaceholder.placeholderName,
                                            placeholderMasspoint: selectedPlaceholderMasspoint
                                        }))}
                                    selectedValue={massPointCriteriaSuggestion.suggestions.find(x => x.display === ruleMpPlaceholder.displayName)}
                                />
                                :
                                <DropdownComponent name="Text - Maßpunkt - Kriterium"
                                    data={massPointCriteriaSuggestion.suggestions}
                                    isLoading={false}
                                    isRequired={true}
                                    displayedProperties={["display"]}
                                    onSelect={(selectedPlaceholderMasspoint) =>
                                        dispatch(selectedRulePlaceholder({
                                            ruleIndex: ruleIndex,
                                            placeholderName: ruleMpPlaceholder.placeholderName,
                                            placeholderMasspoint: selectedPlaceholderMasspoint
                                        }))}
                                    selectedValue={massPointCriteriaSuggestion.suggestions.find(x => x.display === ruleMpPlaceholder.displayName)}
                                    errorText="Muss eine Auswahl enthalten"
                                />
                            }
                        </Grid>
                        <Grid item>
                            <IconButton
                                style={{ outline: "none", marginLeft: "15px" }}
                                onClick={() => dispatch(deleteRulePlaceholder({ ruleIndex: ruleIndex, placeholderName: ruleMpPlaceholder.placeholderName }))}>

                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}

                <Grid item
                    style={{ marginTop: "15px" }}
                    md={6}
                    spacing={3}>
                    <ListButtonComponent
                        onClick={() => dispatch(addRulePlaceholder(ruleIndex))}>
                        <AddIcon /> Text-Maßpunkt hinzufügen
                    </ListButtonComponent>
                </Grid>
            </Grid>
        </>)
}