import { Grid } from "@mui/material";
import { useAppDispatch, useAppSelector } from '../../../../app/hook';
import { AppDispatch } from "app/redux_store";
import { PolicyEditorComponent } from "check/policy/components/policy_editor.component";
import i18next from "i18next";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";
import { EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";
import { LoadingOverlayComponent, LoadingOverlayContainer } from "shared/components/loading_overlay/loading_overlay.component";
import { LoadingIndicatorComponent } from "shared/shared";
import { addConfiguration, changeMultiplyValue, changeName, completedSave, deleteConfiguration, filterSelectedArticleTypes, filterUnselectedArticleTypes, resetState, selectAddition, selectArticleTypes, selectMasspoint1, selectMasspoint2, toggleIgnoreOnQuotation, unSelectArticleTypes } from "./redux/policy_adjust_maximum_range_create.slice";
import { cancelSave, initData, savePolicy } from "./redux/policy_adjust_maximum_range_create.thunks";
import { PolicyAdjustMaximumRangeEditorComponent } from "../components/policy_adjust_maximum_range_editor.component";

export const PolicyAdjustMaximumRangeCreateComponent = () => {
    const state = useAppSelector((state) => state.policy_adjust_maximum_range_create);
    const dispatch: AppDispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(initData());

        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    if (state.query.initData.status === "pending") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.cancelSave.status === "success") {
        navigate("/plausibilitycheck/policy");
        dispatch(resetState());
    }

    if (state.command.savePolicy.status === "success") {
        enqueueSnackbar("Regelwerk gespeichert", { variant: "success" });
        navigate("/plausibilitycheck/policy");
        dispatch(resetState());
    }

    if (state.command.savePolicy.status === "error") {
        enqueueSnackbar(state.command.savePolicy.message, { variant: "error" });
        dispatch(completedSave());
    }

    if (state.command.savePolicy.status === "warning") {
        enqueueSnackbar(state.command.savePolicy.message, { variant: "warning" });
        dispatch(completedSave());
    }

    const isSaving = state.command.savePolicy.status === "pending";

    return (
        <>
            <LoadingOverlayContainer>
                {isSaving ? <LoadingOverlayComponent /> : <></>}
                <Grid
                    container
                    spacing={3}
                    direction="row">
                    <Grid item md={6}>
                        <EditorSectionHeaderComponent>Neue Rangewert Maximalanpassung anhand Maßpunktdifferenz erstellen</EditorSectionHeaderComponent>
                    </Grid>
                    <Grid item md={6}>
                        <p>{i18next.t("policy.adjust_second_range.description")}</p>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={3}
                    direction="column">
                    <PolicyEditorComponent
                        name={state.data.policy.name}
                        ignoreOnQuotation={state.data.policy.ignoreOnQuotation}
                        changeName={(value) => dispatch(changeName(value))}
                        toggleIgnoreOnQuotation={(value) => dispatch(toggleIgnoreOnQuotation(value))}
                    />
                    {
                        <PolicyAdjustMaximumRangeEditorComponent
                            selectedAddition={state.data.selectedAddition}
                            allAdditions={state.loadedData.additions}
                            addConfiguration={() => dispatch(addConfiguration())}
                            selectAddition={(addition) => dispatch(selectAddition(addition))}
                            configurations={state.data.configurations}
                            selectMasspoint1={(value) => dispatch(selectMasspoint1(value))}
                            selectMasspoint2={(value) => dispatch(selectMasspoint2(value))}
                            deleteConfiguration={(index) => dispatch(deleteConfiguration(index))}
                            filterSelectedArticleTypes={(value) => dispatch(filterSelectedArticleTypes(value))}
                            filterUnselectedArticleTypes={(value) => dispatch(filterUnselectedArticleTypes(value))}
                            selectArticleTypes={(value) => dispatch(selectArticleTypes(value))}
                            unSelectArticleTypes={(value) => dispatch(unSelectArticleTypes(value))}
                            changeMultiplyValue={(value) => dispatch(changeMultiplyValue(value))}
                        />
                    }
                    <EditorButtonComponent
                        canExecute={state.command.savePolicy.canExecute}
                        save={() => dispatch(savePolicy())}
                        cancelSave={() => dispatch(cancelSave())}
                    />
                </Grid>
            </LoadingOverlayContainer>
        </>
    );
}
