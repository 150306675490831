import styled from "@emotion/styled";;
import colors from '../../shared/style/colors';

export const HierarchyList = styled.span`
    font-weight: bold;
    margin-right: 100px;
`;

export const CheckType = styled.span`
    font-weight: bold;
    color: ${colors.mediMagenta};
`

export const HierarchyInfoWrapper = styled.div`
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid ${colors.mediLightGray};
`