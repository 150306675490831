import { GridColDef, GridFilterItem, GridSortModel } from "@mui/x-data-grid-pro";
import { AdditionCategoryOverviewState } from './addition_category_overview.model';
import { IAdditionCategoryOverview } from 'models/addition_categories/addition_category_overview';
import { CustomFilterOperators } from "shared/components/datagrid/custom_filter_operators";
import { DatagridMenu } from "shared/components/datagrid/datagrid_menu.component";

export const filterAdditionCategories = (state: AdditionCategoryOverviewState) => {
    const searchTextLower = state.actualData.searchFilter.toLowerCase().trim();
    let filteredItems = state.loadedData.additionCategories.filter(
        (additionCategory) =>
            additionCategory.categoryKey.toLowerCase().includes(searchTextLower) ||
            additionCategory.categoryText.toLowerCase().includes(searchTextLower)
    );

    state.actualData.additionCategoriesGridFilter.items.forEach(fil => {
        filteredItems = filterAdditionCategoryValue(fil, filteredItems);
    });
    state.actualData.additionCategories = filteredItems;
}

export const filterAdditionCategoryValue = (filterItem: GridFilterItem, filteredItems: IAdditionCategoryOverview[]) => {
    if (filterItem.value) {
        switch (filterItem.field) {
            case 'categoryKey':
                filteredItems = filteredItems.filter(x => x.categoryKey.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'categoryText':
                filteredItems = filteredItems.filter(x => x.categoryText.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
        }
    }
    return filteredItems;
}

const GetMenuCell = (params, deleteAdditionCategory) => {

    if (params.row.categoryKey === "Default_Category") {
        return <></>
    }

    return DatagridMenu(
        {
            params: params,
            menuItemsData: [{ label: "Zusatzkategorie löschen", action: deleteAdditionCategory }]
        })
}

export const getGridColumns = (
    deleteAdditionCategory: (additionCategory: IAdditionCategoryOverview) => void,
): GridColDef[] => [
        {
            field: "menu",
            headerName: " ",
            width: 15,
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            hideable: false,
            pinnable: false,
            renderCell: params => GetMenuCell(params, deleteAdditionCategory)
        },
        {
            field: "categoryKey",
            headerName: "Übersetzungskey",
            disableColumnMenu: false,
            flex: 1,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterOperators: CustomFilterOperators(),
        },
        {
            field: "categoryText",
            headerName: "Text",
            disableColumnMenu: false,
            flex: 1,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterOperators: CustomFilterOperators(),
        }
    ]

export const sortByKeyModel: GridSortModel = [
    {
        field: "categoryKey",
        sort: "asc",
    },
];

