import { EnumDescriptor } from "models/enum_descriptor";

export enum BodyInformationType {
    Radio = 1,
    Number = 2,
    Text = 3,
    FreeText = 4
}

export const BodyInformationTypeEnumValues: Array<
    EnumDescriptor<BodyInformationType>
> = [
        { value: BodyInformationType.Radio, text: "Optionsfeld" },
        { value: BodyInformationType.Number, text: "Nummer" },
        { value: BodyInformationType.Text, text: "Text einzeilig" },
        { value: BodyInformationType.FreeText, text: "Freitext" }
    ];

export interface IBodyInformationEditor {
    translationKey: string;
    type: EnumDescriptor<BodyInformationType>;
    isMandatory: boolean;
    freeTextLength: number;
    entriesKeys: IBodyInformationEntry[];
}

export interface IBodyInformationEntry {
    shortKey: string;
    translationKey: string;
}

export interface IBodyInformationEditorAction {
    updateCategoryName: (categoryName) => {} | void,
    updateCategoryType: (type: EnumDescriptor<BodyInformationType>) => {} | void,
    updateIsMandatory: (isMandatory: boolean) => {} | void,
    updateFreeTextLength: (freeTextLength: number) => {} | void;
    addTranslationKey: () => {} | void;
    removeTranslationKey: (index: number) => {} | void;
    updateTranslationKey: (index: number, translationKey: string) => {} | void;
    updateShortKey: (index: number, shortKey: string) => {} | void;
}