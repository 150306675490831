import { IPositionCategoryItemDetail } from "models/position_category/position_category_item/position_category_item_detail";
import { filterPositionCategoryItemDetailValue } from "../position_category_item_details.helper";
import { PositionCategoryItemDetailsState } from "./position_category_item_details.model";
import { BodySideEnum } from "@medi-mtm/components";
import { MasspointBodySideEnumValuesLookup } from "models/masspoints/enums/body_side.enum";
import { IPositionCategory, IPositionItem, IPositionItemDirection } from "models/position_category/position_category";
import { SideSeamModeEnum } from "models/position_category/side_seam_mode.enum";

export const filterPositionCategoryItemDetails = (state: PositionCategoryItemDetailsState) => {
    const searchTextLower = state.actualData.searchFilter.toLowerCase().trim();
    let filteredItems = state.actualData.positionCategoryItemDetails.filter(
        (positionCategoryItemDetail) =>
            positionCategoryItemDetail.displayName.toLowerCase().includes(searchTextLower)
    ).sort(sortPositionCategoryItemDetail);

    state.actualData.gridFilters.items.forEach(fil => {
        filteredItems = filterPositionCategoryItemDetailValue(fil, filteredItems);
    });
    state.actualData.filteredPositionCategoryItemDetails = filteredItems;
}

export const sortPositionCategoryItemDetail = (positionCategoryItemDetail1: IPositionCategoryItemDetail, positionCategoryItemDetail2: IPositionCategoryItemDetail) => {
    return positionCategoryItemDetail1.displayName.localeCompare(positionCategoryItemDetail2.displayName);
}

export const extractPositionCategoryItemDetails = (positionCategory: IPositionCategory): IPositionCategoryItemDetail[] => {
    let positioncategoryitemdetail: IPositionCategoryItemDetail[] = [];
    positionCategory.positionItems.forEach(x => {
        positioncategoryitemdetail.push(...extractPositionCategoryItemDetail(x));
    });

    let id = 1;
    positioncategoryitemdetail.forEach(x => { x.id = id, id++});
    return positioncategoryitemdetail;
};

const extractPositionCategoryItemDetail = (positionCategory: IPositionItem): IPositionCategoryItemDetail[] => {
    const bodyAreas = positionCategory.positionItemBodyAreas.map(x => x.bodyArea.name);
    const sides = positionCategory.positionItemBodySides.map(x => x.bodySide);

    const horizontalStartDetailIndex = positionCategory.positionItemDirections.findIndex(x => x.additionHierarchyId === 1);
    const horizontalEndDetailIndex = positionCategory.positionItemDirections.findIndex(x => x.additionHierarchyId === 3);
    const verticalStartDetailIndex = positionCategory.positionItemDirections.findIndex(x => x.additionHierarchyId === 2);
    const verticalEndDetailIndex = positionCategory.positionItemDirections.findIndex(x => x.additionHierarchyId === 4);

    const matrix = new Array<Array<string>>();

    matrix.push(buildBodyAreaDisplayNames(bodyAreas));
    matrix.push(buildBodySideDisplayNames(sides));
    if (horizontalStartDetailIndex !== -1) {
        const horizontalStartDetail = positionCategory.positionItemDirections[horizontalStartDetailIndex];
        matrix.push(buildStartPrepositionsDisplayNames(horizontalStartDetail));
        matrix.push(buildHorizontalStartDisplayNames(horizontalStartDetail));
    }
    if (horizontalEndDetailIndex !== -1) {
        const horizontalEndDetail = positionCategory.positionItemDirections[horizontalEndDetailIndex];
        matrix.push(buildEndPrepositionsDisplayNames(horizontalEndDetail));
        matrix.push(buildHorizontalEndDisplayNames(horizontalEndDetail));
    }
    if (verticalStartDetailIndex !== -1) {
        const verticalStartDetail = positionCategory.positionItemDirections[verticalStartDetailIndex];
        matrix.push(buildStartPrepositionsDisplayNames(verticalStartDetail));
        matrix.push(buildVerticalStartDisplayNames(verticalStartDetail));
    }
    if (verticalEndDetailIndex !== -1) {
        const verticalEndDetail = positionCategory.positionItemDirections[verticalEndDetailIndex];
        matrix.push(buildEndPrepositionsDisplayNames(verticalEndDetail));
        matrix.push(buildVerticalEndDisplayNames(verticalEndDetail));
    }
    let generatedPositionDisplayNames: string[] = [];


    matrix.forEach((x, index) => {
        if (index === 0) {
            generatedPositionDisplayNames = x.map(y => y);
        }
        else {
            generatedPositionDisplayNames = multiplyWithArray(generatedPositionDisplayNames, x);
        }
    })

    let positioncategoryitemdetails: IPositionCategoryItemDetail[] = generatedPositionDisplayNames.map((x, index)=> {
        return {
            id: index,
            displayName: x,
            sideSeamModeAllowed: positionCategory.sideSeamModeAllowed ?? SideSeamModeEnum.Undefinied,
            isDocumentAllowed: positionCategory.isDocumentAllowed,
            isMeasurementInputPossible: positionCategory.isDiameterAllowed || positionCategory.isLengthAllowed || positionCategory.isWidthAllowed
        }
    })

    return positioncategoryitemdetails;
}

const multiplyWithArray = (array: string[], items: string[]): string[] => {
    let positioncategoryitemdetails: string[] = [];
    array.forEach(x => {
        items.forEach(y => {
            const extendedDisplayName = x + y
            positioncategoryitemdetails.push(extendedDisplayName);
        })
    })

    return positioncategoryitemdetails;
}

const buildStartPrepositionsDisplayNames = (itemDetail: IPositionItemDirection) => {
    let displayNames: string[] = [];

    if (itemDetail.positionItemPrepositions.length > 0) {
        itemDetail.positionItemPrepositions.forEach(x => {
            displayNames.push(`, ${x.name}`);
        })
    }
    else {
        displayNames.push("");
    }
    return displayNames;
}

const buildEndPrepositionsDisplayNames = (itemDetail: IPositionItemDirection) => {
    let displayNames: string[] = [];

    if (itemDetail.positionItemPrepositions.length > 0) {
        itemDetail.positionItemPrepositions.forEach(x => {
            displayNames.push(` ${x.name}`);
        })
    }
    else {
        displayNames.push("");
    }
    return displayNames;
}

const buildVerticalStartDisplayNames = (verticalStartDetail: IPositionItemDirection) => {
    let displayNames: string[] = [];

    verticalStartDetail.positionItemAdditions.forEach(x => {
        if (x.name !== "") {
            displayNames.push(`, ${x.name}`);
        }
    });

    return displayNames;
}

const buildVerticalEndDisplayNames = (verticalEndDetail: IPositionItemDirection) => {
    let displayNames: string[] = [];

    verticalEndDetail.positionItemAdditions.forEach(x => {
        if (x.name !== "") {
            displayNames.push(` ${x.name}`);
        }
    });

    return displayNames;
}

const buildHorizontalStartDisplayNames = (horizontalStartDetail: IPositionItemDirection) => {
    let displayNames: string[] = [];

    if (horizontalStartDetail.positionItemAdditions.length > 0) {
        horizontalStartDetail.positionItemAdditions.forEach(x => {
            displayNames.push(`, ${x.name}`);
        })
    }
    return displayNames;
}

const buildHorizontalEndDisplayNames = (horizontalEndDetail: IPositionItemDirection) => {
    let displayNames: string[] = [];

    horizontalEndDetail.positionItemAdditions.forEach(x => {
        if (x.name !== "") {
            displayNames.push(` ${x.name}`);
        }
    });

    return displayNames;
}

const buildBodyAreaDisplayNames = (bodyAreas: string[]): string[] => {
    let displayNames: string[] = [];
    bodyAreas.forEach(x => {
        displayNames.push(x)
    });

    return displayNames;
}

const buildBodySideDisplayNames = (sides: BodySideEnum[]): string[] => {
    let displayNames: string[] = [];
    sides.forEach(x => {
        let displayName = !(x === BodySideEnum.LeftRight || x === BodySideEnum.None) ? `, ${MasspointBodySideEnumValuesLookup(x)}` : ``;
        displayNames.push(displayName)
    });

    return displayNames;
}