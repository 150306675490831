import { IAvailableAdditionAttributeCategory } from "models/addition_attribute_categories/available_addition_attribute_category";
import { IAvailableAdditionAttribute } from "models/addition_attribute_categories/available_addition_attribute";
import { EvaluationResultEnum, ProductConfigurationEvaluation } from 'models/simulator/product_configuration_evaluation';
import { MasspointEvaluation } from '../../../models/simulator/product_configuration_evaluation';
import {
    SimulationMassPoint,
    SimulationState,
    ISimulationAdditionCategoryAttributeViewModel,
    ISimulationAdditionCategoryAttributeValueViewModel,
    EvaluationState
} from './simulator_simulation.model';
import { CategoryAttributeRequest } from "models/simulator/save_custom_design_request";
import { doesExist } from "services/validation.service";
import { OfferTypeEnum } from "models/simulator/offer_type.enum";
import { SimulationTypeEnum } from "models/simulator/simulation_type.enum";
import IAvailableQuality from "models/available_basedata/available_quality";
import { 
    ComponentDataEvent,
    MassDefinitionEvaluationsResponse, 
    MassTypeEnum, 
    SideSelectionEnum 
} from "@medi-mtm/components";
import { switchSimulatorState } from "./simulator_simulation.state";
import { MasspointTypeEnum } from "models/masspoints/enums/masspoint_type.enum";

export const recalculateComponentStateForAddition = (state: SimulationState, componentData: ComponentDataEvent ) => {
    state.command.evaluateAddition.canExecute = componentData.isValid;
    state.simulation.configurationRunId = componentData.productConfigurationRunId;
    state.simulation.productConfigurationId = componentData.productConfigurationId;
}

export const recalculateComponentStateForProductionInstruction = (state: SimulationState, componentData: ComponentDataEvent) => {
    state.command.evaluateProductionInstruction.canExecute = componentData.isValid;
    state.simulation.configurationRunId = componentData.productConfigurationRunId;
    state.simulation.productConfigurationId = componentData.productConfigurationId;
}

export const recalculateComponentStateForMassMask = (state: SimulationState, componentData: ComponentDataEvent) => {
    state.command.evaluateMassMask.canExecute = componentData.isValid;
    state.simulation.configurationRunId = componentData.productConfigurationRunId;
    state.simulation.productConfigurationId = componentData.productConfigurationId;
}

export const initializeProductConfiguration = (state: SimulationState) => {
    if (doesExist(state.storedConfigurationData)) {
        state.simulation.processType = state.storedConfigurationData.content.processTypeEnum;
        if (state.storedConfigurationData.content.processTypeEnum) {
            state.simulation.offerType = OfferTypeEnum.NormalOffer;
        }
        if (state.storedConfigurationData.content.isCustomDesign) {
            state.simulation.simulationType = SimulationTypeEnum.CustomDesign;
        } else {
            state.simulation.simulationType = SimulationTypeEnum.Mass;
        }
        calculateSelectedQuality(state);
        calculateSelectedSide(state);
        calculateSelectedFootOption(state);
        calculateSelectedCountry(state);
        calculateAttributes(state);
        state.simulation.configurationRunId = state.storedConfigurationData.id;
        state.simulation.productConfigurationIsLocked = state.storedConfigurationData?.isLocked;
        state.simulation.productConfigurationId = state.storedConfigurationData.productConfigurationId;
        switchSimulatorState(EvaluationState.restoring, state);
    }
}

const calculateSelectedQuality = (state: SimulationState) => {
    let quality: IAvailableQuality = null;
    if (state.simulation.simulationType === SimulationTypeEnum.Mass) {
        quality = state.qualities.find(qu => qu.id === state.storedConfigurationData.content.massmaskInput.qualities[0].id);
    } else {
        quality = state.qualities.find(qu => qu.id === state.storedConfigurationData.content.customDesignInput.quality.id);
    }
    state.simulation.selectedQuality = quality;
}

const calculateSelectedSide = (state: SimulationState) => {
    let side = SideSelectionEnum.None;
    if (state.simulation.simulationType === SimulationTypeEnum.Mass) {
        side = state.storedConfigurationData.content.massmaskInput.selectedSide.side;
    } else {
        side = state.storedConfigurationData.content.customDesignInput.selectedSide.side;
    }
    state.simulation.selectedSide = side;
}

const calculateSelectedFootOption = (state: SimulationState) => {
    let footOption = state.availablefootoptions.find(fo => fo.id === null);
    if (state.simulation.simulationType === SimulationTypeEnum.Mass && doesExist(state.storedConfigurationData.content.massmaskInput.selectedFootType)) {
        footOption = state.availablefootoptions.find(fo => fo.externalIdentifier === state.storedConfigurationData.content.massmaskInput.selectedFootType);
    } else if (state.simulation.simulationType === SimulationTypeEnum.CustomDesign && doesExist(state.storedConfigurationData.content.customDesignInput!.footOption)) {
        footOption = state.availablefootoptions.find(fo => fo.externalIdentifier === state.storedConfigurationData.content.customDesignInput.footOption.footType.externalIdentifier);
    }
    state.simulation.selectedFootoption = footOption;
}

const calculateSelectedCountry = (state: SimulationState) => {
    let country = null;
    if (doesExist(state.storedConfigurationData.content.additionInput)) {
        country = state.countries.find(x => x.id === state.storedConfigurationData.content.additionInput.countryId);
    } else {
        country = state.countries.find(x => x.countryCode === "DE");
    }
    state.simulation.selectedCountry = country;
}

const calculateAttributes = (state: SimulationState) => {
    if (doesExist(state.storedConfigurationData.content.additionInput)) {
        state.storedConfigurationData.content.additionInput.attributes.forEach(att => {
            state.simulation.additionCategoryAttributes.forEach(cat => {
                const attribute = cat.allAttributes.find(aat => aat.id === att.availableAdditionAttributeId);
                if (doesExist(attribute)) {
                    cat.selectedAttribute = attribute;
                }
            });
        });
    }
}

export const updateInputEvaluated = (state: SimulationState, result: ProductConfigurationEvaluation) => {
    const evaluations: MasspointEvaluation[] = [];
    result.qualityResults[0].massPointEvaluationResult.massDefinitionEvaluations.forEach(massDefinitionEvaluation => {
        massDefinitionEvaluation.massPoints.forEach(massPointEvaluation => {
            evaluations.push(massPointEvaluation);
        });
    });

    sortEvaluations(evaluations)

    state.simulation.productConfigurationId = result.productConfigurationId;
    state.simulation.evaluations = evaluations;
}

export const restoreInputEvaluated = (state: SimulationState, definitionEvaluationResponses: MassDefinitionEvaluationsResponse[]) => {
    const evaluations: MasspointEvaluation[] = [];
    definitionEvaluationResponses.forEach(definition => {
            definition.massPoints.forEach(masspoint => evaluations.push({
                id: 0,
                bodyAreaId: 0,
                bodyAreaName: "",
                value: masspoint.value,
                bodySide: masspoint.bodySide,
                bodySideName: masspoint.bodySideName,
                name: masspoint.name,
                massPointTypeName: masspoint.massPointTypeName,
                massPointType: masspointTypeConvert(masspoint.massPointType),
                evaluationResult: evaluationResultConvert(masspoint.evaluationResult),
                evaluationResultName: "",
                massPointTestRuns:  masspoint.massPointTestRuns.map(mtr =>{ return {
                    id: mtr.id,
                    evaluationResult: {
                        result: evaluationResultConvert(mtr.evaluationResult.result),
                        name:  mtr.evaluationResult.name,
                        message: mtr.evaluationResult.message,
                    },
                    value: mtr.value,
                    matchedCheck : {
                        qualityName: mtr.matchedCheck.qualityName,
                        qualityId: mtr.matchedCheck.qualityId,
                        articleTypeName: mtr.matchedCheck.qualityName,
                        articleTypeId: mtr.matchedCheck.articleTypeId,
                        bodyAreaName: mtr.matchedCheck.bodyAreaName,
                        bodyAreaId: mtr.matchedCheck.bodyAreaId,
                        mainProductLineName: mtr.matchedCheck.mainProductLineName,
                        mainProductLineId: mtr.matchedCheck.mainProductLineId,
                        checkId: mtr.matchedCheck.checkId,
                        checkTypeName: mtr.matchedCheck.checkTypeName,
                        checkTypeId: mtr.matchedCheck.checkTypeId
                    },
                }})
            }))
        });

    sortEvaluations(evaluations)

    state.simulation.evaluations = evaluations;
}

export const masspointTypeConvert = (masspointTypeEnum: MassTypeEnum): MasspointTypeEnum => {
    let convertedResult = MasspointTypeEnum.Undefined; 
    switch(masspointTypeEnum) {
        case MassTypeEnum.Circumference:
            convertedResult = MasspointTypeEnum.Circumference;    
            break;
        case MassTypeEnum.Length:
            convertedResult = MasspointTypeEnum.Length;    
            break;      
        default:
            convertedResult = MasspointTypeEnum.Undefined;    
            break;                                                      
    }
    return convertedResult;
}

export const evaluationResultConvert = (errorEnum: EvaluationResultEnum): EvaluationResultEnum => {
    let convertedResult = EvaluationResultEnum.Undefined; 
    switch(errorEnum) {
        case EvaluationResultEnum.Error:
            convertedResult = EvaluationResultEnum.Error;    
            break;
        case EvaluationResultEnum.Warning:
            convertedResult = EvaluationResultEnum.Warning;    
            break;  
        case EvaluationResultEnum.MassPointsNotFound:
            convertedResult = EvaluationResultEnum.MassPointsNotFound;    
            break;            
        case EvaluationResultEnum.Invalid:
            convertedResult = EvaluationResultEnum.Invalid;    
            break;     
        case EvaluationResultEnum.Success:
            convertedResult = EvaluationResultEnum.Success;    
            break;   
        case EvaluationResultEnum.Undefined:
            convertedResult = EvaluationResultEnum.Undefined;    
            break;    
        default:
            convertedResult = EvaluationResultEnum.Undefined;    
            break;                                                      
    }
    return convertedResult;
}

export const getCategoryAttributeRequests = (categoryAttributes: ISimulationAdditionCategoryAttributeViewModel[]): CategoryAttributeRequest[] => {
    let attributeCategories: CategoryAttributeRequest[] = [];
    categoryAttributes.filter(item => item.selectedAttribute.code != null).forEach(item => {
        attributeCategories.push({
            categoryErpId: item.erpId,
            attributeCode: item.selectedAttribute.code,
        });
    });

    return attributeCategories;
}

export const findMassPointById = (masspoints: SimulationMassPoint[], massPointId: number): SimulationMassPoint => {
    const massPointIndex = masspoints
        .findIndex(mp => mp.massPointId === massPointId);

    if (massPointIndex !== -1) {
        return masspoints[massPointIndex];
    }

    return null;
}

const sortEvaluations = (evaluations: MasspointEvaluation[]) => {
    evaluations.sort((a, b) => a.name.localeCompare(b.name)
        || a.massPointType.valueOf() - b.massPointType.valueOf()
        || (a.bodyAreaName ?? "Alle").localeCompare(b.bodyAreaName ?? "Alle")
        || a.bodySide.valueOf() - b.bodySide.valueOf()
        || a.evaluationResult.valueOf() - b.evaluationResult.valueOf());

    evaluations.forEach(evaluation => {
        evaluation.massPointTestRuns.sort((a, b) => {
            return (
                a.matchedCheck.checkTypeName.localeCompare(b.matchedCheck.checkTypeName)
            );
        })
    })
}

export const toArticleCategoryAttributes = (articleCategoryAttributes: IAvailableAdditionAttributeCategory[]): ISimulationAdditionCategoryAttributeViewModel[] => {
    return articleCategoryAttributes.map(category => {
        return {
            erpId: category.erpId,
            displayName: category.erpId + " - " + category.name,
            name: category.name,
            allAttributes: toArticleCategoryAttributeValues(category.availableAdditionAttributes),
            selectedAttribute: {
                id: null,
                code: null,
                name: "",
                displayName: "Keine Auswahl",
            }
        }
    })
}

const toArticleCategoryAttributeValues = (additionAttributes: IAvailableAdditionAttribute[]): ISimulationAdditionCategoryAttributeValueViewModel[] => {
    return [
        {
            id: null,
            code: null,
            name: "",
            displayName: "Keine Auswahl",
        },
        ...additionAttributes.map(attribute => {
            return {
                id: attribute.id,
                code: attribute.code,
                name: attribute.name,
                displayName: attribute.name,
            }
        })];
}