import { doesExist, isNullOrWhitespace } from "services/validation.service";
import { PolicyAdditionRankCreateState } from "./policy_addition_rank_create.model";
import { IMassPointCriteriaItem } from '../../../components/policy_editor.model';
import IMasspointOverview from "models/masspoints/masspoint_overview";
import { MasspointTypeEnumValuesLookup } from "models/masspoints/enums/masspoint_type.enum";
import IAvailableArticleType from "models/available_basedata/available_article_type";
import { BodySideEnum } from "models/masspoints/enums/body_side.enum";
import { AdditionPlaceHolderTypeEnum } from "models/additions/addition_placeholder.enum";
import { IFilterList } from "shared/components/selectionComponent/models/filterList";
import { distinctMassPointCriteria } from "shared/helpers/distinctMassPointCriteria";

export const updateCanSave = (state: PolicyAdditionRankCreateState) => {
    state.command.savePolicy.canExecute =
        !isNullOrWhitespace(state.data.policy.name)
        && doesExist(state.data.selectedAddition)
        && state.data.selectedAddition.placeholderType === AdditionPlaceHolderTypeEnum.RangeInput
        && doesExist(state.data.selectedMainProductLine)
        && doesExist(state.data.selectedBodyArea)
        && doesExist(state.data.selectedMasspoint1)
        && doesExist(state.data.selectedMasspoint2)
        && containsArticleType(state);
}

const containsArticleType = (state: PolicyAdditionRankCreateState) => {
    return state.data.articleTypes.selectedList.allItems.length > 0;
}

export const createMasspointItemList = (massPoints: IMasspointOverview[]) : IMassPointCriteriaItem[] => {
    return  massPoints
                .filter(distinctMassPointCriteria)
                .filter(x => x.bodySide === BodySideEnum.Left || BodySideEnum.Right)
                .map(mp =>  { return {
                    id: mp.id,
                    displayName: `${mp.name} ${MasspointTypeEnumValuesLookup(mp.massPointType)} ${mp.bodyArea.name}`,
                    name: mp.name,
                    massPointType: mp.massPointType,
                    bodySide: mp.bodySide,
                    bodyAreaId: mp.bodyArea.id, 
    }})
};

export const createArticleTypeList = (articleType: IAvailableArticleType[]) : IFilterList => {
    return {
        allItems: articleType.map(art =>  { return {
            id: art.id,
            displayName: `${art.erpId} ${art.name}`,
        }}),
        filteredItems: [],
        searchText: "" 
    };
}