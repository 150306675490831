import { isNullOrWhitespace } from "services/validation.service";
import { CharacteristicCategoryCreateState } from "./characteristic_category_create.model";
import { ISelectable } from "shared/components/selectionComponent/models/selectable";
import { ICharacteristicCategoryViewModel, ICharacteristicValueFilterList, ICharacteristicValueFilterListItem } from "additions/characteristic_category/components/characteristic_category_editor.model";
import { ICharacteristicCategoryCreate } from "models/characteristic_categories/characteristic_category_create";
import { ICharacteristicValue } from "models/characteristic_types/characteristic_type";

export const updateCanSave = (state: CharacteristicCategoryCreateState) => {
    state.command.saveCharacteristicCategory.canExecute =
        !isNullOrWhitespace(state.data.characteristicCategory.name);
}

export const filterValues = (listToFilter: ICharacteristicValueFilterList) => {
    listToFilter.filteredItems = listToFilter.allItems
        .filter(m => valueMatchesText(m, listToFilter.searchText))
        .sort(sortValues);
}

export const valueMatchesText = (m: ISelectable, searchText: string): boolean => {
    if (searchText === "") {
        return true;
    }
    const searchTextLowerCase = searchText.toLowerCase();
    return (m.displayName.toLowerCase().includes(searchTextLowerCase));
}

export const sortValues = (mp1: ISelectable, mp2: ISelectable) => {
    return mp1.displayName.localeCompare(mp2.displayName);
}

export const moveValueFromUnselectedToSelected = (state: CharacteristicCategoryCreateState, ids: number[]) => {
    const selectedList = state.data.characteristicCategory.selectionValues.selectedList;
    const unselectedList = state.data.characteristicCategory.selectionValues.unSelectedList;

    ids.forEach(id => {
        const itemToMove = state.data.characteristicCategory.selectionValues.unSelectedList.allItems.find(x => x.id === id);
        moveValueFromSourceToDestination(unselectedList, selectedList, itemToMove);
    });

    filterValues(selectedList);
    filterValues(unselectedList);
}

export const moveValueFromSelectedToUnselected = (state: CharacteristicCategoryCreateState, ids: number[]) => {
    const selectedList = state.data.characteristicCategory.selectionValues.selectedList;
    const unselectedList = state.data.characteristicCategory.selectionValues.unSelectedList;

    ids.forEach(id => {
        const itemToMove = state.data.characteristicCategory.selectionValues.selectedList.allItems.find(x => x.id === id);
        moveValueFromSourceToDestination(selectedList, unselectedList, itemToMove);
    });

    filterValues(selectedList);
    filterValues(unselectedList);
}

export const moveValueFromSourceToDestination = (source: ICharacteristicValueFilterList, destination: ICharacteristicValueFilterList, itemToMove: ICharacteristicValueFilterListItem) => {
    const indexInAll = source.allItems.findIndex(x => x.id === itemToMove.id);
    destination.allItems.push(itemToMove);
    source.allItems.splice(indexInAll, 1);
}

export const createSaveRequest = (category: ICharacteristicCategoryViewModel): ICharacteristicCategoryCreate => {
    let valueIds  = [];
    if(category.selectionValues.selectedList.allItems.length > 0) {
        valueIds = category.selectionValues.selectedList.allItems.map(x => {
            return x.id;
        });
    } else {
        valueIds = category.selectionValues.unSelectedList.allItems.map(x => {
            return x.id;
        });
    }
    
    return {
        name: category.name,
        values: valueIds
    }
} 