import { IAdditionCategoryOverview } from "models/addition_categories/addition_category_overview";
import { IAdditionPolicyInformation } from "models/additions/addition_policy_information";
import { IMassPointCriteriaItem } from "../components/policy_editor.model";
import IMasspointOverview from "models/masspoints/masspoint_overview";
import { MassPointCriteriaSide } from "models/masspoints/masspoint_criteria";
import IMasspointGroupOverview from "models/masspoint_groups/masspoint_group_overview";

export const sortAdditions = (mp1: IAdditionPolicyInformation, mp2: IAdditionPolicyInformation) => {
    return mp1.option.localeCompare(mp2.option);
}

export const sortAdditionCategories = (mp1: IAdditionCategoryOverview, mp2: IAdditionCategoryOverview) => {
    return mp1.categoryText.localeCompare(mp2.categoryText);
}

export const sortMassPointGroups = (mpg1: IMasspointGroupOverview, mpg2: IMasspointGroupOverview) => {
    return mpg1.identifier.localeCompare(mpg2.identifier);
}

export const filterMasspoint = (masspoints: IMassPointCriteriaItem[], ignoredMasspoint: IMassPointCriteriaItem): IMassPointCriteriaItem[] => {
    return masspoints.filter(x => !(x.bodyAreaId === ignoredMasspoint.bodyAreaId
        && x.name === ignoredMasspoint.name
        && x.massPointType === ignoredMasspoint.massPointType));
}

export const distinctMassPointCriteriaSide = (value: MassPointCriteriaSide, currentIndex: number, arr: MassPointCriteriaSide[]) => {
    const indexOfFirstOccurrence = arr.findIndex(mp =>
        mp.massPointName === value.massPointName
        && mp.massPointType === value.massPointType
        && mp.bodyArea.id === value.bodyArea.id)
    return currentIndex === indexOfFirstOccurrence;
}