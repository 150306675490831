import { useAppSelector, useAppDispatch } from "app/hook";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { AppDispatch } from "app/redux_store";
import { cancelSave, initData, saveCharacteristicType } from "./redux/characteristic_type_create.thunks";
import { addValue, changeName, changeShortKey, changeValueKey, changeValueName, completedSave, deleteValue, resetState } from "./redux/characteristic_type_create.slice";
import { LoadingIndicatorComponent } from "shared/shared";
import { LoadingOverlayComponent, LoadingOverlayContainer } from "shared/components/loading_overlay/loading_overlay.component";
import { Grid } from "@mui/material";
import { CharacteristicTypeEditorComponent } from "../components/characteristic_type_editor.component";
import { EditorButtonComponent } from "../../../shared/components/editors/editor_button/editor_buttons.component";

export const CharacteristicTypeCreateComponent = () => {
    const state = useAppSelector((state) => state.characteristic_types_create);
    const dispatch: AppDispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(initData());

        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    if (state.query.initData.status === "pending") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.cancelSave.status === "success") {
        navigate("/additions/characteristictype/overview");
        dispatch(resetState());
    }

    if (state.command.saveCharacteristicType.status === "success") {
        enqueueSnackbar("Ausprägungstyp gespeichert", { variant: "success" });
        navigate("/additions/characteristictype/overview");
        dispatch(resetState());
    }

    if (state.command.saveCharacteristicType.status === "error") {
        enqueueSnackbar(state.command.saveCharacteristicType.message, { variant: "error" });
        dispatch(completedSave());
    }

    if (state.command.saveCharacteristicType.status === "warning") {
        enqueueSnackbar(state.command.saveCharacteristicType.message, { variant: "warning" });
        dispatch(completedSave());
    }

    const isSaving = state.command.saveCharacteristicType.status === "pending";

    return (
        <>
            <LoadingOverlayContainer>
                {isSaving ? <LoadingOverlayComponent /> : <></>}
                <Grid
                    container
                    spacing={3}
                    md={12}
                    direction="column">
                    <Grid item>
                        <CharacteristicTypeEditorComponent
                            header='Neuen Ausprägungstyp erstellen'
                            name={state.data.characteristicType.name}
                            shortKey={state.data.characteristicType.shortKey}
                            values={state.data.characteristicType.values}
                            translations={state.loadedData.characteristicValueTranslations}
                            changeName={(value) => dispatch(changeName(value))} 
                            changeShortKey={(value) => dispatch(changeShortKey(value))}   
                            changeValueName={(value) => dispatch(changeValueName(value))}
                            changeValueKey={(value) => dispatch(changeValueKey(value))} 
                            deleteValue={(value) => dispatch(deleteValue(value))}
                            addValue={() => dispatch(addValue())}
                        />
                    </Grid>
                        <EditorButtonComponent
                            canExecute={state.command.saveCharacteristicType.canExecute}
                            save={() => dispatch(saveCharacteristicType())}
                            cancelSave={() => dispatch(cancelSave())}
                        />
                </Grid>
            </LoadingOverlayContainer>
        </>
    );
};