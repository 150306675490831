import { GridFilterModel } from "@mui/x-data-grid-pro";
import IAvailableBodyArea from "models/available_basedata/available_body_area";
import { BodySideEnum } from "models/masspoints/enums/body_side.enum";
import { ISelectionList } from "shared/components/selectionComponent/models/selectionList";
import { SideSeamModeEnum } from "./side_seam_mode.enum";
import { EditorModeEnum } from "models/editors/editor_mode.enum";

export interface IPositionCategoryEditor {
    canSaveCategory: boolean;
    canSaveItem: boolean;
    editorMode: EditorModeEnum;
    editorState: PositionCategoryEditorState;
    positionCategoryItemSet: IPositionCategoryItemSet;
    indexOfItem: number;
    sideSeamAllowed: boolean;
    name: string;
    id?: number;
}

export interface IPositionCategoryItemSet {
    positionCategoryItems: IPositionCategoryItem[];
    filteredPositionCategoryItems: IPositionCategoryItem[],
    filter: GridFilterModel;
}

export interface IPositionCategoryItem {
    sequenceId: number;
    sideSeamModeAllowed?: SideSeamModeEnum;
    isDocumentAllowed: boolean;
    isLengthAllowed: boolean;
    isWidthAllowed: boolean;
    lengthFrom: number;
    lengthTo: number;
    widthFrom: number;
    widthTo: number;
    isDiameterAllowed: boolean;
    diameterFrom: number;
    diameterTo: number;
    bodyAreas: IAvailableBodyArea[];
    bodySides: BodySideEnum[];
    isDisplayAsPantyTop: boolean;
    horizontalStartItem: ICategoryItemDetail;
    horizontalEndItem: ICategoryItemDetail;
    verticalStartItem: ICategoryItemDetail;
    verticalEndItem: ICategoryItemDetail;
    excludedArticleTypesEnabled: boolean;
    excludedArticleTypesSelection: ISelectionList;
}

export interface ICategoryItemDetail {
    additionPositionSelection: ISelectionList;
    prepositionSelection: ISelectionList;
    editableArticleTypeConfig: IArticleTypeConfig;
    editableArticleTypeConfigIndex: number;
    articleTypeConfigDeletingIndex: number;
    articleTypeConfigs: IArticleTypeConfig[];
    start: number;
    end: number;
}

export interface IArticleTypeConfig{
    articleTypes: ISelectionList;
    positions: ISelectionList;
}

export enum PositionCategoryEditorState {
    Category = 0,
    ItemCreate = 1,
    ItemEdit = 2,
}