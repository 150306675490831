import { IAdditionEditor } from "models/additions/addition_editor.model";
import IAvailableBodyArea from "models/available_basedata/available_body_area";
import { removeDuplicates } from "shared/helpers/removeDuplicates";
import { doesExist } from "services/validation.service";
import { LockTypeEnum } from "shared/components/selectionComponent/models/locktype.enum";
import { IAvailableMappedBaseData } from "models/available_basedata/available_mapped_base_data";
import { IValidityScope } from "models/additions/validity_scope";
import { ISelectionList } from "shared/components/selectionComponent/models/selectionList";
import { createSelectionList, filterSelectionList, getEmptySelectionList, toISelectable } from "shared/components/selectionComponent/selectionList.helper";
import { getIAvailableArticleTypeDisplayName, getIAvailableBodyAreaDisplayName, getIAvailableProductLineDisplayName, getIAvailableQualityDisplayName } from "shared/helpers/displayNames";
import { ISelectable } from "shared/components/selectionComponent/models/selectable";
import { IValidityScopeRequest, IValidityScopeResponse } from "models/additions/addition";
import { AdditionState } from "additions/addition/redux/addition.model";

export const calculateSelectableArticleTypesByScope = (validityScope: IValidityScope, mappedBaseData: IAvailableMappedBaseData) => {
    const selectedMainProductLine = validityScope.mainProductLine;
    const selectedBodyArea = validityScope.bodyArea;

    const selectedMainProductLineMapped = mappedBaseData.availableMainProductLines.find(x => x.id === selectedMainProductLine.id);
    const selectedBodyAreaMapped = selectedMainProductLineMapped.bodyAreas.find(x => x.id === selectedBodyArea.id);

    return selectedBodyAreaMapped ? selectedBodyAreaMapped.articleTypes : [];
}

export const calculateSelectableQualitiesByScope = (validityScope: IValidityScope, mappedBaseData: IAvailableMappedBaseData) => {
    const selectedMainProductLine = validityScope.mainProductLine;
    const selectedBodyArea = validityScope.bodyArea;
    const selectedArticleTypes = validityScope.articleTypes;

    const selectedMainProductLineMapped = mappedBaseData.availableMainProductLines.find(x => x.id === selectedMainProductLine.id);
    const selectedBodyAreaMapped = selectedMainProductLineMapped.bodyAreas.find(x => x.id === selectedBodyArea.id);
    if(!selectedBodyAreaMapped){
        return [];
    }
    const selectedArticleTypesMapped = calculateSelectedArticleTypes(selectedBodyAreaMapped, selectedArticleTypes);
    if(!selectedArticleTypesMapped){
        return [];
    }
    const selectableQualities = selectedArticleTypesMapped.map(x => x.qualities).flat();
    
    return removeDuplicates(selectableQualities, "id");
}

export const calculateSelectedArticleTypes = (bodyArea: IAvailableBodyArea, articleTypeSelection: ISelectionList) => {
    if(articleTypeSelection.lockType === LockTypeEnum.Allow){ 
        if(articleTypeSelection.selectedList.allItems.length === 0){
            return bodyArea.articleTypes.filter(x=> articleTypeSelection.unSelectedList.allItems.some(y=> x.id === y.id) );
        }
        else{
            return bodyArea.articleTypes.filter(x=> articleTypeSelection.selectedList.allItems.some(y=> x.id === y.id) );
        }
    }
    else{
        if(articleTypeSelection.selectedList.allItems.length === 0){
            return [];
        }
        return bodyArea.articleTypes.filter(x=> articleTypeSelection.unSelectedList.allItems.some(y=> x.id === y.id) ); 
    }
}

export const calculateArticleTypeSelection = (validityScope: IValidityScope, mappedBaseData: IAvailableMappedBaseData) => {
    const selectableArticleTypes = calculateSelectableArticleTypesByScope(validityScope, mappedBaseData);
    const selectedArticleTypes = validityScope.articleTypes.selectedList.allItems;
    
    const newArticleTypeSelection = {
        lockType: validityScope.articleTypes.lockType,
        selectedList: {
            searchText: validityScope.articleTypes.selectedList.searchText,
            allItems: selectableArticleTypes.filter(x => selectedArticleTypes.some(y=> y.id === x.id)).map(x => toISelectable(x,getIAvailableArticleTypeDisplayName)),
            filteredItems: [],
        },
        unSelectedList: {
            searchText: validityScope.articleTypes.unSelectedList.searchText,
            allItems: selectableArticleTypes.filter(x => selectedArticleTypes.every(y=> y.id !== x.id)).map(x => toISelectable(x,getIAvailableArticleTypeDisplayName)),
            filteredItems: [],
        },
    }
    
    filterSelectionList(newArticleTypeSelection.selectedList);
    filterSelectionList(newArticleTypeSelection.unSelectedList);

    return newArticleTypeSelection;
}

export const calculateQualitiesSelection = (validityScope: IValidityScope, mappedBaseData: IAvailableMappedBaseData) => {
    const selectableQualities = calculateSelectableQualitiesByScope(validityScope, mappedBaseData);
    const selectedQualities = validityScope.qualities.selectedList.allItems;

    const newQualitySelection = {
        lockType: validityScope.qualities.lockType,
        selectedList: {
            searchText: validityScope.qualities.selectedList.searchText,
            allItems: selectableQualities.filter(x => selectedQualities.some(y=> y.id === x.id)).map(x => toISelectable(x,getIAvailableArticleTypeDisplayName)),
            filteredItems: [],
        },
        unSelectedList: {
            searchText: validityScope.qualities.unSelectedList.searchText,
            allItems: selectableQualities.filter(x => selectedQualities.every(y=> y.id !== x.id)).map(x => toISelectable(x,getIAvailableArticleTypeDisplayName)),
            filteredItems: [],
        },
    }

    filterSelectionList(newQualitySelection.selectedList)
    filterSelectionList(newQualitySelection.unSelectedList)

    return newQualitySelection;
}

export const calculateNewValidityScope = (): IValidityScope => {
    return {
        mainProductLine: {displayName: ""},
        bodyArea: {displayName: ""},
        articleTypes: getEmptySelectionList(),
        qualities: getEmptySelectionList()
    };
}

export const createEditValidityScope = (configuration: IValidityScope): IValidityScope => {

    const editorConfiguration: IValidityScope = {
        mainProductLine: configuration.mainProductLine,
        bodyArea: configuration.bodyArea,
        articleTypes: configuration.articleTypes,
        qualities: configuration.qualities
    };

    return editorConfiguration;
}

export const calculateDependentFields = (editableScope: IValidityScope, mappedBaseData: IAvailableMappedBaseData) => {
    const selectedBodyArea = editableScope.bodyArea;
    if(selectedBodyArea.id){
        const loadedMainProductLine = mappedBaseData.availableMainProductLines.find(x=> x.id === editableScope.mainProductLine.id);
        const selectedBodyAreaNoLongerFits = !loadedMainProductLine.bodyAreas.some(x=> x.id === selectedBodyArea.id);
        if(selectedBodyAreaNoLongerFits){
            editableScope.bodyArea = {id: null, displayName: ""};
        }
        else{
            editableScope.articleTypes = calculateArticleTypeSelection(editableScope, mappedBaseData);
            editableScope.qualities = calculateQualitiesSelection(editableScope, mappedBaseData);
        }
    }
}

export const dependentPlaceholdersStillValid = (additionEditor: IAdditionEditor) => {
    const editableScope = additionEditor.addition.editableScope;
    const editableScopeIndex = additionEditor.addition.editableScopeIndex;
    const scopeDeltingIndex = additionEditor.addition.scopeDeletingIndex;
    const otherAdditionScopes = additionEditor.addition.validityScopes.filter((_,index)=> editableScopeIndex !== index && scopeDeltingIndex !== index); 

    const usedQualitiesIds = additionEditor.addition.placeholder.qualityConfigs.filter(x=> doesExist(x.quality)).map(qC => qC.quality.id);
    const availableQualities = [...getSelectedQualitiesFromScopes(otherAdditionScopes), ...getSelectedQualitiesFromScopes(editableScope ? [editableScope] : [])];
    
    const result = usedQualitiesIds.every(x => availableQualities.some(y => y.id === x));

    return result;
}

export const getSelectedQualitiesFromScopes = (additionScopes: IValidityScope[]) => {
    let selectableQualities: ISelectable[] = []
    additionScopes.forEach(scope => {
        let scopeQualities: ISelectable[] = []
        if (scope.qualities.lockType === LockTypeEnum.Allow) {
            if (scope.qualities.selectedList.allItems.length > 0) {
                scopeQualities = scope.qualities.selectedList.allItems;
            }
            else {
                scopeQualities = scope.qualities.unSelectedList.allItems;
            }
        }
        else {
            if (scope.qualities.selectedList.allItems.length > 0) {
                scopeQualities = scope.qualities.unSelectedList.allItems;
            }
            else {
                scopeQualities = [];
            }
        }
        selectableQualities.push(...scopeQualities);
    })

    return removeDuplicates(selectableQualities, "id");

}

export const isEditableValidityScopeValid = (validityScope: IValidityScope, mappedBaseData: IAvailableMappedBaseData) => {
    const mainProductLine = mappedBaseData.availableMainProductLines.find(x => x.id === validityScope.mainProductLine.id);
    const bodyArea = mainProductLine?.bodyAreas.find(x=> x.id === validityScope.bodyArea.id)

    if(bodyArea){
        return true;
    }
    else{
        return false
    }
}

export const hasValidValidityScopes = (state: AdditionState, additionEditor: IAdditionEditor, mappedBaseData: IAvailableMappedBaseData) => {
    const editableScopeIsClosed = !additionEditor.addition.editableScope;
    
    const validScopes = isUniqueValidityScope(additionEditor.addition.validityScopes) 
    && validityScopeValuesValid(additionEditor,mappedBaseData);

    if(!validScopes) {
        state.errorData.editorError = "Die Settings zur Validierung und Filterung sind nicht gültig."
    }

    if(additionEditor.addition.editableScope) {
        state.errorData.editorError = "Die Settings zur Validierung und Filterung werden editiert."
    }

    return validScopes && editableScopeIsClosed;
}

export const validityScopeValuesValid = (additionEditor: IAdditionEditor, mappedBaseData: IAvailableMappedBaseData) => {
    return !additionEditor.addition.validityScopes.some(validityScope => 
        {
            return !isValidValidityScope(validityScope, mappedBaseData);
        });
}

export const isValidValidityScopeByIndex = (scopeIndex: number, validtiyScopes: IValidityScope[], mappedBaseData: IAvailableMappedBaseData) => {
    const scope = validtiyScopes[scopeIndex];
    if(scope){
        return isValidValidityScope(scope, mappedBaseData);
    }
    else{
        return false;
    }
}

const isValidValidityScope = (validityScope: IValidityScope, mappedBaseData: IAvailableMappedBaseData) => {
    const mainProductLine = mappedBaseData.availableMainProductLines.find(x => x.id === validityScope.mainProductLine.id);
    const bodyArea = mainProductLine?.bodyAreas.find(x=> x.id === validityScope.bodyArea.id);
    const selectableArticleTypes = calculateSelectableArticleTypesByScope(validityScope, mappedBaseData);
    const selectableQualities = calculateSelectableQualitiesByScope(validityScope, mappedBaseData);
    const currentSelectableArticleTypes = [...validityScope.articleTypes.selectedList.allItems, ...validityScope.articleTypes.unSelectedList.allItems];
    const currentSelectableQualities = [...validityScope.qualities.selectedList.allItems, ...validityScope.qualities.unSelectedList.allItems];
    const articleTypesValid = currentSelectableArticleTypes.every(x => selectableArticleTypes.some(y=> y.id === x.id));
    const qualitiesValid =currentSelectableQualities.every(x => selectableQualities.some(y=> y.id === x.id));
    
    return doesExist(bodyArea) && articleTypesValid && qualitiesValid;
}

const isUniqueValidityScope = (scopes: IValidityScope[]) => {
    const uniqueArray = removeDuplicates(scopes.map(scope => ({ids: `${scope.mainProductLine.id}${scope.bodyArea.id}`})), "ids");
    return uniqueArray.length === scopes.length;
}

export const calculateMainProductLines = (mappedBaseData: IAvailableMappedBaseData) => {
    return mappedBaseData.availableMainProductLines.map(x => toISelectable(x, getIAvailableProductLineDisplayName));
}

export const calculateBodyAreas = (mappedBaseData: IAvailableMappedBaseData, validityScope: IValidityScope) => {
    const selectedMainProductLine = mappedBaseData.availableMainProductLines
        .find(x => x.id === validityScope.mainProductLine.id);

    if (!selectedMainProductLine) {
        return []
    }

    return selectedMainProductLine.bodyAreas.map(x => toISelectable(x, getIAvailableBodyAreaDisplayName));
}

export const toValidityScopeRequests = (validityScopes: IValidityScope[]): IValidityScopeRequest[] => {
    return validityScopes.map(validityScope => ({
        mainProductLineId: validityScope.mainProductLine.id,
        bodyAreaId: validityScope.bodyArea.id,
        articleTypeValidityType: validityScope.articleTypes.lockType,
        qualityValidityType: validityScope.qualities.lockType,
        articleTypeIds: validityScope.articleTypes.selectedList.allItems.map(x=> x.id),
        qualityIds: validityScope.qualities.selectedList.allItems.map(x=> x.id)
    }) as IValidityScopeRequest);
}

export const toValidityScopeState = (mappedBaseData: IAvailableMappedBaseData, validityScopes: IValidityScopeResponse[]) => {
    
    return validityScopes.map(item => {
        const selectedMainProductionLine = mappedBaseData.availableMainProductLines.find(x=> item.mainProductLineId === x.id);
        const selectedBodyArea = selectedMainProductionLine.bodyAreas.find(x=> item.bodyAreaId === x.id);
        let selectedArticleTypes = selectedBodyArea.articleTypes.filter(x=> item.articleTypeIds.some(y=> y === x.id ));
        
        if(item.articleTypeIds.length === 0){
            selectedArticleTypes = selectedBodyArea.articleTypes;
        }

        const selectableQualities = removeDuplicates(selectedArticleTypes.flatMap(x=> x.qualities),"id");

        return {
            mainProductLine: toISelectable(selectedMainProductionLine, getIAvailableProductLineDisplayName),
            bodyArea: toISelectable(selectedBodyArea, getIAvailableBodyAreaDisplayName),
            articleTypes: createSelectionList(selectedBodyArea.articleTypes, item.articleTypeIds, getIAvailableArticleTypeDisplayName, item.articleTypeValidityType),
            qualities: createSelectionList(selectableQualities, item.qualityIds, getIAvailableQualityDisplayName, item.qualityValidityType),
        } as IValidityScope;
    });
}