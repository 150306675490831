import { filterFootOptionValue } from "../footoptions_overview.helper";
import { FootOptionsOverviewState } from "./footoptions_overview.model";

export const filterFootOptions = (state: FootOptionsOverviewState) => {
    const searchTextLower = state.actualData.searchFilter.toLowerCase().trim();
    let filteredItems = state.loadedData.footOptions.filter(
        (footOption) =>
            footOption.mainProductLine.name.toLowerCase().includes(searchTextLower) ||
            footOption.footType != null && `${footOption.footType.externalIdentifier} ${footOption.footType.name}`
                .toLowerCase()
                .includes(searchTextLower)
    );

    state.actualData.gridFilters.items.forEach(fil => {
        filteredItems = filterFootOptionValue(fil, filteredItems);
    });
    state.actualData.footOptions = filteredItems;
}