import { createAsyncThunk } from '@reduxjs/toolkit';
import { MassImageService } from "../../../../../services/massimage.service";
import { RootState } from '../../../../../app/redux_store';
import { handleResponseErrors } from '../../../../../shared/networking/error_handling';

export const updateMassimageConfig = createAsyncThunk(
    'admin/massimages/overview/config/update',
    async (_, { getState, dispatch }) => {
        const state = getState() as RootState;
        const data = state.massimage_configuration_file_update.actualData;
        const response = await await MassImageService.editConfigFile(state.massimage_configuration_file_update.loadedData.imageId, data.configFile.file);

        handleResponseErrors(response, "Massbild Konfig");
        return response;
    }
);