import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MasspointGroupState } from './masspoint_group.model';
import { editMasspointGroup, getMasspointGroup, getMasspoints, saveMasspointGroup } from './masspoint_group.thunks';
import { toMassPointGroupViewModel } from './masspoint_group.reducer';
import { createSelectionList, filterSelectionList, getEmptySelectionList, moveFromSelectedToUnselected, moveFromUnselectedToSelected } from 'shared/components/selectionComponent/selectionList.helper';
import { getIMasspointOverviewDisplayName } from 'shared/helpers/displayNames';
import { distinctMassPointCriteria } from 'shared/helpers/distinctMassPointCriteria';
import { EditorModeEnum } from 'models/editors/editor_mode.enum';

const initialState: MasspointGroupState = {
    actualData: {
        editorMode: EditorModeEnum.Create,
        masspointGroup: {
            identifier: "",
            nameTranslationKey: "",
            nameTranslation: "",
            massPointCriterias: getEmptySelectionList()
        }
    },
    loadedData: {
        massPointCriterias: [],
        masspointGroup: null
    },
    query: {
        getMasspointGroup: { status: "idle", canExecute: true },
        getMasspoints: { status: "idle", canExecute: true },
    },
    command: {
        editMasspointGroup: { status: "idle", canExecute: true },
        saveMasspointGroup: { status: "idle", canExecute: true },
        cancelMasspoint: { status: "idle", canExecute: false }
    },
}

export const masspointEditSlice = createSlice({
    name: 'masspoints/masspoint/edit',
    initialState,
    reducers: {
        resetState: (state) => {
            state.command = initialState.command;
            state.query = initialState.query;
            state.actualData = initialState.actualData;
        },
        cancelEditor: (state) => {
            state.command.cancelMasspoint.status = "success";
        },
        editMasspointCompleted: (state) => {
            state.command.editMasspointGroup.status = "idle"
        },   
        saveMasspointCompleted: (state) => {
            state.command.saveMasspointGroup.status = "idle"
        },
        setEditorMode: (state, action: PayloadAction<EditorModeEnum>) => {
            state.actualData.editorMode = action.payload
        },
        updateIdentifier: (state, action: PayloadAction<string>) => {
            state.actualData.masspointGroup.identifier = action.payload
        },
        updateNameTranslationKey: (state, action: PayloadAction<string>) => {
            state.actualData.masspointGroup.nameTranslationKey = action.payload
        },
        filterSelectedMassPointCriterias: (state, action: PayloadAction<string>) => {
            state.actualData.masspointGroup.massPointCriterias.selectedList.searchText = action.payload;
            filterSelectionList(state.actualData.masspointGroup.massPointCriterias.selectedList);
        },
        filterUnselectedMassPointCriterias: (state, action: PayloadAction<string>) => {
            state.actualData.masspointGroup.massPointCriterias.unSelectedList.searchText = action.payload;
            filterSelectionList(state.actualData.masspointGroup.massPointCriterias.unSelectedList);
        },
        selectMassPointCriterias: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.actualData.masspointGroup.massPointCriterias, action.payload);
        },
        unSelectMassPointCriterias: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.actualData.masspointGroup.massPointCriterias, action.payload);
        },
    }, extraReducers: (builder) => {

        // getMasspointGroup
        builder.addCase(getMasspointGroup.pending, (state, action) => {
            state.query.getMasspointGroup.status = "pending"
            state.query.getMasspointGroup.canExecute = false;
        }).addCase(getMasspointGroup.rejected, (state, action) => {
            state.query.getMasspointGroup.status = "error"
            state.query.getMasspointGroup.message = action.error.message;
            state.query.getMasspointGroup.canExecute = true;
        }).addCase(getMasspointGroup.fulfilled, (state, action) => {
            state.query.getMasspointGroup.status = "success"
            state.query.getMasspointGroup.message = undefined;
            const loadedMassPointGroup = action.payload.getData();
            state.loadedData.masspointGroup = loadedMassPointGroup;
            state.actualData.masspointGroup = toMassPointGroupViewModel(loadedMassPointGroup, state.loadedData.massPointCriterias);
            state.query.getMasspointGroup.canExecute = true;
            state.command.editMasspointGroup.canExecute = true;

            // edit masspoint
        }).addCase(editMasspointGroup.pending, (state, action) => {
            state.command.editMasspointGroup.status = "pending"
            state.command.editMasspointGroup.canExecute = false;
        }).addCase(editMasspointGroup.rejected, (state, action) => {
            state.command.editMasspointGroup.status = "error"
            state.command.editMasspointGroup.canExecute = true;
            state.command.editMasspointGroup.message = action.error.message;
        }).addCase(editMasspointGroup.fulfilled, (state, action) => {
            state.command.editMasspointGroup.status = "success"
            state.command.editMasspointGroup.canExecute = true;

            // fetchMasspoints
        }).addCase(getMasspoints.pending, (state) => {
            state.query.getMasspoints.status = "pending"
            state.query.getMasspoints.canExecute = false;
        }).addCase(getMasspoints.rejected, (state, action) => {
            state.query.getMasspoints.status = "error"
            state.query.getMasspoints.message = action.error.message;
            state.query.getMasspoints.canExecute = true;
        }).addCase(getMasspoints.fulfilled, (state, action) => {
            state.query.getMasspoints.status = "success"
            state.query.getMasspoints.canExecute = true;
            state.loadedData.massPointCriterias = action.payload.getData().filter(distinctMassPointCriteria);
            state.actualData.masspointGroup.massPointCriterias = createSelectionList(state.loadedData.massPointCriterias, [], getIMasspointOverviewDisplayName);

            // save masspoint
        }).addCase(saveMasspointGroup.pending, (state, action) => {
            state.command.saveMasspointGroup.status = "pending"
            state.command.saveMasspointGroup.canExecute = false;
        }).addCase(saveMasspointGroup.rejected, (state, action) => {
            state.command.saveMasspointGroup.status = "error"
            state.command.saveMasspointGroup.canExecute = true;
            state.command.saveMasspointGroup.message = action.error.message;
        }).addCase(saveMasspointGroup.fulfilled, (state, action) => {
            state.command.saveMasspointGroup.status = "success"
            state.command.saveMasspointGroup.canExecute = true;
        })
    }
})

export const {
    resetState,
    setEditorMode,
    cancelEditor,
    updateIdentifier,
    updateNameTranslationKey,
    editMasspointCompleted,
    saveMasspointCompleted,
    unSelectMassPointCriterias,
    selectMassPointCriterias,
    filterUnselectedMassPointCriterias,
    filterSelectedMassPointCriterias
} = masspointEditSlice.actions

export default masspointEditSlice.reducer