import { Grid } from '@mui/material';
import { DropdownComponent } from 'shared/components/form_controls/dropdown/dropdown.component';
import { isNullOrWhitespace } from 'services/validation.service';
import { IAdditionEditor, IAdditionEditorAction } from 'models/additions/addition_editor.model';
import { SelectionListComponent } from 'shared/components/selectionComponent/selectionList.component';
import { IAvailableMappedBaseData } from 'models/available_basedata/available_mapped_base_data';
import { calculateBodyAreas, calculateMainProductLines } from 'additions/common/helpers/validityScope';

interface ValidityItemComponentProps {
    mappedBaseData: IAvailableMappedBaseData,
    additionEditor: IAdditionEditor,
    additionEditorActions: IAdditionEditorAction,
}

export const ValidityItemComponent: React.FC<ValidityItemComponentProps> = (
    {
        mappedBaseData,
        additionEditor,
        additionEditorActions,
    }) => {

    const bodyAreaSelectionReadOnly = isNullOrWhitespace(additionEditor.addition.editableScope.mainProductLine.displayName);

    return (
        <Grid spacing={3}
            direction="column"
            container
            marginBottom={"20px"}
            justifyContent="space-between">
            <Grid item
                container
                direction="row">
                <Grid item>
                    <DropdownComponent
                        name="Hauptproduktlinie"
                        data={calculateMainProductLines(mappedBaseData)}
                        isLoading={false}
                        isRequired={true}
                        onSelect={additionEditorActions.updateMainProductLine}
                        displayedProperties={["displayName"]}
                        selectedValue={additionEditor.addition.editableScope.mainProductLine}
                    />
                </Grid>
                <Grid item>
                    <DropdownComponent
                        name="Körperregion"
                        data={bodyAreaSelectionReadOnly ? [] : calculateBodyAreas(mappedBaseData,additionEditor.addition.editableScope)}
                        isLoading={false}
                        isRequired={true}
                        isReadonly={bodyAreaSelectionReadOnly}
                        onSelect={additionEditorActions.updateBodyArea}
                        displayedProperties={["displayName"]}
                        selectedValue={additionEditor.addition.editableScope.bodyArea}
                    />
                </Grid>
            </Grid>
            <Grid item md={12}>
                <SelectionListComponent
                    entityName='Artikeltyp'
                    title='Artikeltypen'
                    onFilterSelected={additionEditorActions.filterSelectedArticleType}
                    onFilterUnselected={additionEditorActions.filterUnselectedArticleType}
                    selectEntities={additionEditorActions.selectArticleTypes}
                    unselectEntities={additionEditorActions.unselectArticleTypes}
                    allow={additionEditorActions.allowArticleType}
                    deny={additionEditorActions.denyArticleType}
                    showSelectedAllIcon={true}
                    disabled={isNullOrWhitespace(additionEditor.addition.editableScope.bodyArea.displayName)}
                    selectionList={additionEditor.addition.editableScope.articleTypes} />
            </Grid>
            <Grid item md={12}>
                <SelectionListComponent
                    entityName='Qualität'
                    title='Qualitäten'
                    onFilterSelected={additionEditorActions.filterSelectedQuality}
                    onFilterUnselected={additionEditorActions.filterUnselectedQuality}
                    selectEntities={additionEditorActions.selectQualities}
                    unselectEntities={additionEditorActions.unselectQualities}
                    allow={additionEditorActions.allowQuality}
                    deny={additionEditorActions.denyQuality}
                    showSelectedAllIcon={true}
                    disabled={isNullOrWhitespace(additionEditor.addition.editableScope.bodyArea.displayName)}
                    selectionList={additionEditor.addition.editableScope.qualities} />
            </Grid>
        </Grid>
    );
};