import { createAsyncThunk } from '@reduxjs/toolkit';
import { IProductionInstructionOverview } from 'models/production_instructions/production_instruction_overview';
import { ProductionInstructionService } from 'services/production_instruction.service';
import { handleResponseErrors } from 'shared/networking/error_handling';
import { RootState } from 'app/redux_store';

export const getProductionInstructions = createAsyncThunk(
    'production-instruction/overview/getProductionInstructions',
    async (_) => {
        const response = await ProductionInstructionService.getAll();
        handleResponseErrors(response, "Produktionsanweisungen");
        return response;
    }
)

export const togglePublishProductionInstruction = createAsyncThunk(
    'production-instruction/overview/togglePublishProductionInstruction',
    async (instruction: IProductionInstructionOverview, { dispatch }) => {
        if (instruction.isPublished) {
            await dispatch(unpublishProductionInstruction(instruction));
        } else {
            await dispatch(publishProductionInstruction(instruction));
        }
    }
)

export const publishProductionInstruction = createAsyncThunk(
    'production-instruction/overview/publishProductionInstruction',
    async (instruction: IProductionInstructionOverview, thunkApi) => {
        const responsePublish = await ProductionInstructionService.publish(instruction);
        handleResponseErrors(responsePublish, "Produktionsanweisungen");
        const responseInstructions = await ProductionInstructionService.getAll();
        handleResponseErrors(responseInstructions, "Produktionsanweisungen");
        return responseInstructions;
    }
)

export const unpublishProductionInstruction = createAsyncThunk(
    'production-instruction/overview/unpublishProductionInstruction',
    async (instruction: IProductionInstructionOverview, thunkApi) => {
        const responseUnpublish = await ProductionInstructionService.unpublish(instruction);
        handleResponseErrors(responseUnpublish, "Produktionsanweisungen");
        const responseInstructions = await ProductionInstructionService.getAll();
        handleResponseErrors(responseInstructions, "Produktionsanweisungen");
        return responseInstructions;
    }
)

export const confirmDeleteProductionInstruction = createAsyncThunk(
    'production-instruction/overview/confirmDeleteProductionInstruction',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const activeProductionInstructionId = state.production_instruction_overview.actualData.activeProductionInstruction.productionInstructionId;

        const deleteResponse = await ProductionInstructionService.deleteProductionInstruction(activeProductionInstructionId);
        handleResponseErrors(deleteResponse, "Produktionsanweisungen");

        const productionInstructionsResponse = await ProductionInstructionService.getAll();
        handleResponseErrors(productionInstructionsResponse, "Produktionsanweisungen");
        return productionInstructionsResponse;
    }
)

export const createNewProductionInstruction = createAsyncThunk(
    'production-instruction/overview/createNewProductionInstruction',
    async (_) => { }
)
