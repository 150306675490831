import React, { useState } from "react";
import { Chip, Menu, MenuItem } from "@mui/material";
import { MasspointEvaluation, MasspointEvaluationTestRun } from '../../../models/simulator/product_configuration_evaluation';
import { TestRunIconComponent } from "./test_run_icon.component";
import { doesExist, isNullOrWhitespace } from '../../../services/validation.service';
import { MasspointTypeEnumValues } from '../../../models/masspoints/enums/masspoint_type.enum';
import { MasspointBodySideEnumValues } from "models/masspoints/enums/body_side.enum";
import * as style from "./evaluations_table.style";
import { MenuOverviewComponent } from 'shared/components/overview/menu_overview.component';
import { Link } from "react-router-dom";

export interface IEvaluationsTableComponentProps {
    evaluations: MasspointEvaluation[];
}

export const EvaluationsTableComponent: React.FC<IEvaluationsTableComponentProps> = ({ evaluations }) => {

    const [selectedTestRun, setSelectedTestRun] = useState<MasspointEvaluationTestRun>(null);
    const [menuAnchor, setMenuAnchor] = useState(null);

    const onMenuOpen = (event, testRun: MasspointEvaluationTestRun) => {
        setSelectedTestRun(testRun);
        setMenuAnchor(event.currentTarget);
    };

    const onMenuClose = () => {
        setMenuAnchor(null);
    };

    return (
        <>
            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={onMenuClose}
            >
                <MenuItem component={Link} to={`/plausibilitycheck/check/${selectedTestRun?.matchedCheck?.checkId}/rule/true`}>Regel editieren</MenuItem>
            </Menu>
            <div>
                <style.Headline>
                    <style.HeaderMenuColumn></style.HeaderMenuColumn>
                    <style.HeaderIconColumn></style.HeaderIconColumn>
                    <style.HeaderColumn>Maßpunkt</style.HeaderColumn>
                    <style.HeaderColumn>Maßtyp</style.HeaderColumn>
                    <style.HeaderColumn>Seite</style.HeaderColumn>
                    <style.HeaderColumn>Hauptproduktlinie</style.HeaderColumn>
                    <style.HeaderColumn>Körperregion</style.HeaderColumn>
                    <style.HeaderColumn>Artikelart</style.HeaderColumn>
                    <style.HeaderColumn>Qualität</style.HeaderColumn>
                    <style.HeaderColumn>Prüfungsart</style.HeaderColumn>
                </style.Headline>

                {evaluations.map((evaluation, evaluationIndex) => {
                    return (
                        evaluation.massPointTestRuns.map((testRun, testRunIndex) => {
                            return (
                                <div key={`${evaluationIndex}_${testRunIndex}`}>
                                    <style.Content>
                                        <style.TestInformationContent>
                                            <style.HeaderMenuColumn>
                                                <MenuOverviewComponent onMenuOpen={onMenuOpen}
                                                    menuItem={testRun} />
                                            </style.HeaderMenuColumn>
                                            <style.ContentIconColumn>
                                                <TestRunIconComponent result={testRun.evaluationResult.result} />
                                            </style.ContentIconColumn>
                                            <style.ContentColumn>{evaluation.name}</style.ContentColumn>
                                            <style.ContentColumn>{MasspointTypeEnumValues.find(x => x.value === evaluation.massPointType)?.text}</style.ContentColumn>
                                            <style.ContentColumn>{MasspointBodySideEnumValues.find(x => x.value === evaluation.bodySide)?.text}</style.ContentColumn>
                                            <style.ContentColumn>{testRun.matchedCheck.mainProductLineName}</style.ContentColumn>
                                            <style.ContentColumn>{doesExist(testRun.matchedCheck.bodyAreaId) ? testRun.matchedCheck.bodyAreaName : <Chip size="medium" label="Alle" />}</style.ContentColumn>
                                            <style.ContentColumn>{doesExist(testRun.matchedCheck.articleTypeId) ? testRun.matchedCheck.articleTypeName : <Chip size="medium" label="Alle" />}</style.ContentColumn>
                                            <style.ContentColumn>{doesExist(testRun.matchedCheck.qualityId) ? testRun.matchedCheck.qualityName : <Chip size="medium" label="Alle" />}</style.ContentColumn>
                                            <style.ContentColumn>{testRun.matchedCheck.checkTypeName}</style.ContentColumn>
                                        </style.TestInformationContent>

                                        {isNullOrWhitespace(testRun.evaluationResult.message) === false ?
                                            <style.ErrorContent>
                                                <style.ContentMenuColumn></style.ContentMenuColumn>
                                                <style.ErrorInformationContent>{testRun.evaluationResult.message}</style.ErrorInformationContent>
                                                <style.ContentMenuColumn></style.ContentMenuColumn>
                                            </style.ErrorContent>
                                            : <></>
                                        }
                                    </style.Content>

                                </div>
                            );
                        })
                    )
                }
                )}
            </div>
        </>
    );
};