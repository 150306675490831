import { doesExist, isNullOrWhitespace } from "services/validation.service";
import { PolicyDefaultAdditionByArticleTypeUpdateState } from "./policy_default_addition_by_articletype_update.model";
import { IAdditionPolicyInformation } from "models/additions/addition_policy_information";
import { ISelectable } from "shared/components/selectionComponent/models/selectable";

export const updateCanSave = (state: PolicyDefaultAdditionByArticleTypeUpdateState) => {
    state.command.updatePolicy.canExecute =
        !isNullOrWhitespace(state.data.policy.name)
        && doesExist(state.data.selectedAddition)
        && containsArticleType(state)
        && isNotEqual(state);
}

const containsArticleType = (state: PolicyDefaultAdditionByArticleTypeUpdateState) => {
    return state.data.articleTypes.selectedList.allItems.length > 0;
}

const isNotEqual = (state: PolicyDefaultAdditionByArticleTypeUpdateState) => {
    return state.data.policy.name !== state.loadedData.policy.name
        || state.data.policy.ignoreOnQuotation !== state.loadedData.policy.ignoreOnQuotation
        || state.data.selectedAddition.id !== state.loadedData.policy.additionId
        || haveArticleTypesChanged(state.data.articleTypes.selectedList.allItems, state.loadedData.policy.articleTypes)
}

const haveArticleTypesChanged = (assigned: ISelectable[], loadedArticleTypes: number[]) => {
    let changedItems = false;

    loadedArticleTypes.forEach(x => {
        if (assigned.findIndex(y => y.id === x) === -1) {
            changedItems = true;
        }
    });
    return loadedArticleTypes.length !== assigned.length || changedItems;
}

export const sortAdditions = (mp1: IAdditionPolicyInformation, mp2: IAdditionPolicyInformation) => {
    return mp1.option.localeCompare(mp2.option);
}