import { createAsyncThunk } from '@reduxjs/toolkit';
import ChecktypeHistoryServiceApi from "../../../services/checktype_history.service";
import { handleResponseErrors } from '../../../shared/networking/error_handling';

export const fetchChecktypeHistoryOverviews = createAsyncThunk(
    'checktype_history/check_history_overview/fetchChecktypeHistoryOverviews',
    async (thunkApi) => {
        const response = await ChecktypeHistoryServiceApi.getChecktypesHistoryOverview();
        handleResponseErrors(response, "Prüfungstyp Historie");
        return response;
    }
)