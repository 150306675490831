import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OneLegCreateState } from './onelegs_create.model';
import IAvailableProductLine from '../../../models/available_basedata/available_product_line';
import IAvailableArticleType from '../../../models/available_basedata/available_article_type';
import { fetchAllData, getBaseData, getMassPoints, createOneLeg, cancelSave } from './onelegs_create.thunks';
import { applyFilter, updateCanExecuteCreate, moveFromAvailableToSelected, moveFromSelectedToAvailable, canExecuteCreateFootOption, distinctMassPointCriteria } from './onelegs_create.reducer';

const initialState: OneLegCreateState = {
    actualData: {
        productLines: [],
        selectedProductLine: null,
        articleTypes: [],
        selectedArticleType: null,
        massPointCriteria: {
            selected: {
                searchText: "",
                allItems: [],
                filteredItems: [],
            },
            available: {
                searchText: "",
                allItems: [],
                filteredItems: [],
            },
            loaded: {
                allItems: []
            }
        }
    },
    query: {
        fetchAllData: { status: "idle", canExecute: false },
        getBaseData: { status: "idle", canExecute: false },
        getMassPoints: { status: "idle", canExecute: true },
    },
    command: {
        createOneLeg: { status: "idle", canExecute: false },
        cancel: { status: "idle", canExecute: false },
    }
}

export const oneLegCreateSlice = createSlice({
    name: 'massMask/onelegs/create',
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        resetCreateOneLeg: (state) => {
            state.command.createOneLeg = {
                ...initialState.command.createOneLeg,
                canExecute: canExecuteCreateFootOption(state)
            };
        },
        selectProductLine: (state, action: PayloadAction<IAvailableProductLine>) => {
            state.actualData.selectedProductLine = action.payload;
            updateCanExecuteCreate(state);
        },
        selectArticleType: (state, action: PayloadAction<IAvailableArticleType>) => {
            state.actualData.selectedArticleType = action.payload;
            updateCanExecuteCreate(state);
        },
        selectMassPointCriteria: (state, action: PayloadAction<number>) => {
            moveFromAvailableToSelected(state, action.payload);
            applyFilter(state.actualData.massPointCriteria.selected);
            updateCanExecuteCreate(state);
        },
        deselectMassPointCriteria: (state, action: PayloadAction<number>) => {
            moveFromSelectedToAvailable(state, action.payload);
            applyFilter(state.actualData.massPointCriteria.available);
            updateCanExecuteCreate(state);
        },
        filterSelectedMassPointCriteria: (state, action: PayloadAction<string>) => {
            state.actualData.massPointCriteria.selected.searchText = action.payload;
            applyFilter(state.actualData.massPointCriteria.selected);
        },
        filterAvailableMassPointCriteria: (state, action: PayloadAction<string>) => {
            state.actualData.massPointCriteria.available.searchText = action.payload;
            applyFilter(state.actualData.massPointCriteria.available);
        },
    },
    extraReducers: (builder) => {
        //fetchAllData
        builder.addCase(fetchAllData.pending, (state, action) => {
            state.query.fetchAllData.status = 'pending'
        }).addCase(fetchAllData.rejected, (state, action) => {
            state.query.fetchAllData.status = "error"
            state.query.fetchAllData.message = action.error.message;
        }).addCase(fetchAllData.fulfilled, (state, action) => {
            state.query.fetchAllData.status = "success"
            // getBaseData
        }).addCase(getBaseData.pending, (state, action) => {
            state.query.getBaseData.status = 'pending'
        }).addCase(getBaseData.rejected, (state, action) => {
            state.query.getBaseData.status = "error"
            state.query.getBaseData.message = action.error.message;
        }).addCase(getBaseData.fulfilled, (state, action) => {
            state.query.getBaseData.status = "success"
            state.query.getBaseData.canExecute = true;
            const data = action.payload.getData();
            state.actualData.productLines = data.availableMainProductLines;
            state.actualData.articleTypes = data.availableArticleTypes;

            // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancel.status = 'pending'
            state.command.cancel.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancel.status = "success"
            state.command.cancel.canExecute = false;
            
            //getMasspoints
        }).addCase(getMassPoints.pending, (state, action) => {
            state.query.getMassPoints.status = 'pending'
        }).addCase(getMassPoints.rejected, (state, action) => {
            state.query.getMassPoints.status = "error"
            state.query.getMassPoints.message = action.error.message;
        }).addCase(getMassPoints.fulfilled, (state, action) => {
            state.query.getMassPoints.status = "success"
            state.query.getMassPoints.canExecute = true;
            const massPoints = action.payload.getData();

            const massPointCriteria = massPoints.map(mp => {
                return {
                    massPointName: mp.name,
                    massPointType: mp.massPointType,
                    bodyArea: mp.bodyArea
                }
            }).filter(distinctMassPointCriteria);

            state.actualData.massPointCriteria.available.allItems = massPointCriteria;
            state.actualData.massPointCriteria.loaded.allItems = massPointCriteria;
            applyFilter(state.actualData.massPointCriteria.available);
        })// createOneLeg
            .addCase(createOneLeg.pending, (state, action) => {
                state.command.createOneLeg.status = 'pending'
                state.command.createOneLeg.canExecute = false;
            }).addCase(createOneLeg.rejected, (state, action) => {
                state.command.createOneLeg.status = "error"
                state.command.createOneLeg.canExecute = true;
                state.command.createOneLeg.message = action.error.message;
            }).addCase(createOneLeg.fulfilled, (state, action) => {
                state.command.createOneLeg.status = "success"
                state.command.createOneLeg.canExecute = true;
            })
    }
});

export const {
    resetState,
    resetCreateOneLeg,
    selectProductLine,
    selectArticleType,
    selectMassPointCriteria,
    deselectMassPointCriteria,
    filterSelectedMassPointCriteria,
    filterAvailableMassPointCriteria
} = oneLegCreateSlice.actions

export default oneLegCreateSlice.reducer