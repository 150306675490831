import { ApiResult } from "./api_result";
import { fetchWithTimeout } from "./fetch_with_timeout";
import { authenticationParameters, publicClientApplication } from "../../services/aad.service";
import { getConfig } from "services/config.service";

class HttpServiceCommonResult {
  timeoutMs: number;

  constructor(timeoutMs?: number) {
    if (timeoutMs) {
      this.timeoutMs = timeoutMs;
    } else {
      const appConfig = getConfig();
      this.timeoutMs = Number(appConfig.httpTimeoutMs);
    }
  }

  private async getAccessTokenViaMSAL(): Promise<string> {
    const accounts = publicClientApplication.getAllAccounts();
    if (accounts.length > 0) {
      const request = {
        scopes: authenticationParameters.scopes,
        account: accounts[0]
      }
      const accessToken = await publicClientApplication.acquireTokenSilent(request).then((response) => {
        return response.accessToken;
      }).catch(error => {
        // Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
        console.log(error);
        return null;
      });
      return accessToken;
    }

    return null;
  }

  protected async uploadFileForm<ReturnType>(path: string, content: FormData): Promise<ApiResult<ReturnType>> {
    try {
      const accessToken = await this.getAccessTokenHeader();
      const response = await fetchWithTimeout(path, {
        method: "POST",
        body: content,
        headers: {
          Authorization: accessToken,
        },
        timeout: this.timeoutMs,
      });

      const result = new ApiResult<ReturnType>().withStatusCode(response.status);
      if (response.ok) {
        const data = await response.json();
        result.withData(data);
      }
      return result;
    } catch (error) {
      console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
      return new ApiResult<ReturnType>().withTimeout();
    }
  }

  public async getAccessTokenHeader(): Promise<string> {
    const accessToken = await this.getAccessTokenViaMSAL();
    return `Bearer ${accessToken}`;
  }

  protected async editFileForm<ReturnType>(path: string, content: FormData): Promise<ApiResult<ReturnType>> {
    try {
      const accessToken = await this.getAccessTokenHeader();
      const response = await fetchWithTimeout(path, {
        method: "PUT",
        body: content,
        headers: {
          Authorization: accessToken,
        },
        timeout: this.timeoutMs,
      });

      const result = new ApiResult<ReturnType>().withStatusCode(response.status);
      if (response.ok) {
        const data = await response.json();
        result.withData(data);
      }
      return result;

    } catch (error) {
      console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
      return new ApiResult<ReturnType>().withTimeout();
    }
  }

  protected async get<ReturnType>(path: string): Promise<ApiResult<ReturnType>> {
    try {
      const accessToken = await this.getAccessTokenHeader();
      const response = await fetchWithTimeout(path, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: accessToken,
        },
        timeout: this.timeoutMs,
      });
      const result = new ApiResult<ReturnType>().withStatusCode(response.status);
      if (response.ok) {
        const data = await response.json();
        result.withData(data);
      }
      return result;
    } catch (error) {
      console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
      return new ApiResult<ReturnType>().withTimeout();
    }
  }

  protected async post<ContentType,ReturnType>(path: string, content: ContentType): Promise<ApiResult<ReturnType>> {
    try {
      const accessToken = await this.getAccessTokenHeader();
      const response = await fetchWithTimeout(path, {
        method: "POST",
        body: JSON.stringify(content),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: accessToken,
        },
        timeout: this.timeoutMs,
      });

      const result = new ApiResult<ReturnType>().withStatusCode(response.status);
      if (response.ok) {
        result.withData(await response.json());
      }
      return result;
    } catch (error) {
      console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
      return new ApiResult<ReturnType>().withTimeout();
    }
  }

  protected async postForData<ReturnType>(path: string): Promise<ApiResult<ReturnType>> {
    try {
      const accessToken = await this.getAccessTokenHeader();
      const response = await fetchWithTimeout(path, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: accessToken,
        },
        timeout: this.timeoutMs,
      });
      const result = new ApiResult<ReturnType>().withStatusCode(response.status);
      if (response.ok) {       
        const data = await response.json();
        result.withData(data);
      }
      return result;
    } catch (error) {
      console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
      return new ApiResult<ReturnType>().withTimeout();
    }
  }

  protected async put<ContentType,ReturnType>(path: string, content?: ContentType): Promise<ApiResult<ReturnType>> {
    try {
      const accessToken = await this.getAccessTokenHeader();
      const response = await fetchWithTimeout(path, {
        method: "PUT",
        body: content ? JSON.stringify(content) : null,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: accessToken,
        },
        timeout: this.timeoutMs,
      });

      const result = new ApiResult<ReturnType>().withStatusCode(response.status);
      if (response.ok) {
        result.withData(await response.json());
      }
      return result;
    } catch (error) {
      console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
      return new ApiResult<ReturnType>().withTimeout();
    }
  }

  protected async delete(path: string): Promise<ApiResult<void>> {
    try {
      const accessToken = await this.getAccessTokenHeader();

      const response = await fetchWithTimeout(path, {
        method: "DELETE",
        headers: {
          Authorization: accessToken,
        },
        timeout: this.timeoutMs,
      });

      const result = new ApiResult<void>().withStatusCode(response.status);
      if (response.ok) {
        result.withData(await response.json());
      }

      return result;
    } catch (error) {
      console.warn(`HTTP timeout after ${this.timeoutMs} ms`)
      return new ApiResult<void>().withTimeout();
    }
  }
}

export default HttpServiceCommonResult;
