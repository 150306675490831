import { filterAdditionPositionsValue } from "../addition_position_overview.helper";
import { AdditionPositionOverviewState } from "./addition_position_overview.model";

export const filterAdditionPositions = (state: AdditionPositionOverviewState) => {
    const searchTextLower = state.actualData.searchFilter.toLowerCase().trim();
    let filteredItems = state.loadedData.additionPositions.filter(
        (additionPosition) =>
            additionPosition.name.toLowerCase().includes(searchTextLower)
            || additionPosition.translationKeyForName.toLowerCase().includes(searchTextLower)
            || additionPosition.selectedPositionHierarchys.findIndex(x => x.additionHierarchy.name.toLowerCase().includes(searchTextLower)) >= 0
    );

    state.actualData.gridFilters.items.forEach(fil => {
        filteredItems = filterAdditionPositionsValue(fil, filteredItems);
    });

    state.actualData.additionPositions = filteredItems;
}