import { IMasspointGroup, IMasspointGroupViewModel } from "models/masspoint_groups/masspoint_group"
import { MassPointCriteria } from "models/masspoints/masspoint_criteria"
import IMasspointOverview from "models/masspoints/masspoint_overview"
import { createSelectionList } from "shared/components/selectionComponent/selectionList.helper"
import { getIMasspointOverviewDisplayName } from "shared/helpers/displayNames"

export const toMassPointGroupViewModel = (masspointGroup: IMasspointGroup, loadedMassPointCriterias: IMasspointOverview[]): IMasspointGroupViewModel => {
    const selectedCriteriaIds = masspointGroup.massPointCriterias.map(mpc => 
        {
            const matchingMassPointCriteria = loadedMassPointCriterias.find(loadedmpc => 
                loadedmpc.name === mpc.massPointName && 
                loadedmpc.massPointType === mpc.massPointType && 
                loadedmpc.bodyArea.id === mpc.bodyAreaId);
    
            return matchingMassPointCriteria.id;
        });
    
    return {
        id: masspointGroup.id,
        identifier: masspointGroup.identifier,
        nameTranslationKey: masspointGroup.nameTranslationKey,
        nameTranslation: masspointGroup.nameTranslation,
        massPointCriterias: createSelectionList(loadedMassPointCriterias, selectedCriteriaIds, getIMasspointOverviewDisplayName)
    }
}

export const fromMassPointGroupViewModel = (massPointGroupViewModels: IMasspointGroupViewModel, loadedMasspointCriterias: IMasspointOverview[]): IMasspointGroup => {
    return {
        id: massPointGroupViewModels.id,
        identifier: massPointGroupViewModels.identifier,
        nameTranslationKey: massPointGroupViewModels.nameTranslationKey,
        nameTranslation: massPointGroupViewModels.nameTranslation,
        massPointCriterias: massPointGroupViewModels.massPointCriterias.selectedList.allItems.map(x =>
            mapToPureCriteria(loadedMasspointCriterias.find(y => y.id === x.id)))
    }
}

const mapToPureCriteria = (masspointCriteria: IMasspointOverview): MassPointCriteria => {
    return {
        massPointName: masspointCriteria.name,
        massPointType: masspointCriteria.massPointType,
        bodyAreaId: masspointCriteria.bodyArea.id
    }
}
