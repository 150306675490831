import { isNullOrWhitespace } from "services/validation.service";
import { CharacteristicTypeEditState } from "./characteristic_type_edit.model";
import { ICharacteristicTypeViewModel, ICharacteristicValueViewModel } from 'additions/characteristic_type/components/characteristic_type_editor.model';
import { ICharacteristicType, ICharacteristicValue } from "models/characteristic_types/characteristic_type";

export const updateCanSave = (state: CharacteristicTypeEditState) => {
    state.command.updateCharacteristicType.canExecute =
        !isNullOrWhitespace(state.data.characteristicType.name)
        && !isNullOrWhitespace(state.data.characteristicType.shortKey)
        && valuesValide(state.data.characteristicType.values, state.loadedData.characteristicType.values)
        && (
            state.data.characteristicType.name !== state.loadedData.characteristicType.name
            || state.data.characteristicType.shortKey !== state.loadedData.characteristicType.shortKey
            || changedValues(state.data.characteristicType.values, state.loadedData.characteristicType.values)
        );
}

const valuesValide = (values: ICharacteristicValueViewModel[], loadedValues: ICharacteristicValue[]) => {
    let valuesValide = true;
    values.forEach(x => {
        const valueValide = !isNullOrWhitespace(x.name)
            && !isNullOrWhitespace(x.translationKey)
            && !(values.filter(y => y.name === x.name).length > 1);

        if(!valueValide){
            valuesValide = valueValide;
        }
    });

    return values.length > 0 && valuesValide;
}

const changedValues = (values: ICharacteristicValueViewModel[], loadedValues: ICharacteristicValue[]) => {
    let valuesChanged = false;
    values.forEach(x => {
        const changed = loadedValues.filter(y => y.name === x.name 
            && y.translationKeyForName === x.translationKey 
            && y.id === x.id).length !== 1;

        if(changed){
            valuesChanged = changed;
        }
    });

    return (values.length !== loadedValues.length) || valuesChanged;
}

export const initializeData = ( characteristicType: ICharacteristicType) : ICharacteristicTypeViewModel => {
    return {
        id: characteristicType.id,
        name: characteristicType.name,
        shortKey: characteristicType.shortKey,
        values: characteristicType.values.map(x => { return {
           id: x.id,
           name: x.name,
           translationKey: x.translationKeyForName,
           translation: x.translation 
        }})
    };
}