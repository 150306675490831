import { useAppDispatch, useAppSelector } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { useEffect } from "react";
import { resetState, updateQuality, updateArticleType, completeFetchPreviewError } from "./redux/serial_size_preview.slice";
import { LoadingIndicatorComponent } from "shared/components/loading_indicator/loading_indicator.component";
import { LoadingOverlayComponent, LoadingOverlayContainer } from "shared/components/loading_overlay/loading_overlay.component";
import { Grid } from "@mui/material";
import { DropdownComponent, FlexBox, Left, PrimaryButtonComponent, Right } from "shared/shared";
import { fetchBaseData, fetchSerialSizesPreviews, finish } from "./redux/serial_size_preview.thunks";
import { useSnackbar } from "notistack";
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from "react-router-dom";
import { SizePreviewComponent } from "./component/serial_sizes.component";
import { AttributePreviewComponent } from "./component/serial_attributes.component";

export const SerialSizesPreviewComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.serial_sizes_preview);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchBaseData());
        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    const articleType = useAppSelector((state) => state.serial_sizes_preview.actualData.articleType);
    const quality = useAppSelector((state) => state.serial_sizes_preview.actualData.quality);

    useEffect(() => {
        if (state.query.fetchSerialSizePreview.canExecute === true) {
            dispatch(fetchSerialSizesPreviews());
        }
    }, [dispatch, articleType, quality]);

    if (state.query.fetchBaseData.status === "error") {
        return <div>Fehler beim Laden der Daten</div>;
    }

    if (state.query.fetchBaseData.status !== "success") {
        return <LoadingIndicatorComponent />;
    }

    if (state.query.fetchSerialSizePreview.status === "error") {
        enqueueSnackbar(state.query.fetchSerialSizePreview.message, { variant: "error" });
        dispatch(completeFetchPreviewError())
    }

    if (state.command.finish.status === "success") {
        navigate("/masspoints/serialsize/overview");
        dispatch(resetState());
    }

    const isLoading = state.query.fetchSerialSizePreview.status === "pending";
    const notLoaded = state.actualData.serialSizePreview == null;

    return (<LoadingOverlayContainer>
        {isLoading ? <LoadingOverlayComponent /> : <></>}
        <Grid
            container
            direction="column"
            spacing={3}>
            <Grid item>
                <FlexBox>
                    <Left>
                        <DropdownComponent
                            name="Qualität"
                            data={state.loadedData.qualities}
                            isLoading={isLoading}
                            isRequired={true}
                            onSelect={(value) => dispatch(updateQuality(value))}
                            displayedProperties={["erpId", "name"]}
                            selectedValue={state.actualData.quality}
                        />
                        <DropdownComponent
                            name="Artikelart"
                            data={state.loadedData.articleTypes}
                            isLoading={isLoading}
                            isRequired={true}
                            onSelect={(value) => dispatch(updateArticleType(value))}
                            displayedProperties={["erpId", "name"]}
                            selectedValue={state.actualData.articleType}
                        />
                    </Left>
                </FlexBox>
            </Grid>
            {notLoaded && <Grid item
                container
                justifyContent={"center"}
                alignContent={"center"}
                style={{ "minHeight": 250 }}>
                <div>Keine Seriengröße geladen</div>
            </Grid>}
            {!notLoaded && <Grid item
                container
                style={{ "minHeight": 250 }}>
                {state.actualData.serialSizePreview.sizes.length && <SizePreviewComponent sizes={state.actualData.serialSizePreview.sizes}
                    isLoading={isLoading} />
                }
                {state.actualData.serialSizePreview.attributes.length > 0 &&
                    <AttributePreviewComponent attributes={state.actualData.serialSizePreview.attributes}
                        isLoading={isLoading} />
                }
            </Grid>}
            <Grid item>
                <FlexBox>
                    <Right>
                        <PrimaryButtonComponent onClick={() => dispatch(finish())}>
                            <ClearIcon /> Schliessen
                        </PrimaryButtonComponent>
                    </Right>
                </FlexBox>
            </Grid>
        </Grid>
    </LoadingOverlayContainer>
    )
};