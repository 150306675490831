
import { Grid, IconButton, Switch, TextField } from "@mui/material";
import { IAttributeMasspointConfiguration, IDefaultByAttributeEditor, IDefaultByAttributeEditorActions, IDefaultByAttributeLoadedData, SelectArticleTypePayload } from "../models/policy_mandatory_by_attribute.models";
import { DropdownComponent, FlexBox, PrimaryButtonComponent, Right } from "shared/shared";
import { doesExist, isNullOrWhitespace } from "services/validation.service";
import { EditorMediumSectionHeaderComponent, EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { AdditionPlaceHolderTypeEnum } from "models/additions/addition_placeholder.enum";
import IAvailableArticleType from "models/available_basedata/available_article_type";
import { MasspointTypeEnumValuesLookup } from "models/masspoints/enums/masspoint_type.enum";
import { MassPointCriteriaSide } from "models/masspoints/masspoint_criteria";
import { MasspointBodySideEnumValuesLookup } from "models/masspoints/enums/body_side.enum";
import { distinctMassPointCriteriaSide } from "check/policy/helper/policy.helper";
import { SelectionArticleTypeComponent } from "additions/addition/components/validities/addition_validity_item_articleType_selection.component";
import { SelectionQualityComponent } from "additions/addition/components/validities/addition_validity_item_quality_selection.component";
import { useAppSelector, useAppDispatch } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { addMasspointConfiguration, filterSelectedArticleType, filterSelectedQuality, filterUnselectedArticleType, filterUnselectedQuality, removeDefaultMasspointConfiguration, removeMasspointConfiguration, selectArticleTypes, selectDefaultMasspointOne, selectDefaultMasspointTwo, selectMasspointOne, selectMasspointTwo, selectQualities, selectRangeArticleType, toggleIsSideDependant, unselectArticleTypes, unselectQualities, updateBodyArea, updateMainProductLine } from "../redux/policy_mandatory_by_attribute.slice";
import { EditorDescriptionComponent } from "shared/components/editors/editor_description/editor_description.style";

export const PolicyMandatoryByAttributeSubConfigComponent = () => {
    const state = useAppSelector((state) => state.policy_mandatory_by_attribute);
    const dispatch: AppDispatch = useAppDispatch();

    const calculateMainProductLines = () => {
        const editableConfig = state.data.editor.editableConfig;
        const mappedBaseData = state.loadedData.mappedBaseDatasByAddition.find(x => x.additionId === editableConfig?.addition?.id);
        return mappedBaseData.availableMainProductLines;
    }

    const calculateBodyAreas = () => {
        const editableConfig = state.data.editor.editableConfig;
        const mappedBaseData = state.loadedData.mappedBaseDatasByAddition.find(x => x.additionId === editableConfig?.addition?.id);

        const selectedMainProductLine = mappedBaseData.availableMainProductLines
            .find(x => x.id === state.data.editor.editableSubConfig.mainProductLine.id);

        let alreadySelectedbodyAreas = [];
        if (doesExist(selectedMainProductLine)) {
            alreadySelectedbodyAreas = state.data.editor.editableConfig.subConfigs
                .filter((x, index) => x.mainProductLine.id === selectedMainProductLine.id && index !== state.data.editor.editableSubConfigIndex)
                .map(x => x.bodyArea.id);
        }

        return selectedMainProductLine ? selectedMainProductLine.bodyAreas.filter(x => !alreadySelectedbodyAreas.some(y => y === x.id)) : [];
    }

    const mainProductLineSelected = isNullOrWhitespace(state.data.editor.editableSubConfig?.mainProductLine?.name);
    const bodyAreaSelected = isNullOrWhitespace(state.data.editor.editableSubConfig?.bodyArea?.name);
    const isSideDependant = state.data.editor.editableSubConfig.isSideDependant;
    const masspointsByBodyAreaWithSide = state.loadedData.masspoints.filter(x => x.bodyArea.id === state.data.editor.editableSubConfig.bodyArea.id);
    const masspointsByBodyAreaWithoutSide = masspointsByBodyAreaWithSide.filter(distinctMassPointCriteriaSide);

    const allArticleTypes = (): IAvailableArticleType[] => {
        let allItems = state.data.editor.editableSubConfig.articleTypes.selectedList.allItems;
        if (allItems.length === 0) {
            allItems = state.data.editor.editableSubConfig.articleTypes.unSelectedList.allItems;
        }

        return allItems;
    }

    const calculateArticleTypes = (): IAvailableArticleType[] => {
        let allItems = allArticleTypes();
        const masspointConfigurations = state.data.editor.editableSubConfig.attributeMasspointConfigurations;
        const alreadySelectedArticleTypeIds = masspointConfigurations.flatMap(x => x.articleType?.id);
        return allItems.filter(x => alreadySelectedArticleTypeIds.find(articleTypeId => articleTypeId === x.id) == undefined);
    }

    const isValid = (value: IAttributeMasspointConfiguration): boolean => {
        if (value.articleType == null) {
            return false;
        }

        if (value.masspointOne == null) {
            return false;
        }

        if (state.data.editor.editableConfig.addition.placeholderType === AdditionPlaceHolderTypeEnum.TwoRangeInput) {
            if (value.masspointTwo == null) {
                return false;
            }
        }

        if (value.articleType == null) {
            return true;
        }
        const availableArticleTypes = allArticleTypes();
        const isValidArticleType = availableArticleTypes.map(x => x.id).includes(value.articleType.id)

        return isValidArticleType;
    }

    const buildMasspointDisplayText = (mp: MassPointCriteriaSide): string => {
        return `${mp.massPointName} ${MasspointTypeEnumValuesLookup(mp.massPointType)} ${mp.bodyArea.name}`;
    }

    const buildMasspointSideDisplayText = (mp: MassPointCriteriaSide): string => {
        return `${mp.massPointName} ${MasspointTypeEnumValuesLookup(mp.massPointType)} ${mp.bodyArea.name} ${MasspointBodySideEnumValuesLookup(mp.bodySide)}`;
    }

    const renderAttributeMasspointConfiguration = (value: IAttributeMasspointConfiguration, index: number) =>
        <Grid item key={index} sx={{ border: isValid(value) ? "transparent" : "2px solid red", margin: "0px 0px 10px 0px", padding: isValid(value) ? "0px" : "5px" }} >
            <DropdownComponent
                name="Artikelart"
                isReadonly={bodyAreaSelected}
                data={calculateArticleTypes()}
                isRequired={true}
                onSelect={(value) => dispatch(selectRangeArticleType({ articleType: value, index: index }))}
                displayedProperties={["name"]}
                selectedValue={value.articleType}
            />
            {
                state.data.editor.editableConfig.addition.placeholderType === AdditionPlaceHolderTypeEnum.RangeInput &&
                <DropdownComponent
                    name="1. Maßpunkt"
                    isReadonly={bodyAreaSelected}
                    data={masspointsByBodyAreaWithoutSide}
                    isRequired={true}
                    onSelect={(value) => dispatch(selectMasspointOne({ masspoint: value, index: index }))}
                    renderEntry={buildMasspointDisplayText}
                    selectedValue={value.masspointOne}
                />
            }
            {
                state.data.editor.editableConfig.addition.placeholderType === AdditionPlaceHolderTypeEnum.TwoRangeInput &&
                <>
                    <DropdownComponent
                        name="1. Maßpunkt"
                        isReadonly={bodyAreaSelected}
                        data={isSideDependant ? masspointsByBodyAreaWithSide : masspointsByBodyAreaWithoutSide}
                        isRequired={true}
                        onSelect={(value) => dispatch(selectMasspointOne({ masspoint: value, index: index }))}
                        renderEntry={isSideDependant ? buildMasspointSideDisplayText : buildMasspointDisplayText}
                        selectedValue={value.masspointOne}
                    />
                    <DropdownComponent
                        name="2. Maßpunkt"
                        isReadonly={bodyAreaSelected}
                        data={isSideDependant ? masspointsByBodyAreaWithSide : masspointsByBodyAreaWithoutSide}
                        isRequired={true}
                        onSelect={(value) => dispatch(selectMasspointTwo({ masspoint: value, index: index }))}
                        renderEntry={isSideDependant ? buildMasspointSideDisplayText : buildMasspointDisplayText}
                        selectedValue={value.masspointTwo}
                    />
                </>
            }
            <IconButton
                style={{ outline: "none" }}
                onClick={() => dispatch(removeMasspointConfiguration(index))}>
                <DeleteIcon />
            </IconButton>
        </Grid>

    const articleMassPointConfigDisabled = bodyAreaSelected
        || (
            AdditionPlaceHolderTypeEnum.TwoRangeInput
            && !doesExist(state.data.editor.editableSubConfig.defaultMasspointOne)
            && !doesExist(state.data.editor.editableSubConfig.defaultMasspointTwo)
        )
        || (
            AdditionPlaceHolderTypeEnum.RangeInput
            && !doesExist(state.data.editor.editableSubConfig.defaultMasspointOne)
        );

    return (
        <Grid spacing={3}
            direction="column"
            container
            marginBottom={"20px"}
            justifyContent="space-between">
            <Grid item md={12}>
                <EditorMediumSectionHeaderComponent style={{ marginTop: "0px", marginBottom: "25px" }}>Artikelabhängige Bedingung</EditorMediumSectionHeaderComponent>
            </Grid>
            <Grid item
                container
                direction="row">
                <Grid item>
                    <DropdownComponent
                        name="Hauptproduktlinie"
                        data={calculateMainProductLines()}
                        isRequired={true}
                        onSelect={(newValue) => dispatch(updateMainProductLine(newValue))}
                        displayedProperties={["name"]}
                        selectedValue={state.data.editor.editableSubConfig.mainProductLine}
                    />
                </Grid>
                <Grid item>
                    <DropdownComponent
                        name="Körperregion"
                        data={mainProductLineSelected ? [] : calculateBodyAreas()}
                        isRequired={true}
                        isReadonly={mainProductLineSelected}
                        onSelect={(newValue) => dispatch(updateBodyArea(newValue))}
                        displayedProperties={["name"]}
                        selectedValue={state.data.editor.editableSubConfig.bodyArea}
                    />
                </Grid>
            </Grid>
            <Grid container
                spacing={3}
                item
                md={12}>
                <SelectionArticleTypeComponent
                    onFilterSelected={(searchText) => dispatch(filterSelectedArticleType(searchText))}
                    onFilterUnselected={(searchText) => dispatch(filterUnselectedArticleType(searchText))}
                    select={(selection) => dispatch(selectArticleTypes(selection))}
                    unselect={(selection) => dispatch(unselectArticleTypes(selection))}
                    isReadOnly={bodyAreaSelected}
                    showSelectedAllIcon={true}
                    articleTypeSelection={state.data.editor.editableSubConfig.articleTypes} />
            </Grid>
            <Grid container
                spacing={3}
                item
                md={12}>
                <SelectionQualityComponent
                    onFilterSelected={(searchText) => dispatch(filterSelectedQuality(searchText))}
                    onFilterUnselected={(searchText) => dispatch(filterUnselectedQuality(searchText))}
                    select={(selection) => dispatch(selectQualities(selection))}
                    unselect={(selection) => dispatch(unselectQualities(selection))}
                    showSelectedAllIcon={true}
                    isReadOnly={bodyAreaSelected}
                    qualitySelection={state.data.editor.editableSubConfig.qualities}
                />
            </Grid>
            <Grid
                spacing={3}
                direction="column"
                container
                item
                md={12}
                justifyContent="space-between">
                {
                    (state.data.editor.editableConfig.addition.placeholderType === AdditionPlaceHolderTypeEnum.RangeInput
                        || state.data.editor.editableConfig.addition.placeholderType === AdditionPlaceHolderTypeEnum.TwoRangeInput) &&
                    <>
                        {
                            state.data.editor.editableConfig.addition.placeholderType === AdditionPlaceHolderTypeEnum.TwoRangeInput &&
                            <Grid item md={12}>
                                <EditorDescriptionComponent>Seitenabhängig?</EditorDescriptionComponent>
                                <Switch
                                    checked={state.data.editor.editableSubConfig.isSideDependant}
                                    onChange={(event) => dispatch(toggleIsSideDependant(event.target.checked))} />
                            </Grid>
                        }
                        <Grid item>
                            <EditorMediumSectionHeaderComponent>Default</EditorMediumSectionHeaderComponent>
                        </Grid>
                    </>

                }
                {
                    state.data.editor.editableConfig.addition.placeholderType === AdditionPlaceHolderTypeEnum.RangeInput &&
                    <Grid item>
                        <DropdownComponent
                            name="1. Maßpunkt"
                            isReadonly={bodyAreaSelected}
                            data={masspointsByBodyAreaWithoutSide}
                            isRequired={true}
                            onSelect={(value: MassPointCriteriaSide) => dispatch(selectDefaultMasspointOne(value))}
                            renderEntry={buildMasspointDisplayText}
                            selectedValue={state.data.editor.editableSubConfig.defaultMasspointOne}
                        />
                        <IconButton
                            disabled={state.data.editor.editableSubConfig.attributeMasspointConfigurations.length > 0}
                            style={{ outline: "none" }}
                            onClick={() => dispatch(removeDefaultMasspointConfiguration())}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                }
                {
                    state.data.editor.editableConfig.addition.placeholderType === AdditionPlaceHolderTypeEnum.TwoRangeInput &&
                    <Grid item>
                        <DropdownComponent
                            name="1. Maßpunkt"
                            isReadonly={bodyAreaSelected}
                            data={isSideDependant ? masspointsByBodyAreaWithSide : masspointsByBodyAreaWithoutSide}
                            isRequired={true}
                            onSelect={(value: MassPointCriteriaSide) => dispatch(selectDefaultMasspointOne(value))}
                            renderEntry={isSideDependant ? buildMasspointSideDisplayText : buildMasspointDisplayText}
                            selectedValue={state.data.editor.editableSubConfig.defaultMasspointOne}
                        />
                        <DropdownComponent
                            name="2. Maßpunkt"
                            isReadonly={bodyAreaSelected}
                            data={isSideDependant ? masspointsByBodyAreaWithSide : masspointsByBodyAreaWithoutSide}
                            isRequired={true}
                            onSelect={(value: MassPointCriteriaSide) => dispatch(selectDefaultMasspointTwo(value))}
                            renderEntry={isSideDependant ? buildMasspointSideDisplayText : buildMasspointDisplayText}
                            selectedValue={state.data.editor.editableSubConfig.defaultMasspointTwo}
                        />
                        <IconButton
                            disabled={state.data.editor.editableSubConfig.attributeMasspointConfigurations.length > 0}
                            style={{ outline: "none" }}
                            onClick={() => dispatch(removeDefaultMasspointConfiguration())}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                }
                <Grid item>
                    {
                        state.data.editor.editableSubConfig.attributeMasspointConfigurations.map((value, index) => renderAttributeMasspointConfiguration(value, index))
                    }
                </Grid>
                {
                    (state.data.editor.editableConfig.addition.placeholderType === AdditionPlaceHolderTypeEnum.RangeInput
                        || state.data.editor.editableConfig.addition.placeholderType === AdditionPlaceHolderTypeEnum.TwoRangeInput) &&
                    <FlexBox>
                        <Right>
                            <PrimaryButtonComponent
                                disabled={articleMassPointConfigDisabled}
                                onClick={() => dispatch(addMasspointConfiguration())}>
                                <AddIcon />{"Artikelabhängige Rangewert Konfiguration hinzufügen"}
                            </PrimaryButtonComponent>
                        </Right>
                    </FlexBox>
                }
            </Grid>
        </Grid>
    );
};