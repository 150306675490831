import styled from "@emotion/styled";

export const ButtonMargin = styled.span`
  margin-right: 10px;
`

export const FinalizeButtonComponentWrapper = styled.div`
  margin-top: 20px;
`;

export const OrWrapper = styled.div`
  padding: 20px;
`;