export interface DateTimeDisplayComponentyProps {
    date: Date;
}

export const DateTimeDisplayComponent: React.FC<DateTimeDisplayComponentyProps> = ({
    date
}) => {
    return (
        <>
            {
                new Date(date).toLocaleDateString() + " " + new Date(date).toLocaleTimeString()
            }
        </>);
};
