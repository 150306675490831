import { IPolicyMandatoryCategory } from "models/policies/policy_mandatory_category";
import { IPolicyMandatoryCategoryByMasspointCreate, IPolicyMandatoryCategoryByMasspointUpdate } from "models/policies/policy_mandatory_category_by_masspoint_create";
import { IPolicyMandatoryCategoryCreate, IPolicyMandatoryCategoryUpdate } from "models/policies/policy_mandatory_category_create";
import { IPolicyOverview } from "models/policies/policy_overview";
import { IPolicySoftSeam } from "models/policies/policy_soft_seam";
import { IPolicySoftSeamCreate, IPolicySoftSeamUpdate } from "models/policies/policy_soft_seam_create";
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from './config.service';
import { IPolicyMandatoryCategoryByMasspoint } from "models/policies/policy_mandatory_category_by_masspoint";
import { IPolicyAdditionRankCreate, IPolicyAdditionRankUpdate } from "models/policies/policy_addition_rank_create";
import { IPolicyAdditionRank } from "models/policies/policy_addition_rank";
import { IPolicyDefaultAdditionCreate, IPolicyDefaultAdditionUpdate } from "models/policies/policy_default_addition_create";
import { IPolicyDefaultAddition } from "models/policies/policy_default_addition";
import HttpServiceCommonResult from "shared/networking/http_service_common_result";
import { IPolicyExcludeAddition } from "models/policies/policy_exclude_addition";
import { IPolicyExcludeAdditionCreate, IPolicyExcludeAdditionUpdate } from "models/policies/policy_exclude_addition_create";
import { IPolicyUpperThresholdCreate, IPolicyUpperThresholdUpdate } from "models/policies/policy_upper_threshold_create";
import { IPolicyUpperThreshold } from "models/policies/policy_upper_threshold";
import { IPolicyDefaultAdditionByQualityAndAttributeCreate, IPolicyDefaultAdditionByQualityAndAttributeUpdate } from "models/policies/policy_default_addition_by_quality_and_attribute_create";
import { IPolicyDefaultAdditionByQualityAndAttribute } from "models/policies/policy_default_addition_by_quality_and_attribute";
import { IPolicyExcludeAdditionByMasspointValue } from "models/policies/policy_exclude_addition_by_masspoint_value";
import { IPolicyExcludeAdditionByMasspointValueCreate, IPolicyExcludeAdditionByMasspointValueUpdate } from "models/policies/policy_exclude_addition_by_masspoint_value_create";
import { IPolicyAdjustRangesByMasspoint } from "models/policies/policy_adjust_ranges_by_masspoint";
import { IPolicyAdjustRangesByMasspointCreate, IPolicyAdjustRangesByMasspointUpdate } from "models/policies/policy_adjust_ranges_by_masspoint_create";
import { IPolicyAdjustMaximumRangeCreate, IPolicyAdjustMaximumRangeUpdate } from "models/policies/policy_adjust_maximum_range_create";
import { IPolicyAdjustMaximumRange } from "models/policies/policy_adjust_maximum_range";
import { IPolicyMandatoryByAttributeCreate, IPolicyMandatoryByAttributeUpdate } from "models/policies/policy_mandatory_by_attribute_create";
import { IPolicySideSeam, IPolicySideSeamCreate, IPolicySideSeamUpdate } from "models/policies/policy_side_seam";
import { IPolicyMandatoryByAttributeGet } from "models/policies/policy_mandatory_by_attribute_get";
import { IPolicyFingersAndToes, IPolicyFingersAndToesCreate, IPolicyFingersAndToesUpdate } from "models/policies/policy_fingers_and_toes";
import { IPolicyScarTreatment, IPolicyScarTreatmentCreate, IPolicyScarTreatmentUpdate } from "models/policies/policy_scar_treatment";

class PolicyAPI extends HttpServiceCommonResult {
    public async getMandatoryCategory(id: string): Promise<ApiResult<IPolicyMandatoryCategory>> {
        return this.get<IPolicyMandatoryCategory>(`/api/policy/${id}/mandatorycategory`);
    }

    public async getMandatoryCategoryByMasspoint(id: string): Promise<ApiResult<IPolicyMandatoryCategoryByMasspoint>> {
        return this.get<IPolicyMandatoryCategoryByMasspoint>(`/api/policy/${id}/mandatorycategorybymasspoint`);
    }

    public async getSoftSeam(id: string): Promise<ApiResult<IPolicySoftSeam>> {
        return this.get<IPolicySoftSeam>(`/api/policy/${id}/softseam`);
    }

    public async getAdditionRank(id: string): Promise<ApiResult<IPolicyAdditionRank>> {
        return this.get<IPolicyAdditionRank>(`/api/policy/${id}/additionrank`);
    }

    public async getDefaultAddition(id: string): Promise<ApiResult<IPolicyDefaultAddition>> {
        return this.get<IPolicyDefaultAddition>(`/api/policy/${id}/defaultaddition`);
    }

    public async getMandatoryByAttribute(id: string): Promise<ApiResult<IPolicyMandatoryByAttributeGet>> {
        return this.get<IPolicyMandatoryByAttributeGet>(`/api/policy/${id}/mandatorybyattribute`);
    }

    public async getDefaultAdditionByQualityAndAttribute(id: string): Promise<ApiResult<IPolicyDefaultAdditionByQualityAndAttribute>> {
        return this.get<IPolicyDefaultAdditionByQualityAndAttribute>(`/api/policy/${id}/defaultadditionbyqualityandattribute`);
    }

    public async getExcludeAddition(id: string): Promise<ApiResult<IPolicyExcludeAddition>> {
        return this.get<IPolicyExcludeAddition>(`/api/policy/${id}/excludeaddition`);
    }

    public async getUpperThreshold(id: string): Promise<ApiResult<IPolicyUpperThreshold>> {
        return this.get<IPolicyUpperThreshold>(`/api/policy/${id}/upperthreshold`);
    }

    public async getExcludeAdditionByMasspointValue(id: string): Promise<ApiResult<IPolicyExcludeAdditionByMasspointValue>> {
        return this.get<IPolicyExcludeAdditionByMasspointValue>(`/api/policy/${id}/excludeadditionbymasspointvalue`);
    }

    public async getAdjustRangesByMasspoint(id: string): Promise<ApiResult<IPolicyAdjustRangesByMasspoint>> {
        return this.get<IPolicyAdjustRangesByMasspoint>(`/api/policy/${id}/adjustrangesbymasspoint`);
    }

    public async getAdjustMaximumRange(id: string): Promise<ApiResult<IPolicyAdjustMaximumRange>> {
        return this.get<IPolicyAdjustMaximumRange>(`/api/policy/${id}/adjustmaximumrange`);
    }

    public async getSideSeam(id: string): Promise<ApiResult<IPolicySideSeam>> {
        return this.get<IPolicySideSeam>(`/api/policy/${id}/sideseam`);
    }

    public async getScarTreatment(id: string): Promise<ApiResult<IPolicyScarTreatment>> {
        return this.get<IPolicyScarTreatment>(`/api/policy/${id}/scartreatment`);
    }

    public async getFingersAndToes(id: string): Promise<ApiResult<IPolicyFingersAndToes>> {
        return this.get<IPolicyFingersAndToes>(`/api/policy/${id}/fingersandtoes`);
    }

    public async getPolicies(): Promise<ApiResult<Array<IPolicyOverview>>> {
        return this.get<Array<IPolicyOverview>>("/api/policy");
    }

    public async createAdditionRank(policy: IPolicyAdditionRankCreate): Promise<ApiResult<void>> {
        return this.post<IPolicyAdditionRankCreate, void>("/api/policy/additionrank", policy);
    }

    public async createDefaultAddition(policy: IPolicyDefaultAdditionCreate): Promise<ApiResult<void>> {
        return this.post<IPolicyDefaultAdditionCreate, void>("/api/policy/defaultaddition", policy);
    }

    public async createMandatoryByAttribute(policy: IPolicyMandatoryByAttributeCreate): Promise<ApiResult<void>> {
        return this.post<IPolicyMandatoryByAttributeCreate, void>("/api/policy/mandatorybyattribute", policy);
    }

    public async createDefaultAdditionByQualityAndAttribute(policy: IPolicyDefaultAdditionByQualityAndAttributeCreate): Promise<ApiResult<void>> {
        return this.post<IPolicyDefaultAdditionByQualityAndAttributeCreate, void>("/api/policy/defaultadditionbyqualityandattribute", policy);
    }

    public async createExcludeAddition(policy: IPolicyExcludeAdditionCreate): Promise<ApiResult<void>> {
        return this.post<IPolicyExcludeAdditionCreate, void>("/api/policy/excludeaddition", policy);
    }

    public async createUpperThreshold(policy: IPolicyUpperThresholdCreate): Promise<ApiResult<void>> {
        return this.post<IPolicyUpperThresholdCreate, void>("/api/policy/upperthreshold", policy);
    }

    public async createMandatoryCategory(policy: IPolicyMandatoryCategoryCreate): Promise<ApiResult<void>> {
        return this.post<IPolicyMandatoryCategoryCreate, void>("/api/policy/mandatorycategory", policy);
    }

    public async createMandatoryCategoryByMasspoint(policy: IPolicyMandatoryCategoryByMasspointCreate): Promise<ApiResult<void>> {
        return this.post<IPolicyMandatoryCategoryByMasspointCreate, void>("/api/policy/mandatorycategorybymasspoint", policy);
    }

    public async createSoftSeam(policy: IPolicySoftSeamCreate): Promise<ApiResult<void>> {
        return this.post<IPolicySoftSeamCreate, void>("/api/policy/softseam", policy);
    }

    public async createSideSeam(policy: IPolicySideSeamCreate): Promise<ApiResult<void>> {
        return this.post<IPolicySideSeamCreate, void>("/api/policy/sideseam", policy);
    }   
    
    public async createFingersAndToes(policy: IPolicyFingersAndToesCreate): Promise<ApiResult<void>> {
        return this.post<IPolicyFingersAndToesCreate, void>("/api/policy/fingersandtoes", policy);
    }

    public async createScarTreatment(policy: IPolicyScarTreatmentCreate): Promise<ApiResult<void>> {
        return this.post<IPolicyScarTreatmentCreate, void>("/api/policy/scartreatment", policy);
    }

    public async createExcludeAdditionByMasspointValue(policy: IPolicyExcludeAdditionByMasspointValueCreate): Promise<ApiResult<void>> {
        return this.post<IPolicyExcludeAdditionByMasspointValueCreate, void>("/api/policy/excludeadditionbymasspointvalue", policy);
    }

    public async createAdjustRangesByMasspoint(policy: IPolicyAdjustRangesByMasspointCreate): Promise<ApiResult<void>> {
        return this.post<IPolicyAdjustRangesByMasspointCreate, void>("/api/policy/adjustrangesbymasspoint", policy);
    }

    public async createAdjustMaximumRange(policy: IPolicyAdjustMaximumRangeCreate): Promise<ApiResult<void>> {
        return this.post<IPolicyAdjustMaximumRangeCreate, void>("/api/policy/adjustmaximumrange", policy);
    }

    public async deletePolicy(id: number): Promise<ApiResult<void>> {
        return this.delete(`/api/policy/${id}`);
    }

    public async publish(check: IPolicyOverview): Promise<ApiResult<void>> {
        return this.put<void, void>(`/api/policy/${check.id}/publish`);
    }

    public async unpublish(check: IPolicyOverview): Promise<ApiResult<void>> {
        return this.put<void, void>(`/api/policy/${check.id}/unpublish`);
    }

    public async updateMandatoryCategory(data: IPolicyMandatoryCategoryUpdate): Promise<ApiResult<void>> {
        return this.put<IPolicyMandatoryCategoryUpdate, void>(`/api/policy/mandatorycategory`, data);
    }

    public async updateMandatoryCategoryByMasspoint(data: IPolicyMandatoryCategoryByMasspointUpdate): Promise<ApiResult<void>> {
        return this.put<IPolicyMandatoryCategoryByMasspointUpdate, void>(`/api/policy/mandatorycategorybymasspoint`, data);
    }

    public async updateSoftSeam(data: IPolicySoftSeamUpdate): Promise<ApiResult<void>> {
        return this.put<IPolicySoftSeamUpdate, void>(`/api/policy/softseam`, data);
    }

    public async updateSideSeam(data: IPolicySideSeamUpdate): Promise<ApiResult<void>> {
        return this.put<IPolicySideSeamUpdate, void>(`/api/policy/sideseam`, data);
    }  
    
    public async updateFingersAndToes(data: IPolicyFingersAndToesUpdate): Promise<ApiResult<void>> {
        return this.put<IPolicyFingersAndToesUpdate, void>(`/api/policy/fingersandtoes`, data);
    }

    public async updateScarTreatment(data: IPolicyScarTreatmentUpdate): Promise<ApiResult<void>> {
        return this.put<IPolicyScarTreatmentUpdate, void>(`/api/policy/scartreatment`, data);
    }

    public async updateAdditionRank(data: IPolicyAdditionRankUpdate): Promise<ApiResult<void>> {
        return this.put<IPolicyAdditionRankUpdate, void>(`/api/policy/additionrank`, data);
    }

    public async updateDefaultAddition(data: IPolicyDefaultAdditionUpdate): Promise<ApiResult<void>> {
        return this.put<IPolicyDefaultAdditionUpdate, void>(`/api/policy/defaultaddition`, data);
    }

    public async updateMandatoryByAttribute(data: IPolicyMandatoryByAttributeUpdate): Promise<ApiResult<void>> {
        return this.put<IPolicyMandatoryByAttributeUpdate, void>(`/api/policy/mandatorybyattribute`, data);
    }

    public async updateDefaultAdditionByQualityAndAttribute(data: IPolicyDefaultAdditionByQualityAndAttributeUpdate): Promise<ApiResult<void>> {
        return this.put<IPolicyDefaultAdditionByQualityAndAttributeUpdate, void>(`/api/policy/defaultadditionbyqualityandattribute`, data);
    }

    public async updateExcludeAddition(data: IPolicyExcludeAdditionUpdate): Promise<ApiResult<void>> {
        return this.put<IPolicyExcludeAdditionUpdate, void>(`/api/policy/excludeaddition`, data);
    }

    public async updateUpperThreshold(data: IPolicyUpperThresholdUpdate): Promise<ApiResult<void>> {
        return this.put<IPolicyUpperThresholdUpdate, void>(`/api/policy/upperthreshold`, data);
    }

    public async updateExcludeAdditionByMasspointValue(data: IPolicyExcludeAdditionByMasspointValueUpdate): Promise<ApiResult<void>> {
        return this.put<IPolicyExcludeAdditionByMasspointValueUpdate, void>(`/api/policy/excludeadditionbymasspointvalue`, data);
    }

    public async updateAdjustRangesByMasspoint(data: IPolicyAdjustRangesByMasspointUpdate): Promise<ApiResult<void>> {
        return this.put<IPolicyAdjustRangesByMasspointUpdate, void>(`/api/policy/adjustrangesbymasspoint`, data);
    }

    public async updateAdjustMaximumRange(data: IPolicyAdjustMaximumRangeUpdate): Promise<ApiResult<void>> {
        return this.put<IPolicyAdjustMaximumRangeUpdate, void>(`/api/policy/adjustmaximumrange`, data);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const PolicyService = new PolicyAPI(TimeoutMilliseconds);

export default PolicyService;
