import { ICharacteristicCategoryOverview } from "models/characteristic_categories/characteristic_category_overview";
import { CharcteristicCategoryOverviewState } from "./characteristic_category_overview.model";
import { filterCharacterCategoryValue } from "../characteristic_category_overview.helper";

export const filterCharacteristicCategories = (state: CharcteristicCategoryOverviewState) => {
    const searchTextLower = state.actualData.searchFilter.toLocaleLowerCase().trim();
    let filteredItems = state.loadedData.characteristicCategories.filter(
        (characteristicType) =>{
            return (
                characteristicType.name.toLowerCase().includes(searchTextLower) ||
                characteristicType.typeName.toLowerCase().includes(searchTextLower));
        }
    );

    state.actualData.gridFilters.items.forEach(fil => {
        filteredItems = filterCharacterCategoryValue(fil, filteredItems);
    });
    
    state.actualData.characteristicCategories = filteredItems.sort(sortCharacteristicCategories);

}

export const sortCharacteristicCategories = (mp1: ICharacteristicCategoryOverview, mp2: ICharacteristicCategoryOverview) => {
    return mp1.name.localeCompare(mp2.name);
}