import { SelectionListComponent } from "shared/components/selectionComponent/selectionList.component";
import { useAppDispatch, useAppSelector } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { filterSelectedExcludedArticleTypes, filterUnselectedExcludedArticleTypes, selectExcludedArticleTypes, setExcludedArticleTypesEnabled, unselectExcludedArticleTypes } from "../position_category/redux/position_category.slice";
import { Grid, Switch } from "@mui/material";
import { EditorDescriptionComponent } from "shared/components/editors/editor_description/editor_description.style";
import { EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";

export const PositionCategoryItemArticleTypeExclusionsComponent = () => {

    const state = useAppSelector((state) => state.position_category);
    const dispatch: AppDispatch = useAppDispatch();

    return <>
        <Grid item md={12}>
            <EditorSectionHeaderComponent>Ausschlüsse</EditorSectionHeaderComponent>
            <EditorDescriptionComponent>Für bestimmte Artikelarten ausschließen?</EditorDescriptionComponent>
            <Switch
                checked={state.currentEditedPositionCategoryItem.excludedArticleTypesEnabled}
                onChange={(event) => dispatch(setExcludedArticleTypesEnabled(event.target.checked))} />
        </Grid>
        {
            state.currentEditedPositionCategoryItem.excludedArticleTypesEnabled &&
            <Grid item>
                <SelectionListComponent
                    title={"Ausgeschlossene Artikelarten"}
                    entityName={"Artikelarten"}
                    onFilterSelected={(searchText) => { dispatch(filterSelectedExcludedArticleTypes(searchText)) }}
                    onFilterUnselected={(searchText) => { dispatch(filterUnselectedExcludedArticleTypes(searchText)) }}
                    selectionList={state.currentEditedPositionCategoryItem.excludedArticleTypesSelection}
                    selectEntities={(ids) => { dispatch(selectExcludedArticleTypes(ids)) }}
                    unselectEntities={(ids) => { dispatch(unselectExcludedArticleTypes(ids)) }}
                />
            </Grid>
        }
    </>
}