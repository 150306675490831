import { IMassImageConfigUploaded } from "models/massimages/massimage_config_uploaded";
import IMassimage from "../models/massimages/massimage";
import IMassimageOverview from "../models/massimages/massimage_overview";
import { IMassImageUploaded } from "../models/massimages/massimage_uploaded";
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from "./config.service";
import HttpServiceCommonResult from "shared/networking/http_service_common_result";
import { CommonApiResult } from "shared/networking/common_api_result";

class MassImageServiceAPI extends HttpServiceCommonResult {
  public async getAllMassImages(): Promise<ApiResult<Array<IMassimageOverview>>> {
    return this.get<Array<IMassimageOverview>>("/api/massimage");
  }

  public async saveMassImage(massimage: IMassimage): Promise<ApiResult<void>> {
    const data = new FormData();
    data.append("image", massimage.image);
    data.append("name", massimage.name);

    return this.uploadFileForm<void>("/api/massimage", data);
  }

  public async updateMassImage(id: number, massimage: IMassimage): Promise<ApiResult<void>> {
    const data = new FormData();
    data.append("image", massimage.image);
    data.append("name", massimage.name);

    return this.editFileForm<void>("/api/massimage/" + id, data);
  }

  public async createConfigFile(id: Number, jsonFile: File): Promise<ApiResult<IMassImageConfigUploaded>> {
    const data = new FormData();
    data.append("configFile", jsonFile);
    return this.uploadFileForm<IMassImageUploaded>("/api/massimage/" + id + "/config", data);
  }

  public async editConfigFile(id: Number, jsonFile: File): Promise<ApiResult<IMassImageConfigUploaded>> {
    const data = new FormData();
    data.append("configFile", jsonFile);
    return this.editFileForm<IMassImageUploaded>("/api/massimage/" + id + "/config", data);
  }

  public async deleteMassImage(id: Number): Promise<ApiResult<void>> {
    return this.delete(`/api/massimage/${id}`);
  }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
export const MassImageService = new MassImageServiceAPI(TimeoutMilliseconds);
