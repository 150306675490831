import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PolicyScarTreatmentState } from "./policy_scar_treatment.model";
import { convertMappedBaseDataToViewModel, createBodyAreaEditableItem, createBodyAreaViewModel, updateCanSave, updateValideBodyArea } from "./policy_scar_treatment.reducer";
import { cancelSave, getMappedBaseData, getPolicy, initData, savePolicy, updatePolicy } from "./policy_scar_treatment.thunks";
import { filterSelectionList, moveFromSelectedToUnselected, moveFromUnselectedToSelected } from "shared/components/selectionComponent/selectionList.helper";

const initialState: PolicyScarTreatmentState = {
    data: { 
        policy: { name: "", ignoreOnQuotation: false},
        selectedMainProductLine: null,
        mainProductLines: [],
    },
    loadedData: {
        mappedBaseData: null,
        policy: null,
        isEdit: false,
    },
    command:{
        savePolicy: { status: "idle", canExecute: false },
        cancelSave: { status: "idle", canExecute: true }
    },
    query: {
        fetchMappedBaseData: {status: "idle", canExecute: true },
        getPolicy: {status: "idle", canExecute: true },
        initData: {status: "idle", canExecute: true}
    }
}

export const policyScarTreatment = createSlice({
    name: 'policy_scar_treatment',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = initialState.data;
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        changeName: (state, action: PayloadAction<string>) => {
            state.data.policy.name = action.payload;
            updateCanSave(state);
        },
        selectMainProductLinie: (state, action: PayloadAction<number>) => {
            let mainProductLine = null;
            if(action.payload) {
                mainProductLine = state.data.mainProductLines.find(mpl => mpl.id === action.payload);
            } 
            state.data.selectedMainProductLine = mainProductLine;
            updateCanSave(state);
        },
        cancelBodyAreaConfiguration: (state) => {
            state.data.selectedMainProductLine.editableItem = null;
            state.data.selectedMainProductLine.editableIndex = -1;
            updateCanSave(state);
        },
        createBodyAreaConfiguration: (state) => {
            state.data.selectedMainProductLine.editableItem = createBodyAreaEditableItem(state);
            state.data.selectedMainProductLine.editableItem.isValide = updateValideBodyArea(state.data.selectedMainProductLine.editableItem);
            updateCanSave(state);
        },        
        editBodyAreaConfiguration: (state, action: PayloadAction<number>) => {
            state.data.selectedMainProductLine.editableIndex = action.payload;
            state.data.selectedMainProductLine.editableItem = createBodyAreaEditableItem(state, state.data.selectedMainProductLine.selectedBodyAreas[action.payload]);
            state.data.selectedMainProductLine.editableItem.isValide = updateValideBodyArea(state.data.selectedMainProductLine.editableItem);
            updateCanSave(state);
        },
        deleteBodyAreaConfiguration: (state, action: PayloadAction<number>) => {
            state.data.selectedMainProductLine.selectedBodyAreas = state.data.selectedMainProductLine.selectedBodyAreas.filter((_, index) => action.payload !== index);
            updateCanSave(state);
        },
        selectBodyArea: (state, action: PayloadAction<number>) => {
            let bodyArea = null;
            if(action.payload) {
                bodyArea = state.data.selectedMainProductLine.editableItem.bodyAreas.find(mpl => mpl.id === action.payload);
            } 
            state.data.selectedMainProductLine.editableItem.selectedBodyArea = bodyArea;
            state.data.selectedMainProductLine.editableItem.isValide = updateValideBodyArea(state.data.selectedMainProductLine.editableItem);
        },
        takeBodyAreaConfiguration: (state) => {
            var bodyArea = createBodyAreaViewModel(state.data.selectedMainProductLine.editableItem); 
             state.data.selectedMainProductLine.editableItem;
            if (state.data.selectedMainProductLine.editableIndex === -1) {
                state.data.selectedMainProductLine.selectedBodyAreas = [...state.data.selectedMainProductLine.selectedBodyAreas, bodyArea];
            }
            else {
                state.data.selectedMainProductLine.selectedBodyAreas = state.data.selectedMainProductLine.selectedBodyAreas.map((con, index) =>
                    index === state.data.selectedMainProductLine.editableIndex ? bodyArea : con
                );
            }
            state.data.selectedMainProductLine.editableItem = null;
            state.data.selectedMainProductLine.editableIndex = -1;
            updateCanSave(state);
        },
        filterSelectedArticleTypes: (state, action: PayloadAction<string>) => {
            state.data.selectedMainProductLine.editableItem.selectedBodyArea.articleTypes.selectedList.searchText = action.payload;
            filterSelectionList(state.data.selectedMainProductLine.editableItem.selectedBodyArea.articleTypes.selectedList);
        },
        filterUnselectedArticleTypes: (state, action: PayloadAction<string>) => {
            state.data.selectedMainProductLine.editableItem.selectedBodyArea.articleTypes.unSelectedList.searchText = action.payload;
            filterSelectionList(state.data.selectedMainProductLine.editableItem.selectedBodyArea.articleTypes.unSelectedList);
        },
        filterSelectedQualities: (state, action: PayloadAction<string>) => {
            state.data.selectedMainProductLine.editableItem.selectedBodyArea.qualities.selectedList.searchText = action.payload;
            filterSelectionList(state.data.selectedMainProductLine.editableItem.selectedBodyArea.qualities.selectedList);
        },
        filterUnselectedQualities: (state, action: PayloadAction<string>) => {
            state.data.selectedMainProductLine.editableItem.selectedBodyArea.qualities.unSelectedList.searchText = action.payload;
            filterSelectionList(state.data.selectedMainProductLine.editableItem.selectedBodyArea.qualities.unSelectedList);
        },
        selectArticleTypes: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.data.selectedMainProductLine.editableItem.selectedBodyArea.articleTypes, action.payload);
            state.data.selectedMainProductLine.editableItem.isValide = updateValideBodyArea(state.data.selectedMainProductLine.editableItem);
        },
        unselectArticleTypes: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.data.selectedMainProductLine.editableItem.selectedBodyArea.articleTypes, action.payload);
            state.data.selectedMainProductLine.editableItem.isValide = updateValideBodyArea(state.data.selectedMainProductLine.editableItem);
        },
        selectQualities: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.data.selectedMainProductLine.editableItem.selectedBodyArea.qualities, action.payload);
            state.data.selectedMainProductLine.editableItem.isValide = updateValideBodyArea(state.data.selectedMainProductLine.editableItem);
        },
        unselectQualities: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.data.selectedMainProductLine.editableItem.selectedBodyArea.qualities, action.payload);
            state.data.selectedMainProductLine.editableItem.isValide = updateValideBodyArea(state.data.selectedMainProductLine.editableItem);
        },
        toggleIgnoreOnQuotation: (state, action: PayloadAction<boolean>) => {
            state.data.policy.ignoreOnQuotation = action.payload;
            updateCanSave(state);
        },               
        completedSave: (state) => {
            state.command.savePolicy = initialState.command.savePolicy;
        }
    }, extraReducers: (builder) => {

        // fetchPolicy
        builder.addCase(getPolicy.pending, (state) => {
            state.query.getPolicy.status = 'pending'
            state.query.getPolicy.canExecute = false;
        }).addCase(getPolicy.rejected, (state, action) => {
            state.query.getPolicy.status = "error"
            state.query.getPolicy.canExecute = true;
            state.query.getPolicy.message = action.error.message;
        }).addCase(getPolicy.fulfilled, (state, action) => {
            state.query.getPolicy.status = "success"
            state.query.getPolicy.canExecute = false;
            state.loadedData.policy = action.payload.getData();

        }).addCase(getMappedBaseData.pending, (state) => {
            state.query.fetchMappedBaseData.status = 'pending'
            state.query.fetchMappedBaseData.canExecute = false;
        }).addCase(getMappedBaseData.rejected, (state, action) => {
            state.query.fetchMappedBaseData.status = "error"
            state.query.fetchMappedBaseData.canExecute = true;
            state.query.fetchMappedBaseData.message = action.error.message;
        }).addCase(getMappedBaseData.fulfilled, (state, action) => {
            state.query.fetchMappedBaseData.status = "success"
            state.query.fetchMappedBaseData.canExecute = false;
            state.loadedData.mappedBaseData = action.payload.getData();

        // savePolicy
        }).addCase(savePolicy.pending, (state) => {
            state.command.savePolicy.status = 'pending'
            state.command.savePolicy.canExecute = false;
        }).addCase(savePolicy.rejected, (state, action) => {
            state.command.savePolicy.status = "error"
            state.command.savePolicy.canExecute = true;
            state.command.savePolicy.message = action.error.message;
        }).addCase(savePolicy.fulfilled, (state) => {
            state.command.savePolicy.status = "success"
            state.command.savePolicy.canExecute = false;

        // updatePolicy
        }).addCase(updatePolicy.pending, (state) => {
            state.command.savePolicy.status = 'pending'
            state.command.savePolicy.canExecute = false;
        }).addCase(updatePolicy.rejected, (state, action) => {
            state.command.savePolicy.status = "error"
            state.command.savePolicy.canExecute = true;
            state.command.savePolicy.message = action.error.message;
        }).addCase(updatePolicy.fulfilled, (state) => {
            state.command.savePolicy.status = "success"
            state.command.savePolicy.canExecute = false;

        // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancelSave.status = 'pending'
            state.command.cancelSave.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancelSave.status = "success"
            state.command.cancelSave.canExecute = false;

        // initData
        }).addCase(initData.pending, (state) => {
            state.query.initData.status = "pending"
            state.query.initData.canExecute = false;
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.canExecute = true;
            state.query.initData.message = action.error.message;
        }).addCase(initData.fulfilled, (state) => {
            state.query.initData.status = "success"
            state.query.initData.canExecute = true;
            state.data.mainProductLines = convertMappedBaseDataToViewModel(state.loadedData.mappedBaseData, state.loadedData.policy);

            if(state.loadedData.policy) {
                state.data.policy = { 
                    id: state.loadedData.policy.id,
                    name: state.loadedData.policy.name,
                    ignoreOnQuotation: state.loadedData.policy.ignoreOnQuotation
                };
                state.data.selectedMainProductLine = state.data.mainProductLines.find(mpl => mpl.id == state.loadedData.policy.mainProductLineId);     
                state.loadedData.isEdit = true;
            } else {
                state.loadedData.isEdit = false;
            } 
            updateCanSave(state);
        })
    }
})

export const {
    resetState,
    changeName,
    toggleIgnoreOnQuotation,
    completedSave,
    selectMainProductLinie,
    filterSelectedArticleTypes,
    filterSelectedQualities,
    filterUnselectedArticleTypes,
    filterUnselectedQualities,
    cancelBodyAreaConfiguration,
    createBodyAreaConfiguration,
    editBodyAreaConfiguration,
    deleteBodyAreaConfiguration,
    selectBodyArea,
    selectArticleTypes,
    selectQualities,
    unselectArticleTypes,
    unselectQualities,
    takeBodyAreaConfiguration,
} = policyScarTreatment.actions

export default policyScarTreatment.reducer