import { filterOneLegValue } from "../onelegs_overview.helper";
import { OneLegOverviewState } from "./onelegs_overview.model"

export const filterOneLegs = (state: OneLegOverviewState) => {
    let searchTextLower = state.actualData.searchFilter.toLocaleLowerCase();
    let filteredItems = state.loadedData.oneLegs.filter(
        (oneLeg) =>
            oneLeg.mainProductLine.name.toLowerCase().includes(searchTextLower) ||
            oneLeg.articleType != null && `${oneLeg.articleType.erpId} ${oneLeg.articleType.name}`
                .toLowerCase()
                .includes(searchTextLower)
    );
    state.actualData.gridFilters.items.forEach(fil => {
        filteredItems = filterOneLegValue(fil, filteredItems);
    });
    state.actualData.oneLegs = filteredItems;
}