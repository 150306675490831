import { ImportStartComponent } from './components/import_start.component';
import { ImportsOverviewComponent } from './components/imports_overview.component';
import { useEffect } from "react";
import { AppDispatch } from "app/redux_store";
import { useAppDispatch, useAppSelector } from "app/hook";
import { createAndFetchBasedataImport, fetchAllBasedataImports } from './redux/basedataimports.thunks';
import { useSnackbar } from "notistack";
import { displayedCreateMessage, displayedFetchMessage, resetState } from "./redux/basedataimports.slice";

export const BaseDataImportsComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.basedata_imports);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        dispatch(fetchAllBasedataImports());
        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    if (state.query.fetchImports.status === "error" ||
        state.query.fetchImports.status === "warning") {
        enqueueSnackbar(state.query.fetchImports.message, { variant: state.query.fetchImports.status });
        dispatch(displayedFetchMessage());
    }

    if (state.command.createImport.status === "error" ||
        state.command.createImport.status === "warning") {
        enqueueSnackbar(state.command.createImport.message, { variant: state.command.createImport.status });
        dispatch(displayedCreateMessage());
    }

    if (state.command.createImport.status === "success") {
        enqueueSnackbar("Import abgeschlossen", { variant: "success" });
        dispatch(displayedCreateMessage());
    }

    return (
        <div>
            <ImportStartComponent
                onStartImport={() => dispatch(createAndFetchBasedataImport())}
                isImportRunning={state.command.createImport.status === "pending"}
                disabled={!state.command.createImport.canExecute} />

            <ImportsOverviewComponent
                isLoading={state.query.fetchImports.status === "pending"}
                isReloading={state.command.createImport.status === "pending"}
                imports={state.data.imports} />
        </div>);
}