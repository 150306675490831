import { Grid } from '@mui/material';
import { AppDispatch } from '../../../../app/redux_store';
import { LoadingOverlayComponent, LoadingOverlayContainer } from 'shared/components/loading_overlay/loading_overlay.component';
import { PrimaryButtonComponent } from '../../../../shared/components/buttons/primary_button.component';
import * as style from "./addition_erp_data_load.style";
import { EditorSectionHeaderComponent } from 'shared/components/editors/editor_header/editor_header.style'
import { useAppDispatch, useAppSelector } from '../../../../app/hook';
import { TextBoxComponent } from '../../../../shared/components/form_controls/text_box/text_box.component';
import { getAdditionDefinition } from '../../redux/addition.thunks';
import { updateOptionName } from '../../redux/addition.slice';

export const AdditionERPDataLoadComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.addition);

    const isRunning = state.query.getAdditionDefinition.status === "pending";
    const isButtonDisabled = isRunning || !state.query.getAdditionDefinition.canExecute;
    const errorText = state.errorData.additionDefinitionError != null
        && state.errorData.additionDefinitionError.isVisible ? state.errorData.additionDefinitionError.text : null;

    const onCancel = () => {
        window.history.back();
    };

    return (<div>
        <LoadingOverlayContainer>
            {isRunning ? <LoadingOverlayComponent /> : <></>}
            <EditorSectionHeaderComponent>Zusatz erstellen / Basisdaten abholen</EditorSectionHeaderComponent>
            <style.AdditionLoadWrapper>
                <Grid container
                    spacing={3}
                    direction="column"
                    justifyContent="space-between">
                    <Grid item md={6}>
                        <h3>ERP-Daten Import</h3>
                        <div>
                            <p>Dieser Vorgang holt eine ERP-Zusatzkonfiguration ab. Neue Zusätze können erst im Maßservice angelegt werden, nachdem die Nummer im ERP-System eingepflegt wurde.</p>
                            <TextBoxComponent
                                name="ERP-Artikelnummer"
                                value={state.actualData.optionName}
                                onChange={(value) => dispatch(updateOptionName(value))}
                                isRequired={true}
                                isAutoFocus={true}
                                errorText={errorText} />
                        </div>
                    </Grid>

                    <Grid item
                        container
                        direction="row"
                        md={6}
                        spacing={3}>
                        <Grid item
                            md={3}>
                            <PrimaryButtonComponent onClick={() => onCancel()}>Abbrechen</PrimaryButtonComponent>
                        </Grid>
                        <Grid item
                            md={6}>
                            <PrimaryButtonComponent disabled={isButtonDisabled} onClick={() => dispatch(getAdditionDefinition())}>prüfen und Daten holen</PrimaryButtonComponent>
                        </Grid>
                    </Grid>
                </Grid>
            </style.AdditionLoadWrapper>
        </LoadingOverlayContainer>
    </div>);
}