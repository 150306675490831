import { ICheckOverview } from "models/plausibility_check/check_overview";
import { ISimilarPlausibilityCheckType } from "models/plausibility_check/plausability_check_type_similar";
import { CheckEditState } from "./check_edit.model";

export const toSimilarCheckTypeEditModel = (checkType: ICheckOverview): ISimilarPlausibilityCheckType => {
    return {
        id: checkType.id,
        name: checkType.plausibilityCheckTypeVersion.name,
    };
}

export const updateCanExecuteEdit = (state: CheckEditState) => {
    const isModified = state.loadedData.check.plausibilityCheckTypeVersion.id !== state.actualData.selectedCheckType.id;
    state.command.updateCheckType.canExecute = isModified;
}