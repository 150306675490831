import React from "react";
import * as interactivestyle from "./interactive_massimage.style";
import Grid from '@mui/material/Grid';
import { TextBoxComponent } from "shared/components/form_controls/text_box/text_box.component";

interface MassDefinitionEditorComponentProps {
    measurementSheet?: string; 
    updateMeasurementSheet: (sheetName: string) => void;
}

export const MassDefinitionEditorComponent: React.FC<MassDefinitionEditorComponentProps> =
    ({ measurementSheet,
        updateMeasurementSheet
     }) => {
        return (
            <interactivestyle.Wrapper>
                <Grid container
                    direction="column"
                    spacing={5}
                    columnSpacing={5}
                >
                    <Grid container
                        item
                        direction="row">
                        <Grid item md={5} >
                            <TextBoxComponent
                                name="Maßdaten Template"
                                value={measurementSheet}
                                onChange={updateMeasurementSheet}
                                maxLength={100}
                                isAutoFocus={true}
                                />
                        </Grid>
                    </Grid>
                </Grid>
            </interactivestyle.Wrapper>
        );
    }