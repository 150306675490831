import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ChecktypeHistoryOverviewsState } from './checktype_history.model';
import { fetchChecktypeHistoryOverviews } from './checktype_history.thunks';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import { filterChecktypeHistoryOverviews } from './checktype_history.reducer';

const initialState: ChecktypeHistoryOverviewsState = {
    loadedData: {
        checktypeHistoryOverviews: [],
    },  
    actualData: {
        checktypeHistoryOverviews: [],
        searchFilter: "",
        gridFilters: {items: []}
    },
    query: {
        fetchChecktypeHistoryOverviews: { status: "idle", canExecute: true },
    },
}

export const getChecktypeHistoryOverviewSlice = createSlice({
    name: 'checktype_history/checktype_history_overview',
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.loadedData = initialState.loadedData;
            state.query = initialState.query;
        },
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.actualData.searchFilter = action.payload;
            filterChecktypeHistoryOverviews(state);
        },  
        setGridFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.gridFilters = action.payload;
            filterChecktypeHistoryOverviews(state);
        },
    }, extraReducers: (builder) => {

        // fetch ChecktypeHistoryOverviews
        builder.addCase(fetchChecktypeHistoryOverviews.pending, (state, action) => {
            state.query.fetchChecktypeHistoryOverviews.status = "pending"
            state.query.fetchChecktypeHistoryOverviews.canExecute = false;
        }).addCase(fetchChecktypeHistoryOverviews.rejected, (state, action) => {
            state.query.fetchChecktypeHistoryOverviews.status = "error"
            state.query.fetchChecktypeHistoryOverviews.message = action.error.message;
            state.query.fetchChecktypeHistoryOverviews.canExecute = true;
        }).addCase(fetchChecktypeHistoryOverviews.fulfilled, (state, action) => {
            state.query.fetchChecktypeHistoryOverviews.status = "success"
            state.query.fetchChecktypeHistoryOverviews.message = undefined;
            state.query.fetchChecktypeHistoryOverviews.canExecute = true;
            state.loadedData.checktypeHistoryOverviews = action.payload.getData();
            filterChecktypeHistoryOverviews(state);
        })
    }
})

export const {
    resetState,
    setSearchFilter,
    setGridFilters,
} = getChecktypeHistoryOverviewSlice.actions

export default getChecktypeHistoryOverviewSlice.reducer