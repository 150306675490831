import { createAsyncThunk } from '@reduxjs/toolkit';
import MassPointHistoryServiceAPI from '../../../services/masspoint_history.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';

export const fetchMasspointHistoryOverviews = createAsyncThunk(
    'masspoint_history/masspoint_history_overview/fetchMasspointHistoryOverviews',
    async (thunkApi) => {
        const response = await MassPointHistoryServiceAPI.getMasspointsHistoryOverview();
        handleResponseErrors(response, "Maßpunkt Historie");
        return response;
    }
)