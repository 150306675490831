import { EnumDescriptor } from "../enum_descriptor";

export enum SideEnum {
    None = 0,
    Left = 2,
    Right = 3,
    LeftRight = 4,
}

export const AdditionSideValues: Array<
    EnumDescriptor<SideEnum>
> = [
        { value: SideEnum.Left, text: "links" },
        { value: SideEnum.Right, text: "rechts" },
        { value: SideEnum.LeftRight, text: "beide" },
    ];