import { createAsyncThunk } from '@reduxjs/toolkit';
import BaseDataService from 'services/base_data.service';
import { handleResponseErrors } from 'shared/networking/error_handling';


export const fetchBodyAreas = createAsyncThunk(
    'dialogs/missing_masspoints/fetchBodyAreas',
    async (thunkApi) => {
        const response = await BaseDataService.getBodyAreas();
        handleResponseErrors(response, "Basisdaten");
        return response;
    }
)