import HttpServiceCommonResult from 'shared/networking/http_service_common_result';
import { IBasedataImport } from '../models/basedata_import/basedata_import';
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from "./config.service";
import { CommonApiResult } from 'shared/networking/common_api_result';

class BasedataImportServiceAPI extends HttpServiceCommonResult {
    public async getImportStatus(): Promise<ApiResult<IBasedataImport[]>> {
        return this.get<IBasedataImport[]>(`/api/basedataimport`);
    }

    public async createBasedataImport(): Promise<ApiResult<void>> {
        return this.put<void,void>(`/api/basedataimport`);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const BasedataImportsService = new BasedataImportServiceAPI(TimeoutMilliseconds);

export default BasedataImportsService;
