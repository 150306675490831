import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/redux_store';
import IMassmaskOverview from '../../../models/massmasks/massmask_overview';
import MassMaskService from '../../../services/massmask.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';

export const getMassMasks = createAsyncThunk(
    'massMask/overview/getMassMasks',
    async (_) => {
        const response = await MassMaskService.getMassmasksOverview();
        handleResponseErrors(response, "Maßmasken");
        return response;
    }
)

export const togglePublishMassMask = createAsyncThunk(
    'massMask/overview/togglePublishMassMask',
    async (massMask: IMassmaskOverview, { dispatch }) => {
        if (massMask.isPublished) {
            await dispatch(unpublishMassMask(massMask));
        } else {
            await dispatch(publishMassMask(massMask));
        }
    }
)

export const publishMassMask = createAsyncThunk(
    'massMask/overview/publishMassMask',
    async (massMask: IMassmaskOverview, thunkApi) => {
        const responsePublish = await MassMaskService.publishMassmask(massMask);
        handleResponseErrors(responsePublish, "Maßmaske");
        const responseMassMasks = await MassMaskService.getMassmasksOverview();
        handleResponseErrors(responseMassMasks, "Maßmasken");
        return responseMassMasks;
    }
)


export const unpublishMassMask = createAsyncThunk(
    'massMask/overview/unpublishMassMask',
    async (massMask: IMassmaskOverview, thunkApi) => {
        const responseUnpublish = await MassMaskService.unpublishMassmask(massMask);
        handleResponseErrors(responseUnpublish, "Maßmaske");
        const responseMassMasks = await MassMaskService.getMassmasksOverview();
        handleResponseErrors(responseMassMasks, "Maßmasken");
        return responseMassMasks;
    }
)

export const confirmDeleteMassMask = createAsyncThunk(
    'massMask/overview/confirmDeleteMassMask',
    async (_, { getState }) => {
        const state = getState() as RootState
        const massMaskId = state.massmasks_overview.actualData.massMaskToDelete.id
        const responseDelete = await MassMaskService.deleteMassMask(massMaskId)
        handleResponseErrors(responseDelete, "Maßmaske");

        const response = await MassMaskService.getMassmasksOverview();
        handleResponseErrors(response, "Maßmaske");
        return response;
    }
)

export const createNewMassMask = createAsyncThunk(
    'massMask/overview/createNewMassMask',
    async (_) => { }
)

