import { useEffect } from "react";
import { initData, updateCheckType } from "./redux/check_edit.thunks";
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { AppDispatch } from "app/redux_store";
import { LoadingIndicatorComponent } from '../../shared/components/loading_indicator/loading_indicator.component';
import { LoadingOverlayComponent, LoadingOverlayContainer } from '../../shared/components/loading_overlay/loading_overlay.component';
import { TextBoxComponent } from '../../shared/components/form_controls/text_box/text_box.component';
import { DropdownComponent } from 'shared/shared';
import { Checkbox, FormControlLabel, Switch } from "@mui/material";
import * as style from "./check_edit.style";
import { updateSelectedPlausibilityCheckType, cancelEdit, resetState, handleError, updateCheckTypeCompleted } from "./redux/check_edit.slice";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";

export const CheckEditComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.check_edit);
    const { id } = useParams();

    useEffect(() => {
        dispatch(initData(Number(id)));

        return () => {
            dispatch(resetState());
        }

    }, [dispatch, id]);

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    if (state.query.fetchPlausibilityCheckType.status === "error") {
        enqueueSnackbar(state.query.fetchPlausibilityCheckType.message, { variant: "error" });
        return <div>Fehler beim Laden der Daten</div>;
    }    
    
    if (state.query.fetchSimilarPlausibilityCheckTypes.status === "error") {
        enqueueSnackbar(state.query.fetchSimilarPlausibilityCheckTypes.message, { variant: "error" });
        return <div>Fehler beim Laden der Daten</div>;
    }

    if (state.query.initData.status === "pending") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.cancelEdit.status === "success") {
        navigate("/plausibilitycheck/check");
    }

    if (state.command.updateCheckType.status === "success") {
        enqueueSnackbar("Prüfung aktualisiert", { variant: "success" });
        dispatch(updateCheckTypeCompleted());
        navigate("/plausibilitycheck/check?focusId=" + state.loadedData.check.id);
    }

    if (state.command.updateCheckType.status === "error") {
        enqueueSnackbar(state.command.updateCheckType.message, { variant: "error" });
        dispatch(handleError());
    }

    const isSaving = state.command.updateCheckType.status === "pending";

    return (
        <>
            <LoadingOverlayContainer>
                {isSaving ? <LoadingOverlayComponent /> : <></>}

                <style.Box>
                    <TextBoxComponent
                        name="Hauptproduktlinie"
                        value={state.loadedData.check?.mainProductLine?.name}
                        onChange={() => {}}
                        isRequired={false}
                        isReadonly={true}
                    />
                    <TextBoxComponent
                        name="Körperregion"
                        value={state.loadedData.check?.bodyArea?.name}
                        onChange={() => {}}
                        isRequired={false}
                        isReadonly={true}
                    />
                    <TextBoxComponent
                        name="Artikelart"
                        value={`${state.loadedData.check?.articleType?.erpId} ${state.loadedData.check?.articleType?.name}`}
                        onChange={() => {}}
                        isRequired={false}
                        isReadonly={true}
                    />
                    <TextBoxComponent
                        name="Qualität"
                        value={`${state.loadedData.check?.quality?.erpId} ${state.loadedData.check?.quality?.name}`}
                        onChange={() => {}}
                        isRequired={false}
                        isReadonly={true}
                    />
                </style.Box>
                <style.Box>
                    <DropdownComponent
                        name="Prüfungstyp"
                        data={state.loadedData.similiarCheckTypes}
                        isRequired={true}
                        isLoading={false}
                        onSelect={(value) => dispatch(updateSelectedPlausibilityCheckType(value))}
                        displayedProperties={["name"]}
                        selectedValue={state.actualData.selectedCheckType}
                    />
                    <style.IsSideSpecificWrapper>
                        <FormControlLabel
                            control={<Switch name="IsSideSpecificSwitch" />}
                            label="Seitenabhängig"
                            checked={state.loadedData.check?.isSideSpecific}
                            disabled={true}
                            labelPlacement="end"
                        />
                    </style.IsSideSpecificWrapper>
                </style.Box>

                {
                    <style.Box>
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="isuniquerules-checktype-checkbox"
                                        checked={state.loadedData.check?.plausibilityCheckTypeVersion.isUniqueRules}
                                        disabled={true}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="eindeutiges Regelwerk"
                            />
                        </div>
                    </style.Box>
                }

                <EditorButtonComponent
                    canExecute={state.command.updateCheckType.canExecute}
                    save={() => dispatch(updateCheckType())}
                    cancelSave={() => dispatch(cancelEdit())}
                />
            </LoadingOverlayContainer>
        </>
    );
};