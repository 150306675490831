import { makeStyles } from "@mui/material";
import styled from "@emotion/styled";
import colors from '../../shared/style/colors';

export const Spacer = styled.div`
    border-left: 1px solid ${colors.mediLightGray};
    border-right: 1px solid ${colors.mediLightGray};
    width: 5px;
    min-height: 10px;
    height: 300px;
    margin-left: 20px;
    margin-right: 20px;
`;
