import { GridFilterItem, GridColDef, getGridBooleanOperators, GridSortModel } from "@mui/x-data-grid-pro";
import IAvailableArticleType from "models/available_basedata/available_article_type";
import IMassmaskOverview from "models/massmasks/massmask_overview";
import { CustomBooleanCell } from "shared/components/datagrid/custom_boolean_cell.component";
import { CustomFilterOperators } from "shared/components/datagrid/custom_filter_operators";


export const buildArticleTypeDisplayText = ( articleType: IAvailableArticleType ) => {
    return `${articleType.erpId} ${articleType.name}`;
}

export const filterMassmaskValue = (filterItem: GridFilterItem, filteredItems: IMassmaskOverview[]) => {
    if(filterItem.value)
    {
        switch(filterItem.field)
        {
            case 'mainProductLine':
                filteredItems = filteredItems.filter(x => x.mainProductLine.name.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;    
            case 'bodyArea':
                filteredItems = filteredItems.filter(x => x.bodyArea.name.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'articleType':
                filteredItems = filteredItems.filter(x => buildArticleTypeDisplayText(x.articleType).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;         
            case 'isPublished':
                filteredItems = filteredItems.filter(x => filterItem.value === 'any' ? true: x.isPublished.toString() === filterItem.value);
                break;                                                                                                      
        }
    }
    return filteredItems;
}

export const getGridColumns = (): GridColDef[] => [
    {
        field: "isPublished",
        headerName: "Veröffentlicht",
        disableColumnMenu: false,
        width: 120,
        sortable: false,
        hideable: false,
        pinnable: false,
        align: "center",
        filterOperators: getGridBooleanOperators(),
        renderCell: (params) => CustomBooleanCell({value: params.row.isPublished})
    },
    {
        field: "mainProductLine",
        headerName: "Hauptproduktlinie",
        disableColumnMenu: false,
        flex: 0.3,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
        valueGetter: (value, row) => row.mainProductLine.name
    }, 
    {
        field: "bodyArea",
        headerName: "Körperregion",
        disableColumnMenu: false,
        flex: 0.3,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
        valueGetter: (value, row) => row.bodyArea.name
    },   
    {
        field: "articleType",
        headerName: "Artikelart",
        disableColumnMenu: false,
        flex: 0.3,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
        valueGetter: (value, row) => buildArticleTypeDisplayText(row.articleType)
    }
]    

export const sortByMainProductLineModel: GridSortModel = [
    {
        field: "mainProductLine",
        sort: "asc",
    },
];