import { PolicyEditorComponent } from "check/policy/components/policy_editor.component";
import { LoadingOverlayComponent, LoadingOverlayContainer } from "shared/components/loading_overlay/loading_overlay.component";
import { useAppDispatch, useAppSelector } from '../../../../app/hook';
import { AppDispatch } from '../../../../app/redux_store';
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { LoadingIndicatorComponent } from "shared/shared";
import { Grid } from "@mui/material";
import { EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";
import { useNavigate } from "react-router-dom";
import { cancelSave, initData, savePolicy } from "./redux/policy_default_addition_by_articletype_create.thunks";
import { changeName, completedSave, filterSelectedArticleTypes, filterUnselectedArticleTypes, resetState, selectAddition, toggleIgnoreOnQuotation, selectArticleTypes, unselectArticleTypes } from "./redux/policy_default_addition_by_articletype_create.slice";
import { PolicyDefaultAdditionEditorComponent } from "../components/policy_default_addition_editor.component";
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";
import i18next from "i18next";

export const PolicyDefaultAdditionByArticleTypeCreateComponent = () => {
    const state = useAppSelector((state) => state.policy_default_addition_by_articletype_create);
    const dispatch: AppDispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(initData());

        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    if (state.query.initData.status === "pending") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.cancelSave.status === "success") {
        navigate("/plausibilitycheck/policy");
        dispatch(resetState());
    }

    if (state.command.savePolicy.status === "success") {
        enqueueSnackbar("Regelwerk gespeichert", { variant: "success" });
        navigate("/plausibilitycheck/policy");
        dispatch(resetState());
    }

    if (state.command.savePolicy.status === "error") {
        enqueueSnackbar(state.command.savePolicy.message, { variant: "error" });
        dispatch(completedSave());
    }

    if (state.command.savePolicy.status === "warning") {
        enqueueSnackbar(state.command.savePolicy.message, { variant: "warning" });
        dispatch(completedSave());
    }

    const isSaving = state.command.savePolicy.status === "pending";

    return (
        <>
            <LoadingOverlayContainer>
                {isSaving ? <LoadingOverlayComponent /> : <></>}
                <Grid
                    container
                    spacing={3}
                    direction="row">
                    <Grid item md={6}>
                        <EditorSectionHeaderComponent>Neuen automatischen Zusatz für Artikeltypen erstellen</EditorSectionHeaderComponent>
                    </Grid>
                    <Grid item md={6}>
                        <p>{i18next.t("policy.autocheck_articletype.description")}</p>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={3}
                    direction="column">
                    <PolicyEditorComponent
                        name={state.data.policy.name}
                        ignoreOnQuotation={state.data.policy.ignoreOnQuotation}
                        changeName={(value) => dispatch(changeName(value))}
                        toggleIgnoreOnQuotation={(value) => dispatch(toggleIgnoreOnQuotation(value))}
                    />
                    <PolicyDefaultAdditionEditorComponent
                        selectedAddition={state.data.selectedAddition}
                        allAdditions={state.loadedData.additions}
                        articleTypeSelection={state.data.articleTypes}
                        selectAddition={(addition) => dispatch(selectAddition(addition))}
                        selectArticleTypes={(articleTypes) => dispatch(selectArticleTypes(articleTypes))}
                        unSelectArticleTypes={(articleTypes) => dispatch(unselectArticleTypes(articleTypes))}
                        filterSelectedArticleTypes={(text) => dispatch(filterSelectedArticleTypes(text))}
                        filterUnselectedArticleTypes={(text) => dispatch(filterUnselectedArticleTypes(text))}
                    />
                    <EditorButtonComponent
                        canExecute={state.command.savePolicy.canExecute}
                        save={() => dispatch(savePolicy())}
                        cancelSave={() => dispatch(cancelSave())}
                    />
                </Grid>
            </LoadingOverlayContainer>
        </>
    );
};