import { doesExist, isNullOrWhitespace } from "services/validation.service";
import { PolicyDefaultAdditionByQualityAndAttributeUpdateState } from "./policy_default_addition_by_qu_and_att_update.model";
import { IAdditionFilterList, IAdditionFilterListItem, IAdditionSelectionViewModel, IAttributeCategoryViewModel, IAttributeValueViewModel, IDefaultAdditionByQualityAndAttributeConfigurationViewModel } from "../../components/policy_default_addition_by_qu_and_att.model";
import { IAvailableAdditionAttributeCategory } from "models/addition_attribute_categories/available_addition_attribute_category";
import IAdditionOverview from "models/additions/addition_overview";
import { IAttributeConfiguration } from "models/policies/policy_default_addition_by_quality_and_attribute";
import { filterSelectionList } from "shared/components/selectionComponent/selectionList.helper";

export const updateCanSave = (state: PolicyDefaultAdditionByQualityAndAttributeUpdateState) => {
    state.command.updatePolicy.canExecute =
        !isNullOrWhitespace(state.data.policy.name)
        && doesExist(state.data.selectedQuality)
        && configurationsValid(state.data.attributeConfigurations)
        && isNotEqual(state);
}

const isNotEqual = (state: PolicyDefaultAdditionByQualityAndAttributeUpdateState) => {
    return state.data.policy.name !== state.loadedData.policy.name
        || state.data.policy.ignoreOnQuotation !== state.loadedData.policy.ignoreOnQuotation
        || state.data.selectedQuality.id !== state.loadedData.policy.qualityId
        || haveConfigurationChanged(state.data.attributeConfigurations, state.loadedData.policy.attributes)
}

const haveConfigurationChanged = (configurationsViewModel: IDefaultAdditionByQualityAndAttributeConfigurationViewModel[], loadedConfiguration: IAttributeConfiguration[]) => {
    let changedItems = false;

    loadedConfiguration.forEach(x => {
        const indexConfiguration = configurationsViewModel.findIndex(y => y.selectedAttributeValue.id === x.attributeId);
        if (indexConfiguration === -1) {
            changedItems = true;
        } else {
            const config = configurationsViewModel.find(y => y.selectedAttributeValue.id === x.attributeId);
            if (haveAdditionsChanged(x.additions, config.additions.selectedList.allItems)) {
                changedItems = true;
            }

        }
    });

    return configurationsViewModel.length !== loadedConfiguration.length || changedItems;
}

const haveAdditionsChanged = (loadedAdditions: number[], assigned: IAdditionFilterListItem[]) => {
    let changedItems = false;

    loadedAdditions.forEach(x => {
        if (assigned.findIndex(y => y.id === x) === -1) {
            changedItems = true;
        }
    });
    return loadedAdditions.length !== assigned.length || changedItems;
}

const configurationsValid = (configurations: IDefaultAdditionByQualityAndAttributeConfigurationViewModel[]) => {
    return configurations.length > 0
        && configurations.filter(conf => conf.selectedAttributeValue == null || conf.additions.selectedList.allItems.length === 0).length === 0;
}

export const createAttributeCategory = (state: PolicyDefaultAdditionByQualityAndAttributeUpdateState): IAttributeCategoryViewModel[] => {
    return state.loadedData.attributeCategories.map(ac => {
        return {
            displayName: ac.name,
            id: ac.id,
            attributes: ac.availableAdditionAttributes.map(at => {
                return {
                    displayName: at.name,
                    id: at.id
                }
            })
        }
    });
}

export const createAdditions = (additions: IAdditionOverview[]): IAdditionFilterList => {
    return {
        allItems: additions.map(add => {
            return {
                id: add.id,
                displayName: `${add.option}`,
            }
        }),
        filteredItems: [],
        searchText: ""
    };
}

export const createAttributeConfigurations = (state: PolicyDefaultAdditionByQualityAndAttributeUpdateState): IDefaultAdditionByQualityAndAttributeConfigurationViewModel[] => {

    const allCategories = state.loadedData.attributeCategories.map((cat) => {
        return {
            id: cat.id,
            displayName: cat.name,
            attributes: cat.availableAdditionAttributes.map((att) => {
                return {
                    id: att.id,
                    displayName: `${att.code} - ${att.name}`
                }
            })
        }
    })

    var attributeConfigurations = state.loadedData.policy.attributes.map((att, index) => {
        let category = null;
        allCategories.forEach(cat => {
            if (cat.attributes.findIndex(defAtt => defAtt.id === att.attributeId) >= 0) {
                category = cat;
            }
        });

        return {
            index: index + 1,
            attributeCategoryValues: allCategories,
            selectedAttributeCategoryValue: category,
            attributeValues: category.attributes,
            selectedAttributeValue: category.attributes.find(x => x.id === att.attributeId),
            additions: createAdditionRestore(att.additions, state.loadedData.additions)
        }
    });

    return calculateAvailableAttributes(attributeConfigurations, state.loadedData.attributeCategories);
}

const createAdditionRestore = (selectedAdditions: number[], additions: IAdditionOverview[]): IAdditionSelectionViewModel => {
    const additionViewModel: IAdditionSelectionViewModel = {
        selectedList: {
            allItems: [],
            filteredItems: [],
            searchText: ""
        },
        unSelectedList: createAdditions(additions),
    };

    selectedAdditions.forEach(x => {
        const indexUnselected = additionViewModel.unSelectedList.allItems.findIndex(y =>
            y.id === x);
        if (indexUnselected > -1) {
            const selectedAddition = additionViewModel.unSelectedList.allItems.find(y =>
                y.id === x);
            additionViewModel.selectedList.allItems.push(selectedAddition);
            additionViewModel.unSelectedList.allItems.splice(indexUnselected, 1);
        }
    });
    filterSelectionList(additionViewModel.selectedList);
    filterSelectionList(additionViewModel.unSelectedList);
    return additionViewModel;
}


export const calculateAvailableAttributes = (configurations: IDefaultAdditionByQualityAndAttributeConfigurationViewModel[], additionAttributeCategoriesLoaded: IAvailableAdditionAttributeCategory[]): IDefaultAdditionByQualityAndAttributeConfigurationViewModel[] => {
    additionAttributeCategoriesLoaded.forEach(lcat => {
        const selectedAttributes = [];
        const categoryConfigurations = configurations.filter(conf => conf.selectedAttributeCategoryValue && conf.selectedAttributeCategoryValue.id === lcat.id);
        categoryConfigurations.filter(x => x.selectedAttributeValue)
            .forEach(x => selectedAttributes.push(x.selectedAttributeValue));

        const availableAttributes = [];
        lcat.availableAdditionAttributes.forEach(x => {
            if (selectedAttributes.length === 0 || selectedAttributes.findIndex(y => y.id === x.id) === -1) {
                availableAttributes.push({
                    id: x.id,
                    displayName: `${x.code} - ${x.name}`
                });
            }
        });

        configurations.filter(conf => conf.selectedAttributeCategoryValue && conf.selectedAttributeCategoryValue.id === lcat.id)
            .forEach(x => {
                x.attributeValues = [...availableAttributes];
                if (x.selectedAttributeValue) {
                    x.attributeValues.push(x.selectedAttributeValue);
                }
                x.attributeValues.sort(sortAttributeValue);
            });
    })
    return configurations;
}

const sortAttributeValue = (atr1: IAttributeValueViewModel, atr2: IAttributeValueViewModel) => {
    return atr1.displayName.localeCompare(atr2.displayName);
}

export const moveFromUnselectedToSelected = (configuration: IDefaultAdditionByQualityAndAttributeConfigurationViewModel, ids: number[]) => {
    const selectedList = configuration.additions.selectedList;
    const unselectedList = configuration.additions.unSelectedList;

    ids.forEach(id => {
        const itemToMove = configuration.additions.unSelectedList.allItems.find(x => x.id === id);
        moveFromSourceToDestination(unselectedList, selectedList, itemToMove);
    });

    filterSelectionList(selectedList);
    filterSelectionList(unselectedList);
}

export const moveFromSelectedToUnselected = (configuration: IDefaultAdditionByQualityAndAttributeConfigurationViewModel, ids: number[]) => {
    const selectedList = configuration.additions.selectedList;
    const unselectedList = configuration.additions.unSelectedList;

    ids.forEach(id => {
        const itemToMove = configuration.additions.selectedList.allItems.find(x => x.id === id);
        moveFromSourceToDestination(selectedList, unselectedList, itemToMove);
    });

    filterSelectionList(selectedList);
    filterSelectionList(unselectedList);
}

export const moveFromSourceToDestination = (source: IAdditionFilterList, destination: IAdditionFilterList, itemToMove: IAdditionFilterListItem) => {
    const indexInAll = source.allItems.findIndex(x => x.id === itemToMove.id);
    destination.allItems.push(itemToMove);
    source.allItems.splice(indexInAll, 1);
}
