import { PublishedIconComponent } from "../icons/published_icon.component"
import { UnpublishedIconComponent } from "../icons/unpublished_icon.component"

interface CustomBooleanCellProps{
    value: boolean;
}

export const CustomBooleanCell: React.FC<CustomBooleanCellProps> = ({value}) => {
    return <>
        {value ? <PublishedIconComponent /> : <UnpublishedIconComponent />}
    </>
}