import { Menu, MenuItem } from "@mui/material";
import { GridCellParams } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { MenuOverviewComponent } from "../overview/menu_overview.component";

interface MenuItemData {
    action: (row: any) => any,
    label: string
}

interface DataGridMenuProps {
    params: GridCellParams
    menuItemsData: MenuItemData[]
}

export const DatagridMenu: React.FC<DataGridMenuProps> = ({ params, menuItemsData }) => {

    const [menuAnchor, setAnchorEl] = useState(null);

    const onMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onMenuClose = () => {
        setAnchorEl(null);
    };

    const onAction = (itemAction: (row) => any) => {
        itemAction(params.row);
        onMenuClose();
    }

    const menuItems = [];
    menuItemsData.forEach((item, index) => menuItems.push(
        <MenuItem key={index} onClick={() => onAction(item.action)}>{item.label}</MenuItem>
    ));

    return <>
        <Menu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={onMenuClose}>
            {menuItems.map(item => item)}
        </Menu>
        <MenuOverviewComponent outline="none"
            onMenuOpen={onMenuOpen}
            menuItem={params.row}
        />
    </>

}