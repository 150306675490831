import { createSlice } from '@reduxjs/toolkit';
import { AdditionHistoryDetailsState } from './addition_history_details.model';
import { fetchAdditionHistoryDetails } from './addition_history_details.thunks';

const initialState: AdditionHistoryDetailsState = {
    data: {
        additionHistoryDetails: null,
    },
    query: {
        fetchAdditionHistoryDetails: { status: "idle", canExecute: true },
    },
}

export const additionHistoryDetailsSlice = createSlice({
    name: 'addition_history/addition_history_details',
    initialState,
    reducers: {
    }, extraReducers: (builder) => {

        //fetchAdditionHistoryDetails
        builder.addCase(fetchAdditionHistoryDetails.pending, (state, action) => {
            state.query.fetchAdditionHistoryDetails.status = "pending"
            state.query.fetchAdditionHistoryDetails.canExecute = false;
        }).addCase(fetchAdditionHistoryDetails.rejected, (state, action) => {
            state.query.fetchAdditionHistoryDetails.status = "error"
            state.query.fetchAdditionHistoryDetails.message = action.error.message;
            state.query.fetchAdditionHistoryDetails.canExecute = true;
        }).addCase(fetchAdditionHistoryDetails.fulfilled, (state, action) => {
            state.query.fetchAdditionHistoryDetails.status = "success"
            state.query.fetchAdditionHistoryDetails.message = undefined;
            state.query.fetchAdditionHistoryDetails.canExecute = true;
            state.data.additionHistoryDetails = action.payload.getData();
        })
    }
})

export default additionHistoryDetailsSlice.reducer