import { Grid, Typography } from "@mui/material";
import { LinkComponent } from "shared/components/typography/link.component";
import { getConfig } from "services/config.service";

export interface CalingaLinkComponentProps {
    projectName: string
}

export const CalingaLinkComponent: React.FC<CalingaLinkComponentProps> = ( { projectName }) => {

    const generateCalingaLink = () => {
        const c = getConfig().calinga;
        return `${c.baseUrl}/translations/${c.organization}/${c.team}/${projectName}/${c.referenceLanguage}`
    }

    return (
        <Grid item md={9}>
            <Typography>
                Neue Texte können in <LinkComponent href={generateCalingaLink()} rel="noreferrer" target="_blank">Calinga</LinkComponent> angelegt werden.
            </Typography>
        </Grid>
    );
};