import { SideEnum } from 'models/additions/addition_side.enum';
import { AdditionPlaceHolderTypeEnum } from 'models/additions/addition_placeholder.enum';
import { AdditionExcludeCriteria } from 'models/additions/addition_exclude_criteria';
import { IAdditionCategoryOverview } from 'models/addition_categories/addition_category_overview';
import { EnumDescriptor } from 'models/enum_descriptor';
import { ICountry } from 'models/country/country';
import { IAvailableAdditionAttribute } from './addition';
import { IAvailableAdditionAttributeCategory } from 'models/addition_attribute_categories/available_addition_attribute_category';
import { IAttributeFormSearchText, IAttributeSelection } from 'models/addition_categories/addition_category_create';
import { AdditionCompressionClassEnum } from './addition_compressionclass.enum';
import IAvailableFootType from 'models/available_basedata/available_foot_type';
import { IPositionCategoryOverview } from 'models/position_category/position_category_overview';
import { ProductionTypeEnum } from './productiontype.enum';
import { MasspointTypeEnum } from 'models/masspoints/enums/masspoint_type.enum';
import { IBodyArea } from 'models/masspoints/body_area';
import { ICharacteristicCategoryOverview } from 'models/characteristic_categories/characteristic_category_overview';
import { IAvailableMappedBaseData } from 'models/available_basedata/available_mapped_base_data';
import IAvailableQuality from 'models/available_basedata/available_quality';
import IAvailableArticleType from 'models/available_basedata/available_article_type';
import { IIndexedIds } from 'check/policy/policy_adjust_ranges_by_masspoint/components/policy_adjust_ranges_by_masspoint_configuration.model';
import { LockTypeEnum } from 'shared/components/selectionComponent/models/locktype.enum';
import { ISelectionList } from 'shared/components/selectionComponent/models/selectionList';
import { IValidityScope } from './validity_scope';
import { ISelectable } from 'shared/components/selectionComponent/models/selectable';
import { QuantityCodeEnum } from './quantity_code.enum';
import { QuantityCalculationModeEnum } from './quantity_calculation_mode.enum';

export interface IErrorState {
    isVisible: boolean;
    text: string;
}

export enum PrintAreaEnum {
    General = 1,
    KnittingArea = 2,
    Confection = 3,
    Info = 4
}

export const PrintAreaEnumValues: Array<EnumDescriptor<PrintAreaEnum>> = [
    { value: PrintAreaEnum.General, text: "Allgemein" },
    { value: PrintAreaEnum.KnittingArea, text: "Strickbereich" },
    { value: PrintAreaEnum.Confection, text: "Konfektion" },
    { value: PrintAreaEnum.Info, text: "Info" },
];

export enum OrderManagementEnum {
    All = 1,
    OnlyCustomers = 2,
    OnlyMediEmployees = 3
}

export const OrderManagementEnumValues: Array<EnumDescriptor<OrderManagementEnum>> = [
    { value: OrderManagementEnum.All, text: "Alle" },
    { value: OrderManagementEnum.OnlyCustomers, text: "Nur Kunden" },
    { value: OrderManagementEnum.OnlyMediEmployees, text: "Nur medi intern" },
];

export const CompressionClassEnumValues: Array<EnumDescriptor<AdditionCompressionClassEnum>> = [
    { value: AdditionCompressionClassEnum.None, text: "Keine" },
    { value: AdditionCompressionClassEnum.Compression1, text: "Kompressionsklasse 1" },
    { value: AdditionCompressionClassEnum.Compression2, text: "Kompressionsklasse 2" },
    { value: AdditionCompressionClassEnum.Compression3, text: "Kompressionsklasse 3" },
    { value: AdditionCompressionClassEnum.Compression4, text: "Kompressionsklasse 4" },
];

export interface IAdditionEditor {
    addition: IViewModelAddition;
    additionExcludeCriteriaSelection: ISelectionList;
    positionCategorySelection: ISelectionList;
    selectedcharacteristicCategoryId?: number;
    showDeleteValidityDialog: boolean;
    showErrorMessageDialog: boolean;
    showDismissValidityAreaDialog: boolean;
    errorMessageTitle: string,
    errorMessageDescription: string,
    selectedPositioningOption: EnumDescriptor<boolean>;
    selectedCharacteristicCategoryOption: EnumDescriptor<boolean>;
    selectedLockCountry: EnumDescriptor<boolean>;
    selectedCountries: CountrySelection;
    footTypeSelection: ISelectionList;
    categories: CategorySelection;
}

export interface CategorySelection {
    availableCategories: AvailableCategory[];
    attributeForms: AttributeForm[],
}

export interface AttributeForm {
    selectedCategory: AvailableCategory;
    isLocked: boolean;
    selectedList: AttributeFilterList;
    unSelectedList: AttributeFilterList;
}

export interface AttributeFilterList {
    searchText: string,
    allItems: IAvailableAdditionAttribute[],
    filteredItems: IAvailableAdditionAttribute[],
}

export interface AvailableCategory {
    id: number;
    name: string;
    erpId: string;
}

export interface FootTypeSelection {
    loadedFootTypes: IAvailableFootType[];
    selectedList: FootTypeFilterList;
    unSelectedList: FootTypeFilterList;
}

export interface FootTypeFilterList {
    searchText: string,
    allItems: IAvailableFootType[],
    filteredItems: IAvailableFootType[],
}

export interface CountrySelection {
    loadedCountries: ICountry[];
    selectedList: CountryFilterList;
    unSelectedList: CountryFilterList;
}

export interface CountryFilterList {
    searchText: string,
    allItems: ICountry[],
    filteredItems: ICountry[],
}

export interface IAdditionInitial {
    mappedBaseData: IAvailableMappedBaseData; 
    bodyAreas: IBodyAreaViewModel[];
    mainProductLines: IProductLineViewModel[];
    articleTypes: IAvailableArticleType[],
    qualities: IAvailableQuality[],
    masspoints: IGussetMassPointViewModel[],
    positionCategories: IPositionCategoryOverview[],
    characteristicCategories: ICharacteristicCategoryOverview[],
    sides: EnumDescriptor<SideEnum>[];
    categories: IAdditionCategoryOverview[];
    printAreas: EnumDescriptor<PrintAreaEnum>[];
    positioningOptions: EnumDescriptor<boolean>[];
    characteristicCategoryOptions: EnumDescriptor<boolean>[];
    orderManagementOptions: EnumDescriptor<OrderManagementEnum>[];
    automatedProductions: EnumDescriptor<boolean>[];
    reorderOptions: EnumDescriptor<boolean>[];
    lockCountryOptions: EnumDescriptor<boolean>[];
    isFullyKnitted: EnumDescriptor<boolean>[];
    isRecipeValidOptions: EnumDescriptor<boolean>[];
    isGussetOptions: EnumDescriptor<boolean>[];
    isMultiplyOptions: EnumDescriptor<boolean>[];
    compressionClass: EnumDescriptor<AdditionCompressionClassEnum>[];
    productionTypes: EnumDescriptor<ProductionTypeEnum>[];
    createOrderPositionOptions: EnumDescriptor<boolean>[];
    calculateOrderPosition: EnumDescriptor<boolean>[];
    additionScopeValidity: EnumDescriptor<LockTypeEnum>[];
    placeholderTypes: EnumDescriptor<AdditionPlaceHolderTypeEnum>[];
    availableAttributeCategories: IAvailableAdditionAttributeCategory[];
}

export default interface IViewModelAddition {
    id?: number;
    option: string;
    code: string;
    description: string;
    baseDescription?: string;
    hasCalingaDescription: boolean;
    descriptionLong: string;
    quantityCalculationMode: EnumDescriptor<QuantityCalculationModeEnum>;
    quantityCode?: QuantityCodeEnum;
    quantityText: string;
    side: EnumDescriptor<SideEnum>;
    createOrderPosition: EnumDescriptor<boolean>;
    calculateOrderPosition: EnumDescriptor<boolean>;
    orderManagement: EnumDescriptor<OrderManagementEnum>;
    reOrder: EnumDescriptor<boolean>;
    automaticProduction: EnumDescriptor<boolean>;
    printArea: EnumDescriptor<PrintAreaEnum>;
    category: IAdditionCategoryOverview;
    validityScopes: IValidityScope[];
    editableScope: IValidityScope;
    editableScopeIndex: number; 
    scopeDeletingIndex: number;
    additionGussets: IAdditionGussetViewModel[];
    placeholder: IPlaceholderViewModel;
    countryLock: CountryLockViewModel;
    lockFootType: boolean;
    isFullyKnitted: EnumDescriptor<boolean>;
    isRecipeValid: EnumDescriptor<boolean>;
    isGusset: EnumDescriptor<boolean>;
    isMultiply: EnumDescriptor<boolean>;
    compressionClass: EnumDescriptor<AdditionCompressionClassEnum>;
    productionType: EnumDescriptor<ProductionTypeEnum>;
    positionCode: string;
    maxPositions: number;
}

export interface CountryLockViewModel {
    lockCountry: boolean;
    countryLockType: LockTypeEnum,
    countryLocks: ICountry[],
}

export interface IPlaceholderViewModel {
    placeholderTemplate: EnumDescriptor<AdditionPlaceHolderTypeEnum>;
    defaultConfig: IPlaceholderConfigViewModel;
    qualityConfigs: IPlaceholderQualityConfigViewModel[];
    twoRangePlaceholderIsSideSpecific: boolean;
    isInteger: boolean;
}

export interface IPlaceholderQualityConfigViewModel{
    quality?: IAvailableQuality;
    config?: IPlaceholderConfigViewModel;
    selectableQualites?: ISelectable[]
}

export interface IPlaceholderConfigViewModel{
    textInputPlaceholderVersion?: ITextInputPlaceholderViewModel;
    rangePlaceholderVersion?: IRangePlaceholderViewModel;
    twoRangePlaceholderVersion?: ITwoRangePlaceholderViewModel;
}

export interface IPlaceholderValue{
    index?: number;
    value: number;
}

export interface IPlaceholderQuality{
    index?: number;
    quality: IAvailableQuality;
}

export interface ITextInputPlaceholderViewModel {
    textInputMaxLength: number;
}

export interface IRangePlaceholderViewModel {
    id?: number;
    minimalValue: number;
    maximalValue: number;
}

export interface ITwoRangePlaceholderViewModel {
    id?: number;
    firstMinimalValue: number;
    firstMaximalValue: number;
    secondMinimalValue: number;
    secondMaximalValue: number;
}

export interface IAdditionViewModelScope {
    id?: number 
    mainProductLine: IProductLineViewModel;
    bodyArea: IBodyAreaViewModel;
    articleTypes: IArticleTypeSelectionViewModel;
    qualities: IQualitySelectionViewModel;
    additionId?: number;
}

export interface IArticleTypeSelectionViewModel {
    lockType?: LockTypeEnum,
    selectedList: ArticleTypeFilterList;
    unSelectedList: ArticleTypeFilterList;
}

export interface ArticleTypeFilterList {
    searchText: string,
    allItems: IAvailableArticleType[],
    filteredItems: IAvailableArticleType[],
}

export interface IQualitySelectionViewModel {
    lockType?: LockTypeEnum,
    selectedList: QualityFilterList;
    unSelectedList: QualityFilterList;
}

export interface QualityFilterList {
    searchText: string,
    allItems: IAvailableQuality[],
    filteredItems: IAvailableQuality[],
}

export interface IAdditionGussetViewModel {
    priority?: number;
    additionGussetArticleTypes: IArticleTypeSelectionViewModel;
    additionGussetQualities: IQualitySelectionViewModel;
    additionGussetMassPoints: IGussetMassPointViewModel[];
    availableGussetMassPointModels: IGussetMassPointViewModel[];
    index: number
}

export interface IGussetMassPointViewModel {
    displayName: string;
    name: string;
    type: MasspointTypeEnum;
    bodyAreaId: number;
    minimalValue?: number;
    maximalValue?: number;
    bodyArea: IBodyArea;
}

export interface IAdditionExcludeViewModel {
    id: number;
    option: string;
}

export interface IBodyAreaViewModel {
    id: number;
    name: string;
}

export interface IProductLineViewModel {
    id: number;
    name: string;
}

export interface IIndexedFilter {
    index: number,
    searchText: string,
}

export interface IIndexedSelection {
    index: number,
    item: number,
}

export interface IIndexedNumber {
    index: number,
    value: number,
}

export interface IIndexMasspointDelete {
    index: number,
    masspoint: IGussetMassPointViewModel,
}

export interface IIndexMasspointUpdate {
    index: number,
    masspoint: IGussetMassPointViewModel,
    targetMasspoint: IGussetMassPointViewModel
}

export interface IIndexMasspointValueUpdate {
    index: number,
    masspoint: IGussetMassPointViewModel,
    value: number,
}

export interface IAdditionGussetEditorAction {
    addGussetDefinition: () => {},
    deleteGussetDefinition: (index: number) => {},

    filterSelectedGussetQualities: (filter: IIndexedFilter) => {},
    filterUnselectedGussetQualities: (filter: IIndexedFilter) => {},
    selectGussetQualities: (selection: IIndexedIds) => {},
    unselectGussetQualities: (selection: IIndexedIds) => {},

    filterSelectedGussetArticleTypes: (filter: IIndexedFilter) => {},
    filterUnselectedGussetArticleTypes: (filter: IIndexedFilter) => {},
    selectGussetArticleTypes: (selection: IIndexedIds) => {},
    unselectGussetArticleTypes: (selection: IIndexedIds) => {},

    addGussetMasspoint: (index: number) => {},
    updateGussetMasspoint: (selection: IIndexMasspointUpdate) => {},
    deleteGussetMasspoint: (selection: IIndexMasspointDelete) => {},
    updateGussetRangeMinimalValue: (selection: IIndexMasspointValueUpdate) => {},
    updateGussetRangeMaximalValue: (selection: IIndexMasspointValueUpdate) => {},

    updateGussetPriority: (selection: IIndexedNumber) => {},
}

export interface IAdditionEditorAction {
    gussets: IAdditionGussetEditorAction,
    updateSide: (side: EnumDescriptor<SideEnum>) => {},
    updateQuantityCalculationMode: (quantityCalculationMode: EnumDescriptor<QuantityCalculationModeEnum>) => {},
    updateCategory: (category: IAdditionCategoryOverview) => {},
    updatePlaceHolderType: (placeholderType: EnumDescriptor<AdditionPlaceHolderTypeEnum>) => {},
    addPlaceholderQualityConfig: () => {},
    deletePlaceholderQualityConfig: (value: number) => {},
    selectPlaceholderConfigQuality: (value: IPlaceholderQuality) => {},
    updatePlaceholderTextLength: (value: IPlaceholderValue) => {},
    updateRangeMinimalValue: (value: IPlaceholderValue) => {},
    updateRangeMaximalValue: (value: IPlaceholderValue) => {},
    updateTwoRangeFirstMinimalValue: (value: IPlaceholderValue) => {},
    updateTwoRangeFirstMaximalValue: (value: IPlaceholderValue) => {},
    updateTwoRangeSecondMinimalValue: (value: IPlaceholderValue) => {},
    updateTwoRangeSecondMaximalValue: (value: IPlaceholderValue) => {},
    toggleTwoRangePlaceholderIsSideSpecific: () => {},
    toggleIsInteger: () => {},
    updateCreateOrderPosition: (value: EnumDescriptor<boolean>) => {},
    updateCalculateOrderPosition: (value: EnumDescriptor<boolean>) => {},
    updateOrderManagement: (value: EnumDescriptor<OrderManagementEnum>) => {},
    updateReorder: (value: EnumDescriptor<boolean>) => {},
    updatePositioningOptions: (value: EnumDescriptor<boolean>) => {},
    updateCharacteristicCategoryOptions: (value: EnumDescriptor<boolean>) => {},
    updateLockCountry: (value: EnumDescriptor<boolean>) => {},
    updateLockFootType: (value: boolean) => {},
    updateAutomatedProduction: (value: EnumDescriptor<boolean>) => {},
    updatePrintArea: (value: EnumDescriptor<PrintAreaEnum>) => {},
    updateMainProductLine: (mainProductionLine: ISelectable) => {},
    updateBodyArea: (bodyArea: ISelectable) => {},
    selectAdditionExcludeCriteria: (ids: number[]) => {},
    unselectAdditionExcludeCriteria: (ids: number[]) => {},
    filterSelectedAdditionExcludeCriteria: (searchtext: string) => {},
    filterAvailableAdditionExcludeCriteria: (searchtext: string) => {},
    filterUnselectedCountries: (searchtext: string) => {},
    filterSelectedCountries: (searchtext: string) => {},
    selectCountries: (countries: ICountry[]) => {},
    unselectCountries: (countries: ICountry[]) => {},
    allowCountry: () => {},
    denyCountry: () => {},
    allowFootType: () => {},
    denyFootType: () => {},
    executeSave: () => {},
    cancelSave: () => {},
    canExecuteSave: boolean,
    addAttributeForm: () => {},
    selectCategory: (category: AvailableCategory) => {},
    deleteAttributeForm: (attributeForm: AttributeForm) => {},
    filterUnselectedAttributes: (attributeSearchText: IAttributeFormSearchText) => {},
    filterSelectedAttributes: (attributeSearchText: IAttributeFormSearchText) => {},
    selectAttributes: (attributeSelections: IAttributeSelection) => {},
    unselectAttributes: (attributeSelections: IAttributeSelection) => {},
    allowCategory: (categoryIndex: number) => {},
    denyCategory: (categoryIndex: number) => {},
    updateFullyKnitted: (isFullyKnitted: EnumDescriptor<boolean>) => {},
    updateIsRecipeValid: (isRecipeValid: EnumDescriptor<boolean>) => {},
    updateIsGusset: (isGusset: EnumDescriptor<boolean>) => {},
    updateIsMultiply: (isGusset: EnumDescriptor<boolean>) => {},
    updateCompressionClass: (compression: EnumDescriptor<AdditionCompressionClassEnum>) => {},
    updateProductionType: (productionType: EnumDescriptor<ProductionTypeEnum>) => {},
    selectPositionCategories: (ids: number[]) => {},
    unselectPositionCategories: (ids: number[]) => {},
    filterSelectedPositionCategories: (searchtext: string) => {},
    filterUnselectedPositionCategories: (searchtext: string) => {},
    filterSelectedArticleType: (searchText: string) => {};
    filterUnselectedArticleType: (searchText: string) => {};
    selectArticleTypes: (ids: number[]) => {},
    unselectArticleTypes: (ids: number[]) => {},
    allowArticleType: () => {};
    denyArticleType: () => {};
    filterSelectedQuality: (searchText: string) => {};
    filterUnselectedQuality: (searchText: string) => {};
    selectQualities: (ids: number[]) => {},
    unselectQualities: (ids: number[]) => {},
    allowQuality: () => {};
    denyQuality: () => {};   
    cancelEditableScope: () => {};
    applyEditableScope: () => {};
    editScopeConfiguration: (index: number) => {};
    deleteValidityScope: (index: number) => {},
    addValidityScope: () => {},
    filterUnselectedFootType: (searchText: string) => void;
    filterSelectedFootType: (searchText: string) => void;
    selectFootTypes: (ids: number[]) => {},
    unselectFootTypes: (ids: number[]) => {},
    updatePositionCode: (positionCode: string) => {};
    updateMaxPositions: (maxPositions: number) => {};
    characteristicCategoryActions: {
        selectCharacteristicCategory: (id: number) => {},
    }
}

export interface AdditionExcludeCirteriaList {
    searchText: string,
    allItems: AdditionExcludeCriteria[],
    filteredItems: AdditionExcludeCriteria[],
}
