import React from "react";
import { HistoryOverviewComponent } from "./history_overview/history_overview.component";
import { MassMaskHistoryComponent } from "./massmask_history/massmask_history.component";
import { CheckTypeHistoryComponent } from "./checktype_history/checktype_history.component";
import { MassMaskHistoryDetailsComponent } from './massmask_history_details/massmask_history_details.component';
import { MasspointHistoryComponent } from "./masspoint_history/masspoint_history.component";
import { MassPointHistoryDetailsComponent } from "./masspoint_history_details/masspoint_history_details.component";
import { CheckHistoryComponent } from "./check_history/check_history.component";
import { CheckHistoryDetailsComponent } from './check_history_details/check_history_details.component';
import { AdditionsHistoryComponent } from "./addition_history/addition_history.component";
import { AdditionHistoryDetailsComponent } from './addition_history_details/addition_history_details.component';
import { Route, Routes } from "react-router-dom";

export const HistoryComponent = () => {
    return (
        <Routes>
            <Route element={<HistoryOverviewComponent />} path="/" />
            <Route element={<MassMaskHistoryComponent />} path="/massmask" />
            <Route element={<MassMaskHistoryDetailsComponent />} path="/massmask/:historyId" />
            <Route element={<MasspointHistoryComponent />} path="/masspoint" />
            <Route element={<MassPointHistoryDetailsComponent />} path="/masspoint/:historyId" />
            <Route element={<CheckTypeHistoryComponent />} path="/checktype" />
            <Route element={<CheckHistoryComponent />} path="/check" />
            <Route element={<CheckHistoryDetailsComponent />} path="/check/:historyId" />
            <Route element={<AdditionsHistoryComponent />} path="/addition"/>
            <Route element={<AdditionHistoryDetailsComponent />} path="/addition/:historyId"/>

        </Routes>
    )
}