import { createAsyncThunk } from '@reduxjs/toolkit';
import MassMaskHistoryService from '../../../services/masspoint_history.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';

export const fetchMasspointHistoryDetail = createAsyncThunk(
    'history/masspoint/details/fetchMasspointHistoryDetail',
    async (historyId: Number, thunkApi) => {
        const response = await MassMaskHistoryService.getMasspointHistoryDetails(historyId);
        handleResponseErrors(response, "Maßpunkt Historie");
        return response;
    }
)
