import { filterMassmaskValue } from '../massmask_overview.helper';
import { MassMasksOverviewState } from './massmasks_overview.model';

export const filterMassMasks = (state: MassMasksOverviewState) => {
    const searchTextLower = state.actualData.searchFilter.toLowerCase().trim();
    let filteredItems = state.loadedData.massMasks.filter(
        (massmask) =>
            massmask.mainProductLine.name.toLowerCase().includes(searchTextLower) ||
            massmask.bodyArea.name.toLowerCase().includes(searchTextLower) ||
            (massmask.articleType != null && `${massmask.articleType.erpId} ${massmask.articleType.name}`
                .toLowerCase()
                .includes(searchTextLower)) ||
            (massmask.footType != null && `${massmask.footType.externalIdentifier} ${massmask.footType.name}`
                .toLowerCase()
                .includes(searchTextLower))
    );

    state.actualData.gridFilters.items.forEach(fil => {
        filteredItems = filterMassmaskValue(fil, filteredItems);
    });
    state.actualData.massMasks = filteredItems;
}