import { Grid } from "@mui/material";
import { EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";
import { DropdownComponent, TextBoxComponent } from "shared/shared";
import { ICharacteristicTypeOverview } from "models/characteristic_types/characteristic_type_overview";
import { SelectionListComponent } from "shared/components/selectionComponent/selectionList.component";
import { ICharacteristicValueSelectionViewModel } from "./characteristic_category_editor.model";

interface CharacteristicCatagoryEditorProps {
    header: string;
    name: string;
    types: ICharacteristicTypeOverview[];
    selectedType: ICharacteristicTypeOverview;
    selectionValues: ICharacteristicValueSelectionViewModel;
    changeName: (name: string) => void;
    selectType: (type: ICharacteristicTypeOverview) => void;
    filterSelectedValues: (text: string) => void;
    filterUnselectedValues: (text: string) => void;
    selectValues: (ids: number[]) => void;
    unSelectValues: (ids: number[]) => void;
}

export const CharacteristicCategoryEditorComponent: React.FC<CharacteristicCatagoryEditorProps> = (
    {
        header,
        name,
        types,
        selectedType,
        selectionValues,
        changeName,
        selectType,
        filterSelectedValues,
        filterUnselectedValues,
        selectValues,
        unSelectValues
    }) => {

    const showSelectedAllIcon = (selectionValues.selectedList.allItems.length > 0 
        || selectionValues.unSelectedList.allItems.length > 0)
        && selectionValues.selectedList.searchText === '';
    return (
        <Grid container md={6} spacing={3}  direction={'column'}>
            <Grid item >
                <EditorSectionHeaderComponent>{header}</EditorSectionHeaderComponent>
            </Grid>
            <Grid item>
                <TextBoxComponent
                    name="Name"
                    value={name}
                    onChange={changeName}
                    isRequired={true}
                    maxLength={512}
                    isAutoFocus={true}
                />
            </Grid>
            <Grid item>
                <DropdownComponent
                    name="Ausprägungstyp"
                    data={types}
                    isLoading={false}
                    isRequired={true}
                    onSelect={(type) => selectType(type)}
                    displayedProperties={["name"]}
                    selectedValue={selectedType}
                />
            </Grid>
            <Grid item>
                <SelectionListComponent
                    title={"Ausprägungswerte"}
                    entityName={"Ausprägung"}
                    onFilterSelected={(searchText) => { filterSelectedValues(searchText) }}
                    onFilterUnselected={(searchText) => { filterUnselectedValues(searchText) }}
                    selectionList={selectionValues}
                    selectEntities={(ids) => { selectValues(ids)}}
                    unselectEntities={(ids) => { unSelectValues(ids) }}
                    showSelectedAllIcon={showSelectedAllIcon}
                /> 
            </Grid> 
        </Grid>
    );
};