import { AdditionOverviewState } from './addition_overview.model';
import { filterAdditionValue } from '../addition_overview.helper';

export const filterAdditions = (state: AdditionOverviewState) => {
    const searchTextLower = state.actualData.searchFilter.toLowerCase().trim();
    let filteredItems = state.loadedData.additions.filter(
        (addition) =>
            addition.option.toLowerCase().includes(searchTextLower) ||
            addition.description.toLowerCase().includes(searchTextLower) ||
            addition.code.toLowerCase().includes(searchTextLower)
    );

    state.actualData.additonsGridFilter.items.forEach(fil => {
        filteredItems = filterAdditionValue(fil, filteredItems);
    });
    state.actualData.additions = filteredItems;
}