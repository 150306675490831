import { AppDispatch } from "app/redux_store";
import { AdditionState } from "./redux/addition.model";
import { 
    addAttributeForm, 
    addGussetDefinition,
    addGussetMasspoint, 
    addPlaceholderQualityConfig, 
    addValidityScope, 
    allowArticleType, 
    allowCategory, 
    allowCountry, 
    allowFootType, 
    allowQuality, 
    applyEditableScope, 
    cancelEditableScope, 
    deleteAttributeForm, 
    deleteGussetDefinition, 
    deleteGussetMasspoint, 
    deletePlaceholderQualityConfig, 
    deleteValidityScope, 
    denyArticleType,
    denyCategory, 
    denyCountry, 
    denyFootType, 
    denyQuality, 
    editScopeConfiguration,
    filterSelectedAdditionExcludeCriteria,
    filterSelectedArticleType, 
    filterSelectedAttributes, 
    filterSelectedCountries, 
    filterSelectedFootType, 
    filterSelectedGussetArticleTypes, 
    filterSelectedGussetQualities, 
    filterSelectedPositionCategories, 
    filterSelectedQuality, 
    filterUnselectedAdditionExcludeCriteria, 
    filterUnselectedArticleType, 
    filterUnselectedAttributes, 
    filterUnselectedCountries, 
    filterUnselectedFootType, 
    filterUnselectedGussetArticleTypes, 
    filterUnselectedGussetQualities, 
    filterUnselectedPositionCategories, 
    filterUnselectedQuality, 
    getAdditionDefinitionErrorCompleted, 
    getAdditionErrorCompleted, 
    saveAdditionCompleted, 
    saveAdditionErrorCompleted, 
    selectAdditionExcludeCriteria, 
    selectArticleTypes, 
    selectAttributes, 
    selectCategory, 
    selectCharacteristicCategory, 
    selectCountries, 
    selectFootTypes, 
    selectGussetArticleTypes, 
    selectGussetQualities, 
    selectPlaceholderConfigQuality, 
    selectPositionCategories, 
    selectQualities, 
    toggleIsInteger, 
    toggleTwoRangePlaceholderIsSideSpecific, 
    unselectAdditionExcludeCriteria, 
    unselectArticleTypes, 
    unselectAttributes, 
    unselectCountries, 
    unselectFootTypes, 
    unselectGussetArticleTypes, 
    unselectGussetQualities, 
    unselectPositionCategories, 
    unselectQualities, 
    updateAdditionCompleted, 
    updateAutomatedProduction, 
    updateBodyArea, 
    updateCalculateOrderPosition, 
    updateCategory,
    updateQuantityCalculationMode,
    updateCharacteristicCategoryOptions, 
    updateCompressionClass, 
    updateCreateOrderPosition, 
    updateFullyKnitted, 
    updateGussetMasspoint, 
    updateGussetPriority, 
    updateGussetRangeMaximalValue, 
    updateGussetRangeMinimalValue, 
    updateIsGusset, 
    updateIsMultiply, 
    updateIsRecipeValid, 
    updateLockCountry, 
    updateLockFootType, 
    updateMainProductLine, 
    updateMaxPositions, 
    updateOrderManagement, 
    updatePlaceholderTextLength, 
    updatePlaceHolderType, 
    updatePositionCode, 
    updatePositioningOptions, 
    updatePrintArea, 
    updateProductionType, 
    updateRangeMaximalValue, 
    updateRangeMinimalValue, 
    updateReorder, 
    updateSide, 
    updateTwoRangeFirstMaximalValue, 
    updateTwoRangeFirstMinimalValue,
    updateTwoRangeSecondMaximalValue, 
    updateTwoRangeSecondMinimalValue 
} from "./redux/addition.slice";
import { NavigateFunction } from "react-router-dom";
import { EnqueueSnackbar } from "notistack";
import { IAdditionEditorAction, IIndexedFilter, IIndexedNumber, IIndexMasspointDelete, IIndexMasspointUpdate, IIndexMasspointValueUpdate } from "models/additions/addition_editor.model";
import { IIndexedIds } from "check/policy/policy_adjust_ranges_by_masspoint/components/policy_adjust_ranges_by_masspoint_configuration.model";
import { EditorModeEnum } from "models/editors/editor_mode.enum";
import { updateAddition, saveAddition, cancelSave } from "./redux/addition.thunks";

export const handleCommandAndQueryStatus = (state: AdditionState, dispatch: AppDispatch, navigate: NavigateFunction, enqueueSnackbar: EnqueueSnackbar) => {

    if (state.query.getAddition.status === "error") {
        enqueueSnackbar(state.query.getAddition.message, { variant: "warning" });
        dispatch(getAdditionErrorCompleted());
        navigate("/additions/addition/overview");
    }

    if (state.command.updateAddition.status === "error") {
        enqueueSnackbar(state.command.updateAddition.message, { variant: "error" });
        dispatch(saveAdditionErrorCompleted());
    }

    if (state.command.updateAddition.status === "warning") {
        enqueueSnackbar(state.command.updateAddition.message, { variant: "warning" });
        dispatch(saveAdditionErrorCompleted());
    }

    if (state.command.updateAddition.status === "success") {
        enqueueSnackbar("Zusatz gespeichert", { variant: "success" });
        dispatch(updateAdditionCompleted());
        navigate("/additions/addition/overview");
    }

    if (state.query.getAdditionDefinition.status === "error") {
        enqueueSnackbar(state.query.getAdditionDefinition.message, { variant: "warning" });
        dispatch(getAdditionDefinitionErrorCompleted());
    }

    if (state.command.saveAddition.status === "error") {
        enqueueSnackbar(state.command.saveAddition.message, { variant: "error" });
        dispatch(saveAdditionErrorCompleted());
    }

    if (state.command.saveAddition.status === "warning") {
        enqueueSnackbar(state.command.saveAddition.message, { variant: "warning" });
        dispatch(saveAdditionErrorCompleted());
    }

    if (state.command.saveAddition.status === "success") {
        enqueueSnackbar("Zusatz gespeichert", { variant: "success" });
        dispatch(saveAdditionCompleted());
        navigate("/additions/addition/overview");
    }

    if (state.command.cancel.status === "success") {
        navigate("/additions/addition/overview");
    }
}

export const getAdditionEditorActions = (state: AdditionState, dispatch: AppDispatch): IAdditionEditorAction => {
    return {
        canExecuteSave: state.command.updateAddition.canExecute,
        gussets: {
            addGussetDefinition: () => dispatch(addGussetDefinition()),
            deleteGussetDefinition: (index: number) => dispatch(deleteGussetDefinition(index)),

            filterSelectedGussetQualities: (filter: IIndexedFilter) => dispatch(filterSelectedGussetQualities(filter)),
            filterUnselectedGussetQualities: (filter: IIndexedFilter) => dispatch(filterUnselectedGussetQualities(filter)),
            selectGussetQualities: (selection: IIndexedIds) => dispatch(selectGussetQualities(selection)),
            unselectGussetQualities: (selection: IIndexedIds) => dispatch(unselectGussetQualities(selection)),

            filterSelectedGussetArticleTypes: (filter: IIndexedFilter) => dispatch(filterSelectedGussetArticleTypes(filter)),
            filterUnselectedGussetArticleTypes: (filter: IIndexedFilter) => dispatch(filterUnselectedGussetArticleTypes(filter)),
            selectGussetArticleTypes: (selection: IIndexedIds) => dispatch(selectGussetArticleTypes(selection)),
            unselectGussetArticleTypes: (selection: IIndexedIds) => dispatch(unselectGussetArticleTypes(selection)),

            addGussetMasspoint: (index: number) => dispatch(addGussetMasspoint(index)),
            updateGussetMasspoint: (selection: IIndexMasspointUpdate) => dispatch(updateGussetMasspoint(selection)),
            deleteGussetMasspoint: (selection: IIndexMasspointDelete) => dispatch(deleteGussetMasspoint(selection)),
            updateGussetRangeMinimalValue: (selection: IIndexMasspointValueUpdate) => dispatch(updateGussetRangeMinimalValue(selection)),
            updateGussetRangeMaximalValue: (selection: IIndexMasspointValueUpdate) => dispatch(updateGussetRangeMaximalValue(selection)),

            updateGussetPriority: (selection: IIndexedNumber) => dispatch(updateGussetPriority(selection)),
        },
        updateSide: (side) => dispatch(updateSide(side)),
        updateQuantityCalculationMode: (quantityCalculationMode) => dispatch(updateQuantityCalculationMode(quantityCalculationMode)),
        updateCategory: (category) => dispatch(updateCategory(category)),
        updateCreateOrderPosition: (value) => dispatch(updateCreateOrderPosition(value)),
        updateCalculateOrderPosition: (value) => dispatch(updateCalculateOrderPosition(value)),
        updateOrderManagement: (value) => dispatch(updateOrderManagement(value)),
        updateReorder: (value) => dispatch(updateReorder(value)),
        updatePositioningOptions: (value) => dispatch(updatePositioningOptions(value)),
        updateIsRecipeValid: (value) => dispatch(updateIsRecipeValid(value)),
        updateLockCountry: (value) => dispatch(updateLockCountry(value)),
        updateLockFootType: (value) => dispatch(updateLockFootType(value)),
        updateAutomatedProduction: (value) => dispatch(updateAutomatedProduction(value)),
        updatePrintArea: (value) => dispatch(updatePrintArea(value)),
        updateBodyArea: (value) => dispatch(updateBodyArea(value)),
        updateMainProductLine: (value) => dispatch(updateMainProductLine(value)),
        updatePlaceHolderType: (value) => dispatch(updatePlaceHolderType(value)),
        addPlaceholderQualityConfig: () => dispatch(addPlaceholderQualityConfig()),
        deletePlaceholderQualityConfig: (value) => dispatch(deletePlaceholderQualityConfig(value)),
        selectPlaceholderConfigQuality: (value) => dispatch(selectPlaceholderConfigQuality(value)),
        updatePlaceholderTextLength: (value) => dispatch(updatePlaceholderTextLength(value)),
        updateRangeMinimalValue: (value) => dispatch(updateRangeMinimalValue(value)),
        updateRangeMaximalValue: (value) => dispatch(updateRangeMaximalValue(value)),
        updateTwoRangeFirstMinimalValue: (value) => dispatch(updateTwoRangeFirstMinimalValue(value)),
        updateTwoRangeFirstMaximalValue: (value) => dispatch(updateTwoRangeFirstMaximalValue(value)),
        updateTwoRangeSecondMinimalValue: (value) => dispatch(updateTwoRangeSecondMinimalValue(value)),
        updateTwoRangeSecondMaximalValue: (value) => dispatch(updateTwoRangeSecondMaximalValue(value)),
        toggleTwoRangePlaceholderIsSideSpecific: () => dispatch(toggleTwoRangePlaceholderIsSideSpecific()),
        toggleIsInteger: () => dispatch(toggleIsInteger()),
        selectAdditionExcludeCriteria: (ids) => dispatch(selectAdditionExcludeCriteria(ids)),
        unselectAdditionExcludeCriteria: (ids) => dispatch(unselectAdditionExcludeCriteria(ids)),
        filterAvailableAdditionExcludeCriteria: (searchText) => dispatch(filterUnselectedAdditionExcludeCriteria(searchText)),
        filterSelectedAdditionExcludeCriteria: (searchText) => dispatch(filterSelectedAdditionExcludeCriteria(searchText)),
        executeSave: () => dispatch(state.actualData.editorMode === EditorModeEnum.Edit ? updateAddition() : saveAddition()),
        cancelSave: () => dispatch(cancelSave()),
        filterSelectedPositionCategories: (searchText) => dispatch(filterSelectedPositionCategories(searchText)),
        filterUnselectedPositionCategories: (searchText) => dispatch(filterUnselectedPositionCategories(searchText)),
        selectPositionCategories: (ids) => dispatch(selectPositionCategories(ids)),
        unselectPositionCategories: (ids) => dispatch(unselectPositionCategories(ids)),
        filterUnselectedCountries: (searchText) => dispatch(filterUnselectedCountries(searchText)),
        filterSelectedCountries: (searchText) => dispatch(filterSelectedCountries(searchText)),
        selectCountries: (countries) => dispatch(selectCountries(countries)),
        unselectCountries: (countries) => dispatch(unselectCountries(countries)),
        allowCountry: () => dispatch(allowCountry()),
        denyCountry: () => dispatch(denyCountry()),
        allowFootType: () => dispatch(allowFootType()),
        denyFootType: () => dispatch(denyFootType()),
        selectFootTypes: (footTypes) => dispatch(selectFootTypes(footTypes)),
        unselectFootTypes: (footTypes) => dispatch(unselectFootTypes(footTypes)),
        filterSelectedFootType: (footType) => dispatch(filterSelectedFootType(footType)),
        filterUnselectedFootType: (footType) => dispatch(filterUnselectedFootType(footType)),
        addAttributeForm: () => dispatch(addAttributeForm()),
        selectCategory: (category) => dispatch(selectCategory(category)),
        deleteAttributeForm: (attributeForm) => dispatch(deleteAttributeForm(attributeForm)),
        filterSelectedAttributes: (attributeSearchText) => dispatch(filterSelectedAttributes(attributeSearchText)),
        filterUnselectedAttributes: (attributeSearchText) => dispatch(filterUnselectedAttributes(attributeSearchText)),
        selectAttributes: (attributeSelection) => dispatch(selectAttributes(attributeSelection)),
        unselectAttributes: (attributeSelection) => dispatch(unselectAttributes(attributeSelection)),
        allowCategory: (attributeForm) => dispatch(allowCategory(attributeForm)),
        denyCategory: (attributeForm) => dispatch(denyCategory(attributeForm)),
        updateFullyKnitted: (item) => dispatch(updateFullyKnitted(item)),
        updateIsGusset: (item) => dispatch(updateIsGusset(item)),
        updateIsMultiply: (item) => dispatch(updateIsMultiply(item)),
        updateCompressionClass: (item) => dispatch(updateCompressionClass(item)),
        updateProductionType: (item) => dispatch(updateProductionType(item)),
        filterSelectedArticleType: (item) => dispatch(filterSelectedArticleType(item)),
        filterUnselectedArticleType: (item) => dispatch(filterUnselectedArticleType(item)),
        selectArticleTypes: (item) => dispatch(selectArticleTypes(item)),
        unselectArticleTypes: (item) => dispatch(unselectArticleTypes(item)),
        allowArticleType: () => dispatch(allowArticleType()),
        denyArticleType: () => dispatch(denyArticleType()),
        filterSelectedQuality: (item) => dispatch(filterSelectedQuality(item)),
        filterUnselectedQuality: (item) => dispatch(filterUnselectedQuality(item)),
        selectQualities: (item) => dispatch(selectQualities(item)),
        unselectQualities: (item) => dispatch(unselectQualities(item)),
        allowQuality: () => dispatch(allowQuality()),
        denyQuality: () => dispatch(denyQuality()),
        updatePositionCode: (positionCode) => dispatch(updatePositionCode(positionCode)),
        updateCharacteristicCategoryOptions: (value) => dispatch(updateCharacteristicCategoryOptions(value)),
        characteristicCategoryActions: {
            selectCharacteristicCategory: (id) => dispatch(selectCharacteristicCategory(id))
        },
        cancelEditableScope: () => dispatch(cancelEditableScope()),
        applyEditableScope: () => dispatch(applyEditableScope()),
        editScopeConfiguration: (index) => dispatch(editScopeConfiguration(index)),
        deleteValidityScope: (index) => dispatch(deleteValidityScope(index)),
        addValidityScope: () => dispatch(addValidityScope()),
        updateMaxPositions: (maxPositions) => dispatch(updateMaxPositions(maxPositions))
    }
}