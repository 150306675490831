import React from "react";
import { BreadcrumbsComponent } from '../shared/breadcrumbs/components/breadcrumbs';
import { BreadcrumbRoute } from '../shared/breadcrumbs/models/breadcrumb_route';

export const SimulatorBreadcrumbs = () => {
    return <BreadcrumbsComponent breadcrumbs={routes} />
}

const routes: BreadcrumbRoute[] = [
    {
        route: "/simulator",
        parts: [{ title: "Simulator" }]
    },
    {
        route: "/simulator/configuration",
        parts: [
            { title: "Simulator", url: "/simulator" },
            { title: "Konfigurationen" }
        ]
    },
    {
        route: "/simulator/massmask",
        parts: [
            { title: "Simulator", url: "/simulator" },
            { title: "Maßmaske auswählen" }
        ]
    },
    {
        route: "simulator/massmask/simulate/:mainProductLineErpId/:bodyAreaErpId/:articleTypeErpId",
        parts: [
            { title: "Simulator", url: "/simulator" },
            { title: "Maßmaske auswählen", url: "/simulator/massmask" },
            { title: "Simulation durchführen" }
        ]
    },
    {
        route: "simulator/massmask/simulate/:mainProductLineErpId/:bodyAreaErpId/:articleTypeErpId/:productConfiguration",
        parts: [
            { title: "Simulator", url: "/simulator" },
            { title: "Maßmaske auswählen", url: "/simulator/massmask" },
            { title: "Simulation durchführen" }
        ]
    },
    {
        route: "simulator/massmask/simulate/:mainProductLineErpId/:bodyAreaErpId/:articleTypeErpId/:productConfiguration/:configurationRun",
        parts: [
            { title: "Simulator", url: "/simulator" },
            { title: "Maßmaske auswählen", url: "/simulator/massmask" },
            { title: "Simulation durchführen" }
        ]
    }
]