import { doesExist, isNullOrWhitespace } from "services/validation.service";
import IMasspointOverview from "models/masspoints/masspoint_overview";
import { MasspointTypeEnumValuesLookup } from "models/masspoints/enums/masspoint_type.enum";
import { PolicyAdjustRangesByMasspointUpdateState } from "./policy_adjust_ranges_by_masspoint_update.model";
import { IAdjustRangeByMasspointConfiguration, IMassPointCriteriaItem } from "../../components/policy_adjust_ranges_by_masspoint_configuration.model";
import IAvailableArticleType from "models/available_basedata/available_article_type";
import IAvailableQuality from "models/available_basedata/available_quality";
import { IAdditionPolicyInformation } from "models/additions/addition_policy_information";
import { IAdjustRangesbyMassPointConfig } from "models/policies/policy_adjust_ranges_by_masspoint";
import { createSelectionList } from "shared/components/selectionComponent/selectionList.helper";
import { getIAvailableArticleTypeDisplayName, getIAvailableQualityDisplayName } from "shared/helpers/displayNames";
import { distinctMassPointCriteria } from "shared/helpers/distinctMassPointCriteria";

export const updateCanSave = (state: PolicyAdjustRangesByMasspointUpdateState) => {
    state.command.updatePolicy.canExecute =
        isNotEqual(state)
        && !isNullOrWhitespace(state.data.policy.name)
        && additionValid(state.data.selectedAddition)
        && state.data.configurations.length > 0
        && allConfigsValid(state.data.configurations);
}

export const allConfigsValid = (configurations: IAdjustRangeByMasspointConfiguration[]) => {
    return !configurations.some(config =>
        config.qualities.selectedList.allItems.length === 0 ||
        config.articleTypes.selectedList.allItems.length === 0 ||
        !doesExist(config.selectedMasspoint) ||
        !doesExist(config.defaultPercentage) ||
        (!doesExist(config.thresholdPercentage) && doesExist(config.thresholdValue)) ||
        (doesExist(config.thresholdPercentage) && !doesExist(config.thresholdValue))
    )
}

export const additionValid = (addition: IAdditionPolicyInformation) => {
    return doesExist(addition) && doesExist(addition.placeholder?.twoRangePlaceholder)
}

const isNotEqual = (state: PolicyAdjustRangesByMasspointUpdateState) => {
    return state.data.policy.name !== state.loadedData.policy?.name
        || state.data.policy.ignoreOnQuotation !== state.loadedData.policy?.ignoreOnQuotation
        || state.data.selectedAddition.id !== state.loadedData.policy?.additionId
        || state.data.configurations.length !== state.loadedData.policy?.configs?.length
        || configurationHasChanged(state.data.configurations, state.loadedData.policy?.configs)
}

const configurationHasChanged = (configurations: IAdjustRangeByMasspointConfiguration[], loadedConfiguration: IAdjustRangesbyMassPointConfig[]) => {

    return loadedConfiguration.some((config, index) => {
        const currentConfig = configurations[index];

        return config.massPoint.massPointName !== currentConfig.selectedMasspoint?.name ||
            config.massPoint.massPointType !== currentConfig.selectedMasspoint?.type ||
            config.massPoint.bodyAreaId !== currentConfig.selectedMasspoint?.bodyAreaId ||
            config.articleTypes.length !== currentConfig.articleTypes.selectedList.allItems.length ||
            config.articleTypes.some(articleType => !currentConfig.articleTypes.selectedList.allItems.some(currentArticleType => currentArticleType.id === articleType)) ||
            config.qualities.length !== currentConfig.qualities.selectedList.allItems.length ||
            config.qualities.some(quality => !currentConfig.qualities.selectedList.allItems.some(currentQuality => currentQuality.id === quality)) ||
            config.defaultPercentage !== currentConfig.defaultPercentage ||
            config.thresholdValue !== currentConfig.thresholdValue ||
            config.thresholdPercentage !== currentConfig.thresholdPercentage
    })
}

export const createMasspointItemList = (massPoints: IMasspointOverview[]): IMassPointCriteriaItem[] => {
    return massPoints
        .filter(distinctMassPointCriteria)
        .map((mp, index) => {
            return {
                id: index,
                displayName: `${mp.name} ${MasspointTypeEnumValuesLookup(mp.massPointType)} ${mp.bodyArea.name}`,
                name: mp.name,
                type: mp.massPointType,
                bodyAreaId: mp.bodyArea.id,
            }
        })
};

export const createConfigurations = (state: PolicyAdjustRangesByMasspointUpdateState): IAdjustRangeByMasspointConfiguration[] => {
    return state.loadedData.policy.configs?.map(config => {
        return ({
            selectedMasspoint: state.loadedData.masspoints.find(x =>
                x.bodyAreaId === config.massPoint.bodyAreaId
                && x.name === config.massPoint.massPointName
                && x.type === config.massPoint.massPointType),
            defaultPercentage: config.defaultPercentage,
            thresholdPercentage: config.thresholdPercentage,
            thresholdValue: config.thresholdValue,
            selectableMasspoints: [],
            articleTypes: createSelectionList( state.loadedData.articleTypes, config.articleTypes, getIAvailableArticleTypeDisplayName),
            qualities: createSelectionList(state.loadedData.qualities, config.qualities, getIAvailableQualityDisplayName)
        })
    }
    )
}

export const createNewConfiguration = (state: PolicyAdjustRangesByMasspointUpdateState): IAdjustRangeByMasspointConfiguration => {
    return {
        selectedMasspoint: null,
        selectableMasspoints: [],
        defaultPercentage: null,
        thresholdPercentage: null,
        thresholdValue: null,
        articleTypes: createSelectionList(state.loadedData.articleTypes, [], getIAvailableArticleTypeDisplayName),
        qualities: createSelectionList(state.loadedData.qualities, [], getIAvailableQualityDisplayName)
    };
}

export const calculateAvailableMasspoints = (configurations: IAdjustRangeByMasspointConfiguration[], masspoints: IMassPointCriteriaItem[]) => {

    const allSelectedMPs = configurations.flatMap(x => x.selectedMasspoint ? [x.selectedMasspoint] : []);

    configurations.forEach(config => {
        const notSelectableMPs = allSelectedMPs.filter(x => !(x.name === config.selectedMasspoint?.name && x.type === config.selectedMasspoint?.type && x.bodyAreaId === config.selectedMasspoint?.bodyAreaId));
        config.selectableMasspoints = masspoints.filter(x => !notSelectableMPs.find(y => x.name === y.name && x.type === y.type && x.bodyAreaId === y.bodyAreaId));
    })
}