import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "app/redux_store";
import { handleResponseErrors } from "shared/networking/error_handling";
import CharacteristicTypeServiceAPI from '../../../../services/characteristic_type.service';
import CharacteristicCategoryServiceAPI from '../../../../services/characteristic_category.service';
import { createSaveRequest } from "./characteristic_category_create.reducer";

export const saveCharacteristicCategory = createAsyncThunk(
    'characteristic_category/characteristic_category_create/saveCharacteristicCategory',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.characteristic_categories_create.data;
        const response = await CharacteristicCategoryServiceAPI.createCharacteristicCategory(createSaveRequest(data.characteristicCategory));
        handleResponseErrors(response, "Ausprägungskategorie");
        return response;
    }
)

export const getCharacteristicTypes = createAsyncThunk(
    'characteristic_category/characteristic_category_create/getCharacteristicTypes',
    async (_) => {
        const response = await CharacteristicTypeServiceAPI.getCharacteristicTypes();
        handleResponseErrors(response, "Ausprägungstypen");
        return response;
     }
)
 
export const cancelSave = createAsyncThunk(
    'characteristic_category/characteristic_category_create/cancelSave',
    async (_) => { }
)

export const initData = createAsyncThunk(
    'characteristic_category/characteristic_category_create/initData',
    async (_, thunkApi) => {
        await Promise.all([
            thunkApi.dispatch(getCharacteristicTypes())
        ]);
     }
)