class HttpError extends Error {
    // we have to do the following because of: https://github.com/Microsoft/TypeScript/issues/13965
    // otherwise we cannot use instanceof later to catch a given type
    public __proto__: Error;

    public code: string;
    public subcode: string;  

    constructor(message?: string, code?: string ) {
      const trueProto = new.target.prototype;
 
      super(message);
  
      this.__proto__ = trueProto;
      this.code = code; 
    }
  }

  export default HttpError;