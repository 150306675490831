import { DialogComponent } from '../../../shared/components/dialogs/dialog.component';
import { cancelDeleteOneLeg } from '../redux/onelegs_overview.slice';
import { AppDispatch } from '../../../app/redux_store';
import { useAppDispatch, useAppSelector } from '../../../app/hook';
import { confirmDeleteOneLeg } from '../redux/onelegs_overview.thunks';

export const OneLegDeleteDialogComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.one_leg_overview);
    const oneLeg = state.actualData.selectedOneLeg;
    const oneLegText = `${oneLeg.mainProductLine.name} ${oneLeg.articleType.erpId} ${oneLeg.articleType.name}`;

    return (<>
        <DialogComponent
            title={"Einbeinhose löschen?"}
            isOpen={true}
            options={[
                { title: "Abbrechen", handleOption: () => { dispatch(cancelDeleteOneLeg()) } },
                { title: "Löschen", handleOption: () => { dispatch(confirmDeleteOneLeg()) } }
            ]}
            onClose={() => { }}>
            <div>
                Möchten Sie die Einbeinhose {oneLegText} löschen?
            </div>
        </DialogComponent>
    </>);
}