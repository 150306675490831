import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'app/redux_store';
import AdditionPositionService from 'services/addition_position.service';
import PositionCategoryService from 'services/position_category.service';
import BaseDataService from "../../../../services/base_data.service";
import { handleResponseErrors } from '../../../../shared/networking/error_handling';
import { buildSelectedDirectionRequests, buildSelectedExcludedArticleTypes } from './position_category.reducer';
import AdditionPositionCategoryService from 'services/position_category.service';
import PrepositionService from 'services/preposition.service';
import { setEditorMode } from './position_category.slice';
import { EditorModeEnum } from 'models/editors/editor_mode.enum';

export const fetchAllData = createAsyncThunk(
    'positionCategory/fetchAllData',
    async (positionCategoryId: string, thunkApi) => {
        await Promise.all([
            thunkApi.dispatch(getPrepositions()),
            thunkApi.dispatch(getBaseData()),
            thunkApi.dispatch(fetchAdditionPositions()),
        ]);
        if(positionCategoryId){
            thunkApi.dispatch(setEditorMode(EditorModeEnum.Edit));
            await thunkApi.dispatch(getPositionCategory(positionCategoryId));
        }
    }
)

export const fetchAdditionPositions = createAsyncThunk(
    'positionCategory/fetchAdditionPositions',
    async (thunkApi) => {
        const response = await AdditionPositionService.getAdditionPositionsOverview();
        handleResponseErrors(response, "Zusatzpositionen");
        return response;
    }
)

export const getPrepositions = createAsyncThunk(
    'positionCategory/getPrepositions',
    async () => {
        const response = await PrepositionService.getPrepositions();
        handleResponseErrors(response, "Präposition");
        return response;
    }
)

export const getBaseData = createAsyncThunk(
    'positionCategory/getBaseData',
    async (_) => {
        const response = await BaseDataService.getBaseData()
        handleResponseErrors(response, "Basisdaten");
        return response;
    }
)

export const getPositionCategory = createAsyncThunk(
    'positionCategory/getPositionCategory',
    async (positionCategoryId: string, _) => {
        const response = await AdditionPositionCategoryService.getPositionCategory(positionCategoryId)
        handleResponseErrors(response, "Positionskategorie");
        return response;
    }
)

export const updatePositionCategory = createAsyncThunk(
    'positionCategory/updatePositionCategory',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const data = state.position_category;
        const response = await PositionCategoryService.updatePositionCategory({
            id: data.loadedData.positionCategoryId,
            name: data.actualData.name,
            sideSeamAllowed: data.actualData.sideSeamAllowed,
            positionItems: data.actualData.positionCategoryItemSet.positionCategoryItems.map(x => {
                return {
                    isDocumentAllowed: x.isDocumentAllowed,
                    isDiameterAllowed: x.isDiameterAllowed,
                    diameterFrom: x.diameterFrom,
                    diameterTo: x.diameterTo,
                    isLengthAllowed: x.isLengthAllowed,
                    lengthFrom: x.lengthFrom,
                    lengthTo: x.lengthTo,
                    isWidthAllowed: x.isWidthAllowed,
                    widthFrom: x.widthFrom,
                    widthTo: x.widthTo,
                    displayAsPantyTop: x.isDisplayAsPantyTop,
                    sideSeamModeAllowed: data.actualData.sideSeamAllowed ? x.sideSeamModeAllowed : null,
                    selectedBodySides: x.bodySides.map(y => {
                        return {
                            bodySide: y
                        }
                    }),
                    selectedBodyAreas: x.bodyAreas.map(y => {
                        return {
                            bodyAreaId: y.id
                        }
                    }),
                    selectedDirectionRequests: buildSelectedDirectionRequests(x),
                    selectedExcludedArticleTypeIds: buildSelectedExcludedArticleTypes(x)
                }
            })
        });
        handleResponseErrors(response, "Positionskategorie");
        return response;
    }
)

export const createPositionCategory = createAsyncThunk(
    'positionCategory/createPositionCategory',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const data = state.position_category;
        const response = await PositionCategoryService.createPositionCategory({
            name: data.actualData.name,
            sideSeamAllowed: data.actualData.sideSeamAllowed,
            positionItems: data.actualData.positionCategoryItemSet.positionCategoryItems.map(x => {
                return {
                    isDocumentAllowed: x.isDocumentAllowed,
                    isDiameterAllowed: x.isDiameterAllowed,
                    diameterFrom: x.diameterFrom,
                    diameterTo: x.diameterTo,
                    isLengthAllowed: x.isLengthAllowed,
                    lengthFrom: x.lengthFrom,
                    lengthTo: x.lengthTo,
                    isWidthAllowed: x.isWidthAllowed,
                    widthFrom: x.widthFrom,
                    widthTo: x.widthTo,
                    displayAsPantyTop: x.isDisplayAsPantyTop,
                    sideSeamModeAllowed: data.actualData.sideSeamAllowed ? x.sideSeamModeAllowed : null,
                    selectedBodySides: x.bodySides.map(y => {
                        return {
                            bodySide: y
                        }
                    }),
                    selectedBodyAreas: x.bodyAreas.map(y => {
                        return {
                            bodyAreaId: y.id
                        }
                    }),
                    selectedDirectionRequests: buildSelectedDirectionRequests(x),
                    selectedExcludedArticleTypeIds: buildSelectedExcludedArticleTypes(x)
                }
            })
        });
        handleResponseErrors(response, "Positionskategorie");
        return response;
    }
)