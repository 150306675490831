import { IconButton, Grid } from '@mui/material';
import { ICountry } from 'models/country/country';
import SearchFieldComponent from 'shared/components/search/searchfield.component';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import * as style from "./addition_selectCountries.style";
import { PaperItem } from 'shared/style/paper';
import { MoveAllContainerStyled } from 'shared/components/selectionComponent/selectionList.style';
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { ToggleButtonComponent } from 'shared/components/buttons/toggle_button.component';
import { LockTypeEnum } from 'shared/components/selectionComponent/models/locktype.enum';

interface SelectCountriesComponentProps {
    unselectedCountries: ICountry[],
    selectedCountries: ICountry[],
    countryLockType: LockTypeEnum,
    onFilterUnselectedCountry: (searchText: string) => void;
    onFilterSelectedCountry: (searchText: string) => void;
    unselectCountries: (countries: ICountry[]) => {};
    selectCountries: (countries: ICountry[]) => {};
    allowCountry: () => {},
    denyCountry: () => {},
}

export const SelectCountriesComponent: React.FC<SelectCountriesComponentProps> = ({
    unselectedCountries,
    selectedCountries,
    countryLockType,
    onFilterUnselectedCountry,
    onFilterSelectedCountry,
    unselectCountries,
    selectCountries,
    allowCountry,
    denyCountry
}) => {

    const unselectedCountriesList = unselectedCountries.map((item, index) => {
        return (
            <div key={index}>
                <IconButton style={{ outline: "none" }} onClick={() => selectCountries([item])} >
                    <KeyboardArrowLeftIcon />
                </IconButton>
                {" "}
                {item.countryCode} {item.name}
            </div >
        )
    });

    const selectedCountriesList = selectedCountries.map((item, index) => {
        return (
            <div key={index}>
                <IconButton style={{ outline: "none" }} onClick={() => unselectCountries([item])}>
                    <KeyboardArrowRightIcon />
                </IconButton>
                {" "}
                {item.countryCode} {item.name}
            </div>
        )
    });

    const selectAll = () => {
        selectCountries(unselectedCountries);
    }

    const unSelectAll = () => {
        unselectCountries(selectedCountries);
    }

    const renderLockedCountries = () => {
        return <style.LockedCountryHeader>
            <style.LockedCountryHeadline>Ländersperre</style.LockedCountryHeadline>
            <style.ToggleWrapper>
                <style.ToggleInfo>Zusatz in den gewählten Ländern</style.ToggleInfo>
                <ToggleButtonComponent
                    enabledText="erlauben"
                    disabledText="verbieten"
                    isEnabled={countryLockType === LockTypeEnum.Allow}
                    onDisable={() => denyCountry()}
                    onEnable={() => allowCountry()}
                />
            </style.ToggleWrapper>
        </style.LockedCountryHeader>
    }

    return (
        <Grid
            container spacing={3}>
            <Grid item>
                {renderLockedCountries()}
                <PaperItem>
                    <Grid item container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        wrap="nowrap">
                        <Grid item md={6}>
                            <Grid
                                container
                                direction="column"
                                spacing={2}>
                                <Grid item>
                                    <strong>Ausgewählte Länder</strong>
                                </Grid>
                                <Grid item>
                                    <SearchFieldComponent
                                        onSearch={onFilterSelectedCountry}
                                        placeholder="In Ausgewählten suchen" />
                                </Grid>
                                <Grid item>
                                    <style.ListWrapper>
                                        {selectedCountriesList}
                                    </style.ListWrapper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <MoveAllContainerStyled>
                                <IconButton style={{ outline: "none" }} onClick={() => selectAll()}>
                                    <KeyboardDoubleArrowLeft />
                                </IconButton>
                                <IconButton style={{ outline: "none" }} onClick={() => unSelectAll()}>
                                    <KeyboardDoubleArrowRight />
                                </IconButton>
                            </MoveAllContainerStyled>
                        </Grid>
                        <Grid item md={6}>
                            <Grid
                                container
                                direction="column"
                                spacing={2}>
                                <Grid item>
                                    <strong>Verfügbare Länder</strong>
                                </Grid>
                                <Grid item>
                                    <SearchFieldComponent
                                        onSearch={onFilterUnselectedCountry}
                                        placeholder="In Verfügbaren suchen" />
                                </Grid>
                                <Grid item>
                                    <style.ListWrapper>
                                        {unselectedCountriesList}
                                    </style.ListWrapper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </PaperItem>
            </Grid>
        </Grid >
    );
}