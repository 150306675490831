import { doesExist } from "services/validation.service"
import { EvaluationState, SimulationState } from "./simulator_simulation.model"
import { SimulationTypeEnum } from "models/simulator/simulation_type.enum";
import { enqueueSnackbar } from "notistack";
import { ProcessTypeEnum } from "models/simulator/process_type.enum";
import { OfferTypeEnum } from "models/simulator/offer_type.enum";

export const switchSimulatorState = (targetState: EvaluationState, state: SimulationState) => {
    //console.log(" Switch state from ", state.simulation.evaluationState, " to ",targetState);
    if(targetState !== state.simulation.evaluationState) {
        switch(state.simulation.evaluationState) {
            case EvaluationState.presetSimulator:
                switchPreSimulator(targetState, state); 
                break;
            case EvaluationState.loadMassMask:
                switchLoadMassMask(targetState, state); 
                break;
            case EvaluationState.loadingMassMask:
                switchLoadingMassMask(targetState, state);
                break;
            case EvaluationState.loadedMassMask:
                switchLoadedMassMask(targetState, state);
                break;                
            case EvaluationState.reloadMassMask:
                switchReloadedMassMask(targetState, state);
                break;  
            case EvaluationState.evaluatingMassMask:
                switchEvaluatingMassMask(targetState, state);
                break;  
            case EvaluationState.evaluatedMassMask:
                switchEvaluatedMassMask(targetState, state);
                break;                              
            case EvaluationState.validatingMassMask:
                switchValidatingMassMask(targetState, state);
                break; 
            case EvaluationState.validatedMassMask:
                switchValidatedMassMask(targetState, state);
                break;         
            case EvaluationState.evaluateCustomDesign:
                switchEvaluateCustomDesign(targetState, state);
                break;
            case EvaluationState.evaluatingCustomDesign:
                switchEvaluatingCustomDesign(targetState, state);
                break;    
            case EvaluationState.evaluatedCustomDesign:
                switchEvaluatedCustomDesign(targetState, state);
                break;                   
            case EvaluationState.loadAdditions:
                switchLoadAdditions(targetState, state);
                break;                     
            case EvaluationState.loadingAdditions:
                switchLoadingAdditions(targetState, state);
                break;      
            case EvaluationState.loadedAdditions:
                switchLoadedAdditions(targetState, state);
                break;                                
            case EvaluationState.reloadAdditions:
                switchReloadAdditions(targetState, state);
                break;
            case EvaluationState.evaluatingAdditions:
                switchEvaluatingAdditions(targetState, state);
                break;
            case EvaluationState.evaluatedAdditions:
                switchEvaluatedAdditions(targetState, state);
                break;
            case EvaluationState.validatingAdditions:
                switchValidatingAdditions(targetState, state);
                break;
            case EvaluationState.validatedAdditions:
                switchValidatedAdditions(targetState, state);
                break;
            case EvaluationState.loadProductionInstructions:
                switchLoadProductionInstructions(targetState, state);
                break;     
            case EvaluationState.loadingProductionInstructions:
                switchLoadingProductionInstructions(targetState, state);
                break;                           
            case EvaluationState.loadedProductionInstructions:
                switchLoadedProductionInstructions(targetState, state);
                break;  
            case EvaluationState.reloadProductionInstructions:
                switchReloadProductionInstructions(targetState, state);
                break;  
            case EvaluationState.evaluatingProductionInstructions:
                switchEvaluatingProductionInstructions(targetState, state);
                break;
            case EvaluationState.evaluatedProductionInstructions:
                switchEvaluatedProductionInstructions(targetState, state);
                break;
            case EvaluationState.validatingProductionInstruction:
                switchValidatingProductionInstructions(targetState, state);
                break;
            case EvaluationState.validatedProductionInstruction:
                switchValidatedProductionInstructions(targetState, state);
                break;
            case EvaluationState.restoring:
                switchRestoring(targetState, state);
                break; 
            case EvaluationState.restoreMassMask:
                switchRestoreMassMask(targetState, state);
                break; 
            case EvaluationState.restoringMassMask:
                switchRestoringMassMask(targetState, state);
                break;     
            case EvaluationState.restoredMassMask:
                switchRestoredMassMask(targetState, state);
                break;                    
            case EvaluationState.restoreAdditions:
                switchRestoreAdditions(targetState, state);
                break; 
            case EvaluationState.restoringAdditions:
                switchRestoringAdditions(targetState, state);
                break;     
            case EvaluationState.restoredAdditions:
                switchRestoredAdditions(targetState, state);
                break;  
            case EvaluationState.restoreProductionInstructions:
                switchRestoreProductionInstructions(targetState, state);
                break; 
            case EvaluationState.restoringProductionInstructions:
                switchRestoringProductioInstructions(targetState, state);
                break;     
            case EvaluationState.restoredProductionInstructions:
                switchRestoredProductionInstructions(targetState, state);
                break;                  
            default:
                errorStateProhibited(targetState, state);
                break;
            }
    }
}

const switchPreSimulator = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.loadMassMask:
            toLoadMassMask(state);
            break;
        case EvaluationState.evaluateCustomDesign:
            toEvaluateCustomDesign(state);
            break;
        case EvaluationState.restoring:
            toRestoring(state);    
            break;
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchLoadMassMask = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState) {
        case EvaluationState.loadingMassMask:
            toLoadingMassMask(state);
            break
        case EvaluationState.evaluatingMassMask:
            toEvaluatingMassMask(state);
            break;            
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchLoadingMassMask = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState) {
        case EvaluationState.loadMassMask:
            toLoadMassMask(state);
            break; 
        case EvaluationState.loadedMassMask:
            toLoadedMassMask(state);
            break;
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchLoadedMassMask = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState) {
        case EvaluationState.loadMassMask:
            toLoadMassMask(state);
            break;
        case EvaluationState.evaluatingMassMask:
             toEvaluatingMassMask(state);
             break;
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchReloadedMassMask = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.loadingMassMask:
            toLoadingMassMask(state);
            break
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchEvaluatingMassMask = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.evaluatedMassMask:
            toEvaluatedMassMask(state);
            break;
        case EvaluationState.loadedMassMask:
            toLoadedMassMask(state);    
            break;
        case EvaluationState.loadMassMask:
            toLoadMassMask(state);    
            break;            
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchEvaluatedMassMask = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.validatingMassMask:
            toValidatingMassMask(state);
            break;
        case EvaluationState.loadAdditions:
            toLoadAdditions(state);
            break;            
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchValidatingMassMask = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.validatedMassMask:
            toValidatedMassMask(state);
            break;
        case EvaluationState.reloadMassMask:
            toReloadMassMask(state);
            break;    
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchValidatedMassMask = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.loadAdditions:
            toLoadAdditions(state);
            break;
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchEvaluateCustomDesign = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState) {
        case EvaluationState.evaluatingCustomDesign:
            toEvaluatingCustomDesign(state);
            break;
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchEvaluatingCustomDesign = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState) {
        case EvaluationState.evaluatedCustomDesign:
            toEvaluatedCustomDesign(state);
            break;
        case EvaluationState.evaluateCustomDesign:
            toEvaluateCustomDesign(state);
            break;    
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchEvaluatedCustomDesign = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState) {
        case EvaluationState.loadAdditions:
            toLoadAdditions(state);
            break;
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchLoadAdditions = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.loadingAdditions:
            toLoadingAdditions(state);
            break;
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchLoadingAdditions = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.loadMassMask:
            toLoadMassMask(state);
            break;
        case EvaluationState.loadedAdditions:
            toLoadedAdditions(state);
            break;
        case EvaluationState.loadAdditions:
            toLoadAdditions(state);
            break;    
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchLoadedAdditions = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.loadMassMask:
            toLoadMassMask(state);
            break;
        case EvaluationState.loadedMassMask:
            toLoadedMassMask(state);
            break;            
        case EvaluationState.evaluatingAdditions:
            toEvaluatingAdditions(state);
            break;
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchReloadAdditions = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.loadingAdditions:
            toLoadingAdditions(state);
            break;
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchEvaluatingAdditions = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.evaluatedAdditions:
            toEvaluatedAdditions(state);
            break;
        case EvaluationState.loadedAdditions:
            toLoadedAdditions(state);
            break;  
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchEvaluatedAdditions = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.validatingAdditions:
            toValidatingAdditions(state);
            break;
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchValidatingAdditions = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.reloadAdditions:
            toReloadAdditions(state);
            break;
        case EvaluationState.validatedAdditions:
            toValidatedAdditions(state);
            break;            
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchValidatedAdditions = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.loadProductionInstructions:
            toLoadProductionInstructions(state);
            break;
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchLoadProductionInstructions = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.loadingProductionInstructions:
            toLoadingProductionInstructions(state);
            break;
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchLoadingProductionInstructions = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.loadedProductionInstructions:
            toLoadedProductionInstructions(state);
            break;
        case EvaluationState.loadProductionInstructions:
            toLoadProductionInstructions(state);
            break;
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchLoadedProductionInstructions = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.loadMassMask:
            toLoadMassMask(state);
            break;
        case EvaluationState.loadedMassMask:
            toLoadedMassMask(state);
            break;            
        case EvaluationState.loadedAdditions:
            toLoadedAdditions(state);
            break;
        case EvaluationState.evaluatingProductionInstructions:
            toEvaluatingProductionInstructions(state)
            break;
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchReloadProductionInstructions = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.loadingProductionInstructions:
            toLoadingProductionInstructions(state);
            break;
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchEvaluatingProductionInstructions = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.evaluatedProductionInstructions:
            toEvaluatedProductionInstructions(state);
            break;
        case EvaluationState.loadedProductionInstructions:
            toLoadedProductionInstructions(state);
            break;
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchEvaluatedProductionInstructions = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.validatingProductionInstruction:
            toValidatingProductionInstructions(state);
            break;
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchValidatingProductionInstructions = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.reloadProductionInstructions:
            toReloadProductionInstructions(state);
            break;
        case EvaluationState.validatedProductionInstruction:
            toValidatedProductionInstructions(state);
            break;            
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchValidatedProductionInstructions = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.loadedMassMask:
            toLoadedMassMask(state);
            break;
        case EvaluationState.loadedAdditions:
            toLoadedAdditions(state);
            break;  
        case EvaluationState.loadedProductionInstructions:
            toLoadedProductionInstructions(state);
            break;                              
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchRestoring = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {      
        case EvaluationState.loadAdditions:
            toLoadAdditions(state);
            break;
        case EvaluationState.restoreMassMask:
            toRestoreMassMask(state);
            break;              
        case EvaluationState.restoreAdditions:
            toRestoreAdditions(state);
            break;                             
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchRestoreMassMask = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.restoringMassMask:
            toRestoringMassMask(state);
            break;                         
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchRestoringMassMask = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.restoredMassMask:
            toRestoredMassMask(state);
            break;  
        case EvaluationState.restoreMassMask:
            toRestoreMassMask(state);
            break;                                        
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchRestoredMassMask = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {        
        case EvaluationState.loadAdditions:
            toLoadAdditions(state);
            break;
        case EvaluationState.restoreMassMask:        
            toRestoreMassMask(state);
            break;    
        case EvaluationState.restoreAdditions:        
            toRestoreAdditions(state);
            break;                              
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchRestoreAdditions = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.restoringAdditions:
            toRestoringAdditions(state);
            break;                         
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchRestoringAdditions = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.restoredAdditions:
            toRestoredAdditions(state);
            break;  
        case EvaluationState.restoreAdditions:
            toRestoreAdditions(state);
            break;                                        
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchRestoredAdditions = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {        
        case EvaluationState.loadProductionInstructions:
            toLoadProductionInstructions(state);
            break;
        case EvaluationState.restoreProductionInstructions:        
            toRestoreProductionInstructions(state);
            break;                
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchRestoreProductionInstructions = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.restoringProductionInstructions:
            toRestoringProductionInstructions(state);
            break;                         
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchRestoringProductioInstructions = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {
        case EvaluationState.restoredProductionInstructions:
            toRestoredProductionInstructions(state);
            break;  
        case EvaluationState.restoreProductionInstructions:
            toRestoreProductionInstructions(state);
            break;                                        
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const switchRestoredProductionInstructions = (targetState: EvaluationState, state: SimulationState) => {
    switch(targetState)
    {        
        case EvaluationState.validatedProductionInstruction:
            toValidatedProductionInstructions(state);
            break;
        case EvaluationState.restoreProductionInstructions:        
            toRestoreProductionInstructions(state);
            break;                
        default:
            errorStateProhibited(targetState, state);
            break;
    }
}

const toLoadMassMask = (state: SimulationState) =>
{
    if(doesExist(state.simulation.selectedCountry)
        && doesExist(state.simulation.selectedCountry.id)
        && doesExist(state.simulation.selectedQuality)
        && doesExist(state.simulation.selectedQuality.id)
        && doesExist(state.simulation.selectedSide)
        && state.simulation.simulationType === SimulationTypeEnum.Mass) {
        state.simulation.evaluationState = EvaluationState.loadMassMask;
    }
}

const toEvaluateCustomDesign = (state: SimulationState) =>
{
    if(doesExist(state.simulation.selectedCountry)
        && doesExist(state.simulation.selectedCountry.id)
        && doesExist(state.simulation.selectedQuality)
        && doesExist(state.simulation.selectedQuality.id)
        && doesExist(state.simulation.selectedSide)
        && state.simulation.simulationType === SimulationTypeEnum.CustomDesign) {
        state.simulation.evaluationState = EvaluationState.evaluateCustomDesign;
    }
}

const toLoadingMassMask = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.loadingMassMask;
}

const toLoadedMassMask = (state: SimulationState) => {
     state.simulation.evaluationState = EvaluationState.loadedMassMask;
}

const toEvaluatingMassMask = (state: SimulationState) => {
    if((state.simulation.simulationType === SimulationTypeEnum.Mass && 
        state.simulation.processType === ProcessTypeEnum.Offer 
        && state.simulation.offerType === OfferTypeEnum.OfferWithoutMassmask)
        || state.simulation.evaluationState === EvaluationState.loadedMassMask
    ){
        state.simulation.evaluationState = EvaluationState.evaluatingMassMask;
    } else {
        enqueueSnackbar(`Ein Übergang von State ${state.simulation.evaluationState} nach State ${EvaluationState.evaluatingMassMask} ist mit der aktuellen Konfiguration nicht erlaubt.`, { variant: "warning" });
    }
    
}

const toEvaluatedMassMask = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.evaluatedMassMask;
}

const toValidatingMassMask = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.validatingMassMask;
}

const toValidatedMassMask = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.validatedMassMask;
}

const toReloadMassMask = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.reloadMassMask;
}

const toEvaluatingCustomDesign = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.evaluatingCustomDesign;;
}

const toEvaluatedCustomDesign = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.evaluatedCustomDesign;;
}

const toLoadAdditions = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.loadAdditions;
}

const toLoadingAdditions = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.loadingAdditions;
}

const toLoadedAdditions = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.loadedAdditions;
}

const toEvaluatingAdditions = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.evaluatingAdditions;
}

const toEvaluatedAdditions = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.evaluatedAdditions;
}

const toValidatingAdditions = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.validatingAdditions;
}

const toValidatedAdditions = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.validatedAdditions;
}

const toReloadAdditions = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.reloadAdditions;
}

const toLoadProductionInstructions = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.loadProductionInstructions;
}

const toLoadingProductionInstructions = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.loadingProductionInstructions;
}

const toLoadedProductionInstructions = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.loadedProductionInstructions;
}

const toEvaluatingProductionInstructions = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.evaluatingProductionInstructions;
}

const toEvaluatedProductionInstructions = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.evaluatedProductionInstructions;
}

const toValidatingProductionInstructions = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.validatingProductionInstruction;
}

const toReloadProductionInstructions = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.reloadProductionInstructions;
}

const toValidatedProductionInstructions = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.validatedProductionInstruction;
}

const toRestoring = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.restoring;
}

const toRestoreMassMask = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.restoreMassMask;
}

const toRestoringMassMask = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.restoringMassMask;
}

const toRestoredMassMask = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.restoredMassMask;
} 

const toRestoreAdditions = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.restoreAdditions;
}

const toRestoringAdditions = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.restoringAdditions;
}

const toRestoredAdditions = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.restoredAdditions;
} 

const toRestoreProductionInstructions = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.restoreProductionInstructions;
} 

const toRestoringProductionInstructions = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.restoringProductionInstructions;
} 

const toRestoredProductionInstructions = (state: SimulationState) => {
    state.simulation.evaluationState = EvaluationState.restoredProductionInstructions;
} 


const errorStateProhibited = (stateTo: EvaluationState, state: SimulationState) => {
    enqueueSnackbar(`Ein Übergang von State ${state.simulation.evaluationState} nach State ${stateTo} ist nicht implementiert.`, { variant: "warning" });
}