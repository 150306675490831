import { IPolicyOverview } from "models/policies/policy_overview";
import { PolicyOverviewState } from "./policy_overview.model";
import { PolicyTypeValues } from "models/policies/policy_type.enum";
import { filterPolicyValue } from "../policy_overview.helper";

export const filterPolicies = (state: PolicyOverviewState) => {
    const searchTextLower = state.actualData.searchFilter.toLocaleLowerCase().trim();
    let filteredItems = state.loadedData.policies.filter(
        (policy) =>{
                        const masspointType = PolicyTypeValues.find(x => x.value === policy?.policyType)?.text;

            return (
                policy.name.toLowerCase().includes(searchTextLower) ||
                masspointType?.toLowerCase().includes(searchTextLower));
        }
    );

    state.actualData.gridFilters.items.forEach(fil => {
        filteredItems = filterPolicyValue(fil, filteredItems);
    });
    
    state.actualData.policies = filteredItems.sort(sortPolicies);

}

export const sortPolicies = (mp1: IPolicyOverview, mp2: IPolicyOverview) => {
    return mp1.name.localeCompare(mp2.name);
}