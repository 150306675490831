import styled from "@emotion/styled";

export const CustomNoRowsOverlay: React.FC = () => {
  return (
    <NoRowsOverlay>Keine passenden Ergebnisse gefunden</NoRowsOverlay>
  )
}

export const NoRowsOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: white;
`;