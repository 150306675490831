import { ListButtonComponent } from "shared/components/buttons/list_button.component";
import { SelectionListComponent } from "shared/components/selectionComponent/selectionList.component";
import { DropdownComponent } from "shared/shared";
import { IMassPointCriteriaViewModel, ISelectCompressionClass, IUpdateThreshold, ISoftSeamConfigurationViewModel, ISearchQuality, ISelectQualities } from "./policy_soft_seam.model";
import { ErrorTextComponent } from "./policy_soft_seam_editor.style";
import { PolicySoftSeamConfigurationComponent } from "./policy_soft_seam_configuration.component";
import AddIcon from '@mui/icons-material/Add';
import { Grid } from "@mui/material";
import { IAdditionPolicyInformation } from "models/additions/addition_policy_information";

interface PolicySoftSeamEditor {
    selectedAddition: IAdditionPolicyInformation,
    allAdditions: IAdditionPolicyInformation[],
    masspointSelection: IMassPointCriteriaViewModel,
    configurations: ISoftSeamConfigurationViewModel[],
    select:(addition: IAdditionPolicyInformation) => void;
    addConfiguration: () => {},
    deleteConfiguration: (index: number) => {};
    filterSelectedMassPointCriterias: (value: string) => {},
    filterUnselectedMassPointCriterias: (value: string) => {},
    selectMassPointCriterias: (values: number[]) => {},
    unSelectMassPointCriterias: (values: number[]) => {},
    selectCompressionClass: (selectClass: ISelectCompressionClass) => {},
    updateThreshold: (textInput: IUpdateThreshold) => {},
    filterSelectedQualities: (qualities : ISearchQuality ) => {};
    filterUnselectedQualities: (qualities : ISearchQuality ) => {};
    selectQualities: (selectedQualities: ISelectQualities) => {};
    unSelectQualities: (selectedQualities: ISelectQualities) => {};
}

export const PolicySoftSeamEditorComponent: React.FC<PolicySoftSeamEditor> = ({ 
    selectedAddition, 
    allAdditions, 
    masspointSelection,
    configurations,
    select, 
    addConfiguration,
    deleteConfiguration,
    filterSelectedMassPointCriterias,
    filterUnselectedMassPointCriterias,
    selectMassPointCriterias,
    unSelectMassPointCriterias,
    selectCompressionClass,
    selectQualities,
    unSelectQualities,
    filterSelectedQualities,
    filterUnselectedQualities,
    updateThreshold
}) => {

    const renderMasspoints = <SelectionListComponent
        title={"Zu beachtende Maßpunkte"}
        entityName={"Maßpunkte"}
        onFilterSelected={(searchText) => { filterSelectedMassPointCriterias(searchText) }}
        onFilterUnselected={(searchText) => { filterUnselectedMassPointCriterias(searchText) }}
        selectionList={ masspointSelection}
        selectEntities={(ids) => { selectMassPointCriterias(ids) }}
        unselectEntities={(ids) => { unSelectMassPointCriterias(ids) }}
        />

    return (
        <Grid item container md={12}
            direction="column"
            spacing={3}>
            <Grid item md={12}>
                <DropdownComponent name="Zusatz"
                    data={allAdditions}
                    minWidth="350px"
                    isRequired={true}
                    onSelect={(e) => select(e)}
                    displayedProperties={["option", "description"]}
                    selectedValue={selectedAddition}                           
                />
            </Grid>
            <Grid item>
                {renderMasspoints}
            </Grid>
            {configurations ? configurations.map(x => {
                return (<Grid key={x.index}
                 item>
                    <PolicySoftSeamConfigurationComponent 
                        configuration={x}
                        deleteConfiguration={deleteConfiguration}
                        selectCompressionClass={selectCompressionClass}
                        updateThreshold={updateThreshold}
                        selectQualities={selectQualities}
                        unSelectQualities={unSelectQualities}
                        filterSelectedQualities={filterSelectedQualities}
                        filterUnselectedQualities={filterUnselectedQualities}
                    />
                </Grid>);
            }) : <></>} 
            <Grid item>
                <ListButtonComponent
                    onClick={addConfiguration}>
                    <AddIcon /> Konfiguration hinzufügen
                </ListButtonComponent>                   
            </Grid>   
            { allAdditions.length === 0 ?
                <ErrorTextComponent>
                    Es kann kein Zusatz zugewiesen werden.
                </ErrorTextComponent> : <></>
            }
        </Grid>
    );
};