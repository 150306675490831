import React from 'react';
import { IconButton, Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { AppDispatch } from '../../app/redux_store';
import { TextBoxComponent } from 'shared/shared';
import * as style from "./rule_placeholder.style";
import { addRuleConstantPlaceholder, deleteRuleConstantPlaceholder, changedRuleConstantPlaceholder } from '../redux/rules_formula.slice';
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ListButtonComponent } from "shared/components/buttons/list_button.component";

interface RuleConstantPlaceholderComponentProps {
    ruleIndex: number;
}

export const RuleConstantPlaceholderComponent: React.FC<RuleConstantPlaceholderComponentProps> = ({ ruleIndex }) => {
    const dispatch: AppDispatch = useAppDispatch();

    const state = useAppSelector(store => store.rules_formula);
    const rule = state.actualData.rules[ruleIndex];
    const placerholderValidated = state.actualData.placerholderConstantValidated;

    return (
        <>
            <Grid container
                direction='row'
                md={12}>
                {rule.formulaRuleConstantPlaceholderVersions.map((ruleConstantPlaceholder) => (
                    <Grid item
                        container
                        direction="row"
                        alignItems="center"
                        alignContent='center'
                        style={{ marginTop: "5px" }}
                        md={12}
                        spacing={3}>
                        <Grid item
                            alignContent="center">
                            <style.PlaceholderText>#{ruleConstantPlaceholder.placeholderName}</style.PlaceholderText>
                        </Grid>
                        <Grid item
                            direction="column">
                            {ruleConstantPlaceholder.isValid || !placerholderValidated ?
                                <TextBoxComponent name="Text - Konstante"
                                    value={ruleConstantPlaceholder.value}
                                    onChange={(value) =>
                                        dispatch(changedRuleConstantPlaceholder({
                                            ruleIndex: ruleIndex,
                                            placeholderName: ruleConstantPlaceholder.placeholderName,
                                            value: value
                                        }))}
                                    isRequired={true}
                                    isAutoFocus={false}
                                />
                                :
                                <TextBoxComponent name="Text - Konstante"
                                    value={ruleConstantPlaceholder.value}
                                    onChange={(value) =>
                                        dispatch(changedRuleConstantPlaceholder({
                                            ruleIndex: ruleIndex,
                                            placeholderName: ruleConstantPlaceholder.placeholderName,
                                            value: value
                                        }))}
                                    isRequired={true}
                                    isAutoFocus={false}               
                                    errorText="Es muss ein wert ausgefüllt sein"
                                />
                            }
                        </Grid>
                        <Grid item>
                            <IconButton
                                style={{ outline: "none", marginLeft: "15px" }}
                                onClick={() => dispatch(deleteRuleConstantPlaceholder({ ruleIndex: ruleIndex, placeholderName: ruleConstantPlaceholder.placeholderName }))}>

                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}

                <Grid item
                    style={{ marginTop: "15px" }}
                    md={6}
                    spacing={3}>
                    <ListButtonComponent
                        onClick={() => dispatch(addRuleConstantPlaceholder(ruleIndex))}>
                        <AddIcon /> Text-Konstante hinzufügen
                    </ListButtonComponent>
                </Grid>
            </Grid>
        </>)
}