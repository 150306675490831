import { useAppDispatch, useAppSelector } from '../../app/hook';
import { AppDispatch } from '../../app/redux_store';
import { useEffect } from "react";
import styled from "@emotion/styled";
import { LoadingIndicatorComponent } from '../../shared/components/loading_indicator/loading_indicator.component';
import { useSnackbar } from "notistack";
import { fetchChecktypeHistoryOverviews } from './redux/checktype_history.thunks';
import { FlexBox, Left } from 'shared/shared';
import SearchFieldComponent from 'shared/components/search/searchfield.component';
import { resetState, setGridFilters, setSearchFilter } from './redux/checktype_history.slice';
import { getGridColumns } from './checktype_history.helper';
import { OverviewDatagrid } from 'shared/components/datagrid/overview_datagrid.component';

export const TableContainer = styled.div`
  margin-top: 20px;
`;

export const CheckTypeHistoryComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.checktypes_history_overview);

    useEffect(() => {
        dispatch(fetchChecktypeHistoryOverviews());
        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    const { enqueueSnackbar } = useSnackbar();

    if (state.query.fetchChecktypeHistoryOverviews.status === "error") {
        enqueueSnackbar(state.query.fetchChecktypeHistoryOverviews.message, { variant: "error" });
        return <div>Fehler beim Laden der Daten</div>;
    }

    if (state.query.fetchChecktypeHistoryOverviews.status !== "success") {
        return <LoadingIndicatorComponent />;
    }

    const checktypeHistoryOverviewsAvailable = state.loadedData.checktypeHistoryOverviews.length > 0;

    return (
        <div>
            <FlexBox>
                {
                    checktypeHistoryOverviewsAvailable &&
                    <Left>
                        <SearchFieldComponent onSearch={(filterText) => dispatch(setSearchFilter(filterText))} placeholder="In Historie suchen ..." />
                    </Left>
                }
            </FlexBox>
            <TableContainer>
                {
                    !checktypeHistoryOverviewsAvailable &&
                    <div>Keine Historie verfügbar</div>
                }
                {
                    checktypeHistoryOverviewsAvailable &&
                    <OverviewDatagrid
                        isLoading={false}
                        columns={getGridColumns()}
                        rows={state.actualData.checktypeHistoryOverviews}
                        loadedRowsCount={state.loadedData.checktypeHistoryOverviews.length}
                        onFilterModelChange={(newFilterModel) => dispatch(setGridFilters(newFilterModel))}
                    />
                }
            </TableContainer>
        </div>
    )
}