import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from "@mui/material";
import { doesExist } from 'services/validation.service';

interface IMenuOverviewComponentProps {
    outline?: string;
    onMenuOpen: (e: any, parameter: any)  => void;
    menuItem: any;
    color?: string
  }  

export const MenuOverviewComponent: React.FC<IMenuOverviewComponentProps> = ({ outline, onMenuOpen, menuItem, color }) => {
    const outlineStyle = doesExist(outline) ? outline : "none";
    return (
        <IconButton style={{ color: color, outline: outlineStyle}} onClick={(e) => onMenuOpen(e, menuItem)}>
            <MoreVertIcon />
        </IconButton>        
    )
}


