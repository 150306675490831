import { getConfig } from "./config.service";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";

const appConfig = getConfig();

const config: Configuration = {
  auth: {
    authority: `https://login.microsoftonline.com/${appConfig.authTenantId}`,
    clientId: appConfig.authClientAppId,
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

export const authenticationParameters = {
  scopes: [
    "openid",
    "profile",
    "offline_access",
    `api://${appConfig.authServerAppId}/access_as_user`,
  ],
};

export const publicClientApplication = await PublicClientApplication.createPublicClientApplication(config);
