import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BodyInformationOverviewState } from "./bodyinformation_overview.model";
import { confirmDeleteBodyInformation, createBodyInformation, getBodyInformations, updateBodyInformation } from "./bodyinformation_overview.thunks";
import { filterBodyInformations } from "./bodyinformation_overview.reducer";
import { GridFilterModel } from "@mui/x-data-grid-pro";
import IBodyInformationOverview from "models/bodyinformation/bodyinformation_overview";

const initialState: BodyInformationOverviewState = {
    actualData: {
        bodyInformations: [],
        searchFilter: "",
        gridFilter: { items: [] },
        activeBodyInformation: undefined,
        showDeleteDialog: false,
    },
    loadedData: {
        bodyInformations: []
    },
    query: {
        getBodyInformations: { status: "idle", canExecute: true },
    },
    command: {
        createBodyInformation: { status: "idle", canExecute: false },
        updateBodyInformation: { status: "idle", canExecute: false },
        confirmDeleteBodyInformation: { status: "idle", canExecute: false },
    }
}

export const bodyInformationOverviewSlice = createSlice({
    name: 'massMask/bodyInformations/overview',
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.actualData.searchFilter = action.payload;
            filterBodyInformations(state);
        },
        setGridFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.gridFilter = action.payload;
            filterBodyInformations(state);
        },
        resetDeleteBodyInformation: (state) => {
            state.command.confirmDeleteBodyInformation = initialState.command.confirmDeleteBodyInformation;
        },
        deleteBodyInformation: (state, action: PayloadAction<IBodyInformationOverview>) => {
            state.actualData.activeBodyInformation = action.payload;
            state.actualData.showDeleteDialog = true;
        },
        cancelDeleteBodyInformation: (state) => {
            state.actualData.activeBodyInformation = null;
            state.actualData.showDeleteDialog = false;
        },
    }, extraReducers: (builder) => {
        // createBodyInformationNavigation
        builder.addCase(createBodyInformation.pending, (state, action) => {
            state.command.createBodyInformation.status = 'pending'
        }).addCase(createBodyInformation.fulfilled, (state, action) => {
            state.command.createBodyInformation.status = "success"

            //getBodyInformations
        }).addCase(getBodyInformations.pending, (state, action) => {
            state.query.getBodyInformations.status = 'pending'
        }).addCase(getBodyInformations.rejected, (state, action) => {
            state.query.getBodyInformations.status = "error"
            state.query.getBodyInformations.message = action.error.message;
        }).addCase(getBodyInformations.fulfilled, (state, action) => {
            state.query.getBodyInformations.status = "success"
            state.loadedData.bodyInformations = action.payload.getData();
            filterBodyInformations(state);

            //navigate to updateBodyInformation
        }).addCase(updateBodyInformation.pending, (state, action) => {
            state.command.updateBodyInformation.status = 'pending'
        }).addCase(updateBodyInformation.fulfilled, (state, action) => {
            state.actualData.activeBodyInformation = action.payload;
            state.command.updateBodyInformation.status = "success"

            // confirmDeleteBodyInformation
        }).addCase(confirmDeleteBodyInformation.pending, (state, action) => {
            state.command.confirmDeleteBodyInformation.status = 'pending'
        }).addCase(confirmDeleteBodyInformation.rejected, (state, action) => {
            state.command.confirmDeleteBodyInformation.status = "error"
            state.command.confirmDeleteBodyInformation.message = action.error.message;
            state.actualData.activeBodyInformation = null;
            state.actualData.showDeleteDialog = false;
        }).addCase(confirmDeleteBodyInformation.fulfilled, (state, action) => {
            state.command.confirmDeleteBodyInformation.status = "success"
            state.loadedData.bodyInformations = action.payload.getData();
            filterBodyInformations(state);
            state.actualData.activeBodyInformation = null;
            state.actualData.showDeleteDialog = false;
        })
    }
})

export const {
    resetState,
    setSearchFilter,
    setGridFilters,
    resetDeleteBodyInformation,
    cancelDeleteBodyInformation,
    deleteBodyInformation
} = bodyInformationOverviewSlice.actions

export default bodyInformationOverviewSlice.reducer