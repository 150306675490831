import { SelectionListComponent } from "shared/components/selectionComponent/selectionList.component";
import { DropdownComponent } from "shared/shared";
import { Grid } from "@mui/material";
import { IAdditionPolicyInformation } from "models/additions/addition_policy_information";
import { ISelectionList } from "shared/components/selectionComponent/models/selectionList";

interface PolicyDefaultAdditionEditor {
    selectedAddition: IAdditionPolicyInformation,
    allAdditions: IAdditionPolicyInformation[],
    articleTypeSelection: ISelectionList,
    selectAddition:(addition: IAdditionPolicyInformation) => void;
    filterSelectedArticleTypes: (text : string ) => {};
    filterUnselectedArticleTypes: (text : string ) => {};
    selectArticleTypes: (value: number[]) => {};
    unSelectArticleTypes: (value: number[]) => {};
}

export const PolicyDefaultAdditionEditorComponent: React.FC<PolicyDefaultAdditionEditor> = ({ 
    selectedAddition, 
    allAdditions, 
    articleTypeSelection,
    selectAddition, 
    filterSelectedArticleTypes,
    filterUnselectedArticleTypes,
    selectArticleTypes,
    unSelectArticleTypes,
}) => {

    const renderArticleTypes = <SelectionListComponent
        title={"Zu beachtende Artikelarten"}
        entityName={"Artikelarten"}
        onFilterSelected={(searchText) => { filterSelectedArticleTypes(searchText) }}
        onFilterUnselected={(searchText) => { filterUnselectedArticleTypes(searchText) }}
        selectionList={ articleTypeSelection}
        selectEntities={(ids) => { selectArticleTypes(ids) }}
        unselectEntities={(ids) => { unSelectArticleTypes(ids) }}
        />

    return (
        <Grid item container md={12}
            direction="column"
            spacing={3}>
            <Grid container item md={12} spacing={3}
                direction="column">
                <Grid item>
                    <DropdownComponent name="Zusatz"
                        data={allAdditions}
                        minWidth="350px"
                        isRequired={true}
                        onSelect={(e) => selectAddition(e)}
                        displayedProperties={["option", "description"]}
                        selectedValue={selectedAddition}                                                   
                    />
                </Grid>
            </Grid>
            <Grid item
                direction="column">
                {renderArticleTypes}
            </Grid>
        </Grid>
    );
};