import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MassImageConfigurationUpdateState } from './update_config.model';
import { updateCanUploadConfigFile } from './update_config.reducer';
import { updateMassimageConfig } from './update_config.thunks';

const initialState: MassImageConfigurationUpdateState = {
    loadedData: {
        imageId: 0,
    },
    actualData: {
        configFile: {
            file: null,
        },
    },
    command: {
        updateConfigFile: { status: "idle", canExecute: false },
        uploadConfigFile: { status: "idle", canExecute: false },
    },
}

export const updateMassImageConfigSlice = createSlice({
    name: 'update_massimage_config',
    initialState,
    reducers: {
        initCurrentMassImage: (state, action: PayloadAction<number>) => {
            state.loadedData.imageId = action.payload;
        },
        resetState: (state) => {
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
        },
        updateConfigFile: (state, action: PayloadAction<File>) => {
            state.actualData.configFile.file = action.payload;
            updateCanUploadConfigFile(state);
        },
        updateConfigFileErrorCompleted: (state) => {
            state.command.updateConfigFile.status = "idle";
        },
        resetUpdateConfigFile: (state) => {
            state.command.uploadConfigFile.status = "idle";
            state.command.updateConfigFile.status = "idle";
        }
    }, extraReducers: (builder) => {
        //updateConfigFile
        builder.addCase(updateMassimageConfig.pending, (state, action) => {
            state.command.updateConfigFile.status = "pending"
        }).addCase(updateMassimageConfig.rejected, (state, action) => {
            state.command.updateConfigFile.status = "error"
        }).addCase(updateMassimageConfig.fulfilled, (state, action) => {
            state.command.updateConfigFile.status = "success"
        })
    }
})

export const {
    initCurrentMassImage,
    resetState,
    resetUpdateConfigFile,
    updateConfigFile,
    updateConfigFileErrorCompleted
} = updateMassImageConfigSlice.actions

export default updateMassImageConfigSlice.reducer