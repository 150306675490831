import { PolicySoftSeamCreateState } from "./policy_soft_seam_create.model";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    cancelSave, 
    getAdditions, 
    getMassPoints,
    getQualities,
    savePolicy, 
    initData, 
    getArticleAttributeCategories
} from "./policy_soft_seam_create.thunks";
import { 
    createMasspointCriteriaList, 
    moveMasspointFromSelectedToUnselected, 
    moveMasspointFromUnselectedToSelected, 
    filterMasspoints,
    calculateAvailableCompressionClasses,
    updateCanSave, 
    createQualityCriterias,
    filterQualities,
    moveQualityFromSelectedToUnselected,
    moveQualityFromUnselectedToSelected,
    createCompressionClass,
} from "./policy_soft_seam_create.reducer";
import { MasspointTypeEnum } from "models/masspoints/enums/masspoint_type.enum";
import { ISearchQuality, ISelectCompressionClass, ISelectQualities, ISoftSeamConfigurationViewModel, IUpdateThreshold } from "../../components/policy_soft_seam.model";
import { IAdditionPolicyInformation } from "models/additions/addition_policy_information";
import { sortAdditions } from "check/policy/helper/policy.helper";
import { distinctMassPointCriteria } from "shared/helpers/distinctMassPointCriteria";

const initialState: PolicySoftSeamCreateState = {
    data: { 
        policy: { name: "", ignoreOnQuotation: false},
        selectedAddition: null,
        massPointCriterias: {
            selectedList: {
                allItems: [],
                filteredItems: [],
                searchText: ""
                
            },
            unSelectedList: {
                allItems: [],
                filteredItems: [],
                searchText: ""
                
            },
        },
        configurations: [],
    },
    loadedData: {
        additions: [],
        masspoints: [],
        qualities: [],
        compressionClasses: [],
    },
    command:{
        savePolicy: { status: "idle", canExecute: false },
        cancelSave: { status: "idle", canExecute: true }
    },
    query: {
        fetchAdditions: { status: "idle", canExecute: true },
        fetchMasspoints: { status: "idle", canExecute: true },
        fetchQualities: { status: "idle", canExecute: true },  
        fetchCompressionClass: { status: "idle", canExecute: true },               
        initData: { status: "idle", canExecute: true }
    }
}

export const createPolicySoftSeam = createSlice({
    name: 'create_policy_soft_seam',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = initialState.data;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        changeName: (state, action: PayloadAction<string>) => {
            state.data.policy.name = action.payload;
            updateCanSave(state);
        },
        selectAddition: (state, action: PayloadAction<IAdditionPolicyInformation>) => {
            state.data.selectedAddition = action.payload;
            updateCanSave(state);
        },
        selectMassPointCriterias: (state, action: PayloadAction<number[]>) => {
            moveMasspointFromUnselectedToSelected(state, action.payload);
            updateCanSave(state);
        },      
        unselectMassPointCriterias: (state, action: PayloadAction<number[]>) => {
            moveMasspointFromSelectedToUnselected(state, action.payload);
            updateCanSave(state);
        },      
        filterSelectedMassPointCriterias: (state, action: PayloadAction<string>) => {
            state.data.massPointCriterias.selectedList.searchText = action.payload;
            filterMasspoints(state.data.massPointCriterias.selectedList);
        },
        filterUnselectedMassPointCriterias: (state, action: PayloadAction<string>) => {
            state.data.massPointCriterias.unSelectedList.searchText = action.payload;
            filterMasspoints(state.data.massPointCriterias.unSelectedList);
        },  
        addConfiguration: (state) => {
            const nextIndex = state.data.configurations.length + 1;
            let newSoftConfigurations = [];
            state.data.configurations.forEach(x => {
                newSoftConfigurations.push(x);
            });

            const configuration : ISoftSeamConfigurationViewModel = {
                index: nextIndex,
                compressionClasses: createCompressionClass(state),
                selectedCompressionClass: null,
                threshold: 0.0,
                qualities: {
                    selectedList: {
                        allItems: [],
                        filteredItems:[],
                        searchText: ""
                    },
                    unSelectedList: createQualityCriterias(state.loadedData.qualities)
                }
            };
            filterQualities(configuration.qualities.selectedList);
            filterQualities(configuration.qualities.unSelectedList);
            newSoftConfigurations.push(configuration);
            state.data.configurations = calculateAvailableCompressionClasses(newSoftConfigurations, state.loadedData.compressionClasses);
            updateCanSave(state);
        },  
        selectQuality: (state, action: PayloadAction<ISelectQualities>) => {
            const index = action.payload.index - 1;
            const configuration = state.data.configurations[index];
            moveQualityFromUnselectedToSelected(configuration, action.payload.qualityIds);
            updateCanSave(state);
        },      
        unselectQuality: (state, action: PayloadAction<ISelectQualities>) => {
            const index = action.payload.index - 1;
            const configuration = state.data.configurations[index];
            moveQualityFromSelectedToUnselected(configuration, action.payload.qualityIds);
            updateCanSave(state);
        },      
        filterSelectedQualities: (state, action: PayloadAction<ISearchQuality>) => {
            const index = action.payload.index - 1;
            const configuration = state.data.configurations[index];
            configuration.qualities.selectedList.searchText = action.payload.searchText;
            filterQualities(configuration.qualities.selectedList);
        },
        filterUnselectedQualities: (state, action: PayloadAction<ISearchQuality>) => {
            const index = action.payload.index - 1;
            const configuration = state.data.configurations[index];
            configuration.qualities.unSelectedList.searchText = action.payload.searchText;
            filterQualities(configuration.qualities.unSelectedList);
        },  
        deleteConfiguration: (state, action: PayloadAction<number>) => {
            let nextIndex = 1;
            const deletedItemIndex = action.payload;
            let newSoftConfigurations = [];
            state.data.configurations.forEach(x => {
                if(x.index !== deletedItemIndex)
                {
                    x.index = nextIndex;
                    newSoftConfigurations.push(x);
                    nextIndex++;
                }
            });
            state.data.configurations = calculateAvailableCompressionClasses(newSoftConfigurations, state.loadedData.compressionClasses);
            updateCanSave(state);
        },
        selectCompressionClass: (state, action: PayloadAction<ISelectCompressionClass>) => {
            const index = action.payload.index - 1;
            const configuration = state.data.configurations[index];
            configuration.selectedCompressionClass = action.payload.item;
            state.data.configurations = calculateAvailableCompressionClasses(state.data.configurations, state.loadedData.compressionClasses);
            updateCanSave(state);
        },      
        updateThreshold: (state, action: PayloadAction<IUpdateThreshold>) => {
            const index = action.payload.index - 1;
            const configuration = state.data.configurations[index];
            configuration.threshold = action.payload.threshold;
            updateCanSave(state);
        },      
        toggleIgnoreOnQuotation: (state, action: PayloadAction<boolean>) => {
            state.data.policy.ignoreOnQuotation = action.payload;
            updateCanSave(state);
        },                              
        completedSave: (state) => {
            state.command.savePolicy = initialState.command.savePolicy;
        }
    }, extraReducers: (builder) => {

        // fetchAdditions
        builder.addCase(getAdditions.pending, (state) => {
            state.query.fetchAdditions.status = "pending"
            state.query.fetchAdditions.canExecute = false;
        }).addCase(getAdditions.rejected, (state, action) => {
            state.query.fetchAdditions.status = "error"
            state.query.fetchAdditions.message = action.error.message;
            state.query.fetchAdditions.canExecute = true;
        }).addCase(getAdditions.fulfilled, (state, action) => {
            state.query.fetchAdditions.status = "success"
            state.query.fetchAdditions.canExecute = true;
            state.loadedData.additions = action.payload.getData().sort(sortAdditions);
            updateCanSave(state);

        // fetchQualities
        }).addCase(getQualities.pending, (state) => {
            state.query.fetchQualities.status = "pending"
            state.query.fetchQualities.canExecute = false;
        }).addCase(getQualities.rejected, (state, action) => {
            state.query.fetchQualities.status = "error"
            state.query.fetchQualities.message = action.error.message;
            state.query.fetchQualities.canExecute = true;
        }).addCase(getQualities.fulfilled, (state, action) => {
            state.query.fetchQualities.status = "success"
            state.query.fetchQualities.canExecute = true;
            state.loadedData.qualities = action.payload.getData().availableQualities;            

            // fetchMasspoints
        }).addCase(getMassPoints.pending, (state) => {
            state.query.fetchMasspoints.status = "pending"
            state.query.fetchMasspoints.canExecute = false;
        }).addCase(getMassPoints.rejected, (state, action) => {
            state.query.fetchMasspoints.status = "error"
            state.query.fetchMasspoints.message = action.error.message;
            state.query.fetchMasspoints.canExecute = true;
        }).addCase(getMassPoints.fulfilled, (state, action) => {
            state.query.fetchMasspoints.status = "success"
            state.query.fetchMasspoints.canExecute = true;
            const masspoints = action.payload.getData();  
            state.loadedData.masspoints = masspoints;
            state.data.massPointCriterias = {
                unSelectedList: createMasspointCriteriaList(masspoints
                    .filter(distinctMassPointCriteria)
                    .filter(x => x.massPointType === MasspointTypeEnum.Circumference)),
                selectedList: {
                    allItems: [],
                    filteredItems: [],
                    searchText: ""
                },
            };    
            filterMasspoints(state.data.massPointCriterias.selectedList);
            filterMasspoints(state.data.massPointCriterias.unSelectedList);
   
            // fetchCompressionClass
        }).addCase(getArticleAttributeCategories.pending, (state) => {
            state.query.fetchCompressionClass.status = "pending"
            state.query.fetchCompressionClass.canExecute = false;
        }).addCase(getArticleAttributeCategories.rejected, (state, action) => {
            state.query.fetchCompressionClass.status = "error"
            state.query.fetchCompressionClass.message = action.error.message;
            state.query.fetchCompressionClass.canExecute = true;
        }).addCase(getArticleAttributeCategories.fulfilled, (state, action) => {
            state.query.fetchCompressionClass.status = "success"
            state.query.fetchCompressionClass.canExecute = true;
            const compressionClasses = action.payload.getData().filter(x => x.erpId == "CompressionClass")
                .flatMap(x => x.availableAdditionAttributes).map(x => { return {
                    id: x.id,
                    name: x.name,
                    code: x.code
                }});  
            state.loadedData.compressionClasses = compressionClasses;  

        // savePolicy
        }).addCase(savePolicy.pending, (state) => {
            state.command.savePolicy.status = 'pending'
            state.command.savePolicy.canExecute = false;
        }).addCase(savePolicy.rejected, (state, action) => {
            state.command.savePolicy.status = "error"
            state.command.savePolicy.canExecute = true;
            state.command.savePolicy.message = action.error.message;
        }).addCase(savePolicy.fulfilled, (state) => {
            state.command.savePolicy.status = "success"
            state.command.savePolicy.canExecute = false;

        // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancelSave.status = 'pending'
            state.command.cancelSave.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancelSave.status = "success"
            state.command.cancelSave.canExecute = false;

            // initData
        }).addCase(initData.pending, (state) => {
            state.query.initData.status = "pending"
            state.query.initData.canExecute = false;
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.canExecute = true;
            state.query.initData.message = action.error.message;
        }).addCase(initData.fulfilled, (state) => {
            state.query.initData.status = "success"
            state.query.initData.canExecute = true;
            updateCanSave(state);

        })
    }
})

export const {
    resetState,
    changeName,
    addConfiguration,
    deleteConfiguration,
    filterSelectedMassPointCriterias,
    filterUnselectedMassPointCriterias,
    selectAddition,
    selectMassPointCriterias,
    unselectMassPointCriterias,
    selectQuality,
    unselectQuality,
    filterSelectedQualities,
    filterUnselectedQualities,    
    completedSave,
    selectCompressionClass,
    updateThreshold,
    toggleIgnoreOnQuotation
} = createPolicySoftSeam.actions

export default createPolicySoftSeam.reducer