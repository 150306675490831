import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/redux_store';
import BaseDataService from '../../../services/base_data.service';
import { FootOptionService } from '../../../services/footoption.service';
import MassPointService from '../../../services/masspoint.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';

export const fetchAllData = createAsyncThunk(
    'massMasks/footOption/create/fetchAllData',
    async (_, thunkApi) => {
        await Promise.all([
            thunkApi.dispatch(getFootTypes()),
            thunkApi.dispatch(getMainProductLines()),
            thunkApi.dispatch(getMassPoints()),
        ]);
    }
)

export const getFootTypes = createAsyncThunk(
    'massMasks/footOption/create/getFootTypes',
    async (_) => {
        const response = await BaseDataService.getFootTypes();
        handleResponseErrors(response, "Fußarten");
        return response;
    }
)

export const getMainProductLines = createAsyncThunk(
    'massMasks/footOption/create/getMainProductLines',
    async (_) => {
        const response = await BaseDataService.getMainProductLines();
        handleResponseErrors(response, "Hauptproduktlinien");
        return response;
    }
)

export const getMassPoints = createAsyncThunk(
    'massMasks/footOption/create/getMassPoints',
    async (_, { getState }) => {
        const response = await MassPointService.getMassPoints()
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'massMasks/footOption/create/cancelSave',
    async (_) => { }
)

export const createFootOption = createAsyncThunk(
    'massMasks/footOption/create/createFootOption',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const selectedData = state.foot_options_create.actualData;

        const response = await FootOptionService.createFootOption({
            footTypeId: selectedData.selectedFootType.id,
            mainProductLineId: selectedData.selectedProductLine.id,
            massPoints: selectedData.massPointCriteria.selected.allItems.map(item => {
                return {
                    name: item.massPointName,
                    type: item.massPointType,
                    bodyAreaId: item.bodyArea.id,
                };
            })
        });

        handleResponseErrors(response, "Fußoption");
        return response;
    }
)