import { filterMasspointGroupValue } from '../masspoint_group_overview.helper';
import { MasspointGroupOverviewState } from './masspoint_group_overview.model';

export const filterMasspointGroupOverviews = (state: MasspointGroupOverviewState) => {
    const searchTextLower = state.actualData.searchFilter.toLowerCase().trim();
    let filteredItems = state.loadedData.masspointGroups.filter(
        (masspointGroup) => {
            return (masspointGroup.identifier.toLowerCase().includes(searchTextLower) ||
                masspointGroup.nameTranslationKey.toLowerCase().includes(searchTextLower) ||
                masspointGroup.nameTranslation.toLowerCase().includes(searchTextLower));
        }
    );

    state.actualData.gridFilters.items.forEach(fil => {
        filteredItems = filterMasspointGroupValue(fil, filteredItems);
    });

    state.actualData.masspointGroups = filteredItems;
}