import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from "./config.service";
import { IOneLegCreateModel } from '../models/onelegs/oneLeg_create';
import { IOneLegOverview} from '../models/onelegs/oneLeg_overview';
import { IOneLegEditModel, IUpdateOneLegModel } from "models/onelegs/oneLeg_edit";
import HttpServiceCommonResult from "shared/networking/http_service_common_result";
import { CommonApiResult } from "shared/networking/common_api_result";

class OneLegServiceAPI extends HttpServiceCommonResult {
    public async getOneLegs(): Promise<ApiResult<Array<IOneLegOverview>>> {
      return this.get<Array<IOneLegOverview>>("/api/oneleg");
    }

    public async createOneLeg(oneLeg: IOneLegCreateModel): Promise<ApiResult<void>> {
      return this.post<IOneLegCreateModel,void>("/api/oneleg", oneLeg);
    }

    public async deleteOneLeg(id: number): Promise<ApiResult<void>> {
      return this.delete(`/api/oneleg/${id}`);
    }

    public async getOneLeg(id: string): Promise<ApiResult<IOneLegEditModel>>{
      return this.get(`/api/oneleg/${id}`);
    }

    public async isOneLeg(mainproductlineErpId: string, articletypeErpId): Promise<ApiResult<boolean>>{
      return this.get(`/api/oneleg/isoneleg/${mainproductlineErpId}/${articletypeErpId}`);
    }

    public async updateOneLeg(oneLeg: IUpdateOneLegModel): Promise<ApiResult<void>> {
      return this.put<IUpdateOneLegModel,void>("/api/oneleg", oneLeg);
    }
}
  
const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
export const OneLegService = new OneLegServiceAPI(TimeoutMilliseconds);