import { RuleTypeEnum, RuleTypeEnumValues } from '../../models/plausibility_check/enums/rule_type.enum';
import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, Grid } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { removeRule, selectMassPointCriteria, selectRuleType, updateFormula } from '../redux/rules_formula.slice';
import { MasspointTypeEnumValues } from '../../models/masspoints/enums/masspoint_type.enum';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { AppDispatch } from '../../app/redux_store';
import { MassPointCriteria } from '../../models/plausibility_check/masspoint_criteria';
import { MasspointBodySideEnumValuesLookup } from '../../models/masspoints/enums/body_side.enum';
import { DropdownComponent } from 'shared/shared';
import { SuggestionTextBox } from 'shared/components/form_controls/suggestion_text_box/suggestion_text_box';
import { doesExist } from '../../services/validation.service';
import { RulePlaceholderListComponent } from './rule_placeholder_list.component';
 
interface RuleElementComponentProps {
    ruleIndex: number;
}

export const RuleElementComponent: React.FC<RuleElementComponentProps> = ({ ruleIndex }) => {
    const dispatch: AppDispatch = useAppDispatch();

    const rule = useAppSelector(store => store.rules_formula.actualData.rules[ruleIndex]);
    const massPointCriteriaSuggestion = useAppSelector(store => store.rules_formula.massPointCriteriaSuggestions);
    const massPointCriteria = useAppSelector(store => store.rules_formula.actualData.massPointCriteria);
    const checkType = useAppSelector(store => store.rules_formula.loadedData.check);
    const ruleTypeValues = RuleTypeEnumValues
        .filter(r => r.value !== RuleTypeEnum.Both)
        .map(r => r.value);

    const [menuAnchor, setMenuAnchor] = useState(null);

    const handleToggleMenu = (event) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleDeleteRule = () => {
        dispatch(removeRule(ruleIndex));
        handleMenuClose();
    }

    const handleMenuClose = () => {
        setMenuAnchor(null);
    }

    const renderMassPointCriteriaEntry = (entry: MassPointCriteria): string => {
        const massPointTypeText = MasspointTypeEnumValues.find(massPointType => massPointType.value === entry.massPointType).text
        return checkType.isSideSpecific ? `${entry.massPointName} - ${massPointTypeText} - ${MasspointBodySideEnumValuesLookup(entry.massPointBodySide)}` : `${entry.massPointName} - ${massPointTypeText} `;
    }

    const renderRuleType = (entry: RuleTypeEnum): string => {
        return RuleTypeEnumValues.find(rt => rt.value === entry).text;
    }
    return (
        <>
            <Grid container
                direction='row'>
                    <Grid item
                        direction='row'
                        md={1}> 
                        <div>
                            <IconButton
                                edge="start"
                                size="small"
                                style={{ outline: 'none', marginRight: '0.4em' }}
                                aria-label="MoreVert"
                                aria-controls="delete-menu"
                                aria-haspopup="true"
                                onClick={handleToggleMenu}>
                                <MoreVertIcon />
                            </IconButton>

                            <Menu
                                id="context-menu"
                                anchorEl={menuAnchor}
                                keepMounted
                                open={Boolean(menuAnchor)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={handleDeleteRule}>Löschen</MenuItem>
                            </Menu>
                        </div>
                    </Grid>
                    <Grid container
                        md={11}
                        item
                        direction="column">
                            <Grid container
                                item
                                alignItems="flex-end"
                                direction="row">
                                <Grid item
                                    md={3}
                                    style={{ paddingBottom: "7px"}}>
                                    <DropdownComponent
                                        name="Typ"
                                        data={ruleTypeValues}
                                        isLoading={false}
                                        isRequired={true}
                                        onSelect={(ruleType) => dispatch(selectRuleType({ ruleIndex, selectedRuleType: ruleType }))}
                                        renderEntry={renderRuleType}
                                        selectedValue={rule.ruleType}
                                    />
                                </Grid>
                                <Grid item
                                    md={3}
                                    style={{ paddingBottom: "7px"}}
                                    >
                                    <DropdownComponent
                                        name="Maßpunkt Kriterien"
                                        data={massPointCriteria}
                                        isLoading={false}
                                        isRequired={true}
                                        onSelect={(masspoint) => dispatch(selectMassPointCriteria({ ruleIndex, selectedMassPointCriteria: masspoint }))}
                                        renderEntry={renderMassPointCriteriaEntry}
                                        selectedValue={rule.massPointCriteria}
                                    />
                                </Grid>
                                <Grid item
                                    md={6}
                                    >
                                    {doesExist(rule.ruleType)
                                        ? <SuggestionTextBox
                                            data={massPointCriteriaSuggestion.suggestions}
                                            value={rule.formula}
                                            onChange={(_event, _newValue, newPlainTextValue, _mentions) => {
                                                dispatch(updateFormula({ ruleIndex, formula: newPlainTextValue }));
                                            }}
                                            placeholder="Formel" />
                                        : <></>}
                                </Grid>    
                            </Grid>   
                            <Grid container
                                item
                                alignItems="flex-end"
                                direction="row">
                                <Grid item
                                    md={3}/>
                                <Grid item
                                    md={9}>
                                        <RulePlaceholderListComponent  ruleIndex={ruleIndex}/>
                                </Grid>   
                            </Grid>                  
                    </Grid>
            </Grid>   
        </>)   
}