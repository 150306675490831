import { useNavigate, useParams } from "react-router-dom";
import { LoadingOverlayComponent, LoadingOverlayContainer } from "../../../shared/components/loading_overlay/loading_overlay.component";
import { ProductionInstructionEditorComponent } from "../components/production_instruction_editor.component";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hook";
import { AppDispatch } from "../../../app/redux_store";
import { useSnackbar } from "notistack";
import { intiData } from "./redux/production_instruction.thunks";
import { resetState } from "./redux/production_instruction.slice";
import { LoadingIndicatorComponent } from "../../../shared/components/loading_indicator/loading_indicator.component";
import { getProductionInstructionEditorActions, handleCommandAndQueryStatus } from "./production_instruction.helper";
import { ProductionInstructionDialogsComponent } from "../components/production_instruction_dialogs.component";

export const ProductionInstructionComponent = () => {
    const { id } = useParams();
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.production_instruction)
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(intiData(id));
        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    if (state.query.getProductionInstructionCalingaKeys.status === "error") {
        return <div>Fehler beim Laden der Daten</div>;
    }

    if (state.query.initData.status !== "success") {
        return <LoadingIndicatorComponent/>;
    }

    handleCommandAndQueryStatus(state,dispatch,navigate,enqueueSnackbar)

    const isLoadingOverlay = state.command.updateProductionInstruction.status === "pending" || state.command.createProductionInstruction.status === "pending";

    return (<>
        <LoadingOverlayContainer>
            {isLoadingOverlay && <LoadingOverlayComponent/>}
            <ProductionInstructionDialogsComponent/>
            <ProductionInstructionEditorComponent
                canSave={state.command.updateProductionInstruction.canExecute}
                productionInstructionEditor={state.actualData.productionInstructionEditor}
                mappedBaseData={state.loadedData.mappedBaseData}
                calingaKeys={state.loadedData.calingaKeys}
                productionInstructionEditorActions={getProductionInstructionEditorActions(state,dispatch,navigate)}
                editMode={true}
            />
        </LoadingOverlayContainer>
    </>)
}