import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OneLegEditState } from './oneleg_edit.model';
import { applyFilter, canExecuteEditOneLeg, distinctMassPointCriteria, moveFromAvailableToSelected, moveFromSelectedToAvailable, removeAlreadySelectedFootmassPoints, updateCanExecuteEdit } from './oneleg_edit.reducer';
import { cancelSave, fetchAllData, getMassPoints, getOneLeg, updateOneLeg } from './oneleg_edit.thunks';


const initialState: OneLegEditState = {
    loadedData: {
        productLines: [],
        articleTypes: [],
        massPointCriteria: {
            selected: {
                searchText: "",
                allItems: [],
                filteredItems: [],
            },
            available: {
                searchText: "",
                allItems: [],
                filteredItems: [],
            },
            loaded: {
                allItems: [],
            }
        }
    }, 
    actualData: {
        oneLegId: null,
        selectedProductLine: null,
        selectedArticleType: null,
        massPointCriteria: {
            selected: {
                searchText: "",
                allItems: [],
                filteredItems: [],
            },
            available: {
                searchText: "",
                allItems: [],
                filteredItems: [],
            },
            loaded: {
                allItems: [],
            }
        }
    },
    query: {
        fetchAllData: { status: "idle", canExecute: true },
        getMassPoints: { status: "idle", canExecute: true },
        getOneLeg: {status: "idle", canExecute: true },
    },
    command: {
        editOneLeg: { status: "idle", canExecute: false },
        cancel: { status: "idle", canExecute: false },
    }
}

export const oneLegEditSlice = createSlice({
    name: 'massMasks/oneLeg/edit',
    initialState,
    reducers: {
        resetState: (state) => {
            state = initialState;
        },
        resetEditOneLeg: (state) => {
            state.command.editOneLeg = {
                ...initialState.command.editOneLeg,
                canExecute: canExecuteEditOneLeg(state)
            };
        },
        filterSelectedMassPointCriteria: (state, action: PayloadAction<string>) => {
            state.actualData.massPointCriteria.selected.searchText = action.payload;
            applyFilter(state.actualData.massPointCriteria.selected);
        },
        filterAvailableMassPointCriteria: (state, action: PayloadAction<string>) => {
            state.actualData.massPointCriteria.available.searchText = action.payload;
            applyFilter(state.actualData.massPointCriteria.available);
        },
        selectMassPointCriteria: (state, action: PayloadAction<number>) => {
            moveFromAvailableToSelected(state, action.payload);
            applyFilter(state.actualData.massPointCriteria.selected);
            updateCanExecuteEdit(state);
        },
        deselectMassPointCriteria: (state, action: PayloadAction<number>) => {
            moveFromSelectedToAvailable(state, action.payload);
            applyFilter(state.actualData.massPointCriteria.available);
            updateCanExecuteEdit(state);
        },
    }, extraReducers: (builder) => {
            //fetchAllData
        builder.addCase(fetchAllData.pending, (state, action) => {
            state.query.fetchAllData.status = 'pending';
        }).addCase(fetchAllData.rejected, (state, action) => {
            state.query.fetchAllData.status = "error";
            state.query.fetchAllData.message = action.error.message;
        }).addCase(fetchAllData.fulfilled, (state, action) => {
            state.query.fetchAllData.status = "success";
            
            removeAlreadySelectedFootmassPoints(state.actualData.massPointCriteria.selected, state.actualData.massPointCriteria.available);
            
            state.command.editOneLeg.canExecute = canExecuteEditOneLeg(state);

            //getMasspoints
        }).addCase(getMassPoints.pending, (state, action) => {
            state.query.getMassPoints.status = 'pending';
        }).addCase(getMassPoints.rejected, (state, action) => {
            state.query.getMassPoints.status = "error";
            state.query.getMassPoints.message = action.error.message;
        }).addCase(getMassPoints.fulfilled, (state, action) => {
            state.query.getMassPoints.status = "success";
            state.query.getMassPoints.canExecute = true;
            const massPoints = action.payload.getData();

            const massPointCriteria = massPoints.map(mp => {
                return {
                    massPointName: mp.name,
                    massPointType: mp.massPointType,
                    bodyArea: mp.bodyArea
                }
            }).filter(distinctMassPointCriteria);

            state.actualData.massPointCriteria.available.allItems = massPointCriteria;
            state.actualData.massPointCriteria.loaded.allItems = massPointCriteria;
            applyFilter(state.actualData.massPointCriteria.available);

            // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancel.status = 'pending'
            state.command.cancel.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancel.status = "success"
            state.command.cancel.canExecute = false;

            //getOneLeg
        }).addCase(getOneLeg.pending, (state, action) => {
            state.query.getOneLeg.status = 'pending';
        }).addCase(getOneLeg.rejected, (state, action) => {
            state.query.getOneLeg.status = "error";
            state.query.getOneLeg.message = action.error.message;
        }).addCase(getOneLeg.fulfilled, (state, action) => {
            state.query.getOneLeg.status = "success";
            const oneLeg = action.payload.getData();

            state.actualData.oneLegId = oneLeg.id;
            state.actualData.selectedProductLine = oneLeg.mainProductLine;
            state.actualData.selectedArticleType = oneLeg.articleType;

            const massPointCriteria = oneLeg.oneLegMassPointVersions.map(mp => {
                return {
                    massPointName: mp.name,
                    massPointType: mp.type,
                    bodyArea: mp.bodyArea
                }
            }).filter(distinctMassPointCriteria);

            state.loadedData.massPointCriteria.selected.allItems = massPointCriteria;
            state.actualData.massPointCriteria.selected.allItems = massPointCriteria;
            applyFilter(state.actualData.massPointCriteria.selected);

            //updateOneLeg
        }).addCase(updateOneLeg.pending, (state, action) => {
            state.command.editOneLeg.status = 'pending';
            state.command.editOneLeg.canExecute = false;
        }).addCase(updateOneLeg.rejected, (state, action) => {
            state.command.editOneLeg.status = "error";
            state.command.editOneLeg.canExecute = true;
            state.command.editOneLeg.message = action.error.message;
        }).addCase(updateOneLeg.fulfilled, (state, action) => {
            state.command.editOneLeg.status = "success";
            state.command.editOneLeg.canExecute = true;
        })
    }
})

export const {
    resetState,
    resetEditOneLeg,
    filterSelectedMassPointCriteria,
    filterAvailableMassPointCriteria,
    selectMassPointCriteria,
    deselectMassPointCriteria
} = oneLegEditSlice.actions

export default oneLegEditSlice.reducer