import { Paper, PaperProps } from "@mui/material";
import { materialTheme } from "./theme";

export const PaperItem = (props: PaperProps) => {
    const { sx, ...other } = props;
    return (
        <Paper
        sx={{
            padding: materialTheme.spacing(2),
            color: materialTheme.palette.text.secondary,        
            ...sx,
        }}
        {...other}
        />
    );
}

export const PaperContentFitItem = (props: PaperProps) => {
    const { sx, ...other } = props;
    return (
        <Paper
        sx={{
            padding: materialTheme.spacing(2),
            color: materialTheme.palette.text.secondary,      
            width: 'fit-content',  
            ...sx,
        }}
        {...other}
        />
    );
}