import { createSlice } from '@reduxjs/toolkit';
import { BasedataImportsState } from './basedataimports.model';
import { createAndFetchBasedataImport, fetchAllBasedataImports } from './basedataimports.thunks';

const initialState: BasedataImportsState = {
    data: {
        imports: [],
    },
    query: {
        fetchImports: { status: "idle", canExecute: true },
    },
    command: {
        createImport: { status: "idle", canExecute: false },
    },
}

export const basedataImportsSlice = createSlice({
    name: 'admin/basedataimports',
    initialState,
    reducers: {
        resetState: (state) => {
            state.command = initialState.command;
            state.data = initialState.data;
            state.query = initialState.query;
        },
        displayedCreateMessage: (state) => {
            state.command.createImport.status = "idle";
        },
        displayedFetchMessage: (state) => {
            state.query.fetchImports.status = "idle";
        },
    }, extraReducers: (builder) => {

        // fetch imports
        builder.addCase(fetchAllBasedataImports.pending, (state, action) => {
            state.query.fetchImports.status = "pending"
            state.query.fetchImports.canExecute = false;
        }).addCase(fetchAllBasedataImports.rejected, (state, action) => {
            state.query.fetchImports.status = "error"
            state.query.fetchImports.message = action.error.message;
            state.query.fetchImports.canExecute = true;
        }).addCase(fetchAllBasedataImports.fulfilled, (state, action) => {
            state.query.fetchImports.status = "success"
            state.query.fetchImports.message = undefined;
            state.query.fetchImports.canExecute = true;
            state.data.imports = action.payload.getData();
            state.command.createImport.canExecute = true;

            // create import
        }).addCase(createAndFetchBasedataImport.pending, (state, action) => {
            state.command.createImport.status = 'pending'
        }).addCase(createAndFetchBasedataImport.rejected, (state, action) => {
            state.command.createImport.status = "error"
            state.command.createImport.message = action.error.message;

            if (action.error.code === "409") {
                state.command.createImport.status = "warning"
            }
        }).addCase(createAndFetchBasedataImport.fulfilled, (state, action) => {
            state.command.createImport.status = "success"
            state.command.createImport.canExecute = true;
            state.data.imports = action.payload.getData();
        })
    }
})

export const {
    resetState,
    displayedCreateMessage,
    displayedFetchMessage,
} = basedataImportsSlice.actions

export default basedataImportsSlice.reducer