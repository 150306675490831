import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CharacteristicTypeEditState } from "./characteristic_type_edit.model";
import { initializeData, updateCanSave } from "./characteristic_type_edit.reducer";
import { cancelSave, getCharacteristicType, getCharacteristicValueTranslation, initData, updateCharacteristicType } from "./characteristic_type_edit.thunks";
import { IIndexedValueChanged } from "additions/characteristic_type/components/characteristic_type_editor.model";
import CommonHttpError from "shared/networking/common_http_error";
import { Errordefinitions } from "models/error/error_definitions";
import { CommonApiResultError } from "shared/networking/common_api_result";

const initialState: CharacteristicTypeEditState = {
    data: {
        characteristicType: {
            name: "",
            shortKey: "",
            values: []
        }
    },
    errorData: {
        error: null,
        showDeleteErrorDialog: false
    },
    command: {
        updateCharacteristicType: { status: "idle", canExecute: false },
        cancelSave: { status: "idle", canExecute: true }
    },
    query: {
        initData: { status: "idle", canExecute: true },
        fetchCharacteristicType: { status: "idle", canExecute: true },
        fetchCharacteristicValueTranslations: { status: "idle", canExecute: true },
    },
    loadedData: {
        characteristicType: undefined,
        characteristicValueTranslations: [],
    }
}

export const updateCharacteristicTypeSlice = createSlice({
    name: 'update_characteristic_type',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = initialState.data;
            state.command = initialState.command;
            state.query = initialState.query;
            state.errorData = initialState.errorData;

        },
        addValue: (state) => {
            state.data.characteristicType.values.push({
                name: "",
                translationKey: ""
            });
            updateCanSave(state);
        },
        changeName: (state, action: PayloadAction<string>) => {
            state.data.characteristicType.name = action.payload;
            updateCanSave(state);
        },
        changeShortKey: (state, action: PayloadAction<string>) => {
            state.data.characteristicType.shortKey = action.payload;
            updateCanSave(state);
        },
        changeValueName: (state, action: PayloadAction<IIndexedValueChanged>) => {
            const payLoad = action.payload;
            state.data.characteristicType.values[payLoad.index].name = payLoad.value;
            updateCanSave(state);
        },
        changeValueKey: (state, action: PayloadAction<IIndexedValueChanged>) => {
            const payLoad = action.payload;
            state.data.characteristicType.values[payLoad.index].translationKey = payLoad.value;
            updateCanSave(state);
        },
        deleteValue: (state, action: PayloadAction<number>) => {
            state.data.characteristicType.values.splice(action.payload, 1);
            updateCanSave(state);
        },
        completedSave: (state) => {
            state.command.updateCharacteristicType = initialState.command.updateCharacteristicType;
        }
    }, extraReducers: (builder) => {
        // updateCharacteristicType
        builder.addCase(updateCharacteristicType.pending, (state) => {
            state.command.updateCharacteristicType.status = 'pending'
            state.command.updateCharacteristicType.canExecute = false;
        }).addCase(updateCharacteristicType.rejected, (state, action) => {
            state.command.updateCharacteristicType.status = "error"
            state.command.updateCharacteristicType.canExecute = true;
            const commonError = action.payload as CommonApiResultError;
            if (commonError) {
                if (commonError.errorSubCode === Errordefinitions.CHARACTERISTICTYPE_USED) {
                    state.errorData.showDeleteErrorDialog = true;
                    state.errorData.error = commonError;
                }
                state.command.updateCharacteristicType.message = commonError.message;
            } else {
                state.command.updateCharacteristicType.message = action.error.message;
            }
        }).addCase(updateCharacteristicType.fulfilled, (state) => {
            state.command.updateCharacteristicType.status = "success"
            state.command.updateCharacteristicType.canExecute = false;

            // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancelSave.status = 'pending'
            state.command.cancelSave.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancelSave.status = "success"
            state.command.cancelSave.canExecute = false;

            // getCharacteristicType
        }).addCase(getCharacteristicType.pending, (state) => {
            state.query.fetchCharacteristicType.status = "pending"
            state.query.fetchCharacteristicType.canExecute = false;
        }).addCase(getCharacteristicType.rejected, (state, action) => {
            state.query.fetchCharacteristicType.status = "error"
            state.query.fetchCharacteristicType.message = action.error.message;
            state.query.fetchCharacteristicType.canExecute = true;
        }).addCase(getCharacteristicType.fulfilled, (state, action) => {
            state.query.fetchCharacteristicType.status = "success"
            state.query.fetchCharacteristicType.canExecute = true;
            state.loadedData.characteristicType = action.payload.getData();

            // getCharacteristicValueTranslations
        }).addCase(getCharacteristicValueTranslation.pending, (state) => {
            state.query.fetchCharacteristicValueTranslations.status = "pending"
            state.query.fetchCharacteristicValueTranslations.canExecute = false;
        }).addCase(getCharacteristicValueTranslation.fulfilled, (state, action) => {
            state.query.fetchCharacteristicValueTranslations.status = "success"
            state.query.fetchCharacteristicValueTranslations.canExecute = true;
            state.loadedData.characteristicValueTranslations = action.payload.getData();

            // initData
        }).addCase(initData.pending, (state) => {
            state.query.initData.status = "pending"
            state.query.initData.canExecute = false;
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.canExecute = true;
            state.query.initData.message = action.error.message;
        }).addCase(initData.fulfilled, (state) => {
            state.query.initData.status = "success"
            state.query.initData.canExecute = true;
            state.data.characteristicType = initializeData(state.loadedData.characteristicType);
            updateCanSave(state);
        })
    }
})

export const {
    resetState,
    addValue,
    changeName,
    changeShortKey,
    changeValueName,
    changeValueKey,
    completedSave,
    deleteValue
} = updateCharacteristicTypeSlice.actions

export default updateCharacteristicTypeSlice.reducer