import { DropdownComponent } from "shared/shared";
import { Grid } from "@mui/material";
import { IAdditionPolicyInformation } from "models/additions/addition_policy_information";
import { IMassPointCriteriaItem } from "check/policy/components/policy_editor.model";

interface PolicyExcludeAdditionEditor {
    selectedAddition: IAdditionPolicyInformation,
    selectedMasspoint1: IMassPointCriteriaItem,
    selectedMasspoint2: IMassPointCriteriaItem,
    allAdditions: IAdditionPolicyInformation[],
    allMasspoints1: IMassPointCriteriaItem[],
    allMasspoints2: IMassPointCriteriaItem[],
    selectAddition:(addition: IAdditionPolicyInformation) => void;
    selectMasspoint1:  (masspoint: IMassPointCriteriaItem) => void;
    selectMasspoint2:  (masspoint: IMassPointCriteriaItem) => void;
}

export const PolicyExcludeAdditionEditorComponent: React.FC<PolicyExcludeAdditionEditor> = ({ 
    selectedAddition, 
    selectedMasspoint1,
    selectedMasspoint2,
    allAdditions, 
    allMasspoints1,
    allMasspoints2,
    selectAddition, 
    selectMasspoint1,
    selectMasspoint2,
}) => {

    return (
        <Grid item container md={12}
            direction="column"
            spacing={3}>
            <Grid container item md={12} spacing={3}
                direction="column">
                <Grid item>
                    <DropdownComponent name="Zusatz"
                        data={allAdditions}
                        minWidth="350px"
                        isRequired={true}
                        onSelect={(e) => selectAddition(e)}
                        displayedProperties={["option", "description"]}
                        selectedValue={selectedAddition}                                                   
                    />
                </Grid>
            </Grid>
            <Grid container item md={12} spacing={3}
                direction="row">
                <Grid item>
                    <DropdownComponent
                        name="1. Maßpunkt"
                        data={allMasspoints1}
                        minWidth="350px"
                        isLoading={false}
                        isRequired={true}
                        onSelect={selectMasspoint1}
                        displayedProperties={["displayName"]}
                        selectedValue={selectedMasspoint1}
                    />
                </Grid>
                <Grid item>
                    <DropdownComponent
                        name="2. Maßpunkt"
                        data={allMasspoints2}
                        minWidth="350px"
                        isLoading={false}
                        isRequired={true}
                        onSelect={selectMasspoint2}
                        displayedProperties={["displayName"]}
                        selectedValue={selectedMasspoint2}
                    />                        
                </Grid>                 
            </Grid>
        </Grid>
    );
};