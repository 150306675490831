import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IIndexedMasspoint, IIndexedNumber, IIndexedSearchText, PolicyAdjustMaximumRangeCreateState } from "./policy_adjust_maximum_range_create.model";
import { cancelSave, getAdditions, getBaseData, getMassPoints, initData, savePolicy } from "./policy_adjust_maximum_range_create.thunks";
import { sortAdditions } from "check/policy/helper/policy.helper";
import { IAdditionPolicyInformation } from "models/additions/addition_policy_information";
import { addArticleTypeToAllConfigurations, createMasspointItemList, createNewConfiguration, removeArticleTypeFromAllConfigurations, updateCanSave } from "./policy_adjust_maximum_range_create.reducer";
import { IIndexedIds } from "check/policy/policy_adjust_ranges_by_masspoint/components/policy_adjust_ranges_by_masspoint_configuration.model";
import { filterSelectionList, moveFromSelectedToUnselected, moveFromUnselectedToSelected } from "shared/components/selectionComponent/selectionList.helper";

const initialState: PolicyAdjustMaximumRangeCreateState = {
    data: {
        policy: { name: "", ignoreOnQuotation: false },
        selectedAddition: null,
        configurations: []
    },
    loadedData: {
        additions: [],
        masspoints: [],
        articleTypes: [],
    },
    command: {
        savePolicy: { status: "idle", canExecute: false },
        cancelSave: { status: "idle", canExecute: true }
    },
    query: {
        fetchAdditions: { status: "idle", canExecute: true },
        fetchMasspoints: { status: "idle", canExecute: true },
        fetchBaseData: { status: "idle", canExecute: true },
        initData: { status: "idle", canExecute: true }
    }
}

export const createPolicyAdjustMaximumRange = createSlice({
    name: 'create_policy_adjust_second_range',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = initialState.data;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        changeName: (state, action: PayloadAction<string>) => {
            state.data.policy.name = action.payload;
            updateCanSave(state);
        },
        selectAddition: (state, action: PayloadAction<IAdditionPolicyInformation>) => {
            state.data.selectedAddition = action.payload;
            updateCanSave(state);
        },
        toggleIgnoreOnQuotation: (state, action: PayloadAction<boolean>) => {
            state.data.policy.ignoreOnQuotation = action.payload;
            updateCanSave(state);
        },
        addConfiguration: (state) => {
            const configuration = createNewConfiguration(state);
            filterSelectionList(configuration.articleTypes.selectedList);
            filterSelectionList(configuration.articleTypes.unSelectedList);
            state.data.configurations = [...state.data.configurations, configuration];
            updateCanSave(state);
        },
        deleteConfiguration: (state, action: PayloadAction<number>) => {
            const configToRemove = state.data.configurations[action.payload];
            const articleTypesToAdd = configToRemove.articleTypes.selectedList.allItems.map(x => x.id);
            addArticleTypeToAllConfigurations(state, articleTypesToAdd);

            state.data.configurations = state.data.configurations.filter((_, index) => index !== action.payload);
        },
        selectMasspoint1: (state, action: PayloadAction<IIndexedMasspoint>) => {
            state.data.configurations[action.payload.index].selectedMasspoint1 = action.payload.masspoint;
            updateCanSave(state);
        },
        selectMasspoint2: (state, action: PayloadAction<IIndexedMasspoint>) => {
            state.data.configurations[action.payload.index].selectedMasspoint2 = action.payload.masspoint;
            updateCanSave(state);
        },
        changeMultiplyValue: (state, action: PayloadAction<IIndexedNumber>) => {
            const configuration = state.data.configurations[action.payload.index];
            configuration.multiplyValue = action.payload.value;
            updateCanSave(state);
        },
        selectArticleTypes: (state, action: PayloadAction<IIndexedIds>) => {
            const configuration = state.data.configurations[action.payload.index];
            moveFromUnselectedToSelected(configuration.articleTypes, action.payload.ids);
            removeArticleTypeFromAllConfigurations(state, action.payload.ids);
            updateCanSave(state);
        },
        unSelectArticleTypes: (state, action: PayloadAction<IIndexedIds>) => {
            const configuration = state.data.configurations[action.payload.index];
            moveFromSelectedToUnselected(configuration.articleTypes, action.payload.ids);
            addArticleTypeToAllConfigurations(state, action.payload.ids);
            updateCanSave(state);
        },
        filterSelectedArticleTypes: (state, action: PayloadAction<IIndexedSearchText>) => {
            const configuration = state.data.configurations[action.payload.index];
            configuration.articleTypes.selectedList.searchText = action.payload.searchText;
            filterSelectionList(configuration.articleTypes.selectedList);
        },
        filterUnselectedArticleTypes: (state, action: PayloadAction<IIndexedSearchText>) => {
            const configuration = state.data.configurations[action.payload.index];
            configuration.articleTypes.unSelectedList.searchText = action.payload.searchText;
            filterSelectionList(configuration.articleTypes.unSelectedList);
        },
        completedSave: (state) => {
            state.command.savePolicy = initialState.command.savePolicy;
        }
    }, extraReducers: (builder) => {

        // fetchAdditions
        builder.addCase(getAdditions.pending, (state) => {
            state.query.fetchAdditions.status = "pending"
            state.query.fetchAdditions.canExecute = false;
        }).addCase(getAdditions.rejected, (state, action) => {
            state.query.fetchAdditions.status = "error"
            state.query.fetchAdditions.message = action.error.message;
            state.query.fetchAdditions.canExecute = true;
        }).addCase(getAdditions.fulfilled, (state, action) => {
            state.query.fetchAdditions.status = "success"
            state.query.fetchAdditions.canExecute = true;
            state.loadedData.additions = action.payload.getData().sort(sortAdditions);

            // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancelSave.status = 'pending'
            state.command.cancelSave.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancelSave.status = "success"
            state.command.cancelSave.canExecute = false;
            // initData
        }).addCase(initData.pending, (state) => {
            state.query.initData.status = "pending"
            state.query.initData.canExecute = false;
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.canExecute = true;
            state.query.initData.message = action.error.message;
        }).addCase(initData.fulfilled, (state) => {
            state.query.initData.status = "success"
            state.query.initData.canExecute = true;
            updateCanSave(state);

            // fetchMasspoints
        }).addCase(getMassPoints.pending, (state) => {
            state.query.fetchMasspoints.status = "pending"
            state.query.fetchMasspoints.canExecute = false;
        }).addCase(getMassPoints.rejected, (state, action) => {
            state.query.fetchMasspoints.status = "error"
            state.query.fetchMasspoints.message = action.error.message;
            state.query.fetchMasspoints.canExecute = true;
        }).addCase(getMassPoints.fulfilled, (state, action) => {
            state.query.fetchMasspoints.status = "success"
            state.query.fetchMasspoints.canExecute = true;
            const masspoints = createMasspointItemList(action.payload.getData());
            state.loadedData.masspoints = masspoints;

            // fetchBaseData
        }).addCase(getBaseData.pending, (state) => {
            state.query.fetchBaseData.status = "pending"
            state.query.fetchBaseData.canExecute = false;
        }).addCase(getBaseData.rejected, (state, action) => {
            state.query.fetchBaseData.status = "error"
            state.query.fetchBaseData.message = action.error.message;
            state.query.fetchBaseData.canExecute = true;
        }).addCase(getBaseData.fulfilled, (state, action) => {
            state.query.fetchBaseData.status = "success"
            state.query.fetchBaseData.canExecute = true;
            state.loadedData.articleTypes = action.payload.getData().availableArticleTypes;

            // savePolicy
        }).addCase(savePolicy.pending, (state) => {
            state.command.savePolicy.status = 'pending'
            state.command.savePolicy.canExecute = false;
        }).addCase(savePolicy.rejected, (state, action) => {
            state.command.savePolicy.status = "error"
            state.command.savePolicy.canExecute = true;
            state.command.savePolicy.message = action.error.message;
        }).addCase(savePolicy.fulfilled, (state) => {
            state.command.savePolicy.status = "success"
            state.command.savePolicy.canExecute = false;
        })
    }
})

export const {
    resetState,
    changeName,
    selectAddition,
    addConfiguration,
    selectArticleTypes,
    unSelectArticleTypes,
    filterSelectedArticleTypes,
    filterUnselectedArticleTypes,
    deleteConfiguration,
    selectMasspoint1,
    selectMasspoint2,
    changeMultiplyValue,
    toggleIgnoreOnQuotation,
    completedSave
} = createPolicyAdjustMaximumRange.actions

export default createPolicyAdjustMaximumRange.reducer