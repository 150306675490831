import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAdditionPositions, fetchAllData, getPositionCategory, updatePositionCategory, getPrepositions, createPositionCategory, getBaseData } from "./position_category.thunks";
import { PositionCategoryEditorState } from "models/position_category/position_category_editor";
import { addNewConfig, calculateEditConfig, calculateNewSequenceIds, createEmptyPositionCategoryItem, createPositionCategoryItems, filterPositionCategoryItems, getDirectionItem, getEmptyDirectionItem, updateCanSave, updateCanSaveItem } from "./position_category.reducer";
import IAvailableBodyArea from "models/available_basedata/available_body_area";
import { AdditionPositionFilterHelper } from "models/addition_position/addition_position_overview";
import { BodySideEnum } from "models/masspoints/enums/body_side.enum";
import { PositionCategoryEditState } from "./position_category.model";
import { GridFilterModel } from '@mui/x-data-grid-pro';
import { filterSelectionList, getEmptySelectionList, moveFromSelectedToUnselected, moveFromUnselectedToSelected } from 'shared/components/selectionComponent/selectionList.helper';
import { PositionDirectionEnum } from 'models/position_category/position_direction.enum';
import { SideSeamModeEnum } from 'models/position_category/side_seam_mode.enum';
import { IAvailableBaseData } from 'models/available_basedata/available_base_data';
import { EditorModeEnum } from 'models/editors/editor_mode.enum';

PositionCategoryEditorState
const initialState: PositionCategoryEditState = {
    loadedData: {
        positionCategoryId: 0,
        positionCategory: null,
        bodyAreas: [],
        horizontalStartAdditionPositions: [],
        verticalStartAdditionPositions: [],
        horizontalEndAdditionPositions: [],
        verticalEndAdditionPositions: [],
        prepositions: [],
        articleTypes: []
    },
    actualData: {
        canSaveCategory: false,
        canSaveItem: false,
        name: "",
        sideSeamAllowed: false,
        positionCategoryItemSet: {
            positionCategoryItems: [],
            filteredPositionCategoryItems: [],
            filter: { items: [] },
        },
        editorState: PositionCategoryEditorState.Category,
        editorMode: EditorModeEnum.Create,
        indexOfItem: 0,
    },
    dismissChangesDirection: null,
    showDeleteArticleTypeConfigDialog: false,
    showDismissArticleTypeConfigDialog: false,
    currentEditedPositionCategoryItem: {
        sequenceId: 0,
        bodyAreas: [],
        bodySides: [],
        isDocumentAllowed: false,
        isLengthAllowed: false,
        isWidthAllowed: false,
        lengthFrom: 0,
        lengthTo: 0,
        widthFrom: 0,
        widthTo: 0,
        isDiameterAllowed: false,
        diameterFrom: 0,
        diameterTo: 0,
        isDisplayAsPantyTop: false,
        excludedArticleTypesEnabled: false,
        excludedArticleTypesSelection: getEmptySelectionList(),
        horizontalStartItem: getEmptyDirectionItem(),
        verticalStartItem: getEmptyDirectionItem(),
        verticalEndItem: getEmptyDirectionItem(),
        horizontalEndItem: getEmptyDirectionItem(),
    },
    query: {
        fetchAllData: { status: "idle", canExecute: true },
        fetchAdditionPositions: { status: "idle", canExecute: true },
        getPositionCategory: { status: "idle", canExecute: true },
        getPrepositions: { status: "idle", canExecute: true },
        getBaseData: { status: "idle", canExecute: true },
    },
    command: {
        cancelSavePositionCategory: { status: "idle", canExecute: true },
        updatePositionCategory: { status: "idle", canExecute: false },
        savePositionCategory: { status: "idle", canExecute: false },
    },
    error: {
        showErrorDialog: false,
        errorCode: null
    }
}

export const positionCategoryEditSlice = createSlice({
    name: 'positionCategory/edit',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loadedData = initialState.loadedData;
            state.actualData = initialState.actualData;
            state.query = initialState.query;
            state.command = initialState.command;
        },
        updateName: (state, action: PayloadAction<string>) => {
            state.actualData.name = action.payload;
            updateCanSave(state);
        },
        createNewPositionCategoryItem: (state) => {
            state.currentEditedPositionCategoryItem = createEmptyPositionCategoryItem(state);
            state.actualData.editorState = PositionCategoryEditorState.ItemCreate;
            filterPositionCategoryItems(state);
            updateCanSaveItem(state);
        },
        filterSelectedHorizontalAdditionPositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.horizontalStartItem.additionPositionSelection.selectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.horizontalStartItem.additionPositionSelection.selectedList);
        },
        filterUnselectedHorizontalAdditionPositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.horizontalStartItem.additionPositionSelection.unSelectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.horizontalStartItem.additionPositionSelection.unSelectedList);
        },
        selectHorizontalAdditionPositions: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.currentEditedPositionCategoryItem.horizontalStartItem.additionPositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        unselectHorizontalAdditionPositions: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.currentEditedPositionCategoryItem.horizontalStartItem.additionPositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        filterSelectedVerticalAdditionPositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.verticalStartItem.additionPositionSelection.selectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.verticalStartItem.additionPositionSelection.selectedList);
        },
        filterUnselectedVerticalAdditionPositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.verticalStartItem.additionPositionSelection.unSelectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.verticalStartItem.additionPositionSelection.unSelectedList);
        },
        selectVerticalAdditionPositions: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.currentEditedPositionCategoryItem.verticalStartItem.additionPositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        unselectVerticalAdditionPositions: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.currentEditedPositionCategoryItem.verticalStartItem.additionPositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        toggleSelectionBodyArea: (state, action: PayloadAction<IAvailableBodyArea>) => {
            const index = state.currentEditedPositionCategoryItem.bodyAreas.findIndex(x => x.id === action.payload.id);
            if (index >= 0) {
                state.currentEditedPositionCategoryItem.bodyAreas.splice(index, 1);
            } else {
                state.currentEditedPositionCategoryItem.bodyAreas.push(action.payload);
            }
            updateCanSaveItem(state);
        },
        toggleSelectionBodySide: (state, action: PayloadAction<BodySideEnum>) => {
            const index = state.currentEditedPositionCategoryItem.bodySides.findIndex(x => x === action.payload);
            if (index >= 0) {
                state.currentEditedPositionCategoryItem.bodySides.splice(index, 1);
            } else {
                state.currentEditedPositionCategoryItem.bodySides.push(action.payload);
            }
            updateCanSaveItem(state);
        },
        cancelSavePositionCategoryItem: (state) => {
            state.currentEditedPositionCategoryItem = createEmptyPositionCategoryItem(state);
            state.actualData.editorState = PositionCategoryEditorState.Category;
        },
        savePositionCategoryItem: (state) => {
            if (state.actualData.editorState === PositionCategoryEditorState.ItemCreate) {
                state.actualData.positionCategoryItemSet.positionCategoryItems.push(state.currentEditedPositionCategoryItem);
            }
            else {
                const indexOfItem = state.actualData.indexOfItem;
                const index = state.actualData.positionCategoryItemSet.positionCategoryItems.findIndex(x => x.sequenceId === indexOfItem);
                state.actualData.positionCategoryItemSet.positionCategoryItems[index] = state.currentEditedPositionCategoryItem;
            }
            state.actualData.editorState = PositionCategoryEditorState.Category;
            filterPositionCategoryItems(state);
            updateCanSave(state);
        },
        cancelSavePositionCategory: (state) => {
            state.command.cancelSavePositionCategory.status = "success";
        },
        handleSaveError: (state) => {
            state.command = initialState.command;
        },
        updateHorizontalStartBeginValue: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.horizontalStartItem.start = action.payload;
            updateCanSaveItem(state);
        },
        updateHorizontalStartEndValue: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.horizontalStartItem.end = action.payload;
            updateCanSaveItem(state);
        },
        updateVerticalStartBeginValue: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.verticalStartItem.start = action.payload;
            updateCanSaveItem(state);
        },
        updateVerticalStartEndValue: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.verticalStartItem.end = action.payload;
            updateCanSaveItem(state);
        },
        setIsDocumentAllowed: (state, action: PayloadAction<boolean>) => {
            state.currentEditedPositionCategoryItem.isDocumentAllowed = action.payload;
        },
        toggleSideSeamAllowed: (state) => {
            state.actualData.sideSeamAllowed = !state.actualData.sideSeamAllowed;
            updateCanSave(state);
        },
        setIsLengthAllowed: (state, action: PayloadAction<boolean>) => {
            const newValue = action.payload;
            state.currentEditedPositionCategoryItem.isLengthAllowed = newValue;
            if (!newValue) {
                state.currentEditedPositionCategoryItem.lengthFrom = 0;
                state.currentEditedPositionCategoryItem.lengthTo = 0;
            }
            updateCanSaveItem(state);
        },
        setIsWidthAllowed: (state, action: PayloadAction<boolean>) => {
            const newValue = action.payload;
            state.currentEditedPositionCategoryItem.isWidthAllowed = newValue;
            if (!newValue) {
                state.currentEditedPositionCategoryItem.widthFrom = 0;
                state.currentEditedPositionCategoryItem.widthTo = 0;
            }
            updateCanSaveItem(state);
        },
        setSideSeamMode: (state, action: PayloadAction<SideSeamModeEnum>) => {
            state.currentEditedPositionCategoryItem.sideSeamModeAllowed = action.payload;
            updateCanSaveItem(state);
        },
        setIsDiameterAllowed: (state, action: PayloadAction<boolean>) => {
            const newValue = action.payload;
            state.currentEditedPositionCategoryItem.isDiameterAllowed = newValue;
            if (!newValue) {
                state.currentEditedPositionCategoryItem.diameterFrom = 0;
                state.currentEditedPositionCategoryItem.diameterTo = 0;
            }
            updateCanSaveItem(state);
        },
        updateDiameterFrom: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.diameterFrom = action.payload;
            updateCanSaveItem(state);
        },
        updateDiameterTo: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.diameterTo = action.payload;
            updateCanSaveItem(state);
        },
        updateLengthFrom: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.lengthFrom = action.payload;
            updateCanSaveItem(state);
        },
        updateLengthTo: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.lengthTo = action.payload;
            updateCanSaveItem(state);
        },
        updateWidthFrom: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.widthFrom = action.payload;
            updateCanSaveItem(state);
        },
        updateWidthTo: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.widthTo = action.payload;
            updateCanSaveItem(state);
        },
        deletePositionCategoryItemByIndex: (state, action: PayloadAction<number>) => {
            const indexInAll = state.actualData.positionCategoryItemSet.positionCategoryItems.findIndex(x => x.sequenceId === action.payload);
            state.actualData.positionCategoryItemSet.positionCategoryItems.splice(indexInAll, 1);
            calculateNewSequenceIds(state);
            filterPositionCategoryItems(state);
            updateCanSave(state);
        },
        editPositionCategoryItemByIndex: (state, action: PayloadAction<number>) => {
            const indexInAll = action.payload;
            const itemToEdit = state.actualData.positionCategoryItemSet.positionCategoryItems.find(x => x.sequenceId === indexInAll);
            state.currentEditedPositionCategoryItem = itemToEdit;
            state.actualData.indexOfItem = indexInAll;
            state.actualData.editorState = PositionCategoryEditorState.ItemEdit;
        },
        filterSelectedHorizontalStartPrepositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.horizontalStartItem.prepositionSelection.selectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.horizontalStartItem.prepositionSelection.selectedList);
        },
        filterUnselectedHorizontalStartPrepositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.horizontalStartItem.prepositionSelection.unSelectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.horizontalStartItem.prepositionSelection.unSelectedList);
        },
        selectHorizontalStartPrepositions: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.currentEditedPositionCategoryItem.horizontalStartItem.prepositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        unselectHorizontalStartPrepositions: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.currentEditedPositionCategoryItem.horizontalStartItem.prepositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        filterSelectedVerticalStartPrepositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.verticalStartItem.prepositionSelection.selectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.verticalStartItem.prepositionSelection.selectedList);
        },
        filterUnselectedVerticalStartPrepositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.verticalStartItem.prepositionSelection.unSelectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.verticalStartItem.prepositionSelection.unSelectedList);
        },
        selectVerticalStartPrepositions: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.currentEditedPositionCategoryItem.verticalStartItem.prepositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        unselectVerticalStartPrepositions: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.currentEditedPositionCategoryItem.verticalStartItem.prepositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        updateHorizontalEndFromValue: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.horizontalEndItem.start = action.payload;
            updateCanSaveItem(state);
        },
        updateHorizontalEndToValue: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.horizontalEndItem.end = action.payload;
            updateCanSaveItem(state);
        },
        setIsDisplayAsPantyTop: (state, action: PayloadAction<boolean>) => {
            state.currentEditedPositionCategoryItem.isDisplayAsPantyTop = action.payload;
            updateCanSaveItem(state);
        },
        filterSelectedHorizontalEndAdditionPositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.horizontalEndItem.additionPositionSelection.selectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.horizontalEndItem.additionPositionSelection.selectedList);
        },
        filterUnselectedHorizontalEndAdditionPositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.horizontalEndItem.additionPositionSelection.unSelectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.horizontalEndItem.additionPositionSelection.unSelectedList);
        },
        selectHorizontalEndAdditionPositions: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.currentEditedPositionCategoryItem.horizontalEndItem.additionPositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        unselectHorizontalEndAdditionPositions: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.currentEditedPositionCategoryItem.horizontalEndItem.additionPositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        filterSelectedHorizontalEndPrepositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.horizontalEndItem.prepositionSelection.selectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.horizontalEndItem.prepositionSelection.selectedList);
        },
        filterUnselectedHorizontalEndPrepositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.horizontalEndItem.prepositionSelection.unSelectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.horizontalEndItem.prepositionSelection.unSelectedList);
        },
        selectHorizontalEndPrepositions: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.currentEditedPositionCategoryItem.horizontalEndItem.prepositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        unselectHorizontalEndPrepositions: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.currentEditedPositionCategoryItem.horizontalEndItem.prepositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        updateVerticalEndFromValue: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.verticalEndItem.start = action.payload;
            updateCanSaveItem(state);
        },
        updateVerticalEndToValue: (state, action: PayloadAction<number>) => {
            state.currentEditedPositionCategoryItem.verticalEndItem.end = action.payload;
            updateCanSaveItem(state);
        },
        filterSelectedVerticalEndAdditionPositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.verticalEndItem.additionPositionSelection.selectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.verticalEndItem.additionPositionSelection.selectedList);
        },
        filterUnselectedVerticalEndAdditionPositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.verticalEndItem.additionPositionSelection.unSelectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.verticalEndItem.additionPositionSelection.unSelectedList);
        },
        selectVerticalEndAdditionPositions: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.currentEditedPositionCategoryItem.verticalEndItem.additionPositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        unselectVerticalEndAdditionPositions: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.currentEditedPositionCategoryItem.verticalEndItem.additionPositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        filterSelectedVerticalEndPrepositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.verticalEndItem.prepositionSelection.selectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.verticalEndItem.prepositionSelection.selectedList);
        },
        filterUnselectedVerticalEndPrepositions: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.verticalEndItem.prepositionSelection.unSelectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.verticalEndItem.prepositionSelection.unSelectedList);
        },
        setGridFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.positionCategoryItemSet.filter = action.payload;
            filterPositionCategoryItems(state);
        },
        selectVerticalEndPrepositions: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.currentEditedPositionCategoryItem.verticalEndItem.prepositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        unselectVerticalEndPrepositions: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.currentEditedPositionCategoryItem.verticalEndItem.prepositionSelection, action.payload);
            updateCanSaveItem(state);
        },
        applyArticleTypeConfig: (state, action: PayloadAction<PositionDirectionEnum>) => {
            var item = getDirectionItem(state, action.payload);

            if (item.editableArticleTypeConfigIndex === -1) {
                item.articleTypeConfigs = [...item.articleTypeConfigs, item.editableArticleTypeConfig];
            }
            else {
                item.articleTypeConfigs = item.articleTypeConfigs.map((config, index) =>
                    index === item.editableArticleTypeConfigIndex ? item.editableArticleTypeConfig : config
                );
            }
            item.editableArticleTypeConfig = null;
            item.editableArticleTypeConfigIndex = -1;
            updateCanSaveItem(state);
        },
        cancelArticleTypeConfig: (state, action: PayloadAction<PositionDirectionEnum>) => {
            state.showDismissArticleTypeConfigDialog = true;
            state.dismissChangesDirection = action.payload;
        },
        confirmDismissChanges: (state) => {
            var item = getDirectionItem(state, state.dismissChangesDirection);

            item.editableArticleTypeConfig = null;
            item.editableArticleTypeConfigIndex = -1;
            updateCanSaveItem(state);
        },
        cancelDismissChanges: (state) => {
            state.showDismissArticleTypeConfigDialog = false;
        },
        addArticleTypeConfig: (state, action: PayloadAction<PositionDirectionEnum>) => {
            addNewConfig(state, action.payload);
            updateCanSaveItem(state);
        },
        editArticleTypeConfig: (state, action: PayloadAction<{ index: number, direction: PositionDirectionEnum }>) => {
            var item = getDirectionItem(state, action.payload.direction);
            item.editableArticleTypeConfigIndex = action.payload.index;
            item.editableArticleTypeConfig = calculateEditConfig(item.articleTypeConfigs[action.payload.index]);
            filterSelectionList(item.editableArticleTypeConfig.articleTypes.selectedList);
            filterSelectionList(item.editableArticleTypeConfig.articleTypes.unSelectedList);
            filterSelectionList(item.editableArticleTypeConfig.positions.selectedList);
            filterSelectionList(item.editableArticleTypeConfig.positions.unSelectedList);
            updateCanSaveItem(state);
        },
        deleteArticleTypeConfig: (state, action: PayloadAction<{ index: number, direction: PositionDirectionEnum }>) => {
            var item = getDirectionItem(state, action.payload.direction);
            item.articleTypeConfigDeletingIndex = action.payload.index;
            state.showDeleteArticleTypeConfigDialog = true;
            state.deleteArticleTypeConfigDirection = action.payload.direction;
        },
        confirmDeleteArticleTypeConfig: (state) => {
            var item = getDirectionItem(state, state.deleteArticleTypeConfigDirection);
            item.articleTypeConfigs = item.articleTypeConfigs.filter((_, index) => index !== item.articleTypeConfigDeletingIndex);
            state.showDeleteArticleTypeConfigDialog = false;
        },
        cancelDeleteArticleTypeConfig: (state) => {
            state.deleteArticleTypeConfigDirection = undefined;
            state.showDeleteArticleTypeConfigDialog = false;
        },
        filterSelectedArticleTypeConfigArticleTypes: (state, action: PayloadAction<{ searchText: string, direction: PositionDirectionEnum }>) => {
            var item = getDirectionItem(state, action.payload.direction);
            item.editableArticleTypeConfig.articleTypes.selectedList.searchText = action.payload.searchText;
            filterSelectionList(item.editableArticleTypeConfig.articleTypes.selectedList);
            updateCanSaveItem(state);
        },
        filterUnselectedArticleTypeConfigArticleTypes: (state, action: PayloadAction<{ searchText: string, direction: PositionDirectionEnum }>) => {
            var item = getDirectionItem(state, action.payload.direction);
            item.editableArticleTypeConfig.articleTypes.unSelectedList.searchText = action.payload.searchText;
            filterSelectionList(item.editableArticleTypeConfig.articleTypes.unSelectedList);
            updateCanSaveItem(state);
        },
        selectArticleTypeConfigArticleTypes: (state, action: PayloadAction<{ ids: number[], direction: PositionDirectionEnum }>) => {
            var item = getDirectionItem(state, action.payload.direction);
            moveFromUnselectedToSelected(item.editableArticleTypeConfig.articleTypes, action.payload.ids);
            updateCanSaveItem(state);
        },
        unselectArticleTypeConfigArticleTypes: (state, action: PayloadAction<{ ids: number[], direction: PositionDirectionEnum }>) => {
            var item = getDirectionItem(state, action.payload.direction);
            moveFromSelectedToUnselected(item.editableArticleTypeConfig.articleTypes, action.payload.ids);
            updateCanSaveItem(state);
        },
        filterSelectedArticleTypeConfigPositions: (state, action: PayloadAction<{ searchText: string, direction: PositionDirectionEnum }>) => {
            var item = getDirectionItem(state, action.payload.direction);
            item.editableArticleTypeConfig.positions.selectedList.searchText = action.payload.searchText;
            filterSelectionList(item.editableArticleTypeConfig.positions.selectedList);
            updateCanSaveItem(state);
        },
        filterUnselectedArticleTypeConfigPositions: (state, action: PayloadAction<{ searchText: string, direction: PositionDirectionEnum }>) => {
            var item = getDirectionItem(state, action.payload.direction);
            item.editableArticleTypeConfig.positions.unSelectedList.searchText = action.payload.searchText;
            filterSelectionList(item.editableArticleTypeConfig.positions.unSelectedList);
            updateCanSaveItem(state);
        },
        selectArticleTypeConfigPositions: (state, action: PayloadAction<{ ids: number[], direction: PositionDirectionEnum }>) => {
            var item = getDirectionItem(state, action.payload.direction);
            moveFromUnselectedToSelected(item.editableArticleTypeConfig.positions, action.payload.ids);
            updateCanSaveItem(state);
        },
        unselectArticleTypeConfigPositions: (state, action: PayloadAction<{ ids: number[], direction: PositionDirectionEnum }>) => {
            var item = getDirectionItem(state, action.payload.direction);
            moveFromSelectedToUnselected(item.editableArticleTypeConfig.positions, action.payload.ids);
            updateCanSaveItem(state);
        },
        setEditorMode: (state, action: PayloadAction<EditorModeEnum>) => {
            state.actualData.editorMode = action.payload;
        },
        setExcludedArticleTypesEnabled: (state, action: PayloadAction<boolean>) => {
            state.currentEditedPositionCategoryItem.excludedArticleTypesEnabled = action.payload;
        },
        selectExcludedArticleTypes: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.currentEditedPositionCategoryItem.excludedArticleTypesSelection, action.payload);
            updateCanSaveItem(state);
        },
        unselectExcludedArticleTypes: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.currentEditedPositionCategoryItem.excludedArticleTypesSelection, action.payload);
            updateCanSaveItem(state);
        },
        filterSelectedExcludedArticleTypes: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.excludedArticleTypesSelection.selectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.excludedArticleTypesSelection.selectedList);
        },
        filterUnselectedExcludedArticleTypes: (state, action: PayloadAction<string>) => {
            state.currentEditedPositionCategoryItem.excludedArticleTypesSelection.unSelectedList.searchText = action.payload;
            filterSelectionList(state.currentEditedPositionCategoryItem.excludedArticleTypesSelection.unSelectedList);
        },
    }, extraReducers: (builder) => {
        // fetch bodyAreas
        builder.addCase(fetchAdditionPositions.pending, (state, action) => {
            state.query.fetchAdditionPositions.status = "pending"
            state.query.fetchAdditionPositions.canExecute = false;
        }).addCase(fetchAdditionPositions.rejected, (state, action) => {
            state.query.fetchAdditionPositions.status = "error"
            state.query.fetchAdditionPositions.message = action.error.message;
            state.query.fetchAdditionPositions.canExecute = true;
        }).addCase(fetchAdditionPositions.fulfilled, (state, action) => {
            state.query.fetchAdditionPositions.status = "success"
            state.query.fetchAdditionPositions.message = undefined;
            state.query.fetchAdditionPositions.canExecute = true;

            const additionPositions = action.payload.getData();
            const horizontalStartAdditionPositions = AdditionPositionFilterHelper.FilterHorizontalStart(additionPositions);
            state.loadedData.horizontalStartAdditionPositions = horizontalStartAdditionPositions;

            const horizontalEndAdditionPositions = AdditionPositionFilterHelper.FilterHorizontalEnd(additionPositions);
            state.loadedData.horizontalEndAdditionPositions = horizontalEndAdditionPositions;

            const verticalStartAdditionPositions = AdditionPositionFilterHelper.FilterVerticalStart(additionPositions);
            state.loadedData.verticalStartAdditionPositions = verticalStartAdditionPositions;

            const verticalEndAdditionPositions = AdditionPositionFilterHelper.FilterVerticalEnd(additionPositions);
            state.loadedData.verticalEndAdditionPositions = verticalEndAdditionPositions;

            //getPrepositions
        }).addCase(getPrepositions.pending, (state, action) => {
            state.query.getPrepositions.status = "pending"
            state.query.getPrepositions.canExecute = false;
        }).addCase(getPrepositions.rejected, (state, action) => {
            state.query.getPrepositions.status = "error"
            state.query.getPrepositions.message = action.error.message;
            state.query.getPrepositions.canExecute = true;
        }).addCase(getPrepositions.fulfilled, (state, action) => {
            state.query.getPrepositions.status = "success"
            state.query.getPrepositions.message = undefined;
            state.query.getPrepositions.canExecute = true;
            const prepositions = action.payload.getData();
            state.loadedData.prepositions = prepositions.map(x => {
                return {
                    id: x.id,
                    name: x.name,
                    translationKeyForName: x.translationKeyForName
                }
            });

            //getBaseData
        }).addCase(getBaseData.pending, (state, action) => {
            state.query.getBaseData.status = "pending"
            state.query.getBaseData.canExecute = false;
        }).addCase(getBaseData.rejected, (state, action) => {
            state.query.getBaseData.status = "error"
            state.query.getBaseData.message = action.error.message;
            state.query.getBaseData.canExecute = true;
        }).addCase(getBaseData.fulfilled, (state, action) => {
            state.query.getBaseData.status = "success"
            state.query.getBaseData.message = undefined;
            state.query.getBaseData.canExecute = true;
            const baseData: IAvailableBaseData = action.payload.getData();
            state.loadedData.articleTypes = baseData.availableArticleTypes;
            state.loadedData.bodyAreas = baseData.availableBodyAreas;

            //fetchAllData
        }).addCase(fetchAllData.pending, (state, action) => {
            state.query.fetchAllData.status = "pending"
            state.query.fetchAllData.canExecute = false;
        }).addCase(fetchAllData.rejected, (state, action) => {
            state.query.fetchAllData.status = "error"
            state.query.fetchAllData.message = action.error.message;
            state.query.fetchAllData.canExecute = true;
        }).addCase(fetchAllData.fulfilled, (state, action) => {
            state.query.fetchAllData.status = "success"
            state.query.fetchAllData.message = undefined;
            state.query.fetchAllData.canExecute = true;

            //save
        }).addCase(createPositionCategory.pending, (state, action) => {
            state.command.savePositionCategory.status = "pending"
            state.command.savePositionCategory.canExecute = false;
        }).addCase(createPositionCategory.rejected, (state, action) => {
            state.command.savePositionCategory.status = "error"
            state.command.savePositionCategory.canExecute = true;
            state.command.savePositionCategory.message = action.error.message;
        }).addCase(createPositionCategory.fulfilled, (state, action) => {
            state.command.savePositionCategory.status = "success"
            state.command.savePositionCategory.message = undefined;
            state.command.savePositionCategory.canExecute = true;

            //update
        }).addCase(updatePositionCategory.pending, (state, action) => {
            state.command.updatePositionCategory.status = "pending"
            state.command.updatePositionCategory.canExecute = false;
        }).addCase(updatePositionCategory.rejected, (state, action) => {
            state.command.updatePositionCategory.status = "error"
            state.command.updatePositionCategory.canExecute = true;
            state.command.updatePositionCategory.message = action.error.message;

            if (action.error.code === "422" || action.error.code === "400") {
                state.error.showErrorDialog = true;
                state.error.errorCode = action.error.code
            } else {
                state.error.showErrorDialog = false;
                state.error.errorCode = null;
            }
        }).addCase(updatePositionCategory.fulfilled, (state, action) => {
            state.command.updatePositionCategory.status = "success"
            state.command.updatePositionCategory.message = undefined;
            state.command.updatePositionCategory.canExecute = true;


            //getPositionCategory
        }).addCase(getPositionCategory.pending, (state, action) => {
            state.query.getPositionCategory.status = "pending"
            state.query.getPositionCategory.canExecute = false;
        }).addCase(getPositionCategory.rejected, (state, action) => {
            state.query.getPositionCategory.status = "error"
            state.query.getPositionCategory.canExecute = true;
            state.query.getPositionCategory.message = action.error.message;
        }).addCase(getPositionCategory.fulfilled, (state, action) => {
            state.query.getPositionCategory.status = "success"
            state.query.getPositionCategory.message = undefined;
            state.query.getPositionCategory.canExecute = true;

            const positionCategory = action.payload.getData();
            state.loadedData.positionCategoryId = positionCategory.id;
            state.actualData.name = positionCategory.name;
            state.actualData.sideSeamAllowed = positionCategory.sideSeamAllowed;
            state.actualData.positionCategoryItemSet.positionCategoryItems = createPositionCategoryItems(state, positionCategory.positionItems);
            calculateNewSequenceIds(state);
            filterPositionCategoryItems(state);
            state.loadedData.positionCategory = state.actualData;
        });
    }
})

export const {
    updateName,
    resetState,
    createNewPositionCategoryItem,
    filterSelectedHorizontalAdditionPositions,
    filterUnselectedHorizontalAdditionPositions,
    unselectHorizontalAdditionPositions,
    selectHorizontalAdditionPositions,
    filterSelectedVerticalAdditionPositions,
    filterUnselectedVerticalAdditionPositions,
    unselectVerticalAdditionPositions,
    selectVerticalAdditionPositions,
    toggleSelectionBodyArea,
    toggleSelectionBodySide,
    cancelSavePositionCategoryItem,
    savePositionCategoryItem,
    cancelSavePositionCategory,
    handleSaveError,
    updateHorizontalStartBeginValue,
    updateHorizontalStartEndValue,
    updateVerticalStartBeginValue,
    updateVerticalStartEndValue,
    setIsDocumentAllowed,
    setIsLengthAllowed,
    setIsWidthAllowed,
    setIsDiameterAllowed,
    updateDiameterTo,
    updateDiameterFrom,
    updateLengthFrom,
    updateLengthTo,
    updateWidthFrom,
    updateWidthTo,
    toggleSideSeamAllowed,
    deletePositionCategoryItemByIndex,
    editPositionCategoryItemByIndex,
    filterSelectedHorizontalStartPrepositions,
    filterUnselectedHorizontalStartPrepositions,
    filterSelectedVerticalStartPrepositions,
    filterUnselectedVerticalStartPrepositions,
    selectHorizontalStartPrepositions,
    unselectHorizontalStartPrepositions,
    selectVerticalStartPrepositions,
    unselectVerticalStartPrepositions,
    updateHorizontalEndFromValue,
    updateHorizontalEndToValue,
    filterSelectedHorizontalEndAdditionPositions,
    filterUnselectedHorizontalEndAdditionPositions,
    selectHorizontalEndAdditionPositions,
    unselectHorizontalEndAdditionPositions,
    filterSelectedHorizontalEndPrepositions,
    filterUnselectedHorizontalEndPrepositions,
    selectHorizontalEndPrepositions,
    unselectHorizontalEndPrepositions,
    updateVerticalEndFromValue,
    updateVerticalEndToValue,
    filterSelectedVerticalEndAdditionPositions,
    filterUnselectedVerticalEndAdditionPositions,
    selectVerticalEndAdditionPositions,
    unselectVerticalEndAdditionPositions,
    filterSelectedVerticalEndPrepositions,
    filterUnselectedVerticalEndPrepositions,
    selectVerticalEndPrepositions,
    unselectVerticalEndPrepositions,
    setGridFilters,
    setSideSeamMode,
    setIsDisplayAsPantyTop,
    applyArticleTypeConfig,
    cancelDismissChanges,
    confirmDismissChanges,
    cancelArticleTypeConfig,
    deleteArticleTypeConfig,
    editArticleTypeConfig,
    addArticleTypeConfig,
    unselectArticleTypeConfigPositions,
    selectArticleTypeConfigPositions,
    filterUnselectedArticleTypeConfigPositions,
    filterSelectedArticleTypeConfigPositions,
    unselectArticleTypeConfigArticleTypes,
    selectArticleTypeConfigArticleTypes,
    filterUnselectedArticleTypeConfigArticleTypes,
    filterSelectedArticleTypeConfigArticleTypes,
    confirmDeleteArticleTypeConfig,
    cancelDeleteArticleTypeConfig,
    setEditorMode,
    selectExcludedArticleTypes,
    unselectExcludedArticleTypes,
    filterSelectedExcludedArticleTypes,
    filterUnselectedExcludedArticleTypes,
    setExcludedArticleTypesEnabled
} = positionCategoryEditSlice.actions

export default positionCategoryEditSlice.reducer