import colors from "shared/style/colors";
import styled from "@emotion/styled";

interface CustomFooterProps {
  resultCount: number,
  total: number
}

export const CustomFooter: React.FC<CustomFooterProps> = ({ resultCount, total }) => {
  return (
    <DataGridFooter>{resultCount} von {total}</DataGridFooter>
  )
}

const DataGridFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  height: 50px;
  background-color: ${colors.mediSecondaryGray};
  padding-right: 20px;
`;