import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseErrors } from "shared/networking/error_handling";
import AdditionService from 'services/addition.service';
import PolicyServiceAPI from '../../../../services/policy.service';
import { RootState } from "app/redux_store";
import { PolicyTypeEnum } from "models/policies/policy_type.enum";
import { InitBaseModel } from "app/helper/initialize_editors";

export const getAdditions = createAsyncThunk(
    'policy/policy_side_seam/getAdditions',
    async (initBase: InitBaseModel, { getState } ) => {
        const response = await AdditionService.getAvailableAdditionsByPolicyTypeOverview(PolicyTypeEnum.SideSeam, initBase.id);
        handleResponseErrors(response, "Zusätze");
        return response;
    }
)

export const savePolicy = createAsyncThunk(
    'policy/policy_side_seam/savepolicy',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.policy_side_seam.data;
        const response = await PolicyServiceAPI.createSideSeam({
            name: data.policy.name,
            ignoreOnQuotation: data.policy.ignoreOnQuotation,
            additionId: data.selectedAddition.id
        });
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const updatePolicy = createAsyncThunk(
    'policy/policy_side_seam/updatepolicy',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.policy_side_seam.data;
        const response = await PolicyServiceAPI.updateSideSeam({
            policyId: data.policy.id,
            name: data.policy.name,
            ignoreOnQuotation: data.policy.ignoreOnQuotation,
            additionId: data.selectedAddition.id
        });
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const storePolicy = createAsyncThunk(
    'policy/policy_side_seam/storePolicy',
    async (_, { getState, dispatch }) => {
        const state = getState() as RootState;
        if(state.policy_side_seam.loadedData.isEdit) {
            await dispatch(updatePolicy());
        } else {
            await dispatch(savePolicy());
        }
    }
)


export const cancelSave = createAsyncThunk(
    'policy/policy_side_seam/cancelSave',
    async (_) => { }
)

export const getPolicy = createAsyncThunk(
    'policy/policy_side_seam/getPolicy',
    async (id: string) => {
        const response = await PolicyServiceAPI.getSideSeam(id);
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const initData = createAsyncThunk(
    'policy/policy_side_seam/initData',
    async (initBase: InitBaseModel, { dispatch }) => {
        await Promise.all([
            dispatch(getAdditions(initBase)),
        ]);
        if(initBase.id){
            await dispatch(getPolicy(initBase.id));
        }
    }
)
