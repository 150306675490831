import { doesExist, isNotEmpty, doesNotStartOrEndWithWhitespace } from "../../../../services/validation.service";
import { MassimagesOverviewState } from "./massimages_overview.model";
import { FileExtensionConstantsEnum } from "../../../../models/file_extension_constants.enum";
import { MimeTypeConstantsEnum } from "../../../../models/mime_type_constants.enum";
import { filterMassimageValue } from "../massimage_overview.helper";

export const updateCanUpload = (state: MassimagesOverviewState) => {
    state.command.uploadPicture.canExecute = doesExist(state.actualData.selectedMassimage.name)
        && isNotEmpty(state.actualData.selectedMassimage.name)
        && doesNotStartOrEndWithWhitespace(state.actualData.selectedMassimage.name)
        && state.actualData.file
        && state.actualData.file.type === MimeTypeConstantsEnum.Svg
        && state.actualData.file.name.toLowerCase().endsWith(FileExtensionConstantsEnum.Svg)
}

export const filterMassimages = (state: MassimagesOverviewState) => {
    const searchTextLower = state.actualData.searchFilter.toLowerCase().trim();
    let filteredItems = state.loadedData.massimages.filter(
        (massimage) => massimage.name.toLowerCase().includes(searchTextLower) 
    );

    state.actualData.gridFilters.items.forEach(fil => {
        filteredItems = filterMassimageValue(fil, filteredItems);
    });
    state.actualData.massimages = filteredItems;
}