import colors from "./colors";
import {
  createTheme
} from "@mui/material";

const theme = {
  colors: {
    tableBackground: colors.reallyLightGray,
    inactiveSeconday: colors.mediSecondaryGray,
    inactive: colors.mediLightGray,
    active: colors.mediMagenta,
    backgroundActive: colors.mediLightGray,
    text: colors.mediGrey,
  },
  form: { minWidth: 186 },
};

export const materialTheme = createTheme({
  typography: {
    fontFamily: "medistradapro, Raleway, Arial",
    fontSize: 14,
  },
  palette: {
    primary: {
      light: "#48586a",
      main: "#48586a",
      dark: "#48586a",
      contrastText: "#48586a",
    },
    secondary: {
      light: "#e6007e",
      main: "#e6007e",
      dark: "#e6007e",
      contrastText: "#48586a",
    },
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: colors.mediMagenta,
          color: "white"
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginRight: 10,
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
        }
      }
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          display: "flex",
          paddingTop: 24,
          paddingBottom: 50,
          paddingLeft: 0,
          paddingRight: 0,
          "&.MuiPaper-root": {
            backgroundColor: "transparent"
          }
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.MuiStepIcon-active": { color: colors.mediMagenta }
        },
        text: {
          display: "none"
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        container: {
          background: "white",
          marginBottom: 8
        },
        gutters: {
          marginBottom: 8,
          background: "white"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {       
          backgroundColor: colors.mediGrey,
          textTransform: "none",
          "&.MuiButtonBase-root": {
            color: "white",
            opacity: 1,
            borderBottomColor: colors.mediMagenta,
            borderWidth: 0,
            border: "solid",
            borderBottomWidth: 2,
          },        
          "&.Mui-selected": {
            color: colors.mediGrey,
            backgroundColor: "transparent",
            borderColor: colors.mediMagenta,
            borderWidth: 2,
            border: "solid",
            borderBottomWidth: 0,
          },
          "&.MuiTab-textColorInherit.Mui-disabled": {
            backgroundColor: "transparent",
            opacity: 1,
            minWidth: "100%",
          }
        }
      }
    }
  }
});

export default theme;