import React from "react";
import { SerialSizeTableEntryItem, SizeTableEntryContainer } from "./serial_sizes.style";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, IconButton } from "@mui/material";
import { ISerialSizeArticleViewModel } from "./serial_size_editor.model";

export interface SerialSizeArticleTypeSummaryComponentProps {
    articleTypeSummary: ISerialSizeArticleViewModel;
    isDisabled: boolean;
    editArticleType: (articleType: ISerialSizeArticleViewModel) => void;
    deleteArticleType: (articleType: ISerialSizeArticleViewModel) => void;
}

export const SerialSizeArticleTypeSummaryComponent: React.FC<SerialSizeArticleTypeSummaryComponentProps> = ({ 
    articleTypeSummary,
    isDisabled,
    editArticleType,
    deleteArticleType,
}) => {

    const sizeName = articleTypeSummary.sizeViewModels.map(size => size.size.name).join(", ");
    const attributeName = articleTypeSummary.attributeViewModels.map(attribute => attribute.attribute.name).join(", ");

    return (
        <>
            <SizeTableEntryContainer>
                <SerialSizeTableEntryItem width="42px" backgroundColor="white"/>
                <SerialSizeTableEntryItem width="150px" backgroundColor="white">
                    {articleTypeSummary.articleType.name}
                </SerialSizeTableEntryItem>
                <SerialSizeTableEntryItem flex={1} backgroundColor="white">
                    <Grid container direction={"column"}>
                        <Grid item>
                            {sizeName}
                        </Grid>
                        {articleTypeSummary.attributeViewModels.length > 0 && 
                            <Grid item>
                                {attributeName}
                            </Grid>
                        }
                    </Grid>           
                </SerialSizeTableEntryItem>
                <SerialSizeTableEntryItem width="80px" backgroundColor="white">
                    <IconButton
                        disabled={isDisabled}
                        style={{ outline: "none" }}
                        onClick={() => editArticleType(articleTypeSummary)}>
                        <EditIcon />
                    </IconButton>
                </SerialSizeTableEntryItem>
                <SerialSizeTableEntryItem width="80px">
                    <IconButton
                        disabled={articleTypeSummary.articleType.id === -1 || isDisabled}
                        style={{ outline: "none" }}
                        onClick={() => deleteArticleType(articleTypeSummary)}>
                        <DeleteIcon />
                    </IconButton>            
                </SerialSizeTableEntryItem>   
            </SizeTableEntryContainer>    
        </>
    )
}