import { resetState, setGridFilters, setSearchFilter } from "./redux/position_category_item_details.slice";
import { useAppDispatch, useAppSelector } from 'app/hook';
import { AppDispatch } from 'app/redux_store';
import { useEffect } from "react";
import { getPositionCategory, goBackToOverview } from "./redux/position_category_item_details.thunks";
import { FlexBox, Left, LoadingIndicatorComponent, PrimaryButtonComponent, Right } from "shared/shared";
import { Grid } from "@mui/material";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";
import { useNavigate, useParams } from "react-router-dom";
import { OverviewDatagrid } from "shared/components/datagrid/overview_datagrid.component";
import SearchFieldComponent from "shared/components/search/searchfield.component";
import { getColumnVisibilityModel, getGridColumns, sortByDisplayNameModel } from "./position_category_item_details.helper";

export const PositionCategoryItemDetailComponent = () => {
    const state = useAppSelector((state) => state.position_category_item_details);
    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();

    const { positionCategoryId } = useParams();

    useEffect(() => {
        dispatch(getPositionCategory(positionCategoryId));
        return () => {
            dispatch(resetState());
        }
    }, [dispatch, positionCategoryId]);

    if (state.query.getPositionCategory.status === "pending") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.goBackToOverview.status === "pending") {
        navigate(`/additions/positioncategory/overview`);
    }

    const headerText = state.loadedData.positionCategory ? `Positionselemente in ${state.loadedData.positionCategory.name}` : "Positionselemente";

    const positionCategoryItemDetailsAvailable = state.actualData.positionCategoryItemDetails?.length > 0;

    return (
        <>
            <Grid item
                direction="column"
                container
                spacing={3}>
                <Grid item md={12}>
                    <EditorSectionHeaderComponent>{headerText}</EditorSectionHeaderComponent>
                </Grid>

                {
                    positionCategoryItemDetailsAvailable &&
                    <Grid item md={12}>
                        <FlexBox>
                            <Left>
                                <SearchFieldComponent onSearch={(filterText) => dispatch(setSearchFilter(filterText))}
                                    placeholder="Suchen ..." />
                            </Left>
                        </FlexBox>
                    </Grid>
                }

                <Grid item md={12}>
                    {
                        !positionCategoryItemDetailsAvailable && <div>Keine Positionselemente vorhanden</div>
                    }
                    {
                        positionCategoryItemDetailsAvailable &&
                        <OverviewDatagrid
                            isLoading={false}
                            columns={getGridColumns()}
                            columnVisibilityModel={getColumnVisibilityModel(state.loadedData.positionCategory.sideSeamAllowed)}
                            rows={state.actualData.filteredPositionCategoryItemDetails}
                            loadedRowsCount={state.actualData.positionCategoryItemDetails.length}
                            sortModel={sortByDisplayNameModel}
                            onFilterModelChange={(newFilterModel) => dispatch(setGridFilters(newFilterModel))}
                        />
                    }
                </Grid>
                <Grid item md={12}
                    container
                    justifyContent="flex-end"
                    spacing={2}>
                    <Grid item>
                        <PrimaryButtonComponent
                            onClick={() => dispatch(goBackToOverview())}>
                            <ExitToAppIcon /> Zurück
                        </PrimaryButtonComponent>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}