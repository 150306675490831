import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/redux_store';
import BaseDataService from "../../../services/base_data.service";
import MassPointService from '../../../services/masspoint.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';
import { toUpdateReferences } from './edit_references.reducer';

export const updateReferences = createAsyncThunk(
    'masspoint/edit_references/update_references',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const slice = state.masspoint_edit_references;
        const response = await MassPointService.updateReferences(toUpdateReferences(slice));
        handleResponseErrors(response, "Referenzen");
        return response;
    }
)

export const fetchExistingBaseData = createAsyncThunk(
    'masspoint/edit_references/fetch_existing_base_data',
    async (_,) => {
        const response = await BaseDataService.getBaseData();
        handleResponseErrors(response, "Baisdaten");
        return response;
    }
)

export const fetchExistingReferences = createAsyncThunk(
    'masspoint/edit_references/fetch_references',
    async (massPointId: Number, _) => {
        const response = await MassPointService.getMassPointReferences(massPointId);
        handleResponseErrors(response, "Referenzen");
        return response;
    }
)

export const fetchExistingMassPoint = createAsyncThunk(
    'masspoint/edit_references/fetch_existing_masspoint',
    async (massPointId: Number, _) => {
        const response = await MassPointService.getMassPoint(massPointId);
        handleResponseErrors(response, "Maßpunkt");
        return response;
    }
)


export const initData = createAsyncThunk(
    'masspoint/edit_references/init_data',
    async (massPointId: Number, { dispatch }) => {
        await Promise.all([
            dispatch(fetchExistingBaseData()),
            await dispatch(fetchExistingReferences(massPointId)),
            dispatch(fetchExistingMassPoint(massPointId)),
        ]);
    }
)


export const cancelEditReferences = createAsyncThunk(
    'masspoint/edit_references/cancel',
    async (_,) => { }
)

