import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasspointTypeEnumValuesLookup } from "models/masspoints/enums/masspoint_type.enum";
import { IAdditionPositionTranslation } from "models/addition_position/addition_position_translation";
import { AdditionPositionEditState, SelectionAdditionHierarchyPayload } from "./addition_position.model";
import { filterMasspoints, moveFromSelectedToUnselected, moveFromUnselectedToSelected, updateCanSave } from "./addition_position.reducer";
import { createAdditionPosition, fetchAllData, getAdditionHierarchies, getAdditionPosition, getAdditionPositionTranslations, getMassPoints, updateAdditionPosition } from "./addition_position.thunks";
import { distinctMassPointCriteria } from "shared/helpers/distinctMassPointCriteria";
import { EditorModeEnum } from "models/editors/editor_mode.enum";

const initialState: AdditionPositionEditState = {
    loadedData: {
        id: 0,
        additionHierarchies: [],
    },
    actualData: {
        canSave: false,
        name: "",
        showConfirmCreateDialog: false,
        editorMode: EditorModeEnum.Create,
        additionHierarchies: [],
        masspointSelection: {
            selectedList: {
                searchText: "",
                allItems: [],
                filteredItems: []
            },
            unSelectedList: {
                searchText: "",
                allItems: [],
                filteredItems: []
            }
        },
        additionPositionTranslations: [],
        selectedAdditionPositionTranslation: null
    },
    query: {
        fetchAllData: { status: "idle", canExecute: false },
        getMassPoints: { status: "idle", canExecute: true },
        getAdditionHierarchies: { status: "idle", canExecute: true },
        getAdditionPositionTranslations: { status: "idle", canExecute: true },
        getAdditionPosition: { status: "idle", canExecute: true },
    },
    command: {
        createAdditionPosition: { status: "idle", canExecute: false },
        updateAdditionPosition: { status: "idle", canExecute: false },
        cancelUpdateAdditionPosition: { status: "idle", canExecute: false },
    },
    error: {
        showErrorDialog: false,
        errorCode: null
    }
}

export const additionPositionEditSlice = createSlice({
    name: 'additionPosition/edit',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loadedData = initialState.loadedData;
            state.query = initialState.query;
            state.command = initialState.command;
            state.actualData = initialState.actualData;
            state.error = initialState.error;
        },
        cancelSaveAdditionPosition: (state) => {
            state.command.cancelUpdateAdditionPosition.status = "success";
        },
        updateAdditionPositionName: (state, action: PayloadAction<string>) => {
            state.actualData.name = action.payload;
            updateCanSave(state);
        },
        filterSelectedMasspoints: (state, action: PayloadAction<string>) => {
            state.actualData.masspointSelection.selectedList.searchText = action.payload;
            filterMasspoints(state.actualData.masspointSelection.selectedList);
        },
        filterUnselectedMasspoints: (state, action: PayloadAction<string>) => {
            state.actualData.masspointSelection.unSelectedList.searchText = action.payload;
            filterMasspoints(state.actualData.masspointSelection.unSelectedList);
        },
        selectMasspoints: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state, action.payload);
            updateCanSave(state);
        },
        unselectMasspoints: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state, action.payload);
            updateCanSave(state);
        },
        setSelectionAdditionHierarchy: (state, action: PayloadAction<SelectionAdditionHierarchyPayload>) => {
            const payload = action.payload;
            const additionHierarchy = state.actualData.additionHierarchies.find(x => x.id === payload.id);
            additionHierarchy.isSelected = payload.isChecked;
            updateCanSave(state);
        },
        selectTranslation: (state, action: PayloadAction<IAdditionPositionTranslation>) => {
            state.actualData.selectedAdditionPositionTranslation = action.payload;
            updateCanSave(state);
        },
        setEditorMode: (state, action: PayloadAction<EditorModeEnum>) => {
            state.actualData.editorMode = action.payload;
        },
        setShowConfirmCreateDialog: (state, action: PayloadAction<boolean>) => {
            state.actualData.showConfirmCreateDialog = action.payload;
        },
        handleSaveError: (state) => {
            state.command = {
                updateAdditionPosition: { status: "idle", canExecute: true },
                cancelUpdateAdditionPosition: { status: "idle", canExecute: true },
                createAdditionPosition: { status: "idle", canExecute: true },
            };
        },
    }, extraReducers: (builder) => {
        // fetch allData
        builder.addCase(fetchAllData.pending, (state, action) => {
            state.query.fetchAllData.status = 'pending'
        }).addCase(fetchAllData.rejected, (state, action) => {
            state.query.fetchAllData.status = "error"
            state.query.fetchAllData.message = action.error.message;
        }).addCase(fetchAllData.fulfilled, (state, action) => {
            state.query.fetchAllData.status = "success"

            //getMasspoints
        }).addCase(getMassPoints.pending, (state, action) => {
            state.query.getMassPoints.status = 'pending'
        }).addCase(getMassPoints.rejected, (state, action) => {
            state.query.getMassPoints.status = "error"
            state.query.getMassPoints.message = action.error.message;
        }).addCase(getMassPoints.fulfilled, (state, action) => {
            state.query.getMassPoints.status = "success"
            state.query.getMassPoints.canExecute = true;
            const massPoints = action.payload.getData();

            const massPointCriteria = massPoints.filter(distinctMassPointCriteria).map(mp => {
                return {
                    massPointName: mp.name,
                    massPointType: mp.massPointType,
                    bodyArea: mp.bodyArea,
                    displayName: `${mp.name} ${MasspointTypeEnumValuesLookup(mp.massPointType)} ${mp.bodyArea.name}`,
                    id: mp.id
                }
            });

            state.actualData.masspointSelection.unSelectedList.allItems = massPointCriteria;
            filterMasspoints(state.actualData.masspointSelection.unSelectedList);

            //getAdditionHierarchies
        }).addCase(getAdditionHierarchies.pending, (state, action) => {
            state.query.getAdditionHierarchies.status = 'pending'
        }).addCase(getAdditionHierarchies.rejected, (state, action) => {
            state.query.getAdditionHierarchies.status = "error"
            state.query.getAdditionHierarchies.message = action.error.message;
        }).addCase(getAdditionHierarchies.fulfilled, (state, action) => {
            state.query.getAdditionHierarchies.status = "success"
            state.query.getAdditionHierarchies.canExecute = true;
            const additionHierarchies = action.payload.getData();
            state.loadedData.additionHierarchies = additionHierarchies;
            state.actualData.additionHierarchies = additionHierarchies.map(hierarchy => {
                return {
                    id: hierarchy.id,
                    name: hierarchy.name,
                    translationKeyForName: hierarchy.translationKeyForName,
                    isSelected: false
                }
            });

            //getAdditionPositionTranslations
        }).addCase(getAdditionPositionTranslations.pending, (state) => {
            state.query.getAdditionPositionTranslations.status = 'pending'
        }).addCase(getAdditionPositionTranslations.rejected, (state, action) => {
            state.query.getAdditionPositionTranslations.status = "error"
            state.query.getAdditionPositionTranslations.message = action.error.message;
        }).addCase(getAdditionPositionTranslations.fulfilled, (state, action) => {
            state.query.getAdditionPositionTranslations.status = "success"
            state.query.getAdditionPositionTranslations.canExecute = true;
            const translations = action.payload.getData();
            state.actualData.additionPositionTranslations = translations;

            // updateAdditionPosition
        }).addCase(createAdditionPosition.pending, (state, action) => {
            state.command.createAdditionPosition.status = "pending"
            state.command.createAdditionPosition.canExecute = false;
        }).addCase(createAdditionPosition.rejected, (state, action) => {
            state.command.createAdditionPosition.status = "error"
            state.command.createAdditionPosition.canExecute = true;
            state.command.createAdditionPosition.message = action.error.message;
        }).addCase(createAdditionPosition.fulfilled, (state, action) => {
            state.command.createAdditionPosition.status = "success"
            state.command.createAdditionPosition.message = undefined;
            state.command.createAdditionPosition.canExecute = true;

            // updateAdditionPosition
        }).addCase(updateAdditionPosition.pending, (state) => {
            state.command.updateAdditionPosition.status = "pending"
            state.command.updateAdditionPosition.canExecute = false;
        }).addCase(updateAdditionPosition.rejected, (state, action) => {
            state.command.updateAdditionPosition.status = "error"
            state.command.updateAdditionPosition.canExecute = true;
            state.command.updateAdditionPosition.message = action.error.message;
            if(action.error.code === "422" || action.error.code === "400") {
                state.error.showErrorDialog = true;
                state.error.errorCode = action.error.code
            } else {
                state.error.showErrorDialog = false;
                state.error.errorCode = null;  
            }

        }).addCase(updateAdditionPosition.fulfilled, (state) => {
            state.command.updateAdditionPosition.status = "success"
            state.command.updateAdditionPosition.message = undefined;
            state.command.updateAdditionPosition.canExecute = true;
            state.error.showErrorDialog = false;
            state.error.errorCode = null;  

            //getAdditionPosition
        }).addCase(getAdditionPosition.pending, (state) => {
            state.query.getAdditionPosition.status = "pending"
            state.query.getAdditionPosition.canExecute = false;
        }).addCase(getAdditionPosition.rejected, (state, action) => {
            state.query.getAdditionPosition.status = "error"
            state.query.getAdditionPosition.canExecute = true;
            state.query.getAdditionPosition.message = action.error.message;
        }).addCase(getAdditionPosition.fulfilled, (state, action) => {
            state.query.getAdditionPosition.status = "success"
            state.query.getAdditionPosition.message = undefined;
            state.query.getAdditionPosition.canExecute = true;

            const payload = action.payload.getData();
            state.loadedData.id = payload.id;
            state.actualData.name = payload.name;
            state.actualData.selectedAdditionPositionTranslation = state.actualData.additionPositionTranslations.find(x => x.key === payload.translationKeyForName);
            payload.selectedPositionMassPointCriterias.forEach(x => {
                const id = state.actualData.masspointSelection.unSelectedList.allItems.find(y =>
                    y.massPointName === x.massPointName
                    && y.massPointType === x.massPointType
                    && y.bodyArea.id === x.bodyAreaId).id;
                moveFromUnselectedToSelected(state, [id]);
            });
            payload.selectedPositionHierarchys.forEach(x => {
                state.actualData.additionHierarchies.find(y => y.id === x.additionHierarchy.id).isSelected = true;
            });
        })
    }
});


export const {
    resetState,
    cancelSaveAdditionPosition,
    updateAdditionPositionName,
    filterSelectedMasspoints,
    filterUnselectedMasspoints,
    selectMasspoints,
    unselectMasspoints,
    setSelectionAdditionHierarchy,
    selectTranslation,
    handleSaveError,
    setEditorMode,
    setShowConfirmCreateDialog,
} = additionPositionEditSlice.actions

export default additionPositionEditSlice.reducer