import { doesExist, isNullOrWhitespace } from "services/validation.service";
import { PolicyDefaultAdditionByQualityAndAttributeCreateState } from "./policy_default_addition_by_qu_and_att_create.model";
import { IAdditionFilterList, IAdditionFilterListItem, IAttributeCategoryViewModel, IAttributeValueViewModel, IDefaultAdditionByQualityAndAttributeConfigurationViewModel } from "../../components/policy_default_addition_by_qu_and_att.model";
import { IAvailableAdditionAttributeCategory } from "models/addition_attribute_categories/available_addition_attribute_category";
import IAdditionOverview from "models/additions/addition_overview";
import { filterSelectionList } from "shared/components/selectionComponent/selectionList.helper";

export const updateCanSave = (state: PolicyDefaultAdditionByQualityAndAttributeCreateState) => {
    state.command.savePolicy.canExecute =
        !isNullOrWhitespace(state.data.policy.name)
        && doesExist(state.data.selectedQuality)
        && configurationsValid(state.data.attributeConfigurations)
}


const configurationsValid = (configurations: IDefaultAdditionByQualityAndAttributeConfigurationViewModel[]) => {
    return configurations.length > 0
        && configurations.filter(conf => conf.selectedAttributeValue == null || conf.additions.selectedList.allItems.length === 0).length === 0;
}

export const createAttributeCategory = (state: PolicyDefaultAdditionByQualityAndAttributeCreateState): IAttributeCategoryViewModel[] => {
    return state.loadedData.attributeCategories.map(ac => {
        return {
            displayName: ac.name,
            id: ac.id,
            attributes: ac.availableAdditionAttributes.map(at => {
                return {
                    displayName: at.name,
                    id: at.id
                }
            })
        }
    });
}

export const createAdditions = (additions: IAdditionOverview[]): IAdditionFilterList => {
    return {
        allItems: additions.map(add => {
            return {
                id: add.id,
                displayName: `${add.option}`,
            }
        }),
        filteredItems: [],
        searchText: ""
    };
}

export const calculateAvailableAttributes = (configurations: IDefaultAdditionByQualityAndAttributeConfigurationViewModel[], additionAttributeCategoriesLoaded: IAvailableAdditionAttributeCategory[]): IDefaultAdditionByQualityAndAttributeConfigurationViewModel[] => {
    additionAttributeCategoriesLoaded.forEach(lcat => {
        const selectedAttributes = [];
        const categoryConfigurations = configurations.filter(conf => conf.selectedAttributeCategoryValue && conf.selectedAttributeCategoryValue.id === lcat.id);
        categoryConfigurations.filter(x => x.selectedAttributeValue)
            .forEach(x => selectedAttributes.push(x.selectedAttributeValue));

        const availableAttributes = [];
        lcat.availableAdditionAttributes.forEach(x => {
            if (selectedAttributes.length === 0 || selectedAttributes.findIndex(y => y.id === x.id) === -1) {
                availableAttributes.push({
                    id: x.id,
                    displayName: `${x.code} - ${x.name}`
                });
            }
        });

        configurations.filter(conf => conf.selectedAttributeCategoryValue && conf.selectedAttributeCategoryValue.id === lcat.id)
            .forEach(x => {
                x.attributeValues = [...availableAttributes];
                if (x.selectedAttributeValue) {
                    x.attributeValues.push(x.selectedAttributeValue);
                }
                x.attributeValues.sort(sortAttributeValue);
            });
    })
    return configurations;
}

const sortAttributeValue = (atr1: IAttributeValueViewModel, atr2: IAttributeValueViewModel) => {
    return atr1.displayName.localeCompare(atr2.displayName);
}

export const moveFromUnselectedToSelected = (configuration: IDefaultAdditionByQualityAndAttributeConfigurationViewModel, ids: number[]) => {
    const selectedList = configuration.additions.selectedList;
    const unselectedList = configuration.additions.unSelectedList;

    ids.forEach(id => {
        const itemToMove = configuration.additions.unSelectedList.allItems.find(x => x.id === id);
        moveFromSourceToDestination(unselectedList, selectedList, itemToMove);
    });

    filterSelectionList(selectedList);
    filterSelectionList(unselectedList);
}

export const moveFromSelectedToUnselected = (configuration: IDefaultAdditionByQualityAndAttributeConfigurationViewModel, ids: number[]) => {
    const selectedList = configuration.additions.selectedList;
    const unselectedList = configuration.additions.unSelectedList;

    ids.forEach(id => {
        const itemToMove = configuration.additions.selectedList.allItems.find(x => x.id === id);
        moveFromSourceToDestination(selectedList, unselectedList, itemToMove);
    });

    filterSelectionList(selectedList);
    filterSelectionList(unselectedList);
}

export const moveFromSourceToDestination = (source: IAdditionFilterList, destination: IAdditionFilterList, itemToMove: IAdditionFilterListItem) => {
    const indexInAll = source.allItems.findIndex(x => x.id === itemToMove.id);
    destination.allItems.push(itemToMove);
    source.allItems.splice(indexInAll, 1);
}
