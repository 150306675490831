import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/redux_store';
import { handleResponseErrors } from '../../../../shared/networking/error_handling';
import SerialSizeService from 'services/serial_size.service';
import BaseDataService from 'services/base_data.service';

export const fetchSerialSizesPreviews = createAsyncThunk(
    'serial_sizes/serial_sizes_preview/fetchSerialSizesPreviews',
    async (_, { getState }) => {
        const state = getState() as RootState
        const actualData = state.serial_sizes_preview.actualData;
        const response = await SerialSizeService.getSerialSizeByArticleTypeAndQuality(actualData.quality.erpId, actualData.articleType.erpId);
        handleResponseErrors(response, "Seriengrößen");
        return response;
    }
)

export const fetchBaseData = createAsyncThunk(
    'serial_sizes/serial_sizes_preview/fetchBaseData',
        async (_) => {
            const response = await BaseDataService.getBaseData();
            handleResponseErrors(response, "Basisdaten");
            return response;
    }
)

export const finish = createAsyncThunk(
    'serial_sizes/serial_sizes_preview/close',
    async (_) => { }
)
