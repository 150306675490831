import { PolicyMandatoryCategoryUpdateState } from "./policy_mandatory_category_update.model";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAdditionCategoryOverview } from "models/addition_categories/addition_category_overview";
import { cancelSave, getAdditionCategories, getPolicy, initData, updatePolicy } from "./policy_mandatory_category_update.thunks";
import { updateCanSave } from "./policy_mandatory_category_update.reducer";
import { sortAdditionCategories } from "check/policy/helper/policy.helper";

const initialState: PolicyMandatoryCategoryUpdateState = {
    data: { 
        policy: { name: "", ignoreOnQuotation: false},
        selectedCategory: null,
    },
    loadedData: {
        additionCategories: [],
        policy: null,
    },
    command:{
        updatePolicy: { status: "idle", canExecute: false },
        cancelSave: { status: "idle", canExecute: true }
    },
    query: {
        fetchAdditionCategories: { status: "idle", canExecute: true },
        fetchPolicy: { status: "idle", canExecute: true },
        initData: { status: "idle", canExecute: true },
    }
}

export const updatePolicyMandatoryCategory = createSlice({
    name: 'update_policy_mandatory_category',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = initialState.data;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        changeName: (state, action: PayloadAction<string>) => {
            state.data.policy.name = action.payload;
            updateCanSave(state);
        },
        selectCategory: (state, action: PayloadAction<IAdditionCategoryOverview>) => {
            state.data.selectedCategory = action.payload;
            updateCanSave(state);
        },
        toggleIgnoreOnQuotation: (state, action: PayloadAction<boolean>) => {
            state.data.policy.ignoreOnQuotation = action.payload;
            updateCanSave(state);
        },            
        completedSave: (state) => {
            state.command.updatePolicy = initialState.command.updatePolicy;
        }
    }, extraReducers: (builder) => {

        // fetchPolicies
        builder.addCase(getAdditionCategories.pending, (state) => {
            state.query.fetchAdditionCategories.status = "pending"
            state.query.fetchAdditionCategories.canExecute = false;
        }).addCase(getAdditionCategories.rejected, (state, action) => {
            state.query.fetchAdditionCategories.status = "error"
            state.query.fetchAdditionCategories.message = action.error.message;
            state.query.fetchAdditionCategories.canExecute = true;
        }).addCase(getAdditionCategories.fulfilled, (state, action) => {
            state.query.fetchAdditionCategories.status = "success"
            state.query.fetchAdditionCategories.canExecute = true;
            state.loadedData.additionCategories = action.payload.getData()
                .sort(sortAdditionCategories);
   
        // fetchPolicy
        }).addCase(getPolicy.pending, (state) => {
            state.query.fetchPolicy.status = "pending"
            state.query.fetchPolicy.canExecute = false;
        }).addCase(getPolicy.rejected, (state, action) => {
            state.query.fetchPolicy.status = "error"
            state.query.fetchPolicy.message = action.error.message;
            state.query.fetchPolicy.canExecute = true;
        }).addCase(getPolicy.fulfilled, (state, action) => {
            state.query.fetchPolicy.status = "success"
            state.query.fetchPolicy.canExecute = true;
            state.loadedData.policy = action.payload.getData();

        // updatePolicy
        }).addCase(updatePolicy.pending, (state) => {
            state.command.updatePolicy.status = 'pending'
            state.command.updatePolicy.canExecute = false;
        }).addCase(updatePolicy.rejected, (state, action) => {
            state.command.updatePolicy.status = "error"
            state.command.updatePolicy.canExecute = true;
            state.command.updatePolicy.message = action.error.message;
        }).addCase(updatePolicy.fulfilled, (state) => {
            state.command.updatePolicy.status = "success"
            state.command.updatePolicy.canExecute = false;

        // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancelSave.status = 'pending'
            state.command.cancelSave.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancelSave.status = "success"
            state.command.cancelSave.canExecute = false;

            // initData
        }).addCase(initData.pending, (state) => {
            state.query.initData.status = "pending"
            state.query.initData.canExecute = false;
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.canExecute = true;
            state.query.initData.message = action.error.message;
        }).addCase(initData.fulfilled, (state) => {
            state.query.initData.status = "success"
            state.query.initData.canExecute = true;
            state.data.policy = { 
                id: state.loadedData.policy.id,
                name: state.loadedData.policy.name,
                ignoreOnQuotation: state.loadedData.policy.ignoreOnQuotation
            };
            state.data.selectedCategory = state.loadedData.additionCategories.find(x => x.id === state.loadedData.policy.additionCategoryId);
            updateCanSave(state);

        })
    }
})

export const {
    resetState,
    changeName,
    selectCategory,
    toggleIgnoreOnQuotation,
    completedSave,
} = updatePolicyMandatoryCategory.actions

export default updatePolicyMandatoryCategory.reducer