import { doesExist, isNullOrWhitespace } from "services/validation.service";
import { PolicyAdditionRankUpdateState } from "./policy_addition_rank_update.model";
import { IMassPointCriteriaItem } from '../../../components/policy_editor.model';
import { MassPointCriteria } from "models/masspoints/masspoint_criteria";
import { ISelectable } from "shared/components/selectionComponent/models/selectable";
import IMasspointOverview from "models/masspoints/masspoint_overview";
import { MasspointTypeEnumValuesLookup } from "models/masspoints/enums/masspoint_type.enum";
import { BodySideEnum } from "models/masspoints/enums/body_side.enum";
import { AdditionPlaceHolderTypeEnum } from "models/additions/addition_placeholder.enum";
import { distinctMassPointCriteria } from "shared/helpers/distinctMassPointCriteria";

export const updateCanSave = (state: PolicyAdditionRankUpdateState) => {
    state.command.updatePolicy.canExecute =
        !isNullOrWhitespace(state.data.policy.name)
        && doesExist(state.data.selectedAddition)
        && state.data.selectedAddition.placeholderType === AdditionPlaceHolderTypeEnum.RangeInput
        && doesExist(state.data.selectedMainProductLine)
        && doesExist(state.data.selectedBodyArea)        
        && doesExist(state.data.selectedMasspoint1)
        && doesExist(state.data.selectedMasspoint2)
        && containsArticleType(state)
        && isNotEqual(state);
}

const containsArticleType = (state: PolicyAdditionRankUpdateState) => {
    return state.data.articleTypes.selectedList.allItems.length > 0;
}

const isNotEqual = (state: PolicyAdditionRankUpdateState) => {
    return state.data.policy.name !== state.loadedData.policy.name 
        || state.data.policy.ignoreOnQuotation !== state.loadedData.policy.ignoreOnQuotation 
        || state.data.selectedAddition.id !== state.loadedData.policy.additionId
        || state.data.selectedMainProductLine.id !== state.loadedData.policy.mainProductLineId
        || state.data.selectedBodyArea.id !== state.loadedData.policy.bodyAreaId
        || masspointNotEquals(state.data.selectedMasspoint1, state.loadedData.policy.massPoint1)
        || masspointNotEquals(state.data.selectedMasspoint2, state.loadedData.policy.massPoint2)
        || haveArticleTypesChanged(state.data.articleTypes.selectedList.allItems, state.loadedData.policy.articleTypes)
}

const haveArticleTypesChanged = (assigned: ISelectable[], loadedArticleTypes: number[] ) => {
    let changedItems = false;
    
    loadedArticleTypes.forEach(x => {
        if(assigned.findIndex(y => y.id === x) === -1) {
            changedItems = true;
        }
    });
    return loadedArticleTypes.length !== assigned.length || changedItems;
}

const masspointNotEquals = (selectedMasspoint: IMassPointCriteriaItem, loadedMasspointCriteria: MassPointCriteria) => {
    return !(selectedMasspoint.bodyAreaId === loadedMasspointCriteria.bodyAreaId 
        && selectedMasspoint.name === loadedMasspointCriteria.massPointName
        && selectedMasspoint.massPointType === loadedMasspointCriteria.massPointType);
}

export const sortMasspointItems = (mp1: IMassPointCriteriaItem, mp2: IMassPointCriteriaItem) => {
    return mp1.displayName.localeCompare(mp2.displayName);
}

export const createMasspointItemList = (massPoints: IMasspointOverview[]) : IMassPointCriteriaItem[] => {
    return  massPoints
                .filter(distinctMassPointCriteria)
                .filter(x => x.bodySide === BodySideEnum.Left || BodySideEnum.Right)
                .map(mp =>  { return {
                    id: mp.id,
                    displayName: `${mp.name} ${MasspointTypeEnumValuesLookup(mp.massPointType)} ${mp.bodyArea.name}`,
                    name: mp.name,
                    massPointType: mp.massPointType,
                    bodySide: mp.bodySide,
                    bodyAreaId: mp.bodyArea.id, 
    }})
};