import { PrimaryButtonComponent } from "./primary_button.component";
import ClearIcon from "@mui/icons-material/Clear";

export const CancelButtonComponent = () => {
  const handleBack = () => {
    window.history.back();
  };

  return (
    <PrimaryButtonComponent onClick={handleBack}>
      <ClearIcon /> Abbrechen
    </PrimaryButtonComponent>
  );
};
