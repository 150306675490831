import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseErrors } from "shared/networking/error_handling";
import AdditionService from 'services/addition.service';
import PolicyServiceAPI from 'services/policy.service';
import { PolicyTypeEnum } from "models/policies/policy_type.enum";
import BaseDataService from 'services/base_data.service';
import { RootState } from "app/redux_store";
import AdditionAttributeCategoryService from 'services/addition_attribute_category.service';

export const savePolicy = createAsyncThunk(
    'policy/create_policy_default_addition_by_quality_and_attribute/savepolicy',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.policy_default_addition_by_quality_and_attribute_create.data;
        const response = await PolicyServiceAPI.createDefaultAdditionByQualityAndAttribute({
            name: data.policy.name,
            ignoreOnQuotation: data.policy.ignoreOnQuotation,
            qualityId: data.selectedQuality.id,
            attributeConfigurationRequest: data.attributeConfigurations.map(att => { return {
                    attributeId: att.selectedAttributeValue.id,
                    additionIds: att.additions.selectedList.allItems.map(x => x.id)
                }
            })
        });
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const getBaseData = createAsyncThunk(
    'policy/create_policy_default_addition_by_quality_and_attribute/getBaseData',
    async (_) => {
        const response = await BaseDataService.getQualitiesForPolicy(PolicyTypeEnum.DefaultAdditionByQualityAndAttribute)
        handleResponseErrors(response, "Basisdaten");
        return response;
    }
)

export const getAvailableAttributeCategories = createAsyncThunk(
    'policy/create_policy_default_addition_by_quality_and_attribute/getAttributeCategories',
    async (_) => {
        const response = await AdditionAttributeCategoryService.getAvailableAttributeCategories()
        handleResponseErrors(response, "Sizes");
        return response;
    }
)

export const getAdditions = createAsyncThunk(
    'policy/create_policy_default_addition_by_quality_and_attribute/getAdditions',
    async (_) => {
        const response = await AdditionService.getAdditionsOverview();
        handleResponseErrors(response, "Zusätze");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'policy/create_policy_default_addition_by_quality_and_attribute/cancelSave',
    async (_) => { }
)

export const initData = createAsyncThunk(
    'policy/create_policy_default_addition_by_quality_and_attribute/initData',
    async (_, { dispatch }) => {
        await Promise.all([
            dispatch(getAdditions()),
            dispatch(getBaseData()),
            dispatch(getAvailableAttributeCategories())
        ]);
    }
)