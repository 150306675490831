import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IAvailableArticleType from 'models/available_basedata/available_article_type';
import IAvailableProductLine from 'models/available_basedata/available_product_line';
import IAvailableQuality from 'models/available_basedata/available_quality';
import { IBodyArea } from 'models/masspoints/body_area';
import { IPlausibilityCheckType } from 'models/plausibility_check/plausibility_check_type';
import { CreateCheckState } from './check_create.model';
import { updateCanCreateCheck } from './check_create.reducer';
import { createCheck, fetchBaseData, fetchPlausibilityCheckType, initData } from './check_create.thunks';

const initialState: CreateCheckState = {
    data: {
        checkCreate: { isSideSpecific: false },
        plausibilityChecks: [],
        baseData: {
            availableMainProductLines: [],
            availableArticleTypes: [],
            availableBodyAreas: [],
            availableQualities: [],
        },
        showIsUniqueRules: false
    },
    query: {
        fetchBaseData: { status: "idle", canExecute: true },
        fetchPlausibilityCheckType: { status: "idle", canExecute: true },
        initData: { status: "idle", canExecute: true },
    },
    command: {
        createCheck: { status: "idle", canExecute: false },
        cancelCheck: { status: "idle", canExecute: false }
    },
}

export const createCheckSlice = createSlice({
    name: 'create_check',
    initialState,
    reducers: {
        resetState: (state) => {
            state.command = initialState.command;
            state.query = initialState.query;
            state.data = initialState.data;
        },
        handleError: (state) => {
            state.command = {
                createCheck: { status: "idle", canExecute: true },
                cancelCheck: { status: "idle", canExecute: true }
            };
        },
        updateMainProductLine: (state, action: PayloadAction<IAvailableProductLine>) => {
            state.data.selectedMainProductLine = action.payload;
            state.data.checkCreate.mainProductLineId = action.payload.id;
            updateCanCreateCheck(state);
        },
        updateBodyArea: (state, action: PayloadAction<IBodyArea>) => {
            state.data.selectedBodyArea = action.payload;
            state.data.checkCreate.bodyAreaId = action.payload.id;
            updateCanCreateCheck(state);
        },
        updateArticleType: (state, action: PayloadAction<IAvailableArticleType>) => {
            state.data.selectedArticleType = action.payload;
            state.data.checkCreate.articleTypeId = action.payload.id;
            updateCanCreateCheck(state);
        },
        updateQuality: (state, action: PayloadAction<IAvailableQuality>) => {
            state.data.selectedQuality = action.payload;
            state.data.checkCreate.qualityId = action.payload.id;
            updateCanCreateCheck(state);
        },
        updatePlausibilityCheck: (state, action: PayloadAction<IPlausibilityCheckType>) => {
            state.data.selectedPlausibilityCheckType = action.payload;
            state.data.checkCreate.plausibilityCheckTypeId = action.payload.id;
            state.data.showIsUniqueRules = state.data.selectedPlausibilityCheckType != null ? true : false;
            updateCanCreateCheck(state);
        },
        updateIsSideSpecific: (state, action: PayloadAction<boolean>) => {
            state.data.checkCreate.isSideSpecific = action.payload;
            updateCanCreateCheck(state);
        },
        cancelCheck: (state) => {
            state.command.cancelCheck.status = "success";
        },
    }, extraReducers: (builder) => {

        // fetch PlausibiltyCheckType
        builder.addCase(fetchPlausibilityCheckType.pending, (state, action) => {
            state.query.fetchPlausibilityCheckType.status = "pending"
            state.query.fetchPlausibilityCheckType.canExecute = false;
        }).addCase(fetchPlausibilityCheckType.rejected, (state, action) => {
            state.query.fetchPlausibilityCheckType.status = "error"
            state.query.fetchPlausibilityCheckType.message = action.error.message;
            state.query.fetchPlausibilityCheckType.canExecute = true;
        }).addCase(fetchPlausibilityCheckType.fulfilled, (state, action) => {
            state.query.fetchPlausibilityCheckType.status = "success"
            state.query.fetchPlausibilityCheckType.message = undefined;
            state.query.fetchPlausibilityCheckType.canExecute = true;
            state.data.plausibilityChecks = action.payload.getData();

            // fetch BaseData
        }).addCase(fetchBaseData.pending, (state, action) => {
            state.query.fetchBaseData.status = "pending"
            state.query.fetchBaseData.canExecute = false;
        }).addCase(fetchBaseData.rejected, (state, action) => {
            state.query.fetchBaseData.status = "error"
            state.query.fetchBaseData.message = action.error.message;
            state.query.fetchBaseData.canExecute = true;
        }).addCase(fetchBaseData.fulfilled, (state, action) => {
            const dataLoaded = action.payload.getData();
            state.data.baseData = {
                availableMainProductLines: dataLoaded.availableMainProductLines,
                availableBodyAreas: dataLoaded.availableBodyAreas,
                availableArticleTypes: [{ name: "Alle", id: null, erpId: null }, ...dataLoaded.availableArticleTypes],
                availableQualities: [{ name: "Alle", id: null, erpId: null }, ...dataLoaded.availableQualities],
            }

            state.query.fetchBaseData.status = "success"
            state.query.fetchBaseData.message = undefined;
            state.query.fetchBaseData.canExecute = true;

            // create check
        }).addCase(createCheck.pending, (state, action) => {
            state.command.createCheck.status = "pending"
            state.command.createCheck.canExecute = false;
        }).addCase(createCheck.rejected, (state, action) => {
            state.command.createCheck.status = "error"
            state.command.createCheck.canExecute = true;
            state.command.createCheck.message = action.error.message;
            if (action.error.code === "409") {
                state.command.createCheck.status = "warning"
            }
        }).addCase(createCheck.fulfilled, (state, action) => {
            state.command.createCheck.status = "success"
            state.command.createCheck.message = undefined;
            state.command.createCheck.canExecute = true;
            state.data.checkCreate.checkId = action.payload.getData();

            // initData
        }).addCase(initData.pending, (state, action) => {
            state.query.initData.status = "pending"
            state.query.initData.canExecute = false;
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.canExecute = true;
            state.query.initData.message = action.error.message;
        }).addCase(initData.fulfilled, (state, action) => {
            state.query.initData.status = "success"
            state.query.initData.message = undefined;
            state.query.initData.canExecute = true;
            state.data.selectedArticleType = state.data.baseData.availableArticleTypes.find(item => item.id == null)
            state.data.selectedBodyArea = state.data.baseData.availableBodyAreas.find(item => item.id == null)
            state.data.selectedQuality = state.data.baseData.availableQualities.find(item => item.id == null)
        })
    }
})

export const {
    updateMainProductLine,
    updateBodyArea,
    updateArticleType,
    updateQuality,
    updatePlausibilityCheck,
    updateIsSideSpecific,
    cancelCheck,
    resetState,
    handleError,
} = createCheckSlice.actions

export default createCheckSlice.reducer