import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductionInstructionState } from "./production_instruction.model";
import { YesNoOptions } from "../../../../models/boolean_radio_box.options";
import { EnumDescriptor } from "../../../../models/enum_descriptor";
import { IProductionInstructionCalingaKey } from "../../../../models/production_instructions/production_instruction_calinga_key";
import { loadedProductionInstructionToState, updateCanSave } from "./production_instruction.reducer";
import { getProductionInstructionCalingaKeys, intiData, getProductionInstruction, updateProductionInstruction, createProductionInstruction, getMappedBaseData } from "./production_instruction.thunks";
import { EditorModeEnum } from "models/editors/editor_mode.enum";
import { ISelectable } from "shared/components/selectionComponent/models/selectable";
import { calculateArticleTypeSelection, calculateDependentFields, calculateNewValidityScope, calculateQualitiesSelection, createEditValidityScope, isEditableValidityScopeValid } from "additions/common/helpers/validityScope";
import { filterSelectionList, moveFromSelectedToUnselected, moveFromUnselectedToSelected } from "shared/components/selectionComponent/selectionList.helper";
import { LockTypeEnum } from "shared/components/selectionComponent/models/locktype.enum";


const initialState: ProductionInstructionState = {
    loadedData: {
        calingaKeys: [],
        mappedBaseData: null,
    },
    actualData: {
        editorMode: EditorModeEnum.Create,
        id: 0,
        productionInstructionEditor: {
            symbol: '',
            isDocumentsEnabled: YesNoOptions.find(x => x.value === false),
            isFreeTextEnabled: YesNoOptions.find(x => x.value === false),
            isAutomaticProductionEnabled: YesNoOptions.find(x => x.value === false),
            calingaKey: null,
            validityScopes: [],
            editableValidityScope: null,
            editableValidityScopeIndex: -1,
            validityScopeDeletingIndex: -1,
            errorMessageTitle: "",
            errorMessageDescription: "",
            showDeleteValidityScopeDialog: false,
            showDismissValidityAreaDialog: false,
            showErrorMessageDialog: false
        },
    },
    query: {
        initData: { status: "idle", canExecute: true},
        getProductionInstructionCalingaKeys: { status: "idle",canExecute: false},
        getProductionInstruction: { status: "idle",canExecute: false},
        getMappedBaseData: { status: "idle", canExecute: true },
    },
    command: {
        createProductionInstruction: { status: "idle", canExecute: false },
        updateProductionInstruction: {
            status: "idle",
            canExecute: false
        },
    }

}

export const productionInstructionEditSlice = createSlice({
    name: 'production-instruction/edit',
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.query = initialState.query;
            state.command = initialState.command;
        },
        updateProductionInstructionErrorCompleted: (state) => {
            state.command.updateProductionInstruction.status = "idle";
        },
        updateProductionInstructionCompleted: (state) => {
            state.command.updateProductionInstruction.status = "idle";
        },
        createProductionInstructionErrorCompleted: (state) => {
            state.command.createProductionInstruction.status = "idle";
        }, 
        createProductionInstructionCompleted: (state) => {
            state.command.createProductionInstruction.status = "idle";
        },
        selectSymbol: (state, action: PayloadAction<string>) => {
            state.actualData.productionInstructionEditor.symbol = action.payload;
            updateCanSave(state);
        },
        selectIsDocumentsEnabled: (state, action: PayloadAction<EnumDescriptor<boolean>>) => {
            state.actualData.productionInstructionEditor.isDocumentsEnabled = action.payload;
            updateCanSave(state);
        },
        selectIsFreetextEnabled: (state, action: PayloadAction<EnumDescriptor<boolean>>) => {
            state.actualData.productionInstructionEditor.isFreeTextEnabled = action.payload;
            updateCanSave(state);
        },
        selectIsAutoProductionEnabled: (state, action: PayloadAction<EnumDescriptor<boolean>>) => {
            state.actualData.productionInstructionEditor.isAutomaticProductionEnabled = action.payload;
            updateCanSave(state);
        },
        selectCalingaKey: (state, action: PayloadAction<IProductionInstructionCalingaKey>) => {
            state.actualData.productionInstructionEditor.calingaKey = action.payload;
            updateCanSave(state);
        },
        setEditorMode: (state, action: PayloadAction<EditorModeEnum>) => {
            state.actualData.editorMode = action.payload
        },
        updateBodyArea: (state, action: PayloadAction<ISelectable>) => {
            const editableScope = state.actualData.productionInstructionEditor.editableValidityScope;
            editableScope.bodyArea = action.payload;
            editableScope.articleTypes = calculateArticleTypeSelection(editableScope, state.loadedData.mappedBaseData);
            editableScope.qualities = calculateQualitiesSelection(editableScope, state.loadedData.mappedBaseData);
            updateCanSave(state)
        },
        updateMainProductLine: (state, action: PayloadAction<ISelectable>) => {
            const editableValidityScope = state.actualData.productionInstructionEditor.editableValidityScope;
            editableValidityScope.mainProductLine = action.payload;
            calculateDependentFields(editableValidityScope, state.loadedData.mappedBaseData);
            updateCanSave(state);
        },
        deleteValidityScope: (state, action: PayloadAction<number>) => {
            state.actualData.productionInstructionEditor.validityScopeDeletingIndex = action.payload;
            state.actualData.productionInstructionEditor.showDeleteValidityScopeDialog = true;
        },
        addValidityScope: (state) => {
            state.actualData.productionInstructionEditor.editableValidityScope = calculateNewValidityScope();
            updateCanSave(state);
        },
        editScopeConfiguration: (state, action: PayloadAction<number>) => {
            state.actualData.productionInstructionEditor.editableValidityScopeIndex = action.payload;
            state.actualData.productionInstructionEditor.editableValidityScope = createEditValidityScope(state.actualData.productionInstructionEditor.validityScopes[action.payload]);
            const editableValidityScope = state.actualData.productionInstructionEditor.editableValidityScope;
            editableValidityScope.articleTypes = calculateArticleTypeSelection(editableValidityScope, state.loadedData.mappedBaseData);
            editableValidityScope.qualities = calculateQualitiesSelection(editableValidityScope, state.loadedData.mappedBaseData);
            filterSelectionList(editableValidityScope.articleTypes.selectedList);
            filterSelectionList(editableValidityScope.articleTypes.unSelectedList);
            filterSelectionList(editableValidityScope.qualities.selectedList);
            filterSelectionList(editableValidityScope.qualities.unSelectedList);
            updateCanSave(state);
        },
        applyEditableScope: (state) => {
            const editableValidityScopeValid = isEditableValidityScopeValid(state.actualData.productionInstructionEditor.editableValidityScope, state.loadedData.mappedBaseData);
            if (editableValidityScopeValid) {
                const editor = state.actualData.productionInstructionEditor;
                if (editor.editableValidityScopeIndex === -1) {
                    editor.validityScopes = [...editor.validityScopes, editor.editableValidityScope];
                }
                else {
                    editor.validityScopes = editor.validityScopes.map((vscope, index) =>
                        index === editor.editableValidityScopeIndex ? editor.editableValidityScope : vscope
                    );
                }
                editor.editableValidityScope = null;
                editor.editableValidityScopeIndex = -1;
                updateCanSave(state);
            }
            else {
                state.actualData.productionInstructionEditor.showErrorMessageDialog = true;
                state.actualData.productionInstructionEditor.errorMessageTitle = "Fehler beim Übernehmen";
                state.actualData.productionInstructionEditor.errorMessageDescription = "Die Konfiguration kann nicht übernommen werden, da sie ungültige Werte enthält.";
            }
        },
        cancelDeleteValidityScope: (state) => {
            state.actualData.productionInstructionEditor.showDeleteValidityScopeDialog = false;
            state.actualData.productionInstructionEditor.validityScopeDeletingIndex = -1;
        },
        confirmDeleteValidityScope: (state) => {
            const validityScopeDeletingIndex = state.actualData.productionInstructionEditor.validityScopeDeletingIndex;
            state.actualData.productionInstructionEditor.validityScopes = state.actualData.productionInstructionEditor.validityScopes.filter((x, index) => validityScopeDeletingIndex !== index);
            state.actualData.productionInstructionEditor.validityScopeDeletingIndex = -1;
            state.actualData.productionInstructionEditor.showDeleteValidityScopeDialog = false;
            updateCanSave(state);
        },
        confirmError: (state) => {
            state.actualData.productionInstructionEditor.showErrorMessageDialog = false;
        },
        confirmDismissChanges: (state) => {
            state.actualData.productionInstructionEditor.editableValidityScope = null;
            state.actualData.productionInstructionEditor.editableValidityScopeIndex = -1;
            updateCanSave(state);
        },
        cancelDismissChanges: (state) => {
            state.actualData.productionInstructionEditor.showDismissValidityAreaDialog = false;
        },
        cancelEditableScope: (state) => {
            state.actualData.productionInstructionEditor.showDismissValidityAreaDialog = true;
        },
        filterSelectedArticleType: (state, action: PayloadAction<string>) => {
            const editableValidityScope = state.actualData.productionInstructionEditor.editableValidityScope;
            editableValidityScope.articleTypes.selectedList.searchText = action.payload;
            filterSelectionList(editableValidityScope.articleTypes.selectedList);
        },
        filterUnselectedArticleType: (state, action: PayloadAction<string>) => {
            const editableValidityScope = state.actualData.productionInstructionEditor.editableValidityScope;
            editableValidityScope.articleTypes.unSelectedList.searchText = action.payload;
            filterSelectionList(editableValidityScope.articleTypes.unSelectedList);
        },
        filterSelectedQuality: (state, action: PayloadAction<string>) => {
            const editableValidityScope = state.actualData.productionInstructionEditor.editableValidityScope;
            editableValidityScope.qualities.selectedList.searchText = action.payload;
            filterSelectionList(editableValidityScope.qualities.selectedList);
        },
        filterUnselectedQuality: (state, action: PayloadAction<string>) => {
            const editableValidityScope = state.actualData.productionInstructionEditor.editableValidityScope;
            editableValidityScope.qualities.unSelectedList.searchText = action.payload;
            filterSelectionList(editableValidityScope.qualities.unSelectedList);
        },
        selectArticleTypes: (state, action: PayloadAction<number[]>) => {
            const editableValidityScope = state.actualData.productionInstructionEditor.editableValidityScope;
            moveFromUnselectedToSelected(editableValidityScope.articleTypes, action.payload);
            editableValidityScope.qualities = calculateQualitiesSelection(editableValidityScope, state.loadedData.mappedBaseData);
        },
        unselectArticleTypes: (state, action: PayloadAction<number[]>) => {
            const editableValidityScope = state.actualData.productionInstructionEditor.editableValidityScope;
            moveFromSelectedToUnselected(editableValidityScope.articleTypes, action.payload);
            editableValidityScope.qualities = calculateQualitiesSelection(editableValidityScope, state.loadedData.mappedBaseData);
        },
        selectQualities: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.actualData.productionInstructionEditor.editableValidityScope.qualities, action.payload);
        },
        unselectQualities: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.actualData.productionInstructionEditor.editableValidityScope.qualities, action.payload);
        },
        allowArticleType: (state) => {
            const editableValidityScope = state.actualData.productionInstructionEditor.editableValidityScope;
            editableValidityScope.articleTypes.lockType = LockTypeEnum.Allow;
            editableValidityScope.qualities = calculateQualitiesSelection(editableValidityScope, state.loadedData.mappedBaseData);
        },
        allowQuality: (state) => {
            const editableValidityScope = state.actualData.productionInstructionEditor.editableValidityScope;
            editableValidityScope.qualities.lockType = LockTypeEnum.Allow;
        },
        denyArticleType: (state) => {
            const editableValidityScope = state.actualData.productionInstructionEditor.editableValidityScope;
            editableValidityScope.articleTypes.lockType = LockTypeEnum.Deny;
            editableValidityScope.qualities = calculateQualitiesSelection(editableValidityScope, state.loadedData.mappedBaseData);
        },
        denyQuality: (state) => {
            const editableValidityScope = state.actualData.productionInstructionEditor.editableValidityScope;
            editableValidityScope.qualities.lockType = LockTypeEnum.Deny;
        },

    },
    extraReducers: (builder) => {
        builder.addCase(createProductionInstruction.pending, (state, action) => {
            state.command.createProductionInstruction.status = 'pending'
            state.command.createProductionInstruction.canExecute = false;
        }).addCase(createProductionInstruction.rejected, (state, action) => {
            state.command.createProductionInstruction.status = "error"
            state.command.createProductionInstruction.canExecute = true;
            state.command.createProductionInstruction.message = action.error.message;
        }).addCase(createProductionInstruction.fulfilled, (state, action) => {
            state.command.createProductionInstruction.status = "success"
            state.command.createProductionInstruction.canExecute = true;

        }).addCase(updateProductionInstruction.pending, (state, action) => {
            state.command.updateProductionInstruction.status = 'pending'
            state.command.updateProductionInstruction.canExecute = false;
        }).addCase(updateProductionInstruction.rejected, (state, action) => {
            state.command.updateProductionInstruction.status = "error"
            state.command.updateProductionInstruction.canExecute = true;
            state.command.updateProductionInstruction.message = action.error.message;
        }).addCase(updateProductionInstruction.fulfilled, (state, action) => {
            state.command.updateProductionInstruction.status = "success"
            state.command.updateProductionInstruction.canExecute = true;
        
        }).addCase(intiData.pending, (state, action) => {
            state.query.initData.status = 'pending'
            state.query.initData.canExecute = false;
        }).addCase(intiData.rejected, (state, action) => {
            state.query.initData.status = 'error'
            state.query.initData.canExecute = false;
        }).addCase(intiData.fulfilled, (state, action) => {
            state.query.initData.status = 'success'
            state.query.initData.canExecute = false;

        }).addCase(getMappedBaseData.pending, (state, action) => {
            state.query.getMappedBaseData.status = 'pending';
            state.query.getMappedBaseData.canExecute = false;
        }).addCase(getMappedBaseData.rejected, (state, action) => {
            state.query.getMappedBaseData.status = "error";
            state.query.getMappedBaseData.canExecute = true;
            state.query.getMappedBaseData.message = action.error.message;
        }).addCase(getMappedBaseData.fulfilled, (state, action) => {
            state.query.getMappedBaseData.status = "success";
            state.query.getMappedBaseData.canExecute = true;
            const mappedBaseData = action.payload.getData();
            state.loadedData.mappedBaseData = mappedBaseData;
        
        }).addCase(getProductionInstruction.pending, (state, action) => {
            state.query.getProductionInstruction.status = 'pending'
            state.query.getProductionInstruction.canExecute = false;
        }).addCase(getProductionInstruction.rejected, (state, action) => {
            state.query.getProductionInstruction.status = 'error'
            state.query.getProductionInstruction.canExecute = false;
        }).addCase(getProductionInstruction.fulfilled, (state, action) => {
            state.query.getProductionInstruction.status = 'success'
            state.query.getProductionInstruction.canExecute = false;
            const prodInstruction = action.payload.getData();
            loadedProductionInstructionToState(state,prodInstruction);
                
        }).addCase(getProductionInstructionCalingaKeys.pending, (state, action) => {
            state.query.getProductionInstructionCalingaKeys.status = 'pending'
            state.query.getProductionInstructionCalingaKeys.canExecute = false;
        }).addCase(getProductionInstructionCalingaKeys.rejected, (state, action) => {
            state.query.getProductionInstructionCalingaKeys.status = "error"
            state.query.getProductionInstructionCalingaKeys.canExecute = true;
        }).addCase(getProductionInstructionCalingaKeys.fulfilled, (state, action) => {
            state.query.getProductionInstructionCalingaKeys.status = "success"
            state.query.getProductionInstructionCalingaKeys.canExecute = true;
            const keys = action.payload.getData();
            state.loadedData.calingaKeys = keys;
        })
    }
})

export const {
    resetState,
    updateProductionInstructionErrorCompleted,
    updateProductionInstructionCompleted,  
    createProductionInstructionErrorCompleted,
    createProductionInstructionCompleted,
    selectSymbol,
    selectIsDocumentsEnabled,
    selectIsFreetextEnabled,
    selectIsAutoProductionEnabled,
    selectCalingaKey,
    setEditorMode,
    filterSelectedArticleType,
    filterUnselectedArticleType,
    selectArticleTypes,
    unselectArticleTypes,
    allowArticleType,
    denyArticleType,
    filterSelectedQuality,
    filterUnselectedQuality,
    selectQualities,
    unselectQualities,
    allowQuality,
    denyQuality,
    updateMainProductLine,
    updateBodyArea,
    editScopeConfiguration,
    addValidityScope,
    applyEditableScope,
    cancelEditableScope,
    confirmDismissChanges,
    confirmError,
    cancelDeleteValidityScope,
    confirmDeleteValidityScope,
    cancelDismissChanges,
    deleteValidityScope
} = productionInstructionEditSlice.actions


export default productionInstructionEditSlice.reducer