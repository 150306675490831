import { GridFilterOperator, GridFilterItem, GridColDef, GridFilterInputValue } from "@mui/x-data-grid-pro";

export const CustomFilterOperators = (type: string = 'string'): GridFilterOperator[]  => {
    return [{  
        label: 'enthält',
        value: 'includes',
        getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {return null},
        InputComponent:  GridFilterInputValue, 
        InputComponentProps: { type: type }
    }];
}