import AddIcon from "@mui/icons-material/Add";
import { AppDispatch } from "app/redux_store";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { AdditionCategoryDeleteDialogComponent } from './components/addition_category_delete_dialog.component';
import {
  resetState,
  deleteAdditionCategory,
  resetDeleteAdditionCategory,
  setGridFilters,
  setSearchFilter,
} from "./redux/addition_category_overview.slice";
import { createNewAdditionCategory, getAdditionCategories } from './redux/addition_category_overview.thunks';
import * as style from "./additioncategory_overview.style";
import { useNavigate } from "react-router-dom";
import SearchFieldComponent from "shared/components/search/searchfield.component";
import { getGridColumns, sortByKeyModel } from "./redux/addition_category_overview.reducer";
import { OverviewDatagrid } from "shared/components/datagrid/overview_datagrid.component";
import { useAppDispatch, useAppSelector } from "app/hook";
import { LoadingIndicatorComponent, FlexBox, Left, Right, PrimaryButtonComponent } from "shared/shared";

export const AdditionCategoriesOverviewComponent = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const state = useAppSelector((state) => state.addition_category_overview);

  useEffect(() => {
    dispatch(getAdditionCategories());
    return () => {
      dispatch(resetState());
    }
  }, [dispatch]);

  if (state.query.getAdditionCategories.status === "error") {
    enqueueSnackbar(state.query.getAdditionCategories.message, { variant: "error" });
  }

  if (state.query.getAdditionCategories.status !== "success") {
    return <LoadingIndicatorComponent />;
  }

  if (state.command.createNewAdditionCategory.status === "pending") {
    navigate(`/additions/additioncategory/create`);
  }

  if (state.command.confirmDeleteAdditionCategory.status === "error") {
    enqueueSnackbar(state.command.confirmDeleteAdditionCategory.message, { variant: "warning" });
    dispatch(resetDeleteAdditionCategory());
  }

  if (state.command.confirmDeleteAdditionCategory.status === "success") {
    enqueueSnackbar("Zusatzkategorie erfolgreich gelöscht", { variant: "success" });
    dispatch(resetDeleteAdditionCategory());
  }

  const isLoading = state.command.confirmDeleteAdditionCategory.status === "pending";
  const additionalCategoriesAvailable = state.loadedData.additionCategories?.length > 0;

  return (
    <div>
      {state.actualData.showDeleteDialog && <AdditionCategoryDeleteDialogComponent />}
      <FlexBox>
        {
          additionalCategoriesAvailable &&
          <Left>
            <SearchFieldComponent onSearch={(filterText) => dispatch(setSearchFilter(filterText))}
              placeholder="In Zusatzkategorien suchen ..." />
          </Left>
        }
        <Right>
          <PrimaryButtonComponent onClick={() => dispatch(createNewAdditionCategory())}>
            <AddIcon /> Neue Zusatzkategorie erstellen
          </PrimaryButtonComponent>
        </Right>
      </FlexBox>
      <style.TableContainer>
        {
          !additionalCategoriesAvailable &&
          <div>Keine Zusatzkategorien verfügbar</div>
        }
        {
          additionalCategoriesAvailable &&
          <OverviewDatagrid
            isLoading={isLoading}
            columns={getGridColumns((additionCategory) => dispatch(deleteAdditionCategory(additionCategory)))}
            rows={state.actualData.additionCategories}
            loadedRowsCount={state.loadedData.additionCategories.length}
            sortModel={sortByKeyModel}
            onFilterModelChange={(newFilterModel) => dispatch(setGridFilters(newFilterModel))}
          />
        }
      </style.TableContainer>
    </div>
  );
};