import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from '../../../../app/redux_store';
import { handleResponseErrors } from '../../../../shared/networking/error_handling';
import { ProductionInstructionService } from 'services/production_instruction.service';
import { EditorModeEnum } from "models/editors/editor_mode.enum";
import { setEditorMode } from "./production_instruction.slice";
import BaseDataService from "services/base_data.service";
import { toValidityScopeRequests } from "additions/common/helpers/validityScope";

export const createProductionInstruction = createAsyncThunk(
    'production-instruction/createProductionInstruction',
    async (_, {getState,dispatch}) => {
    const state = getState() as RootState;
    var data = state.production_instruction.actualData.productionInstructionEditor;
    const response = await ProductionInstructionService.create({
        symbol: data.symbol,
        calingaKey: data.calingaKey.key,
        isFreeTextEnabled: data.isFreeTextEnabled.value,
        isDocumentsEnabled: data.isDocumentsEnabled.value,
        isAutomaticProductionEnabled: data.isAutomaticProductionEnabled.value,
        validityScopes: toValidityScopeRequests(data.validityScopes)
    });
    handleResponseErrors(response, "Produktionsanweisung");
    return response;
})

export const updateProductionInstruction = createAsyncThunk(
    'production-instruction/updateProductionInstruction',
     async (_, {getState, dispatch}) => {

    const state = getState() as RootState;
    var data = state.production_instruction.actualData;
    const response = await ProductionInstructionService.update({
        productionInstructionId: data.id,
        symbol: data.productionInstructionEditor.symbol,
        calingaKey: data.productionInstructionEditor.calingaKey.key,
        isFreeTextEnabled: data.productionInstructionEditor.isFreeTextEnabled.value,
        isDocumentsEnabled: data.productionInstructionEditor.isDocumentsEnabled.value,
        isAutomaticProductionEnabled: data.productionInstructionEditor.isAutomaticProductionEnabled.value,
        validityScopes: toValidityScopeRequests(data.productionInstructionEditor.validityScopes)
    });
    handleResponseErrors(response, "Produktionsanweisung");
    return response;
})

export const intiData = createAsyncThunk(
    'production-instruction/loadInitial',
     async (id: string, thunkAPI) => {

    await thunkAPI.dispatch(getProductionInstructionCalingaKeys());
    await thunkAPI.dispatch(getMappedBaseData());
    if(id){
        thunkAPI.dispatch(setEditorMode(EditorModeEnum.Edit));
        await thunkAPI.dispatch(getProductionInstruction(id));
    }
})

export const getProductionInstructionCalingaKeys = createAsyncThunk(
    'production-instruction/getCalingaKeys'
    , async (thunkApi) => {
    const response = await ProductionInstructionService.getCalingaKeys();
    handleResponseErrors(response, "Produktionsanweisung");
    return response;
})

export const getProductionInstruction = createAsyncThunk(
    'production-instruction/getProductionInstruction',
     async (id: string, thunkApi) => {
    const response = await ProductionInstructionService.getById(id);
    handleResponseErrors(response, "Produktionsanweisung");
    return response;
})

export const getMappedBaseData = createAsyncThunk(
    'production-instruction/initData/getMappedBaseData',
    async (thunkApi) => {
        const response = await BaseDataService.getMappedBaseData();
        handleResponseErrors(response, "Basisdaten");
        return response;
    }
)
