import FormControl from "@mui/material/FormControl";
import * as style from "./addition_compresssionclass.style";
import { DropdownComponent } from "shared/components/form_controls/dropdown/dropdown.component";
import theme from "shared/style/theme";
import { EnumDescriptor } from "models/enum_descriptor";
import { AdditionCompressionClassEnum } from "models/additions/addition_compressionclass.enum";

export interface AdditionCompressionClassComponentProps {
    data: Array<EnumDescriptor<AdditionCompressionClassEnum>>;
    selectedItem?: EnumDescriptor<AdditionCompressionClassEnum>;
    onSelect: (selectedItem: EnumDescriptor<AdditionCompressionClassEnum>) => void;
}

export const AdditionCompressionClassComponent: React.FC<AdditionCompressionClassComponentProps> = ({
    data,
    selectedItem,
    onSelect,
}) => {
    return (<div>
        <FormControl
            component="fieldset"
            style={{ minWidth: theme.form.minWidth }}
            required={true}>
            <style.RadioBoxHeader>Kompressionsklasse</style.RadioBoxHeader>
            <style.Description>Welche Kompressionsklasse hat der Zusatz?</style.Description>
            <DropdownComponent
                name="Kompressionsklasse"
                data={data}
                isLoading={false}
                isRequired={true}
                onSelect={(item: EnumDescriptor<AdditionCompressionClassEnum>) => onSelect(item)}
                displayedProperties={["text"]}
                selectedValue={selectedItem}
            />
        </FormControl>
    </div>
    );
};
