import React from "react";
import { LoadingIndicatorComponent, PrimaryButtonComponent } from "../../../../shared/shared";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

interface MassimageConfigUploadComponentProps {
    onFileSelected: (FileList) => void;
    onUpload: () => void;
    isUploading: boolean;
    isUploadAllowed: boolean;
}

export const MassimageConfigUploadComponent: React.FC<MassimageConfigUploadComponentProps> = ({ onFileSelected, onUpload, isUploading, isUploadAllowed }) => {
    return (<Card>
        <CardContent>
            <Grid alignItems="flex-end" container spacing={3}>
                <Grid item xs={12} sm={6} md={2} lg={2}>
                    Konfigfile hochladen
                </Grid>
                <Grid item xs={12} sm={6} md={7} lg={5}>
                    <input type="file" accept=".json" onChange={onFileSelected} />
                </Grid>
                <Grid item xs={12} sm={6} md={12} lg={2}>
                    <PrimaryButtonComponent disabled={!isUploadAllowed} onClick={onUpload}>Hochladen</PrimaryButtonComponent>
                    {isUploading ? <LoadingIndicatorComponent /> : <></>}
                </Grid>
            </Grid>
        </CardContent>
    </Card>
    );
}