import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInteractiveMasspoint, UpdateMassDefinitionState } from './massmask_masspoints.model';
import { toInteractiveMasspoint, updateMassPoints } from './massmask_masspoints.reducer';
import { activateMassPoints, cancelActivateMassPoints, getMassMask } from './massmask_masspoints.thunks';

const initialState: UpdateMassDefinitionState = {
    loadedData: {
        massmask: null,
        allMassPoints: [],
    },
    actualData: {
        massMaskId: null,
        massDefinitionId: null,
        measurementSheet: null,
        activeMassPointsIds: [],
        massImageContent: "",
        leftSideMassPoints: [],
        rightSideMassPoints: [],
        noSideMassPoints: [],
    },
    query: {
        getMassMask: { status: "idle", canExecute: true }
    },
    command: {
        activateMassPoints: { status: "idle", canExecute: true },
        cancelActivateMassPoints: { status: "idle", canExecute: true },
    },
}

export const massMaskMassPointsSlice = createSlice({
    name: 'massMask/massPoints',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loadedData = initialState.loadedData;
            state.actualData = initialState.actualData;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        toggleMassPointActive: (state, action: PayloadAction<IInteractiveMasspoint>) => {
            const masspoint = action.payload;
            const masspointsToChange = [...state.loadedData.allMassPoints];
            const index = masspointsToChange.findIndex(mp => mp.id === masspoint.id);
            masspointsToChange[index].isActive = !state.loadedData.allMassPoints[index].isActive
            updateMassPoints(state, masspointsToChange)
        },
        toggleMassPointHighlight: (state, action: PayloadAction<IInteractiveMasspoint>) => {
            const masspoint = action.payload;
            const masspointsToChange = [...state.loadedData.allMassPoints];
            const index = masspointsToChange.findIndex(mp => mp.id === masspoint.id);
            masspointsToChange[index].isHighlighted = !state.loadedData.allMassPoints[index].isHighlighted
            updateMassPoints(state, masspointsToChange)
        },
        updateMeasurementSheet: (state, action: PayloadAction<string>) => {
            state.actualData.measurementSheet = action.payload;
        },
        activateMassPointsCompleted: (state) => {
            state.command.activateMassPoints.status = "idle";
        },
    }, extraReducers: (builder) => {
        // getMassMask
        builder.addCase(getMassMask.pending, (state, action) => {
            state.query.getMassMask.status = 'pending'
            state.actualData.massMaskId = action.meta.arg.massMaskId;
            state.actualData.massDefinitionId = action.meta.arg.massDefinitionId;

        }).addCase(getMassMask.rejected, (state, action) => {
            state.query.getMassMask.status = "error"
            state.query.getMassMask.message = action.error.message;
        }).addCase(getMassMask.fulfilled, (state, action) => {
            state.query.getMassMask.status = "success"
            state.query.getMassMask.canExecute = true;
            const massMask = action.payload.getData();;
            state.loadedData.massmask = massMask;

            const massDefinition = massMask.massDefinitionVersions.find(massDefintion => massDefintion.id === state.actualData.massDefinitionId)
            const massPoints = massDefinition.massDefinitionVersionMassPoints.map(mp => toInteractiveMasspoint(mp, false));
            state.actualData.massImageContent = atob(massDefinition.massImage.content);
            updateMassPoints(state, massPoints);
            state.actualData.measurementSheet = massDefinition.measurementSheet;

            // activeMassPoints
        }).addCase(activateMassPoints.pending, (state, action) => {
            state.command.activateMassPoints.status = 'pending'
            state.command.activateMassPoints.canExecute = false;
        }).addCase(activateMassPoints.rejected, (state, action) => {
            state.command.activateMassPoints.status = "error"
            state.command.activateMassPoints.message = action.error.message;
            state.command.activateMassPoints.canExecute = true;
        }).addCase(activateMassPoints.fulfilled, (state, action) => {
            state.command.activateMassPoints.status = "success"
            state.command.activateMassPoints.canExecute = true;

            // cancelActivateMassPoints
        }).addCase(cancelActivateMassPoints.pending, (state, action) => {
            state.command.cancelActivateMassPoints.status = 'pending'
        }).addCase(cancelActivateMassPoints.fulfilled, (state, action) => {
            state.command.cancelActivateMassPoints.status = "success"
        })
    }
})

export const {
    resetState,
    updateMeasurementSheet,
    toggleMassPointActive,
    toggleMassPointHighlight,
    activateMassPointsCompleted,
} = massMaskMassPointsSlice.actions

export default massMaskMassPointsSlice.reducer