export enum OfferTypeEnum {
    Undefined = 0,
    NormalOffer = 1,
    OfferWithoutMassmask = 2
}

export const OfferTypeEnumValues = [
    { value: OfferTypeEnum.Undefined, text: "Kein Auswahl" },
    { value: OfferTypeEnum.NormalOffer, text: "Angebot mit Maßmaske" },
    { value: OfferTypeEnum.OfferWithoutMassmask, text: "Angebot ohne Maßmaske" }
];