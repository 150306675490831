import { PositionMappingState } from "./position_mapping.model";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { cancelSave, confirmDelete, fetchLegacyPositions, fetchConfiguredPositions, updatePositionsMapping, uploadFile } from "./position_mapping.thunks";
import { GridFilterModel } from "@mui/x-data-grid-pro";
import { buildMappedPositions, findSelectedPositions, getFilterConfiguredPositions, mapConfiguredPosition, setValuesOfSelectedPositions, updateCanSave } from "./position_mapping.reducer";
import { OverviewPosition } from "models/mapping_position/overview_position";

const initialState: PositionMappingState = {
    actualData: {
        upload: {

        },
        gridFilters: { items: [] },
        selectedLegacyPosition: undefined,
        selectedPositions: [],
        showDeleteDialog: false,
    },
    loadedData: {
        legacyPositions: [],
        configuredPositions: [],
    },
    query: {
        fetchConfiguredPositions: { status: "idle", canExecute: true },
        fetchLegacyPositions: { status: "idle", canExecute: true },
    },
    command: {
        uploadFile: { status: "idle", canExecute: true },
        updatePositionsMapping: { status: "idle", canExecute: false },
        cancelSave: { status: "idle", canExecute: true },
        confirmDelete: { status: "idle", canExecute: true },
    }
}

export const positionMappingSlice = createSlice({
    name: 'admin/positionmapping',
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        selectFile: (state, action: PayloadAction<File>) => {
            state.actualData.upload.file = action.payload;
        },
        setGridFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.gridFilters = action.payload;
            state.loadedData.configuredPositions = getFilterConfiguredPositions(state);
        },
        setLegacyPositionById: (state, action: PayloadAction<number>) => {
            const legacyPositionId = action.payload;
            const legacyPosition = state.loadedData.legacyPositions.find(x => x.id === legacyPositionId);
            state.actualData.selectedPositions = findSelectedPositions(state, legacyPosition);
            state.loadedData.configuredPositions = setValuesOfSelectedPositions(state.loadedData.configuredPositions, state.actualData.selectedPositions);
            state.actualData.selectedLegacyPosition = legacyPosition;
        },
        setSelectedPositions: (state, action: PayloadAction<Array<OverviewPosition>>) => {
            state.actualData.selectedPositions = action.payload;
            state.command.updatePositionsMapping.canExecute = updateCanSave(state);
        },
        uploadFileCompleted: (state) => {
            state.command.uploadFile.status = "idle";
        },
        updatePositionsMappingCompleted: (state) => {
            state.command.updatePositionsMapping.status = "idle";
        },
        updatePosition: (state, action: PayloadAction<OverviewPosition>) => {
            const updatedPosition = action.payload;
            const position = state.loadedData.configuredPositions.find(x => x.sequenceId === updatedPosition.sequenceId);
            position.horizontalStartValue = updatedPosition.horizontalStartValue;
            position.horizontalEndValue = updatedPosition.horizontalEndValue;
            position.verticalStartValue = updatedPosition.verticalStartValue;
            position.verticalEndValue = updatedPosition.verticalEndValue;

            let selectedPosition = state.actualData.selectedPositions.find(x => x.sequenceId == updatedPosition.sequenceId);
            if (selectedPosition != undefined) {
                selectedPosition.horizontalStartValue = updatedPosition.horizontalStartValue;
                selectedPosition.horizontalEndValue = updatedPosition.horizontalEndValue;
                selectedPosition.verticalStartValue = updatedPosition.verticalStartValue;
                selectedPosition.verticalEndValue = updatedPosition.verticalEndValue;

                state.command.updatePositionsMapping.canExecute = updateCanSave(state);
            }
        },
        cancelDelete: (state) => {
            state.actualData.showDeleteDialog = false;
        },
        showDeleteDialog: (state) => {
            state.actualData.showDeleteDialog = true;
        },
    }, extraReducers: (builder) => {
        // upload File
        builder.addCase(uploadFile.pending, (state, action) => {
            state.command.uploadFile.status = 'pending'
            state.command.uploadFile.canExecute = false;
        }).addCase(uploadFile.rejected, (state, action) => {
            state.command.uploadFile.status = "error"
            state.command.uploadFile.canExecute = true;
            state.command.uploadFile.message = action.error.message;
        }).addCase(uploadFile.fulfilled, (state, action) => {
            state.command.uploadFile.status = "success"
            state.command.uploadFile.canExecute = true;
            // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancelSave.status = 'pending'
            state.command.cancelSave.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancelSave.status = "success"
            state.command.cancelSave.canExecute = false;

            //fetch configured Positions
        }).addCase(fetchConfiguredPositions.pending, (state, action) => {
            state.query.fetchConfiguredPositions.status = 'pending'
            state.query.fetchConfiguredPositions.canExecute = false;
        }).addCase(fetchConfiguredPositions.rejected, (state, action) => {
            state.query.fetchConfiguredPositions.status = "error"
            state.query.fetchConfiguredPositions.canExecute = true;
            state.query.fetchConfiguredPositions.message = action.error.message;
        }).addCase(fetchConfiguredPositions.fulfilled, (state, action) => {
            const configuredOverviewPositions: OverviewPosition[] = action.payload.getData().map((x, index) => {
                let overviewPosition = mapConfiguredPosition(x);
                overviewPosition.sequenceId = index;
                return overviewPosition;
            });
            state.loadedData.configuredPositions = configuredOverviewPositions;

            state.query.fetchConfiguredPositions.status = "success"
            state.query.fetchConfiguredPositions.canExecute = true;

            //fetch AS postions
        }).addCase(fetchLegacyPositions.pending, (state, action) => {
            state.query.fetchLegacyPositions.status = 'pending'
            state.query.fetchLegacyPositions.canExecute = false;
        }).addCase(fetchLegacyPositions.rejected, (state, action) => {
            state.query.fetchLegacyPositions.status = "error"
            state.query.fetchLegacyPositions.canExecute = true;
            state.query.fetchLegacyPositions.message = action.error.message;
        }).addCase(fetchLegacyPositions.fulfilled, (state, action) => {
            state.query.fetchLegacyPositions.status = "success"
            state.query.fetchLegacyPositions.canExecute = true;
            state.loadedData.legacyPositions = action.payload.getData();

            //updateMapping
        }).addCase(updatePositionsMapping.pending, (state, action) => {
            state.command.updatePositionsMapping.status = 'pending'
            state.command.updatePositionsMapping.canExecute = false;
        }).addCase(updatePositionsMapping.rejected, (state, action) => {
            state.command.updatePositionsMapping.status = "error"
            state.command.updatePositionsMapping.canExecute = true;
            state.command.updatePositionsMapping.message = action.error.message;
        }).addCase(updatePositionsMapping.fulfilled, (state, action) => {
            const currentLegacyPosition = state.loadedData.legacyPositions.find(x => x.id === state.actualData.selectedLegacyPosition.id);
            currentLegacyPosition.mappedPositions = buildMappedPositions(state.actualData.selectedPositions);

            state.command.updatePositionsMapping.status = "success"
            state.command.updatePositionsMapping.canExecute = false;

            //confirmDelete
        }).addCase(confirmDelete.pending, (state, action) => {
            state.command.confirmDelete.status = 'pending'
            state.command.confirmDelete.canExecute = false;
        }).addCase(confirmDelete.rejected, (state, action) => {
            state.command.confirmDelete.status = "error"
            state.command.confirmDelete.canExecute = true;
            state.command.confirmDelete.message = action.error.message;
        }).addCase(confirmDelete.fulfilled, (state, action) => {
            state.command.confirmDelete.status = "success"
            state.command.confirmDelete.canExecute = false;
        })
    }
})

export const {
    resetState,
    selectFile,
    setGridFilters,
    setLegacyPositionById,
    setSelectedPositions,
    uploadFileCompleted,
    updatePositionsMappingCompleted,
    updatePosition,
    cancelDelete,
    showDeleteDialog
} = positionMappingSlice.actions

export default positionMappingSlice.reducer
