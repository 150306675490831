import { BoxProps, Box } from "@mui/material";

export const BoxItem = (props: BoxProps) => {
  const { sx, ...other } = props;
  return (
      <Box
      sx={{
        boxShadow: 'none',
        background: '#ffffff',
        border: '2px solid #6a7c8d',
        padding: '20px',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
          ...sx,
      }}
      {...other}
      />
  );
}