import { Grid, Switch } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../app/hook";
import { selectQuality, selectFootoption, selectSide, selectCountry, selectCategoryAttribute, toggleProcessType, toggleDiagnoseModeEnabled, toggleQuotationType, toggleSimulationType } from "../redux/simulator_simulation.slice";
import { DropdownComponent } from "../../../shared/shared";
import IAvailableQuality from "models/available_basedata/available_quality";
import * as style from "./simulation_filter.style";
import { PrimaryButtonComponent } from "shared/shared";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { EvaluationState, ISimulationAdditionCategoryAttributeValueViewModel } from "../redux/simulator_simulation.model";
import { EditorDescriptionComponent } from "shared/components/editors/editor_description/editor_description.style";
import { ProcessTypeEnum, ProcessTypeEnumValues } from "models/simulator/process_type.enum";
import { OfferTypeEnum, OfferTypeEnumValues } from "models/simulator/offer_type.enum";
import { SimulationTypeEnum, SimulationTypeEnumValues } from "models/simulator/simulation_type.enum";

export type SimulationFilterComponentProps = {
    startSimulation: () => {} | void;
    startCustomDesign: () => {} | void;
}

export const SimulationFilterComponent: React.FC<SimulationFilterComponentProps> = (
    {
        startSimulation,
        startCustomDesign
    }) => {
    const dispatch = useAppDispatch();

    const qualities = useAppSelector((state) => state.simulation.qualities)
    const footoptions = useAppSelector((state) => state.simulation.availablefootoptions)
    const selectedQuality = useAppSelector((state) => state.simulation.simulation.selectedQuality);
    const selectedFootoption = useAppSelector((state) => state.simulation.simulation.selectedFootoption);
    const selectedSide = useAppSelector((state) => state.simulation.sides.find(x => x.value === state.simulation.simulation.selectedSide));
    const countries = useAppSelector((state) => state.simulation.countries);
    const selectedCountry = useAppSelector((state) => state.simulation.simulation.selectedCountry);
    const categoryAttributes = useAppSelector((state) => state.simulation.simulation.additionCategoryAttributes);

    const state = useAppSelector((state) => state.simulation);

    const processType = state.simulation.processType === ProcessTypeEnum.Order;
    const isNormalOffer = state.simulation.offerType === OfferTypeEnum.NormalOffer;

    return <style.InfoWrapper>
        <Grid container rowSpacing={3}>
            <Grid container item columnSpacing={3}>
                {qualities.length === 0
                    ? null :
                    <Grid item key={0}>
                        <DropdownComponent
                            name="Qualität"
                            data={qualities}
                            isLoading={false}
                            isRequired={true}
                            isReadonly={state.simulation.productConfigurationIsLocked}
                            onSelect={(selectedItem: IAvailableQuality) => dispatch(selectQuality({
                                quality: selectedItem
                            }))}
                            selectedValue={selectedQuality}
                            displayedProperties={["erpId", "name"]}
                        />
                    </Grid>
                }
                <Grid item key={1}>
                    <DropdownComponent
                        name="Maßmaske Seite"
                        data={state.sides}
                        isLoading={false}
                        isRequired={true}
                        isReadonly={state.simulation.productConfigurationIsLocked}
                        onSelect={(selectedItem) => {
                            dispatch(selectSide(selectedItem.value));
                        }}
                        selectedValue={selectedSide}
                        displayedProperties={["text"]}
                    />
                </Grid>
                <Grid item key={2}>
                    <DropdownComponent
                        name="Fußart"
                        data={footoptions}
                        isLoading={false}
                        isRequired={true}
                        isReadonly={state.simulation.productConfigurationIsLocked}
                        onSelect={(selectedItem) => dispatch(selectFootoption({
                            footOption: selectedItem
                        }))}
                        selectedValue={selectedFootoption}
                        displayedProperties={["externalIdentifier", "name"]}
                    />
                </Grid>
                <Grid item key={3}>
                    <DropdownComponent
                        name="Zusätze für Land"
                        data={countries}
                        isLoading={false}
                        isRequired={true}
                        isReadonly={state.simulation.productConfigurationIsLocked}
                        onSelect={(selectedItem) => dispatch(selectCountry(selectedItem))}
                        selectedValue={selectedCountry}
                        displayedProperties={["countryCode", "name"]}
                    />
                </Grid>
                <Grid item key={4}>
                    {
                        state.simulation.simulationType === SimulationTypeEnum.Mass &&
                        <PrimaryButtonComponent
                            disabled={state.simulation.productConfigurationIsLocked || state.simulation.evaluationState !== EvaluationState.loadMassMask}
                            onClick={() => startSimulation()}>
                            <PlayArrowIcon />
                            {" "}
                            Massmaske laden
                        </PrimaryButtonComponent>
                    }
                    {
                        state.simulation.simulationType === SimulationTypeEnum.CustomDesign &&
                        <PrimaryButtonComponent
                            disabled={state.simulation.productConfigurationIsLocked || state.simulation.evaluationState !== EvaluationState.evaluateCustomDesign}
                            onClick={() => startCustomDesign()}>
                            <PlayArrowIcon />
                            {" "}
                            Sonderanfertigung erstellen
                        </PrimaryButtonComponent>
                    }
                </Grid>
            </Grid>
            <Grid container item columnSpacing={3}>
                <Grid item key={5}>
                    <EditorDescriptionComponent>{SimulationTypeEnumValues[state.simulation.simulationType].text}</EditorDescriptionComponent>
                    <Switch
                        disabled={state.simulation.productConfigurationIsLocked}
                        checked={state.simulation.simulationType == SimulationTypeEnum.Mass}
                        onChange={(event) => dispatch(toggleSimulationType(event.target.checked))}
                    />
                </Grid>
                <Grid item key={6}>
                    <EditorDescriptionComponent>{ProcessTypeEnumValues[state.simulation.processType].text}</EditorDescriptionComponent>
                    <Switch
                        disabled={state.simulation.productConfigurationIsLocked || state.simulation.productConfigurationStarted}
                        checked={processType}
                        onChange={(event) => dispatch(toggleProcessType(event.target.checked))}
                    />
                </Grid>
                {
                    (state.simulation.processType === ProcessTypeEnum.Offer && state.simulation.simulationType === SimulationTypeEnum.Mass) &&
                    <Grid item key={7}>
                        <EditorDescriptionComponent>{OfferTypeEnumValues[state.simulation.offerType].text}</EditorDescriptionComponent>
                        <Switch
                            disabled={state.simulation.productConfigurationIsLocked}
                            checked={isNormalOffer}
                            onChange={(event) => dispatch(toggleQuotationType(event.target.checked))}
                        />
                    </Grid>
                }
                <Grid item key={9}>
                    <EditorDescriptionComponent>Diagnosemodus</EditorDescriptionComponent>
                    <Switch
                        disabled={state.simulation.productConfigurationIsLocked}
                        checked={state.simulation.diagnoseModeEnabled}
                        onChange={(event) => dispatch(toggleDiagnoseModeEnabled(event.target.checked))}
                    />
                </Grid>
            </Grid>
            <Grid container item columnSpacing={3} rowSpacing={3}>
                {
                    categoryAttributes.map((categoryAttribute, index) =>
                        <Grid item key={index}>
                            <DropdownComponent
                                name={categoryAttribute.displayName}
                                data={categoryAttribute.allAttributes}
                                isLoading={false}
                                isRequired={false}
                                isReadonly={state.simulation.productConfigurationIsLocked}
                                onSelect={(selectedItem: ISimulationAdditionCategoryAttributeValueViewModel) => dispatch(selectCategoryAttribute({
                                    categoryAttribute: categoryAttribute,
                                    selectedItem: selectedItem
                                }))}
                                selectedValue={categoryAttribute.selectedAttribute}
                                displayedProperties={["displayName"]}
                            />
                        </Grid>
                    )
                }
            </Grid>
        </Grid>
    </style.InfoWrapper >
}