import { Grid, IconButton } from '@mui/material';
import React from 'react';
import SearchFieldComponent from 'shared/components/search/searchfield.component';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { AppDispatch } from '../../app/redux_store';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import * as style from './rule_link_masspoint.style';
import { MasspointTypeEnumValuesLookup } from 'models/masspoints/enums/masspoint_type.enum';
import { deSelectLinkMasspoint, filterSelectedLinkMasspoints, filterUnselectedLinkMasspoints, selectLinkMasspoint } from 'rules_formula/redux/rules_formula.slice';
import IMasspointOverview from 'models/masspoints/masspoint_overview';
import { PaperItem } from 'shared/style/paper';
import colors from 'shared/style/colors';
import { MasspointBodySideEnumValuesLookup } from 'models/masspoints/enums/body_side.enum';

interface RuleLinkMasspointComponentProps {
    ruleIndex: number;
}

export const RuleLinkMasspointComponent: React.FC<RuleLinkMasspointComponentProps> = ({ ruleIndex }) => {
    const dispatch: AppDispatch = useAppDispatch();

    const state = useAppSelector(store => store.rules_formula);
    const rule = state.actualData.rules[ruleIndex];
    const loadedCheck = state.loadedData.check;

    const renderDisplayText = (item: IMasspointOverview) => {
        const isSideSpecific = loadedCheck.isSideSpecific;
        if (isSideSpecific) {
            return (
                <>
                    {item.name} - {MasspointTypeEnumValuesLookup(item.massPointType)} - {item.bodyArea.name} - {MasspointBodySideEnumValuesLookup(item.bodySide)}
                </>
            )
        }
        else {
            return (
                <>
                    {item.name} - {MasspointTypeEnumValuesLookup(item.massPointType)} - {item.bodyArea.name}
                </>
            )
        }
    }

    const unselectedMasspointList = rule.linkMasspointSelection.available.filteredItems.map((item, index) => {
        const isEvenIndex = index % 2 === 0;
        return (
            <div key={index} style={isEvenIndex ? {backgroundColor: colors.mediLightGray} : {}}>
                <IconButton style={{ outline: "none" }} onClick={() => dispatch(selectLinkMasspoint({ ruleIndex: ruleIndex, masspointId: item.id }))}>
                    <KeyboardArrowLeftIcon />
                </IconButton>
                {" "}
                {renderDisplayText(item)}
            </div >
        )
    });

    const selectedMasspointList = rule.linkMasspointSelection.selected.filteredItems.map((item, index) => {
        const isEvenIndex = index % 2 === 0;
        return (
            <div key={index} style={isEvenIndex ? {backgroundColor: colors.mediLightGray} : {}}>
                <IconButton style={{ outline: "none" }} onClick={() => dispatch(deSelectLinkMasspoint({ ruleIndex: ruleIndex, masspointId: item.id }))}>
                    <KeyboardArrowRightIcon />
                </IconButton>
                {" "}
                {renderDisplayText(item)}
            </div >
        )
    });

    return (
        <>
            <Grid item container sx={{ paddingTop: 7}}
                direction='row'
                md={12}>
                <Grid item>
                    Wählen Sie hier Maßpunkte, deren Maßangaben das Prüfungsergebnis beeinflussen können. Die Punkte werden in der Maßmaske als zugehörige Maßpunkte markiert, um Hinweise auf gegenseitige Beeinflussung zu liefern.
                </Grid>
                <Grid
                    item>
                    <style.BoxHeader>Relatiertes Maßpunkthighlighting / Markierungsmaßpunkte</style.BoxHeader>

                    <PaperItem>
                        <Grid item container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            wrap="nowrap">
                            <Grid item>
                                <Grid
                                    container
                                    direction="column"
                                    spacing={2}>
                                    <Grid item>
                                        <strong>Verknüpfte Maßpunkte</strong>
                                    </Grid>
                                    <Grid item>
                                        <SearchFieldComponent
                                            onSearch={(searchText) => dispatch(filterSelectedLinkMasspoints({ ruleIndex: ruleIndex, filterText: searchText }))}
                                            placeholder="In Maßpunkten suchen" />
                                    </Grid>
                                    <Grid item>
                                        <style.ListWrapper>
                                            {selectedMasspointList}
                                        </style.ListWrapper>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <style.Spacer />
                            </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    direction="column"
                                    spacing={2}>
                                    <Grid item>
                                        <strong>Verfügbare Maßpunkte</strong>
                                    </Grid>
                                    <Grid item>
                                        <SearchFieldComponent
                                            onSearch={(searchText) => dispatch(filterUnselectedLinkMasspoints({ ruleIndex: ruleIndex, filterText: searchText }))}
                                            placeholder="In Maßpunkten suchen" />
                                    </Grid>
                                    <Grid item>
                                        <style.ListWrapper>
                                            {unselectedMasspointList}
                                        </style.ListWrapper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </PaperItem>
                </Grid>
            </Grid>
        </>
    )
};