import { MasspointBodySideEnumValues } from '../../../models/masspoints/enums/body_side.enum';
import { MasspointTypeEnumValues } from '../../../models/masspoints/enums/masspoint_type.enum';
import { MasspointOverviewState } from './masspoint_overview.model';
import { filterMasspointValue } from '../masspoint_overview.helper';

export const filterMasspoints = (state: MasspointOverviewState ) => {
    const searchTextLower = state.actualData.searchFilter.toLowerCase().trim();
    let filteredItems = state.loadedData.masspoints.filter(
        (masspoint) =>{
            const masspointType = MasspointTypeEnumValues.find(type => type.value === masspoint.massPointType);
            const masspointBodySide = MasspointBodySideEnumValues.find(bodySide => bodySide.value === masspoint.bodySide);
    
            return (masspoint.name.toLowerCase().includes(searchTextLower) ||
              masspointType.text.toLowerCase().includes(searchTextLower) ||
              masspoint.bodyArea.name.toLowerCase().includes(searchTextLower) ||
              masspointBodySide.text.toLowerCase().includes(searchTextLower));          
        }
    );

    state.actualData.gridFilters.items.forEach(fil => {
        filteredItems = filterMasspointValue(fil, filteredItems);
    });
    
    state.actualData.masspoints = filteredItems;
}