import { EnumDescriptor } from "../../enum_descriptor";

export enum MasspointTypeEnum {
  Undefined,
  Circumference,
  Length,
}

export const MasspointTypeEnumValues: Array<
  EnumDescriptor<MasspointTypeEnum>
> = [
    { value: MasspointTypeEnum.Circumference, text: "Umfang" },
    { value: MasspointTypeEnum.Length, text: "Länge" },
  ];

export const MasspointTypeEnumValuesLookup = (value: MasspointTypeEnum) => MasspointTypeEnumValues.find(x => x.value === value).text;
export const MasspointTypeEnumValuesLookupForEnum = (value: string): MasspointTypeEnum => MasspointTypeEnumValues.find(x => x.text === value).value;