import React from "react";
import { AdminOverviewComponent } from './overview/overview.component';
import { MassimageOverviewComponent } from './massimages/overview/massimage_overview.component';
import { BaseDataImportsComponent } from "./basedataimports/basedataimports.component";
import { CreateConfigComponent } from "./massimages/config/config_create/create_config.component";
import { EditConfigComponent } from "./massimages/config/config_edit/edit_config.component";
import { UpdateMassImageComponent } from "./massimages/update/update_massimage.component";
import { Route, Routes } from "react-router-dom";
import { PositionMappingComponent } from "admin/positionmapping/positionMapping.component";

export const AdminComponent = () => {
    return (
        <Routes>
            <Route element={<AdminOverviewComponent />} path="/" />
            <Route element={<MassimageOverviewComponent />} path="massimages" />
            <Route element={<UpdateMassImageComponent />} path="massimages/:massImageId/update" />
            <Route element={<BaseDataImportsComponent />} path="basedataimports" />
            <Route element={<CreateConfigComponent />} path="massimages/:massImageId/config/create" />
            <Route element={<EditConfigComponent />} path="massimages/:massImageId/config/edit" />
            <Route element={<PositionMappingComponent />} path="positionmapping" />
        </Routes>
    )
}