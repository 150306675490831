import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    RulesFormulaState,
    SelectMassPointCriteriaPayload,
    SelectRuleTypePayload,
    UpdateFormulaPayload,
    DeleteRulePlaceHolderPayload,
    SelectRulePlaceHolderPayload,
    SelectRuleConstantPlaceHolderPayload,
    FilterLinkMasspointsPayload,
    SelectionLinkMasspointsPayload
} from './rules_formula.model';
import {
    addNewRuleIfLastRuleFilledOut,
    distinctMassPointCriteria,
    distinctMassPointCriteriaByIsSideDependant,
    updateCanSaveRules,
    massPointCriteriaSuggestions,
    addRulePlaceholderToRule,
    addRuleConstantPlaceholderToRule,
    deleteRulePlaceholderFromRule,
    deleteRuleConstantPlaceholderFromRule,
    selectRulePlaceholderToRule,
    changedRuleConstantPlaceholderOnRule,
    getMassPointCriteria,
    applyFilterToSelected,
    applyFilterToUnselected,
    moveFromAvailableToSelected,
    moveFromSelectedToAvailable,
    distinctMassPointOverviewByIsSideDependant,
    distinctMassPointOverview,
    createEmptyRule,
    mapFormulaRuleRequest,
} from './rules_formula.reducer';
import { cancelSave, fetchAllData, fetchAllMassPointCriterias, fetchCheck, fetchMassPointCriterias, fetchRules, saveRules } from './rules_formula.thunks';

const initialState: RulesFormulaState = {
    actualData: {
        rules: [{
            formularRuleMassPointPlaceholderVersions: [],
            formulaRuleConstantPlaceholderVersions: [],
            isExpanded: false,
            isConstantExpanded: false,
            linkMasspointSelection: {
                selected: {
                    allItems: [],
                    filteredItems: [],
                    searchText: ""
                },
                available: {
                    allItems: [],
                    filteredItems: [],
                    searchText: ""
                }
            }
        }],
        placerholderValidated: false,
        placerholderConstantValidated: false,
    },
    loadedData: {
        errorText: "",
        warningText: "",
        allMassPoints: []
    },
    massPointCriteriaSuggestions: {},
    placeholderMassPointCriteriaSuggestions: {},
    query: {
        fetchAllData: { status: "idle", canExecute: true },
        fetchAllMassPointCriterias: { status: "idle", canExecute: true },
        fetchCheck: { status: "idle", canExecute: true },
        fetchRules: { status: "idle", canExecute: true },
        fetchMassPointCriterias: { status: "idle", canExecute: true },
    },
    command: { 
        saveRules: { status: "idle", canExecute: false },
        cancel: { status: "idle", canExecute: false },
    },
}

export const rulesFormulaSlice = createSlice({
    name: 'rules_formula',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loadedData = initialState.loadedData;
            state.actualData = initialState.actualData;
            state.query = initialState.query;
            state.command = initialState.command;
        },
        selectMassPointCriteria: (state, action: PayloadAction<SelectMassPointCriteriaPayload>) => {
            const payload = action.payload;
            state.actualData.rules[payload.ruleIndex].massPointName = payload.selectedMassPointCriteria.massPointName;
            state.actualData.rules[payload.ruleIndex].massPointType = payload.selectedMassPointCriteria.massPointType;
            state.actualData.rules[payload.ruleIndex].bodySide = payload.selectedMassPointCriteria.massPointBodySide;
            state.actualData.rules[payload.ruleIndex].massPointCriteria = payload.selectedMassPointCriteria;

            addNewRuleIfLastRuleFilledOut(state);
            updateCanSaveRules(state);
        },
        selectRuleType: (state, action: PayloadAction<SelectRuleTypePayload>) => {
            const payload = action.payload;
            state.actualData.rules[payload.ruleIndex].ruleType = payload.selectedRuleType;

            addNewRuleIfLastRuleFilledOut(state);
            updateCanSaveRules(state);
        },
        removeRule: (state, action: PayloadAction<number>) => {
            const ruleIndexToDelete = action.payload;
            const indexOfLastRule = state.actualData.rules.length - 1;

            if (ruleIndexToDelete !== indexOfLastRule) {
                state.actualData.rules.splice(ruleIndexToDelete, 1);
            }
            updateCanSaveRules(state);
        },
        toggleExpandedRulePlaceholder: (state, action: PayloadAction<number>) => {
            state.actualData.rules.forEach((_, index) => {
                if (index === action.payload) {
                    state.actualData.rules[index].isExpanded = state.actualData.rules[index].isExpanded === false;
                } else {
                    state.actualData.rules[index].isExpanded = false;
                }
            })
        },
        toggleExpandedRuleConstantPlaceholder: (state, action: PayloadAction<number>) => {
            state.actualData.rules.forEach((_, index) => {
                if (index === action.payload) {
                    state.actualData.rules[index].isConstantExpanded = state.actualData.rules[index].isConstantExpanded === false;
                } else {
                    state.actualData.rules[index].isConstantExpanded = false;
                }
            })
        },
        updateFormula: (state, action: PayloadAction<UpdateFormulaPayload>) => {
            const payload = action.payload;
            state.actualData.rules[payload.ruleIndex].formula = payload.formula;
            updateCanSaveRules(state);
        },
        addRulePlaceholder: (state, action: PayloadAction<number>) => {
            const payload = action.payload;
            addRulePlaceholderToRule(state, payload);
            updateCanSaveRules(state);
        },
        addRuleConstantPlaceholder: (state, action: PayloadAction<number>) => {
            const payload = action.payload;
            addRuleConstantPlaceholderToRule(state, payload);
            updateCanSaveRules(state);
        },
        deleteRulePlaceholder: (state, action: PayloadAction<DeleteRulePlaceHolderPayload>) => {
            const payload = action.payload;
            deleteRulePlaceholderFromRule(state, payload);
            updateCanSaveRules(state);
        },
        deleteRuleConstantPlaceholder: (state, action: PayloadAction<DeleteRulePlaceHolderPayload>) => {
            const payload = action.payload;
            deleteRuleConstantPlaceholderFromRule(state, payload);
            updateCanSaveRules(state);
        },
        selectedRulePlaceholder: (state, action: PayloadAction<SelectRulePlaceHolderPayload>) => {
            const payload = action.payload;
            selectRulePlaceholderToRule(state, payload);
            updateCanSaveRules(state);
        },
        changedRuleConstantPlaceholder:  (state, action: PayloadAction<SelectRuleConstantPlaceHolderPayload>) => {
            const payload = action.payload;
            changedRuleConstantPlaceholderOnRule(state, payload);
            updateCanSaveRules(state);
        },
        saveRulesCompleted: (state) => {
            state.command.saveRules.status = "idle"
        },
        saveRulesErrorCompleted: (state) => {
            state.command.saveRules.status = "idle";
        },
        filterSelectedLinkMasspoints: (state, action: PayloadAction<FilterLinkMasspointsPayload>) => {
            const payload = action.payload;
            applyFilterToSelected(state, payload.ruleIndex, payload.filterText);
        },
        filterUnselectedLinkMasspoints: (state, action: PayloadAction<FilterLinkMasspointsPayload>) => {
            const payload = action.payload;
            applyFilterToUnselected(state, payload.ruleIndex, payload.filterText);
        },
        selectLinkMasspoint: (state, action: PayloadAction<SelectionLinkMasspointsPayload>) => {
            const payload = action.payload;
            moveFromAvailableToSelected(state, payload.ruleIndex, payload.masspointId);
        },
        deSelectLinkMasspoint: (state, action: PayloadAction<SelectionLinkMasspointsPayload>) => {
            const payload = action.payload;
            moveFromSelectedToAvailable(state, payload.ruleIndex, payload.masspointId);
        },
    }, extraReducers: (builder) => {

        // fetchMassPointCriterias
        builder.addCase(fetchMassPointCriterias.pending, (state, action) => {
            state.query.fetchMassPointCriterias.status = "pending"
            state.query.fetchMassPointCriterias.canExecute = false;
        }).addCase(fetchMassPointCriterias.rejected, (state, action) => {
            state.query.fetchMassPointCriterias.status = "error"
            state.query.fetchMassPointCriterias.message = action.error.message;
            state.query.fetchMassPointCriterias.canExecute = true;
        }).addCase(fetchMassPointCriterias.fulfilled, (state, action) => {
            state.query.fetchMassPointCriterias.status = "success"
            state.query.fetchMassPointCriterias.message = undefined;
            state.query.fetchMassPointCriterias.canExecute = true;
            state.loadedData.massPoints = action.payload.getData();

            // fetchCheck
        }).addCase(fetchCheck.pending, (state, action) => {
            state.query.fetchCheck.status = "pending"
            state.query.fetchCheck.canExecute = false;
        }).addCase(fetchCheck.rejected, (state, action) => {
            state.query.fetchCheck.status = "error"
            state.query.fetchCheck.canExecute = true;
            state.query.fetchCheck.message = action.error.message;
        }).addCase(fetchCheck.fulfilled, (state, action) => {
            state.query.fetchCheck.status = "success"
            state.query.fetchCheck.message = undefined;
            state.query.fetchCheck.canExecute = true;
            state.loadedData.check = action.payload.getData();

            // saveRules
        }).addCase(saveRules.pending, (state, action) => {
            state.command.saveRules.status = "pending"
            state.command.saveRules.canExecute = false;
            state.actualData.placerholderValidated = true;
        }).addCase(saveRules.rejected, (state, action) => {
            state.command.saveRules.canExecute = true;
            state.command.saveRules.message = action.error.message;
            if (action.error.code === "409") {
                state.command.saveRules.status = "warning";
            }
            else {
                state.command.saveRules.status = "error"
            }
        }).addCase(saveRules.fulfilled, (state, action) => {
            state.command.saveRules.status = "success"
            state.command.saveRules.message = undefined;
            state.command.saveRules.canExecute = true;

            // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancel.status = 'pending'
            state.command.cancel.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancel.status = "success"
            state.command.cancel.canExecute = false;

            // fetchRules
        }).addCase(fetchRules.pending, (state, action) => {
            state.query.fetchRules.status = "pending"
            state.query.fetchRules.canExecute = false;
        }).addCase(fetchRules.rejected, (state, action) => {
            state.query.fetchRules.status = "error"
            state.query.fetchRules.canExecute = true;
            state.query.fetchRules.message = action.error.message;
        }).addCase(fetchRules.fulfilled, (state, action) => {
            state.query.fetchRules.status = "success"
            state.query.fetchRules.message = undefined;
            state.query.fetchRules.canExecute = true;
            const payload = action.payload.getData();
            state.loadedData.rules = payload.formulaRuleVersions;
            state.loadedData.warningText = payload.warningMessage;
            state.loadedData.errorText = payload.errorMessage;
            // fetchAllMasspoints
        }).addCase(fetchAllMassPointCriterias.pending, (state, action) => {
            state.query.fetchAllMassPointCriterias.status = "pending"
            state.query.fetchAllMassPointCriterias.canExecute = false;
        }).addCase(fetchAllMassPointCriterias.rejected, (state, action) => {
            state.query.fetchAllMassPointCriterias.status = "error"
            state.query.fetchAllMassPointCriterias.canExecute = true;
            state.query.fetchAllMassPointCriterias.message = action.error.message;
        }).addCase(fetchAllMassPointCriterias.fulfilled, (state, action) => {
            state.query.fetchAllMassPointCriterias.status = "success"
            state.query.fetchAllMassPointCriterias.message = undefined;
            state.query.fetchAllMassPointCriterias.canExecute = true;
            const data = action.payload.getData();
            state.loadedData.allMassPoints = data;

            // fetchAllData
        }).addCase(fetchAllData.pending, (state, action) => {
            state.query.fetchAllData.status = "pending"
        }).addCase(fetchAllData.rejected, (state, action) => {
            state.query.fetchAllData.status = "error"
            state.query.fetchAllData.message = action.error.message;
        }).addCase(fetchAllData.fulfilled, (state, action) => {
            state.query.fetchAllData.status = "success"
            state.query.fetchAllData.message = undefined;

            const loadedCheck = state.loadedData.check;
            const loadedMassPoints = state.loadedData.massPoints;
            const allMassPoints = state.loadedData.allMassPoints
                .filter(mp => mp.bodyArea.id ===  loadedCheck.bodyArea.id)
                .filter(loadedCheck.isSideSpecific ? distinctMassPointOverviewByIsSideDependant : distinctMassPointOverview);
            if (!loadedCheck.isSideSpecific) {
                allMassPoints.forEach(x => x.bodySide = null);
            }

            const massPointCriteria = loadedMassPoints.map(mp => {
                return {
                    massPointName: mp.name,
                    massPointType: mp.massPointType,
                    massPointBodySide: loadedCheck.isSideSpecific ? mp.bodySide : null
                }
            }).filter(loadedCheck.isSideSpecific ? distinctMassPointCriteriaByIsSideDependant : distinctMassPointCriteria);

            const mappedRules = mapFormulaRuleRequest(state.loadedData.rules, allMassPoints, loadedCheck.isSideSpecific);
            mappedRules.forEach(rule => rule.massPointCriteria = massPointCriteria.find(mp =>
                mp.massPointType === rule.massPointType
                && mp.massPointName === rule.massPointName
                && (loadedCheck.isSideSpecific ? (mp.massPointBodySide === rule.bodySide) : true)
            ));

            state.actualData.massPointCriteria = massPointCriteria;

            state.massPointCriteriaSuggestions = {
                suggestions: massPointCriteriaSuggestions(loadedCheck, loadedMassPoints),
            };
            state.placeholderMassPointCriteriaSuggestions = {
                suggestions: [{ id: null, display: "Keine" }, ...massPointCriteriaSuggestions(loadedCheck, loadedMassPoints)]
            };

            mappedRules.forEach(x => x.formularRuleMassPointPlaceholderVersions.forEach(y => {
                y.displayName = getMassPointCriteria(y);
                y.isValid = true;
            }));

            mappedRules.forEach(x => x.formulaRuleConstantPlaceholderVersions.forEach(y => {
                y.isValid = true;
            }));

            const allRules = [...mappedRules, createEmptyRule(allMassPoints)];

            state.actualData.rules = allRules;

            updateCanSaveRules(state);
        })
    }
})

export const {
    saveRulesCompleted,
    saveRulesErrorCompleted,
    updateFormula,
    selectMassPointCriteria,
    selectRuleType,
    removeRule,
    toggleExpandedRulePlaceholder,
    toggleExpandedRuleConstantPlaceholder,
    addRulePlaceholder,
    addRuleConstantPlaceholder,
    deleteRulePlaceholder,
    deleteRuleConstantPlaceholder,
    selectedRulePlaceholder,
    changedRuleConstantPlaceholder,
    resetState,
    filterUnselectedLinkMasspoints,
    filterSelectedLinkMasspoints,
    deSelectLinkMasspoint,
    selectLinkMasspoint,
} = rulesFormulaSlice.actions

export default rulesFormulaSlice.reducer