import { createAsyncThunk } from '@reduxjs/toolkit';
import { MassImageService } from "../../../../services/massimage.service";
import { RootState } from '../../../../app/redux_store';
import { updateIsUnprocessableEntityException, updateUploadErrorData } from './massimages_overview.slice';
import { handleResponseErrors, errorMessages } from '../../../../shared/networking/error_handling';
import IMassimageOverview from 'models/massimages/massimage_overview';
import MassImageUploadErrorData from 'models/massimages/massimage_error_data';

export const confirmDeleteMassimage = createAsyncThunk(
    'admin/massimages/overview/deleteMassimages',
    async (_, { getState }) => {
        const state = getState() as RootState
        const massimaged = state.massimage_overviews.actualData.selectedMassimage.massImageId
        const responseDelete = await MassImageService.deleteMassImage(massimaged)
        handleResponseErrors(responseDelete, "Massbild", errorMessages.dataAllreadyUsedExists("Massbild"));

        const response = await MassImageService.getAllMassImages();
        handleResponseErrors(response, "Massbilder");
        return response;
    }
)

export const uploadMassimage = createAsyncThunk(
    'admin/massimages/overview/upload',
    async (_, { getState, dispatch }) => {
        const state = getState() as RootState;
        const data = state.massimage_overviews.actualData;
        const responseSave = await MassImageService.saveMassImage({ name: data.selectedMassimage.name, image: data.file });
        // If UnprocessableEntityException
        if (responseSave.getErrorCode() === "422") {
            await dispatch(updateIsUnprocessableEntityException(true));
            const massImageUploadErrorData = JSON.parse(responseSave.getCustomError().messageParameter["massImageUploadErrorData"]) as MassImageUploadErrorData ;
            await dispatch(updateUploadErrorData(massImageUploadErrorData));
        }
        handleResponseErrors(responseSave, "Massbild");

        const response = await MassImageService.getAllMassImages();
        handleResponseErrors(response, "Massbilder");
        return response;
    }
)

export const fetchMassimages = createAsyncThunk(
    'admin/massimages/overview/fetchMassimages',
    async (thunkApi) => {
        const response = await MassImageService.getAllMassImages();
        handleResponseErrors(response, "Massbilder");
        return response;
    }
)

export const uploadConfigFile = createAsyncThunk(
    'admin/massimages/overview/uploadConfigFile',
    async (massImage: IMassimageOverview) => { return massImage }
)