import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseErrors } from "shared/networking/error_handling";
import AdditionCategoryService from 'services/addition_category.service';
import PolicyServiceAPI from '../../../../../services/policy.service';
import { RootState } from "app/redux_store";
import { PolicyTypeEnum } from "models/policies/policy_type.enum";
import MassPointService from "services/masspoint.service";

export const getAdditionCategories = createAsyncThunk(
    'policy/update_policy_mandatory_category/getAdditionCategories',
    async (id: string) => {
        const response = await AdditionCategoryService.getAvailableAdditionCategoryByPolicyTypeOverview(PolicyTypeEnum.MandatoryCatagoryByMasspoint, id);
        handleResponseErrors(response, "Zusatzekategorien");
        return response;
    }
)

export const getMasspoints = createAsyncThunk(
    'policy/update_policy_mandatory_category_by_masspoint/getMasspoints',
    async (_) => {
        const response = await MassPointService.getMassPoints();
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)

export const getPolicy = createAsyncThunk(
    'policy/update_policy_mandatory_category_by_masspoint/getPolicy',
    async (id: string) => {
        const response = await PolicyServiceAPI.getMandatoryCategoryByMasspoint(id);
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const updatePolicy = createAsyncThunk(
    'policy/update_policy_mandatory_category_by_masspoint/savepolicy',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.policy_mandatory_category_by_masspoint_update.data;
        const response = await PolicyServiceAPI.updateMandatoryCategoryByMasspoint({
            policyId: data.policy.id,
            name: data.policy.name,
            ignoreOnQuotation: data.policy.ignoreOnQuotation,
            additionCategoryId: data.selectedCategory.id,
            massPointName: data.selectedMasspointCriteria.massPointName,
            massPointType: data.selectedMasspointCriteria.massPointType,
            bodyAreaId: data.selectedMasspointCriteria.bodyAreaId,
        });
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'policy/update_policy_mandatory_category_by_masspoint/cancelSave',
    async (_) => { }
)


export const initData = createAsyncThunk(
    'policy/update_policy_mandatory_category_by_masspoint/initData',
    async (id: string, { dispatch }) => {
        await Promise.all([
            dispatch(getMasspoints()),
            dispatch(getAdditionCategories(id)),
            dispatch(getPolicy(id))
        ]);
    }
)

