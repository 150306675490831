export interface IAdditionPositionOverview {
    id: number;
    name: string;
    translationKeyForName: string;
    selectedPositionHierarchys: ISelectedPositionHierarchy[];
}

export interface ISelectedPositionHierarchy {
    additionHierarchy: IAdditionHierarchy;
}

export interface IAdditionHierarchy {
    id: number;
    name: string;
    translationKeyForName: string;
}

export class AdditionPositionFilterHelper {
    static FilterHorizontalStart(additionPositions: IAdditionPositionOverview[]): IAdditionPositionOverview[] {
        return additionPositions.filter(x => x.selectedPositionHierarchys.some(y => y.additionHierarchy.id === 1))
    }

    static FilterHorizontalEnd(additionPositions: IAdditionPositionOverview[]): IAdditionPositionOverview[] {
        return additionPositions.filter(x => x.selectedPositionHierarchys.some(y => y.additionHierarchy.id === 3))
    }

    static FilterVerticalStart(additionPositions: IAdditionPositionOverview[]): IAdditionPositionOverview[] {
        return additionPositions.filter(x => x.selectedPositionHierarchys.some(y => y.additionHierarchy.id === 2))
    }

    static FilterVerticalEnd(additionPositions: IAdditionPositionOverview[]): IAdditionPositionOverview[] {
        return additionPositions.filter(x => x.selectedPositionHierarchys.some(y => y.additionHierarchy.id === 4))
    }
}