import { doesExist, isNullOrWhitespace } from "services/validation.service";
import { PolicyFingersAndToesState } from "./policy_fingers_and_toes.model";
import _ from "lodash";


export const updateCanSave = (state: PolicyFingersAndToesState) => {
    const saveAllowed =
        !isNullOrWhitespace(state.data.policy.name)
        && doesExist(state.data.selectedAdditionCategory)
        && state.data.massPointGroupSelection.selectedList.allItems.length > 0
        && 
        ( 
            state.loadedData.isEdit === false
            || isNotEqual(state)
        );
    state.command.savePolicy.canExecute = saveAllowed;
}

const isNotEqual = (state: PolicyFingersAndToesState) => {
    return state.data.policy.name !== state.loadedData.policy.name 
        || state.data.policy.ignoreOnQuotation !== state.loadedData.policy.ignoreOnQuotation 
        || state.data.selectedAdditionCategory.id !== state.loadedData.policy.additionCategoryId
        || massPointGroupsChanged(state);
}

const massPointGroupsChanged = (state: PolicyFingersAndToesState) => {
    const loadedMassPointGroupIds = state.loadedData.policy.massPointGroupIds;
    const currentMassPointGroupIds = state.data.massPointGroupSelection.selectedList.allItems.map(x => x.id);
    return !_.isEqual(loadedMassPointGroupIds,currentMassPointGroupIds);
}


