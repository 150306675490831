import { isNullOrWhitespace } from "services/validation.service";
import { CharacteristicCategoryEditState } from "./characteristic_category_edit.model";
import { ICharacteristicCategoryViewModel, ICharacteristicValueFilterList, ICharacteristicValueFilterListItem } from "additions/characteristic_category/components/characteristic_category_editor.model";
import { ICharacteristicValue } from "models/characteristic_categories/characteristic_category";
import { ISelectable } from "shared/components/selectionComponent/models/selectable";
import { ICharacteristicTypeOverview } from "models/characteristic_types/characteristic_type_overview";
import { ICharacteristicCategoryUpdate } from "models/characteristic_categories/characteristic_category_create";

export const updateCanSave = (state: CharacteristicCategoryEditState) => {
    state.command.updateCharacteristicCategory.canExecute =
        !isNullOrWhitespace(state.data.characteristicCategory.name)
        && (
            state.data.characteristicCategory.name !== state.loadedData.characteristicCategory.name
             || changedValues(state.data.characteristicCategory.selectionValues.selectedList.allItems, state.loadedData.characteristicCategory.values)
        );
}

const changedValues = (values: ISelectable[], loadedValues: ICharacteristicValue[]) => {
    let valuesChanged = false;
    values.forEach(x => {
        const changed = loadedValues.filter(y => y.id === x.id).length !== 1;

        if(changed){
            valuesChanged = changed;
        }
    });

    return (values.length !== loadedValues.length) || valuesChanged;
}

export const initializeData = (state: CharacteristicCategoryEditState) : void => { 
    const characteristicCategory = state.loadedData.characteristicCategory;
    const characteristicTypes = state.loadedData.characteristicTypes;

    let selectedCharacteristicType: ICharacteristicTypeOverview = undefined;
    characteristicTypes.forEach(x => { 
        if(x.values.findIndex(y => y.id ===  characteristicCategory.values[0].id) >= 0) {
            selectedCharacteristicType = x;
        }
    });

    state.data.selectedCharacteristicType = selectedCharacteristicType;

    const category = {
        id: characteristicCategory.id,
        name: characteristicCategory.name,
        selectionValues: {
            selectedList: 
            {
                searchText: "",
                allItems: [],
                filteredItems: []
            },
            unSelectedList: {
                searchText: "",
                allItems: selectedCharacteristicType.values.map(x => {
                    return {
                        id: x.id,
                        displayName: x.name,
                    };
                }),
                filteredItems: []
            }
        }
    };

    if(!checkAllItemsSelected(selectedCharacteristicType.values, characteristicCategory.values))
    {
        characteristicCategory.values.forEach(x => {
            const indexUnselected = category.selectionValues.unSelectedList.allItems.findIndex(y => 
                y.id === x.id );
            if(indexUnselected > -1) {
                const selected = category.selectionValues.unSelectedList.allItems.find(y => 
                    y.id === x.id);
                category.selectionValues.selectedList.allItems.push(selected);
                category.selectionValues.unSelectedList.allItems.splice(indexUnselected,1);
            }
        });
    }

    filterValues(category.selectionValues.selectedList);
    filterValues(category.selectionValues.unSelectedList);
    state.data.characteristicCategory = category;
}

const checkAllItemsSelected = (typeValues: ICharacteristicValue[], categoryValues: ICharacteristicValue[]) => {
    let sameValues = typeValues.length === categoryValues.length;
    if(sameValues) {
        categoryValues.forEach(x => {
            if(typeValues.findIndex(y => y.id === x.id) < 0) {
                sameValues = false;
            }
        });
    }

    return sameValues;
}

export const filterValues = (listToFilter: ICharacteristicValueFilterList) => {
    listToFilter.filteredItems = listToFilter.allItems
        .filter(m => valueMatchesText(m, listToFilter.searchText))
        .sort(sortValues);
}

export const valueMatchesText = (m: ISelectable, searchText: string): boolean => {
    if (searchText === "") {
        return true;
    }
    const searchTextLowerCase = searchText.toLowerCase();
    return (m.displayName.toLowerCase().includes(searchTextLowerCase));
}

export const sortValues = (mp1: ISelectable, mp2: ISelectable) => {
    return mp1.displayName.localeCompare(mp2.displayName);
}

export const moveValueFromUnselectedToSelected = (state: CharacteristicCategoryEditState, ids: number[]) => {
    const selectedList = state.data.characteristicCategory.selectionValues.selectedList;
    const unselectedList = state.data.characteristicCategory.selectionValues.unSelectedList;

    ids.forEach(id => {
        const itemToMove = state.data.characteristicCategory.selectionValues.unSelectedList.allItems.find(x => x.id === id);
        moveValueFromSourceToDestination(unselectedList, selectedList, itemToMove);
    })

    filterValues(selectedList);
    filterValues(unselectedList);
}

export const moveValueFromSelectedToUnselected = (state: CharacteristicCategoryEditState, ids: number[]) => {
    const selectedList = state.data.characteristicCategory.selectionValues.selectedList;
    const unselectedList = state.data.characteristicCategory.selectionValues.unSelectedList;

    ids.forEach(id => {
        const itemToMove = state.data.characteristicCategory.selectionValues.selectedList.allItems.find(x => x.id === id);
        moveValueFromSourceToDestination(selectedList, unselectedList, itemToMove);
    })

    filterValues(selectedList);
    filterValues(unselectedList);
}

export const moveValueFromSourceToDestination = (source: ICharacteristicValueFilterList, destination: ICharacteristicValueFilterList, itemToMove: ICharacteristicValueFilterListItem) => {
    const indexInAll = source.allItems.findIndex(x => x.id === itemToMove.id);
    destination.allItems.push(itemToMove);
    source.allItems.splice(indexInAll, 1);
}

export const createUpdateRequest = (category: ICharacteristicCategoryViewModel): ICharacteristicCategoryUpdate => {
    let valueIds  = [];
    if(category.selectionValues.selectedList.allItems.length > 0) {
        valueIds = category.selectionValues.selectedList.allItems.map(x => {
            return x.id;
        });
    } else {
        valueIds = category.selectionValues.unSelectedList.allItems.map(x => {
            return x.id;
        });
    }
    
    return {
        characteristicCategoryId: category.id,
        name: category.name,
        values: valueIds
    }
} 