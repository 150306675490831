import { Grid, IconButton } from "@mui/material";
import { EditorMediumSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";
import { DropdownComponent, FlexBox, Left, PrimaryButtonComponent } from "shared/shared";
import { IAttributeConfigurationViewModel, IMassPointCriteriaSelection, IMassPointValueSelection } from "./serial_size_editor.model";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { ListButtonComponent } from "shared/components/buttons/list_button.component";
import { EditorDescriptionComponent } from "shared/components/editors/editor_description/editor_description.style";
import { IAvailableAdditionAttributeCategory } from "models/addition_attribute_categories/available_addition_attribute_category";
import { IAvailableAdditionAttribute } from "models/additions/addition";
import { DecimalInputComponent } from "shared/components/input/numberInput/decimal_input.component";

interface AttributeEditorProps {
    configurationAttribute: IAttributeConfigurationViewModel;
    takeAttribute: () => void;
    cancelAttribute: () => void;
    selectCategory: (category: IAvailableAdditionAttributeCategory) => void;
    selectAttribute: (attribute: IAvailableAdditionAttribute) => void;
    addAttributeMasspoint: () => void;
    selectAttributeMasspoint: (masspoint: IMassPointCriteriaSelection) => void;
    updateAttributeValueFrom: (masspoint: IMassPointValueSelection) => void;
    updateAttributeValueTo: (masspoint: IMassPointValueSelection) => void;
    deleteAttributeMasspoint: (index: number) => void;
}

export const AttributeEditorComponent: React.FC<AttributeEditorProps> = (
    {
        configurationAttribute,
        takeAttribute,
        cancelAttribute,
        selectCategory,
        selectAttribute,
        addAttributeMasspoint,
        selectAttributeMasspoint,
        updateAttributeValueFrom,
        updateAttributeValueTo,
        deleteAttributeMasspoint
    }) => {

    const renderMasspoints = () => {
        return (
            <Grid container
                item
                direction='row'
                md={12}>
                {configurationAttribute.masspoints.map((masspoint, index) => (
                    <Grid item
                        container
                        direction="row"
                        alignItems="center"
                        alignContent='center'
                        style={{ marginTop: "5px" }}
                        md={12}
                        spacing={3}
                        key={index}>
                        <Grid item>
                            <DropdownComponent name="Maßpunkt - Kriterium"
                                data={masspoint.availableMasspoints}
                                isLoading={false}
                                isRequired={true}
                                displayedProperties={["displayName"]}
                                onSelect={(selectedMasspoint) =>
                                    selectAttributeMasspoint({
                                        masspoint: selectedMasspoint,
                                        index: index
                                    })
                                }
                                selectedValue={masspoint.masspoint}
                            />
                        </Grid>
                        <Grid item>
                            <EditorDescriptionComponent>von</EditorDescriptionComponent>
                        </Grid>
                        <Grid item>
                            <DecimalInputComponent
                                name="von"
                                fixedPositions={1}
                                placeholder="1,0"
                                minValue={0.1}
                                maxValue={999.9}
                                isRequired={true}
                                onChange={(value) => updateAttributeValueFrom({ index: index, value: value })}
                                value={masspoint.valueFrom}
                            />
                        </Grid>
                        <Grid item>
                            <EditorDescriptionComponent>bis</EditorDescriptionComponent>
                        </Grid>
                        <Grid item>
                            <DecimalInputComponent
                                name="bis"
                                fixedPositions={1}
                                placeholder="999,9"
                                minValue={0.1}
                                maxValue={999.9}
                                isRequired={true}
                                onChange={(value) => updateAttributeValueTo({ index: index, value: value })}
                                value={masspoint.valueTo}
                            />
                        </Grid>
                        <Grid item>
                            <IconButton
                                style={{ outline: "none", marginLeft: "15px" }}
                                onClick={() => deleteAttributeMasspoint(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}

                <Grid item
                    style={{ marginTop: "15px" }}
                    md={6}>
                    <ListButtonComponent
                        disabled={configurationAttribute.attribute == undefined || !configurationAttribute.masspoints.every(x => x.masspoint != undefined && !isNaN(x.valueFrom) && !isNaN(x.valueTo) && x.valueFrom < x.valueTo)}
                        onClick={() => addAttributeMasspoint()}>
                        <AddIcon /> Maßpunkt hinzufügen
                    </ListButtonComponent>
                </Grid>
            </Grid>
        );
    }

    const disableTake = configurationAttribute.masspoints.length <= 0 || configurationAttribute.masspoints.findIndex(ds => ds.masspoint == undefined || isNaN(ds.valueFrom) || isNaN(ds.valueTo) || ds.valueFrom >= ds.valueTo) >= 0;

    return (
        <>
            <Grid spacing={3}
                direction="column"
                container
                justifyContent="space-between">
                <Grid item>
                    <EditorMediumSectionHeaderComponent style={{ marginTop: "0px" }}>Sondergröße konfigurieren</EditorMediumSectionHeaderComponent>
                </Grid>
                <Grid item>
                    <FlexBox>
                        <Left>
                            <DropdownComponent name="Attribute Kategorie"
                                data={configurationAttribute.availableCategories}
                                isRequired={true}
                                onSelect={(e) => selectCategory(e)}
                                displayedProperties={["string", "name"]}
                                selectedValue={configurationAttribute.category}
                            />
                            <DropdownComponent name="Attribute"
                                data={configurationAttribute.availableAttributes}
                                isRequired={true}
                                onSelect={(e) => selectAttribute(e)}
                                displayedProperties={["code", "name"]}
                                selectedValue={configurationAttribute.attribute}
                            />
                        </Left>
                    </FlexBox>
                </Grid>
                <Grid item>
                    {renderMasspoints()}
                </Grid>
                <Grid item
                    container
                    justifyContent="flex-end"
                    spacing={2}>
                    <Grid item>
                        <PrimaryButtonComponent
                            onClick={cancelAttribute}>
                            Verwerfen
                        </PrimaryButtonComponent>
                    </Grid>
                    <Grid item>
                        <PrimaryButtonComponent
                            disabled={disableTake}
                            onClick={takeAttribute}>
                            Übernehmen
                        </PrimaryButtonComponent>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};