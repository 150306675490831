import { NavigateFunction } from "react-router-dom";
import { AppDispatch } from "../../../app/redux_store";
import { addValidityScope, allowArticleType, allowQuality, applyEditableScope, cancelEditableScope, createProductionInstructionCompleted, createProductionInstructionErrorCompleted, deleteValidityScope, denyArticleType, denyQuality, editScopeConfiguration, filterSelectedArticleType, filterSelectedQuality, filterUnselectedArticleType, filterUnselectedQuality, selectArticleTypes, selectCalingaKey, selectIsAutoProductionEnabled, selectIsDocumentsEnabled, selectIsFreetextEnabled, selectQualities, selectSymbol, unselectArticleTypes, unselectQualities, updateBodyArea, updateMainProductLine, updateProductionInstructionCompleted, updateProductionInstructionErrorCompleted } from "./redux/production_instruction.slice";
import { IProductionInstructionEditorAction } from "../components/production_instruction_editor.model";
import { ProductionInstructionState } from "./redux/production_instruction.model";
import { EnqueueSnackbar } from "notistack";
import { EditorModeEnum } from "models/editors/editor_mode.enum";
import { createProductionInstruction, updateProductionInstruction } from "./redux/production_instruction.thunks";

export const handleCommandAndQueryStatus = (state: ProductionInstructionState, dispatch: AppDispatch, navigate: NavigateFunction, enqueueSnackbar: EnqueueSnackbar) => {

    if (state.command.updateProductionInstruction.status === "error") {
        enqueueSnackbar(state.command.updateProductionInstruction.message, { variant: "error" });
        dispatch(updateProductionInstructionErrorCompleted());
    }

    if (state.command.updateProductionInstruction.status === "warning") {
        enqueueSnackbar(state.command.updateProductionInstruction.message, { variant: "warning" });
        dispatch(updateProductionInstructionErrorCompleted());
    }

    if (state.command.updateProductionInstruction.status === "success") {
        enqueueSnackbar("Produktionsanweisung gespeichert", { variant: "success" });
        dispatch(updateProductionInstructionCompleted());
        navigate("/additions/production-instruction/overview");
    }
    
    if (state.command.createProductionInstruction.status === "error") {
        enqueueSnackbar(state.command.createProductionInstruction.message, { variant: "error" });
        dispatch(createProductionInstructionErrorCompleted());
    }

    if (state.command.createProductionInstruction.status === "warning") {
        enqueueSnackbar(state.command.createProductionInstruction.message, { variant: "warning" });
        dispatch(createProductionInstructionErrorCompleted());
    }

    if (state.command.createProductionInstruction.status === "success") {
        enqueueSnackbar("Produktionsanweisung gespeichert", { variant: "success" });
        dispatch(createProductionInstructionCompleted());
        navigate("/additions/production-instruction/overview");
    }
}

export const getProductionInstructionEditorActions = (state: ProductionInstructionState, dispatch: AppDispatch, navigate: NavigateFunction): IProductionInstructionEditorAction => {
    return{
        saveProductionInstruction: () => dispatch(state.actualData.editorMode === EditorModeEnum.Edit ? updateProductionInstruction() : createProductionInstruction()),
        cancelSave: () => navigate("/additions/production-instruction/overview"),
        selectProductionInstructionCalingaKey: (value) => dispatch(selectCalingaKey(value)),
        selectSymbol: (value) => dispatch(selectSymbol(value)),
        selectIsDocumentEnabled: (value) => dispatch(selectIsDocumentsEnabled(value)),
        selectIsFreetextEnabled: (value) => dispatch(selectIsFreetextEnabled(value)),
        selectIsAutoProductionEnabled: (value) => dispatch(selectIsAutoProductionEnabled(value)),
        filterSelectedArticleType: (item) => dispatch(filterSelectedArticleType(item)),
        filterUnselectedArticleType: (item) => dispatch(filterUnselectedArticleType(item)),
        selectArticleTypes: (item) => dispatch(selectArticleTypes(item)),
        unselectArticleTypes: (item) => dispatch(unselectArticleTypes(item)),
        allowArticleType: () => dispatch(allowArticleType()),
        denyArticleType: () => dispatch(denyArticleType()),
        filterSelectedQuality: (item) => dispatch(filterSelectedQuality(item)),
        filterUnselectedQuality: (item) => dispatch(filterUnselectedQuality(item)),
        selectQualities: (item) => dispatch(selectQualities(item)),
        unselectQualities: (item) => dispatch(unselectQualities(item)),
        allowQuality: () => dispatch(allowQuality()),
        denyQuality: () => dispatch(denyQuality()),
        cancelEditableScope: () => dispatch(cancelEditableScope()),
        applyEditableScope: () => dispatch(applyEditableScope()),
        editScopeConfiguration: (index) => dispatch(editScopeConfiguration(index)),
        addValidityScope: () =>  dispatch(addValidityScope()),
        deleteValidityScope: (value) =>  dispatch(deleteValidityScope(value)),
        updateBodyArea: (value) => dispatch(updateBodyArea(value)),
        updateMainProductLine: (value) => dispatch(updateMainProductLine(value)),
    }

};