import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EnumDescriptor } from 'models/enum_descriptor';
import { MasspointSubMassCodeEnum } from 'models/masspoints/enums/masspoint_sub_code.enum';
import { MasspointSubMassCodeEnumValues } from '../../../models/masspoints/enums/masspoint_sub_code.enum';
import { MassPointEditReferencesState, UpdateArticleTypePayload, UpdateMainProductLinePayload, UpdateMassCodePayload, UpdateMassSubCodePayload } from './edit_references.model';
import { updateActualData, updateCanRemoveMainReference, updateCanUpdateReferences, updateIsAddReferenceEnabled } from './edit_references.reducer';
import { cancelEditReferences, fetchExistingBaseData, fetchExistingMassPoint, fetchExistingReferences, initData, updateReferences } from './edit_references.thunks';

const initialState: MassPointEditReferencesState = {
    actualData: {
        massCode: "",
        massSubCode: MasspointSubMassCodeEnumValues[0],
        references: [],
        availableMassSubCodes: MasspointSubMassCodeEnumValues,
        isRemoveMainReferenceEnabled: false,
        isAddReferenceEnabled: false,
    },
    loadedData: {},
    query: {
        initData: { status: "idle", canExecute: true },
        fetchExistingMassPoint: { status: "idle", canExecute: true },
        fetchExistingReferences: { status: "idle", canExecute: true },
        fetchExistingBaseData: { status: "idle", canExecute: true },
    },
    command: {
        updateReferences: { status: "idle", canExecute: false },
        cancelEditReferences: { status: "idle", canExecute: true },
    },
}

export const editReferencesSlice = createSlice({
    name: 'masspoint/edit_references',
    initialState,
    reducers: {
        resetState: (state) => {
            state.command = initialState.command;
            state.query = initialState.query;
            state.actualData = initialState.actualData;
            state.loadedData = initialState.loadedData;
        },
        updateMainMassCode: (state, action: PayloadAction<string>) => {
            state.actualData.massCode = action.payload;
            updateCanUpdateReferences(state);
            updateIsAddReferenceEnabled(state);
        },
        updateMainMassSubCode: (state, action: PayloadAction<EnumDescriptor<MasspointSubMassCodeEnum>>) => {
            state.actualData.massSubCode = action.payload;
            updateCanUpdateReferences(state);
            updateIsAddReferenceEnabled(state);
        },
        removeMainReference: (state) => {
            state.actualData.massSubCode = MasspointSubMassCodeEnumValues[0];
            state.actualData.massCode = "";
            updateCanUpdateReferences(state);
            updateIsAddReferenceEnabled(state);
        },
        addReference: (state) => {
            state.actualData.references.push({
                massCode: "",
                massSubCode: MasspointSubMassCodeEnumValues[0],
                articleType: null,
                mainProductLine: state.loadedData.existingMainProductLines[0],
            });
            updateCanUpdateReferences(state);
            updateCanRemoveMainReference(state);
        },
        removeReference: (state, action: PayloadAction<number>) => {
            const index = action.payload;
            state.actualData.references.splice(index, 1);
            updateCanUpdateReferences(state);
            updateCanRemoveMainReference(state);
        },
        updateMassCode: (state, action: PayloadAction<UpdateMassCodePayload>) => {
            const payload = action.payload;
            state.actualData.references[payload.index].massCode = payload.massCode;
            updateCanUpdateReferences(state);
        },
        updateMassSubCode: (state, action: PayloadAction<UpdateMassSubCodePayload>) => {
            const payload = action.payload;
            state.actualData.references[payload.index].massSubCode = payload.massSubCode;
            updateCanUpdateReferences(state);
        },
        updateArticleType: (state, action: PayloadAction<UpdateArticleTypePayload>) => {
            const payload = action.payload;
            state.actualData.references[payload.index].articleType = payload.articleType;
            updateCanUpdateReferences(state);
        },
        updateMainProductLine: (state, action: PayloadAction<UpdateMainProductLinePayload>) => {
            const payload = action.payload;
            state.actualData.references[payload.index].mainProductLine = payload.mainProductLine;
            updateCanUpdateReferences(state);
        },
    }, extraReducers: (builder) => {

        // initData
        builder.addCase(initData.pending, (state, action) => {
            state.query.initData.status = "pending"
            state.query.initData.canExecute = false;
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.message = action.error.message;
            state.query.initData.canExecute = true;
        }).addCase(initData.fulfilled, (state, action) => {
            state.query.initData.status = "success"
            state.query.initData.message = undefined;
            state.query.initData.canExecute = true;
            updateActualData(state);
            updateCanUpdateReferences(state);
            updateCanRemoveMainReference(state);
            updateIsAddReferenceEnabled(state);

            // fetchExistingMassPoint
        }).addCase(fetchExistingMassPoint.pending, (state, action) => {
            state.query.fetchExistingMassPoint.status = "pending"
            state.query.fetchExistingMassPoint.canExecute = false;
        }).addCase(fetchExistingMassPoint.rejected, (state, action) => {
            state.query.fetchExistingMassPoint.status = "error"
            state.query.fetchExistingMassPoint.canExecute = true;
            state.query.fetchExistingMassPoint.message = action.error.message;
        }).addCase(fetchExistingMassPoint.fulfilled, (state, action) => {
            state.query.fetchExistingMassPoint.status = "success"
            state.query.fetchExistingMassPoint.canExecute = true;
            state.loadedData.existingMassPoint = action.payload.getData();

            // fetchExistingReferences
        }).addCase(fetchExistingReferences.pending, (state, action) => {
            state.query.fetchExistingReferences.status = "pending"
            state.query.fetchExistingReferences.canExecute = false;
        }).addCase(fetchExistingReferences.rejected, (state, action) => {
            state.query.fetchExistingReferences.status = "error"
            state.query.fetchExistingReferences.canExecute = true;
            state.query.fetchExistingReferences.message = action.error.message;
        }).addCase(fetchExistingReferences.fulfilled, (state, action) => {
            state.query.fetchExistingReferences.status = "success"
            state.query.fetchExistingReferences.canExecute = true;
            state.loadedData.existingReferences = action.payload.getData();

            // fetchExistingBaseData
        }).addCase(fetchExistingBaseData.pending, (state, action) => {
            state.query.fetchExistingBaseData.status = "pending"
            state.query.fetchExistingBaseData.canExecute = false;
        }).addCase(fetchExistingBaseData.rejected, (state, action) => {
            state.query.fetchExistingBaseData.status = "error"
            state.query.fetchExistingBaseData.canExecute = true;
            state.query.fetchExistingBaseData.message = action.error.message;
        }).addCase(fetchExistingBaseData.fulfilled, (state, action) => {
            state.query.fetchExistingBaseData.status = "success"
            state.query.fetchExistingBaseData.canExecute = true;
            const data = action.payload.getData().availableArticleTypes;
            state.loadedData.existingArticleTypes = data;
            state.actualData.availableArticleTypes = data;
            const dataLoaded = action.payload.getData().availableMainProductLines;
            const dataMainProductLine = [{ name: "Alle", id: null, erpId: null }, ...dataLoaded];          
            state.loadedData.existingMainProductLines =  dataMainProductLine;
            state.actualData.availableMainProductLines = dataMainProductLine;      

            // updateReferences
        }).addCase(updateReferences.pending, (state, action) => {
            state.command.updateReferences.status = "pending"
            state.command.updateReferences.canExecute = false;
        }).addCase(updateReferences.rejected, (state, action) => {
            state.command.updateReferences.status = "error"
            state.command.updateReferences.canExecute = true;
            state.command.updateReferences.message = action.error.message;
        }).addCase(updateReferences.fulfilled, (state, action) => {
            state.command.updateReferences.status = "success"
            state.command.updateReferences.canExecute = true;

            // cancelEditCodeReferences
        }).addCase(cancelEditReferences.pending, (state, action) => {
            state.command.cancelEditReferences.status = "pending"
        }).addCase(cancelEditReferences.fulfilled, (state, action) => {
            state.command.cancelEditReferences.status = "success"
        })

    }
})

export const {
    resetState,
    updateMainMassCode,
    updateMainMassSubCode,
    removeMainReference,
    addReference,
    removeReference,
    updateMassCode,
    updateMassSubCode,
    updateArticleType,
    updateMainProductLine,
} = editReferencesSlice.actions

export default editReferencesSlice.reducer