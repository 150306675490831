import { ThumbnailModel } from "../../models/thumbnail.model";
import { ThumbnailComponent } from "../../shared/components/buttons/thumbnail.component";
import massMaskImage from './images/massmasks.jpg';
import * as style from "./overview.style";

export const MassPointsOverviewComponent = () => {
    const thumbnails: ThumbnailModel[] = [
        new ThumbnailModel("Maßpunkte verwalten", massMaskImage, "/masspoints/masspoint/overview"),
        new ThumbnailModel("Seriengrößen verwalten", massMaskImage, "/masspoints/serialsize/overview"),
        new ThumbnailModel("Maßpunktgruppen verwalten", massMaskImage, "/masspoints/masspointgroup/overview"),
    ];

    return (
        <style.ThumbnailWrapper>
            {thumbnails.map((thumb, i) => {
                return (<ThumbnailComponent key={i} thumbnail={thumb} />)
            })}
        </style.ThumbnailWrapper>
    );
}