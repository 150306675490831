import { DropdownComponent, TextBoxComponent } from "shared/shared";
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { SelectionListComponent } from "shared/components/selectionComponent/selectionList.component";
import { getConfig } from "services/config.service";
import { CalingaLinkComponent } from "shared/components/editors/calinga_link/calinga_link.component";
import { useAppDispatch, useAppSelector } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { filterSelectedMasspoints, filterUnselectedMasspoints, selectMasspoints, selectTranslation, setSelectionAdditionHierarchy, unselectMasspoints, updateAdditionPositionName } from "../redux/addition_position.slice";

export const AdditionPositionInformationComponent: React.FC = () => {

    const state = useAppSelector((state) => state.addition_position);
    const dispatch: AppDispatch = useAppDispatch();

    const renderAdditionHierarchies = () => {
        return (
            <>
                <Typography>
                    <strong>
                        Zusatzhierarchie (mindestens eine)*
                    </strong>
                </Typography>
                {
                    state.actualData.additionHierarchies.map(x => {
                        return (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={x.isSelected}
                                        onChange={(event, checked) => dispatch(setSelectionAdditionHierarchy({ id: x.id, isChecked: checked }))}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label={x.name}
                            />
                        )
                    })
                }
            </>
        );
    }

    const selectedTranslation = state.actualData.selectedAdditionPositionTranslation != null;

    return (
        <Grid container
            direction="column"
            spacing={3}>
            <Grid item md={6}>
                <TextBoxComponent name="Name der Zusatzposition"
                    value={state.actualData.name}
                    onChange={(value) => dispatch(updateAdditionPositionName(value))}
                    placeholder={"z.B. Wade"}
                    isRequired={true}
                    isAutoFocus={true}
                    maxLength={20}
                    width={"100%"}
                />
            </Grid>
            <Grid item container direction={'column'} md={9} spacing={1}>
                <Grid item container direction={'row'} justifyContent={'flex-start'} alignItems={'flex-end'}>
                    <Grid item>
                        <DropdownComponent
                            name="Übersetzungskey"
                            data={state.actualData.additionPositionTranslations}
                            isLoading={false}
                            isRequired={true}
                            onSelect={(translation) => dispatch(selectTranslation(translation))}
                            displayedProperties={["key"]}
                            selectedValue={state.actualData.selectedAdditionPositionTranslation}
                        />
                    </Grid>
                    {selectedTranslation && <Grid item>
                        <Typography textAlign={'end'}>
                            {state.actualData.selectedAdditionPositionTranslation?.value}
                        </Typography>
                    </Grid>}
                </Grid>
                <CalingaLinkComponent projectName={getConfig().calinga.project.additionPositions} />
            </Grid>

            <Grid item md={12}>
                {renderAdditionHierarchies()}
            </Grid>
            <Grid item md={12}>
                <SelectionListComponent
                    title="Maßpunkte verknüpfen"
                    entityName="Maßpunkte"
                    onFilterSelected={(searchText) => { dispatch(filterSelectedMasspoints(searchText)) }}
                    onFilterUnselected={(searchText) => { dispatch(filterUnselectedMasspoints(searchText)) }}
                    selectionList={state.actualData.masspointSelection}
                    selectEntities={(ids) => { dispatch(selectMasspoints(ids)) }}
                    unselectEntities={(ids) => { dispatch(unselectMasspoints(ids)) }}
                />
            </Grid>
        </Grid>
    );
}