import { Grid } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { OverviewDatagrid } from "shared/components/datagrid/overview_datagrid.component";
import { DialogComponent } from "shared/components/dialogs/dialog.component";
import SearchFieldComponent from "shared/components/search/searchfield.component";
import { LoadingIndicatorComponent, FlexBox, Left, Right, PrimaryButtonComponent } from "shared/shared";
import { getGridColumns, sortByNameModel } from "./masspoint_group_overview.helper";
import { resetState, setSearchFilter, setGridFilters, deleteMasspointGroup, confirmDeleteMasspointGroupCompleted, cancelDeleteMasspointGroup } from "./redux/masspoint_group_overview.slice";
import { confirmDeleteMasspointGroup, fetchMasspointGroupOverviews } from "./redux/masspoint_group_overview.thunks";
import AddIcon from "@mui/icons-material/Add";
import * as style from "./masspoint_group_overview.style";


export const MassPointGroupOverviewComponent = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const state = useAppSelector((state) => state.masspoint_group_overviews);

  useEffect(() => {
    dispatch(fetchMasspointGroupOverviews());
    return () => {
      dispatch(resetState());
    }
  }, [dispatch]);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  if (state.command.confirmDeleteMasspointGroup.status === "error") {
    enqueueSnackbar(state.command.confirmDeleteMasspointGroup.message, { variant: "warning" });
    dispatch(confirmDeleteMasspointGroupCompleted());
  }

  if (state.command.confirmDeleteMasspointGroup.status === "success") {
    enqueueSnackbar("Maßpunktgruppe entfernt", { variant: "success" });
    dispatch(confirmDeleteMasspointGroupCompleted())
  }

  if (state.query.fetchMasspointGroupOverviews.status === "error") {
    return <div>Fehler beim Laden der Daten</div>;
  }

  if (state.query.fetchMasspointGroupOverviews.status !== "success") {
    return <LoadingIndicatorComponent />;
  }

  const isLoading = state.command.confirmDeleteMasspointGroup.status === "pending";
  const masspointGroupsAvailable = state.loadedData.masspointGroups.length > 0;

  return (
    <div>
      {
        state.actualData.showDeleteMasspointGroupDialog &&
        <DialogComponent
          title={"Maßpunktgruppe löschen?"}
          isOpen={true}
          options={[
            { title: "Abbrechen", handleOption: () => { dispatch(cancelDeleteMasspointGroup()) } },
            { title: "Löschen", handleOption: () => { dispatch(confirmDeleteMasspointGroup()) } }
          ]}
          onClose={() => { }}>
          <Grid container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}>
            <Grid item>
              Möchten Sie die Maßpunktgruppe {state.actualData.selectedMasspointGroupOverview.identifier} löschen?
            </Grid>
            <Grid item>
              
            </Grid>
          </Grid>
        </DialogComponent>
      }

      <FlexBox>
        {
          masspointGroupsAvailable &&
          <Left>
            <SearchFieldComponent onSearch={(filterText) => dispatch(setSearchFilter(filterText))} placeholder="In Maßpunktgruppen suchen ..." />
          </Left>
        }
        <Right>
          <PrimaryButtonComponent onClick={() => navigate("/masspoints/masspointgroup/create")}>
            <AddIcon /> Neue Maßpunktgruppe erstellen
          </PrimaryButtonComponent>
        </Right>
      </FlexBox>
      <style.TableContainer>
        {
          !masspointGroupsAvailable &&
          <div>Keine Maßpunktgruppe verfügbar</div>
        }
        {
          masspointGroupsAvailable &&
          <OverviewDatagrid
            isLoading={isLoading}
            columns={getGridColumns(
              (masspointgroup) => navigate(`/masspoints/masspointgroup/edit/${masspointgroup.id}`),
              (masspointgroup) => dispatch(deleteMasspointGroup(masspointgroup))
            )}
            rows={state.actualData.masspointGroups}
            loadedRowsCount={state.loadedData.masspointGroups.length}
            sortModel={sortByNameModel}
            onFilterModelChange={(newFilterModel) => dispatch(setGridFilters(newFilterModel))}
            onDoubleClickRow={(masspointgroup) => navigate(`/masspoints/masspointgroup/edit/${masspointgroup.id}`)}
          />
        }
      </style.TableContainer>
    </div>
  );
};
