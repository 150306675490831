import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPolicyOverview } from 'models/policies/policy_overview';
import { RootState } from '../../../../app/redux_store';
import PolicyServiceAPI from '../../../../services/policy.service';
import { handleResponseErrors } from '../../../../shared/networking/error_handling';

export const fetchPolicies = createAsyncThunk(
    'policies/policy_overview/fetchPolicies',
    async (_) => {
        const response = await PolicyServiceAPI.getPolicies();
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const togglePublish = createAsyncThunk(
    'policies/policy_overview/togglePublish',
    async (policy: IPolicyOverview, { dispatch }) => {
        if (policy.isPublished) {
            await dispatch(unpublish(policy));
        } else {
            await dispatch(publish(policy));
        }
    }
)

export const publish = createAsyncThunk(
    'policies/policy_overview/publish',
    async (policy: IPolicyOverview, _) => {
        const responsePublish = await PolicyServiceAPI.publish(policy);
        handleResponseErrors(responsePublish, "Regelwerk");
        const responsePolicies = await PolicyServiceAPI.getPolicies();
        handleResponseErrors(responsePolicies, "Regelwerke");
        return responsePolicies;
    }
)

export const unpublish = createAsyncThunk(
    'policies/policy_overview/unpublish',
    async (policy: IPolicyOverview, thunkApi) => {
        const responseUnpublish = await PolicyServiceAPI.unpublish(policy);
        handleResponseErrors(responseUnpublish, "Regelwerk");
        const responsePolicies = await PolicyServiceAPI.getPolicies();
        handleResponseErrors(responsePolicies, "Regelwerke");
        return responsePolicies;
    }
)

export const confirmDelete = createAsyncThunk(
    'policies/policy_overview/confirmDelete',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const activePolicyId = state.policy_overview.actualData.policy.id

        const deleteResponse = await PolicyServiceAPI.deletePolicy(activePolicyId);
        handleResponseErrors(deleteResponse, "Regelwerk");
        const responsePolicies = await PolicyServiceAPI.getPolicies();
        handleResponseErrors(responsePolicies, "Regelwerke");
        return responsePolicies;
    }
)

export const createNewMandatoryCategoryPolicy = createAsyncThunk(
    'policies/policy_overview/createNewMandatoryCategoryPolicy',
    async (_) => { }
)

export const createNewMandatoryCategoryByMasspointPolicy = createAsyncThunk(
    'policies/policy_overview/createNewMandatoryCategoryByMasspointPolicy',
    async (_) => { }
)

export const createNewWhippedSeamPolicy = createAsyncThunk(
    'policies/policy_overview/createNewWhippedSeamPolicy',
    async (_) => { }
)

export const createNewSideSeamPolicy = createAsyncThunk(
    'policies/policy_overview/createNewSideSeamPolicy',
    async (_) => { }
)

export const createNewScarTreatmentPolicy = createAsyncThunk(
    'policies/policy_overview/createNewScarTreatmentPolicy',
    async (_) => { }
)

export const createNewAdditionRankPolicy = createAsyncThunk(
    'policies/policy_overview/createNewAdditionRankPolicy',
    async (_) => { }
)

export const createNewFingersAndToesPolicy = createAsyncThunk(
    'policies/policy_overview/createNewFingersAndToesPolicy',
    async (_) => { }
)

export const createNewDefaultAdditionByArticleTypePolicy = createAsyncThunk(
    'policies/policy_overview/createNewDefaultAdditionByArticleTypePolicy',
    async (_) => { }
)

export const createNewDefaultAdditionByQualityAndAttributePolicy = createAsyncThunk(
    'policies/policy_overview/createNewDefaultAdditionByQualiotyAndAttributePolicy',
    async (_) => { }
)

export const createNewDefaultByAttributePolicy = createAsyncThunk(
    'policies/policy_overview/createNewDefaultByAttributePolicy',
    async (_) => { }
)

export const createNewExcludeAdditionByMasspointPolicy = createAsyncThunk(
    'policies/policy_overview/createNewExcludeAdditionByMasspointPolicy',
    async (_) => { }
)

export const createNewExcludeAdditionByMasspointValuePolicy = createAsyncThunk(
    'policies/policy_overview/createNewExcludeAdditionByMasspointValuePolicy',
    async (_) => { }
)

export const createNewUpperThresholdPolicy = createAsyncThunk(
    'policies/policy_overview/createNewUpperThresholdPolicy',
    async (_) => { }
)

export const createNewAdjustRangesByMasspointPolicy = createAsyncThunk(
    'policies/policy_overview/createNewAdjustRangesByMasspointPolicy',
    async (_) => { }
)

export const createNewAdjustMaximumRangePolicy = createAsyncThunk(
    'policies/policy_overview/createNewAdjustMaximumRangePolicy',
    async (_) => { }
)

export const updatePolicy = createAsyncThunk(
    'policies/policy_overview/updatePolicy',
    async (policy: IPolicyOverview) => { return policy }
)
