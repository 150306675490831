import { EnumDescriptor } from "../enum_descriptor";

export enum AdditionPlaceHolderTypeEnum {
    None = 0,
    TextInput = 1,
    RangeInput = 2,
    TwoRangeInput = 3,
}

export const AdditionPlaceHolderTypeValues: Array<
    EnumDescriptor<AdditionPlaceHolderTypeEnum>
> = [
        { value: AdditionPlaceHolderTypeEnum.None, text: "Keine Auswahl" },
        { value: AdditionPlaceHolderTypeEnum.TextInput, text: "Zeichenanzahl" },
        { value: AdditionPlaceHolderTypeEnum.RangeInput, text: "Rangewert" },
        { value: AdditionPlaceHolderTypeEnum.TwoRangeInput, text: "2 Rangewerte (von - bis)" },
    ];