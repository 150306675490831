import { ThumbnailModel } from "../../models/thumbnail.model";
import { ThumbnailComponent } from "../../shared/components/buttons/thumbnail.component";
import additionImage from './images/additionals.jpg';
import * as style from "./overview.style";

export const AdditionsOverviewComponent = () => {
    const thumbnails: ThumbnailModel[] = [
        new ThumbnailModel("Zusätze", additionImage, "/additions/addition/overview"),
        new ThumbnailModel("Zusatzkategorien", additionImage, "/additions/additioncategory/overview"),
        new ThumbnailModel("Produktionsanweisungen", additionImage, "/additions/production-instruction/overview"),
        new ThumbnailModel("Zusatzpositionen", additionImage, "/additions/additionposition"),
        new ThumbnailModel("Positionskategorien", additionImage, "/additions/positioncategory/overview"),
        new ThumbnailModel("Ausprägungskategorien", additionImage, "/additions/characteristiccategory/overview"),
        new ThumbnailModel("Ausprägungstypen", additionImage, "/additions/characteristictype/overview"),
    ];

    return (
        <style.ThumbnailWrapper>
            {thumbnails.map((thumb, i) => {
                return (<ThumbnailComponent key={i} thumbnail={thumb} />)
            })}
        </style.ThumbnailWrapper>
    );
}