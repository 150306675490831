import React from "react";
import { SerialSizeTableEntryContainer, SerialSizeTableEntryItem } from "./serial_sizes.style";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from "@mui/material";
import { ISizeViewModel } from "./serial_size_editor.model";

export interface SizeSummaryDetailComponentProps {
    sizeSummary: ISizeViewModel;
    disabledSize: boolean;
    editSize: (size: ISizeViewModel) => void;
    deleteSize: (size: ISizeViewModel) => void;
}

export const SizeSummaryDetailComponent: React.FC<SizeSummaryDetailComponentProps> = ({ 
    sizeSummary,
    disabledSize,
    editSize,
    deleteSize,
}) => {

    const masspointNames = sizeSummary.masspoints.map(mp => mp.masspoint.displayName).join(", ");
    return (
        <>
            <SerialSizeTableEntryContainer>
                <SerialSizeTableEntryItem width="42px" backgroundColor="white"/>
                <SerialSizeTableEntryItem width="150px" backgroundColor="white">
                    {sizeSummary.size.name}
                </SerialSizeTableEntryItem>
                <SerialSizeTableEntryItem flex={1} backgroundColor="white">
                    {masspointNames}
                </SerialSizeTableEntryItem>
                <SerialSizeTableEntryItem width="80px" backgroundColor="white">
                    <IconButton
                        disabled={disabledSize}
                        style={{ outline: "none" }}
                        onClick={() => editSize(sizeSummary)}>
                        <EditIcon />
                    </IconButton>
                </SerialSizeTableEntryItem>
                <SerialSizeTableEntryItem width="80px">
                    <IconButton
                        disabled={disabledSize}
                        style={{ outline: "none" }}
                        onClick={() => deleteSize(sizeSummary)}>
                        <DeleteIcon />
                    </IconButton>            
                </SerialSizeTableEntryItem>   
            </SerialSizeTableEntryContainer>    
        </>
    )
}