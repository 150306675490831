import React, { useEffect } from "react";
import { LoadingIndicatorComponent } from '../shared/components/loading_indicator/loading_indicator.component';
import { cancelSave, fetchAllData, saveRules } from './redux/rules_min_max.thunks';
import { resetState, saveRulesCompleted, saveRulesErrorCompleted } from './redux/rules_min_max.slice';
import { RuleElementComponent } from './components/rule_element.component';
import { useAppDispatch, useAppSelector } from '../app/hook';
import { AppDispatch } from '../app/redux_store';
import { Grid } from "@mui/material";
import { LoadingOverlayComponent, LoadingOverlayContainer } from '../shared/components/loading_overlay/loading_overlay.component';
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

interface RulesMinMaxComponentProps {
    checkId: number;
}

export const RulesMinMaxComponent: React.FC<RulesMinMaxComponentProps> = ({ checkId }) => {
    const state = useAppSelector((state) => state.rules_min_max);
    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        dispatch(fetchAllData(checkId));
        return () => {
            dispatch(resetState());
        }
    }, [dispatch, checkId]);

    if (state.query.fetchMassPointCriterias.status === "error") {
        enqueueSnackbar(state.query.fetchMassPointCriterias.message, { variant: "error" });
        return <div>Fehler beim Laden der Basisdaten</div>;
    }   
    
    if (state.query.fetchCheck.status === "error") {
        enqueueSnackbar(state.query.fetchCheck.message, { variant: "error" });
        return <div>Fehler beim Laden der Basisdaten</div>;
    }

    if (state.query.fetchRules.status === "error") {
        enqueueSnackbar(state.query.fetchRules.message, { variant: "error" });
        return <div>Fehler beim Laden der Regeln</div>;
    }

    if (state.query.fetchAllData.status !== "success") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.saveRules.status === "error") {
        enqueueSnackbar(state.command.saveRules.message, { variant: "error" });
        dispatch(saveRulesErrorCompleted());
    }

    if (state.command.saveRules.status === "warning") {
        enqueueSnackbar(state.command.saveRules.message, { variant: "warning" });
        dispatch(saveRulesErrorCompleted());
    }

    if (state.command.saveRules.status === "success") {
        enqueueSnackbar("Prüfungsregeln gespeichert", { variant: "success" });
        dispatch(saveRulesCompleted());
        navigate("/plausibilitycheck/check?focusId=" + state.loadedData.check.id);
    }

    if (state.command.cancel.status === "success") {
        navigate("/plausibilitycheck/check");
    }

    const isSaving = state.command.saveRules.status === "pending";

    return (<>
        <LoadingOverlayContainer>
            {isSaving ? <LoadingOverlayComponent /> : <></>}

            <Grid container item spacing={2} xs={12}>
                {state.actualData.rules.map((_, index) => {
                    return (<Grid item key={index} xs={12}>
                        <RuleElementComponent key={index} ruleIndex={index} />
                    </Grid>);
                })}
                <EditorButtonComponent
                    canExecute={state.command.saveRules.canExecute}
                    save={() => dispatch(saveRules())}
                    cancelSave={() => dispatch(cancelSave())}
                />
            </Grid>
        </LoadingOverlayContainer>
    </>)
};
