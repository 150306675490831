import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "app/redux_store";
import AdditionHierarchyService from "services/addition_hierarchy.service";
import AdditionPositionService from "services/addition_position.service";
import MassPointService from "services/masspoint.service";
import { handleResponseErrors } from '../../../shared/networking/error_handling';
import { EditorModeEnum } from "models/editors/editor_mode.enum";
import { setEditorMode } from "./addition_position.slice";

export const fetchAllData = createAsyncThunk(
    'additionPosition/fetchAllData',
    async (id: string, thunkApi) => {
        await Promise.all([
            thunkApi.dispatch(getMassPoints()),
            thunkApi.dispatch(getAdditionHierarchies()),
            thunkApi.dispatch(getAdditionPositionTranslations())
        ]);

        if(id){
            thunkApi.dispatch(setEditorMode(EditorModeEnum.Edit))
            await thunkApi.dispatch(getAdditionPosition(id))
        }
    }
)

export const getAdditionPosition = createAsyncThunk(
    'additionPosition/getAdditionPosition',
    async (id: string) => {
        const response = await AdditionPositionService.getAdditionPosition(id);
        handleResponseErrors(response, "Zusatzposition");
        return response;
    }
)

export const getMassPoints = createAsyncThunk(
    'additionPosition/getMassPoints',
    async (_) => {
        const response = await MassPointService.getMassPoints()
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)

export const getAdditionHierarchies = createAsyncThunk(
    'additionPosition/getAdditionHierarchies',
    async (_) => {
        const response = await AdditionHierarchyService.getAdditionHierarchies()
        handleResponseErrors(response, "ZusatzHierarchien");
        return response;
    }
)

export const getAdditionPositionTranslations = createAsyncThunk(
    'additionPosition/getAdditionPositionTranslations',
    async (_) => {
        const response = await AdditionPositionService.getAdditionPositionTranslations();
        handleResponseErrors(response, "Zusatzposition Übersetzungskeys");
        return response;
    }
)

export const createAdditionPosition = createAsyncThunk(
    'additionPosition/createAdditionPosition',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const data = state.addition_position.actualData;
        const response = await AdditionPositionService.saveAdditionPosition({
            name: data.name,            
            translationKeyForName: data.selectedAdditionPositionTranslation.key,
            selectedPositionHierarchies: data.additionHierarchies.filter(x => x.isSelected).map(x => {
                return {
                    id: x.id
                }
            }),
            selectedPositionMassPointCriterias: data.masspointSelection.selectedList.allItems.map(x => {
                return {
                    massPointName: x.massPointName,
                    bodyAreaId: x.bodyArea.id,
                    massPointType: x.massPointType
                }
            })

        });
        handleResponseErrors(response, "Zusatzposition");
        return response;
    }
)

export const updateAdditionPosition = createAsyncThunk(
    'additionPosition/updateAdditionPosition',
    async (forceUpdate: boolean, { getState }) => {
        const state = getState() as RootState;
        const data = state.addition_position.actualData;
        const id = state.addition_position.loadedData.id;
        const response = await AdditionPositionService.updateAdditionPosition({
            id: id,
            name: data.name,
            forceUpdate: forceUpdate,
            translationKeyForName: data.selectedAdditionPositionTranslation.key,
            selectedPositionHierarchies: data.additionHierarchies.filter(x => x.isSelected).map(x => {
                return {
                    id: x.id
                }
            }),
            selectedPositionMassPointCriterias: data.masspointSelection.selectedList.allItems.map(x => {
                return {
                    massPointName: x.massPointName,
                    bodyAreaId: x.bodyArea.id,
                    massPointType: x.massPointType
                }
            })

        });

        handleResponseErrors(response, "Zusatzposition");
        return response;
    }
)