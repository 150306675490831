import { BoxProps, Box } from "@mui/material";
import styled from "@emotion/styled";
import colors from "shared/style/colors";

export const EditorBoxStyled = (props: BoxProps) => {
    const { sx, ...other } = props;
    return (
        <Box
            sx={{
                boxShadow: 'none',
                paddingTop: '20px',
                paddingBottom: '20px',
                '&:before': {
                    display: 'none',
                },
                ...sx,
            }}
            {...other}
        />
    );
}

export const EditorConfigurationBox = (props: BoxProps) => {
    const { sx, ...other } = props;
    return (
        <Box
            sx={{
                boxShadow: '2px 2px 2px 1px #6a7c8d',
                background: '#ffffff',
                border: '1px solid #6a7c8d',
                padding: '20px',
                marginTop: '10px',
                marginBottom: '10px',
                '&:before': {
                    display: 'none',
                },
                ...sx,
            }}
            {...other}
        />
    );
}



export interface EditorSummaryHeadlineProps {
    width?: number;
    flex?: number
}
 
export const EditorSummaryHeadline = styled('div')<EditorSummaryHeadlineProps>`
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    ${props => props.width ? 'width: ' + props.width + 'px;': ''}
    ${props => props.flex ? 'flex: ' + props.flex + ';': ''}
    word-wrap: normal;
    padding-right: 10px;
    font-weight: bold;
`;

export interface EditorSummaryItemProps {
    width?: number;
    flex?: number;
}

export interface EditorSummaryItemContainerProps {
    showRedBorder?: boolean;
    backgroundColor?: string;
}

export const EditorSummaryItem = styled.div<EditorSummaryItemProps>`
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    min-height: 60px;
    ${props => props.width ? 'width: ' + props.width + 'px;': ''}
    ${props => props.flex ? 'flex: ' + props.flex + ';': ''}
    word-wrap: normal;
    padding: 10px;
    display: inline-flex;
    flex-wrap: wrap;
    align-content: center;
`
export const EditorSummaryItemContainer = styled.div<EditorSummaryItemContainerProps>`
    border: ${props => props.showRedBorder ? "2px solid red" : "2px solid transparent"};
    ${props => props.backgroundColor ? 'background-color: ' + props.backgroundColor + ';': colors.transparent}
    margin: 4px auto;
    display: flex;

    width: 100%;
`
