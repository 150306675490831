import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/redux_store';
import MassMaskService from '../../../services/massmask.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';
import { BodyInformationService } from 'services/bodyInformation.service';

export const fetchAllData = createAsyncThunk(
    'massMask/commonInfo/fetchAllData',
    async (id: number, thunkApi) => {
        await Promise.all([
            thunkApi.dispatch(getBodyInformations())
        ]);
        await thunkApi.dispatch(getMassMask(id));
    }
)

export const editMassMask = createAsyncThunk(
    'massMask/commonInfo/editMassMask',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const selectedData = state.massmask_common_info_edit.actualData;

        const response = await MassMaskService.updateMassMask({
            massmaskId: Number(selectedData.massMask.id),
            isPantyTopWithStandardDimensions: selectedData.isPantyTopWithStandardDimensions,
            isEllipticalShapeKneeFunctionZone: selectedData.isEllipticalShapeKneeFunctionZone,
            hideFingersAndToes: selectedData.hideFingersAndToes,
            selectedBodyInformations: selectedData.massMask ? selectedData.bodyInformationSelection.map((x, index) => {
                return {
                    id: x.id,
                    index: index
                }
            }) : undefined
        });

        handleResponseErrors(response, "Maßmaske");
        return response;
    }
)

export const getBodyInformations = createAsyncThunk(
    'massMask/commonInfo/getBodyInformations',
    async (_) => {
        const response = await BodyInformationService.getBodyInformations();
        handleResponseErrors(response, "Zusätzliche Angabe");
        return response;
    }
)

export const getMassMask = createAsyncThunk(
    'massMask/commonInfo/getMassMask',
    async (massMaskId: number, _) => {
        const response = await MassMaskService.getMassmask(massMaskId);
        handleResponseErrors(response, "Maßmaske");
        return response;
    }
)