import { AdditionCompressionClassEnum } from './addition_compressionclass.enum';
import { ProductionTypeEnum } from './productiontype.enum';
import { MasspointTypeEnum } from 'models/masspoints/enums/masspoint_type.enum';
import { LockTypeEnum } from 'shared/components/selectionComponent/models/locktype.enum';
import { QuantityCodeEnum } from './quantity_code.enum';
import { QuantityCalculationModeEnum } from './quantity_calculation_mode.enum';

export default interface IAddition {
    id?: number;
    option: string;
    code: string;
    description: string;
    descriptionLong: string;
    quantityCalculationMode: QuantityCalculationModeEnum;
    quantityCode?: QuantityCodeEnum;
    quantityText: string;
    side: number;
    createOrderPosition: boolean;
    calculateOrderPosition: boolean;
    orderManagement: number;
    reOrder: boolean;
    isCountryLocked: boolean,
    isFullyKnitted: boolean,
    compressionClass: AdditionCompressionClassEnum,
    productionType: ProductionTypeEnum,
    countryLockType: LockTypeEnum,
    additionCountryVersions: AdditionCountry[];
    automaticProduction: boolean;
    printArea: number;
    hasCalingaDescription: boolean;
    placeholderVersion: IPlaceholder;
    category: ICategory;
    validityScopes: IValidityScopeResponse[];
    additionExcludeVersions: IAdditionExclude[];
    additionAttributeCategoryVersions: IAdditionAttributeCategory[];
    isFootTypeLocked: boolean,
    isPositioned: boolean,
    isRecipeValid: boolean,
    isGusset: boolean,
    isMultiply: boolean,
    footTypeLockType: LockTypeEnum,
    additionFootTypeVersions: AdditionFootType[];
    assignedPositionCategoryVersions: AssignedPositionCategory[];
    characteristicCategory: AssignedCharacteristicCategory;
    additionGussets: IAdditionGusset[];
    positionCode: string;
    maxPositions?: number;
    isCharacteristic: boolean;
}

export interface AssignedCharacteristicCategory {
    characteristicCategoryId: number;
    name: string;
}

export interface AssignedPositionCategory {
    positionCategoryId: number;
    name: string;
}

export interface AdditionFootType {
    footTypeId: number;
}

export interface ICategory {
    id?: number;
    categoryKey: string;
    categoryText?: string;
    hasTranslation: boolean;
}

export interface AdditionCountry {
    countryId: number;
}

export interface IPlaceholder {
    id?: number;
    placeholderTemplate: number;
    defaultTextInputPlaceholderVersion: ITextInputPlaceholderVersion;
    defaultRangePlaceholderVersion: IRangePlaceholderVersion;
    defaultTwoRangePlaceholderVersion: ITwoRangePlaceholderVersion;
    qualityConfigs: IPlaceholderQualityConfig[];
    twoRangePlaceholderIsSideSpecific: boolean;
    isInteger: boolean;
}

export interface IPlaceholderQualityConfig {
    id?: number;
    qualityId: number;
    textInputPlaceholderVersion: ITextInputPlaceholderVersion;
    rangePlaceholderVersion: IRangePlaceholderVersion;
    twoRangePlaceholderVersion: ITwoRangePlaceholderVersion; 
}

export interface ITextInputPlaceholderVersion {
    id?: number;
    textInputMaxLength: number;
}

export interface IRangePlaceholderVersion {
    id?: number;
    minimalValue: number;
    maximalValue: number;
}

export interface ITwoRangePlaceholderVersion {
    id?: number;
    firstMinimalValue: number;
    firstMaximalValue: number;
    secondMinimalValue: number;
    secondMaximalValue: number;
}

export interface IAdditionExclude {
    additionId: number,
    option: string;
}

export interface IAdditionGusset {
    priority: number;
    additionGussetArticleTypes: IAdditionGussetArticleType[];
    additionGussetQualities: IAdditionGussetQuality[];
    additionGussetMassPoints: IAdditionGussetMassPoint[];
}

export interface IAdditionGussetArticleType {
    articleTypeId: number;
}

export interface IAdditionGussetQuality {
    qualityId: number;
}

export interface IAdditionGussetMassPoint {
    bodyAreaId: number;
    maximalValue: number;
    minimalValue: number;
    name: string;
    type: MasspointTypeEnum;
}

export interface IValidityScopeResponse {
    mainProductLineId: number;
    bodyAreaId: number;
    articleTypeValidityType: LockTypeEnum,
    qualityValidityType: LockTypeEnum,
    articleTypeIds: number[];
    qualityIds: number[];
}

export interface IValidityScopeRequest extends IValidityScopeResponse{}

export interface IAdditionAttributeCategory {
    id?: number;
    type: AdditionCategoryType;
    availableAdditionAttributeCategory: IAvailableAdditionAttributeCategory;
    additionAttributeVersions: IAdditionAttribute[];
}

export enum AdditionCategoryType {
    Undefined,
    Whitelist,
    Blacklist
}

export interface IAvailableAdditionAttributeCategory {
    id?: number;
    name: string;
    erpId: string;
}

export interface IAdditionAttribute {
    id?: number;
    additionAttributeCategoryVersionId?: number;
    availableAdditionAttribute: IAvailableAdditionAttribute;
}

export interface IAvailableAdditionAttribute {
    id?: number;
    code: string;
    name: string;
}