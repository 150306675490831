import { BoxProps, Box } from "@mui/material";
import colors from "shared/style/colors";
import styled from "@emotion/styled";

export const BoxItem = (props: BoxProps) => {
    const { sx, ...other } = props;
    return (
        <Box
            sx={{
                boxShadow: 'none',
                background: '#ffffff',
                border: '2px solid #6a7c8d',
                padding: '20px',
                '&:before': {
                    display: 'none',
                },
                ...sx,
            }}
            {...other}
        />
    );
}

export const BoxItemWithShadow = (props: BoxProps) => {
    const { sx, ...other } = props;
    return (
        <Box
            sx={{
                boxShadow: '2px 2px 2px 1px #6a7c8d',
                background: '#ffffff',
                border: '1px solid #6a7c8d',
                padding: '20px',
                '&:before': {
                    display: 'none',
                },
                ...sx,
            }}
            {...other}
        />
    );
}

export const MarginBottom = styled('div')({
    'margin-bottom': '30px'
});

export interface SerialSizeTableHeadlineProps {
    width?: string;
    flex?: number
}

export const SerialSizeTableHeadline = styled.div<SerialSizeTableHeadlineProps>
    `
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: ${props => props.width};
    flex: ${props => props.flex};
    word-wrap: normal;
    padding-right: 10px;
    font-weight: bold;
`

export const SerialSizeTableHeadlineContainer = styled.div
    `
    width: calc(100% - 32px);
    min-height: 50px;
    align-items: center;
    display: flex;
`

export interface SerialSizeTableEntryItemProps {
    width?: string;
    flex?: number;
    backgroundColor?: string;
}

export const SerialSizeTableEntryItem = styled.div<SerialSizeTableEntryItemProps>
    `
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    min-height: 60px;
    background-color: ${props => props.backgroundColor};
    width: ${props => props.width};
    flex: ${props => props.flex};
    word-wrap: normal;
    padding-right: 10px;
    display: table-cell;
    vertical-align: middle;
`

export const SerialSizeTableEntryContainer = styled.div
    `
    width: calc(100% - 32px);
    padding-top: 10px;
    min-height: 60px;
    align-items: center;
    display: table; 
    border: 1px solid #6a7c8d;
    margin-bottom: 10px;
`

export const SizeTableEntryContainer = styled.div
    `
    width: calc(100% - 32px);
    padding-top: 10px;
    min-height: 60px;
    align-items: center;
    display: table; 
`

export const SerialSizeMasspointConfigEntryContainer = styled.div
    `
    width: 100%;
    min-height: 50px;
    align-items: center;
    display: flex;
`
export interface InputProps {
    isHighlighted: boolean;
}

const determineBorderColor = (props: InputProps) => {
    if (props.isHighlighted) {
        return props.isHighlighted ? colors.mediMagenta : colors.mediBlue
    }
}

const determineBorder = (props: InputProps, borderStyle: string) => {
    return `${determineBorderThickness(props)} ${borderStyle} ${determineBorderColor(props)}`;
}

const determineBorderThickness = (props: InputProps) => {
    if (props.isHighlighted) {
        return "2px";
    }

    return "2px";
}

export const Input = styled.input<InputProps>`
    border: ${props => determineBorder(props, "solid")};

    width:70px;
    height: 30px;
    z-index: 99;
    text-align: center;
    
    :focus {
        border: ${props => determineBorder(props, "dotted")};
        outline: none;
    }
`