import styled from "@emotion/styled";
import colors from "shared/style/colors";

export const AdditionLoadWrapper = styled.div`
    background: white;
    padding: 10px;
    margin-top: 25px;
`;

export const TextHighlighted = styled.span`
    color: ${colors.mediMagenta};
    font-weight: bold;
`;