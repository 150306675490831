import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from "./config.service";
import HttpServiceCommonResult from "shared/networking/http_service_common_result";
import { AdditionValidationStateResponse, MassMaskValidationStateResponse, ProductInstructionValidationStateResponse } from "models/simulator/product_configuration_validate_response";

class SimulatorCommonServiceAPI extends HttpServiceCommonResult {
    public async getMassMaskValidationState(productConfigurationId: string): Promise<ApiResult<MassMaskValidationStateResponse>> {
        return this.get<MassMaskValidationStateResponse>(`/api/productconfigurationstate/${productConfigurationId}/massmask`);
    }

    public async getAdditionsValidationState(productConfigurationId: string): Promise<ApiResult<AdditionValidationStateResponse>> {
        return this.get<AdditionValidationStateResponse>(`/api/productconfigurationstate/${productConfigurationId}/addition`);
    }

    public async getProductionInstructionsValidationState(productConfigurationId: string): Promise<ApiResult<ProductInstructionValidationStateResponse>> {
        return this.get<ProductInstructionValidationStateResponse>(`/api/productconfigurationstate/${productConfigurationId}/productioninstruction`);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const SimulatorCommonService = new SimulatorCommonServiceAPI(TimeoutMilliseconds);

export default SimulatorCommonService;
