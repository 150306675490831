import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "app/redux_store";
import { handleResponseErrors } from "shared/networking/error_handling";
import CharacteristicCategoryServiceAPI from '../../../../services/characteristic_category.service';
import CharacteristicTypeServiceAPI from '../../../../services/characteristic_type.service';
import { createUpdateRequest } from "./characteristic_category_edit.reducer";

export const updateCharacteristicCategory = createAsyncThunk(
    'characteristic_category/characteristic_category_edit/saveCharacteristicCategory',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.characteristic_categories_edit.data;
        const response = await CharacteristicCategoryServiceAPI.updateCharacteristicCategory(createUpdateRequest(data.characteristicCategory));
        handleResponseErrors(response, "Ausprägungskategorie");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'characteristic_category/characteristic_category_edit/cancelSave',
    async (_) => { }
)

export const getCharacteristicCategory = createAsyncThunk(
    'characteristic_category/characteristic_category_edit/getCharacteristicCategory',
    async (id: string) => {
        const response = await CharacteristicCategoryServiceAPI.getCharacteristicCategory(id);
        handleResponseErrors(response, "Ausprägungskategorie");
        return response;
    }
)

export const getCharacteristicTypes = createAsyncThunk(
    'characteristic_category/characteristic_category_edit/getCharacteristicType',
    async (_) => {
        const response = await CharacteristicTypeServiceAPI.getCharacteristicTypes();
        handleResponseErrors(response, "Ausprägungstypen");
        return response;
     }
)

export const initData = createAsyncThunk(
    'characteristic_category/characteristic_category_edit/initData',
    async (id: string, { dispatch }) => {
        await Promise.all([ 
            dispatch(getCharacteristicTypes())
        ]);
        await dispatch(getCharacteristicCategory(id));
    }
)