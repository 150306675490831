import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdditionCategoryState } from './addition_category.model';
import { updateCanSaveAdditionCategory } from './addition_category.reducer';
import { cancelSave, saveAdditionCategory } from './addition_category.thunks';

const initialState: AdditionCategoryState = {
    actualData: {
        categorykey: "", 
        categoryvalue: "",    
        },
    command: {
        saveAdditionCategory: { status: "idle", canExecute: false },
        cancel: { status: "idle", canExecute: false }, 
    }
}

export const additionCategorySlice = createSlice({
    name: 'additioncategory',
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.command = initialState.command;
        },
        saveAdditionCategoryErrorCompleted: (state) => {
            state.command.saveAdditionCategory.status = "idle";
        },
        saveAdditionCategoryCompleted: (state) => {
            state.command.saveAdditionCategory.status = "idle";
        },
        updateCategoryKey: (state, action: PayloadAction<string>) => {
            state.actualData.categorykey = action.payload;
            updateCanSaveAdditionCategory(state);
        },
        updateCategoryText: (state, action: PayloadAction<string>) => {
            state.actualData.categoryvalue = action.payload;
            updateCanSaveAdditionCategory(state);
        }, 
    }, extraReducers: (builder) => {
        // saveAdditionCategory
        builder.addCase(saveAdditionCategory.pending, (state, action) => {
            state.command.saveAdditionCategory.status = 'pending'
            state.command.saveAdditionCategory.canExecute = false;
        }).addCase(saveAdditionCategory.rejected, (state, action) => {
            state.command.saveAdditionCategory.status = "error"
            state.command.saveAdditionCategory.canExecute = true;
            state.command.saveAdditionCategory.message = action.error.message;
        }).addCase(saveAdditionCategory.fulfilled, (state, action) => {
            state.command.saveAdditionCategory.status = "success"
            state.command.saveAdditionCategory.canExecute = true;
        
            // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancel.status = 'pending'
            state.command.cancel.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancel.status = "success"
            state.command.cancel.canExecute = false;
        })
    }
})

export const {
    resetState,
    saveAdditionCategoryErrorCompleted,
    saveAdditionCategoryCompleted,
    updateCategoryKey,
    updateCategoryText,
} = additionCategorySlice.actions

export default additionCategorySlice.reducer