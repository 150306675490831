import { createSlice } from '@reduxjs/toolkit';
import { RulesInfoState } from './rules_info.model';
import { fetchCheck } from './rules_info.thunks';

const initialState: RulesInfoState = {
    loadedData: {},
    actualData: {},
    query: { fetchCheck: { status: "idle", canExecute: true } }
}

export const rulesInfoSlice = createSlice({
    name: 'rules_info',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //fetchCheck
        builder.addCase(fetchCheck.pending, (state, action) => {
            state.query.fetchCheck.status = "pending"
            state.query.fetchCheck.canExecute = false;
        }).addCase(fetchCheck.rejected, (state, action) => {
            state.query.fetchCheck.status = "error"
            state.query.fetchCheck.canExecute = true;
            state.query.fetchCheck.message = action.error.message;
        }).addCase(fetchCheck.fulfilled, (state, action) => {
            state.query.fetchCheck.status = "success"
            state.query.fetchCheck.message = undefined;
            state.query.fetchCheck.canExecute = true;
            state.loadedData.check = action.payload.getData();
            const loadedCheck = state.loadedData.check;

            state.actualData.info = {
                articleType: loadedCheck.articleType,
                checkId: loadedCheck.id,
                bodyArea: loadedCheck.bodyArea,
                checkTypeName: loadedCheck.plausibilityCheckTypeVersion.name,
                mainProductLine: loadedCheck.mainProductLine,
                quality: loadedCheck.quality,
                isSideSpecific: loadedCheck.isSideSpecific
            };
        })
    }
})

export default rulesInfoSlice.reducer