import { createAsyncThunk } from '@reduxjs/toolkit';
import MassMaskHistoryServiceAPI from '../../../services/massmask_history.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';

export const fetchMassmaskHistoryOverviews = createAsyncThunk(
    'massmask_history/massmask_history_overview/fetchMassmaskHistoryOverviews',
    async (thunkApi) => {
        const response = await MassMaskHistoryServiceAPI.getMassmasksHistoryOverview();
        handleResponseErrors(response, "Maßmasken Historie");
        return response;
    }
)