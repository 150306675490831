import React from "react";
import { NavLink } from "react-router-dom";
import { Container } from "reactstrap";
import colors from "shared/style/colors";
import styled from "@emotion/styled";
import MenuRoutes from "../../routing/menu_routes";
import theme from "../../style/theme";

const Box = styled.div`
  display: flex;
  align-items: center;
`;

const MenuArea = styled.div`
  border-top: 1px solid ${theme.colors.inactive};
  border-bottom: 2px solid ${theme.colors.inactive};
  flex-shrink: 0;
`;

const LinkWrapper = styled.div`
  text-decoration: none;
  margin-right: 20px;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid white;
  color: ${theme.colors.text};
  font-weight: bold;

  :hover :focus :active {
    color: ${theme.colors.active};
    border-bottom: 1px solid ${theme.colors.active};
    cursor: pointer;
    text-decoration: none;
  }
  a {
    text-decoration: none;
    padding-bottom: 12px;
  }
`;

export const MenuComponent = () => {
  return (
    <MenuArea>
      <Container>
        <Box>
          {MenuRoutes.map((entry, index) => (
            <LinkWrapper key={index}>
              <NavLink key={`${index}_${entry.name}`} to={entry.target} style={({ isActive }) => isActive
                ? { color: theme.colors.active, borderBottom: `2px solid ${theme.colors.active}`, paddingBottom: "14px" }
                : { color: theme.colors.text, borderBottom: `2px solid ${colors.mediLightGray}`, paddingBottom: "14px" }
              }>
                {entry.name}
              </NavLink >
            </LinkWrapper>
          ))}
        </Box>
      </Container>
    </MenuArea>
  );
};
