import React from "react";
import { BreadcrumbsComponent } from '../shared/breadcrumbs/components/breadcrumbs';
import { BreadcrumbRoute } from "../shared/breadcrumbs/models/breadcrumb_route";

export const AdminBreadcrumbs = () => {
    return <BreadcrumbsComponent breadcrumbs={routes} />
}

const routes: BreadcrumbRoute[] = [
    {
        route: "/admin",
        parts: [{ title: "Administration" }]
    },
    {
        route: "/admin/massimages",
        parts: [
            { title: "Administration", url: "/admin" },
            { title: "Maßbilder" }
        ]
    },
    {
        route: "/admin/massimages/:massImageId/update",
        parts: [
            { title: "Administration", url: "/admin" },
            { title: "Maßbilder", url: "/admin/massimages" },
            { title: "Maßbild ersetzen" }
        ]
    },
    {
        route: "/admin/massimages/:massImageId/config/create",
        parts: [
            { title: "Administration", url: "/admin" },
            { title: "Maßbilder", url: "/admin/massimages" },
            { title: "Konfig hochladen" }
        ]
    },
    {
        route: "/admin/massimages/:massImageId/config/edit",
        parts: [
            { title: "Administration", url: "/admin" },
            { title: "Maßbilder", url: "/admin/massimages" },
            { title: "Konfig hochladen" }
        ]
    },
    {
        route: "/admin/basedataimports",
        parts: [
            { title: "Administration", url: "/admin" },
            { title: "eHub Import" }
        ]
    },
    {
        route: "/admin/positionmapping",
        parts: [
            { title: "Administration", url: "/admin" },
            { title: "Positionsmapping" }
        ]
    }]