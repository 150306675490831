import { DropdownComponent } from "shared/shared";
import { Grid } from "@mui/material";
import { ListButtonComponent } from "shared/components/buttons/list_button.component";
import AddIcon from '@mui/icons-material/Add';
import IAvailableQuality from "models/available_basedata/available_quality";
import { PolicyDefaultAdditionAttributeConfigurationComponent } from "./policy_default_addition_by_qu_and_att_configuration";
import { IAdditionSelection, IAttributeCategorySelection, IAttributeSelection, IDefaultAdditionByQualityAndAttributeConfigurationViewModel, ISearchAddition } from "./policy_default_addition_by_qu_and_att.model";

interface PolicyDefaultAdditionByQualityAndAttributeEditor {
    allQualities: IAvailableQuality[],
    selectedQuality: IAvailableQuality,
    attributeConfigurations: IDefaultAdditionByQualityAndAttributeConfigurationViewModel[],
    addConfiguration: () => {};
    deleteConfiguration: (value: number) => {};
    selectAttributeCategory: (category: IAttributeCategorySelection) => {};
    selectAttribute: (attribute: IAttributeSelection) => {};
    selectQuality: (quality: IAvailableQuality) => {};
    filterSelectedAdditions: (additions: ISearchAddition) => {};
    filterUnselectedAdditions: (additions: ISearchAddition) => {};
    selectAdditions: (selectedAdditions: IAdditionSelection) => {};
    unSelectAdditions: (selectedAdditions: IAdditionSelection) => {};
}

export const PolicyDefaultAdditionByQualityAndAttributeEditorComponent: React.FC<PolicyDefaultAdditionByQualityAndAttributeEditor> = ({
    allQualities,
    selectedQuality,
    attributeConfigurations,
    addConfiguration,
    deleteConfiguration,
    selectAttributeCategory,
    selectAttribute,
    selectQuality,
    filterSelectedAdditions,
    filterUnselectedAdditions,
    selectAdditions,
    unSelectAdditions,
}) => {

    const isDisabled = () => {
        return !(attributeConfigurations.length === 0
            || attributeConfigurations.filter(x => x.selectedAttributeValue == null).length === 0);
    }

    return (
        <Grid item container md={12}
            direction="column"
            spacing={3}>
            <Grid container item md={12} spacing={3}
                direction="column">
                <Grid item>
                    <DropdownComponent name="Qualität"
                        data={allQualities}
                        minWidth="350px"
                        isRequired={true}
                        onSelect={(e) => selectQuality(e)}
                        displayedProperties={["erpId", "name"]}
                        selectedValue={selectedQuality}
                    />
                </Grid>
            </Grid>
            {attributeConfigurations ?
                attributeConfigurations.map((con) => {
                    return (
                        <Grid item key={con.index}>
                            <PolicyDefaultAdditionAttributeConfigurationComponent
                                attributeConfiguration={con}
                                deleteConfiguration={deleteConfiguration}
                                selectAttributeCategory={selectAttributeCategory}
                                selectAttribute={selectAttribute}
                                filterSelectedAdditions={filterSelectedAdditions}
                                filterUnselectedAdditions={filterUnselectedAdditions}
                                selectAdditions={selectAdditions}
                                unSelectAdditions={unSelectAdditions} />
                        </Grid>
                    );
                }) : <></>
            }
            <Grid item>
                <ListButtonComponent
                    onClick={addConfiguration} disabled={isDisabled()}>
                    <AddIcon /> Konfiguration hinzufügen
                </ListButtonComponent>
            </Grid>
        </Grid>
    );
};