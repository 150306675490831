import React from "react";
import { Grid } from '@mui/material';
import { EditorDescriptionComponent } from 'shared/components/editors/editor_description/editor_description.style';
import { DecimalInputComponent } from 'shared/components/input/numberInput/decimal_input.component';
import { doesExist } from 'services/validation.service';
import { IAdditionEditor, IAdditionEditorAction } from "models/additions/addition_editor.model";

interface PlaceHolderTwoRangeComponentProps {
    additionEditor: IAdditionEditor,
    additionEditorActions: IAdditionEditorAction
    placeholderQualityConfigIndex?: number
}

export const PlaceHolderTwoRangeComponent: React.FC<PlaceHolderTwoRangeComponentProps> = (
    {
        additionEditor,
        additionEditorActions,
        placeholderQualityConfigIndex
    }) => {

    let firstMinimalValue = null;
    let firstMaximalValue = null;
    let secondMinimalValue = null;
    let secondMaximalValue = null;
        
    if(doesExist(placeholderQualityConfigIndex)){
        firstMinimalValue = additionEditor.addition.placeholder.qualityConfigs[placeholderQualityConfigIndex].config.twoRangePlaceholderVersion.firstMinimalValue;
        firstMaximalValue = additionEditor.addition.placeholder.qualityConfigs[placeholderQualityConfigIndex].config.twoRangePlaceholderVersion.firstMaximalValue;
        secondMinimalValue = additionEditor.addition.placeholder.qualityConfigs[placeholderQualityConfigIndex].config.twoRangePlaceholderVersion.secondMinimalValue;
        secondMaximalValue = additionEditor.addition.placeholder.qualityConfigs[placeholderQualityConfigIndex].config.twoRangePlaceholderVersion.secondMaximalValue;
    }
    else{
        firstMinimalValue = additionEditor.addition.placeholder.defaultConfig.twoRangePlaceholderVersion.firstMinimalValue;
        firstMaximalValue = additionEditor.addition.placeholder.defaultConfig.twoRangePlaceholderVersion.firstMaximalValue;
        secondMinimalValue = additionEditor.addition.placeholder.defaultConfig.twoRangePlaceholderVersion.secondMinimalValue;
        secondMaximalValue = additionEditor.addition.placeholder.defaultConfig.twoRangePlaceholderVersion.secondMaximalValue;
    }

    const updateTwoRangeFirstMinimalValue = additionEditorActions.updateTwoRangeFirstMinimalValue;
    const updateTwoRangeFirstMaximalValue = additionEditorActions.updateTwoRangeFirstMaximalValue;
    const updateTwoRangeSecondMinimalValue = additionEditorActions.updateTwoRangeSecondMinimalValue;
    const updateTwoRangeSecondMaximalValue = additionEditorActions.updateTwoRangeSecondMaximalValue;

    const isInteger = additionEditor.addition.placeholder.isInteger;

    return (
        <Grid direction="row"
              container
              justifyContent="flex-start"
              alignContent="flex-end"
              alignItems="flex-end"
              rowSpacing={1}>
            <Grid item md={3}>
                <EditorDescriptionComponent>von Mindestwert</EditorDescriptionComponent>
            </Grid>
            <Grid item md={3}>
                <DecimalInputComponent
                    name={"von"}
                    fixedPositions={isInteger ? 0 : 1 }
                    placeholder={isInteger ? '1' : '0.1'}
                    minValue={isInteger ? 1 : 0.1}
                    maxValue={isInteger ? 999 : 999.9}
                    isRequired={true}
                    onChange={(value) => updateTwoRangeFirstMinimalValue({ index: placeholderQualityConfigIndex, value: value})}
                    value={firstMinimalValue}
                />
            </Grid>
            <Grid item md={3}>
                <EditorDescriptionComponent>bis Maximalwert</EditorDescriptionComponent>
            </Grid>
            <Grid item md={3}>
                <DecimalInputComponent
                    name={"bis"}
                    fixedPositions={isInteger ? 0 : 1 }
                    placeholder={isInteger ? '999' : '999.9'}
                    minValue={isInteger ? 1 : 0.1}
                    maxValue={isInteger ? 999 : 999.9}
                    isRequired={true}
                    onChange={(value) => updateTwoRangeFirstMaximalValue({ index: placeholderQualityConfigIndex, value: value})}
                    value={firstMaximalValue}
                />
            </Grid>
            <Grid item md={3}>
                <EditorDescriptionComponent>von Mindestwert</EditorDescriptionComponent>
            </Grid>
            <Grid item md={3}>
                <DecimalInputComponent
                    name={"von"}
                    fixedPositions={isInteger ? 0 : 1 }
                    placeholder={isInteger ? '1' : '0.1'}
                    minValue={isInteger ? 1 : 0.1}
                    maxValue={isInteger ? 999 : 999.9}
                    isRequired={true}
                    onChange={(value) => updateTwoRangeSecondMinimalValue({ index: placeholderQualityConfigIndex, value: value})}
                    value={secondMinimalValue}
                />
            </Grid>
            <Grid item md={3}>
                <EditorDescriptionComponent>bis Maximalwert</EditorDescriptionComponent>
            </Grid>
            <Grid item md={3}>
                <DecimalInputComponent
                    name={"bis"}
                    fixedPositions={isInteger ? 0 : 1 }
                    placeholder={isInteger ? '999' : '999.9'}
                    minValue={isInteger ? 1 : 0.1}
                    maxValue={isInteger ? 999 : 999.9}
                    isRequired={true}
                    onChange={(value) => updateTwoRangeSecondMaximalValue({ index: placeholderQualityConfigIndex, value: value})}
                    value={secondMaximalValue}
                />
            </Grid>
        </Grid>
    );
};