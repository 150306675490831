import { createAsyncThunk } from '@reduxjs/toolkit';
import CheckTypeService from 'services/checktype.service';
import CheckTypeMessageService from 'services/checktype_message.service';
import { RootState } from '../../../app/redux_store';
import { handleResponseErrors } from '../../../shared/networking/error_handling';

export const fetchPlausibilityCheckType = createAsyncThunk(
    'plausibilitychecktype/type_edit/fetchPlausibilityCheckType',
    async (id: number) => {
        const response = await CheckTypeService.getCheckType(id);
        handleResponseErrors(response, "Prüfungstyp");
        return response;
    }
)

export const editPlausibilityCheckType = createAsyncThunk(
    'plausibilitychecktype/type_edit/editPlausibilityCheckType',
    async (_, { getState }) => {
        const state = getState() as RootState;   const data = state.plausibilitychecktype_edit.editedData.plausibilityCheckType;
        const response = await CheckTypeService.updateCheckType({
            plausibilityCheckTypeId: data.id,
            name: data.name,
            warningMessageKey: data.warningMessageKey,
            errorMessageKey: data.errorMessageKey,
            isBestMatchEnabled: data.isBestMatchEnabled,
        });
        handleResponseErrors(response, "Prüfungstyp");
        return response;
    }
)

export const getCheckTypeMessages = createAsyncThunk(
    'plausibilitychecktype/getCheckTypeMessages',
    async (_) => {
        const response = await CheckTypeMessageService.getMessages();
        handleResponseErrors(response, "Fehler/Warntexte");
        return response;
    }
)

export const initData = createAsyncThunk(
    'plausibilitychecktype/type_edit/initData',
    async (checkId: number, thunkApi) => {
        await Promise.all([
            thunkApi.dispatch(fetchPlausibilityCheckType(checkId)),
            thunkApi.dispatch(getCheckTypeMessages()),
        ]);
    }
)