import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PolicyFingersAndToesState } from "./policy_fingers_and_toes.model";
import { sortAdditionCategories, sortMassPointGroups } from "check/policy/helper/policy.helper";
import { updateCanSave } from "./policy_fingers_and_toes.reducer";
import { IAdditionCategoryOverview } from "models/addition_categories/addition_category_overview";
import { cancelSave, getAdditionCategories, getMassPointGroups, getPolicy, initData, savePolicy, updatePolicy } from "./policy_fingers_and_toes.thunks";
import { createSelectionList, filterSelectionList, getEmptySelectionList, moveFromSelectedToUnselected, moveFromUnselectedToSelected } from "shared/components/selectionComponent/selectionList.helper";
import { getIMasspointGroupOverviewDisplayName } from "shared/helpers/displayNames";

const initialState: PolicyFingersAndToesState = {
    data: { 
        policy: { name: "", ignoreOnQuotation: false},
        selectedAdditionCategory: null,
        massPointGroupSelection: getEmptySelectionList()
    },
    loadedData: {
        additionCategories: [],
        massPointGroups: [],
        policy: null,
        isEdit: false,
    },
    command:{
        savePolicy: { status: "idle", canExecute: false },
        cancelSave: { status: "idle", canExecute: true }
    },
    query: {
        getAdditionCategories: { status: "idle", canExecute: true },
        getMassPointGroups: { status: "idle", canExecute: true },
        getPolicy: {status: "idle", canExecute: true },
        initData: {status: "idle", canExecute: true}
    }
}

export const policyFingersAndToes = createSlice({
    name: 'policy_fingers_and_toes',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = initialState.data;
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        changeName: (state, action: PayloadAction<string>) => {
            state.data.policy.name = action.payload;
            updateCanSave(state);
        },
        selectAdditionCategory: (state, action: PayloadAction<IAdditionCategoryOverview>) => {
            state.data.selectedAdditionCategory = action.payload;
            updateCanSave(state);
        },
        toggleIgnoreOnQuotation: (state, action: PayloadAction<boolean>) => {
            state.data.policy.ignoreOnQuotation = action.payload;
            updateCanSave(state);
        },               
        completedSave: (state) => {
            state.command.savePolicy = initialState.command.savePolicy;
        },
        filterSelectedMassPointGroups: (state, action: PayloadAction<string>) => {
            state.data.massPointGroupSelection.selectedList.searchText = action.payload;
            filterSelectionList(state.data.massPointGroupSelection.selectedList);
        },
        filterUnselectedMassPointGroups: (state, action: PayloadAction<string>) => {
            state.data.massPointGroupSelection.unSelectedList.searchText = action.payload;
            filterSelectionList(state.data.massPointGroupSelection.unSelectedList);
        },
        selectMassPointGroups: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.data.massPointGroupSelection, action.payload);
            updateCanSave(state);
        },
        unSelectMassPointGroups: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.data.massPointGroupSelection, action.payload);
            updateCanSave(state);
        },
    }, extraReducers: (builder) => {

        // getAdditionCategories
        builder.addCase(getAdditionCategories.pending, (state) => {
            state.query.getAdditionCategories.status = "pending"
            state.query.getAdditionCategories.canExecute = false;
        }).addCase(getAdditionCategories.rejected, (state, action) => {
            state.query.getAdditionCategories.status = "error"
            state.query.getAdditionCategories.message = action.error.message;
            state.query.getAdditionCategories.canExecute = true;
        }).addCase(getAdditionCategories.fulfilled, (state, action) => {
            state.query.getAdditionCategories.status = "success"
            state.query.getAdditionCategories.canExecute = true;
            state.loadedData.additionCategories = action.payload.getData().sort(sortAdditionCategories);
            updateCanSave(state);   
        
        // getMassPointGroups
        }).addCase(getMassPointGroups.pending, (state) => {
            state.query.getMassPointGroups.status = "pending"
            state.query.getMassPointGroups.canExecute = false;
        }).addCase(getMassPointGroups.rejected, (state, action) => {
            state.query.getMassPointGroups.status = "error"
            state.query.getMassPointGroups.message = action.error.message;
            state.query.getMassPointGroups.canExecute = true;
        }).addCase(getMassPointGroups.fulfilled, (state, action) => {
            state.query.getMassPointGroups.status = "success"
            state.query.getMassPointGroups.canExecute = true;
            state.loadedData.massPointGroups = action.payload.getData().sort(sortMassPointGroups);
            updateCanSave(state);
   
        // getPolicy
        }).addCase(getPolicy.pending, (state) => {
            state.query.getPolicy.status = 'pending'
            state.query.getPolicy.canExecute = false;
        }).addCase(getPolicy.rejected, (state, action) => {
            state.query.getPolicy.status = "error"
            state.query.getPolicy.canExecute = true;
            state.query.getPolicy.message = action.error.message;
        }).addCase(getPolicy.fulfilled, (state, action) => {
            state.query.getPolicy.status = "success"
            state.query.getPolicy.canExecute = false;
            state.loadedData.policy = action.payload.getData();

        // savePolicy
        }).addCase(savePolicy.pending, (state) => {
            state.command.savePolicy.status = 'pending'
            state.command.savePolicy.canExecute = false;
        }).addCase(savePolicy.rejected, (state, action) => {
            state.command.savePolicy.status = "error"
            state.command.savePolicy.canExecute = true;
            state.command.savePolicy.message = action.error.message;
        }).addCase(savePolicy.fulfilled, (state) => {
            state.command.savePolicy.status = "success"
            state.command.savePolicy.canExecute = false;

        // updatePolicy
        }).addCase(updatePolicy.pending, (state) => {
            state.command.savePolicy.status = 'pending'
            state.command.savePolicy.canExecute = false;
        }).addCase(updatePolicy.rejected, (state, action) => {
            state.command.savePolicy.status = "error"
            state.command.savePolicy.canExecute = true;
            state.command.savePolicy.message = action.error.message;
        }).addCase(updatePolicy.fulfilled, (state) => {
            state.command.savePolicy.status = "success"
            state.command.savePolicy.canExecute = false;

        // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancelSave.status = 'pending'
            state.command.cancelSave.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancelSave.status = "success"
            state.command.cancelSave.canExecute = false;

        // initData
        }).addCase(initData.pending, (state) => {
            state.query.initData.status = "pending"
            state.query.initData.canExecute = false;
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.canExecute = true;
            state.query.initData.message = action.error.message;
        }).addCase(initData.fulfilled, (state) => {
            state.query.initData.status = "success"
            state.query.initData.canExecute = true;
            if(state.loadedData.policy) {
                state.data.policy = { 
                    id: state.loadedData.policy.id,
                    name: state.loadedData.policy.name,
                    ignoreOnQuotation: state.loadedData.policy.ignoreOnQuotation
                };
                state.loadedData.isEdit = true;
                const selectedIds = state.loadedData.policy.massPointGroupIds;
                state.data.massPointGroupSelection = createSelectionList(state.loadedData.massPointGroups, selectedIds, getIMasspointGroupOverviewDisplayName)
                state.data.selectedAdditionCategory = state.loadedData.additionCategories.find(x => x.id === state.loadedData.policy.additionCategoryId);
            } else {
                state.loadedData.isEdit = false;
                state.data.massPointGroupSelection = createSelectionList(state.loadedData.massPointGroups, [], getIMasspointGroupOverviewDisplayName)
            } 
            updateCanSave(state);
        })
    }
})

export const {
    resetState,
    changeName,
    selectAdditionCategory,
    toggleIgnoreOnQuotation,
    completedSave,
    unSelectMassPointGroups,
    selectMassPointGroups,
    filterUnselectedMassPointGroups,
    filterSelectedMassPointGroups
} = policyFingersAndToes.actions

export default policyFingersAndToes.reducer