import { BodySideEnum } from "@medi-mtm/components";
import { GridFilterItem, GridColDef, getGridBooleanOperators, GridSortModel } from "@mui/x-data-grid-pro";
import { MasspointBodySideEnumValues } from "models/masspoints/enums/body_side.enum";
import { MasspointTypeEnum, MasspointTypeEnumValues } from "models/masspoints/enums/masspoint_type.enum";
import IMasspointOverview from "models/masspoints/masspoint_overview";
import { CustomBooleanCell } from "shared/components/datagrid/custom_boolean_cell.component";
import { CustomFilterOperators } from "shared/components/datagrid/custom_filter_operators";
import { CustomTooltipHeader } from "shared/components/datagrid/custom_tooltip_header.component";
import { DatagridMenu } from "shared/components/datagrid/datagrid_menu.component";


const buildBodySideDisplayText = ( bodySide: BodySideEnum ) => {
    return MasspointBodySideEnumValues.find(x => x.value === bodySide)?.text;
}

const buildTypeDisplayText = ( type: MasspointTypeEnum ) => {
    return MasspointTypeEnumValues.find(x => x.value === type)?.text;
}

export const filterMasspointValue = (filterItem: GridFilterItem, filteredItems: IMasspointOverview[]) => {
    if(filterItem.value)
    {
        switch(filterItem.field)
        {
            case 'name':
                filteredItems = filteredItems.filter(x => x.name.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'type':
                filteredItems = filteredItems.filter(x => buildTypeDisplayText(x.massPointType).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;   
            case 'bodyArea':
                filteredItems = filteredItems.filter(x => x.bodyArea.name.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;           
            case 'bodySide':
                filteredItems = filteredItems.filter(x => buildBodySideDisplayText(x.bodySide).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;          
            case 'isPantyTopWithStandardDimensions':
                filteredItems = filteredItems.filter(x => filterItem.value === 'any' ? true: x.isPantyTopWithStandardDimensions.toString() === filterItem.value);
                break;  
            case 'isEllipticalShapeKneeFunctionZone':
                filteredItems = filteredItems.filter(x => filterItem.value === 'any' ? true: x.isEllipticalShapeKneeFunctionZone.toString() === filterItem.value);
                break;                                                                                                      
        }
    }
    return filteredItems;
}

export const getGridColumns = (
    editMasspoint: (masspoint: IMasspointOverview) => void,
    editReferences: (masspoint: IMasspointOverview) => void,
    deleteMasspoint: (masspoint: IMasspointOverview) => void,
    ): GridColDef[] => [
    {
        field: "menu",
        headerName: " ",
        width: 15,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        renderCell: params => DatagridMenu({params: params, menuItemsData: 
            [
                {label: "Maßpunkt editieren", action: editMasspoint},
                {label: "medi 400 Referenzen verwalten", action: editReferences},
                {label: "Maßpunkt löschen", action: deleteMasspoint}
            ]})
    },
    {
        field: "name",
        headerName: "Name",
        disableColumnMenu: false,
        flex: 0.125,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    },  
    {
        field: "type",
        headerName: "Typ",
        disableColumnMenu: false,
        flex: 0.125,
        sortable: false,
        hideable: false,
        pinnable: false,
        valueGetter: (value, row) => buildTypeDisplayText(row.massPointType),
        filterOperators: CustomFilterOperators(),
    },  
    {
        field: "bodyArea",
        headerName: "Körperregion",
        disableColumnMenu: false,
        flex: 0.125,
        sortable: false,
        hideable: false,
        pinnable: false,
        valueGetter: (value, row) => row.bodyArea.name,
        filterOperators: CustomFilterOperators(),
    },   
    {
        field: "bodySide",
        headerName: "Seite",
        disableColumnMenu: false,
        flex: 0.125,
        sortable: false,
        hideable: false,
        pinnable: false,
        valueGetter: (value, row) => buildBodySideDisplayText(row.bodySide),
        filterOperators: CustomFilterOperators(),
    },
    {
        field: "isPantyTopWithStandardDimensions",
        headerName: "Leibteil mit Standardmaßen",
        disableColumnMenu: false,
        flex: 0.125,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: getGridBooleanOperators(),
        renderHeader: () => CustomTooltipHeader({ shortText: "LmS", tooltip: "Leibteil mit Standardmaßen" }),
        renderCell: (params) => CustomBooleanCell({value: params.row.isPantyTopWithStandardDimensions})
    },  
    {
        field: "isEllipticalShapeKneeFunctionZone",
        headerName: "Ellipsenform/Kniefunktionszone",
        disableColumnMenu: false,
        flex: 0.125,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: getGridBooleanOperators(),
        renderHeader: () => CustomTooltipHeader({ shortText: "E/K", tooltip: "Ellipsenform/Kniefunktionszone" }),
        renderCell: (params) => CustomBooleanCell({value: params.row.isEllipticalShapeKneeFunctionZone})
    }
]    

export const sortByNameModel: GridSortModel = [
    {
        field: "name",
        sort: "asc",
    },
];