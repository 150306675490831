import styled from "@emotion/styled";
import { BoxProps, Box } from "@mui/material";
import colors from "shared/style/colors";

export const BoxItem = (props: BoxProps) => {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        boxShadow: 'none',
        background: '#ffffff',
        border: '2px solid #6a7c8d',
        '&:not(:last-child)': {
          borderBottom: 0,
        },
        '&:before': {
          display: 'none',
        },
        ...sx,
      }}
      {...other}
    />
  );
}

export const Spacer = styled.div`
    border-left: 1px solid ${colors.mediLightGray};
    border-right: 1px solid ${colors.mediLightGray};
    width: 5px;
    min-height: 10px;
    height: 300px;
    margin-left: 20px;
    margin-right: 20px;
`;

export const ListWrapper = styled.div`
    overflow-y: scroll;
    height: 200px;
`;

export const AttributeCategoryBoxHeader = styled.div`
  background-color: ${colors.mediLightGray};
  display: flex;
  align-items: flex-end;  
  justify-content: space-between;
  padding: 10px 15px;
`;

export const ToggleWrapper = styled.div`
    display:inline-block;
`;

export const ButtonSection = styled.div`
`;

export const ToggleInfo = styled.div`
  color: ${colors.mediGrey};
`;

export const AttributeCategoryBoxHeadline = styled.h3`

`;