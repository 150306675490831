import { ArticleTypeFilterList, QualityFilterList } from "models/additions/addition_editor.model";
import IAvailableArticleType from "models/available_basedata/available_article_type";
import IAvailableQuality from "models/available_basedata/available_quality";
import { getIAvailableArticleTypeDisplayName, getIAvailableQualityDisplayName } from "shared/helpers/displayNames";

export const filterQualities = (listToFilter: QualityFilterList) => {
    listToFilter.filteredItems = listToFilter.allItems
        .filter(quality => nameMatchesQualityText(quality, listToFilter.searchText))
        .sort(sortQualityCriteria);
}

export const filterArticleTypes = (listToFilter: ArticleTypeFilterList) => {
    listToFilter.filteredItems = listToFilter.allItems
        .filter(m => nameMatchesArticleTypeText(m, listToFilter.searchText))
        .sort(sortArticleTypeCriteria);
}

const nameMatchesArticleTypeText = (articleType: IAvailableArticleType, searchText: string): boolean => {
    if (searchText === "") {
        return true;
    }
    const searchTextLowerCase = searchText.toLowerCase();
    return (getIAvailableArticleTypeDisplayName(articleType).toLowerCase().includes(searchTextLowerCase));
}

const nameMatchesQualityText = (quality: IAvailableQuality, searchText: string): boolean => {
    if (searchText === "") {
        return true;
    }
    const searchTextLowerCase = searchText.toLowerCase();
    return (getIAvailableQualityDisplayName(quality).toLowerCase().includes(searchTextLowerCase));
}

const sortArticleTypeCriteria = (articleType1: IAvailableArticleType, articleType2: IAvailableArticleType) => {
    return getIAvailableArticleTypeDisplayName(articleType1).localeCompare(getIAvailableArticleTypeDisplayName(articleType2));
}

const sortQualityCriteria = (quality1: IAvailableQuality, quality2: IAvailableQuality) => {
    return getIAvailableQualityDisplayName(quality1).localeCompare(getIAvailableQualityDisplayName(quality2));
}