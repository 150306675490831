import CheckIcon from '@mui/icons-material/Check';
import colors from "shared/style/colors";
import styled from "@emotion/styled";

const ColorGreen = styled.span`
  color: ${colors.mediGreen};
`

export const PublishedIconComponent = () => {
  return <ColorGreen><CheckIcon /></ColorGreen>
}