import React from "react";
import * as style from "./tooltip.style";
import colors from "shared/style/colors";
import { Box, ClickAwayListener, Grid, Popper } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { BorderAll, BorderAllRounded } from "@mui/icons-material";


interface TooltipComponentProps {
    text: string;
}

export const TooltipComponent: React.FC<TooltipComponentProps> = ({ text }) => {

    const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);
    const showPopper = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };
    
    const renderTooltip = () => {
        return (
            <>
                <style.DescriptionComponent>
                    <style.InfoIconStyled onClick={e => handleClick(e)} />
                    {showPopper ? <style.DescriptionTooltip>
                        <Popper
                            style={{ zIndex: 9999 }}
                            open={showPopper}
                            anchorEl={anchorEl}
                            placement="top"
                        >
                            <ClickAwayListener onClickAway={handleClickAway}>
                                <style.BoxStyled>
                                    <Grid container>
                                        <Grid container item justifyContent="flex-end">
                                            <CloseIcon onClick={handleClickAway}></CloseIcon>
                                        </Grid>
                                        <Grid item>
                                            {text}
                                        </Grid>
                                    </Grid>
                                </style.BoxStyled>
                            </ClickAwayListener>
                        </Popper>
                    </style.DescriptionTooltip> : <></>}
                </style.DescriptionComponent>
            </>
        );
    };

    return (
        <>
            {renderTooltip()}
        </>
    );
};
