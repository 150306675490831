import React, { useEffect } from "react";
import { MassimageConfigUploadComponent } from "../components/massimage_config_upload.component";
import { useAppDispatch, useAppSelector } from '../../../../app/hook';
import { AppDispatch } from '../../../../app/redux_store';
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { createMassimageConfig } from './redux/create_config.thunks';
import { initCurrentMassImage, resetState, resetCreateConfigFile, updateConfigFile, createConfigFileErrorCompleted } from "./redux/create_config.slice";

export const CreateConfigComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.massimage_configuration_file_create);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { massImageId } = useParams();
    useEffect(() => {
        dispatch(initCurrentMassImage(Number(massImageId)));
        return () => {
            dispatch(resetState());
          }
    }, [dispatch, massImageId]);

    const handleSelectFile = (file: File) => {
        dispatch(updateConfigFile(file));
    }

    const handleUpload = () => {
        dispatch(createMassimageConfig());
    }

    if (state.command.createConfigFile.status === "success") {
        enqueueSnackbar("Konfigurationsdatei erfolgreich aktualisiert", { variant: "success" });
        dispatch(resetCreateConfigFile());
        navigate(`/admin/massimages`);
    }

    if (state.command.createConfigFile.status === "error") {
        enqueueSnackbar("Json Datei nicht valide", { variant: "warning" });
        dispatch(createConfigFileErrorCompleted());
    }

    return (
        <>
            <MassimageConfigUploadComponent
                onFileSelected={(e) => handleSelectFile(e.target.files[0])}
                isUploadAllowed={state.command.uploadConfigFile.canExecute}
                isUploading={false}
                onUpload={() => handleUpload()}
            />
        </>
    )
}