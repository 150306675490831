import { ArticleTypeFilterList, QualityFilterList, IArticleTypeSelectionViewModel } from "models/additions/addition_editor.model";
import IAvailableBodyArea from "models/available_basedata/available_body_area";
import { removeDuplicates } from "shared/helpers/removeDuplicates";
import { doesExist } from "services/validation.service";
import { filterArticleTypes, filterQualities } from "additions/common/helpers/filters";
import { IDefaultByAttributeConfigViewModel, IDefaultByAttributeConfigEditorViewModel, IDefaultByAttributeEditor, IDefaultByAttributeLoadedData, IDefaultByAttributeSubConfigViewModel as IDefaultByAttributeSubConfigViewModel } from "../models/policy_mandatory_by_attribute.models";
import { IAvailableMappedBaseDataByAddition } from "models/available_basedata/available_mapped_base_data";
import IAvailableQuality from "models/available_basedata/available_quality";
import IAvailableArticleType from "models/available_basedata/available_article_type";
import IMasspointOverview from "models/masspoints/masspoint_overview";
import { MassPointCriteriaSide, MassPointCriteriaSideRequest } from "models/masspoints/masspoint_criteria";
import { IMandatoryByAttributeSubConfigGet, IPolicyMandatoryByAttributeConfigGet, MassPointCriteriaSideGet } from "models/policies/policy_mandatory_by_attribute_get";
import { LockTypeEnum } from "shared/components/selectionComponent/models/locktype.enum";
import { PolicyMandatoryByAttributeState } from "../redux/policy_mandatory_by_attribute.model";

export const moveArticleTypeFromUnselectedToSelected = (editableSubConfig: IDefaultByAttributeSubConfigViewModel, mappedBaseData: IAvailableMappedBaseDataByAddition, selection: IAvailableArticleType[]) => {
    const selectedList = editableSubConfig.articleTypes.selectedList;
    const unselectedList = editableSubConfig.articleTypes.unSelectedList;

    selection.forEach(item => {
        moveArticleTypeFromSourceToDestination(unselectedList, selectedList, item);
    });

    editableSubConfig.qualities = calculateQualitiesSelection(editableSubConfig, mappedBaseData);
    filterArticleTypes(selectedList);
    filterArticleTypes(unselectedList);
}

export const moveArticleTypeFromSelectedToUnselected = (editableSubConfig: IDefaultByAttributeSubConfigViewModel, mappedBaseData: IAvailableMappedBaseDataByAddition, selection: IAvailableArticleType[]) => {
    const selectedList = editableSubConfig.articleTypes.selectedList;
    const unselectedList = editableSubConfig.articleTypes.unSelectedList;

    selection.forEach(item => {
        moveArticleTypeFromSourceToDestination(selectedList, unselectedList, item);
    });

    editableSubConfig.qualities = calculateQualitiesSelection(editableSubConfig, mappedBaseData);
    filterArticleTypes(selectedList);
    filterArticleTypes(unselectedList);
}

export const moveQualityFromUnselectedToSelected = (editableSubConfig: IDefaultByAttributeSubConfigViewModel, selection: IAvailableQuality[]) => {
    const selectedList = editableSubConfig.qualities.selectedList;
    const unselectedList = editableSubConfig.qualities.unSelectedList;

    selection.forEach(item => {
        moveQualitiesFromSourceToDestination(unselectedList, selectedList, item);
    });

    filterQualities(selectedList);
    filterQualities(unselectedList);
}

export const moveQualityFromSelectedToUnselected = (editableSubConfig: IDefaultByAttributeSubConfigViewModel, selection: IAvailableQuality[]) => {
    const selectedList = editableSubConfig.qualities.selectedList;
    const unselectedList = editableSubConfig.qualities.unSelectedList;

    selection.forEach(item => {
        moveQualitiesFromSourceToDestination(selectedList, unselectedList, item);
    });

    filterQualities(selectedList);
    filterQualities(unselectedList)
}

export const moveArticleTypeFromSourceToDestination = (source: ArticleTypeFilterList,
    destination: ArticleTypeFilterList, articleType: IAvailableArticleType) => {
    const indexInAll = source.allItems.findIndex(x => x.id === articleType.id);
    destination.allItems.push(articleType);
    source.allItems.splice(indexInAll, 1);
}

export const moveQualitiesFromSourceToDestination = (source: QualityFilterList, destination: QualityFilterList,
    quality: IAvailableQuality) => {
    const indexInAll = source.allItems.findIndex(x => x.id === quality.id);
    destination.allItems.push(quality);
    source.allItems.splice(indexInAll, 1);
}

export const calculateSelectableArticleTypesByConfig = (config: IDefaultByAttributeSubConfigViewModel, mappedBaseData: IAvailableMappedBaseDataByAddition) => {
    const selectedMainProductLine = config.mainProductLine;
    const selectedBodyArea = config.bodyArea;

    const selectedMainProductLines = mappedBaseData.availableMainProductLines.filter(x => x.id === selectedMainProductLine.id);
    const selectedBodyAreaMapped = selectedMainProductLines?.flatMap(main => main.bodyAreas)
        .find(x => x.id === selectedBodyArea.id);

    return selectedBodyAreaMapped ? selectedBodyAreaMapped.articleTypes : [];
}

export const calculateSelectableQualitiesByConfig = (config: IDefaultByAttributeSubConfigViewModel, mappedBaseData: IAvailableMappedBaseDataByAddition) => {
    const selectedMainProductLine = config.mainProductLine;
    const selectedBodyArea = config.bodyArea;
    const selectedMainProductLines= mappedBaseData.availableMainProductLines.filter(x => x.id === selectedMainProductLine.id);
    const selectedBodyAreaMapped = selectedMainProductLines?.flatMap(mai => mai.bodyAreas)
        .find(x => x.id === selectedBodyArea.id);
    if (!selectedBodyAreaMapped) {
        return [];
    }
    const selectedArticleTypesMapped = selectedBodyAreaMapped.articleTypes;
    const selectableQualities = selectedArticleTypesMapped.map(x => x.qualities).flat();

    return removeDuplicates(selectableQualities, "id");
}

export const calculateSelectedArticleTypes = (bodyArea: IAvailableBodyArea, articleTypeSelection: IArticleTypeSelectionViewModel) => {
    if (articleTypeSelection.lockType === LockTypeEnum.Allow) {
        if (articleTypeSelection.selectedList.allItems.length === 0) {
            return bodyArea.articleTypes.filter(x => articleTypeSelection.unSelectedList.allItems.some(y => x.id === y.id));
        }
        else {
            return bodyArea.articleTypes.filter(x => articleTypeSelection.selectedList.allItems.some(y => x.id === y.id));
        }
    }
    else {
        if (articleTypeSelection.selectedList.allItems.length === 0) {
            return [];
        }
        return bodyArea.articleTypes.filter(x => articleTypeSelection.unSelectedList.allItems.some(y => x.id === y.id));
    }
}

export const calculateArticleTypeSelection = (config: IDefaultByAttributeSubConfigViewModel, mappedBaseData: IAvailableMappedBaseDataByAddition) => {
    const selectableArticleTypes = calculateSelectableArticleTypesByConfig(config, mappedBaseData);
    const selectedArticleTypes = config.articleTypes.selectedList.allItems;

    const newArticleTypeSelection = {
        lockType: config.articleTypes.lockType,
        selectedList: {
            searchText: config.articleTypes.selectedList.searchText,
            allItems: selectableArticleTypes.filter(x => selectedArticleTypes.some(y => y.id === x.id)),
            filteredItems: selectableArticleTypes.filter(x => selectedArticleTypes.some(y => y.id === x.id)),
        },
        unSelectedList: {
            searchText: config.articleTypes.unSelectedList.searchText,
            allItems: selectableArticleTypes.filter(x => selectedArticleTypes.every(y => y.id !== x.id)),
            filteredItems: selectableArticleTypes.filter(x => selectedArticleTypes.every(y => y.id !== x.id)),
        },
    }

    filterArticleTypes(newArticleTypeSelection.selectedList);
    filterArticleTypes(newArticleTypeSelection.unSelectedList);

    return newArticleTypeSelection;
}

export const calculateQualitiesSelection = (config: IDefaultByAttributeSubConfigViewModel, mappedBaseData: IAvailableMappedBaseDataByAddition) => {
    const selectableQualities = calculateSelectableQualitiesByConfig(config, mappedBaseData);
    const selectedQualities = config.qualities.selectedList.allItems;

    const newQualitySelection = {
        lockType: config.qualities.lockType,
        selectedList: {
            searchText: config.qualities.selectedList.searchText,
            allItems: selectableQualities.filter(x => selectedQualities.some(y => y.id === x.id)),
            filteredItems: selectableQualities.filter(x => selectedQualities.some(y => y.id === x.id)),
        },
        unSelectedList: {
            searchText: config.qualities.unSelectedList.searchText,
            allItems: selectableQualities.filter(x => selectedQualities.every(y => y.id !== x.id)),
            filteredItems: selectableQualities.filter(x => selectedQualities.every(y => y.id !== x.id)),
        },
    }

    filterQualities(newQualitySelection.selectedList)
    filterQualities(newQualitySelection.unSelectedList)

    return newQualitySelection;
}

export const calculateDependentFields = (config: IDefaultByAttributeSubConfigViewModel, mappedBaseData: IAvailableMappedBaseDataByAddition) => {
    const selectedBodyArea = config.bodyArea;
    if (selectedBodyArea.id) {
        const loadedMainProductLine = mappedBaseData.availableMainProductLines.find(x => x.id === config.mainProductLine.id);
        const selectedBodyAreaNoLongerFits = !loadedMainProductLine.bodyAreas.some(x => x.id === selectedBodyArea.id);
        if (selectedBodyAreaNoLongerFits) {
            config.bodyArea = { id: null, name: "", erpId: "" };
        }
        else {
            config.articleTypes = calculateArticleTypeSelection(config, mappedBaseData);
            config.qualities = calculateQualitiesSelection(config, mappedBaseData);
        }
    }
}

export const isEditableConfigValid = (config: IDefaultByAttributeSubConfigViewModel, mappedBaseData: IAvailableMappedBaseDataByAddition) => {
    const mainProductLine = mappedBaseData.availableMainProductLines.find(x => x.id === config?.mainProductLine?.id);
    const bodyArea = mainProductLine?.bodyAreas.find(x => x.id === config.bodyArea.id)

    if (bodyArea) {
        return true;
    }
    else {
        return false
    }
}

export const isValidSubConfigByIndex = (configIndex: number, configs: IDefaultByAttributeSubConfigViewModel[], mappedBaseData: IAvailableMappedBaseDataByAddition, isMinMaxPlaceholder: boolean) => {
    const config = configs[configIndex];
    if (config) {
        return isValidSubConfig(config, mappedBaseData, isMinMaxPlaceholder);
    }
    else {
        return false;
    }
}

export const isValidSubConfig = (config: IDefaultByAttributeSubConfigViewModel, mappedBaseData: IAvailableMappedBaseDataByAddition, isMinMaxPlaceholder: boolean) => {
    const mainProductLine = mappedBaseData?.availableMainProductLines.find(x => x.id === config.mainProductLine.id);
    const bodyArea = mainProductLine?.bodyAreas.find(x => x.id === config.bodyArea.id);
    if (!bodyArea) {
        return false;
    }

    const selectableArticleTypes = calculateSelectableArticleTypesByConfig(config, mappedBaseData);
    const selectableQualities = calculateSelectableQualitiesByConfig(config, mappedBaseData);
    const currentSelectableArticleTypes = [...config.articleTypes.selectedList.allItems, ...config.articleTypes.unSelectedList.allItems];
    const currentSelectableQualities = [...config.qualities.selectedList.allItems, ...config.qualities.unSelectedList.allItems];
    const articleTypesValid = currentSelectableArticleTypes.every(x => selectableArticleTypes.some(y => y.id === x.id));
    const qualitiesValid = currentSelectableQualities.every(x => selectableQualities.some(y => y.id === x.id));
    const currentSelectedArticleTypes = config.attributeMasspointConfigurations.map(x => x.articleType?.id);
    const masspointArticleTypesValid = currentSelectedArticleTypes.every(x => calculateSelectedArticleTypes(bodyArea, config.articleTypes).map(articleType => articleType.id).includes(x));
    const masspointsValid = isValidMasspointConfigurations(config, isMinMaxPlaceholder);

    return doesExist(bodyArea) && articleTypesValid && qualitiesValid && masspointArticleTypesValid && masspointsValid;
}

const isValidMasspointConfigurations = (config: IDefaultByAttributeSubConfigViewModel, isMinMaxPlaceholder: boolean): boolean => {
    let isValid = true;
    const emptyArticletype = config.attributeMasspointConfigurations.find(x => x.articleType == null);
    if (emptyArticletype) {
        isValid = false;
    }

    const emptyPlaceholder = config.attributeMasspointConfigurations.find(x => x.masspointOne === null);
    if (emptyPlaceholder) {
        isValid = false;
    }

    if (isMinMaxPlaceholder) {
        const emptySecondPlaceholder = config.attributeMasspointConfigurations.find(x => x.masspointTwo === null);
        if (emptySecondPlaceholder) {
            isValid = false;
        }
    }

    return isValid;
}

export const isValidConfigWithSubConfigsByIndex = (index: number, configs: IDefaultByAttributeConfigViewModel[], mappedBaseData: IAvailableMappedBaseDataByAddition, isMinMaxPlaceholder: boolean) => {
    const foundConfig = configs[index];
    let valid = false;
    if (foundConfig) {
        valid = isValidConfigWithSubConfigs(foundConfig, mappedBaseData, isMinMaxPlaceholder);
    }
    return valid;
}

export const isValidConfigWithSubConfigs = (config: IDefaultByAttributeConfigViewModel, mappedBaseData: IAvailableMappedBaseDataByAddition, isMinMaxPlaceholder: boolean) => {
    return isValidConfig(config) && !config.subConfigs.some(x => !isValidSubConfig(x, mappedBaseData, isMinMaxPlaceholder));
}

export const isValidConfig = (config: IDefaultByAttributeConfigViewModel): boolean => {
    return doesExist(config?.addition);
}

export const createNewConfig = (editor: IDefaultByAttributeEditor, loadedData: IDefaultByAttributeLoadedData): IDefaultByAttributeConfigEditorViewModel => {
    const additionIds = editor.configs.map(con => con.addition.id);
    return {
        addition: null,
        availableAdditions: loadedData.additions.filter(ads => additionIds.indexOf(ads.id) === -1),
        isValid: false,
        subConfigs: []
    };
}

export const createEditConfig = (config: IDefaultByAttributeConfigViewModel, configs: IDefaultByAttributeConfigViewModel[], loadedData: IDefaultByAttributeLoadedData): IDefaultByAttributeConfigEditorViewModel => {
    const additionIds = configs.filter(con => con.addition.id !== config.addition.id)
        .map(con => con.addition.id);

    const editorConfig: IDefaultByAttributeConfigEditorViewModel = {
        addition: config.addition,
        availableAdditions: loadedData.additions.filter(ads => additionIds.indexOf(ads.id) === -1),
        isValid: false,
        subConfigs: config.subConfigs.map(subConfig => createEditSubConfig(subConfig))
    };
    editorConfig.isValid = isValidConfig(editorConfig);
    return editorConfig;
}

export const createNewSubConfig = (): IDefaultByAttributeSubConfigViewModel => {
    return {
        mainProductLine: { id: null, name: "", erpId: "" },
        bodyArea: { id: null, name: "", erpId: "" },
        articleTypes: {
            lockType: LockTypeEnum.Allow,
            selectedList: {
                searchText: "",
                allItems: [],
                filteredItems: [],
            },
            unSelectedList: {
                searchText: "",
                allItems: [],
                filteredItems: [],
            },
        },
        qualities: {
            lockType: LockTypeEnum.Allow,
            selectedList: {
                searchText: "",
                allItems: [],
                filteredItems: [],
            },
            unSelectedList: {
                searchText: "",
                allItems: [],
                filteredItems: [],
            },
        },
        attributeMasspointConfigurations: [],
        defaultMasspointOne: null,
        defaultMasspointTwo: null,
        isSideDependant: true,
    };
}

export const createEditSubConfig = (config: IDefaultByAttributeSubConfigViewModel): IDefaultByAttributeSubConfigViewModel => {
    const editableConfig: IDefaultByAttributeSubConfigViewModel = {
        mainProductLine: config.mainProductLine,
        bodyArea: config.bodyArea,
        articleTypes: {
            lockType: LockTypeEnum.Allow,
            selectedList: {
                searchText: "",
                allItems: config.articleTypes.selectedList.allItems,
                filteredItems: config.articleTypes.selectedList.allItems,
            },
            unSelectedList: {
                searchText: "",
                allItems: config.articleTypes.unSelectedList.allItems,
                filteredItems: config.articleTypes.unSelectedList.allItems,
            },
        },
        qualities: {
            lockType: LockTypeEnum.Allow,
            selectedList: {
                searchText: "",
                allItems: config.qualities.selectedList.allItems,
                filteredItems: config.qualities.selectedList.allItems,
            },
            unSelectedList: {
                searchText: "",
                allItems: config.qualities.unSelectedList.allItems,
                filteredItems: config.qualities.unSelectedList.allItems,
            },
        },
        attributeMasspointConfigurations: config.attributeMasspointConfigurations,
        defaultMasspointOne: config.defaultMasspointOne,
        defaultMasspointTwo: config.defaultMasspointTwo,
        isSideDependant: config.isSideDependant
    };

    return editableConfig;
}

export const createMasspointItemList = (massPoints: IMasspointOverview[]): MassPointCriteriaSide[] => {
    return massPoints
        .map(mp => {
            return {
                massPointName: mp.name,
                massPointType: mp.massPointType,
                bodySide: mp.bodySide,
                bodyArea: mp.bodyArea
            }
        })
};

export const setMasspoint = (mp: MassPointCriteriaSide): MassPointCriteriaSideRequest => {
    if (mp != null) {
        return {
            name: mp.massPointName,
            type: mp.massPointType,
            bodyAreaId: mp.bodyArea.id,
            bodySide: mp.bodySide
        }
    }

    return null;
}

export const getMasspointByCriteria = (masspoints: MassPointCriteriaSide[], masspoint: MassPointCriteriaSideGet): MassPointCriteriaSide => {
    if (masspoint == null) {
        return null;
    }

    return masspoints.find(mp => mp.massPointName === masspoint.massPointName && mp.bodyArea.id === masspoint.bodyAreaId && mp.massPointType === masspoint.massPointType && mp.bodySide === masspoint.bodySide);
}

const getArticleTypes = (mappedBaseData: IAvailableMappedBaseDataByAddition[], additionId: number, configuration: IMandatoryByAttributeSubConfigGet): IAvailableArticleType[] => {
    const articleTypes = mappedBaseData.find(add => add.additionId === additionId)
        .availableMainProductLines
        .filter(mai => mai.id === configuration.mainProductLine.id)
        .flatMap(mai => mai.bodyAreas)
        .find(bdy => bdy.id === configuration.bodyArea.id)
        .articleTypes;
    return articleTypes;
}

const getQualities = (mappedBaseData: IAvailableMappedBaseDataByAddition[], additionId: number, configuration: IMandatoryByAttributeSubConfigGet): IAvailableQuality[] => {
    const qualities = mappedBaseData.find(add => add.additionId === additionId)
        .availableMainProductLines
        .filter(mai => mai.id === configuration.mainProductLine.id)
        .flatMap(mai => mai.bodyAreas)
        .find(bdy => bdy.id === configuration.bodyArea.id)
        .articleTypes
        .flatMap(art => art.qualities);
    return qualities;
}

const getEditorSubConfig = (config: IMandatoryByAttributeSubConfigGet, additionId: number, state: PolicyMandatoryByAttributeState) : IDefaultByAttributeSubConfigViewModel => {
    const availableArticleTypes = getArticleTypes(state.loadedData.mappedBaseDatasByAddition, additionId, config);
    
    const selectedArticleTypes = config.articleTypes.length > 0 ? availableArticleTypes
        .filter(aat => config.articleTypes.some(at => at.id === aat.id)) : [];
    const unselectedArticleTypes = config.articleTypes.length > 0 ? availableArticleTypes
        .filter(aat => !config.articleTypes.some(at => at.id === aat.id)) : availableArticleTypes;

    const availableQualities = getQualities(state.loadedData.mappedBaseDatasByAddition, additionId, config);
    const selectedQualities = config.qualities.length > 0 ? availableQualities
        .filter(aat => config.qualities.some(at => at.id === aat.id)) : [];
    const unselectedQualities = config.qualities.length > 0 ? availableQualities
        .filter(aat => !config.qualities.some(at => at.id === aat.id)) : availableQualities;
    return {
        mainProductLine: config.mainProductLine,
        bodyArea: config.bodyArea,
        articleTypes: {
            lockType: LockTypeEnum.Allow,
            selectedList: {
                searchText: "",
                allItems: selectedArticleTypes,
                filteredItems: selectedArticleTypes,
            },
            unSelectedList: {
                searchText: "",
                allItems: unselectedArticleTypes,
                filteredItems: unselectedArticleTypes,
            },
        },
        qualities: {
            lockType: LockTypeEnum.Allow,
            selectedList: {
                searchText: "",
                allItems: selectedQualities,
                filteredItems: selectedQualities,
            },
            unSelectedList: {
                searchText: "",
                allItems: unselectedQualities,
                filteredItems: unselectedQualities,
            },
        },
        isSideDependant: doesExist(config.isSideDependant) ? config.isSideDependant : true,
        defaultMasspointOne: getMasspointByCriteria(state.loadedData.masspoints, config.defaultMasspointOne),
        defaultMasspointTwo: getMasspointByCriteria(state.loadedData.masspoints, config.defaultMasspointTwo),
        attributeMasspointConfigurations: config.attributeMasspointConfigurations.map(masspointConfiguration => ({
            masspointOne: getMasspointByCriteria(state.loadedData.masspoints, masspointConfiguration.masspointOne),
            masspointTwo: getMasspointByCriteria(state.loadedData.masspoints, masspointConfiguration.masspointTwo),                      
            articleType : availableArticleTypes.find(aat => aat.id === masspointConfiguration.articleTypeId)
        }))
    } as IDefaultByAttributeSubConfigViewModel;
}


export const getEditorConfiguration = (config: IPolicyMandatoryByAttributeConfigGet, state: PolicyMandatoryByAttributeState) : IDefaultByAttributeConfigViewModel => { 
    return {
        addition: state.loadedData.additions.find(ad => ad.id === config.additionId),
        subConfigs: config.subConfigs.map(subConfig => getEditorSubConfig(subConfig, config.additionId, state))
    } as IDefaultByAttributeConfigViewModel;
}