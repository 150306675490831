import DeleteIcon from '@mui/icons-material/Delete';
import { DropdownComponent } from "shared/shared";
import { Grid, IconButton } from "@mui/material";
import { EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style"
import { BoxItem } from 'check/policy/components/policy_box_item.style';
import { SelectionListComponent } from 'shared/components/selectionComponent/selectionList.component';
import { IAdjustRangeByMasspointConfiguration, IIndexedIds, IIndexedMasspoint, IIndexedNumber, IIndexedSearchText, IMassPointCriteriaItem } from './policy_adjust_ranges_by_masspoint_configuration.model';
import { DecimalInputComponent } from 'shared/components/input/numberInput/decimal_input.component';

interface PolicyAdjustRangesByMasspointConfigurationComponentProps {
    configuration: IAdjustRangeByMasspointConfiguration
    configurationIndex: number
    deleteConfiguration: (index: number) => {};
    filterSelectedArticleTypes: (value: IIndexedSearchText) => {};
    filterUnselectedArticleTypes: (value: IIndexedSearchText) => {};
    filterSelectedQualities: (value: IIndexedSearchText) => {};
    filterUnselectedQualities: (value: IIndexedSearchText) => {};
    selectArticleTypes: (value: IIndexedIds) => {};
    unSelectArticleTypes: (value: IIndexedIds) => {};
    selectQualities: (value: IIndexedIds) => {};
    unSelectQualities: (value: IIndexedIds) => {};
    selectMasspoint: (value: IIndexedMasspoint) => void;
    changeDefaultPercentage: (value: IIndexedNumber) => void;
    changeThresholdValue: (value: IIndexedNumber) => void;
    changeThresholdPercentage: (value: IIndexedNumber) => void;
}

export const PolicyAdjustRangesByMasspointConfigurationComponent: React.FC<PolicyAdjustRangesByMasspointConfigurationComponentProps> = ({
    configuration,
    configurationIndex,    
    deleteConfiguration,
    filterSelectedArticleTypes,
    filterUnselectedArticleTypes,
    filterSelectedQualities,
    filterUnselectedQualities,
    selectArticleTypes,
    unSelectArticleTypes,
    selectQualities,
    unSelectQualities,
    selectMasspoint,
    changeDefaultPercentage,
    changeThresholdValue,
    changeThresholdPercentage
}) => {
    return (
        <BoxItem>
            <Grid container item md={12}
                direction="column"
                spacing={3}>
                <Grid item
                    container
                    direction="row"
                    alignItems="stretch"
                    justifyContent="space-between"
                    spacing={3}>
                    <Grid item
                        md={9}>
                        <EditorSectionHeaderComponent style={{ marginTop: "0px" }}>{`Konfiguration ${configurationIndex + 1}`}</EditorSectionHeaderComponent>
                    </Grid>
                    <Grid item
                        container
                        md={3}
                        justifyContent='flex-end'>
                        <IconButton
                            style={{ outline: "none" }}
                            onClick={() => deleteConfiguration(configurationIndex)}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                    <Grid container item md={12} spacing={3}
                        direction="row">
                        <Grid item>
                            <DropdownComponent
                                name="Maßpunkt"
                                data={configuration.selectableMasspoints}
                                minWidth="350px"
                                isLoading={false}
                                isRequired={true}
                                onSelect={(selectedItem) => selectMasspoint({index: configurationIndex, masspoint: selectedItem}, )}
                                displayedProperties={["displayName"]}
                                selectedValue={configuration.selectedMasspoint}
                            />
                        </Grid>
                    </Grid>
                    <Grid item md={12}>
                        <SelectionListComponent
                            title={"Zu beachtende Artikelarten"}
                            entityName={"Artikelarten"}
                            onFilterSelected={(searchText) => { filterSelectedArticleTypes( {index: configurationIndex, searchText: searchText}) }}
                            onFilterUnselected={(searchText) => { filterUnselectedArticleTypes( {index: configurationIndex, searchText: searchText}) }}
                            selectionList={configuration.articleTypes}
                            selectEntities={(ids) => { selectArticleTypes( {index: configurationIndex, ids: ids}) }}
                            unselectEntities={(ids) => { unSelectArticleTypes( {index: configurationIndex, ids: ids}) }}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <SelectionListComponent
                            title={"Zu beachtende Qualitäten"}
                            entityName={"Qualitäten"}
                            onFilterSelected={(searchText) => { filterSelectedQualities( {index: configurationIndex, searchText: searchText}) }}
                            onFilterUnselected={(searchText) => { filterUnselectedQualities( {index: configurationIndex, searchText: searchText}) }}
                            selectionList={configuration.qualities}
                            selectEntities={(ids) => { selectQualities( {index: configurationIndex, ids: ids}) }}
                            unselectEntities={(ids) => { unSelectQualities( {index: configurationIndex, ids: ids}) }}
                        />
                    </Grid>
                    <Grid container item md={12}>
                        <Grid item>
                            <DecimalInputComponent
                                name="Default Prozentsatz"
                                width={350}
                                fixedPositions={0}
                                placeholder="0 bis 999"
                                minValue={0}
                                maxValue={999}
                                isRequired={true}
                                onChange={(value) => changeDefaultPercentage({index: configurationIndex, value: value})}
                                value={configuration.defaultPercentage}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item md={12}>
                        <Grid item>
                            <DecimalInputComponent
                                name="Schwellwert"
                                width={350}
                                fixedPositions={1}
                                placeholder="0.1 bis 999.9"
                                minValue={0.1}
                                maxValue={999.9}
                                isRequired={false}
                                onChange={(value) => changeThresholdValue({index: configurationIndex, value: value})}
                                value={configuration.thresholdValue}
                            />
                        </Grid>
                        <Grid item>
                            <DecimalInputComponent
                                name="Prozentsatz"
                                width={350}
                                fixedPositions={0}
                                placeholder="0 bis 999"
                                minValue={0}
                                maxValue={999}
                                isRequired={false}
                                onChange={(value) => changeThresholdPercentage({index: configurationIndex, value: value})}
                                value={configuration.thresholdPercentage}
                            />
                        </Grid>
                    </Grid>    
                </Grid>
            </Grid>
        </BoxItem>
    )
}