import { doesExist, isNullOrWhitespace } from "services/validation.service";
import { IScarTreatmentBodyAreaViewModel, IScarTreatmentEditableBodyAreaViewModel, IScarTreatmentMainProductLineViewModel, PolicyScarTreatmentState } from "./policy_scar_treatment.model";
import { IAvailableMappedBaseData } from "models/available_basedata/available_mapped_base_data";
import { IPolicyScarTreatment, IPolicyScarTreatmentBodyArea } from "models/policies/policy_scar_treatment";
import { createSelectionList, moveFromUnselectedToSelected } from "shared/components/selectionComponent/selectionList.helper";
import { ISelectable } from "shared/components/selectionComponent/models/selectable";
import { getIAvailableArticleTypeDisplayName } from "shared/helpers/displayNames";
import { removeDuplicates } from "shared/helpers/removeDuplicates";

export const updateCanSave = (state: PolicyScarTreatmentState) => {
    const saveAllowed =
        !isNullOrWhitespace(state.data.policy.name)
        && doesExist(state.data.selectedMainProductLine)
        && state.data.selectedMainProductLine.selectedBodyAreas.length > 0
        && !doesExist(state.data.selectedMainProductLine.editableItem)
        && 
        ( 
            state.loadedData.isEdit === false
            || 
            isNotEqual(state)      
        );
    state.command.savePolicy.canExecute = saveAllowed;
}

const isNotEqual = (state: PolicyScarTreatmentState) => {
    return state.data.policy.name !== state.loadedData.policy.name 
        || state.data.policy.ignoreOnQuotation !== state.loadedData.policy.ignoreOnQuotation
        || state.data.selectedMainProductLine.id !== state.loadedData.policy.mainProductLineId
        || bodyAreasNotEqual(state.data.selectedMainProductLine.selectedBodyAreas, state.loadedData.policy.bodyAreas)
}

const bodyAreasNotEqual = ( newBodyAreas: IScarTreatmentBodyAreaViewModel[], loadedBodyAreas: IPolicyScarTreatmentBodyArea[]) => {
    let isNotEqual = false;
    if(newBodyAreas.length !== loadedBodyAreas.length) {
        isNotEqual = true;
    } else {
        newBodyAreas.forEach(nby => {
            const lby = loadedBodyAreas.find(lby => lby.bodyAreaId === nby.id);
            if(lby) {
                if(articlesNotEqual(nby.articleTypes.selectedList.allItems, lby.articleTypeIds)) {
                    isNotEqual = true;
                } else if (qualitiesNotEqual(nby.qualities.selectedList.allItems, lby.qualityIds)){
                    isNotEqual = true;
                }
            } else {
                isNotEqual = true;
            }
        }); 
    }

    return isNotEqual;
}

const qualitiesNotEqual = ( newQualities: ISelectable[], loadedQualities: number[]) => {
    let isNotEqual = false;
    if(newQualities.length !== loadedQualities.length) {
        isNotEqual = true;
    } else {
        if(!newQualities.every(nqu => loadedQualities.includes(nqu.id))){
            isNotEqual = true;
        }
    }
    return isNotEqual;
}

const articlesNotEqual = ( newArticleTypes: ISelectable[], loadedArticleTypes: number[]) => {
    let isNotEqual = false;
    if(newArticleTypes.length !== loadedArticleTypes.length) {
        isNotEqual = true;
    } else {
        if(!newArticleTypes.every(nar => loadedArticleTypes.includes(nar.id))){
            isNotEqual = true;
        }
    }
    return isNotEqual;
}

export const updateValideBodyArea = (bodyAreaItem: IScarTreatmentEditableBodyAreaViewModel) => {
    return doesExist(bodyAreaItem.selectedBodyArea)
        && bodyAreaItem.selectedBodyArea.articleTypes.selectedList.allItems.length > 0
        && bodyAreaItem.selectedBodyArea.qualities.selectedList.allItems.length > 0;
}

export const convertMappedBaseDataToViewModel = (mappedBaseData :IAvailableMappedBaseData, policyLoaded?: IPolicyScarTreatment ) : IScarTreatmentMainProductLineViewModel[] => {
    let mainProductLineViewModels = mappedBaseData.availableMainProductLines.map(amp => { return {
        editableIndex: -1,
        id: amp.id,
        name: amp.name,
        erpId: amp.erpId,
        bodyAreas: amp.bodyAreas.map(bdy => { return {
            id: bdy.id,
            name: bdy.name,
            erpId: bdy.erpId, 
            articleTypes: createSelectionList(bdy.articleTypes, [], getIAvailableArticleTypeDisplayName), 
            qualities: createSelectionList(removeDuplicates(bdy.articleTypes.flatMap(art => art.qualities), "id"), [], getIAvailableArticleTypeDisplayName)
        }}),
        editableItem: undefined,
        selectedBodyAreas: [],
    }});

    if(policyLoaded) {
        const mainProductLineViewModel = mainProductLineViewModels.find(mp => mp.id == policyLoaded.mainProductLineId);
        mainProductLineViewModel.selectedBodyAreas = initializeBodyAreas(mainProductLineViewModel.bodyAreas, policyLoaded);

    }

    return mainProductLineViewModels;
}

const initializeBodyAreas = (bodyAreas: IScarTreatmentBodyAreaViewModel[], policyLoaded: IPolicyScarTreatment): IScarTreatmentBodyAreaViewModel[] => {
    let selectedBodyAreas = [];   
    
    bodyAreas
        .forEach(bdy => {
            const loadedBodyArea = policyLoaded.bodyAreas.find(plb => plb.bodyAreaId === bdy.id);
            if(loadedBodyArea) {
                const selectedBodyArea = {
                    name: bdy.name,
                    id: bdy.id,
                    erpId: bdy.erpId,
                    articleTypes: bdy.articleTypes,
                    qualities: bdy.qualities
                };
                moveFromUnselectedToSelected(selectedBodyArea.articleTypes, loadedBodyArea.articleTypeIds);
                moveFromUnselectedToSelected(selectedBodyArea.qualities, loadedBodyArea.qualityIds);
                selectedBodyAreas.push(selectedBodyArea);
            }
        });
    return selectedBodyAreas;
} 
export const createBodyAreaViewModel = (editableItem: IScarTreatmentEditableBodyAreaViewModel) : IScarTreatmentBodyAreaViewModel => {
    return {
        name: editableItem.selectedBodyArea.name,
        id:  editableItem.selectedBodyArea.id,
        erpId: editableItem.selectedBodyArea.erpId,
        articleTypes: editableItem.selectedBodyArea.articleTypes,
        qualities: editableItem.selectedBodyArea.qualities
    };
}

export const createBodyAreaEditableItem = ( state: PolicyScarTreatmentState,item?: IScarTreatmentBodyAreaViewModel) : IScarTreatmentEditableBodyAreaViewModel  => {
    let selectedBodyAreas = state.data.selectedMainProductLine.selectedBodyAreas
    if(item) {
        selectedBodyAreas = selectedBodyAreas.filter(bdy => bdy.id !== item.id);
    }
    
    let usedBodyAreaIds = selectedBodyAreas.flatMap(bdy => bdy.id);
    let bodyAreas = state.data.selectedMainProductLine.bodyAreas.map(bdy => { return {
        name: bdy.name,
        id: bdy.id,
        erpId: bdy.erpId,
        articleTypes: item && bdy.id === item.id  ? item.articleTypes : bdy.articleTypes,
        qualities: item && bdy.id === item.id  ? item.qualities : bdy.qualities
    }});

    if(usedBodyAreaIds && usedBodyAreaIds.length > 0) {
        bodyAreas = bodyAreas.filter(fby => !usedBodyAreaIds.includes(fby.id));
    }

    return {
        isValide: false,
        bodyAreas: bodyAreas,
        selectedBodyArea: item ? bodyAreas.find(bdy => bdy.id === item.id) : null,
    };
}

