import React, { Fragment } from "react";
import { IAttributeViewModel, IMassPointCriteriaSelection, IMassPointValueSelection, ISerialSizeArticleConfigurationViewModel, ISerialSizeArticleViewModel, ISizeViewModel } from "./serial_size_editor.model";
import IAvailableArticleType from "models/available_basedata/available_article_type";
import { SerialSizeArticleTypeSummaryComponent } from "./serial_size_editor_articletype_summary.component";
import { SerialSizeArticleTypeConfigurationComponent } from "./serial_size_editor_articletype_configuration.component";
import { BoxItem } from "./serial_sizes.style";
import IAvailableSize from "models/available_basedata/available_size";
import { IAvailableAdditionAttributeCategory } from "models/addition_attribute_categories/available_addition_attribute_category";
import { IAvailableAdditionAttribute } from "models/addition_attribute_categories/available_addition_attribute";

export interface SerialSizeArticleTypeAccordionComponentProps {
    articleTypeSummaries: ISerialSizeArticleViewModel[];
    configurationArticleType: ISerialSizeArticleConfigurationViewModel;
    editArticleType: (articleType: ISerialSizeArticleViewModel) => void;
    deleteArticleType: (articleType: ISerialSizeArticleViewModel) => void;
    selectArticleType: (articleType: IAvailableArticleType) => void;
    takeArticleType: () => void;
    cancelArticleType: () => void;
    addSize: () => void;
    editSize: (size: ISizeViewModel) => void;
    deleteSize: (size: ISizeViewModel) => void;
    selectSize: (size: IAvailableSize) => void;
    takeSize: () => void;
    cancelSize: () => void;
    addMasspoint: () => void;
    selectMasspoint: (masspoint: IMassPointCriteriaSelection) => void;
    updateValueFrom: (masspoint: IMassPointValueSelection) => void;
    updateValueTo: (masspoint: IMassPointValueSelection) => void;
    deleteMasspoint: (index: number) => void;
    addAttribute: () => void;
    takeAttribute: () => void;
    editAttribute: (attribute: IAttributeViewModel) => void;
    deleteAttribute: (attribute: IAttributeViewModel) => void;
    selectCategory: (category: IAvailableAdditionAttributeCategory) => void;
    selectAttribute: (attribute: IAvailableAdditionAttribute) => void;
    cancelAttribute: () => void;
    addAttributeMasspoint: () => void;
    selectAttributeMasspoint: (masspoint: IMassPointCriteriaSelection) => void;
    updateAttributeValueFrom: (masspoint: IMassPointValueSelection) => void;
    updateAttributeValueTo: (masspoint: IMassPointValueSelection) => void;
    deleteAttributeMasspoint: (index: number) => void;
}

export const SerialSizeArticleTypeAccordionComponent: React.FC<SerialSizeArticleTypeAccordionComponentProps> = ({
    articleTypeSummaries,
    configurationArticleType,
    editArticleType,
    deleteArticleType,
    selectArticleType,
    takeArticleType,
    cancelArticleType,
    addSize,
    editSize,
    deleteSize,
    selectSize,
    takeSize,
    cancelSize,
    addMasspoint,
    selectMasspoint,
    updateValueFrom,
    updateValueTo,
    deleteMasspoint,
    addAttribute,
    takeAttribute,
    editAttribute,
    deleteAttribute,
    selectCategory,
    selectAttribute,
    cancelAttribute,
    addAttributeMasspoint,
    selectAttributeMasspoint,
    updateAttributeValueFrom,
    updateAttributeValueTo,
    deleteAttributeMasspoint
}) => {
    return (
        <>
            {
                articleTypeSummaries?.map((articleTypeSummary, index) => {

                    const showDetails = configurationArticleType != undefined
                        && !configurationArticleType.isNewArticle
                        && configurationArticleType.oldArticleType.id === articleTypeSummary.articleType.id;
                    return <Fragment key={index}>
                        <>
                            <SerialSizeArticleTypeSummaryComponent
                                articleTypeSummary={articleTypeSummary}
                                isDisabled={configurationArticleType != undefined}
                                editArticleType={editArticleType}
                                deleteArticleType={deleteArticleType}
                            />
                            {showDetails && <>
                                <BoxItem marginTop={1} marginBottom={1}>
                                    <SerialSizeArticleTypeConfigurationComponent
                                        configurationArticleType={configurationArticleType}
                                        selectArticleType={selectArticleType}
                                        takeArticleType={takeArticleType}
                                        cancelArticleType={cancelArticleType}
                                        selectSize={selectSize}
                                        addSize={addSize}
                                        takeSize={takeSize}
                                        editSize={editSize}
                                        deleteSize={deleteSize}
                                        cancelSize={cancelSize}
                                        addMasspoint={addMasspoint}
                                        selectMasspoint={selectMasspoint}
                                        updateValueFrom={updateValueFrom}
                                        updateValueTo={updateValueTo}
                                        deleteMasspoint={deleteMasspoint}
                                        addAttribute={addAttribute}
                                        selectCategory={selectCategory}
                                        selectAttribute={selectAttribute}
                                        takeAttribute={takeAttribute}
                                        editAttribute={editAttribute}
                                        deleteAttribute={deleteAttribute}
                                        cancelAttribute={cancelAttribute}
                                        addAttributeMasspoint={addAttributeMasspoint}
                                        selectAttributeMasspoint={selectAttributeMasspoint}
                                        updateAttributeValueFrom={updateAttributeValueFrom}
                                        updateAttributeValueTo={updateAttributeValueTo}
                                        deleteAttributeMasspoint={deleteAttributeMasspoint}
                                    />
                                </BoxItem>
                            </>}
                        </>
                    </Fragment>
                })
            }
        </>
    );
}