import { IInteractiveMasspoint } from "../redux/massmask_masspoints.model";
import * as style from "./masspoint_list.style";
import { SelectableMasspointComponent } from "./selectable_masspoint.component";

interface MasspointListComponentProps {
    masspoints: Array<IInteractiveMasspoint>;
    headline?: string;
    toggleMasspointActiveState: (IInteractiveMasspoint) => void;
    setMasspointHighlightState: (IInteractiveMasspoint, boolean) => void;
}

export const MasspointListTopComponent: React.FC<MasspointListComponentProps> =
    ({ masspoints,
        headline,
        toggleMasspointActiveState,
        setMasspointHighlightState }) => {
        return  <style.MaskTopComponent>
                    {(headline && masspoints.length > 0) ? <style.HeadlineTop>{headline}</style.HeadlineTop> : <></>}
                    <style.MaskTopCenterComponent>
                        <style.FlexareaTop>              
                            {masspoints.map(masspoint => <style.MasspointWrapper>
                                <SelectableMasspointComponent
                                    key={masspoint.id}
                                    isActive={masspoint.isActive}
                                    name={masspoint.massPoint.name}
                                    onClick={() => toggleMasspointActiveState(masspoint)}
                                    onMouseEnter={() => setMasspointHighlightState(masspoint, true)}
                                    onMouseLeave={() => setMasspointHighlightState(masspoint, false)}
                                />
                            </style.MasspointWrapper>)}
                        </style.FlexareaTop> 
                    </style.MaskTopCenterComponent>
                </style.MaskTopComponent>
    }
