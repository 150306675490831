import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IMassimageOverview from 'models/massimages/massimage_overview';
import { IMassImageUploaded } from 'models/massimages/massimage_uploaded';
import { MassimagesOverviewState } from './massimages_overview.model';
import { filterMassimages, updateCanUpload } from './massimages_overview.reducer';
import { fetchMassimages, uploadMassimage, confirmDeleteMassimage, uploadConfigFile } from './massimages_overview.thunks';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import MassImageUploadErrorData from 'models/massimages/massimage_error_data';

const initialState: MassimagesOverviewState = {
    loadedData: {
        massimages: []
    },
    actualData: {
        isUpdating: false,
        isUploadAllowed: false,
        file: null,
        configFile: {
            file: null,
        },
        massimages: [],
        searchFilter: "",
        gridFilters: { items: [] },
        selectedMassimage: { name: "", id: -1, massImageId: -1, content: null, hasConfigurationSetting: false },
    },
    uploadError: {
        uploadErrorData: null,
        isUnprocessableEntityException: false,
    },
    query: {
        fetchMassimages: { status: "idle", canExecute: true },
    },
    command: {
        confirmDeleteMassimage: { status: "idle", canExecute: false },
        uploadPicture: { status: "idle", canExecute: false },
        uploadConfigFile: { status: "idle", canExecute: false },
        createConfigFile: { status: "idle", canExecute: false },
        editConfigFile: { status: "idle", canExecute: false },
    },
}

export const createMassimageOverviewSlice = createSlice({
    name: 'massimage_overview',
    initialState,
    reducers: {
        deleteMassimage: (state, action: PayloadAction<IMassimageOverview>) => {
            state.actualData.selectedMassimage = action.payload;
        },
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.actualData.searchFilter = action.payload;
            filterMassimages(state);
        },  
        setGridFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.gridFilters = action.payload;
            filterMassimages(state);
        },
        updateUploadErrorData: (state, action: PayloadAction<MassImageUploadErrorData>) => {
            state.uploadError.uploadErrorData = action.payload;
        },
        updateIsUnprocessableEntityException: (state, action: PayloadAction<boolean>) => {
            state.uploadError.isUnprocessableEntityException = action.payload;
        },
        updateFiles: (state, action: PayloadAction<File>) => {
            state.actualData.file = action.payload;
            updateCanUpload(state);
        },
        updateName: (state, action: PayloadAction<string>) => {
            state.actualData.selectedMassimage.name = action.payload;
            updateCanUpload(state);
        },
        confirmDeleteMassimageCompleted: (state) => {
            state.command.confirmDeleteMassimage.status = "idle"
        }
    }, extraReducers: (builder) => {

        // fetch Massimages
        builder.addCase(fetchMassimages.pending, (state, action) => {
            state.query.fetchMassimages.status = "pending"
            state.query.fetchMassimages.canExecute = false;
        }).addCase(fetchMassimages.rejected, (state, action) => {
            state.query.fetchMassimages.status = "error"
            state.query.fetchMassimages.message = action.error.message;
            state.query.fetchMassimages.canExecute = true;
        }).addCase(fetchMassimages.fulfilled, (state, action) => {
            state.query.fetchMassimages.status = "success"
            state.query.fetchMassimages.message = undefined;
            state.query.fetchMassimages.canExecute = true;
            state.loadedData.massimages = action.payload.getData();
            filterMassimages(state);

            // delete massimage
        }).addCase(confirmDeleteMassimage.pending, (state, action) => {
            state.command.confirmDeleteMassimage.status = 'pending'
        }).addCase(confirmDeleteMassimage.rejected, (state, action) => {
            state.command.confirmDeleteMassimage.status = "error"
            state.command.confirmDeleteMassimage.message = action.error.message;
        }).addCase(confirmDeleteMassimage.fulfilled, (state, action) => {
            state.command.confirmDeleteMassimage.status = "success"
            state.command.confirmDeleteMassimage.canExecute = true;
            state.loadedData.massimages = action.payload.getData();
            filterMassimages(state);

            // upload massimage
        }).addCase(uploadMassimage.pending, (state, action) => {
            state.command.uploadPicture.status = "pending"
            state.command.uploadPicture.canExecute = false;
        }).addCase(uploadMassimage.rejected, (state, action) => {
            state.command.uploadPicture.status = "error"
            state.command.uploadPicture.canExecute = true;
            state.command.uploadPicture.message = action.error.message;
        }).addCase(uploadMassimage.fulfilled, (state, action) => {
            state.command.uploadPicture.status = "success"
            state.command.uploadPicture.canExecute = true;
            state.loadedData.massimages = action.payload.getData();
            filterMassimages(state);

            //uploadConfigFile
        }).addCase(uploadConfigFile.pending, (state, action) => {
            state.command.uploadConfigFile.status = "pending"
        }).addCase(uploadConfigFile.rejected, (state, action) => {
            state.command.uploadConfigFile.status = "error"
        }).addCase(uploadConfigFile.fulfilled, (state, action) => {
            state.command.uploadConfigFile.status = "success"
            state.actualData.selectedMassimage = action.payload;
        })
    }
})

export const {
    setSearchFilter,
    setGridFilters,
    deleteMassimage,
    updateFiles,
    updateName,
    updateIsUnprocessableEntityException,
    updateUploadErrorData,
    resetState,
    confirmDeleteMassimageCompleted,
} = createMassimageOverviewSlice.actions

export default createMassimageOverviewSlice.reducer