import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CharacteristicCategoryCreateState } from './characteristic_category_create.model';
import { filterValues, moveValueFromUnselectedToSelected, moveValueFromSelectedToUnselected, updateCanSave } from './characteristic_category_create.reducer'; 
import { saveCharacteristicCategory, cancelSave, getCharacteristicTypes, initData } from './characteristic_category_create.thunks';
import { ICharacteristicTypeOverview } from 'models/characteristic_types/characteristic_type_overview';
import { createSelectionList } from 'shared/components/selectionComponent/selectionList.helper';
import { ICharacteristicValue } from 'models/characteristic_types/characteristic_type';
import { getICharacteristicValueDisplayName } from 'shared/helpers/displayNames';

const initialState: CharacteristicCategoryCreateState = {
    data: { 
        characteristicCategory: {
            name: "", 
            selectionValues: {
                selectedList: 
                {
                    searchText: "",
                    allItems: [],
                    filteredItems: []
                },
                unSelectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: []
                }
            }
        }
    },
    loadedData: {
        characteristicTypes: [],
    },
    command:{
        saveCharacteristicCategory: { status: "idle", canExecute: false },
        cancelSave: { status: "idle", canExecute: true }
    },
    query: {              
        initData: { status: "idle", canExecute: true },
        fetchCharacteristicTypes: { status: "idle", canExecute: true },
    }
}

export const createCharacteristicCategorySlice = createSlice({
    name: 'create_characteristic_category',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = initialState.data;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        changeName: (state, action: PayloadAction<string>) => {
            state.data.characteristicCategory.name = action.payload;
            updateCanSave(state);
        },              
        completedSave: (state) => {
            state.command.saveCharacteristicCategory = initialState.command.saveCharacteristicCategory;
        },
        selectType: (state, action: PayloadAction<ICharacteristicTypeOverview>) => {
            state.data.selectedCharacteristicType = action.payload;
            state.data.characteristicCategory.selectionValues = createSelectionList(action.payload.values, [], getICharacteristicValueDisplayName);
            filterValues(state.data.characteristicCategory.selectionValues.unSelectedList);
        },
        filterSelectedValues: (state, action: PayloadAction<string>) => {
            state.data.characteristicCategory.selectionValues.selectedList.searchText = action.payload;
            filterValues(state.data.characteristicCategory.selectionValues.selectedList);
        },
        filterUnselectedValues: (state, action: PayloadAction<string>) => {
            state.data.characteristicCategory.selectionValues.unSelectedList.searchText = action.payload;
            filterValues(state.data.characteristicCategory.selectionValues.unSelectedList);
        },
        selectValues: (state, action: PayloadAction<number[]>) => {
            moveValueFromUnselectedToSelected(state, action.payload);
            updateCanSave(state);
        },      
        unSelectValues: (state, action: PayloadAction<number[]>) => {
            moveValueFromSelectedToUnselected(state, action.payload);
            updateCanSave(state);
        },     
    }, extraReducers: (builder) => {
        // saveCharacteristicCategory
        builder.addCase(saveCharacteristicCategory.pending, (state) => {
            state.command.saveCharacteristicCategory.status = 'pending'
            state.command.saveCharacteristicCategory.canExecute = false;
        }).addCase(saveCharacteristicCategory.rejected, (state, action) => {
            state.command.saveCharacteristicCategory.status = "error"
            state.command.saveCharacteristicCategory.canExecute = true;
            state.command.saveCharacteristicCategory.message = action.error.message;
        }).addCase(saveCharacteristicCategory.fulfilled, (state) => {
            state.command.saveCharacteristicCategory.status = "success"
            state.command.saveCharacteristicCategory.canExecute = false;

        // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancelSave.status = 'pending'
            state.command.cancelSave.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancelSave.status = "success"
            state.command.cancelSave.canExecute = false;

        // getCharacteristicTypes
    }).addCase(getCharacteristicTypes.pending, (state) => {
        state.query.fetchCharacteristicTypes.status = "pending"
        state.query.fetchCharacteristicTypes.canExecute = false;
    }).addCase(getCharacteristicTypes.fulfilled, (state, action) => {
        state.query.fetchCharacteristicTypes.status = "success"
        state.query.fetchCharacteristicTypes.canExecute = true;
        state.loadedData.characteristicTypes = action.payload.getData();

            // initData
        }).addCase(initData.pending, (state) => {
            state.query.initData.status = "pending"
            state.query.initData.canExecute = false;
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.canExecute = true;
            state.query.initData.message = action.error.message;
        }).addCase(initData.fulfilled, (state) => {
            state.query.initData.status = "success"
            state.query.initData.canExecute = true;
            updateCanSave(state);
        })
    }
})

export const {
    resetState,
    changeName,
    completedSave,
    selectType,
    filterSelectedValues,
    filterUnselectedValues,
    selectValues,
    unSelectValues,
} = createCharacteristicCategorySlice.actions

export default createCharacteristicCategorySlice.reducer