import { LegacyPosition, MappedPositions } from "models/mapping_position/as_position";
import { PositionMappingState } from "./position_mapping.model";
import { OverviewPosition } from "models/mapping_position/overview_position";
import { DifferenceViewModel, GetConfiguredPosition } from "models/mapping_position/get_configured_position";
import { PostMapping } from "models/mapping_position/post_mapping";
import { GridFilterItem } from "@mui/x-data-grid-pro";

export const updateCanSave = (state: PositionMappingState) => {
    const actualLegacyPosition = state.actualData.selectedLegacyPosition;
    if (actualLegacyPosition) {
        const savedSelectedPositions = actualLegacyPosition.mappedPositions;
        const currentSelectedPositions = state.actualData.selectedPositions;
        return isModifiedSelection(savedSelectedPositions, currentSelectedPositions);
    }

    return false;
}

const isModifiedSelection = (savedSelectedPositions: MappedPositions[], currentSelectedPositions: OverviewPosition[]): boolean => {
    if (savedSelectedPositions.length !== currentSelectedPositions.length) {
        return true;
    }
    else {
        for (let i = 0; i < currentSelectedPositions.length; i++) {
            const selectedPositionToCheck = currentSelectedPositions[i];
            const hasPosition = savedSelectedPositions.some(x =>
                x.bodyAreaId == selectedPositionToCheck.bodyAreaId
                && x.bodySide == selectedPositionToCheck.bodySide

                && x.bodyPositionHorizontalStartId == selectedPositionToCheck.bodyPositionHorizontalStartId
                && x.bodyPositionHorizontalEndId == selectedPositionToCheck.bodyPositionHorizontalEndId
                && x.bodyPositionVerticalStartId == selectedPositionToCheck.bodyPositionVerticalStartId
                && x.bodyPositionVerticalEndId == selectedPositionToCheck.bodyPositionVerticalEndId

                && x.prepositionHorizontalStartId == selectedPositionToCheck.prepositionHorizontalStartId
                && x.prepositionHorizontalEndId == selectedPositionToCheck.prepositionHorizontalEndId
                && x.prepositionVerticalStartId == selectedPositionToCheck.prepositionVerticalStartId
                && x.prepositionVerticalEndId == selectedPositionToCheck.prepositionVerticalEndId

                && x.horizontalStartValue == selectedPositionToCheck.horizontalStartValue
                && x.horizontalEndValue == selectedPositionToCheck.horizontalEndValue
                && x.verticalStartValue == selectedPositionToCheck.verticalStartValue
                && x.verticalEndValue == selectedPositionToCheck.verticalEndValue
            );
            if (!hasPosition) {
                return true;
            }
        }
    }

    return false;
}

export const mapConfiguredPosition = (position: GetConfiguredPosition): OverviewPosition => {
    let availableHorizontalStartValues = buildAvailableValues(position.differenceHorizontalStart);
    let availableHorizontalEndValues = buildAvailableValues(position.differenceHorizontalEnd);
    let availableVerticalStartValues = buildAvailableValues(position.differenceVerticalStart);
    let availableVerticalEndValues = buildAvailableValues(position.differenceVerticalEnd);

    return {
        isVisibleByFilter: true,
        sequenceId: 0,
        displayText: position.displayText,
        isLengthAllowed: position.isLengthAllowed,
        lengthFrom: position.lengthFrom,
        lengthTo: position.lengthTo,
        isWidthAllowed: position.isWidthAllowed,
        widthFrom: position.widthFrom,
        widthTo: position.widthTo,
        isDiameterAllowed: position.isDiameterAllowed,
        diameterFrom: position.diameterFrom,
        diameterTo: position.diameterTo,

        isDocumentAllowed: position.isDocumentAllowed,

        bodyAreaId: position.bodyAreaId,
        bodyAreaTranslation: position.bodyAreaTranslation,

        bodySide: position.bodySide,
        bodySideTranslation: position.bodySideTranslation ? position.bodySideTranslation : "",
        displayAsPantyTop: position.displayAsPantyTop,
        prepositionHorizontalStartId: position.prepositionHorizontalStartId,
        bodyPositionHorizontalStartId: position.bodyPositionHorizontalStartId,


        availableHorizontalStartValues: availableHorizontalStartValues,
        horizontalStartValue: getValidNumberOfArray(availableHorizontalStartValues),

        prepositionHorizontalStartTranslation: position.prepositionHorizontalStartTranslation ? position.prepositionHorizontalStartTranslation : "",
        bodyPositionHorizontalStartTranslation: position.bodyPositionHorizontalStartTranslation ? position.bodyPositionHorizontalStartTranslation : "",

        prepositionHorizontalEndId: position.prepositionHorizontalEndId,
        bodyPositionHorizontalEndId: position.bodyPositionHorizontalEndId,

        availableHorizontalEndValues: availableHorizontalEndValues,
        horizontalEndValue: getValidNumberOfArray(availableHorizontalEndValues),

        prepositionHorizontalEndTranslation: position.prepositionHorizontalEndTranslation ? position.prepositionHorizontalEndTranslation : "",
        bodyPositionHorizontalEndTranslation: position.bodyPositionHorizontalEndTranslation ? position.bodyPositionHorizontalEndTranslation : "",

        prepositionVerticalStartId: position.prepositionVerticalStartId,
        bodyPositionVerticalStartId: position.bodyPositionVerticalStartId,

        availableVerticalStartValues: availableVerticalStartValues,
        verticalStartValue: getValidNumberOfArray(availableVerticalStartValues),

        prepositionVerticalStartTranslation: position.prepositionVerticalStartTranslation ? position.prepositionVerticalStartTranslation : "",
        bodyPositionVerticalStartTranslation: position.bodyPositionVerticalStartTranslation ? position.bodyPositionVerticalStartTranslation : "",

        prepositionVerticalEndId: position.prepositionVerticalEndId,
        bodyPositionVerticalEndId: position.bodyPositionVerticalEndId,

        availableVerticalEndValues: availableVerticalEndValues,
        verticalEndValue: getValidNumberOfArray(availableVerticalEndValues),

        prepositionVerticalEndTranslation: position.prepositionVerticalEndTranslation ? position.prepositionVerticalEndTranslation : "",
        bodyPositionVerticalEndTranslation: position.bodyPositionVerticalEndTranslation ? position.bodyPositionVerticalEndTranslation : "",
    }
}

const getValidNumberOfArray = (availableHorizontalStartValues: number[]): number => {
    if (availableHorizontalStartValues.length > 0) {
        return availableHorizontalStartValues[0];
    }

    return 0;
}

const buildAvailableValues = (differenceViewModel: DifferenceViewModel): number[] => {
    const options: number[] = [];
    if (differenceViewModel != null && differenceViewModel != undefined && differenceViewModel.ranges != null && differenceViewModel.ranges != undefined) {

        differenceViewModel.ranges.forEach(x => {
            for (let i = x.start; i <= x.end; i = i + 0.5) {
                if (!options.find(option => option === i)) {
                    options.push(i);
                }
            }
        })
    }

    return options;
}

export const getPositionMapping = (state: PositionMappingState): PostMapping => {
    return {
        legacyPositionId: state.actualData.selectedLegacyPosition.id,
        mappedPositions: buildMappedPositions(state.actualData.selectedPositions)
    }
}

export const buildMappedPositions = (overviewPositions: OverviewPosition[]): MappedPositions[] => {
    return overviewPositions.map(x => {
        return {
            id: 0,
            bodyAreaId: x.bodyAreaId,
            bodySide: x.bodySide,

            prepositionHorizontalStartId: x.prepositionHorizontalStartId,
            bodyPositionHorizontalStartId: x.bodyPositionHorizontalStartId,
            horizontalStartValue: x.horizontalStartValue,

            prepositionHorizontalEndId: x.prepositionHorizontalEndId,
            bodyPositionHorizontalEndId: x.bodyPositionHorizontalEndId,
            horizontalEndValue: x.horizontalEndValue,

            prepositionVerticalStartId: x.prepositionVerticalStartId,
            bodyPositionVerticalStartId: x.bodyPositionVerticalStartId,
            verticalStartValue: x.verticalStartValue,

            prepositionVerticalEndId: x.prepositionVerticalEndId,
            bodyPositionVerticalEndId: x.bodyPositionVerticalEndId,
            verticalEndValue: x.verticalEndValue,

            isLengthAllowed: x.isLengthAllowed,
            lengthFrom: x.lengthFrom,
            lengthTo: x.lengthTo,
            isWidthAllowed: x.isWidthAllowed,
            widthFrom: x.widthFrom,
            widthTo: x.widthTo,
            isDiameterAllowed: x.isDiameterAllowed,
            diameterFrom: x.diameterFrom,
            diameterTo: x.diameterTo,
            isDocumentAllowed: x.isDocumentAllowed
        }
    })
}

export const findSelectedPositions = (state: PositionMappingState, legacyPosition: LegacyPosition): OverviewPosition[] => {
    const selectedPositions = new Array<OverviewPosition>();
    legacyPosition.mappedPositions.forEach(x => {
        let overviewPosition = getOverviewPosition(state.loadedData.configuredPositions, x);

        overviewPosition.horizontalStartValue = x.horizontalStartValue;
        overviewPosition.horizontalEndValue = x.horizontalEndValue;
        overviewPosition.verticalStartValue = x.verticalStartValue;
        overviewPosition.verticalEndValue = x.verticalEndValue;

        selectedPositions.push(overviewPosition);
    });

    return selectedPositions;
}

const getOverviewPosition = (configuredPositions: OverviewPosition[], mappedPosition: MappedPositions): OverviewPosition | undefined => {
    return configuredPositions.find(selectedPositionToCheck =>
        mappedPosition.bodyAreaId == selectedPositionToCheck.bodyAreaId
        && mappedPosition.bodySide == selectedPositionToCheck.bodySide
        && mappedPosition.bodyPositionHorizontalStartId == selectedPositionToCheck.bodyPositionHorizontalStartId
        && mappedPosition.bodyPositionHorizontalEndId == selectedPositionToCheck.bodyPositionHorizontalEndId
        && mappedPosition.bodyPositionVerticalStartId == selectedPositionToCheck.bodyPositionVerticalStartId
        && mappedPosition.bodyPositionVerticalEndId == selectedPositionToCheck.bodyPositionVerticalEndId
        && mappedPosition.prepositionHorizontalStartId == selectedPositionToCheck.prepositionHorizontalStartId
        && mappedPosition.prepositionHorizontalEndId == selectedPositionToCheck.prepositionHorizontalEndId
        && mappedPosition.prepositionVerticalStartId == selectedPositionToCheck.prepositionVerticalStartId
        && mappedPosition.prepositionVerticalEndId == selectedPositionToCheck.prepositionVerticalEndId);
}

export const setValuesOfSelectedPositions = (visiblePositions: OverviewPosition[], selectedPositions: OverviewPosition[],): OverviewPosition[] => {
    selectedPositions.forEach(selectedPosition => {
        let sequenceId = selectedPosition.sequenceId;
        let visiblePosition = visiblePositions.find(visiblePosition => visiblePosition.sequenceId === sequenceId);

        visiblePosition.horizontalStartValue = selectedPosition.horizontalStartValue;
        visiblePosition.horizontalEndValue = selectedPosition.horizontalEndValue;
        visiblePosition.verticalStartValue = selectedPosition.verticalStartValue;
        visiblePosition.verticalEndValue = selectedPosition.verticalEndValue;
    });

    return visiblePositions;
}

export const getFilterConfiguredPositions = (state: PositionMappingState): OverviewPosition[] => {
    const allPositions = state.loadedData.configuredPositions;
    allPositions.forEach(x => x.isVisibleByFilter = true);
    state.actualData.gridFilters.items.forEach(filter => {
        setIsVisbleByFilter(allPositions, filter);
    });

    return allPositions;
}

const setIsVisbleByFilter = (allPositions: OverviewPosition[], filter: GridFilterItem) => {
    let positionsToHide = new Array<OverviewPosition>();
    if (filter.value) {
        const filterValueLowerCase = filter.value.toString().toLocaleLowerCase();

        switch (filter.field) {
            case 'bodyAreaTranslation':
                positionsToHide = allPositions.filter(x => !x.bodyAreaTranslation.toLocaleLowerCase().includes(filterValueLowerCase));
                break;
            case 'side':
                positionsToHide = allPositions.filter(x => !x.bodySideTranslation.toLocaleLowerCase().includes(filterValueLowerCase));
                break;
            case 'position_horizontal_start':
                positionsToHide = allPositions.filter(x => !x.bodyPositionHorizontalStartTranslation.toLocaleLowerCase().includes(filterValueLowerCase));
                break;
            case 'pre_position_horizontal_start':
                positionsToHide = allPositions.filter(x => !x.prepositionHorizontalStartTranslation.toLocaleLowerCase().includes(filterValueLowerCase));
                break;
            case 'pre_position_horizontal_end':
                positionsToHide = allPositions.filter(x => !x.prepositionHorizontalEndTranslation.toLocaleLowerCase().includes(filterValueLowerCase));
                break;
            case 'position_horizontal_end':
                positionsToHide = allPositions.filter(x => !x.bodyPositionHorizontalEndTranslation.toLocaleLowerCase().includes(filterValueLowerCase));
                break;
            case 'pre_position_vertical_start':
                positionsToHide = allPositions.filter(x => !x.prepositionVerticalStartTranslation.toLocaleLowerCase().includes(filterValueLowerCase));
                break;
            case 'position_vertical_start':
                positionsToHide = allPositions.filter(x => !x.bodyPositionVerticalStartTranslation.toLocaleLowerCase().includes(filterValueLowerCase));
                break;
            case 'pre_position_vertical_end':
                positionsToHide = allPositions.filter(x => !x.prepositionVerticalEndTranslation.toLocaleLowerCase().includes(filterValueLowerCase));
                break;
            case 'position_vertical_end':
                positionsToHide = allPositions.filter(x => !x.bodyPositionVerticalEndTranslation.toLocaleLowerCase().includes(filterValueLowerCase));
                break;
            case 'isDocumentAllowed':
                positionsToHide = allPositions.filter(x => filter.value === 'any' ? false : x.isDocumentAllowed.toString() !== filter.value);
                break;
            case 'isMeasurementAllowed':
                positionsToHide = allPositions.filter(x => filter.value === 'any' ? false : (x.isLengthAllowed || x.isWidthAllowed || x.isDiameterAllowed).toString() !== filter.value);
                break;
        }
    }

    if (positionsToHide.length > 0) {
        positionsToHide.forEach(x => x.isVisibleByFilter = false);
    }
}