import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAdditionPolicyInformation } from "models/additions/addition_policy_information";
import { PolicyExcludeAdditionByMasspointCreateState } from './policy_exclude_addition_by_masspoint_create.model';
import { cancelSave, getAdditions, getMassPoints, initData, savePolicy } from './policy_exclude_addition_by_masspoint_create.thunks';
import { filterMasspoint, sortAdditions } from 'check/policy/helper/policy.helper';
import { createMasspointItemList, updateCanSave } from './policy_exclude_addition_by_masspoint_create.reducer';
import { IMassPointCriteriaItem } from 'check/policy/components/policy_editor.model';

const initialState: PolicyExcludeAdditionByMasspointCreateState = {
    data: { 
        policy: { name: "", ignoreOnQuotation: false},
        selectedAddition: null,
        selectedMasspoint1: null,
        selectedMasspoint2: null,
        allMasspoints1: [],
        allMasspoints2: [],
    },
    loadedData: {
        additions: [],
        masspoints: [],
    },
    command:{
        savePolicy: { status: "idle", canExecute: false },
        cancelSave: { status: "idle", canExecute: true }
    },
    query: {
        fetchAdditions: { status: "idle", canExecute: true },
        fetchMasspoints: { status: "idle", canExecute: true },             
        initData: { status: "idle", canExecute: true }
    }
}

export const createPolicyExcludeAdditionByMasspoint = createSlice({
    name: 'create_policy_exclude_addition_by_masspoint',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = initialState.data;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        changeName: (state, action: PayloadAction<string>) => {
            state.data.policy.name = action.payload;
            updateCanSave(state);
        },  
        selectAddition: (state, action: PayloadAction<IAdditionPolicyInformation>) => {
            state.data.selectedAddition = action.payload;
            updateCanSave(state);
        }, 
        selectMasspoint1: (state, action: PayloadAction<IMassPointCriteriaItem>) => {
            state.data.selectedMasspoint1 = action.payload;
            if(state.data.selectedMasspoint1)
            {
                state.data.allMasspoints2 = filterMasspoint(state.loadedData.masspoints, state.data.selectedMasspoint1); 
            }
            updateCanSave(state);
        },      
        selectMasspoint2: (state, action: PayloadAction<IMassPointCriteriaItem>) => {
            state.data.selectedMasspoint2 = action.payload;
            if(state.data.selectedMasspoint2)
            {
                state.data.allMasspoints1 =  filterMasspoint(state.loadedData.masspoints, state.data.selectedMasspoint2); 
            }
            updateCanSave(state);
        },      
        toggleIgnoreOnQuotation: (state, action: PayloadAction<boolean>) => {
            state.data.policy.ignoreOnQuotation = action.payload;
            updateCanSave(state);
        },                                
        completedSave: (state) => {
            state.command.savePolicy = initialState.command.savePolicy;
        }
    }, extraReducers: (builder) => {

        // fetchAdditions
        builder.addCase(getAdditions.pending, (state) => {
            state.query.fetchAdditions.status = "pending"
            state.query.fetchAdditions.canExecute = false;
        }).addCase(getAdditions.rejected, (state, action) => {
            state.query.fetchAdditions.status = "error"
            state.query.fetchAdditions.message = action.error.message;
            state.query.fetchAdditions.canExecute = true;
        }).addCase(getAdditions.fulfilled, (state, action) => {
            state.query.fetchAdditions.status = "success"
            state.query.fetchAdditions.canExecute = true;
            state.loadedData.additions = action.payload.getData().sort(sortAdditions);
            updateCanSave(state);

            // fetchMasspoints
        }).addCase(getMassPoints.pending, (state) => {
            state.query.fetchMasspoints.status = "pending"
            state.query.fetchMasspoints.canExecute = false;
        }).addCase(getMassPoints.rejected, (state, action) => {
            state.query.fetchMasspoints.status = "error"
            state.query.fetchMasspoints.message = action.error.message;
            state.query.fetchMasspoints.canExecute = true;
        }).addCase(getMassPoints.fulfilled, (state, action) => {
            state.query.fetchMasspoints.status = "success"
            state.query.fetchMasspoints.canExecute = true;
            const masspoints = createMasspointItemList(action.payload.getData());   
            state.loadedData.masspoints = masspoints;
            state.data.allMasspoints1 = masspoints;
            state.data.allMasspoints2 = masspoints;

        // savePolicy
        }).addCase(savePolicy.pending, (state) => {
            state.command.savePolicy.status = 'pending'
            state.command.savePolicy.canExecute = false;
        }).addCase(savePolicy.rejected, (state, action) => {
            state.command.savePolicy.status = "error"
            state.command.savePolicy.canExecute = true;
            state.command.savePolicy.message = action.error.message;
        }).addCase(savePolicy.fulfilled, (state) => {
            state.command.savePolicy.status = "success"
            state.command.savePolicy.canExecute = false;

        // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancelSave.status = 'pending'
            state.command.cancelSave.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancelSave.status = "success"
            state.command.cancelSave.canExecute = false;

            // initData
        }).addCase(initData.pending, (state) => {
            state.query.initData.status = "pending"
            state.query.initData.canExecute = false;
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.canExecute = true;
            state.query.initData.message = action.error.message;
        }).addCase(initData.fulfilled, (state) => {
            state.query.initData.status = "success"
            state.query.initData.canExecute = true;
            updateCanSave(state);
        })
    }
})

export const {
    resetState,
    changeName,
    selectAddition,
    selectMasspoint1,
    selectMasspoint2,
    toggleIgnoreOnQuotation,
    completedSave
} = createPolicyExcludeAdditionByMasspoint.actions

export default createPolicyExcludeAdditionByMasspoint.reducer