import { IPositionItemDirectionCreateItemModel, IPositionItems } from "models/position_category/position_category_create";
import { IArticleTypeConfig, ICategoryItemDetail, IPositionCategoryItem, PositionCategoryEditorState } from "models/position_category/position_category_editor"
import { PositionCategoryEditState } from "./position_category.model"
import { filterPositionCategoryValue } from "additions/position_categories/component/position_category_editor.reducer";
import IAvailableBodyArea from "models/available_basedata/available_body_area";
import { BodySideEnum } from "models/masspoints/enums/body_side.enum";
import { SideSeamModeEnum } from "models/position_category/side_seam_mode.enum";
import { createSelectionList, getEmptySelectionList } from "shared/components/selectionComponent/selectionList.helper";
import { PositionDirectionEnum } from "models/position_category/position_direction.enum";
import { getIAdditionPositionOverviewDisplayName, getIAvailableArticleTypeDisplayName } from "shared/helpers/displayNames";
import { IPositionItem, IPositionItemDirection } from "models/position_category/position_category";
import { IAdditionPositionOverview } from "models/addition_position/addition_position_overview";
import { EditorModeEnum } from "models/editors/editor_mode.enum";
import { SelectionListComponent } from "shared/components/selectionComponent/selectionList.component";

export const createEmptyPositionCategoryItem = (state: PositionCategoryEditState): IPositionCategoryItem => {
    return {
        sequenceId: state.actualData.positionCategoryItemSet.positionCategoryItems.length,
        bodyAreas: [],
        bodySides: [],
        isDocumentAllowed: false,
        isLengthAllowed: false,
        isWidthAllowed: false,
        lengthFrom: 0,
        lengthTo: 0,
        widthFrom: 0,
        widthTo: 0,
        isDiameterAllowed: false,
        diameterFrom: 0,
        diameterTo: 0,
        excludedArticleTypesEnabled: false,
        excludedArticleTypesSelection: createSelectionList(state.loadedData.articleTypes, [], getIAvailableArticleTypeDisplayName),
        isDisplayAsPantyTop: false,
        sideSeamModeAllowed: SideSeamModeEnum.Normal,
        horizontalStartItem: createDirectionItem(state, state.loadedData.horizontalStartAdditionPositions),
        verticalStartItem: createDirectionItem(state, state.loadedData.verticalStartAdditionPositions),
        horizontalEndItem: createDirectionItem(state, state.loadedData.horizontalEndAdditionPositions),
        verticalEndItem: createDirectionItem(state, state.loadedData.verticalEndAdditionPositions),
    }
}

export const calculateNewSequenceIds = (state: PositionCategoryEditState) => {
    let index = 0;
    state.actualData.positionCategoryItemSet.positionCategoryItems.forEach(x => {
        x.sequenceId = index;
        index = index + 1;
    });
}

export const updateCanSaveItem = (state: PositionCategoryEditState) => {
    state.actualData.canSaveItem = state.currentEditedPositionCategoryItem.bodyAreas.length > 0 &&
        state.currentEditedPositionCategoryItem.bodySides.length > 0 &&
        rangeIsValid(state.currentEditedPositionCategoryItem.horizontalStartItem) &&
        selectedItemsAreValid(state.currentEditedPositionCategoryItem.horizontalStartItem) &&
        rangeIsValid(state.currentEditedPositionCategoryItem.verticalStartItem) &&
        selectedItemsAreValid(state.currentEditedPositionCategoryItem.verticalStartItem) &&
        rangeIsValid(state.currentEditedPositionCategoryItem.horizontalEndItem) &&
        selectedItemsAreValid(state.currentEditedPositionCategoryItem.horizontalEndItem) &&
        rangeIsValid(state.currentEditedPositionCategoryItem.verticalEndItem) &&
        selectedItemsAreValid(state.currentEditedPositionCategoryItem.verticalEndItem) &&
        lengthInputIsValid(state.currentEditedPositionCategoryItem) &&
        widthInputIsValid(state.currentEditedPositionCategoryItem) &&
        diameterIsValid(state.currentEditedPositionCategoryItem) &&
        articleTypeConfigsValid(state.currentEditedPositionCategoryItem);
}

const articleTypeConfigsValid = (item: IPositionCategoryItem) => {
    return item.horizontalStartItem.editableArticleTypeConfig == null &&
        item.horizontalEndItem.editableArticleTypeConfig == null &&
        item.verticalStartItem.editableArticleTypeConfig == null &&
        item.verticalEndItem.editableArticleTypeConfig == null
}

const lengthInputIsValid = (item: IPositionCategoryItem): boolean => {
    if (item.isLengthAllowed) {
        if (item.lengthFrom === 0 || item.lengthTo === 0 || item.lengthFrom >= item.lengthTo) {
            return false;
        }
    }

    return true;
}

const widthInputIsValid = (item: IPositionCategoryItem): boolean => {
    if (item.isWidthAllowed) {
        if (item.widthFrom === 0 || item.widthTo === 0 || item.widthFrom >= item.widthTo) {
            return false;
        }
    }

    if (item.isWidthAllowed && !item.isLengthAllowed) {
        return false;
    }

    return true;
}

const diameterIsValid = (item: IPositionCategoryItem): boolean => {
    if (item.isDiameterAllowed) {
        if (item.diameterFrom === 0 || item.diameterTo === 0 || item.diameterFrom >= item.diameterTo) {
            return false;
        }
    }

    return true;
}

const selectedItemsAreValid = (itemDetail: ICategoryItemDetail): boolean => {
    return (itemDetail.prepositionSelection.selectedList.allItems.length === 0 || (itemDetail.prepositionSelection.selectedList.allItems.length > 0 && itemDetail.additionPositionSelection.selectedList.allItems.length > 0));
}

const rangeIsValid = (itemDetail: ICategoryItemDetail): boolean => {
    return itemDetail.start <= itemDetail.end;
}

export const updateCanSave = (state: PositionCategoryEditState) => {
    state.actualData.canSaveCategory = state.actualData.name.length > 0 &&
        state.actualData.positionCategoryItemSet.positionCategoryItems.length > 0 &&
        (state.actualData.editorMode === EditorModeEnum.Create || changedConfiguration(state));
}

const changedConfiguration = (state: PositionCategoryEditState): boolean => {
    return state.actualData.name !== state.loadedData.positionCategory.name
        || state.actualData.sideSeamAllowed !== state.loadedData.positionCategory.sideSeamAllowed
        || itemConfigurationChanged(
            state.actualData.positionCategoryItemSet.positionCategoryItems,
            state.loadedData.positionCategory.positionCategoryItemSet.positionCategoryItems,
            state.actualData.sideSeamAllowed)
}

const itemConfigurationChanged = (actualItems: IPositionCategoryItem[], loadedItems: IPositionCategoryItem[], isSideSeamAllowed: boolean): boolean => {
    let changedItems = false;

    actualItems.forEach(ai => {
        let itemEquals = false;
        loadedItems.forEach(li => {
            const loadedItemEquals = positionItemEquals(ai, li, isSideSeamAllowed);
            if (loadedItemEquals) {
                itemEquals = true;
                return;
            }
        });

        if (itemEquals === false) {
            changedItems = true;
            return;
        }
    });
    return actualItems.length != loadedItems.length || changedItems;
}

const positionItemEquals = (actualItem: IPositionCategoryItem, loadedItem: IPositionCategoryItem, isSideSeamAllowed: boolean): boolean => {
    return actualItem.isDocumentAllowed === loadedItem.isDocumentAllowed
        && actualItem.isDiameterAllowed === loadedItem.isDiameterAllowed
        && actualItem.diameterFrom === loadedItem.diameterFrom
        && actualItem.diameterTo === loadedItem.diameterTo
        && actualItem.isLengthAllowed === loadedItem.isLengthAllowed
        && actualItem.lengthFrom === loadedItem.lengthFrom
        && actualItem.lengthTo === loadedItem.lengthTo
        && actualItem.isWidthAllowed === loadedItem.isWidthAllowed
        && actualItem.widthFrom === loadedItem.widthFrom
        && actualItem.widthTo === loadedItem.widthTo
        && actualItem.isDiameterAllowed === loadedItem.isDiameterAllowed
        && actualItem.diameterFrom === loadedItem.diameterFrom
        && actualItem.diameterTo === loadedItem.diameterTo
        && actualItem.isDisplayAsPantyTop === loadedItem.isDisplayAsPantyTop
        && (actualItem.sideSeamModeAllowed === loadedItem.sideSeamModeAllowed || isSideSeamAllowed === false)
        && bodyAreasEquals(actualItem.bodyAreas, loadedItem.bodyAreas)
        && bodySidesEquals(actualItem.bodySides, loadedItem.bodySides)
        && directionEquals(actualItem.horizontalStartItem, loadedItem.horizontalStartItem)
        && directionEquals(actualItem.horizontalEndItem, loadedItem.horizontalEndItem)
        && directionEquals(actualItem.verticalStartItem, loadedItem.verticalStartItem)
        && directionEquals(actualItem.verticalEndItem, loadedItem.verticalEndItem)
        && excludedArticleTypesEquals(actualItem.excludedArticleTypesSelection.selectedList.allItems.map(x=> x.id), loadedItem.excludedArticleTypesSelection.selectedList.allItems.map(x=> x.id));
}

const excludedArticleTypesEquals = (actualExcludedArticleTypeIds: number[], loadedExcludedArticleTypeIds: number[]): boolean => {
    let equalExcludedArticleTypes = true;
    actualExcludedArticleTypeIds.forEach(ab => {
        if (loadedExcludedArticleTypeIds.findIndex(lb => lb === ab) === -1) {
            equalExcludedArticleTypes = false;
        }
    });
    return equalExcludedArticleTypes && actualExcludedArticleTypeIds.length === loadedExcludedArticleTypeIds.length;
}

const bodyAreasEquals = (actualBodyAreas: IAvailableBodyArea[], loadedBodyAreas: IAvailableBodyArea[]): boolean => {
    let equalBodyAreas = true;
    actualBodyAreas.forEach(ab => {
        if (loadedBodyAreas.findIndex(lb => lb.id === ab.id) === -1) {
            equalBodyAreas = false;
        }
    });
    return equalBodyAreas && actualBodyAreas.length === loadedBodyAreas.length;
}

const bodySidesEquals = (actualBodySides: BodySideEnum[], loadedBodySides: BodySideEnum[]): boolean => {
    let equalBodySides = true;
    actualBodySides.forEach(ab => {
        if (loadedBodySides.findIndex(lb => lb === ab) === -1) {
            equalBodySides = false;
            return;
        }
    });
    return equalBodySides && actualBodySides.length === loadedBodySides.length;
}

const directionEquals = (actualDirection: ICategoryItemDetail, loadedDirection: ICategoryItemDetail): boolean => {
    const isEqual = actualDirection.end === loadedDirection.end
        && actualDirection.start === loadedDirection.start

    let equalPositions = true;
    actualDirection.additionPositionSelection.selectedList.allItems.forEach(ab => {
        if (loadedDirection.additionPositionSelection.selectedList.allItems.findIndex(lb => lb.id === ab.id) === -1) {
            equalPositions = false;
            return;
        }
    });
    equalPositions = equalPositions && actualDirection.additionPositionSelection.selectedList.allItems.length === loadedDirection.additionPositionSelection.selectedList.allItems.length;

    let equalPrepositions = true;
    actualDirection.prepositionSelection.selectedList.allItems.forEach(ab => {
        if (loadedDirection.prepositionSelection.selectedList.allItems.findIndex(lb => lb.id === ab.id) === -1) {
            equalPositions = false;
            return;
        }
    });
    equalPrepositions = equalPrepositions && actualDirection.prepositionSelection.selectedList.allItems.length === loadedDirection.prepositionSelection.selectedList.allItems.length;

    let equalArticleTypeConfigs = actualDirection.articleTypeConfigs.length === loadedDirection.articleTypeConfigs.length && 
        actualDirection.articleTypeConfigs.every((element,index) => articleTypeConfigEquals(element,loadedDirection.articleTypeConfigs[index]));

    return isEqual && equalPositions && equalPrepositions && equalArticleTypeConfigs;
}

const articleTypeConfigEquals = (actualArticleTypeConfig: IArticleTypeConfig, loadedArticleTypeConfig: IArticleTypeConfig ) => {
    let equalArticleTypes = true;
    actualArticleTypeConfig.articleTypes.selectedList.allItems.forEach(ab => {
        if (loadedArticleTypeConfig.articleTypes.selectedList.allItems.findIndex(lb => lb.id === ab.id) === -1) {
            equalArticleTypes = false;
            return;
        }
    });

    equalArticleTypes = equalArticleTypes && actualArticleTypeConfig.articleTypes.selectedList.allItems.length === loadedArticleTypeConfig.articleTypes.selectedList.allItems.length;
    
    let equalPositions = true;
    actualArticleTypeConfig.positions.selectedList.allItems.forEach(ab => {
        if (loadedArticleTypeConfig.positions.selectedList.allItems.findIndex(lb => lb.id === ab.id) === -1) {
            equalPositions = false;
            return;
        }
    });

    equalPositions = equalPositions && actualArticleTypeConfig.positions.selectedList.allItems.length === loadedArticleTypeConfig.positions.selectedList.allItems.length;

    return equalArticleTypes && equalPositions
}

export const buildSelectedExcludedArticleTypes = (item: IPositionCategoryItem): number[] => {
    let result = []
    if(item.excludedArticleTypesEnabled){
        result = item.excludedArticleTypesSelection.selectedList.allItems.map(x=> x.id);
    }
    return result;
}

export const buildSelectedDirectionRequests = (item: IPositionCategoryItem): IPositionItemDirectionCreateItemModel[] => {
    const result = new Array<IPositionItemDirectionCreateItemModel>();
    if (item.horizontalStartItem.additionPositionSelection.selectedList.allItems.length > 0) {
        result.push({
            additionHierarchyId: 1,
            start: item.horizontalStartItem.start,
            end: item.horizontalStartItem.end,
            selectedPositions: item.horizontalStartItem.additionPositionSelection.selectedList.allItems.map(y => {
                return {
                    additionPositionId: y.id
                }
            }),
            selectedPrepositions: item.horizontalStartItem.prepositionSelection.selectedList.allItems.map(y => {
                return {
                    prepositionId: y.id
                }
            }),
            articleTypeConfigs: item.horizontalStartItem.articleTypeConfigs.map(config => ({
                selectedArticleTypeIds: config.articleTypes.selectedList.allItems.map(x => x.id),
                selectedPositionIds: config.positions.selectedList.allItems.map(x => x.id)
            })),
        });
    }

    if (item.verticalStartItem.additionPositionSelection.selectedList.allItems.length > 0) {
        result.push({
            additionHierarchyId: 2,
            start: item.verticalStartItem.start,
            end: item.verticalStartItem.end,
            selectedPositions: item.verticalStartItem.additionPositionSelection.selectedList.allItems.map(y => {
                return {
                    additionPositionId: y.id
                }
            }),
            selectedPrepositions: item.verticalStartItem.prepositionSelection.selectedList.allItems.map(y => {
                return {
                    prepositionId: y.id
                }
            }),
            articleTypeConfigs: item.verticalStartItem.articleTypeConfigs.map(config => ({
                selectedArticleTypeIds: config.articleTypes.selectedList.allItems.map(x => x.id),
                selectedPositionIds: config.positions.selectedList.allItems.map(x => x.id)
            })),
        });
    }

    if (item.horizontalEndItem.additionPositionSelection.selectedList.allItems.length > 0) {
        result.push({
            additionHierarchyId: 3,
            start: item.horizontalEndItem.start,
            end: item.horizontalEndItem.end,
            selectedPositions: item.horizontalEndItem.additionPositionSelection.selectedList.allItems.map(y => {
                return {
                    additionPositionId: y.id
                }
            }),
            selectedPrepositions: item.horizontalEndItem.prepositionSelection.selectedList.allItems.map(y => {
                return {
                    prepositionId: y.id
                }
            }),
            articleTypeConfigs: item.horizontalEndItem.articleTypeConfigs.map(config => ({
                selectedArticleTypeIds: config.articleTypes.selectedList.allItems.map(x => x.id),
                selectedPositionIds: config.positions.selectedList.allItems.map(x => x.id)
            })),
        });
    }

    if (item.verticalEndItem.additionPositionSelection.selectedList.allItems.length > 0) {
        result.push({
            additionHierarchyId: 4,
            start: item.verticalEndItem.start,
            end: item.verticalEndItem.end,
            selectedPositions: item.verticalEndItem.additionPositionSelection.selectedList.allItems.map(y => {
                return {
                    additionPositionId: y.id
                }
            }),
            selectedPrepositions: item.verticalEndItem.prepositionSelection.selectedList.allItems.map(y => {
                return {
                    prepositionId: y.id
                }
            }),
            articleTypeConfigs: item.verticalEndItem.articleTypeConfigs.map(config => ({
                selectedArticleTypeIds: config.articleTypes.selectedList.allItems.map(x => x.id),
                selectedPositionIds: config.positions.selectedList.allItems.map(x => x.id)
            })),
        });
    }

    return result;
}

export const filterPositionCategoryItems = (state: PositionCategoryEditState) => {
    let filteredItems = state.actualData.positionCategoryItemSet.positionCategoryItems;
    state.actualData.positionCategoryItemSet.filter.items.forEach(fil => {
        filteredItems = filterPositionCategoryValue(fil, filteredItems);
    });
    state.actualData.positionCategoryItemSet.filteredPositionCategoryItems = filteredItems;
}

export const calculateNewConfig = (state: PositionCategoryEditState, direction: PositionDirectionEnum): IArticleTypeConfig => {
    
    let positions = [];

    switch (direction) {
        case PositionDirectionEnum.HorizontalStart:
            positions = state.loadedData.horizontalStartAdditionPositions;
            break;
        case PositionDirectionEnum.HorizontalEnd:
            positions = state.loadedData.horizontalEndAdditionPositions;
            break;
        case PositionDirectionEnum.VerticalStart:
            positions = state.loadedData.verticalStartAdditionPositions;
            break;
        case PositionDirectionEnum.VerticalEnd:
            positions = state.loadedData.verticalEndAdditionPositions;
            break;
    }
    
    return {
        articleTypes: createSelectionList(state.loadedData.articleTypes, [], getIAdditionPositionOverviewDisplayName),
        positions: createSelectionList(positions, [], getIAdditionPositionOverviewDisplayName),
    };
}

export const calculateEditConfig = (config: IArticleTypeConfig): IArticleTypeConfig => {

    const editConfig: IArticleTypeConfig = {
        articleTypes: config.articleTypes,
        positions: config.positions
    };

    return editConfig;
}

export const addNewConfig = (state: PositionCategoryEditState, direction: PositionDirectionEnum) => {
    var directionItem = getDirectionItem(state, direction);
    directionItem.editableArticleTypeConfig = calculateNewConfig(state, direction);
}

export const getDirectionItem = (state: PositionCategoryEditState, direction: PositionDirectionEnum): ICategoryItemDetail => {
    switch (direction) {
        case PositionDirectionEnum.HorizontalStart:
            return state.currentEditedPositionCategoryItem.horizontalStartItem;
        case PositionDirectionEnum.HorizontalEnd:
            return state.currentEditedPositionCategoryItem.horizontalEndItem;
        case PositionDirectionEnum.VerticalStart:
            return state.currentEditedPositionCategoryItem.verticalStartItem;
        case PositionDirectionEnum.VerticalEnd:
            return state.currentEditedPositionCategoryItem.verticalEndItem;

    }
}

export const getEmptyDirectionItem = () => {
    return {
        articleTypeConfigDeletingIndex: -1,
        articleTypeConfigs: [],
        editableArticleTypeConfig: null,
        editableArticleTypeConfigIndex: -1,
        additionPositionSelection: getEmptySelectionList(),
        prepositionSelection: getEmptySelectionList(),
        start: 0,
        end: 0,
    }
}

export const createPositionCategoryItems = (state: PositionCategoryEditState, positionItems: IPositionItem[]): IPositionCategoryItem[] => {
    return positionItems.map(positionItem => (
         {
            sequenceId: state.actualData.positionCategoryItemSet.positionCategoryItems.length,
            bodyAreas: positionItem.positionItemBodyAreas?.map(x => state.loadedData.bodyAreas.find(y => y.id === x.bodyAreaId)) ?? [],
            bodySides: positionItem.positionItemBodySides?.map(x=> x.bodySide) ?? [],
            isDocumentAllowed: positionItem.isDiameterAllowed,
            isLengthAllowed: positionItem.isLengthAllowed,
            isWidthAllowed: positionItem.isWidthAllowed,
            lengthFrom: positionItem.lengthFrom,
            lengthTo: positionItem.lengthTo,
            widthFrom: positionItem.widthFrom,
            widthTo: positionItem.widthTo,
            isDiameterAllowed: positionItem.isDiameterAllowed,
            diameterFrom: positionItem.diameterFrom,
            diameterTo: positionItem.diameterTo,
            excludedArticleTypesEnabled: (positionItem.positionItemExcludedArticleTypes?.map(x=> x.articleTypeId)?.length > 0),
            excludedArticleTypesSelection: createSelectionList(state.loadedData.articleTypes,positionItem.positionItemExcludedArticleTypes?.map(x=> x.articleTypeId) ?? [], getIAvailableArticleTypeDisplayName),
            isDisplayAsPantyTop: positionItem.displayAsPantyTop,
            sideSeamModeAllowed: positionItem.sideSeamModeAllowed ?? SideSeamModeEnum.Normal,
            horizontalStartItem: createDirectionItem(state, state.loadedData.horizontalStartAdditionPositions, positionItem.positionItemDirections.find(x => x.additionHierarchyId === 1)),
            verticalStartItem: createDirectionItem(state, state.loadedData.verticalStartAdditionPositions, positionItem.positionItemDirections.find(x => x.additionHierarchyId === 2)),
            horizontalEndItem: createDirectionItem(state, state.loadedData.horizontalEndAdditionPositions, positionItem.positionItemDirections.find(x => x.additionHierarchyId === 3)),
            verticalEndItem: createDirectionItem(state, state.loadedData.verticalEndAdditionPositions, positionItem.positionItemDirections.find(x => x.additionHierarchyId === 4)),
        }
    ));
}

export const createDirectionItem = (
    state: PositionCategoryEditState, 
    positions: IAdditionPositionOverview[],
    direction?: IPositionItemDirection,
): ICategoryItemDetail => {

    const additionPositionIds = direction?.positionItemAdditions?.map(x => x.additionPositionId) ?? [];
    const prepositionIds = direction?.positionItemPrepositions?.map(x => x.prepositionId) ?? [];

    const articleTypes = state.loadedData.articleTypes;
    const prePositions = state.loadedData.prepositions;

    return {
        additionPositionSelection: createSelectionList(positions, additionPositionIds, getIAdditionPositionOverviewDisplayName),
        prepositionSelection: createSelectionList(prePositions, prepositionIds, getIAdditionPositionOverviewDisplayName),
        articleTypeConfigDeletingIndex: -1,
        editableArticleTypeConfigIndex: -1,
        editableArticleTypeConfig: null,
        articleTypeConfigs: direction?.positionItemArticleTypeConfigs?.map(config => ({
            articleTypes: createSelectionList(articleTypes, config?.articleTypes?.map(x => x.articleTypeId) ?? [], getIAdditionPositionOverviewDisplayName),
            positions: createSelectionList(positions, config?.positions?.map(x=> x.additionPositionId) ?? [], getIAdditionPositionOverviewDisplayName),
        })) ?? [],
        start: direction?.start ?? 0,
        end: direction?.end ?? 0,
    }
}