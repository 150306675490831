import { Chip } from "@mui/material";
import { GridFilterItem, GridCellParams, GridColDef, GridSortModel } from "@mui/x-data-grid-pro";
import { IPositionCategoryOverview } from "models/position_category/position_category_overview";
import { CustomFilterOperators } from "shared/components/datagrid/custom_filter_operators";
import { DatagridMenu } from "shared/components/datagrid/datagrid_menu.component";

export const filterPositionCategoriesValue = (filterItem: GridFilterItem, filteredItems: IPositionCategoryOverview[]) => {
    if(filterItem.value)
    {
        switch(filterItem.field)
        {
            case 'name':
                filteredItems = filteredItems.filter(x => x.name.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'itemCount':
                filteredItems = filteredItems.filter(x => x.itemCount.toString().toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;                                                                                                    
        }
    }
    return filteredItems;
}

const renderItemCount = (params: GridCellParams) => {
    return <>
        <Chip color="primary" size="medium" label={params.row?.itemCount} />
    </>
}

export const getGridColumns = (
    detailsPositionCategory: (positionCategory: IPositionCategoryOverview) => void,
    editPositionCategory: (positionCategory: IPositionCategoryOverview) => void,
    deletePositionCategory: (positionCategory: IPositionCategoryOverview) => void,
    ): GridColDef[] => [
    {
        field: "menu",
        headerName: " ",
        width: 15,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        renderCell: params => DatagridMenu({params: params, menuItemsData: 
            [
                {label: "Positionsliste anzeigen", action: detailsPositionCategory},
                {label: "Positionskategorie editieren" , action: editPositionCategory},
                {label: "Positionskategorie löschen", action: deletePositionCategory}
            ]})
    },
    {
        field: "name",
        headerName: "Name",
        disableColumnMenu: false,
        flex: 0.5,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    },  
    {
        field: "itemCount",
        headerName: "Anzahl Items",
        disableColumnMenu: false,
        flex: 0.5,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators('number'),
        renderCell: renderItemCount
    }
]    

export const sortByNameModel: GridSortModel = [
    {
        field: "name",
        sort: "asc",
    },
];