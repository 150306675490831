import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseErrors } from "shared/networking/error_handling";
import AdditionService from 'services/addition.service';
import MassPointService from 'services/masspoint.service';
import PolicyServiceAPI from 'services/policy.service';
import BaseDataService from 'services/base_data.service';
import ArticleAttributeCategoryService from 'services/addition_attribute_category.service';
import { RootState } from "app/redux_store";
import { PolicyTypeEnum } from "models/policies/policy_type.enum";
import { toQualityId } from "./policy_soft_seam_create.reducer";

export const getAdditions = createAsyncThunk(
    'policy/create_policy_soft_seam/getAdditions',
    async (_) => {
        const response = await AdditionService.getAvailableAdditionsByPolicyTypeOverview(PolicyTypeEnum.SoftSeam, undefined);
        handleResponseErrors(response, "Zusätze");
        return response;
    }
)

export const getMassPoints = createAsyncThunk(
    'policy/create_policy_soft_seam/getMassPoints',
    async (_) => {
        const response = await MassPointService.getMassPoints()
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)

export const getQualities = createAsyncThunk(
    'policy/create_policy_soft_seam/getQualities',
    async (_) => {
        const response = await BaseDataService.getBaseData()
        handleResponseErrors(response, "Qualities");
        return response;
    }
)

export const getArticleAttributeCategories = createAsyncThunk(
    "policy/create_policy_soft_seam/getArticleAttributeCategories",
    async (_) => {
        const response = await ArticleAttributeCategoryService.getAvailableAttributeCategories();
        handleResponseErrors(response, "Attribute");
        return response;
    }
)

export const savePolicy = createAsyncThunk(
    'policy/create_policy_soft_seam/savepolicy',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.policy_soft_seam_create.data;
        const response = await PolicyServiceAPI.createSoftSeam({
            name: data.policy.name,
            additionId: data.selectedAddition.id,
            ignoreOnQuotation: data.policy.ignoreOnQuotation,
            massPointCriterias: data.massPointCriterias.selectedList.allItems.map(x => { return {
                name: x.name,
                type: x.massPointType,
                bodyAreaId: x.bodyAreaId,
            }}),
            softSeamConfigurations: data.configurations.map(x => { return {
                compressionClassId: x.selectedCompressionClass.id,
                threshold: x.threshold,
                qualities: toQualityId(x.qualities.selectedList.allItems)
            }})
        });
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'policy/create_policy_soft_seam/cancelSave',
    async (_) => { }
)

export const initData = createAsyncThunk(
    'policy/create_policy_soft_seam/initData',
    async (_, { dispatch }) => {
        await Promise.all([
            dispatch(getAdditions()),
            dispatch(getMassPoints()),
            dispatch(getQualities()),
            dispatch(getArticleAttributeCategories()),
        ]);
    }
)



