import { filterSerialSizeValue } from '../serial_sizes_overview.helper';
import { SerialSizeOverviewState } from './serial_sizes_overview.model';

export const filterSerialSizes = (state: SerialSizeOverviewState ) => {
    const searchTextLower = state.actualData.searchFilter.toLowerCase().trim();
    let filteredItems = state.loadedData.serialSizes.filter(
        (serialSize) =>{

            const allArticleTypes = serialSize.articleTypes.map(articleType => articleType.erpId !== "" ? `${articleType.erpId} - ${articleType.name}` : `${articleType.name}` ).join(" ");
            const qualityString = `${serialSize.quality.erpId} - ${serialSize.quality.name}`;
            return (qualityString.toLowerCase().includes(searchTextLower) ||
            allArticleTypes.toLowerCase().includes(searchTextLower));          
        }
    );

    state.actualData.gridFilters.items.forEach(fil => {
        filteredItems = filterSerialSizeValue(fil, filteredItems);
    });
    
    state.actualData.serialSizes = filteredItems;
}