import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'app/redux_store';
import MassPointService from 'services/masspoint.service';
import MassPointGroupService from 'services/masspoint_group.service';
import { handleResponseErrors } from 'shared/networking/error_handling';
import { fromMassPointGroupViewModel } from './masspoint_group.reducer';

export const editMasspointGroup = createAsyncThunk(
    'masspoints/masspointGroup/editMasspointGroup',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const updatedMassPointGroupViewModel = state.masspoint_groups.actualData.masspointGroup;
        const loadedMassPointCriterias = state.masspoint_groups.loadedData.massPointCriterias;
        const updatedMassPointGroup = fromMassPointGroupViewModel(updatedMassPointGroupViewModel, loadedMassPointCriterias);
        const response = await MassPointGroupService.updateMassPointGroup(updatedMassPointGroup);
        handleResponseErrors(response, "Maßpunktgruppe");
        return response;
    }
)

export const saveMasspointGroup = createAsyncThunk(
    'masspoints/masspointGroup/saveMasspointGroup',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const newMassPointGroupViewModel = state.masspoint_groups.actualData.masspointGroup;
        const loadedMassPointCriterias = state.masspoint_groups.loadedData.massPointCriterias;
        const newMassPointGroup = fromMassPointGroupViewModel(newMassPointGroupViewModel, loadedMassPointCriterias);
        const response = await MassPointGroupService.saveMasspointGroup(newMassPointGroup);
        handleResponseErrors(response, "Maßpunktgruppe");
        return response;
    }
)

export const getMasspointGroup = createAsyncThunk(
    'masspoints/masspointGroup/getMasspointGroup',
    async (masspointGroupId: Number, { dispatch }) => {    
        await dispatch(getMasspoints());
        const response = await MassPointGroupService.getMassPointGroup(masspointGroupId);
        handleResponseErrors(response, "Maßpunktgruppe");
        return response;
    }
)

export const getMasspoints = createAsyncThunk(
    'masspoints/masspointGroup/getMasspoints',
    async (_) => {
        const response = await MassPointService.getMassPoints();
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)