import { doesExist, isNullOrWhitespace } from "services/validation.service";
import { PolicyMandatoryByAttributeState } from "./policy_mandatory_by_attribute.model";
import { IDefaultByAttributeConfigViewModel, IDefaultByAttributeSubConfigViewModel } from "../models/policy_mandatory_by_attribute.models";
import { IMandatoryByAttributeSubConfig, IPolicyMandatoryByAttributeConfig } from "models/policies/policy_mandatory_by_attribute";
import _ from "lodash";
import { isValidConfigWithSubConfigsByIndex } from "../common/helpers";
import { EditorModeEnum } from "models/editors/editor_mode.enum";

export const updateCanSave = (state: PolicyMandatoryByAttributeState) => {
    state.command.updatePolicy.canExecute =
        !isNullOrWhitespace(state.data.editor.policy.name)
        && (state.data.editor.editorMode === EditorModeEnum.Create || isNotEqual(state))
        && doesExist(state.data.editor.selectedAttribute)
        && state.data.editor.configs.length > 0
        && state.data.editor.editableConfig == null
        && hasValidConfigs(state);
}

const hasValidConfigs = (state: PolicyMandatoryByAttributeState) => {
    const isMinMaxPlaceholder = Boolean(state.data.editor.editableConfig?.addition?.placeholder?.twoRangePlaceholder);
    return !state.data.editor.configs.some((config, index) => {
        const mappedBaseData = state.loadedData.mappedBaseDatasByAddition.find(x => x.additionId === config.addition?.id);
        return !isValidConfigWithSubConfigsByIndex(index, state.data.editor.configs, mappedBaseData, isMinMaxPlaceholder);
    });
}

const isNotEqual = (state: PolicyMandatoryByAttributeState) => {
    return state.data.editor.policy.name !== state.loadedData.policy.name
        || state.data.editor.policy.ignoreOnQuotation !== state.loadedData.policy.ignoreOnQuotation
        || state.data.editor.selectedAttribute?.id !== state.loadedData.policy.attributeId
        || !_.isEqual(toOriginalConfigStructure(state.data.editor.configs), state.loadedData.policy.configs)
}

const toOriginalConfigStructure = (editedConfigs: IDefaultByAttributeConfigViewModel[]) => {
    return editedConfigs.map(config => ({
        additionId: config.addition.id,
        subConfigs: toOriginalSubConfigStructure(config.subConfigs)
    } as IPolicyMandatoryByAttributeConfig))
}

const toOriginalSubConfigStructure = (editedSubConfigs: IDefaultByAttributeSubConfigViewModel[]) => {
    return editedSubConfigs.map(subConfig => ({
        mainProductLine: subConfig.mainProductLine,
        bodyArea: subConfig.bodyArea,
        articleTypes: subConfig.articleTypes.selectedList.allItems,
        qualities: subConfig.qualities.selectedList.allItems,
    } as IMandatoryByAttributeSubConfig))
}