import { GridFilterItem, GridColDef, getGridBooleanOperators, GridSortModel } from "@mui/x-data-grid-pro";
import IMasspointGroupOverview from "models/masspoint_groups/masspoint_group_overview";
import { CustomFilterOperators } from "shared/components/datagrid/custom_filter_operators";
import { DatagridMenu } from "shared/components/datagrid/datagrid_menu.component";

export const filterMasspointGroupValue = (filterItem: GridFilterItem, filteredItems: IMasspointGroupOverview[]) => {
    if(filterItem.value)
    {
        switch(filterItem.field)
        {
            case 'identifier':
                filteredItems = filteredItems.filter(x => x.identifier.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'nameTranslationKey':
                filteredItems = filteredItems.filter(x => x.nameTranslationKey.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'nameTranslation':
                filteredItems = filteredItems.filter(x => x.nameTranslation.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;                                                                                                   
        }
    }
    return filteredItems;
}

export const getGridColumns = (
    editMasspointGroup: (masspointGroup: IMasspointGroupOverview) => void,
    deleteMasspointGroup: (masspointGroup: IMasspointGroupOverview) => void,
    ): GridColDef[] => [
    {
        field: "menu",
        headerName: " ",
        width: 15,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        renderCell: params => DatagridMenu({params: params, menuItemsData: 
            [
                {label: "Maßpunktgruppe editieren", action: editMasspointGroup},
                {label: "Maßpunktgruppe löschen", action: deleteMasspointGroup}
            ]})
    },
    {
        field: "identifier",
        headerName: "Bezeichner",
        disableColumnMenu: false,
        flex: 0.125,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    },
    {
        field: "nameTranslationKey",
        headerName: "Übersetzungs-Key",
        disableColumnMenu: false,
        flex: 0.125,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    },
    {
        field: "nameTranslation",
        headerName: "Übersetzung",
        disableColumnMenu: false,
        flex: 0.125,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    }
]    

export const sortByNameModel: GridSortModel = [
    {
        field: "identifier",
        sort: "asc",
    },
];