import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MasspointCreateState } from './masspoint_create.model';
import { updateCanCreateMasspoint } from './masspoint_create.reducer';
import { createMasspoint, fetchBodyAreas, initData } from './masspoint_create.thunks';
import { IBodyArea } from 'models/masspoints/body_area';
import { MasspointTypeEnum } from "models/masspoints/enums/masspoint_type.enum";
import { BodySideEnum } from 'models/masspoints/enums/body_side.enum';

const initialState: MasspointCreateState = {
    data: {
        masspoint: {
            name: null,
            massPointType: MasspointTypeEnum.Undefined,
            bodySide: BodySideEnum.None,
            bodyArea: null,
            massCode: null,
            massSubCode: null,
            isPantyTopWithStandardDimensions: null,
            isEllipticalShapeKneeFunctionZone: null
        },
        bodyareas: [],
    },
    query: {
        fetchBodyAreas: { status: "idle", canExecute: true },
        initData: { status: "idle", canExecute: true },
    },
    command: {
        createMasspoint: { status: "idle", canExecute: false },
        cancelMasspoint: { status: "idle", canExecute: false }
    },
}

export const createMasspointSlice = createSlice({
    name: 'masspoints/masspoint/create',
    initialState,
    reducers: {
        resetState: (state) => {
            state.command = initialState.command;
            state.query = initialState.query;
            state.data = initialState.data;
        },
        updateName: (state, action: PayloadAction<string>) => {
            const payload = action.payload;
            state.data.masspoint.name = payload;
            updateCanCreateMasspoint(state);
        },
        updateMasscode: (state, action: PayloadAction<string>) => {
            const payload = action.payload;
            state.data.masspoint.massCode = payload;
            updateCanCreateMasspoint(state);
        },
        updateBodyArea: (state, action: PayloadAction<IBodyArea>) => {
            const payload = action.payload;
            state.data.masspoint.bodyArea = payload;
            updateCanCreateMasspoint(state);
        },
        updateBodySide: (state, action: PayloadAction<BodySideEnum>) => {
            const payload = action.payload;
            state.data.masspoint.bodySide = payload;
            updateCanCreateMasspoint(state);
        },
        updateMassSubcode: (state, action: PayloadAction<any>) => {
            const payload = action.payload;
            state.data.masspoint.massSubCode = payload.text;
            updateCanCreateMasspoint(state);
        },
        updateMasspointType: (state, action: PayloadAction<MasspointTypeEnum>) => {
            const payload = action.payload;
            state.data.masspoint.massPointType = payload;
            updateCanCreateMasspoint(state);
        },
        updateIsPantyTopWithStandardDimensions: (state, action: PayloadAction<boolean>) => {
            state.data.masspoint.isPantyTopWithStandardDimensions = action.payload;
            updateCanCreateMasspoint(state);
        },
        updateIsEllipticalShapeKneeFunctionZone: (state, action: PayloadAction<boolean>) => {
            state.data.masspoint.isEllipticalShapeKneeFunctionZone = action.payload;
            updateCanCreateMasspoint(state);
        },
        cancelMasspoint: (state) => {
            state.command.cancelMasspoint.status = "success";
        },
        createMasspointCompleted: (state) => {
            state.command.createMasspoint.status = "idle"
        },
    }, extraReducers: (builder) => {

        // fetch MassPoint
        builder.addCase(fetchBodyAreas.pending, (state, action) => {
            state.query.fetchBodyAreas.status = "pending"
            state.query.fetchBodyAreas.canExecute = false;
        }).addCase(fetchBodyAreas.rejected, (state, action) => {
            state.query.fetchBodyAreas.status = "error"
            state.query.fetchBodyAreas.message = action.error.message;
            state.query.fetchBodyAreas.canExecute = true;
        }).addCase(fetchBodyAreas.fulfilled, (state, action) => {
            state.query.fetchBodyAreas.status = "success"
            state.query.fetchBodyAreas.message = undefined;
            state.query.fetchBodyAreas.canExecute = true;
            state.data.bodyareas = action.payload.getData();

            // create masspoint
        }).addCase(createMasspoint.pending, (state, action) => {
            state.command.createMasspoint.status = "pending"
            state.command.createMasspoint.canExecute = false;
        }).addCase(createMasspoint.rejected, (state, action) => {
            state.command.createMasspoint.status = "error"
            state.command.createMasspoint.canExecute = true;
            state.command.createMasspoint.message = action.error.message;
        }).addCase(createMasspoint.fulfilled, (state, action) => {
            state.command.createMasspoint.status = "success"
            state.command.createMasspoint.canExecute = true;

            // initData
        }).addCase(initData.pending, (state, action) => {
            state.query.initData.status = "pending"
            state.query.initData.canExecute = false;
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.canExecute = true;
            state.query.initData.message = action.error.message;
        }).addCase(initData.fulfilled, (state, action) => {
            state.query.initData.status = "success"
            state.query.initData.canExecute = true;
        })
    }
})

export const {
    resetState,
    updateName,
    updateMasscode,
    updateMassSubcode,
    updateBodyArea,
    updateMasspointType,
    updateBodySide,
    updateIsPantyTopWithStandardDimensions,
    updateIsEllipticalShapeKneeFunctionZone,
    cancelMasspoint,
    createMasspointCompleted,
} = createMasspointSlice.actions

export default createMasspointSlice.reducer