import { PolicyEditorComponent } from "check/policy/components/policy_editor.component";
import { LoadingOverlayComponent, LoadingOverlayContainer } from "shared/components/loading_overlay/loading_overlay.component";
import { useAppDispatch, useAppSelector } from '../../../app/hook';
import { AppDispatch } from '../../../app/redux_store';
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { DropdownComponent, LoadingIndicatorComponent } from "shared/shared";
import { Grid } from "@mui/material";
import { EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";
import { useNavigate, useParams } from "react-router-dom";
import i18next from "i18next";
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";
import { changeName, completedSave, filterSelectedMassPointGroups, filterUnselectedMassPointGroups, resetState, selectAdditionCategory, selectMassPointGroups, toggleIgnoreOnQuotation, unSelectMassPointGroups } from "./redux/policy_fingers_and_toes.slice";
import { cancelSave, initData, storePolicy } from "./redux/policy_fingers_and_toes.thunks";
import { SelectionListComponent } from "shared/components/selectionComponent/selectionList.component";

export const PolicyFingersAndToesComponent = () => {
    const state = useAppSelector((state) => state.policy_fingers_and_toes);
    const dispatch: AppDispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { id } = useParams();

    useEffect(() => {
        dispatch(initData({id: id}));

        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    if (state.query.initData.status === "pending") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.cancelSave.status === "success") {
        navigate("/plausibilitycheck/policy");
        dispatch(resetState());
    }

    if (state.command.savePolicy.status === "success") {
        enqueueSnackbar("Regelwerk gespeichert", { variant: "success" });
        navigate("/plausibilitycheck/policy");
        dispatch(resetState());
    }

    if (state.command.savePolicy.status === "error") {
        enqueueSnackbar(state.command.savePolicy.message, { variant: "error" });
        dispatch(completedSave());
    }

    if (state.command.savePolicy.status === "warning") {
        enqueueSnackbar(state.command.savePolicy.message, { variant: "warning" });
        dispatch(completedSave());
    }

    const isSaving = state.command.savePolicy.status === "pending";
    const textHeader = state.loadedData.isEdit ? "Finger und Zehen Regelwerk editieren" : "Neues Finger und Zehen Regelwerk erstellen";

    return (
        <>
            <LoadingOverlayContainer>
                {isSaving && <LoadingOverlayComponent />}
                <Grid
                    container
                    spacing={3}
                    direction="row">
                    <Grid item md={6}>
                        <EditorSectionHeaderComponent>{textHeader}</EditorSectionHeaderComponent>
                    </Grid>
                    <Grid item md={6}>
                        <p>{i18next.t("policy.fingers_and_toes.description")}</p>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={3}
                    direction="column">
                    <PolicyEditorComponent
                        name={state.data.policy.name}
                        ignoreOnQuotation={state.data.policy.ignoreOnQuotation}
                        changeName={(value) => dispatch(changeName(value))}
                        toggleIgnoreOnQuotation={(value) => dispatch(toggleIgnoreOnQuotation(value))}
                    />
                    <Grid container item md={12} spacing={3}
                        direction="column">
                        <Grid item>
                            <DropdownComponent name="Zusatzkategorie"
                                data={state.loadedData.additionCategories}
                                minWidth="350px"
                                isRequired={true}
                                onSelect={(e) => dispatch(selectAdditionCategory(e))}
                                displayedProperties={["categoryText"]}
                                selectedValue={state.data.selectedAdditionCategory}
                            />
                        </Grid>
                        <Grid item md={12} marginTop={"20px"}>
                            <SelectionListComponent
                                title={"Zugeordnete Maßpunktgruppen"}
                                entityName={"Maßpunktgruppen"}
                                onFilterSelected={(searchText) => { dispatch(filterSelectedMassPointGroups(searchText)) }}
                                onFilterUnselected={(searchText) => { dispatch(filterUnselectedMassPointGroups(searchText)) }}
                                selectionList={state.data.massPointGroupSelection}
                                selectEntities={(ids) => { dispatch(selectMassPointGroups(ids)) }}
                                unselectEntities={(ids) => { dispatch(unSelectMassPointGroups(ids)) }}
                            />
                        </Grid>
                    </Grid>
                    <EditorButtonComponent
                        canExecute={state.command.savePolicy.canExecute}
                        save={() => dispatch(storePolicy())}
                        cancelSave={() => dispatch(cancelSave())}
                    />
                </Grid>
            </LoadingOverlayContainer>
        </>
    );
};