import { Fragment } from "react"
import { IAttributePreviewViewModel } from "./serial_size_preview.models"
import { Grid } from "@mui/material"
import { EditorMediumSectionHeaderComponent, EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style"
import { OverviewDatagrid } from "shared/components/datagrid/overview_datagrid.component"
import { getGridColumns, sortBySide } from "./serial_size_preview.helper"

export interface AttributePreviewComponentProps {
    attributes: IAttributePreviewViewModel[];
    isLoading: boolean;
}

export const AttributePreviewComponent: React.FC<AttributePreviewComponentProps> = ({
    attributes,
    isLoading
}) => {

    return (
    <>
        <EditorSectionHeaderComponent>Attributeabhängige Einstellungen</EditorSectionHeaderComponent>
        { 
            attributes?.map((attribute, index) => {
                return <Fragment key={index}>
                    <Grid container
                        spacing={3}
                        direction={"column"}>
                        <Grid item>
                            <EditorMediumSectionHeaderComponent style={{ marginBottom : "15px"}}>{`Attribute Kategorie: ${attribute.displayCategory}`}</EditorMediumSectionHeaderComponent>
                            <EditorMediumSectionHeaderComponent style={{ marginBottom : "15px"}}>{`Attribute: ${attribute.displayName}`}</EditorMediumSectionHeaderComponent>
                        </Grid>
                        <Grid item>
                            <OverviewDatagrid
                                isLoading={isLoading}
                                columns={getGridColumns()}
                                rows={attribute.masspoints}
                                loadedRowsCount={attribute.masspoints.length}
                                sortModel={sortBySide}
                                onFilterModelChange={() => {}}
                            />
                        </Grid>
                    </Grid>
                </Fragment>
            })
        }
    </>)
}