import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/redux_store';
import CharacteristicTypeServiceAPI from '../../../../services/characteristic_type.service';
import { handleResponseErrors } from '../../../../shared/networking/error_handling';
import { ICharacteristicTypeOverview } from 'models/characteristic_types/characteristic_type_overview';
import CommonHttpError from 'shared/networking/common_http_error';

export const fetchCharacteristicTypes = createAsyncThunk(
    'characteristic_types/characteristic_types_overview/fetch',
    async (_) => {
        const response = await CharacteristicTypeServiceAPI.getCharacteristicTypes();
        handleResponseErrors(response, "Ausprägungstypen");
        return response;
    }
)

export const confirmDelete = createAsyncThunk(
    'characteristic_types/characteristic_types_overview/confirmDelete',
    async ( _, { getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const activeId = state.characteristic_types_overview.actualData.characteristicType.id

        const deleteResponse = await CharacteristicTypeServiceAPI.deleteCharacteristicType(activeId);
        try {
            handleResponseErrors(deleteResponse, "Ausprägungstyp");
        }catch(error) {
            const commonError = error as CommonHttpError;
            if(commonError && commonError.error) {
                return rejectWithValue(commonError.error);
            }
            throw error;
        }
    
        const responsePolicies = await CharacteristicTypeServiceAPI.getCharacteristicTypes();
        handleResponseErrors(responsePolicies, "Ausprägungstypen");
        return responsePolicies;
    }
)

export const createNewCharacteristicType = createAsyncThunk(
    'characteristic_types/characteristic_types_overview/createNew',
    async (_) => { }
)

export const updateCharacteristicType = createAsyncThunk(
    'characteristic_types/characteristic_types_overview/update',
    async (characteristic_type: ICharacteristicTypeOverview) => { return characteristic_type }
)
