import { useAppDispatch, useAppSelector } from '../../../app/hook';
import { AppDispatch } from '../../../app/redux_store';
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { MassimageUploadComponent } from "../../../shared/components/upload/massimage_upload.component";
import * as style from "./massimage_overview.style";
import { deleteMassimage, resetState, updateFiles, updateName, confirmDeleteMassimageCompleted, setSearchFilter, setGridFilters } from "./redux/massimages_overview.slice";
import { fetchMassimages, confirmDeleteMassimage, uploadMassimage, uploadConfigFile } from "./redux/massimages_overview.thunks";
import { LoadingIndicatorComponent } from '../../../shared/components/loading_indicator/loading_indicator.component';
import IMassimageOverview from "models/massimages/massimage_overview";
import { useDialog } from "shared/contexts/dialog_context";
import { IParsedMassPoint } from "models/masspoints/parsed_masspoint";
import { MissingMasspointsComponent } from "../../../shared/components/dialogs/missing_masspoints/missing_masspoints.component";
import { LoadingOverlayComponent, LoadingOverlayContainer } from '../../../shared/components/loading_overlay/loading_overlay.component';
import { useNavigate } from "react-router-dom";
import { OverviewDatagrid } from 'shared/components/datagrid/overview_datagrid.component';
import SearchFieldComponent from 'shared/components/search/searchfield.component';
import { FlexBox, Left } from 'shared/shared';
import { getGridColumns, sortByNameModel } from './massimage_overview.helper';

export const MassimageOverviewComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.massimage_overviews);
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(fetchMassimages());
        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    const dialog = useDialog();
    const { enqueueSnackbar } = useSnackbar();

    const massPointsNotFoundDialog = (notExistingMassPoints: IParsedMassPoint[]) => {
        dialog.show(
            "Alle Maßpunkte müssen registriert sein",
            <MissingMasspointsComponent masspoints={notExistingMassPoints} masspointsNotInNewImage={[]} />,
            [{
                title: "Ok",
                handleOption: () => { }
            }]);
    }
    if (state.query.fetchMassimages.status === "error") {
        return <div>Fehler beim Laden der Daten</div>;
    }

    if (state.query.fetchMassimages.status !== "success") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.confirmDeleteMassimage.status === "error") {
        enqueueSnackbar(state.command.confirmDeleteMassimage.message, { variant: "warning" });
        dispatch(confirmDeleteMassimageCompleted());
    }

    if (state.command.uploadPicture.status === "error") {
        if (state.uploadError.isUnprocessableEntityException) {
            massPointsNotFoundDialog(state.uploadError.uploadErrorData.notExistingMassPoints);
        }
        else {
            enqueueSnackbar(state.command.uploadPicture.message, { variant: "error" });
        }
        dispatch(resetState());
        dispatch(fetchMassimages());
    }

    if (state.command.uploadPicture.status === "success") {
        dispatch(resetState());
        dispatch(fetchMassimages());
    }

    if (state.command.uploadConfigFile.status === "success") {
        const selectedMassImage = state.actualData.selectedMassimage;
        if (selectedMassImage.hasConfigurationSetting) {
            navigate(`/admin/massimages/${selectedMassImage.id}/config/edit`);
        }
        else {
            navigate(`/admin/massimages/${selectedMassImage.id}/config/create`);
        }
    }

    const handleNavigateToReplace = (massimage: IMassimageOverview) => {
        navigate(`/admin/massimages/${massimage.id}/update`);
    };

    const handleDelete = (massimage: IMassimageOverview) => {
        dispatch(deleteMassimage(massimage));
        dispatch(confirmDeleteMassimage());
    };

    const handleSelectFile = (file: File) => {
        dispatch(updateFiles(file));
    }

    const handleUpload = () => {
        dispatch(uploadMassimage());
    }

    const isLoading = state.command.confirmDeleteMassimage.status === "pending"
        || state.command.uploadPicture.status === "pending";

    var isError = state.command.uploadPicture.status === "error";

    const massimagesAvailable = state.loadedData.massimages.length > 0;

    return (
        <>
            <LoadingOverlayContainer>
                {isLoading ? <LoadingOverlayComponent /> : <></>}

                <MassimageUploadComponent
                    onFileSelected={(e) => handleSelectFile(e.target.files[0])}
                    onUpload={() => handleUpload()}
                    onNameChange={(name) => dispatch(updateName(name))}
                    name={state.actualData.selectedMassimage.name}
                    isError={isError}
                    isUploading={state.actualData.isUpdating}
                    isUploadAllowed={state.command.uploadPicture.canExecute}
                />
                <style.OverviewArea>

                    <FlexBox>
                        {
                            massimagesAvailable &&
                            <Left>
                                <SearchFieldComponent onSearch={(filterText) => dispatch(setSearchFilter(filterText))} placeholder="In Maßbildern suchen ..." />
                            </Left>
                        }
                    </FlexBox>
                    <div style={{ height: "20px" }}></div>
                    {
                        !massimagesAvailable &&
                        <div>Keine Maßbilder verfügbar</div>
                    }
                    {
                        massimagesAvailable &&
                        <OverviewDatagrid
                            isLoading={isLoading}
                            columns={getGridColumns(
                                (massimage) => handleNavigateToReplace(massimage),
                                (massimage) => handleDelete(massimage),
                                (massimage) => dispatch(uploadConfigFile(massimage))
                            )}
                            rows={state.actualData.massimages}
                            loadedRowsCount={state.loadedData.massimages.length}
                            sortModel={sortByNameModel}
                            onFilterModelChange={(newFilterModel) => dispatch(setGridFilters(newFilterModel))}
                        />
                    }
                </style.OverviewArea>
            </LoadingOverlayContainer>
        </>
    );
}