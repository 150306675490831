import { FootOptionCreateModel } from "../models/footoptions/footoption_create";
import { IFootOptionOverview } from '../models/footoptions/footoption_overview';
import { IFootOptionEditModel, IUpdateFootOptionModel } from '../models/footoptions/footoption_edit';
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from "./config.service";
import IAvailableFootType from "models/available_basedata/available_foot_type";
import HttpServiceCommonResult from "shared/networking/http_service_common_result";

class FootOptionServiceAPI extends HttpServiceCommonResult {
  public async getFootOptions(): Promise<ApiResult<Array<IFootOptionOverview>>> {
    return this.get<Array<IFootOptionOverview>>("/api/footoption");
  }

  public async getAvailableFootTypes(): Promise<ApiResult<Array<IAvailableFootType>>> {
    return this.get<Array<IAvailableFootType>>("/api/availablebaseData/foottype");
  }

  public async deleteFootOption(id: number): Promise<ApiResult<void>> {
    return this.delete(`/api/footoption/${id}`);
  }

  public async getFootOption(id: string): Promise<ApiResult<IFootOptionEditModel>> {
    return this.get(`/api/footoption/${id}`);
  }

  public async createFootOption(footOption: FootOptionCreateModel): Promise<ApiResult<void>> {
    return this.post<FootOptionCreateModel,void>("/api/footoption", footOption);
  }

  public async updateFootOption(footOption: IUpdateFootOptionModel): Promise<ApiResult<void>> {
    return this.put<IUpdateFootOptionModel,void>("/api/footoption", footOption);
  }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
export const FootOptionService = new FootOptionServiceAPI(TimeoutMilliseconds);
