import styled from "@emotion/styled";

export const Box = styled.div`
  display: flex;
  align-items: center;
`;

export const IsSideSpecificWrapper = styled.div`
  margin-top: 2em;
  margin-left: 0.5em;
`

export const ButtonMargin = styled.span`
  margin-right: 10px;
`