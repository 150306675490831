import { PolicyEditorComponent } from "check/policy/components/policy_editor.component";
import { LoadingOverlayComponent, LoadingOverlayContainer } from "shared/components/loading_overlay/loading_overlay.component";
import { useAppDispatch, useAppSelector } from '../../../../app/hook';
import { AppDispatch } from '../../../../app/redux_store';
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import {
    resetState,
    changeName,
    addConfiguration,
    deleteConfiguration,
    selectAddition,
    selectMassPointCriterias,
    unselectMassPointCriterias,
    filterSelectedMassPointCriterias,
    filterUnselectedMassPointCriterias,
    selectQuality,
    unselectQuality,
    filterSelectedQualities,
    filterUnselectedQualities,
    selectCompressionClass,
    updateThreshold,
    toggleIgnoreOnQuotation,
    completedSave
} from "./redux/policy_soft_seam_create.slice";
import { LoadingIndicatorComponent } from "shared/shared";
import { cancelSave, initData, savePolicy } from "./redux/policy_soft_seam_create.thunks";
import { Grid } from "@mui/material";
import { EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";
import { useNavigate } from "react-router-dom";
import { PolicySoftSeamEditorComponent } from "../components/policy_soft_seam_editor.component";
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";
import i18next from "i18next";

export const PolicySoftSeamCreateComponent = () => {
    const state = useAppSelector((state) => state.policy_soft_seam_create);
    const dispatch: AppDispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(initData());

        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    if (state.query.initData.status === "pending") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.cancelSave.status === "success") {
        navigate("/plausibilitycheck/policy");
        dispatch(resetState());
    }

    if (state.command.savePolicy.status === "success") {
        enqueueSnackbar("Regelwerk gespeichert", { variant: "success" });
        navigate("/plausibilitycheck/policy");
        dispatch(resetState());
    }

    if (state.command.savePolicy.status === "error") {
        enqueueSnackbar(state.command.savePolicy.message, { variant: "error" });
        dispatch(completedSave());
    }

    if (state.command.savePolicy.status === "warning") {
        enqueueSnackbar(state.command.savePolicy.message, { variant: "warning" });
        dispatch(completedSave());
    }

    const isSaving = state.command.savePolicy.status === "pending";

    return (
        <>
            <LoadingOverlayContainer>
                {isSaving ? <LoadingOverlayComponent /> : <></>}
                <Grid
                    container
                    spacing={3}
                    direction="row">
                    <Grid item md={6}>
                        <EditorSectionHeaderComponent>Neue Kettelnaht erstellen</EditorSectionHeaderComponent>
                    </Grid>
                    <Grid item md={6}>
                        <p>{i18next.t("policy.mandatory_whipped_seam.description")}</p>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={3}
                    direction="column">
                    <PolicyEditorComponent
                        name={state.data.policy.name}
                        ignoreOnQuotation={state.data.policy.ignoreOnQuotation}
                        changeName={(value) => dispatch(changeName(value))}
                        toggleIgnoreOnQuotation={(value) => dispatch(toggleIgnoreOnQuotation(value))}
                    />
                    <PolicySoftSeamEditorComponent
                        selectedAddition={state.data.selectedAddition}
                        allAdditions={state.loadedData.additions}
                        configurations={state.data.configurations}
                        masspointSelection={state.data.massPointCriterias}
                        addConfiguration={() => dispatch(addConfiguration())}
                        deleteConfiguration={(id) => dispatch(deleteConfiguration(id))}
                        select={(addition) => dispatch(selectAddition(addition))}
                        selectMassPointCriterias={(ids) => dispatch(selectMassPointCriterias(ids))}
                        unSelectMassPointCriterias={(ids) => dispatch(unselectMassPointCriterias(ids))}
                        filterSelectedMassPointCriterias={(text) => dispatch(filterSelectedMassPointCriterias(text))}
                        filterUnselectedMassPointCriterias={(text) => dispatch(filterUnselectedMassPointCriterias(text))}
                        selectCompressionClass={(selectCompression) => dispatch(selectCompressionClass(selectCompression))}
                        updateThreshold={(threshold) => dispatch(updateThreshold(threshold))}
                        selectQualities={(qualities) => dispatch(selectQuality(qualities))}
                        unSelectQualities={(qualities) => dispatch(unselectQuality(qualities))}
                        filterSelectedQualities={(text) => dispatch(filterSelectedQualities(text))}
                        filterUnselectedQualities={(text) => dispatch(filterUnselectedQualities(text))}
                    />
                    <EditorButtonComponent
                        canExecute={state.command.savePolicy.canExecute}
                        save={() => dispatch(savePolicy())}
                        cancelSave={() => dispatch(cancelSave())}
                    />
                </Grid>
            </LoadingOverlayContainer >
        </>
    );
};