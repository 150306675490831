import React, { Fragment } from "react";
import { IAttributeConfigurationViewModel, IAttributeViewModel, IMassPointCriteriaSelection, IMassPointValueSelection, ISizeConfigurationViewModel } from "./serial_size_editor.model";
import { BoxItem } from "./serial_sizes.style";
import { AttributeSummaryDetailComponent } from "./serial_size_editor_attribute_summary_detail.component";
import { AttributeEditorComponent } from "./serial_size_editor_attribute_editor.component";
import { IAvailableAdditionAttributeCategory } from "models/addition_attribute_categories/available_addition_attribute_category";
import { IAvailableAdditionAttribute } from "models/addition_attribute_categories/available_addition_attribute";


export interface AttributeAccordionComponentProps {
    attributeSummaries: IAttributeViewModel[];
    configurationAttribute: IAttributeConfigurationViewModel;
    configurationSize: ISizeConfigurationViewModel;
    takeAttribute: () => void;
    cancelAttribute: () => void;
    selectCategory: (category: IAvailableAdditionAttributeCategory) => void;
    selectAttribute: (attribute: IAvailableAdditionAttribute) => void;
    editAttribute: (size: IAttributeViewModel) => void;
    deleteAttribute: (attribute: IAttributeViewModel) => void;
    addAttributeMasspoint: () => void;
    selectAttributeMasspoint: (masspoint: IMassPointCriteriaSelection) => void;
    updateAttributeValueFrom: (masspoint: IMassPointValueSelection) => void;
    updateAttributeValueTo: (masspoint: IMassPointValueSelection) => void;
    deleteAttributeMasspoint: (index: number) => void;
}

export const AttributeAccordionComponent: React.FC<AttributeAccordionComponentProps> = ({
    attributeSummaries,
    configurationAttribute,
    configurationSize,
    editAttribute,
    deleteAttribute,
    selectCategory,
    selectAttribute,
    takeAttribute,
    cancelAttribute,
    addAttributeMasspoint,
    selectAttributeMasspoint,
    updateAttributeValueFrom,
    updateAttributeValueTo,
    deleteAttributeMasspoint
}) => {

    const disabledAttribute = configurationAttribute != undefined || configurationSize != undefined;

    return (
        <>
            {
                attributeSummaries?.map((attributeSummary, index) => {

                    const showDetails = configurationAttribute != undefined
                        && !configurationAttribute.isNewAttribute
                        && configurationAttribute.oldAttribute.id === attributeSummary.attribute.id;

                    return <Fragment key={index}>
                        <>
                            <AttributeSummaryDetailComponent
                                attributeSummary={attributeSummary}
                                disabledAttribute={disabledAttribute}
                                editAttribute={editAttribute}
                                deleteAttribute={deleteAttribute}
                            />
                            {showDetails && <>
                                <BoxItem marginTop={1} marginBottom={1}>
                                    <AttributeEditorComponent
                                        configurationAttribute={configurationAttribute}
                                        selectCategory={selectCategory}
                                        selectAttribute={selectAttribute}
                                        takeAttribute={takeAttribute}
                                        cancelAttribute={cancelAttribute}
                                        addAttributeMasspoint={addAttributeMasspoint}
                                        selectAttributeMasspoint={selectAttributeMasspoint}
                                        updateAttributeValueFrom={updateAttributeValueFrom}
                                        updateAttributeValueTo={updateAttributeValueTo}
                                        deleteAttributeMasspoint={deleteAttributeMasspoint}
                                    />
                                </BoxItem>
                            </>}
                        </>
                    </Fragment>
                })
            }
        </>
    );
}