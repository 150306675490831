import { createAsyncThunk } from '@reduxjs/toolkit';
import AdditionHistoryServiceApi from "../../../services/addition_history.service";
import { handleResponseErrors } from '../../../shared/networking/error_handling';

export const fetchAdditionHistoryOverviews = createAsyncThunk(
    'addition_history/addition_history_overview/fetchAdditionHistoryOverviews',
    async (thunkApi) => {
        const response = await AdditionHistoryServiceApi.getAdditionsHistoryOverview();
        handleResponseErrors(response, "Zusatzhistorie");
        return response;
    }
)