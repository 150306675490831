import { Grid } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { useNavigate } from "react-router-dom";
import { FlexBox, Left, LoadingIndicatorComponent, PrimaryButtonComponent, Right } from "shared/shared";
import { createBodyInformation, getBodyInformations, updateBodyInformation } from "./redux/bodyinformation_overview.thunks";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from "react";
import { deleteBodyInformation, resetDeleteBodyInformation, resetState, setGridFilters, setSearchFilter } from "./redux/bodyinformation_overview.slice";
import { OverviewDatagrid } from "shared/components/datagrid/overview_datagrid.component";
import { getGridColumns, sortModel } from "./bodyinformation_overview.helper";
import SearchFieldComponent from "shared/components/search/searchfield.component";
import { enqueueSnackbar } from "notistack";
import { BodyInformationDeleteDialogComponent } from "../components/bodyinformation_delete.dialog.component";
export const BodyInformationOverviewComponent = () => {
    const state = useAppSelector((state) => state.body_information_overview);
    const navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getBodyInformations());

        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    if (state.query.getBodyInformations.status === "error") {
        enqueueSnackbar(state.query.getBodyInformations.message, { variant: "error" });
    }

    if (state.query.getBodyInformations.status !== "success") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.createBodyInformation.status === "pending") {
        navigate("/massmasks/bodyInformations/create");
    }

    if (state.command.updateBodyInformation.status === "success") {
        navigate(`/massmasks/bodyInformations/edit/${state.actualData.activeBodyInformation.id}`);
    }

    if (state.command.confirmDeleteBodyInformation.status === "error") {
        enqueueSnackbar(state.command.confirmDeleteBodyInformation.message, { variant: "error" });
        dispatch(resetDeleteBodyInformation());
    }

    if (state.command.confirmDeleteBodyInformation.status === "success") {
        enqueueSnackbar("Zusätzliche Angabe erfolgreich gelöscht", { variant: "success" });
        dispatch(resetDeleteBodyInformation());
    }

    const bodyInformationsAvailable = state.actualData.bodyInformations?.length > 0;

    return (
        <>
            {
                state.actualData.showDeleteDialog
                && <BodyInformationDeleteDialogComponent />
            }
            <Grid container direction={"column"}
                spacing={3}>
                <Grid item>
                    <FlexBox>
                        <Left>
                            <SearchFieldComponent onSearch={(filterText) => dispatch(setSearchFilter(filterText))}
                                placeholder="In zusätzlichen Angaben suchen ..." />
                        </Left>
                        <Right>
                            <PrimaryButtonComponent
                                onClick={() => dispatch(createBodyInformation())}>
                                <AddIcon /> Neue zusätzliche Angabe
                            </PrimaryButtonComponent>
                        </Right>
                    </FlexBox>
                </Grid>
                <Grid item>
                    <>
                        {
                            !bodyInformationsAvailable &&
                            <div>Keine Angaben verfügbar</div>
                        }
                        {
                            bodyInformationsAvailable &&
                            <OverviewDatagrid
                                isLoading={false}
                                columns={
                                    getGridColumns(
                                        (bodyInformation) => dispatch(updateBodyInformation(bodyInformation)),
                                        (bodyInformation) => dispatch(deleteBodyInformation(bodyInformation))
                                    )
                                }
                                rows={state.actualData.bodyInformations}
                                loadedRowsCount={state.loadedData.bodyInformations.length}
                                sortModel={sortModel}
                                onFilterModelChange={(newFilterModel) => dispatch(setGridFilters(newFilterModel))}
                                getRowId={(row) => row.id}
                                onDoubleClickRow={(bodyInformation) => dispatch(updateBodyInformation(bodyInformation))}
                            />
                        }
                    </>
                </Grid>
            </Grid>
        </>
    );
}