import { Typography } from '@mui/material';
import { getConfig } from "services/config.service";
import { LinkComponent } from 'shared/components/typography/link.component';

const generateEHubLink = () => {
    const c = getConfig().ehub;
    return `${c.baseUrl}`  
}

export const EhubLinkComponent = () => {
    return (
        <Typography>
            Fußarten sind eine Zusammenfassung von verschiedenen Füßen (Infor LN), welche die gleichen Maßpunkt-Definitionen haben. Die Fußarten und die Referenz zum ERP-Fuß werden im <LinkComponent href={generateEHubLink()} rel="noreferrer" target="_blank">medi eHub</LinkComponent> verwaltet.
        </Typography>);
}