import React from "react";
import AddIcon from '@mui/icons-material/Add';
import { FlexBox, Left, LoadingIndicatorComponent, PrimaryButtonComponent, Right } from "shared/shared";
import * as style from "./type_overview.style";
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { AppDispatch } from '../../app/redux_store';
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { fetchPlausibilityCheckTypes, confirmDeletePlausibilityCheckType } from './redux/type_overview.thunks';
import { confirmDeletePlausibilityCheckCompleted, cancelDeletePlausibilityCheckType, deletePlausibilityCheckType, setSearchFilter, setGridFilters, resetState } from "./redux/type_overview.slice";
import { useNavigate } from "react-router-dom";
import { DialogComponent } from '../../shared/components/dialogs/dialog.component';
import SearchFieldComponent from "shared/components/search/searchfield.component";
import { getGridColumns, sortByNameModel } from "./type_overview.helper";
import { OverviewDatagrid } from "shared/components/datagrid/overview_datagrid.component";

export const TypeOverviewComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.plausibilitychecktype_get);

    useEffect(() => {
        dispatch(fetchPlausibilityCheckTypes());
        return () => {
            dispatch(resetState());
          }
    }, [dispatch]);

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    if (state.command.confirmDeletePlausibilityCheckType.status === "error") {
        enqueueSnackbar(state.command.confirmDeletePlausibilityCheckType.message, { variant: "warning" });
        dispatch(confirmDeletePlausibilityCheckCompleted());
    }

    if (state.command.confirmDeletePlausibilityCheckType.status === "success") {
        enqueueSnackbar("Prüfungstyp entfernt", { variant: "success" });
        dispatch(confirmDeletePlausibilityCheckCompleted())
    }

    if (state.query.fetchPlausibilityCheckType.status === "error") {
        return <div>Fehler beim Laden der Daten</div>;
    }

    if (state.query.fetchPlausibilityCheckType.status !== "success") {
        return <LoadingIndicatorComponent />;
    }

    const checkTypesAvailable = state.loadedData.plausibilityCheckTypes.length > 0;
    const isLoading = state.command.confirmDeletePlausibilityCheckType.status === "pending";

    return (
        <div>
            {
                state.actualData.showDeletePlausibilityCheckDialogDialog &&
                <DialogComponent
                    title={"Prüfungstyp löschen?"}
                    isOpen={true}
                    options={[
                        { title: "Abbrechen", handleOption: () => { dispatch(cancelDeletePlausibilityCheckType()) } },
                        { title: "Löschen", handleOption: () => { dispatch(confirmDeletePlausibilityCheckType()) } }
                    ]}
                    onClose={() => { }}>
                    <div>Möchten Sie den Prüfungstyp wirklich löschen?</div>
                </DialogComponent>
            }
            <FlexBox>
                {
                    checkTypesAvailable &&
                    <Left>
                        <SearchFieldComponent onSearch={(filterText) => dispatch(setSearchFilter(filterText))} placeholder="In Prüfungstypen suchen ..." />
                    </Left>
                }
                <Right>
                    <PrimaryButtonComponent onClick={() => navigate("/plausibilitycheck/type/create")}>
                        <AddIcon /> Neuen Prüfungstyp erstellen
                    </PrimaryButtonComponent>
                </Right>
            </FlexBox>
            <style.TypeOverviewArea>
                {
                    !checkTypesAvailable &&
                    <div>Keine Prüfungen verfügbar</div>
                }
                {
                    checkTypesAvailable &&
                    <OverviewDatagrid
                        isLoading={isLoading}
                        columns={getGridColumns(
                            (plausibilityCheck) => navigate(`/plausibilitycheck/type/${plausibilityCheck.id}/edit`),
                            (plausibilityCheck) => dispatch(deletePlausibilityCheckType(plausibilityCheck))
                        )}
                        rows={state.actualData.plausibilityCheckTypes}
                        loadedRowsCount={state.loadedData.plausibilityCheckTypes.length}
                        sortModel={sortByNameModel}
                        onFilterModelChange={(newFilterModel) => dispatch(setGridFilters(newFilterModel))}
                        onDoubleClickRow={(plausibilityCheck) => navigate(`/plausibilitycheck/type/${plausibilityCheck.id}/edit`)}
                    />
                }
            </style.TypeOverviewArea>
        </div>
    );
};
