import { isNullOrWhitespace } from "services/validation.service";
import { CharacteristicTypeCreateState } from "./characteristic_type_create.model";
import { ICharacteristicValueViewModel } from 'additions/characteristic_type/components/characteristic_type_editor.model';

export const updateCanSave = (state: CharacteristicTypeCreateState) => {
    state.command.saveCharacteristicType.canExecute =
        !isNullOrWhitespace(state.data.characteristicType.name)
        && !isNullOrWhitespace(state.data.characteristicType.shortKey)
        && valuesValide(state.data.characteristicType.values);
}

const valuesValide = (values: ICharacteristicValueViewModel[]) => {
    let valuesValide = true;
    values.forEach(x => {
        const valueValide = !isNullOrWhitespace(x.name)
            && !isNullOrWhitespace(x.translationKey)
            && !(values.filter(y => y.name === x.name).length > 1);

        if(!valueValide){
            valuesValide = valueValide;
        }
    });

    return values.length > 0 && valuesValide;
}