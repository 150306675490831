import { useAppDispatch, useAppSelector } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { DialogComponent } from "shared/components/dialogs/dialog.component";
import { cancelDeleteArticleTypeConfig, cancelDismissChanges, confirmDeleteArticleTypeConfig, confirmDismissChanges } from "../position_category/redux/position_category.slice";

export const PositionCategoryItemDialogsComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.position_category);

    return (<>
            {
                state.showDeleteArticleTypeConfigDialog &&
                <DialogComponent
                    title={"Konfiguration löschen?"}
                    isOpen={true}
                    options={[
                        {
                            title: "Abbrechen", handleOption: () => {
                                dispatch(cancelDeleteArticleTypeConfig())
                            }
                        },
                        {
                            title: "Löschen", handleOption: () => {
                                dispatch(confirmDeleteArticleTypeConfig())
                            }
                        }
                    ]}
                    onClose={() => dispatch(cancelDeleteArticleTypeConfig())}>

                </DialogComponent>
            }

            {
                state.showDismissArticleTypeConfigDialog &&
                <DialogComponent
                    title={"Änderungen verwerfen?"}
                    isOpen={true}
                    options={[
                        {
                            title: "Ja", handleOption: () => dispatch(confirmDismissChanges())
                        },
                        {
                            title: "Nein", handleOption: () => dispatch(cancelDismissChanges())
                        }
                    ]}
                    onClose={() => dispatch(cancelDismissChanges())}>
                </DialogComponent>
            }
    </>)
};