import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GridFilterModel } from "@mui/x-data-grid-pro";
import { CharcteristicCategoryOverviewState } from './characteristic_category_overview.model';
import { ICharacteristicCategoryOverview } from 'models/characteristic_categories/characteristic_category_overview';
import { filterCharacteristicCategories } from './characteristic_category_overview.reducer';
import { 
    createNewCharacteristicCategory,
    fetchCharacteristicCategories, 
    updateCharacteristicCategory,
    confirmDelete
} from './characteristic_category_overview.thunks';

const initialState: CharcteristicCategoryOverviewState = {
    loadedData: {
        characteristicCategories: []
    },
    actualData: { 
        characteristicCategories: [],
        searchFilter: "",
        gridFilters: {items: []},
        characteristicCategory: null,
        showDeleteCharacteristicCategoryDialog: false
    },
    command:{
        confirmDeleteCharacteristicCategory: { status: "idle", canExecute: false }, 
        createNewCharacteristicCategory: { status: "idle", canExecute: false },
        updateCharacteristicCategory: { status: "idle", canExecute: false },
    },
    query: {
        fetchCharacteristicCategories: { status: "idle", canExecute: true }
    }
}

export const getCharacteristicCategoriesSlice = createSlice({
    name: 'get_characteristic_categories_overview',
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.actualData.searchFilter = action.payload;
            filterCharacteristicCategories(state);
        },
        setGridFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.gridFilters = action.payload;
            filterCharacteristicCategories(state);
        }, 
        cancelDelete: (state) => {
            state.actualData.characteristicCategory = null;
            state.actualData.showDeleteCharacteristicCategoryDialog = false;
        },      
        deleteCompleted: (state) => {
            state.command.confirmDeleteCharacteristicCategory = initialState.command.confirmDeleteCharacteristicCategory;
        },  
        updateCompleted: (state) => {
            state.actualData.characteristicCategory = null;;
        },  
        deleteCharacteristicCategory: (state, action: PayloadAction<ICharacteristicCategoryOverview>) => {
            state.actualData.showDeleteCharacteristicCategoryDialog = true;
            state.actualData.characteristicCategory = action.payload;
        },
    }, extraReducers: (builder) => {

        // fetchCharacteristicCategories
        builder.addCase(fetchCharacteristicCategories.pending, (state) => {
            state.query.fetchCharacteristicCategories.status = "pending"
            state.query.fetchCharacteristicCategories.canExecute = false;
        }).addCase(fetchCharacteristicCategories.rejected, (state, action) => {
            state.query.fetchCharacteristicCategories.status = "error"
            state.query.fetchCharacteristicCategories.message = action.error.message;
            state.query.fetchCharacteristicCategories.canExecute = true;
        }).addCase(fetchCharacteristicCategories.fulfilled, (state, action) => {
            state.query.fetchCharacteristicCategories.status = "success"
            state.query.fetchCharacteristicCategories.canExecute = true;
            state.loadedData.characteristicCategories = action.payload.getData();
            filterCharacteristicCategories(state);
   
            // createNewCharacteristicCategory
        }).addCase(createNewCharacteristicCategory.pending, (state, action) => {
            state.command.createNewCharacteristicCategory.status = "pending"
        }).addCase(createNewCharacteristicCategory.fulfilled, (state, action) => {
            state.command.createNewCharacteristicCategory.status = "success"   
  
            // updateCharacteristicCategory
        }).addCase(updateCharacteristicCategory.pending, (state) => {
            state.command.updateCharacteristicCategory.status = "pending"
        }).addCase(updateCharacteristicCategory.fulfilled, (state, action) => {
            state.command.updateCharacteristicCategory.status = "success"     
            state.actualData.characteristicCategory = action.payload;       

            // confirmDelete 
        }).addCase(confirmDelete.pending, (state) => {
            state.command.confirmDeleteCharacteristicCategory.status = 'pending'
        }).addCase(confirmDelete.rejected, (state, action) => {
            state.command.confirmDeleteCharacteristicCategory.status = "error"
            state.command.confirmDeleteCharacteristicCategory.message = action.error.message;
            state.actualData.showDeleteCharacteristicCategoryDialog = false;
        }).addCase(confirmDelete.fulfilled, (state, action) => {
            state.command.confirmDeleteCharacteristicCategory.status = "success"
            state.command.confirmDeleteCharacteristicCategory.canExecute = true;
            state.actualData.showDeleteCharacteristicCategoryDialog = false;
            state.loadedData.characteristicCategories = action.payload.getData();
            filterCharacteristicCategories(state);
           })
    }
})

export const {
    resetState,
    setSearchFilter,
    setGridFilters,
    deleteCompleted,
    updateCompleted,
    cancelDelete,
    deleteCharacteristicCategory
} = getCharacteristicCategoriesSlice.actions

export default getCharacteristicCategoriesSlice.reducer