import HttpServiceCommonResult from "shared/networking/http_service_common_result";
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from "./config.service";
import { IAdditionCategoryCreate } from 'models/addition_categories/addition_category_create';
import { IAdditionCategoryOverview } from 'models/addition_categories/addition_category_overview';
import { PolicyTypeEnum } from "models/policies/policy_type.enum";

class AdditionCategoryServiceAPI extends HttpServiceCommonResult {
  public async getAdditionCategoryOverview(): Promise<ApiResult<Array<IAdditionCategoryOverview>>> {
      return this.get<Array<IAdditionCategoryOverview>>("/api/additioncategory");
  }

  public async getAvailableAdditionCategoryByPolicyTypeOverview(policyType: PolicyTypeEnum, ignoredPolicy?: string ): Promise<ApiResult<Array<IAdditionCategoryOverview>>> {
    let url = `/api/additioncategory/availablecreation/${policyType}`;
    if(ignoredPolicy)
    {
      url = url + `?ignoredPolicy=${ignoredPolicy}`; 
    }
    
    return this.get<Array<IAdditionCategoryOverview>>(url);
  }

  public async deleteAdditionCategory(additionid: number): Promise<ApiResult<void>> {
      return this.delete(`/api/additioncategory/${additionid}`);
  }

  public async createAdditionCategory(additionCategoryKey: IAdditionCategoryCreate): Promise<ApiResult<void>> {
      return this.post<IAdditionCategoryCreate,void>("/api/additioncategory", additionCategoryKey);
  
  }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const AdditionCategoryService = new AdditionCategoryServiceAPI(TimeoutMilliseconds);

export default AdditionCategoryService;