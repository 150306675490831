import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IAvailableFootType from '../../../models/available_basedata/available_foot_type';
import IAvailableProductLine from '../../../models/available_basedata/available_product_line';
import { FootOptionsCreateState } from './footoptions.model';
import { applyFilter, canExecuteCreateFootOption, distinctMassPointCriteria, moveFromAvailableToSelected, moveFromSelectedToAvailable, updateCanExecuteCreate } from './footoptions.reducer';
import { cancelSave, createFootOption, fetchAllData, getFootTypes, getMainProductLines, getMassPoints } from './footoptions.thunks';

const initialState: FootOptionsCreateState = {
    actualData: {
        productLines: [],
        selectedProductLine: null,
        footTypes: [],
        selectedFootType: null,
        massPointCriteria: {
            selected: {
                searchText: "",
                allItems: [],
                filteredItems: [],
            },
            available: {
                searchText: "",
                allItems: [],
                filteredItems: [],
            },
            loaded: {
                allItems: [],
            }
        }
    },
    query: {
        fetchAllData: { status: "idle", canExecute: true },
        getProductLines: { status: "idle", canExecute: true },
        getFootTypes: { status: "idle", canExecute: true },
        getMassPoints: { status: "idle", canExecute: true },
    },
    command: {
        createFootOption: { status: "idle", canExecute: false },
        cancel: { status: "idle", canExecute: false },
    }
}

export const footOptionCreateSlice = createSlice({
    name: 'massMasks/footOption/create',
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        resetCreateFootOption: (state) => {
            state.command.createFootOption = {
                ...initialState.command.createFootOption,
                canExecute: canExecuteCreateFootOption(state)
            };
        },
        selectFootType: (state, action: PayloadAction<IAvailableFootType>) => {
            state.actualData.selectedFootType = action.payload;
            updateCanExecuteCreate(state);
        },
        selectProductLine: (state, action: PayloadAction<IAvailableProductLine>) => {
            state.actualData.selectedProductLine = action.payload;
            updateCanExecuteCreate(state);
        },
        selectMassPointCriteria: (state, action: PayloadAction<number>) => {
            moveFromAvailableToSelected(state, action.payload);
            applyFilter(state.actualData.massPointCriteria.selected);
            updateCanExecuteCreate(state);
        },
        deselectMassPointCriteria: (state, action: PayloadAction<number>) => {
            moveFromSelectedToAvailable(state, action.payload);
            applyFilter(state.actualData.massPointCriteria.available);
            updateCanExecuteCreate(state);
        },
        filterSelectedMassPointCriteria: (state, action: PayloadAction<string>) => {
            state.actualData.massPointCriteria.selected.searchText = action.payload;
            applyFilter(state.actualData.massPointCriteria.selected);
        },
        filterAvailableMassPointCriteria: (state, action: PayloadAction<string>) => {
            state.actualData.massPointCriteria.available.searchText = action.payload;
            applyFilter(state.actualData.massPointCriteria.available);
        },
    }, extraReducers: (builder) => {
        // fetchAllData 
        builder.addCase(fetchAllData.pending, (state) => {
            state.query.fetchAllData.status = 'pending'
        }).addCase(fetchAllData.rejected, (state, action) => {
            state.query.fetchAllData.status = "error"
            state.query.fetchAllData.message = action.error.message;
        }).addCase(fetchAllData.fulfilled, (state) => {
            state.query.fetchAllData.status = "success"
            state.command.createFootOption.canExecute = canExecuteCreateFootOption(state);

            // getMainProductLines
        }).addCase(getMainProductLines.pending, (state) => {
            state.query.getProductLines.status = 'pending'
        }).addCase(getMainProductLines.rejected, (state, action) => {
            state.query.getProductLines.status = "error"
            state.query.getProductLines.message = action.error.message;
        }).addCase(getMainProductLines.fulfilled, (state, action) => {
            state.query.getProductLines.status = "success"
            state.query.getProductLines.canExecute = true;
            state.actualData.productLines = action.payload.getData();

            // getMassPoints
        }).addCase(getMassPoints.pending, (state) => {
            state.query.getMassPoints.status = 'pending'
        }).addCase(getMassPoints.rejected, (state, action) => {
            state.query.getMassPoints.status = "error"
            state.query.getMassPoints.message = action.error.message;
        }).addCase(getMassPoints.fulfilled, (state, action) => {
            state.query.getMassPoints.status = "success"
            state.query.getMassPoints.canExecute = true;
            const massPoints = action.payload.getData();

            const massPointCriteria = massPoints.map(mp => {
                return {
                    massPointName: mp.name,
                    massPointType: mp.massPointType,
                    bodyArea: mp.bodyArea
                }
            }).filter(distinctMassPointCriteria);

            state.actualData.massPointCriteria.available.allItems = massPointCriteria;
            state.actualData.massPointCriteria.loaded.allItems = massPointCriteria;
            applyFilter(state.actualData.massPointCriteria.available)

            // getFootTypes
        }).addCase(getFootTypes.pending, (state, action) => {
            state.query.getFootTypes.status = 'pending'
        }).addCase(getFootTypes.rejected, (state, action) => {
            state.query.getFootTypes.status = "error"
            state.query.getFootTypes.message = action.error.message;
        }).addCase(getFootTypes.fulfilled, (state, action) => {
            state.query.getFootTypes.status = "success"
            state.query.getFootTypes.canExecute = true;
            state.actualData.footTypes = action.payload.getData();

            // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancel.status = 'pending'
            state.command.cancel.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancel.status = "success"
            state.command.cancel.canExecute = false;

            // createMassMask 
        }).addCase(createFootOption.pending, (state, action) => {
            state.command.createFootOption.status = 'pending'
            state.command.createFootOption.canExecute = false;
        }).addCase(createFootOption.rejected, (state, action) => {
            state.command.createFootOption.status = "error"
            state.command.createFootOption.canExecute = true;
            state.command.createFootOption.message = action.error.message;
        }).addCase(createFootOption.fulfilled, (state, action) => {
            state.command.createFootOption.status = "success"
            state.command.createFootOption.canExecute = true;
        })
    }
})

export const {
    resetState,
    resetCreateFootOption,
    selectFootType,
    selectProductLine,
    deselectMassPointCriteria,
    selectMassPointCriteria,
    filterSelectedMassPointCriteria,
    filterAvailableMassPointCriteria,
} = footOptionCreateSlice.actions

export default footOptionCreateSlice.reducer