import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IAvailableFootType from '../../../models/available_basedata/available_foot_type';
import IAvailableProductLine from '../../../models/available_basedata/available_product_line';
import { FootOptionsEditState } from './footoption_edit.model';
import { applyFilter, canExecuteEditFootOption, distinctMassPointCriteria, moveFromAvailableToSelected, moveFromSelectedToAvailable, updateCanExecuteEdit, removeAlreadySelectedFootmassPoints } from './footoption_edit.reducer';
import { fetchAllData, getFootTypes, getMainProductLines, getMassPoints, getFootoption, updateFootOption, cancelSave } from './footoption_edit.thunks';

const initialState: FootOptionsEditState = {
    loadedData: {
        productLines: [],
        footTypes: [],
        massPointCriteria: {
            selected: {
                searchText: "",
                allItems: [],
                filteredItems: [],
            },
            available: {
                searchText: "",
                allItems: [],
                filteredItems: [],
            },
            loaded: {
                allItems: [],
            }
        }
    }, 
    actualData: {
        footOptionId: null,
        selectedProductLine: null,
        selectedFootType: null,
        massPointCriteria: {
            selected: {
                searchText: "",
                allItems: [],
                filteredItems: [],
            },
            available: {
                searchText: "",
                allItems: [],
                filteredItems: [],
            },
            loaded: {
                allItems: [],
            }
        }
    },
    query: {
        fetchAllData: { status: "idle", canExecute: true },
        getProductLines: { status: "idle", canExecute: true },
        getFootTypes: { status: "idle", canExecute: true },
        getMassPoints: { status: "idle", canExecute: true },
        getFootOption: {status: "idle", canExecute: true },
    },
    command: {
        editFootOption: { status: "idle", canExecute: false },
        cancel: { status: "idle", canExecute: false },
    }
}

export const footOptionEditSlice = createSlice({
    name: 'massMasks/footOption/edit',
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        resetEditFootOption: (state) => {
            state.command.editFootOption = {
                ...initialState.command.editFootOption,
                canExecute: canExecuteEditFootOption(state)
            };
        },
        selectFootType: (state, action: PayloadAction<IAvailableFootType>) => {
            state.actualData.selectedFootType = action.payload;
            updateCanExecuteEdit(state);
        },
        selectProductLine: (state, action: PayloadAction<IAvailableProductLine>) => {
            state.actualData.selectedProductLine = action.payload;
            updateCanExecuteEdit(state);
        },
        selectMassPointCriteria: (state, action: PayloadAction<number>) => {
            moveFromAvailableToSelected(state, action.payload);
            applyFilter(state.actualData.massPointCriteria.selected);
            updateCanExecuteEdit(state);
        },
        deselectMassPointCriteria: (state, action: PayloadAction<number>) => {
            moveFromSelectedToAvailable(state, action.payload);
            applyFilter(state.actualData.massPointCriteria.available);
            updateCanExecuteEdit(state);
        },
        filterSelectedMassPointCriteria: (state, action: PayloadAction<string>) => {
            state.actualData.massPointCriteria.selected.searchText = action.payload;
            applyFilter(state.actualData.massPointCriteria.selected);
        },
        filterAvailableMassPointCriteria: (state, action: PayloadAction<string>) => {
            state.actualData.massPointCriteria.available.searchText = action.payload;
            applyFilter(state.actualData.massPointCriteria.available);
        },
    }, extraReducers: (builder) => {
        // fetchAllData 
        builder.addCase(fetchAllData.pending, (state, action) => {
            state.query.fetchAllData.status = 'pending';
        }).addCase(fetchAllData.rejected, (state, action) => {
            state.query.fetchAllData.status = "error";
            state.query.fetchAllData.message = action.error.message;
        }).addCase(fetchAllData.fulfilled, (state, action) => {
            state.query.fetchAllData.status = "success";

            removeAlreadySelectedFootmassPoints(state.actualData.massPointCriteria.selected, state.actualData.massPointCriteria.available);
            
            state.command.editFootOption.canExecute = canExecuteEditFootOption(state);

            // getMainProductLines
        }).addCase(getMainProductLines.pending, (state, action) => {
            state.query.getProductLines.status = 'pending';
        }).addCase(getMainProductLines.rejected, (state, action) => {
            state.query.getProductLines.status = "error";
            state.query.getProductLines.message = action.error.message;
        }).addCase(getMainProductLines.fulfilled, (state, action) => {
            state.query.getProductLines.status = "success";
            state.query.getProductLines.canExecute = true;
            state.loadedData.productLines  = action.payload.getData()

            // getMassPoints
        }).addCase(getMassPoints.pending, (state, action) => {
            state.query.getMassPoints.status = 'pending';
        }).addCase(getMassPoints.rejected, (state, action) => {
            state.query.getMassPoints.status = "error";
            state.query.getMassPoints.message = action.error.message;
        }).addCase(getMassPoints.fulfilled, (state, action) => {
            state.query.getMassPoints.status = "success";
            state.query.getMassPoints.canExecute = true;
            const massPoints = action.payload.getData();

            const massPointCriteriaLoaded = massPoints.map(mp => {
                return {
                    massPointName: mp.name,
                    massPointType: mp.massPointType,
                    bodyArea: mp.bodyArea
                }
            }).filter(distinctMassPointCriteria);

            state.loadedData.massPointCriteria.available.allItems = massPointCriteriaLoaded;
            state.loadedData.massPointCriteria.loaded.allItems = massPointCriteriaLoaded;

            const massPointCriteria = massPoints.map(mp => {
                return {
                    massPointName: mp.name,
                    massPointType: mp.massPointType,
                    bodyArea: mp.bodyArea
                }
            }).filter(distinctMassPointCriteria);

            state.actualData.massPointCriteria.available.allItems = massPointCriteria;
            state.actualData.massPointCriteria.loaded.allItems = massPointCriteria;
            applyFilter(state.actualData.massPointCriteria.available)

            // getFootTypes
        }).addCase(getFootTypes.pending, (state, action) => {
            state.query.getFootTypes.status = 'pending';
        }).addCase(getFootTypes.rejected, (state, action) => {
            state.query.getFootTypes.status = "error";
            state.query.getFootTypes.message = action.error.message;
        }).addCase(getFootTypes.fulfilled, (state, action) => {
            state.query.getFootTypes.status = "success";
            state.query.getFootTypes.canExecute = true;
            state.loadedData.footTypes = action.payload.getData();

            // getFootoption 
        }).addCase(getFootoption.pending, (state, action) => {
            state.query.getFootOption.status = 'pending';
            state.query.getFootOption.canExecute = false;
        }).addCase(getFootoption.rejected, (state, action) => {
            state.query.getFootOption.status = "error";
            state.query.getFootOption.canExecute = true;
            state.query.getFootOption.message = action.error.message;
        }).addCase(getFootoption.fulfilled, (state, action) => {
            state.query.getFootOption.status = "success";
            state.query.getFootOption.canExecute = true;

            const footOption = action.payload.getData();

            state.actualData.footOptionId = footOption.id;
            state.actualData.selectedProductLine = footOption.mainProductLine;
            state.actualData.selectedFootType = footOption.footType;

            const massPointCriteria = footOption.footOptionMassPointVersions.map(mp => {
                return {
                    massPointName: mp.name,
                    massPointType: mp.type,
                    bodyArea: mp.bodyArea
                }
            }).filter(distinctMassPointCriteria);

            state.loadedData.massPointCriteria.selected.allItems = massPointCriteria;
            state.actualData.massPointCriteria.selected.allItems = massPointCriteria;
            applyFilter(state.actualData.massPointCriteria.selected);

            // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancel.status = 'pending'
            state.command.cancel.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancel.status = "success"
            state.command.cancel.canExecute = false;

            // editFootOption
        }).addCase(updateFootOption.pending, (state, action) => {
            state.command.editFootOption.status = 'pending';
            state.command.editFootOption.canExecute = false;
        }).addCase(updateFootOption.rejected, (state, action) => {
            state.command.editFootOption.status = "error";
            state.command.editFootOption.canExecute = true;
            state.command.editFootOption.message = action.error.message;
        }).addCase(updateFootOption.fulfilled, (state, action) => {
            state.command.editFootOption.status = "success";
            state.command.editFootOption.canExecute = true;
        })
    }
})

export const {
    resetState,
    resetEditFootOption,
    selectFootType,
    selectProductLine,
    deselectMassPointCriteria,
    selectMassPointCriteria,
    filterSelectedMassPointCriteria,
    filterAvailableMassPointCriteria,
} = footOptionEditSlice.actions

export default footOptionEditSlice.reducer