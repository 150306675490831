import React, { useRef } from "react";
import { LoadingIndicatorComponent, PrimaryButtonComponent, TextBoxComponent } from "../../shared";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

interface MassimageUploadComponentProps {
    onFileSelected: (FileList) => void;
    onUpload: () => void;
    onNameChange: (string) => void;
    name: string;
    isError: boolean;
    isUploading: boolean;
    isUploadAllowed: boolean;
}

export const MassimageUploadComponent: React.FC<MassimageUploadComponentProps> = ({ onFileSelected, onUpload, onNameChange, isUploading, isUploadAllowed, name, isError }) => {
    const imageref = useRef(null);

    if (isError) {
        imageref.current.value = null;
    }

    return (<Card>
        <CardContent>
            <Grid alignItems="flex-end" container spacing={3}>
                <Grid item xs={12} sm={6} md={2} lg={2}>
                    Maßbild hochladen
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <TextBoxComponent name="Name" onChange={onNameChange} value={name} />
                </Grid>
                <Grid item xs={12} sm={6} md={7} lg={5}>
                    <input ref={imageref} type="file" accept=".svg" onChange={onFileSelected} />
                </Grid>
                <Grid item xs={12} sm={6} md={12} lg={2}>
                    <PrimaryButtonComponent disabled={!isUploadAllowed} onClick={onUpload}>Hochladen</PrimaryButtonComponent>
                    {isUploading ? <LoadingIndicatorComponent /> : <></>}
                </Grid>
            </Grid>
        </CardContent>
    </Card>
    );
}