import { Grid } from "@mui/material";
import { DropdownComponent } from "shared/shared";
import { useAppSelector, useAppDispatch } from "app/hook";
import { cancelBodyAreaConfiguration, createBodyAreaConfiguration, deleteBodyAreaConfiguration, editBodyAreaConfiguration, selectMainProductLinie, takeBodyAreaConfiguration } from "../redux/policy_scar_treatment.slice";
import { AppDispatch } from "app/redux_store";
import { ListEditorComponent } from "shared/components/editors/list_editor/list_editor.component";
import { EditorSummaryDefinition } from "shared/components/editors/list_editor/models/EditorSummaryDefinition";
import { IScarTreatmentBodyAreaViewModel } from "../redux/policy_scar_treatment.model";
import { doesExist } from "services/validation.service";
import { ListEditorMode } from "shared/components/editors/list_editor/enums/EditorMode";
import { PolicyScarTreatmentBodyAreaComponent } from "./policy_scar_treatment_bodyarea.component";
import { EditorConfigurationComponent } from "shared/components/editors/list_editor/editor_configuration.component";

export const PolicyScarTreatmentEditorComponent: React.FC = ({  
}) => {

    const state = useAppSelector((state) => state.policy_scar_treatment);
    const dispatch: AppDispatch = useAppDispatch();
    
    const editorSummaryDefinitions: EditorSummaryDefinition[] = [{
        heading: "Körperregionen",
        flex: 1,
        displayValue: (value: IScarTreatmentBodyAreaViewModel) => `${value.erpId} - ${value.name}`
    }];

    const getEditorMode = () => {
        let editorMode =  ListEditorMode.None;
        if (doesExist(state.data.selectedMainProductLine.editableItem)) {
            editorMode = state.data.selectedMainProductLine.editableIndex === -1 ? ListEditorMode.New : ListEditorMode.Edit;
        } else {
            // check bpdyAreas === selectedbodyAreas 
        }
        return editorMode;
    }

    const renderContent = () => {
        return <PolicyScarTreatmentBodyAreaComponent/>
    }

    const isEditableConfigValid = () : boolean => {
        return doesExist(state.data.selectedMainProductLine.editableItem) && state.data.selectedMainProductLine?.editableItem.isValide;
    } 

    const renderConfig = () => {
        return <EditorConfigurationComponent
            renderContent={renderContent}
            disabledTakeConfiguration={!isEditableConfigValid()}
            onTakeConfiguration={() => dispatch(takeBodyAreaConfiguration())}
            onCancelConfiguration={() => dispatch(cancelBodyAreaConfiguration())}
        />
    }

    var mainProductLines = state.data.mainProductLines.map(mpl => { return {
        id: mpl.id,
        name: mpl.name,
        erpId: mpl.erpId
    }});

    var selectedMainProductLine = state.data.selectedMainProductLine ? {
        id: state.data.selectedMainProductLine.id,
        name: state.data.selectedMainProductLine.name,
        erpId: state.data.selectedMainProductLine.erpId,
    } : null;

    return (
        <Grid item container md={12}
            direction="column"
            spacing={3}>
            <Grid container item md={12} spacing={3}
                direction="column">
                <Grid item>
                    <DropdownComponent name="Hauptproduktlinie"
                        data={mainProductLines}
                        minWidth="350px"
                        isRequired={true}
                        onSelect={(e) => dispatch(selectMainProductLinie(e ? e.id : null))}
                        displayedProperties={["erpId", "name"]}
                        selectedValue={selectedMainProductLine}                                                   
                    />
                </Grid>
                {
                state.data.selectedMainProductLine != null &&
                <Grid item md={12}>
                    <ListEditorComponent
                        addButtonText="Neue Konfiguration hinzufügen"
                        editorSummaryDefinitions={editorSummaryDefinitions}
                        items={state.data.selectedMainProductLine.selectedBodyAreas}
                        isValid={() => true}
                        onEditConfiguration={(index) => dispatch(editBodyAreaConfiguration(index))}
                        onDeleteConfiguration={(index) => dispatch(deleteBodyAreaConfiguration(index))}
                        onNewConfiguration={() => dispatch(createBodyAreaConfiguration())}
                        renderConfiguration={state.data.selectedMainProductLine ? renderConfig : null}
                        editorMode={getEditorMode()}
                        editableConfigurationIndex={state.data.selectedMainProductLine.editableIndex}
                    />
                </Grid>
            }
            </Grid>
        </Grid>
    );
};