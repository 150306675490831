export const isNotEmpty = (value: string) => {
    return (value !== "");
}

export const isNotUndefinied = (value: any) => {
    return (value != undefined);
}

export const doesExist = (value: any): boolean => {
    return (value != null && value != undefined);
}

export const isNullOrWhitespace = (value: string): boolean => {
    return (value == null || value == undefined || value.trim() === "");
}

export const isLengthValid = (value: string, minLength: number, maxLength: number): boolean => {
    return (value != null && value != undefined && value.length >= minLength && value.length <= maxLength);
}

export const doesNotStartOrEndWithWhitespace = (content: string): boolean => {
    return /^[^\s]+(\s+[^\s]+)*$/.test(content);
}

export const isNumeric = (value: string) => {
    const result = parseFloat(value);
    return !Number.isNaN(result);
}