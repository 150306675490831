export interface MassMaskValidationStateResponse {
    productConfigurationId: string;
    productConfigurationRunId: number;
    globalState: ProductConfigurationStateEnum;
}

export interface AdditionValidationStateResponse {
    productConfigurationId: string;
    productConfigurationRunId: number;
    globalState: ProductConfigurationStateEnum;
}

export interface ProductInstructionValidationStateResponse {
    productConfigurationId: string;
    productConfigurationRunId: number;
    globalState: ProductConfigurationStateEnum;
}


export enum ProductConfigurationStateEnum {
    Undefined = 0,
    NotExecuted = 1,
    UpToDate = 2,
    OutOfDate = 3,
    Invalid = 4,
    Deleted =5
}

export const ProductConfigurationStateValues = [
    { value: ProductConfigurationStateEnum.Undefined, text: "Kein Auswahl" },
    { value: ProductConfigurationStateEnum.NotExecuted, text: "Nicht ausgeführt" },
    { value: ProductConfigurationStateEnum.UpToDate, text: "Aktuell" },
    { value: ProductConfigurationStateEnum.OutOfDate, text: "Nicht mehr aktuell" },
    { value: ProductConfigurationStateEnum.Invalid, text: "Ungültig" },
    { value: ProductConfigurationStateEnum.Deleted, text: "Gelöscht" }
];