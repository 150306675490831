import { Grid } from "@mui/material";
import { SerialSizeArticleTypeSummaryHeading } from "./serial_size_editor_articletype_summary_heading.component";
import { IAttributeViewModel, IMassPointCriteriaSelection, IMassPointValueSelection, ISerialSizeArticleConfigurationViewModel, ISerialSizeArticleViewModel, ISizeViewModel } from "./serial_size_editor.model";
import { SerialSizeArticleTypeAccordionComponent } from "./serial_size_editor_articletype_summary_accordion.component";
import IAvailableArticleType from "models/available_basedata/available_article_type";
import IAvailableSize from "models/available_basedata/available_size";
import { IAvailableAdditionAttributeCategory } from "models/addition_attribute_categories/available_addition_attribute_category";
import { IAvailableAdditionAttribute } from "models/addition_attribute_categories/available_addition_attribute";

interface SerialSizeSummaryProps {
    articleTypeSummaries: ISerialSizeArticleViewModel[];
    configurationArticleType: ISerialSizeArticleConfigurationViewModel;
    editArticleType: (articleType: ISerialSizeArticleViewModel) => void;
    deleteArticleType: (articleType: ISerialSizeArticleViewModel) => void;
    selectArticleType: (articleType: IAvailableArticleType) => void;
    takeArticleType: () => void;
    cancelArticleType: () => void;
    addSize: () => void;
    editSize: (size: ISizeViewModel) => void;
    deleteSize: (size: ISizeViewModel) => void;
    selectSize: (size: IAvailableSize) => void;
    takeSize: () => void;
    cancelSize: () => void;
    addMasspoint: () => void;
    selectMasspoint: (masspoint: IMassPointCriteriaSelection) => void;
    updateValueFrom: (masspoint: IMassPointValueSelection) => void;
    updateValueTo: (masspoint: IMassPointValueSelection) => void;
    deleteMasspoint: (index: number) => void;
    addAttribute: () => void;
    takeAttribute: () => void;
    editAttribute: (attribute: IAttributeViewModel) => void;
    deleteAttribute: (attribute: IAttributeViewModel) => void;
    selectCategory: (category: IAvailableAdditionAttributeCategory) => void;
    selectAttribute: (attribute: IAvailableAdditionAttribute) => void;
    cancelAttribute: () => void;
    addAttributeMasspoint: () => void;
    selectAttributeMasspoint: (masspoint: IMassPointCriteriaSelection) => void;
    updateAttributeValueFrom: (masspoint: IMassPointValueSelection) => void;
    updateAttributeValueTo: (masspoint: IMassPointValueSelection) => void;
    deleteAttributeMasspoint: (index: number) => void;
}

export const SerialSizeSummaryComponent: React.FC<SerialSizeSummaryProps> = (
    {
        articleTypeSummaries,
        configurationArticleType,
        editArticleType,
        deleteArticleType,
        selectArticleType,
        takeArticleType,
        cancelArticleType,
        addSize,
        editSize,
        deleteSize,
        selectSize,
        takeSize,
        cancelSize,
        addMasspoint,
        selectMasspoint,
        updateValueFrom,
        updateValueTo,
        deleteMasspoint,
        addAttribute,
        takeAttribute,
        editAttribute,
        deleteAttribute,
        selectCategory,
        selectAttribute,
        cancelAttribute,
        addAttributeMasspoint,
        selectAttributeMasspoint,
        updateAttributeValueFrom,
        updateAttributeValueTo,
        deleteAttributeMasspoint
    }) => {

    return (
        <>
            <Grid spacing={3}
                direction="column"
                container
                justifyContent="space-between">
                <Grid item>
                    <SerialSizeArticleTypeSummaryHeading />
                    <SerialSizeArticleTypeAccordionComponent
                        articleTypeSummaries={articleTypeSummaries}
                        configurationArticleType={configurationArticleType}
                        editArticleType={editArticleType}
                        deleteArticleType={deleteArticleType}
                        selectArticleType={selectArticleType}
                        takeArticleType={takeArticleType}
                        cancelArticleType={cancelArticleType}
                        addSize={addSize}
                        editSize={editSize}
                        deleteSize={deleteSize}
                        selectSize={selectSize}
                        takeSize={takeSize}
                        cancelSize={cancelSize}
                        addMasspoint={addMasspoint}
                        selectMasspoint={selectMasspoint}
                        updateValueFrom={updateValueFrom}
                        updateValueTo={updateValueTo}
                        deleteMasspoint={deleteMasspoint}
                        addAttribute={addAttribute}
                        takeAttribute={takeAttribute}
                        editAttribute={editAttribute}
                        deleteAttribute={deleteAttribute}
                        selectCategory={selectCategory}
                        selectAttribute={selectAttribute}
                        cancelAttribute={cancelAttribute}
                        addAttributeMasspoint={addAttributeMasspoint}
                        selectAttributeMasspoint={selectAttributeMasspoint}
                        updateAttributeValueFrom={updateAttributeValueFrom}
                        updateAttributeValueTo={updateAttributeValueTo}
                        deleteAttributeMasspoint={deleteAttributeMasspoint}
                    />
                </Grid>
            </Grid>
        </>
    );
};