import IMasspointHistoryOverview from '../models/masspoints_history/masspoint_history_overview';
import IMasspointHistoryDetails from '../models/masspoints_history/masspoint_history_details';
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from './config.service';
import HttpServiceCommonResult from 'shared/networking/http_service_common_result';

class MasspointHistoryServiceAPI extends HttpServiceCommonResult {
    public async getMasspointsHistoryOverview(): Promise<ApiResult<Array<IMasspointHistoryOverview>>> {
        return this.get<Array<IMasspointHistoryOverview>>("/api/masspointhistory");
    }

    public async getMasspointHistoryDetails(historyId: Number ): Promise<ApiResult<IMasspointHistoryDetails>> {
        return this.get<IMasspointHistoryDetails>(`/api/masspointhistory/${historyId}`);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const MasspointHistoryService = new MasspointHistoryServiceAPI(TimeoutMilliseconds);

export default MasspointHistoryService;
