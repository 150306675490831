import AddIcon from "@mui/icons-material/Add";
import { PrimaryButtonComponent } from "../../../shared/shared";
import { FlexBox, Left, Right } from "../../../shared/style/alignment";
import { LoadingIndicatorComponent } from '../../../shared/components/loading_indicator/loading_indicator.component';
import { AppDispatch } from "app/redux_store";
import { useAppDispatch, useAppSelector } from '../../../app/hook';
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import * as style from "./production_instruction_overview.style";
import { createNewProductionInstruction, getProductionInstructions, togglePublishProductionInstruction } from "./redux/production_instruction_overview.thunks";
import {
    publishProductionInstructionCompleted,
    resetState,
    unpublishProductionInstructionCompleted,
    deleteProductionInstruction,
    resetDeleteProductionInstruction,
    setGridFilters,
    setSearchFilter
} from "./redux/production_instruction_overview.slice";
import { ProductionInstructionDeleteDialogComponent } from "./components/instruction_delete_dialog.component";
import SearchFieldComponent from "shared/components/search/searchfield.component";
import { useNavigate } from "react-router-dom";
import { OverviewDatagrid } from "shared/components/datagrid/overview_datagrid.component";
import { getGridColumns, sortBySymbolModel } from "./production_instruction_overview.helper";

export const ProductionInstructionOverviewComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const state = useAppSelector((state) => state.production_instruction_overview);

    useEffect(() => {
        dispatch(getProductionInstructions());
        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    if (state.query.getProductionInstructions.status === "error") {
        enqueueSnackbar(state.query.getProductionInstructions.message, { variant: "error" });
    }

    if (state.query.getProductionInstructions.status !== "success") {
        return <LoadingIndicatorComponent/>;
    }

    if (state.command.publishProductionInstruction.status === "success") {
        enqueueSnackbar("Anweisung erfolgreich veröffentlicht", { variant: "success" });
        dispatch(publishProductionInstructionCompleted())
    }

    if (state.command.unpublishProductionInstruction.status === "success") {
        enqueueSnackbar("Veröffentlichung erfolgreich aufgehoben", { variant: "success" });
        dispatch(unpublishProductionInstructionCompleted())
    }

    if (state.command.createNewProductionInstruction.status === "pending") {
        navigate(`/additions/production-instruction/create`);
    }

    if (state.command.confirmDeleteProductionInstruction.status === "error") {
        enqueueSnackbar(state.command.confirmDeleteProductionInstruction.message, { variant: "error" });
        dispatch(resetDeleteProductionInstruction());
    }

    if (state.command.confirmDeleteProductionInstruction.status === "success") {
        enqueueSnackbar("Anweisung erfolgreich gelöscht", { variant: "success" });
        dispatch(resetDeleteProductionInstruction());
    }

    const isLoading = state.command.publishProductionInstruction.status === "pending"
        || state.command.unpublishProductionInstruction.status === "pending";

    const productionInstructionsAvailable = state.loadedData.productionInstructions?.length > 0;

    return (
        <div>
            {
                state.actualData.showDeleteDialog && <ProductionInstructionDeleteDialogComponent/>
            }
            <FlexBox>
                {
                    state.actualData.isSearchEnabled &&
                    <Left>
                        <SearchFieldComponent onSearch={(filterText) => dispatch(setSearchFilter(filterText))}
                                              placeholder="Suchen ..."/>
                    </Left>
                }
                <Right>
                    <PrimaryButtonComponent onClick={() => dispatch(createNewProductionInstruction())}>
                        <AddIcon/> Neue Anweisung erstellen
                    </PrimaryButtonComponent>
                </Right>
            </FlexBox>
            <style.TableContainer>
                {
                    productionInstructionsAvailable &&
                    <OverviewDatagrid
                        isLoading={isLoading}
                        columns={getGridColumns(
                            (productionInstruction) => navigate(`/additions/production-instruction/edit/${productionInstruction.productionInstructionId}`),
                            (productionInstruction) => dispatch(deleteProductionInstruction(productionInstruction)),
                            (productionInstruction) => dispatch(togglePublishProductionInstruction(productionInstruction))
                        )}
                        rows={state.actualData.productionInstructions}
                        loadedRowsCount={state.loadedData.productionInstructions.length}
                        sortModel={sortBySymbolModel}
                        onFilterModelChange={(newFilterModel) => dispatch(setGridFilters(newFilterModel))}
                        getRowId={(row) => row.productionInstructionId}
                        onDoubleClickRow={params => navigate(`/additions/production-instruction/edit/${params.row.productionInstructionId}`)}
                    />
                }
                {
                    !productionInstructionsAvailable && <div>Keine Anweisungen verfügbar</div>
                }
            </style.TableContainer>
        </div>
    );
};