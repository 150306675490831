import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SerialSizeUpdateState } from './serial_sizes_edit.model';
import { cancelSave, updateSerialSize, getMasspoints, getQualitiesAndArticleTypes, getSizes, initData, getSerialSize, getAvailableAttributeCategories } from './serial_sizes_edit.thunks';
import IAvailableQuality from 'models/available_basedata/available_quality';
import { calculateAvailableArticleTypes, recalculateAvailableMasspoints, calculateAvailableSizes, calculateViewModel, createMasspointItemList, updateCanSave, recalculateAvailableAttributeMasspoints, recalculateAvailableAttributes, sortAvailableAttributes } from './serial_sizes_edit.reducer';
import IAvailableArticleType from 'models/available_basedata/available_article_type';
import { IAttributeViewModel, IMassPointCriteriaSelection, IMassPointValueSelection, ISerialSizeArticleViewModel, ISizeViewModel } from 'masspoints/serial_sizes/components/serial_size_editor.model';
import IAvailableSize from 'models/available_basedata/available_size';
import { IAvailableAdditionAttributeCategory } from 'models/addition_attribute_categories/available_addition_attribute_category';
import { IAvailableAdditionAttribute } from 'models/additions/addition';

const initialState: SerialSizeUpdateState = {
    query: {
        fetchQualitiesAndArticleTypes: { status: "idle", canExecute: false },
        fetchSizes: { status: "idle", canExecute: false },
        fetchMasspoints: { status: "idle", canExecute: false },
        fetchSerialSize: { status: "idle", canExecute: false },
        fetchAttributes: { status: "idle", canExecute: false },
        initData: { status: "idle", canExecute: false },
    },
    command: {
        updateSerialSize: { status: "idle", canExecute: false },
        cancelSerialSize: { status: "idle", canExecute: false },
    },
    loadedData: {
        qualities: [],
        articleTypes: [],
        sizes: [],
        attributeCategories: [],
        masspointCriterias: [],
        serialSize: null,
    },
    actualData: {
        serialSize: {
            articleTypeViewModels: []
        },
    }
}

export const updateSerialSizesSlice = createSlice({
    name: 'serial_sizes/serial_sizes_edit',
    initialState,
    reducers: {
        resetState: (state) => {
            state.command = initialState.command;
            state.loadedData = initialState.loadedData;
            state.actualData = initialState.actualData;
            state.query = initialState.query;
        },
        selectQuality: (state, action: PayloadAction<IAvailableQuality>) => {
            state.actualData.serialSize.quality = action.payload;
            updateCanSave(state);
        },
        selectArticleType: (state, action: PayloadAction<IAvailableArticleType>) => {
            state.actualData.configureArticleType.articleType = action.payload;
        },
        addArticleType: (state) => {
            const availableArticletypes = calculateAvailableArticleTypes(state);
            state.actualData.configureArticleType = {
                isNewArticle: true,
                availableArticleTypes: availableArticletypes,
                oldArticleType: undefined,
                articleType: availableArticletypes.length > 0 ? availableArticletypes[0] : null,
                sizes: [],
                attributes: [],
            };
        },
        editArticleType: (state, action: PayloadAction<ISerialSizeArticleViewModel>) => {
            const articleType = state.actualData.serialSize.articleTypeViewModels.find(x => x.articleType.id === action.payload.articleType.id);
            const selectedArticleType = state.loadedData.articleTypes.find(x => x.id === action.payload.articleType.id)
            if (articleType && selectArticleType) {
                const availableArticleTypes = calculateAvailableArticleTypes(state);
                availableArticleTypes.push(selectedArticleType);

                state.actualData.configureArticleType = {
                    isNewArticle: false,
                    articleType: selectedArticleType,
                    oldArticleType: selectedArticleType,
                    availableArticleTypes: availableArticleTypes,
                    sizes: articleType.sizeViewModels.map(size => {
                        return {
                            size: size.size,
                            masspoints: size.masspoints
                        }
                    }),
                    attributes: articleType.attributeViewModels.map(attribute => {
                        return {
                            attribute: attribute.attribute,
                            masspoints: attribute.masspoints
                        }
                    }),
                };
            }
        },
        deleteArticleType: (state, action: PayloadAction<ISerialSizeArticleViewModel>) => {
            const articleTypeIndex = state.actualData.serialSize.articleTypeViewModels.findIndex(x => x.articleType.id === action.payload.articleType.id);
            if (articleTypeIndex > -1) {
                state.actualData.serialSize.articleTypeViewModels.splice(articleTypeIndex, 1);
            }
        },
        takeArticleType: (state) => {
            const articleType = state.actualData.configureArticleType;
            state.actualData.configureArticleType = undefined;
            if (articleType.isNewArticle) {
                state.actualData.serialSize.articleTypeViewModels.push({
                    articleType: articleType.articleType,
                    sizeViewModels: articleType.sizes.map(x => {
                        return {
                            size: x.size,
                            masspoints: x.masspoints
                        }
                    }),
                    attributeViewModels: articleType.attributes.map(x => {
                        return {
                            attribute: x.attribute,
                            masspoints: x.masspoints
                        }
                    })
                });
            } else {
                const summaryArticletype = state.actualData.serialSize.articleTypeViewModels.find(x => x.articleType.id === articleType.oldArticleType.id);
                summaryArticletype.articleType = articleType.articleType;
                summaryArticletype.sizeViewModels = articleType.sizes.map(x => {
                    return {
                        size: x.size,
                        masspoints: x.masspoints
                    }
                });
                summaryArticletype.attributeViewModels = articleType.attributes.map(x => {
                    return {
                        attribute: x.attribute,
                        masspoints: x.masspoints
                    }
                });
            };
            updateCanSave(state);
        },
        cancelArticleType: (state) => {
            state.actualData.configureArticleType = undefined;
        },
        addSize: (state) => {
            if (state.actualData.configureArticleType) {
                state.actualData.configureArticleType.configureSize = {
                    isNewSize: true,
                    availableSizes: calculateAvailableSizes(state),
                    masspoints: []
                };
            }
        },
        editSize: (state, action: PayloadAction<ISizeViewModel>) => {
            const size = state.actualData.configureArticleType.sizes.find(x => x.size.id === action.payload.size.id);
            const selectedSize = state.loadedData.sizes.find(x => x.id === action.payload.size.id)
            if (size && selectArticleType) {
                const availableSizes = calculateAvailableSizes(state);
                availableSizes.push(selectedSize);

                state.actualData.configureArticleType.configureSize = {
                    isNewSize: false,
                    size: selectedSize,
                    oldSize: selectedSize,
                    availableSizes: availableSizes,
                    masspoints: size.masspoints.map((mp) => {
                        return {
                            masspoint: mp.masspoint,
                            availableMasspoints: [],
                            valueFrom: mp.valueFrom,
                            valueTo: mp.valueTo,
                        }
                    }),
                };
                state.actualData.configureArticleType.configureSize.masspoints.forEach(mp => {
                    mp.availableMasspoints = recalculateAvailableMasspoints(state, mp.masspoint);
                });
            }
        },
        deleteSize: (state, action: PayloadAction<ISizeViewModel>) => {
            const sizeIndex = state.actualData.configureArticleType.sizes.findIndex(x => x.size.id === action.payload.size.id);
            if (sizeIndex > -1) {
                state.actualData.configureArticleType.sizes.splice(sizeIndex, 1);
            }
        },
        selectSize: (state, action: PayloadAction<IAvailableSize>) => {
            state.actualData.configureArticleType.configureSize.size = action.payload;
        },
        takeSize: (state) => {
            const size = state.actualData.configureArticleType.configureSize;
            state.actualData.configureArticleType.configureSize = undefined;
            if (size.isNewSize) {
                state.actualData.configureArticleType.sizes.push({
                    size: size.size,
                    masspoints: size.masspoints.map(mp => {
                        return {
                            masspoint: mp.masspoint,
                            valueFrom: mp.valueFrom,
                            valueTo: mp.valueTo
                        }
                    })
                });
            } else {
                const changedSize = state.actualData.configureArticleType.sizes.find(x => x.size.id === size.oldSize.id);
                changedSize.size = size.size;
                changedSize.masspoints = size.masspoints;
            };
        },
        cancelSize: (state) => {
            state.actualData.configureArticleType.configureSize = undefined;
        },
        addMasspoint: (state) => {
            const availableMasspoints = recalculateAvailableMasspoints(state, undefined);
            state.actualData.configureArticleType.configureSize.masspoints.push({ availableMasspoints: availableMasspoints, valueFrom: undefined, valueTo: undefined });
        },
        selectMasspoint: (state, action: PayloadAction<IMassPointCriteriaSelection>) => {
            const selection = action.payload;
            state.actualData.configureArticleType.configureSize.masspoints[selection.index].masspoint = action.payload.masspoint;
            state.actualData.configureArticleType.configureSize.masspoints.forEach(mp => {
                mp.availableMasspoints = recalculateAvailableMasspoints(state, mp.masspoint);
            });
        },
        updateValueFrom: (state, action: PayloadAction<IMassPointValueSelection>) => {
            const selection = action.payload;
            state.actualData.configureArticleType.configureSize.masspoints[selection.index].valueFrom = action.payload.value;
        },
        updateValueTo: (state, action: PayloadAction<IMassPointValueSelection>) => {
            const selection = action.payload;
            state.actualData.configureArticleType.configureSize.masspoints[selection.index].valueTo = action.payload.value;
        },
        deleteMasspoint: (state, action: PayloadAction<number>) => {
            const masspointIndex = action.payload;
            if (masspointIndex > -1) {
                state.actualData.configureArticleType.configureSize.masspoints.splice(masspointIndex, 1);
            }
            state.actualData.configureArticleType.configureSize.masspoints.forEach(mp => {
                mp.availableMasspoints = recalculateAvailableMasspoints(state, mp.masspoint);
            });
        },
        addAttribute: (state) => {
            if (state.actualData.configureArticleType) {
                state.actualData.configureArticleType.configureAttribute = {
                    isNewAttribute: true,
                    availableCategories: state.loadedData.attributeCategories,
                    category: undefined,
                    availableAttributes: [],
                    masspoints: []
                };
            }
        },
        editAttribute: (state, action: PayloadAction<IAttributeViewModel>) => {
            const attribute = state.actualData.configureArticleType.attributes.find(x => x.attribute.id === action.payload.attribute.id);
            const selectedAttribute = state.loadedData.attributeCategories.flatMap(x => x.availableAdditionAttributes).find(x => x.id === action.payload.attribute.id)
            const selectedAttributeCategory = state.loadedData.attributeCategories.find(x => x.availableAdditionAttributes.includes(selectedAttribute));
            if (attribute && selectedAttribute && selectedAttributeCategory) {
                const availableAttributes = recalculateAvailableAttributes(state, selectedAttributeCategory.availableAdditionAttributes);
                availableAttributes.push(selectedAttribute);

                state.actualData.configureArticleType.configureAttribute = {
                    isNewAttribute: false,
                    attribute: selectedAttribute,
                    category: selectedAttributeCategory,
                    oldAttribute: selectedAttribute,
                    availableAttributes: availableAttributes.sort(sortAvailableAttributes),
                    availableCategories: state.loadedData.attributeCategories,
                    masspoints: attribute.masspoints.map((mp) => {
                        return {
                            masspoint: mp.masspoint,
                            availableMasspoints: [],
                            valueFrom: mp.valueFrom,
                            valueTo: mp.valueTo,
                        }
                    }),
                };
                state.actualData.configureArticleType.configureAttribute.masspoints.forEach(mp => {
                    mp.availableMasspoints = recalculateAvailableAttributeMasspoints(state, mp.masspoint);
                });
            }
        },
        takeAttribute: (state) => {
            const attribute = state.actualData.configureArticleType.configureAttribute;
            state.actualData.configureArticleType.configureAttribute = undefined;
            if (attribute.isNewAttribute) {
                state.actualData.configureArticleType.attributes.push({
                    attribute: attribute.attribute,
                    masspoints: attribute.masspoints.map(mp => {
                        return {
                            masspoint: mp.masspoint,
                            valueFrom: mp.valueFrom,
                            valueTo: mp.valueTo
                        }
                    })
                });
            } else {
                const changedAttribute = state.actualData.configureArticleType.attributes.find(x => x.attribute.id === attribute.oldAttribute.id);
                changedAttribute.attribute = attribute.attribute;
                changedAttribute.masspoints = attribute.masspoints;
            };
        },
        deleteAttribute: (state, action: PayloadAction<IAttributeViewModel>) => {
            const attributeIndex = state.actualData.configureArticleType.attributes.findIndex(x => x.attribute.id === action.payload.attribute.id);
            if (attributeIndex > -1) {
                state.actualData.configureArticleType.attributes.splice(attributeIndex, 1);
            }
        },
        selectCategory: (state, action: PayloadAction<IAvailableAdditionAttributeCategory>) => {
            state.actualData.configureArticleType.configureAttribute.category = action.payload;
            state.actualData.configureArticleType.configureAttribute.availableAttributes = recalculateAvailableAttributes(state, action.payload.availableAdditionAttributes);
            state.actualData.configureArticleType.configureAttribute.attribute = undefined;
        },
        selectAttribute: (state, action: PayloadAction<IAvailableAdditionAttribute>) => {
            state.actualData.configureArticleType.configureAttribute.attribute = action.payload;
        },
        addAttributeMasspoint: (state) => {
            const availableMasspoints = recalculateAvailableAttributeMasspoints(state, undefined);
            state.actualData.configureArticleType.configureAttribute.masspoints.push({ availableMasspoints: availableMasspoints, valueFrom: undefined, valueTo: undefined });
        },
        selectAttributeMasspoint: (state, action: PayloadAction<IMassPointCriteriaSelection>) => {
            const selection = action.payload;
            state.actualData.configureArticleType.configureAttribute.masspoints[selection.index].masspoint = action.payload.masspoint;
            state.actualData.configureArticleType.configureAttribute.masspoints.forEach(mp => {
                mp.availableMasspoints = recalculateAvailableAttributeMasspoints(state, mp.masspoint);
            });
        },
        updateAttributeValueFrom: (state, action: PayloadAction<IMassPointValueSelection>) => {
            const selection = action.payload;
            state.actualData.configureArticleType.configureAttribute.masspoints[selection.index].valueFrom = action.payload.value;
        },
        updateAttributeValueTo: (state, action: PayloadAction<IMassPointValueSelection>) => {
            const selection = action.payload;
            state.actualData.configureArticleType.configureAttribute.masspoints[selection.index].valueTo = action.payload.value;
        },
        deleteAttributeMasspoint: (state, action: PayloadAction<number>) => {
            const masspointIndex = action.payload;
            if (masspointIndex > -1) {
                state.actualData.configureArticleType.configureAttribute.masspoints.splice(masspointIndex, 1);
            }
            state.actualData.configureArticleType.configureAttribute.masspoints.forEach(mp => {
                mp.availableMasspoints = recalculateAvailableAttributeMasspoints(state, mp.masspoint);
            });
        },
        cancelAttribute: (state) => {
            state.actualData.configureArticleType.configureAttribute = undefined;
        },
        completedSave: (state) => {
            state.command.updateSerialSize = initialState.command.updateSerialSize;
        }
    }, extraReducers: (builder) => {
        // fetchQualitiesAndArticleTypes
        builder.addCase(getQualitiesAndArticleTypes.pending, (state) => {
            state.query.fetchQualitiesAndArticleTypes.status = "pending"
            state.query.fetchQualitiesAndArticleTypes.canExecute = false;
        }).addCase(getQualitiesAndArticleTypes.rejected, (state, action) => {
            state.query.fetchQualitiesAndArticleTypes.status = "error"
            state.query.fetchQualitiesAndArticleTypes.message = action.error.message;
            state.query.fetchQualitiesAndArticleTypes.canExecute = true;
        }).addCase(getQualitiesAndArticleTypes.fulfilled, (state, action) => {
            state.query.fetchQualitiesAndArticleTypes.status = "success"
            state.query.fetchQualitiesAndArticleTypes.canExecute = true;
            state.loadedData.qualities = action.payload.getData().availableQualities;
            state.loadedData.articleTypes = action.payload.getData().availableArticleTypes;
            state.loadedData.articleTypes.unshift({ id: -1, erpId: undefined, name: "Default" });
        })

            // fetchSizes
            .addCase(getSizes.pending, (state) => {
                state.query.fetchSizes.status = "pending"
                state.query.fetchSizes.canExecute = false;
            }).addCase(getSizes.rejected, (state, action) => {
                state.query.fetchSizes.status = "error"
                state.query.fetchSizes.message = action.error.message;
                state.query.fetchSizes.canExecute = true;
            }).addCase(getSizes.fulfilled, (state, action) => {
                state.query.fetchSizes.status = "success"
                state.query.fetchSizes.canExecute = true;
                state.loadedData.sizes = action.payload.getData();
            })

            // getSerialSize
            .addCase(getSerialSize.pending, (state) => {
                state.query.fetchSerialSize.status = "pending"
                state.query.fetchSerialSize.canExecute = false;
            }).addCase(getSerialSize.rejected, (state, action) => {
                state.query.fetchSerialSize.status = "error"
                state.query.fetchSerialSize.message = action.error.message;
                state.query.fetchSerialSize.canExecute = true;
            }).addCase(getSerialSize.fulfilled, (state, action) => {
                state.query.fetchSerialSize.status = "success"
                state.query.fetchSerialSize.canExecute = true;
                state.loadedData.serialSize = action.payload.getData();
            })

            // fetchAttributes
            .addCase(getAvailableAttributeCategories.pending, (state) => {
                state.query.fetchAttributes.status = "pending"
                state.query.fetchAttributes.canExecute = false;
            }).addCase(getAvailableAttributeCategories.rejected, (state, action) => {
                state.query.fetchAttributes.status = "error"
                state.query.fetchAttributes.message = action.error.message;
                state.query.fetchAttributes.canExecute = true;
            }).addCase(getAvailableAttributeCategories.fulfilled, (state, action) => {
                state.query.fetchAttributes.status = "success"
                state.query.fetchAttributes.canExecute = true;
                state.loadedData.attributeCategories = action.payload.getData();
            })

            // fetchMasspoints
            .addCase(getMasspoints.pending, (state) => {
                state.query.fetchMasspoints.status = "pending"
                state.query.fetchMasspoints.canExecute = false;
            }).addCase(getMasspoints.rejected, (state, action) => {
                state.query.fetchMasspoints.status = "error"
                state.query.fetchMasspoints.message = action.error.message;
                state.query.fetchMasspoints.canExecute = true;
            }).addCase(getMasspoints.fulfilled, (state, action) => {
                state.query.fetchMasspoints.status = "success"
                state.query.fetchMasspoints.canExecute = true;
                const masspoints = createMasspointItemList(action.payload.getData());
                state.loadedData.masspointCriterias = masspoints;

                // cancelSave
            }).addCase(cancelSave.pending, (state) => {
                state.command.cancelSerialSize.status = 'pending'
                state.command.cancelSerialSize.canExecute = false;
            }).addCase(cancelSave.fulfilled, (state) => {
                state.command.cancelSerialSize.status = "success"
                state.command.cancelSerialSize.canExecute = false;

                // updateSerialSize
            }).addCase(updateSerialSize.pending, (state) => {
                state.command.updateSerialSize.status = 'pending'
                state.command.updateSerialSize.canExecute = false;
            }).addCase(updateSerialSize.rejected, (state, action) => {
                state.command.updateSerialSize.status = "error"
                state.command.updateSerialSize.canExecute = true;
                state.command.updateSerialSize.message = action.error.message;
            }).addCase(updateSerialSize.fulfilled, (state) => {
                state.command.updateSerialSize.status = "success"
                state.command.updateSerialSize.canExecute = false;

                // initData
            }).addCase(initData.pending, (state) => {
                state.query.initData.status = "pending"
                state.query.initData.canExecute = false;
            }).addCase(initData.rejected, (state, action) => {
                state.query.initData.status = "error"
                state.query.initData.canExecute = true;
                state.query.initData.message = action.error.message;
            }).addCase(initData.fulfilled, (state) => {
                state.query.initData.status = "success"
                state.query.initData.canExecute = true;
                state.actualData.serialSize = calculateViewModel(state);
                updateCanSave(state);
            })
    }
})

export const {
    selectQuality,
    addArticleType,
    editArticleType,
    deleteArticleType,
    takeArticleType,
    cancelArticleType,
    selectArticleType,
    addSize,
    editSize,
    deleteSize,
    takeSize,
    cancelSize,
    selectSize,
    addMasspoint,
    selectMasspoint,
    updateValueFrom,
    updateValueTo,
    deleteMasspoint,
    addAttribute,
    takeAttribute,
    editAttribute,
    deleteAttribute,
    selectCategory,
    selectAttribute,
    cancelAttribute,
    addAttributeMasspoint,
    selectAttributeMasspoint,
    updateAttributeValueFrom,
    updateAttributeValueTo,
    deleteAttributeMasspoint,
    completedSave,
    resetState,
} = updateSerialSizesSlice.actions

export default updateSerialSizesSlice.reducer