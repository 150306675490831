import React from "react";
import { JsonViewer } from '@textea/json-viewer';
import { LoadingIndicatorComponent } from '../../shared/components/loading_indicator/loading_indicator.component';
import { useSnackbar } from "notistack";
import { fetchCheckHistoryDetails } from './redux/check_history_details.thunks';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { AppDispatch } from '../../app/redux_store';
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const CheckHistoryDetailsComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.checks_history_details);
    const { historyId } = useParams();

    useEffect(() => {
        dispatch(fetchCheckHistoryDetails(Number(historyId)));
    }, [dispatch,historyId]);

    const { enqueueSnackbar } = useSnackbar();

    if (state.query.fetchCheckHistoryDetails.status === "error") {
        enqueueSnackbar(state.query.fetchCheckHistoryDetails.message, { variant: "error" });
        return <div>Fehler beim Laden der Daten</div>;
    }

    if (state.query.fetchCheckHistoryDetails.status !== "success") {
        return <LoadingIndicatorComponent />;
    }

    return (
        <div>
            <JsonViewer displayDataTypes={false} value={JSON.parse(state.data.checkHistoryDetails.content)} />
        </div>
    )
}