import { createAsyncThunk } from '@reduxjs/toolkit';
import CheckHistoryServiceApi from "../../../services/check_history.service";
import { handleResponseErrors } from '../../../shared/networking/error_handling';

export const fetchCheckHistoryOverviews = createAsyncThunk(
    'check_history/check_history_overview/fetchCheckHistoryOverviews',
    async (thunkApi) => {
        const response = await CheckHistoryServiceApi.getChecksHistoryOverview();
        handleResponseErrors(response, "Prüfungshistorie");
        return response;
    }
)