import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from "./config.service";
import HttpServiceCommonResult from "shared/networking/http_service_common_result";
import { CommonApiResult } from "shared/networking/common_api_result";
import { GetConfiguredPosition } from "models/mapping_position/get_configured_position";
import { LegacyPosition } from "models/mapping_position/as_position";
import { PostMapping } from "models/mapping_position/post_mapping";

class MappingPositionServiceAPI extends HttpServiceCommonResult {
    public async uploadMappingFileFile(file: File): Promise<ApiResult<void>> {
        const data = new FormData();
        data.append("file", file);
        return this.uploadFileForm<void>("/api/positionmapping/file", data);
    }

    public async getConfiguredPositions(): Promise<ApiResult<Array<GetConfiguredPosition>>> {
        return this.get<Array<GetConfiguredPosition>>("/api/positionmapping/allPositions");
    }

    public async getLegacyPositions(): Promise<ApiResult<Array<LegacyPosition>>> {
        return this.get<Array<LegacyPosition>>("/api/positionmapping/");
    }

    public async updatePositionsMapping(mapping: PostMapping): Promise<ApiResult<void>> {
        return this.put<PostMapping,void>("/api/positionmapping/", mapping);
    }

    public async deletePositionsMapping(): Promise<ApiResult<void>> {
        return this.delete("/api/positionmapping/");
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const MappingPositionService = new MappingPositionServiceAPI(TimeoutMilliseconds);

export default MappingPositionService;