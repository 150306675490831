import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/redux_store';
import AdditionService from '../../../services/addition.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';
import BaseDataService from "../../../services/base_data.service";
import CategoryService from "../../../services/addition_category.service";
import { toIAdditionCreate, toIAdditionUpdate } from './addition.reducer';
import CountryService from 'services/country.service';
import AdditionAttributeCategoryService from 'services/addition_attribute_category.service';
import PositionCategoryService from 'services/position_category.service';
import MassPointService from 'services/masspoint.service';
import CharacteristicCategoryServiceAPI from 'services/characteristic_category.service';
import { setEditorMode, setShowERPDataLoader } from './addition.slice';
import { EditorModeEnum } from 'models/editors/editor_mode.enum';

export const getAdditionDefinition = createAsyncThunk(
    'addition/getAdditionDefinition',
    async (_, { getState, dispatch }) => {
        const state = getState() as RootState;
        const response = await AdditionService.getAdditionDefinition(state.addition.actualData.optionName);
        handleResponseErrors(response, "Zusatz");
        return response;
    }
)

export const saveAddition = createAsyncThunk(
    'addition/saveAddition',
    async (_, { getState, dispatch }) => {
        const state = getState() as RootState;
        const categoryAttributes = state.addition.actualData.additionEditor.categories;
        const addition = toIAdditionCreate(state.addition.actualData.additionEditor, categoryAttributes);
        const response = await AdditionService.createAddition(addition);
        handleResponseErrors(response, "Zusatz");
        return response;
    }
)

export const updateAddition = createAsyncThunk(
    'addition/updateAddition',
    async (_, { getState, dispatch }) => {
        const state = getState() as RootState;
        const categoryAttributes = state.addition.actualData.additionEditor.categories;
        const addition = toIAdditionUpdate(state.addition.actualData.additionEditor, categoryAttributes);
        const response = await AdditionService.updateAddition(addition);
        handleResponseErrors(response, "Zusatz");
        return response;
    }
)

export const getAddition = createAsyncThunk(
    'addition/getAddition',
    async (additionid: string, _) => {
        const response = await AdditionService.getAddition(additionid);
        handleResponseErrors(response, "Zusatz");
        return response;
    }
)

export const getAdditions = createAsyncThunk(
    'addition/initData/getAdditions',
    async (thunkApi) => {
        const response = await AdditionService.getAdditionsOverview();
        handleResponseErrors(response, "Zusätze");
        return response;
    }
)

export const getBaseData = createAsyncThunk(
    'addition/initData/getBaseDate',
    async (thunkApi) => {
        const response = await BaseDataService.getBaseData();
        handleResponseErrors(response, "Basisdaten");
        return response;
    }
)

export const getMappedBaseData = createAsyncThunk(
    'addition/initData/getMappedBaseData',
    async (thunkApi) => {
        const response = await BaseDataService.getMappedBaseData();
        handleResponseErrors(response, "Basisdaten");
        return response;
    }
)

export const getCountries = createAsyncThunk(
    "addition/initData/getCountries",
    async (thunkApi) => {
        const response = await CountryService.getCountries();
        handleResponseErrors(response, "Länder");
        return response;
    }
)

export const getCategories = createAsyncThunk(
    'addition/initData/getCategories',
    async (thunkApi) => {
        const response = await CategoryService.getAdditionCategoryOverview();
        handleResponseErrors(response, "Zusatzkategorie");
        return response;
    }
)

export const getAttributeCategories = createAsyncThunk(
    'addition/initData/getAttributeCategories',
    async (thunkApi) => {
        const response = await AdditionAttributeCategoryService.getAvailableAttributeCategories();
        handleResponseErrors(response, "Attribute");
        return response;
    }
)

export const getFootTypes = createAsyncThunk(
    'addition/initData/getFootTypes',
    async (_) => {
        const response = await BaseDataService.getFootTypes();
        handleResponseErrors(response, "Fußarten");
        return response;
    }
)

export const getPositionCategories = createAsyncThunk(
    'addition/getPositionCategories',
    async (_) => {
        const response = await PositionCategoryService.getPositionCategories();
        handleResponseErrors(response, "Positionskategorien");
        return response;
    }
)

export const getMassPoints = createAsyncThunk(
    'addition/initData/getMassPoints',
    async (_, { getState }) => {
        const response = await MassPointService.getMassPoints()
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)

export const getCharacteristicCategories = createAsyncThunk(
    'addition/initData/getCharacteristicCategories',
    async (_, { getState }) => {
        const response = await CharacteristicCategoryServiceAPI.getCharacteristicCategories();
        handleResponseErrors(response, "Ausprägungskategorien");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'addition/cancelSave',
    async (_) => { }
)

export const initData = createAsyncThunk(
    'addition/initData',
    async (additionid: string, thunkApi) => {
        await Promise.all([
            thunkApi.dispatch(getBaseData()),
            thunkApi.dispatch(getMappedBaseData()),
            thunkApi.dispatch(getAdditions()),
            thunkApi.dispatch(getCountries()),
            thunkApi.dispatch(getCategories()),
            thunkApi.dispatch(getAttributeCategories()),
            thunkApi.dispatch(getFootTypes()),
            thunkApi.dispatch(getPositionCategories()),
            thunkApi.dispatch(getMassPoints()),
            thunkApi.dispatch(getCharacteristicCategories()),
        ]);
        if(additionid){
            await thunkApi.dispatch(setEditorMode(EditorModeEnum.Edit));
            await thunkApi.dispatch(setShowERPDataLoader(false));
            await thunkApi.dispatch(getAddition(additionid));
        }
    }
)