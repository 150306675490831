import { MentionsInput, Mention } from 'react-mentions'
import { SuggestionTextBoxStyle } from './suggestion_text_box.style'
import styles from './suggestion_text_box.module.css'
import { SuggestionTextBoxModel } from './model/suggestion_text_box';

export const SuggestionTextBox: React.FC<SuggestionTextBoxModel> = ({ value, data, onChange, placeholder }) => {
    return (
        <MentionsInput
            singleLine
            value={value}
            className={styles.suggestion__textbox}
            onChange={onChange}
            style={SuggestionTextBoxStyle}
            placeholder={placeholder}
            allowSuggestionsAboveCursor={true}
        >
            <Mention
                trigger={"@"}
                data={data}
                className={styles.suggestion__item}
                appendSpaceOnAdd={true}
            />
        </MentionsInput>

    )
}
