import { Grid } from "@mui/material"
import { GridFilterItem, GridColDef, GridSortModel } from "@mui/x-data-grid-pro"
import IAvailableArticleType from "models/available_basedata/available_article_type"
import { MasspointTypeEnumValuesLookup } from "models/masspoints/enums/masspoint_type.enum"
import { IOneLegMassPoint, IOneLegOverview } from "models/onelegs/oneLeg_overview"
import { CustomFilterOperators } from "shared/components/datagrid/custom_filter_operators"
import { DatagridMenu } from "shared/components/datagrid/datagrid_menu.component"

const buildArticleTypeDisplayString = (articleType: IAvailableArticleType): string => {
    if (articleType != null) {
        return `${articleType.erpId} ${articleType.name}`;
    }
    return "-";
}

const renderHiddenMasspointCriterias = (masspoints: IOneLegMassPoint[]) => {
    return <div style={{ minHeight: "60px", padding: "10px 0px" }}>
        <Grid container spacing={2} xs={12}>
            {
                masspoints.map((masspoint, index) => (
                    <Grid item xs={6} key={index}>
                        {masspoint.name} {MasspointTypeEnumValuesLookup(masspoint.type)} {masspoint.bodyArea.name}
                    </Grid>
                ))
            }
        </Grid>
    </div>
}

export const filterOneLegValue = (filterItem: GridFilterItem, filteredItems: IOneLegOverview[]) => {
    if (filterItem.value) {
        switch (filterItem.field) {
            case 'mainProductLine':
                filteredItems = filteredItems.filter(x => x.mainProductLine.name.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'articleType':
                filteredItems = filteredItems.filter(x => buildArticleTypeDisplayString(x.articleType).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
        }
    }
    return filteredItems;
}

export const getGridColumns = (
    editOneLeg: (oneLeg: IOneLegOverview) => void,
    deleteOneLeg: (oneLeg: IOneLegOverview) => void,
): GridColDef[] => [
        {
            field: "menu",
            headerName: " ",
            width: 15,
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            hideable: false,
            pinnable: false,
            renderCell: params => DatagridMenu({
                params: params, menuItemsData:
                    [
                        { label: "Einbeinhose editieren", action: editOneLeg },
                        { label: "Einbeinhose löschen", action: deleteOneLeg }
                    ]
            })
        },
        {
            field: "mainProductLine",
            headerName: "Hauptproduktlinie",
            disableColumnMenu: false,
            flex: 0.25,
            sortable: false,
            hideable: false,
            pinnable: false,
            valueGetter: (value, row) => row.mainProductLine.name,
            filterOperators: CustomFilterOperators(),
        },
        {
            field: "articleType",
            headerName: "Artikelart",
            disableColumnMenu: false,
            flex: 0.5,
            sortable: false,
            hideable: false,
            pinnable: false,
            valueGetter: (value, row) => buildArticleTypeDisplayString(row.articleType),
            filterOperators: CustomFilterOperators(),
        },
        {
            field: "hiddenMasspointCriterias",
            headerName: "Ausgeblendete Maßpunktkriterien",
            disableColumnMenu: true,
            flex: 0.25,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterable: false,
            renderCell: params => renderHiddenMasspointCriterias(params.row.oneLegMassPointVersions),
        }
    ]

export const sortByMainProductLineModel: GridSortModel = [
    {
        field: "mainProductLine",
        sort: "asc",
    },
];