import { FootOptionMassPointCriteria } from '../../../models/footoptions/footOptionMassPointCriteria';
import { IconButton, Grid } from '@mui/material';
import { MasspointTypeEnumValuesLookup } from '../../../models/masspoints/enums/masspoint_type.enum';
import SearchFieldComponent from 'shared/components/search/searchfield.component';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import * as style from "./masspoint_criteria_list.style";

interface MassPointCriteriaListComponentProps {
    onClick: (index: number, item: FootOptionMassPointCriteria) => {};
    onFilter: (searchText: string) => void;
    items: FootOptionMassPointCriteria[];
    title: string;
    type: MassPointCriteriaListComponentType;
};

export type MassPointCriteriaListComponentType = "add" | "remove";

export const MassPointCriteriaListComponent: React.FC<MassPointCriteriaListComponentProps> = (
    { items,
        onClick,
        onFilter,
        title,
        type }) => {

    const criteriaList = items.map((item, index) => <div key={index}>
        <IconButton
            style={{ outline: "none" }}
            onClick={() => onClick(index, item)}>
            {type === "add"
                ? <KeyboardArrowLeftIcon />
                : <KeyboardArrowRightIcon />
            }
        </IconButton>
        {" "}
        {item.massPointName} {MasspointTypeEnumValuesLookup(item.massPointType)} {item.bodyArea.name}
    </div>);

    return (<Grid
        container
        direction="column"
        spacing={2}>
        <Grid item>
            <strong>{title}</strong>
        </Grid>
        <Grid item>
            <SearchFieldComponent
                onSearch={onFilter}
                placeholder="In Maßpunkten suchen" />
        </Grid>
        <Grid item>
            <style.ListWrapper>
                {criteriaList}
            </style.ListWrapper>
        </Grid>
    </Grid>);

}