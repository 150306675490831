import { ICheckTypeMessage } from 'models/plausibility_check/check_type_message';
import { FormulaTemplateEnum } from '../../../models/plausibility_check/enums/formula_template.enum';
import { doesExist, doesNotStartOrEndWithWhitespace, isNotEmpty } from "../../../services/validation.service";
import { ICheckTypeMessageCreateModel, PlausibilityCheckTypeState } from "./type_create.model";

export const updateCanCreatePlausibilityCheckType = (state: PlausibilityCheckTypeState) => {
    state.command.createPlausibilityCheckType.canExecute = doesExist(state.actualData.checkTypeToCreate.name)
        && isNotEmpty(state.actualData.checkTypeToCreate.name)
        && doesNotStartOrEndWithWhitespace(state.actualData.checkTypeToCreate.name)
        && doesExist(state.actualData.checkTypeToCreate.formulaTemplate)
        && (state.actualData.checkTypeToCreate.formulaTemplate !== FormulaTemplateEnum.Undefined)
}

export const toCheckTypeMessageCreateModel = (message: ICheckTypeMessage): ICheckTypeMessageCreateModel => {
    return {
        displayValue: message.key,
        message: message,
    };
}