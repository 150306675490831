import ListItem from '@mui/material/ListItem';
import styled from "@emotion/styled";

export interface ListItemProps {
  isSelected: boolean;
}

export const StyledListItem = styled(ListItem) <ListItemProps>`
  border: 2px solid ${(props) => props.isSelected ? "#e6007e" : "transparent"};
  &:hover {
    border: 2px solid #e6007e;
    cursor:pointer;
  }
`;

export const Thumbnail = styled.img`
    max-width:40px;
    max-height:40px;
`
export const Column1 = styled.div`
    margin-left:20px;
`
export const Column2 = styled.div`
    margin-left:40px;
`