import { GridFilterItem, GridColDef, getGridBooleanOperators, GridSortModel, GridColumnVisibilityModel } from "@mui/x-data-grid-pro";
import { IPositionCategoryItemDetail } from "models/position_category/position_category_item/position_category_item_detail";
import { SideSeamModeEnum } from "models/position_category/side_seam_mode.enum";
import { CustomBooleanCell } from "shared/components/datagrid/custom_boolean_cell.component";
import { CustomFilterOperators } from "shared/components/datagrid/custom_filter_operators";
import { CustomIconCell } from "shared/components/datagrid/custom_icon_cell.component";
import { AllIconComponent } from "shared/components/icons/all_icon.component";
import { PublishedIconComponent } from "shared/components/icons/published_icon.component";
import { UnpublishedIconComponent } from "shared/components/icons/unpublished_icon.component";

export const filterPositionCategoryItemDetailValue = (filterItem: GridFilterItem, filteredItems: IPositionCategoryItemDetail[]) => {
    if(filterItem.value)
    {
        switch(filterItem.field)
        {
            case 'displayName':
                filteredItems = filteredItems.filter(x => x.displayName.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'isDocumentAllowed':
                filteredItems = filteredItems.filter(x => filterItem.value === 'any' ? true: x.isDocumentAllowed.toString() === filterItem.value);
                break;  
            case 'isMeasurementInputPossible':
                filteredItems = filteredItems.filter(x => filterItem.value === 'any' ? true: x.isMeasurementInputPossible.toString() === filterItem.value);
                break;                                                                                                    
        }
    }
    return filteredItems;
}

export const getColumnVisibilityModel = (isSideSeamAllowed: boolean): GridColumnVisibilityModel => {
    let visibilityModel: GridColumnVisibilityModel  = {};

    if(!isSideSeamAllowed){
        visibilityModel = {
            sideSeamModeAllowed: false
        }
    }
    return visibilityModel;
}

export const getGridColumns = (): GridColDef[] => [
    {
        field: "displayName",
        headerName: "Positionselement",
        disableColumnMenu: false,
        flex: 1,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    },  
    {
        field: "isDocumentAllowed",
        headerName: "Bildupload",
        disableColumnMenu: false,
        width: 150,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: getGridBooleanOperators(),
        renderCell: params => CustomBooleanCell({value: params.row.isDocumentAllowed})
    },
    {
        field: "isMeasurementInputPossible",
        headerName: "Maßeingaben möglich",
        disableColumnMenu: false,
        width: 200,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: getGridBooleanOperators(),
        renderCell: params => CustomBooleanCell({value: params.row.isMeasurementInputPossible})
    },
    {
        field: "sideSeamModeAllowed",
        headerName: "seitliche Naht",
        width: 150,
        disableColumnMenu: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterable: false,
        renderCell: (params) => CustomIconCell<SideSeamModeEnum>( { value: params.row.sideSeamModeAllowed, iconSelector: (value) => renderSideSeamMode(value) })
    }
]

const renderSideSeamMode = (sideSeamMode: SideSeamModeEnum) => {
if(sideSeamMode === SideSeamModeEnum.Both) {
    return <AllIconComponent/>;
} else if (sideSeamMode === SideSeamModeEnum.Normal) {
    return <UnpublishedIconComponent/>;
} else if (sideSeamMode === SideSeamModeEnum.SideSeam) {
    return <PublishedIconComponent/>;
} else {
    return <></>
}
}

export const sortByDisplayNameModel: GridSortModel = [
    {
        field: "displayName",
        sort: "asc",
    },
];

