import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { filterMasspoint, sortAdditions } from 'check/policy/helper/policy.helper';
import { IAdditionPolicyInformation } from 'models/additions/addition_policy_information';
import { IMassPointCriteriaItem } from 'check/policy/components/policy_editor.model';
import { PolicyExcludeAdditionByMasspointValueUpdateState } from './policy_exclude_addition_by_masspoint_value_update.model';
import { updateCanSave, createMasspointItemList } from './policy_exclude_addition_by_masspoint_value_update.reducer';
import { getAdditions, getMassPoints, updatePolicy, cancelSave, getPolicy, initData } from './policy_exclude_addition_by_masspoint_value_update.thunks';

const initialState: PolicyExcludeAdditionByMasspointValueUpdateState = {
    data: { 
        policy: { name: "", ignoreOnQuotation: false},
        selectedAddition: null,
        selectedMasspoint: null,
        thresholdValue: 0,
        allMasspoints: [],
    },
    loadedData: {
        additions: [],
        masspoints: [],
        policy: null
    },
    command:{
        updatePolicy: { status: "idle", canExecute: false },
        cancelSave: { status: "idle", canExecute: true }
    },
    query: {
        fetchAdditions: { status: "idle", canExecute: true },
        fetchMasspoints: { status: "idle", canExecute: true },    
        fetchPolicy: { status: "idle", canExecute: true },           
        initData: { status: "idle", canExecute: true }
    }
}

export const updatePolicyExcludeAdditionByMasspointValue = createSlice({
    name: 'update_policy_exclude_addition_by_masspoint_value',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = initialState.data;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        changeName: (state, action: PayloadAction<string>) => {
            state.data.policy.name = action.payload;
            updateCanSave(state);
        },    
        selectAddition: (state, action: PayloadAction<IAdditionPolicyInformation>) => {
            state.data.selectedAddition = action.payload;
            updateCanSave(state);
        },  
        selectMasspoint: (state, action: PayloadAction<IMassPointCriteriaItem>) => {
            state.data.selectedMasspoint = action.payload;
            updateCanSave(state);
        }, 
        changeThresholdValue: (state, action: PayloadAction<number>) => {
            state.data.thresholdValue = action.payload;
            updateCanSave(state);
        },        
        toggleIgnoreOnQuotation: (state, action: PayloadAction<boolean>) => {
            state.data.policy.ignoreOnQuotation = action.payload;
            updateCanSave(state);
        },               
        completedSave: (state) => {
            state.command.updatePolicy = initialState.command.updatePolicy;
        }
    }, extraReducers: (builder) => {

        // fetchAdditions
        builder.addCase(getAdditions.pending, (state) => {
            state.query.fetchAdditions.status = "pending"
            state.query.fetchAdditions.canExecute = false;
        }).addCase(getAdditions.rejected, (state, action) => {
            state.query.fetchAdditions.status = "error"
            state.query.fetchAdditions.message = action.error.message;
            state.query.fetchAdditions.canExecute = true;
        }).addCase(getAdditions.fulfilled, (state, action) => {
            state.query.fetchAdditions.status = "success"
            state.query.fetchAdditions.canExecute = true;
            state.loadedData.additions = action.payload.getData().sort(sortAdditions);
            updateCanSave(state);

            // fetchMasspoints
        }).addCase(getMassPoints.pending, (state) => {
            state.query.fetchMasspoints.status = "pending"
            state.query.fetchMasspoints.canExecute = false;
        }).addCase(getMassPoints.rejected, (state, action) => {
            state.query.fetchMasspoints.status = "error"
            state.query.fetchMasspoints.message = action.error.message;
            state.query.fetchMasspoints.canExecute = true;
        }).addCase(getMassPoints.fulfilled, (state, action) => {
            state.query.fetchMasspoints.status = "success"
            state.query.fetchMasspoints.canExecute = true;
            const masspoints = createMasspointItemList(action.payload.getData());   
            state.loadedData.masspoints = masspoints;
            state.data.allMasspoints = masspoints;

        // savePolicy
        }).addCase(updatePolicy.pending, (state) => {
            state.command.updatePolicy.status = 'pending'
            state.command.updatePolicy.canExecute = false;
        }).addCase(updatePolicy.rejected, (state, action) => {
            state.command.updatePolicy.status = "error"
            state.command.updatePolicy.canExecute = true;
            state.command.updatePolicy.message = action.error.message;
        }).addCase(updatePolicy.fulfilled, (state) => {
            state.command.updatePolicy.status = "success"
            state.command.updatePolicy.canExecute = false;

        // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancelSave.status = 'pending'
            state.command.cancelSave.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancelSave.status = "success"
            state.command.cancelSave.canExecute = false;

            // fetchPolicy
        }).addCase(getPolicy.pending, (state) => {
            state.query.fetchPolicy.status = "pending"
            state.query.fetchPolicy.canExecute = false;
        }).addCase(getPolicy.rejected, (state, action) => {
            state.query.fetchPolicy.status = "error"
            state.query.fetchPolicy.message = action.error.message;
            state.query.fetchPolicy.canExecute = true;
        }).addCase(getPolicy.fulfilled, (state, action) => {
            state.query.fetchPolicy.status = "success"
            state.query.fetchPolicy.canExecute = true;
            state.loadedData.policy = action.payload.getData();

            // initData
        }).addCase(initData.pending, (state) => {
            state.query.initData.status = "pending"
            state.query.initData.canExecute = false;
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.canExecute = true;
            state.query.initData.message = action.error.message;
        }).addCase(initData.fulfilled, (state) => {
            state.query.initData.status = "success"
            state.query.initData.canExecute = true;
            state.data.policy = { 
                id: state.loadedData.policy.id,
                name: state.loadedData.policy.name,
                ignoreOnQuotation: state.loadedData.policy.ignoreOnQuotation
            };
            state.data.selectedAddition = state.loadedData.additions.find(x => x.id === state.loadedData.policy.additionId);
            state.data.selectedMasspoint = state.loadedData.masspoints.find(x => x.bodyAreaId === state.loadedData.policy.massPoint.bodyAreaId
                &&  x.name === state.loadedData.policy.massPoint.massPointName
                && x.massPointType === state.loadedData.policy.massPoint.massPointType);     
            state.data.thresholdValue = state.loadedData.policy.thresholdValue;
            updateCanSave(state);
        })
    }
})

export const {
    resetState,
    changeName,
    selectAddition,
    selectMasspoint,
    changeThresholdValue,
    completedSave,
    toggleIgnoreOnQuotation,
} = updatePolicyExcludeAdditionByMasspointValue.actions

export default updatePolicyExcludeAdditionByMasspointValue.reducer