import { HistoryValues } from "models/history_type.enum";
import { ChecktypeHistoryOverviewsState } from "./checktype_history.model";
import { FormulaTemplateEnumValues } from "models/plausibility_check/enums/formula_template.enum";
import { filterCheckTypeHistoryOverviewValue } from "../checktype_history.helper";

export const filterChecktypeHistoryOverviews = (state: ChecktypeHistoryOverviewsState) => {
    const searchTextLower = state.actualData.searchFilter.toLocaleLowerCase().trim();
    let filteredItems = state.loadedData.checktypeHistoryOverviews.filter(
        (checkTypeHistoryOverview) =>{
            const type = HistoryValues.find(x => x.value === checkTypeHistoryOverview.type)?.text;
            const formulaTemplate =  FormulaTemplateEnumValues.find(x => x.value === checkTypeHistoryOverview.formulaTemplate)?.text

            return (
                type?.toLowerCase().includes(searchTextLower) ||
                checkTypeHistoryOverview.userName?.toLowerCase().includes(searchTextLower) ||
                checkTypeHistoryOverview.name?.toLowerCase().includes(searchTextLower) ||
                formulaTemplate?.toLowerCase().includes(searchTextLower));  
        }
    );

    state.actualData.gridFilters.items.forEach(fil => {
        filteredItems = filterCheckTypeHistoryOverviewValue(fil, filteredItems);
    });
    
    state.actualData.checktypeHistoryOverviews = filteredItems;

}