import { Grid, Switch } from "@mui/material";
import { TextBoxComponent } from "shared/shared";
import * as style from "shared/components/selectionComponent/selectionList.style";
import { ToggleButtonComponent } from "shared/components/buttons/toggle_button.component";
import { EditorDescriptionComponent } from "shared/components/editors/editor_description/editor_description.style";

interface PolicyEditorProps {
    name: string;
    ignoreOnQuotation: boolean;
    changeName: (name: string) => void;
    toggleIgnoreOnQuotation: (checked: boolean) => void;
}

export const PolicyEditorComponent: React.FC<PolicyEditorProps> = (
    { 
        name, 
        ignoreOnQuotation,
        changeName,
        toggleIgnoreOnQuotation 
    }) => {
    return (
        <>
            <Grid item md={12}>
                <TextBoxComponent
                    name="Name"
                    value={name}
                    width="350px"
                    onChange={changeName}
                    isRequired={true}
                    maxLength={512}
                    isAutoFocus={true}
                />
            </Grid>
            <Grid item md={12}>
                <EditorDescriptionComponent>Ignorieren der Regel bei Angebot ohne Maßeingabe</EditorDescriptionComponent>
                <Switch
                    checked={ignoreOnQuotation}
                    onChange={(event) => toggleIgnoreOnQuotation(event.target.checked)} />
            </Grid>
        </>
    );
};