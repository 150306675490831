import { SerialSizePreviewState } from "./serial_size_preview.model";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchBaseData, fetchSerialSizesPreviews, finish } from "./serial_size_preview.thunks";
import IAvailableArticleType from "models/available_basedata/available_article_type";
import IAvailableQuality from "models/available_basedata/available_quality";
import { createViewModel, updateCanPreview } from "./serial_size_preview.reducer";

const initialState: SerialSizePreviewState = {
    query: {
        fetchBaseData: { status: "idle", canExecute: false },
        fetchSerialSizePreview: { status: "idle", canExecute: false },
    },
    command: {
        finish: { status: "idle", canExecute: false },
    },
    loadedData: {
        articleTypes: [],
        qualities: [],
    },
    actualData: {
        serialSizePreview: null,
        articleType: null,
        quality: null,
    }
}

export const getSerialSizesPreviewSlice = createSlice({
    name: 'serial_sizes/serial_sizes_preview',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loadedData = initialState.loadedData;
            state.actualData = initialState.actualData;
            state.query = initialState.query;
            state.command = initialState.command;
        },
        completeFetchPreviewError: (state) =>  {
            state.query.fetchSerialSizePreview.status = "idle";
            updateCanPreview(state);
        },
        updateArticleType: (state, action: PayloadAction<IAvailableArticleType>) => {
            state.actualData.articleType = action.payload;
            state.actualData.serialSizePreview = null;
            updateCanPreview(state);
        },
        updateQuality: (state, action: PayloadAction<IAvailableQuality>) => {
            state.actualData.quality = action.payload;
            state.actualData.serialSizePreview = null;
            updateCanPreview(state);
        },
    }, extraReducers: (builder) => {

        // fetchSerialSizesPreviews
        builder.addCase(fetchSerialSizesPreviews.pending, (state) => {
            state.query.fetchSerialSizePreview.status = "pending"
            state.query.fetchSerialSizePreview.canExecute = false;
        }).addCase(fetchSerialSizesPreviews.rejected, (state, action) => {
            state.query.fetchSerialSizePreview.status = "error"
            state.query.fetchSerialSizePreview.message = action.error.message;
            state.query.fetchSerialSizePreview.canExecute = true;
            state.actualData.serialSizePreview = null;
        }).addCase(fetchSerialSizesPreviews.fulfilled, (state, action) => {
            state.query.fetchSerialSizePreview.status = "success"
            state.query.fetchSerialSizePreview.canExecute = true;
            state.actualData.serialSizePreview = createViewModel(action.payload.getData());

        // close
        }).addCase(finish.pending, (state) => {
            state.command.finish.status = 'pending'
            state.command.finish.canExecute = false;
        }).addCase(finish.fulfilled, (state) => {
            state.command.finish.status = "success"
            state.command.finish.canExecute = false;

        // fetchBaseData
        }).addCase(fetchBaseData.pending, (state) => {
            state.query.fetchBaseData.status = 'pending'
        }).addCase(fetchBaseData.rejected, (state, action) => {
            state.query.fetchBaseData.status = "error"
            state.query.fetchBaseData.message = action.error.message;
            state.query.fetchBaseData.canExecute = true;
        }).addCase(fetchBaseData.fulfilled, (state, action) => {
            state.query.fetchBaseData.status = "success"
            state.query.fetchBaseData.canExecute = true;
            const baseData = action.payload.getData();
            state.loadedData.articleTypes = baseData.availableArticleTypes;
            state.loadedData.qualities = baseData.availableQualities;
        })
    }
})

export const {
    resetState,
    updateQuality,
    updateArticleType,
    completeFetchPreviewError,
} = getSerialSizesPreviewSlice.actions

export default getSerialSizesPreviewSlice.reducer