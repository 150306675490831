import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseErrors } from "shared/networking/error_handling";
import AdditionService from 'services/addition.service';
import PolicyServiceAPI from 'services/policy.service';
import MassPointService from 'services/masspoint.service';
import { PolicyTypeEnum } from "models/policies/policy_type.enum";
import { RootState } from "app/redux_store";

export const getAdditions = createAsyncThunk(
    'policy/update_policy_exclude_addition_by_masspoint_value/getAdditions',
    async (id: string) => {
        const response = await AdditionService.getAvailableAdditionsByPolicyTypeOverview(PolicyTypeEnum.ExcludeAdditionByMasspointValue, id);
        handleResponseErrors(response, "Zusätze");
        return response;
    }
)

export const getMassPoints = createAsyncThunk(
    'policy/update_policy_exclude_addition_by_masspoint_value/getMassPoints',
    async (_) => {
        const response = await MassPointService.getMassPoints()
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)

export const getPolicy = createAsyncThunk(
    'policy/update_policy_exclude_addition_by_masspoint_value/getPolicy',
    async (id: string) => {
        const response = await PolicyServiceAPI.getExcludeAdditionByMasspointValue(id);
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const updatePolicy = createAsyncThunk(
    'policy/update_policy_exclude_addition_by_masspoint_value/savepolicy',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.policy_exclude_addition_by_masspoint_value_update.data;
        const response = await PolicyServiceAPI.updateExcludeAdditionByMasspointValue({
            policyId: data.policy.id,
            name: data.policy.name,
            ignoreOnQuotation: data.policy.ignoreOnQuotation,
            additionId: data.selectedAddition.id,
            masspoint: 
            {
                name: data.selectedMasspoint.name,
                type: data.selectedMasspoint.massPointType,
                bodyAreaId: data.selectedMasspoint.bodyAreaId
            },
            thresholdValue: data.thresholdValue
        });
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'policy/update_policy_exclude_addition_by_masspoint_value/cancelSave',
    async (_) => { }
)


export const initData = createAsyncThunk(
    'policy/update_policy_exclude_addition_by_masspoint_value/initData',
    async (id: string, { dispatch }) => {
        await Promise.all([
            dispatch(getAdditions(id)),
            dispatch(getMassPoints()),
        ]);
        await dispatch(getPolicy(id));
    }
)

