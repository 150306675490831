import styled from "@emotion/styled";

export const AccountAreaBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const AccountInfoBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 20px;
`;

export const AccountNameBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AccountName = styled.div``;

export const AccountUserName = styled.div`
  font-size: small;
`;