import { Grid } from "@mui/material";
import { IAdditionCategoryOverview } from "models/addition_categories/addition_category_overview";
import { DropdownComponent } from "shared/shared";
import { ErrorTextComponent } from "./policy_mandatory_category_editor.style";

interface PolicyMandatoryCategoryEditor {
    selectedCategory: IAdditionCategoryOverview;
    allCategories: IAdditionCategoryOverview[];
    select:(category: IAdditionCategoryOverview) => void;
}

export const PolicyMandatoryCategoryEditorComponent: React.FC<PolicyMandatoryCategoryEditor> = ({ selectedCategory, allCategories, select }) => {
    return (
        <Grid item container md={12}
            direction="row">
            <DropdownComponent name="Zusatzkategorie"
                data={allCategories}
                minWidth="350px"
                isRequired={true}
                onSelect={(e) => select(e)}
                displayedProperties={["categoryText"]}
                selectedValue={selectedCategory}                           
            />
            { allCategories.length === 0 ?
                <ErrorTextComponent>
                    Es kann keine Zusatzkategorie zugewiesen werden.
                </ErrorTextComponent> : <></>
            }
        </Grid>
    );
};