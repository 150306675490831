import { BodySideEnum } from 'models/masspoints/enums/body_side.enum';
import { MasspointTypeEnum } from 'models/masspoints/enums/masspoint_type.enum';
import { RuleTypeEnum } from '../plausibility_check/enums/rule_type.enum';

export interface ProductConfigurationEvaluation {
    productConfigurationId: string;
    qualityResults: QualityResult[]   
}

export interface QualityResult {
    massPointEvaluationResult: MassPointEvaluationResult;
}

export interface MassPointEvaluationResult {
    massDefinitionEvaluations: MassDefinitionEvaluation[];
}

export interface MassDefinitionEvaluation {
    massDefinitionVersionId: number;
    massPoints: MasspointEvaluation[];
}

export interface MasspointEvaluation {
    id: number;
    value: number;
    name: string;
    massPointType: MasspointTypeEnum;
    massPointTypeName: string;
    bodyAreaId: number;
    bodyAreaName: string;
    bodySide: BodySideEnum;
    bodySideName: string;
    evaluationResult: EvaluationResultEnum;
    evaluationResultName: string;
    massPointTestRuns: MasspointEvaluationTestRun[]
}

export interface MasspointEvaluationTestRun {
    id: number;
    value: number;
    evaluationResult: EvaluationResult;
    matchedCheck: MassPointMatchedCheck;
}

export interface EvaluationResult {
    result: EvaluationResultEnum;
    name: string;
    message: string;
}

export interface MassPointMatchedCheck {
    checkId: number;
    mainProductLineId: number;
    mainProductLineName: string;
    bodyAreaId?: number;
    bodyAreaName: string;
    articleTypeId?: number;
    articleTypeName: string;
    qualityId?: number;
    qualityName: string;
    checkTypeId: number;
    checkTypeName: string;
}

export interface MassPointMatchedMinMaxRule {
    ruleType: RuleTypeEnum;
    ruleTypeName: string;
    massPointName: string;
    massPointType: MasspointTypeEnum;
    massPointTypeName: string;
    bodySide?: BodySideEnum;
    bodySideName: string;
    errorMinValue?: number;
    errorMaxValue?: number;
    warningMinValue?: number;
    warningMaxValue?: number;
}

export interface MassPointMatchedFormulaRule {
    ruleType: RuleTypeEnum;
    ruleTypeName: string;
    massPointName: string;
    massPointType: MasspointTypeEnum;
    massPointTypeName: string;
    bodySide?: BodySideEnum;
    bodySideName: string;
    formula: string;
}

export enum EvaluationResultEnum {
    Undefined = 0,
    Success = 1,
    Warning = 2,
    Error = 3,
    Invalid = 4,
    MassPointsNotFound = 5
}