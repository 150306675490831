import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseErrors } from "shared/networking/error_handling";
import AdditionCategoryService from 'services/addition_category.service';
import PolicyServiceAPI from '../../../../../services/policy.service';
import { RootState } from "app/redux_store";
import { PolicyTypeEnum } from "models/policies/policy_type.enum";

export const getAdditionCategories = createAsyncThunk(
    'policy/update_policy_mandatory_category/getAdditionCategories',
    async (id: string) => {
        const response = await AdditionCategoryService.getAvailableAdditionCategoryByPolicyTypeOverview(PolicyTypeEnum.MandatoryCategory, id);
        handleResponseErrors(response, "Zusatzekategorien");
        return response;
    }
)

export const getPolicy = createAsyncThunk(
    'policy/update_policy_mandatory_category/getPolicy',
    async (id: string) => {
        const response = await PolicyServiceAPI.getMandatoryCategory(id);
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const updatePolicy = createAsyncThunk(
    'policy/update_policy_mandatory_category/savepolicy',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.policy_mandatory_category_update.data;
        const response = await PolicyServiceAPI.updateMandatoryCategory({
            policyId: data.policy.id,
            name: data.policy.name,
            ignoreOnQuotation: data.policy.ignoreOnQuotation,
            additionCategoryId: data.selectedCategory.id
        });
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'policy/update_policy_mandatory_category/cancelSave',
    async (_) => { }
)


export const initData = createAsyncThunk(
    'policy/update_policy_mandatory_category/initData',
    async (id: string, { dispatch }) => {
        await Promise.all([
            dispatch(getAdditionCategories(id)),
        ]);
        await dispatch(getPolicy(id));
    }
)

