
import { Grid } from "@mui/material";
import { EditorConfigurationComponent } from "shared/components/editors/list_editor/editor_configuration.component";
import { ListEditorComponent } from "shared/components/editors/list_editor/list_editor.component";
import { DropdownComponent, FlexBox, Left } from "shared/shared";
import { IDefaultByAttributeConfigViewModel } from "../models/policy_mandatory_by_attribute.models";
import { doesExist, isNullOrWhitespace } from "services/validation.service";
import { PolicyMandatoryByAttributeConfigComponent } from "./policy_mandatory_by_attribute_config.component";
import { ListEditorMode } from "shared/components/editors/list_editor/enums/EditorMode";
import { EditorSummaryDefinition } from "shared/components/editors/list_editor/models/EditorSummaryDefinition";
import { isValidConfigWithSubConfigs, isValidConfigWithSubConfigsByIndex } from "../common/helpers";
import { useAppSelector, useAppDispatch } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { cancelConfig, deleteConfig, editConfig, newConfig, selectAttribute, selectCategory, takeConfig } from "../redux/policy_mandatory_by_attribute.slice";

export const PolicyMandatoryByAttributeEditorComponent: React.FC = () => {
    const state = useAppSelector((state) => state.policy_mandatory_by_attribute);
    const dispatch: AppDispatch = useAppDispatch();

    const renderContent = () => {
        return (
            <PolicyMandatoryByAttributeConfigComponent />
        );
    };

    const _isValidConfigWithSubConfigsByIndex = (index: number) => {
        const config = state.data.editor.configs[index];
        const mappedBaseData = state.loadedData.mappedBaseDatasByAddition.find(x => x.additionId === config.addition.id);
        const isMinMaxPlaceholder = Boolean(config.addition?.placeholder?.twoRangePlaceholder);
        return isValidConfigWithSubConfigsByIndex(index, state.data.editor.configs, mappedBaseData, isMinMaxPlaceholder);
    }

    const isEditableConfigValid = () => {
        const editableConfig = state.data.editor.editableConfig;
        const mappedBaseData = state.loadedData.mappedBaseDatasByAddition.find(x => x.additionId === editableConfig?.addition?.id);
        const isMinMaxPlaceholder = Boolean(editableConfig.addition?.placeholder?.twoRangePlaceholder);
        return !doesExist(state.data.editor.editableSubConfig) && isValidConfigWithSubConfigs(state.data.editor.editableConfig, mappedBaseData, isMinMaxPlaceholder);
    }

    const renderConfig = () => {
        return <EditorConfigurationComponent
            renderContent={renderContent}
            disabledTakeConfiguration={!isEditableConfigValid()}
            disabledEditorButtons={doesExist(state.data.editor.editableSubConfig)}
            onTakeConfiguration={() => dispatch(takeConfig())}
            onCancelConfiguration={() => dispatch(cancelConfig())}
        />
    }

    const editorSummaryDefinitions: EditorSummaryDefinition[] = [{
        heading: "Zusätze",
        flex: 1,
        displayValue: (value: IDefaultByAttributeConfigViewModel) => value.addition.option
    }];

    const getEditorMode = () => {
        if (doesExist(state.data.editor.editableConfig)) {
            return state.data.editor.editableConfigIndex === -1 ? ListEditorMode.New : ListEditorMode.Edit;
        }
        else {
            return ListEditorMode.None;
        }
    }

    return (
        <Grid item container md={12}
            direction="column"
            spacing={3}>
            <Grid item>
                <FlexBox>
                    <Left>
                        <DropdownComponent name="Attribute Kategorie"
                            isReadonly={isNullOrWhitespace(state.data.editor.policy.name)}
                            data={state.loadedData.attributeCategories}
                            isRequired={true}
                            onSelect={(cat) => dispatch(selectCategory(cat))}
                            displayedProperties={["erpId", "name"]}
                            selectedValue={state.data.editor.selectedCategory}
                        />
                        <DropdownComponent name="Attribute"
                            isReadonly={state.data.editor.selectedCategory == null}
                            data={state.data.editor.selectedCategory?.availableAdditionAttributes}
                            isRequired={true}
                            onSelect={(att) => dispatch(selectAttribute(att))}
                            displayedProperties={["code", "name"]}
                            selectedValue={state.data.editor.selectedAttribute}
                        />
                    </Left>
                </FlexBox>
            </Grid>
            {
                state.data.editor.selectedAttribute != null &&
                <Grid item md={12}>
                    <ListEditorComponent
                        addButtonText="Neue Konfiguration hinzufügen"
                        editorSummaryDefinitions={editorSummaryDefinitions}
                        items={state.data.editor.configs}
                        isValid={_isValidConfigWithSubConfigsByIndex}
                        onEditConfiguration={(index) => dispatch(editConfig(index))}
                        onDeleteConfiguration={(index) => dispatch(deleteConfig(index))}
                        onNewConfiguration={() => dispatch(newConfig())}
                        renderConfiguration={state.data.editor.editableConfig != null ? renderConfig : null}
                        editorMode={getEditorMode()}
                        editableConfigurationIndex={state.data.editor.editableConfigIndex}
                    />
                </Grid>
            }
        </Grid>
    );
};