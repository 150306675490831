import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PolicyDefaultAdditionByQualityAndAttributeCreateState } from './policy_default_addition_by_qu_and_att_create.model';
import { 
    calculateAvailableAttributes,
    createAdditions,
    createAttributeCategory,
    moveFromSelectedToUnselected,
    moveFromUnselectedToSelected,
    updateCanSave,
} from './policy_default_addition_by_qu_and_att_create.reducer';
import { cancelSave, getAdditions, getAvailableAttributeCategories, getBaseData, initData, savePolicy } from './policy_default_addition_by_qu_and_att_create.thunks';
import { sortAdditionOverviews, sortQualities } from 'shared/sort/sorting';
import IAvailableQuality from 'models/available_basedata/available_quality';
import { IAdditionSelection, IAttributeCategorySelection, IAttributeSelection, IDefaultAdditionByQualityAndAttributeConfigurationViewModel, ISearchAddition } from '../../components/policy_default_addition_by_qu_and_att.model';
import { filterSelectionList } from 'shared/components/selectionComponent/selectionList.helper';

const initialState: PolicyDefaultAdditionByQualityAndAttributeCreateState = {
    data: { 
        policy: { name: "", ignoreOnQuotation: false},  
        selectedQuality: null,
        attributeConfigurations: [],
    },
    loadedData: {
        qualities: [],
        attributeCategories: [],
        additions: [],
    },
    command:{
        savePolicy: { status: "idle", canExecute: false },
        cancelSave: { status: "idle", canExecute: true }
    },
    query: {
        fetchAdditions: { status: "idle", canExecute: true },
        fetchBaseData: { status: "idle", canExecute: true },        
        fetchAttributes: { status: "idle", canExecute: true },       
        initData: { status: "idle", canExecute: true }
    }
}

export const createPolicyDefaultAdditionByQualityAndAttribute = createSlice({
    name: 'create_policy_default_addition_by_quality_and_attribute',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = initialState.data;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        changeName: (state, action: PayloadAction<string>) => {
            state.data.policy.name = action.payload;
            updateCanSave(state);
        },
        selectQuality: (state, action: PayloadAction<IAvailableQuality>) => {
            state.data.selectedQuality = action.payload;
            updateCanSave(state);
        },
        addConfiguration: (state) => {
            const nextIndex = state.data.attributeConfigurations.length + 1;
            let newConfigurations = [];
            state.data.attributeConfigurations.forEach(x => {
                newConfigurations.push(x);
            });

            const configuration : IDefaultAdditionByQualityAndAttributeConfigurationViewModel = {
                index: nextIndex,
                attributeCategoryValues: createAttributeCategory(state),
                selectedAttributeCategoryValue: null,
                attributeValues: null,
                selectedAttributeValue: null,
                additions: {
                    selectedList: {
                        allItems: [],
                        filteredItems:[],
                        searchText: ""
                    },
                    unSelectedList: createAdditions(state.loadedData.additions)
                }
            };
            filterSelectionList(configuration.additions.selectedList);
            filterSelectionList(configuration.additions.unSelectedList);
            newConfigurations.push(configuration);
            state.data.attributeConfigurations = calculateAvailableAttributes(newConfigurations, state.loadedData.attributeCategories);;
            updateCanSave(state);
        },  
        deleteConfiguration: (state, action: PayloadAction<number>) => {
            let nextIndex = 1;
            const deletedItemIndex = action.payload;
            let newAttributeConfigurations = [];
            state.data.attributeConfigurations.forEach(x => {
                if(x.index !== deletedItemIndex)
                {
                    x.index = nextIndex;
                    newAttributeConfigurations.push(x);
                    nextIndex++;
                }
            });
            state.data.attributeConfigurations = calculateAvailableAttributes(newAttributeConfigurations, state.loadedData.attributeCategories);
            updateCanSave(state);
        },
        selectAttributeCategory: (state, action: PayloadAction<IAttributeCategorySelection>) => {
            const index = action.payload.index - 1;
            const configuration = state.data.attributeConfigurations[index];
            configuration.selectedAttributeCategoryValue = action.payload.item;
            configuration.selectedAttributeValue = null;
            state.data.attributeConfigurations = calculateAvailableAttributes(state.data.attributeConfigurations, state.loadedData.attributeCategories);
            updateCanSave(state);
        },     
        selectAttribute: (state, action: PayloadAction<IAttributeSelection>) => {
            const index = action.payload.index - 1;
            const configuration = state.data.attributeConfigurations[index];
            configuration.selectedAttributeValue = action.payload.item;
            state.data.attributeConfigurations = calculateAvailableAttributes(state.data.attributeConfigurations, state.loadedData.attributeCategories);
            updateCanSave(state);
        },                   
        selectAdditions: (state, action: PayloadAction<IAdditionSelection>) => {
            const index = action.payload.index - 1;
            const configuration = state.data.attributeConfigurations[index];
            moveFromUnselectedToSelected(configuration, action.payload.ids);
            updateCanSave(state);
        },      
        unSelectAdditions: (state, action: PayloadAction<IAdditionSelection>) => {
            const index = action.payload.index - 1;
            const configuration = state.data.attributeConfigurations[index];
            moveFromSelectedToUnselected(configuration, action.payload.ids);
            updateCanSave(state);
        },   
        filterSelectedAdditions: (state, action: PayloadAction<ISearchAddition>) => {
            const index = action.payload.index - 1;
            const configuration = state.data.attributeConfigurations[index];
            configuration.additions.selectedList.searchText = action.payload.searchText;
            filterSelectionList(configuration.additions.selectedList);
        },
        filterUnselectedAdditions: (state, action: PayloadAction<ISearchAddition>) => {
            const index = action.payload.index - 1;
            const configuration = state.data.attributeConfigurations[index];
            configuration.additions.unSelectedList.searchText = action.payload.searchText;
            filterSelectionList(configuration.additions.unSelectedList);
        },    
        toggleIgnoreOnQuotation: (state, action: PayloadAction<boolean>) => {
            state.data.policy.ignoreOnQuotation = action.payload;
            updateCanSave(state);
        },                                  
        completedSave: (state) => {
            state.command.savePolicy = initialState.command.savePolicy;
        }
    }, extraReducers: (builder) => {

        // fetchAdditions
        builder.addCase(getAdditions.pending, (state) => {
            state.query.fetchAdditions.status = "pending"
            state.query.fetchAdditions.canExecute = false;
        }).addCase(getAdditions.rejected, (state, action) => {
            state.query.fetchAdditions.status = "error"
            state.query.fetchAdditions.message = action.error.message;
            state.query.fetchAdditions.canExecute = true;
        }).addCase(getAdditions.fulfilled, (state, action) => {
            state.query.fetchAdditions.status = "success"
            state.query.fetchAdditions.canExecute = true;
            state.loadedData.additions = action.payload.getData().sort(sortAdditionOverviews);
            updateCanSave(state);

        // fetchBaseData
        }).addCase(getBaseData.pending, (state) => {
            state.query.fetchBaseData.status = "pending"
            state.query.fetchBaseData.canExecute = false;
        }).addCase(getBaseData.rejected, (state, action) => {
            state.query.fetchBaseData.status = "error"
            state.query.fetchBaseData.message = action.error.message;
            state.query.fetchBaseData.canExecute = true;
        }).addCase(getBaseData.fulfilled, (state, action) => {
            state.query.fetchBaseData.status = "success"
            state.query.fetchBaseData.canExecute = true;
            state.loadedData.qualities = action.payload.getData().sort(sortQualities);    
        
        // fetchAttributes
        }).addCase(getAvailableAttributeCategories.pending, (state) => {
            state.query.fetchAttributes.status = "pending"
            state.query.fetchAttributes.canExecute = false;
        }).addCase(getAvailableAttributeCategories.rejected, (state, action) => {
            state.query.fetchAttributes.status = "error"
            state.query.fetchAttributes.message = action.error.message;
            state.query.fetchAttributes.canExecute = true;
        }).addCase(getAvailableAttributeCategories.fulfilled, (state, action) => {
            state.query.fetchAttributes.status = "success"
            state.query.fetchAttributes.canExecute = true;
            state.loadedData.attributeCategories = action.payload.getData();  

        // savePolicy
        }).addCase(savePolicy.pending, (state) => {
            state.command.savePolicy.status = 'pending'
            state.command.savePolicy.canExecute = false;
        }).addCase(savePolicy.rejected, (state, action) => {
            state.command.savePolicy.status = "error"
            state.command.savePolicy.canExecute = true;
            state.command.savePolicy.message = action.error.message;
        }).addCase(savePolicy.fulfilled, (state) => {
            state.command.savePolicy.status = "success"
            state.command.savePolicy.canExecute = false;

        // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancelSave.status = 'pending'
            state.command.cancelSave.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancelSave.status = "success"
            state.command.cancelSave.canExecute = false;

        // initData
        }).addCase(initData.pending, (state) => {
            state.query.initData.status = "pending"
            state.query.initData.canExecute = false;
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.canExecute = true;
            state.query.initData.message = action.error.message;
        }).addCase(initData.fulfilled, (state) => {
            state.query.initData.status = "success"
            state.query.initData.canExecute = true;
            updateCanSave(state);
        })
    }
})

export const {
    resetState,
    changeName,
    addConfiguration,
    deleteConfiguration,
    selectAttributeCategory,
    selectAttribute,
    selectQuality,
    filterSelectedAdditions,
    filterUnselectedAdditions,
    selectAdditions,
    unSelectAdditions,
    toggleIgnoreOnQuotation,
    completedSave
} = createPolicyDefaultAdditionByQualityAndAttribute.actions

export default createPolicyDefaultAdditionByQualityAndAttribute.reducer