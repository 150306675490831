import { Grid, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { EditorSummaryHeadline, EditorSummaryItem, EditorSummaryItemContainer } from "./list_editor.style"
import colors from "shared/style/colors";
import { doesExist } from "services/validation.service";
import { ListEditorMode } from "./enums/EditorMode";
import { EditorSummaryDefinition } from "./models/EditorSummaryDefinition";
import { ReactNode } from "react";

interface ListEditorSummaryProps {
    editorSummaryDefinitions?: EditorSummaryDefinition[];
    items: Array<any>;
    onEdit: (index: number) => void;
    onDelete: (index: number) => void;
    editorMode: ListEditorMode;
    renderConfiguration: () => ReactNode;
    editableConfigurationIndex: number;
    isValid?: (index: number) => boolean;
    isNested?: boolean;
}

export const ListEditorSummaryComponent = (props: ListEditorSummaryProps) => {

    const renderEditor = () => {
        return <Grid item container direction={"column"} justifyContent={"center"}>
            {props.renderConfiguration()}
        </Grid>
    }

    const renderSummary = (it, index) => {
        return (
            <EditorSummaryItemContainer
                backgroundColor={props.isNested ? "whitesmoke" : colors.paper}
                showRedBorder={doesExist(props.isValid) ? !props.isValid(index) : false}
                key={`summary_editor_id_${index}`}>
                {
                    props.editorSummaryDefinitions?.length > 0 && props.editorSummaryDefinitions?.map((esd, sdIndex) =>
                        <EditorSummaryItem width={esd.width} flex={esd.flex} key={sdIndex}>
                            {esd.displayValue(it)}
                        </EditorSummaryItem>)
                }
                {
                    !props.editorSummaryDefinitions &&
                    <EditorSummaryItem flex={1}>
                        {"Konfiguration " + (index + 1)}
                    </EditorSummaryItem>
                }
                <EditorSummaryItem width={100}>
                    <IconButton
                        disabled={props.editorMode !== ListEditorMode.None}
                        style={{ outline: "none" }}
                        onClick={() => props.onEdit(index)}>
                        <EditIcon />
                    </IconButton>
                </EditorSummaryItem>
                <EditorSummaryItem width={100}>
                    <IconButton
                        disabled={props.editorMode !== ListEditorMode.None}
                        style={{ outline: "none" }}
                        onClick={() => props.onDelete(index)}>
                        <DeleteIcon />
                    </IconButton>
                </EditorSummaryItem>
            </EditorSummaryItemContainer>
        );
    };

    const renderItems = () => {
        return props.items.map((it, index) =>
            <Grid item container direction={"row"} key={index}>
                {props.editableConfigurationIndex === index ? renderEditor() : renderSummary(it, index)}
            </Grid>
        )
    };

    return (
        <Grid item container direction={"column"} justifyContent={"center"}>
            <Grid item container direction={"row"} key={"summary_list"}>
                {
                    props.editorSummaryDefinitions?.length > 0 && props.editorSummaryDefinitions?.map((esd, index) =>
                        <EditorSummaryHeadline width={esd.width} flex={esd.flex} key={index}>
                            {esd.heading}
                        </EditorSummaryHeadline>
                    )
                }
                {
                    !props.editorSummaryDefinitions &&
                    <EditorSummaryHeadline flex={1}>
                        Konfigurationen
                    </EditorSummaryHeadline>

                }
                <EditorSummaryHeadline width={100}>
                    ändern
                </EditorSummaryHeadline>
                <EditorSummaryHeadline width={100}>
                    löschen
                </EditorSummaryHeadline>
            </Grid>
            {
                props.items.length > 0 &&
                renderItems()
            }
        </Grid>
    )

};