import { BodySideEnum } from "models/masspoints/enums/body_side.enum";
import { MasspointTypeEnum } from "models/masspoints/enums/masspoint_type.enum";
import { doesExist } from "../../../services/validation.service";
import { MasspointCreateState } from "./masspoint_create.model";

export const updateCanCreateMasspoint = (state: MasspointCreateState) => {

        const maxNameLength = 5;
        state.command.createMasspoint.canExecute = (
                doesExist(state.data.masspoint.name)
                && state.data.masspoint.name.trim().length <= maxNameLength
                && state.data.masspoint.massPointType !== MasspointTypeEnum.Undefined
                && state.data.masspoint.bodyArea != null
                && state.data.masspoint.bodyArea.id > 0
                && state.data.masspoint.bodySide !== BodySideEnum.None
                && state.data.masspoint.isPantyTopWithStandardDimensions != null
                && state.data.masspoint.isEllipticalShapeKneeFunctionZone != null);
}