import { MassimageUploadComponent } from "shared/components/upload/massimage_upload.component";
import * as style from "./update_massimage.style";
import { useEffect } from "react";
import { AppDispatch } from '../../../app/redux_store';
import { useAppDispatch, useAppSelector } from '../../../app/hook';
import { useDialog } from "shared/contexts/dialog_context";
import { useSnackbar } from "notistack";
import { IParsedMassPoint } from "models/masspoints/parsed_masspoint";
import { MissingMasspointsComponent } from "shared/components/dialogs/missing_masspoints/missing_masspoints.component";
import { initCurrentMassImage, resetState, updateFile, updateName } from "./redux/update_massimage.slice";
import { updateMassimage } from "./redux/update_massimage.thunks";
import { LoadingOverlayComponent, LoadingOverlayContainer } from "shared/components/loading_overlay/loading_overlay.component";
import { useNavigate, useParams } from "react-router-dom";

export const UpdateMassImageComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.update_massimage);
    const { massImageId } = useParams();

    const dialog = useDialog();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        dispatch(initCurrentMassImage(Number(massImageId)));
    }, [dispatch, massImageId]);

    const errorDialog = (notExistingMassPoints: IParsedMassPoint[], masspointsNotInNewImage: IParsedMassPoint[]) => {
        dialog.show(
            "Ungültiges Maßbild",
            <MissingMasspointsComponent masspoints={notExistingMassPoints} masspointsNotInNewImage={masspointsNotInNewImage} />,
            [{
                title: "Ok",
                handleOption: () => { }
            }]);
    }

    const handleSelectFile = (file: File) => {
        dispatch(updateFile(file));
    }

    const handleUpload = () => {
        dispatch(updateMassimage());
    }

    if (state.command.uploadImage.status === "success") {
        enqueueSnackbar("Maßbild und Maßmasken erfolgreich aktualisiert", { variant: "success" });
        navigate("/admin/massimages");
        dispatch(resetState());
        dispatch(initCurrentMassImage(Number(massImageId)));
    }

    if (state.command.uploadImage.status === "error") {

        if(state.uploadError.isUnprocessableEntityException){
            errorDialog(state.uploadError.uploadErrorData.notExistingMassPoints, state.uploadError.uploadErrorData.notInNewImageAvailable);
        }
        else {
            enqueueSnackbar(state.command.uploadImage.message, { variant: "error" });
        }
        dispatch(resetState());
        dispatch(initCurrentMassImage(Number(massImageId)));
    }

    const isLoading = state.command.uploadImage.status === "pending";
    var isError = state.command.uploadImage.status === "error";

    return (
        <>
            <LoadingOverlayContainer>
                {isLoading ? <LoadingOverlayComponent /> : <></>}

                <style.InfoArea>
                    <style.Title>Hinweise:</style.Title>
                    <style.Description>Alle Maßpunkte des neuen Maßbildes müssen im System angelegt sein.</style.Description>
                    <style.Description>Im alten Maßbild vorhandene Maßpunkte müssen auch im neuen Maßbild enthalten sein.</style.Description>
                </style.InfoArea>
                <MassimageUploadComponent
                    onFileSelected={(e) => handleSelectFile(e.target.files[0])}
                    onUpload={() => handleUpload()}
                    onNameChange={(name) => dispatch(updateName(name))}
                    name={state.data.name}
                    isError={isError}
                    isUploading={state.data.isUpdating}
                    isUploadAllowed={state.command.uploadImage.canExecute} />
            </LoadingOverlayContainer>
        </>
    );
}