import React from "react";
import { useImperativeHandle, useRef, useEffect, forwardRef } from "react";
import { EvaluationsTableComponent } from '../components/evaluations_table.component';
import { MasspointEvaluation } from "models/simulator/product_configuration_evaluation";
import { Grid, Switch } from '@mui/material';
import { EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";
import { EditorDescriptionComponent } from "shared/components/editors/editor_description/editor_description.style";
import { AdditionsWrapperComponent } from './additions_wrapper.component';
import { EvaluationState, ISimulationAdditionCategoryAttributeViewModel } from '../redux/simulator_simulation.model';
import { ProductionInstructionsWrapperComponent } from "./production_instructions_wrapper.component";
import { AdditionsEvaluatedEvent, AdditionsLoadedEvent, ComponentDataEvent, ProductionInstructionEvaluatedEvent, ProductionInstructionLoadedEvent } from "@medi-mtm/components";
import { BoxItem, TabItem, TabItemSpacer, TabListItem } from "./simulation_tab.style";

interface SimulationTabComponentProps {
    evaluations: MasspointEvaluation[],
    updateShowEvaluationResultInMassmask: (updatedValue: boolean) => {};
    showEvaluationResultInMassmask: boolean;
    productConfigurationId: string;
    configurationRunId: number;
    mainProductLineErpId: string;
    bodyAreaErpId: string;
    countryId: string;
    articleTypeErpId?: string;
    qualityId?: string;
    footOptionExternalIdentifier?: number;
    evaluationState: EvaluationState;
    attributeCategoryDefinitions?: ISimulationAdditionCategoryAttributeViewModel[];
    useUnpublishedChecks: boolean;
    productConfigurationIsLocked: boolean;
    isMass: boolean;
    updateAdditionsEvaluating: () => {};
    updateAdditionsEvaluationFailed: (detail: any) => void;
    updateAdditionsEvaluated: (event: AdditionsEvaluatedEvent) => void;
    updateAdditionsLoading: () => {};
    updateAdditionsLoadingFailed: (detail: any) => void;
    updateAdditionsLoaded: (event: AdditionsLoadedEvent) => void;
    updateProductionInstructionsEvaluating: () => {};
    updateProductionInstructionsEvaluationFailed: (detail: any) => void;
    updateProductionInstructionsEvaluated: (event: ProductionInstructionEvaluatedEvent) => {};
    updateProductionInstructionsLoading: () => {};
    updateProductionInstructionsLoadingFailed: (detail: any) => void;
    updateProductionInstructionsLoaded: (event: ProductionInstructionLoadedEvent) => {};
    onAdditionsComponentState: (data: ComponentDataEvent) => void;
    onProductionInstructionComponentState: (data: ComponentDataEvent) => void;
}

export const SimulationTabComponent = forwardRef<any, SimulationTabComponentProps>((
    {
        evaluations,
        updateShowEvaluationResultInMassmask,
        showEvaluationResultInMassmask,
        productConfigurationId,
        configurationRunId,
        countryId,
        attributeCategoryDefinitions,
        qualityId,
        evaluationState,
        productConfigurationIsLocked,
        isMass,
        updateAdditionsEvaluating,
        updateAdditionsEvaluationFailed,
        updateAdditionsEvaluated,
        updateAdditionsLoading,
        updateAdditionsLoadingFailed,
        updateAdditionsLoaded,
        updateProductionInstructionsEvaluating,
        updateProductionInstructionsEvaluationFailed,
        updateProductionInstructionsEvaluated,
        updateProductionInstructionsLoading,
        updateProductionInstructionsLoadingFailed,
        updateProductionInstructionsLoaded,
        onAdditionsComponentState,
        onProductionInstructionComponentState
    }, ref) => {
    const componentRef = useRef(null) as any;
    const productionInstructionsRef = useRef(null) as any;

    useEffect(() => {
        switch (evaluationState) {
            case EvaluationState.loadedMassMask:
                setTabValue("1");
                break;
            case EvaluationState.evaluatedCustomDesign:
                setTabValue("2");
                break;
            case EvaluationState.loadingAdditions:
                setTabValue("2");
                break;
            case EvaluationState.loadedProductionInstructions:
                setTabValue("3");
                break;
            case EvaluationState.validatedProductionInstruction:
                setTabValue("3");
                break;
        }

    }, [evaluationState])

    useImperativeHandle(ref, () => ({
        evaluateAdditions() {
            if (componentRef.current != null) {
                const additionComponent = (componentRef.current as any);
                additionComponent.evaluateAdditions();
            }
        },
        evaluateProductionInstructions() {
            if (productionInstructionsRef.current != null) {
                const productionInstructionComponent = (productionInstructionsRef.current as any);
                productionInstructionComponent.evaluateProductionInstructions();
            }
        },
        initializeAdditions() {
            if (componentRef.current != null) {
                const additionComponent = (componentRef.current as any);
                additionComponent.initializeAdditions();
            }
        },
        initializeProductionInstructions() {
            if (productionInstructionsRef.current != null) {
                const productionInstructionComponentRef = (productionInstructionsRef.current as any);
                productionInstructionComponentRef.initializeProductionInstructions();
            }
        }
    }));

    const [tabValue, setTabValue] = React.useState('1');

    const handleTabChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    const testRunCount = evaluations
        .map(e => e.massPointTestRuns.length)
        .reduce((sum, tr) => sum + tr, 0);

    const getTabStyleForIndex = (indexOfTab: string) => {
        let isVisible = false;
        if (indexOfTab === tabValue) {
            isVisible = true;
        }
        return isVisible;
    }

    const setTabIndexAfterAdditionsRestore = (simulationRestore: ComponentDataEvent) => {
        onAdditionsComponentState(simulationRestore);
    }

    const setTabIndexAfterProductInstructionRestore = (simulationRestore: ComponentDataEvent) => {
        onProductionInstructionComponentState(simulationRestore);
    }

    return <div>
        <BoxItem>
            <TabListItem onChange={handleTabChange} value={tabValue}>
                <TabItem label="Angewandte Regeln" key="rules" aria-label=""
                    hidden={!(evaluationState >= EvaluationState.loadedMassMask && isMass)}
                    value='1' />
                <TabItem label="Zusätze" key="addition"
                    hidden={!(evaluationState >= EvaluationState.loadingAdditions)}
                    value='2' />
                <TabItem label="Produktionsanweisungen" key="productionInstructions"
                    hidden={!(evaluationState >= EvaluationState.loadProductionInstructions)}
                    value='3' />
                <TabItemSpacer />

            </TabListItem>

            {
                (evaluationState >= EvaluationState.loadedMassMask && isMass) ?
                    <Grid item
                        sx={getTabStyleForIndex("1") ? {
                            display: 'block',
                            paddingTop: '20px',
                        } : { display: 'none' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <EditorSectionHeaderComponent>Angewandte Regeln</EditorSectionHeaderComponent>
                                <EditorDescriptionComponent>Diese Simulation prüft die angegebenen Maßwerte auf Produzierbarkeit des Produktes.</EditorDescriptionComponent>
                            </Grid>
                            <Grid item xs={4}>
                                <EditorSectionHeaderComponent>Befund am Maßpunkt farbig markieren</EditorSectionHeaderComponent>
                                <Switch disabled={productConfigurationIsLocked} checked={showEvaluationResultInMassmask} onChange={(event) => updateShowEvaluationResultInMassmask(event.target.checked)}></Switch>
                            </Grid>
                        </Grid>
                        {testRunCount > 0
                            ? <EvaluationsTableComponent evaluations={evaluations} />
                            :
                            testRunCount === 0
                                ? <div>Keine passenden Regeln vorhanden</div>
                                : <div></div>
                        }
                    </Grid> : <></>
            }

            {
                (evaluationState >= EvaluationState.loadedMassMask && isMass)
                    || evaluationState >= EvaluationState.evaluatedCustomDesign ?
                    <Grid item
                        sx={getTabStyleForIndex("2") ? {
                            display: 'block',
                            paddingTop: '20px',
                        } : { display: 'none' }}>
                        <AdditionsWrapperComponent
                            ref={componentRef}
                            productConfigurationId={productConfigurationId}
                            configurationRunId={configurationRunId}
                            countryId={countryId}
                            qualityId={qualityId}
                            attributeCategoryDefinitions={attributeCategoryDefinitions}
                            onAdditionsComponentUpdated={setTabIndexAfterAdditionsRestore}
                            updateAdditionsEvaluating={updateAdditionsEvaluating}
                            updateAdditionsEvaluationFailed={updateAdditionsEvaluationFailed}
                            updateAdditionsEvaluated={updateAdditionsEvaluated}
                            updateAdditionsLoading={updateAdditionsLoading}
                            updateAdditionsLoadingFailed={updateAdditionsLoadingFailed}
                            updateAdditionsLoaded={updateAdditionsLoaded}
                        />
                    </Grid> : <></>
            }
            {
                evaluationState >= EvaluationState.loadedAdditions ?
                    <Grid item
                        sx={getTabStyleForIndex("3") ? {
                            display: 'block',
                            paddingTop: '20px',
                        } : { display: 'none' }}>
                        <ProductionInstructionsWrapperComponent
                            ref={productionInstructionsRef}
                            productConfigurationId={productConfigurationId}
                            configurationRunId={configurationRunId}
                            onProductionInstructionsComponentUpdated={setTabIndexAfterProductInstructionRestore}
                            updateProductionInstructionsEvaluating={updateProductionInstructionsEvaluating}
                            updateProductionInstructionsEvaluationFailed={updateProductionInstructionsEvaluationFailed}
                            updateProductionInstructionsEvaluated={updateProductionInstructionsEvaluated}
                            updateProductionInstructionsLoading={updateProductionInstructionsLoading}
                            updateProductionInstructionsLoadingFailed={updateProductionInstructionsLoadingFailed}
                            updateProductionInstructionsLoaded={updateProductionInstructionsLoaded}
                        />
                    </Grid>
                    : <></>
            }
        </BoxItem>
    </div >
});