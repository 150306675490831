import { createAsyncThunk } from '@reduxjs/toolkit';
import { OneLegService } from '../../../services/oneleg.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';
import { RootState } from 'app/redux_store';
import { IOneLegOverview } from 'models/onelegs/oneLeg_overview';

export const createOneLeg = createAsyncThunk(
    'massMask/oneLegs/overview/createOneLeg',
    async (_) => { }
)

export const editOneLeg = createAsyncThunk(
    'massMask/oneLegs/overview/editOneLeg',
    async (oneLeg: IOneLegOverview) => { return oneLeg }
)

export const getOneLegs = createAsyncThunk(
    'massMask/onelegs/overview/getOneLegs',
    async (_) => {
        const response = await OneLegService.getOneLegs();
        handleResponseErrors(response, "Einbeinhosen");
        return response;
    }
)

export const confirmDeleteOneLeg = createAsyncThunk(
    'massMask/onelegs/overview/confirmDeleteOneLeg',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const oneLegId = state.one_leg_overview.actualData.selectedOneLeg.id

        const deleteResponse = await OneLegService.deleteOneLeg(oneLegId);
        handleResponseErrors(deleteResponse, "Einbeinhosen");

        const oneLegsResponse = await OneLegService.getOneLegs();
        handleResponseErrors(oneLegsResponse, "Einbeinhosen");
        return oneLegsResponse;
    }
)