import { doesExist, doesNotStartOrEndWithWhitespace, isNotEmpty, isNotUndefinied } from "../../../services/validation.service";
import { ICheckTypeMessage } from 'models/plausibility_check/check_type_message';
import { ICheckTypeMessageEditModel, PlausibilityCheckTypeState } from "./type_edit.model";

export const updateCanEditPlausibilityCheckType = (state: PlausibilityCheckTypeState) => {
    state.command.editPlausibilityCheckType.canExecute = doesExist(state.editedData.plausibilityCheckType.name)
        && isNotEmpty(state.editedData.plausibilityCheckType.name)
        && doesNotStartOrEndWithWhitespace(state.editedData.plausibilityCheckType.name)
        && doesExist(state.editedData.plausibilityCheckType.id)
        && (
            state.editedData.plausibilityCheckType.name !== state.actualData.plausibilityCheckType.name
            || state.editedData.plausibilityCheckType.isBestMatchEnabled !== state.actualData.plausibilityCheckType.isBestMatchEnabled
            || state.editedData.plausibilityCheckType.warningMessageKey !== state.actualData.plausibilityCheckType.warningMessageKey
            ||state.editedData.plausibilityCheckType.errorMessageKey !== state.actualData.plausibilityCheckType.errorMessageKey
        )
        && isNotUndefinied(state.editedData.selectedWarningMessage)
        && isNotUndefinied(state.editedData.selectedErrorMessage)
}

export const toCheckTypeMessageEditModel = (message: ICheckTypeMessage): ICheckTypeMessageEditModel => {
    return {
        displayValue: message.key,
        message: message,
    };
}
