import React, { useEffect } from "react";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { MassmaskStepperComponent, MassmaskWizardStepEnum } from "massmasks/components/massmask.stepper.component";
import { CancelButtonComponent, DropdownComponent, FlexBox, PrimaryButtonComponent, Right } from "shared/shared";
import { useAppDispatch, useAppSelector } from "app/hook";
import { useSnackbar } from 'notistack';
import { editMassMask, fetchAllData, getMassMask } from './redux/massmask_common_info_edit.thunks';
import { AppDispatch } from "app/redux_store";
import { resetState, setMassMaskIsPantyTopWithStandardDimensions, setMassMaskIsEllipticalShapeKneeFunctionZone, selectBodyInformation, moveUp, moveDown, removeBodyInformation, addBodyInformation, setHideFingersAndToes } from "./redux/massmask_common_info_edit.slice";
import * as style from "./massmask_common_information_edit.style";
import { LoadingOverlayComponent, LoadingOverlayContainer } from '../../shared/components/loading_overlay/loading_overlay.component';
import Grid from "@mui/material/Grid";
import { useNavigate, useParams } from "react-router-dom";
import { Checkbox, FormControlLabel, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import AddIcon from '@mui/icons-material/Add';
import { ListButtonComponent } from "shared/components/buttons/list_button.component";

export const MassmaskCommonInfoEditComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const state = useAppSelector((state) => state.massmask_common_info_edit);
    const { massmaskId } = useParams();

    useEffect(() => {
        dispatch(fetchAllData(Number(massmaskId)));
        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    if (state.command.editMassMask.status === "error") {
        enqueueSnackbar(state.command.editMassMask.message, { variant: "error" });
        dispatch(resetState());
    }

    if (state.query.getMassMask.status === "error") {
        enqueueSnackbar(state.query.getMassMask.message, { variant: "error" });
        navigate(`/massmasks/massmask/overview`);
        dispatch(resetState());
    }

    if (state.command.editMassMask.status === "success") {
        enqueueSnackbar("Allgemeine Informationen gespeichert", { variant: "success" })
        navigate(`/massmasks/massmask/${state.actualData.massMask.id}/massdefinition`);
        dispatch(resetState());
    }

    const isSaving = state.command.editMassMask.status === "pending";

    const showAddBodyInformationButton = () => {
        return state.loadedData.bodyInformations.length > 0 &&
            state.actualData.bodyInformationSelection.length < state.loadedData.bodyInformations.length
    }

    const showBodyInformations = state.actualData.massMask?.articleType != null
        && state.actualData.massMask?.articleType != undefined;

    const renderBodyInformation = () => <>
        <Grid item>
            {
                state.actualData.bodyInformationSelection.map((value, index) => {
                    return (
                        <div key={index}>
                            <DropdownComponent
                                name="Zusätzliche Anweisungen"
                                data={state.loadedData.bodyInformations}
                                isLoading={false}
                                isRequired={true}
                                onSelect={(bodyInformation) => dispatch(selectBodyInformation({ id: bodyInformation.id, arrayPosition: index }))}
                                displayedProperties={["translationKeyForName"]}
                                selectedValue={state.loadedData.bodyInformations.find(x => x.id === value.id)}
                            />
                            <IconButton
                                style={{ outline: "none", marginLeft: "15px" }}
                                onClick={() => dispatch(moveUp(index))}>
                                <ArrowUpward />
                            </IconButton>
                            <IconButton
                                style={{ outline: "none", marginLeft: "15px" }}
                                onClick={() => dispatch(moveDown(index))}>
                                <ArrowDownward />
                            </IconButton>
                            <IconButton
                                style={{ outline: "none", marginLeft: "15px" }}
                                onClick={() => dispatch(removeBodyInformation(index))}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    );
                })
            }
        </Grid>
        {
            showAddBodyInformationButton() &&
            <Grid item
                style={{ marginTop: "15px", marginBottom: "15px" }}
                md={6}>
                <ListButtonComponent
                    onClick={() => dispatch(addBodyInformation())}>
                    <AddIcon /> Zusätzliche Angabe hinzufügen
                </ListButtonComponent>
            </Grid>
        }

    </>

    return (
        <>
            <LoadingOverlayContainer>
                {isSaving ? <LoadingOverlayComponent /> : <></>}

                <MassmaskStepperComponent currentStep={MassmaskWizardStepEnum.CommonInformation} />
                <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    spacing={3}>
                    <Grid
                        item
                        container
                        direction="row" md={6}>
                        <Grid item>
                            <DropdownComponent
                                name="Hauptproduktlinie"
                                data={[]}
                                isRequired={true}
                                onSelect={() => { }}
                                displayedProperties={["name"]}
                                selectedValue={state.actualData.massMask?.mainProductLine}
                                isReadonly={true}
                            />
                        </Grid>
                        <Grid item>
                            <DropdownComponent
                                name="Körperregion"
                                data={[]}
                                isRequired={true}
                                onSelect={() => { }}
                                displayedProperties={["name"]}
                                selectedValue={state.actualData.massMask?.bodyArea}
                                isReadonly={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        direction="row" md={6}>
                        <Grid item>
                            <DropdownComponent
                                name="Artikelart"
                                data={[]}
                                isRequired={false}
                                onSelect={() => { }}
                                displayedProperties={["erpId", "name"]}
                                selectedValue={state.actualData.massMask?.articleType}
                                isReadonly={true}
                            />
                        </Grid>
                        <Grid item
                            alignContent="center">
                            <style.OrWrapper>oder</style.OrWrapper>
                        </Grid>
                        <Grid item>
                            <DropdownComponent
                                name="Fußart"
                                data={[]}
                                isRequired={false}
                                onSelect={() => { }}
                                displayedProperties={["externalIdentifier", "name"]}
                                selectedValue={state.actualData.massMask?.footType}
                                isReadonly={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.actualData?.isPantyTopWithStandardDimensions}
                                    onChange={(event, value) => { dispatch(setMassMaskIsPantyTopWithStandardDimensions(value)) }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label={"Leibteil mit Standardmaßen"}
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.actualData?.isEllipticalShapeKneeFunctionZone}
                                    onChange={(event, value) => { dispatch(setMassMaskIsEllipticalShapeKneeFunctionZone(value)) }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label={"Ellipsenform/Kniefunktionszone"}
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.actualData?.hideFingersAndToes}
                                    onChange={(event, value) => { dispatch(setHideFingersAndToes(value)) }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label={"Finger & Zehen ausblenden"}
                        />
                    </Grid>
                </Grid>
                {
                    showBodyInformations &&
                    renderBodyInformation()
                }

                <style.FinalizeButtonComponentWrapper>
                    <FlexBox>
                        <Right>
                            <style.ButtonMargin>
                                <CancelButtonComponent />
                            </style.ButtonMargin>
                            <PrimaryButtonComponent
                                disabled={!state.command.editMassMask.canExecute}
                                onClick={() => dispatch(editMassMask())}
                            >
                                Weiter <ChevronRightIcon />
                            </PrimaryButtonComponent>
                        </Right>
                    </FlexBox>
                </style.FinalizeButtonComponentWrapper>
            </LoadingOverlayContainer>
        </>
    );
}