import { Grid } from "@mui/material";
import { SizeSummaryHeading } from "./serial_size_editor_size_summary_heading.component";
import { IAttributeConfigurationViewModel, IMassPointCriteriaSelection, IMassPointValueSelection, ISizeConfigurationViewModel, ISizeViewModel } from "./serial_size_editor.model";
import { SizeAccordionComponent } from "./serial_size_editor_size_summary_accordion.component";
import IAvailableSize from "models/available_basedata/available_size";

interface SizeSummaryProps {
    sizeSummaries: ISizeViewModel[];
    configurationSize: ISizeConfigurationViewModel;
    configurationAttribute: IAttributeConfigurationViewModel;
    editSize: (size: ISizeViewModel) => void;
    deleteSize: (size: ISizeViewModel) => void;
    selectSize: (size: IAvailableSize) => void;
    takeSize: () => void;
    cancelSize: () => void;
    addMasspoint: () => void;
    selectMasspoint: (masspoint: IMassPointCriteriaSelection) => void;
    updateValueFrom: (masspoint: IMassPointValueSelection) => void;
    updateValueTo: (masspoint: IMassPointValueSelection) => void;
    deleteMasspoint: (index: number) => void;
}

export const SizeSummaryComponent: React.FC<SizeSummaryProps> = (
    {
        sizeSummaries,
        configurationSize,
        configurationAttribute,
        editSize,
        deleteSize,
        selectSize,
        takeSize,
        cancelSize,
        addMasspoint,
        selectMasspoint,
        updateValueFrom,
        updateValueTo,
        deleteMasspoint
    }) => {

    return (
        <>
            <Grid spacing={3}
                direction="column"
                container
                justifyContent="space-between">
                <Grid item>
                    <SizeSummaryHeading />
                    <SizeAccordionComponent
                        sizeSummaries={sizeSummaries}
                        configurationSize={configurationSize}
                        configurationAttribute={configurationAttribute}
                        editSize={editSize}
                        deleteSize={deleteSize}
                        selectSize={selectSize}
                        takeSize={takeSize}
                        cancelSize={cancelSize}
                        addMasspoint={addMasspoint}
                        selectMasspoint={selectMasspoint}
                        updateValueFrom={updateValueFrom}
                        updateValueTo={updateValueTo}
                        deleteMasspoint={deleteMasspoint}
                    />
                </Grid>
            </Grid>
        </>
    );
};