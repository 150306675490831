import { Grid } from "@mui/material"
import { EditorConfigurationComponent } from "shared/components/editors/list_editor/editor_configuration.component";
import { ListEditorComponent } from "shared/components/editors/list_editor/list_editor.component";
import { ListEditorMode } from "shared/components/editors/list_editor/enums/EditorMode";
import { doesExist } from "services/validation.service";
import { ReactNode } from "react";
import { IArticleTypeConfig } from "models/position_category/position_category_editor";

export interface PositionCategoryItemConfigListComponentProps {
    configs: IArticleTypeConfig[];
    applyEditableConfig: () => void;
    cancelEditableConfig: () => void;
    addConfig: () => void;
    editConfig: (index: number) => void;
    deleteConfig: (index: number) => void;
    editableConfig: IArticleTypeConfig;
    editableConfigIndex: number;
    children: ReactNode;
}

export const PositionCategoryItemConfigListComponent = (props: PositionCategoryItemConfigListComponentProps) => {

    const renderContent = () => {
        return (
            <Grid item>
                {props.children}
            </Grid>
        )
    };

    const isEditableConfigValid = () => {
        return props.editableConfig.articleTypes.selectedList.allItems.length > 0 && props.editableConfig.positions.selectedList.allItems.length > 0;
    }

    const renderNewConfiguration = () => {
        return <EditorConfigurationComponent
            renderContent={renderContent}
            disabledTakeConfiguration={!isEditableConfigValid()}
            onTakeConfiguration={props.applyEditableConfig}
            onCancelConfiguration={props.cancelEditableConfig}
        />
    }

    const isValidScope = (index: number) => {
        return true;
    }

    const getEditorMode = () => {
        if (doesExist(props.editableConfig)) {
            return props.editableConfigIndex === -1 ? ListEditorMode.New : ListEditorMode.Edit;
        } else {
            return ListEditorMode.None;
        }
    }

    return (
        <div style={{ marginTop: "25px" }}>
            <ListEditorComponent
                addButtonText="Neue Konfiguration hinzufügen"
                items={props.configs}
                onEditConfiguration={props.editConfig}
                onDeleteConfiguration={props.deleteConfig}
                onNewConfiguration={props.addConfig}
                isValid={isValidScope}
                renderConfiguration={renderNewConfiguration}
                editorMode={getEditorMode()}
                editableConfigurationIndex={props.editableConfigIndex} />
        </div>
    )
}