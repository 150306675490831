import { Grid } from "@mui/material";
import { EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";
import { DropdownComponent, FlexBox, Left, PrimaryButtonComponent, Right } from "shared/shared";
import AddIcon from '@mui/icons-material/Add';
import IAvailableQuality from "models/available_basedata/available_quality";
import { IAttributeViewModel, IMassPointCriteriaSelection, IMassPointValueSelection, ISerialSizeArticleConfigurationViewModel, ISerialSizeArticleViewModel, ISerialSizeViewModel, ISizeViewModel } from "./serial_size_editor.model";
import { BoxItem } from "./serial_sizes.style";
import IAvailableArticleType from "models/available_basedata/available_article_type";
import { SerialSizeArticleTypeConfigurationComponent } from "./serial_size_editor_articletype_configuration.component";
import { SerialSizeSummaryComponent } from "./serial_size_editor_summary.component";
import IAvailableSize from "models/available_basedata/available_size";
import { IAvailableAdditionAttributeCategory } from "models/addition_attribute_categories/available_addition_attribute_category";
import { IAvailableAdditionAttribute } from "models/addition_attribute_categories/available_addition_attribute";

interface SerialSizeEditorProps {
    header: string;
    qualities: IAvailableQuality[];
    articleTypes: IAvailableArticleType[];
    serialSize: ISerialSizeViewModel;
    configurationArticleType: ISerialSizeArticleConfigurationViewModel;
    selectQuality: (quality: IAvailableQuality) => void;
    addArticleType: () => void;
    editArticleType: (articleType: ISerialSizeArticleViewModel) => void;
    deleteArticleType: (articleType: ISerialSizeArticleViewModel) => void;
    takeArticleType: () => void;
    cancelArticleType: () => void;
    selectArticleType: (articleType: IAvailableArticleType) => void;
    addSize: () => void;
    editSize: (size: ISizeViewModel) => void;
    deleteSize: (size: ISizeViewModel) => void;
    selectSize: (size: IAvailableSize) => void;
    takeSize: () => void;
    cancelSize: () => void;
    addMasspoint: () => void;
    selectMasspoint: (masspoint: IMassPointCriteriaSelection) => void;
    updateValueFrom: (masspoint: IMassPointValueSelection) => void;
    updateValueTo: (masspoint: IMassPointValueSelection) => void;
    deleteMasspoint: (index: number) => void;
    addAttribute: () => void;
    takeAttribute: () => void;
    editAttribute: (attribute: IAttributeViewModel) => void;
    deleteAttribute: (attribute: IAttributeViewModel) => void;
    selectCategory: (category: IAvailableAdditionAttributeCategory) => void;
    selectAttribute: (attribute: IAvailableAdditionAttribute) => void;
    cancelAttribute: () => void;
    addAttributeMasspoint: () => void;
    selectAttributeMasspoint: (masspoint: IMassPointCriteriaSelection) => void;
    updateAttributeValueFrom: (masspoint: IMassPointValueSelection) => void;
    updateAttributeValueTo: (masspoint: IMassPointValueSelection) => void;
    deleteAttributeMasspoint: (index: number) => void;
}

export const SerialSizeEditorComponent: React.FC<SerialSizeEditorProps> = (
    {
        header,
        qualities,
        articleTypes,
        serialSize,
        configurationArticleType,
        selectQuality,
        addArticleType,
        editArticleType,
        deleteArticleType,
        takeArticleType,
        cancelArticleType,
        selectArticleType,
        addSize,
        editSize,
        deleteSize,
        selectSize,
        takeSize,
        cancelSize,
        addMasspoint,
        selectMasspoint,
        updateValueFrom,
        updateValueTo,
        deleteMasspoint,
        addAttribute,
        takeAttribute,
        editAttribute,
        deleteAttribute,
        selectCategory,
        selectAttribute,
        cancelAttribute,
        addAttributeMasspoint,
        selectAttributeMasspoint,
        updateAttributeValueFrom,
        updateAttributeValueTo,
        deleteAttributeMasspoint
    }) => {

    const qualityIsSelected = serialSize.quality != null && serialSize.quality != undefined;
    const configurationActive = configurationArticleType != undefined;
    const disabledArticleType = (articleTypes.length <= serialSize.articleTypeViewModels.length
        || configurationActive) && qualityIsSelected;

    const isNewArticleType = configurationActive
        && configurationArticleType.isNewArticle;

    return (
        <>
            <Grid
                container
                spacing={3}
                direction="column">
                <Grid item>
                    <EditorSectionHeaderComponent>{header}</EditorSectionHeaderComponent>
                </Grid>
                <Grid item>
                    <FlexBox>
                        <Left>
                            <DropdownComponent name="Qualität"
                                data={qualities}
                                isRequired={true}
                                onSelect={(e) => selectQuality(e)}
                                displayedProperties={["erpId", "name"]}
                                selectedValue={serialSize.quality}
                            />
                        </Left>
                        {qualityIsSelected &&
                            <Right>
                                <PrimaryButtonComponent disabled={disabledArticleType} onClick={() => addArticleType()}>
                                    <AddIcon />Neuen Artikel konfigurieren
                                </PrimaryButtonComponent>
                            </Right>}
                    </FlexBox>
                </Grid>
                {qualityIsSelected &&
                    <Grid item>
                        <Grid item>
                            {isNewArticleType && <BoxItem>
                                <SerialSizeArticleTypeConfigurationComponent
                                    configurationArticleType={configurationArticleType}
                                    selectArticleType={selectArticleType}
                                    takeArticleType={takeArticleType}
                                    cancelArticleType={cancelArticleType}
                                    addSize={addSize}
                                    editSize={editSize}
                                    deleteSize={deleteSize}
                                    selectSize={selectSize}
                                    takeSize={takeSize}
                                    cancelSize={cancelSize}
                                    addMasspoint={addMasspoint}
                                    selectMasspoint={selectMasspoint}
                                    updateValueFrom={updateValueFrom}
                                    updateValueTo={updateValueTo}
                                    deleteMasspoint={deleteMasspoint}
                                    addAttribute={addAttribute}
                                    takeAttribute={takeAttribute}
                                    editAttribute={editAttribute}
                                    deleteAttribute={deleteAttribute}
                                    selectCategory={selectCategory}
                                    selectAttribute={selectAttribute}
                                    cancelAttribute={cancelAttribute}
                                    addAttributeMasspoint={addAttributeMasspoint}
                                    selectAttributeMasspoint={selectAttributeMasspoint}
                                    updateAttributeValueFrom={updateAttributeValueFrom}
                                    updateAttributeValueTo={updateAttributeValueTo}
                                    deleteAttributeMasspoint={deleteAttributeMasspoint}
                                />
                            </BoxItem>}
                        </Grid>
                        <Grid>
                            {serialSize.articleTypeViewModels.length > 0 &&
                                <SerialSizeSummaryComponent
                                    articleTypeSummaries={serialSize.articleTypeViewModels}
                                    configurationArticleType={configurationArticleType}
                                    editArticleType={editArticleType}
                                    deleteArticleType={deleteArticleType}
                                    selectArticleType={selectArticleType}
                                    takeArticleType={takeArticleType}
                                    cancelArticleType={cancelArticleType}
                                    addSize={addSize}
                                    editSize={editSize}
                                    deleteSize={deleteSize}
                                    selectSize={selectSize}
                                    takeSize={takeSize}
                                    cancelSize={cancelSize}
                                    addMasspoint={addMasspoint}
                                    selectMasspoint={selectMasspoint}
                                    updateValueFrom={updateValueFrom}
                                    updateValueTo={updateValueTo}
                                    deleteMasspoint={deleteMasspoint}
                                    addAttribute={addAttribute}
                                    takeAttribute={takeAttribute}
                                    editAttribute={editAttribute}
                                    deleteAttribute={deleteAttribute}
                                    selectCategory={selectCategory}
                                    selectAttribute={selectAttribute}
                                    cancelAttribute={cancelAttribute}
                                    addAttributeMasspoint={addAttributeMasspoint}
                                    selectAttributeMasspoint={selectAttributeMasspoint}
                                    updateAttributeValueFrom={updateAttributeValueFrom}
                                    updateAttributeValueTo={updateAttributeValueTo}
                                    deleteAttributeMasspoint={deleteAttributeMasspoint}
                                />
                            }
                        </Grid>
                    </Grid>
                }
            </Grid>
        </>
    );
};