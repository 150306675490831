import { Grid } from '@mui/material';
import { EditorSectionHeaderComponent } from 'shared/components/editors/editor_header/editor_header.style';
import { ListButtonComponent } from "shared/components/buttons/list_button.component";
import AddIcon from "@mui/icons-material/Add";
import { AdditionAttributeCategoryItemComponent } from './addition_attribute_category_item.component';
import { EditorDescriptionComponent } from 'shared/components/editors/editor_description/editor_description.style';
import { IAdditionEditor, IAdditionEditorAction, IAdditionInitial } from 'models/additions/addition_editor.model';

interface AdditionAttributeCategoriesProps {
    additionInitial: IAdditionInitial,
    additionEditor: IAdditionEditor,
    additionEditorActions: IAdditionEditorAction,
}

export const AdditionAttributeCategoriesComponent: React.FC<AdditionAttributeCategoriesProps> = (
    {
        additionInitial,
        additionEditor,
        additionEditorActions,
    }) => {
    return (<Grid container
        spacing={3}
        direction="column"
        justifyContent="space-between">
        <Grid item md={6}>
            <EditorSectionHeaderComponent>Attribute</EditorSectionHeaderComponent>
            <EditorDescriptionComponent>
                Bedingungen zu Hauptartikel. Legen Sie ein Attribut fest und wählen Sie aus, ob das Attribut als Einzugskriterium oder Ausschlusskriterium verwendet wird.
            </EditorDescriptionComponent>
            <EditorDescriptionComponent>
                Einbezug:
            </EditorDescriptionComponent>
            <EditorDescriptionComponent>
                wird dieses Attribut in der Produktkonfiguration ausgewählt, dann darf der Zusatz {additionEditor.addition.option} verwendet werden.
            </EditorDescriptionComponent>
            <EditorDescriptionComponent>
                Ausschluss:
            </EditorDescriptionComponent>
            <EditorDescriptionComponent>
                wird dieses Attribut in der Produktkonfiguration ausgewählt, wird der Zusatz {additionEditor.addition.option} nicht zur Verfügung stehen
            </EditorDescriptionComponent>
        </Grid>
        {
            additionEditor.categories.attributeForms.map((attributeForm, index) => {
                return (
                    <Grid item>
                        <AdditionAttributeCategoryItemComponent
                            attributeForm={attributeForm}
                            additionEditor={additionEditor}
                            additionEditorActions={additionEditorActions}
                            formIndex={index} />
                    </Grid>
                );
            })
        }
        {
            additionEditor.categories.attributeForms.length < additionInitial.availableAttributeCategories.length ?
                <Grid item>
                    <ListButtonComponent
                        onClick={additionEditorActions.addAttributeForm}>
                        <AddIcon /> Attribut hinzufügen
                    </ListButtonComponent>
                </Grid>
                : <></>
        }
    </Grid>
    );
};