import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { AppDispatch } from '../../app/redux_store';
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { MasspointBodySideEnumValues } from "../../models/masspoints/enums/body_side.enum";
import { MasspointTypeEnumValues } from "../../models/masspoints/enums/masspoint_type.enum";
import { DropdownComponent, FlexBox, PrimaryButtonComponent, RadioBoxComponent, Right, TextBoxComponent } from "../../shared/shared";
import { LoadingIndicatorComponent } from '../../shared/components/loading_indicator/loading_indicator.component';
import { editMasspoint, getMasspoint } from './redux/masspoint_edit.thunks';
import { cancelMasspoint, resetState, editMasspointCompleted, updateIsPantyTopWithStandardDimensions, updateIsEllipticalShapeKneeFunctionZone } from "./redux/masspoint_edit.slice";
import * as style from "./masspoint_edit.style";
import { LoadingOverlayContainer, LoadingOverlayComponent } from '../../shared/components/loading_overlay/loading_overlay.component';
import { FormControlLabel, FormLabel, Grid, Radio } from "@mui/material";
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";

export const MassPointEditComponent = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const state = useAppSelector((state) => state.masspoint_edit);
  const { masspointId } = useParams();


  useEffect(() => {
    dispatch(getMasspoint(Number(masspointId)));
    return () => {
      dispatch(resetState());
    }
  }, [dispatch, masspointId]);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  if (state.command.cancelMasspoint.status === "success") {
    navigate("/masspoints/masspoint/overview");
    dispatch(resetState());
  }

  if (state.query.getMasspoint.status === "idle" ||
    state.query.getMasspoint.status === "pending") {
    return <LoadingIndicatorComponent />;
  }

  if (state.command.editMasspoint.status === "success") {
    enqueueSnackbar("Maßpunkt gespeichert", { variant: "success" });
    navigate("/masspoints/masspoint/overview");
    dispatch(resetState());
  }

  if (state.query.getMasspoint.status === "error") {
    enqueueSnackbar(state.query.getMasspoint.message, { variant: "error" });
    navigate("/masspoints/masspoint/overview");
    dispatch(resetState());
  }

  if (state.command.editMasspoint.status === "error") {
    enqueueSnackbar(state.command.editMasspoint.message, { variant: "error" });
    dispatch(editMasspointCompleted());
  }

  const isSaving = state.command.editMasspoint.status === "pending";

  return (
    <>
      <LoadingOverlayContainer>
        {isSaving ? <LoadingOverlayComponent /> : <></>}
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start">
          <style.FormRow>
            <TextBoxComponent
              name="Name Maßpunkt"
              value={state.loadedData.masspoint?.name}
              onChange={() => { }}
              isReadonly={true}
              isRequired={true}
              maxLength={5}
              isAutoFocus={true}
            />
            <DropdownComponent
              name="Körperregion"
              data={[]}
              isLoading={false}
              isRequired={true}
              onSelect={() => { }}
              isReadonly={true}
              selectedValue={state.loadedData.masspoint?.bodyArea}
              displayedProperties={["name"]}
            />
          </style.FormRow>
          <style.FormCheckboxRow>
            <RadioBoxComponent
              name="Maßtyp"
              data={MasspointTypeEnumValues}
              displayedProperties={["text"]}
              valueProperty="value"
              onSelect={() => { }}
              isRequired={true}
              isReadOnly={true}
              selectedItem={MasspointTypeEnumValues.find(v => v.value === state.loadedData.masspoint?.massPointType)}
            />
          </style.FormCheckboxRow>
          <style.FormCheckboxRow>
            <RadioBoxComponent
              name="Seite"
              data={MasspointBodySideEnumValues}
              displayedProperties={["text"]}
              valueProperty="value"
              onSelect={() => { }}
              isRequired={true}
              isReadOnly={true}
              selectedItem={MasspointBodySideEnumValues.find(v => v.value === state.loadedData.masspoint?.bodySide)}
            />
          </style.FormCheckboxRow>
          <FormLabel>Für Leibteil mit Standardmaßen*</FormLabel>
          <Grid item>
            <FormControlLabel label={"Ja"}
              control={
                <Radio
                  checked={state.actualData.isPantyTopWithStandardDimensions}
                  inputProps={{ 'aria-label': 'controlled' }}
                  onChange={() => dispatch(updateIsPantyTopWithStandardDimensions(true))}
                />} />
            <FormControlLabel label={"Nein"}
              control={
                <Radio
                  checked={!state.actualData.isPantyTopWithStandardDimensions}
                  inputProps={{ 'aria-label': 'controlled' }}
                  onChange={() => dispatch(updateIsPantyTopWithStandardDimensions(false))}
                />} />
          </Grid>  
          <FormLabel>Für Ellipsenform/Kniefunktionszone*</FormLabel>
          <Grid item>
            <FormControlLabel label={"Ja"}
              control={
                <Radio
                  checked={state.actualData.isEllipticalShapeKneeFunctionZone}
                  inputProps={{ 'aria-label': 'controlled' }}
                  onChange={() => dispatch(updateIsEllipticalShapeKneeFunctionZone(true))}
                />} />
            <FormControlLabel label={"Nein"}
              control={
                <Radio
                  checked={state.actualData.isEllipticalShapeKneeFunctionZone === false}
                  inputProps={{ 'aria-label': 'controlled' }}
                  onChange={() => dispatch(updateIsEllipticalShapeKneeFunctionZone(false))}
                />} />
          </Grid>
        </Grid>
        <style.InfoText>Mit * gekennzeichnete Felder sind Pflichtfelder.</style.InfoText>
        <EditorButtonComponent
            canExecute={state.command.editMasspoint.canExecute}
            save={() => dispatch(editMasspoint())}
            cancelSave={() => dispatch(cancelMasspoint())}
        />
      </LoadingOverlayContainer>
    </>
  );
};

