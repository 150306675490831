import { doesExist, isNullOrWhitespace } from "services/validation.service";
import IMasspointOverview from "models/masspoints/masspoint_overview";
import { MasspointTypeEnumValuesLookup } from "models/masspoints/enums/masspoint_type.enum";
import { PolicyAdjustRangesByMasspointCreateState } from "./policy_adjust_ranges_by_masspoint_create.model";
import IAvailableQuality from "models/available_basedata/available_quality";
import IAvailableArticleType from "models/available_basedata/available_article_type";
import { IAdjustRangeByMasspointConfiguration, IMassPointCriteriaItem } from "../../components/policy_adjust_ranges_by_masspoint_configuration.model";
import { IAdditionPolicyInformation } from "models/additions/addition_policy_information";
import { createSelectionList } from "shared/components/selectionComponent/selectionList.helper";
import { getIAvailableArticleTypeDisplayName, getIAvailableQualityDisplayName } from "shared/helpers/displayNames";
import { distinctMassPointCriteria } from "shared/helpers/distinctMassPointCriteria";

export const updateCanSave = (state: PolicyAdjustRangesByMasspointCreateState) => {
    state.command.savePolicy.canExecute =
        !isNullOrWhitespace(state.data.policy.name)
        && additionValid(state.data.selectedAddition)
        && state.data.configurations.length > 0
        && allConfigsValid(state.data.configurations);
}

export const allConfigsValid = (configurations: IAdjustRangeByMasspointConfiguration[]) => {
    return !configurations.some(config =>
        config.qualities.selectedList.allItems.length === 0 ||
        config.articleTypes.selectedList.allItems.length === 0 ||
        !doesExist(config.selectedMasspoint) ||
        !doesExist(config.defaultPercentage) ||
        (!doesExist(config.thresholdPercentage) && doesExist(config.thresholdValue)) ||
        (doesExist(config.thresholdPercentage) && !doesExist(config.thresholdValue))
    )
}

export const additionValid = (addition: IAdditionPolicyInformation) => {
    return doesExist(addition) && doesExist(addition.placeholder?.twoRangePlaceholder)
}

export const createMasspointItemList = (massPoints: IMasspointOverview[]): IMassPointCriteriaItem[] => {
    return massPoints
        .filter(distinctMassPointCriteria)
        .map(mp => {
            return {
                id: mp.id,
                displayName: `${mp.name} ${MasspointTypeEnumValuesLookup(mp.massPointType)} ${mp.bodyArea.name}`,
                name: mp.name,
                type: mp.massPointType,
                bodySide: mp.bodySide,
                bodyAreaId: mp.bodyArea.id,
            }
        })
};

export const createNewConfiguration = (state): IAdjustRangeByMasspointConfiguration => {
    return {
        selectedMasspoint: null,
        selectableMasspoints: [],
        defaultPercentage: null,
        thresholdPercentage: null,
        thresholdValue: null,
        articleTypes: createSelectionList(state.loadedData.articleTypes, [], getIAvailableArticleTypeDisplayName),
        qualities: createSelectionList(state.loadedData.qualities, [], getIAvailableQualityDisplayName)
    };
}

export const calculateAvailableMasspoints = (configurations: IAdjustRangeByMasspointConfiguration[], masspoints: IMassPointCriteriaItem[]) => {
    const allSelectedMPs = configurations.flatMap(x => x.selectedMasspoint ? [x.selectedMasspoint] : []);

    configurations.forEach(config => {
        const notSelectableMPs = allSelectedMPs.filter(x => !(x.name === config.selectedMasspoint?.name && x.type === config.selectedMasspoint?.type && x.bodyAreaId === config.selectedMasspoint?.bodyAreaId));
        config.selectableMasspoints = masspoints.filter(x => !notSelectableMPs.find(y => x.name === y.name && x.type === y.type && x.bodyAreaId === y.bodyAreaId));
    })
}