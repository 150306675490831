import { SerialSizeTableHeadline, SerialSizeTableHeadlineContainer } from "./serial_sizes.style"

export const AttributeSummaryHeading = () => {
    return (
        <SerialSizeTableHeadlineContainer>
            <SerialSizeTableHeadline width="42px" />
            <SerialSizeTableHeadline width="150px">
                Sondergröße
            </SerialSizeTableHeadline>
            <SerialSizeTableHeadline flex={1}>
                Maßpunkte
            </SerialSizeTableHeadline>
            <SerialSizeTableHeadline width="80px">
                ändern
            </SerialSizeTableHeadline>  
            <SerialSizeTableHeadline width="80px">
                löschen
            </SerialSizeTableHeadline> 
        </SerialSizeTableHeadlineContainer>
    )
}