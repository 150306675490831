import { createAsyncThunk } from '@reduxjs/toolkit';
import AdditionHistoryServiceAPI from '../../../services/addition_history.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';

export const fetchAdditionHistoryDetails = createAsyncThunk(
    'addition_history/addition_history_details/fetchAdditionHistoryDetails',
    async (id: number) => {
        const response = await AdditionHistoryServiceAPI.getAdditionHistoryDetails(id);
        handleResponseErrors(response, "Zusatzhistorie");
        return response;
    }
)