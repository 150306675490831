import { createAsyncThunk } from '@reduxjs/toolkit';
import { MassImageService } from "../../../../services/massimage.service";
import { RootState } from '../../../../app/redux_store';
import { updateIsUnprocessableEntityException, updateUploadErrorData } from './update_massimage.slice';
import { handleResponseErrors } from '../../../../shared/networking/error_handling';
import MassImageUploadErrorData from 'models/massimages/massimage_error_data';

export const updateMassimage = createAsyncThunk(
    'admin/massimages/update',
    async (_, { getState, dispatch }) => {
        const state = getState() as RootState;
        const data = state.update_massimage.data;
        const responseUpdate = await MassImageService.updateMassImage(data.currentMassImageId, { name: data.name, image: data.file });
        // If UnprocessableEntityException
        if (responseUpdate.getErrorCode() === "422") {
            await dispatch(updateIsUnprocessableEntityException(true));
            const massImageUploadErrorData = JSON.parse(responseUpdate.getCustomError().messageParameter["massImageUploadErrorData"]) as MassImageUploadErrorData;
            await dispatch(updateUploadErrorData(massImageUploadErrorData));
        }
        handleResponseErrors(responseUpdate, "Massbild");

        return responseUpdate;
    }
)