import AddIcon from '@mui/icons-material/Add';
import { FlexBox, Right, PrimaryButtonComponent, LoadingIndicatorComponent, Left } from 'shared/shared';
import * as style from "./check_overview.style";
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { AppDispatch } from '../../app/redux_store';
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { fetchPlausibilityChecks, togglePublishPlausibilityCheck, confirmDeletePlausibilityCheck } from './redux/check_overview.thunks';
import { DialogComponent } from '../../shared/components/dialogs/dialog.component';
import { confirmDeletePlausibilityCheckCompleted, cancelDeletePlausibilityCheck, deletePlausibilityCheck, setModifiedCheckId, setSearchFilter, setGridFilters, resetState } from "./redux/check_overview.slice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { OverviewDatagrid } from "shared/components/datagrid/overview_datagrid.component";
import { ICheckOverview } from "models/plausibility_check/check_overview";
import SearchFieldComponent from "shared/components/search/searchfield.component";
import { getGridColumns, sortByMainProductLineModel } from "./check_overview.helper";

export const CheckOverviewComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const [search] = useSearchParams();
    const state = useAppSelector((state) => state.plausibilitycheck_get);

    const getQueryParameter = (queryParam) => {
        return search.get(queryParam);
    };

    const setFocusId = () => {
        const focusId = getQueryParameter("focusId");
        if (focusId !== "") {
            dispatch(setModifiedCheckId(focusId));
        }
    }

    useEffect(() => {
        setFocusId();
        dispatch(fetchPlausibilityChecks());
        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    if (state.command.confirmDeletePlausibilityCheck.status === "error") {
        enqueueSnackbar(state.command.confirmDeletePlausibilityCheck.message, { variant: "warning" });
        dispatch(confirmDeletePlausibilityCheckCompleted());
    }

    if (state.command.confirmDeletePlausibilityCheck.status === "success") {
        enqueueSnackbar("Prüfung entfernt", { variant: "success" });
        dispatch(confirmDeletePlausibilityCheckCompleted())
    }

    if (state.query.fetchPlausibilityChecks.status === "error") {
        return <div>Fehler beim Laden der Daten</div>;
    }

    if (state.query.fetchPlausibilityChecks.status !== "success") {
        return <LoadingIndicatorComponent />;
    }

    const handleTogglePublish = (check: ICheckOverview) => {
        dispatch(setModifiedCheckId(check.id.toString()));
        dispatch(togglePublishPlausibilityCheck(check));
    }


    const isLoading = state.command.confirmDeletePlausibilityCheck.status === "pending"
        || state.command.publishPlausibilityCheck.status === "pending"
        || state.command.unpublishPlausibilityCheck.status === "pending";

    const checksAvailable = state.loadedData.checks.length > 0;

    return (<div>
        {
            state.actualData.showDeletePlausibilityCheckDialog &&
            <DialogComponent
                title={"Prüfung löschen?"}
                isOpen={true}
                options={[
                    { title: "Abbrechen", handleOption: () => { dispatch(cancelDeletePlausibilityCheck()) } },
                    { title: "Löschen", handleOption: () => { dispatch(confirmDeletePlausibilityCheck()) } }
                ]}
                onClose={() => { }}>
                <div>Möchten Sie die Prüfung wirklich löschen?</div>
            </DialogComponent>
        }
        <FlexBox>
            {
                checksAvailable &&
                <Left>
                    <SearchFieldComponent onSearch={(filterText) => dispatch(setSearchFilter(filterText))} placeholder="In Prüfungen suchen ..." />
                </Left>
            }
            <Right>
                <PrimaryButtonComponent onClick={() => navigate("/plausibilitycheck/check/create")}>
                    <AddIcon /> Neue Plausibilitätsprüfung erstellen
                </PrimaryButtonComponent>
            </Right>
        </FlexBox>

        <style.OverviewWrapper>
            {
                !checksAvailable &&
                <div>Keine Prüfungen verfügbar</div>
            }
            {
                checksAvailable &&
                <OverviewDatagrid
                    isLoading={isLoading}
                    columns={getGridColumns(
                        (check) => navigate(`/plausibilitycheck/check/${check.id}/rule/edit/false`),
                        (check) => handleTogglePublish(check),
                        (check) => navigate(`/plausibilitycheck/check/${check.id}/edit`),
                        (check) => navigate(`/plausibilitycheck/check/duplicate/${check.id}`),
                        (check) => dispatch(deletePlausibilityCheck(check))
                    )}
                    rows={state.actualData.checks}
                    loadedRowsCount={state.loadedData.checks.length}
                    sortModel={sortByMainProductLineModel}
                    onFilterModelChange={(newFilterModel) => dispatch(setGridFilters(newFilterModel))}
                />
            }
        </style.OverviewWrapper>
    </div>
    );
};
