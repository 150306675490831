import { useAppDispatch, useAppSelector } from 'app/hook';
import { AppDispatch } from 'app/redux_store';
import { DialogComponent } from 'shared/components/dialogs/dialog.component';
import { cancelDeleteAdditionCategory } from '../redux/addition_category_overview.slice';
import { confirmDeleteAdditionCategory } from '../redux/addition_category_overview.thunks';

export const AdditionCategoryDeleteDialogComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.addition_category_overview);
    const additionCategory = state.actualData.activeAdditionCategory;

    return (<>
        <DialogComponent
            title={"Zusatzkategorie löschen?"}
            isOpen={true}
            options={[
                { title: "Abbrechen", handleOption: () => { dispatch(cancelDeleteAdditionCategory()) } },
                { title: "Löschen", handleOption: () => { dispatch(confirmDeleteAdditionCategory()) } }
            ]}
            onClose={() => { }}>
            <div>
                Möchten Sie die Zusatzkategorie {additionCategory.categoryKey} löschen?
            </div>
        </DialogComponent>
    </>);
}