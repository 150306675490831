import React from "react";
import { MassmaskCommonInfoCreateComponent } from "./massmask_common_info_create/massmask_common_information_create.component";
import { MassmaskMasspointsComponent } from "./massmask_masspoints/massmask_masspoints.component";
import { MassmaskMassdefinitionComponent } from "./massmask_massdefinition/massmask_massdefinition.component";
import { MassmaskMassimageComponent } from "./massmask_massimage/massmask_massimage.component";
import { MassmaskOverviewComponent } from './massmask_overview/massmask_overview.component';
import { MassmasksOverviewComponent } from './overview/overview.component';
import { FootOptionsOverviewComponent } from './footoptions_overview/footoptions_overview.component';
import { FootOptionsCreateComponent } from './footoptions_create/footoptions_create.component';
import { FootOptionsEditComponent } from './footoptions_edit/footoption_edit.component';
import { OneLegsOverviewComponent } from './onelegs_overview/onelegs_overview.component';
import { OneLegCreateComponent } from './onelegs_create/onelegs_create.component';
import { OneLegsEditComponent } from "./onelegs_edit/oneleg_edit.component";
import { Route, Routes } from "react-router-dom";
import { MassmaskCommonInfoEditComponent } from "./massmask_common_info_edit/massmask_common_information_edit.component";
import { BodyInformationOverviewComponent } from "./bodyinformation/bodyinformation_overview/bodyinformation_overview.component";
import { BodyInformationCreateComponent } from "./bodyinformation/bodyinformation_create/bodyinformation_create.component";
import { BodyInformationEditComponent } from "./bodyinformation/bodyinformation_edit/bodyinformation_edit.component";

export const MassmasksComponent = () => {
    return (
        <Routes>
            <Route element={<MassmasksOverviewComponent />} path="/" />
            <Route element={<FootOptionsOverviewComponent />} path="footoptions/overview" />
            <Route element={<FootOptionsCreateComponent />} path="footoptions/create" />
            <Route element={<FootOptionsEditComponent />} path="footoptions/edit/:footoptionid" />
            <Route element={<MassmaskOverviewComponent />} path="massmask/overview" />
            <Route element={<MassmaskCommonInfoCreateComponent />} path="massmask/create" />
            <Route element={<MassmaskCommonInfoEditComponent />} path="massmask/edit/:massmaskId" />
            <Route element={<MassmaskMassdefinitionComponent />} path="massmask/:massmaskId/massdefinition" />
            <Route element={<MassmaskMassimageComponent />} path="massmask/:massmaskId/massdefinition/massimages" />
            <Route element={<MassmaskMasspointsComponent />} path="massmask/:massmaskId/massdefinition/:massdefinitionId/masspoints" />
            <Route element={<OneLegsOverviewComponent />} path="onelegs/overview" />
            <Route element={<OneLegCreateComponent />} path="onelegs/create" />
            <Route element={<OneLegsEditComponent />} path="onelegs/edit/:onelegid" />
            <Route element={<BodyInformationOverviewComponent />} path="bodyinformations/overview" />
            <Route element={<BodyInformationCreateComponent />} path="bodyinformations/create" />
            <Route element={<BodyInformationEditComponent />} path="bodyinformations/edit/:bodyinformationid" />
        </Routes>)
}