export const getConfig = () => {
    switch (process.env.REACT_APP_ENVIRONMENT) {
        case "prod":
            return {
                name: "prod",
                authTenantId: "c5546184-c827-45e1-bdca-78353c329c48",
                authClientAppId: "1735b224-ee6e-410c-8339-475e0a8b168d",
                authServerAppId: "c0562d58-61b3-4b7a-939d-b4a348fd0d56",
                httpTimeoutMs: 30000,
                showSwaggerLinks: false,
                muiLicense: "fb628b87ff14bf63497770ead67af1ccTz04NTg1MCxFPTE3NDEzMjc3NjAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
                calinga: {
                    baseUrl: "https://manage.calinga.io",
                    organization: "medi",
                    team: "MTM-Service",
                    project: {
                        checkTypeErrorWarnMessages: "check_type.error_warn_messages",
                        additionDescriptions: "addition.description",
                        productionInstructions: "production.instructions",
                        additionPositions: "addition.positions",
                        general: "general.translations",
                        additionCategory: "addition.categories",
                        bodyInformationEntrys: "bodyinformation.entry",
                        bodyInformationCategories: "bodyinformation.category",
                        characteristicValues: "characteristic.values"
                    },
                    referenceLanguage: "de-DE",
                },  
                poeditor: {
                    baseUrl: "https://poeditor.com",
                },
                ehub: {
                    baseUrl: "https://prod.ehub.app.medi.de/",
                }
            };
        case "stage":
            return {
                name: "stage",
                authTenantId: "c5546184-c827-45e1-bdca-78353c329c48",
                authClientAppId: "f2377bef-0512-4703-99c6-43ced96fed0b",
                authServerAppId: "44c4e7e5-50bc-4369-84e0-f1fd80841ad2",
                httpTimeoutMs: 30000,
                showSwaggerLinks: false,
                muiLicense: "fb628b87ff14bf63497770ead67af1ccTz04NTg1MCxFPTE3NDEzMjc3NjAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",                
                calinga: {
                    baseUrl: "https://manage.calinga.io",
                    organization: "medi",
                    team: "MTM-Service",
                    project: {
                        checkTypeErrorWarnMessages: "check_type.error_warn_messages",
                        additionDescriptions: "addition.description",
                        productionInstructions: "production.instructions",
                        additionPositions: "addition.positions",
                        general: "general.translations",
                        additionCategory: "addition.categories",
                        bodyInformationEntrys: "bodyinformation.entry",
                        bodyInformationCategories: "bodyinformation.category",
                        characteristicValues: "characteristic.values"
                    },
                    referenceLanguage: "de-DE",
                },
                poeditor: {
                    baseUrl: "https://poeditor.com",
                },
                ehub: {
                    baseUrl: "https://prod.ehub.app.medi.de/",
                }
            };
        case "dev":
        default:
            return {
                name: "dev",
                authTenantId: "c5546184-c827-45e1-bdca-78353c329c48",
                authClientAppId: "32c5891a-0fd8-445c-a502-b48444b8f6c6",
                authServerAppId: "f46a5923-4048-4efc-9bcb-96b2fcb2bbbf",
                httpTimeoutMs: 300000,
                showSwaggerLinks: true,
                muiLicense: "fb628b87ff14bf63497770ead67af1ccTz04NTg1MCxFPTE3NDEzMjc3NjAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",                
                calinga: {
                    baseUrl: "https://manage.calinga.io",
                    organization: "medi",
                    team: "MTM-Service",
                    project: {
                        checkTypeErrorWarnMessages: "check_type.error_warn_messages",
                        additionDescriptions: "addition.description",
                        productionInstructions: "production.instructions",
                        additionPositions: "addition.positions",
                        general: "general.translations",
                        additionCategory: "addition.categories",
                        bodyInformationEntrys: "bodyinformation.entry",
                        bodyInformationCategories: "bodyinformation.category",
                        characteristicValues: "characteristic.values"
                    },
                    referenceLanguage: "de-DE",
                },
                poeditor: {
                    baseUrl: "https://poeditor.com",
                },
                ehub: {
                    baseUrl: "https://dev.ehub.app.medi.de/",
                }
            };
    }
}

export const getConfigName = () : "dev" | "stage" | "prod" => {
    switch (process.env.REACT_APP_ENVIRONMENT) {
        case "prod":
            return "prod";
        case "stage":
            return "stage";
        case "dev":
        default:
            return "dev"
    }
}