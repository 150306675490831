import { useAppDispatch, useAppSelector } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { DialogComponent } from "shared/components/dialogs/dialog.component";
import { cancelDeleteBodyInformation } from "../bodyinformation_overview/redux/bodyinformation_overview.slice";
import { confirmDeleteBodyInformation } from "../bodyinformation_overview/redux/bodyinformation_overview.thunks";

export const BodyInformationDeleteDialogComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.body_information_overview);
    const bodyInformation = state.actualData.activeBodyInformation;
    const bodyInformationText = `${bodyInformation.translationKeyForName}`;

    return (<>
        <DialogComponent
            title={"Zusätzliche Angabe löschen?"}
            isOpen={true}
            options={[
                { title: "Abbrechen", handleOption: () => { dispatch(cancelDeleteBodyInformation()) } },
                { title: "Löschen", handleOption: () => { dispatch(confirmDeleteBodyInformation()) } }
            ]}
            onClose={() => { }}>
            <div>
                Möchten Sie die Zusätzlcihe Angabe {bodyInformationText} löschen?
            </div>
        </DialogComponent>
    </>);
}