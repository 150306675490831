import { MassPointEditReferencesState } from "../redux/edit_references.model";
import { resetState } from "../redux/edit_references.slice";

export const handleThunkStates = (state: MassPointEditReferencesState, enqueueSnackbar, dispatch, navigate) => {
    if (state.query.fetchExistingBaseData.status === "error") {
        enqueueSnackbar("Fehler beim Laden der Basisdaten", { variant: "error" });
        dispatch(resetState());
    }

    if (state.query.fetchExistingReferences.status === "error") {
        enqueueSnackbar("Fehler beim Laden der existierenden Referenzen", { variant: "error" });
        dispatch(resetState());
    }

    if (state.query.fetchExistingMassPoint.status === "error") {
        enqueueSnackbar("Fehler beim Laden des ausgewählten Maßpunkts", { variant: "error" });
        dispatch(resetState());
    }

    if (state.command.updateReferences.status === "success") {
        enqueueSnackbar("medi 400 Referenzen gespeichert", { variant: "success" });
        navigate("/masspoints/masspoint/overview");
        dispatch(resetState());
    }

    if (state.command.updateReferences.status === "error") {
        enqueueSnackbar("Fehler beim Speichern der medi 400 Referenzen", { variant: "error" });
        dispatch(resetState());
    }

    if (state.command.cancelEditReferences.status === "success") {
        navigate("/masspoints/masspoint/overview");
    }
}
