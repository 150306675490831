import { buildArticleTypeDisplayText, buildBodyAreaDisplayText, buildQualitiyDisplayText, buildRulesCountDisplayText, filterCheckValue } from "../check_overview.helper";
import { PlausibilityCheckOverviewState } from "./check_overview.model"

export const filterChecks = (state: PlausibilityCheckOverviewState) => {
    const searchTextLower = state.actualData.searchFilter.toLocaleLowerCase().trim();
    let filteredItems = state.loadedData.checks.filter(
        (check) =>{
            return (
                check.mainProductLine.name.toLowerCase().includes(searchTextLower) ||
                buildBodyAreaDisplayText(check.bodyArea).toLowerCase().includes(searchTextLower) ||
                buildArticleTypeDisplayText(check.articleType).toLowerCase().includes(searchTextLower) ||
                buildQualitiyDisplayText(check.quality).toLowerCase().includes(searchTextLower) ||
                buildRulesCountDisplayText(check).toString().includes(searchTextLower));          
        }
    );

    state.actualData.gridFilters.items.forEach(fil => {
        filteredItems = filterCheckValue(fil, filteredItems);
    });
    
    state.actualData.checks = filteredItems;

}