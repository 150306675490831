export const SuggestionTextBoxStyle = {
  '&singleLine': {
    display: 'inline-block',
    width: '100%',
    '&focused': {
      backgroundColor: '#D7DEE2',
    },
    highlighter: {
      padding: 1,

    },
    input: {
      background: 'none',
      'font-size': '16px',
      padding: '1px 0px',
      display: 'block',
      outline: 'none',
    },

  },

  suggestions: {
    list: {
      'box-shadow': '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
      'max-height': '200px',
      'overflow-y': 'scroll',
      borderRadius: 8
    },
    item: {
      padding: '10px 15px',
      color: '#000000de',
      '&focused': {
        backgroundColor: '#f5f4f4',
      },
    },
  },
}
