import IAvailableArticleType from "models/available_basedata/available_article_type";
import { IAdjustMaximumRangeConfiguration, IMassPointCriteriaItem, PolicyAdjustMaximumRangeCreateState } from "./policy_adjust_maximum_range_create.model";
import IMasspointOverview from "models/masspoints/masspoint_overview";
import { MasspointTypeEnumValuesLookup } from "models/masspoints/enums/masspoint_type.enum";
import { ISelectable } from "shared/components/selectionComponent/models/selectable";
import { doesExist, isNullOrWhitespace } from "services/validation.service";
import { IAdditionPolicyInformation } from "models/additions/addition_policy_information";
import { IFilterList } from "shared/components/selectionComponent/models/filterList";
import { filterSelectionList } from "shared/components/selectionComponent/selectionList.helper";
import { distinctMassPointCriteria } from "shared/helpers/distinctMassPointCriteria";

export const updateCanSave = (state: PolicyAdjustMaximumRangeCreateState) => {
    state.command.savePolicy.canExecute =
        !isNullOrWhitespace(state.data.policy.name)
        && additionValid(state.data.selectedAddition)
        && state.data.configurations.length > 0
        && allConfigsValid(state.data.configurations);
}

export const allConfigsValid = (configurations: IAdjustMaximumRangeConfiguration[]) => {
    return !configurations.some(config =>
        config.articleTypes.selectedList.allItems.length === 0 ||
        !doesExist(config.selectedMasspoint1) ||
        !doesExist(config.selectedMasspoint2) ||
        !doesExist(config.multiplyValue) ||
        config.multiplyValue === 0
    )
}

export const removeArticleTypeFromAllConfigurations = (state: PolicyAdjustMaximumRangeCreateState, articleIds: number[]) => {
    state.data.configurations.forEach(config => {
        let unselectedList = config.articleTypes.unSelectedList;
        articleIds.forEach(articleId => {
            if (unselectedList.allItems.find(x => x.id === articleId)) {
                const articleTypeToRemove = unselectedList.allItems.findIndex(x => x.id == articleId);
                unselectedList.allItems.splice(articleTypeToRemove, 1);
            }
        });
        filterSelectionList(unselectedList);
    });
}

export const addArticleTypeToAllConfigurations = (state: PolicyAdjustMaximumRangeCreateState, articleIds: number[]) => {
    state.data.configurations.forEach(config => {
        let unselectedList = config.articleTypes.unSelectedList;
        articleIds.forEach(articleId => {
            if (unselectedList.allItems.find(x => x.id === articleId) == undefined) {
                const articleType = state.loadedData.articleTypes.find(x => x.id == articleId);
                unselectedList.allItems.push(createArticleType(articleType));
            }
        });
        filterSelectionList(unselectedList);
    });
}

export const additionValid = (addition: IAdditionPolicyInformation) => {
    return doesExist(addition) && doesExist(addition.placeholder?.rangePlaceholder)
}

export const createNewConfiguration = (state: PolicyAdjustMaximumRangeCreateState): IAdjustMaximumRangeConfiguration => {
    return {
        selectedMasspoint1: null,
        selectedMasspoint2: null,
        allMasspoints: state.loadedData.masspoints,
        multiplyValue: 1,
        articleTypes: {
            selectedList: {
                allItems: [],
                filteredItems: [],
                searchText: ""
            },
            unSelectedList: createArticleTypes(getUnusedArticleTypes(state))
        }
    };
}

const getUnusedArticleTypes = (state: PolicyAdjustMaximumRangeCreateState): IAvailableArticleType[] => {
    let availableArticleTypes = state.loadedData.articleTypes;
    const usedArticleTypes = state.data.configurations.flatMap(x => x.articleTypes.selectedList.allItems);
    return availableArticleTypes.filter(x => usedArticleTypes.find(articleType => articleType.id === x.id) == undefined);
}

const createArticleTypes = (articleTypes: IAvailableArticleType[]): IFilterList => {
    return {
        allItems: articleTypes.map(articleType => createArticleType(articleType)),
        filteredItems: [],
        searchText: ""
    };
}

const createArticleType = (articleType: IAvailableArticleType): ISelectable => {
    return {
        id: articleType.id,
        displayName: `${articleType.erpId} ${articleType.name}`,
    }
}

export const createMasspointItemList = (massPoints: IMasspointOverview[]): IMassPointCriteriaItem[] => {
    return massPoints
        .filter(distinctMassPointCriteria)
        .map(mp => {
            return {
                id: mp.id,
                displayName: `${mp.name} ${MasspointTypeEnumValuesLookup(mp.massPointType)} ${mp.bodyArea.name}`,
                name: mp.name,
                type: mp.massPointType,
                bodySide: mp.bodySide,
                bodyAreaId: mp.bodyArea.id,
            }
        })
};