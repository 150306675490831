import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICheckOverview } from 'models/plausibility_check/check_overview';
import { PlausibilityCheckOverviewState } from './check_overview.model';
import { fetchPlausibilityChecks, publishPlausibilityCheck, unpublishPlausibilityCheck, confirmDeletePlausibilityCheck} from './check_overview.thunks';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import { filterChecks } from './check_overview.reducer';

const initialState: PlausibilityCheckOverviewState = {
    loadedData:{
        checks: [],
    },
    actualData: { 
        checks: [],
        searchFilter: "",
        gridFilters: {items: []},
        selectedCheck: null,
        showDeletePlausibilityCheckDialog: false,
        modifiedCheckId: null,
    },
    command:{
        publishPlausibilityCheck: { status: "idle", canExecute: false },
        unpublishPlausibilityCheck: { status: "idle", canExecute: false }, 
        confirmDeletePlausibilityCheck: { status: "idle", canExecute: false }, 
    },
    query: {
        fetchPlausibilityChecks: { status: "idle", canExecute: true }
    }
}

export const getPlausibilityCheckSlice = createSlice({
    name: 'get_plausibilitycheck_overview',
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.actualData.searchFilter = action.payload;
            filterChecks(state);
        },
        setGridFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.gridFilters = action.payload;
            filterChecks(state);
        },
        confirmDeletePlausibilityCheckCompleted: (state) => {
            state.command.confirmDeletePlausibilityCheck.status = "idle";
        },
        deletePlausibilityCheck: (state, action: PayloadAction<ICheckOverview>) => {
            state.actualData.showDeletePlausibilityCheckDialog = true;
            state.actualData.selectedCheck = action.payload;
        },
        cancelDeletePlausibilityCheck: (state) => {
            state.actualData.showDeletePlausibilityCheckDialog = false;
        },
        setModifiedCheckId: (state, action: PayloadAction<string>) => {
            state.actualData.modifiedCheckId = action.payload
        }
    }, extraReducers: (builder) => {

        // fetch PlausibiltyCheck
        builder.addCase(fetchPlausibilityChecks.pending, (state, action) => {
            state.query.fetchPlausibilityChecks.status = "pending"
            state.query.fetchPlausibilityChecks.canExecute = false;
        }).addCase(fetchPlausibilityChecks.rejected, (state, action) => {
            state.query.fetchPlausibilityChecks.status = "error"
            state.query.fetchPlausibilityChecks.message = action.error.message;
            state.query.fetchPlausibilityChecks.canExecute = true;
        }).addCase(fetchPlausibilityChecks.fulfilled, (state, action) => {
            state.query.fetchPlausibilityChecks.status = "success"
            state.query.fetchPlausibilityChecks.canExecute = true;
            state.loadedData.checks = action.payload.getData();
            filterChecks(state);
        
        // publishPlausibiltyCheck
        }).addCase(publishPlausibilityCheck.pending, (state, action) => {
            state.command.publishPlausibilityCheck.status = 'pending'
        }).addCase(publishPlausibilityCheck.rejected, (state, action) => {
            state.command.publishPlausibilityCheck.status = "error"
            state.command.publishPlausibilityCheck.message = action.error.message;
        }).addCase(publishPlausibilityCheck.fulfilled, (state, action) => {
            state.command.publishPlausibilityCheck.status = "success"
            state.command.publishPlausibilityCheck.canExecute = true;
            state.loadedData.checks = action.payload.getData();
            filterChecks(state);

            // unpublishPlausibiltyCheck
        }).addCase(unpublishPlausibilityCheck.pending, (state, action) => {
            state.command.unpublishPlausibilityCheck.status = 'pending'
        }).addCase(unpublishPlausibilityCheck.rejected, (state, action) => {
            state.command.unpublishPlausibilityCheck.status = "error"
            state.command.unpublishPlausibilityCheck.message = action.error.message;
        }).addCase(unpublishPlausibilityCheck.fulfilled, (state, action) => {
            state.command.unpublishPlausibilityCheck.status = "success"
            state.command.unpublishPlausibilityCheck.canExecute = true;
            state.loadedData.checks = action.payload.getData();
            filterChecks(state);

            // confirmDeletePlausibilityCheck 
        }).addCase(confirmDeletePlausibilityCheck.pending, (state, action) => {
            state.command.confirmDeletePlausibilityCheck.status = 'pending'
        }).addCase(confirmDeletePlausibilityCheck.rejected, (state, action) => {
            state.command.confirmDeletePlausibilityCheck.status = "error"
            state.command.confirmDeletePlausibilityCheck.message = action.error.message;
            state.actualData.showDeletePlausibilityCheckDialog = false;
        }).addCase(confirmDeletePlausibilityCheck.fulfilled, (state, action) => {
            state.command.confirmDeletePlausibilityCheck.status = "success"
            state.command.confirmDeletePlausibilityCheck.canExecute = true;
            state.actualData.showDeletePlausibilityCheckDialog = false;
            state.loadedData.checks = action.payload.getData();
            filterChecks(state);        
        })
    }
})

export const {
    resetState,
    setSearchFilter,
    setGridFilters,
    confirmDeletePlausibilityCheckCompleted,
    cancelDeletePlausibilityCheck,
    deletePlausibilityCheck,
    setModifiedCheckId
} = getPlausibilityCheckSlice.actions

export default getPlausibilityCheckSlice.reducer