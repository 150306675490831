import Tooltip from '@mui/material/Tooltip';
import { EvaluationResultEnum } from '../../../models/simulator/product_configuration_evaluation';
import * as style from "./test_run_icon.style";

export interface TestRunIconComponentProps {
    result: EvaluationResultEnum;
}

export const TestRunIconComponent: React.FC<TestRunIconComponentProps> = ({ result }) => {
    switch (result) {
        case EvaluationResultEnum.Error:
            return <Tooltip title="Error" placement="top">
                <style.ErrorResultIcon />
            </Tooltip>;
        case EvaluationResultEnum.Warning:
            return <Tooltip title="Warning" placement="top">
                <style.WarningResultIcon />
            </Tooltip>;
        case EvaluationResultEnum.Success:
            return <Tooltip title="Success" placement="top">
                <style.SuccessResultIcon />
            </Tooltip>
        case EvaluationResultEnum.Invalid:
            return <Tooltip title="Invalid" placement="top">
                <style.InvalidIcon />
            </Tooltip>
        case EvaluationResultEnum.MassPointsNotFound:
            return <Tooltip title="Skipped" placement="top">
                <style.SkippedIcon />
            </Tooltip>
        default:
            return <Tooltip title="Invalid" placement="top">
                <style.InvalidIcon />
            </Tooltip>;
    }
}