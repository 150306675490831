import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseErrors } from "shared/networking/error_handling";
import PolicyServiceAPI from '../../../../services/policy.service';
import { RootState } from "app/redux_store";
import { PolicyTypeEnum } from "models/policies/policy_type.enum";
import { InitBaseModel } from "app/helper/initialize_editors";
import AdditionCategoryService from "services/addition_category.service";
import MassPointGroupService from "services/masspoint_group.service";

export const getAdditionCategories = createAsyncThunk(
    'policy/policy_fingers_and_toes/getAdditionCategories',
    async (initBase: InitBaseModel, _) => {
        const response = await AdditionCategoryService.getAvailableAdditionCategoryByPolicyTypeOverview(PolicyTypeEnum.FingersAndToes, initBase.id);
        handleResponseErrors(response, "Zusatzkategorien");
        return response;
    }
)

export const getMassPointGroups = createAsyncThunk(
    'policy/policy_fingers_and_toes/getMassPointGroups',
    async (_) => {
        const response = await MassPointGroupService.getMassPointGroupOverviews();
        handleResponseErrors(response, "Masspunktgruppen");
        return response;
    }
)

export const savePolicy = createAsyncThunk(
    'policy/policy_fingers_and_toes/savepolicy',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.policy_fingers_and_toes.data;
        const response = await PolicyServiceAPI.createFingersAndToes({
            name: data.policy.name,
            ignoreOnQuotation: data.policy.ignoreOnQuotation,
            additionCategoryId: data.selectedAdditionCategory.id,
            massPointGroupIds: data.massPointGroupSelection.selectedList.allItems.map(x=> x.id)
        });
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const updatePolicy = createAsyncThunk(
    'policy/policy_fingers_and_toes/updatepolicy',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.policy_fingers_and_toes.data;
        const response = await PolicyServiceAPI.updateFingersAndToes({
            policyId: data.policy.id,
            name: data.policy.name,
            ignoreOnQuotation: data.policy.ignoreOnQuotation,
            additionCategoryId: data.selectedAdditionCategory.id,
            massPointGroupIds: data.massPointGroupSelection.selectedList.allItems.map(x=> x.id)
        });
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const storePolicy = createAsyncThunk(
    'policy/policy_fingers_and_toes/storePolicy',
    async (_, { getState, dispatch }) => {
        const state = getState() as RootState;
        if(state.policy_fingers_and_toes.loadedData.isEdit) {
            await dispatch(updatePolicy());
        } else {
            await dispatch(savePolicy());
        }
    }
)


export const cancelSave = createAsyncThunk(
    'policy/policy_fingers_and_toes/cancelSave',
    async (_) => { }
)

export const getPolicy = createAsyncThunk(
    'policy/policy_fingers_and_toes/getPolicy',
    async (id: string) => {
        const response = await PolicyServiceAPI.getFingersAndToes(id);
        handleResponseErrors(response, "Regelwerk");
        return response;
    }
)

export const initData = createAsyncThunk(
    'policy/policy_fingers_and_toes/initData',
    async (initBase: InitBaseModel, { dispatch }) => {
        await Promise.all([
            dispatch(getAdditionCategories(initBase)),
            dispatch(getMassPointGroups()),
        ]);
        if(initBase.id){
            await dispatch(getPolicy(initBase.id));
        }
    }
)
