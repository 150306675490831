import { Container } from "reactstrap";
import { getConfig } from "services/config.service";
import { FlexBox, Left, Right } from "shared/shared";
import colors from "shared/style/colors";
import styled from "@emotion/styled";
import theme from "../../style/theme";

const FooterArea = styled.div`
  background-color: ${theme.colors.inactive};
  flex-shrink: 0;
`;

const Slogan = styled.div`
  color: ${theme.colors.active};
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const EnvironmentBadge = styled.span`
  color: ${colors.mediGrey};
`;

const VersionBadge = styled.span`
  margin-left: 10px;  
  color: ${colors.mediGrey};
`;

const SwaggerLink = styled.a`
  color: ${colors.mediGrey};
  margin-right: 10px;
  :hover,
    :focus,
    :active,
    :visited {
      text-decoration: none;
      color: ${theme.colors.active};
    };
    color: ${theme.colors.active};
`;

export const FooterComponent = () => {
  const config = getConfig();

  return (
    <FooterArea>
      <Container>
        <FlexBox>
          <Left>
            <Slogan>medi. ich fühl mich besser.</Slogan>
          </Left>
          <Right>
            {config.showSwaggerLinks ? <>
              <SwaggerLink href="/swagger-private" target="_blank">Private API</SwaggerLink>{" "}
              <SwaggerLink href="/swagger-public" target="_blank">Public API</SwaggerLink>
            </> : <></>}
            <EnvironmentBadge>{config.name}</EnvironmentBadge>
            <VersionBadge>{process.env.REACT_APP_VERSION ?? "0.0.0"}</VersionBadge>
          </Right>
        </FlexBox>
      </Container>
    </FooterArea>
  );
};
