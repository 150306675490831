import React from "react";
import { Grid } from "@mui/material";
import { DropdownComponent } from '../../shared/components/form_controls/dropdown/dropdown.component';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { AppDispatch } from '../../app/redux_store';
import { useSnackbar } from 'notistack';
import { cancelSave, fetchAllData, updateFootOption } from "./redux/footoption_edit.thunks";
import { MassPointCriteriaListComponent } from '../../shared/components/criteriaListComponent/masspoint_criteria_list.component';
import { LoadingOverlayContainer } from 'shared/components/loading_overlay/loading_overlay.component';
import { LoadingOverlayComponent } from '../../shared/components/loading_overlay/loading_overlay.component';
import { deselectMassPointCriteria, filterAvailableMassPointCriteria, filterSelectedMassPointCriteria, resetEditFootOption, resetState, selectFootType, selectMassPointCriteria, selectProductLine } from "./redux/footoption_edit.slice";
import * as style from "./footoption_edit.style";
import { useNavigate, useParams } from "react-router-dom";
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";
import { PaperContentFitItem } from "shared/style/paper";

export const FootOptionsEditComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const state = useAppSelector((state) => state.foot_options_edit);
    const { footoptionid } = useParams();

    useEffect(() => {
        dispatch(fetchAllData(footoptionid));

        return () => {
            dispatch(resetState());
        }
    }, [dispatch, footoptionid]);

    if (state.query.getFootOption.status === "error") {
        enqueueSnackbar(state.query.getFootOption.message, { variant: "warning" });
        dispatch(resetState())
    }

    if (state.query.getFootTypes.status === "error") {
        enqueueSnackbar(state.query.getFootTypes.message, { variant: "warning" });
        dispatch(resetState())
    }

    if (state.query.getMassPoints.status === "error") {
        enqueueSnackbar(state.query.getMassPoints.message, { variant: "warning" });
        dispatch(resetState())
    }

    if (state.query.getProductLines.status === "error") {
        enqueueSnackbar(state.query.getProductLines.message, { variant: "warning" });
        dispatch(resetState())
    }

    if (state.query.fetchAllData.status === "error") {
        enqueueSnackbar("Fehler beim Laden der Basisdaten", { variant: "error" });
        dispatch(resetState())
    }

    if (state.command.cancel.status === "success") {
        navigate("/massmasks/footoptions/overview");
        dispatch(resetState());
    }

    if (state.command.editFootOption.status === "success") {
        enqueueSnackbar("Fußoption erfolgreich editiert", { variant: "success" });
        navigate("/massmasks/footoptions/overview");
        dispatch(resetState());
    }

    if (state.command.editFootOption.status === "error") {
        enqueueSnackbar(state.command.editFootOption.message, { variant: "error" });
        dispatch(resetEditFootOption())
    }
    const isEditingFootOption = state.command.editFootOption.status === "pending";

    return (
        <LoadingOverlayContainer>
            {isEditingFootOption
                ? <LoadingOverlayComponent />
                : <></>
            }
            <Grid
                container
                spacing={3}>
                <Grid
                    container
                    item
                    direction="row">
                    <Grid
                        item>
                        <DropdownComponent
                            name="Hauptproduktlinie"
                            data={state.loadedData.productLines}
                            isLoading={state.query.getProductLines.status === "pending"}
                            isRequired={true}
                            onSelect={(productLine) => dispatch(selectProductLine(productLine))}
                            displayedProperties={["name"]}
                            isReadonly={true}
                            selectedValue={state.actualData.selectedProductLine}
                        />
                    </Grid>
                    <Grid
                        item>
                        <DropdownComponent
                            name="Fußart"
                            data={state.loadedData.footTypes}
                            isLoading={state.query.getFootTypes.status === "pending"}
                            isRequired={true}
                            onSelect={(footType) => dispatch(selectFootType(footType))}
                            displayedProperties={["externalIdentifier", "name"]}
                            isReadonly={true}
                            selectedValue={state.actualData.selectedFootType}
                        />
                    </Grid>
                </Grid>

                <Grid
                    item>
                    <h3>Maßpunktfilter</h3>
                    <p>Diese Maßpunkte werden in der Maßmaske ausgeblendet.</p>

                    <PaperContentFitItem>
                        <Grid item container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            wrap="nowrap">
                            <Grid item>
                                <MassPointCriteriaListComponent
                                    type="remove"
                                    title="Folgende Maßpunkte werden ausgeblendet"
                                    items={state.actualData.massPointCriteria.selected.filteredItems}
                                    onClick={(index) => dispatch(deselectMassPointCriteria(index))}
                                    onFilter={(searchText) => dispatch(filterSelectedMassPointCriteria(searchText))} />

                            </Grid>
                            <Grid item>
                                <style.Spacer />
                            </Grid>
                            <Grid item>
                                <MassPointCriteriaListComponent
                                    type="add"
                                    title="Verfügbare Maßpunkte"
                                    items={state.actualData.massPointCriteria.available.filteredItems}
                                    onClick={(index) => dispatch(selectMassPointCriteria(index))}
                                    onFilter={(searchText) => dispatch(filterAvailableMassPointCriteria(searchText))} />
                            </Grid>
                        </Grid>
                    </PaperContentFitItem>
                </Grid>
                <EditorButtonComponent
                    canExecute={state.command.editFootOption.canExecute}
                    save={() => dispatch(updateFootOption())}
                    cancelSave={() => dispatch(cancelSave())}
                />
            </Grid>
        </LoadingOverlayContainer>);
}