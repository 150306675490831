import { useAppDispatch, useAppSelector } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { BodyInformationEditorComponent } from "../components/bodyinformation_editor.component";
import { IBodyInformationEditorAction } from "../components/bodyinformation_editor.model";
import { addTranslationKey, removeTranslationKey, resetCreateBodyInformation, resetState, updateTranslationKey, updateCategoryName, updateCategoryType, updateFreeTextLength, updateShortKey, updateIsMandatory } from "./redux/bodyinformation_create.slice";
import { LoadingOverlayComponent, LoadingOverlayContainer } from "shared/components/loading_overlay/loading_overlay.component";
import { cancelSave, createBodyInformation } from "./redux/bodyinformation_create.thunks";
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";

export const BodyInformationCreateComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const state = useAppSelector((state) => state.body_information_create);

    const editorActions: IBodyInformationEditorAction = {
        updateCategoryName: (categoryName) => dispatch(updateCategoryName(categoryName)),
        updateCategoryType: (categoryType) => dispatch(updateCategoryType(categoryType)),
        updateIsMandatory: (isMandatory) => dispatch(updateIsMandatory(isMandatory)),
        updateFreeTextLength: (freeTextLength) => dispatch(updateFreeTextLength(freeTextLength)),
        addTranslationKey: () => dispatch(addTranslationKey()),
        removeTranslationKey: (index) => dispatch(removeTranslationKey(index)),
        updateTranslationKey: (index, translationKey) => dispatch(updateTranslationKey({ index, translationKey })),
        updateShortKey: (index, shortKey) => dispatch(updateShortKey({ index, shortKey }))
    };

    if (state.command.cancel.status === "success") {
        navigate("/massmasks/bodyinformations/overview");
        dispatch(resetState());
    }

    if (state.command.createBodyInformation.status === "success") {
        enqueueSnackbar("Zusätzliche Angabe erfolgreich erstellt", { variant: "success" });
        navigate("/massmasks/bodyinformations/overview");
        dispatch(resetState());
    }

    if (state.command.createBodyInformation.status === "error") {
        enqueueSnackbar(state.command.createBodyInformation.message, { variant: "error" });
        dispatch(resetCreateBodyInformation())
    }
    const isCreatingBodyInformation = state.command.createBodyInformation.status === "pending";

    return (
        <>
            <LoadingOverlayContainer>
                {
                    isCreatingBodyInformation &&
                    <LoadingOverlayComponent />
                }
                <BodyInformationEditorComponent
                    editor={state.actualData.editor}
                    editorActions={editorActions}
                />
                <EditorButtonComponent
                    canExecute={state.command.createBodyInformation.canExecute}
                    save={() => dispatch(createBodyInformation())}
                    cancelSave={() => dispatch(cancelSave())}
                />                
            </LoadingOverlayContainer>
        </>
    );
}