import { DropdownComponent } from "shared/shared";
import { Grid } from "@mui/material";
import { IAdditionPolicyInformation } from "models/additions/addition_policy_information";
import { IAdjustRangeByMasspointConfiguration, IIndexedIds, IIndexedMasspoint, IIndexedNumber, IIndexedSearchText, IMassPointCriteriaItem } from "./policy_adjust_ranges_by_masspoint_configuration.model";
import { PolicyAdjustRangesByMasspointConfigurationComponent } from "./policy_adjust_ranges_by_masspoint_configuration";
import { ListButtonComponent } from "shared/components/buttons/list_button.component";
import AddIcon from '@mui/icons-material/Add';

interface PolicyAdjustRangesByMasspointEditor {
    selectedAddition: IAdditionPolicyInformation,
    allAdditions: IAdditionPolicyInformation[],
    configurations: IAdjustRangeByMasspointConfiguration[]
    addConfiguration: () => void;
    selectAddition: (addition: IAdditionPolicyInformation) => void;
    deleteConfiguration: (index: number) => {};
    filterSelectedArticleTypes: (value: IIndexedSearchText) => {};
    filterUnselectedArticleTypes: (value: IIndexedSearchText) => {};
    filterSelectedQualities: (value: IIndexedSearchText) => {};
    filterUnselectedQualities: (value: IIndexedSearchText) => {};
    selectArticleTypes: (value: IIndexedIds) => {};
    unSelectArticleTypes: (value: IIndexedIds) => {};
    selectQualities: (value: IIndexedIds) => {};
    unSelectQualities: (value: IIndexedIds) => {};
    selectMasspoint: (value: IIndexedMasspoint) => void;
    changeDefaultPercentage: (value: IIndexedNumber) => void;
    changeThresholdValue: (value: IIndexedNumber) => void;
    changeThresholdPercentage: (value: IIndexedNumber) => void;
}

export const PolicyAdjustRangesByMasspointEditorComponent: React.FC<PolicyAdjustRangesByMasspointEditor> = ({
    selectedAddition,
    allAdditions,
    configurations,
    addConfiguration,
    selectAddition,
    selectMasspoint,
    deleteConfiguration,
    filterSelectedArticleTypes,
    filterUnselectedArticleTypes,
    filterSelectedQualities,
    filterUnselectedQualities,
    selectArticleTypes,
    unSelectArticleTypes,
    selectQualities,
    unSelectQualities,
    changeDefaultPercentage,
    changeThresholdValue,
    changeThresholdPercentage
}) => {

    const isMinMaxPlaceholder = Boolean(selectedAddition?.placeholder?.twoRangePlaceholder);
    const errorText = isMinMaxPlaceholder || !selectedAddition ? "" : "Der Zusatz ist nicht als “2 Rangewerte” konfiguriert."

    return (
        <Grid item container md={12}
            direction="column"
            spacing={3}>
            <Grid container item md={12} spacing={3}
                direction="row">
                <Grid item>
                    <DropdownComponent name="Zusatz"
                        data={allAdditions}
                        minWidth="350px"
                        isRequired={true}
                        onSelect={(e) => selectAddition(e)}
                        displayedProperties={["option", "description"]}
                        selectedValue={selectedAddition}
                        errorText={errorText}
                    />
                </Grid>
                <Grid item>
                    { isMinMaxPlaceholder && <div>
                        <div style={{fontWeight: 600}}>Konfigurierte Rangewerte:</div>
                        <div>{`Range 1: ${selectedAddition.placeholder.twoRangePlaceholder.firstMinimalValue} cm - ${selectedAddition.placeholder.twoRangePlaceholder.firstMaximalValue} cm`}</div>
                        <div>{`Range 2: ${selectedAddition.placeholder.twoRangePlaceholder.secondMinimalValue} cm - ${selectedAddition.placeholder.twoRangePlaceholder.secondMaximalValue} cm`}</div>
                    </div>}
                </Grid>
            </Grid>
            {
                configurations &&
                configurations.map((con, index) => {
                    return (
                        <Grid item key={index}>
                            <PolicyAdjustRangesByMasspointConfigurationComponent
                                configuration={con}
                                configurationIndex={index}
                                selectMasspoint={selectMasspoint}
                                deleteConfiguration={deleteConfiguration}
                                filterSelectedArticleTypes={filterSelectedArticleTypes}
                                filterUnselectedArticleTypes={filterUnselectedArticleTypes}
                                filterSelectedQualities={filterSelectedQualities}
                                filterUnselectedQualities={filterUnselectedQualities}
                                selectArticleTypes={selectArticleTypes}
                                unSelectArticleTypes={unSelectArticleTypes}
                                selectQualities={selectQualities}
                                unSelectQualities={unSelectQualities}
                                changeDefaultPercentage={changeDefaultPercentage}
                                changeThresholdValue={changeThresholdValue}
                                changeThresholdPercentage={changeThresholdPercentage}
                            />
                        </Grid>
                    )
                })
            }
            <Grid item>
                <ListButtonComponent
                    onClick={addConfiguration} disabled={false}>
                    <AddIcon /> Konfiguration hinzufügen
                </ListButtonComponent>
            </Grid>
        </Grid>
    );
};