import styled from "@emotion/styled";
import colors from '../../../shared/style/colors';

export const ImportStartWrapper = styled.div`
    background: white;
    padding: 10px;
`;

export const TextHighlighted = styled.span`
    color: ${colors.mediMagenta};
    font-weight: bold;
`;