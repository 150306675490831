import { PolicyEditorComponent } from "check/policy/components/policy_editor.component";
import { LoadingOverlayComponent, LoadingOverlayContainer } from "shared/components/loading_overlay/loading_overlay.component";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { LoadingIndicatorComponent } from "shared/shared";
import { Grid } from "@mui/material";
import { EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";
import { useNavigate, useParams } from "react-router-dom";
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";
import i18next from "i18next";
import { useAppSelector, useAppDispatch } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { PolicyMandatoryByAttributeEditorComponent } from "./components/policy_mandatory_by_attribute_editor.component";
import { changeName, completedCreate, completedUpdate, resetState, toggleIgnoreOnQuotation } from "./redux/policy_mandatory_by_attribute.slice";
import { cancelSave, createPolicy, getMappedBaseDataByAddition, initData, updatePolicy } from "./redux/policy_mandatory_by_attribute.thunks";
import { EditorModeEnum } from "models/editors/editor_mode.enum";

export const PolicyMandatoryByAttributeComponent = () => {
    const state = useAppSelector((state) => state.policy_mandatory_by_attribute);
    const dispatch: AppDispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (state.data.editor.editableConfig?.addition) {
            const additionId = state.data.editor.editableConfig.addition.id;
            const mappedDataAlreadyLoaded = state.loadedData.mappedBaseDatasByAddition.some(x => x.additionId === additionId);
            if (!mappedDataAlreadyLoaded) {
                dispatch(getMappedBaseDataByAddition([additionId]));
            }
        }
    }, [state.data.editor.editableConfig?.addition]);

    useEffect(() => {
        dispatch(getMappedBaseDataByAddition(state.loadedData.policy?.configs.map(x => x.additionId)));
    }, [state.loadedData.policy]);

    useEffect(() => {
        dispatch(initData(id));

        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    if (state.query.initData.status === "pending") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.cancelSave.status === "success") {
        navigate("/plausibilitycheck/policy");
        dispatch(resetState());
    }

    if (state.command.createPolicy.status === "success") {
        enqueueSnackbar("Regelwerk gespeichert", { variant: "success" });
        navigate("/plausibilitycheck/policy");
        dispatch(resetState());
    }

    if (state.command.updatePolicy.status === "success") {
        enqueueSnackbar("Regelwerk gespeichert", { variant: "success" });
        navigate("/plausibilitycheck/policy");
        dispatch(resetState());
    }

    if (state.command.updatePolicy.status === "error") {
        enqueueSnackbar(state.command.updatePolicy.message, { variant: "error" });
        dispatch(completedUpdate());
    }

    if (state.command.updatePolicy.status === "warning") {
        enqueueSnackbar(state.command.updatePolicy.message, { variant: "warning" });
        dispatch(completedUpdate());
    }

    if (state.command.cancelSave.status === "success") {
        navigate("/plausibilitycheck/policy");
        dispatch(resetState());
    }

    if (state.command.createPolicy.status === "error") {
        enqueueSnackbar(state.command.createPolicy.message, { variant: "error" });
        dispatch(completedCreate());
    }

    if (state.command.createPolicy.status === "warning") {
        enqueueSnackbar(state.command.createPolicy.message, { variant: "warning" });
        dispatch(completedCreate());
    }

    const isSaving = state.command.updatePolicy.status === "pending";

    return (
        <>
            <LoadingOverlayContainer>
                {(isSaving || state.query.fetchMappedBaseData.status === "pending") ? <LoadingOverlayComponent /> : <></>}
                <Grid
                    container
                    spacing={3}
                    direction="row">
                    <Grid item md={6}>
                        <EditorSectionHeaderComponent>Automatischen Zusatz für Attribute editieren</EditorSectionHeaderComponent>
                    </Grid>
                    <Grid item md={6}>
                        <p>{i18next.t("policy.autocheck_attribute.description")}</p>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={3}
                    direction="column">
                    <PolicyEditorComponent
                        name={state.data.editor.policy.name}
                        ignoreOnQuotation={state.data.editor.policy.ignoreOnQuotation}
                        changeName={(value) => dispatch(changeName(value))}
                        toggleIgnoreOnQuotation={(value) => dispatch(toggleIgnoreOnQuotation(value))}
                    />
                    <PolicyMandatoryByAttributeEditorComponent />
                    <EditorButtonComponent
                        canExecute={state.command.updatePolicy.canExecute}
                        save={() => dispatch(state.data.editor.editorMode === EditorModeEnum.Edit ? updatePolicy() : createPolicy() )}
                        cancelSave={() => dispatch(cancelSave())}
                    />
                </Grid>
            </LoadingOverlayContainer>
        </>
    );
};