import { BreadcrumbsComponent } from '../shared/breadcrumbs/components/breadcrumbs';
import { BreadcrumbRoute } from '../shared/breadcrumbs/models/breadcrumb_route';

export const PlausibilityCheckBreadcrumbs = () => {
    return <BreadcrumbsComponent breadcrumbs={routes} />
}

const routes: BreadcrumbRoute[] = [
    {
        route: "/plausibilitycheck",
        parts: [{ title: "Prüfungen" }]
    },
    {
        route: "/plausibilitycheck/type",
        parts: [
            { title: "Prüfungen", url: "/plausibilitycheck" },
            { title: "Prüfungstypen" }
        ]
    },
    {
        route: "/plausibilitycheck/type/create",
        parts: [
            { title: "Prüfungen", url: "/plausibilitycheck" },
            { title: "Prüfungstypen", url: "/plausibilitycheck/type" },
            { title: "Prüfungstyp erstellen" }
        ]
    },
    {
        route: "/plausibilitycheck/type/:id/edit",
        parts: [
            { title: "Prüfungen", url: "/plausibilitycheck" },
            { title: "Prüfungstypen", url: "/plausibilitycheck/type" },
            { title: "Prüfungstyp editieren" }
        ]
    },
    {
        route: "/plausibilitycheck/check",
        parts: [
            { title: "Prüfungen", url: "/plausibilitycheck" },
            { title: "Übersicht" }
        ]
    },
    {
        route: "/plausibilitycheck/check/create",
        parts: [
            { title: "Prüfungen", url: "/plausibilitycheck" },
            { title: "Übersicht", url: "/plausibilitycheck/check" },
            { title: "Prüfung erstellen" },
        ]
    },
    {
        route: "/plausibilitycheck/check/duplicate/:checkId",
        parts: [
            { title: "Prüfungen", url: "/plausibilitycheck" },
            { title: "Übersicht", url: "/plausibilitycheck/check" },
            { title: "Prüfung duplizieren" },
        ]
    },
    {
        route: "/plausibilitycheck/check/:checkId/rule/:isVersion",
        parts: [
            { title: "Prüfungen", url: "/plausibilitycheck" },
            { title: "Übersicht", url: "/plausibilitycheck/check" },
            { title: "Prüfung erstellen", url: "/plausibilitycheck/check/create " },
            { title: "Regeln hinzufügen" },
        ]
    },
    {
        route: "/plausibilitycheck/check/:checkId/rule/edit/:isVersion",
        parts: [
            { title: "Prüfungen", url: "/plausibilitycheck" },
            { title: "Übersicht", url: "/plausibilitycheck/check" },
            { title: "Regeln bearbeiten" },
        ]
    },
    {
        route: "/plausibilitycheck/check/:id/edit",
        parts: [
            { title: "Prüfungen", url: "/plausibilitycheck" },
            { title: "Übersicht", url: "/plausibilitycheck/check" },
            { title: "Prüfung bearbeiten" }
        ]
    },
    {
        route: "/plausibilitycheck/policy",
        parts: [
            { title: "Prüfungen", url: "/plausibilitycheck" },
            { title: "Regelwerke" }
        ]
    },
    {
        route: "/plausibilitycheck/policy/create",
        parts: [
            { title: "Prüfungen", url: "/plausibilitycheck" },
            { title: "Regelwerke", url: "/plausibilitycheck/policy" },
            { title: "Regelwerk erstellen" }
        ]
    }, 
    {
        route: "/plausibilitycheck/policy/:policyType/:id/edit",
        parts: [
            { title: "Prüfungen", url: "/plausibilitycheck" },
            { title: "Regelwerke", url: "/plausibilitycheck/policy" },
            { title: "Regelwerk bearbeiten" }
        ]
    },  
    {
        route: "/plausibilitycheck/policy/:policyType/create",
        parts: [
            { title: "Prüfungen", url: "/plausibilitycheck" },
            { title: "Regelwerke", url: "/plausibilitycheck/policy" },
            { title: "Regelwerk erstellen" }
        ]
    },
]