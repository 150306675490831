import { GridFilterItem, GridColDef, getGridBooleanOperators, GridSortModel } from "@mui/x-data-grid-pro";
import IAvailableArticleType from "models/available_basedata/available_article_type";
import IAvailableBodyArea from "models/available_basedata/available_body_area";
import IAvailableQuality from "models/available_basedata/available_quality";
import { ICheckOverview } from "models/plausibility_check/check_overview";
import { CustomBooleanCell } from "shared/components/datagrid/custom_boolean_cell.component";
import { CustomChipCellComponent } from "shared/components/datagrid/custom_chip_cell.component";
import { CustomFilterOperators } from "shared/components/datagrid/custom_filter_operators";
import { CustomTooltipHeader } from "shared/components/datagrid/custom_tooltip_header.component";
import { DatagridMenu } from "shared/components/datagrid/datagrid_menu.component";


export const buildBodyAreaDisplayText = ( bodyArea: IAvailableBodyArea ) => {
    return bodyArea ? bodyArea.name : "Alle";
}

export const buildArticleTypeDisplayText = ( articleType: IAvailableArticleType ) => {
    return articleType ? `${articleType.erpId} ${articleType.name}` : "Alle";
}

export const buildQualitiyDisplayText = ( quality: IAvailableQuality ) => {
    return quality ? `${quality.erpId} ${quality.name}` : "Alle";
}

export const buildRulesCountDisplayText = ( check: ICheckOverview ) => {
    return check?.minMaxRuleVersionsCount + check?.formulaRuleVersionsCount
}

export const filterCheckValue = (filterItem: GridFilterItem, filteredItems: ICheckOverview[]) => {
    if(filterItem.value)
    {
        switch(filterItem.field)
        {
            case 'mainProductLine':
                filteredItems = filteredItems.filter(x => x.mainProductLine.name.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'bodyArea':
                filteredItems = filteredItems.filter(x => buildBodyAreaDisplayText(x.bodyArea).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;           
            case 'articleType':
                filteredItems = filteredItems.filter(x => buildArticleTypeDisplayText(x.articleType).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;          
            case 'quality':
                filteredItems = filteredItems.filter(x => buildQualitiyDisplayText(x.quality).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;  
            case 'checkType':
                filteredItems = filteredItems.filter(x => x.plausibilityCheckTypeVersion?.name.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;    
            case 'rulesCount':
                filteredItems = filteredItems.filter(x => buildRulesCountDisplayText(x).toString().toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;        
            case 'isSideSpecific':
                filteredItems = filteredItems.filter(x => filterItem.value === 'any' ? true: x.isSideSpecific.toString() === filterItem.value);
                break;          
            case 'isPublished':
                filteredItems = filteredItems.filter(x => filterItem.value === 'any' ? true: x.isPublished.toString() === filterItem.value);
                break;                                                                                                      
        }
    }
    return filteredItems;
}

export const getGridColumns = (
    editRule: (check: ICheckOverview) => void,
    togglePublishCheck: (check: ICheckOverview) => void,
    editCheck: (check: ICheckOverview) => void,
    duplicatcheck: (check: ICheckOverview) => void,
    deleteCheck: (check: ICheckOverview) => void,
    ): GridColDef[] => [
    {
        field: "menu",
        headerName: " ",
        width: 15,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        renderCell: params => DatagridMenu({params: params, menuItemsData: 
            [
                {label: "Regel editieren", action: editRule},
                {label: params.row.isPublished ? "Veröffentlichung aufheben" : "Prüfung veröffentlichen", action: togglePublishCheck},
                {label: "Prüfung editieren", action: editCheck},
                {label: "Prüfung duplizieren", action: duplicatcheck},
                {label: "Prüfung löschen", action: deleteCheck},
            ]})
    },
    {
        field: "mainProductLine",
        headerName: "Hauptproduktlinie",
        disableColumnMenu: false,
        flex: 0.2,
        sortable: false,
        hideable: false,
        pinnable: false,
        valueGetter: (value, row) => row.mainProductLine?.name,
        filterOperators: CustomFilterOperators(),
    },  
    {
        field: "bodyArea",
        headerName: "Körperregion",
        disableColumnMenu: false,
        flex: 0.2,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
        renderCell: ({row: {bodyArea}}) => bodyArea ? <>{bodyArea.name}</> : CustomChipCellComponent({content: "Alle"}) 
    },  
    {
        field: "articleType",
        headerName: "Artikelart",
        disableColumnMenu: false,
        flex: 0.2,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
        renderCell: ({row: {articleType}}) => articleType ? <>{articleType.erpId} {articleType.name}</> : CustomChipCellComponent({content: "Alle"}) 
    },   
    {
        field: "quality",
        headerName: "Qualität",
        disableColumnMenu: false,
        flex: 0.2,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
        renderCell: ({row: {quality}}) => quality ? <>{quality.erpId} {quality.name}</> : CustomChipCellComponent({content: "Alle"}) 
    },   
    {
        field: "checkType",
        headerName: "Prüfungstyp",
        disableColumnMenu: false,
        flex: 0.2,
        sortable: false,
        hideable: false,
        pinnable: false,
        valueGetter: (value, row) => row.plausibilityCheckTypeVersion?.name,
        filterOperators: CustomFilterOperators(),
    },   
    {
        field: "rulesCount",
        headerName: "Anzahl Regeln",
        disableColumnMenu: false,
        width: 100,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators('number'),
        renderCell: (params) => CustomChipCellComponent({content: (params.row.minMaxRuleVersionsCount + params.row.formulaRuleVersionsCount)})
    },
    {
        field: "isSideSpecific",
        headerName: "Seitenabhängig",
        disableColumnMenu: false,
        width: 120,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: getGridBooleanOperators(),
        valueGetter: (value, row) => row.isSideSpecific ? "ja" : "nein",
    },  
    {
        field: "isPublished",
        headerName: "Veröffentlicht",
        disableColumnMenu: false,
        width: 80,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: getGridBooleanOperators(),
        renderHeader: () => CustomTooltipHeader({ shortText: "v", tooltip: "Veröffentlicht" }),
        renderCell: (params) => CustomBooleanCell({value: params.row.isPublished})
    }
]    

export const sortByMainProductLineModel: GridSortModel = [
    {
        field: "mainProductLine",
        sort: "asc",
    },
];