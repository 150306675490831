import { EnumDescriptor } from "models/enum_descriptor";

export enum LockTypeEnum {
    Deny,
    Allow,
}

export const LockTypeEnumValues: Array<EnumDescriptor<LockTypeEnum>> = [
    { value: LockTypeEnum.Deny, text: "verbieten" },
    { value: LockTypeEnum.Allow, text: "erlauben" },
];