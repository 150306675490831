import { GridCellParams, GridColDef, GridColumnGroupingModel, GridColumnVisibilityModel, GridFilterItem, GridSortModel, getGridBooleanOperators, getGridSingleSelectOperators } from "@mui/x-data-grid-pro";
import { MasspointBodySideEnumValuesLookup } from "models/masspoints/enums/body_side.enum";
import { IPositionCategoryItem } from "models/position_category/position_category_editor";
import { SideSeamModeEnum, SideSeamModeEnumValues } from "models/position_category/side_seam_mode.enum";
import { CustomBooleanCell } from "shared/components/datagrid/custom_boolean_cell.component";
import { CustomChipCellComponent } from "shared/components/datagrid/custom_chip_cell.component";
import { CustomFilterOperators } from "shared/components/datagrid/custom_filter_operators";
import { CustomIconCell } from "shared/components/datagrid/custom_icon_cell.component";
import { DatagridMenu } from "shared/components/datagrid/datagrid_menu.component";
import { AllIconComponent } from "shared/components/icons/all_icon.component";
import { PublishedIconComponent } from "shared/components/icons/published_icon.component";
import { UnpublishedIconComponent } from "shared/components/icons/unpublished_icon.component";

const buildBodyAreaDisplayString = (categoryItem): string => {
    return categoryItem.bodyAreas.map(x => x.name).join(",");
}

const buildSideDisplayString = (categoryItem): string => {
    return categoryItem.bodySides.map(x => MasspointBodySideEnumValuesLookup(x)).join(",");
}

const buildPositionDisplayString = (categoryItem): string => {
    return categoryItem.additionPositionSelection.selectedList.allItems.map(x => x.displayName).join(", ");

}

const buildPrePositionDisplayString = (categoryItem): string => {
    return categoryItem.prepositionSelection.selectedList.allItems.map(x => x.displayName).join(", ");
}

export const filterPositionCategoryValue = (filterItem: GridFilterItem, filteredItems: IPositionCategoryItem[]) => {

const isMeasurementAllowed = (positionCategoryItem) => positionCategoryItem.isLengthAllowed || positionCategoryItem.isWidthAllowed || positionCategoryItem.isDiameterAllowed;

    if (filterItem.value) {
        switch (filterItem.field) {
            case 'bodyArea':
                filteredItems = filteredItems.filter(x => buildBodyAreaDisplayString(x).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'side':
                filteredItems = filteredItems.filter(x => buildSideDisplayString(x).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'pre_position_horizontal_start':
                filteredItems = filteredItems.filter(x => buildPrePositionDisplayString(x.horizontalStartItem).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'position_horizontal_start':
                filteredItems = filteredItems.filter(x => buildPositionDisplayString(x.horizontalStartItem).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'pre_position_horizontal_end':
                filteredItems = filteredItems.filter(x => buildPrePositionDisplayString(x.horizontalEndItem).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'position_horizontal_end':
                filteredItems = filteredItems.filter(x => buildPositionDisplayString(x.horizontalEndItem).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'pre_position_vertical_start':
                filteredItems = filteredItems.filter(x => buildPrePositionDisplayString(x.verticalStartItem).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'position_vertical_start':
                filteredItems = filteredItems.filter(x => buildPositionDisplayString(x.verticalStartItem).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'pre_position_vertical_end':
                filteredItems = filteredItems.filter(x => buildPrePositionDisplayString(x.verticalEndItem).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'position_vertical_end':
                filteredItems = filteredItems.filter(x => buildPositionDisplayString(x.verticalEndItem).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'isDocumentAllowed':
                filteredItems = filteredItems.filter(x => filterItem.value === 'any' ? true: x.isDocumentAllowed.toString() === filterItem.value);
                break;        
            case 'isMeasurementAllowed':
                filteredItems = filteredItems.filter(x => filterItem.value === 'any' ? true: isMeasurementAllowed(x).toString() === filterItem.value);
                break; 
            case 'isSideSeamAllowed':   
                filteredItems = filteredItems.filter(x => filterItem.value === 'any' ? true: x.sideSeamModeAllowed.toString() === filterItem.value);
                break;     
        }
    }
    return filteredItems;
}

export const getGroupingModel = (): GridColumnGroupingModel => 
    [
        {
          groupId: 'horizontal_start',
          headerName: "Horizontal Start",
          children: [{ field: 'pre_position_horizontal_start'},{ field: "position_horizontal_start"}],
        },    
        {
          groupId: 'horizontal_end',
          headerName: "Horizontal Ende",
          children: [{ field: 'pre_position_horizontal_end'},{ field: "position_horizontal_end"}],
        },    
        {
          groupId: 'vertical_start',
          headerName: "Vertikal Start",
          children: [{ field: 'pre_position_vertical_start'},{ field: "position_vertical_start"}],
        },    
        {
          groupId: 'vertical_end',
          headerName: "Vertikal Ende",
          children: [{ field: 'pre_position_vertical_end'},{ field: "position_vertical_end"}],
        },
    ]

export const getColumnVisibilityModel = (isSideSeamAllowed: boolean): GridColumnVisibilityModel => {
    let visibilityModel: GridColumnVisibilityModel  = {};

    if(!isSideSeamAllowed){
        visibilityModel = {
            isSideSeamAllowed: false
        }
    }
    return visibilityModel;
}

export const getGridColumns = (
    editPositionCategoryItemByIndex: (index: number) => {},
    deletePositionCategoryItemByIndex: (index: number) => {}
): GridColDef[] => [
        {
            field: "menu",
            headerName: " ",
            width: 15,
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            hideable: false,
            pinnable: false,
            renderCell: params => DatagridMenu({
                params: params, menuItemsData:
                    [
                        { label: "Positionskategorie-Item editieren", action: () => editPositionCategoryItemByIndex(params.row.sequenceId) },
                        { label: "Positionskategorie-Item löschen", action: () => deletePositionCategoryItemByIndex(params.row.sequenceId) }
                    ]
            })
        },
        {
            field: "bodyArea",
            headerName: "Körperregion",
            disableColumnMenu: false,
            flex: 0.5,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterOperators: CustomFilterOperators(),
            valueGetter: (value, row) => buildBodyAreaDisplayString(row)
        },
        {
            field: "side",
            headerName: "Seite",
            disableColumnMenu: false,
            flex: 0.5,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterOperators: CustomFilterOperators(),
            valueGetter: (value, row) => buildSideDisplayString(row)
        },
        {
            field: "pre_position_horizontal_start",
            headerName: "Präposition",
            disableColumnMenu: false,
            flex: 0.5,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterOperators: CustomFilterOperators(),
            valueGetter: (value, row) => buildPrePositionDisplayString(row.horizontalStartItem)
        },
        {
            field: "position_horizontal_start",
            headerName: "Körperposition",
            disableColumnMenu: false,
            flex: 0.4,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterOperators: CustomFilterOperators(),
            valueGetter: (value, row) => buildPositionDisplayString(row.horizontalStartItem)
        },
        {
            field: "pre_position_horizontal_end",
            headerName: "Präposition",
            flex: 0.5,
            disableColumnMenu: false,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterOperators: CustomFilterOperators(),
            valueGetter: (value, row) => buildPrePositionDisplayString(row.horizontalEndItem)
        },
        {
            field: "position_horizontal_end",
            headerName: "Körperposition",
            disableColumnMenu: false,
            flex: 0.4,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterOperators: CustomFilterOperators(),
            valueGetter: (value, row) => buildPositionDisplayString(row.horizontalEndItem)
        },
        {
            field: "pre_position_vertical_start",
            headerName: "Präposition",
            disableColumnMenu: false,
            flex: 0.5,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterOperators: CustomFilterOperators(),
            valueGetter: (value, row) => buildPrePositionDisplayString(row.verticalStartItem)
        },
        {
            field: "position_vertical_start",
            headerName: "Körperposition",
            disableColumnMenu: false,
            flex: 0.4,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterOperators: CustomFilterOperators(),
            valueGetter: (value, row) => buildPositionDisplayString(row.verticalStartItem)
        },
        {
            field: "pre_position_vertical_end",
            headerName: "Präposition",
            disableColumnMenu: false,
            flex: 0.5,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterOperators: CustomFilterOperators(),
            valueGetter: (value, row) => buildPrePositionDisplayString(row.verticalEndItem)
        },
        {
            field: "position_vertical_end",
            headerName: "Körperposition",
            flex: 0.4,
            disableColumnMenu: false,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterOperators: CustomFilterOperators(),
            valueGetter: (value, row) => buildPositionDisplayString(row.verticalEndItem)
        },
        {
            field: "isDocumentAllowed",
            headerName: "Bild",
            width: 25,
            disableColumnMenu: false,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterOperators: getGridBooleanOperators(),
            renderCell: (params) => CustomBooleanCell({value: params.row.isDocumentAllowed})
        },
        {
            field: "isMeasurementAllowed",
            headerName: "Maß",
            width: 25,
            disableColumnMenu: false,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterOperators: getGridBooleanOperators(),
            renderCell: (params) => CustomBooleanCell({value: params.row.isLengthAllowed || params.row.isWidthAllowed || params.row.isDiameterAllowed})
        },
        {
            field: "isSideSeamAllowed",
            headerName: "s. Naht",
            width: 25,
            disableColumnMenu: false,
            sortable: false,
            hideable: false,
            pinnable: false,
            type: 'singleSelect',
            getOptionValue: (value: any) => value.code,
            getOptionLabel: (value: any) => value.name,
            valueOptions: [
              { code: SideSeamModeEnumValues[1].value.toString(), name:  SideSeamModeEnumValues[1].text },
              { code: SideSeamModeEnumValues[2].value.toString(), name:  SideSeamModeEnumValues[2].text },
              { code: SideSeamModeEnumValues[3].value.toString(), name:  SideSeamModeEnumValues[3].text },
            ],
            filterOperators: getGridSingleSelectOperators(),
            renderCell: (params) => CustomIconCell<SideSeamModeEnum>( { value: params.row.sideSeamModeAllowed, iconSelector: (value) => renderSideSeamMode(value) })
        },
        {
            field: "excludedArticleTypesCount",
            headerName: "a. Artikelarten",
            disableColumnMenu: false,
            flex: 0.3,
            sortable: false,
            hideable: false,
            pinnable: false,
            filterOperators: CustomFilterOperators('number'),
            renderCell: (params) => CustomChipCellComponent({content: calcExcludedArticleTypesCount(params.row)})
        },
    ]

const renderSideSeamMode = (sideSeamMode: SideSeamModeEnum) => {
    if(sideSeamMode === SideSeamModeEnum.Both) {
        return <AllIconComponent/>;
    } else if (sideSeamMode === SideSeamModeEnum.Normal) {
        return <UnpublishedIconComponent/>;
    } else if (sideSeamMode === SideSeamModeEnum.SideSeam) {
        return <PublishedIconComponent/>;
    } else {
        return <></>
    }
}

const calcExcludedArticleTypesCount = (item: IPositionCategoryItem): string => {
    let count = "0";
    if(item.excludedArticleTypesEnabled){
        count = (item.excludedArticleTypesSelection?.selectedList.allItems.length ?? 0).toString()
    }
    return count;
}

export const sortByBodyArea: GridSortModel = [
    {
        field: "bodyArea",
        sort: "desc",
    },
];