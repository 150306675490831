import { createAsyncThunk } from '@reduxjs/toolkit';
import BaseDataService from '../../../services/base_data.service';
import MassPointService from '../../../services/masspoint.service';
import { OneLegService } from '../../../services/oneleg.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';
import { RootState } from '../../../app/redux_store';

export const fetchAllData = createAsyncThunk(
    'massMasks/oneLeg/create/fetchAllData',
    async (_, thunkApi) => {
        await Promise.all([
            thunkApi.dispatch(getBaseData()),
            thunkApi.dispatch(getMassPoints()),
        ]);
    }
)

export const getBaseData = createAsyncThunk(
    'massMasks/oneLeg/create/getBaseDate',
    async (_) => {
        const response = await BaseDataService.getBaseData();
        handleResponseErrors(response, "Basisdaten");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'massMasks/oneLeg/create/cancelSave',
    async (_) => { }
)

export const getMassPoints = createAsyncThunk(
    'massMasks/oneLeg/create/getMassPoints',
    async (_, { getState }) => {
        const response = await MassPointService.getMassPoints()
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)

export const createOneLeg = createAsyncThunk(
    'massMasks/oneLeg/create/createOneLeg',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const actualData = state.one_leg_create.actualData;
        const response = await OneLegService.createOneLeg({
            mainProductLineId: actualData.selectedProductLine.id,
            articleTypeId: actualData.selectedArticleType.id,
            massPoints: actualData.massPointCriteria.selected.allItems.map(item => {
                return {
                    name: item.massPointName,
                    type: item.massPointType,
                    bodyAreaId: item.bodyArea.id,
                };
            })
        });

        handleResponseErrors(response, "Fußoption");
        return response;
    }
)