import { createSlice } from '@reduxjs/toolkit';
import { CheckHistoryDetailsState } from './check_history_details.model';
import { fetchCheckHistoryDetails } from './check_history_details.thunks';

const initialState: CheckHistoryDetailsState = {
    data: {
        checkHistoryDetails: null,
    },
    query: {
        fetchCheckHistoryDetails: { status: "idle", canExecute: true },
    },
}

export const getCheckHistoryDetailsSlice = createSlice({
    name: 'check_history/check_history_details',
    initialState,
    reducers: {
    }, extraReducers: (builder) => {

        // fetch CheckHistoryDetails
        builder.addCase(fetchCheckHistoryDetails.pending, (state, action) => {
            state.query.fetchCheckHistoryDetails.status = "pending"
            state.query.fetchCheckHistoryDetails.canExecute = false;
        }).addCase(fetchCheckHistoryDetails.rejected, (state, action) => {
            state.query.fetchCheckHistoryDetails.status = "error"
            state.query.fetchCheckHistoryDetails.message = action.error.message;
            state.query.fetchCheckHistoryDetails.canExecute = true;
        }).addCase(fetchCheckHistoryDetails.fulfilled, (state, action) => {
            state.query.fetchCheckHistoryDetails.status = "success"
            state.query.fetchCheckHistoryDetails.message = undefined;
            state.query.fetchCheckHistoryDetails.canExecute = true;
            state.data.checkHistoryDetails = action.payload.getData();
        })
    }
})

export default getCheckHistoryDetailsSlice.reducer