import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/redux_store';
import MassPointService from '../../../services/masspoint.service';
import { errorMessages, handleResponseErrors } from '../../../shared/networking/error_handling';

export const fetchMasspointOverviews = createAsyncThunk(
    'masspoints/masspoint_overview/fetchMasspointsOverview',
    async (thunkApi) => {
        const response = await MassPointService.getMassPoints();
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)

export const confirmDeleteMasspoint = createAsyncThunk(
    'masspoints/masspoint_overview/confirmDeleteMasspoint',
    async (_, { getState }) => {
        const state = getState() as RootState
        const masspointId = state.masspoint_overviews.actualData.selectedMasspointOverview.id
        const responseDelete = await MassPointService.deleteMassPoint(masspointId)
        handleResponseErrors(responseDelete, "Maßpunkt", errorMessages.dataAllreadyUsedExists("Maßpunkt"));

        const response = await MassPointService.getMassPoints();
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)