import { EnumDescriptor } from "./enum_descriptor";

export enum HistoryTypeEnum {
    Undefined,
    Created,
    Modified,
    Deleted
}

export const HistoryValues: Array<
    EnumDescriptor<HistoryTypeEnum>
> = [
        { value: HistoryTypeEnum.Undefined, text: "Nicht definiert" },
        { value: HistoryTypeEnum.Created, text: "Erstellt" },
        { value: HistoryTypeEnum.Modified, text: "Bearbeitet" },
        { value: HistoryTypeEnum.Deleted, text: "Gelöscht" },
    ];