import { Grid } from '@mui/material';
import { EditorDescriptionComponent } from 'shared/components/editors/editor_description/editor_description.style';
import { DecimalInputComponent } from 'shared/components/input/numberInput/decimal_input.component';
import { doesExist } from 'services/validation.service';
import { IAdditionEditor, IAdditionEditorAction } from 'models/additions/addition_editor.model';

interface PlaceHolderRangeComponentProps {
    additionEditor: IAdditionEditor,
    additionEditorActions: IAdditionEditorAction
    placeholderQualityConfigIndex?: number
}

export const PlaceHolderRangeComponent: React.FC<PlaceHolderRangeComponentProps> = (
    {
        additionEditor,
        additionEditorActions,
        placeholderQualityConfigIndex
    }) => {

    let minimalValue = null;
    let maximalValue = null;

    if (doesExist(placeholderQualityConfigIndex)) {
        minimalValue = additionEditor.addition.placeholder.qualityConfigs[placeholderQualityConfigIndex].config.rangePlaceholderVersion.minimalValue;
        maximalValue = additionEditor.addition.placeholder.qualityConfigs[placeholderQualityConfigIndex].config.rangePlaceholderVersion.maximalValue;
    }
    else {
        minimalValue = additionEditor.addition.placeholder.defaultConfig.rangePlaceholderVersion.minimalValue;
        maximalValue = additionEditor.addition.placeholder.defaultConfig.rangePlaceholderVersion.maximalValue;
    }

    const isInteger = additionEditor.addition.placeholder.isInteger;

    const updateRangeMinimalValue = additionEditorActions.updateRangeMinimalValue;
    const updateRangeMaximalValue = additionEditorActions.updateRangeMaximalValue;

    return (<Grid direction="row"
        container
        justifyContent="flex-start"
        alignContent="flex-end"
        alignItems="flex-end">
        <Grid item md={3}>
            <EditorDescriptionComponent>von Mindestwert</EditorDescriptionComponent>
        </Grid>
        <Grid item md={3}>
            <DecimalInputComponent
                name={"von"}
                fixedPositions={isInteger ? 0 : 1 }
                placeholder={isInteger ? '1' : '0.1'}
                minValue={isInteger ? 1 : 0.1}
                maxValue={isInteger ? 999 : 999.9}
                isRequired={true}
                onChange={(value) => updateRangeMinimalValue({ index: placeholderQualityConfigIndex, value: value })}
                value={minimalValue}
            />
        </Grid>
        <Grid item md={3}>
            <EditorDescriptionComponent>bis Maximalwert</EditorDescriptionComponent>
        </Grid>
        <Grid item md={3}>
            <DecimalInputComponent
                name={"bis"}
                fixedPositions={isInteger ? 0 : 1 }
                placeholder={isInteger ? '999' : '999.9'}
                minValue={isInteger ? 1 : 0.1}
                maxValue={isInteger ? 999 : 999.9}
                isRequired={true}
                onChange={(value) => updateRangeMaximalValue({ index: placeholderQualityConfigIndex, value: value })}
                value={maximalValue}
            />
        </Grid>
    </Grid>
    );
};