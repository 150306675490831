import { HistoryValues } from "models/history_type.enum";
import { AdditionHistoryOverviewsState } from "./addition_history.model";
import { filterAdditionHistoryOverviewValue } from "../addition_history.helper";

export const filterAdditionHistoryOverviews = (state: AdditionHistoryOverviewsState) => {
    const searchTextLower = state.actualData.searchFilter.toLocaleLowerCase().trim();
    let filteredItems = state.loadedData.additionHistoryOverviews.filter(
        (additionHistoryOverview) =>{
            const type = HistoryValues.find(x => x.value === additionHistoryOverview.type)?.text;

            return (
                type.toLowerCase().includes(searchTextLower) ||
                additionHistoryOverview.userName?.toLowerCase().includes(searchTextLower) ||
                additionHistoryOverview.option?.toLowerCase().includes(searchTextLower) ||
                additionHistoryOverview.code?.toLowerCase().includes(searchTextLower));  
        }
    );

    state.actualData.gridFilters.items.forEach(fil => {
        filteredItems = filterAdditionHistoryOverviewValue(fil, filteredItems);
    });
    
    state.actualData.additionHistoryOverviews = filteredItems;

}