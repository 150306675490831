import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/redux_store';
import { handleResponseErrors } from '../../../shared/networking/error_handling';
import AdditionCategoryService from 'services/addition_category.service';

export const saveAdditionCategory = createAsyncThunk(
    'additioncategory/saveAddition',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const additionCategoryKey= state.addition_category.actualData.categorykey;
        const additionValue = state.addition_category.actualData.categoryvalue;
        const response = await AdditionCategoryService.createAdditionCategory({categoryKey: additionCategoryKey, value: additionValue});
        handleResponseErrors(response, "Zusatzkategorie");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'additioncategory/cancelSave',
    async (_) => { }
)

