import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/redux_store';
import { errorMessages, handleResponseErrors } from '../../../../shared/networking/error_handling';
import SerialSizeService from 'services/serial_size.service';

export const fetchSerialSizesOverviews = createAsyncThunk(
    'serial_sizes/serial_sizes_overview/fetchSerialSizesOverviews',
    async (_) => {
        const response = await SerialSizeService.getSerialSizes();
        handleResponseErrors(response, "Seriengrößen");
        return response;
    }
)

export const confirmDeleteSerialSize = createAsyncThunk(
    'serial_sizes/serial_sizes_overview/confirmDeleteSerialSize',
    async (_, { getState }) => {
        const state = getState() as RootState
        const serialSizeId = state.serial_size_overview.actualData.selectedSerialSizeOverview.id
        const responseDelete = await SerialSizeService.deleteSerialSize(serialSizeId)
        handleResponseErrors(responseDelete, "Seriengröße", errorMessages.dataAllreadyUsedExists("Seriengröße"));

        const response = await SerialSizeService.getSerialSizes();
        handleResponseErrors(response, "Seriengrößen");
        return response;
    }
)