export enum SimulationTypeEnum {
    Undefined = 0,
    Mass = 1,
    CustomDesign = 2
}

export const SimulationTypeEnumValues = [
    { value: SimulationTypeEnum.Undefined, text: "Kein Auswahl" },
    { value: SimulationTypeEnum.Mass, text: "Erfassung nach Maß" },
    { value: SimulationTypeEnum.CustomDesign, text: "Sonderanfertigung" }
];