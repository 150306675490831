import { ICharacteristicCategoryOverview } from "models/characteristic_categories/characteristic_category_overview";
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from './config.service';
import { CommonApiResult } from "shared/networking/common_api_result";
import HttpServiceCommonResult from "shared/networking/http_service_common_result";
import { ICharacteristicCategoryCreate, ICharacteristicCategoryUpdate } from "models/characteristic_categories/characteristic_category_create";
import { ICharacteristicCategory } from "models/characteristic_categories/characteristic_category";

class CharacteristicCategoryAPI extends HttpServiceCommonResult {
    public async getCharacteristicCategory(id: string): Promise<ApiResult<ICharacteristicCategory>> {
        return this.get<ICharacteristicCategory>(`/api/characteristiccategory/${id}`);
    }  

    public async getCharacteristicCategories(): Promise<ApiResult<Array<ICharacteristicCategoryOverview>>> {
        return this.get<Array<ICharacteristicCategoryOverview>>("/api/characteristiccategory");
    }

    public async createCharacteristicCategory(category: ICharacteristicCategoryCreate): Promise<ApiResult<void>> {
        return this.post<ICharacteristicCategoryCreate,void>("/api/characteristiccategory", category);
    } 

    public async deleteCharacteristicCategory(id: number): Promise<ApiResult<void>> {
        return this.delete(`/api/characteristiccategory/${id}`);
    }

    public async updateCharacteristicCategory(data: ICharacteristicCategoryUpdate): Promise<ApiResult<void>> {
        return this.put<ICharacteristicCategoryUpdate,void>(`/api/characteristiccategory`, data);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const CharacteristicCategoryService = new CharacteristicCategoryAPI(TimeoutMilliseconds);

export default CharacteristicCategoryService;
