import { GridFilterItem } from "@mui/x-data-grid-pro";
import { BodyInformationOverviewState } from "./bodyinformation_overview.model";
import IBodyInformationOverview from "models/bodyinformation/bodyinformation_overview";

export const filterBodyInformations = (state: BodyInformationOverviewState) => {
    const searchTextLower = state.actualData.searchFilter.toLowerCase().trim();
    let filteredItems = state.loadedData.bodyInformations.filter(
        (bodyInformation) =>
            bodyInformation.translationKeyForName.toLowerCase().includes(searchTextLower)
    );

    state.actualData.gridFilter.items.forEach(fil => {
        filteredItems = filterBodyInformationValue(fil, filteredItems);
    });

    state.actualData.bodyInformations = filteredItems;
}

const filterBodyInformationValue = (filterItem: GridFilterItem, filteredItems: IBodyInformationOverview[]) => {
    if (filterItem.value) {
        switch (filterItem.field) {
            case 'categoryName':
                filteredItems = filteredItems.filter(x => x.translationKeyForName.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
        }
    }
    return filteredItems;
}