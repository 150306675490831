import { GridFilterItem, GridColDef, GridSortModel } from "@mui/x-data-grid-pro";
import { ICharacteristicTypeOverview } from "models/characteristic_types/characteristic_type_overview";
import { ITranslationValue } from "models/translation/translation_value";
import { CustomChipCellComponent } from "shared/components/datagrid/custom_chip_cell.component";
import { CustomFilterOperators } from "shared/components/datagrid/custom_filter_operators";
import { DatagridMenu } from "shared/components/datagrid/datagrid_menu.component";

export const filterCharacterTypeValue = (filterItem: GridFilterItem, filteredItems: ICharacteristicTypeOverview[]) => {
    if(filterItem.value)
    {
        switch(filterItem.field)
        {
            case 'name':
                filteredItems = filteredItems.filter(x => x.name.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'shortKey':
                filteredItems = filteredItems.filter(x => x.shortKey.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;                                                                                                              
        }
    }
    return filteredItems;
}

export const getGridColumns = (
    editItem: (type: ICharacteristicTypeOverview) => void,
    deleteItem: (type: ICharacteristicTypeOverview) => void,
    ): GridColDef[] => [
    {
        field: "menu",
        headerName: " ",
        width: 15,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        renderCell: params => DatagridMenu({params: params, menuItemsData: 
            [
                {label: "Ausprägungstyp editieren", action: editItem},
                {label: "Ausprägungstyp löschen", action: deleteItem},
            ]})
    },
    {
        field: "name",
        headerName: "Name",
        disableColumnMenu: false,
        flex: 0.4,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    }, 
    {
        field: "shortKey",
        headerName: "Kürzel",
        disableColumnMenu: false,
        flex: 0.4,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    }, 
    {
        field: "rulesCount",
        headerName: "Anzahl Regeln",
        disableColumnMenu: false,
        width: 100,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators('number'),
        renderCell: (params) => CustomChipCellComponent({content: (params.row.values.length), info: (createInfo(params.row.values))})
    },
]    

export const sortByNameModel: GridSortModel = [
    {
        field: "name",
        sort: "asc",
    },
];

const createInfo = (values : ITranslationValue[]): string => {
    let info: string = null;
    if(values && values.length > 0) {
        info = '';
        values.forEach(x => {
            if(info === '') {
                info+= x.name
            } else {
                info+= `; ${x.name}`;
            }
        }); 
    }
    return info;
}