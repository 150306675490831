import { filterMassmaskValue } from "../massmask_selection.helper";
import { MassmaskSelectionState } from "./massmask_selection.model";

export const filterMassMasks = (state: MassmaskSelectionState) => {
    const searchTextLower = state.actualData.searchFilter.toLocaleLowerCase().trim();
    let filteredItems = state.loadedData.massMasks.filter(
        (massmask) =>{

            const articelType = `${massmask.articleType.erpId} ${massmask.articleType.name}`;
            return (
                massmask.mainProductLine.name.toLowerCase().includes(searchTextLower) ||
                massmask.bodyArea.name.toLowerCase().includes(searchTextLower) ||
                articelType.toLowerCase().includes(searchTextLower));
        }
    );

    state.actualData.gridFilters.items.forEach(fil => {
        filteredItems = filterMassmaskValue(fil, filteredItems);
    });
    
    state.actualData.massMasks = filteredItems;

}