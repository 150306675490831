import { Grid } from '@mui/material';
import { EditorDescriptionComponent } from 'shared/components/editors/editor_description/editor_description.style';
import { DecimalInputComponent } from 'shared/components/input/numberInput/decimal_input.component';
import { doesExist } from 'services/validation.service';
import { IAdditionEditor, IAdditionEditorAction } from 'models/additions/addition_editor.model';

interface PlaceHolderTextInputComponentProps {
    additionEditor: IAdditionEditor,
    additionEditorActions: IAdditionEditorAction
    placeholderQualityConfigIndex?: number
}

export const PlaceHolderTextInputComponent: React.FC<PlaceHolderTextInputComponentProps> = (
    {
        additionEditor, 
        additionEditorActions,
        placeholderQualityConfigIndex
    }) => {

    let placeholderTextLength = null;
    if(doesExist(placeholderQualityConfigIndex)){
        placeholderTextLength = additionEditor.addition.placeholder.qualityConfigs[placeholderQualityConfigIndex].config.textInputPlaceholderVersion.textInputMaxLength; 
    }
    else{
        placeholderTextLength = additionEditor.addition.placeholder.defaultConfig.textInputPlaceholderVersion.textInputMaxLength;    
    }
    const updatePlaceholderTextLength = additionEditorActions.updatePlaceholderTextLength;    

    return (<Grid direction="row"
                container
                justifyContent="flex-start"
                alignContent="flex-end"
                alignItems="flex-end">
                <Grid item md={9}>
                    <EditorDescriptionComponent>maximale Zeichenanzahl inklusive Leerzeichen, die zur Verfügung stehen</EditorDescriptionComponent>
                </Grid>
                <Grid item md={3}>
                    <DecimalInputComponent
                        name={""}
                        fixedPositions={0}
                        placeholder='999'
                        minValue={1}
                        maxValue={999}
                        isRequired={true}
                        onChange={(value) => updatePlaceholderTextLength({ index: placeholderQualityConfigIndex, value: value})}
                        value={placeholderTextLength}
                    />  
                </Grid>
            </Grid>                  
    );
};