import { Box, BoxProps, Tab, TabProps, Tabs, TabsProps, styled } from "@mui/material";
import colors from "shared/style/colors";

export const BoxItem = (props: BoxProps) => {
    const { sx, ...other } = props;
    return (
        <Box
            sx={{
                marginTop: '50px',
                paddingBottom: '20px',
                boxShadow: 'none',
                borderBottom: '2px solid #e6007e',
                '&:not(:last-child)': {
                    borderBottom: 0,
                },
                '&:before': {
                    display: 'none',
                },
                ...sx,
            }}
            {...other}
        />
    );
}

export const TabListItem = (props: TabsProps) => {
    const { sx, ...other } = props;
    return (
        <Tabs
            sx={{
                '& .MuiTabs-indicator': {
                    height: 0,
                },
                ...sx,
            }}
            {...other}
        />
    );
}

export const TabItem = (props: TabProps) => {
    const { sx, ...other } = props;
    return (
        <Tab
            sx={{
                minWidth: '200px',
                '&.MuiTab-root': {
                    borderWidth: 0,
                    borderBottom: `2px solid ${colors.mediMagenta}`,
                },
                '&.Mui-selected': {
                    border: `2px solid ${colors.mediMagenta}`,
                    borderBottomWidth: 0
                },
                ...sx,
            }}
            {...other}
        />
    );
}

export const TabItemSpacer = styled('div')({
    'width': '100%',
    'borderBottom': `2px solid ${colors.mediMagenta}`
})

export const MarginBottom = styled('div')({
    'margin-bottom': '30px'
});