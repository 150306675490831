import { useState } from 'react';
import { ProductConfigurationViewModel } from '../../../../models/simulator/product_configuration';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ProductConfigurationRunTableComponent from './product_configuration_run_table.component';
import { useAppDispatch } from 'app/hook';
import { AppDispatch } from 'app/redux_store';
import * as style from './product_configurations_accordion.style';
import { AccordionDetailsItem, AccordionItem, AccordionSummaryItem } from './product_configurations_accordion.style';
import { DateTimeDisplayComponent } from 'shared/components/form_controls/date_time_display.component';
import { MenuOverviewComponent } from 'shared/components/overview/menu_overview.component';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { cloneProductConfiguration, loadProductConfiguration, lockProductConfiguration, unlockProductConfiguration } from '../redux/product_configurations_overview.thunks';
import { CustomBooleanCell } from 'shared/components/datagrid/custom_boolean_cell.component';
import { setProductconfiguration } from '../redux/product_configurations_overview.slice';
import { ProductConfigurationRun } from 'models/simulator/product_configuration_run';

type ProductConfigurationsAccordionComponentItemProps = {
    productConfiguration: ProductConfigurationViewModel;
    toggleExpandedProductConfiguration: (index: number) => void;
    index: number;
    isExpanded: boolean;
};

export const ProductConfigurationsAccordionComponentItem = (props: ProductConfigurationsAccordionComponentItemProps) => {
    const dispatch: AppDispatch = useAppDispatch();
    const [menuAnchor, setAnchorEl] = useState(null);

    const onMenuOpen = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const onMenuClose = () => {
        setAnchorEl(null);
    };

    const toggleLockStatus = () => {
        if (props.productConfiguration?.isLocked) {
            dispatch(unlockProductConfiguration(props.productConfiguration?.id))
        }
        else {
            dispatch(lockProductConfiguration(props.productConfiguration?.id))
        }
        onMenuClose();
    }
    
    const restoreLastRun = () => {
        dispatch(setProductconfiguration(props.productConfiguration?.id)); 
        dispatch(loadProductConfiguration());
        onMenuClose();
    }

    const sortbyId = (pr1: ProductConfigurationRun, pr2: ProductConfigurationRun) => {
        return pr2.id - pr1.id;
    }

    var createdAt = props.productConfiguration.productConfigurationRuns
        .flatMap(pr => pr.productConfigurationRun)
        .sort(sortbyId)[0].createdAt; 

    return (
        <>
            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={onMenuClose}
            >
                <MenuItem
                    key={1}
                    disabled = {props.productConfiguration.system !== "Simulator"}
                    onClick={toggleLockStatus}>
                    {props.productConfiguration?.isLocked ? "Schreibschutz aufheben" : "Schreibschutz setzen"}
                </MenuItem>   
                <MenuItem
                    key={2}
                    disabled = {props.productConfiguration.system !== "Simulator"}
                    onClick={restoreLastRun}>
                    {"Letzten Testlauf wiederherstellen"}
                </MenuItem>
                <MenuItem
                    key={3}
                    onClick={() => {
                        dispatch(
                        cloneProductConfiguration({
                            productConfigurationId: props.productConfiguration.id
                        }))
                    }}>Duplizieren
                    </MenuItem>
            </Menu>
            <AccordionItem id={props.productConfiguration.id} square expanded={props.isExpanded} onChange={() => props.toggleExpandedProductConfiguration(props.index)}>
                <AccordionSummaryItem aria-controls={`${props.productConfiguration}-content`} id={`${props.productConfiguration}-header`}>
                    <style.HeaderIconColumn>
                        <IconButton style={{ color: 'white' }}>
                            {props.isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                        <MenuOverviewComponent color='white' onMenuOpen={onMenuOpen} menuItem={undefined} />
                    </style.HeaderIconColumn>
                    <style.HeaderColumnLong>
                        {props.productConfiguration.id}
                    </style.HeaderColumnLong>
                    <style.HeaderColumn>
                        {props.productConfiguration.system}
                    </style.HeaderColumn>   
                    <style.HeaderColumn>
                        <DateTimeDisplayComponent date={createdAt} />
                    </style.HeaderColumn>                      
                    <style.HeaderColumn>
                        <DateTimeDisplayComponent date={props.productConfiguration.createdAt} />
                    </style.HeaderColumn>                                   
                    <style.HeaderColumn>
                        <CustomBooleanCell value={props.productConfiguration.isLocked} />
                    </style.HeaderColumn>
                </AccordionSummaryItem>
                <AccordionDetailsItem>
                    <ProductConfigurationRunTableComponent productConfiguration={props.productConfiguration} productConfigurationRuns={props.productConfiguration.productConfigurationRuns} />
                </AccordionDetailsItem>
            </AccordionItem>
        </>
    );
}