import { GridFilterItem, GridColDef, GridSortModel } from "@mui/x-data-grid-pro";
import { HistoryTypeEnum, HistoryValues } from "models/history_type.enum";
import IMassmaskHistoryOverview from "models/massmasks_history/massmask_history_overview";
import { CustomDateCell } from "shared/components/datagrid/custom_date_cell.component";
import { CustomFilterOperators } from "shared/components/datagrid/custom_filter_operators";
import { DatagridMenu } from "shared/components/datagrid/datagrid_menu.component";

export const buildTypeDisplayText = ( type: HistoryTypeEnum ) => {
    return HistoryValues.find(x => x.value === type)?.text;
}

export const filterMassmaskHistoryOverviewValue = (filterItem: GridFilterItem, filteredItems: IMassmaskHistoryOverview[]) => {
    if(filterItem.value)
    {
        switch(filterItem.field)
        {
            case 'type':
                filteredItems = filteredItems.filter(x => buildTypeDisplayText(x.type).toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;           
            case 'userName':
                filteredItems = filteredItems.filter(x => x.userName?.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;          
            case 'mainProductLineName':
                filteredItems = filteredItems.filter(x => x.mainProductLineName?.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;  
            case 'bodyAreaName':
                filteredItems = filteredItems.filter(x => x.bodyAreaName?.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;    
            case 'articleTypeName':
                filteredItems = filteredItems.filter(x => x.articleTypeName?.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;             
            case 'footTypeName':
                filteredItems = filteredItems.filter(x => x.footTypeName?.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;                                                                                                      
        }
    }
    return filteredItems;
}

export const getGridColumns = ( 
        showDetails: (history: IMassmaskHistoryOverview) => void,
    ): GridColDef[] => [
    {
        field: "menu",
        headerName: " ",
        width: 15,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        renderCell: params => DatagridMenu({params: params, menuItemsData: 
            [
                {label: "Details ansehen", action: showDetails}
            ]})
    },
    {
        field: "date",
        headerName: "Datum",
        disableColumnMenu: true,
        flex: 0.15,
        sortable: false,
        hideable: false,
        pinnable: false,
        valueGetter: (value, row) => row.mainProductLine?.name,
        renderCell: params => CustomDateCell({date: params.row.date})
    },  
    {
        field: "type",
        headerName: "Typ",
        disableColumnMenu: false,
        flex: 0.15,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
        valueGetter: (value, row) => buildTypeDisplayText(row.type) 
    },  
    {
        field: "userName",
        headerName: "Benutzer",
        disableColumnMenu: false,
        flex: 0.15,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    },   
    {
        field: "mainProductLineName",
        headerName: "Hauptproduktlinie",
        disableColumnMenu: false,
        flex: 0.15,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators()
    },   
    {
        field: "bodyAreaName",
        headerName: "Körperregion",
        disableColumnMenu: false,
        flex: 0.15,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    },   
    {
        field: "articleTypeName",
        headerName: "Artikelart",
        disableColumnMenu: false,
        flex: 0.15,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    },
    {
        field: "footTypeName",
        headerName: "Fußart",
        disableColumnMenu: false,
        flex: 0.15,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    }
]    

export const sortByMainProductLineModel: GridSortModel = [
    {
        field: "mainProductLine",
        sort: "asc",
    },
];