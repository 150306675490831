import { Grid } from '@mui/material';
import { EditorSectionHeaderComponent } from 'shared/components/editors/editor_header/editor_header.style';
import { EditorDescriptionComponent } from 'shared/components/editors/editor_description/editor_description.style';
import { IAdditionEditor, IAdditionEditorAction } from 'models/additions/addition_editor.model';
import { SelectionListComponent } from 'shared/components/selectionComponent/selectionList.component';

interface AdditionExcludesComponentProps {
    additionEditor: IAdditionEditor,
    additionEditorActions: IAdditionEditorAction
}

export const AdditionExcludesComponent: React.FC<AdditionExcludesComponentProps> = (
    {
        additionEditor,
        additionEditorActions,
    }) => {

    return (<Grid spacing={3}
        container>
        <Grid item md={6}>
            <EditorSectionHeaderComponent>Aussschlüsse</EditorSectionHeaderComponent>
            <EditorDescriptionComponent>Zusätze und Attribute können sich gegenseitig bedingen und ausschließen. Ausschlüsse werden automatisch miteinander verknüpft.</EditorDescriptionComponent>
            <EditorDescriptionComponent>Beispiel:<br />In Zusatz A wird festgelegt, dass Zusatz B nicht mehr zur Verfügung steht, wenn A ausgewählt wird. Durch die Verknüpfung wird für Zusatz B automatisch festgelegt, dass A nicht zur Verfügung steht, wenn B gewählt wird.</EditorDescriptionComponent>
            <EditorSectionHeaderComponent>Zusatzaussschlüsse</EditorSectionHeaderComponent>
            <EditorDescriptionComponent>Wenn Zusatz {additionEditor.addition.option} gewählt wurde, werden folgende Zusätze ausgeschlossen</EditorDescriptionComponent>
        </Grid>
        <Grid item md={12}>
            <SelectionListComponent
                entityName={"Zusatzausschlüsse"}
                onFilterSelected={(searchText) => additionEditorActions.filterSelectedAdditionExcludeCriteria(searchText)}
                onFilterUnselected={(searchText) => additionEditorActions.filterAvailableAdditionExcludeCriteria(searchText)}
                selectionList={additionEditor.additionExcludeCriteriaSelection}
                selectEntities={(ids) => additionEditorActions.selectAdditionExcludeCriteria(ids)}
                unselectEntities={(ids) => additionEditorActions.unselectAdditionExcludeCriteria(ids)}
            />
        </Grid>
    </Grid>
    );
};