import React from "react";
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { MimeTypeConstantsEnum } from "models/mime_type_constants.enum";
import IMassimageOverview from "../../../models/massimages/massimage_overview";
import { LoadingOverlayComponent, LoadingOverlayContainer } from '../../../shared/components/loading_overlay/loading_overlay.component';
import * as style from "./massimages_table.style";

interface MassimagesTableComponentProps {
    massImages: Array<IMassimageOverview>,
    onSelectMassImage: (massImage: IMassimageOverview) => void,
    selectedMassImage: IMassimageOverview,
    isSaving: boolean,
}

const MassimagesTableComponent: React.FC<MassimagesTableComponentProps> = ({ massImages, isSaving, onSelectMassImage, selectedMassImage }) => {
    if (massImages.length === 0) {
        return <div>Keine Maßbilder vorhanden</div>
    }
    return (
        <>
            <h3>Grafik auswählen</h3>
            <LoadingOverlayContainer>
                {isSaving ? <LoadingOverlayComponent /> : <></>}

                <List>
                    {massImages.map(massImage => {
                        return (
                            <style.StyledListItem button={null} isSelected={selectedMassImage === massImage} key={massImage.id} onClick={() => onSelectMassImage(massImage)}>
                                <style.Column1>
                                    <ListItemAvatar>
                                        <style.Thumbnail src={`data:${MimeTypeConstantsEnum.Svg};base64, ${massImage.content}`} alt={massImage.name} />
                                    </ListItemAvatar>
                                </style.Column1>
                                <style.Column2>
                                    <ListItemText primary={massImage.name} />
                                </style.Column2>
                                <ListItemSecondaryAction>
                                </ListItemSecondaryAction>
                            </style.StyledListItem>);
                    })
                    }
                </List>
            </LoadingOverlayContainer>
        </>
    );
};

export default MassimagesTableComponent;