import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "app/redux_store";
import { BodyInformationService } from "services/bodyInformation.service";
import { handleResponseErrors } from "shared/networking/error_handling";

export const createBodyInformation = createAsyncThunk(
    'massMasks/bodyinformation/create/createBodyInformation',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const selectedData = state.body_information_create.actualData.editor;

        const response = await BodyInformationService.createBodyInformation({
            translationKeyForName: selectedData.translationKey,
            type: selectedData.type.value,
            isMandatory: selectedData.isMandatory,
            freeTextLength: selectedData.freeTextLength,
            bodyInformationEntries: selectedData.entriesKeys.map(x => {
                return {
                    translationKeyForName: x.translationKey,
                    shortKey: x.shortKey
                }
            })
        });

        handleResponseErrors(response, "Zusätzliche Angaben");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'massMasks/bodyinformation/create/cancelSave',
    async (_) => { }
)