import { GridApiPro, GridCallbackDetails, GridColDef, GridColumnGroupingModel, GridColumnVisibilityModel, GridFilterModel, GridLogicOperator, GridRowSelectionModel, GridSortModel, GridValidRowModel } from "@mui/x-data-grid-pro";
import { StyledDataGrid } from "shared/style/dataGrid";
import { CustomFilterOperators } from "./custom_filter_operators";
import { CustomNoRowsOverlay } from "./custom_no_rows_overlay.component";
import { CustomColumnMenu } from "./custom_column_menu.component";
import { CustomFooter } from "./custom_footer.component";
import { LoadingOverlayComponent, LoadingOverlayContainer } from "../loading_overlay/loading_overlay.component";

interface OverviewDataGridProps {
    isLoading: boolean
    sortModel?: GridSortModel,
    columns: GridColDef<GridValidRowModel>[],
    columnVisibilityModel?: GridColumnVisibilityModel,
    rows: readonly GridValidRowModel[],
    loadedRowsCount: number,
    onFilterModelChange: (model: GridFilterModel, details: GridCallbackDetails<"filter">) => void,
    onRowSelectionModelChange?: (model: GridRowSelectionModel, details: GridCallbackDetails<any>) => void,
    getRowId?: (row: any) => any,
    autoRowHeight?: boolean,
    onDoubleClickRow?: (row: any) => void,
    checkboxSelection?: boolean;
    groupingModel?: GridColumnGroupingModel;
    apiRef?: React.MutableRefObject<GridApiPro>;
    selectedRowIds?: number[];
    processRowUpdate?: (newRow: GridValidRowModel, oldRow: GridValidRowModel) => GridValidRowModel
    disableRowSelectionOnClick?: boolean;
    pagination?: boolean;
}

export const OverviewDatagrid: React.FC<OverviewDataGridProps> = ({ 
    isLoading, 
    apiRef, 
    sortModel, 
    columns, 
    columnVisibilityModel,
    rows, 
    loadedRowsCount, 
    onFilterModelChange, 
    onRowSelectionModelChange, 
    getRowId, 
    autoRowHeight, 
    onDoubleClickRow, 
    groupingModel, 
    checkboxSelection, 
    selectedRowIds, 
    processRowUpdate, 
    disableRowSelectionOnClick, 
    pagination }) => {

    const isSelectable = (onRowSelectionModelChange && (disableRowSelectionOnClick == undefined || !disableRowSelectionOnClick)) ? true : false;
    const slots = {
        columnMenu: CustomColumnMenu,
        footer: () => CustomFooter({ resultCount: rows.length, total: loadedRowsCount }),
        noRowsOverlay: CustomNoRowsOverlay
    }

    const slotsPaging = {
        columnMenu: CustomColumnMenu,
        noRowsOverlay: CustomNoRowsOverlay
    }

    return (
        <LoadingOverlayContainer>
            {isLoading && <LoadingOverlayComponent />}

            <StyledDataGrid
                apiRef={apiRef}
                checkboxSelection={checkboxSelection}
                enableHover={isSelectable}
                sx={{ width: '100%' }}
                aria-label="category position item table"
                getRowId={getRowId ?? ((row) => row.id)}
                disableRowSelectionOnClick={!isSelectable}
                getRowHeight={() => autoRowHeight ? 'auto' : null}
                rowHeight={60}
                slots={pagination ? slotsPaging : slots}
                slotProps={{
                    columnsManagement:{
                        disableShowHideToggle: true
                    },
                    filterPanel: {
                        filterFormProps: {
                            logicOperatorInputProps: CustomFilterOperators
                        },
                        disableAddFilterButton: false,
                        disableRemoveAllButton: false,
                        logicOperators: [GridLogicOperator.And]
                    }
                }}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'whiteBackground' : 'grayBackground'
                }
                columns={columns}
                columnVisibilityModel={columnVisibilityModel}
                columnGroupingModel={groupingModel}
                filterMode="server"
                sortModel={sortModel}
                rows={rows}
                pagination={pagination ? pagination : false}
                hideFooterPagination={false}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 25, page: 0 },
                    },
                }}
                onFilterModelChange={onFilterModelChange}
                onRowSelectionModelChange={onRowSelectionModelChange}
                processRowUpdate={processRowUpdate}
                onRowDoubleClick={onDoubleClickRow}
                rowSelectionModel={selectedRowIds}
                autoHeight
                localeText={
                    {
                        MuiTablePagination: {
                            labelDisplayedRows: ({ from, to, count }) => <>{from} - {to} von {count}</>,
                            labelRowsPerPage: <>Zeilen pro Seite</>
                        },
                        footerRowSelected: (count) => <>
                            {
                                count !== 1
                                    ? `${count.toLocaleString()} Zeilen ausgewählt`
                                    : `${count.toLocaleString()} Zeile ausgewählt`
                            }
                        </>,
                        footerTotalRows: <>Zeilen insgesamt:</>
                    }}
            />
        </LoadingOverlayContainer>
    );
};