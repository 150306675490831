import IAvailableQuality from "models/available_basedata/available_quality";
import IAvailableArticleType from "../models/available_basedata/available_article_type";
import { IAvailableBaseData } from '../models/available_basedata/available_base_data';
import IAvailableBodyArea from "../models/available_basedata/available_body_area";
import IAvailableFootType from '../models/available_basedata/available_foot_type';
import IAvailableProductLine from "../models/available_basedata/available_product_line";
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from "./config.service";
import IAvailableSize from "models/available_basedata/available_size";
import { PolicyTypeEnum } from "models/policies/policy_type.enum";
import { IAvailableMappedBaseData, IAvailableMappedBaseDataByAddition } from "models/available_basedata/available_mapped_base_data";
import HttpServiceCommonResult from "shared/networking/http_service_common_result";


class BaseDataServiceAPI extends HttpServiceCommonResult {
    public async getBaseData(): Promise<ApiResult<IAvailableBaseData>> {
        return this.get<IAvailableBaseData>(`/api/availablebasedata`);
    }

    public async getMappedBaseData(): Promise<ApiResult<IAvailableMappedBaseData>> {
        return this.get<IAvailableMappedBaseData>(`/api/availablebasedata/mappedBaseData`);
    }

    public async getMappedBaseDataByAddition(additionId: number): Promise<ApiResult<IAvailableMappedBaseDataByAddition>> {
        return this.get<IAvailableMappedBaseDataByAddition>(`/api/availablebasedata/mappedBaseDataByAddition/${additionId}`);
    }

    public async getMappedBaseDataByPolicy(policyType: PolicyTypeEnum, ignoredPolicy?: string): Promise<ApiResult<IAvailableMappedBaseData>> {
        let url = `/api/availablebasedata/mappedbasedata/availablecreation/${policyType}`;
        if (ignoredPolicy) {
          url = url + `?ignoredPolicy=${ignoredPolicy}`;
        }

        return this.get<IAvailableMappedBaseData>(url);
    }

    public async getMainProductLines(): Promise<ApiResult<Array<IAvailableProductLine>>> {
        return this.get<Array<IAvailableProductLine>>(`/api/availablebasedata/mainproductline`);
    }

    public async getBodyAreas(): Promise<ApiResult<Array<IAvailableBodyArea>>> {
        return this.get<Array<IAvailableBodyArea>>(`/api/availablebasedata/bodyarea`);
    }

    public async getFootTypes(): Promise<ApiResult<Array<IAvailableFootType>>> {
        return this.get<Array<IAvailableFootType>>(`/api/availablebasedata/foottype`);
    }

    public async getMatchingBodyAreas(mainProductLineId): Promise<ApiResult<Array<IAvailableBodyArea>>> {
        return this.get<Array<IAvailableBodyArea>>(`/api/availablebasedata/matchingbodyareas?mainProductLineId=${mainProductLineId}`);
    }

    public async getMatchingArticleTypes(mainProductLineId: number, bodyAreaId: number): Promise<ApiResult<Array<IAvailableArticleType>>> {
        return this.get<Array<IAvailableArticleType>>(`/api/availablebasedata/matchingarticletypes?mainProductLineId=${mainProductLineId}&bodyAreaId=${bodyAreaId}`);
    }

    public async getMatchingQualities(mainProductLineId: number, bodyAreaId: number, articleTypeId: number): Promise<ApiResult<Array<IAvailableQuality>>> {
        return this.get<Array<IAvailableQuality>>(`/api/availablebasedata/matchingqualities?mainProductLineId=${mainProductLineId}&bodyAreaId=${bodyAreaId}&articleTypeId=${articleTypeId}`);
    }

    public async getSizes(): Promise<ApiResult<Array<IAvailableSize>>> {
        return this.get<Array<IAvailableSize>>(`/api/availablebasedata/sizes`);
    }

    public async getQualitiesForPolicy(policyTypeEnum: PolicyTypeEnum, ignoredPolicy?: string): Promise<ApiResult<Array<IAvailableQuality>>> {
        let url = `/api/availablebasedata/availablecreation/${policyTypeEnum}`;
        if (ignoredPolicy) {
          url = url + `?ignoredPolicy=${ignoredPolicy}`;
        }
       
        return this.get<Array<IAvailableQuality>>(url);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const BaseDataService = new BaseDataServiceAPI(TimeoutMilliseconds);

export default BaseDataService;
