import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdditionOverviewState } from './addition_overview.model';
import { filterAdditions } from './addition_overview.reducer';
import IAdditionOverview from 'models/additions/addition_overview'
import { createNewAddition, getAdditions, publishAddition, unpublishAddition, updateAddition, confirmDeleteAddition } from './addition_overview.thunks';
import { GridFilterModel } from '@mui/x-data-grid-pro';

const initialState: AdditionOverviewState = {
    loadedData: {
        additions: [],
    },
    actualData: {
        additions: [],
        additonsGridFilter: { items: [] },
        isSearchEnabled: false,
        searchFilter: "",
        showDeleteDialog: false,
        activeAddition: null,
    },
    query: {
        getAdditions: { status: "idle", canExecute: true },
    },
    command: {
        publishAddition: { status: "idle", canExecute: false },
        unpublishAddition: { status: "idle", canExecute: false },
        createNewAddition: { status: "idle", canExecute: false },
        updateAddition: { status: "idle", canExecute: false },
        confirmDeleteAddition: { status: "idle", canExecute: false },
    },
}

export const additionOverviewSlice = createSlice({
    name: 'addition/overview',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loadedData = initialState.loadedData;
            state.actualData = initialState.actualData;
            state.query = initialState.query;
            state.command = initialState.command;
        },
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.actualData.searchFilter = action.payload;
            filterAdditions(state)
        },
        publishAdditionCompleted: (state) => {
            state.command.publishAddition.status = "idle";
        },
        unpublishAdditionCompleted: (state) => {
            state.command.unpublishAddition.status = "idle";
        },
        resetDeleteAddition: (state) => {
            state.command.confirmDeleteAddition = initialState.command.confirmDeleteAddition;
        },
        deleteAddition: (state, action: PayloadAction<IAdditionOverview>) => {
            state.actualData.activeAddition = action.payload;
            state.actualData.showDeleteDialog = true;
        },
        cancelDeleteAddition: (state) => {
            state.actualData.activeAddition = null;
            state.actualData.showDeleteDialog = false;
        },
        setGridFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.additonsGridFilter = action.payload;
            filterAdditions(state);
        },
    }, extraReducers: (builder) => {
        // getAdditions
        builder.addCase(getAdditions.pending, (state, action) => {
            state.query.getAdditions.status = "pending"
            state.query.getAdditions.canExecute = false;
        }).addCase(getAdditions.rejected, (state, action) => {
            state.query.getAdditions.status = "error"
            state.query.getAdditions.message = action.error.message;
        }).addCase(getAdditions.fulfilled, (state, action) => {
            state.query.getAdditions.status = "success"

            const additionals = action.payload.getData();
            state.loadedData.additions = additionals;
            filterAdditions(state);
            state.actualData.isSearchEnabled = (additionals != null && additionals.length > 0);

            // publishAddition
        }).addCase(publishAddition.pending, (state, action) => {
            state.command.publishAddition.status = 'pending'
        }).addCase(publishAddition.rejected, (state, action) => {
            state.command.publishAddition.status = "error"
            state.command.publishAddition.message = action.error.message;
        }).addCase(publishAddition.fulfilled, (state, action) => {
            state.command.publishAddition.status = "success"
            state.command.publishAddition.canExecute = true;

            const additions = action.payload.getData();
            state.loadedData.additions = additions;
            filterAdditions(state)
            state.actualData.isSearchEnabled = (additions != null && additions.length > 0);


            // unpublishAddition
        }).addCase(unpublishAddition.pending, (state, action) => {
            state.command.unpublishAddition.status = 'pending';

        }).addCase(unpublishAddition.rejected, (state, action) => {
            state.command.unpublishAddition.status = "error";
            state.command.unpublishAddition.message = action.error.message;

        }).addCase(unpublishAddition.fulfilled, (state, action) => {
            state.command.unpublishAddition.status = "success";
            state.command.unpublishAddition.canExecute = true;

            const additions = action.payload.getData();
            state.loadedData.additions = additions;
            filterAdditions(state);
            state.actualData.isSearchEnabled = (additions != null && additions.length > 0);

            // confirmDeleteAddition
        }).addCase(confirmDeleteAddition.pending, (state, action) => {
            state.command.confirmDeleteAddition.status = 'pending'
        }).addCase(confirmDeleteAddition.rejected, (state, action) => {
            state.command.confirmDeleteAddition.status = "error"
            state.command.confirmDeleteAddition.message = action.error.message;
            state.actualData.activeAddition = null;
            state.actualData.showDeleteDialog = false;
        }).addCase(confirmDeleteAddition.fulfilled, (state, action) => {
            state.command.confirmDeleteAddition.status = "success"
            state.actualData.additions = action.payload.getData();
            state.actualData.activeAddition = null;
            state.actualData.showDeleteDialog = false;

            // createNewAddition
        }).addCase(createNewAddition.pending, (state, action) => {
            state.command.createNewAddition.status = "pending"
        }).addCase(createNewAddition.fulfilled, (state, action) => {
            state.command.createNewAddition.status = "success"

            // updateAddition
        }).addCase(updateAddition.pending, (state, action) => {
            state.command.updateAddition.status = "pending"
        }).addCase(updateAddition.fulfilled, (state, action) => {
            state.command.updateAddition.status = "success"
            state.actualData.activeAddition = action.payload;
        })
    }
})

export const {
    publishAdditionCompleted,
    unpublishAdditionCompleted,
    setSearchFilter,
    deleteAddition,
    cancelDeleteAddition,
    resetState,
    resetDeleteAddition,
    setGridFilters,
} = additionOverviewSlice.actions

export default additionOverviewSlice.reducer