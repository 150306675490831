import { createAsyncThunk } from '@reduxjs/toolkit';
import HttpError from 'shared/networking/http_error';
import BasedataImportsService from '../../../services/base_data_import.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';

export const fetchAllBasedataImports = createAsyncThunk(
    'admin/basedataimports/fetchAllBasedataImports',
    async (_) => {
        const response = await BasedataImportsService.getImportStatus();
        handleResponseErrors(response, "Import");
        return response;
    }
)

export const createAndFetchBasedataImport = createAsyncThunk(
    'admin/basedataimports/createAndFetchBasedataImport',
    async (_) => {
        const responseCreate = await BasedataImportsService.createBasedataImport();

        if (responseCreate.isConflict()) {
            throw new HttpError("Es läuft bereits ein Import", responseCreate.getErrorCode());
        }
        handleResponseErrors(responseCreate, "Import");

        const responseFetch = await BasedataImportsService.getImportStatus();
        handleResponseErrors(responseFetch, "Import");
        return responseFetch;
    }
)