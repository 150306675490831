import { IAvailableAdditionAttribute } from "models/addition_attribute_categories/available_addition_attribute";
import IAvailableQuality from "models/available_basedata/available_quality";
import { MasspointTypeEnumValuesLookup } from "models/masspoints/enums/masspoint_type.enum";
import { MassPointCriteria } from "models/masspoints/masspoint_criteria";
import IMasspointOverview from "models/masspoints/masspoint_overview";
import { SoftSeamConfiguration } from "models/policies/policy_soft_seam";
import { doesExist, isNullOrWhitespace } from "services/validation.service";
import { ICompressionClassViewModel, IMassPointCriteriaFilterList, IMassPointCriteriaFilterListItem, IMassPointCriteriaViewModel, IQualityFilterList, IQualityFilterListItem, IQualitySelectionViewModel, ISoftSeamConfigurationViewModel } from "../../components/policy_soft_seam.model";
import { PolicySoftSeamUpdateState } from "./policy_soft_seam_update.model";
import { ISelectable } from "shared/components/selectionComponent/models/selectable";

export const updateCanSave = (state: PolicySoftSeamUpdateState) => {
    state.command.updatePolicy.canExecute =
        !isNullOrWhitespace(state.data.policy.name)
        && doesExist(state.data.selectedAddition)
        && containsMasspoints(state)
        && containsConfiguration(state)
        && isNotEqual(state);
}

const isNotEqual = (state: PolicySoftSeamUpdateState) => {
    return state.data.policy.name !== state.loadedData.policy.name 
        || state.data.policy.ignoreOnQuotation !== state.loadedData.policy.ignoreOnQuotation 
        || state.data.selectedAddition.id !== state.loadedData.policy.additionId
        || haveMassPointsChanged(state.data.massPointCriterias.selectedList.allItems, state.loadedData.policy.massPointCriterias)
        || haveConfigurationChanged(state.data.configurations, state.loadedData.policy.configurations)
}

const haveConfigurationChanged = (configurationsViewModel: ISoftSeamConfigurationViewModel[], loadedConfiguration: SoftSeamConfiguration[]) => {
    let changedItems = false;
    
    loadedConfiguration.forEach(x => {
        const indexConfiguration = configurationsViewModel.findIndex(y => y.selectedCompressionClass.id === x.compressionClassId);
        if(indexConfiguration === -1) {
            changedItems = true;
        } else {
            const config = configurationsViewModel.find(y => y.selectedCompressionClass.id === x.compressionClassId);
            if( config.threshold !== x.threshold) {
                changedItems = true;
            } else if( haveQualitiesChanged(x.qualities, config.qualities.selectedList.allItems)) {
                changedItems = true;
            }
           
        }
    });

    return configurationsViewModel.length !== loadedConfiguration.length || changedItems;
}

const haveQualitiesChanged = (loadedQualities: number[], assigned: IQualityFilterListItem[] ) => {
    let changedItems = false;
    
    loadedQualities.forEach(x => {
        if(assigned.findIndex(y => y.id === x) === -1) {
            changedItems = true;
        }
    });
    return loadedQualities.length !== assigned.length || changedItems;
}

const haveMassPointsChanged = (massPointCriteriaViewModel: IMassPointCriteriaFilterListItem[], loadedMasspointCriteria: MassPointCriteria[]) => {
    let changedItems = false;
    massPointCriteriaViewModel.forEach(x => {
        if(loadedMasspointCriteria.findIndex(y => x.bodyAreaId === y.bodyAreaId
            && x.massPointType === y.massPointType
            && x.name === y.massPointName) === -1) {
                changedItems = true;
        }
    });

    return massPointCriteriaViewModel.length !== loadedMasspointCriteria.length
        || changedItems;
}

const containsMasspoints = (state: PolicySoftSeamUpdateState) => {
    return state.data.massPointCriterias.selectedList.allItems.length > 0;
}

const containsConfiguration = (state: PolicySoftSeamUpdateState) => {
    return state.data.configurations.length > 0
        && valideConfigurations(state.data.configurations);
}

const valideConfigurations = (configurations: ISoftSeamConfigurationViewModel[]) => {
    let valide = true;
    configurations.forEach(x => {
        if(!x.selectedCompressionClass) {
            valide = false;
        } else if(x.threshold < 0.0 || x.threshold > 999.0) {
            valide = false;
        } else if(x.qualities.selectedList.allItems.length === 0) {
            valide = false;
        }
    });
    return valide;
}

export const calculateAvailableCompressionClasses = (configurations: ISoftSeamConfigurationViewModel[], compressionsClassesLoaded: IAvailableAdditionAttribute[])  : ISoftSeamConfigurationViewModel[] => {
    const selectedCompressionClasses = [];
    configurations.filter(x => x.selectedCompressionClass)
        .forEach(x => selectedCompressionClasses.push(x.selectedCompressionClass));

    const availableCompressionClasses: ICompressionClassViewModel[] = [];
    compressionsClassesLoaded.forEach(x => {
        if(selectedCompressionClasses.length === 0 || selectedCompressionClasses.findIndex(y => y.id === x.id) === -1) {
            availableCompressionClasses.push({
                id: x.id,
                displayName: x.name
            });
        }
    });

    configurations.forEach(x => {
        x.compressionClasses = [...availableCompressionClasses];
        if(x.selectedCompressionClass) {
            x.compressionClasses.push(x.selectedCompressionClass);
        }
        x.compressionClasses.sort(sortCompressionClass);
    });
    return configurations;
}

export const sortCompressionClass = (mp1: ICompressionClassViewModel, mp2: ICompressionClassViewModel) => {
    return mp1.displayName.localeCompare(mp2.displayName);
}

export const createCompressionClass = (state: PolicySoftSeamUpdateState): ICompressionClassViewModel[] => {
    return state.loadedData.compressionClasses.map(x => { return {
        id: x.id,
        displayName: x.name
    }});
}

export const toQualityId = (qualities: IQualityFilterListItem[]): number[] => {
    var qualityIds: number[] = [];
    qualities.forEach(item => {
        qualityIds.push(
            item.id
        );
    });
    return qualityIds;
}

export const createQualityCriterias = (qualities: IAvailableQuality[]) : IQualityFilterList => {
    return {
        allItems: qualities.map(qu =>  { return {
            id: qu.id,
            displayName: `${qu.erpId} ${qu.name}`,
        }}),
        filteredItems: [],
        searchText: "" 
    };    
}

export const moveQualityFromUnselectedToSelected = (configuration: ISoftSeamConfigurationViewModel, qualityIds: number[]) => {
    const selectedList = configuration.qualities.selectedList;
    const unselectedList = configuration.qualities.unSelectedList;

    qualityIds.forEach(qualityId => {
        const itemToMove = configuration.qualities.unSelectedList.allItems.find(x => x.id === qualityId);
        moveQualityFromSourceToDestination(unselectedList, selectedList, itemToMove);
    });

    filterQualities(selectedList);
    filterQualities(unselectedList);
}

export const moveQualityFromSelectedToUnselected = (configuration: ISoftSeamConfigurationViewModel, qualityIds: number[]) => {
    const selectedList = configuration.qualities.selectedList;
    const unselectedList = configuration.qualities.unSelectedList;

    qualityIds.forEach(qualityId => {
        const itemToMove = configuration.qualities.selectedList.allItems.find(x => x.id === qualityId);
        moveQualityFromSourceToDestination(selectedList, unselectedList, itemToMove);
    });

    filterQualities(selectedList);
    filterQualities(unselectedList);
}

export const moveQualityFromSourceToDestination = (source: IQualityFilterList, destination: IQualityFilterList, itemToMove: IQualityFilterListItem) => {
    const indexInAll = source.allItems.findIndex(x => x.id === itemToMove.id);
    destination.allItems.push(itemToMove);
    source.allItems.splice(indexInAll, 1);
}

export const filterQualities = (listToFilter: IQualityFilterList) => {
    listToFilter.filteredItems = listToFilter.allItems
        .filter(m => qualityMatchesText(m, listToFilter.searchText))
        .sort(sortQuality);
}

export const qualityMatchesText = (m: ISelectable, searchText: string): boolean => {
    if (searchText === "") {
        return true;
    }
    const searchTextLowerCase = searchText.toLowerCase();
    return (m.displayName.toLowerCase().includes(searchTextLowerCase));
}

export const sortQuality = (mp1: ISelectable, mp2: ISelectable) => {
    return mp1.displayName.localeCompare(mp2.displayName);
}

export const createMasspointCriteriaList = (massPoints: IMasspointOverview[]) : IMassPointCriteriaFilterList => {
    return {
        allItems: massPoints.map(mp => { return {
            id: mp.id,
            displayName: `${mp.name} ${MasspointTypeEnumValuesLookup(mp.massPointType)} ${mp.bodyArea.name}`,
            name: mp.name,
            massPointType: mp.massPointType,
            bodySide: mp.bodySide,
            bodyAreaId: mp.bodyArea.id, 
        }}),
        filteredItems: [],
        searchText: "" 
    };
}

export const moveMasspointFromUnselectedToSelected = (state: PolicySoftSeamUpdateState, masspointIds: number[]) => {
    const selectedList = state.data.massPointCriterias.selectedList;
    const unselectedList = state.data.massPointCriterias.unSelectedList;

    masspointIds.forEach(masspointId => {
        const itemToMove = state.data.massPointCriterias.unSelectedList.allItems.find(x => x.id === masspointId);
        moveMasspointFromSourceToDestination(unselectedList, selectedList, itemToMove);
    })

    filterMasspoints(selectedList);
    filterMasspoints(unselectedList);
}

export const moveMasspointFromSelectedToUnselected = (state: PolicySoftSeamUpdateState, masspointIds: number[]) => {
    const selectedList = state.data.massPointCriterias.selectedList;
    const unselectedList = state.data.massPointCriterias.unSelectedList;

    masspointIds.forEach(masspointId => {
        const itemToMove = state.data.massPointCriterias.selectedList.allItems.find(x => x.id === masspointId);
        moveMasspointFromSourceToDestination(selectedList, unselectedList, itemToMove);
    })

    filterMasspoints(selectedList);
    filterMasspoints(unselectedList);
}

export const moveMasspointFromSourceToDestination = (source: IMassPointCriteriaFilterList, destination: IMassPointCriteriaFilterList, itemToMove: IMassPointCriteriaFilterListItem) => {
    const indexInAll = source.allItems.findIndex(x => x.id === itemToMove.id);
    destination.allItems.push(itemToMove);
    source.allItems.splice(indexInAll, 1);
}

export const filterMasspoints = (listToFilter: IMassPointCriteriaFilterList) => {
    listToFilter.filteredItems = listToFilter.allItems
        .filter(m => masspointMatchesText(m, listToFilter.searchText))
        .sort(sortMasspointCriteria);
}

export const masspointMatchesText = (m: ISelectable, searchText: string): boolean => {
    if (searchText === "") {
        return true;
    }
    const searchTextLowerCase = searchText.toLowerCase();
    return (m.displayName.toLowerCase().includes(searchTextLowerCase));
}

export const sortMasspointCriteria = (mp1: ISelectable, mp2: ISelectable) => {
    return mp1.displayName.localeCompare(mp2.displayName);
}

export const mergeMassPoints = (loadedMassPointCriteria: MassPointCriteria[], massPointCriteria: IMassPointCriteriaViewModel ) => {
    
    loadedMassPointCriteria.forEach(x => {
        const indexUnselected = massPointCriteria.unSelectedList.allItems.findIndex(y => 
            y.name === x.massPointName 
            && y.massPointType === x.massPointType 
            && y.bodyAreaId === x.bodyAreaId);
        if(indexUnselected > -1) {
            const selectedMassPoint = massPointCriteria.unSelectedList.allItems.find(y => 
                y.name === x.massPointName 
                && y.massPointType === x.massPointType 
                && y.bodyAreaId === x.bodyAreaId);
            massPointCriteria.selectedList.allItems.push(selectedMassPoint);
            massPointCriteria.unSelectedList.allItems.splice(indexUnselected,1);
        }
    });
} 

export const createConfigurations = (configurations: SoftSeamConfiguration[], compressionClasses: IAvailableAdditionAttribute[], qualities: IAvailableQuality[]): ISoftSeamConfigurationViewModel[] => {
    let index = 1
    const seamConfigurations: ISoftSeamConfigurationViewModel[] = [];
    configurations.forEach(x => {
        seamConfigurations.push({
            index: index,
            threshold: x.threshold,
            selectedCompressionClass: createSelectedCompressionClass(x.compressionClassId, compressionClasses),
            compressionClasses: [],
            qualities: createQuality(x.qualities, qualities)
        });
        index++;
    });

    calculateAvailableCompressionClasses(seamConfigurations, compressionClasses);
    return seamConfigurations;
} 

const createSelectedCompressionClass = (compressionClassId: number,  compressionClasses: IAvailableAdditionAttribute[]): ICompressionClassViewModel => {
    
    const compressionClass = compressionClasses.find(x => x.id === compressionClassId);
    return {
        id: compressionClass.id,
        displayName: compressionClass.name
    };
}

const createQuality = (selectedQualities: number[], qualities: IAvailableQuality[]): IQualitySelectionViewModel => {
    const qualityViewModel: IQualitySelectionViewModel = {
        selectedList: {
            allItems: [],
            filteredItems: [],
            searchText: ""
        },
        unSelectedList: createQualityCriterias(qualities),
    };

    selectedQualities.forEach(x => {
        const indexUnselected = qualityViewModel.unSelectedList.allItems.findIndex(y => 
            y.id === x );
        if(indexUnselected > -1) {
            const selectedQuality = qualityViewModel.unSelectedList.allItems.find(y => 
                y.id === x);
            qualityViewModel.selectedList.allItems.push(selectedQuality);
            qualityViewModel.unSelectedList.allItems.splice(indexUnselected,1);
        }
    });
    filterQualities(qualityViewModel.selectedList);
    filterQualities(qualityViewModel.unSelectedList);
    return qualityViewModel;
}

