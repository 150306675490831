import { GridFilterItem, GridColDef, getGridBooleanOperators, GridSortModel } from "@mui/x-data-grid-pro";
import IAvailableArticleType from "models/available_basedata/available_article_type";
import IAvailableBodyArea from "models/available_basedata/available_body_area";
import IAvailableQuality from "models/available_basedata/available_quality";
import { ICheckOverview } from "models/plausibility_check/check_overview";
import { FormulaTemplateEnumValues } from "models/plausibility_check/enums/formula_template.enum";
import { IPlausibilityCheckType } from "models/plausibility_check/plausibility_check_type";
import { CustomBooleanCell } from "shared/components/datagrid/custom_boolean_cell.component";
import { CustomChipCellComponent } from "shared/components/datagrid/custom_chip_cell.component";
import { CustomFilterOperators } from "shared/components/datagrid/custom_filter_operators";
import { CustomTooltipHeader } from "shared/components/datagrid/custom_tooltip_header.component";
import { DatagridMenu } from "shared/components/datagrid/datagrid_menu.component";


const buildFormulaTemplateText = ( checkType: IPlausibilityCheckType ) => {
    return FormulaTemplateEnumValues.find(x => x.value === checkType?.formulaTemplate)?.text;
}

export const filterPlausibilityCheckTypeValue = (filterItem: GridFilterItem, filteredItems: IPlausibilityCheckType[]) => {
    if(filterItem.value)
    {
        switch(filterItem.field)
        {
            case 'name':
                filteredItems = filteredItems.filter(x => x.name.toLocaleLowerCase().includes(filterItem.value?.toString().toLocaleLowerCase()));
                break;
            case 'formulaTemplate':
                filteredItems = filteredItems.filter(x => buildFormulaTemplateText(x).toLocaleLowerCase().includes(filterItem.value?.toString().toLocaleLowerCase()));
                break;           
            case 'warningMessageKey':
                filteredItems = filteredItems.filter(x => x.warningMessageKey?.toLocaleLowerCase().includes(filterItem.value?.toString().toLocaleLowerCase()));
                break;          
            case 'errorMessageKey':
                filteredItems = filteredItems.filter(x => x.errorMessageKey?.toLocaleLowerCase().includes(filterItem.value?.toString().toLocaleLowerCase()));
                break;  
            case 'isUniqueRules':
                filteredItems = filteredItems.filter(x => (x.isUniqueRules ? "eindeutig" : "uneindeutig").toLocaleLowerCase().includes(filterItem.value?.toString().toLocaleLowerCase()));
                break;                                                                                                  
        }
    }
    return filteredItems;
}

export const getGridColumns = (
    editPlausibilityCheckType: (plausibilityCheckType: IPlausibilityCheckType) => void,
    deletePlausibilityCheckType: (plausibilityCheckType: IPlausibilityCheckType) => void,
    ): GridColDef[] => [
    {
        field: "menu",
        headerName: " ",
        width: 15,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        renderCell: params => DatagridMenu({params: params, menuItemsData: 
            [
                {label: "Editieren", action: editPlausibilityCheckType},
                {label: "Löschen", action: deletePlausibilityCheckType},
            ]})
    },
    {
        field: "name",
        headerName: "Name",
        disableColumnMenu: false,
        flex: 0.2,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    },  
    {
        field: "formulaTemplate",
        headerName: "Regeltemplate",
        disableColumnMenu: false,
        flex: 0.2,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
        valueGetter: (value, row) => buildFormulaTemplateText(row),
    },  
    {
        field: "warningMessageKey",
        headerName: "Warnmeldung",
        disableColumnMenu: false,
        flex: 0.2,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    },   
    {
        field: "errorMessageKey",
        headerName: "Fehlermeldung",
        disableColumnMenu: false,
        flex: 0.2,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    },   
    {
        field: "isUniqueRules",
        headerName: "Regelwerk",
        disableColumnMenu: false,
        flex: 0.2,
        sortable: false,
        hideable: false,
        pinnable: false,
        valueGetter: (value, row) => row.isUniqueRules ? "eindeutig" : "uneindeutig",
        filterOperators: CustomFilterOperators(),
    }
]    

export const sortByNameModel: GridSortModel = [
    {
        field: "name",
        sort: "asc",
    },
];