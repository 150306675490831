import { GridFilterItem, GridColDef, GridSortModel } from "@mui/x-data-grid-pro";
import { IAdditionPositionOverview } from "models/addition_position/addition_position_overview";
import { CustomFilterOperators } from "shared/components/datagrid/custom_filter_operators";
import { DatagridMenu } from "shared/components/datagrid/datagrid_menu.component";

export const filterAdditionPositionsValue = (filterItem: GridFilterItem, filteredItems: IAdditionPositionOverview[]) => {
    if(filterItem.value)
    {
        switch(filterItem.field)
        {
            case 'name':
                filteredItems = filteredItems.filter(x => x.name.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;
            case 'translationKeyForName':
                filteredItems = filteredItems.filter(x => x.translationKeyForName.toLocaleLowerCase().includes(filterItem.value.toString().toLocaleLowerCase()));
                break;           
            case 'selectedPositionHierarchys':
                filteredItems = filteredItems
                    .filter(x => x.selectedPositionHierarchys
                        .map(x => x.additionHierarchy.name)
                        .join(", ")
                        .toLocaleLowerCase()
                        .includes(filterItem.value.toString().toLocaleLowerCase()));
                break;                                                                                               
        }
    }
    return filteredItems;
}

export const getGridColumns = (
    editAdditionPosition: (additionPosition: IAdditionPositionOverview) => void,
    deleteAdditionPosition: (additionPosition: IAdditionPositionOverview) => void,
    ): GridColDef[] => [
    {
        field: "menu",
        headerName: " ",
        width: 15,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        renderCell: params => DatagridMenu({params: params, menuItemsData: 
            [
                {label: "Zusatzposition editieren", action: editAdditionPosition},
                {label: "Zusatzposition löschen", action: deleteAdditionPosition}
            ]})
    },
    {
        field: "name",
        headerName: "Name",
        disableColumnMenu: false,
        flex: 0.2,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    },  
    {
        field: "translationKeyForName",
        headerName: "Übersetzungskey",
        disableColumnMenu: false,
        flex: 0.2,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
    },  
    {
        field: "selectedPositionHierarchys",
        headerName: "Zusatz-Hierarchien",
        disableColumnMenu: false,
        flex: 0.6,
        sortable: false,
        hideable: false,
        pinnable: false,
        filterOperators: CustomFilterOperators(),
        valueGetter: (value, row) =>
            row.selectedPositionHierarchys.map(x => x.additionHierarchy.name).join(", ")
        ,
    }
]    

export const sortByNameModel: GridSortModel = [
    {
        field: "name",
        sort: "asc",
    },
];