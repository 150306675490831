import React from 'react';
import { closeProductConfigurationRunDetails } from '../redux/product_configurations_overview.slice';
import { JsonViewer } from '@textea/json-viewer';;
import { ProductConfigurationRunDetails } from '../../../../models/simulator/product_configuration_run_details';
import ClearIcon from "@mui/icons-material/Clear";
import { AppDispatch } from '../../../../app/redux_store';
import { useAppDispatch } from '../../../../app/hook';
import { PrimaryButtonComponent } from '../../../../shared/components/buttons/primary_button.component';
import { Grid } from '@mui/material';

type ProductConfigurationRunDetailsComponentProps = {
    productConfigurationRunDetails: ProductConfigurationRunDetails;
};

export const ProductConfigurationRunDetailsComponent: React.FC<ProductConfigurationRunDetailsComponentProps> = ({ productConfigurationRunDetails }) => {
    const dispatch: AppDispatch = useAppDispatch();

    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={3}>

            <Grid
                item
                container
                justifyContent="flex-end">

                <PrimaryButtonComponent onClick={() => dispatch(closeProductConfigurationRunDetails())}>
                    <ClearIcon /> Zurück zur Übersicht
                </PrimaryButtonComponent>
            </Grid>

            <Grid item>
                <JsonViewer displayDataTypes={false} value={productConfigurationRunDetails.content} />
            </Grid>
    </Grid>
    );
}