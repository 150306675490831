import styled from "@emotion/styled";

export const MasspointWrapper = styled.div`
    margin: 4px;
    width: relative;
`

export const MaskTopComponent = styled.div`
    zIndex: 99;
`
export const MaskTopCenterComponent = styled.div`
    margin-top: 20px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center; 
    display: flex;
`

export const MaskSideComponent = styled.div`
    zIndex: 99;
    margin-bottom: 50px;
`
export const ImageComponent = styled.div`
    min-width: 200px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center; 
    flex-direction: column;
    display: flex;
    margin-bottom: 50px;
`
export const FlexareaTop = styled.div`   
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    display: flex;
    flex-wrap: wrap-reverse;
    flex-direction: row;
    width: 240px;
`

export const FlexareaLeft = styled.div`
    justify-content: flex-start;
    justify-items: center;
    align-items: center;
    align-content: center;
    display: flex;
    flex-wrap: wrap-reverse;
    height: 280px;
    flex-direction: column-reverse;
`
export const FlexareaRight = styled.div`
    justify-content: flex-start;
    justify-items: center;
    align-items: center;
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    height: 280px;
    flex-direction: column-reverse;
`

export const HeadlineTop = styled.div`
    font-weight: bold;
    text-align: center;
`

export const HeadlineLeft = styled.div`
    font-weight: bold;
    text-align: center;
`

export const HeadlineRight = styled.div`
    font-weight: bold;
    text-align: center;
`