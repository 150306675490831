import { Fragment } from "react"
import { ISizePreviewViewModel } from "./serial_size_preview.models"
import { Grid } from "@mui/material"
import { EditorMediumSectionHeaderComponent, EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style"
import { OverviewDatagrid } from "shared/components/datagrid/overview_datagrid.component"
import { getGridColumns, sortBySide } from "./serial_size_preview.helper"

export interface SizePreviewComponentProps {
    sizes: ISizePreviewViewModel[];
    isLoading: boolean;
}

export const SizePreviewComponent: React.FC<SizePreviewComponentProps> = ({
    sizes,
    isLoading
}) => {

    return (
    <>
        <EditorSectionHeaderComponent>Grösse</EditorSectionHeaderComponent>
        {
            sizes?.map((size, index) => {
                return <Fragment key={index}>
                    <Grid container
                        spacing={3}
                        direction={"column"}>
                        <Grid item>
                            <EditorMediumSectionHeaderComponent style={{ marginBottom : "15px" }}>{`Grösse: ${size.displayName}`}</EditorMediumSectionHeaderComponent>
                        </Grid>
                        <Grid item>
                            <OverviewDatagrid
                                isLoading={isLoading}
                                columns={getGridColumns()}
                                rows={size.masspoints}
                                loadedRowsCount={size.masspoints.length}
                                sortModel={sortBySide}
                                onFilterModelChange={() => {}}
                            />
                        </Grid>
                    </Grid>
                </Fragment>
            })
        }
    </>)
}