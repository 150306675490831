import { doesExist, isNotEmpty, doesNotStartOrEndWithWhitespace } from "../../../../services/validation.service";
import { FileExtensionConstantsEnum } from "../../../../models/file_extension_constants.enum";
import { MimeTypeConstantsEnum } from "../../../../models/mime_type_constants.enum";
import { UpdateMassImageState } from "./update_massimage.model";

export const updateCanUpload = (state: UpdateMassImageState) => {
    state.command.uploadImage.canExecute = doesExist(state.data.name)
        && isNotEmpty(state.data.name)
        && doesNotStartOrEndWithWhitespace(state.data.name)
        && state.data.file
        && state.data.file.type === MimeTypeConstantsEnum.Svg
        && state.data.file.name.toLowerCase().endsWith(FileExtensionConstantsEnum.Svg)
}
