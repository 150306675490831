import theme from 'shared/style/theme';
import styled from "@emotion/styled";

export const EditorSectionHeaderComponent = styled.div`
    margin-top: 25px;
    font-weight: bold;
    font-size: larger;
    color: ${theme.colors.text};
`;

export const EditorMediumSectionHeaderComponent = styled.div`
    margin-top: 25px;
    margin-right: 25px;
    font-weight: bold;
    font-size: medium;
    color: ${theme.colors.text};
`;