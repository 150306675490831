import styled from "@emotion/styled";;

export const HeaderColumn = styled.div`
    flex-basis: 12.5%;
    text-align: left;
    display: inline-block;
`;

export const HeaderIconColumn = styled.div`
    width: 50px;
    padding: 20px 0px;
    display: inline-block;
`;

export const HeaderMenuColumn = styled.div`
    width: 75px;
    padding: 25px;
    display:inline-block;
`;

export const Headline = styled.div`
    display: inline-block;
    display: flex;
    font-weight: bold;
    min-height: 56px;
    background-color: #e9edf0;
    font-size: 14px;
    justify-content: center;
    align-items: center;
`;

export const ContentColumn = styled.div`
    flex-basis: 12.5%;
    color: #48586a;
    text-align: left;
    display: inline-block;
`;

export const ContentIconColumn = styled.div`
    width: 50px;
    padding: 20px 0px;
    align: left;
`;

export const ContentMenuColumn = styled.div`
    width: 75px;
    padding: 25px;
`;

export const TestInformationContent = styled.div`
    display: flex; 
    justify-content: center;
    align-items: center;
    height: 75px;
`;

export const ErrorInformationContent = styled.div`
    flex-basis: 100%; 
    padding: 10px;
    border-top-style:solid;
    border-color:#e9edf0;
    border-width:1px;
    color: #48586a;
    text-align: left;
`;

export const ErrorContent = styled.div`
    display: flex; 
`;

export const Content = styled.div`
    border-bottom-style:solid;
    border-color:#e9edf0;
    border-width:1px;
    background-color: white;
    font-size: 14px;
`;